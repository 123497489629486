import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import OutlinedButton from '../../ui/OutlinedButton';
import { useSelector } from "react-redux"
import {getCalculationModalsState} from '../../../store/modules/appeals';
import Modal from '@mui/material/Modal';
import {styled as MUIstyled} from '@mui/material/styles'


const Container = styled.div`
  width: 569px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 100%;
    border-radius: 0px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const Title = styled(Text)`
  color:  #3C3C3C;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;
`

const Question = styled(Text)`
  color: #3C3C3C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
`

const ButtonBlock = styled.div`
  display: flex;
  margin-top: 20px;

  & > :first-child {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

const Button = MUIstyled(OutlinedButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20px',
}))



export default function ConfirmCancelationModal({ onConfirm, ...props }) {
  const {data} = useSelector(getCalculationModalsState)
  const isExtraPayment = (data?.type || props.type)  === 'payment';

  return (
    <Modal 
      {...props}
      aria-labelledby="calculation-decline-confirm-modal-title"
      aria-describedby="calculation-decline-confirm-modal-description"
    >
      <Container>
        <Title>Отклонить расчет</Title>
        <Question>
          Вы уверены, что хотите отклонить расчет на {isExtraPayment ? 'доплату' : 'возврат'} денежных средств по данной заявке?
        </Question>
        <ButtonBlock>
          <Button type="button" onClick={onConfirm}>Да, отклонить расчет </Button>
          <Button
            type="button"
            onClick={() => {
              typeof props.onGoBack === "function" && props.onGoBack()
            }}
          >
            Нет, вернуться к расчету
          </Button>
        </ButtonBlock>
      </Container>
    </Modal>
  )
}
