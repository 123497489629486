import React from "react"
import styled from "styled-components"
import moment from "moment"
import {Text,Separator} from "../../../../ui"
import { PassengerTypes } from "../../../../../store/modules/orders"
import { generateIconPath } from "./utils"

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    display: block;
    & > first-child {
      margin-bottom: 10px;
    }
    margin-bottom: 15px;
  }
`

const Header = styled(Text)`
  display: flex;
  align-items: center;
  & > img {
    margin-right: 8px;
  }
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
`

// const TicketNumber = styled(Text)`
//   color: #737373;
//   font-size: 16px;
//   line-height: 20px;
//   @media (max-width: 1024px) {
//     font-size: 14px;
//     line-height: 18px;
//     margin-bottom: 15px;
//   }
// `;

//Passengers main data

const Row = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 1fr;
  margin-bottom: 40px;

  &.solo {
    grid-template-columns: 50% 1fr;
  }

  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 20px;
    &.last {
      margin-bottom: 35px;
    }
    & > div {
      margin-bottom: 20px;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    &.last {
      margin-bottom: 0;
    }
  }
`

const Column = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    border-bottom: 1px solid #dcdcdc;
    & > :first-child {
      margin-bottom: 3px;
    }
    padding-bottom: 5px;
  }
`

const Label = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`

const Description = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-weight: normal;
  }
`

const Passenger = React.memo(({ document, ...props }) => {
  const birthDate = moment(props.birthDate).format("DD.MM.YYYY")
  const documentType = getPassportDescription(document.type)
  const sex = getGenderDescription(props.gender)
  const documentNumber = document.number

  const iconPath = React.useMemo(() => {
    return generateIconPath(props.gender, props.birthDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Head>
        <Header>
          <img
            width={40}
            height={40}
            style={{ borderRadius: 8 }}
            src={iconPath}
            alt="genderImage"
          />
          <span>
            Пассажир {props.passengerNo},{" "}
            {translatePassengerType(props.passengerType)}
          </span>
        </Header>
        {/* {(props.aviaTickets || []).length > 0 && (
        <TicketNumber>
          № билета: {props.aviaTickets[0]?.eTicketNumber}
        </TicketNumber>
      )} */}
      </Head>
      <Row className="solo">
        <Column>
          <Label>Имя:</Label>
          <Description
            style={{ textTransform: "capitalize" }}
          >{`${props.lastName.toLowerCase()} ${props.firstName.toLowerCase()} ${
            props.patronymic ? props.patronymic : ""
          }`}</Description>
        </Column>
        <Column>
          <Label>Пол:</Label>
          <Description>{sex}</Description>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Дата рождения:</Label>
          <Description>{birthDate}</Description>
        </Column>
        <Column>
          <Label>Гражданство:</Label>
          <Description>{props.citizen}</Description>
        </Column>
        <Column>
          <Label>Документ:</Label>
          <Description style={{ whiteSpace: "initial" }}>
            {documentType}
          </Description>
        </Column>
      </Row>
      <Row className="last">
        <Column>
          <Label>Серия и номер:</Label>
          <Description>{documentNumber}</Description>
        </Column>
        {props.aviaTickets && props.aviaTickets[0]?.eTicketNumber && (
          <Column>
            <Label>Номер билета:</Label>
            <Description>{props.aviaTickets[0]?.eTicketNumber}</Description>
          </Column>
        )}
        {props.loyalityCard && (
          <Column>
            <Label>№ карты лояльности:</Label>
            <Description>{props.loyalityCard}</Description>
          </Column>
        )}
        {document.issueDate && document.issueDateExist && (
          <Column>
            <Label>Срок действия:</Label>
            <Description>
              {moment(document.issueDate).format("YYYY-MM-DD")}
            </Description>
          </Column>
        )}
      </Row>
      {props.separator && <Separator />}
    </>
  )
})

function translatePassengerType(type) {
  switch (type) {
    case PassengerTypes.Adult:
      return "взрослый"
    case PassengerTypes.Child:
      return "ребёнок"
    default:
      return "младенец"
  }
}

function getPassportDescription(type) {
  switch (type) {
    case "RussianFederationPassport":
      return "Паспорт РФ"
    case "BirthCertificate":
      return "Свидетельство о рождении"
    case "InternationalPassport":
      return "Загран. паспорт"
    case "NationalPassport":
      return "Национальный документ"
    default:
      return "Другой документ"
  }
}

function getGenderDescription(type) {
  return type === "Male" ? "Мужской" : "Женский"
}

export default Passenger
