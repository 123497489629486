import './style.scss'

const BASE_IMAGE_URL = 'https://apigate-tui.fstravel.com/api/geocontent/static/Country'

function VisaCountries({ content, title }) {
    return <div className='country-list'>
        <h3>{title}</h3>
        <div className='country-grid'>
            {content.map((item) => {
                const imageUrl = `${BASE_IMAGE_URL}/${item.id}/${item.countryFlagFileName}`
                const link = 'visa' + item.urlName.substring().substring(item.urlName.lastIndexOf('/'))
                return <div key={item.id}>
                    <a className='item-link' href={link}>
                        <div className='item-flag' style={{ backgroundImage: `url(${imageUrl})` }} />
                        <div>
                            {item.name}
                        </div>
                    </a>
                </div>
            })}
        </div>
    </div>

}

export default VisaCountries