import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import '../style.css'
import { isEmpty } from 'lodash'
import useBookingStore from '../../../store'
import AviaFilter from './AviaFilter'
import AviaFlightsTable from './AviaFlightsTable'

const AviaRegBlock = ({ tour, isCruises = false }) => {
	const transportsGDS = useBookingStore(({ transportsGDS }) => transportsGDS)
	const flightLoading = useBookingStore(({ flightLoading }) => flightLoading)
	// const actions = useBookingStore(({ actions }) => actions)
	// const flights = useBookingStore(state => state?.tour?.flightOptions)
	// const withoutFlights = tour?.tourType.id === 7 || tour?.name.includes('(hotel only)') || tour?.tourType.id === 30

	// useEffect(() => {
	// 	if (
	// 		// !withoutFlights && 
	// 		tour?.gdsId && isEmpty(flights)) {
	// 		actions.getRegularFlights()
	// 	}
	// }, [tour, actions, flights,
	// 	// withoutFlights
	// ])

	// if (
	// 	withoutFlights
	// 	) {
	// 	return <>
	// 		<div className='block-header'>
	// 			<h3 className='title'>Транспорт</h3>
	// 		</div>
	// 		<div className='no-flights'>Нет доступных перелетов</div>
	// 	</>
	// }

	if (isCruises) return

	return (
		<>
			<div className='block-header'>
				<h3 className='title'>Транспорт</h3>
			</div>
			{!isEmpty(transportsGDS) && <AviaFilter />}
			{isEmpty(transportsGDS) ? (
				<div className='no-flights'>
					{flightLoading ? <CircularProgress /> : 'Нет доступных перелетов'}
				</div>
			) : (
				<AviaFlightsTable />
			)}
		</>
	)
}

export default AviaRegBlock
