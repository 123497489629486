import { create } from 'zustand'

export const useErrorStore = create((set, get) => ({
	error: null,
	valid: 0,
	checkValid: () => set({ valid: get().valid + 1 }),
	setErrorForm: value => {
		set({ error: value })
	}
}))
