import { Box, Fade, Grid, IconButton, Paper } from '@mui/material'
import React, { memo } from 'react'
import { ReactComponent as PDF } from '../../../../../asset/icons/PDF.svg'
import { ReactComponent as Trash } from '../../../../../asset/icons/trash.svg'

function humanFileSize(bytes, si = true, dp = 1) {
	const thresh = si ? 1000 : 1024

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + ' ' + units[u]
}

export default memo(function DocumentItem({ file, handleDelete }) {
	return (
		<Grid item xs={6}>
			<Fade in={!!file.name}>
				<Paper
					elevation={0}
					sx={{
						p: '10px 16px 10px 16px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						borderRadius: '8px'
					}}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}>
						<PDF />
						<Box sx={{ pl: 2, pr: 2, maxWidth: '245px' }}>
							<span className='upload-file-title'>{file.name}</span>
							<br />
							<span className='upload-file-size'>
								{humanFileSize(file.size)}
							</span>
						</Box>
					</Box>
					<Box>
						<IconButton onClick={() => handleDelete(file.name)} size='small'>
							<Trash />
						</IconButton>
					</Box>
				</Paper>
			</Fade>
		</Grid>
	)
})
