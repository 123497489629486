import useSearchTourStore from "../../store.js";
import Card from "../../../../components/Card/Card.js";
import {isMobile} from "react-device-detect";

const ListMeals = () => {
	const meals = useSearchTourStore(({ filters }) => filters.meals);
	const mealIds = useSearchTourStore((state) => state.meals)
	const { addMeals, clearFilterMeals } = useSearchTourStore((state) => state.actions);

	const resetMealsFilter = (event) => {
		if (event.target.checked)
			clearFilterMeals()
	}

	const changeStatus = (id, event) => {
		addMeals(id, event.target.checked)
	}

	return (
		<div style={{ width: !isMobile ? '20%' : '100%' }}>
			<div style={{display: 'flex', justifyContent: "space-between", marginRight: !isMobile ? '10' : '0', marginBottom: 2}}>
				<span>
					Питание
				</span>
				<label htmlFor="resetMealsFilter">
					<input id='resetMealsFilter' type='checkbox' checked={!mealIds.length} onChange={resetMealsFilter} />
					Любое
				</label>
			</div>
			<div className="hotels__container">
				{meals?.map((el) => {
					return <Card key={el.id} name={el.name} id={el.id.toString()} isChecked={mealIds.includes(el.id.toString())} changeStatus={changeStatus} prefix={'meal_'} />;
				})}
			</div>
		</div>
	);
};

export default ListMeals;
