import styled from 'styled-components';

export const TextContaner = styled.div`
  font-family: "Open Sans";
  color: #3C3C3C;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const TimeText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`;
