const zipFlightClass = (flightClass) => {
    switch (flightClass) {
      case 'economy':
        return 'E';
      case 'comfort':
        return 'C';
      case 'business':
        return 'B';
      case 'first':
        return 'F';
      default:
        return 'E';  
    }
  };

export default function createSearchParams({
    fromCode,
    ToCode,
    forwardDate,
    backwardDate,
    adults,
    children,
    infants,
    flightClass,
  }) {
    let result = `${fromCode}${forwardDate.format('DDMMYY')}${ToCode}`;
    if (backwardDate) {
      result += `${backwardDate.format('DDMMYY')}`;
    }
  
    const flightClassFormated =
      flightClass === 'economy' ? '' : zipFlightClass(flightClass);
    const passengers = `${adults}${children ? children : infants ? 0 : ''}${
      infants ? infants : children ? 0 : ''
    }`;
    return result + flightClassFormated + passengers;
  }