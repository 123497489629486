import { useMediaQuery } from '@material-ui/core'
import { Button, useYMaps } from '@pbe/react-yandex-maps'
import React from 'react'
import useCountryStore from '../../../store'
import useYandexMapStore from '../store'
import '../style.css'

export default function CustomButton() {
	const ymaps = useYMaps()
	const openBtn = useYandexMapStore(({ openBtn }) => openBtn)
	const actions = useYandexMapStore(({ actions }) => actions)
	const openCountries = useCountryStore(({ openCountries }) => openCountries)
	const allCountries = useCountryStore(({ allCountries }) => allCountries)
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	const toggleHandler = () => {
		actions.toggleBtns()
	}

	return (
		<>
			<Button
				onClick={toggleHandler}
				options={{
					position: {
						top: isSmallScreen ? '10px' : '540px',
						left: '170px'
					},
					layout: ymaps?.templateLayoutFactory.createClass(
						"<div class='myButton {% if state.selected %}myButtonSelected{% endif %}'>" +
							'{{ data.content }}' +
							'</div>'
					)
				}}
				data={{ content: 'Все направления' }}
				state={{ selected: openBtn }}
			/>
			<Button
				onClick={toggleHandler}
				options={{
					position: {
						top: isSmallScreen ? '10px' : '540px',
						left: '10px'
					},
					layout: ymaps?.templateLayoutFactory.createClass(
						"<div class='myButton {% if state.selected %}myButtonSelected{% endif %}'>" +
							'{{ data.content }}' +
							'</div>'
					)
				}}
				data={{ content: 'Открытые страны' }}
				state={{ selected: !openBtn }}
			/>
			{isSmallScreen && (
				<Button
					options={{
						position: {
							bottom: '10px',
							left: '10px'
						},
						layout: ymaps?.templateLayoutFactory.createClass(
							`<div class='map-legend'>
						<div class='map-legend-count'>{{ data.content.name }}</div>
						<div class='v-map-legend-text'>{{ data.content.open }}</div>
						</div>`
						)
					}}
					data={{
						content: openBtn
							? { name: openCountries.length, open: 'стран открыто' }
							: { name: allCountries.length, open: 'страны, в которые летаем' }
					}}
				/>
			)}
		</>
	)
}
