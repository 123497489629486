import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Mark } from '../../../../asset/images/cabinet/mark.svg'
import useFitClaimStore from '../store'
import { isEmpty } from 'lodash'
import CommentModal from './CommentModal';

const Table = styled.div`
	width: 860px; 
	margin-top: 24px;
	:nth-last-child(1) {
		border-radius: 0px 0px 16px 16px; 
    }
`

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 132px 240px 120px 264px;
	gap: 24px;
	padding: 16px;
`

const TableHeader = styled(TableGrid)`
	color: rgba(126, 131, 137, 1);
	background: #EDF1FE;
	border-radius: 16px 16px 0px 0px ;
`

const TableLine = styled(TableGrid)`
	border-right: 1px solid #DBE0E5;
	border-bottom: 1px solid #DBE0E5;
	border-left: 1px solid #DBE0E5; 
`

const H4 = styled.h4`
font-family: Open Sans;
font-size: 18px;
line-height: 26px;
font-weight: 600;
margin: 0;
`

const TextM = styled.div`
font-family: Open Sans;
font-size: 18px;
line-height: 24px;
font-weight: 400;
color: rgba(46, 46, 50, 1);
`

const Claim = styled.div`
	display:flex;
	gap: 12px;
`

const ClaimText = styled(TextM)`
	color: #4872F2;
	cursor: pointer;
`



export default function OpenClaims() {
	const actions = useFitClaimStore(({ actions }) => actions)
	const issues = useFitClaimStore(({ issues }) => issues)
	const [currentComment, setCurrentComment] = useState(null)
	const comments = useFitClaimStore(({ comments }) => comments)

	const handleClick = (fit, comment) => {
		setCurrentComment({ fit: fit, comment: comment })
	}

	const handleClose = () => {
		setCurrentComment(null)
	}

	useEffect(() => {
		actions.getComments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issues])

	return (
	<>
		<Table>
			<TableHeader>
				<H4>Номер заявки</H4>
				<H4>Тема</H4>
				<H4>Статус</H4>
				<H4>Дата создания</H4>
			</TableHeader>
			{issues.map((item) => <FitClaimLine key={item.key} item={item} onClick={handleClick} />)}
		</Table>
			{currentComment && <CommentModal claimId={currentComment?.fit?.id} comments={comments[currentComment?.fit?.id]} onClose={handleClose} />}
	</>)
}

const FitClaimLine = ({ item, onClick }) => {
	const PART_PASS = localStorage.getItem('agentInfo')
	const actions = useFitClaimStore(({ actions }) => actions)
	const comments = useFitClaimStore(({ comments }) => comments)
	const comment = comments[item.id]
	const date = isEmpty(comment) ? null : comment[0]?.created

	useEffect(() => {
		actions.getComments(item.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <TableLine>
		<Claim>
			<ClaimText
				onClick={() => onClick(item, comment)}
			>{item.key}</ClaimText>
			{/* <Mark /> */}
		</Claim>
		<TextM>{JSON.parse(PART_PASS)?.partPass}</TextM>
		<TextM>{item?.fields?.status?.name}</TextM>
		<TextM>{date?.replace('T', ' ')}</TextM>
	</TableLine>
}

