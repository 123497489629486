import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const AntTabs = styled(Tabs)({

    '& .MuiTabs-indicator': {
        backgroundColor: '#4872F2',
        borderRadius: '2px',
        height: '4px',
        zIndex: 10,
    },
});

const AntTab = styled(props => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0
        },
        fontWeight: 600,
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingBottom: '0px',
        color: '#B3B9C0',
        lineHeight: '18px',
        marginBottom: 5,
        '&.Mui-selected': {
            color: '#4872F2',
            fontWeight: 600
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#4872F2'
		}
    })
)

export default function CustomizedTabs({ value, handleChange }) {
    return (
        <Box sx={{ width: '100%', padding: '0px', fontSize: 16, marginTop: '16px' }}>
            <Box sx={{ bgcolor: '#fff' }}>
                <AntTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="ant example"
                >
                    <AntTab label="Для вылетов из Москвы" />
                    <AntTab label="Для вылетов из регионов" />
                </AntTabs>
                <div
                    style={{
                        width: '100%',
                        height: '4px',
                        backgroundColor: '#DBE0E5',
                        borderRadius: '2px',
						top: '-4px',
						position: 'relative',
                    }}
                ></div>
            </Box>
        </Box>
    )
}
