/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"
import { Button } from "../ui"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getDetailOrder,issueOrderPositionRequest } from "../../store/modules/orders"
import formatPrice from '../../functions/formatPrice';
import {styled as styledMUI} from '@mui/material/styles' 
import PrimaryButton from "../ui/PrimaryButton"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    font-size: 16px;
    line-height: 20px;
    width: 220px;
  }
  @media (max-width: 767px) {
    margin-top: 35px;
    display: block;
    & > button {
      margin-bottom: 10px;
      font-size: 14px;
      padding: 8px 16px;
      width: 100%;
    }
  }
`
const LeftSide = styled.div`
  display: flex;
  & > button {
    font-size: 16px;
    line-height: 20px;
    margin-right: 40px;
  }
  @media (max-width: 767px) {
    display: block;
  }
`

const PaymentButton = styledMUI(PrimaryButton)(()=>({
  width: '220px',
  fontWeight: '600',
}));

const LastBlock = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const order = useSelector(x => x.orders.orderDetail)

  const {
    availableActions,
  } = useSelector(getDetailOrder)
  const user = useSelector(x => x.user)

  const [isLoading, setLoading] = React.useState(false)

  const totalAmount = useSelector(
    x => x.orders.orderDetail.activeCalulations.totalAmount
  )

  const extraPayment = totalAmount > 0


  return (
    <Wrapper>
      <LeftSide />
      {availableActions.includes("PAYMENT") &&
        !extraPayment &&
        order?.data?.salesChannel !== "Собственная розница" && (
          <PaymentButton
            isLoading={isLoading}
            color='secondary'
            onClick={props.onPay}
          >
            {user?.data?.agentId ? "Оплатить заявку" : "Оплатить заявку"}
          </PaymentButton>
        )}
      {availableActions.includes("PAYMENT") &&
        !extraPayment &&
        order?.data?.salesChannel === "Собственная розница" && (
          <PaymentButton
            isLoading={isLoading}
            color='secondary'
            onClick={() =>
              dispatch(
                issueOrderPositionRequest({
                  orderNumber: order?.data.orderNumber,
                  orderPositionId: order?.data?.orderPositions[0].id
                })
              )
            }
          >
            Выписать заявку
          </PaymentButton>
        )}
      {extraPayment && (
        <PaymentButton
          isLoading={isLoading}
          color='secondary'
          onClick={props.onPay}
        >
          Доплатить {formatPrice(totalAmount)}
        </PaymentButton>
      )}
    </Wrapper>
  )
}

export default LastBlock
