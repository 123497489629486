import React, { useEffect, useState } from 'react'

import './style.css'
import { format, parseISO } from 'date-fns'
import ForwardFlightSelect from './ForwardFlightSelect'
import { isEmpty } from 'lodash'
import BackwardFlightSelect from './BackwardFlightSelect'
import Baggage from './Baggage'
import useBookingStore from '../../store'
import TransferFlight from './TransferFlight/TransferFlight'
import EventSeatOutlinedIcon from '@material-ui/icons/EventSeatOutlined';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import Boarding from "../Boarding";

const AviaBlock = ({ flights, tour }) => {
	const { forwardFlightId, backwardFlightId } = useBookingStore()
	const actions = useBookingStore(({ actions }) => actions)
	const paxes = useBookingStore(state => state.paxes.filter(pax => pax.person.ageGroup !== 3))

	const [openBoarding, setOpenBoarding] = useState(false)
	const [boardingData, setBoardingData] = useState({ freight: null, dateFreight: null })

	const closeBoarding = () => {
		setOpenBoarding(false)
	}

	const getBoarding = (id, date) => {
		setBoardingData({ freight: id, dateFreight: date })
		setOpenBoarding(true)
	}

	useEffect(() => {
		actions.setSelectedFlightId()
	}, [forwardFlightId, backwardFlightId, actions])

	const fullBoardingForward = () => {
		const seatCountForwardFlight = paxes.filter(pax => pax.selectedFlightsSeats.some(item => item.freight === Number(forwardFlightId)))
		return seatCountForwardFlight.length === paxes.length
	}

	const fullBoardingBackward = () => {
		const seatCountBackwardFlight = paxes.filter(pax => pax.selectedFlightsSeats.some(item => item.freight === Number(backwardFlightId)))
		return seatCountBackwardFlight.length === paxes.length
	}

	const isFullBoardingBackward = fullBoardingBackward()
	const isFullBoardingForward = fullBoardingForward()

	return (
		<>
			<div className='block-header'>
				<h3 className='title'>Транспорт</h3>
			</div>
			{isEmpty(flights) ? (
				<div className='no-flights'>Нет доступных перелетов</div>
			) : flights[0].forwardFlight.segments.length === 1 ? (
				<div className='block-body'>
					<div className='grid-avia header'>
						<div>Рейс</div>
						<div>Дата</div>
						<div>Кол-во</div>
						<div></div>
					</div>
					<div className='grid-avia body'>
						<div className='select-flight'>
							<ForwardFlightSelect
								value={forwardFlightId.replace('.3', '')}
								options={flights}
								title='Туда'
								onChange={actions.changeForwardFlights}
							/>
						</div>
						<div className='flight-date'>
							{format(parseISO(tour.accommodation.beginDate), 'dd.MM.yyyy')}
						</div>
						<div className='flight-paxes'>{tour.paxes.filter(pax => pax.person.ageGroup !== 3).length}</div>
						<div style={{ display: 'flex', alignItems: "center" }}>
							{flights.find(item => item.forwardFlight.id === forwardFlightId.toString())?.forwardFlight.boardingAvailable &&
								(!isFullBoardingForward ? <EventSeatOutlinedIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(forwardFlightId, tour.accommodation.beginDate)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/> : <EventSeatIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(forwardFlightId, tour.accommodation.beginDate)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/>)}
						</div>
					</div>
					<div className='baggage-info'>
						<Baggage
							baggage={
								flights.find(item => item.forwardFlight?.id === forwardFlightId)
									?.forwardFlight?.segments[0]
							}
						/>
					</div>
					<div className='grid-avia body'>
						<div className='select-flight'>
							<BackwardFlightSelect
								value={backwardFlightId.replace('.3', '')}
								options={flights}
								title='Обратно'
								onChange={actions.changeBackwardFlights}
							/>
						</div>
						<div className='flight-date'>
							{format(parseISO(tour.accommodation.endDate), 'dd.MM.yyyy')}
						</div>
						<div className='flight-paxes'>{tour.paxes.filter(pax => pax.person.ageGroup !== 3).length}</div>
						<div style={{ display: 'flex', alignItems: "center" }}>
							{flights.find(item => item.backwardFlight.id === backwardFlightId.toString())?.backwardFlight.boardingAvailable &&
								(!isFullBoardingBackward ? <EventSeatOutlinedIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(backwardFlightId, tour.accommodation.endDate)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/> : <EventSeatIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(backwardFlightId, tour.accommodation.endDate)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/>)}
						</div>
					</div>
					<div className='baggage-info'>
						<Baggage
							baggage={
								flights.find(
									item => item.backwardFlight?.id === backwardFlightId
								)?.backwardFlight?.segments[0]
							}
						/>
					</div>
				</div>
			) : (
				<TransferFlight flight={flights[0]} />
			)}
			{openBoarding && <Boarding dateFreight={boardingData.dateFreight} freight={Number(boardingData.freight)} onClose={closeBoarding} />}
		</>
	)
}

export default AviaBlock
