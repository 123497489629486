import { create } from 'zustand'

export const useForwardFlightStore = create((set, get) => ({
	forwardFlightStart: '00:00',
	forwardFlightEnd: '24:00',
	setForwardFlightDate: date => {
		set({ forwardFlightStart: date[0], forwardFlightEnd: date[1] })
	}
}))

export const useBackwardFlightStore = create((set, get) => ({
	backwardFlightStart: '00:00',
	backwardFlightEnd: '24:00',
	setBackwardFlightDate: date => {
		set({ backwardFlightStart: date[0], backwardFlightEnd: date[1] })
	}
}))

export const useAviaCompanyStore = create((set, get) => ({
	currentAviaCompany: '',
	setCurrentAviaCompany: name => {
		set({ currentAviaCompany: name})
	}
}))

