import React from 'react';
import PdfIcon from "../../../asset/icons/Pdf-icon";
import DeleteIcon from "../../../asset/icons/Delete-icon";
import useErskStore from "../store";

const Document = ({ file }) => {

	const actions = useErskStore(({ actions }) => actions)

	return (
		<div className={'upload-document'}>
			<PdfIcon />
			<div className={'file-name'}>{file.fileName}</div>
			<DeleteIcon onClick={() => actions.deleteFile(file.id)} />
		</div>
	);
};

export default Document;