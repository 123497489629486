import React from "react"
import styled from "styled-components"
import Segment from "./Segment"
import {Text} from "../../../../ui"

const Body = styled.div`
  & > div {
    margin-bottom: 30px;
    border-bottom: 1px solid #DCDCDC;
  }
  & > :last-child {
    border-bottom: 0;
  }
`
const Header = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;

  & > strong {
    color: #4872F2;
  }
  margin-bottom: 20px;
`

const Flight = ({ segments, className, ...props }) => {
  return (
    <>
      <Header fontWeight="600" size={18} color="dark-gray">
        Детали маршрута:{" "}
        <strong>
          {props.departureCity?.name} — {props.arrivalCity?.name}
        </strong>
      </Header>
      <Body>
        {segments.map((segment, key) => (
          <Segment key={key} {...segment} />
        ))}
      </Body>
    </>
  )
}

export default Flight
