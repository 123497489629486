import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useGeoStore from "../LandingPage/store";

const ExpertNews = () => {

	const {alias} = useParams()
	const actions = useGeoStore(({ actions }) => actions)
	const [content, setContent] = useState('')

	useEffect(() => {
		actions.getOneNewsPage(alias).then(res => {
			console.log(JSON.parse(res.blocks[2].content))
			setContent(JSON.parse(res.blocks[0].content))
		})
		// eslint-disable-next-line
	}, [])
	return (
		<div className={'container'}>
			<div dangerouslySetInnerHTML={{__html: content.text}} />
		</div>
	);
};

export default ExpertNews;