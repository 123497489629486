import React, {useState} from 'react';
import './style.scss'
import PassengerItem from "./components/PassengerItem";
import {Divider} from "@material-ui/core";
import {useSelector} from "react-redux";
import {aviaPassengersSelector, getUserDataState} from "../../../store/modules/user";
import {Outlet, useNavigate} from 'react-router-dom'
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';

const Input = React.forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

const InputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 20px;
  border-radius: 4px;
  color: #1C2025;
  background: '#FFFFFF';
  border: 1px solid #DAE2ED;
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.05);

  &:hover {
    border-color: #4872F2;
  }

  &:focus {
    border-color: #3399FF;
    box-shadow: 0 0 0 3px #B6DAFF;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }`,
);

const MyPassengers = () => {
  const [search, setSearch] = useState('')
  const passengers = useSelector(aviaPassengersSelector)
  const navigateTo = useNavigate();
  const user = useSelector(getUserDataState);
  const isViewManager = user?.role === 'ViewManager';

  return (
    <>
      {isViewManager && <h1>Данный раздел вам не доступен</h1>}
      {(!isViewManager && !!user) && <>
        <div className={'my-passengers-title'}>Мои пассажиры</div>
        <div className={'my-passengers-add-search-block'}>
          <div className={'my-passengers-add-btn'}>
            <div className={'my-passengers-add-icon'} />
            <div className={'my-passengers-add-text'}
                 onClick={() => navigateTo('/cabinet/my-passengers/create')}
            >Добавить нового</div>
          </div>
          <Input aria-label="filter input"
                 placeholder="Поиск по имени/номеру документа…"
                 onChange={e => setSearch(e.target.value)}
          />
        </div>
        <Outlet/>
        <Divider style={{ color: '#DCDCDC' }} />
        {passengers
          .filter(el => {
            if (search.toLowerCase() === '') {
              return el
            } else {
              return el.firstName?.toLowerCase().includes(search) ||
                el.lastName?.toLowerCase().includes(search) ||
                el.patronymic?.toLowerCase().includes(search) ||
                el.documents.some(doc => doc.number.includes(search))
            }
          })
          .map((item, idx) => {
            return (
              <PassengerItem
                key={item.customerPassengerId}
                item={item}
                idx={idx}
              />
            )
          })}
      </>}
    </>
  )
}

export default MyPassengers
