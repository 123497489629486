import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs, Mousewheel, Pagination } from 'swiper/modules';

import './style.css'
import {isEmpty} from "lodash";
import emptyImage from "../../../asset/images/hotelRoomPlug.svg";
import useHotelDescriptionStore from "../store";
import { useMediaQuery } from 'react-responsive'

const RoomSlider = ({ room }) => {

	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)

	let images = room.images.map(item => {
		return hotel.images.find(image => image.id === item.id)
	})

	images = images.filter(item => item)

	const createLinkToImage = (data) => {
		if (isEmpty(images)) {
			return emptyImage
		} else {
			return `https://apigate-tui.fstravel.com/api/geocontent/static/${hotel.objectType}/${hotel.id}/${data.imageType}-${data.resolutionType}-${data.fileName}`
		}
	}
	const isMobile = useMediaQuery({maxWidth: 576})

	return (
		<>
			{!isMobile && <Swiper
				onSwiper={setThumbsSwiper}
				spaceBetween={10}
				slidesPerView={6}
				freeMode={true}
				mousewheel={true}
				// navigation={true}
				// navigation={{
				// 	prevEl: ".swiper-button-prev-1",
				// 	nextEl: ".my__slider .swiper-button-next.icon-next",
				// }}
				watchSlidesProgress={true}
				modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
				className="slider-thumbs"
				direction={'vertical'}
				watchOverflow={true}
			>
				{images.map(item => <SwiperSlide key={room.name}>
					<img src={createLinkToImage(item)} alt={room.name}/>
				</SwiperSlide>)}
			</Swiper>}
			<Swiper
				style={{
					'--swiper-navigation-color': '#fff',
					'--swiper-pagination-color': '#fff',
				}}
				spaceBetween={10}
				navigation={!isMobile}
				thumbs={{ swiper: thumbsSwiper }}
				pagination={{ clickable: true }}
				watchSlidesProgress
				modules={[FreeMode, Navigation, Thumbs, Pagination]}
				className="slider-big"
			>
				{images.map(item => <SwiperSlide key={item.fileName} >
					<img src={createLinkToImage(item)} alt={room.name}/>
				</SwiperSlide>)}
			</Swiper>
		</>

	);
};

export default RoomSlider;