import React from "react";
import { format } from "date-fns";

import "./style.css";
import { encodeQueryString } from "../../../../functions/sequelize";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@material-ui/core/styles";

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 12,
		fontWeight: 400,
		padding: "12px",
	},
	[`& .${tooltipClasses.arrow}`]: {
		"&::before": {
			color: "white",
			boxShadow:
				"rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
		},
	},
}));

const Offer = ({ offer }) => {
	const getLink = () => {
		const searchParams = {
			clientId: "b2b:ru",
			lang: "ru",
			departureCityId: offer.accent.city_id,
			arrivalCountryId: offer.accent.countryEbgId,
			adults: 2,
			minStartDate: offer.accent.date,
			maxStartDate: offer.accent.date,
			minNightsCount: offer.accent.days,
			maxNightsCount: offer.accent.days,
			hotelIds: [offer.accent.hotelEbgId],
			currencyId: 3,
		};
		return "/search_tour?" + encodeQueryString(searchParams);
	};

	const queryString = getLink();

	return (
		<div className={"accent-offer"}>
			<div className={"accent-offer_column accent-offer_date"}>
				{format(new Date(offer?.checkinDate), "dd.MM")}
			</div>
			<div className={"accent-offer_column accent-offer_nights"}>
				{offer.daysCount} ночей
			</div>
			<div className={"accent-offer_column accent-offer_hotel"}>
				<a
					href={`https://agentb2b.fstravel.com/hotel/${offer.hotelId}`}
					target={"_blank"}
					rel={"noreferrer"}
					title={`${offer.hotelName} ${offer.stars ? offer.stars + "*" : ""}`}
				>
					{offer.hotelName} {offer.stars ? offer.stars + "*" : ""}
				</a>
			</div>
			<div className={"accent-offer_column accent-offer_icon"}>
				{offer?.accent?.icon && (
					<LightTooltip
						title={offer?.accent?.tag?.name}
						placement={"top"}
						arrow
					>
						<picture>
							<source srcSet={offer.accent.icon} type="image/svg+xml" />
							<img src={offer.accent.icon} alt={""} />
						</picture>
					</LightTooltip>
				)}
			</div>
			<div className={"accent-offer_column accent-offer_description"}>
				{offer.accent.description}
			</div>
			<div className={"accent-offer_column accent-offer_price"}>
				от {Math.ceil(offer.price.value / 2).toLocaleString("ru", {})} ₽
			</div>
			<div className={"accent-offer_column accent-offer_book"}>
				<a href={queryString} target={"_blank"} rel={"noreferrer"}>
					Забронировать
				</a>
			</div>
		</div>
	);
};

export default Offer;