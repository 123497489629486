import { Icon } from '@mui/material'
import styled from 'styled-components';
import {useEffect} from "react";
import useAuthStore from "../../pages/Auth/store";


const StyledLink = styled.a`
	display: flex;
	align-items: center;
	min-height: 56px;
  	color: #B3B9C0;

	&.active {
		color: rgb(60, 60, 60);
		opacity: 1;
		background-color: rgb(237, 241, 254);
    	img {
        	opacity: 1;
    	}
	}
  
	&:hover {
		img {
        	opacity: 1;
		}
	}
`

const StyledIcon = styled(Icon)`
  	img {
      opacity: .5;
	}
`

const LinkContent = styled.span`
	margin-left: 16px;
    display: flex;
    align-items: center;
`

const SamoLink = ({icon, label, value, styles, ...props}) => {

	const actions = useAuthStore(({ actions }) => actions)
	const agent = useAuthStore(({ agent }) => agent)
	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)

	useEffect(() => {
		if (agent) {
			actions.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer')
		}
		// eslint-disable-next-line
	}, [agent])

	const refreshLink = event => {
		event.preventDefault()
		actions.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer').then(() => {
			window.open(samoLinks['cl_refer'], '_blank', 'noreferrer')
		})
	}

	return (
		<StyledLink
			{...props}
			href={samoLinks['cl_refer'] || 'https://b2b.fstravel.com/cl_refer'}
			onClick={refreshLink}
			rel={'noreferrer'}
			style={{display: 'flex'}}
		>
			<LinkContent>
				<StyledIcon style={{marginRight:"15px", marginBottom:"0px"}}>
					<img alt='' src={icon} />
				</StyledIcon>
				<span style={styles} dangerouslySetInnerHTML={{__html: label}} />
			</LinkContent>
		</StyledLink>
	);
}

export default SamoLink;
