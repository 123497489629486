import React from "react"
import styled from "styled-components"
import { Text, Button as BaseButton } from "../../../ui"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../../asset/images/avia/ui/close.svg'

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 588px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-overlay {
    overflow: scroll;
    padding: 20px 0;

    @media (max-width: 767px) {
      z-index: 1000 !important;
      padding: 0;
    }
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;
  display: block;
  margin-bottom: 20px;
`

const Question = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
  text-align: left;
`

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

const Button = styled(BaseButton).attrs({
  type: "outlined",
  htmlType: "button"
})`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

export default function ConfirmCancelationModal({ onConfirm, ...props }) {
  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="Закрыть окно"
        onClick={() => {
          typeof props.onClose === "function" && props.onClose()
        }}
      >
        <Icon/>
      </CloseButton>
      <Title>Отклонение изменений</Title>
      <Question>
        Вы уверены, что хотите отклонить изменения в вашем перелете?
      </Question>
      <ButtonBlock>
        <Button onClick={onConfirm}>Да, отклонить изменения </Button>
        <Button
          onClick={() => {
            typeof props.onClose === "function" && props.onClose()
          }}
        >
          Нет, вернуться к заявке
        </Button>
      </ButtonBlock>
    </StyledPopup>
  )
}
