import React from 'react';
import { Button,Checkbox,Text } from '../ui';
import styled from 'styled-components';
import { Link } from '../ui/Typography';
import { useSelector } from 'react-redux';
// import ReactGA from 'react-ga4';
import {
  getUpt,
  getSelectedFare,
  bookingInsuranceSelector,
} from '../../store/modules/booking';
import { FareConditionModal,CharterTariffsModal } from '../modals';
import { useFormikContext } from 'formik';
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../ui/PrimaryButton';

const Wrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;

  & > :first-child {
    margin-right: 8px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    width: 230px;
  }

  @media (max-width: 767px) {
    display: block;
    & > button {
      width: 100%;
    }
  }
`;

const StyledLink = styled(Link)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const PromoContainer = styled.div`
  display: flex;
`;

const StyledButton = MUIstyled(PrimaryButton)(()=>({
  fontWeight: '600'
}))

const PaymentBlock = () => {
  const [popup, setPopup] = React.useState(false);
  const upt = useSelector(getUpt);
  const { name } = useSelector(getSelectedFare);
  const insurance = useSelector(bookingInsuranceSelector);

  const {
    handleChange,
    errors,
    values,
    submitCount,
    validateForm,
    setFieldValue,
  } = useFormikContext();

  const isInsurancesChecked = insurance.list.offers.some(
    (item) => item.checked
  );

  React.useEffect(() => {
    setFieldValue('isInsurancesChecked', isInsurancesChecked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInsurancesChecked]);

  return (
    <Wrapper>
      <CheckboxWrapper style={{ marginBottom: 10 }}>
        <Checkbox
          type="squar"
          checked={values.offerta}
          name="offerta"
          error={errors.offerta && submitCount > 0 ? true : false}
          onChange={handleChange}
        />{' '}
        <StyledText
          color={errors.offerta && submitCount > 0 ? 'dark-gray' : 'dark-gray'}
        >
          Я ознакомлен и согласен с{' '}
          <StyledLink href="/docs/oferta.pdf" target="_blank">
            договором публичной оферты
          </StyledLink>
          {!values.isCharter && (
            <>
              <StyledText
                color="blue"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                , условиями применения тарифов
              </StyledText>{' '}
            </>
          )}{' '}
          и{' '}
          <StyledLink href="/docs/police.pdf" target="_blank">
            политикой в отношении обработки персональных данных
          </StyledLink>
          .
        </StyledText>
      </CheckboxWrapper>
      {isInsurancesChecked && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.offertaWithInsurances}
            name="offertaWithInsurances"
            error={
              errors.offertaWithInsurances && submitCount > 0 ? true : false
            }
            onChange={handleChange}
          />{' '}
          <StyledText style={{ color: '#737373' }}>
            Я ознакомлен(а) и согласен(а) с договором публичной оферты и
            правилами выбранного страхового продукта.
          </StyledText>
        </CheckboxWrapper>
      )}

      {values.isCharter && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.charterTariffs}
            name="charterTariffs"
            error={errors.charterTariffs && submitCount > 0 ? true : false}
            onChange={handleChange}
          />{' '}
          <StyledText>
            Я согласен(а) с{' '}
            <StyledText
              color={'blue'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPopup(true);
              }}
            >
              условиями применения тарифов перевозки.
            </StyledText>{' '}Я предупреждён, что в исключительных случаях
            возможна замена перевозчика, типа воздушного судна на
            аналогичные услуги без взимания какой-либо доплаты.
          </StyledText>
        </CheckboxWrapper>
      )}

      <CheckboxWrapper>
        <Checkbox
          type="squar"
          checked={values.emailOfferta}
          name="emailOfferta"
          error={errors.emailOfferta && submitCount > 0 ? true : false}
          onChange={handleChange}
        />{' '}
        <StyledText
          color={
            errors.emailOfferta && submitCount > 0 ? 'dark-gray' : 'dark-gray'
          }
        >
          Я согласен(а) на получение email-рассылки.
        </StyledText>
      </CheckboxWrapper>
      <Container>
        <PromoContainer>
        </PromoContainer>
        <StyledButton
          type="submit"
          color="secondary"
          onClick={async () => {
            const curErrors = await validateForm();
            const curErrorsKeys = Object.keys(curErrors);

            if (curErrorsKeys.length) {
              const key = curErrorsKeys[0];
              if (key === 'offerta') return;

              const el = document.getElementById(
                key === 'passengers' ? key : 'contacts'
              );

              if (el) {
                const offset = el.offsetTop;
                window.scrollTo({
                  top: offset,
                  behavior: 'smooth',
                });
              }
            }
          }}
        >
          Забронировать
        </StyledButton>
      </Container>
      {values.isCharter ? (
        <CharterTariffsModal open={popup} onClose={() => setPopup(false)} />
      ) : (
        <FareConditionModal
          open={popup}
          onClose={() => {
            setPopup(false);
          }}
          routes={upt}
          fare={name}
        />
      )}
    </Wrapper>
  );
};

export default PaymentBlock;
