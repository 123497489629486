import React, { useState } from 'react';
import styled,{keyframes} from 'styled-components';
import {Text,InfoBlock,Separator} from '../ui';
import formatPrice from '../../functions/formatPrice';
import { useSelector, useDispatch } from 'react-redux';
import { getPriceDetail,setAgentCommissionVisibility } from '../../store/modules/booking';

const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const DetailContent = styled.div`
  margin-top: 24px;
  & > div, & > button {
    margin-bottom: 22px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 10px;
    }
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 20px;
    font-weight: 600;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;


const StyledTextBig = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledTextBigBlue = styled(StyledTextBig)`
  color: #4872F2;
`;

const show = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const hide = keyframes`
  to {
    stroke-dashoffset: 23;
  }
`;

const ActionButton = styled.button`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: none;
  background: transparent;
  padding: 0;
  display: flex; 
  align-items: center;
  color: #4872F2;

  & > svg {
    margin-right: 5px;
  }

  .tte-svg-eye {
    .tte-svg-eye-line {
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      &.hide {
        stroke-dashoffset: 0;
        animation: ${hide} 0.3s linear forwards;
      }
      &.show {
        animation: ${show} 0.3s linear forwards;
      }
    }
  }
`;

const PriceDetail = () => {
  const { total, subagentTotal, detailList,hideAgentCommission } = useSelector(getPriceDetail);
  const dispatch = useDispatch();

  const [isOpen] = useState(true);

  const handleActionButtonClick = () => {
    dispatch(setAgentCommissionVisibility(!hideAgentCommission));
  }

  const agentCommission = detailList.find(x => x.type === 'subagent-cost');

  return (
    <InfoBlock>
      <Head>
        <HeaderWrapper>
          <Title size={24} style={{ marginRight: 10 }}>
            Стоимость заявки
          </Title>
        </HeaderWrapper>
        <Title size={24} fontWeight="600" color="blue" bold>
          {formatPrice(total)}
        </Title>
      </Head>
      {isOpen && (
        <DetailContent>
          {[...detailList].sort((a, b) => typeToNumber(a.type) - typeToNumber(b.type)).filter(x => x.type !== 'subagent-cost').map((value) => (
            <DetailRow key={value.id}>
              <StyledText>{value.description}</StyledText>
              <StyledText>
                {value.quantity > 1 && `${value.quantity} X `}
                {formatPrice(value.cost)}
              </StyledText>
            </DetailRow>
          ))}
        <ActionButton type='button' onClick={handleActionButtonClick}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="tte-svg-eye">
          <g id="Frame">
          <path id="Vector" d="M15.9424 8.2215C16.2979 8.6865 16.2979 9.31425 15.9424 9.7785C14.8226 11.2403 12.1361 14.25 8.99964 14.25C5.86314 14.25 3.17664 11.2403 2.05689 9.7785C1.88393 9.55585 1.79004 9.28194 1.79004 9C1.79004 8.71806 1.88393 8.44415 2.05689 8.2215C3.17664 6.75975 5.86314 3.75 8.99964 3.75C12.1361 3.75 14.8226 6.75975 15.9424 8.2215Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
          <path stroke="#4872F2" d="M3 15L15 3"  stroke-linecap="round" stroke-linejoin="round" className={`tte-svg-eye-line ${!hideAgentCommission ? 'show': 'hide'}`}/>
          <path id="Vector_2" d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          </svg>
          <span>{hideAgentCommission ? 'Открыть' : 'Скрыть'} детали для агента</span>
        </ActionButton>
        {agentCommission && !hideAgentCommission && (
          <DetailRow>
          <StyledText style={{ fontWeight: 600 }}>{agentCommission.description}</StyledText>
          <StyledText style={{ fontWeight: 600 }}>
            {agentCommission.quantity > 1 && `${agentCommission.quantity} X `}
            {formatPrice(agentCommission.cost)}
          </StyledText>
        </DetailRow>
        )}
        {subagentTotal && !hideAgentCommission && (
          <>
            <Separator />
            <DetailRow>
              <StyledTextBig>Сумма оплаты для агента</StyledTextBig>
              <StyledTextBigBlue>{formatPrice(subagentTotal)}</StyledTextBigBlue>
            </DetailRow>
          </>
        )}
        </DetailContent>
      )}
    </InfoBlock>
  );
};

function typeToNumber(type) {
  return type === 'subagent-cost' ? 1 : 0;
}

export default PriceDetail;
