import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { getAgentInfo } from "../../../store/modules/agents/agent-finance/selectors"
import Skeleton from '@mui/material/Skeleton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  //width: 750px;
`

const Title = styled.span`
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 13px;
    line-height: 22px;
  }
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 13px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
    flex-direction: row;
  }
`

const Header = styled.p`
  margin: 0;
  margin-bottom: 20px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
`

const FlexColumnRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 0;
    align-items: baseline;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 13px;
  }
`

const Line = styled.div`
  height: 46px;
  background: #dcdcdc;
  width: 2px;
  margin-right: 50px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const LoadingView = () => (
  <Container>
    <Header>
      <Skeleton width={210} sx={{ fontSize: '18px', lineHeight: '120%' }}/>
    </Header>
    <FlexColumnRow>
        <Column>
          <Skeleton width={80} sx={{ fontSize: '14px', lineHeight: '18px' }}/>
          <Skeleton width={80} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
        </Column>
        <Line> </Line>
        <Column>
          <Skeleton width={80} sx={{ fontSize: '14px', lineHeight: '18px' }}/>
          <Skeleton width={80} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
        </Column>
        <Line> </Line>
        <Column>
          <Skeleton width={80} sx={{ fontSize: '14px', lineHeight: '18px' }}/>
          <Skeleton width={80} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
        </Column>
      </FlexColumnRow>
  </Container>
)

const AgentInfoBlock = () => {
  const data = useSelector(getAgentInfo)
  if(!data) {
    return <LoadingView/>;
  }
  
  return (
    <Container>
      <Header>
        {data?.name}, ИНН: {data?.inn}
      </Header>
      <FlexColumnRow>
        <Column>
          <SubTitle>группа ТТЕ:</SubTitle>
          <Text>{data?.group?.name}</Text>
        </Column>
        <Line> </Line>
        <Column>
          <SubTitle>наличие в ТТЕ:</SubTitle>
          <Text>{data?.active ? "Включен" : "Выключен"}</Text>
        </Column>
        <Line> </Line>

        <Column>
          <SubTitle>ID в самотуре:</SubTitle>
          <Text>{data?.externalId}</Text>
        </Column>
      </FlexColumnRow>
    </Container>
  )
}

export default AgentInfoBlock
