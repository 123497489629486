import { Icon } from '@mui/material'
import React from 'react'

export default function HotelIcon({ menuIconSVG }) {
	return (
		<Icon style={{ marginRight: '10px', marginBottom: '0px' }}>
			<img alt='' src={menuIconSVG}></img>
		</Icon>
	)
}
