import {
    useEffect,
    // useEffect, 
    useState
} from "react"
import WorkTime from "../WorkTime"
import useVisaStore from "../../store"
import VisaCountries from "./VisaCountries"
import {
    isEmpty, orderBy,
    // orderBy
} from "lodash"
import VisaCalculator from "../VisaCalculator";
import VisaCalculatorShedule from "../VisaCalculator/VisaCalculatorShedule";
import {Box} from "@mui/material";

function VisaMain({ content }) {
    const [activeTab, setActiveTab] = useState("t1")
    // const countries = useVisaStore(({ countries }) => countries)
    const countriesList = useVisaStore(({ countriesList }) => countriesList)

    const filteredCountries = countriesList.filter(i => content?.available?.some(e => i?.urlName?.split('/').pop() === e))

    const {hash} = window.location

    useEffect(() => {
        if (window.location.hash === '#calculator') {
            setActiveTab('t3')
        }
    }, [hash])

    return <div className="visaTabs">
        <div className="tabs-head">
            <div className={activeTab === 't1' ? 'tabs-item active' : 'tabs-item'} onClick={() => setActiveTab('t1')}>Визовые страны</div>
            <div className={activeTab === 't2' ? 'tabs-item active' : 'tabs-item'} onClick={() => setActiveTab('t2')}>Безвизовые страны </div>
            <div className={activeTab === 't3' ? 'tabs-item active' : 'tabs-item'} onClick={() => setActiveTab('t3')}>Расчет срока действия паспорта/Крайние сроки</div>
        </div>
        {activeTab !== 't3' && <div>
            <div dangerouslySetInnerHTML={{ __html: content.welcome[activeTab] }} />
            <WorkTime content={content.workTime[activeTab]} />
        </div>}
        {!isEmpty(filteredCountries) && activeTab !== 't3' && <VisaCountries
            content={activeTab === 't1' ? filteredCountries.filter(i => i.isVisaRequired) : filteredCountries.filter(i => !i.isVisaRequired)}
            title={activeTab === 't1' ? 'Визовые страны' : 'Безвизовые страны'}
        />}
        {activeTab === 't3' && <Box mb={4} sx={{display: 'flex'}} className={'visa-calc__block'}>
            <VisaCalculator countries={orderBy(filteredCountries, 'name')} />
            <VisaCalculatorShedule countries={orderBy(filteredCountries, 'name')} />
        </Box>}
    </div>
}

export default VisaMain