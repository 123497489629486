import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Button, ButtonLoader} from '../../ui/Button';
import {Checkbox} from '../../ui/form';
import Popup from 'reactjs-popup';
import AsyncSelect from 'react-select/async';
import Api from "../../../functions/fetchApi";
import {useMediaQuery} from 'react-responsive'
import {CountryNameWithFlag} from "../PassengersBlock/Item";

const SELECTED_DOC = {
  RussianFederationPassport: { value: 'PASSPORT', label: 'Паспорт РФ' },
  InternationalPassport: {
    value: 'INTERNATIONAL_PASSPORT',
    label: 'Заграничный паспорт',
  },
  BirthCertificate: {
    value: 'BirthCertificate',
    label: 'Свидетельство о рождении',
  },
  NationalPassport: {
    value: 'NATIONALPASSPORT',
    label: 'Национальный паспорт',
  },
  Other: {
    value: 'OTHER',
    label: 'Другой документ',
  },
};

const CONVERTED_DOCS = {
  RussianFederationPassport: { value: 'PASSPORT', label: 'Паспорт РФ' },
  InternationalPassport: {
    value: 'INTERNATIONAL_PASSPORT1',
    label: 'Заграничный паспорт',
  },
  NationalPassport: {
    value: 'NATIONALPASSPORT',
    label: 'Национальный паспорт',
  },
  BirthCertificate: {
    value: 'BIRTH_CERTIFICATE',
    label: 'Свидетельство о рождении',
  },
  Other: {
    value: 'OTHER',
    label: 'Другой документ',
  },
};
async function getCountriesList(value) {
  try {
    return await Api
      .get(process.env.REACT_APP_COUNTRIES_SEARCH)
      .setContentType('application/json')
      .query({value})
      .send();
  } catch (e) {
    // console.log(e);
  }
}

const countriesList = async (str) => {
  const response = await getCountriesList(str);
  return response
    ? response.data.map((country) => ({
      label: CountryNameWithFlag(country),
      value: country.isoCode,
    }))
    : [];
};

async function getPassengers (SearchText) {
  try {
    return Api.get(process.env.REACT_APP_GET_USER_PASSANGERS)
      .query({
        PageSize: 1000,
        SearchText
      })
      .setContentType('application/json')
      .addAuthGuard()
      .send()
  } catch (e) {
    console.log(e)
  }
}

const StyledPopup = styled(Popup)`
  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    //padding: 40px 0px;
    box-sizing: border-box;
    padding: 0 40px;
    background-color: #FFFFFF;
    min-height: 355px;
    width: 569px;

    @media (max-width: 767px) {
      height: 100%;
      width: 100%;
      padding: 25px 20px;
    }
  }
`;

// const SelectWrapper = styled.div`
//   margin: 35px 0px 22px 0px;
// `;

const Header = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  @media (max-width: 767px) {
    font-weight: 600;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const SelectButton = styled(Button)`
  width: 330px;
  margin: 35px auto;

  @media (max-width: 767px) {
    width: 90%;
  }
`;

const Row = styled.div`
  margin-bottom: 14px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 21px;
  top: 17px;
  outline: none;

  &:hover {
    &:before,
    &:after {
      background-color: #737373;
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: #4872F2;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const SubText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #3c3c3c;
  margin: 20px 0 0 0;
`;

const PassengerAutoSelectModal = ({
  isOpen,
  selectPassanger,
  onClose,
  autoSelectInProgress,
  passengers,
  setProgress,
  oneOfFlightsIsCharter,
  setCitizenAbsolute,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);
  const isMobile = useMediaQuery({maxWidth: 767})

  const [selectedPassenger, selectPassenger] = React.useState({
    customerPassengerId: passengers[0] ? passengers[0].customerPassengerId : '',
    document:
      oneOfFlightsIsCharter &&
      oneOfFlightsIsCharter.length > 0 &&
      passengers[0]?.documents[0].type === 'RussianFederationPassport'
        ? ''
        : passengers[0]?.documents[0]?.id,
    documentType: passengers[0]?.documents[0]?.type,
  });

  const currentPassenger = passengers.find(x => x.customerPassengerId === selectedPassenger.customerPassengerId)

  const documents = currentPassenger?.documents;

  const options = passengers.map(x => {
    const current = {
      label: `${x.lastName} ${x.firstName} ${
        x?.patronymic ? x.patronymic : ''
      }`,
      id: x.customerPassengerId,
      value: x.customerPassengerId,
    };

    return current;
  });

  const selectedOption = React.useMemo(() =>
      options.find(value => value.value === selectedPassenger.customerPassengerId), [options, selectedPassenger]
  );

  React.useEffect(() => {
    const check = passengers.find(
      (item) =>
        item.customerPassengerId === selectedPassenger.customerPassengerId &&
        item?.documents.find((y) => y?.id === selectedPassenger?.document)
    );

    if (!check && !currentPassenger) {
      selectPassenger({
        ...selectedPassenger,
        customerPassengerId: passengers[0]
          ? passengers[0].customerPassengerId
          : '',
        document:
          oneOfFlightsIsCharter &&
          oneOfFlightsIsCharter.length > 0 &&
          passengers[0]?.documents[0].type === 'RussianFederationPassport'
            ? ''
            : passengers[0]?.documents[0]?.id,
      });
    } else if (!check) {
      selectPassenger({
        ...selectedPassenger,
        document:
          oneOfFlightsIsCharter &&
          oneOfFlightsIsCharter.length > 0 &&
          passengers[0]?.documents[0].type === 'RussianFederationPassport'
            ? ''
            : currentPassenger?.documents[0]?.id,
    });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformData = (passengerId, docType) => {
    setProgress(true);
    const passenger = passengers.find(x =>
        x.customerPassengerId === passengerId ||
        x.customerPassengerId === selectedPassenger.customerPassengerId
    )
    const document = passenger?.documents.find(
      x => x?.id === docType || x?.id === selectedPassenger.document
    );
    const getCountry = async () => {
      const countries = await countriesList(document?.citizenship || 'Россия');
      if (countries.length) {
        const [a] = countries;
        setCitizenAbsolute && setCitizenAbsolute(a);
      }
    };

    getCountry();

    const documentType = CONVERTED_DOCS[document?.type];
    const lastName = passenger.lastName;
    const name = passenger.firstName;
    const secondName = passenger.patronymic;
    const number = document?.number;
    const dateOfIssue = document?.issueDate
      ? new Date(document?.issueDate)
      : null;
    const birhDate = new Date(passenger.birthDate);
    const sex = passenger.gender === 'MALE' ? 'm' : 'f';
    const secondNameRequired = !!passenger?.patronymic;
    const customerPassengerId = passenger?.customerPassengerId;

    setTimeout(() => {
      selectPassanger({
        documentType,
        lastName,
        name,
        secondName,
        number,
        dateOfIssue,
        birhDate,
        sex,
        secondNameRequired,
        customerPassengerId,
      });
    }, 1000);
  };

  const passengersList = async str => {
    if (/^[0-9]/g.test(str)) return []
    const response = await getPassengers(str);

    return response
      ? response.map(pas => ({
        value: pas,
        label: pas.lastName + ' ' + pas.firstName + ' ' + pas.patronymic,
      }))
      : [];
  };

  return (
    <StyledPopup open={isOpen} onClose={onClose}>
      <Cross onClick={onClose} />
      <Content>
        <Header style={{ textAlign: isMobile ? 'left' : 'center' }} size="h3">
          Выберите пассажира из списка
        </Header>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Label>Пассажир</Label>

          <AsyncSelect
            onChange={(data, ...rest) => {
              !data.id
                ? selectPassenger({
                  ...data.value,
                  customerPassengerId: data.value.customerPassengerId,
                  document: data?.value.documents[0],
                })
                : selectPassenger({
                  ...selectedPassenger,
                  customerPassengerId: data.id,
                  document: data?.document,
                });
            }}
            styles={{
              indicatorSeparator: (provided, state) => ({
                ...provided,
                visibility: 'hidden',
              }),

              input: (provided, state) => ({
                ...provided,
                fontFamily: 'Open Sans',
                color: '#3C3C3C',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: 0,
                paddingLeft: '12px',
              }),
              container: (provided, state) => ({
                ...provided,
                padding: 0,
              }),
              control: (provided, { isFocused, ...state }) => {
                const border = isFocused
                  ? '1px solid #3C3C3C !important'
                  : '1px solid #DCDCDC';

                const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
                const boxShadow = 'none';
                return {
                  ...provided,
                  minHeight: 42,
                  border,
                  borderColor,
                  boxShadow,
                };
              },
              menu: (provided, state) => ({
                ...provided,
                zIndex: 100,
              }),
            }}
            noOptionsMessage={() => 'Ничего не найдено'}
            loadOptions={async (inputValue, callback) => {
              callback(await passengersList(inputValue));
            }}
            defaultOptions={options}
            cacheOptions
            value={selectedOption}
          />
          <SubText>
            Выберите один из доступных документов для бронирования:
          </SubText>
          {/* <SelectWrapper>
            <Select
              label="Пассажиры:"
              onChange={(data: any, ...rest) => {
                selectPassenger({
                  ...selectedPassenger,
                  customerPassengerId: data.id,
                  document: data?.document,
                });
              }}
              withCustomOption
              isSearchable={false}
              value={selectedOption}
              options={options}
            />
          </SelectWrapper> */}
        </div>
        {documents &&
          documents?.map((x, i) => (
            <Fragment key={i}>
              {oneOfFlightsIsCharter &&
              oneOfFlightsIsCharter?.length > 0 &&
              x.type === 'RussianFederationPassport' ? (
                <></>
              ) : (
                <Row key={i}>
                  <Checkbox
                    label={`${SELECTED_DOC[x?.type]?.label} ${x.number}`}
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        selectPassenger({
                          ...selectedPassenger,
                          document: null,
                        });
                        return;
                      }
                      selectPassenger({
                        ...selectedPassenger,
                        document: x.id,
                      });
                    }}
                    checked={x.id === selectedPassenger.document}
                    type="squar"
                  />
                </Row>
              )}
            </Fragment>
          ))}

        <SelectButton
          disabled={!selectedPassenger?.customerPassengerId}
          onClick={() => transformData()}
        >
          <ButtonLoader isLoading={autoSelectInProgress}>
            Продолжить бронирование
          </ButtonLoader>
        </SelectButton>
      </Content>
    </StyledPopup>
  );
};

export default PassengerAutoSelectModal;
