import { useState } from "react";
import StaticBlocks from "../../StaticBlocks";
import ArrowDownSmall from "../../../../asset/icons/ArrowDownSmall";

import "./style.scss";

function Spoiler({ content }) {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const handleClickLink = (link) => {
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <div className="spoiler-tab-container">
            <div className="spoiler-tab-head" onClick={toggle}>
                <div className="spoiler-tab-title-block" onClick={() => handleClickLink()}>
                    <div className="spoiler-tab-title__wrapper">
                        <div className="spoiler-tab-title">{content?.name}</div>
                        {content?.sub_name &&
                            <div className="spoiler-tab-sub-title">{
                                content.sub_name
                            }</div>
                        }
                    </div>
                    <div className="spoiler-tab-last-container">
                        {content?.badges?.map((item) => (
                            <div
                                style={{
                                    backgroundColor: item?.backgroundColor,
                                    color: item?.textColor,
                                }}
                                className="spoiler-tab-badge"
                            >
                                {item?.title}
                            </div>
                        ))}

                        <div className={`spoiler-tab-arrow ${open ? "rotate" : ""}`}>
                            <ArrowDownSmall />
                        </div>
                    </div>
                </div>
            </div>
            {open && (
                <div className="spoiler-tab-content">
                    {<StaticBlocks blocks={content?.blocks} />}
                </div>
            )}
        </div>
    );
}

export default Spoiler;
