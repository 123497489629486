import React, { useEffect, useState } from 'react'

import './style.css'
// import ArrowThink from '../../../asset/icons/Arrow-think'
import useClaimsStore from './store'
// import ClaimList from './ClaimList/index'
// import Claim from './components/Claim'
// import ArrowShort from '../../../asset/icons/Arrow-short'
import { orderBy } from 'lodash'
// import { Button } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'
import { Alert, Stack } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import useAuthStore from '../../Auth/storecopy'
import ClaimFilter from './ClaimFilter'
import { ReactComponent as SortDown } from '../../../asset/images/cabinet/sort-down.svg'
import Claim from './components/Claim'
import Pagination from './Pagination'
import { useLocation } from 'react-router-dom'

const Claims = () => {
	// const [openClaim, setOpenClaim] = useState([])
	const [sort, setSort] = useState({ name: 'claimId', order: 'desc' })
	const [openError, setOpenError] = React.useState(false)

	const actions = useClaimsStore(({ actions }) => actions)
	const claims = useClaimsStore(({ claims }) => claims)
	const loading = useClaimsStore(({ loading }) => loading)
	const loadingClaimError = useClaimsStore(
		({ loadingClaimError }) => loadingClaimError
	)
	const authError = useClaimsStore(({ authError }) => authError)

	const authActions = useAuthStore(({ actions }) => actions)
	const agents = useAuthStore(({ agents }) => agents)

	const claimsCount = useClaimsStore(({ claimsCount }) => claimsCount)
	const page = useClaimsStore(({ page }) => page)
	const pagesLimit = useClaimsStore(({ pagesLimit }) => pagesLimit)

	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const claimId = Number(searchParams.get('claimId'))
	const payStatus = Number(searchParams.get('payStatus'))

	React.useEffect(() => {
		if (claimId) {
			actions.setFilterConfig('searchValue', claimId)
			actions.setFilterConfig('isButtonApply', false)
		}
		if (payStatus) {
			actions.setFilterConfig('paymentStatus', {id: payStatus, title: 'Не оплачена'})
			actions.setFilterConfig('isButtonApply', false)
		}
		// eslint-disable-next-line
	}, [])

	const handleChangeSort = field => {
		if (field === sort.name) {
			setSort({ name: field, order: sort.order === 'asc' ? 'desc' : 'asc' })
			return
		}
		if (field === 'clientName') {
			setSort({ name: field, order: 'asc' })
			return
		}
		setSort({ name: field, order: 'desc' })
	}

	// const handleOpenClaim = id => {
	// 	if (openClaim.includes(id)) {
	// 		setOpenClaim(openClaim.filter(item => item !== id))
	// 	} else {
	// 		setOpenClaim([...openClaim, id])
	// 	}
	// }

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenError(false)
	}

	useEffect(() => {
		setOpenError(true)
		// setOpenClaim(openClaim.filter(item => !loadingClaimError.includes(item)))
		// eslint-disable-next-line
	}, [loadingClaimError])

	useEffect(() => {
		if (authError) {
			authActions.logout()
		}
		actions.clearAuthError()
		// eslint-disable-next-line
	}, [authError])

	useEffect(() => {
		actions.clearClaims()
		// eslint-disable-next-line
	}, [agents])
	const filterConfig = useClaimsStore(state => state.filterConfig)

	const [filteredClaims, setFilteredClaims] = useState([])

	const applyFilter = () => {
		if (!claims) {
			return
		}
		let res = [...claims]

		if (isFinite(filterConfig.searchValue)) {
			res = res?.filter(item => {
				return item.claimId.toString().includes(filterConfig.searchValue)
			})
		} else {
			res = res?.filter(item => {
				return item.clientName
					.toLowerCase()
					.includes(filterConfig.searchValue?.toLowerCase())
			})
		}
		// if (filterConfig.paymentStatus?.id) {
		// 	res = res?.filter(item => {
		// 		return (
		// 			item.paymentStatus.id.toString() === filterConfig.paymentStatus.id
		// 		)
		// 	})
		// }
		// 	// вместо фильтрации полученных значений - новый запрос
		// 	// если изменился статус оплаты
		// 	// actions.getClaims()
		// }
		if (filterConfig.country?.title) {
			res = res?.filter(item => {
				return item.country.title === filterConfig.country.title
			})
		}
		if (filterConfig.managerName?.title) {
			res = res?.filter(item => {
				return item.managerName === filterConfig.managerName.title
			})
		}
		if (filterConfig.operatorName?.title) {
			res = res?.filter(item => {
				return item.operatorName === filterConfig.operatorName.title
			})
		}

		if (filterConfig.arrivalDate) {
			res = res.filter(obj => {
				const objDateBeg = new Date(obj.dateBeg)
				return (
					objDateBeg >= new Date(filterConfig.arrivalDate[0]) &&
					objDateBeg <= new Date(filterConfig.arrivalDate[1])
				)
			})
		}

		if (filterConfig.reservationDate) {
			res = res.filter(obj => {
				const objDateReservation = new Date(obj.reservationDate)
				return (
					objDateReservation >= new Date(filterConfig.reservationDate[0]) &&
					objDateReservation <= new Date(filterConfig.reservationDate[1])
				)
			})
		}
		setFilteredClaims(res)
	}

	React.useEffect(() => {
		// actions.getClaims()
		actions.getReasons()
		// setOpenClaim([])
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		actions.getClaims()
		// eslint-disable-next-line
	}, [page])

	React.useEffect(() => {
		setFilteredClaims(claims)
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [claims])

	// фильтр без нажатия на кнопку
	React.useEffect(() => {
		applyFilter()
		// eslint-disable-next-line
	}, [filterConfig, claims])

	return (
		<div className={'claims'}>
			<h1>Заявки</h1>
			<div className={'claims-sort'}>
				<div
					className={`sort-item ${sort.name === 'claimId' ? 'active' : ''}`}
					onClick={() => handleChangeSort('claimId')}>
					<div className={`sort-icon `}>
						<SortDown
							className={sort.name === 'claimId' ? sort.order : ''}
							htmlColor={`${sort.name === 'claimId' ? '#4872F2' : '#dcdcdc'}`}
						/>
					</div>
					Номер заявки
				</div>
				{/* <div
					className={`sort-item  ${sort.name === 'reservationDate' ? 'active' : ''
						}`}
					onClick={() => handleChangeSort('reservationDate')}>
					<div
						className={`sort-icon ${sort.name === 'reservationDate' ? 'active' : ''
							}`}>
						<SortDown
							className={sort.name === 'reservationDate' ? sort.order : ''}
							htmlColor={`${sort.name === 'reservationDate' ? '#4872F2' : '#dcdcdc'
								}`}
						/>
					</div>
					Дата заказа
				</div> */}
				{/*<div className={`sort-item ${sort.name === 'type' ? 'active' : ''}`}>*/}
				{/*	<div*/}
				{/*		className={`sort-icon `}*/}
				{/*	// onClick={() => handleChangeSort('type')}*/}
				{/*	>*/}
				{/*		<SortDown*/}
				{/*			className={sort.name === 'type' ? sort.order : ''}*/}
				{/*			htmlColor={`${sort.name === 'claimId' ? '#4872F2' : '#dcdcdc'}`}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*	Тип заявки</div>*/}
				<div
					className={`sort-item ${sort.name === 'clientName' ? 'active' : ''}`}
					onClick={() => handleChangeSort('clientName')}>
					<div className={`sort-icon`}>
						<SortDown
							className={
								sort.name === 'clientName'
									? sort.order === 'asc'
										? 'desc'
										: 'asc'
									: ''
							}
							htmlColor={`${
								sort.name === 'clientName' ? '#4872F2' : '#dcdcdc'
							}`}
						/>
					</div>
					ФИО клиента
				</div>
			</div>

			<ClaimFilter applyFilter={applyFilter} />

			{orderBy(filteredClaims, sort.name, sort.order).map(item => (
				<Claim
					// isOpen={openClaim.includes(item.claimId)}
					// onOpen={handleOpenClaim}
					key={item.claimId}
					claim={item}
				/>
			))}
			{/* <ClaimList claims={orderBy(filtredClaims, sort.name, sort.order)}/> */}

			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress size={20} />
				</div>
			)}
			{claimsCount > pagesLimit && (
				<Pagination
					claimsCount={claimsCount}
					limit={pagesLimit}
					page={page}
					setPage={actions.setPage}
				/>
			)}
			<Snackbar
				open={openError}
				autoHideDuration={3000}
				onClose={handleCloseError}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Stack flexDirection='column' gap={1}>
					{loadingClaimError.map(item => (
						<Alert
							key={item}
							onClose={handleCloseError}
							severity={'error'}
							sx={{ width: '300px' }}>
							Ошибка загрузки заявки {item}
						</Alert>
					))}
				</Stack>
			</Snackbar>
		</div>
	)
}

export default Claims
