import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { geoApiInstance } from "../../services/api";
import axios from "axios";
import { isEmpty, minBy, uniqBy } from "lodash";
import { format } from "date-fns";
import { encodeQueryString } from "../../functions/sequelize";

const BASE_URL = process.env.REACT_APP_FS_APIGATE;

const useAccentStore = create(
	devtools((set, get) => ({
		geo: null,
		countries: null,
		countryId: null,
		regions: null,
		regionId: null,
		hotelIds: [],
		accents: null,
		offers: [],
		isAllCountry: false,
		actions: {
			getSalesGeo: async () => {
				const res = await geoApiInstance()
					.get('/sales-accents/geo')
				set({ geo: res.data })
			},
			getSalesAccent: async () => {
				const data = {
					showcase: "b2c:ru",
					countryEbgId: get().countryId,
					regionEbgId: get().regionId
				}
				const res = await geoApiInstance()
					.post('/sales-accents', data)
				set({ accents: res.data })
				set({ hotelIds: res.data.map(item => { return item.hotelEbgId.toString() }) })
			},

			getSalesAccentByAllCountry: async () => {
				const countries = get().countries?.map(i => i.id);
				set({ regions: [], hotelIds: [], accents: [] });
				const regionsRequest = countries.map(id => axios({
					url: `${BASE_URL}/Filters/GetFilters?DepartureCityId=1&ArrivalCountryId=${id}`,
					method: 'GET'
				}));
				try {
					const responses = await Promise.all(regionsRequest);

					const regions = responses.map((response, index) => {
						const regionIds = get().geo[countries[index]].regions;
						return uniqBy(response.data.availableTowns, 'regionKey')
							.filter(item => regionIds.includes(item.regionKey))
							.map(item => ({ id: item.regionKey, name: item.region }));
					});

					set({ regions: regions.flatMap(region => region) })

					let newAccents = [];
					let newHotelIds = [];

					const accentsRequests = regions.flatMap((region, index) => {
						return region.map(async (regionItem) => {
							const data = {
								showcase: "b2c:ru",
								countryEbgId: countries[index],
								regionEbgId: regionItem.id
							};
							const response = await geoApiInstance().post('/sales-accents', data);
							newAccents = [...newAccents, ...response.data];
							newHotelIds = [...newHotelIds, ...response.data.map(item => item.hotelEbgId.toString())];
						});
					});

					await Promise.all(accentsRequests);

					set({
						accents: newAccents,
						hotelIds: newHotelIds
					});
				} catch (e) {
					console.log(e?.response?.data);
				}
			},
			// getHotel: async () => {
			// 	const res = await axios({
			// 		url: 'http://10.20.20.24:32000/hotel/getbyurlname',
			// 		method: 'POST',
			// 		data: 'hotel/europe/russia/sochi/krasnaya-polyana/novotel-resort-krasnya-polyana-221719',
			// 		headers: {
			// 			'Requestfrom': 'B2b',
			// 			'X-Tui-Storefront-Id': 'b2c:ru',
			// 			'Content-Type': 'application/json-patch+json'
			// 		}
			// 	})
			// 	console.log(res)
			// },
			getCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=1&ClientId=b2c:ru`,
						method: 'GET',
						params: {
							LoadCities: false
						}
					})
					const countryIds = Object.keys(get().geo)
					set({ countries: res.data.filter(item => countryIds.includes(item.id.toString())) })
					set({ countryId: Number(countryIds[0]) })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getRegions: async (country) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetFilters
						?DepartureCityId=1
						&ArrivalCountryId=${country}`,
						method: 'GET'
					})
					const regionIds = get().geo[get().countryId].regions
					console.log(regionIds)
					set({ regions: uniqBy(res.data.availableTowns, 'regionKey').filter(item => regionIds.includes(item.regionKey)).map(item => { return { id: item.regionKey, name: item.region } }) })
					set({ regionId: Number(regionIds[0]) })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getOffers: async (accent) => {
				const req = {
					lang: 'ru',
					adults: '2',
					currency: '1',
					maxStartDate: accent.date,
					minStartDate: accent.date,
					nightsFrom: accent.days.toString(),
					nightsTo: accent.days.toString(),
					hotelIds: [accent.hotelEbgId.toString()],
					arrivalCountryId: accent.countryEbgId.toString(),
					departureCityId: accent.city_id.toString()
				}
				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetByHotels`,
						method: 'POST',
						data: req
					})
					let offer = get().offers
					if (!isEmpty(res.data.offers)) {
						const searchParams = {
							clientId: 'b2b:ru',
							lang: 'ru',
							departureCityId: accent.city_id,
							arrivalCountryId: accent.countryEbgId,
							adults: 2,
							minStartDate: accent.date,
							maxStartDate: accent.date,
							minNightsCount: accent.days,
							maxNightsCount: accent.days,
							hotelIds: [accent.hotelEbgId],
							currencyId: 1
						}
						const link = 'https://newb2b.fstravel.com/search_tour?' + encodeQueryString(searchParams);
						const region = get().regions.find(item => item.id === get().regionId) ?? get().regions.find(item => item.id === accent.regionEbgId)
						const country = get().countries.find(item => item.id === get().countryId) ?? get().countries.find(item => item.id === accent.countryEbgId)

						offer.push({
							...minBy(res.data.offers, 'price.value'),
							accent: accent,
							country: country,
							region: region,
							formattedDate: format(new Date(accent.date), 'dd.MM.yyyy'),
							priceStr: `от ${Math.ceil(res.data.offers[0].price.value / 2).toLocaleString('ru', {})} ₽`,
							bookingLink: link
						})
					}
					set({ offers: [...offer] })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			changeCountry: id => {
				console.log(id)
				set({ countryId: id })
				set({ regionId: null })
				id === 0 ? set({ isAllCountry: true }) : set({ isAllCountry: false })
				get().actions.clearOffers()
			},
			clearOffers: () => {
				set({ offers: [] })
			},
			changeRegion: id => {
				set({ regionId: id })
				get().actions.clearOffers()
			}
		}
	})))

export default useAccentStore