import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { transferApiInstance } from '../../../services/transfer'
import { isEmpty, orderBy, sumBy } from 'lodash'
import axios from 'axios'
import { format } from 'date-fns'
import { convertTo } from "../../../functions/convertLang";

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useTransferStore = create(
	devtools((set, get) => ({
		authError: false,
		loading: false,
		loadingUpdateBasket: false,
		loadingSearch: false,
		countriesKiwi: null,
		countriesSamo: null,
		currentCountry: null,
		regionsKiwi: null,
		samoCities: null,
		currentRegionFrom: null,
		currentRegionTo: null,
		placeTypes: null,
		placesFrom: null,
		placesTo: null,
		currentPlaceType: null,
		placeFrom: 1,
		placeTo: 1,
		transfers: null,
		transfer: null,
		meetingData: [],
		emptySearch: false,
		adults: 1,
		children: [],
		pageFrom: 0,
		pageTo: 0,
		stopSearch: false,
		searchComplete: false,
		draft: null,
		moreInfo: {},
		isReadyForBooking: false,
		travelers: [{ id: 1, sex: 'male' }],
		status: null,
		TransferServiceInfos: [],
		fullPrice: 0,
		childAgreement: false,
		actions: {
			getCountries: async () => {
				const res = await transferApiInstance().get('/getCountries')
				const { results } = res.data.data

				set({
					countriesKiwi: orderBy(
						results
							.filter(item => item.published)
							.map(item => {
								return { id: item.id, name: item.name.ru }
							}),
						'name'
					)
				})
				return orderBy(
					results
						.filter(item => item.published)
						.map(item => {
							return { id: item.id, name: item.name.ru }
						}),
					'name'
				)
				// set({currentCountry: get().countriesKiwi[0].id})
			},
			changeChildAgreement: () => {
				set({ childAgreement: !get().childAgreement })
			},
			getCountriesSamo: async (countryId) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries`,
						method: 'GET',
						params: {
							DepartureCityId: 1,
							LoadCities: true,
							ClientId: 'b2c:ru'
						}
					})
					set({ countriesSamo: res.data })
					if (!countryId) {
						set({
							currentCountry: get().countriesKiwi.find(item =>
								res.data.find(data => data.name === item.name)
							)?.id
						})
					}
					return res.data
				} catch (e) { }
			},
			getSamoCities: async countryId => {
				// try {
				// 	const res = await axios({
				// 		url: `${BASE_URL}/Filters/GetFilters`,
				// 		method: 'GET',
				// 		params: {
				// 			DepartureCityId: 1,
				// 			ArrivalCountryId: countryId
				// 		}
				// 	})
				// 	set({ samoCities: orderBy(res.data.availableTowns, 'name') })
				// 	if (!get().currentRegionFrom) {
				// 		set({ currentRegionFrom: get().samoCities[0]?.id })
				// 	}
				// 	if (!get().currentRegionTo) {
				// 		set({ currentRegionTo: get().samoCities[0]?.id })
				// 	}
				// 	return orderBy(res.data.availableTowns, 'name')
				// } catch (e) { }
				const kiwi = get().countriesKiwi.find(country => country.id === get().currentCountry)?.name
				const cities = get().countriesSamo.find(country => country.name === kiwi)?.towns
				set({ samoCities: orderBy(cities, 'name') })
				if (!get().currentRegionFrom) {
					set({ currentRegionFrom: get().samoCities[0]?.id })
				}
				if (!get().currentRegionTo) {
					set({ currentRegionTo: get().samoCities[0]?.id })
				}
				return orderBy(cities, 'name')
			},
			setCountry: id => {
				set({ currentCountry: id })
				// set({currentRegionFrom: get().regionsKiwi.filter(item => item.country.id === get().currentCountry)[0]?.id})
				// set({currentRegionTo: get().regionsKiwi.filter(item => item.country.id === get().currentCountry)[0]?.id})
				set({ emptySearch: false })
				set({ transfers: null })
				set({ searchComplete: false })
			},
			setRegionFrom: id => {
				set({ currentRegionFrom: id })
				set({ emptySearch: false })
				set({ transfers: null })
				set({ searchComplete: false })
			},
			setRegionTo: id => {
				set({ currentRegionTo: id })
				set({ emptySearch: false })
				set({ transfers: null })
				set({ searchComplete: false })
			},
			getRegions: async () => {
				const res = await transferApiInstance().get('/getRegions')

				const { data } = res.data
				let page = data?.page
				let nextPage = data?.nextPage

				let regions = data.results.map(item => {
					return { id: item.id, name: item.name.ru, country: item.country }
				})

				while (page !== data?.lastPage) {
					const res = await transferApiInstance({ page: nextPage }).get(
						'/getRegions'
					)
					const { data } = res.data
					page = data?.page
					nextPage = data?.nextPage
					regions = [
						...regions,
						...data.results.map(item => {
							return { id: item.id, name: item.name.ru, country: item.country }
						})
					]
				}
				set({ regionsKiwi: orderBy(regions, 'name') })
				set({
					currentRegionFrom: get().regionsKiwi.filter(
						item => item.country.id === get().currentCountry
					)[0]?.id
				})
				set({
					currentRegionTo: get().regionsKiwi.filter(
						item => item.country.id === get().currentCountry
					)[0]?.id
				})
			},
			getPlaceTypes: async (placeType) => {
				const res = await transferApiInstance().get('/getPlaceTypes')
				const { results } = res.data?.data
				let result = []
				results.forEach(from => {
					results.forEach(to => {
						result = [
							...result,
							{
								id: `${from.id}${to.id}`,
								name: `${from.name.ru} - ${to.name.ru}`,
								places: {
									placeFrom: from.id,
									placeTo: to.id
								}
							}
						]
					})
				})
				set({ placeTypes: orderBy(result, 'id') })
				if (!placeType) {
					set({
						currentPlaceType: get().placeTypes.find(
							item => item.id.toString() === `${get().placeFrom}${get().placeTo}`
						).id
					})
				} else {
					set({currentPlaceType: placeType})
				}
			},
			setPlaceType: id => {
				set({
					placeFrom: get().placeTypes.find(item => item.id === id).places
						.placeFrom
				})
				set({
					placeTo: get().placeTypes.find(item => item.id === id).places.placeTo
				})
				set({ emptySearch: false })
				set({ transfers: null })
				set({ searchComplete: false })
				set({ currentPlaceType: id })
			},
			getTransfers: async payload => {
				set({ loadingSearch: true })
				try {
					const res = await transferApiInstance({
						maxPerPage: 120,
						searchParams: {
							searchData: {
								countryId: get().currentCountry,
								placeFromId: payload.placesFrom,
								placeToId: payload.placesTo,
								id: payload.id
							}
						}
					}).get('/searchTransfers')

					const { results } = res.data?.data
					if (get().transfers) {
						set({ transfers: [...get().transfers, ...results] })
					} else {
						set({ transfers: results })
					}
					if (results && !isEmpty(results)) {
						set({ stopSearch: true })
						results.forEach(item => {
							get().actions.getTransferMeetingData(item.id)
						})
					}
				} catch (e) {
					console.log(e)
				} finally {
					set({ loadingSearch: false })
				}
			},
			getTransfer: async id => {
				const res = await transferApiInstance({
					maxPerPage: 120,
					searchParams: {
						searchData: {
							id: id
						}
					}
				}).get('/searchTransfers')

				const { results } = res.data?.data
				set({ transfer: results })
				const addressTo = ![2, 3, 4, 5].includes(
					results[0].route.placeTo.type.id
				)
					? get().draft.draftBody.transfers[0].to.address
					: results[0].route.placeTo.name.ru
				set({ moreInfo: { ...get().moreInfo, addressTo: addressTo } })
				const addressFrom = ![2, 3, 4, 5].includes(
					results[0].route.placeFrom.type.id
				)
					? get().draft.draftBody.transfers[0].from.address
					: results[0].route.placeFrom.name.ru
				set({ moreInfo: { ...get().moreInfo, addressFrom: addressFrom } })
			},
			getTransferMeetingData: async payload => {
				const res = await transferApiInstance({
					maxPerPage: 120,
					searchParams: {
						transferId: payload
					}
				}).get('/getTransferMeetingData')
				set({
					meetingData: [
						...get().meetingData,
						{ id: payload, ...res.data?.data }
					]
				})
			},
			getPlaces: async (cities) => {
				const samoCities = cities || get().samoCities
				const from = await transferApiInstance({
					maxPerPage: 500,
					searchParams: {
						searchData: {
							countryId: get().currentCountry,
							name: `%${samoCities.find(
								item => item.id === get().currentRegionFrom
							)?.name
								}%`,
							typeId: get().placeFrom
						}
					}
				}).get('/searchPlaces')

				if (!isEmpty(from.data?.data?.results)) {
					set({ placesFrom: from.data?.data?.results })
					set({ placesTo: from.data?.data?.results })
					if (
						get().placeTo !== get().placeFrom ||
						get().currentRegionFrom !== get().currentRegionTo
					) {
						const to = await transferApiInstance({
							maxPerPage: 500,
							searchParams: {
								searchData: {
									countryId: get().currentCountry,
									name: `%${samoCities.find(
										item => item.id === get().currentRegionTo
									)?.name
										}%`,
									typeId: get().placeTo
								}
							}
						}).get('/searchPlaces')
						set({ placesTo: to.data?.data?.results })
					}
				}
				if (isEmpty(get().placesFrom) || isEmpty(get().placesTo)) {
					set({ emptySearch: true })
					set({ transfers: [] })
				}
				set({ stopSearch: false })
				if (!isEmpty(get().placesFrom) && !isEmpty(get().placesTo)) {
					for (const [indexFrom] of get().placesFrom.entries()) {
						for (const [indexTo] of get().placesTo.entries()) {
							if (!get().stopSearch) {
								set({ pageFrom: indexFrom })
								set({ pageTo: indexTo })
								await get().actions.getTransfers({
									placesFrom: get().placesFrom[get().pageFrom].id,
									placesTo: get().placesTo[get().pageTo].id
								})
							}
						}
					}
				}
				if (get().placesFrom?.length - 1 === get().pageFrom || get().placesTo?.length - 1 === get().pageTo) {
					set({ searchComplete: true })
				}
			},
			loadMore: async () => {
				set({ stopSearch: false })
				if (get().pageTo < get().placesTo.length - 1) {
					set({ pageTo: get().pageTo + 1 })
					for (
						let indexFrom = get().pageFrom;
						indexFrom < get().placesFrom.length;
						indexFrom++
					) {
						for (
							let indexTo = get().pageTo;
							indexTo < get().placesTo.length;
							indexTo++
						) {
							if (!get().stopSearch) {
								set({ pageFrom: indexFrom })
								set({ pageTo: indexTo })
								await get().actions.getTransfers({
									placesFrom: get().placesFrom[get().pageFrom].id,
									placesTo: get().placesTo[get().pageTo].id
								})
							}
						}
					}
				} else {
					set({ pageFrom: get().pageFrom + 1 })
					set({ pageTo: 0 })
					for (
						let indexFrom = get().pageFrom;
						indexFrom < get().placesFrom.length;
						indexFrom++
					) {
						for (
							let indexTo = get().pageTo;
							indexTo < get().placesTo.length;
							indexTo++
						) {
							if (!get().stopSearch) {
								set({ pageFrom: indexFrom })
								set({ pageTo: indexTo })
								await get().actions.getTransfers({
									placesFrom: get().placesFrom[get().pageFrom].id,
									placesTo: get().placesTo[get().pageTo].id
								})
							}
						}
					}
				}
			},
			addToBasket: async transfer => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
				try {
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: null,
							transfers: [{
								date: "0001-01-01",
								clientPhone: '',
								transferId: transfer.id,
								services: {
									extraStopComment: null,
									transferServiceInfos: null
								},
								currency: {
									currencyAlias: "RUB",
									currencyId: 1
								},
								type: {
									id: 2,
									provider: 'kiwi',
									sla: transfer.type.sla,
								},
								travellers: [],
								netPrice: transfer.price.rub.cost,
								price: Math.round(transfer.price.rub.cost + transfer.price.rub.cost * 0.25),
								passengers: {
									adults: get().adults,
									children: get().children.length,
									infants: 0
								},
								from: {
									typeId: transfer.route.placeFrom.type.id,
									cityId: get().currentRegionFrom,
									hotelName: '',
									iata: transfer.route.placeFrom.iata,
									flightNumber: '',
									address: ''
								},
								to: {
									typeId: transfer.route.placeTo.type.id,
									cityId: get().currentRegionTo,
									hotelName: '',
									iata: transfer.route.placeTo.iata,
									flightNumber: '',
									address: ''
								},
								name: transfer.type.name.ru,
								id: transfer.type.id
							}]
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					window.open('/booking/transfer')
				} catch (e) {
					console.log(e)
				}
			},
			getDraft: async () => {
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
				try {
					const res = await axios({
						url: `${BASE_URL}/Draft`,
						method: 'GET',
						params: {
							userId: agentInfo?.agents.id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					set({ draft: res.data })
					set({ travelers: res.data.draftBody.transfers[0].travellers })
					const moreInfo = {
						// startDate: format(new Date(get().draft?.draftBody?.transfers[0]?.date), 'yyyy-MM-dd') || null,
						startDate: null,
						startTime:
							format(
								new Date(get().draft?.draftBody?.transfers[0]?.date),
								'HH:mm'
							) || null,
						addressFrom:
							get().draft?.draftBody?.transfers[0]?.from.address || '',
						addressTo: get().draft?.draftBody?.transfers[0]?.to.address || '',
						flightNumber:
							get().draft?.draftBody?.transfers[0]?.from.flightNumber || null,
						labelName: get().draft?.draftBody?.transfers[0]?.labelName || null,
						extraStopComment:
							get().draft?.draftBody?.transfers[0]?.services
								?.extraStopComment || null,
						clientPhone:
							get().draft?.draftBody?.transfers[0]?.clientPhone || null
					}
					set({ moreInfo: moreInfo })
					set({ fullPrice: get().draft?.draftBody?.transfers[0]?.price })
					return res.data.draftBody
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e)
				}
			},
			getDraftTrace: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Draft/trace`,
						method: 'GET',
						params: {
							DraftId: 3497
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					console.log(res)
				} catch (e) {
					console.log(e)
				}
			},
			updateBasket: async () => {
				set({ loadingUpdateBasket: true })
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

				let addressFrom = get().moreInfo.addressFrom
				let addressTo = get().moreInfo.addressTo

				if (/[а-яА-ЯЁё]/.test(addressFrom)) {
					addressFrom = convertTo('translit', addressFrom)
				}
				if (/[а-яА-ЯЁё]/.test(addressTo)) {
					addressTo = convertTo('translit', addressTo)
				}

				try {
					const res = await transferApiInstance({
						maxPerPage: 1,
						searchParams: {
							searchData: {
								id: get().draft.draftBody.transfers[0].transferId
							}
						}
					}).get('/searchTransfers')
					const actualNetPrice = res.data.data.results[0].price.rub.cost
					const actualPrice = Math.round(
						1.25 * res.data.data.results[0].price.rub.cost
					)
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: get().travelers[0],
							userInfo: null,
							transfers: [
								{
									date: `${get().moreInfo.startDate}T${get().moreInfo.startTime
										}`,
									clientPhone: get().moreInfo.clientPhone,
									transferId: get().draft.draftBody.transfers[0].transferId,
									currency: {
										currencyAlias: 'RUB',
										currencyId: 1
									},
									type: {
										id: 2,
										provider: 'kiwi'
									},
									travellers: get().travelers,
									netPrice:
										partpass === '169167'
											? 1
											: actualNetPrice,
									price:
										partpass === '169167'
											? 1
											: actualPrice,
									passengers: get().draft.draftBody.transfers[0].passengers,
									from: {
										typeId: get().draft.draftBody.transfers[0].from.typeId,
										cityId: get().draft.draftBody.transfers[0].from.cityId,
										hotelName: addressFrom,
										iata: get().draft.draftBody.transfers[0].from.iata || null,
										flightNumber:
											get().moreInfo.flightNumber || addressFrom,
										address: addressFrom
									},
									to: {
										typeId: get().draft.draftBody.transfers[0].to.typeId,
										cityId: get().draft.draftBody.transfers[0].to.cityId,
										hotelName: addressTo,
										iata: get().draft.draftBody.transfers[0].to.iata || null,
										flightNumber:
											get().moreInfo.flightNumber || addressTo,
										address: addressTo
									},
									name: get().draft.draftBody.transfers[0].name,
									id: 1,
									labelName: get().moreInfo.labelName || null,
									services: {
										ExtraStopComment: get().moreInfo.extraStopComment || null,
										TransferServiceInfos: get().TransferServiceInfos
									}
								}
							]
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					set({
						fullPrice:
							get().draft.draftBody.transfers[0].price +
							sumBy(get().TransferServiceInfos, 'rub.cost')
					})
					set({ isReadyForBooking: true })
				} catch (e) {
					console.log(e)
				} finally {
					set({ loadingUpdateBasket: false })
				}
			},
			reserveDraft: async id => {
				set({ loading: true })
				try {
					await axios({
						url: `${BASE_URL}/Draft/reserve`,
						method: 'POST',
						data: {
							draftId: id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					await get().actions.getStatus(id)
				} catch (e) {
					console.log(e)
				} finally {
					set({ loading: false })
				}
			},
			getStatus: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Draft/status`,
						method: 'GET',
						params: {
							draftId: id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					set({ status: res.data })
				} catch (e) {
					console.log(e)
				}
			},
			searchOrders: async () => {
				await transferApiInstance({
					maxPerPage: 500,
					searchParams: {
						searchData: {
							id: 990071
						}
					}
				}).get('/searchOrders')
			},
			setAdult: (id) => {
				set({ adults:  id})
			},
			setChild: (id) => {
				set({ children:  Array(id).fill('2')})
			},
			adultInc: () => {
				set({ adults: get().adults + 1 })
			},
			adultDec: () => {
				set({ adults: get().adults - 1 })
			},
			childInc: () => {
				set({ children: [...get().children, '2'] })
			},
			childDec: () => {
				set({ children: get().children.slice(1) })
			},
			setStartDate: date => {
				set({ moreInfo: { ...get().moreInfo, startDate: date } })
			},
			setStartTime: time => {
				set({ moreInfo: { ...get().moreInfo, startTime: time } })
			},
			setAddressFrom: address => {
				set({ moreInfo: { ...get().moreInfo, addressFrom: address } })
			},
			setAddressTo: address => {
				set({ moreInfo: { ...get().moreInfo, addressTo: address } })
			},
			setFlightNumber: num => {
				set({ moreInfo: { ...get().moreInfo, flightNumber: num } })
			},
			setLabelName: label => {
				set({ moreInfo: { ...get().moreInfo, labelName: label } })
			},
			setClientPhone: phone => {
				set({ moreInfo: { ...get().moreInfo, clientPhone: phone } })
			},
			setExtraStopComment: address => {
				set({ moreInfo: { ...get().moreInfo, extraStopComment: address } })
			},
			setReadyForBooking: value => {
				set({ isReadyForBooking: value })
			},
			changeGender: (gender, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.sex = gender
							return item
						}
						return item
					})
				})
			},
			changeDoc: (doc, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.type = doc
							return item
						}
						return item
					})
				})
			},
			changeNation: (nation, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.nationalityId = nation
							return item
						}
						return item
					})
				})
			},
			changeSeries: (series, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.series = series
							return item
						}
						return item
					})
				})
			},
			changeNumber: (num, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.number = num
							return item
						}
						return item
					})
				})
			},
			changeLastNameLatin: (lastName, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.lastNameLatin = lastName
							return item
						}
						return item
					})
				})
			},
			changeFirstNameLatin: (lastName, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.firstNameLatin = lastName
							return item
						}
						return item
					})
				})
			},
			changePhone: (phone, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.phones = phone
							return item
						}
						return item
					})
				})
			},
			changeEmail: (email, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.email = email
							return item
						}
						return item
					})
				})
			},
			changeBirthDate: (date, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.birthDay = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
			},
			changeExpirationDate: (date, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.expirationDate = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
			},
			setTravelers: () => {
				let travalersCount = 0
				Object.values(get().draft?.draftBody?.transfers[0]?.passengers).forEach(
					item => (travalersCount += item)
				)
				const travelers = Array(travalersCount)
					.fill(1)
					.map((item, index) => {
						return {
							id: index + 1,
							sex: 'male',
							document: {
								type: 'internationalPassport'
							},
							nationalityId: 210357
						}
					})
				set({ travelers: travelers })
			},
			clearError: () => {
				set({ status: null })
			},
			clearAuthError: () => {
				set({ authError: false })
			},
			addService: service => {
				set({ isReadyForBooking: false })
				if (
					!get().TransferServiceInfos.some(
						item => item.latinName === service.latinName
					)
				) {
					const newTransfer = {
						...service,
						rub: {
							...service.rub,
							nCost: service.rub.cost,
							cost: Math.round(1.25 * service.rub.cost)
						}
					}
					set({
						TransferServiceInfos: [...get().TransferServiceInfos, newTransfer]
					})
				} else {
					set({
						TransferServiceInfos: [
							...get().TransferServiceInfos.filter(
								item => item.latinName !== service.latinName
							)
						]
					})
				}
			}
		}
	}))
)

export default useTransferStore
