import React from 'react';
import { SvgIcon } from "@mui/material";

const ArrowDownSmall = (props) => {
	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M18.4019 8.40699C18.3322 8.33714 18.2495 8.28173 18.1584 8.24392C18.0672 8.20611 17.9696 8.18665 17.8709 8.18665C17.7723 8.18665 17.6746 8.20611 17.5835 8.24392C17.4923 8.28173 17.4096 8.33714 17.3399 8.40699L11.2496 14.4988L5.15927 8.40698C5.01844 8.26615 4.82744 8.18704 4.62827 8.18704C4.42911 8.18704 4.2381 8.26615 4.09727 8.40698C3.95644 8.54781 3.87733 8.73882 3.87733 8.93798C3.87733 9.13715 3.95644 9.32815 4.09727 9.46899L10.7186 16.0903C10.7883 16.1602 10.871 16.2156 10.9621 16.2534C11.0533 16.2912 11.1509 16.3106 11.2496 16.3106C11.3482 16.3106 11.4459 16.2912 11.537 16.2534C11.6282 16.2156 11.7109 16.1602 11.7806 16.0903L18.4019 9.46899C18.4718 9.39932 18.5272 9.31655 18.565 9.22544C18.6028 9.13432 18.6223 9.03664 18.6223 8.93799C18.6223 8.83934 18.6028 8.74165 18.565 8.65054C18.5272 8.55942 18.4718 8.47665 18.4019 8.40699Z" fill="#3C3C3C" />
		</SvgIcon>
	);
};

export default ArrowDownSmall;