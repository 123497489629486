import React from "react"
import styled from "styled-components"
import { RadioGroup } from "../../ui"
import {
  FLIGHT_CLASSES,
  FLIGHT_TYPES,
  ROUTE_TYPES,
  CRUISE_TYPE
} from "../../../store/modules/agents/agent-fees/constants"

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const BottomLabel = styled(Label)`
  margin-bottom: 0;
  margin-top: 12px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 50px;

  @media (max-width: 1166px) {
    flex-wrap: wrap;
    row-gap: 30px;
    margin-right: 0;
  }
`

const LastGroup = styled.div`
  //@media (max-width: 1166px) {
  //  margin-top: 30px;
  //}
`

const CheckboxRow = ({ values, setFieldValue }) => {
  return (
    <>
      <Row>
        <div>
          <Label>Класс тарифа</Label>
          <RadioGroup
            name="flightClass"
            defaultValue={values.flightClass ?? ""}
            onChange={value => {
              setFieldValue("flightClass", value)
            }}
            items={FLIGHT_CLASSES}
          />
        </div>
        <div>
          <Label>Тип маршрута</Label>
          <RadioGroup
            name="routeType"
            defaultValue={values.routeType ?? ""}
            onChange={value => {
              setFieldValue("routeType", value)
            }}
            items={ROUTE_TYPES}
          />
        </div>
        <LastGroup>
          <Label>Тип перелета</Label>
          <RadioGroup
            name="flightType"
            defaultValue={values.flightType ?? ""}
            onChange={value => {
              setFieldValue("flightType", value)
            }}
            items={FLIGHT_TYPES}
          />
        </LastGroup>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <div>
          <Label>Тип рейса</Label>
          <RadioGroup
            defaultValue={values.isCharter ?? ""}
            name="isCharter"
            onChange={value => {
              setFieldValue("isCharter", value)
            }}
            items={CRUISE_TYPE}
          />
        </div>
      </Row>
    </>
  )
}

export default CheckboxRow
