import React from 'react';
import styledComponents from "styled-components";

import './style.scss'

const Header = styledComponents.div`
	font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 32px;
    font-family: Open Sans;
    
    @media (max-width: 576px) {
		font-size: 24px;
		line-height: 34px;
    }
`

const Container = styledComponents.div`
	display: grid;
    gap: 30px;
    grid-template-columns: repeat(3,1fr);
    
    @media (max-width: 576px) {
    	gap: 16px;
    	grid-template-columns: 1fr;
    }
`

const Tile = styledComponents.div`
	align-items: flex-start;
    border-radius: 16px;
    display: flex;
    gap: 24px;
    padding: 24px 20px;
`

const TilesBillets = ({ content, title }) => {

	return (
		<div className={'tiles-billets'}>
			{title && <Header>{title}</Header>}
			<Container>
				{content.tiles.map(item =>
					<Tile key={item.order} style={{background: item.backgroundColour ? item.backgroundColour : '#EDFAF9'}}>
						{item.digit && !item.image && <h4
							style={{
								background: item.digitBackgroundColour ? item.digitBackgroundColour : '#49C8C0',
								color: item.digitColour ? item.digitColour : '#FFFFFF'
							}}
							className={'tiles-billets__digit'}
						>
							{item.digit}
						</h4>}
						{item.image && <img className={'tiles-billets__image'} src={item.image} alt=""/>}
						<div style={{color: item.textColour && item.textColour, flex: '1px'}}>
							<h3>{item.title}</h3>
							<div className={'tiles-billets__text'}>{item.text}</div>
						</div>
					</Tile>
				)}
			</Container>
		</div>
	);
};

export default TilesBillets;