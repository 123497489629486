import React from 'react';
// eslint-disable-next-line import/named
import styled from 'styled-components';
import {
  PassengerAgeCategory,
  removeSeatByPassengerId,
} from '../../../../store/modules/booking';
import { Text } from '../../../ui';
import adultPath from '../assets/media/adult.svg';
import childPath from '../assets/media/child.svg';
import { ReactComponent as Cross } from '../assets/media/cross.svg';
import { useDispatch } from 'react-redux';

const PassengerSelect = styled.button`
  width: calc(100% - 32px);
  outline: none;
  padding: 15px;
  background: ${({ background }) => background};
  border: 1px solid
    ${({ background }) => (background === '#E3EAFF' ? '#4872F2' : '#c4c4c4')};
  box-sizing: content-box;
  border-radius: 6px;
  outline: none;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%), 0px 0px 5px rgb(0 0 0 / 5%);

  @media (max-width: 767px) {
    padding: 8px 8px;
    height: 39px;
    width: 120px;
    display: block;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

const PassengerSelectLeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > img {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    margin-bottom: 5px;
    & > img {
      max-height: 16px;
    }
  }
`;

const PassengerSelectRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > :first-child {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    margin-left: 16px;
  }
`;

const PassengerName = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledCross = styled(Cross)`
  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileText = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;

const PassengerSelectButton = (props) => {
  const iconPath =
    props.ageCategory === PassengerAgeCategory.Adult ? adultPath : childPath;

  const dispatch = useDispatch();
  return (
    <PassengerSelect
      {...props}
      background={props.selected ? '#E3EAFF' : '#ffffff'}
    >
      <PassengerSelectLeftSide>
        <img src={iconPath} alt="" />
        <PassengerName>
          <MobileText>
            {props.ageCategoryDescription} {props.number}
          </MobileText>

          <DesktopText>
            Пассажир {props.number},{' '}
            {props.ageCategoryDescription.toLocaleLowerCase()}
          </DesktopText>
        </PassengerName>
      </PassengerSelectLeftSide>

      <PassengerSelectRightSide>
        {!props.seat && <PassengerName>Не выбрано</PassengerName>}
        {props.seat && (
          <>
            <PassengerName>Кресло {props.seat.number}</PassengerName>
            <StyledCross
              onClick={() => dispatch(removeSeatByPassengerId(props.uid))}
            />
          </>
        )}
      </PassengerSelectRightSide>
    </PassengerSelect>
  );
};

export default PassengerSelectButton;
