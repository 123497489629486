const updateObject = (obj, addictionalParams) =>
  obj ? { ...obj, ...addictionalParams } : { ...addictionalParams };

class FetchApi {
    constructor() {
        this.authRequired = null;
        this.params = null;
        this._query = null;
        this._body = null;
        this.type = 'GET'
        this.url = '';
        this.contentType = null;
        this._dontParseResponse = false;
    }

    addAuthGuard() {
        this.authRequired = true;
        return this;
    }

    setContentType(str) {
        this.contentType = str;
        return this;
    }

    dontParseResponse() {
        this._dontParseResponse = true;
        return this;
    }

    query(args) {
        this._query = args;
        return this;
    }

    body(args) {
        if(args.constructor.name === 'FormData') {
            this._body = args;
            return this;
        }
        this._body = JSON.stringify(args);
        return this;
    }


    get(url,params,query) {
        this.type = 'GET';
        this._query = query;
        this.params = params;
        this.url = url;
        this.contentType = null;
        this.authRequired = null;
        this._body = null;
        this._dontParseResponse = false;
        return this;
    }

    post(url,params,query) {
        this.type = 'POST';
        this.params = params;
        this._query = query;
        this.url = url;
        this.contentType = null;
        this.authRequired = null;
        this._body = null;
        this._dontParseResponse = false;
        return this;
    }

    put(url,params,query) {
        this.type = 'PUT';
        this.params = params;
        this._query = query;
        this.url = url;
        this.contentType = null;
        this.authRequired = null;
        this._body = null;
        this._dontParseResponse = false;
        return this;
    }


    delete(url,params,query) {
        this.type = 'DELETE';
        this.params = params;
        this._query = query;
        this.url = url;
        this.contentType = null;
        this.authRequired = null;
        this._body = null;
        this._dontParseResponse = false;
        return this;
    }

    async send() {
        const url = new URL(this.url);

        const dontParseResponse = this._dontParseResponse;

        if(!!this._query) {
            Object.entries(this._query).forEach(([key,value]) => {
                if(value !== undefined) {
                    url.searchParams.append(key,value)
                }
            });
        }

        let params = this.params || {};
        params.method = this.type;


        if(this._body !== null) {
            params = {...params,body: this._body};
        }

        if(this.contentType !== null && this.contentType !== 'multipart/form-data') {
            params = {...params,headers: updateObject(params.headers, {
                'Content-Type': this.contentType,
            })}
        }

        if(this.authRequired) {
            const token =  JSON.parse(window.localStorage.getItem('tte_token'))?.accessToken;
            params = {...params,headers: updateObject(params.headers, {
                Authorization: `Bearer ${token}`,
            })}
        }

        const contentType = this.contentType
        const x = await fetch(url.toString(), params);

        if(x.status === 400) {
            throw new Error(400);
        }

        if(x.status > 400 && x.status <= 499) {
            const data = await x.json();
            throw new HttpError(data.message || data.description, x.status, data)
        }

        if(x.status >= 500) {
            throw new Error(x.status);
        }

        if(dontParseResponse) {
            return x;
        }

        switch(contentType) {
            case 'application/json':
            case 'multipart/form-data':
                return await x.json();
            case 'application/octet-stream':
                return x;
            default:
                return await x.text();
        }
    }

    toString() {
        const url = new URL(this.url);

        if(!!this._query) {
            Object.entries(this._query).forEach(([key,value]) => {
                url.searchParams.append(key,value)
            });
        }
        return url.toString();
    }

    async loadFile(response) {
        const reader = response.body.getReader()
        // const contentLength = +response.headers.get('Content-Length')

        let receivedLength = 0
        let chunks = []
        while(true) {
            const {done, value} = await reader.read()

            if (done) {
                break
            }

            chunks.push(value)
            receivedLength += value.length

            // console.log(`Получено ${receivedLength} из ${contentLength}`)
        }


        let chunksAll = new Uint8Array(receivedLength) // (4.1)
        let position = 0
        for(let chunk of chunks) {
            chunksAll.set(chunk, position) // (4.2)
            position += chunk.length
        }

        let filename = ''
        const disposition = response.headers.get('Content-Disposition')
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }


        const blob = new Blob([chunksAll], {type: response.headers.get('Content-Type')})
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
    }
}

export class HttpError extends Error {
    constructor(message, code, data) {
        super(message);
        this.code = code;
        this.data = data;
    }
}

const entity = new FetchApi();

export default entity;
