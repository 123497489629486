import flatten from "lodash/flatten"
import _ from "lodash"

export const passengersNormalizer = (orderPosition, insurances) => {
  const insurancesGroupedByPassengers = _.groupBy(
    _(insurances?.products).reduce(
      (acc, x) => [
        ...acc,
        ...x.policies.map(y => ({
          value: y.id,
          label: x.name,
          price: y.price,
          checked: false,
          passengerId: y.passenger.aviaPassengerId
        }))
      ],
      []
    ),
    x => x.passengerId
  )

  return orderPosition?.passengers
    .map((value, index) => ({
      label: `${value.firstName} ${value.lastName}`,
      value: value.passengerId,
      index,
      insurances: insurancesGroupedByPassengers[value.passengerId] || [],
      segments: orderPosition.segmentGroups
        .reduce(segmentGroupFlatten, [])
        .map((segment, index) => ({
          label: `${segment.departureCity.name} - ${segment.arrivalCity.name} (${segment.departureAirport.code}-${segment.arrivalAirport.code})`,
          value: extractId(segment),
          checked: false,
          index,
          selectedAncillaryServices: [],
          ancillaryServices: extractAncillarryServices(
            orderPosition,
            value.passengerId,
            segment.id
          ).map((value, index) => ({ ...value, index }))
        }))
    }))
    .reduce(
      (acc, x) => ({
        ...acc,
        passengers: {
          ...acc.passengers,
          [x.value]: {
            seatPlaces: [],
            activeSegment: "",
            value: x.value,
            label: x.label,
            selected: false,
            selectedSegments: {},
            insurances: x.insurances,
            segmentList: x.segments,
            showPassengerInList: false
          }
        },
        passengerList: [
          ...acc.passengerList,
          { value: x.value, label: x.label, checked: false }
        ]
      }),
      {
        selectedSegments: [],
        selectedPassengers: [],
        mealNamesArr: {},
        availableAncillaries: [],
        activePassenger: "",
        passengers: {},
        passengerList: [],
        showPassengersList: false
      }
    )
}

function segmentGroupFlatten(acc, x) {
  return [...acc, ...x.segments]
}

function extractId(args) {
  return args.id
}

function extractAncillarryServices(orderPosition, passengerId, segmentId) {
  const isSamePassengerIdPartial = isSamePassengerId(passengerId)
  const isSameSegmentIdPartial = isSameSegmentId(segmentId)

  const seatsAssignment = orderPosition.seatsAssignment || []
  return flatten([
    (orderPosition.meals || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter(x => x.status.code !== "Refunded")
      .map(data => ({
        label: data.name,
        value: data.id,
        segmentId,
        name: data.name
      })),

    (orderPosition.luggages || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter(x => x.status.code !== "Refunded")
      .map(data => ({
        label: `Доп. багаж до ${data.value} кг`,
        value: data.id,
        segmentId,
        name: data.name
      })),
    (orderPosition.otherAncillaryServices || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter(x => x.status.code !== "Refunded")
      .map(data => ({
        label: data.name,
        value: data.id,
        segmentId,
        name: data.name
      })),
    seatsAssignment
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter(x => x.status.code !== "Refunded")
      .map(data => ({
        label: `Место ${data.seatNumber ||
          data.name?.split(" ")[data.name?.split(" ").length - 1]} `,
        value: data.id,
        segmentId,
        name: data.name
      }))
  ]).filter(value => value !== undefined)
}

const isSamePassengerId = passengerId => value =>
  value.passengerId === passengerId

const isSameSegmentId = segmentId => value => {
  return (value.aviaSegmentIds || []).includes(segmentId)
}
