import { Alert, AlertTitle, Container } from '@mui/material'
import React from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import useTransferStore from '../../../store'

export default function ChildAgreement() {
	const actions = useTransferStore(({ actions }) => actions)
	const childAgreement = useTransferStore(
		({ childAgreement }) => childAgreement
	)

	return (
		<Container maxWidth='sm' sx={{ mb: 2 }}>
			<Alert
				severity={childAgreement ? "info" : 'error'}
				variant='outlined'
				iconMapping={{
					error: childAgreement ? (
						<CheckCircleOutlineIcon
							fontSize='inherit'
							onClick={actions.changeChildAgreement}
							style={{ cursor: 'pointer' }}
						/>
					) : (
						<RadioButtonUncheckedIcon
							fontSize='inherit'
							onClick={actions.changeChildAgreement}
							style={{ cursor: 'pointer' }}
						/>
					),
					info: childAgreement ? (
						<CheckCircleOutlineIcon
							fontSize='inherit'
							onClick={actions.changeChildAgreement}
							style={{ cursor: 'pointer' }}
						/>
					) : (
						<RadioButtonUncheckedIcon
							fontSize='inherit'
							onClick={actions.changeChildAgreement}
							style={{ cursor: 'pointer' }}
						/>
					)
				}}>
				<AlertTitle
					onClick={actions.changeChildAgreement}
					style={{ cursor: 'pointer' }}>
					Необходимо принять условия
				</AlertTitle>
				Важная информация: в некоторых странах обязательно детское кресло при
				перевозке детей. Водитель вправе отказать в перевозке без него.
				Пожалуйста, учитывайте эту особенность при бронировании услуги такси.
			</Alert>
		</Container>
	)
}
