import React, { useEffect, useState } from 'react'
import { Alert } from '@mui/material'

import './style.css'
import CustomSelect from '../../../../../../components/Select'
import { Box, Snackbar, TextField } from '@material-ui/core'
import BirthDateInput from '../../../../../../components/BirthDateInput'
import {
	emailRegExp,
	latinNameRegExp,
	interPassportNumberRegExp,
	cyrillicRegExp,
	seriesBirthCertificatesRegExp,
	rfPassportSeriesRegExp,
	interPassportSeriesRegExp,
	rfPassportNumberRegExp,
	birthCertNumberRegExp,
	latinUpperAndNumberRegExp,
	latinAndNumberRegExp
} from '../../../../../../constants/reg-exps'
import { useErrorStore } from './store'
import { nationality } from './nationalities'
import useLoyaltyStore from '../../../../../Loyalty/store'
import { transliterateToUppercase } from '../../../../../../functions/convertLang'
import { format } from "date-fns";
import useInsuranceStore from "../../../store";

const MembersBlock = ({ count, title, insurance }) => {

	const paxe = useInsuranceStore(state =>
		state.travellers.find(item => item.id === count)
	)
	const customerDocument = useInsuranceStore(state =>
		state.customerDocument
	)

	const phoneRegExp =
		/^(\+7|7)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

	const [typeDoc, setTypeDoc] = React.useState(count === 1 ? 'internationalPassport' : insurance.needTouristInternationalPassport ? 'internationalPassport'  : 'passport')
	const setErrorForm = useErrorStore(state => state.setErrorForm)
	const [errors, setErrors] = useState({
		lastName: null,
		firstName: null,
		lastNameLatin: null,
		firstNameLatin: null,
		patronymic: null,
		phone: null,
		email: null,
		series: null,
		number: null,
		issuingAuthority: null,
		address: null,
		customerDocument: null,
	})

	const actions = useInsuranceStore(({ actions }) => actions)

	const changeFirstNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeFirstNameLatin(event.target.value, count)
	}
	const changeLastNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeLastNameLatin(event.target.value, count)
	}

	const changeFirstNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeFirstName(event.target.value, count)
	}
	const changeLastNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeLastName(event.target.value, count)
	}
	const changePatronymicHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Отчество должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changePatronymic(event.target.value, count)
	}

	const changePhoneHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		actionsPL.setMemberId(null)
		if ((!phoneRegExp.test(event.target.value) && event.target.value !== '') || event.target.value.length < 11) {
			setErrors({
				...errors,
				[prop]: `Допустимая длина номера 11 символов, формата  79876543210`
			})
			actions.setPhoneError(true)
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
			actions.setPhoneError(false)
		}
		actions.changePhone(event.target.value, count)
	}
	const changeEmailHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		actionsPL.setMemberId(null)
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Email указан некорректно`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeEmail(event.target.value, count)
	}

	const changeNationHandler = (e, n) => {
		actions.changeNation(e, n)
	}
	const changeGenderHandler = (e, n) => {
		actions.changeGender(e, n)
	}
	const changeTypeDocHandler = (e, n) => {
		actions.changeDoc(e, n)
		setTypeDoc(e)
	}

	const changeCustomerDocumentHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, customerDocument: { ...errors.customerDocument, [prop]: null } })
		switch (prop) {
			case 'series':
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						customerDocument: { ...errors.customerDocument, [prop]: `Серия должна содержать 4 цифры` }
					})
					setErrorForm(true)
				}
				actions.changeCustomerDocument(prop, value)
				break

			case 'number':
				if (!rfPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						customerDocument: { ...errors.customerDocument, [prop]: `Номер должен быть 6 цифр` }
					})
					setErrorForm(true)
				}
				actions.changeCustomerDocument(prop, value)
				break

			default:
				setErrorForm(false)
				setErrors({ ...errors, customerDocument: { ...errors.customerDocument, [prop]: null } })
				break
		}
	}

	const changeSeriesHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 'internationalPassport':
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				}
				break
			case 'passport':
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 4 цифры`
					})
					setErrorForm(true)
				}
				break
			case 3:
				if (!seriesBirthCertificatesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать римское число и две кириллические буквы, через дефис`
					})
					setErrorForm(true)
				}
				break
			case 4:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorForm(true)
				}
				break
			case 6:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				}
				break
			case 7:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorForm(true)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}
		actions.changeSeries(value, count)
	}

	const changeNumberHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 'internationalPassport':
				if (!interPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 7 цифр`
					})
					setErrorForm(true)
				}
				break
			case 'passport':
				if (!rfPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				}
				break
			case 3:
				if (!birthCertNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				}
				break
			case 4:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			case 6:
				if (!latinAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			case 7:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}
		actions.changeNumber(value, count)
	}

	const actionsPL = useLoyaltyStore(({ actions }) => actions)

	const [error, setError] = useState(null)

	useEffect(() => {
		const checkValidationError = paxe => {
			if (
				!latinNameRegExp.test(paxe?.document?.lastNameLatin) &&
				!!paxe?.document?.lastNameLatin
			) {
				setErrors(errors => ({
					...errors,
					lastNameLatin: `Фамилия должна содержать только латиницу`
				}))
				setErrorForm(true)
			}
			if (
				!latinNameRegExp.test(paxe?.document?.firstNameLatin) &&
				!!paxe?.document?.firstNameLatin
			) {
				setErrors(errors => ({
					...errors,
					firstNameLatin: `Имя должно содержать только латиницу`
				}))
				setErrorForm(true)
			}
			if (
				!cyrillicRegExp.test(paxe?.document?.lastName) &&
				!!paxe?.document?.lastName
			) {
				setErrors(errors => ({
					...errors,
					lastName: `Фамилия должна содержать только кириллицу`
				}))
				setErrorForm(true)
			}
			if (!phoneRegExp.test(paxe?.phones) && !!paxe?.phones) {
				setErrors(errors => ({
					...errors,
					phone: `Допустимая длина номера 11 символов, формата  79876543210`
				}))
				setErrorForm(true)
			}
			if (!emailRegExp.test(paxe?.email) && !!paxe?.email) {
				setErrors(errors => ({
					...errors,
					email: `Email указан некорректно`
				}))
				setErrorForm(true)
			}
			if (
				!cyrillicRegExp.test(paxe?.document?.middleName) &&
				!!paxe?.document?.middleName
			) {
				setErrors(errors => ({
					...errors,
					patronymic: `Отчество должно содержать только кириллицу`
				}))
				setErrorForm(true)
			}

			switch (typeDoc) {
				case 'internationalPassport':
					if (
						!interPassportSeriesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 2:
					if (
						!rfPassportSeriesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 4 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 3:
					if (
						!seriesBirthCertificatesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать римское число и две кириллические буквы, через дефис`
						}))
						setErrorForm(true)
					}
					break
				case 4:
					if (paxe?.document?.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
						setErrorForm(true)
					}
					break
				case 6:
					if (
						!interPassportSeriesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (paxe?.document?.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, series: null }))
					break
			}

			switch (typeDoc) {
				case 'internationalPassport':
					if (
						!interPassportNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 7 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 2:
					if (
						!rfPassportNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 3:
					if (
						!birthCertNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 4:
					if (
						!latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				case 6:
					if (
						!latinAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (
						!latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, number: null }))
					break
			}
		}
		checkValidationError(paxe)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onBlur = (prop, event) => {
		let updatedValue = event.target.value.toUpperCase();
		if (prop === "firstNameLatin") {
		  changeFirstNameLatinHandler('firstNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(updatedValue) } });
		}
		if (prop === "lastNameLatin") {
		  changeLastNameLatinHandler('lastNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(updatedValue) } });
		}
		if (prop === "lastName") {
		  changeLastNameHandler('lastName')({ ...event, target: { ...event.target, value: updatedValue } });
		}
		if (prop === "firstName") {
		  changeFirstNameHandler('firstName')({ ...event, target: { ...event.target, value: updatedValue } });
		}
		if (prop === "patronymic") {
		  changePatronymicHandler('patronymic')({ ...event, target: { ...event.target, value: updatedValue } });
		}
	}

	return (
		<>
			<Snackbar
				open={error}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert
					onClose={() => setError(null)}
					severity='error'
					sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
			<div className='block-header'>
				<h3 className='title'>
					<span>{title ? title : `Информация о туристе ${count}`}</span>
				</h3>
			</div>
			<Alert severity='error' sx={{ width: '100%' }}>
				Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
				информации о туристе!
			</Alert>
			<div className='members-form'>
				<div className='info'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={paxe?.sex || 'male'}
							options={[
								{ id: 'male', name: 'Муж.' },
								{ id: 'female', name: 'Жен.' }
							]}
							title='Пол'
							count={count}
							onChange={changeGenderHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Фамилия по-латински'
							variant='outlined'
							fullWidth
							value={paxe?.document?.lastNameLatin || ''}
							onChange={changeLastNameLatinHandler('lastNameLatin')}
							helperText={errors.lastNameLatin}
							onBlur={(event) => onBlur('lastNameLatin', event)}
							error={!!errors.lastNameLatin}
							required
							title='В фамилии допустимы только прописные латинские буквы'
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Имя по-латински'
							variant='outlined'
							fullWidth
							value={paxe?.document?.firstNameLatin || ''}
							onChange={changeFirstNameLatinHandler('firstNameLatin')}
							helperText={errors.firstNameLatin}
							error={!!errors.firstNameLatin}
							onBlur={(event) => onBlur('firstNameLatin', event)}
							required
							title='В имени допустимы только прописные латинские буквы'
						/>
					</Box>
					{insurance.needRussianFullName &&
						<>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Фамилия по-русски'
									variant='outlined'
									fullWidth
									value={paxe?.document?.lastName || ''}
									onChange={changeLastNameHandler('lastName')}
									onBlur={(event) => onBlur('lastName', event)}
									helperText={errors.lastName}
									error={!!errors.lastName}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Имя по-русски'
									variant='outlined'
									fullWidth
									value={paxe?.document?.firstName || ''}
									onChange={changeFirstNameHandler('firstName')}
									onBlur={(event) => onBlur('firstName', event)}
									helperText={errors.firstName}
									error={!!errors.firstName}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Отчество по-русски'
									variant='outlined'
									fullWidth
									value={paxe?.document?.middleName || ''}
									onChange={changePatronymicHandler('patronymic')}
									onBlur={(event) => onBlur('patronymic', event)}
									helperText={errors.patronymic}
									error={!!errors.patronymic}
									required
								/>
							</Box>
						</>
					}
					<Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={actions.changeBirthDate}
							count={count}
							label='Дата рождения'
							value={paxe?.birthDay ? new Date(paxe?.birthDay) : null}
							fixbirthday
							required
						/>
						<p style={{ marginTop: '15px' }}>* Год рождения указан согласно ранее заданному в поиске</p>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Телефон'
							variant='outlined'
							fullWidth
							helperText={errors.phone}
							error={!!errors.phone}
							value={paxe?.phones || ''}
							onChange={changePhoneHandler('phone')}
							required
						/>
					</Box>
					<Box>
						<TextField
							label='E-Mail'
							variant='outlined'
							fullWidth
							helperText={errors.email}
							error={!!errors.email}
							value={paxe?.email || ''}
							onChange={changeEmailHandler('email')}
							required
						/>
					</Box>
				</div>
				<div className='docs'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={paxe?.nationalityId || 210357}
							options={nationality}
							title='Гражданство'
							count={count}
							onChange={changeNationHandler}
							required
						/>
					</Box>
					{count === 1 &&
						<>
							<Box style={{ marginBottom: 15 }}>
								<CustomSelect
									value={'passport'}
									options={[
										{ id: 'passport', name: 'Паспорт' },
									]}
									title='Тип документа'
									count={count}
									// onChange={changeTypeDocHandler}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Серия документа'
									variant='outlined'
									fullWidth
									helperText={errors.customerDocument?.series}
									error={!!errors.customerDocument?.series}
									value={customerDocument?.series || ''}
									onChange={changeCustomerDocumentHandler('series')}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Номер документа'
									variant='outlined'
									fullWidth
									helperText={errors.customerDocument?.number}
									error={!!errors.customerDocument?.number}
									value={customerDocument?.number || ''}
									onChange={changeCustomerDocumentHandler('number')}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Кем выдан'
									variant='outlined'
									fullWidth
									helperText={errors.customerDocument?.issuingAuthority}
									error={!!errors.customerDocument?.issuingAuthority}
									value={customerDocument?.issuingAuthority || ''}
									onChange={(e) => actions.changeCustomerDocument('issuingAuthority', e.target.value?.toLocaleUpperCase())}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<BirthDateInput
									onChange={(data, count) => actions.changeCustomerDocument('issueDate', data)}
									count={count}
									label='Дата выдачи *'
									value={customerDocument?.issueDate ? format(new Date(customerDocument?.issueDate), 'dd.MM.yyyy') : null}
									isExpiration={false}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Адрес по прописке'
									variant='outlined'
									fullWidth
									helperText={errors.customerDocument?.address}
									error={!!errors.customerDocument?.address}
									value={customerDocument?.address || ''}
									onChange={(e) => actions.changeCustomerDocument('address', e.target.value?.toLocaleUpperCase(), count)}
									required
								/>
							</Box>
						</>
					}
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={paxe?.document?.type || (insurance.needRussianPassport ? 'passport' : 'internationalPassport')}
							options={count === 1 ?
								[
									{ id: 5, name: 'Иной документ' },
									{ id: 3, name: 'Свидетельство о рождении' },
									{ id: 'internationalPassport', name: 'Заграничный паспорт' },
									{ id: 4, name: 'ID карта' }
								] : [
									{ id: 5, name: 'Иной документ' },
									{ id: 'passport', name: 'Паспорт' },
									{ id: 3, name: 'Свидетельство о рождении' },
									{ id: 'internationalPassport', name: 'Заграничный паспорт' },
									{ id: 4, name: 'ID карта' }
								]
							}
							title='Тип документа'
							count={count}
							onChange={changeTypeDocHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Серия документа'
							variant='outlined'
							fullWidth
							helperText={errors.series}
							error={!!errors.series}
							value={paxe?.document?.series || ''}
							onChange={changeSeriesHandler('series')}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер документа'
							variant='outlined'
							fullWidth
							helperText={errors.number}
							error={!!errors.number}
							value={paxe?.document?.number || ''}
							onChange={changeNumberHandler('number')}
							required
						/>
					</Box>
					{paxe?.document?.type === 'internationalPassport' && <Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={actions.changeExpirationDate}
							count={count}
							label='Действителен до *'
							value={paxe?.document?.expirationDate ? format(new Date(paxe?.document?.expirationDate), 'dd.MM.yyyy') : null}
							isExpiration={true}
							required
						/>
					</Box>}
					{typeDoc === 'passport' &&
						<>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Кем выдан'
									variant='outlined'
									fullWidth
									helperText={errors.issuingAuthority}
									error={!!errors.issuingAuthority}
									value={paxe?.document?.issuingAuthority || ''}
									onChange={(e) => actions.changeIssuingAuthority(e.target.value?.toLocaleUpperCase(), count)}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<BirthDateInput
									onChange={actions.changeIssueDate}
									count={count}
									label='Дата выдачи *'
									value={paxe?.document?.issueDate ? format(new Date(paxe?.document?.issueDate), 'dd.MM.yyyy') : null}
									isExpiration={false}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Адрес по прописке'
									variant='outlined'
									fullWidth
									helperText={errors.address}
									error={!!errors.address}
									value={paxe?.document?.address || ''}
									onChange={(e) => actions.changeAddress(e.target.value?.toLocaleUpperCase(), count)}
									required
								/>
							</Box>
						</>
					}
				</div>
			</div>
		</>
	)
}

export default MembersBlock
