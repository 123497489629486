import {
  call,
  all,
  takeLatest,
  takeEvery,
  put,
  select,
  spawn,
  delay
} from "redux-saga/effects"
import {
  setLoadingStatus,
  getOrderByNumber,
  setDetailData,
  getOrdersList,
  setOrdersList,
  payOrderRequest,
  printOrderRequest,
  printOrderSuccess,
  confirmPaymentRequest,
  cancelOrderPositionFailure,
  cancelOrderPositionRequest,
  cancelOrderPositionSuccess,
  getHistoryRequest,
  getHistorySuccess,
  getOrdersListNext,
  updateAddictionalServices,
  addAddictionalService,
  deleteAddictionalService,
  addictionalServiceActionsSuccess,
  purifyAddictionalServices,
  cancelNotification,
  approveNotification,
  refreshOrder,
  getActiveCalculationsSuccess,
  issueTicketsRequest,
  issueTicketsSuccess,
  getOrderPaymentsRequest,
  getOrderPaymentsSuccess,
  setAvailableInsurances,
  addInsurances,
  deleteInsurances,
  getModalErrorText,
  showModalError,
  issueOrderPositionRequest,
  issueOrderPositionSuccess,
  getAgentOrderBillRequest,
  setLoadingStatusCredit,
  promocodeApplySuccess,
  promocodeApply,
  createPaymentSuccess,
  getQrSuccess,
  getQrRequest,
} from "./duck"
import {getAquiringCommission} from '../agents/agent-finance/duck'
import { getUserDataState } from "../user"
import {
  getOrderReferencesRequestWorker,
  getAppealReferencesRequestWorker
} from "../references"
import { STATUS } from "./constants"
import {
  getDetailOrder,
  orderListStateSelector,
  getAddictionalServices,
  getAddictionalServicesNew
} from "./selectors"
import * as OrderManager from "./manager"
import { setPage, setPageSize } from "./utils"
import {getAgentSiginState} from "../agents/agent-finance/selectors";
import * as Manager from "../agents/agent-fees/manager";
import {getAgentTaxesSuccess} from "../agents/agent-fees/duck";
import {createPayment, payOrder} from "./manager";

function* getNotPaidAddictionalServices(orderNumber) {
  const data = yield call(
    OrderManager.getAddictionalServicesByOrderNumber,
    orderNumber
  )
  yield put(
    updateAddictionalServices(
      (data.additionalServices || []).map(x => ({
        ...x,
        isPaid: false,
        checked: false,
        isLoading: false,
        expires: null
      }))
    )
  )
}
function* getDetailData(orderNumber) {
  try {
    yield put(setLoadingStatus(STATUS.loading))
    const data = yield call(OrderManager.getOrderByNumber, orderNumber)
    yield put(setDetailData(data))
    yield put(
      updateAddictionalServices(
        data.additionalServices.map(x => ({
          ...x,
          checked: x.paymentStatus.code === "NotPaid",
          isPaid: x.paymentStatus.code === "Paid" || x.paymentStatus.code === "PaidByCredit",
          isLoading: false,
          expires: x.cancellationTime
        }))
      )
    )

    yield put(setLoadingStatus(STATUS.success))
    const user = yield select(getUserDataState)
    if (data?.backOfficeId && user?.agentId) {
      try {
        const response = yield call(
          OrderManager.getOrderPayments,
          data?.orderNumber.toString()
        )
        yield put(getOrderPaymentsSuccess(response))
      } catch (e) {
        console.log(e);
        //
      }
    }
    yield spawn(getActiveCalculationsWorker, data)
    yield spawn(getAvailableInsurancesWorker, data.orderPositions[0].id)
    yield put(getAquiringCommission({ AgentId: user?.agentId }))

    const agent = yield select(getAgentSiginState)
    if (agent) {
      const tax = yield call(Manager.getAgentTax, agent.agentId)
      yield put(getAgentTaxesSuccess(tax))
    }

    // yield put(getActiveCalculationsRequest());
  } catch (e) {
    yield put(setLoadingStatus(STATUS.failure))
  }
}

function* getActiveCalculationsWorker(data) {
  const activeCalulations = yield call(
    OrderManager.getActiveCalculations,
    data.id
  )

  yield put(getActiveCalculationsSuccess(activeCalulations))
}

function* getAvailableInsurancesWorker(positionId) {
  try {
    const result = yield call(OrderManager.getAvailableInsurances, positionId)
    yield put(setAvailableInsurances(result))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

export function* getOrderByNumberWorker({ payload }) {
  yield put(purifyAddictionalServices())
  yield spawn(getDetailData, payload)
  yield spawn(getAppealReferencesRequestWorker)
  yield spawn(getNotPaidAddictionalServices, payload)
}

export function* getOrdersListWorker({ payload }) {
  try {
    const params = {
      ...payload,
      page: setPage(payload.page),
      pageSize: setPageSize(payload.pageSize),
      number: payload.number
    }

    yield spawn(getOrderReferencesRequestWorker)
    const data = yield call(OrderManager.getOrdersList, params)
    yield put(setOrdersList({ ...data, currPage: setPage(payload.page) }))

    yield put(setLoadingStatus(STATUS.success))

  } catch (e) {

  }
}

export function* getOrdersListNextWorker(action) {
  try {
    const { currPage, pageCount, orders } = yield select(
      orderListStateSelector
    )
    if (currPage === pageCount - 1) return
    const params = {
      page: currPage + 1,
      pageSize: 10,
      ...action.payload
    }

    const data = yield call(OrderManager.getOrdersList, params)
    yield put(
      setOrdersList({
        ...data,
        orders: [...(orders || []), ...(data.orders || [])],
        currPage: currPage + 1
      })
    )
  } catch (e) {

  }
}

/** Сheck if order can be paid, then redirect to payment page, if success */
function* payOrderRequestWorker(action) {
  const { payload } = action

  try {
    const data = yield call(OrderManager.payOrder, payload)

    if (data.paymentURL) {
      window.history.pushState({}, "Заказ такойто", window.location.href)
      window.location.replace(data.paymentURL)
    }
  } catch (e) {
    console.log(e);
  }
}

/** Fetching order receipt */
function* printOrderRequestWorker(action) {
  const { payload } = action

  try {
    const data = yield call(
      OrderManager.printOrder,
      payload.orderId,
      payload.orderPositionId
    )
    yield put(printOrderSuccess(data))
  } catch (e) {

  }
}

/**
 * Send request that payment success, and if it succeed redirect load order detail information
 * @param action
 */
function* confirmPaymentRequestWorker(action) {
  const { payload } = action

  try {
    yield put(setLoadingStatus(STATUS.loading))
    yield call(OrderManager.notifyPayment, payload)

    yield put(setLoadingStatus(STATUS.success))
  } catch (e) {
    console.log(e);
  }
}

export function* refreshOrderWorker(action) {
  try {
    const state = yield select(getDetailOrder)
    const data = yield call(OrderManager.getOrderByNumber, state.orderNumber)
    yield spawn(getAvailableInsurancesWorker, state.orderPositions[0].id)
    yield put(setDetailData(data))
    const activeCalulations = yield call(
      OrderManager.getActiveCalculations,
      data.id
    )
    yield put(getActiveCalculationsSuccess(activeCalulations))
  } catch (e) {
    console.log(e)
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

function* cancelOrderPositionRequestWorker(action) {
  const { payload } = action
  try {
    yield call(OrderManager.cancelOrderPosition, payload)
    yield delay(1000);
    yield call(refreshOrderWorker)
    yield put(cancelOrderPositionSuccess())
  } catch (e) {
    yield put(cancelOrderPositionFailure())

  }
}

function* getHistoryRequestWorker() {
  const state = yield select(getDetailOrder)
  try {
    const data = yield call(OrderManager.getHistory, state.id)
    yield put(getHistorySuccess(data.event))
  } catch (e) {

  }
}

function* cancelNotificationWorker({ payload }) {
  // try {
  //   yield call(AppealsManager.declineSupportTicketCalculation, payload)
  //   yield call(refreshOrderWorker)
  // } catch (e) {

  // }
}

function* approveNotificationWorker({ payload }) {
  // try {
  //   yield call(AppealsManager.applySupportTicketCalculation, payload)
  //   yield call(refreshOrderWorker)
  // } catch (e) {

  // }
}

function* addAddictionalServiceWorker({ payload }) {
  try {
    const { id, contactsInfo } = yield select(getDetailOrder)
    const data = yield select(getAddictionalServices)
    const items = data.items
      .filter(x => payload.services.includes(x.type))
      .map(x => x.id)
    if (items.length) {
      yield call(OrderManager.addAddictionalServicer, {
        orderId: id,
        additionalServiceIds: items,
        additionalContactNameInfo:
          payload.additionalContactNameInfo || contactsInfo.contactName || "",
        additionalContactPhoneInfo:
          payload.additionalContactPhoneInfo || contactsInfo.contactPhone || ""
      })
      yield call(refreshOrderWorker)
      yield put(addictionalServiceActionsSuccess(payload.services))
    }
  } catch (e) {

  }
}

function* deleteAddictionalServiceWorker({ payload }) {
  try {
    const { id } = yield select(getDetailOrder)
    const data = yield select(getAddictionalServicesNew)
    const items = data.items
      .filter(x => payload.includes(x.type))
      .map(x => x.id)
    if (items.length > 0) {
      yield call(OrderManager.deleteAddictionalService, {
        orderId: id,
        additionalServiceIds: items
      })
      yield call(refreshOrderWorker)
      yield put(addictionalServiceActionsSuccess(payload))
    }
  } catch (e) {

  }
}

export function* issueTicketsSaga(action) {
  const { payload } = action
  const isCredit = payload.typePaid === 'credit'
  try {
    yield put(setLoadingStatusCredit(true));
    const response = isCredit
      ? yield call(
        OrderManager.issueTicketsByCredit,
        payload.orderId,
        payload?.userId)
      : yield call(
        OrderManager.issueTicketsByCreditOrInvoice,
        payload.orderId,
        payload.typePaid,
        payload?.userId)
    yield put(issueTicketsSuccess(response))
    yield delay(1000);
    yield call(refreshOrderWorker)
    yield put(setLoadingStatusCredit(false));
  } catch (err) {
    yield delay(1000);
    yield call(refreshOrderWorker)
    yield put(setLoadingStatusCredit(false));
    yield put(getModalErrorText(err.message))
    yield put(showModalError(true))
    //err
  }
}

export function* getOrderPaymentsSaga(action) {
  const { payload } = action
  try {
    const response = yield call(
      OrderManager.getOrderPayments,
      payload.orderNumber
    )
    // console.log(response);
    yield put(getOrderPaymentsSuccess(response))
  } catch (err) {
    //err
  }
}

export function* addInsurancesWorker(action) {
  const state = yield select(getDetailOrder)

  yield call(
    OrderManager.addInsurance,
    state.orderPositions[0].id,
    action.payload
  )
  yield call(refreshOrderWorker)
}

export function* deleteInsurancesWorker(action) {
  const state = yield select(getDetailOrder)

  yield call(
    OrderManager.deleteInsurance,
    state.orderPositions[0].id,
    action.payload
  )
  yield call(refreshOrderWorker)
}

export function* issueOrderPositionSaga(action) {
  const { payload } = action
  try {
    yield put(setLoadingStatusCredit(true));
    const response = yield call(
      OrderManager.issueOrderPosition,
      payload.orderNumber,
      payload.orderPositionId
    )
    // console.log(response);
    yield put(issueOrderPositionSuccess(response))
    yield call(refreshOrderWorker)
    yield put(setLoadingStatusCredit(false));
  } catch (err) {
    //err
  }
}

export function* getAgentOrderBillSaga(action) {
  const { payload } = action
  try {
    yield put(setLoadingStatusCredit(true));
    yield call(OrderManager.getAgentOrderBill, payload.orderId)
    yield delay(1000);
    yield call(refreshOrderWorker)
    yield put(setLoadingStatusCredit(false));
  } catch (err) {
    yield delay(1000);
    yield call(refreshOrderWorker)
    yield put(setLoadingStatusCredit(false));
    console.log(err);
  }
}

export function* promocodeApplySaga(action) {
  const { payload } = action;
  try {
    const response = yield call(OrderManager.promocodeApply, payload);
    yield put(promocodeApplySuccess({
      message: response.message,
      error: response.code === 404,
      success: !!response?.isSuccess,
    }));
    yield call(refreshOrderWorker);
  } catch (err) {
    // yield put(promocodeApplySuccess(err.message));
    yield put(promocodeApplySuccess({
      message: 'Неверный промокод',
      error: true,
      success: false,
    }));
    //err
  }
}

export function* getQrSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(OrderManager.createPayment, payload);
    yield put(createPaymentSuccess(response));
    const data = {
      paymentNumber: response.paymentNumber,
      tinkoffSettingType: 1,
      tinkoffQrType: 0,
    }
    const qr = yield call(OrderManager.getQr, data);
    yield put(getQrSuccess(qr));
  } catch (err) {
    //err
  }
}

export default function* orderFlow() {
  yield all([
    takeEvery(addAddictionalService.getType(), addAddictionalServiceWorker),
    takeEvery(deleteAddictionalService.getType(), deleteAddictionalServiceWorker),
    takeLatest(getOrderByNumber.getType(), getOrderByNumberWorker),
    takeLatest(getOrdersList.getType(), getOrdersListWorker),
    takeLatest(payOrderRequest.getType(), payOrderRequestWorker),
    takeLatest(printOrderRequest.getType(), printOrderRequestWorker),
    takeLatest(confirmPaymentRequest.getType(), confirmPaymentRequestWorker),
    takeLatest(refreshOrder.getType(), refreshOrderWorker),
    takeLatest(cancelOrderPositionRequest.getType(), cancelOrderPositionRequestWorker),
    takeLatest(getHistoryRequest.getType(), getHistoryRequestWorker),
    takeLatest(getAgentOrderBillRequest.getType(), getAgentOrderBillSaga),
    takeLatest(getOrdersListNext.getType(), getOrdersListNextWorker),
    takeLatest(approveNotification.getType(), approveNotificationWorker),
    takeLatest(cancelNotification.getType(), cancelNotificationWorker),
    takeLatest(issueTicketsRequest.getType(), issueTicketsSaga),
    takeLatest(getOrderPaymentsRequest.getType(), getOrderPaymentsSaga),
    takeLatest(addInsurances.getType(), addInsurancesWorker),
    takeLatest(deleteInsurances.getType(), deleteInsurancesWorker),
    takeLatest(issueOrderPositionRequest.getType(), issueOrderPositionSaga),
    takeLatest(promocodeApply.getType(), promocodeApplySaga),
    takeLatest(getQrRequest.getType(), getQrSaga),
  ])
}
