import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import {Button, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {isEmpty} from "lodash";
import Document from "./Document";
import useErskStore from "../store";
import {emailRegExp} from "../../../constants/reg-exps";
import FlagRU from "../../../asset/icons/flags/ru";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const UploadForm = () => {

	const phoneRegExp =
		/^[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

	const hiddenFileInput = useRef(null)
	const actions = useErskStore(({ actions }) => actions)
	const documents = useErskStore(({ documents }) => documents)
	const userData = useErskStore(({ userData }) => userData)
	const userId = useErskStore(({ userId }) => userId)

	const [fields, setFields] = useState(userData)

	const [error, setError] = useState({
		phone: '',
		email: '',
		dogovor: '',
		documents: ''
	})

	const handleChangeFile = async (event) => {
		const fileUploaded = event.target.files[0];
		actions.uploadFiles(fileUploaded)
	};

	const openLoadFile = () => {
		hiddenFileInput.current.click()
		setError({...error, documents: ''})
	}

	const handleUploadDocuments = () => {
		let data = {...error}
		if (fields.phone === '') {
			data = {...data, phone: 'Укажите номер телефона клиента'}
		}
		if (fields.email === '') {
			data = {...data, email: 'Укажите почту клиента'}
		}
		if (fields.dogovor.trim() === '') {
			data = {...data, dogovor: 'Укажите номер договора'}
		}
		if (isEmpty(documents)) {
			data = {...data, documents: 'Добавьте хотя бы один документ'}
		}
		if (!phoneRegExp.test(fields.phone) && fields.phone !==  '') {
			data = {...data, phone: 'Телефон указан некорректно'}
		}
		if (!emailRegExp.test(fields.email) && fields.email !==  '') {
			data = {...data, email: 'Email указан некорректно'}
		}
		setError(data)

		if (Object.values(data).every(item => item === '')) {
			actions.setUserData(fields)
			actions.getUserId(fields.email)
		}
	}

	useEffect(() => {
		if (userId) {
			actions.uploadContract()
		}
		// eslint-disable-next-line
	}, [userId])

	const handleChangeField = prop => e => {
		setError({...error, [prop]: ''})
		setFields({...fields, [prop]: e.target.value})
	}

	return (
		<div className={'upload-documents__form'}>
			<input type="file" ref={hiddenFileInput} style={{display: 'none'}} onChange={handleChangeFile} accept="application/pdf" />
			<Box sx={{display: 'flex'}}>
				<div
					className={`phone-code ${error.phone ? 'error-phone-code' : ''}`}
				>
					<Box sx={{marginRight: '10px'}}><FlagRU /></Box>
					<Box sx={{fontSize: '16px'}}>+7</Box>
					<Box sx={{marginLeft: 'auto', height: '24px'}}><ExpandMoreIcon /></Box>
				</div>
				<TextField
					fullWidth
					variant={'outlined'}
					label={'Телефон'}
					value={fields.phone}
					error={!!error.phone}
					onChange={handleChangeField('phone')}
				/>
			</Box>
			<div style={{color: 'red', marginTop: 5, fontSize: 12, marginBottom: 16}}>{error.phone}</div>
			<Box mb={2}>
				<TextField
					fullWidth
					variant={'outlined'}
					label={'Email'}
					value={fields.email}
					helperText={error.email}
					error={!!error.email}
					onChange={handleChangeField('email')}
				/>
			</Box>
			<Box mb={2}>
				<TextField
					fullWidth
					variant={'outlined'}
					label={'№ договора'}
					value={fields.dogovor}
					helperText={error.dogovor}
					error={!!error.dogovor}
					onChange={handleChangeField('dogovor')}
				/>
			</Box>
			<Box mb={2}>
				<Button
					className={'button-upload__file'}
					variant={'outlined'}
					fullWidth
					onClick={openLoadFile}
				>
					<AddIcon style={{marginRight: 10}}/> Загрузите PDF-документ
				</Button>
				{error.documents && <div style={{color: 'red', marginTop: 5, fontSize: 12}}>{error.documents}</div>}
			</Box>
			{!isEmpty(documents) && documents.map(item => <Document file={item} />)}
			<Box>
				<Button
					className={'button-upload'}
					variant={'contained'}
					fullWidth
					onClick={handleUploadDocuments}
				>
					Загрузить документы в ЛК клиента
				</Button>
			</Box>
		</div>
	);
};

export default UploadForm;