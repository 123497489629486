import React from "react"
import AgentBalance, {AgentBalanceSkeleton} from "../../components/avia-agents/agent-finance/AgentBalance"
import styled from "styled-components"
import AgentUnpaidOrders from "../../components/avia-agents/agent-finance/AgentUnpaidOrders"
import AgentOverPaidOrders from "../../components/avia-agents/agent-finance/AgentOverPaidOrders"
import AgentCreditHistory from "../../components/avia-agents/agent-finance/AgentCreditHistory"
import GetBillByOrderId from "../../components/avia-agents/agent-finance/GetBillByOrderId"
import AgentReport from "../../components/avia-agents/agent-finance/AgentReport"
import PaymentHistory from "../../components/avia-agents/agent-finance/PaymentHistory"
import ReportError from "../../components/avia-agents/agent-finance/ReportError"
// import AgentDeposit from "../../components/avia-agents/agent-finance/AgentDeposit"
// import OrderHistory from '../../components/avia-agents/agent-finance/OrderHistory';
import { getUserState } from "../../store/modules/user"
import { useSelector, useDispatch } from "react-redux"
import { showModalError } from "../../store/modules/orders"
import {
  getAgentReportFailure,
  getAgentInfo
} from "../../store/modules/agents/agent-finance/duck"
import { getAgentSiginState } from "../../store/modules/agents/agent-finance/selectors"
import { hideSnackbar,resetData } from "../../store/modules/agents/agent-finance/duck"
import cancelIcon from '../../asset/images/avia/agents/cancelIcon.svg'
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import Grid from '@mui/material';

const CancelIcon = styled.img.attrs({ src: cancelIcon })`
  margin-right: 10px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  color: #4872f2;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  width: 300px;
  cursor: pointer;
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #dcdcdc;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoadingRow = () => (
  <div>
  <LoadingContainer>
    <div style={{display: 'flex'}}>
      <Skeleton variant='rounded' width={30} height={30} sx={{ marginRight: '10px' }}/>
      <Skeleton  width={376} sx={{ lineHeight: '120%',fontSize: '18px' }}/>
    </div>
    <Skeleton variant='rounded' width={30} height={30}/>
  </LoadingContainer>
  <Skeleton width={552}  sx={{ lineHeight: '120%',fontSize: '16px',marginLeft: '40px' }}/>
  <Separator/>
  </div>
)

const AgentFinance = () => {
  const dispatch = useDispatch()
  const modalErrorVisible = useSelector(
    state => state.orders.orderDetail.modalErrorVisible
  )
  const isLoading = useSelector(state => state.agents.agentInfoLoadingState)

  const [dateCreditLimit, setDateCreditLimit] = React.useState(new Date())
  const [dateReport, setDateReport] = React.useState(null)
  const [typeReport, setTypeReport] = React.useState("Single")

  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState(new Date())
  const [orderNumber, setOrderNumber] = React.useState("")
  const [tteNumber, setTteNumber] = React.useState("")

  const [errorModal, setErrorModal] = React.useState(false)
  const [showErrorModal, setShowErrorModal] = React.useState(false)

  const agent = useSelector(getAgentSiginState)

  const errorInfo = useSelector(state => state.agents.errorModal)

  const agentRequestsInfo = useSelector(state => state.agents.agentRequestsInfo)
  const snackbarText = useSelector(state => state.agents.errorSnackbar)

  const userState = useSelector(getUserState)

  React.useEffect(() => {
    if (errorInfo) {
      setErrorModal(true)
      dispatch(getAgentReportFailure(false))
    }

    if (modalErrorVisible) {
      setShowErrorModal(true)
      dispatch(showModalError(false))
    }
  }, [errorInfo, modalErrorVisible])

  React.useEffect(() => {
    dispatch(
      getAgentInfo({
        PartnerId: agent?.agentSamoCode ?? ""
      })
    )
    return () => dispatch(resetData());
  }, [])

  const handleAlertClose = () => {
    dispatch(hideSnackbar())
  }

  const resetFields = () => {
    setDateCreditLimit(new Date())
    setOrderNumber("")
    setTteNumber("")
    setDateReport(null)
    setStartDate(new Date())
    setEndDate(new Date())
    dispatch(resetData())
  }

  if(isLoading) {
    return <>
      <AgentBalanceSkeleton/>
      <div style={{marginTop: 50}}>
        <LoadingRow/>
        <LoadingRow/>
        <LoadingRow/>
        <LoadingRow/>
        <LoadingRow/>
        <LoadingRow/>
      </div>
    </>
  }
  return (
    <>
      <Snackbar open={snackbarText} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          Ошибка сервера
        </Alert>
      </Snackbar>
      {errorModal && (
        <ReportError errorModal={errorModal} setErrorModal={setErrorModal} />
      )}
      <>
        <AgentBalance />
        {agentRequestsInfo?.finance.ordersNotPaid.enabled && (
          <AgentUnpaidOrders />
        )}
        {agentRequestsInfo?.finance.paymentHistory.enabled && (
          <PaymentHistory
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
        {agentRequestsInfo?.finance.getBill.enabled && (
          <GetBillByOrderId
            setOrderNumber={setOrderNumber}
            orderNumber={orderNumber}
            setTteNumber={setTteNumber}
            tteNumber={tteNumber}
          />
        )}

        <AgentReport
          dateReport={dateReport}
          setDateReport={setDateReport}
          margin={userState?.data?.role === "PluginManager"}
          typeReport={typeReport}
          setTypeReport={setTypeReport}
        />

        {/*пока скрыли https://jira-new.fstravel.com/browse/TTED-62*/}
        {/*{agentRequestsInfo?.finance.depositBills.enabled && <AgentDeposit />}*/}

        {agentRequestsInfo?.finance.creditLimitHistory.enabled && (
          <AgentCreditHistory
            setDateCreditLimit={setDateCreditLimit}
            dateCreditLimit={dateCreditLimit}
          />
        )}
        {agentRequestsInfo?.finance.ordersOverPaid.enabled && (
          <AgentOverPaidOrders />
        )}

        <FlexRow onClick={() => resetFields()}>
          <CancelIcon /> <div>Сбросить все заполненные поля</div>
        </FlexRow>
      </>

      {/* <OrderHistory /> */}
    </>
  )
}

export default AgentFinance
