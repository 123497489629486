import React from 'react'
import StaticText from './components/StaticText'
import EmptyLine from './components/EmptyLine'
import BannerFullWidth from './components/BannerFullWidth'
import TextWithBackground from './components/TextWithBackground'
import TextBlock from './components/TextBlock'
import TilesIcons from './components/TilesIcons'
import PhotoTiles from './components/PhotoTiles'
import StaticButton from './components/StaticButton'
import Image1to4 from './components/Image1to4'
import Image1to4Old from './components/Image1to4Old'
import GenerationListsTables from './components/GenerationListsTables'
import TilesPhotoTextBadge from './components/TilesPhotoTextBadge'
import ListLinks from './components/ListLinks'
import DownloadFiles from './components/DownloadFiles'
import StaticSpoiler from './components/StaticSpoiler'
import StaticH1 from './components/StaticH1'
import TextShadow from './components/TextShadow'
import StaticFrame from './components/StaticFrame'
import BlockWithTabs from './components/BlockWithTabs'
import BannerHorFull from './components/BannerHorFull'
import TilesPhotoText from './components/TilesPhotoText'
import StaticBannerCard from './components/StaticBannerCard'
import SelectionExpertNews from './components/SelectionExpertNews'
import Youtube from './components/Youtube'
import PopupBanner from './components/PopupBanner'
import ColumnsPage from './components/ColumnsPage'
import SimpleSelectionOfTours from './components/SimpleSelectionOfTours'
import BlockWithActions from './components/BlockWithActions'
import LandingVisa from './components/LandingVisa'
import LandingDoing from './components/LandingDoing'
import LandingAnnounce from './components/LandingAnnounce'
import LandingResort from './components/LandingResort'
import TabsSmallSixCards from './components/tabsSmallSixCards'
import ExcursionsFilters from './components/ExcursionsFilters'
import { isEmpty } from 'lodash'
import AdditionalServicesByLocation from './components/AdditionalServicesByLocation'
import TilesBillets from './components/TilesBillets'
import PhotoGallery from './components/photoGallery'
import IconListPopup from './components/IconListPopup'
import CruiseRoutes from './components/CruiseRoutes'
import TextBackgroundImage from './components/TextBackgroundImage'
import BlockTable from "./components/BlockTable";
import BlockWithButtons from './components/blockWithButtons'
import YachtSearchByLocation from './components/YachtSearchByLocation'

const StaticBlocks = ({ blocks, countryId = null }) => {
	const resortBlocks = blocks
		.filter(item => item.component_name === 'landing-Resort')
		.map(item => JSON.parse(item.content))
	const renderBlock = () => {
		return blocks.map(item => {
			switch (item.component_name) {
				case 'cruiseRoutes':
					return (
						<CruiseRoutes
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Text':
					return (
						<StaticText
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'empty-Lines':
					return <EmptyLine key={item.id} content={JSON.parse(item.content)} />
				case 'banner-Full-Width':
					return (
						<BannerFullWidth key={item.id} content={JSON.parse(item.content)} />
					)
				case 'textWithBackground':
					return (
						<TextWithBackground
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'text-Block':
					return (
						<TextBlock
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
						/>
					)
				case 'tiles-Icons':
					return (
						<TilesIcons
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Photo-Tiles':
					return (
						<PhotoTiles
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
						/>
					)
				case 'static-Button':
					return (
						<StaticButton
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Img-From1-To4-New':
					return (
						<Image1to4
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							show_desktop={item.show_desktop}
							show_mobile={item.show_mobile}
							title={item.title}
						/>
					)
				case 'static-Img-From1-To4':
					return (
						<Image1to4Old
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
							show_desktop={item.show_desktop}
							show_mobile={item.show_mobile}
						/>
					)
				case 'generation-lists-tables':
					return (
						<GenerationListsTables
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'tiles-Photo-Text-Badge':
					return (
						<TilesPhotoTextBadge
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-H1':
					return (
						<StaticH1
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'blockWithListLinks':
					return (
						<ListLinks
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'blockDownloadFiles':
					return (
						<DownloadFiles
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Spoiler':
					return (
						<StaticSpoiler
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
						/>
					)
				case 'static-Text-Shadow':
					return (
						<TextShadow
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Frame':
					return (
						<StaticFrame
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'tiles-Photo-Text':
					return (
						<TilesPhotoText
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'static-Banner-Card':
					return (
						<StaticBannerCard
							key={item.id}
							content={item.content}
							title={item.title}
						/>
					)
				case 'selectionExpertNews':
					return (
						<SelectionExpertNews
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'block-with-tabs':
					return (
						<BlockWithTabs
							id={item.id}
							key={item.id}
							content={item.content}
							title={item.title}
						/>
					)
				case 'static-Banner-Hor-Full':
					return (
						<BannerHorFull
							key={item.id}
							content={item.content}
							title={item.title}
						/>
					)
				case 'video-Youtube':
					return (
						<Youtube
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'popupBanner':
					return (
						<PopupBanner
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'columnsPage':
					return (
						<ColumnsPage
							key={item.id}
							content={item.content}
							title={item.title}
						/>
					)
				case 'blockWithActions':
					return (
						<BlockWithActions
							key={item.id}
							showMobile={item.show_mobile}
							showDesktop={item.show_desktop}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'landing-Visa':
					return (
						<LandingVisa
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
							countryId={countryId}
						/>
					)
				case 'simple-Selection-Of-Tours':
					return (
						<SimpleSelectionOfTours
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
						/>
					)
				case 'landing-Doing':
					return (
						<LandingDoing
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'landing-Announce':
					return (
						<LandingAnnounce
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
							countryId={countryId}
						/>
					)
				case 'tabsSmallSixCards':
					return (
						<TabsSmallSixCards
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'additionalServicesByLocation':
					return (
						<AdditionalServicesByLocation
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'excursionsFilters':
					return (
						<ExcursionsFilters
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'tilesBillets':
					return (
						<TilesBillets
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'photoGallery':
					return (
						<PhotoGallery
							id={item.id}
							key={item.id}
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'icon-List-Popup':
					return (<IconListPopup key={item.id} content={JSON.parse(item.content)} title={item.title} show_desktop={item.show_desktop} show_mobile={item.show_mobile} />)
				case 'textBackgroundImage':
					return (
						<TextBackgroundImage
							content={JSON.parse(item.content)}
							title={item.title}
						/>
					)
				case 'blockWithButtons':
					return (<BlockWithButtons content={JSON.parse(item.content)} title={item.title} />)

				case 'blockTable':
					return <BlockTable content={JSON.parse(item.content)} title={item.title} />
				case "yachtSearchByLocation":
					return <YachtSearchByLocation key={item.id} title={item.title} content={JSON.parse(item.content)} />
				default:
					return null
			}
		})
	}

	return (
		<>
			{!isEmpty(resortBlocks) && <LandingResort content={resortBlocks} />}
			{renderBlock()}
		</>
	)
}

export default StaticBlocks
