import React, { useRef, useState, useEffect } from 'react';
import './style.scss'
import { InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import iconSearch from '../../../../../asset/images/chosen-sprite.png'
import { isEmpty } from "lodash";
import { latinRegExp, cyrillicRegExp } from '../../../../../constants/reg-exps'
import { convertTo } from '../../../../../functions/convertLang'

const EMPTY_STRING = ''

const HotelSelect = ({ lists, onChange, title, empty = false, value }) => {
    const [showBody, setShowBody] = useState(false)
    const [inputValue, setInputValue] = useState(empty && (!value || !value?.length) ? '----' : lists.find(item => item.id.toString() === value.toString()) ? lists.find(item => item.id.toString() === value.toString()).name : lists[0].name)
    const [selectValue, setSelectValue] = useState(empty && (!value ) ? '----' : lists.find(item => item.id.toString() === value.toString()) ? lists.find(item => item.id.toString() === value.toString()).name : lists[0].name)
    const [useFilter, setUseFilter] = useState(false)
    const ref = useRef(null);
    const textInput = useRef(null);

    useEffect(() => {
        function handleClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowBody(false)
            }
        }
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref]);

    useEffect(() => {
        if (textInput.current) {
            textInput.current.focus();
        }
    }, [textInput, showBody])

    useEffect(() => {
        if (!isEmpty(lists) && !empty) {
            if (lists.find(item => item?.id?.toString() === value?.toString())) {
                setSelectValue(lists.find(item => item?.id?.toString() === value?.toString()).name)
            } else {
                setSelectValue(lists[0].name)
            }
        }
        if (!value) {
            setSelectValue('Адрес проживания на курорте')
        }
    }, [lists, value, empty])

    if (!lists) return null

    const toggle = () => {
        setShowBody(!showBody)
    }

    const handleChange = (id) => {
        const item = id !== EMPTY_STRING ? lists.find(item => item.id === id) : ''

        setShowBody(false)
        setSelectValue(item?.name ?? 'Адрес проживания на курорте')
        setUseFilter(false)
        onChange(item?.id ?? item)
    }


    const changeInput = (e) => {
        setInputValue(e.target.value)
        setUseFilter(true)
    }

    const getFiltered = () => {
        let result
        if (!inputValue || !useFilter) {
            result = lists
        } else {
            result = lists.filter(el => {
                let value = inputValue.toLowerCase()
                let name = el.name ? el.name.toLowerCase() : null
                let nameAlt = el.stateIso ? el.stateIso.toLowerCase() : el.nameAlt ? el.nameAlt.toLowerCase() : null

                if (!name) {
                    return false
                }
                if (latinRegExp.test(value) && (name.includes(convertTo('ru', value)) || nameAlt?.includes(convertTo('ru', value)))) {
                    return true
                }
                if (cyrillicRegExp.test(value) && (name.includes(convertTo('eng', value)) || nameAlt?.includes(convertTo('eng', value)))) {
                    return true
                }
                if (nameAlt?.includes(value)) return true
                if (name.includes(value)) return true
                return false
            })
        }
        return result
    }


    const filtered = getFiltered()

    return (
        <div className="v-departure-base" ref={ref}>
            <div className="v-departure">
                <div onClick={toggle}>
                    <div>
                        <TextField
                            id={'departure'}
                            label={title}
                            variant="outlined"
                            fullWidth
                            autoComplete={'off'}
                            value={selectValue}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><ArrowDropDownIcon /></InputAdornment>,
                            }}
                        />
                    </div>
                </div>
                {showBody && <div className="v-departure__body-container">
                    <div className={'chosen-search'}>
                        <input
                            type="text"
                            onChange={changeInput}
                            autoComplete={'off'}
                            className={'chosen-search__field'}
                            ref={textInput}
                            style={{ backgroundImage: `url(${iconSearch})` }}
                        /></div>
                    <div className="v-departure__body">
                        {empty &&
                            <div
                                key={"empty"}
                                className="v-departure__elem v-text-16"
                                onClick={() => handleChange(EMPTY_STRING)}
                            >
                                ----
                            </div>
                        }
                        {useFilter && isEmpty(filtered) && <div className={'empty-search'}>Совпадений не найдено</div>}
                        {filtered.map((item) => {
                            return (
                                <div
                                    key={item.id || item}
                                    className={`v-departure__elem v-text-16 ${item.withRegularFreight ? 'regular' : ''}`}
                                    onClick={() => handleChange(item.id || item)}
                                >
                                    {item.name || item}
                                </div>
                            )
                        })}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default HotelSelect;