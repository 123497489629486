import React from 'react';

import './style.scss'
import {format} from "date-fns";

const InsuranceDescriptionBlock = ({ draft, insurance }) => {

	return (
		<div className={'insurance-sravni'}>
			<div className="block-header">
				<h3 className="title">Страховка</h3>
			</div>
			<div className="block-body">
				<div className="grid-tour header">
					<div>Страховая компания</div>
					<div>Название страховки</div>
					<div>Страна</div>
					<div>Сумма покрытия</div>
					<div>Даты</div>
					<div>Кол-во туристов</div>
				</div>
				<div className="grid-tour body">
					<div>{insurance.insuranceCompanyName}</div>
					<div>{insurance.name}</div>
					<div>{insurance.destination}</div>
					<div>{insurance.lifeInsuranceAmount.toLocaleString('ru', {})} {insurance.currencyIsoCode}</div>
					<div>{format(new Date(insurance.policyStartDate), 'dd.MM.yy')} - {format(new Date(insurance.policyEndDate), 'dd.MM.yy')}</div>
					<div>{insurance.adults}</div>
				</div>
			</div>
		</div>
	);
};

export default InsuranceDescriptionBlock;