import React, {useState} from 'react';
import {format} from "date-fns";
import useClaimsStore from "../../store";
import {ReactComponent as ClaimLink} from '../../../../../asset/icons/cabinet/share-link.svg'
import {ReactComponent as Calendar} from '../../../../../asset/icons/cabinet/calendar.svg'
import {ReactComponent as FlightTicket} from '../../../../../asset/icons/cabinet/flight_ticket.svg'
import {ReactComponent as Ruble} from '../../../../../asset/icons/cabinet/ruble.svg'
import {ReactComponent as ArrowDown} from '../../../../../asset/icons/cabinet/arrow-ios-down.svg'
import Skeleton from '@mui/material/Skeleton';
import {Button} from "@material-ui/core";
import {Box} from "@mui/material";
import useAuthStore from "../../../../Auth/store";

const ClaimDescription = ({ id, detail }) => {

	const claim = useClaimsStore(({ claim }) => claim.find(item => item.id === id))
	const actions = useAuthStore(({ actions }) => actions)
	const [isOpen, setOpen] = useState(false)
	const currentClaim = useClaimsStore(({ claims }) => claims?.find(item => item.claimId === id))

	const refreshLink = () => {
		actions.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer?CLAIM=' + id).then((url) => {
			window.open(url, '_blank', 'noreferrer')
		})
	}

	if (!claim) return (
		<div className={'claim-description'}>
			<div className={'info'}>
				<Skeleton width={320} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={200} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={150} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={220} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={320} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={200} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={150} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton width={220} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
			</div>
			<div className={'notes'}>
				<Skeleton sx={{ fontSize: '16px', lineHeight: '22px' }}/>
				<Skeleton height={250} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
			</div>
		</div>
	)

	const isShowPayBlock = !claim.claims[0].numberCheck && !claim.claims[0].partPay && !claim.claims[0].pay

	return (
		<div className={'claim-description'}>
			<div className={'info'}>
				<div>
					{claim.claims[0].claimLink !== 'Нет связанных заявок' && <div className={'claim-link'} style={{marginBottom: 10}}>
						<ClaimLink />
						<div style={{marginLeft: 10}}>
							<div>Связанная заявка:
								{claim.claims[0].claimLink.split(', ').filter(item => item !== id.toString()).map(link =>
									<a
										href={`${window.location.pathname}?claimId=${link}`}
										target={'_blank'}
										style={{fontWeight: 600, color: '#2E2E32', marginLeft: 5}}
									>
										№{link}
									</a>
								)}
							</div>
						</div>
					</div>}
					<div style={{marginBottom: 10}}>
						{claim.claims[0].agentAlias}
					</div>
					<div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
						<Calendar />
						<div style={{marginLeft: 5}}>{format(new Date(detail.dateBeg), 'dd.MM.yyyy')} — {format(new Date(detail.dateEnd), 'dd.MM.yyyy')}</div>
					</div>
					<div style={{display: "flex", marginBottom: 10}}>
						<FlightTicket />
						<div style={{marginLeft: 5}}>
							<div style={{fontWeight: 600, marginBottom: 5}}>{claim.claims[0].tourName}</div>
							<div>{claim.claims[0].spoName}</div>
						</div>
					</div>
					{!isShowPayBlock && <div style={{display: "flex"}}>
						<Ruble/>
						<div style={{marginLeft: 5}}>
							{!!claim.claims[0].numberCheck &&
								<div style={{marginBottom: 5}}>№ Счета: {claim.claims[0].numberCheck}</div>}
							<div style={{marginBottom: 5}}>{claim.claims[0].partPay}</div>
							<div>{claim.claims[0].pay}</div>
						</div>
					</div>}
				</div>
				<Box gap={1} display={'flex'} mt={1} flexDirection={'column'}>
					{currentClaim?.claimStatusId === 13 && <Button fullWidth className={'bron-button'} onClick={refreshLink}>Забронировать</Button>}
					<Button fullWidth className={'edit-button'} onClick={refreshLink}>Редактировать заявку</Button>
				</Box>

			</div>

			<div className={'notes'} style={{maxHeight: !isOpen ? '272px' : '100%'}}>
				{claim.notes.map(note =>
					<div style={{position: "relative"}}>
						<div style={{marginBottom: 10}}>{note.msgCaption}</div>
						<div dangerouslySetInnerHTML={{__html: note.msgText}} />
					</div>
				)}
				<div
					className={'collapse-block'}
					style={{position: !isOpen ? 'absolute' : 'relative'}}
				>
					<div className={'collapse-block__button'} style={{display: "flex"}} onClick={() => setOpen(!isOpen)}>
						<span>{!isOpen ? 'Развернуть' : 'Свернуть'}</span>
						<ArrowDown style={{transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}} />
					</div>
					{claim.claims[0].partComm && claim.claims[0].partComm !== ' null' && <div className={'collapse-block__comment'}>Примечание: <span dangerouslySetInnerHTML={{__html: claim.claims[0].partComm}} /></div>}
				</div>
			</div>
		</div>
	);
};

export default ClaimDescription;