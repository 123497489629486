import {

	Grid,

} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { format, parseISO } from 'date-fns'
import React from 'react'
import useAuthStore from '../../../Auth/store'
import CustomInput from '../components/CustomInput/CustomInput';
import CustomSelect from '../components/CustomSelect/CustomSelect';

import '../style.css'

export default function AboutAgency() {
	const [open, setOpen] = React.useState(false)
	const actions = useAuthStore(({ actions }) => actions)
	const agents = useAuthStore(({ agent }) => agent?.agents)

	const directorPositionsValues = useAuthStore((state) => state.directorPositions);
	const activitiesValues = useAuthStore((state) => state.activities);
	const ownershipsValues = useAuthStore((state) => state.ownerships);

	React.useEffect(() => {
		actions.getDirectorPositions()
		actions.getActivities()
		actions.getOwnerships()
	}, [])

	const handleChangeDirectorPositions = (value) => {
		if (directorPositionsValues?.length) {
			const currentDirectorPositions = directorPositionsValues.find(item => item.title === value)
			actions.changeAgentDirectorPosition(currentDirectorPositions)
		}
	}

	const handleChangeActivities = (value) => {
		if (activitiesValues?.length) {
			const currentActivities = activitiesValues.find(item => item.title === value)
			actions.changeAgentActivity(currentActivities)
		}
	}

	const handleChangeOwnership = (value) => {
		if (ownershipsValues?.length) {
			const currentOwnership = ownershipsValues.find(item => item.title === value)
			actions.changeAgentOwnership(currentOwnership)
		}
	}

	console.log(agents);

	if (!agents) return null

	actions.refreshToken()


	return (
		<div style={{backgroundColor: '#F6F8FA', padding: 24, borderRadius: 16, marginBottom: 24 }}>
			<div className='agency-title'>
				<div className='about-agency'>Общая информация</div>
				{!open ? (
					<ExpandMoreIcon
                        style={{ fontSize: 24, color: '#2E2E32' }}
						type='button'
						className='newagency-open-btn'
						onClick={() => setOpen(prev => !prev)}>
						  
					</ExpandMoreIcon>
				) : (
					<ExpandLessIcon
                        style={{ fontSize: 24, color: '#2E2E32' }}
						type='button'
						className='newagency-close-btn'
						onClick={() => setOpen(prev => !prev)}>
					</ExpandLessIcon>
				)}
			</div>
			{open && (
				<>  
					<Grid
						container
						spacing={3}
						style={{ marginTop: '12px', marginBottom: '8px' }}>


						<Grid item xs={6} align=''>
                            <CustomInput 
                                label='Рекламное название компании'
								disabled
                                required
                                value={agents.name}

                            />  
						</Grid>
						<Grid item xs={6}>
                            <CustomInput 
                                label='Официальное название компании'
								className={!agents.officialName? 'empty-input' : ''}
                                required
                                value={agents.officialName}
                                onChange={e => {
									actions.changeAgentOfficialName(e.target.value)
									}
                                }                    
                            />  
						</Grid>
					</Grid>

					<Grid container spacing={2} style={{ marginBottom: '8px' }}>
						<Grid item xs={12}>
                            <CustomInput 
                                label='Юридический адрес'
								className={!agents.pAddress? 'empty-input' : ''}
                                required
                                value={agents.pAddress}  
								onChange={e => {
									actions.changeAgentPAdress(e.target.value)
								}}                  
                            /> 
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={4} align='center'>
                            <CustomInput 
                                label='КПП'
                                value={agents.kpp}   
								onChange={e => actions.changeAgentKPP(e.target.value)}                                                     
                            /> 
						</Grid>
						<Grid item xs={4}>
                            <CustomInput 
                                label='ИНН'
								disabled
                                value={agents.inn}    
                                onChange={e => actions.changeAgentINN(e.target.value)}                         
                            /> 
						</Grid>
						<Grid item xs={4} align='right'>
                            <CustomInput 
                                label='ОГРН'
                                disabled
								value={agents.ogrn || ''}   
                                onChange={e => actions.changeAgentINN(e.target.value)}                         
                            />
						</Grid>
						<Grid item xs={6} align='left'>
                        <CustomInput 
                                label='Код по ОКПО'
								value={agents.okpo || ''}   
								onChange={e => actions.changeAgentOKPO(e.target.value)}                                              
                            />
						</Grid>
						<Grid item xs={6} align='right'>
                            <CustomInput 
                                label='Код по ОКВЭД'
								value={agents.okonh || ''}     
								onChange={e => actions.changeAgentOKONH(e.target.value)}                   
                            />
						</Grid>
						<Grid item xs={6}>
							<CustomInput 
                                label='Дата регистрации'
								required
								type='date'
								value={format(
									parseISO(agents?.registrationDate),
									'dd.MM.yyyy'
								).split(".").reverse().join("-")}   
								onChange={e => {
									actions.changeAgentRegistrationDate(e.target.value)
								}}         
                            />
						</Grid>
						<Grid item xs={6}>
							<CustomInput 
                                label='Система налогообложения'
								required
                                disabled
								value={agents.taxation.title}                  
                            />
						</Grid>
						<Grid item xs={6}>
							<CustomInput 
                                label='ФИО руководителя'
								required
								className={!agents.boss ? 'empty-input' : ''}
								value={agents.boss}  
								onChange={e => actions.changeAgentBoss(e.target.value)}         
                            />
						</Grid>

						<Grid item xs={6} >
							<CustomSelect 
									label='Должность подписанта договора'
									required
									className={!agents.directorPosition.title ? 'empty-input' : ''}
									value={agents.directorPosition.title}   
									options={[
										{ value: 'Бухгалтер', label: 'Бухгалтер' },
										{ value: 'ВРИО Директора', label: 'ВРИО Директора' },
										{ value: 'Генеральный директор', label: 'Генеральный директор' },
										{ value: 'Главный бухгалтер', label: 'Главный бухгалтер' },
										{ value: 'Директор', label: 'Директор' },
										{ value: 'Заместитель директора', label: 'Заместитель директора' },
										{ value: 'Индивидуальный предприниматель', label: 'Индивидуальный предприниматель' },
										{ value: 'ИО Директора', label: 'ИО Директора' },
										{ value: 'Исполнительный директор', label: 'Исполнительный директор' },
										{ value: 'Коммерческий директор', label: 'Коммерческий директор' },
										{ value: 'Финансовый директор', label: 'Финансовый директор' },
									]}       
									onChange={
										value =>  handleChangeDirectorPositions(value)
									}        
							/>
						</Grid>		
						<Grid item xs={6} >
							<CustomSelect
                                label='Форма собственности'
								required
								className={!agents.ownership.title ? 'empty-input' : ''}
								value={agents.ownership.title}
								onChange={(value)=> handleChangeOwnership(value)}
								options={[
									{ value: 'АО', label: 'АО' },
									{ value: 'ЗАО', label: 'ЗАО' },
									{ value: 'ИП', label: 'ИП' },
									{ value: 'ОАО', label: 'ОАО' },
									{ value: 'ООО', label: 'ООО' },
									{ value: 'Самозанятые', label: 'Самозанятые' },
									{ value: 'Товарищество с ограниченной ответственностью', label: 'Товарищество с ограниченной ответственностью' },
								]}
                            />
						</Grid>			
						<Grid item xs={6} >
							<CustomSelect
                                label='Основание права подписи'
								required
								className={!agents.worker ? 'empty-input' : ''}
								value={agents.worker}    
								onChange={value => actions.changeAgentWorker(value)}
								options={[
									{ value: 'Устав', label: 'Устав' },
									{ value: 'Свидетельства о регистрации ИП', label: 'Свидетельства о регистрации ИП' },
									{ value: 'Лист записи ЕГРИП', label: 'Лист записи ЕГРИП' },
									{ value: 'Доверенность', label: 'Доверенность' },
								]}         
                            />
						</Grid>
						<Grid item xs={12} align='left'>
							<CustomSelect 
                                label='Вид деятельности'
								required
								className={!agents.activity.title ? 'empty-input' : ''}
								value={agents.activity.title}    
								onChange={
									value =>  handleChangeActivities(value)
									}
								options={[
									{ value: 'Туризм', label: 'Туризм' },
									{ value: 'Другое', label: 'Другое' },
								]}               
                            />
						</Grid>
					</Grid>
				</>
			)}
		</div>
	)
}
