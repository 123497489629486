import React from 'react';
import NotFoundIcon from '../../asset/icons/find.png'
import { Box } from "@mui/material";

const SearchNotFound = ({ title }) => {
    return (
        <Box mb={4} textAlign={'center'}>
            <img src={NotFoundIcon} alt="" />
            <p style={{ fontSize: 16, fontWeight: 600, marginTop: 10 }}>{title || 'Ничего не найдено'}</p>
            <p>К сожалению, мы не нашли вариантов по вашим критериям поиска.<br /> Рекомендуем изменить параметры поиска</p>
        </Box>
    );
};

export default SearchNotFound;
