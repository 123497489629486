import React, {useState} from 'react';

import './style.css'
import {Box, Button, TextField} from "@material-ui/core";
import CalendarErsk from "./CalendarErsk";
import useErskStore from "../store";
import {isAfter} from "date-fns";
import FlagRU from "../../../asset/icons/flags/ru";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {emailRegExp} from "../../../constants/reg-exps";

const ReportErsk = () => {

	const phoneRegExp =
		/^[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

	const dateFrom = useErskStore(({ dateFrom }) => dateFrom)
	const dateTo = useErskStore(({ dateTo }) => dateTo)
	const actions = useErskStore(({ actions }) => actions)
	const [error, setError] = useState({
		from: '',
		to: '',
		phone: '',
		email: ''
	})
	const [inputValue, setInputValue] = useState({from: new Date(), to: new Date()})
	const [fields, setFields] = useState({
		phone: '',
		email: '',
		dogovor: ''
	})


	const handleGenerateReport = () => {
		let errors = {...error}
		if (!inputValue.from) {
			errors = {...errors, from: 'Укажите дату начала периода'}
		}
		if (!inputValue.to) {
			errors = {...errors, to: 'Укажите дату окончания периода'}
		}
		if (isAfter(new Date(dateFrom), new Date(dateTo)) && inputValue.from !== '') {
			errors = {...errors, from: 'Дата начала периода не может быть позднее даты окончания периода'}
		}
		if (isAfter(new Date(dateTo), new Date()) && inputValue.to !== '') {
			errors = {...errors, to: 'Дата окончания периода не может быть позднее текущей даты'}
		}
		if (!phoneRegExp.test(fields.phone) && fields.phone !==  '') {
			errors = {...errors, phone: 'Телефон указан некорректно'}
		}
		if (!emailRegExp.test(fields.email) && fields.email !==  '') {
			errors = {...errors, email: 'Email указан некорректно'}
		}
		setError(errors)

		if (Object.values(errors).every(item => item === '')) {
			actions.setUserData(fields)
			actions.generateReport()
		}
	}

	const handleChangeInputFrom = (value) => {
		setInputValue({...inputValue, from: value})
		setError({...error, from: ''})
	}

	const handleChangeInputTo = (value) => {
		setInputValue({...inputValue, to: value})
		setError({...error, to: ''})
	}

	const handleChangeFrom = (value) => {
		actions.changeDateFrom(value)
		setError({...error, from: ''})
		setInputValue({...inputValue, from: value})
	}

	const handleChangeTo = (value) => {
		actions.changeDateTo(value)
		setError({...error, to: ''})
		setInputValue({...inputValue, to: value})
	}

	const handleChangeField = prop => e => {
		setError({...error, [prop]: ''})
		setFields({...fields, [prop]: e.target.value})
	}


	return (
		<div className={'report-ersk'}>
			<h2>Формирование отчёта</h2>
			<div className={'report-ersk__form'}>
				<Box mb={2}>
					<CalendarErsk
						onChange={handleChangeFrom}
						onInputChange={handleChangeInputFrom}
						label="От"
						value={dateFrom}
						error={error.from}
					/>
				</Box>
				<Box mb={3}>
					<CalendarErsk
						onChange={handleChangeTo}
						onInputChange={handleChangeInputTo}
						label="До"
						value={dateTo}
						isDateTo
						error={error.to}
					/>
				</Box>
				<Box sx={{display: 'flex'}}>
					<div
						className={`phone-code ${error.phone ? 'error-phone-code' : ''}`}
					>
						<Box sx={{marginRight: '10px'}}><FlagRU /></Box>
						<Box sx={{fontSize: '16px'}}>+7</Box>
						<Box sx={{marginLeft: 'auto', height: '24px'}}><ExpandMoreIcon /></Box>
					</div>
					<TextField
						fullWidth
						variant={'outlined'}
						label={'Телефон'}
						value={fields.phone}
						error={!!error.phone}
						onChange={handleChangeField('phone')}
					/>
				</Box>
				<div style={{color: 'red', marginTop: 5, fontSize: 12, marginBottom: 16}}>{error.phone}</div>
				<Box mb={2}>
					<TextField
						fullWidth
						variant={'outlined'}
						label={'Email'}
						value={fields.email}
						helperText={error.email}
						error={!!error.email}
						onChange={handleChangeField('email')}
					/>
				</Box>
				<Box mb={2}>
					<TextField
						fullWidth
						variant={'outlined'}
						label={'№ договора'}
						value={fields.dogovor}
						helperText={error.dogovor}
						error={!!error.dogovor}
						onChange={handleChangeField('dogovor')}
					/>
				</Box>
				<Button
					className={'button-report'}
					variant={'contained'}
					fullWidth
					onClick={handleGenerateReport}
				>
					Сформировать отчёт
				</Button>
			</div>
		</div>
	);
};

export default ReportErsk;