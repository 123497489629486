import React from 'react'

import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@mui/material/Collapse'
import call from '../../../../asset/images/cabinet/call.svg'
import email from '../../../../asset/images/cabinet/email.svg'
import CircularProgress from '@mui/material/CircularProgress'
import useFranchisingStore from '../store'
import { isEmpty } from 'lodash'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

const ContactsBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 24px;
	margin-top: 24px;
`

const ContactsText = styled.div`
	display: flex;
	align-items: center;

	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #2e2e32;
`

const CallImage = styled.img.attrs({
	src: call,
})`
	width: 24px;
	height: 24px;
	margin-right: 4px;
`

const MailImage = styled.img.attrs({
	src: email,
})`
	width: 24px;
	height: 24px;
	margin-right: 4px;
	vertical-align: bottom;
`

const Link = styled.a`
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	color: rgba(72, 114, 242, 1);
`

const TabelContacts = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const TabelContactsHead = styled.div`
	display: flex;
	flex-direction: row;
	background: #edf1fe;
	border-radius: 16px 16px 0px 0px;
`

const TabelContactsTitle = styled.div`
	padding: 16px;

	width: 50%;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`

const TabelContactsRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-top: 1px solid #dbe0e5;
	&:first-child {
		border-top: none;
	}
`

const TableBody = styled.div`
	border: 1px solid #dbe0e5;
	border-top: none;
	border-radius: 0px 0px 16px 16px;
`

const TabelContactsText = styled.div`
	padding: 16px;

	width: 50%;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`
	// преобразование в массив  объекта и невалидных данных
	const getArray = (data) => {
		return Array.isArray(data)
		? data
		: data
		? Object.values(data)
		: []
	}

export default function FranchContacts() {
	const [isOpen, setIsOpen] = React.useState(true)
	const franchisingData = useFranchisingStore((state) => state.franchising)
	const transformFranchisingData = getArray(franchisingData)
	const curators = transformFranchisingData?.find(obj  => obj.block === 'curators')
	const loading = useFranchisingStore((state) => state.loading)

	if (loading) {
		return <CircularProgress size={20} />
	}

	return (
		<Container>
			<Row
				onClick={() => setIsOpen(!isOpen)}
				style={{ cursor: 'pointer' }}>
				<FlexRow>
					<Title>{curators?.title}</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => setIsOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<ContactsBlock>
					{curators?.content.text && <ContactsText>{curators?.content.text}</ContactsText>}
					{curators?.content.phone && <ContactsText><CallImage />{curators?.content.phone}</ContactsText>}
					{curators?.content.email &&<ContactsText><Link href={`mailto:${curators?.content.email}`}><MailImage />{curators?.content.email}</Link></ContactsText>}
					{!isEmpty(getArray(curators?.content?.curators)) && (
						<TabelContacts>
							<TabelContactsHead>
								<TabelContactsTitle>ФИО Куратора</TabelContactsTitle>
								<TabelContactsTitle>Добавочный номер</TabelContactsTitle>
							</TabelContactsHead>
							<TableBody>
							{getArray(curators?.content?.curators).map((curator, i) =>
								curator.name || curator.phone ? (
									<TabelContactsRow key={i}>
										<TabelContactsText>{curator.name}</TabelContactsText>
										<TabelContactsText>{curator.phone}</TabelContactsText>
									</TabelContactsRow>
								) : null
							)}
							</TableBody>
						</TabelContacts>
					)}
				</ContactsBlock>
			</Collapse>
		</Container>
	)
}
