import React from "react"
import { Form, Formik } from "formik"
import { FORM_INITIAL } from "./constants"
import CheckboxRow from "./CheckboxRow"
import SalesDateRow from "./SalesDateRow"
import CitiesSearchRow from "./CitiesSearchRow"
import { Button, Text } from "../../ui"
import styled from "styled-components"
import AdditionalFields from "./AdditionalFields"
import PriceRow from "./PriceRow"
import { useDispatch, useSelector } from "react-redux"
import {
  createFeeRequest,
  editFeesRequest
} from "../../../store/modules/agents/agent-fees/duck"
import { useNavigate, useParams } from "react-router-dom"
import { convertFeeToInitialValues } from "../../../store/modules/agents/agent-fees/utils"
import {
  selectAgentFees,
  selectAgentFeesLoading
} from "../../../store/modules/agents/agent-fees/selectors"
import * as yup from "yup"
import PrimaryButton from "../../ui/PrimaryButton"

const schema = yup.object().shape({
  amount: yup.number().required("Необходимо заполнить поле"),
  calculateType: yup.string().required("Необходимо заполнить поле"),
  applyTo: yup.string().required("Необходимо заполнить поле")
})

const OpenText = styled(Text)`
  display: block;
  margin: 35px 0;
  color: #4872f2;
  cursor: pointer;
`

const AgentRulesForm = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  
  const fees = useSelector(selectAgentFees)
  const user = useSelector(x => x.user)

  const [initialValues, setInitialValues] = React.useState(null)
  const navigate = useNavigate()
  const isLoadingFees = useSelector(selectAgentFeesLoading)

  React.useEffect(() => {
    if (!id) {
      setInitialValues(FORM_INITIAL)
    } else if (id && fees) {
      const fee = fees.find(x => x.id === id)
      const selectedInitial = convertFeeToInitialValues(fee)
      const passangersTypes = fee?.passengerTypes?.map(type => type.type)
      const ADT = fee?.passengerTypes?.find(x => x.type === "ADT")?.price
      const CLD = fee?.passengerTypes?.find(x => x.type === "CLD")?.price

      setInitialValues({
        ...selectedInitial,
        passengerTypes: passangersTypes,
        ADT: ADT?.toString(),
        CLD: CLD?.toString()
      })
    } else {
      console.log('else');
    }
  }, [])

  const onSubmit = (values, actions) => {
    values.isCharter = values.isCharter === "charter";

    if (id) {
      dispatch(editFeesRequest({ ...values, userLogin: user?.data?.email }))
      actions.resetForm({ values });
    } else {
      dispatch(createFeeRequest({ ...values, userLogin: user?.data?.email }))
    }
    navigate('/cabinet/avia-agent-info/fees')
  }

  return initialValues ? (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {props => (
        <Form>
          <CheckboxRow {...props} />
          <SalesDateRow {...props} />
          <CitiesSearchRow {...props} />
          <OpenText onClick={() => setIsOpen(val => !val)}>
            {!isOpen
              ? "Открыть дополнительные условия"
              : "Закрыть дополнительные условия"}
          </OpenText>
          {isOpen && <AdditionalFields {...props} />}
          <PriceRow {...props} />
          <PrimaryButton
            disabled={!props.dirty && isLoadingFees}
            type="submit"
            loading={isLoadingFees}
          >
              {id ? "Редактировать изменения" : " Сохранить изменения"}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  ) : null
}

export default AgentRulesForm
