import React, {useState} from 'react';
import {Alert} from "@mui/material";
import Switch from "@material-ui/core/Switch";
import {Box, Button, TextField} from "@material-ui/core";
import BirthDateInput from "../../../../components/BirthDateInputPL";
import CustomSelect from "../../../../components/Select";
import useBookingStore from "../../../newBooking/store";
import useLoyaltyStore from "../../store";
import {cyrillicRegExp, emailRegExp} from "../../../../constants/reg-exps";

const RegistrationForm = ({ count, phone = '', email = '' }) => {
	const people = useBookingStore(state =>
		state?.peoples?.find(item => item.key === 1)
	)
	const paxes = useBookingStore(({ paxes }) => paxes)
	let paxe
	if (paxes) {
		paxe = paxes.find(item => item.id === count)
	}
	const [isRegistration, setRegistration] = useState(false)
	const [value, setValue] = useState({
		lastName: paxe?.person.lastName || '',
		firstName: paxe?.person.firstName || '',
		birthDate: people?.born || '',
		mobilePhone: people?.phone || phone || '',
		email: people?.email || email || '',
		sex: people?.gender === 1 ? 'male' : 'female',
	})

	const [errors, setErrors] = useState({
		lastName: null,
		firstName: null,
		email: null,
		birthDate: null
	});

	const actions = useLoyaltyStore(({ actions }) => actions)
	const errorCreateUser = useLoyaltyStore(({ errorCreateUser }) => errorCreateUser)

	const changeGenderHandler = (e) => {
		setValue({...value, sex: e})
	}

	const changeFirstNameHandler = (event) => {
		setErrors({ ...errors, firstName: null });
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== "") {
			setErrors({
				...errors,
				firstName: `Имя должно содержать только кириллицу`,
			});
		} else {
			setErrors({ ...errors, firstName: null });
		}
		setValue({...value, firstName: event.target.value})
	};
	const changeLastNameHandler = (event) => {
		setErrors({ ...errors, lastName: null });
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== "") {
			setErrors({
				...errors,
				lastName: `Фамилия должна содержать только кириллицу`,
			});
		} else {
			setErrors({ ...errors, lastName: null });
		}
		setValue({...value, lastName: event.target.value})
	};
	const changeEmailHandler = event => {
		setErrors({ ...errors, email: null })
		actions.clearErrorCreateUser()
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				email: `Email указан некорректно`
			})
		} else {
			setErrors({ ...errors, email: null })
		}
		setValue({...value, email: event.target.value})
	}

	const setDateError = (value) => {
		setErrors({ ...errors, birthDate: value })
	}

	return (
		<>
			<Box mb={2}>
				<Alert severity='error' sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
					Клиент не зарегистрирован в программе лояльности. Зарегистрировать клиента?
					<span className={'switcher-pl'}>
					<Switch
						checked={isRegistration}
						onChange={(event) => {
							setRegistration(event.target.checked);
							actions.clearErrorCreateUser()
						}}
						color="primary"
					/>
				</span>
				</Alert>
			</Box>

			{errorCreateUser && <Box mb={2}>
				<Alert severity='error' sx={{width: '100%', display: 'flex', alignItems: 'center', color: 'red'}} icon={<div style={{width: 22}}></div>}>
					Ошибка: {errorCreateUser}
				</Alert>
			</Box>}

			{isRegistration &&
				<>
					<div className='members-form'>
						<div className='info'>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Фамилия'
									variant='outlined'
									fullWidth
									value={value.lastName}
									onChange={changeLastNameHandler}
									helperText={errors.lastName}
									error={!!errors.lastName}
									required
								/>
							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Имя'
									variant='outlined'
									fullWidth
									value={value.firstName}
									onChange={changeFirstNameHandler}
									helperText={errors.firstName}
									error={!!errors.firstName}
									required
								/>
							</Box>
							<Box>
								<BirthDateInput
									onChange={(date) => setValue({...value, birthDate: date})}
									count={count}
									label='Дата рождения *'
									value={value.birthDate}
									isExpiration={false}
									error={!!errors.birthDate}
									setDateError={setDateError}
									required
								/>
							</Box>
						</div>
						<div className='docs'>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='Телефон'
									variant='outlined'
									fullWidth
									// helperText={errors.phone}
									// error={!!errors.phone}
									value={value.mobilePhone}
									onChange={(event) => setValue({...value, mobilePhone: event.target.value})}
									required
									disabled={true}
								/>

							</Box>
							<Box style={{ marginBottom: 15 }}>
								<TextField
									label='E-Mail'
									variant='outlined'
									fullWidth
									helperText={errors.email}
									error={!!errors.email}
									value={value.email}
									onChange={changeEmailHandler}
									required
								/>
							</Box>
							<Box>
								<CustomSelect
									value={value.sex}
									options={[
										{ id: 'male', name: 'Муж.' },
										{ id: 'female', name: 'Жен.' }
									]}
									title='Пол *'
									count={count}
									onChange={changeGenderHandler}
									// onChange={changeGenderHandler}
									required
								/>
							</Box>
						</div>
					</div>
					<Box mb={2}>
						<Button
							variant={'outlined'}
							color={'primary'}
							onClick={() => actions.createClient(value)}
							disabled={Object.values(errors).some(item => item !== null) || Object.values(value).some(v => v === '')}
						>
							Зарегистрировать
						</Button>
					</Box>
				</>
			}
		</>
	);
};

export default RegistrationForm;