import {
	IconButton,
	styled,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import React from 'react'
import useClaimsStore from '../../store'
import EditTouristPopup from './EditTouristPopup'
import { ReactComponent as EditIcon } from '../../../../../asset/icons/edit_icon.svg'

import TouristPassportItem from './TouristPassportItem'
import { isEmpty } from 'lodash'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		whiteSpace: 'nowrap',
		fontFamily: ['Open Sans', 'Roboto', '"Helvetica Neue"'].join(','),
		color: '#2E2E32',
		borderColor: 'transparent',
		fontWeight: 600,
		fontSize: 14,
		lineHeight: '24px',
		backgroundColor: 'transparent',
		padding: '24px 8px 16px 8px'
	},
	[`&.${tableCellClasses.body}`]: {
		whiteSpace: 'nowrap',
		fontFamily: ['Open Sans', 'Roboto', '"Helvetica Neue"'].join(','),
		fontSize: 14,
		borderColor: 'transparent',
		fontWeight: 400,
		color: '#2E2E32',
		padding: '8px',
		lineHeight: '20px'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderRadius: '8px',
	padding: '24px',
	'&:nth-of-type(even)': {
		backgroundColor: '#F6F8FA'
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

export default function TouristsTable({ id }) {
	const tourists = useClaimsStore(
		({ claim }) => claim?.find(claim => claim.id === id)?.people
	)
	const actions = useClaimsStore(({ actions }) => actions)

	const [openEditPopup, setOpenEditPopup] = React.useState(false)

	const handleClickEditClose = () => {
		setOpenEditPopup(false)
	}

	const handleClickEditOpen = tourist => {
		actions.setTourist({
			...tourist,
			edit_name: tourist?.name
		})
		setOpenEditPopup(true)
	}
	const {flights, hotels, inscusts, insurances} = useClaimsStore(
		({ claim }) => claim?.find(claim => claim.id === id)
	) || {}
	const isPartnerInsurance = !isEmpty(insurances) && isEmpty(flights) && isEmpty(hotels) && isEmpty(inscusts)
	
	if (isEmpty(tourists)) return null
	return (
		<div>
			<EditTouristPopup
				handleClose={handleClickEditClose}
				openPopup={openEditPopup}
				id={id}
			/>
			<TableContainer
				sx={{
					borderRadius: '16px',
					overflowY: 'hidden',
					maxWidth: 780,
					'&::-webkit-scrollbar': {
						height: 4
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#D4D9DE',
						borderRadius: 2
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#4872F2',
						borderRadius: 2
					}
				}}>
				<Table aria-label='customized table'>
					<TableHead>
						<TableRow>
							<StyledTableCell align='left' style={{ paddingLeft: 0 }}>
								MR/MRS
							</StyledTableCell>
							<StyledTableCell align='left'>ФИО</StyledTableCell>
							<StyledTableCell align='left'>Дата рождения</StyledTableCell>
							<StyledTableCell align='left' style={{ paddingRight: 0 }}>
								Документ
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tourists?.map((tourist, i) => (
							<StyledTableRow key={i}>
								<StyledTableCell align='left' style={{ paddingLeft: 0 }}>
									{tourist.human}
								</StyledTableCell>
								<StyledTableCell align='left'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>
											<span>{tourist.name}</span>
										</div>
										{!isPartnerInsurance && <IconButton
											aria-label='edit-button'
											onClick={() => {
												handleClickEditOpen(tourist)
											}}>
											<EditIcon />
										</IconButton>}
									</div>
								</StyledTableCell>
								<StyledTableCell align='left'>
									{tourist.bornDate && format(parseISO(tourist.bornDate), 'dd.MM.yyyy')}
								</StyledTableCell>
								<StyledTableCell align='left'>
									<TouristPassportItem
										passportSerie={tourist.passportSerie}
										passportNumber={tourist.passportNumber}
										expirationDate={tourist.passportValidDate}
									/>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
