import React from "react"
import styled from "styled-components"
import Message from "./Message"
import moment from "moment"
import Text from '../Typography'
import { useMediaQuery } from "react-responsive"

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  & > div {
    margin-bottom: 30px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    & > div {
      margin-bottom: 10px;
    }
  }
  padding-right: 5px;
  min-height: 360px;
  max-height: 472px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: #DCDCDC;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }
`

const EmptyListText = styled(Text)`
  font-size: 18px;
  line-height: 24px;
  color: #b9b9b9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  user-select: none;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const MessageList = ({ items, scrollbar, ...props }) => {
  let prevDay = null
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const ref = React.useRef(null);

  React.useEffect(() => {
    if(ref.current) {
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  },[items.length])

  return (
    <Wrapper ref={ref}>
      {items.map(({ id, ...props }) => {
        if (prevDay === null) {
          prevDay = moment(props.date).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
        }
        const currentDate = moment(props.date).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        if (prevDay.diff(currentDate, "days") < 0) {
          const text = formatDayText(currentDate)
          prevDay = currentDate
          return (
            <React.Fragment key={id}>
              <div>
                <Text color="#B9B9B9" fontWeight="600">
                  {text}
                </Text>
              </div>
              <Message {...props} />
            </React.Fragment>
          )
        }
        return <Message key={id} {...props} />
      })}
      {items.length === 0 && (
        <EmptyListText>
          {!isMobile
            ? "У вас пока нет сообщений от специалиста"
            : "Нет сообщений от специалиста"}
        </EmptyListText>
      )}
    </Wrapper>
  )
}

function formatDayText(date) {
  const diff = date.diff(moment(), "days")
  if (isToday(diff)) {
    return "Сегодня"
  }
  if (isYesterday(diff)) {
    return "Вчера"
  }
  return date.format("D MMMM")
}

function isToday(diff) {
  return diff === 0
}

function isYesterday(diff) {
  return diff === -1
}

export default MessageList
