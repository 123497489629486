import React from 'react'

const BASE_IMAGE_URL = process.env.REACT_APP_FS_IMAGE_URL

function HotelSliderCopy({ images, id }) {
	const filteredImages = images.filter(
		image => image?.resolutionType === 'Source'
	)
	const mainPhoto = images.find(
		image =>
			image?.imageType === 'MainPhoto' && image?.resolutionType === 'Source'
	)

	return (
		<div className='hotel_slider'>
			<div className='slider-main-photo'>
				<div className='slider-slide photo-main'>
					<img
						crossOrigin='anonymous'
						className='slider-img'
						src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${
							mainPhoto?.imageType || images[0]?.imageType
						}-${mainPhoto?.resolutionType || images[0]?.resolutionType}-${
							mainPhoto?.fileName || images[0]?.fileName
						}?${new Date()}`}
						alt={mainPhoto?.alt}
						onError={e => {
							e.target.style.visibility = 'hidden'
						}}
					/>
				</div>
			</div>
			<div className='bottom-photo-block'>
				<div className='slider-slide photo-bottom'>
					<img
						crossOrigin='anonymous'
						className='slider-img'
						src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${
							mainPhoto
								? filteredImages[0]?.imageType
								: filteredImages[1]?.imageType
						}-${
							mainPhoto
								? filteredImages[0]?.resolutionType
								: filteredImages[1]?.resolutionType
						}-${
							mainPhoto
								? filteredImages[0]?.fileName
								: filteredImages[1]?.fileName
						}?${new Date()}`}
						alt={filteredImages[0]?.alt}
						onError={e => {
							e.target.style.visibility = 'hidden'
						}}
					/>
				</div>
				<div className='slider-slide photo-bottom'>
					<img
						crossOrigin='anonymous'
						className='slider-img'
						src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${
							mainPhoto
								? filteredImages[1]?.imageType
								: filteredImages[2]?.imageType
						}-${
							mainPhoto
								? filteredImages[1]?.resolutionType
								: filteredImages[2]?.resolutionType
						}-${
							mainPhoto
								? filteredImages[1]?.fileName
								: filteredImages[2]?.fileName
						}?${new Date()}`}
						alt={filteredImages[1]?.alt}
						onError={e => {
							e.target.style.visibility = 'hidden'
						}}
					/>
				</div>
				<div
					className='slider-slide photo-bottom'
					style={{ position: 'relative' }}>
					<img
						crossOrigin='anonymous'
						className='slider-img'
						src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${
							mainPhoto
								? filteredImages[2]?.imageType
								: filteredImages[3]?.imageType
						}-${
							mainPhoto
								? filteredImages[2]?.resolutionType
								: filteredImages[3]?.resolutionType
						}-${
							mainPhoto
								? filteredImages[2]?.fileName
								: filteredImages[3]?.fileName
						}?${new Date()}`}
						alt={filteredImages[2]?.alt}
						onError={e => {
							e.target.style.visibility = 'hidden'
						}}
					/>
					{filteredImages.length && (
						<div className='slider-photos-count'>
							{filteredImages.length} фото
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default HotelSliderCopy
