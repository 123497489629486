import React from 'react';
import styled from 'styled-components';
import {Text, Button} from '../../components/ui';
import {Link,useParams,useNavigate} from 'react-router-dom'

const Wrapper = styled.div`
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 64px;
    width: 440px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
`;

const Image = styled.img.attrs({src: '/media/failure-payment-icon.png'})`
    margin-bottom: 60px;
`;

const Description = styled(Text)`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #2E2E32;

    display: block;
    margin-bottom: 40px;

    & > strong, & > a {
        color: #4872F2;
        font-weight: 600;
    }
`;

const BackToOrder = styled(Button)`
    padding: 16px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`

export default function PaymentConfirmation() {
    const params = useParams();
    const navigateTo = useNavigate();

    return (
        <Wrapper>
            <Image/>
            <Description>
                Ошибка оплаты по заявке <Link to={`/cabinet/orders/${params.orderNumber}`}>№ {params.orderNumber}</Link> Сумма к оплате: <strong>5 000 ₽</strong>
            </Description>
            <BackToOrder onClick={() => navigateTo(`/cabinet/orders/${params.orderNumber}`)}>Вернуться к заявке</BackToOrder>
        </Wrapper>
    )
}