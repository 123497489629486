import React from 'react';
import {SvgIcon} from "@mui/material";

const FlagRU = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 34 24" style={{width: 34, height: 24}}>
			<g>
				<rect x="0.25" y="0.25" width="33.5" height="23.5" rx="5.75" fill="white" stroke="#F5F5F5" />
				<mask id="mask0_457_3815"  maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="24">
					<rect x="0.25" y="0.25" width="33.5" height="23.5" rx="5.75" fill="white" stroke="white" />
				</mask>
				<g mask="url(#mask0_457_3815)">
					<rect x="0.25" y="0.25" width="33.5" height="23.5" rx="5.75" fill="white" stroke="#F5F5F5" />
					<path  d="M0 16H34V8H0V16Z" fill="#0C47B7"/>
					<path  d="M0 24H34V16H0V24Z" fill="#E53B35"/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_457_3815">
					<rect width="34" height="24" rx="6" fill="white"/>
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default FlagRU;