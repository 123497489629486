import React from 'react'

import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box } from '@mui/material'
import DocumentsTable from './components/DocumentsTable'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
`

const CollapseContainer = styled(Container)`
	background: rgba(246, 248, 250, 1);
	border-radius: 16px;
	padding: 24px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.h3`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

export default function ClaimDocuments({ id }) {
	const [isOpen, toggleOpen] = React.useState(false)

	return (
		<CollapseContainer>
			<Row onClick={() => toggleOpen(!isOpen)} style={{ cursor: 'pointer' }}>
				<FlexRow>
					<Title style={{ margin: 0 }}>Документы</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => toggleOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<Box>
					<DocumentsTable id={id} />
				</Box>
			</Collapse>
		</CollapseContainer>
	)
}
