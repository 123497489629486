import React from 'react';
import { isMobile } from 'react-device-detect'
import DescriptionModal from './DescriptionModal'
import './style.scss'

function IconListPopup({ content, title, show_mobile, show_desktop  }) {
    const [showModal, setShowModal] = React.useState(false)

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    if ((!isMobile && !show_desktop) || (isMobile && !show_mobile)) return null

    return <div className='IconListPopup'>
        {title && <h2>{title}</h2>}
        {content.title && <h3 style={{textAlign: content?.titleAlign}} className='IconListPopup_title'>{content.title}</h3>}
        <div className='IconListPopup_listIcons'>
            {content.listIcons?.map((item, index) =>
                <div key={item?.id} className='IconListPopup_iconItem'>
                    <div style={{ backgroundColor: item?.bgColor }} className='IconListPopup_iconItem_image-container'>
                        {item?.bgImg && <div className='IconListPopup_iconItem_bgImg' style={{ backgroundImage: `url(${item?.bgImg})` }}></div>}
                    </div>
                    {item?.title && <h4 className='IconListPopup_iconItem_title' dangerouslySetInnerHTML={{ __html: item?.title }} ></h4>}
                </div>
            )}
        </div>
        {content?.linkName && content.listIcons.length > 1 && <div onClick={() => handleShowModal()} className='IconListPopup_iconItem_link'>{content.linkName}</div>}
        {showModal && <DescriptionModal 
                        content={content}
                        onClose={setShowModal} />}
    </div>
}

export default IconListPopup