import * as React from 'react'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '../../../../../asset/images/checklk.svg'
import RectangleIcon from '../../../../../asset/images/rectangleIcon.svg'

import { Icon } from '@material-ui/core'

export const Logo = () => (
	<Icon style={{ marginLeft: '0px' }}>
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				border: '1px solid #4872F2',
				borderRadius: '6px',
				width: '24px',
				height: '24px',
				backgroundColor: '#4872F2'
			}}>
			<img src={CheckIcon} alt='' />
		</div>
	</Icon>
)

export const IntermediateIcon = () => (
	<Icon style={{ marginLeft: '0px' }}>
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				border: '1px solid #4872F2',
				borderRadius: '6px',
				width: '24px',
				height: '24px',
				backgroundColor: '#4872F2'
			}}>
			<img src={RectangleIcon} alt='' />
		</div>
	</Icon>
)
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: 6,
	width: '24px',
	height: '24px',
	boxShadow:
		'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: '#f5f8fa',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2
	},
	'input:hover ~ &': {
		backgroundColor: '#ebf1f5'
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background: 'rgba(206,217,224,.5)'
	}
}))

// const BpIcon = styled('span')(({ theme }) => ({
// 	borderRadius: 6,
// 	width: 24,
// 	height: 24,
// 	boxShadow:
// 		'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
// 	backgroundColor: '#f5f8fa',
// 	backgroundImage:
// 		'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
// 	'.Mui-focusVisible &': {
// 		outline: '2px auto rgba(19,124,189,.6)',
// 		outlineOffset: 2
// 	},
// 	'input:hover ~ &': {
// 		backgroundColor: '#ebf1f5',
// 		// boxShadow: 'inset 0 0 0 1px #4872F2, inset 0 -1px 0 #4872F2'
// 	},
// 	'input:disabled ~ &': {
// 		boxShadow: 'none',
// 		background: 'rgba(206,217,224,.5)'
// 	}
// }))

export default function BpCheckbox(props) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: 'transparent' }
			}}
			disableRipple
			color='default'
			indeterminateIcon={<IntermediateIcon />}
			// checkedIcon={<Leogo />}
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	)
}





const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#4872F2',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&::before': {
		display: 'block',
		width: 24,
		height: 24,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""'
	},
	'input:hover ~ &': {
		backgroundColor: '#4872F2'
	}
})


