import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {createPassangersRequest} from "../../../../store/modules/user";
import {Form, Formik, getIn} from "formik";
import styled from "styled-components";
import PrimaryButton from "../../../../components/ui/PrimaryButton";
import {Field} from "../../../../components/ui/form/Field";
import {Checkbox, DatePicker, RadioGroup, Select} from "../../../../components/ui";
import NumberTooltip from "../../../../components/ui/form/NumberTooltip";
import AsyncSelect from "react-select/async";
import {useMediaQuery} from "react-responsive";
import * as yup from "yup";
import {v4 as uuid} from "uuid";
import Snackbar from "@mui/material/Snackbar";
import {Alert, Stack} from "@mui/material";
import {isEmpty} from "lodash";
import moment from "moment";
import {getCountriesList} from "../../../../functions/helpers";

const StyledForm = styled(Form)`
  margin: 0;
  width: 100%;
  margin-top: 24px;
`;

const FormBlockNew = styled.div`
  display: grid;
  grid-template-areas:
    'field-1 field-2 field-3'
    '. . field-4'
    'field-11 field-11 field-11'
    'field-5 field-6 field-6 ';
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 12px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    grid-template-areas:
      'field-1'
      'field-2'
      'field-3'
      'field-4'
      'field-5'
      'field-6';
  }

  & .field-1 {
    grid-area: field-1;
  }
  & .field-2 {
    grid-area: field-2;
  }
  & .field-3 {
    grid-area: field-3;
  }
  & .field-4 {
    grid-area: field-4;
  }
  & .field-5 {
    grid-area: field-5;
  }
  & .field-6 {
    grid-area: field-6;
    @media (max-width: 767px) {
      height: 56px;
    }
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const FioBLock = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

const DocumentsHeader = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DocumentBlock = styled.div`
  margin-top: 30px;
`;

const DocumentBlockGrid = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-areas:
    'field-1 field-2 field-3'
    'field-4 . .';
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 12px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    grid-template-areas:
      'field-1'
      'field-2'
      'field-3'
      'field-4';
  }

  & .field-1 {
    grid-area: field-1;
  }
  & .field-2 {
    grid-area: field-2;
  }
  & .field-3 {
    grid-area: field-3;
  }
  & .field-4 {
    grid-area: field-4;
  }
`;

const DocumentItem = styled.div`
  margin-top: 30px;
`;

const DocumentItemTitle = styled.div`
  color: #3C3C3C;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
`;

const ButtonsBlock = styled.div`
  margin-top: 35px;
`;

const SubmitButton = styled(PrimaryButton).attrs({ type: "submit" })`
  padding: 8px 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const CreatePassenger = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({maxWidth: 767})
  const [open, setOpen] = React.useState(false)
  const [notification, setNotification] = useState([])

  const handleClose = (event, reason) => { if (reason === 'clickaway') { return } setOpen(false) }

  React.useEffect(() => {
    if (!isEmpty(notification)) {
      setOpen(true)
    }
    return () => {
    }
  }, [notification])

  const initialValues = {
    firstName: '',
    lastName: '',
    patronymic: '',
    patronymicRequired: true,
    birthDate: null,
    gender: 'MALE',
    documents: [],
    personType: 'ADULT',
  }

  const documentTypes = [
    { value: 'RussianFederationPassport', label: 'Паспорт РФ' },
    { value: 'InternationalPassport', label: 'Заграничный паспорт' },
    { value: 'BirthCertificate', label: 'Свидетельство о рождении' },
    { value: 'NationalPassport', label: 'Нац. паспорт' },
    { value: 'Other', label: 'Другой документ' }
  ]

  const defaultCitizenship = [
    { value: 'RU', label: 'RU - Россия' },
    { value: 'BY', label: 'BY - Беларусь' },
    { value: 'KZ', label: 'KZ - Казахстан' },
    { value: 'GE', label: 'GE - Грузия' },
    { value: 'UZ', label: 'UZ - Узбекистан' },
    { value: 'AZ', label: 'AZ - Азербайджан' },
    { value: 'UA', label: 'UA - Украина' },
  ]

  const defaultDocument = {
    id: '00000000-0000-0000-0000-000000000000',
    citizenship: 'RU',
    citizenshipLabel: 'Россия',
    type: 'RussianFederationPassport',
    number: null,
    issueDate: null,
  }

  const countriesList = async (str) => {
    const response = await getCountriesList(str);
    return response
      ? response.data.map((country) => ({
        label: country.isoCode + ' - ' + country.nameRus,
        value: country.isoCode,
      }))
      : [];
  };

  const handleKeyDown = (event) => {
    if (/[a-zA-Z]/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  function getDocumentTypesArray(personType, citizenship) {
    switch (personType) {
      case 'ADULT': {
        const arr = [
          { value: 'RussianFederationPassport', label: 'Паспорт РФ' },
          { value: 'InternationalPassport', label: 'Заграничный паспорт' },
          { value: 'BirthCertificate', label: 'Свидетельство о рождении' },
        ];

        if (citizenship !== 'RU') {
          arr.push({ value: 'NationalPassport', label: 'Нац. паспорт' });
          arr.push({ value: 'Other', label: 'Другой документ' });
          arr.splice(0, 1);
        }
        return arr;
      }
      default: {
        const arr = [
          { value: 'BirthCertificate', label: 'Свидетельство о рождении' },
          { value: 'InternationalPassport', label: 'Заграничный паспорт' },
        ];
        if (citizenship !== 'RU') {
          arr.push({ value: 'NationalPassport', label: 'Нац. паспорт' });
          arr.push({value: 'Other', label: 'Другой документ',});
          arr.splice(0, 1);
        }
        return arr;
      }
    }
  }

  function getPassportDescription(type) {
    switch (type) {
      case "RussianFederationPassport":
        return "Паспорт РФ"
      case "BirthCertificate":
        return "Свидетельство о рождении"
      case "InternationalPassport":
        return "Загран. паспорт"
      case "NationalPassport":
        return "Национальный документ"
      default:
        return "Другой документ"
    }
  }

  function getDocumentMask(type) {
    switch (type) {
      case 'RussianFederationPassport':
        return '9999-999999'
      case 'InternationalPassport':
        return '99 9999999'
      // case 'BirthCertificate':
      //   return 'aaa-aa 999999'
      default:
        return ''
    }
  }

  function getNumberPlaceholder(document) {
    if (
      document.type === 'BirthCertificate' &&
      document.citizenship === 'RU'
    ) {
      return 'XII-AA 000000';
    } else if (document.type === 'RussianFederationPassport') {
      return '9999-999999';
    } else if (document.type === 'InternationalPassport') {
      return '99 9999999';
    } else {
      return isMobile ? undefined : 'Номер';
    }
  }

  const MIN_DATE_OF_BIRTH = new Date()
  MIN_DATE_OF_BIRTH.setFullYear(new Date().getFullYear() - 100)
  const MAX_DATE_OF_DOCUMENT = new Date()
  MAX_DATE_OF_DOCUMENT.setFullYear(new Date().getFullYear() + 50)

  const FormikValidationSchema = yup.object().shape({
    lastName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
      .required('Заполните'),
    firstName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
      .required('Заполните'),
    patronymic: yup
      .string()
      .when('patronymicRequired', {
        is: (value) => !!value,
        then: yup
          .string()
          .trim()
          .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
          .required('Заполните'),
        otherwise: yup.string(),
      })
      .nullable(),
    birthDate: yup
      .date()
      .min(MIN_DATE_OF_BIRTH, 'Неправильная дата рождения')
      .max(new Date(), 'Неправильная дата рождения')
      .typeError('Заполните')
      .test('not alive', 'Некорректная дата рождения', (v) => {
        return v?.getFullYear() > 1900;
      }),
    documents: yup.array(
      yup.object().shape({
        type: yup.string(),
        number: yup
          .string()
          .when('citizenship', {
            is: 'RU',
            then: yup.string()
              .when('type', {
                is: 'RussianFederationPassport',
                then: yup
                  .string()
                  .trim()
                  .matches(/\d{4}-?\d{6}/, 'Невалидный номер документа')
                  .typeError('Заполните')
                  .required('Заполните'),

                otherwise: yup.string()
                  .when('type', {
                    is: 'InternationalPassport',
                    then: yup
                      .string()
                      .trim()
                      .matches(/\d{2} ?\d{7}/, 'Невалидный номер документа')
                      .typeError('Заполните')
                      .required('Заполните'),

                    otherwise: yup.string()
                      .when('type', {
                        is: 'BirthCertificate',
                        then: yup
                          .string()
                          .trim()
                          .required('Необходимо заполнить'),
                        otherwise: yup.string(),
                }),
              }),
            }),
            otherwise: yup.string()
              .required('Необходимо заполнить')
              .typeError('Заполните'),
          }),
        issueDate: yup
          .date()
          .when('type',{
            is: (value) => ['InternationalPassport','NationalPassport','Other'].includes(value),
            then: yup
              .date()
              .when('citizenship', {
                is: 'RU',
                then: yup.date()
                  .min(new Date(), 'Некорректный срок действия')
                  .max(MAX_DATE_OF_DOCUMENT, 'Некорректный срок действия')
                  .typeError('Заполните')
                  .required('Необходимо заполнить'),
                otherwise: yup.date()
                  .min(new Date(), 'Некорректный срок действия')
                  .max(MAX_DATE_OF_DOCUMENT, 'Некорректный срок действия')
                  .nullable()
                  .notRequired()
              }),
            otherwise: yup
              .date()
              .nullable()
              .notRequired(),
          })
      })
    ),
  });

  // const validate = values => {
  //   const errors = {};
  //   if (!values.lastName) {
  //     errors.lastName = 'Required';
  //   } else if (!/[a-zA-Z]/.test(values.lastName)) {
  //     errors.lastName = 'Только латинские буквы';
  //   }
  //   console.log(errors)
  //   return errors;
  // };

  return (
    <>
      <div className={'my-passengers-title'}>Персональные данные</div>

      <Formik
        initialValues={initialValues}
        // validate={validate}
        validationSchema={FormikValidationSchema}
        onSubmit={(values) => {
          const {patronymicRequired, ...rest} = values
          console.log('payload', {...rest})
          try {
            dispatch(createPassangersRequest({...rest}))
            setNotification(cur => [...cur, {id: uuid(), type: 'success', description: 'Новый пассажир успешно создан'}])
          } catch(e) {
            setNotification(cur => [...cur, {id: uuid(), type: 'error', description: 'Что-то пошло не так'}])
          }
        }}
      >{({handleChange,
           handleBlur,
           handleSubmit,
           setFieldValue,
           errors,
           submitCount,
           values,
           touched,
           setErrors,}) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormBlockNew>
            {/*Фамилия*/}
            <FioBLock className="field-1">
              <StyledField
                wrapperClassName="custom-input"
                error={(errors.lastName && touched.lastName) ? errors.lastName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                label="Фамилия (на латинице)"
                value={values.lastName}
                name={'lastName'}
              />
            </FioBLock>

            {/*Имя*/}
            <FioBLock className="field-2">
              <StyledField
                wrapperClassName="custom-input"
                error={errors.firstName && touched.firstName ? errors.firstName : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                label="Имя (на латинице)"
                value={values.firstName}
                name={'firstName'}
              />
            </FioBLock>

            {/*Отчество*/}
            <FioBLock className="field-3">
              <StyledField
                wrapperClassName="custom-input"
                error={errors.patronymic && touched.patronymic && values.patronymicRequired ? errors.patronymic : undefined}
                disabled={!values.patronymicRequired}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                label="Отчество (на латинице)"
                value={values.patronymic}
                name={'patronymic'}
                style={!values.patronymicRequired ? {backgroundColor: '#EEEEEE'} : {}}
              />
            </FioBLock>

            {/*Чекбокс Без Отчества*/}
            <div className="field-4">
              <Checkbox
                onChange={e => {
                  setFieldValue(`patronymicRequired`, !e.target.checked)
                  if (e.target.checked) {
                    setFieldValue(`patronymic`, '');
                  }
                }}
                type="squar"
                label="Без отчества"
                checked={!values.patronymicRequired}
              />
            </div>

            {/*Дата рождения*/}
            <DatePicker
              className="field-5"
              value={values.birthDate ? new Date(values.birthDate) : ''}
              onChange={date => {
                if (date) {
                  const stillUtc = moment.utc(date).toDate()
                  setFieldValue('birthDate', moment(stillUtc).local().format("YYYY-MM-DD"))
                } else {
                  setFieldValue('birthDate', null)
                }
              }}
              label="Дата рождения"
              error={errors.birthDate && touched.birthDate ? errors.birthDate : undefined}
              isBirthDate={true}
              selectClose={true}
            />

            {/*Пол*/}
            <div className="field-6">
              <Label>Пол</Label>
              <div style={{ marginLeft: 7 }}>
                <RadioGroup
                  flex
                  defaultValue={values.gender}
                  onChange={gender => setFieldValue(`gender`, gender)}
                  items={[
                    { value: 'MALE', label: 'Мужской' },
                    { value: 'FEMALE', label: 'Женский' },
                  ]}
                />
              </div>
            </div>

          </FormBlockNew>

          <DocumentsHeader>
            <div className={'my-passengers-title'}>Документы</div>
            <div className={'my-passengers-add-btn'} style={{margin: '0'}}
                 onClick={() => {
                   setFieldValue('documents', [...values.documents, defaultDocument])
                 }}>
              <div className={'my-passengers-add-icon'} />
              <div className={'my-passengers-add-text'}>Добавить документ</div>
            </div>
          </DocumentsHeader>

          <DocumentBlock>
            {values.documents.map((doc, idx) => (
              <DocumentItem>
                <DocumentItemTitle>Документ {idx + 1} - {getPassportDescription(doc.type)}</DocumentItemTitle>

                <DocumentBlockGrid>

                  {/*тип документа*/}
                  <div className="field-1">
                    <Select
                      label="Выберите документ"
                      onChange={(event) => {
                        setFieldValue(`documents.${idx}.type`, event.value)
                        setFieldValue(`documents.${idx}.number`, '')}}
                      isSearchable={false}
                      value={documentTypes.filter(el => el.value === values.documents[idx].type)}
                      defaultValue={documentTypes[0]}
                      options={getDocumentTypesArray(values.personType, values.documents[idx].citizenship)}
                    />
                  </div>

                  {/*Серия и номер документа*/}
                  <div className="field-2">
                    <NumberTooltip
                      handleChange={(event) => {setFieldValue(`documents.${idx}.number`, event.target.value)}}
                      handleBlur={handleBlur}
                      mask={getDocumentMask(values.documents[idx].type)}
                      value={values.documents[idx].number}
                    >
                      <StyledField
                        wrapperClassName="custom-input"
                        error={getIn(touched, `documents.${idx}.number`) && getIn(errors, `documents.${idx}.number`)
                          ? getIn(errors, `documents.${idx}.number`)
                          : undefined}
                        label="Серия и номер"
                        placeholder={getNumberPlaceholder(values.documents[idx])}
                        name={`documents.${idx}.number`}
                        isInfo={values.documents[idx].type === 'BirthCertificate'}
                      />
                    </NumberTooltip>
                    {(values.documents[idx].type === 'BirthCertificate' &&
                      values.documents[idx].citizenship === 'RU') &&
                      values.documents[idx].number !== '' && (
                      <Label style={{ fontSize: 12, color: '#737373' }}>
                        Латинские буквы (Х, V, I), 2 буквы кириллицей и 6 цифр{' '}
                      </Label>
                    )}
                  </div>

                  {/*Гражданство*/}
                  <div className="field-3">
                    <Label>Гражданство</Label>
                    <AsyncSelect
                      onChange={(event) => {
                        setFieldValue(`documents.${idx}.citizenship`, event.value)
                        setFieldValue(`documents.${idx}.citizenshipLabel`, event.label.split(' - ')[1])
                        if (event.value !== 'RU') {
                          setFieldValue(`documents.${idx}.type`, documentTypes[1].value)
                        }
                      }}
                      styles={{
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          visibility: 'hidden',
                        }),

                        input: (provided, state) => ({
                          ...provided,
                          fontFamily: 'Open Sans',
                          color: '#3C3C3C',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '18px',
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: 0,
                          paddingLeft: '12px',
                        }),
                        container: (provided, state) => ({
                          ...provided,
                          padding: 0,
                        }),
                        control: (provided, { isFocused, ...state }) => {
                          const border = isFocused
                            ? '1px solid #3C3C3C !important'
                            : '1px solid #DCDCDC';

                          const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
                          const boxShadow = 'none';
                          return {
                            ...provided,
                            minHeight: 42,
                            border,
                            borderColor,
                            boxShadow,
                          };
                        },
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                      noOptionsMessage={() => 'Ничего не найдено'}
                      loadOptions={async (inputValue, callback) => {
                        callback(await countriesList(inputValue));
                      }}
                      defaultOptions={defaultCitizenship}
                      defaultValue={defaultCitizenship[0]}
                      cacheOptions
                      value={{
                        value: values.documents[idx].citizenship,
                        label: values.documents[idx].citizenshipLabel
                      }}
                    />
                  </div>

                  {/*Срок действия документа (если предусмотрен)*/}
                  {(values.documents[idx].type === 'InternationalPassport' ||
                    values.documents[idx].type === 'NationalPassport' ||
                    values.documents[idx].type === 'Other') && (
                    <>
                      <DatePicker
                        className="field-4"
                        value={values.documents[idx].issueDate ? new Date(values.documents[idx].issueDate) : null}
                        onChange={date => {
                          if (date) {
                            const stillUtc = moment.utc(date).toDate()
                            setFieldValue(`documents.${idx}.issueDate`, moment(stillUtc).local().format("YYYY-MM-DD"))
                          } else {
                            setFieldValue(`documents.${idx}.issueDate`, null)
                          }
                        }}
                        label="Срок действия"
                        placeholder="ДД.ММ.ГГГГ"
                        error={getIn(touched, `documents.${idx}.issueDate`) && getIn(errors, `documents.${idx}.issueDate`)
                          ? getIn(errors, `documents.${idx}.issueDate`)
                          : undefined}
                        isInfo={values.documents[idx].citizenship !== 'RU'}
                        name={`documents.${idx}.issueDate`}
                      />
                    </>
                  )}

                </DocumentBlockGrid>

                <div className={'my-passengers-add-btn'}
                     style={{margin: '18px 0 0 0', border: 'none', padding: '0'}}
                     onClick={() => {
                       setFieldValue('documents', values.documents.filter((doc, index) => index !== idx))
                     }}>
                  <div className={'my-passengers-close-icon'} />
                  <div className={'my-passengers-add-text'}>Удалить документ</div>
                </div>
              </DocumentItem>

            ))}
          </DocumentBlock>

          <ButtonsBlock>
            <SubmitButton>Сохранить изменения</SubmitButton>
          </ButtonsBlock>

        </StyledForm>

      )}
      </Formik>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Stack flexDirection='column' gap={1}>
          {notification.map(item => {
            return (
              <Alert
                key={item.id}
                onClose={handleClose}
                severity={item.type}
                sx={{ width: '300px' }}>
                {item.description}
              </Alert>
            )
          })}
        </Stack>
      </Snackbar>

    </>
  )
}

export default CreatePassenger
