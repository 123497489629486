import React from "react"
import styled from "styled-components"
import {Link, useParams} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom";
import {getOrderByNumber, refreshOrder, purifyDetailData, isOrdersLoading} from "../store/modules/orders"
import {Loader, Navigation} from "../components/ui"
import {getUserDataState} from "../store/modules/user";
import {Breadcrumbs, Typography} from "@mui/material";

const Wrapper = styled.div``

const Content = styled.div`
  position: relative;
  min-height: 447px;

  & > div {
    margin-bottom: 30px;
  }

  & > :nth-child(2) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    & > :nth-child(2) {
      margin-bottom: 15px;
    }
  }
`

const StyledNavigation = styled(Navigation)`
  @media (max-width: 767px) {
    margin-top: 25px;
  }
  margin-bottom: 35px;
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiBreadcrumbs-root,
  .MuiTypography-root {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #B9B9B9;
  }
  .MuiBreadcrumbs-ol {
    margin-bottom: 16px;
  }
  .MuiBreadcrumbs-li a {
    color: #B9B9B9;
  }
`

const OrderDetail = () => {
  const dispatch = useDispatch()
  const {orderNumber} = useParams();
  const user = useSelector(getUserDataState);
  const canView = user?.role !== 'ViewManager';
  const isLoading = useSelector(isOrdersLoading)
  const isCreditLoading = useSelector(x => x.orders.orderDetail.creditLoading)

  React.useEffect(() => {
    dispatch(getOrderByNumber(parseInt(orderNumber)))

    const id = setInterval(() => {
      dispatch(refreshOrder());
    },20 * 1000)

    return () => {
      clearInterval(id);
      dispatch(purifyDetailData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  const navigation = React.useMemo(
    () => [
      {
        name:  "Информация о заявке",
        path: `/cabinet/orders/${orderNumber}`
      },
      {
        name: "История заявки",
        path: `/cabinet/orders/${orderNumber}/history`
      },
      {
        name: "Обращения по заявке",
        path: `/cabinet/orders/${orderNumber}/tickets`
      }
    ],
    [orderNumber]
  )


  return (
    <>

      {canView
        ? <Wrapper>
            {(isLoading || isCreditLoading) && <Loader opacity='0.8' zIndex={999}/>}
            <StyledBreadcrumbs aria-label='breadcrumb'>
              <Link color='inherit' to={'/cabinet/orders'}>Список заявок</Link>
              <Typography style={{ color: '#737373' }}>Заявка №{orderNumber}</Typography>
            </StyledBreadcrumbs>
            <StyledNavigation items={navigation} />
            <Content>
              <Outlet/>
            </Content>
          </Wrapper>
        : <h1>Данный раздел вам не доступен</h1>
      }
    </>
  )
}

export default OrderDetail
