import React from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'
import {RoundIcon} from "../ui";
import spbPaymentImg from "../../asset/images/avia/shared/spbPaymentImg.svg";
import formatPrice from "../../functions/formatPrice";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 500px;
    min-height: 400px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
  }
`
const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  & span {
    margin: 0 10px;
  }
`;
const HeaderTitleAmount = styled.div`
  color: #4872F2;
`
const BlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  margin-bottom: 20px;
`;
const QrCodeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QrCodePayModal = ({setIsOpenModal, qrCodeIsLoading, qrCode, amount, ...props}) => {
  return (
    <StyledPopup {...props}>
      {qrCodeIsLoading ? (
        <LoaderContainer>
          <RoundIcon />
        </LoaderContainer>
      ) : (
        <>
          <HeaderWrapper>
            <CloseButton
              aria-label="close"
              onClick={() => setIsOpenModal(false)}
            >
              <Icon/>
            </CloseButton>
            <HeaderTitle>
              <img src={spbPaymentImg} alt='img'/>{' '}
              <span>Оплатить через СБП</span>
              <HeaderTitleAmount>{formatPrice(amount)}</HeaderTitleAmount>
            </HeaderTitle>
          </HeaderWrapper>
          {!!qrCode && (
            <>
              <BlockText>
                Отсканируйте QR-код с помощью своего смартфона или
                мобильного приложения банка. <br /> После успешной оплаты
                билеты будут направлены вам по электронной почте.
              </BlockText>
              <QrCodeBlock>
                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} alt='qr' />
              </QrCodeBlock>
            </>
          )}
        </>
      )}
    </StyledPopup>
  )
}

export default QrCodePayModal