import {
	Button,
	Grid,
	InputAdornment,
	MenuItem,
	TextField,
	CircularProgress
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { styled as styledMUI } from '@mui/material/styles'
import AcceptFitClaim from './AcceptFitClaim'
import { ExpandMore } from '@material-ui/icons'
import { MuiTextField } from '../../AgentInfo/components/MuiTextField/MuiTextField'
import { emailRegExp, phoneRegExp } from '../../../../constants/reg-exps'
import FlightsDate from './FlightsDate'
import axios from 'axios'
import { isBefore } from 'date-fns'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const Title = styled.h3`
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`
const Block = styled.div`
	padding-bottom: 8px;
`

const SaveButton = styledMUI(Button)(({ theme }) => ({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '12px 24px 12px 24px',
	border: '1px solid',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
}))

export const FormFITInput = styledMUI(TextField)(() => ({
	'& .MuiSvgIcon-root': {
		right: 16
	},
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.73)'
	},
	'& label.Mui-focused': {},
	'& label': {
		fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
		color: '#7E8389'
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE'
	},
	'& .MuiInputBase-input': {
		fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(',')
	}
}))

export default function NewClaim() {
	const countriesPreffix = [
		{ code: 'BY', label: 'Belarus', phone: '375' },
		{ code: 'EE', label: 'Estonia', phone: '372' },
		{ code: 'LT', label: 'Lithuania', phone: '370' },
		{ code: 'LV', label: 'Latvia', phone: '371' },
		{ code: 'RU', label: 'Russian Federation', phone: '7' },
		{ code: 'UA', label: 'Ukraine', phone: '380' },
		{ code: 'KZ', label: 'Kazachstan', phone: '997' }
	]
	const [loading, setLoading] = React.useState(false)
	const [errors, setErrors] = React.useState({
		inn: null,
		contactPerson: null,
		email: null,
		phone: null,
		tourCountry: null,
		cityRegion: null,
		departureCity: null,
		nightsCount: null,
		touristsCount: null,
		adult: null,
		children: null,
		baby: null,
		hotelCategory: null,
		mealType: null,
		visa: null,
		budget: null
	})

	const [fitClaim, setFitClaim] = React.useState({
		companyName: null,
		inn: null,
		contactPerson: null,
		email: null,
		phone: null,

		tourType: null,
		tourCountry: null,
		cityRegion: null,
		departureCity: null,

		nightsCount: null,
		touristsCount: null,
		adult: null,
		children: null,
		baby: null,

		hotelName: null,
		hotelCategory: null,
		roomCategory: null,
		roomCount: null,
		mealType: null,

		visa: '',
		budget: null,
		additionalServices: null,
		notes: null
	})
	const requiredFields = useMemo(() => {
		return [
			'contactPerson',
			'email',
			'phone',
			'tourCountry',
			'departureCity',
			'touristsCount',
			'contactPerson',
			'hotelCategory',
			'mealType',
			'visa',
		]
	}, [])

	const checkValidation = () => {
		return (
			requiredFields.some(item => {
				if (fitClaim[item]) {
					return !fitClaim[item]
				} else return true
			}) ||
			Object.values(errors).some(item => !!item) ||
			!arrivalDate ||
			!departureDate
		)
	}

	const changeHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		switch (prop) {
			case 'inn':
				if (
					!/^[0-9]+[0-9]*$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только цифр`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'contactPerson':
				if (!/^[а-яА-ЯёЁ\s-]+$/.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'phone':
				setErrors({ ...errors, [prop]: null })
				if (!phoneRegExp.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Номер телефона указан некорректно`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'email':
				setErrors({ ...errors, [prop]: null })
				if (!emailRegExp.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Email указан некорректно`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'tourCountry':
				if (!/^[a-zA-Zа-яА-Я\s-]+$/.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'cityRegion':
				if (
					!/^[a-zA-Zа-яА-Я\s-]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'departureCity':
				if (!/^[a-zA-Zа-яА-Я\s-]+$/.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'nightsCount':
				if (
					!/^[\d\s\-,\\.]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только цифр`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'touristsCount':
				if (!/^[0-9]+[0-9]*$/.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только цифр`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'adult':
				if (
					!/^[а-яА-ЯёЁ\d\s\-,\\.()\s]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы, цифр, символов " ", "-", " ", ",", ".", "(", ")"`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'children':
				if (
					!/^[а-яА-ЯёЁ\d\s\-,\\.()\s]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы, цифр, символов " ", "-", " ", ",", ".", "(", ")"`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'baby':
				if (
					!/^[а-яА-ЯёЁ\d\s\-,\\.()\s]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
					setErrors({
						...errors,
						[prop]: `Допустим ввод только кириллицы, цифр, символов " ", "-", " ", ",", ".", "(", ")"`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'hotelCategory':
				if (!event.target.value) {
					setErrors({
						...errors,
						[prop]: `обязательно для заполнения`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'mealType':
				if (!event.target.value) {
					setErrors({
						...errors,
						[prop]: `Обязательно для заполнения`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'visa':
				if (!event.target.value) {
					setErrors({
						...errors,
						[prop]: `Обязательно для заполнения`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			// case 'budget':
			// 	if (!event.target.value) {
			// 		setErrors({
			// 			...errors,
			// 			[prop]: `Обязательно для заполнения`
			// 		})
			// 	} else {
			// 		setErrors({ ...errors, [prop]: null })
			// 	}
			// break
			default:
				break
		}
		setFitClaim({ ...fitClaim, [prop]: event.target.value })
	}

	const sendClaim = async () => {
		const partPass = JSON.parse(localStorage.getItem('partPass'))
		const data = {
			mailType: 13,
			clientType: 1,
			locale: 0,
			emailTo: "fitbooking@fstravel.com",
			arguments: {
				Title: partPass,
				AgentId: partPass,
				CompanyName: fitClaim.companyName,
				Inn: fitClaim.inn,
				ContactPerson: fitClaim.contactPerson,
				Email: fitClaim.email,
				Phone: fitClaim.phone,
				TourType: fitClaim.tourType,
				TourCountry: fitClaim.tourCountry,
				Location: fitClaim.cityRegion,
				DepartureCity: fitClaim.departureCity,
				DepartureDate: departureDate,
				ArrivalDate: arrivalDate,
				NightsCount: fitClaim.nightsCount,
				ParticipantCount: fitClaim.touristsCount,
				AdultCount: fitClaim.adult,
				ChildrenCount: fitClaim.children ?? 0,
				InfantCount: fitClaim.baby ?? 0,
				HotelName: fitClaim.hotelName,
				HotelCategory: fitClaim.hotelCategory,
				RoomCategory: fitClaim.roomCategory,
				RoomCount: fitClaim.roomCount,
				MealType: fitClaim.mealType,
				Visa: fitClaim.visa,
				Budget: fitClaim.budget,
				AdditionalServices: fitClaim.additionalServices ?? "",
				Note: fitClaim.notes ?? ""
			},
			attachmentFiles: []

		}
		setLoading(true)
		try {
			const res = await axios({
				url: `${BASE_URL}/mail/send`,
				method: "POST",
				data: data,
				headers: {
					'x-api-version': '1.0'
				}
			})
			console.log('sendClaim res', res);
			if (res.status === 200) {
				setOpen(true)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const [arrivalDate, setArrivalDate] = useState(null)
	const [departureDate, setDepartureDate] = useState(null)
	const [open, setOpen] = React.useState(false)

	const handleChangeDepartureDate = (value) => {
		setDepartureDate(value)
		if (!departureDate || isBefore(new Date(arrivalDate), new Date(value))) {
			setArrivalDate(value)
		}
	}

	return (
		<>
			<Block>
				<Title>Данные контактного лица</Title>
				<Grid container rowSpacing={3} columnSpacing={3}>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='Название компании'
							onChange={changeHandler('companyName')}
							value={fitClaim.companyName}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='ИНН'
							onChange={changeHandler('inn')}
							value={fitClaim.inn}
							helperText={errors.inn}
							error={!!errors.inn}
						/>
					</Grid>
					<Grid container columns={14} item rowSpacing={3} columnSpacing={3}>
						<Grid item xs={4}>
							<FormFITInput
								fullWidth
								variant='outlined'
								label='Контактное лицо'
								value={fitClaim.contactPerson}
								onChange={changeHandler('contactPerson')}
								helperText={errors.contactPerson}
								error={!!errors.contactPerson}
								required
							/>
						</Grid>
						<Grid item xs={4}>
							<FormFITInput
								fullWidth
								variant='outlined'
								label='Email'
								onChange={changeHandler('email')}
								value={fitClaim.email}
								helperText={errors.email}
								error={!!errors.email}
								required
							/>
						</Grid>
						<Grid item xs={3}>
							<FormFITInput
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<img
												loading='lazy'
												width='34'
												height='24'
												style={{
													borderRadius: '6px'
												}}
												srcSet={`https://flagcdn.com/w40/${countriesPreffix
													.find(item => `+${item.phone}` === '+7')
													?.code?.toLowerCase()}.png 2x`}
												src={`https://flagcdn.com/w20/${countriesPreffix
													.find(item => `+${item.phone}` === '+7')
													?.code?.toLowerCase()}.png`}
												alt=''
											/>
										</InputAdornment>
									)
								}}
								select
								SelectProps={{ IconComponent: ExpandMore }}
								value={fitClaim?.preffix || '+7'}
								required
								fullWidth
								variant='outlined'
								id='outlined-required'
								name='preffix'>
								<MenuItem value={fitClaim.preffix || '+7'}>+7</MenuItem>
							</FormFITInput>
						</Grid>
						<Grid item xs={3} style={{ paddingLeft: '12px' }}>
							<FormFITInput
								fullWidth
								variant='outlined'
								label='Телефон'
								value={fitClaim.phone}
								onChange={changeHandler('phone')}
								helperText={errors.phone}
								error={!!errors.phone}
								required
							/>
						</Grid>
					</Grid>
				</Grid>
			</Block>
			<Block>
				<Title>Информация о туре</Title>
				<Grid container rowSpacing={3} columnSpacing={3}>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('tourType')}
							value={fitClaim.tourType}
							label='Тип тура'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='Страна тура'
							onChange={changeHandler('tourCountry')}
							value={fitClaim.tourCountry}
							helperText={errors.tourCountry}
							error={!!errors.tourCountry}
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('cityRegion')}
							value={fitClaim.cityRegion}
							helperText={errors.cityRegion}
							error={!!errors.cityRegion}
							label='Город/Регион'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='Город вылета'
							onChange={changeHandler('departureCity')}
							value={fitClaim.departureCity}
							helperText={errors.departureCity}
							error={!!errors.departureCity}
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<FlightsDate
							style={{ alignSelf: 'flex-start' }}
							fullWidth
							onChange={handleChangeDepartureDate}
							label='Дата вылета'
							value={departureDate}
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<FlightsDate
							style={{ alignSelf: 'flex-start' }}
							fullWidth
							onChange={setArrivalDate}
							label='Дата прилета'
							value={arrivalDate}
							minDate={departureDate}
							required
						/>
					</Grid>
				</Grid>
			</Block>
			<Block>
				<Title>Информация о туристах</Title>
				<Grid container rowSpacing={3} columnSpacing={3}>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('nightsCount')}
							value={fitClaim.nightsCount}
							helperText={errors.nightsCount}
							error={!!errors.nightsCount}
							label='Количество ночей пребывания'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='Количество участников'
							onChange={changeHandler('touristsCount')}
							value={fitClaim.touristsCount}
							helperText={errors.touristsCount}
							error={!!errors.touristsCount}
							required
						/>
					</Grid>
					<Grid item xs={4}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('adult')}
							value={fitClaim.adult}
							helperText={errors.adult}
							error={!!errors.adult}
							label='Взрослые'
						/>
					</Grid>
					<Grid item xs={4}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('children')}
							value={fitClaim.children}
							helperText={errors.children}
							error={!!errors.children}
							label='Дети'
						/>
					</Grid>
					<Grid item xs={4}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('baby')}
							value={fitClaim.baby}
							helperText={errors.baby}
							error={!!errors.baby}
							label='Младенцы до двух лет'
						/>
					</Grid>
				</Grid>
			</Block>
			<Block>
				<Title>Информация об отеле</Title>
				<Grid container rowSpacing={3} columnSpacing={3}>
					<Grid item xs={12}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('hotelName')}
							value={fitClaim.hotelName}
							label='Название отеля'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							label='Категория отеля'
							onChange={changeHandler('hotelCategory')}
							value={fitClaim.hotelCategory}
							helperText={errors.hotelCategory}
							error={!!errors.hotelCategory}
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('roomCategory')}
							value={fitClaim.roomCategory}
							label='Категория номера'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('roomCount')}
							value={fitClaim.roomCount}
							label='Предполагаемое количество номеров'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('mealType')}
							value={fitClaim.mealType}
							helperText={errors.mealType}
							error={!!errors.mealType}
							label='Тип питания'
							required
						/>
					</Grid>
				</Grid>
			</Block>
			<Block>
				<Title>Дополнительная информация</Title>
				<Grid container rowSpacing={3} columnSpacing={3}>
					<Grid item xs={6}>
						<FormFITInput
							SelectProps={{
								IconComponent: ExpandMore,
								MenuProps: {
									PaperProps: {
										className: 'myCustomList-actions',
										sx: {
											'& .MuiMenuItem-root': {
												fontFamily: 'Open Sans'
											}
										}
									}
								}
							}}
							select
							fullWidth
							required
							onChange={changeHandler('visa')}
							value={fitClaim.visa}
							helperText={errors.visa}
							error={!!errors.visa}
							variant='outlined'
							label='Виза'>
							<MenuItem value={'Да'}>Да</MenuItem>
							<MenuItem value={'Нет'}>Нет</MenuItem>
							<MenuItem value={'Визовая поддержка'}>Визовая поддержка</MenuItem>
						</FormFITInput>
					</Grid>
					<Grid item xs={6}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('budget')}
							value={fitClaim.budget}
							helperText={errors.budget}
							error={!!errors.budget}
							label='Бюджет'
						/>
					</Grid>
					<Grid item xs={12}>
						<FormFITInput
							fullWidth
							variant='outlined'
							onChange={changeHandler('additionalServices')}
							value={fitClaim.additionalServices}
							label='Дополнительные услуги'
						/>
					</Grid>
					<Grid item xs={12}>
						<MuiTextField
							label='Примечание'
							multiline
							sx={{
								'& .MuiFilledInput-root': {
									borderColor: 'D4D9DE'
								}
							}}
							onChange={changeHandler('notes')}
							value={fitClaim.notes}
							variant='filled'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<SaveButton
							disabled={checkValidation() || loading}
							fullWidth
							onClick={sendClaim}>
							{loading && <CircularProgress size={10} />}
							Запросить расчет тура
						</SaveButton>
					</Grid>
				</Grid>
			</Block>
			{open && (
				<AcceptFitClaim
					onClose={value => {
						setOpen(value)
						window.location.href = '/cabinet/fit'
					}}
				// claimId={'FIT-401122'}
				/>
			)}
		</>
	)
}
