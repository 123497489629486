import React, {useEffect, useState} from 'react';
import {Box, Breadcrumbs} from "@mui/material";
import {Link} from "react-router-dom";
import {Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import useGeoStore from "../LandingPage/store";
import MainBannerSlider from "../LandingPage/components/MainBannerSlider";
import MainBanner from "../LandingPage/components/MainBanner";
import StaticBlocks from "../LandingPage/StaticBlocks";

const OnBoardServices = ({ path }) => {

	const actions = useGeoStore(({ actions }) => actions)
	const content = useGeoStore(({ content }) => content)
	const [isUpdated, setIsUpdated] = useState(false)

	useEffect(() => {
		actions.getPage(path).then(setIsUpdated(true))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		return () => {
			actions.clearContent()
		}
		// eslint-disable-next-line
	}, [])

	if (!content || !isUpdated)
		return null;

	return (
		<div className={'landing landing-container'}>
			<Box mb={2} mt={2}>
				<Breadcrumbs aria-label='breadcrumb' className={'breadcrumbs-action'}>
					<Link style={{ color: '#4872f2' }} to={'/on-board-services'}>Главная</Link>
					<Typography>
						{content?.title}
					</Typography>
				</Breadcrumbs>
			</Box>
			<Helmet title={content?.meta_title || content?.title} />
			{content.main_banner &&
				JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner &&
				JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner.boolSliderMainBanner &&
				<MainBannerSlider content={JSON.parse(content.main_banner.content)} />
			}
			{content.main_banner && <MainBanner content={JSON.parse(content.main_banner.content)}/>}

			<StaticBlocks blocks={content.blocks.filter(block => block.show_desktop)} />
		</div>
	);
};


export default OnBoardServices;