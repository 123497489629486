import DateInput from './DateInput'
import SelectInput from './SelectInput'
import useVisaStore from '../../store'
import { useEffect, useState } from 'react'

import './style.scss'
import {Box} from "@mui/material";
import {addMinutes, format, formatISO, parseISO} from "date-fns";
import {Button} from "@material-ui/core";
import {isEmpty} from "lodash";

function VisaCalculatorShedule() {
	const actions = useVisaStore(({ actions }) => actions)

	const countryId = useVisaStore(({ countrySheduleId }) => countrySheduleId)
	const countries = useVisaStore(({ countries }) => countries)
	const cityId = useVisaStore(({ cityId }) => cityId)
	const tourStartDate = useVisaStore(({ tourSheduleStartDate }) => tourSheduleStartDate)
	const calcSheduleDate = useVisaStore(({ calcSheduleDate }) => calcSheduleDate)
	const departureCities = useVisaStore(({ departureCities }) => departureCities)

	const [error, setError] = useState(false)

	useEffect(() => {
		actions.getDeparture()
		actions.getCountries()
	}, [actions])

	const submit = () => {
		setError(false)
		if (countryId && tourStartDate) {
			actions.getPassportSheduleValid()
		} else {
			setError(true)
		}
	}

	return (
		<div className='visa-calc'>
			<h4>Калькулятор расчёта крайних сроков по оформлению визы</h4>
			<div className='visa_form'>
				<SelectInput
					label='Город вылета'
					value={cityId}
					list={departureCities}
					onChange={actions.changeCity}
					error={error && !cityId}
					required
				/>
				<SelectInput
					label='Страна тура'
					value={countryId}
					list={countries}
					onChange={actions.changeSheduleCountry}
					error={error && !countryId}
					required
				/>
				<DateInput
					label='Дата начала тура'
					value={tourStartDate}
					onChange={actions.changeSheduleStartDate}
					required
					isExpiration
					error={error && !tourStartDate}
				/>
			</div>
			<Button className='visa_submit-btn' onClick={submit} disabled={!countryId || !tourStartDate}>
				Рассчитать
			</Button>
			{calcSheduleDate && <Box mt={3} textAlign={'center'}>
				{calcSheduleDate !== ' ' ? 'Крайний срок подачи документов до:' : 'Крайний срок для приёма документов истёк'}
				{calcSheduleDate !== ' ' && <div><strong>{format(addMinutes(new Date(calcSheduleDate), new Date(calcSheduleDate).getTimezoneOffset()), 'dd.MM.yyyy HH:mm')}</strong></div>}
			</Box>}
		</div>
	)
}

export default VisaCalculatorShedule
