import { Grid, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import useAirportStore from '../../store'
import { isEmpty } from 'lodash'
import SearchAirportServicesResults from '../SearchAirportServicesResults'
import NotFindService from '../../../../components/Services/NotFindService'
import ServiceFiltersBlock from './components/ServiceFiltersBlock'
import MobileFiltersBlock from './components/MobileFiltersBlock'

export default function SearchResultsWithFilters({ result }) {
    const isSmallScreen = useMediaQuery('(min-width:700px)')

    const actions = useAirportStore((state) => state.actions)

    const excursionType = useAirportStore(
        ({ excursionType }) => excursionType
    )
    const checkedExcursionType = useAirportStore(
        ({ checkedExcursionType }) => checkedExcursionType
    )

    const filteredExcursions = useMemo(() => {
        if (
            isEmpty(checkedExcursionType)
        ) {
            return result
        } else {
            return result?.filter(service => {
                const serviceType = service?.other_params?.serviceType?.map(
                    item => item?.name
                )

                return (
                    checkedExcursionType.every(item => serviceType?.includes(item))
                )
            })
        }
    }, [checkedExcursionType, result])

    console.log(filteredExcursions,' filteredExcursions')

    return (
        <Grid
            direction={isSmallScreen ? 'row' : 'column'}
            container
            sx={{ mt: 2, mb: 2 }}
            columnSpacing={'20px'}
            columns={26}>
            {!isEmpty(excursionType) && <Grid item xs={5}>
                {isSmallScreen ? <ServiceFiltersBlock /> : null}
            </Grid>}
            <Grid item xs={!isEmpty(excursionType) ? 21 : 26}>
                {isEmpty(filteredExcursions) ? (
                    <NotFindService clearFilters={actions.clearFilters} />
                ) : (
                    <SearchAirportServicesResults lists={filteredExcursions} />
                )}
            </Grid>
        </Grid>
    )
}
