import React from 'react';
import NewsByCategory from "./NewsByCategory";

import './style.scss'
import NewsManual from "./NewsManual";
import EachByCategory from "./EachByCategory";

const SelectionExpertNews = ({ content, title }) => {

	const getTemplate = () => {
		switch (content.template) {
			case 'byCategory':
				return <NewsByCategory content={content} title={title} />
			case 'manual':
				return <NewsManual content={content} title={title} />
			case 'eachByCategory':
				return <EachByCategory content={content} title={title} />
			default:
				return null
		}
	}

	return (
		<div>
			{getTemplate()}
		</div>
	);
};

export default SelectionExpertNews;