import { createAction, createReducer } from "redux-act"
import { bookingPurify } from "./shared"
import { createServiceAddictionalInfo } from "../utils"
import _ from "lodash"

const INITIAL_STATE = []

//Actions
export const updateAncillaryServices = createAction(
  "@booking/ancillaryServices/update"
)

export const switchAncillaryService = createAction(
  "@booking/ancillaryServices/switch"
)

//Reducer
const reducer = createReducer({}, INITIAL_STATE)

reducer.on(bookingPurify, () => INITIAL_STATE)
reducer.on(updateAncillaryServices, (_, state) => [...state])

reducer.on(
  switchAncillaryService,
  (state, { type, passengerId, segmentId, key }) => {
    return state.map(x => {
      if (x.type !== type) {
        return x
      }
      const xClone = JSON.parse(JSON.stringify(x))
      xClone.offers[segmentId][passengerId] = xClone.offers[segmentId][passengerId].map(
        x => {
          if (x.key !== key) {
            return x
          }
          return { ...x, checked: !x.checked }
        }
      )
      xClone.segments = xClone.segments.map(y => {
        if (y.id !== segmentId) {
          return y
        } else {
          return {
            ...y,
            fulfilled: !!_(xClone.offers[segmentId]).find(x => {
              return !!_(x).find(x => x.checked)
            })
          }
        }
      })
      xClone.addictionalInfo = createServiceAddictionalInfo(xClone)
      return { ...xClone }
    })
  }
)
//key === value.key ? !value.checked : value.checked

export default reducer
