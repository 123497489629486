/* eslint-disable default-case */
import React from "react"
import styled, { css } from "styled-components"
import {Button, Text, DragAndDropFiles, Textarea, Select, SelectWithCheckboxes, AppealNotification} from "../../ui"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { addAppealRequest } from "../../../store/modules/appeals"
import { useProps } from "./useProps"
import { passengersNormalizer } from "./normalizer"
import _ from "lodash"
import { components } from "react-select"
import { referencesSelector } from "../../../store/modules/references"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import PrimaryButton from "../../ui/PrimaryButton"


const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 8px;
    padding: 40px 9px;
    padding-bottom: 30px;
    width: 569px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;


    @media (max-height: 1112px) and (min-height: 1073px) {
      padding: 20px 9px;
    }
  
    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
      padding: 25px 21px;
      height: auto;
      min-height: 100%;
      display: flex;
      // align-items: center;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 2.786px;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #E9EAEC;
    width: 2.786px;
    border-radius: 2px;
  }
  padding: 31px;
`;

const SecondSelectBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  & > span {
    width: 48%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0;
    margin-top: 15px;
    & > span {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`

const Description = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`

const DescriptionUl = styled.ul`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  max-width: 493px;
  padding-left: 27px;
  margin-top: 20px;

  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  & > li {
    margin-bottom: 20px;

    & > strong {
      font-weight: 600;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const TextBlock = styled.div`
  align-text: left;
  margin-bottom: 30px;

  & > span {
    display: block;
  }

  ${Title} {
    margin-bottom: 30px;
  }
`

const AncillaryServicesBlock = styled(SecondSelectBlock)`
  & > span {
    width: 100%;
  }
`

const LastBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`

const StyledForm = styled(Form)`
  margin: 0;
  width: 100%;
  margin-top: 24px;
`

const PassengersListWrapper = styled.div`
  & > * {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-top: 20px;
    margin-bottom: 0;
  }

  margin-bottom: 25px;
`

const PassengerName = styled(Text)`
  white-space: nowrap;
  margin-right: 10px;
  margin-top: 3px;
`

const InsurancesPassenger = styled.div`
  display: flex;
  justify-content: space-between;
`

const GrayLine = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 8px;
  background: #dcdcdc;
`

const InsuranceList = styled.div`
  white-space: break-spaces;
  text-align: right;
`

const InsuranceItem = styled.div`
  display: inline-flex;

  & > :first-child {
    margin-right: 5px;
  }
`

export const ButtonBase = css`
  background: #3C3C3C;
  width: 18px;
  height: 18px;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  &:disabled {
    background: #B9B9B9;
  }
`

const CrossButton = styled.button.attrs({
  type: "button"
})`
  ${ButtonBase}
  &:before, &:after {
    position: absolute;
    content: " ";
    height: 9px;
    width: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #FFFFFF;
    border-radius: 1px;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

// const StyledSelect = styled(Select)`
//   &.
// `;

const ContainerMultiValue = styled.div`
  background: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  margin-rigth: 3px;

  & > div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    text-overflow: ellipsis;
  }
`

const MultiValueContainer = props => {
  return (
    <components.MultiValueContainer {...props}>
      <ContainerMultiValue>{props.children}</ContainerMultiValue>
      <Text size="big"> /</Text>
    </components.MultiValueContainer>
  )
}

const ContainerMultiList = styled.div`
  display: flex;
  flex-shring: 0;

  [class*="-multiValue"] {
    background: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
`

const Submit = styled(PrimaryButton).attrs({ type: "submit" })`
  @media (max-width: 767px) {
    width: 100%;
  }
`

const StyledDragAndDropFiles = styled(DragAndDropFiles)`
  margin-top: 25px;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const ValueContainer = props => {
  return (
    <components.ValueContainer {...props}>
      <ContainerMultiList>{props.children}</ContainerMultiList>
    </components.ValueContainer>
  )
}

const isAddictionalServicesRefund = args =>
  args.code === "RefundAdditionalService"

const showPassengersAndSegments = code =>
  code !== "Other" && code !== "RefundInsurance"

const formikValidateSchema = yup.object().shape({
  message: yup.string().required("Заполните")
})

class StateUpdater {
  state = null

  consumeState(state) {
    this.state = { ...state }
    return this
  }

  setSelectedSegments(args) {
    this.state.selectedSegments = args
    return this
  }

  setSelectedSPassengers(args) {
    this.state.selectedPassengers = args
    return this
  }

  setAncillaries(args) {
    this.state.mealNamesArr = args
    return this
  }

  setActivePassenger(value) {
    this.state.activePassenger = value
    return this
  }

  updatePassengersList() {
    this.state.passengers = _(this.state.passengers)
      .mapValues(x => ({
        ...x,
        selected: !!this.state.selectedPassengers.find(y => y === x.value)
      }))
      .value()

    this.state.passengerList = this.state.passengerList.map(x => ({
      ...x,
      checked: !!this.state.selectedPassengers.find(y => y === x.value)
    }))

    return this
  }

  updateSegments(activeSegment) {
    this.state.passengers = _(this.state.passengers)
      .map(x => {
        if (!this.state.selectedPassengers.includes(x.value)) {
          return x
        }

        return {
          ...x,
          activeSegment: activeSegment || "",
          selectedSegments: _(x.segmentList)
            .filter(x => this.state.selectedSegments.includes(x.value))
            .map(x => x)
            .map(x => ({
              ...x,
              selectedAncillaryServices: x.ancillaryServices
                .filter(x => this.state.mealNamesArr[x.label] !== undefined)
                .map(x => x.value)
            }))
            .keyBy(x => x.value)
            .value()
        }
      })
      .map(x => ({
        ...x,
        segmentList: x.segmentList.map(y => ({
          ...y,
          checked: !!x.selectedSegments[y.value]
        }))
      }))
      .keyBy(x => x.value)
      .value()
    return this
  }

  updateAvailableAncillaries() {
    this.state.availableAncillaries = _(this.state.passengers)
      .map(x => {
        return _(x.selectedSegments)
          .map(x => x.ancillaryServices)
          .flatten()
          .uniqBy(x => x.label)
          .value()
      })
      .flatten()
      .uniqBy(x => x.label)
      .map(x => ({ ...x, value: x.label, id: x.value }))
      .value()

    return this
  }

  getValue() {
    return this.state
  }
}

function reducer(state, action) {
  const Stater = new StateUpdater()
  switch (action.type) {
    case "addPassenger": {
      return Stater.consumeState(state)
        .setActivePassenger(action.payload)
        .setSelectedSPassengers(
          _.uniq([...state.selectedPassengers, action.payload])
        )
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }
    case "removePassenger": {
      const SELECTED_PASSENGERS = _.without(
        state.selectedPassengers,
        action.payload
      )
      const mealNamesArr =
        SELECTED_PASSENGERS.length > 0 ? state.mealNamesArr : {}

      const lastPassenger =
        SELECTED_PASSENGERS.length > 0
          ? SELECTED_PASSENGERS[SELECTED_PASSENGERS.length - 1]
          : ""

      const newState = {
        ...state,
        activePassenger: lastPassenger,
        mealNamesArr
      }

      return Stater.consumeState(newState)
        .setActivePassenger(lastPassenger)
        .setSelectedSPassengers(SELECTED_PASSENGERS)
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }

    case "addAllPassengers": {
      return Stater.consumeState(state)
        .setActivePassenger(state.passengerList[0].value)
        .setSelectedSPassengers(_.uniq(state.passengerList.map(x => x.value)))
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }

    case "addSegment": {
      const SELECTED_SEGMENTS_IDS = _.uniq([
        ..._(state.passengers)
          .map(x =>
            _(x.selectedSegments)
              .map(x => x.value)
              .value()
          )
          .flatten()
          .value(),
        action.payload
      ])

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS_IDS)
        .updateSegments(action.payload)
        .updateAvailableAncillaries()
        .getValue()
    }

    case "addAllSegment": {
      const SELECTED_SEGMENTS_IDS = _.uniq([
        ..._(state.passengers)
          .map(x =>
            _(x.segmentList)
              .map(x => x.value)
              .value()
          )
          .flatten()
          .value()
      ])

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS_IDS)
        .updateSegments(SELECTED_SEGMENTS_IDS[0])
        .updateAvailableAncillaries()
        .getValue()
    }
    case "switchInsurance": {
      const newInsurances = state.passengers[
        action.payload.passenger
      ].insurances.map(item => ({
        ...item,
        checked: item.value === action.payload.id ? !item.checked : item.checked
      }))

      const newPassengers = {
        ...state.passengers,
        [action.payload.passenger]: {
          ...state.passengers[action.payload.passenger],
          insurances: newInsurances,
          showPassengerInList: _(newInsurances).find(item => item.checked)
            ? true
            : false
        }
      }
      return {
        ...state,
        activePassenger: "",
        showPassengersList: _(newPassengers).find(
          item => item.showPassengerInList
        )
          ? true
          : false,
        passengers: newPassengers
      }
    }
    case "removeSegment": {
      const SELECTED_SEGMENTS = _.without(
        state.selectedSegments,
        action.payload
      )

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }
    case "addAncillary": {
      const SELECTED_MEALS = {
        ...state.mealNamesArr,
        [action.payload]: action.payload
      }

      return Stater.consumeState(state)
        .setAncillaries(SELECTED_MEALS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }

    case "removeAncillar": {
      const SELECTED_MEALS = {
        ...state.mealNamesArr,
        [action.payload]: undefined
      }

      return Stater.consumeState(state)
        .setAncillaries(SELECTED_MEALS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue()
    }
    default:
      throw new Error()
  }
}

const CreateFormPopup = (props) => {
  const {
    orderPosition,
    defaultSubject,
    onlyOtherSubject,
    insurances,
    onlyOtherRefundReturnSubjects
  } = useProps()
  const subjects = useSelector(referencesSelector)
    .appeals.subjects.filter(item =>
      onlyOtherRefundReturnSubjects
        ? item.code === "Other" ||
          item.code === "FullExchange" ||
          item.code === "FullRefund"
        : onlyOtherSubject
        ? item.code === "Other"
        : true
    )
    .map(item => ({
      label: item.description,
      value: item.code,
      code: item.code
    }))

  const dispatch = useDispatch()

  const [files, setFiles] = React.useState([])

  const initState = passengersNormalizer(orderPosition, insurances)

  const [state, innerDispatch] = React.useReducer(reducer, initState)

  const initialSubject =
    subjects.find(value => value.code === defaultSubject) || subjects[0]

  const initialValues = {
    subject: initialSubject || { label: "", value: "", code: "" },
    passenger: [],
    message: ""
  }

  const insurancesOptions = _(initState.passengers)
    ?.map(passenger =>
      passenger?.insurances?.map(ins => ({
        value: `${passenger?.value}-${ins?.value}`,
        id: ins?.value,
        passenger: passenger?.value,
        label: `${ins?.label} - ${passenger?.label}`
      }))
    )
    .flatten()
    .value()

  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="close"
        onClick={props.onClose}
      >
          <Icon/>
      </CloseButton>
      <Wrapper style={{ width: "100%" }}>
        <TextBlock>
          <Title>Создание обращения</Title>
          <Description>
            Для возврата или обмена по заявке необходимо выбрать соответствующую
            тему обращения, а также:
          </Description>
          <DescriptionUl>
            <li>
              <strong>Если нужен возврат/обмен только части заявки</strong> -
              выберите из списка пассажиров, сегменты маршрута, по которым
              необходим возврат / обмен.
            </li>
            <li>
              <strong>Если нужен возврат/обмен заявки целиком</strong> - отметьте
              всех пассажиров и все сегменты маршрута.
            </li>
          </DescriptionUl>
        </TextBlock>
        <AppealNotification />
        <Formik
          initialValues={initialValues}
          validationSchema={formikValidateSchema}
          onSubmit={async (data, formik) => {
            // ReactGA.event({
            //   category: 'Order',
            //   action: 'flights_order_position_appeal',
            // });

            if (data.subject.code === "RefundInsurance") {
              dispatch(
                addAppealRequest({
                  text: data.message,
                  passengers: [],
                  orderPositionId: orderPosition.id,
                  subject: data.subject.value,
                  insuranceIds: _(state.passengers)
                    .map(passenger =>
                      passenger.insurances
                        .filter(value => value.checked)
                        .map(ins => ins.value)
                    )
                    .flatten()
                    .uniq()
                    .value(),
                  files
                })
              )
            } else {
              if (
                data.subject.code === "FullRefund" ||
                data.subject.code === "FullExchange"
              ) {
                if (state.selectedPassengers.length === 0) {
                  formik.setFieldError("passengers", "Ошибка")
                  return
                }
                if (state.selectedSegments.length === 0) {
                  formik.setFieldError("segments", "Ошибка")
                  return
                }
              }
              dispatch(
                addAppealRequest({
                  text: data.message,
                  passengers: Object.entries(state.passengers)
                    .filter(([key, value]) => value.selected)
                    .map(([key, value]) => ({
                      aviaPassengerId: value.value,
                      segments: Object.entries(value.selectedSegments).map(
                        ([key, value]) => ({
                          aviaSegmentId: value.value,
                          ancillaryServiceIds: value.selectedAncillaryServices
                        })
                      )
                    })),
                  orderPositionId: orderPosition.id,
                  subject: data.subject.value,
                  insuranceIds: [],
                  files
                })
              )
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            submitCount,
            values,
            setErrors
          }) => (
            <StyledForm>
              <Select
                isSearchable={false}
                onChange={data => {
                  setFieldValue("subject", data)
                }}
                defaultValue={initialSubject}
                name="subject"
                label="Выберите тему обращения"
                options={subjects}
              />
              {showPassengersAndSegments(values.subject.code) && (
                <>
                  <AncillaryServicesBlock>
                    <SelectWithCheckboxes
                      label="Выберите пассажиров"
                      error={errors.passengers}
                      items={state?.passengerList}
                      onSelect={(data, action) => {
                        switch (action) {
                          case "select":
                            innerDispatch({
                              type: "addPassenger",
                              payload: data.value
                            })
                            break
                          case "remove":
                            innerDispatch({
                              type: "removePassenger",
                              payload: data.value
                            })
                            break
                        }
                      }}
                      onSelectAll={() => {
                        innerDispatch({
                          type: "addAllPassengers"
                        })
                      }}
                      onClick={() => {
                        const newErrors = {
                          ...errors
                        }
                        delete newErrors.passengers
                        setErrors(newErrors)
                      }}
                      selectAllText="Выбрать всех пассажиров"
                    />
                  </AncillaryServicesBlock>

                  <AncillaryServicesBlock>
                    <SelectWithCheckboxes
                      label="Выберите сегменты маршрута"
                      error={errors.segments}
                      items={
                        state?.passengers[state.activePassenger]?.segmentList || []
                      }
                      onSelect={(data, action) => {
                        switch (action) {
                          case "select":
                            innerDispatch({
                              type: "addSegment",
                              payload: data.value
                            })
                            break
                          case "remove":
                            innerDispatch({
                              type: "removeSegment",
                              payload: data.value
                            })
                            break
                        }
                      }}
                      onSelectAll={() => {
                        innerDispatch({
                          type: "addAllSegment"
                        })
                      }}
                      onClick={() => {
                        const newErrors = {
                          ...errors
                        }
                        delete newErrors.segments
                        setErrors(newErrors)
                      }}
                      selectAllText="Выбрать все сегменты"
                    />
                  </AncillaryServicesBlock>

                  {isAddictionalServicesRefund(values.subject) &&
                    state?.activePassenger !== "" &&
                    state?.availableAncillaries.length > 0 && (
                      <AncillaryServicesBlock>
                        <Select
                          isMulti={true}
                          isSearchable={false}
                          onChange={(data, meta) => {
                            setFieldValue(
                              "ancillaryServices",
                              (data || []).map(item => item.value)
                            )

                            if (meta.action === "select-option") {
                              innerDispatch({
                                type: "addAncillary",
                                payload: meta.option.value
                              })
                            } else if (meta.action === "remove-value") {
                              innerDispatch({
                                type: "removeAncillar",
                                payload: meta.removedValue.value
                              })
                            }
                          }}
                          name="ancillaryServices"
                          label="Доп. услуги"
                          value={state?.availableAncillaries.filter(
                            x => !!state?.mealNamesArr[x.label]
                          )}
                          placeholder=""
                          options={state?.availableAncillaries}
                        />
                      </AncillaryServicesBlock>
                    )}
                </>
              )}
              {values.subject.code === "RefundInsurance" && (
                <>
                  <AncillaryServicesBlock>
                    <Select
                      isSearchable={false}
                      isMulti={true}
                      onChange={(data, meta) => {
                        if (meta.action === "select-option") {
                          innerDispatch({
                            type: "switchInsurance",
                            payload: { ...meta.option }
                          })
                        }
                      }}
                      name="insurances"
                      label="Страховка"
                      placeholder=""
                      options={insurancesOptions}
                      components={{
                        ValueContainer,
                        MultiValueContainer,
                        MultiValueRemove: () => null
                      }}
                    />
                  </AncillaryServicesBlock>
                  {state.showPassengersList && (
                    <PassengersListWrapper>
                      {_(state.passengers)
                        .map((item, key) => {
                          return item.showPassengerInList ? (
                            <InsurancesPassenger key={key}>
                              <PassengerName>{item.label}</PassengerName>
                              <InsuranceList>
                                {item.insurances
                                  .filter(item => item.checked)
                                  .map((ins, key) => {
                                    return (
                                      <React.Fragment key={key}>
                                        <InsuranceItem>
                                          <Text fontWeight="600">
                                            {ins.label}
                                          </Text>
                                          <CrossButton
                                            onClick={() => {
                                              innerDispatch({
                                                type: "switchInsurance",
                                                payload: {
                                                  id: ins.value,
                                                  passenger: item.value
                                                }
                                              })
                                            }}
                                          />
                                        </InsuranceItem>
                                        {"  "}
                                      </React.Fragment>
                                    )
                                  })}
                              </InsuranceList>
                            </InsurancesPassenger>
                          ) : null
                        })
                        .value()}
                      <GrayLine />
                    </PassengersListWrapper>
                  )}
                </>
              )}
              <Textarea
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.message && submitCount > 0 ? errors.message : undefined
                }
                name="message"
                marginTop={15}
                style={{ width: "100%", resize: "none" }}
                rows={10}
                label="Текст обращения"
              />
              <StyledDragAndDropFiles
                onChange={files => {
                  setFiles(files)
                }}
              />
              <LastBlock>
                <div></div>

                <Submit>Отправить</Submit>
              </LastBlock>
            </StyledForm>
          )}
        </Formik>
      </Wrapper>
    </StyledPopup>
  )
}

export default CreateFormPopup
