import React from 'react'
import useHotelDescriptionStore from '../store'

import './style.css'

export default function HotelLocation({ handleClickOpenMap }) {
	const locationHotelText = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel?.text
	)
	function createText() {
		const newValue = locationHotelText?.startsWith('<p>')
			? locationHotelText?.slice(3, locationHotelText.length - 4)
			: locationHotelText
		return {
			__html:
				newValue?.length > 233 ? newValue?.slice(0, 230) + '...' : newValue
		}
	}
	return (
		<>
			<h4 style={{ margin: '0px 0px 14px 0px' }}>Расположение</h4>
			<div className='container-map'>
				<div className='hotel-map'>
					<div onClick={handleClickOpenMap} className='open-map'>
						Посмотреть на карте
					</div>
				</div>
				<div
					style={{ color: '#2e2e32', margin: '0px' }}
					dangerouslySetInnerHTML={createText()}></div>
			</div>
		</>
	)
}
