import React, { useMemo } from 'react'
import { ReactComponent as ExcursionFiltersIcon } from '../../../../../asset/icons/ExursionFilter.svg'
import styled from 'styled-components'
import MobileFilterPopup from './MobileFilterPopup'
import { Badge } from '@mui/material'
import { isEmpty } from 'lodash'
import useHotelServicesStore from '../../../store'

const FilterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 48px;
	height: 48px;
	padding: 8px;
	border-radius: 8px;
	background: #f6f8fa;
`

export default function MobileFiltersBlock() {
    const [open, setOpen] = React.useState(false)

    const excursionType = useHotelServicesStore(
        ({ excursionType }) => excursionType
    )

    const checkedExcursionType = useHotelServicesStore(
        ({ checkedExcursionType }) => checkedExcursionType
    )

    const actionsType = useHotelServicesStore(
        ({ actionsType }) => actionsType
    )

    const checkedActionsType = useHotelServicesStore(
        ({ checkedActionsType }) => checkedActionsType
    )

    const handleClickOpen = () => {
        setOpen(true)
    }

    const count = useMemo(() => {
        if (
            isEmpty(checkedExcursionType) &&
            isEmpty(checkedActionsType)
        ) {
            return 0
        } else
            return (
                checkedExcursionType?.length + checkedActionsType?.length
            )
    }, [checkedExcursionType, checkedActionsType])

    return (
        <>
            {((!isEmpty(excursionType)) || !isEmpty(actionsType)) && (
                <FilterContainer onClick={handleClickOpen}>
                    <Badge badgeContent={count} color='primary' invisible={!count}>
                        <ExcursionFiltersIcon />
                    </Badge>
                </FilterContainer>
            )}
            <MobileFilterPopup open={open} setOpen={setOpen} />
        </>
    )
}
