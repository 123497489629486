import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {searchFormReducer as searchForm} from './modules/search-form/duck'
import {searchResultReducer as searchResult} from './modules/search-result/duck'
import orders from './modules/orders/duck';
import references from './modules/references/duck';
import appeals from './modules/appeals/duck'
import {default as user} from './modules/user/duck'
import {booking} from './modules/booking'
import agents from './modules/agents/duck';
import notification from "./modules/notification/duck";
import rootSaga from './sagaConfigs';

const sagaMiddleware = createSagaMiddleware()


const store = configureStore({
    reducer: {
        searchForm,
        searchResult,
        user,
        booking,
        orders,
        appeals,
        references,
        notification,
        agents
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);


export default store;