import {normilizeResponse,createFilter} from './normalizer';
import FetchApi from '../../../functions/fetchApi';
import moment from 'moment';

export function fetchFlights(args) {
  let url = process.env.REACT_APP_GET_FLIGHT_SEARCH  + '/';
  const passengers = `${args.passengers.adults}-${args.passengers.children}-${args.passengers.infants}`;
  const fromTo = `${args.origin}-${args.destination}`;
  const toFrom = `${args.destination}-${args.origin}`;
  const firstDate = moment(
      args.forwardDate
  );
  const secondDate = args.backwardDate ? moment(args.backwardDate) : null;

  if(!secondDate) {
    url += `${fromTo}/${firstDate.format('YYYY-MM-DD')}/${passengers}/${args.category}/false/z`;
  } else {
    url += `${fromTo}/${firstDate.format('YYYY-MM-DD')}/${toFrom}/${secondDate ? secondDate.format('YYYY-MM-DD') : "nextDate=false"}/${passengers}/${args.category}/false/z`;
  }

  return FetchApi.get(url)
  .query({agentCode: args.agentSamoCode})
  .setContentType('application/json')
  .send()
  .then(normilizeResponse)
  .then((data) => ({ ...data, filter: createFilter(data) }));
}



