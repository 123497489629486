import React, { useEffect, useRef, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import {FormHelperText, IconButton, InputAdornment, OutlinedInput} from '@material-ui/core'
import {addMonths, addYears, format, isValid} from 'date-fns'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import Calendar from 'react-calendar'

import './style.css'
import { useErrorStore } from "../../pages/Partner/Insurances/Booking/components/MembersBlock/store";

const NumberToDateRegex = /^(\d{2})(\d{2})(\d{4})$/;
const DateRegex = /\b(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.((19|20)\d{2})\b/g;
const ISODateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/

const CHAR_CODES = {
	DELETE: 46,
	DOT: 190,
	ZERO: 48,
	NINE: 57,
	MIN_CONTROL_CHAR_CODE: 31,
	DELETE_NAME: 'Delete',
	BACKSPACE: 8
}
const MAX_DAY = 31;
const MAX_MONTH = 12;

const BirthDateInput = ({ label, onChange, value, count, isExpiration, fixbirthday = false, ...props }) => {
	const [selectedDate, handleDateChange] = useState(value ?? "")
	const [selectedInputDate, handleInputDateChange] = useState(value ?? "")
	const [showCalendar, setShowCalendar] = useState(false)
	const [error, setError] = useState('')

	const setErrorForm = useErrorStore(state => state.setBirthDayError)

	const calendarRef = useRef(null)
	const closeCalendarRef = useRef(null)

	useEffect(() => {
		setShowCalendar(false)
	}, [selectedDate])

	useEffect(() => {
		function handleClick(event) {
			if (calendarRef.current && !calendarRef.current.contains(event.target) && !closeCalendarRef.current.contains(event.target)) {
				setShowCalendar(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [calendarRef,setShowCalendar]);

	useEffect(() => {
		if (value instanceof Date) {
			handleDateChange(value ? new Date(value) : null)
			handleInputDateChange(value ? new Date(value) : null)
		} else {
			let formattedDate = value?.split('.')
			handleDateChange(value ? new Date(new Date(formattedDate[2], (Number(formattedDate[1]) - 1).toString(), formattedDate[0])) : null)
			handleInputDateChange(value ? new Date(new Date(formattedDate[2], (Number(formattedDate[1]) - 1).toString(), formattedDate[0])) : null)
		}

	}, [value])

	const handleInputChange = event => {
		setErrorForm(false)
		setError('')
		let value = event.target.value

		if(value.length > 10){
			setErrorForm(true)
			if (fixbirthday) {
				setError('Некорректная дата рождения')
			}
			return
		}

		if (NumberToDateRegex.test(value) || DateRegex.test(value)) {

			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			const date = new Date(changeDate[2], changeDate[1] - 1, changeDate[0])
			handleInputDateChange(date)
			if (fixbirthday) {
				// const age = Math.trunc((new Date(selectedDate) - new Date()) / (1000 * 60 * 60 * 24 * 365))
				const age = Number(JSON.parse(localStorage.getItem('ages'))[count - 1])
				if (date < addYears(new Date(), -age - 1) || date > addYears(new Date(), -age)) {
					setErrorForm(true)
					setError(`Дата рождения не может быть раньше ${format(addYears(new Date(), -(age + 1)), 'dd.MM.yyyy')} и позднее ${format(addYears(new Date(), -age), 'dd.MM.yyyy')}`)
				} else {
					handleDateChange(date)
					onChange(date, count)
				}
			} else {
				handleDateChange(date)
				onChange(date, count)
			}

			return
		} else {
			setErrorForm(true)
			if (fixbirthday) {
				setError('Некорректная дата рождения')
			}
		}

		if (value.length === 2 && value.slice(0, 2) > MAX_DAY) {
			handleInputDateChange(`${MAX_DAY}.`)
			return
		}
		if (value.length === 5 && value.slice(3, 5) > MAX_MONTH) {
			handleInputDateChange(`${value.slice(0, 3)}${MAX_MONTH}.`)
			return
		}

		handleInputDateChange(value)

		if (NumberToDateRegex.test(value) || DateRegex.test(value)) {
			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			const date = new Date(changeDate[2], changeDate[1] - 1, changeDate[0])
			handleInputDateChange(date)
			handleDateChange(date)
			onChange(date, count)
			return
		}
	}


	const formateInput = (date) => {
		if (ISODateRegex.test(date)) {
			const formatDate = new Date(date)
			return format(formatDate, 'dd.MM.yyyy')
		}
		if (isValid(date)) {
			return format(date, 'dd.MM.yyyy')
		}
		return date
	}
	const handleChange = date => {
		setError('')
		setErrorForm(false)
		handleInputDateChange(date)
		handleDateChange(date)
		onChange(date, count)
	}

	const handleShowCalendar = () => {
		setShowCalendar(!showCalendar)
	}

	const onFocus = (event) => {
		if (event.target.value === "ДД.ММ.ГГГГ") {
			handleInputDateChange('')
		}
	}
	const onBlur = (event) => {
		const { value } = event.target;
		if (!value) {
			handleInputDateChange("ДД.ММ.ГГГГ")
		}
		if (isExpiration && value.length === 10 && new Date(value.split('.').reverse().join('-')) <= new Date()) {
			setErrorForm(true)
			setError('Некорректная дата')
		}

		if (!isExpiration && value.length === 10 && new Date(value.split('.').reverse().join('-')) > new Date()) {
			setErrorForm(true)
			setError('Некорректная дата рождения')
		}
	};

	const handleKeyPress = (event) => {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode !== CHAR_CODES.DELETE && charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE && (charCode < CHAR_CODES.ZERO || charCode > CHAR_CODES.NINE)) {
			event.preventDefault();
		}
	};

	const handleKeyDown = (event) => {
		const value = event.target.value
		const charCode = event.which ? event.which : event.keyCode

		if (charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE && charCode === CHAR_CODES.DOT) {
			return
		}
		if (charCode === CHAR_CODES.BACKSPACE || charCode === CHAR_CODES.DELETE) {
			return
		}
		if (value.length === 2 || value.length === 5) {
			event.target.value = value + '.'
			return
		}
	};

	const getTilesDisabled = (date, view) => {
		switch (view) {
			case 'month':
				if (fixbirthday) {
					const age = Number(JSON.parse(localStorage.getItem('ages'))[count - 1])
					return date < addYears(new Date(), -age - 1) || date > addYears(new Date(), -age)
				} else {
					if (isExpiration) {
						return date < new Date()
					} else {
						return date > new Date()
					}
				}
			case 'year':
				if (fixbirthday) {
					const age = Number(JSON.parse(localStorage.getItem('ages'))[count - 1])
					return date < addMonths(addYears(new Date(), -age - 1), -1) || date > addYears(new Date(), -age)
				} else {
					if (isExpiration) {
						return date.getMonth() < new Date().getMonth()
					} else {
						return date > new Date()
					}
				}
			case 'decade':
				if (fixbirthday) {
					const age = Number(JSON.parse(localStorage.getItem('ages'))[count - 1])
					return date < addYears(new Date(), -age - 2) || date > addYears(new Date(), -age)
				} else {
					if (isExpiration) {
						return date.getFullYear() < new Date().getFullYear()
					} else {
						return date.getFullYear() > new Date().getFullYear()
					}
				}
			default: return true
		}

	}

	return (
		<div>
			<FormControl variant='outlined' style={{ width: '100%' }}>
				<InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
				<OutlinedInput
					label={label}
					id='outlined-adornment-password'
					value={formateInput(selectedInputDate)}
					className='mui-date-input'
					onChange={handleInputChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onKeyDown={handleKeyDown}
					onKeyPress={handleKeyPress}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton
								ref={closeCalendarRef}
								aria-label='toggle password visibility'
								edge='end'
								onClick={handleShowCalendar}>
								<EventAvailableOutlinedIcon />
							</IconButton>
						</InputAdornment>
					}
					error={!!error}
					{...props}
				/>
				{error &&
					<FormHelperText error id="accountId-error">
						{error}
					</FormHelperText>}
				{props.helperText &&
					<FormHelperText error id="accountId-error">
						{props.helperText}
					</FormHelperText>}
			</FormControl>
			{showCalendar && (
				<div className='date-input' ref={calendarRef}>
					<Calendar
						value={isValid(selectedDate) ? selectedDate : new Date()}
						date={selectedDate}
						onChange={date => handleChange(date)}
						tileDisabled={({ date , view}) => getTilesDisabled(date , view)}
						// tileClassName={({ date }) => setCheckinData(date)}
					/>
				</div>
			)}
		</div>
	)
}

export default BirthDateInput
