import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../services/api'
import {isEmpty, sortBy} from 'lodash'
import {format} from "date-fns";

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useVisaStore = create(
	devtools((set, get) => ({
		content: {},
		countries: [],
		countriesList: [],
		filteredCountries: [],
		countryId: null,
		countrySheduleId: null,
		cityId: 274286,
		tourStartDate: null,
		tourSheduleStartDate: null,
		tourEndDate: null,
		passportValidUntilDate: null,
		calcMessage: null,
		calcValidDate: null,
		calcSheduleDate: null,
		departureCities: null,
		actions: {
			getPage: async link => {
				const data = {
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance({
					headers: { clientId: 'b2b:ru' }
				}).post(`/visa/country/${link}`, data)
				set({ content: res.data })
				get().actions.getCountryList()
			},
			getMainPage: async () => {
				const data = {
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance({
					headers: { clientId: 'b2b:ru' }
				}).post(`/visa/main`, data)
				set({ content: res.data })
				get().actions.getCountryList()
				// get().actions.getCountries()
			},
			getDeparture: async () => {
				set({ loading: true })
				try {
					const ruResult = await axios({
						url: `${BASE_URL}/Filters/GetDepartureCities`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ departureCities: ruResult.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getCountryList: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/geocontent/country/list`,
						method: 'GET',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`,
							'Accept-Language': 'ru-RU'
						}
					})
					set({ countriesList: res.data })
					// const calcExp = get().content.calcExp
					// const filteredByCalcExp = res.data.filter(
					// 	country =>
					// 		calcExp.includes(country?.urlName?.split('/')[2]) &&
					//		 !countriesByExp.includes(country?.name)
					// )
					// set({ filteredCountries: sortBy(filteredByCalcExp, 'name') })
				} catch (e) {
					console.log(e)
				}
			},
			getCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=1&ClientId=b2c:ru`,
						method: 'GET',
						params: {
							LoadCities: false
						}
					})
					set({ countries: res.data })
				} catch (e) {
					console.log(e)
				}
			},
			changeCountry: value => {
				set({
					calcValidDate: null
				})
				set({ countryId: value })
			},
			changeSheduleCountry: value => {
				set({
					calcSheduleDate: null
				})
				set({ countrySheduleId: value })
			},
			changeCity: value => {
				set({
					calcSheduleDate: null
				})
				set({ cityId: value })
			},
			changeStartDate: value => {
				set({
					calcValidDate: null
				})
				const tourEndDate = get().tourEndDate
				if (
					!tourEndDate ||
					(tourEndDate && new Date(tourEndDate).getTime() < new Date(value).getTime())
				) {
					set({ tourStartDate: value })
					set({ tourEndDate: value })
				} else {
					set({ tourStartDate: value })
				}
			},
			// changeStartDate: value => {
			// 	set({ tourStartDate: value })

			// },
			changeSheduleStartDate: value => {
				set({
					calcSheduleDate: null
				})
				set({ tourSheduleStartDate: value })
			},
			changeEndDate: value => {
				set({
					calcValidDate: null
				})
				const tourStartDate = get().tourStartDate
				if (
					tourStartDate &&
					new Date(tourStartDate).getTime() > new Date(value).getTime()
				) {
					set({ tourStartDate: value })
					set({ tourEndDate: value })
				} else {
					set({ tourEndDate: value })
				}
			},
			changePassportValidUntilDate: value => {
				set({ passportValidUntilDate: value })
			},
			getPassportValid: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Visas/passport-validity-date`,
						method: 'GET',
						params: {
							CountryId: get().countryId,
							TourStartDate: format(new Date(get().tourStartDate), 'yyyy-MM-dd'),
							TourEndDate: format(new Date(get().tourEndDate), 'yyyy-MM-dd')
						}
					})
					set({
						calcValidDate: res.data
					})
				} catch (e) {
					console.log(e)
				}
			},
			getPassportSheduleValid: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Visas/Schedule`,
						method: 'GET',
						params: {
							DepartureCityId: get().cityId,
							ArrivalCountryId: get().countrySheduleId,
							ArrivalDate: format(new Date(get().tourSheduleStartDate), 'yyyy-MM-dd')
						}
					})
					let calcSheduleDate = res.data
					if (isEmpty(calcSheduleDate)) {
						set({
							calcSheduleDate: ' '
						})
					} else {
						calcSheduleDate = calcSheduleDate.map(date => new Date(date.deadlineDate))
						calcSheduleDate = new Date(Math.min.apply(null, calcSheduleDate));
						set({
							calcSheduleDate: calcSheduleDate.toISOString()
						})
					}
				} catch (e) {
					console.log(e)
				}
			},
			clearCalc: () => {
				set({
					calcValidDate: null
				})
			}
		}
	}))
)

export default useVisaStore

// const countriesByExp = [
// 	'Индонезия',
// 	'Танзания',
// 	'Австрия',
// 	'Сейшелы',
// 	'Узбекистан',
// 	'Франция',
// 	'Хорватия',
// 	'Словения',
// 	'Чехия',
// 	'Финляндия'
// ]
