import {
  call,
  all,
  takeLatest,
  put,
  delay,
  select,
  spawn
} from "redux-saga/effects"
import {
  checkAuthStatus,
  changeUserData,
  changeUserDataSuccess,
  getUserDataFailure,
  getUserDataRequest,
  getUserDataSuccess,
  getPassanagersRequest,
  getPassangersSuccess,
  updatePassangersRequest,
  updatePassangersSuccess,
  removePassangerRequest,
  removePassangerSuccess,
  createPassangersRequest,
  createPassangersSuccess,
  purifyError,
  setFetchUserStatus
} from "./duck"
import { setAgentAuthInformation } from '../agents/agent-finance/duck';
import * as Manager from "./manager"
import { getUserDataState } from "./selectors"



function navigate(url) {
  window.location.href = url;
}

function handleHttpErrors(err){
 //do nothing
}






function* checkAuthStatusWorker() {
  try {
    yield call(loadUserData);
  } catch (err) {
    console.log(err);
  }
}







function* loadUserData() {
  try {
    const userData = yield select(getUserDataState)
    if (!userData) {
      const data = yield call(Manager.getUserData)
      yield put(getUserDataSuccess(data))
      yield put(
        setAgentAuthInformation({
          agentId: data.agentId,
          agentSamoCode: data.agentSamoCode,
        })
      );

      yield put(setFetchUserStatus(false));
    }
  } catch (e) {
    yield put(setFetchUserStatus(false));
    console.log(e);
  }
}

export function* getUserDataRequestWorker() {
  try {
    const data = yield call(Manager.getUserData)
    if (data?.agentId && data.agentSamoCode) {
      yield put(
        setAgentAuthInformation({
          agentId: data.agentId,
          agentSamoCode: data.agentSamoCode
        })
      )
    }

    yield put(getUserDataSuccess(data))
  } catch (err) {
    yield put(handleHttpErrors(err))
    yield put(getUserDataFailure())
  }
}

export function* getUserDataRequestBookingWorker() {
  const data = yield call(Manager.getUserData)
  yield put(getUserDataSuccess(data))
}

export function* changeUserDataWorker({ payload }) {
  try {
    yield call(Manager.updateUserData, payload)
    yield delay(2000)

    yield put(getUserDataSuccess(payload))
    yield put(changeUserDataSuccess())
  } catch (err) {
    yield put(handleHttpErrors(err))
    //err
  }
}

export function* editPassangerWorker({ payload }) {
  try {
    yield call(Manager.editPassanger, payload)
    yield delay(1000)
    yield put(updatePassangersSuccess())
    yield call(getAviaPassengersWorker)
    // if (!payload.isBooking) {
      // yield call(navigate, "/cabinet/my-passengers")
    // }
    yield put(updatePassangersSuccess())
  } catch (err) {
    // yield put(
    //   setUserError('Ошибка при редактировании пассажира, проверьте данные')
    // );
  }
}

export function* createPassangerWorker({ payload }) {
  try {
    yield call(Manager.createPassanger, payload)
    yield delay(1000)
    yield put(createPassangersSuccess())
    yield call(getAviaPassengersWorker)
    if (!payload.isBooking) {
      yield call(navigate, "/cabinet/my-passengers")
    }
  } catch (err) {
    // yield put(setUserError('Ошибка при создании пассажира, проверьте данные'));
    //err
  }
}

export function* removePassangerWorker({ payload }) {
  try {
    yield call(Manager.removePassanger, payload)
    yield delay(1000)

    yield put(removePassangerSuccess())
    yield call(getAviaPassengersWorker)
    yield call(navigate, "/cabinet/my-passengers")
  } catch (err) {
    // yield put(removePassangerFailure());
    //err
  }
}

export function* openAuthModalWorker() {
  yield put(purifyError())
}

export function* getAviaPassengersWorker(action) {
  const data = yield call(Manager.getPassangers, action?.payload?.SearchText)
  yield put(getPassangersSuccess(data))
}

export default function* userFlow() {
  yield all([
    takeLatest(createPassangersRequest.getType(), createPassangerWorker),
    takeLatest(updatePassangersRequest.getType(), editPassangerWorker),
    takeLatest(removePassangerRequest.getType(), removePassangerWorker),
    takeLatest(getPassanagersRequest.getType(), getAviaPassengersWorker),

    takeLatest(checkAuthStatus.getType(), checkAuthStatusWorker),
    takeLatest(getUserDataRequest.getType(), getUserDataRequestWorker),
    takeLatest(changeUserData.getType(), changeUserDataWorker)
  ])
}
