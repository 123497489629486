import React, { useEffect } from 'react'
import './style.css'
import { format } from 'date-fns'
import { Button } from '@material-ui/core'
import useBookingStore from '../../store'
import useLoyaltyStore from "../../../Loyalty/store";
import Commission from "../Commission";
import { currencyAlias } from '../../currencyAlias'
import { useErrorStore } from '../PaxesBlock/components/MembersBlock/store'
import PreReserve from '../PreReserve'
import useAuthStore from "../../../Auth/store";

export default function ButtonsBlock({ response, fromCRM }) {
	const { forwardFlightId, backwardFlightId, selectedFlightOptionId } =
		useBookingStore()
	const oldPrice = useBookingStore(state => state.oldPrice)
	const phoneErrorGds = useBookingStore(({ phoneErrorGds }) => phoneErrorGds)
	const currencys = useBookingStore(({ currencys }) => currencys)
	const actions = useBookingStore(({ actions }) => actions)
	const loading = useBookingStore(({ newBookingLoading }) => newBookingLoading)
	const checkValid = useErrorStore(({ checkValid }) => checkValid)
	const checkedPreReserve = useBookingStore(({ checkedPreReserve }) => checkedPreReserve)
	const errors = useBookingStore(({ buyerErrors }) => buyerErrors)
	const errorPaxes = useErrorStore(({ error }) => error)
	const isReadyToReserve = useBookingStore(({ isReadyToReserve }) => isReadyToReserve)
	const peoples = useBookingStore(({ peoples }) => peoples)
	const buyer = useBookingStore(({ buyer }) => buyer)

	const preorder = useLoyaltyStore(({ preorder }) => preorder)
	const memberId = useLoyaltyStore(({ memberId }) => memberId)
	const client = useLoyaltyStore(({ client }) => client)
	const spentPoints = useLoyaltyStore(({ spentPoints }) => spentPoints)

	const agents = useAuthStore(({ agent }) => agent)

	useEffect(() => {
		actions.setNewPrice()
	}, [forwardFlightId, backwardFlightId, actions, selectedFlightOptionId])

	useEffect(() => {
		actions.setIsReadyToReserve(false)
	}, [JSON.stringify(peoples), JSON.stringify(buyer)])

	const getRates = () => {
		if (response?.claimDocument?.currencyKey === 1) {
			return 1
		}
		let currency = null
		Object.values(currencys).forEach(item => {
			const foundCurrency = item.find(value => value.currencyKey === response?.claimDocument?.currencyKey);
			if (foundCurrency) {
				currency = foundCurrency.rate;
			}
		})
		if (currency) {
			return currency
		}
	}

	const tourRecount = async () => {
		checkValid()
		actions.setNotesErrors()

		await actions.changeServices()
			.then(() => {
				actions.ActualizePacket().then(() => actions.getReservationPrice())
			})

		const element = document.getElementById(`price-buttons-block`)
		element.scrollIntoView({ behavior: 'smooth' })
		actions.setIsReadyToReserve(true)
	}

	const reserve = async () => {
		checkValid()
		actions.setNotesErrors()
		console.log(errors)
		if (Object.values(errors).some(item => item) || errorPaxes  || phoneErrorGds || !isReadyToReserve) {
			document.querySelector('[data-errorsValidation="true"]')?.scrollIntoView({ behavior: 'smooth' })
			return
		}

		await actions.checkReservation({
			memberId: memberId,
			client: client,
			spentPoints: spentPoints,
			preorder: preorder
		})
	}

	const saveToCRM = () => {
		const hotels = response?.claimDocument.hotels.map(hotel => {
			return {
				...hotel,
				www: `https://fstravel.com/hotel/${hotel.key}`
			}
		})
		const data = {
			"MessageType": "SaveTourData_CallBack",
			"gm_jsonfull": {
				claimDocument: [
					{
						...response?.claimDocument,
						arrivalCountryId: response?.claimDocument.stateKey.toString(),
						maxNightsCount: response?.claimDocument.nights.toString(),
						minNightsCount: response?.claimDocument.nights.toString(),
						townFromKey: response?.claimDocument.townFromKey.toString(),
						promo_codetext: "",
						price: response?.claimDocument.moneys.find(item => item.currency === 'RUB')?.price,
						tourname: response?.claimDocument.tour,
						townfromname: response?.claimDocument.townFrom,
						partner: agents.agents.id,
						partpass: Number(agents.partPass),
						hotels: hotels,
					}
				]
			}
		}
		window.top.postMessage(data, '*')
	}

	const money = response?.claimDocument?.moneys?.find(item => item.currencyKey === response.claimDocument.currencyKey)
	const moneyRub = response?.claimDocument?.moneys?.find(item => item.currencyKey === 1)

	return (
		<>
			<div className={`price-amount ${oldPrice ? 'stroke' : ''}`}>
				<div className='eur'>
					{Math.round(preorder?.order?.totalPrice / getRates()) || money?.price}{' '}
					{currencyAlias[response?.claimDocument?.currencyKey || 3]}
				</div>
				{response?.claimDocument?.currencyKey !== moneyRub?.currencyKey && (
					<div className='rub'>
						{Math.round(preorder?.order?.totalPrice) || moneyRub?.price} {currencyAlias[moneyRub.price]} (курс на{' '}
						{format(new Date(), 'dd.MM.yyyy')})
					</div>
				)}
			</div>
			{Number(response?.claimDocument?.enableDraftClaim ?? 0) > 0 && <PreReserve />}
			{!oldPrice && (
				<Commission
					// price={Math.round(preorder?.order?.totalPrice / getRates()) || money?.price}
					currency={currencyAlias[money?.currencyKey ?? 1]}
					partnerCommission={response.claimDocument.comissionPercent}
					money={money}
				/>
			)}
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				<Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={tourRecount}>
					Пересчитать
				</Button>
				<Button
					variant='contained'
					onClick={reserve}
					disabled={oldPrice || loading || !isReadyToReserve}
				>
					{checkedPreReserve ? 'Сохранить' : "Бронировать"}
				</Button>
				{fromCRM && <Button
					style={{ marginLeft: 10 }}
					variant='contained'
					onClick={saveToCRM}
					// disabled={oldPrice || phoneErrorGds || loading}
				>
					Сохранить предложение
				</Button>}
			</div >
		</>
	)
}
