// eslint-disable react-hooks/exhaustive-deps

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SubGroup from './subgroup';
import Checkbox from './checkbox';

const CheckboxContainer = styled.div`
  & label {
    display: block;
    margin-bottom: 15px;
  }
  & label:last-of-type {
    margin-bottom: 0;
  }
`;

const Group = ({
  values,
  scrollSubGroup = false,
  scrollContent = false,
  type,
  onChange,
  groupType,
}) => {
  const [vals, setVals] = useState(
    values.map((val) => {
      if (Array.isArray(val.values)) {
        return {
          name: val.name,
          values: val.values.map((v) => {
            return {
              checked: v.checked || true,
              value: v.value,
              label: v.label,
            };
          }),
        };
      } else {
        return {
          checked: val.checked || true,
          value: val.value,
          label: val.label,
          icon: val.icon,
        };
      }
    })
  );

  useEffect(() => {
    const groupCheckedAll = [];
    setVals(
      values.map((val) => {
        if (Array.isArray(val.values)) {
          let all = true;
          const data = {
            name: val.name,
            values: val.values.map((v) => {
              if (v.checked !== undefined && !v.checked) {
                all = false;
              }
              return {
                checked: v.checked !== undefined ? v.checked : true,
                value: v.value,
                label: v.label,
                icon: v.icon,
              };
            }),
          };
          groupCheckedAll.push(all);
          return data;
        } else {
          if (
            val.checked !== undefined && !val.checked) {
          }
          return {
            checked:
              val.checked !== undefined
                ? val.checked
                : true,
            value: val.value,
            label: val.label,
            icon: val.icon,
          };
        }
      })
    );
  }, [values]);

  const sendChanges = vals => {
    if (typeof onChange !== 'function') {
      return;
    }
    if (groupType === 'subgroups') {
      const result = vals.map((val, index) => {
        const v = val;
        const obj = {};
        v.values.forEach(({ value, checked }) => {
          obj[value] = checked;
        });
        return obj;
      });
      onChange(result);
    } else {
      const obj = {};
      vals.forEach((val, index) => {
        const { value, checked } = val;
        obj[value] = checked;
      });
      onChange(obj);
    }
  };

  const handleSubgroupChange = (
    e
  ) => {
    const index = parseInt(e.target.dataset.index);
    const key = parseInt(e.target.dataset.key);
    const item = vals[index].values;
    const checked = !item[key].checked;
    item[key] = { ...item[key], checked };
    sendChanges(vals);
    setVals([...vals]);
  };

  const hanldeSimpleChange = (
    e
  ) => {
    const key = parseInt(e.target.dataset.key);
    const checked = !vals[key].checked;
    vals[key] = { ...vals[key], checked };
    sendChanges(vals);
    setVals([...vals]);
  };

  const content = vals.map((val, key) => {
    if (Array.isArray(val.values)) {
      return (
        <SubGroup
          name={val.name}
          scrollContent={scrollSubGroup}
        >
          {val.values.map((props, key2) => (
            <Checkbox
              onChange={handleSubgroupChange}
              data-index={key}
              data-key={key2}
              checked={vals[key].values[key2].checked}
              {...props}
              type={type}
            />
          ))}
        </SubGroup>
      );
    } else {
      return (
        <Checkbox
          onChange={hanldeSimpleChange}
          data-key={key}
          {...val}
          type={type}
          icon={val.icon}
        />
      );
    }
  });

  return (
    <CheckboxContainer>{content}</CheckboxContainer>
  );
};

export default Group;
