import React from 'react';
import styled from 'styled-components';
import { Text } from '../../ui';

const StrongText = styled(Text)`
  font-weight: 600;
  margin-top: 15px;
  font-size: 14px;
`;

const TextContainer = styled.p`
  margin-bottom: 25px;
`;

const TextContainerSmall = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
`;

const MarkedList = styled.ul`
  padding-left: 24px;
  margin-bottom: 0;
`;

const MarkedListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 15px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

const PenaltyDescription = () => {
  return (
    <div>
      <TextContainerSmall>
        <StrongText>
          Политика взимания сервисных сборов Fun&Sun при возврате, помимо
          штрафных санкций перевозчика:
        </StrongText>
      </TextContainerSmall>
      <TextContainerSmall>
        <StrongText>За возврат:</StrongText>
      </TextContainerSmall>
      <MarkedList>
        <MarkedListItem>
          <Text>
            Если сумма к возврату составляет 3100 рублей и более, сбор составит
            1900 рублей.
          </Text>
        </MarkedListItem>
        <MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 3100 рублей, сбор составит
            1100 рублей.
          </Text>
        </MarkedListItem>
        <MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 2000 рублей, сбор составит
            750 рублей.
          </Text>
        </MarkedListItem>
      </MarkedList>
      <TextContainerSmall>
        <StrongText>
          За возврат в случае отмены рейса, изменения расписания:
        </StrongText>
      </TextContainerSmall>
      <MarkedList>
        <MarkedListItem>
          <Text>
            Если сумма к возврату составляет 3100 рублей и более, сбор составит
            1100 рублей.
          </Text>
        </MarkedListItem>
        <MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 3100 рублей, сбор составит
            500 рублей.
          </Text>
        </MarkedListItem>
      </MarkedList>
      <TextContainer>
        <StrongText>
          При любом возврате Fun&Sun удерживает сбор в размере 2% от стоимости
          заявки, если билет был оплачен российской картой и 3,6% в случае
          оплаты иностранной картой через Fun&Sun. Сбор Fun&Sun, оплаченный при
          покупке билета, возврату не подлежит.
        </StrongText>
      </TextContainer>
    </div>
  );
};

export default PenaltyDescription;
