import { create } from 'zustand'

export const useErrorStore = create(set => ({
	error: null,
	touristError: null,
	setErrorForm: value => {
		set({ error: value })
	},
	setErrorTouristForm: value => {
		set({ touristError: value })
	}
}))
