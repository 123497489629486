import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import {  styled  } from '@mui/material/styles';


const StyledFormControl = styled(FormControl)(({ fullWidth }) => ({
	width: fullWidth  ? '100%' : undefined,
}))

const StyledInput = styled(InputBase)(({ theme,fullWidth }) => ({
  'label + &': {
    marginTop: '22px',
  },
  '& .MuiInputBase-input': {
    color: '#3C3C3C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    borderRadius: 4,
    position: 'relative',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#DCDCDC' : '#2D3843',
    width: fullWidth ? '100%' : 'auto',
    padding: '9px 12px',
		height: 'auto',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: [
			'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus, &:hover': {
      borderColor: '#959595',
    },
		'&.Mui-error': {
			color: '#E73C3E',
			borderColor: '#E73C3E',
		}
  },
	'&.Mui-error > .MuiInputBase-input': {
		color: '#E73C3E',
		borderColor: '#E73C3E',
		'&:focus': {
			color: '#3C3C3C',
    },
	}
}));

const StyledLabel = styled(InputLabel)(() => ({
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	fontSize: '14px',
	lineHeight: '18px',
	transform: 'none',
	color: '#737373',
	'&.Mui-focused': {
		color: '#3c3c3c'
	},
	'&.Mui-error': {
		color: '#E73C3E'
	}
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	fontSize: '12px',
	lineHeight: '16px',
	transform: 'none',
	color: '#737373',
	fontWeight: 400,
	letterSpacing: 'inherit',
	marginTop: 4,
	'&.Mui-error': {
		color: '#E73C3E'
	}
}))

const StyledFormErrorText = styled(FormHelperText)(({ theme }) => ({
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	fontSize: '12px',
	lineHeight: '16px',
	transform: 'none',
	position: 'absolute',
	bottom: '-20px',
	fontWeight: 400,
	letterSpacing: 'inherit',
	marginTop: 4,
	color: '#E73C3E !important',
}))


export default function NewTextField ({
	label,
	htmlFor,
	error,
	textHelper,
	containerSx,
	...props
}) {
  return	(
		<StyledFormControl variant="standard" error={!!error} sx={containerSx} className={props.className} fullWidth={props.fullWidth}>
			<StyledLabel shrink htmlFor={props.name}>
				{label}
			</StyledLabel>
			<StyledInput {...props} id={props.name}/>
			{!!textHelper && <StyledFormHelperText>{textHelper}</StyledFormHelperText>}
			{!!error && !textHelper && <StyledFormErrorText>{error}</StyledFormErrorText>}
		</StyledFormControl>
	)
}