import {encodeQueryString} from "../../../../functions/sequelize";
import {addDays, format, isBefore, isEqual} from "date-fns";

export const createSearchUrl = (params) => {
	let startDate = params.dateFrom
	let endDate = params.dateTo

	if (startDate) {
		if (isBefore(new Date(startDate), new Date()) || isEqual(new Date(startDate), new Date())) {
			startDate = format(addDays(new Date(), 2), 'yyyy-MM-dd')
			endDate = format(addDays(new Date(), 16), 'yyyy-MM-dd')
		}
	} else {
		startDate = format(addDays(new Date(), 14), 'yyyy-MM-dd')
	}

	if (endDate) {
		if (isBefore(new Date(endDate), new Date(startDate))) {
			endDate = format(addDays(new Date(startDate), 14), 'yyyy-MM-dd')
		}
	} else {
		endDate = format(addDays(new Date(startDate), 7), 'yyyy-MM-dd')
	}

	const searchParams = {
		countryId: params.country.ebgNodeId,
		startDate: startDate,
		endDate: endDate,
		adults: params.touristsCount || 1,
		departureCityId: params.city.ebgNodeId,
	}
	const queryString = encodeQueryString(searchParams);
	const basePath = window.location.origin + window.location.pathname + "?"
	return basePath + queryString
}