import React from 'react';
import { v4 as uuid } from 'uuid';

import useBookingStore from "../../store";

const OptionsBlock = ({ options, isInclude, date }) => {
	const selectedServices = useBookingStore(({ selectedServices }) => selectedServices)

	if (isInclude) {
		return options.map((item) => {
			const peoplesLength = item.clients.length

			if (!selectedServices.find(el => item.uid === el.uid)) return null

			return (
				<div key={uuid()} className='grid-insurances insurances-options'>
					<div>{item.name.replace(': ', '')}</div>
					<div>{date}</div>
					<div>{!item.required && `+${item.price * peoplesLength} ${item.currencyAlias}`}</div>
					<div className="paxes">{peoplesLength}</div>
				</div>)
		})

	}
	return options.map(item => {
		if (!item.required) return null
		return <div key={item.uid + 'other'} className='grid-insurances insurances-options'>
			<div>{item.name.replace(': ', '')}</div>
			<div>{date}</div>
			<div>{!item.required && `+${item.price} ${item.currencyAlias}`}</div>
			<div className="paxes">{item.clients.length}</div>
		</div>
	})
};

export default OptionsBlock;