import { Grid } from '@material-ui/core'
import React from 'react'
import useGeoMenuStore from '../CompanyPopup/store'
import Logo from '../../asset/images/Fun-Sun-logo.png'
// import FUNNY from '../../asset/images/FUNNY.png'

import './style.css'
import { ReactComponent as Telegram } from '../../asset/icons/footer/Telegram.svg'
import { ReactComponent as Youtube } from '../../asset/icons/footer/youtube.svg'
import { ReactComponent as Vk } from '../../asset/icons/footer/vk.svg'
import { ReactComponent as Apple } from '../../asset/icons/footer/Apple.svg'
import { ReactComponent as Google } from '../../asset/icons/footer/Google.svg'
import { Link } from 'react-router-dom'
import MobileList from './MobileList'
import SubscribeBlock from './SubscribeBlock/SubscribeBlock'

function CustomIcon({ icon, href }) {
	return (
		<Link className='v-social-block__link' to={href}>
			{icon}
		</Link>
	)
}

const FooterItem = ({ item }) => {
	return (
		<>
			{item.is_active && (
				<div key={item.id} className='list-footer-container'>
					<Link
						className='list-footer-link'
						to={`${item.url}`}
						target={item.target}>
						{item.title}
					</Link>
				</div>
			)}
		</>
	)
}

const Itemlist = ({ item }) => {
	return (
		<>
			{item.is_active && (
				<Grid item xs={4}>
					<p className='list-title'>{item?.title}</p>
					{item?.childs
						.filter(
							elem =>
								elem?.user_roles?.includes('agent') || elem.user_roles === null
						)
						.map(item => {
							return <FooterItem key={item.id} item={item} />
						})}
				</Grid>
			)}
		</>
	)
}

export default function Footer() {
	const footerMenu = useGeoMenuStore(({ footerMenu }) => footerMenu)
	const content = useGeoMenuStore(({ content }) => content)
	const actions = useGeoMenuStore(({ actions }) => actions)

	React.useEffect(() => {
		actions.getMenuContent()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!content) return null
	return (
		<>
			<div className='footer-container'>
				<div className='v-footer-block container-footer'>
					<SubscribeBlock />
					<div className='v-footer-menu'>
						<div className='v-footer-contact'>
							<div className='v-footer-contact-logo-and-social'>
								<div className='logo'>
									<Link to='/'>
										<img src={Logo} className='logo' alt='' />
									</Link>
								</div>
								<div className='v-social-block'>
									<Link
										className='v-social-block-text'
										to={'tel: 8 495 660 5 660'}>
										8 495 660 5 660
									</Link>
									<div className='social'>
										<CustomIcon
											href='https://vk.com/funsunprofi'
											icon={<Vk className='icon' />}
										/>
										<CustomIcon
											href='https://t.me/funsuntoday'
											icon={<Telegram className='icon' />}
										/>
										<CustomIcon
											href='https://www.youtube.com/channel/UCbc_ToPuKHTKUWwxSu6_vNQ'
											icon={<Youtube className='icon' />}
										/>
									</div>
								</div>
							</div>
							<div className='v-footer-menu-item-mobile'>
								<p className='v-footer-menu-header'>Приложение FUN&SUN</p>
								<div className='v-mobile-apps'>
									<Link
										className='v-mobile-app'
										target={'_blank'}
										to={'https://apps.apple.com/ru/app/fun-sun/id1551934669'}>
										<span className='spacing-icon'>
											<Apple className='icon-play' />
										</span>
										APP STORE
									</Link>
									<Link
										className='v-mobile-app'
										target={'_blank'}
										to={
											'https://play.google.com/store/apps/details?id=com.tui.fstravel&hl=ru&gl=US&pli=1'
										}>
										<span className='spacing-icon'>
											<Google className='icon-play' />
										</span>
										GOOGLE PLAY
									</Link>
								</div>
							</div>
							<div className='v-footer-privacy'>
								<p className='text-grey'>
									Представленная на сайте информация носит справочный характер и
									не является публичной офертой. © {new Date().getFullYear()}, FUN&SUN
								</p>

								<p className='v-text-policy'>
									<Link
										className='text-grey'
										to={'funsun-soglasie-na-obrabotku-personalnyx-dannyx'}>
										Правовая информация
									</Link>
								</p>
							</div>
						</div>
						<ul className='footer-list'>
							{footerMenu?.map(item => (
								<MobileList item={item} key={item.id} />
							))}
						</ul>
						<div className='list-grids'>
							<Grid container spacing={1}>
								{footerMenu?.map(item => {
									return <Itemlist item={item} key={item.id} />
								})}
							</Grid>
						</div>
					</div>
					<Link
						style={{ width: '108px', display: 'block' }}
						to={'https://tourism.gov.ru/operators/show.php?id=109958'}
						target={'_blank'}>
						<div className='tourism_widget'></div>
					</Link>
				</div>
			</div>
		</>
	)
}
