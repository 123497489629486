// import { put, call, select } from "redux-saga/effects"
// import { signInWithouRedirect } from '@modules/user';
// import { showNotification } from '@modules/notification';
// import { bookTicket } from "../workers"
// import { setStatus, setAuthPopup } from "../../duck"
// import { getTempData } from "../../selectors"
// import * as Sentry from '@sentry/react';

/**
 * Sign in user and book order on success
 * @param param0 - data required for sign user
 */
export function* signInFlow({ payload }) {
  // yield put(setAuthPopup(false))
  // yield put(setStatus(true))
  // try {
  //   const tempData = yield select(getTempData)
  //   // const isSuccess = yield call(signInWithouRedirect, payload)
  //   if (isSuccess) {
  //     try {
  //       yield put(setAuthPopup(false))
  //       yield call(bookTicket, tempData)
  //     } catch (e) {
  //       // yield put(showNotification({ code: e.name, message: e.message }))
  //     }
  //   } else {
  //     yield put(setAuthPopup(true))
  //     throw new Error("Ошибка")
  //   }
  // } catch (e) {
  //   yield put(setStatus(false))
  //   yield put(setAuthPopup(true))
  // }
  // yield put(setStatus(false))
}
