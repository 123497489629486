import styled, {keyframes} from "styled-components";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPriceDetail, setAgentCommissionVisibility} from "../../store/modules/booking";
import formatPrice from "../../functions/formatPrice";
import blueInfoIcon from "../../asset/images/avia/agents/blueInfoIcon.svg"
import Tippy from '@tippyjs/react';

const Wrapper = styled.div`
  border: 1px solid rgb(220, 220, 220);
  border-radius: 10px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const show = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const hide = keyframes`
  to {
    stroke-dashoffset: 23;
  }
`;

const ActionButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  color: #4872F2;

  .tte-svg-eye {
    .tte-svg-eye-line {
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      &.hide {
        stroke-dashoffset: 0;
        animation: ${hide} 0.3s linear forwards;
      }
      &.show {
        animation: ${show} 0.3s linear forwards;
      }
    }
  }
`;

const TaxesWrapper = styled.div`
  &.show {
    display: flex;
    flex-direction: column;
  }
  &.hide {
      display: none;
  }
`

const Row = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
`

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
`
const TotalTitle = styled.div`
  display: flex;
  align-items: center;
`
const TotalPrice = styled.div`
  color: #4872F2;
`

const BlueInfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  width: 20px;
  margin-left: 6px;
  &:hover {
    cursor: pointer;
  }
`

const AgentTaxesBlock = () => {
  const { detailList, hideAgentCommission } = useSelector(getPriceDetail);
  const dispatch = useDispatch();
  const feesToShow = ['pasenger-type', 'seatMap', 'SMS_Service', 'Return_Guarantee', 'Payment_Receipt', 'meal', 'luggage']
  const fees = detailList.filter(x => feesToShow.includes(x.type))

  return (
    <Wrapper>
      <Header>
        <Title>Информация для агента</Title>
        <ActionButton onClick={() => dispatch(setAgentCommissionVisibility(!hideAgentCommission))}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="tte-svg-eye">
            <g id="Frame">
              <path id="Vector" d="M15.9424 8.2215C16.2979 8.6865 16.2979 9.31425 15.9424 9.7785C14.8226 11.2403 12.1361 14.25 8.99964 14.25C5.86314 14.25 3.17664 11.2403 2.05689 9.7785C1.88393 9.55585 1.79004 9.28194 1.79004 9C1.79004 8.71806 1.88393 8.44415 2.05689 8.2215C3.17664 6.75975 5.86314 3.75 8.99964 3.75C12.1361 3.75 14.8226 6.75975 15.9424 8.2215Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
              <path stroke="#4872F2" d="M3 15L15 3" stroke-linecap="round" stroke-linejoin="round" className={`tte-svg-eye-line ${!hideAgentCommission ? 'show' : 'hide'}`}/>
              <path id="Vector_2" d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </ActionButton>
      </Header>
      <TaxesWrapper className={`${!hideAgentCommission ? 'show' : 'hide'}`}>
        {fees?.map(x => {
          return (
            <Row>
              <div>{x.description}</div>
              <div>{formatPrice(x.subAgentExtraCharge)}</div>
            </Row>
          )
        })}
        <TotalWrapper>
          <TotalTitle>
            Сумма сборов:
            <Tippy
              maxWidth={232}
              theme="light"
              interactive={true}
              content={
                <div style={{fontWeight:400}}>
                  Размеры сборов по умолчанию рассчитаны в соответствии с вашими правилами или правилами вашей группы.
                  Правила группы применяются, если у вас не созданы правила сборов.<br/><br/>
                  Настройки сборов Вы можете посмотреть в разделе <a href={`/cabinet/avia-agent-info/fees`} rel={'noreferrer'} target={'_blank'}>
                  <span color={'#4872F2'}>Финансы и настройки</span></a>.
                </div>
              }
            >
              <BlueInfoIcon />
            </Tippy>
          </TotalTitle>
          <TotalPrice>{formatPrice(fees?.reduce((acc, x) => acc + x.subAgentExtraCharge, 0))}</TotalPrice>
        </TotalWrapper>
      </TaxesWrapper>
    </Wrapper>
  )
}

export default AgentTaxesBlock