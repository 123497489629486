import React, {useState} from 'react'
import { Button } from "@material-ui/core";
import { CloseOutlined, Link } from "@material-ui/icons";
import { encodeQueryString } from '../../../../functions/sequelize'
import useSearchTourStore from '../../store'

import "./style.css";

function LinkPage() {
    const [modalVisible, setVisible] = useState(false)
    const searchParams = useSearchTourStore(({ searchParams }) => searchParams)

    const queryString = encodeQueryString(searchParams);
    const basePath = window.location.origin + window.location.pathname + "?"

    React.useEffect(() => {
        if(modalVisible){
            const link = document.getElementById('link');
            link.focus();
            link.select();
        }
    }, [modalVisible])

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }


    const handleClose = () => {
        setVisible(!modalVisible)
    }

    return (<>
        <div id='link-page' onClick={() => setVisible(!modalVisible)} title='Ссылка на эту страницу' >
            <Link />
        </div>
        {modalVisible &&
            <>
                <div className="modal-overlay" />
                <div className='modal_link'>
                    <div className="modal-title">
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                alignSelf: "center",
                                fontSize: "1.17em",
                                fontWeight: "bold",
                            }}
                        >
                            Ссылка на эту страницу
                        </div>
                        <div
                            style={{ textAlign: "end", cursor: "pointer" }}
                            onClick={handleClose}
                        >
                            <CloseOutlined />
                        </div>
                    </div>
                    <div className='link-page-body'>
                        <div className='content'>

                            <div style={{ textAlign: "center" }}>
                                <Button onClick={() => copyTextToClipboard(basePath + queryString)} variant="contained" color="primary">Скопировать содержимое</Button>
                            </div>
                            <div className='field'>
                                <textarea id={'link'}>
                                    {basePath + queryString}
                                </textarea> </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
    )
}

export default LinkPage