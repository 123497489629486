export default function declination(value, words){
	let result

	if((value%100 > 9 && value%100 < 20) || (value%10 > 4 && value%10 < 10) || (value%10 === 0)){
		result = words[2]
	} else if(value%10 === 1){
		result = words[0]
	} else {
		result = words[1]
	}

	return result
}
