export const phoneRegExp =
    /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
// export const phoneRegExp = /^\\d{11,12}$/
// export const phoneRegExp = /^\+\d{11,12}$/
export const emailRegExp =
	/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export const digitsRegExp = /\d/

export const notDigitRegExp = /[^0-9]/g

export const cyrillicRegExp = /^[а-яА-ЯёЁ\s-/']+$/

export const issuedByRegExp = /^[а-яА-ЯёЁN\s\d.,-]+$/

export const seriesBirthCertificatesRegExp = /^([IVXLCDM]{1,6})(-[А-ЯЁ]{2})$/

export const latinRegExp = /^[a-zA-Z]+$/

export const latinNameRegExp = /^[a-zA-Z\s-]+$/

export const latinUpperAndNumberRegExp = /^[0-9A-Z]+$/

export const latinAndNumberRegExp = /^[0-9a-z]+$/

export const passwordRegExp =
	/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\!@#$%^&*()[\]{}\-_+=~`|:;"'<>,./?]).{6,20}$)/

export const whiteSpacesRegExp = /\s/g

export const interPassportSeriesRegExp = /^\d{2}$/
//export const interPassportNumberRegExp = /^[A-Z0-9]{2,16}$/
export const interPassportNumberRegExp = /^\d{7}$/
export const rfPassportSeriesRegExp = /^\d{4}$/
export const rfPassportNumberRegExp = /^\d{6}$/

export const birthCertSeriesFirstHalfRegExp = /^[IVX]{2}$/
export const birthCertSeriesSecondHalfRegExp = /^[А-ЯЁ]{2}$/
export const birthCertNumberRegExp = rfPassportNumberRegExp

export const hotelStarsAfterNameRegExp = /(\d|-)\s?\*+\+?$/

// eslint-disable-next-line no-useless-escape
export const loyalSale = /\[(.+)\]/

// eslint-disable-next-line no-useless-escape
export const fileExtRegExp = /\.[^.\/:*?"<>|\r\n]+$/m

export const moneySumRegExp = /^\d+(\.\d?\d)?$/


export const newPhoneRegExp =
	/^(\+7|7)?[\s-]?\(?[479][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/
