import React from 'react';
import '../style.scss'
import {Button} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

const PassengerItem = ({item}) => {
  const navigateTo = useNavigate();

  const documentTypes = [
    { value: 'RussianFederationPassport', label: 'Паспорт РФ' },
    { value: 'InternationalPassport', label: 'Заграничный паспорт' },
    { value: 'BirthCertificate', label: 'Свидетельство о рождении' },
    { value: 'NationalPassport', label: 'Нац. паспорт' },
    { value: 'Other', label: 'Другой документ' }
  ]

  function applyMask(value, type) {
    const str = value.toString()
    switch (type) {
      case 'RussianFederationPassport':
        return str.slice(0,4) + ' ' + str.slice(5)
      case 'InternationalPassport':
        if (str.length === 10) {
          return str.slice(0,2) + ' ' + str.slice(3)
        } else {
          return str.slice(0,2) + ' ' + str.slice(2)
        }
      default:
        return value
    }
  }

  function documentsString() {
    if (item.documents.length) {
      return  item.documents.map(el => {
        return (
          `${documentTypes.filter(type => type.value === el.type)[0].label + ' ' +
          applyMask(el.number, el.type)}`
        )
      }).join(', ')
    } else {
      return 'нет прикрепленных документов'
    }
  }

  return (
    <div className={'my-passengers-item'}
         onClick={() => navigateTo(`/cabinet/my-passengers/edit`, {state: item})}
    >
      <div className={'my-passengers-item-name'}>
        {item.firstName} {item.patronymic} {item.lastName}, &nbsp;
        <span className={'my-passengers-item-document'}>{documentsString()}</span>
      </div>
      <Button
        onClick={() => console.log('click me')}
        variant={'outlined'}
        color={'primary'}
        size={'medium'}
        className={'my-passengers-more-btn'}
      >Подробнее</Button>
    </div>
  )
}

export default PassengerItem
