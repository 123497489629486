import { create } from 'zustand'
import axios from 'axios'

import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

// const BASE_URL = 'http://10.20.23.58:31092/api'

const PART_PASS = localStorage.getItem('agentInfo')

const useFitClaimStore = create(
    devtools((set, get) => ({
        issues: [],
        comments: {},
        loading: false,
        newCommentValue: '',
        actions: {
            getFit: async () => {

                try {
                    const res = await axios({
                        url: `${BASE_URL}/fit/issues/${JSON.parse(PART_PASS)?.partPass}`,
                        method: "GET"
                    })
                    console.log(res)
                    set({ issues: res?.data?.issues })
                } catch (error) {
                    console.error(error)
                }
            },
            getComments: async (issueIdOrKey) => {
                set({ loading: true })
                try {
                    const res = await axios({
                        url: `${BASE_URL}/fit/issues/${issueIdOrKey}/comments`,
                        method: 'GET',
                    })
                    set({ comments: { ...get().comments, [issueIdOrKey]: res?.data?.comments } })
                } catch (error) {
                    console.log(error)
                } finally {
                    set({ loading: false })
                }
            },

            postComment: async (issueId) => {
                set({ loading: true })
                const data = { body: get().newCommentValue }
                set({ newCommentValue: '' })
                try {
                    const res = await axios({
                        url: `${BASE_URL}/fit/issues/${issueId}/comment`,
                        method: 'POST',
                        data
                    })

                    if (res.status === 200) {
                        get().actions.getComments(issueId)
                    }
                } catch (e) {
                    console.error('error in postComments', e)
                } finally {
                    set({ loading: false })
                }
            },

            setNewCommentValue: (value) => {
                set({ newCommentValue: value })
            },
        }
    }))
)

export default useFitClaimStore
