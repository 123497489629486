import React, { useState } from 'react';
// import useBookingStore from "../../store";
import { Box } from "@mui/material";

const Commission = ({ price, currency, partnerCommission, money }) => {

	const [isOpen, setOpen] = useState(false)

	// const partnerCommission = useBookingStore(state => state.partnerCommission)

	return (
		<div style={{ margin: '0 auto', textAlign: 'center', marginBottom: 20, cursor: 'pointer' }}>
			<div onClick={() => setOpen(!isOpen)}>Комиссия (детали)</div>
			{isOpen && <div style={{ width: 400, backgroundColor: '#fbf9ee', borderRadius: 5, margin: '0 auto', padding: '10px 0' }}>
				<Box mb={1}>
					<div>Сумма к оплате {Math.round(money.net)} {currency}</div>
				</Box>
				<Box mb={1}>
					<div style={{ fontWeight: 'bold' }}>Комиссия партнёра {partnerCommission}%</div>
				</Box>
				<Box>
					<div>Сумма комиссии {Math.round(money.sumCommission)} {currency}</div>
				</Box>
			</div>}
		</div>
	);
};

export default Commission;