import { createSelector } from "reselect"
import { STATUS } from "./constants"

export const getAppealStatus = state => state.appeals.status

export const appealListSelector = state => state.appeals.list.tickets

export const getAppealDetail = state => state.appeals.detail

export const isAppealLoading = createSelector(
  getAppealStatus,
  status => status === STATUS.loading
)
export const isAppealFailure = createSelector(
  getAppealStatus,
  status => status === STATUS.failure
)
export const isAppealSuccess = createSelector(
  getAppealStatus,
  status => status === STATUS.success
)

export const getMessagesSelector = state => state.appeals.messages

export const getRefundInfo = createSelector(
  getAppealDetail,
  state => state.calculation
)

export const getAddAppealState = state => state.appeals.addAppeal

export const getCalculationModalsState = state => state.appeals.calculationModals;

export const getSupportTicketCalculation = state =>
  state.appeals.supportTicketCalculation
