import { useEffect, useState } from 'react'
import useBookingStore from './store'
import TourBlock from './components/TourBlock'
import HotelBlock from './components/HotelBlock'
import AviaBlock from './components/AviaBlock'
import InsurancesBlock from './components/InsurancesBlock'
import Snackbar from '@mui/material/Snackbar'
import { Alert, Stack } from '@mui/material'
import { isEmpty } from 'lodash'
import ServicesBlock from './components/ServicesBlock'
import PaxesBlock from './components/PaxesBlock'

import './style.css'
// import { format } from 'date-fns'
import AviaRegBlock from './components/AviaBlock/RegularFlights/AviaRegBlock'
import PromoCode from './components/PromoCode'
import CustomModal from '../../components/CustomModal'
import { useLocation, useNavigate } from 'react-router-dom'
import useLoyaltyStore from '../Loyalty/store'
import ButtonsBlock from './components/ButtonsBlock'
import RegButtonsBlock from './components/ButtonsBlock/RegButtonsBlock'
import BookingSuccessPopup from "./components/BookingSuccessPopup";
import ApplicationNoteBlock from './components/ApplicationNoteBlock/ApplicationNoteBlock'
import BuyerBlock from './components/BuyerBlock'

const RUSSIA_ID = 210357

const BookingNew = () => {
	const [open, setOpen] = useState(false)
	const [isBookingSuccess, setIsBookingSuccess] = useState(false)
	const [isRegFlights, setIsRegFlights] = useState(false)

	const actions = useBookingStore(({ actions }) => actions)
	const client = useLoyaltyStore(({ client }) => client)
	const response = useBookingStore(({ response }) => response)
	const claimNew = useBookingStore(({ claimNew }) => claimNew)
	const insurances = useBookingStore(state => state.insurances)
	const services = useBookingStore(({ services }) => services)
	const selectedServices = useBookingStore(({ selectedServices }) => selectedServices)
	const bookingError = useBookingStore(({ bookingError }) => bookingError)
	const bookingLoadingError = useBookingStore(({ bookingLoadingError }) => bookingLoadingError)
	const peoples = useBookingStore(({ peoples }) => peoples)
	const claimId = useBookingStore(({ claimId }) => claimId)
	const notifications = useBookingStore(({ notifications }) => notifications)
	const actionsPL = useLoyaltyStore(({ actions }) => actions)
	const spentPoints = useLoyaltyStore(({ spentPoints }) => spentPoints)
	const currentTour = useBookingStore(({ currentTour }) => currentTour)
	const bookingNote = useBookingStore(({ bookingNote }) => bookingNote)
	const promocode = useLoyaltyStore(({ promocode }) => promocode)


	const { search } = useLocation()
	const navigate = useNavigate()
	const params = new URLSearchParams(search)
	const fromCRM = params.get('fromCRM')

	const service = [...selectedServices, ...services]

	const backToSearch = () => {
		actions.backToSearch()
		actions.clearError()
		navigate('/search_tour')
	}

	const onCloseErrorModal = () => {
		actions.clearError()
		actions.clearPayload()
	}

	const searchParams = new URLSearchParams(search);
	const currentSelectedCurrency = Number(searchParams.get('currentSelectedCurrency'))
	const query = new URLSearchParams(search)

	const handleClose = (event, reason) => { if (reason === 'clickaway') { return } setOpen(false); actions.clearNotification() }

	useEffect(() => {
		if (currentSelectedCurrency !== 1 && currentSelectedCurrency !== 2 && currentSelectedCurrency !== 3) {
			actions.getLocalCurrencieValue(currentSelectedCurrency);
		}
	}, [currentSelectedCurrency, actions]);

	useEffect(() => {
		actions.getPacket(query.get('priceId'), query.get('currencyId'), query.get('withFlight'))
		// actions.getPartnerCommission()
	}, [actions, search])

	useEffect(() => {
		if (!isEmpty(notifications)) {
			setOpen(true)
		}
		if (claimNew?.claimDocument?.freightExternal) {
			setIsRegFlights(true)
		} else {
			setIsRegFlights(false)
		}
	}, [claimNew, notifications])

	useEffect(() => {
		if (claimNew) {
			actionsPL.preorderNew(claimNew?.claimDocument, currentTour, promocode)
		}
	}, [claimNew, actionsPL, currentTour, client, spentPoints, promocode])

	useEffect(() => {
		if (claimId) {
			actionsPL.preorderNew(claimNew?.claimDocument, currentTour, promocode)
			actionsPL.preorderTransactionNew(claimNew?.claimDocument, claimId, promocode)
			setIsBookingSuccess(true)
		}
		// eslint-disable-next-line
	}, [claimId])
	
  useEffect(() => {
    actions.getCurrenciesValues()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

	if (bookingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingError}
				successButtonTitle='Понятно'
				onSuccess={onCloseErrorModal}
				className={'scroll-popup-text'}
			/>
		)
	}
	if (bookingLoadingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingLoadingError}
				successButtonTitle='Понятно'
				onSuccess={backToSearch}
				className={'scroll-popup-text'}
			/>
		)
	}

	if (!claimNew) return null

	const showBuyerBlock = claimNew.claimDocument.stateKey !== RUSSIA_ID;

	return (
		<div className='container'>
			<TourBlock
				tour={claimNew?.claimDocument}
				notes={claimNew?.notes}
				tourDescription={bookingNote}
			/>
			<HotelBlock
				hotel={claimNew?.claimDocument?.hotels[0]}
				hotels={claimNew?.claimDocument?.hotels}
				variants={claimNew?.variants?.hotels}
			/>
			{query.get('withFlight') ? isRegFlights ? (
				<AviaRegBlock isCruises={claimNew?.claimDocument?.stateKey === 266539} />) : (
				<AviaBlock />) : null}
			{!isEmpty(insurances) && <InsurancesBlock insurances={insurances} />}
			{!isEmpty(service) && (
				<ServicesBlock elements={service} isExcursions title='Экскурсия' />
			)}
			{!isEmpty(service) && (
				<ServicesBlock elements={service} title='Дополнительные услуги' />
			)}
			<form>
				<PaxesBlock
					peoples={peoples}
					freeInfant={claimNew.claimDocument.freeInfant}
					freeInfantChecked={claimNew?.claimDocument?.freeInfantChecked}
				/>
				{/* {showBuyerBlock && <BuyerBlock />} */}
				<BuyerBlock />
				<ApplicationNoteBlock />
				{response?.notes?.spogMessage && <div className={'notes-spog'}>{response.notes.spogMessage}</div>}
				{/*{claimNew?.claimDocument.existsPromoCode && <PromoCode />}*/}
				<div id={'price-buttons-block'}>
				  {isRegFlights ? (
					  <RegButtonsBlock response={claimNew} fromCRM={fromCRM} />
				  ) : (
					  <ButtonsBlock response={claimNew} currentSelectedCurrency={currentSelectedCurrency} fromCRM={fromCRM} />
				)}
				</div>
			</form>
			<Snackbar
				open={open}
				autoHideDuration={16000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Stack flexDirection='column' gap={1}>
					{notifications?.map(item => {
						return (
							<Alert
								key={item.targetId}
								onClose={() => actions.closeNotification(item.targetId)}
								severity={item.targetType === 'price' ? 'error' : 'success'}
								sx={{ width: '300px' }}>
								{item.description}
							</Alert>
						)
					})}
				</Stack>
			</Snackbar>
			{(isBookingSuccess || claimId) && <BookingSuccessPopup claim={claimId} />}
		</div>
	)
}

export default BookingNew
