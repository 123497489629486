import React, { useState } from 'react'
import '../../../pages/SearchTour/components/FromField/style.scss'
import { isEmpty } from 'lodash'
import { cyrillicRegExp, latinRegExp } from '../../../constants/reg-exps'
import { convertTo } from '../../../functions/convertLang'
import { Box, TextField } from '@material-ui/core'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'

const ListCities = ({ handleChange, country }) => (
	<>
		<div className={`v-departure__elem-grouped-title v-text-16`}>
			{country.name}
		</div>
		{country.cites.map(city => (
			<Cities city={city} key={city.id} handleChange={handleChange} />
		))}
	</>
)

const Cities = ({ city, handleChange }) => (
	<div
		className={`v-departure__elem v-text-16`}
		onClick={() => handleChange(city.id)}
		style={{ paddingLeft: '25px' }}>
		{city.name}
	</div>
)

const MobileDepartureCity = ({ lists, onChange, value, onClose }) => {
	const [inputValue, setInputValue] = useState(
		lists.find(item => item.id.toString() === value.toString())
			? lists.find(item => item.id.toString() === value.toString())?.name
			: lists[0]?.name
	)

	const [useFilter, setUseFilter] = useState(false)

	if (!lists) return null

	const handleChange = id => {
		const item = lists.find(item => item.id === id)
		setUseFilter(false)
		onChange(item?.id)
		onClose()
	}

	const changeInput = e => {
		setInputValue(e.target.value)
		setUseFilter(true)
	}

	const getFiltered = () => {
		let result
		if (!inputValue || !useFilter) {
			result = lists
		} else {
			result = lists.filter(el => {
				let value = inputValue.toLowerCase()
				let name = el.name ? el.name.toLowerCase() : null
				let nameAlt = el.nameAlt ? el.nameAlt.toLowerCase() : null

				if (!name) {
					return false
				}
				if (
					latinRegExp.test(value) &&
					(name.includes(convertTo('ru', value)) ||
						nameAlt?.includes(convertTo('ru', value)))
				) {
					return true
				}
				if (
					cyrillicRegExp.test(value) &&
					(name.includes(convertTo('eng', value)) ||
						nameAlt?.includes(convertTo('eng', value)))
				) {
					return true
				}
				if (nameAlt?.includes(value)) return true
				if (name.includes(value)) return true
				return false
			})
		}
		result = result.reduce((acc, city) => {
			const key = city.stateFromNameAlt
			if (!acc[key]) {
				acc[key] = { name: city.stateFromName, cites: [] }
			}
			acc[key].cites.push(city)
			return acc
		}, {})

		return Object.values(result)
	}

	const filtered = getFiltered()

	return (
		<div className='v-departure-base'>
			<Box
				sx={{ pb: 1, pt: 1, pl: 2, pr: 2 }}
				className='header__submenu-return'>
				<ArrowBackIosRoundedIcon
					onClick={onClose}
					fontSize='small'
					style={{ marginRight: '8px', cursor: 'pointer' }}
				/>
				<TextField
					autoFocus
					margin='dense'
					hiddenLabel
					fullWidth
					variant='standard'
					onChange={changeInput}
					placeholder='Начните поиск'
				/>
			</Box>

			<div className='v-departure__body'>
				{useFilter && isEmpty(filtered) && (
					<div className={'empty-search'}>Совпадений не найдено</div>
				)}
				{filtered.map((country, index) => {
					return (
						<ListCities
							country={country}
							key={index}
							handleChange={handleChange}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default MobileDepartureCity
