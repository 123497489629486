import useSearchTourStore from "../../store.js";
import './style.css'
import StarIcon from '@material-ui/icons/Star';
import Card from "../../../../components/Card/Card.js";
import HotelsCard from "../../../../components/Card/HotelsCard.js";
import {isMobile} from "react-device-detect";

const ListStars = () => {

	const stars = useSearchTourStore(({ filters }) => filters.stars);
	const hotelTypes = useSearchTourStore(({ filters }) => filters.hotelTypes);
	const { addStars, addHotelTypes, clearFilterStars } = useSearchTourStore((state) => state.actions);
	const starIds = useSearchTourStore((state) => state.stars)
	const hotelTypesIds = useSearchTourStore((state) => state.hotelTypes)

	const changeStatus = (id, event) => {
		addStars(id, event.target.checked)
	}

	const changeHotelTypes = (id, event) => {
		addHotelTypes(id, event.target.checked)
	}

	const resetStarsFilter = (event) => {
		if (event.target.checked)
			clearFilterStars()
	}

	return (
		<div style={{ width: !isMobile ? '20%' : '100%' }}>
			<div style={{display: 'flex', justifyContent: "space-between", marginRight: !isMobile ? '10' : '0', marginBottom: 2}}>
				<span>
					Категория
				</span>
				<label htmlFor="resetStarsFilter">
					<input id='resetStarsFilter' type='checkbox' checked={!(starIds.length || hotelTypesIds.length)} onChange={resetStarsFilter} />
					Любая
				</label>
			</div>
			<div className="hotels__container">


				{stars?.map((el) => {
					return <Card key={el.id} name={el.name} id={el.id.toString()} isChecked={starIds.includes(el.id.toString())} changeStatus={changeStatus} icon={<StarIcon style={{ marginRight: 5, fill: '#FFE874' }} fontSize="small" />} prefix={'star_'}/>;
				})}
				<hr/>
				{hotelTypes?.map((el) => {
					return <HotelsCard key={el.id} name={el.name} id={el.id} isChecked={hotelTypesIds.includes(el.id)} changeStatus={changeHotelTypes}  prefix={'hotelTypes_'}/>;
				})}
			</div>
		</div>
	);
};

export default ListStars;
