import React, { useState, useEffect } from 'react'
import './style.scss'
import useGeoStore from '../../store'

import { Swiper, SwiperSlide } from 'swiper/react'
import { isMobile } from 'react-device-detect'
import 'swiper/css'

function LandingResort({ content }) {
	const actions = useGeoStore(({ actions }) => actions)
	const caseForms = useGeoStore(({ currentCaseForms }) => currentCaseForms)

	const [cards, setCards] = useState(content)
	const getCards = () => {
		const request = content?.map(item => {
			const countryId = item.countryEbgId
			const resortEbgNodeId = item.resortEbgNodeId
			return actions
				.getMinPrice(countryId, resortEbgNodeId)
				.then(price => ({ ...item, price }))
		})
		Promise.all(request).then(value => {
			setCards(value.filter(value => value))
		})
	}

	useEffect(() => {
		setCards(getCards())
		actions.getCountryList(content[0].countryEbgId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [swiper, setSwiper] = useState(null)
	const [isBeginning, setIsBeginning] = useState(true)
	const [isEnd, setIsEnd] = useState(false)

	const handlePrev = () => {
		if (swiper) {
			swiper.slidePrev()
		}
	}

	const handleNext = () => {
		if (swiper) {
			swiper.slideNext()
		}
	}

	const handleSlideChange = () => {
		if (swiper) {
			setIsBeginning(swiper.isBeginning)
			setIsEnd(swiper.isEnd)
		}
	}

	return (
		<div className='landingResort__wrapper'>
			<div className='landingResort__header'>
				<div className='landingResort__titleBlock'>
					<h4 className='landingResort__subtitle'>
						Исследуйте разные направления
					</h4>
					<h2 className='landingResort__title'>
						Курорты {caseForms?.genitiveCase}
					</h2>
				</div>
				{!isMobile && content.length > 4 && (
					<div className='landingResort__navBlock'>
						<button
							onClick={handlePrev}
							disabled={isBeginning}
							className={`landingResort__swiper-button-prev nav-button ${
								isBeginning ? 'disabled' : ''
							}`}>
							<div
								className={`landingResort__btn_left ${
									isBeginning ? 'disabled' : ''
								}`}></div>
						</button>
						<button
							onClick={handleNext}
							disabled={isEnd}
							className={`landingResort__swiper-button-next nav-button ${
								isEnd ? 'disabled' : ''
							}`}>
							<div
								className={`landingResort__btn_right ${
									isEnd ? 'disabled' : ''
								}`}></div>
						</button>
					</div>
				)}
			</div>

			<Swiper
				slidesPerView={'auto'}
				spaceBetween={10}
				onSwiper={setSwiper}
				onSlideChange={handleSlideChange}
				breakpoints={{
					576: {
						slidesPerView: 1,
						spaceBetween: 10
					},

					726: {
						slidesPerView: 2.5,
						spaceBetween: 10
					},

					900: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					1000: {
						slidesPerView: 4,
						spaceBetween: 20
					}
				}}>
				{cards?.map((el, ind) => (
					<SwiperSlide key={el + ind}>
						<div className='landingResort__slider-item-wrapper'>
							<div className='landingResort__slider-item'>
								{el.images[0] && (
									<img
										className='landingResort__slider-picture'
										src={`${el.images[0]}`}
										alt=''
									/>
								)}
								<a
									href={`${window.location.origin}/${el.landingLink}`}
									className='landingResort__slider-link'>
									<div className='slider-link-title'>{el.resortName}</div>
									{el.price && (
										<div className='slider-link-price'>{`от ${
											el.price
												.toFixed(0)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'
										} / 2 чел.`}</div>
									)}
								</a>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default LandingResort
