import React from 'react';
import { SvgIcon } from "@mui/material";


function Location(props) {
    return <SvgIcon {...props} viewBox="0 0 20 20" fill="none" style={{ width: "20px", height: "20px" }} >
        <g clipPath="url(#clip0)">
            <path d="M15.0444 11.4936H15.0342L15.8644 10.3124C16.5699 9.30855 16.9405 8.14872 16.941 6.96997L15.0444 11.4936ZM15.0444 11.4936L10.2044 18.401C10.1044 18.5437 9.89452 18.5429 9.79547 18.4016C8.69555 16.8324 6.67932 13.9557 4.95495 11.4936H4.9637L4.13728 10.3134C3.45461 9.33853 3.05884 8.19392 3.05884 6.96997C3.05884 6.15717 3.23423 5.35041 3.57718 4.5951C3.92025 3.83955 4.42523 3.14833 5.06696 2.56291C5.70883 1.97737 6.47448 1.50967 7.32176 1.18951C8.16909 0.869338 9.07931 0.703857 9.99991 0.703857C10.9205 0.703857 11.8307 0.869338 12.678 1.18951C13.5253 1.50967 14.291 1.97737 14.9329 2.56291C15.5746 3.14833 16.0796 3.83955 16.4226 4.5951C16.7655 5.35033 16.9409 6.15699 16.941 6.96971L15.0444 11.4936Z" fill='#ffffff' stroke="#4872f2" strokeWidth="1.5" />
            <path d="M12.523 7.0833C12.523 8.47671 11.3935 9.60629 10 9.60629C8.60663 9.60629 7.47705 8.47671 7.47705 7.0833C7.47705 5.68989 8.60663 4.5603 10 4.5603C11.3935 4.5603 12.523 5.68989 12.523 7.0833Z" fill='#ffffff' stroke="#4872f2" strokeWidth="1.5" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>
}
export default Location