import React from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import { Button } from "../../ui"
import {
  getAgentUsersRequest,
  setRoleToUserRequest
} from "../../../store/modules/agents/agent-users/duck"
import { getUserState } from "../../../store/modules/user"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Title = styled.div`
  font-family: "Open Sans", serif;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px !important;
`

const StyledSelect = styled(Select)`
  width: 256px;
  position: relative;
  margin-right: 20px;
  margin-top: 15px;
`

const ButtonsRow = styled.div`
  display: flex;
  margin: 30px 0 40px 0;
  width: 396px;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 20px;
`

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const StyledButton = styled(Button)`
  height: 40px;
  text-align: center;
  margin-top: 15px;
  color: #ffffff;
  font-size: 16px;
  margin-left: 20px;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const AgentUserRoleChange = ({
  changeUserRoleModalOpen,
  setIsChangeUserRoleOpen,
  chosenAgent
}) => {
  const dispatch = useDispatch()
  const [roleId, setRoleId] = React.useState("")

  const roles = useSelector(state => state.agents.roles)
  const userState = useSelector(getUserState)

  const options = roles?.map(item => {
    return {
      value: item.id,
      label: item.description
    }
  })

  // @ts-ignore
  const submit = React.useCallback(roleId => {
    dispatch(
      setRoleToUserRequest({
        userId: chosenAgent,
        roleId: roleId
      })
    )
    dispatch(getAgentUsersRequest({ AgentId: userState?.data?.agentId }))

    setIsChangeUserRoleOpen(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectChange = data => {
    setRoleId(data.value)
  }

  return (
    <StyledPopup
      onClose={() => setIsChangeUserRoleOpen(false)}
      open={changeUserRoleModalOpen}
    >
      <CloseButton
        aria-label="Закрыть окно"
        onClick={() => setIsChangeUserRoleOpen(false)}
      >
        <Icon/>
      </CloseButton>
      <Title>Изменение роли пользователя</Title>
      <SelectContainer>
        <StyledSelect
          isSearchable={false}
          onChange={handleSelectChange}
          placeholder="Выберите роль"
          options={options}
        />
      </SelectContainer>
      <ButtonsRow>
        <StyledButton
          onClick={() => {
            submit(roleId)
          }}
        >
          Изменить роль
        </StyledButton>
        <StyledButton onClick={() => setIsChangeUserRoleOpen(false)}>
          Отмена
        </StyledButton>
      </ButtonsRow>
    </StyledPopup>
  )
}

export default AgentUserRoleChange
