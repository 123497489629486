import React from "react"
import styled from "styled-components"
import { Button, Field } from "../../ui"
import { useDispatch } from "react-redux"
import InputMask from "react-input-mask"
import {
  addAgentUsersSuccess,
  createUserInSamoRequest
} from "../../../store/modules/agents/agent-users/duck"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px 40px;
`

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 40%;

    /* height: 550px; */
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const Title = styled.div`
  font-family: "Open Sans", serif;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px !important;
`

const StyledField = styled(Field)`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;

  @media (max-width: 1124px) {
    width: 335px;
    margin: 0;
    align-self: flex-start;
  }
  @media (max-width: 767px) {
    align-self: flex-start;

    width: 100%;
  }
`

const StyledButton = styled(Button)`
  width: 266px;
  height: 40px;
  text-align: center;
  margin-top: 30px;
  @media (max-width: 1024px) {
    margin-top: 30px;
    width: 100%;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const AgentAddToSamo = ({
  addAgentUserToSamo,
  setAddAgentUserToSamo,
  email,
  setEmail
}) => {
  const dispatch = useDispatch()
  const [lastName, setLastName] = React.useState("")
  const [firstName, setFirstName] = React.useState("")
  const [patronymic, setPatronymic] = React.useState("")
  const [phone, setPhone] = React.useState("")

  const submit = () => {
    dispatch(
      createUserInSamoRequest({
        firstName: firstName,
        lastName: lastName,
        email: email,
        patronymic: patronymic,
        phone: phone
        // partnerId: 0,
        // partnerPass: 0,
      })
    )
    dispatch(addAgentUsersSuccess(1))
    setAddAgentUserToSamo(false)
  }

  return (
    <StyledPopup
      onClose={() => {
        setAddAgentUserToSamo(false)
        dispatch(addAgentUsersSuccess(1))
      }}
      open={addAgentUserToSamo}
    >
      <CloseButton
        aria-label="Закрыть окно"
        onClick={() => {
          setAddAgentUserToSamo(false)
          dispatch(addAgentUsersSuccess(1))
        }}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Title>Изменение роли пользователя</Title>
        <StyledField
          value={lastName}
          autoComplete="off"
          onChange={event => {
            setLastName(event.target.value)
          }}
          label="Фамилия"
        />
        <StyledField
          value={firstName}
          autoComplete="off"
          onChange={event => {
            setFirstName(event.target.value)
          }}
          label="Имя"
        />
        <StyledField
          value={patronymic}
          autoComplete="off"
          onChange={event => {
            setPatronymic(event.target.value)
          }}
          label="Отчество"
        />
        <StyledField
          value={email}
          autoComplete="off"
          onChange={event => {
            setEmail(event.target.value)
          }}
          label="Email"
        />
        <InputMask
          mask="+7 (999) 999-99-99"
          onChange={event => {
            setPhone(event.target.value)
          }}
          autoComplete="anyrandomstring4"
          value={phone}
          maskChar={null}
        >
          <StyledField autoComplete="phone" label="Телефон" />
        </InputMask>
        <Column>
          <StyledButton
            type="primary"
            onClick={() => {
              submit()
            }}
          >
            Отправить запрос
          </StyledButton>
        </Column>
      </Container>
    </StyledPopup>
  )
}

export default AgentAddToSamo
