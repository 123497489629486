import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { isEmpty, orderBy } from "lodash";
import { addDays, addYears, differenceInDays, format } from "date-fns";

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useInsuranceStore = create(
	devtools((set, get) => ({
		isCompleted: false,
		reserveInsuaranceLoading: false,
		loading: false,
		loadingUpdateBasket: false,
		countCompany: 0,
		countItem: 0,
		insurancesAmounts: null,
		currency: 'USD',
		customerDocument: null,
		data: {
			journeyInfo: {
				journeyType: "once",
				dateFrom: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
				dateTo: format(addDays(new Date(), 14), 'yyyy-MM-dd'),
				daysCount: 14
			},
			partner: {
				offerId: 1068,
				affId: 1823,
				sourceId: "2319",
				affSub1: "18"
			},
			utm: {
				medium: "cpa",
				campaign: "mantravel_api",
				content: "1823",
				source: "tui.ru_2319"
			},
			lifeInsuranceAmount: 40000,
			tripCancellationInsuranceAmount: 0,
			baggageInsuranceAmount: 0,
			accidentInsuranceAmount: 0,
			liabilityInsuranceAmount: 0,
			destinations: [
				{
					id: 0,
					isGroup: false
				}
			],
			professionalSports: [],
			supActivities: [],
			tourists: [
				{
					birthDay: format(addYears(new Date(), -20), 'yyyy-MM-dd')
				},
				{
					birthDay: format(addYears(new Date(), -20), 'yyyy-MM-dd')
				}
			]
		},
		countries: null,
		currentCountry: null,
		adults: 2,
		ages: [20, 20],
		insurances: null,
		activities: null,
		selectedActivites: null,
		sport: null,
		selectedSport: null,
		companies: null,
		anotherOptions: [],
		anotherFilters: [],
		draft: null,
		travellers: [],
		searchId: null,
		abortRequest: false,
		status: null,
		phoneError: false,
		actions: {
			getCountries: async () => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/destinations`,
					method: 'GET'
				})
				set({ countries: orderBy(res.data, 'name') })
				set({ currentCountry: get().countries[0].id })
				set({ data: { ...get().data, destinations: [{ id: get().countries[0].id, isGroup: get().countries[0].isGroup }] } })
			},
			calculate: async () => {
				set({ isCompleted: false })
				set({ countCompany: 0 })
				set({ loading: true })
				set({ countItem: 0 })
				const tourists = get().ages.map(age => {
					return {
						birthDate: format(addYears(new Date(), -Number(age)), 'yyyy-MM-dd')
					}
				})
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/calculations`,
					method: 'POST',
					data: {...get().data, tourists}
				})
				set({ searchId: res.data.result })
				do {
					await get().actions.searchInsurance(res.data.result)
				} while (!get().isCompleted)
			},
			searchInsurance: async (id) => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/calculations/${id}`,
					method: 'GET',
				})
				// const data = get().data
				let items = res.data.items
				// if (data.lifeInsuranceAmount) {
				// 	items = items.filter(item => item.lifeInsuranceAmount === data.lifeInsuranceAmount)
				// }
				// if (data.accidentInsuranceAmount) {
				// 	items = items.filter(item => item.accidentInsuranceAmount === data.accidentInsuranceAmount)
				// }
				// if (data.liabilityInsuranceAmount) {
				// 	items = items.filter(item => item.liabilityInsuranceAmount === data.liabilityInsuranceAmount)
				// }
				// if (data.baggageInsuranceAmount) {
				// 	items = items.filter(item => item.baggageInsuranceAmount === data.baggageInsuranceAmount)
				// }
				set({
					insurances: {
						...res.data,
						items: [...items.map(item => {
							return {
								...item,
								netPrice: Math.ceil(item.netPrice),
								price: Math.ceil(item.price),
								discountPrice: Math.ceil(item.discountPrice)
							}
						} )]
					}
				})
				set({ isCompleted: res.data.isCompleted })
				set({ countCompany: res.data.insuranceCompanyCount })
				set({ countItem: items.length })
				set({ currency: !isEmpty(res.data.items) ? res.data.items[0].currencyIsoCode : 'USD' })
				res.data.items.forEach(item => {
					set({ anotherOptions: [...get().anotherOptions, ...item.options] })
				})
				if (res.data.isCompleted) {
					set({ loading: false })
				}
				return res.data.isCompleted
			},
			getInsuranceAmount: async () => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/insurance-amount-limits`,
					method: 'GET',
				})
				set({ insurancesAmounts: res.data })
			},
			getActivites: async () => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/activities`,
					method: 'GET',
				})
				set({ activities: res.data })
			},
			getSports: async () => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/sports`,
					method: 'GET',
				})
				set({ sport: res.data })
			},
			getCompanies: async () => {
				const res = await axios({
					url: `${BASE_URL}/sravniinsurance/companies`,
					method: 'GET',
				})
				set({ companies: res.data })
			},
			setCountry: value => {
				set({ currentCountry: value })
				set({
					data: {
						...get().data,
						destinations: [
							{
								id: get().countries.find(item => item.value === value)?.id,
								isGroup: get().countries.find(item => item.value === value)?.isGroup,
							}
						]
					}
				})
			},
			adultInc: () => {
				set({ adults: get().adults + 1 })
				set({ ages: [...get().ages, 20] })
			},
			adultDec: () => {
				set({ adults: get().adults - 1 })
				const ages = get().ages
				ages.pop()
				set({ ages: ages })
			},
			ageInc: (index) => {
				const age = get().ages
				age[index] = age[index] + 1
				set({ ages: [...age] })
			},
			ageDec: (index) => {
				const age = get().ages
				age[index] = age[index] - 1
				set({ ages: [...age] })
			},
			changeAge: (value, index) => {
				const age = get().ages
				age[index] = value
				set({ ages: [...age] })
			},
			setAnotherFilters: (value) => {
				set({ anotherFilters: value.map(item => { return item.optionType }) })
			},
			addToBasket: async (insurance) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
				const tourists = get().ages.map(age => {
					return {
						birthDay: format(addYears(new Date(), -Number(age)), 'yyyy-MM-dd')
					}
				})
				try {
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: null,
							transfers: [],
							insurance: [
								{
									adults: get().adults,
									travellers: tourists,
									assistanceNames: insurance.assistanceNames,
									country: {
										id: get().data.destinations[0].id,
										externalId: get().data.destinations[0].id,
										isGroup: get().data.destinations[0].isGroup,
										name: insurance.destinations[0]
									},
									currency: {
										currencyId: 1,
										currencyAlias: 'RUB'
									},
									currencyIsoCode: insurance.currencyIsoCode,
									destination: insurance.destinations[0],
									id: insurance.id,
									netPrice: insurance.netPrice,
									price: insurance.price,
									productId: insurance.productId,
									searchId: get().searchId,
									policyStartDate: insurance.policyStartDate,
									policyEndDate: insurance.destinations[0] === 'Шенген' ? get().data?.journeyInfo?.dateTo : insurance.policyEndDate,
									name: insurance.productName,
									options: insurance.options,
									lifeInsuranceAmount: insurance.lifeInsuranceAmount,
									insuranceCompanyName: get().companies.find(item => item.id === insurance.insuranceCompanyId)?.name,
									tripCancellationInsuranceAmount: get().data.tripCancellationInsuranceAmount,
									baggageInsuranceAmount: get().data.baggageInsuranceAmount,
									accidentInsuranceAmount: get().data.accidentInsuranceAmount,
									liabilityInsuranceAmount: get().data.liabilityInsuranceAmount,
									supActivities: get().data.supActivities,
									partner_code: '',
									journeyInfo: get().data.journeyInfo,
									daysCount: get().data.journeyInfo.daysCount,
									partner: {
										offerId: 1068,
										affId: 1823,
										sourceId: "2319",
										affSub1: "18"
									},
									utm: {
										medium: "cpa",
										campaign: "mantravel_api",
										content: "1823",
										source: "tui.ru_2319"
									},
								}
							]
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					window.open('/booking/insurance')
				} catch (e) {
					console.log(e)
				}
			},
			getDraft: async () => {
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
				try {
					const res = await axios({
						url: `${BASE_URL}/Draft`,
						method: 'GET',
						params: {
							userId: agentInfo.agents.id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					set({ draft: res.data })
					return res.data.draftBody
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				}
			},
			updateBasket: async () => {
				set({loadingUpdateBasket: true})
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
				const { needRussianFullName } = get().insurances?.items?.find(item => item.id === get().draft?.draftBody?.insurance[0]?.id)
				const { lastNameLatin, firstNameLatin, ...documentWithoutLatin } = get().travellers[0].document 

				try {
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: {...get().travellers[0], document: {...documentWithoutLatin, type: 'passport' , ...get().customerDocument}},
							userInfo: null,
							insurance: [
								{
									...get().draft.draftBody.insurance[0],
									travellers: get().travellers.map(item => {
										if (needRussianFullName) {
											const { lastName, firstName, middleName, ...rest } = item.document;
											return {
												...item,
												document: rest
											}
										} else return item
									}),
									netPrice: partpass === '169167' ? 1 : get().draft.draftBody.insurance[0].netPrice,
									price: partpass === '169167' ? 1 : get().draft.draftBody.insurance[0].price,
								}
							]

						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					set({ isReadyForBooking: true })
				} catch (e) {
					console.log(e)
				} finally {
					set({loadingUpdateBasket: false})
				}

			},
			reserveDraft: async (id) => {
				set({ loading: true })
				set({ reserveInsuaranceLoading: true })
				try {
					await axios({
						url: `${BASE_URL}/Draft/reserve`,
						method: 'POST',
						data: {
							draftId: id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					await get().actions.getStatus(id)
				} catch (e) {
					console.log(e)
					if (e?.response?.status === 500) {
						set({
							status: {
								status: 'UndoCompleted',
								trace: [{
									message: 'Ошибка бронирования'
								}]
							}
						})
					}
				} finally {
					set({ loading: false })
					set({ reserveInsuaranceLoading: false })
				}
			},
			getStatus: async (id) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Draft/status`,
						method: 'GET',
						params: {
							draftId: id
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					set({ status: res.data })
				} catch (e) {
					console.log(e)
				}
			},
			changeGender: (gender, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.sex = gender
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeDoc: (doc, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.type = doc
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeNation: (nation, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.nationalityId = nation
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeSeries: (series, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.series = series
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeNumber: (num, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.number = num
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeLastNameLatin: (lastName, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.lastNameLatin = lastName
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeFirstNameLatin: (lastName, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.firstNameLatin = lastName
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeFirstName: (name, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.firstName = name
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeLastName: (lastName, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.lastName = lastName
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changePatronymic: (patronymic, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.middleName = patronymic
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changePhone: (phone, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.phones = phone.replace('+', '')
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeEmail: (email, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.email = email
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeAddress: (address, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.address = address
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeIssuingAuthority: (issuingAuthority, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.issuingAuthority = issuingAuthority
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeBirthDate: (date, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.birthDay = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeExpirationDate: (date, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.expirationDate = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			changeCustomerDocument: (prop, value) => {
				set({
					customerDocument: {...get().customerDocument, [prop]: value}
				})
				set({ isReadyForBooking: false })
			},
			changeIssueDate: (date, number) => {
				set({
					travellers: get().travellers.map(item => {
						if (item.id === number) {
							item.document.issueDate = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
				set({ isReadyForBooking: false })
			},
			setTravelers: () => {
				const travellers = get().draft?.draftBody?.insurance[0]?.travellers.map((item, index) => {
					return (
						{
							...item,
							id: index + 1,
							sex: 'male',
							document: {
								type: 'internationalPassport'
							},
							nationalityId: 210357
						}
					)
				})
				set({ travellers: travellers })
			},
			setAmountLife: (value) => {
				set({ data: { ...get().data, lifeInsuranceAmount: value } })
			},
			setAmountBaggage: (value) => {
				set({ data: { ...get().data, baggageInsuranceAmount: value } })
			},
			setAmountLiability: (value) => {
				set({ data: { ...get().data, liabilityInsuranceAmount: value } })
			},
			setAmountAccident: (value) => {
				set({ data: { ...get().data, accidentInsuranceAmount: value } })
			},
			setAmountTripCancellation: (value) => {
				set({ data: { ...get().data, tripCancellationInsuranceAmount: value } })
			},
			setActivites: (value) => {
				set({ data: { ...get().data, supActivities: value } })
				set({ selectedActivites: value })
			},
			setProfessionalSports: (value) => {
				set({ data: { ...get().data, professionalSports: value } })
				set({ selectedSport: value })
			},
			clearFilters: () => {
				set({
					data: {
						...get().data,
						supActivities: [],
						tripCancellationInsuranceAmount: 0,
						accidentInsuranceAmount: 0,
						liabilityInsuranceAmount: 0,
						baggageInsuranceAmount: 0,
						lifeInsuranceAmount: 40000,
					}
				})
			},
			setDate: (value) => {
				const dateFrom = format(value[0], 'yyyy-MM-dd')
				const dateTo = format(value[1], 'yyyy-MM-dd')
				const daysCount = differenceInDays(value[1], value[0]) + 1
				set({
					data: {
						...get().data,
						journeyInfo: {
							...get().data.journeyInfo,
							dateFrom: dateFrom,
							dateTo: dateTo,
							daysCount: daysCount
						}
					}
				})
			},
			clearError: () => {
				set({ status: null })
			},
			setPhoneError: value => {
				set({ phoneError: value })
			},
		}
	}))
)

export default useInsuranceStore 