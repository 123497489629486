import { call, spawn, select, put } from "redux-saga/effects"
import {
  checkAvailabilityRequestWorker,
  getAddictionalServicesRequestWorker
} from "../workers"
import {getAgentSiginState} from "../../../agents/agent-finance/selectors";
import * as Manager from "../../../agents/agent-fees/manager";
import {getAgentTaxesSuccess} from "../../../agents/agent-fees/duck";

/**
 * Используется когда пользователь находится в списке билетов и хочет начать бронирование билета
 * @param param0 - data required for booking page
 */
export function* checkAvailabilityRequestFlow({ payload }) {
  const responseId = yield call(checkAvailabilityRequestWorker, payload)
  const agent = yield select(getAgentSiginState)
  if (responseId) {
    yield spawn(getAddictionalServicesRequestWorker, responseId)

    if (agent) {
      const tax = yield call(Manager.getAgentTax, agent.agentId)
      yield put(getAgentTaxesSuccess(tax))
    }
  }
}
