import React from "react"
import styled from "styled-components"
import { Text } from "../../../../ui"
import Seat from "./Seat"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`

const Line = styled.div`
  width: 50px;
  background: #e3eaff;
  height: 70.71px;
`

const LineLong = styled(Line)`
  height: 200px;
`

const BlueLineWithRoofBase = styled.div`
  overflow-x: hidden;
  width: 50px;
`

const LeftBlueLine = styled(BlueLineWithRoofBase)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  & > :first-child {
    transform: rotate(45deg) translate(32px, 7px);
  }
  & > :last-child {
    transform: rotate(45deg) translate(-32px, -7px);
  }
`

const RightBlueLine = styled(BlueLineWithRoofBase)`
  & > :first-child {
    transform: rotate(-45deg) translate(-32px, 7px);
  }
  & > :last-child {
    transform: rotate(-45deg) translate(32px, -7px);
  }
`

const Content = styled.div`
  border: 3px solid #e3eaff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  z-index: 2;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`

const RowNumbersList = styled.div`
  width: 50px;
  padding-top: 62px;
  padding-left: 21px;
  box-sizing: border-box;
  align-self: flex-start;
  z-index: 1;
  & > span {
    display: block;
    margin-bottom: 12px;
  }
`

const RowNumber = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  @media (max-width: 767px) {
    line-height: 25px;
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  & > div {
    margin-right: 40px;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const SeatsGroup = styled.div`
  display: flex;
  & > * {
    margin-right: 12px;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const PlaceName = styled(Text)`
  display: block;
  width: 30px;
  text-align: center;
  font-weight: 600;
  color: #737373;
  @media (max-width: 767px) {
    width: 25px;
  }
`

const Deck = ({ rows, selectedPassengerType }) => {
  const columns = rows[0].seatGroups.map(x => {
    return x.seats.map(x => x.number.replaceAll(/\d/g, ""))
  })

  return (
    <Wrapper>
      <LeftBlueLine>
        <Line />
        <LineLong />
        <Line />
      </LeftBlueLine>
      <RowNumbersList>
        {rows.map((x, key) => (
          <RowNumber key={key}>{x.number}</RowNumber>
        ))}
      </RowNumbersList>
      <Content>
        <Row>
          {columns.map((x, key) => (
            <SeatsGroup key={key}>
              {x.map((y, key) => (
                <PlaceName key={key}>{y}</PlaceName>
              ))}
            </SeatsGroup>
          ))}
        </Row>
        {rows.map((x, key) => (
          <Row key={key}>
            {x.seatGroups.map((y, key) => (
              <SeatsGroup key={key}>
                {y.seats.map((z, key) => (
                  <Seat key={key} {...z} selectedPassengerType={selectedPassengerType} />
                ))}
              </SeatsGroup>
            ))}
          </Row>
        ))}
      </Content>
      <RightBlueLine>
        <Line />
        <LineLong />
        <Line />
      </RightBlueLine>
    </Wrapper>
  )
}

export default Deck
