import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  selectAgentTaxes,
  selectAgentTaxesLoading
} from "../../../store/modules/agents/agent-fees/selectors"
import { feesFormNormalizer } from "../../../store/modules/agents/agent-fees/utils"
import { Formik, Form } from "formik"
import { Button, Text as UIText} from "../../ui"
import { feesSchema } from "./constants"
import { getAgentInfo } from "../../../store/modules/agents/agent-finance/selectors"
import { updateTaxesRequest } from "../../../store/modules/agents/agent-fees/duck"
import {getUserDataState,changeUserData} from '../../../store/modules/user'
import yellowInfoIcon from "../../../asset/images/avia/agents/yellowInfoIcon.svg"
import Switch from '@mui/material/Switch';
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import { styled as mStyled } from '@mui/material/styles';
import {ReactComponent as InfoIconSVG} from '../../../asset/images/avia/agents/info-icon.svg'
import PriceTextField from '../../ui/form/PriceTextField'
import PrimaryButton from "../../ui/PrimaryButton"

const Container = styled.div`
margin-bottom: 60px;
`

const Title = styled.div`
  margin-bottom: 20px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
  margin-bottom: 29px;
`
const LastRowText = styled(UIText)`
  font-size: 16px;
  line-height: 20px;
`;

const LastRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 31px;

  ${LastRowText} {
    margin-left: 8px;
    margin-right: 5px;
  }
`;

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const YellowBlock = styled.div`
  background: #fff3cd;
  border: 1px solid #ffb800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;
`

const YellowInfoIcon = styled.img.attrs({ src: yellowInfoIcon })`
  margin-right: 7px;
`

const YellowBlockText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledForm = styled(Form)`
  margin: 30px 0;
`;

const TTESwitch = mStyled(Switch)(({theme}) => ({
  width: 40,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4872F2',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 6,
    },
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    "&::before": {
        backgroundColor: 'white',
        border: "1px solid #999"
      }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 15px 20px 0px rgba(16, 16, 16, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    padding: 14,
    width: 221,
  },
}));

const FeeForm = () => {
  const taxes = useSelector(selectAgentTaxes)
  const initialValues = feesFormNormalizer(taxes);

  const agent = useSelector(getAgentInfo)
  const dispatch = useDispatch()
  const isLoading = useSelector(selectAgentTaxesLoading)
  const userData = useSelector(getUserDataState);



  return (
    <Container>
      <Title>Сборы за агентские услуги</Title>{" "}
      <Text>
        Получайте доход с продажи доп. услуг, самостоятельно устанавливая и
        реадктируя собственные агентские сборы.
      </Text>
      <YellowBlock>
        <YellowInfoIcon />
        <Column>
          <YellowBlockText>
            Сбор доступен для установления только в целом значении и в рублях.
            Установленные сборы будут применяться к каждой купленной
            дополнительной услуге.
          </YellowBlockText>
        </Column>
      </YellowBlock>
      <Formik
        validationSchema={feesSchema}
        onSubmit={(values,formikBag) => {
          if (agent) {
            const dto = {
              agentId: agent?.id,
              taxes: Object.entries(values).map(([key, value]) => ({
                taxType: key,
                taxValue: value
              }))
            }
      
            dispatch(updateTaxesRequest(dto))
            formikBag.resetForm({ values })
          }
        }}
        initialValues={initialValues}
      >
        {({ errors,setFieldValue, values, handleBlur, dirty }) => {
        
        const handleChange = (values,e) => {
          setFieldValue(e.event.target.name,values.value);
        }
        return (
          <StyledForm>
            <Grid>            
              <PriceTextField
                onValueChange={handleChange}
                name="ReturnGuaranteeService"
                id="ReturnGuaranteeService"
                fullWidth
                label="Сбор за “Гарантию возрата”"
                value={values.ReturnGuaranteeService}
                error={errors.ReturnGuaranteeService}
                textHelper='Целое значение в рублях'
              />
              <PriceTextField
                onValueChange={handleChange}
                name="SmsService"
                label="Сбор за “SMS-информирование”"
                fullWidth
                value={values.SmsService}
                error={errors.SmsService}
                textHelper='Целое значение в рублях'
              />
              <PriceTextField
                onValueChange={handleChange}
                name="PaymentReceipt"
                label="Сбор за “Справку о стоимости”"
                fullWidth
                value={values.PaymentReceipt}
                error={errors.PaymentReceipt}
                textHelper='Целое значение в рублях'
              />
              <PriceTextField
                onValueChange={handleChange}
                label="Сбор за “Дополнительный багаж”"
                name="AviaAdditionalService1"
                fullWidth
                value={values.AviaAdditionalService1}
                error={errors.AviaAdditionalService1}
                textHelper='Целое значение в рублях'
              />
              <PriceTextField
                onValueChange={handleChange}
                label="Сбор за “Питание в самолете”"
                name="AviaAdditionalService2"
                fullWidth
                value={values.AviaAdditionalService2}
                error={errors.AviaAdditionalService2}
                textHelper='Целое значение в рублях'
              />
              <PriceTextField
                onValueChange={handleChange}
                label="Сбор за “Место в самолете”"
                fullWidth
                name="AviaAdditionalService3"
                value={values.AviaAdditionalService3}
                error={errors.AviaAdditionalService3}
                textHelper='Целое значение в рублях'
              />
            </Grid>
            <LastRow>
              <TTESwitch defaultChecked={userData?.isHideCommission} onChange={(e) => {
                dispatch(changeUserData({...userData,isHideCommission: e.target.checked}))
              }}/>
              <LastRowText>Скрывать для просмотра сборы агента</LastRowText>
              <LightTooltip title="Для удобства работы с клиентом в деталях стоимости сбор агента будет скрыт от просмотра." arrow placement="top">
                <InfoIconSVG/>
              </LightTooltip>
            </LastRow>
            <PrimaryButton disabled={!dirty} type="submit" isLoading={isLoading}>
                Сохранить изменения
            </PrimaryButton>
          </StyledForm>);
        }}
      </Formik>
    </Container>
  )
}

export default FeeForm
