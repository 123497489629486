import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { tooltipClasses } from '@mui/material'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const LightTooltip = styled(({ className, children, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }}>
		{children}
	</Tooltip>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[2],
		fontSize: 14
	}
}))

export const SportActivity = () => {
	return (
		<LightTooltip
			title={
				<div>
					Выбор этой категории исключает возможность добавления фильтров из категории "профессиональный спорт"
				</div>
			}
			arrow
			placement='top'>
			<InfoOutlinedIcon  style={{verticalAlign: 'bottom', marginLeft: '5px'}}/>
		</LightTooltip>
	)
}

export  const ProfessionalSports = () => {
	return (
		<LightTooltip
			title={
				<div>
					Выбор этой категории исключает возможность добавления фильтров из категории "спорт и активности"
				</div>
			}
			arrow
			placement='top'>
			<InfoOutlinedIcon  style={{verticalAlign: 'bottom', marginLeft: '5px'}}/>
		</LightTooltip>
	)
}
