import React, {useEffect, useState} from 'react'
import moment from "moment";
import ArrowDown from "../../../../asset/icons/ArrowDownSmall";
import dateToLocal from "../../../../functions/dateToLocal";
import { firstCharUp } from '../../../../functions/helpers'

const HistoryItem = ({data, id}) => {
  let [isOpen, setOpen] = useState(false)
  const localCreateDate = dateToLocal(data.createDate)
  let localHandlingDate = false
  if (data.handlingDate) {
    localHandlingDate = dateToLocal(data.handlingDate) || false
  }

  useEffect(() => {
    id === 0 && setOpen(true)
  }, [id])

  const handleOpen = () => {
    setOpen(isOpen = !isOpen)
  }

  return (
    <>
      <div className={`tte-request-history-item ${isOpen ? 'open' : ''}`} onClick={handleOpen}>
        <div className={'left'}>
          <p style={{fontWeight: '600'}}>{ moment(localCreateDate).format('DD.MM.YYYY') }</p>
          <p className="tte-request-history-item-time">{ moment(localCreateDate).format('HH:mm') }</p>
          <div className="tte-request-history-item-status">
            <p className={`tte-request-history-item-circle ${data.status.code.toLowerCase()}`}></p>
            <p>{data.status.title}</p>
          </div>
        </div>
        <div className={`right ${isOpen ? 'open' : ''}`}>
          <ArrowDown/>
        </div>
      </div>
      {isOpen &&
        <div className={'tte-request-history-item-details'}>
          <div>Логин агента отправившего запрос: {data.agentUserName}</div>
          {localHandlingDate && <span style={{color: '#7E8389'}}>
          Комментарий от {moment(localHandlingDate).format('DD.MM.YYYY')}, {moment(localHandlingDate).format('HH:mm')}:{' '}
          </span>}
          {firstCharUp(data.comment)}
        </div>
      }
    </>
  )
}

export default HistoryItem
