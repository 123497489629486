import React from 'react';
import './TimeLimit.scss';
import clockIcon from '../../../asset/images/avia/booking/clock_fs.svg';

export default function TimeLimit(props) {
  return (
    <div className='fs-booking-time-limit'>
      <img  className='fs-booking-time-limit__icon' src={clockIcon} alt=""/>
      <span className='fs-booking-time-limit__text'>
        После оформления бронирования у вас будет{' '}
        <strong>{props.duration} на оплату</strong>
      </span>
    </div>
  );
}