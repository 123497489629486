import * as React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { ArrowBackIosSharp, Close } from '@material-ui/icons'
import Slide from '@mui/material/Slide'
import useHotelDescriptionStore from '../../../store'
import {
	Box,
	Container,
	DialogContent,
	DialogTitle,
	Divider
} from '@mui/material'
import { BootstrapDialog } from '../HotelPopup'
import styled from 'styled-components'

const Title = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin: 12px 0px;

`

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='right' ref={ref} {...props} />
})

const FilterBlocks = ({ id, array }) => {
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)
	const findElementById = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return array?.map(item => ({
			...item,
			name: tag?.find(elem => elem.id === item.id)?.name
		}))
	}
	return (
		<>
			<ul>
				{findElementById(id, array)?.map((item, i) => (
					<li key={i}>
						{item.payType === 'Paid' ? item.name + ' (платно)' : item.name}
					</li>
				))}
			</ul>
		</>
	)
}

export default function MobileCategoryPopup({
	open,
	handleClose,
	label,
	close
}) {
	const hotelDescText = useHotelDescriptionStore(
		({ hotelDescText }) => hotelDescText?.text
	)
	const checkTime = useHotelDescriptionStore(({ checkTime }) => checkTime)
	const comfort = useHotelDescriptionStore(({ comfort }) => comfort)
	const deposit = useHotelDescriptionStore(({ deposit }) => deposit)
	const cards = useHotelDescriptionStore(({ cards }) => cards)
	const restaurants = useHotelDescriptionStore(({ restaurants }) => restaurants)
	const forChildren = useHotelDescriptionStore(({ forChildren }) => forChildren)
	const beach = useHotelDescriptionStore(({ beach }) => beach)
	const distance = useHotelDescriptionStore(({ distance }) => distance)
	const territory = useHotelDescriptionStore(({ territory }) => territory)
	const waterEntertainment = useHotelDescriptionStore(
		({ waterEntertainment }) => waterEntertainment
	)
	const mealTypes = useHotelDescriptionStore(({ mealTypes }) => mealTypes)
	const locationHotel = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel
	)
	const thatAround = useHotelDescriptionStore(({ thatAround }) => thatAround)
	const addInfo = useHotelDescriptionStore(({ addInfo }) => addInfo)
	const roomType = useHotelDescriptionStore(({ roomType }) => roomType)
	const inRoom = useHotelDescriptionStore(({ inRoom }) => inRoom)
	const sport = useHotelDescriptionStore(({ sport }) => sport)
	const mountain = useHotelDescriptionStore(({ mountain }) => mountain)
	const language = useHotelDescriptionStore(({ language }) => language)
	const conferency = useHotelDescriptionStore(({ conferency }) => conferency)
	const spa = useHotelDescriptionStore(({ spa }) => spa)
	const healthy = useHotelDescriptionStore(({ healthy }) => healthy)
	const benefits = useHotelDescriptionStore(({ benefits }) => benefits)
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)
	//проверка, чтобы не отрисовывать FilterBlocks с пустыми значениями
	const isFilterBlocksEmpty = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return !array?.every(item => tag.find(elem => elem.id === item.id))
	}

	const isEmpty = item => {
		if (
			!item ||
			(item?.filters?.length === 0 && item?.text === '') ||
			(item?.text === '' &&
				isFilterBlocksEmpty(
					item?.descriptionBlockTypeId,
					item?.descriptionBlockFilters
				))
		) {
			return false
		} else return true
	}

	const renderBlocks = () => {
		switch (label) {
			case 'Описание':
				return (
					<>
						{isEmpty(hotelDescText) && (
							<>
								<Title>Расширенное описание</Title>
								<div
									style={{ textAlign: 'justify' }}
									dangerouslySetInnerHTML={createMarkup(hotelDescText)}></div>
								{(isEmpty(cards) ||
									isEmpty(comfort) ||
									isEmpty(roomType) ||
									isEmpty(checkTime) ||
									isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
						{isEmpty(checkTime) && (
							<>
								<Title>Время заезда/выезда</Title>
								{checkTime?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												checkTime?.text
											)}></div>
										{/* <Divider style={{ margin: '24px 0px' }} /> */}
										{(isEmpty(cards) ||
											isEmpty(comfort) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={checkTime?.descriptionBlockTypeId}
											array={checkTime?.descriptionBlockFilters}
										/>
										{(isEmpty(cards) ||
											isEmpty(comfort) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(deposit) && (
							<>
								<Title>Депозит</Title>
								{deposit?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												deposit?.text
											)}></div>
										{(isEmpty(cards) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={comfort?.descriptionBlockTypeId}
											array={comfort?.descriptionBlockFilters}
										/>
										{(isEmpty(cards) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(comfort) && (
							<>
								<Title>Удобства</Title>
								{comfort?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												comfort?.text
											)}></div>
										{(isEmpty(cards) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={comfort?.descriptionBlockTypeId}
											array={comfort?.descriptionBlockFilters}
										/>
										{(isEmpty(cards) ||
											isEmpty(roomType) ||
											isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(roomType) && (
							<>
								<Title>Номерной фонд</Title>
								{roomType?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												roomType?.text
											)}></div>
										{(isEmpty(cards) || isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={roomType?.descriptionBlockTypeId}
											array={roomType?.descriptionBlockFilters}
										/>
										{(isEmpty(cards) || isEmpty(inRoom)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(cards) && (
							<>
								<Title>Кредитные карты</Title>
								{cards?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
										{isEmpty(inRoom) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={cards?.descriptionBlockTypeId}
											array={cards?.descriptionBlockFilters}
										/>
										{isEmpty(inRoom) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(inRoom) && (
							<>
								<Title>В номере</Title>
								{inRoom?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												inRoom?.text
											)}></div>
									</>
								) : (
									<>
										<FilterBlocks
											id={inRoom?.descriptionBlockTypeId}
											array={inRoom?.descriptionBlockFilters}
										/>
									</>
								)}
							</>
						)}
					</>
				)
			case 'Расположение':
				return (
					<>
						{isEmpty(locationHotel) && (
							<>
								<Title>Где находится</Title>
								{locationHotel?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												locationHotel?.text
											)}></div>
										{isEmpty(thatAround) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={locationHotel?.descriptionBlockTypeId}
											array={locationHotel?.descriptionBlockFilters}
										/>
										{isEmpty(thatAround) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(thatAround) && (
							<>
								<Title>Что вокруг</Title>
								{thatAround?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											thatAround?.text
										)}></div>
								) : (
									<FilterBlocks
										id={thatAround?.descriptionBlockTypeId}
										array={thatAround?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Питание':
				return (
					<>
						{isEmpty(mealTypes) && (
							<>
								<Title>Типы питания</Title>
								{mealTypes?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												mealTypes?.text
											)}></div>
										{isEmpty(restaurants) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={mealTypes?.descriptionBlockTypeId}
											array={mealTypes?.descriptionBlockFilters}
										/>
										{isEmpty(restaurants) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(restaurants) && (
							<>
								<Title>Рестораны и бары</Title>
								{restaurants?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											restaurants?.text
										)}></div>
								) : (
									<FilterBlocks
										id={restaurants?.descriptionBlockTypeId}
										array={restaurants?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Для детей':
				return (
					<>
						<p></p>
						{isEmpty(forChildren) && (
							<>
								{forChildren?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											forChildren?.text
										)}></div>
								) : (
									<FilterBlocks
										id={forChildren?.descriptionBlockTypeId}
										array={forChildren?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Пляж':
				return (
					<>
						{isEmpty(beach) && (
							<>
								<Title>Описание пляжа</Title>
								{beach?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(beach?.text)}></div>
										{isEmpty(distance) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={beach?.descriptionBlockTypeId}
											array={beach?.descriptionBlockFilters}
										/>
										{isEmpty(distance) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(distance) && (
							<>
								<Title>Расстояние до моря</Title>
								{distance?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											distance?.text
										)}></div>
								) : (
									<FilterBlocks
										id={distance?.descriptionBlockTypeId}
										array={distance?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Территория':
				return (
					<>
						{isEmpty(territory) && (
							<>
								<Title>Описание территории</Title>
								{territory?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												territory?.text
											)}></div>
										{/* <Divider style={{ margin: '24px 0px' }} /> */}
										{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={territory?.descriptionBlockTypeId}
											array={territory?.descriptionBlockFilters}
										/>
										{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(waterEntertainment) && (
							<>
								<Title>Водные развлечения</Title>
								{waterEntertainment?.text ? (
									<div>
										<div
											dangerouslySetInnerHTML={createMarkup(
												waterEntertainment?.text
											)}></div>
										{isEmpty(sport) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</div>
								) : (
									<>
										<FilterBlocks
											id={waterEntertainment?.descriptionBlockTypeId}
											array={waterEntertainment?.descriptionBlockFilters}
										/>
										{isEmpty(sport) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(sport) && (
							<>
								<h4>Развлечения и спорт</h4>
								{sport?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(sport?.text)}></div>
								) : (
									<FilterBlocks
										id={sport?.descriptionBlockTypeId}
										array={sport?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Горнолыжная инфраструктура':
				return (
					<>
						{isEmpty(mountain) && (
							<>
								{mountain?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											mountain?.text
										)}></div>
								) : (
									<FilterBlocks
										id={mountain?.descriptionBlockTypeId}
										array={mountain?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Важная информация':
				return (
					<>
						{isEmpty(addInfo) && (
							<>
								<Title>Дополнительная информация</Title>
								{addInfo?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												addInfo?.text
											)}></div>
										{(isEmpty(language) ||
											isEmpty(cards) ||
											isEmpty(benefits)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={addInfo?.descriptionBlockTypeId}
											array={addInfo?.descriptionBlockFilters}
										/>
										{(isEmpty(language) ||
											isEmpty(cards) ||
											isEmpty(benefits)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(language) && (
							<>
								<Title>Язык</Title>
								{language?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(
												language?.text
											)}></div>
										{(isEmpty(benefits) || isEmpty(cards)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={language?.descriptionBlockTypeId}
											array={language?.descriptionBlockFilters}
										/>
										{(isEmpty(benefits) || isEmpty(cards)) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(cards) && (
							<>
								<Title>Кредитные карты</Title>
								{cards?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
										{isEmpty(benefits) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={cards?.descriptionBlockTypeId}
											array={cards?.descriptionBlockFilters}
										/>
										{isEmpty(benefits) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(benefits) && (
							<>
								<Title>Особенности и преимущества</Title>
								{benefits?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											benefits?.text
										)}></div>
								) : (
									<FilterBlocks
										id={benefits?.descriptionBlockTypeId}
										array={benefits?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'Возможности для конференций':
				return (
					<>
						{isEmpty(conferency) && (
							<>
								{conferency?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(
											conferency?.text
										)}></div>
								) : (
									<FilterBlocks
										id={conferency?.descriptionBlockTypeId}
										array={conferency?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			case 'SPA и лечение':
				return (
					<>
						{isEmpty(spa) && (
							<>
								<Title>Услуги SPA</Title>
								{spa?.text ? (
									<>
										<div
											dangerouslySetInnerHTML={createMarkup(spa?.text)}></div>
										{isEmpty(healthy) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								) : (
									<>
										<FilterBlocks
											id={spa?.descriptionBlockTypeId}
											array={spa?.descriptionBlockFilters}
										/>
										{isEmpty(healthy) && (
											<Divider style={{ margin: '24px 0px' }} />
										)}
									</>
								)}
							</>
						)}
						{isEmpty(healthy) && (
							<>
								<Title>Лечение и оздоровление</Title>
								{healthy?.text ? (
									<div
										dangerouslySetInnerHTML={createMarkup(healthy?.text)}></div>
								) : (
									<FilterBlocks
										id={healthy?.descriptionBlockTypeId}
										array={healthy?.descriptionBlockFilters}
									/>
								)}
							</>
						)}
					</>
				)
			default:
				return null
		}
	}
	function createMarkup(value) {
		return { __html: value }
	}
	return (
		<React.Fragment>
			<BootstrapDialog
				fullScreen
				open={open}
				onClose={handleClose}
				scroll='paper'
				TransitionComponent={Transition}>
				<DialogTitle sx={{ p: 0 }}>
					<Box sx={{ position: 'relative' }}>
						<Toolbar>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleClose}
								aria-label='close'>
								<ArrowBackIosSharp />
							</IconButton>
							<Typography
								sx={{
									ml: 1,
									flex: 1,
									fontFamily: 'Open Sans',
									fontWeight: 600,
									fontSize: '24px'
								}}
								variant='h6'
								component='div'>
								{label}
							</Typography>
							<IconButton
								edge='start'
								color='inherit'
								onClick={() => {
									handleClose()
									close()
								}}
								aria-label='close'>
								<Close />
							</IconButton>
						</Toolbar>
					</Box>
				</DialogTitle>
				<DialogContent sx={{ fontSize: '14px', lineHeight: '20px' }}>
					<Container size='sm'> {renderBlocks()}</Container>
				</DialogContent>
			</BootstrapDialog>
		</React.Fragment>
	)
}
