import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { TextField } from '@mui/material';
import { ReactComponent as CloseImg } from '../../../../asset/images/cabinet/close.svg';
import { ReactComponent as Ruble } from '../../../../asset/images/cabinet/Ruble.svg';
import MuiBirthDate from '../../AgentInfo/components/BirthDate'
import PhoneInput from '../components/PhoneInput';
import useClaimsStore from '../store'
import {newPhoneRegExp} from '../../../../constants/reg-exps'

const PopupBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(67, 67, 67, 0.7);
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const FormInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '8px',
        borderColor: 'rgba(212, 217, 222, 1)',
    },
    '& .MuiInputBase-input': {
        background: 'white',
        fontFamily: "'Open Sans', 'sans-serif'",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)'
    },
    '& label': {
        fontFamily: "'Open Sans', 'sans-serif'",
        color: '#7E8389'
    },
}));

const PopupContent = styled.div`
    position: relative;
    border-radius: 16px;
    padding: 32px;
    background-color: #fff;
    width: 770px;
    animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;
    max-height: 100vh;
    overflow-y: auto;
`;

const PopupTitle = styled.h2`
	color: #2E2E32;
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`;

const CloseBtn = styled(CloseImg)`
    width: 32px;
    height: 32px;
    fill: #2e2e32;
    cursor: pointer;
    &:hover {
        fill: #4872f2;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const InputRow = styled(Row)`
    gap: 16px;
    align-items: start;
`

const Text = styled.p`
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
`

const PriceText = styled(Text)`
    font-weight: 600;
`

const ReceiptTitle = styled(Text)`
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
`

const ContentRow = styled.div`
    display: flex;
	flex-direction: column;
    gap: 24px;
`

const ButtonFilled = styled(Button)`
    background-color: #4872f2;
    padding: 16px 32px;
    border-radius: 8px;
    box-shadow: none;
	min-width: fit-content;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: none;
`;

const defaultState = {
    phone: '',
    name: '',
    address: '',
    bdate: '',
    pseries: '',
    pnumber: '',
    pexpiration: '',
    pgivenorg: '',
    amount: ''
}

function ReceiptModal({ onClose, claimId, onClosePayments }) {
    const payment = useClaimsStore(({ payments }) => payments).find(item => item.claimId === claimId)
    const [errors, setError] = useState(defaultState)
    const rubPayment = payment?.paymentDetails?.CLAIM[0].CLAIM_COST.find(pay => pay.CurrencyAlias === "RUB")
    const [values, setValues] = useState({ ...defaultState, amount: rubPayment ? rubPayment.Amount : 0 })
    
    const actions = useClaimsStore(({ actions }) => actions)

    const handleChangePhone = (props) => (value) => {
        if (!newPhoneRegExp.test(value)) {
            setError(prev => ({ ...prev, [props]: 'Номер телефона указан некорректно' }))
            return
        }

        setError(prev => ({ ...prev, [props]: '' }))
        let filteredValue = value.slice(0, 15);
        setValues(prev => ({ ...prev, [props]: filteredValue }))
    }

    const handleChangePexpiration = (value) => {
        setValues(prev => ({ ...prev, pexpiration: value }))
    }

    const handleChangeBdate = (value) => {
        setValues(prev => ({ ...prev, bdate: value }))
    }

    const handleChangeInputs = (props) => (event) => {
        const inputValue = event.target.value;
        switch (props) {
            case 'name': {
                if (inputValue && /[^а-яА-ЯёЁ\- ', '.]/g.test(inputValue)) {
                    setError(prev => ({ ...prev, [props]: 'Введен некорректный символ' }))
                    break;
                }
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break;
            }
            case 'address': {
                if (/[^а-яА-ЯёЁ\- '.,/]/g.test(inputValue)) {
                    setError(prev => ({ ...prev, [props]: 'Введен некорректный символ' }))
                    break;
                }
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break;
            }
            case 'pseries': {
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break;
            }
            case 'pnumber': {
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break;
            }
            case 'pgivenorg': {
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break
            }
            case 'amount': {
                if (/\D/g.test(inputValue)) {
                    setError(prev => ({ ...prev, [props]: 'Введен некорректный символ' }))
                    break;
                }
                setError(prev => ({ ...prev, [props]: '' }))
                setValues(prev => ({ ...prev, [props]: inputValue }))
                break;
            }

            default: return null
        }
    }


    const handlePrintReceipt = () => {
        let allFieldsFilled = true;

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (!values[key]) {
                    setError(prev => ({ ...prev, [key]: 'поле не должно быть пустым' }));
                    allFieldsFilled = false;
                }
            }
        }

        if (!allFieldsFilled) {
            return
        }

        actions.getReceipt(values, claimId).then(() => {
            onClose(false)
            onClosePayments(false)
        })
    }

    return <PopupBackground>
        <PopupContent>
            <Row>
                <PopupTitle>Квитанция для оплаты в отделении банка</PopupTitle>
                <CloseBtn onClick={() => onClose(false)} />
            </Row>
            <ContentRow >
                <ReceiptTitle>Информация о плательщике</ReceiptTitle>
                <FormInput
                    label='ФИО плательщика'
                    value={values.name}
                    onChange={handleChangeInputs('name')}
                    error={errors.name}
                    helperText={errors.name}
                    required
                />
                <InputRow>
                    <FormInput
                        value={values.address}
                        fullWidth
                        label='Адрес плательщика'
                        onChange={handleChangeInputs('address')}
                        error={errors.address}
                        helperText={errors.address}
                        required
                    />
                    <MuiBirthDate
                        label='Дата рождения'
                        value={values.bdate}
                        fullWidth
                        onChange={handleChangeBdate}
                        style={{
                            width: '100%',
                            display: 'flex',
                            height: '100%'
                        }}
                        required
                    />
                </InputRow>

                <PhoneInput
                    label={'Телефон'}
                    phone={values.phone}
                    onChange={handleChangePhone('phone')}
                    error={errors.phone}
                    required
                />

            </ContentRow >
            <ContentRow>
                <ReceiptTitle>Документ</ReceiptTitle>
                <InputRow>
                    <FormInput
                        value={values.pseries}
                        onChange={handleChangeInputs('pseries')}
                        fullWidth
                        label='Серии документа'
                        error={errors.pseries}
                        helperText={errors.pseries}
                        required
                    />
                    <FormInput
                        value={values.pnumber}
                        onChange={handleChangeInputs('pnumber')}
                        fullWidth
                        label='Номер документа'
                        error={errors.pnumber}
                        helperText={errors.pnumber}
                        required
                    />
                </InputRow>

                <InputRow>
                    <FormInput
                        value={values.pgivenorg}
                        fullWidth
                        label='Кем выдан'
                        onChange={handleChangeInputs('pgivenorg')}
                        error={errors.pgivenorg}
                        helperText={errors.pgivenorg}
                        required
                    />
                    <MuiBirthDate
                        label='Действителен до'
                        fullWidth
                        value={values.pexpiration}
                        onChange={handleChangePexpiration}
                        isExpiration
                        style={{
                            width: '100%',
                            display: 'flex',
                            height: '100%'
                        }}
                        required
                    />
                </InputRow>
            </ContentRow>
            <ContentRow>
                <ReceiptTitle>
                    Информация о платеже
                </ReceiptTitle>
                <InputRow>
                    <PriceText>Оплачено:</PriceText>
                    <PriceText>{rubPayment?.Paid}{' '}{rubPayment?.CurrencyAlias}</PriceText>
                </InputRow>
                <InputRow> <FormInput
                    fullWidth
                    label='Банк'
                    defaultValue="ВТБ"
                    InputProps={{
                        readOnly: true,
                    }}
                    required
                />
                    <FormInput
                        fullWidth
                        value={values.amount}
                        label='Сумма для оплаты '
                        onChange={handleChangeInputs('amount')}
                        error={errors.amount}
                        helperText={<span>{errors.amount}</span>}
                        required
                        InputProps={{
                            endAdornment: <Ruble />
                        }}
                    />
                </InputRow>
                <InputRow style={{ justifyContent: "center" }}>
                    <ButtonFilled
                        onClick={handlePrintReceipt}
                        variant="contained"
                        color="primary"
                    >Выписать квитанцию</ButtonFilled>
                </InputRow>
            </ContentRow>
        </PopupContent>
    </PopupBackground>
}


export default ReceiptModal