import React from "react"
import { useSelector} from "react-redux"
import {
  getNotPaidOrders,
} from "../../../store/modules/agents/agent-finance/selectors"
import styled from "styled-components"
import { TableUi } from "../TableUi"
import agentUnpaidImage from "../../../asset/images/avia/agents/agentUnpaidImage.svg"
import ArrowButton from "./ArrowButton"
import Collapse from '@mui/material/Collapse';
import UnpaidOrdersTable from './tables/UnpaidOrdersTable';
import quantityCaseFormat from '../../../functions/quantityCaseFormat'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const GrayText = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #737373;
  padding-top: 15px;
  margin-bottom: 25px;
`

const OrderCounter = styled.div`
  color: ${({ isActive }) => (isActive ? "#4872F2" : "#737373")};

  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderCounterMobile = styled.div`
  color: ${({ isActive }) => (isActive ? "#4872F2" : "#737373")};

  @media screen and (min-width: 767px) {
    display: none;
  }
`

const AgentUnpaidImage = styled.img.attrs({ src: agentUnpaidImage })`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${({ isActive }) => (isActive ? "#4872F2" : "#dcdcdc")};
`

const AgentUnpaidOrders = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [hover, setHover] = React.useState(false)
  const data = useSelector(getNotPaidOrders)

  let ordersQuantityStr = ''; 
  if(data?.length === 0 || data?.length === undefined) {
    ordersQuantityStr = '(Нет заявок)';
  } else {
    ordersQuantityStr = `(${data?.length} ${quantityCaseFormat(data?.length,'заявка','заявки','заявок')})`;
  }

  return (
    <>
      <Row
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <FlexRow>
          <AgentUnpaidImage /> <Title>Список неоплаченных заявок</Title>{" "}
          <OrderCounter
            isActive={data?.length !== 0 && data?.length !== undefined}
          >
            {" "}
            &nbsp;
            {ordersQuantityStr}
          </OrderCounter>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Отслеживайте неоплаченные заявки и формируйте счета.
      </SubTitle>
      <OrderCounterMobile isActive={data?.length !== 0}>
        {" "}
        &nbsp;
        {data?.length === 0 || data?.length === undefined
          ? "(Нет заявок)"
          : "(" + data?.length + " заявок)"}
      </OrderCounterMobile>
      <Collapse in={isOpen}>
        <div >
          <TableUi.Container>
            <Text>
              В данном блоке вам доступен список неоплаченных заявок. При
              нажатии на номер вы сможете перейти к деталям заявки. Получение
              счета на оплату доступно по кнопке “Получить счет”.
            </Text>
            <GrayText>
              * Время выгрузки неоплаченных заявок составляет 10 мин
            </GrayText>
            {!data.length || !data ? (
              <TableUi.SubTitle>Заявки не найдены</TableUi.SubTitle>
            ) : (
              <UnpaidOrdersTable items={data}/>
            )}  
          </TableUi.Container>
        </div>
      </Collapse>
      <Separator isActive={hover} />
    </>
  )
}

export default AgentUnpaidOrders
