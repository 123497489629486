import React from 'react';
import './styl.scss'
import {Button} from "@material-ui/core";
import useGetTransfers from "../../../../../hooks/useGetTransfers";
import useTransferStore from "../../store";
import declination from "../../../../../functions/declination";
import {Box} from "@mui/material";
import Peoples from '../../../../../asset/icons/peoples.svg'
import Baggage from '../../../../../asset/icons/baggage.svg'
import Clock from '../../../../../asset/icons/clock3.svg'
import Cancel from '../../../../../asset/icons/close-round.svg'
import Chat from '../../../../../asset/icons/chat1.svg'
import Tooltip from "@mui/material/Tooltip";

const SearchResults = ({ transfers }) => {
	const adults = useTransferStore(({ adults }) => adults)
	const children = useTransferStore(({ children }) => children)
	const meetingData = useTransferStore(({ meetingData }) => meetingData)
	const actions = useTransferStore(({ actions }) => actions)
	const pageFrom = useTransferStore(({ pageFrom }) => pageFrom)
	const pageTo = useTransferStore(({ pageTo }) => pageTo)
	const placesFrom = useTransferStore(({ placesFrom }) => placesFrom)
	const placesTo = useTransferStore(({ placesTo }) => placesTo)

	const filteredTransfers = useGetTransfers(transfers, adults + children.length)

	return (
		<div className={'container transfer-results'}>
			{filteredTransfers.map(transfer =>
				<div key={transfer.id} className={'transfer-card'}>
					<div className={'image-block'}>
						<div className={'image-block__img'}>
							<img src={transfer.type.photo} alt=""/>
						</div>
						<div className={'image-block__text'}>
							{transfer.type.description.ru}
						</div>
					</div>

					<div className={'transfer-card-description'}>
						<div className={'transfer-card__title'}>
							{transfer.type.name.ru} ({transfer.route.placeFrom.name.ru} - {transfer.route.placeTo.name.ru})
						</div>
						<div className={'transfer-card__paxes'}>
							<Box component={"span"}>
								<i style={{WebkitMask: `url(${Peoples})`}}/>
								{transfer.type.pax} {declination(transfer.type.pax, ['человек', 'человека', 'человек'])}
							</Box>
							<Box component={"span"} ml={2}>
								<i style={{WebkitMask: `url(${Baggage})`}}/>
								{transfer.type.baggage} {declination(transfer.type.baggage, ['багажное', 'багажных', 'багажных'])} {declination(transfer.type.baggage, ['место', 'места', 'мест'])}
							</Box>
						</div>
						<div className={'transfer-card__options'}>
							<Box mb={1}>
								<i style={{WebkitMask: `url(${Cancel})`}}/>
								Бесплатная отмена заказа
							</Box>
							<Box mb={1}>
								{
									meetingData.find(item => item.id === transfer.id)?.waitingTime
									&& <span>
										<i style={{WebkitMask: `url(${Clock})`}}/>
										{meetingData.find(item => item.id === transfer.id)?.waitingTime} минут ожидания заказа
									</span>
								}
							</Box>
							<div>
								<i style={{WebkitMask: `url(${Chat})`}}/>
								<Tooltip
									title={
										<>
											{meetingData.find(item => item.id === transfer.id)?.description.ru}
										</>
									}
									arrow
									placement="bottom-start"
								>
									<span style={{textDecoration: 'underline', textDecorationStyle: 'dotted', cursor: 'pointer'}}>Встреча с табличкой</span>
								</Tooltip>
							</div>
						</div>
					</div>

					<div className={'transfer-card-price'}>
						<div className={'transfer-card-price__cost'}>от {Math.round(transfer.price.rub.cost + transfer.price.rub.cost * 0.25).toLocaleString('ru', {})} ₽</div>
						<Button variant={'contained'} onClick={() => actions.addToBasket(transfer)}>Забронировать</Button>
					</div>
				</div>

			)}
			{(placesFrom.length - 1 > pageFrom || placesTo.length - 1 > pageTo) &&
				<Box textAlign={'right'} width={400} ml={'auto'}>
					<Button variant={'contained'} fullWidth onClick={actions.loadMore}>Загрузить еще</Button>
				</Box>
			}
		</div>
	);
};

export default SearchResults;