export const subscribeAgent = (email, source) => {
	const departureCity = localStorage.getItem('departureCityId') || '274286'
	// console.log(departureCity)
	window.mindbox('async', {
		operation: 'SubscriptionOnInternalPages',
		data: {
			customer: {
				email: email,
				customFields: {
					subscriberTypeFS: 'b2b',
					SectionOfSiteFS: source
				},
				area: {
					ids: {
						externalId: departureCity
					}
				},
				subscriptions: [
					{
						brand: 'FUNSUN',
						pointOfContact: 'Email'
					}
				]
			}
		}
	})
}
