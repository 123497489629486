import React from 'react';

import './style.scss'
import StaticBlocks from "../../StaticBlocks";
import { isMobile } from 'react-device-detect'

const ColumnsPage = ({ content, title }) => {

	const block = content.content || content

	const getClasses = () => {
		switch (block?.template) {
			case 'twoColumns':
				return { left: 'columns-block-content', right: 'columns-block-content' }
			case 'twoColumnsDifferent':
				return { left: 'columns-block-triple', right: 'columns-block-content' }
			case 'twoColumnsDifferentRevers':
				return { left: 'columns-block-content', right: 'columns-block-triple' }
			default:
				return { left: 'columns-block-content', right: 'columns-block-content' }
		}
	}

	const classes = getClasses()

	return (
		<>
			<h2>{title}</h2>
			<div className={'wrap-columns-block'}>
				{block?.nested?.left?.blocks && <div className={classes?.left}>
					<StaticBlocks blocks={block?.nested.left.blocks.filter(block => isMobile ? block.show_mobile : block.show_desktop)} />
				</div>}
				{block?.nested?.right?.blocks && <div className={classes?.right}>
					<StaticBlocks blocks={block?.nested.right.blocks.filter(block => isMobile ? block.show_mobile : block.show_desktop)} />
				</div>}
			</div>
		</>
	);
};

export default ColumnsPage;