import { format } from 'date-fns'
import './styles.scss'

const programmRegex =
	/<u>Продолжительность программы:<\/u><u><br\/><\/u>(.*?)<u>/s

function ServiceInfo({ excursion, additional }) {
	const shortDescription =
		additional?.other_params?.shortDescription?.match(programmRegex)

	return (
		<div className='excursion-header'>
			<div className='excursion-header__title'>
				<h3>Услуги в аэропорту</h3>
			</div>
			<div className='excursion-header-table'>
				<div className='excursion-header-table__header'>
					<div>Название</div>
					<div>Даты</div>
					<div style={{ textAlign: 'center' }}>Количество</div>
					<div></div>
				</div>
				<div className='excursion-header-table__body'>
					<div>{excursion.name}</div>
					<div>{format(new Date(excursion.date), 'dd.MM.yyyy')}</div>

					<div style={{ textAlign: 'center' }}>
						{excursion?.adults + excursion?.children}
					</div>
					<div>
						{Math.ceil(excursion?.price).toLocaleString('ru', {})}{' '}
						{excursion?.currency?.currencyAlias}
					</div>
				</div>
				{shortDescription && (
					<div className='excursion-header-table__note-container'>
						Продолжительность программы:{' '}
						<span
							dangerouslySetInnerHTML={{
								__html: shortDescription[1]?.replace('<br/>', '')
							}}></span>{' '}
					</div>
				)}
			</div>
		</div>
	)
}

export default ServiceInfo
