import { Box, Snackbar, TextField } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import useBookingStore from '../../store'
import './style.css'
import { Alert } from '@mui/material'

export default function ApplicationNoteBlock() {
	const readyToBooking = useBookingStore(({ readyToBooking }) => readyToBooking)
	const actions = useBookingStore(({ actions }) => actions)
	const appNotes = useMemo(
		() => [
			'Размещение в основном корпусе',
			'Соседний номер с',
			'Вместе с заявкой #',
			'Двуспальная кровать (по возможности)',
			'Раздельные кровати (по возможности)',
			'Телефон менеджера',
			'Телефон туриста',
			'Люлька для младенца в номер',
			'День Рождения в период проживания',
			'Размещение на нижних этажах (по возможности)',
			'Размещение на верхних этажах (по возможности)',
			'Тихое место (по возможности)',
			'Номер ближе к лифту (по возможности)',
			'Постоянный гость отеля',
			'Молодожёны',
			'Номер карты постоянного гостя отеля (если есть)'
		],
		[]
	)
	const [message, setMessage] = useState('')
	const [error, setError] = useState(false)
	const [numbers, setNumbers] = useState({
		roomNumber: '',
		noteNumber: '',
		touristNumber: '',
		managerNumber: '',
		cardNumber: ''
	})
	const [checked, setChecked] = useState([])

	useEffect(() => {
		if (readyToBooking) {
			actions.setApplicationNotes({ checked, message, numbers })
		}
	}, [readyToBooking, checked, actions, message, numbers])

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setChecked(newChecked)
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setError(false)
	}

	const changeHandler = (value, id) => {
		switch (id) {
			case 'Соседний номер с':
				setNumbers(prev => ({ ...prev, roomNumber: value }))
				break
			case 'Вместе с заявкой #':
				if (/[0-9]/.test(+value)) {
					setNumbers(prev => ({ ...prev, noteNumber: value }))
					setError(false)
				} else setError(true)
				break
			case 'Телефон менеджера':
				setNumbers(prev => ({ ...prev, managerNumber: value }))
				break
			case 'Телефон туриста':
				setNumbers(prev => ({ ...prev, touristNumber: value }))
				break
			case 'Номер карты постоянного гостя отеля (если есть)':
				setNumbers(prev => ({ ...prev, cardNumber: value }))
				break
			default:
				return
		}
	}

	const chooseProperty = value => {
		switch (value) {
			case 'Соседний номер с':
				return numbers.roomNumber
			case 'Вместе с заявкой #':
				return numbers.noteNumber
			case 'Телефон менеджера':
				return numbers.managerNumber
			case 'Телефон туриста':
				return numbers.touristNumber
			case 'Номер карты постоянного гостя отеля (если есть)':
				return numbers.cardNumber
			default:
				return
		}
	}

	return (
		<>
			<div className='block-header'>
				<h3 className='title'>Примечание к заявке</h3>
			</div>
			<div className='notes-form '>
				<div className='info-notes' style={{ marginRight: 0 }}>
					<div className='info-container' style={{ backgroundColor: 'white' }}>
						{appNotes?.map(appNote => (
							<div
								key={appNote}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}>
								<div>
									<label
										htmlFor={appNote}
										style={{
											display: 'flex',
											alignItems: 'center',
											padding: '3px'
										}}>
										<input
											id={appNote}
											type='checkbox'
											style={{ marginRight: '10px' }}
											checked={checked.indexOf(appNote) !== -1}
											onChange={handleToggle(appNote)}
										/>
										{appNote}
									</label>
								</div>
								{(appNote === 'Соседний номер с' ||
									appNote === 'Вместе с заявкой #' ||
									appNote === 'Телефон менеджера' ||
									appNote === 'Телефон туриста' ||
									appNote ===
										'Номер карты постоянного гостя отеля (если есть)') && (
									<input
										type='text'
										id={appNote}
										disabled={!(checked.indexOf(appNote) !== -1)}
										value={chooseProperty(appNote)}
										onChange={e => changeHandler(e.target.value, appNote)}
										style={{ width: '90px' }}
									/>
								)}
							</div>
						))}
					</div>
				</div>
				{/*<div className='app-note'>*/}
				{/*	<Box style={{ backgroundColor: 'white' }}>*/}
				{/*		<TextField*/}
				{/*			label='Примечание к заявке'*/}
				{/*			variant='outlined'*/}
				{/*			fullWidth*/}
				{/*			value={message}*/}
				{/*			onChange={e => setMessage(e.target.value)}*/}
				{/*			multiline*/}
				{/*			placeholder='Введите сообщение'*/}
				{/*			minRows={8}*/}
				{/*		/>*/}
				{/*	</Box>*/}
				{/*</div>*/}
			</div>
			<Snackbar
				open={error}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert severity='warning' onClose={handleClose}>
					Могут быть только цифры
				</Alert>
			</Snackbar>
		</>
	)
}
