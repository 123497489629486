import React, {useEffect, useState} from 'react';

import './style.scss'
import {Link} from "react-router-dom";
import {CloseOutlined} from "@material-ui/icons";

const PopupBanner = ({ content, title }) => {

	const [isShow, setShow] = useState(false)

	const sleep = ms => new Promise(r => setTimeout(r, ms))

	useEffect( () => {
		async function pause() {
			await sleep(2000);
			setShow(true)
		}
		pause();
	}, [])

	const onClose = (e) => {
		e.preventDefault()
		setShow(false)
	}

	if (!isShow) return

	return (
		<div className={'popup-banner-box'}>
			{content.link ? <Link to={content.link} className={'popup-banner-background'}
				   style={{backgroundImage: `url(${content.image})`}}>
				<div className={'modal-close'}
					 onClick={onClose}
				>
					<CloseOutlined/>
				</div>
				<div>
					<div className={'popup-banner-sub-title'}>{content.sub_title}</div>
					<div className={'popup-banner-title'}>{title}</div>
				</div>
			</Link> :
				<div className={'popup-banner-background'}
					  style={{backgroundImage: `url(${content.image})`}}>
					<div className={'modal-close'}
						 onClick={onClose}
					>
						<CloseOutlined/>
					</div>
					<div>
						<div className={'popup-banner-sub-title'}>{content.sub_title}</div>
						<div className={'popup-banner-title'}>{title}</div>
					</div>
				</div>
			}
		</div>
	);
};

export default PopupBanner;