import React from "react"
import styled from "styled-components"
import { Text } from "./Typography"
import blueInfoIcon from "../../asset/images/avia/agents/blueInfoIcon.svg"

const Container = styled.div`
  padding: 14px 24px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 14px;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 8px;
`

const BlueInfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  margin-right: 4px;
  width: 15px;
  height: 15px;
`

export function OnlyRusCardNotyBlue() {
  return (
    <Flex>
      <BlueInfoIcon />
      <Text color={"blue"} size={"small"} fontWeight={"600"}>
        Оплата заявки принимается только картами российских банков или картами
        платежной системы «Мир».
      </Text>
    </Flex>
  )
}

export default function OnlyRusCardNoty() {
  return (
    <Container>
      <Text>
        Обращаем Ваше внимание, оплата заявки принимается только картами
        российских банков или картами платежной системы «Мир».
      </Text>
    </Container>
  )
}
