// eslint-disable react-hooks/exhaustive-deps
import React from 'react';
import styled from 'styled-components';
import {Outlet} from "react-router-dom";
import AviaSearchForm from '../components/AviaSearchForm'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {initializeSearchForm} from '../store/modules/search-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useAuthStore from "../pages/Auth/store";
import Auth from "../pages/Auth";
import {useMediaQuery} from 'react-responsive'
import ErrorNotification from "../components/AviaBooking/ErrorNotification";
import {getNotification} from "../store/modules/notification";


const theme = createTheme({
	palette: {
		primary: {
			main: '#4872f2',
			light: '#deebff',
			dark: '#0052cc'
		},
		secondary: {
			main: '#ffe100',
			dark: '#ffcd00',
		},
		gray: {
			light: '#D9D9D9',
			main: '#3c3c3c',
			// dark: '#ffcd00',
		},
		lightBlue: {
			main: '#EDF1FE',
			contrastText: '#4872F2',
			// light: '#deebff',
			dark: '#d7dffa'
		}
	}
})

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 81px);
    padding-bottom: 30px;

    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    @media (max-width: 1169px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export default function AviaTemplate() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [prevPathname,setPrevPathname] = React.useState(null);
  const TTEToken = useAuthStore(({ tokenTTE }) => tokenTTE)
	const isMobile = useMediaQuery({maxWidth: 767})
	const notification = useSelector(getNotification);

  React.useEffect(() => {
		if (isMobile) {
			window.location = 'https://avia-new.fstravel.com/'
		}
    // dispatch(checkAuthStatus());
    if (!location.pathname.includes('/search')) {
      dispatch(initializeSearchForm);
    } else {

    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(()=> {
      if(location.pathname !== prevPathname) {
          window.scrollTo(0,0);
          setPrevPathname(location.pathname)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])


  return (
    <>
      {TTEToken
        ?
        <ThemeProvider theme={theme}>
          <Wrapper>
            <AviaSearchForm/>
            <Outlet/>
						{notification?.label && <ErrorNotification />}
					</Wrapper>
        </ThemeProvider>
        :
        <Auth />}
    </>
  );
}
