import React, {useState} from 'react';
import ArrowThink from "../../../../../asset/icons/Arrow-think";
import {Button} from "@material-ui/core";
import AppealsList from "./AppealsList";
import { CreateAppealModal } from "../../../../../components/modals"
import Collapse from '@mui/material/Collapse';
import useClaimsStore from "../../store";

const Appeals = ({details, onChange}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenModal, setIsOpenModal] = useState(false)
	const actions = useClaimsStore(({ actions }) => actions)

	const onlyOtherRefundReturnSubjects = details?.flight_Type === "Charter" && details?.status.code === "DelayedIssue"
	const onlyOtherSubject = (details?.availableActions || []).includes("CREATE_APPEAL_WITH_ONLY_OTHER_THEME")

	const handleNewAppealClick = () => {
		setIsOpenModal(true)
	}

	const handleAppealClose = () => {
		setIsOpenModal(false)
		setTimeout(async () => {
			onChange(await actions.getOrderByNumber(details.orderNumber))
		}, 100)
	}

	return (
		<>
			<div className={'avia-order-block__item'}>
				<div className={'header'}>Обращения по заявке ({details?.supportTickets?.length || 0})</div>
				<div className={`avia-order-icon ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
					<ArrowThink/>
				</div>
			</div>

			<Collapse in={isOpen}> 
				<div className={'messages'} style={{padding: '0 15px 15px'}}>
				{<Button className={'create'} variant={'containedPrimary'} color={'primary'} onClick={handleNewAppealClick}>
					Создать новое обращение
				</Button>}

				<AppealsList supportTickets={details?.supportTickets} orderNumber={details?.orderNumber} />

				</div>
			</Collapse>

			{isOpenModal && <CreateAppealModal
				insurances={details.insurances}
				onlyOtherSubject={onlyOtherRefundReturnSubjects ? false : onlyOtherSubject}
				onlyOtherRefundReturnSubjects={onlyOtherRefundReturnSubjects}
				open={isOpenModal}
				onClose={handleAppealClose}
				orderPosition={details.orderPositions[0]}
				defaultSubject={'Other'}
			/>}
		</>
	);
};

export default Appeals;
