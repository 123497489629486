import React, { useCallback, useState } from 'react';
import { StyledInput, Wrapper, Label, InputWrapper } from './styles';
import styled from 'styled-components';
import InfoIcon from '../../../avia-agents/agent-finance/InfoIcon';

const ErrorText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #e73c3e;
  position: absolute;
  white-space: nowrap;
`;


const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`;



const StyledInfoIcon = styled(InfoIcon)`
  right: -21px;
  top: 1px;
  position: absolute;
  width: auto;
`;
// const InfoIcon = styled.img.attrs({ src: infoIcon })`
//   cursor: pointer;
// `;

const Input = ({
  label,
  error,
  isSuccess,
  isError,
  onFocus,
  onBlur,
  inputRef,
  children,
  placeholder,
  value,
  height,
  wrapperClassName,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false);
  const handleFocus = useCallback(
    (e) => {
      setFocus(true);
      if (typeof onFocus === 'function') {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setFocus(false);
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Wrapper
      className={wrapperClassName}
      focused={isFocused || (error !== undefined && error !== '')}
    >
      {label && (
        <Row>
          <Label>{label} </Label>
          {props.infoIcon && (<StyledInfoIcon title={props.infoIcon.title} width={props.infoIcon.width}/>)}
        </Row>
      )}
      <InputWrapper>
        <StyledInput
          height={height}
          value={value}
          placeholder={placeholder}
          error={error}
          $issuccess={isSuccess}
          $iserror={isError}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
      {children}
    </Wrapper>
  );
};

export default Input;
