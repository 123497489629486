import Api from '../../../functions/fetchApi'

export function getUserData () {
  return Api.get(process.env.REACT_APP_GET_USER_DATA)
    .setContentType('application/json')
    .addAuthGuard()
    .send()

}

export function updateUserData (data)  {
  return Api.put(process.env.REACT_APP_PUT_USER_DATA)
    .body({ ...data, gender: "Male" })
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}

export function getPassangers (SearchText) {
  return Api.get(process.env.REACT_APP_GET_USER_PASSANGERS)
      .query({
        PageSize: 1000,
      })
      .setContentType('application/json')
      .addAuthGuard()
      .send()
}

export function createPassanger (data) {
  return Api.post(process.env.REACT_APP_GET_USER_PASSANGERS)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}

export function editPassanger(data) {
  return Api.put(process.env.REACT_APP_GET_USER_PASSANGERS)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}

export function removePassanger(customerPassengerId) {
  return Api.delete(process.env.REACT_APP_GET_USER_PASSANGERS)
    .body({ customerPassengerId})
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}
