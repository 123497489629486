import { Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function ThreeWithIcons({ content }) {
	console.log(content, 'content')
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	return (
		<Grid
			container
			direction={isSmallScreen ? 'row' : 'column'}
			rowSpacing={isSmallScreen ? '30px' : 3}
			columnSpacing={isSmallScreen ? '30px' : 3}
			style={{ marginBottom: '32px' }}>
			{content?.icons?.map(
				(item, i) => !item?.hideIcon && <ItemIcon key={i} item={item} />
			)}
		</Grid>
	)
}

const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	padding: 24px;
	width: 100%;
	min-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '352px' : '292px')};
	border-radius: 16px;
	border: 1px solid #d4d9de;
`

const Image = styled.img`
	max-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '48px' : '40px')};
	max-width: ${({ $isSmallScreen }) => ($isSmallScreen ? '48px' : '40px')};
	margin-bottom: 32px;
`

const Title = styled.div`
	font-size: ${({ $isSmallScreen }) => ($isSmallScreen ? '24px' : '18px')};
	font-weight: 600;
	line-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '32px' : '26px')};
	word-break: break-word;
	margin-bottom: 16px;
`

const Desc = styled.div`
	font-size: ${({ $isSmallScreen }) => ($isSmallScreen ? '16px' : '14px')};
	font-weight: 400;
	line-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '22px' : '20px')};
	word-break: break-word;
`

const ColorButton = styled(Link)`
	display: block;
	align-items: center;
	background: #ffe100;
	border-radius: 8px;
	color: ${({ $colorButtonText }) =>
		$colorButtonText ? $colorButtonText : '#2e2e32'};
	border: ${({ $colorButtonBorder }) =>
		$colorButtonBorder
			? `1px solid ${$colorButtonBorder}`
			: `1px solid #ffe100`};
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	height: 40px;
	line-height: 24px;
	padding: 8px 20px 8px 20px;
	text-decoration: none;
	&:hover {
		color: ${({ $colorButtonText }) =>
			$colorButtonText ? $colorButtonText : '#2e2e32'};
	}
`

const ItemIcon = ({ item }) => {
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	return (
		<Grid item xs={4}>
			<Item $isSmallScreen={isSmallScreen}>
				<Box>
					<Image
						src={item?.img}
						alt={`Изображение ${item?.title}`}
						$isSmallScreen={isSmallScreen}
					/>
					<Title $isSmallScreen={isSmallScreen}>{item?.title}</Title>
					<Desc $isSmallScreen={isSmallScreen}>{item?.description}</Desc>
				</Box>
				<ColorButton
					to={item?.link ? item?.link : '#'}
					$colorButtonText={item?.colorButtonText}
					$colorButtonBorder={item?.colorButtonBorder}>
					Подробнее
				</ColorButton>
			</Item>
		</Grid>
	)
}
