import Api from '../../../../functions/fetchApi'

export function getAgentUserRoles() {
  return Api.get(process.env.REACT_APP_GET_USER_ROLE)
  .setContentType('application/json')
  .addAuthGuard()
  .send()
}

export function getAgentUsers(AgentId) {
  return Api.get(process.env.REACT_APP_GET_USERS)
  .query({AgentId})
  .setContentType('application/json')
  .addAuthGuard()
  .send()
}

export function addAgentUser(data) {
  return Api.post(process.env.REACT_APP_ADD_USER_TO_AGENT)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export const setRoleToUser = data =>
  Api.put(process.env.REACT_APP_SET_ROLE_TO_USER)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send()


export function setAgentUsers(data) {
  return Api.post(process.env.REACT_APP_SET_USER_ACTIVE)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function createUserInSamo(data) {
  return Api.post(process.env.REACT_APP_CREATE_USER_IN_SAMO)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function getAgentLog(AgentId) {
  return Api.get(process.env.REACT_APP_GET_AGENT_LOG)
    .query({AgentId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function getUsefulInfo() {
  return Api.get(process.env.REACT_APP_USEFUL_PDF_LINK)
    .setContentType('application/octet-stream')
    .addAuthGuard()
    .send()
    .then(Api.loadFile)
}
