import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import { ReactComponent as Triangle } from "./assets/media/triangle.svg"
import { ReactComponent as Blank } from "./assets/media/blank.svg"
import insuranceCancelSrc from "../../../asset/images/avia/insurances/insurance1.svg"
import insuranceProtectionSrc from "../../../asset/images/avia/insurances/insurance2.svg"
import insuranceCovidSrc from "../../../asset/images/avia/insurances/insurance3.svg"
import insuranceDelaySrc from "../../../asset/images/avia/insurances/insurance4.svg"
import insuranceWeatherSrc from "../../../asset/images/avia/insurances/insurance5.svg"
import insuranceDoctorSrc from "../../../asset/images/avia/insurances/insurance6.svg"
import { getProductTotalSum } from "./utils"
import formatPrice from "../../../functions/formatPrice"
import labelPath from "../../../asset/images/avia/insurances/paid-label.svg"
import Collapse from '@mui/material/Collapse';


const Container = styled.div`
  /* display: flex; */
`

const Content = styled.div`
  padding-top: 4px;
  width: 100%;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const TotalPrice = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
`

const Price = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`

const List = styled.div`
  padding-top: 15px;

  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 5px 0 0 0;
  }
`

const Passenger = styled(Text)`
  text-transform: capitalize;
  margin-right: 10px;
`

const CursorPointer = styled.span`
  cursor: pointer;
  padding-left: 3px;

  & > svg {
    transition: transform .5s;
    margin-bottom: 2px;
  }

  ${(props) => props.isOpened ? `
    & > svg {
      transform: rotateX(180deg);
    }
  ` : ''}
`

const Link = styled.a.attrs({ target: "_blank" })`
  text-decoration: none;
  cursor: pointer;
`

const PassengerBlock = styled(SpaceBetween)`
  padding: 8px 0 8px 0;
  position: relative;
  background: ${({ disabled }) => (disabled ? "#fafafa" : "transparent")};

  &:after,
  &:before {
    content: "";
    top: 0;
    bottom: 0;
    position: absolute;
    background: ${({ disabled }) => (disabled ? "#fafafa" : "transparent")};
  }
  &:before {
    left: -40px;
    width: 40px;
    border-radius: 4px 0 0 4px;
  }
  &:after {
    width: 9px;
    right: -9px;
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: 767px) {
    padding: 0;
    &:before {
      left: 0;
    }
  }
`

const Row = styled.div`
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Icon = styled.div`
  width: 30px;
  height: 30px;
`

const DesktopText = styled(Text)`
  margin-right: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;

  @media (max-width: 1170px) {
    display: none;
  }
`

const Img = styled.img.attrs({ src: labelPath, alt: "label-icon" })`
  margin-left: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`

const Product = ({ children, ...props }) => {
  const [isDetailOpen, setDetail] = React.useState(false)

  const handleClick = () => setDetail(x => !x)

  return (
    <Container>
      <SpaceBetween>
        <Row>
          <Icon>
            <img
              alt="icon"
              width={30}
              height={30}
              src={getInsuranceIcon(props.name)}
            />
          </Icon>
          <Title>
            <Name>{props.name}</Name>{" "}
            <CursorPointer isOpened={isDetailOpen} onClick={handleClick}>
              <DesktopText>(детали и полиса)</DesktopText>
              <Triangle />
            </CursorPointer>
          </Title>
          <Img />
        </Row>
        <TotalPrice>{formatPrice(getProductTotalSum(props))}</TotalPrice>
      </SpaceBetween>
      <Content>
        <Collapse in={isDetailOpen}>
          <List>
            {props.policies.map((x, key) => (
              <PassengerBlock key={key} disabled={x.status === "Cancelled"}>
                <Column>
                  <Text>
                    <Passenger>
                      {x.passenger.lastName.toLowerCase()}{" "}
                      {x.passenger.firstName.toLowerCase()}{" "}
                      {x.passenger.patronymic?.toLowerCase() || ""}
                    </Passenger>{" "}
                    {x.status === "Cancelled" && (
                      <Text color="#737373">(страховка аннулирована)</Text>
                    )}
                  </Text>
                  {x.status === "Confirmed" && (
                    <Link href={x.policyUrl}>
                      <Blank style={{ transform: "translateY(2px)" }} />{" "}
                      <Text color="#4872F2">Полис страхования</Text>
                    </Link>
                  )}
                </Column>
                <Price>{formatPrice(x.price)}</Price>
              </PassengerBlock>
            ))}
          </List>
        </Collapse>
      </Content>
    </Container>
  )
}

function getInsuranceIcon(productType) {
  switch (productType) {
    case "ANTICOVID":
      return insuranceCovidSrc
    case "Защита на время полета":
      return insuranceProtectionSrc
    case "Отмена поездки":
      return insuranceCancelSrc
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return insuranceDelaySrc
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
    case "Погода":
      return insuranceWeatherSrc
    case "Врач-online в поездку":
    case "Медицинские расходы":
      return insuranceDoctorSrc;
    default:
      return insuranceDoctorSrc;
  }
}

export default Product
