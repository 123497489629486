import React from 'react';
import styledComponents from "styled-components";
// import {Link} from "react-router-dom";

const Container = styledComponents.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px 20px;	
`;

const ButtonLink = styledComponents.div`
	padding: 10px 20px;
	background: ${props => props.background}; 
	border-radius: 20px;
	color: ${props => props.color};
	cursor: pointer;
	&:hover {
		background: #238dff;
		color: #fff;
	}
`

const BubbleButtons = ({ content }) => {
	const handleClick = (link) => {
		window.open(link, '_self')
	}
	return (
		<Container className={'BubbleButtons'}>
			{content.buttons.filter(item => item.text).map((item, index) => (
				<ButtonLink 
					key={index} 
					background={item.color}
					color={item.textColor}
					onClick={() => handleClick(item.link)}>
					{item.text}
				</ButtonLink>
			))}
		</Container>
	);
};

export default BubbleButtons;