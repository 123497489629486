import React from "react"

const PositionContext = React.createContext(null)

export const useOrderPosition = () => React.useContext(PositionContext)

export const PositionContextProvider = ({ children, ...props }) => {
  return (
    <PositionContext.Provider value={props}>
      {children}
    </PositionContext.Provider>
  )
}
