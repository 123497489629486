import React, { useMemo } from 'react'
import { ReactComponent as ExcursionFiltersIcon } from '../../../../../asset/icons/ExursionFilter.svg'
import styled from 'styled-components'
import MobileFilterPopup from './MobileFilterPopup'
import { Badge } from '@mui/material'
import useExcursionStore from '../../../store'
import { isEmpty } from 'lodash'

const FilterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 48px;
	height: 48px;
	padding: 8px;
	border-radius: 8px;
	background: #f6f8fa;
`

export default function MobileFiltersBlock() {
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	
	const excursionType = useExcursionStore(({ excursionType }) => excursionType)
	const checkedExcursionType = useExcursionStore(
		({ checkedExcursionType }) => checkedExcursionType
	)

	const forWhom = useExcursionStore(({ forWhom }) => forWhom)
	const checkedForWhom = useExcursionStore(
		({ checkedForWhom }) => checkedForWhom
	)

	const activity = useExcursionStore(({ activity }) => activity)
	const checkedActivity = useExcursionStore(
		({ checkedActivity }) => checkedActivity
	)

	const count = useMemo(() => {
		if (
			isEmpty(checkedForWhom) &&
			isEmpty(checkedActivity) &&
			isEmpty(checkedExcursionType)
		) {
			return 0
		} else
			return (
				checkedForWhom?.length +
				checkedActivity?.length +
				checkedExcursionType?.length
			)
	}, [checkedActivity, checkedExcursionType, checkedForWhom])
	return (
		<>
			{(!isEmpty(excursionType) || !isEmpty(activity) || !isEmpty(forWhom)) && (
				<FilterContainer onClick={handleClickOpen}>
					<Badge badgeContent={count} color='primary' invisible={!count}>
						<ExcursionFiltersIcon />
					</Badge>
				</FilterContainer>
			)}
			<MobileFilterPopup open={open} setOpen={setOpen} />
		</>
	)
}
