import { Grid, useMediaQuery } from '@mui/material'
import { format, addDays } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import plug from '../../../../../../asset/images/whiteSq.png'

export default function FiltersNewViewSmallHotelServices({ content }) {
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	return (
		<Grid
			container
			direction={isSmallScreen ? 'row' : 'column'}
			rowSpacing={isSmallScreen ? 4 : 2}
			columnSpacing={isSmallScreen ? 4 : 2}
			style={{ marginBottom: '32px' }}>
			{content?.filters
				?.map((item, i) => (
					<ItemFilter key={i} item={item} />
				))}
		</Grid>
	)
}

const Item = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 24px;
	width: 100%;
	height: ${({ $isSmallScreen }) => ($isSmallScreen ? '88px' : '76px')};
	gap: 24px;
	background-color: #f6f8fa;
	border-radius: 12px;
	color: #2e2e32;
	font-size: ${({ $isSmallScreen }) => ($isSmallScreen ? '18px' : '16px')};
	font-weight: 600;
	line-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '26px' : '20px')};
`

const Image = styled.img`
	max-height: ${({ $isSmallScreen }) => ($isSmallScreen ? '40px' : '28px')};
	max-width: ${({ $isSmallScreen }) => ($isSmallScreen ? '40px' : '28px')};
`

const Title = styled.div`
	word-break: break-word;
`

const ItemFilter = ({ item }) => {
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	const todayDate = new Date()
	const tomorrowDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate() + 1
    )

	const startDay = format(addDays(new Date(tomorrowDate), item?.daysTomorrow ? Number(item?.daysTomorrow) : 0), 'yyyy-MM-dd')
	const endDay = format(addDays(new Date(startDay), item?.daysAfterTomorrow ? Number(item?.daysAfterTomorrow) : 0), 'yyyy-MM-dd')
	// const startDate = new Date(
	// 	todayDate.getFullYear(),
	// 	todayDate.getMonth(),
	// 	todayDate.getDate() + 14
	// )
	// const endDate = new Date(
	// 	startDate.getFullYear(),
	// 	startDate.getMonth(),
	// 	startDate.getDate() + 7
	// )

	const searchResults = () => {
		window.open(
			window.location.origin +
				`/searchhotelservices?countryId=${
					item?.country?.ebgNodeId
				}&departureCityId=${item?.city?.ebgNodeId}&startDate=${
					startDay
				}&endDate=${
					endDay
				}&adults=1&currencyId=1&hotelServiceType=${item?.hotelServiceType?.id}`
		)
	}

	return (
		<Grid item xs={3}>
			<Item $isSmallScreen={isSmallScreen} onClick={searchResults}>
				<Image
					src={item?.imgUrl || plug}
					alt={`Изображение ${item?.changedFilterName}`}
					$isSmallScreen={isSmallScreen}
				/>
				<Title>{item?.changedFilterName || item?.hotelServiceType?.name}</Title>
			</Item>
		</Grid>
	)
}
