import { Grid, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import useYachtRentServicesStore from '../../store'
import { isEmpty } from 'lodash'
import NotFindService from '../../../../components/Services/NotFindService'
import ServiceFiltersBlock from './components/ServiceFiltersBlock'
import SearchYachtRentResult from '../SearchYachtRentResult'

export default function SearchResultsWithFilters({ result }) {
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	const actions = useYachtRentServicesStore(state => state.actions)

	const excursionType = useYachtRentServicesStore(
		({ excursionType }) => excursionType
	)
	const checkedExcursionType = useYachtRentServicesStore(
		({ checkedExcursionType }) => checkedExcursionType
	)

	const actionsType = useYachtRentServicesStore(({ actionsType }) => actionsType)

	const checkedActionsType = useYachtRentServicesStore(
		({ checkedActionsType }) => checkedActionsType
	)

	const filteredExcursions = useMemo(() => {
		if (isEmpty(checkedExcursionType) && isEmpty(checkedActionsType)) {
			return result
		} else {
			return result?.filter(service => {
				const serviceType = service?.other_params?.yachtType?.map(
					item => item?.name
				)
				const actionsType = service?.other_params?.forWhomYacht?.map(
					item => item?.name
				)

				return (
					checkedExcursionType.every(item => serviceType?.includes(item)) &&
					checkedActionsType.every(item => actionsType?.includes(item))
				)
			})
		}
	}, [checkedActionsType, checkedExcursionType, result])

	console.log('ff', filteredExcursions)
	return (
		<Grid
			direction={isSmallScreen ? 'row' : 'column'}
			container
			sx={{ mt: 2, mb: 2 }}
			columnSpacing={'20px'}
			columns={26}>
			{(!isEmpty(excursionType) || !isEmpty(actionsType)) && (
				<Grid item xs={5}>
					{isSmallScreen ? <ServiceFiltersBlock /> : null}
				</Grid>
			)}
			<Grid
				item
				xs={!isEmpty(excursionType) || !isEmpty(actionsType) ? 21 : 26}>
				{isEmpty(filteredExcursions) ? (
					<NotFindService clearFilters={actions.clearFilters} />
				) : (
					<SearchYachtRentResult lists={filteredExcursions} />
				)}
			</Grid>
		</Grid>
	)
}
