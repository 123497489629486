import React from "react"
import styled from "styled-components"
import { Text } from "../ui"
import STATUS_ICON_ERROR from '../../asset/images/avia/log-history/status-icon-error.svg'
import STATUS_ICON_PAYMENT from '../../asset/images/avia/log-history/status-icon-payment.svg'
import STATUS_ICON_CHANGING from '../../asset/images/avia/log-history/status-icon-changing.svg'
import STATUS_ICON_TICKET_CREATED from '../../asset/images/avia/log-history/status-icon-ticket-created.svg'
import STATUS_ICON_PNR from '../../asset/images/avia/log-history/status-icon-pnr.svg'
import STATUS_ICON_TICKET_STATUS from '../../asset/images/avia/log-history/status-icon-ticket-status.svg'
import STATUS_ICON_CALCULATION_CREATED from '../../asset/images/avia/log-history/status-icon-calculation-created.svg'
import STATUS_ICON_EXCHANGE from '../../asset/images/avia/log-history/status-icon-exchange.svg'
import STATUS_ICON_REFUND from '../../asset/images/avia/log-history/status-icon-refund.svg'
import STATUS_ICON_CALCULATION_STATUS from '../../asset/images/avia/log-history/status-icon-calculation-status.svg'
import STATUS_ICON_ADD_SERVICE from '../../asset/images/avia/log-history/status-icon-add-service.svg'
import STATUS_ICON_PLANE from '../../asset/images/avia/log-history/status-icon-plane.svg'
import STATUS_ICON_CHECKED from '../../asset/images/avia/log-history/status-icon-checked.svg'
import moment from "moment"

const Wrapper = styled.div``

const Item = styled.div`
  border-bottom: 1px solid #dcdcdc;
  padding: 14px 0;
  display: flex;
  align-items: center;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background-color: gray;
  margin-right: 15px;
`

const Date = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-right: 14px;
`

const Description = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`

const MobileContainer = styled.div`
  margin-top: -4px;
  display: flex;
  @media (max-width: 1024px) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
`

const ImageSkeleton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #b9b9b9;
  margin-right: 15px;
`

const DateSkeleton = styled.div`
  width: 112.5px;
  height: 18px;
  border-radius: 4px;
  background-color: #b9b9b9;
  margin-right: 14px;
  @media (max-width: 1024px) {
    margin-bottom: 3px;
  }
`

const DescriptionSkeleton = styled.div`
  width: 300px;
  height: 18px;
  border-radius: 4px;
  background-color: #b9b9b9;
`

const LogHistory = ({ items, ...props }) => {
  return (
    <Wrapper {...props}>
      {items.length === 0
        ? Array(3)
            .fill("")
            .map((_, key) => (
              <Item key={key}>
                <ImageSkeleton />
                <MobileContainer>
                  <DateSkeleton />
                  <DescriptionSkeleton />
                </MobileContainer>
              </Item>
            ))
        : items.map((item, key) => (
            <Item key={key}>
              <Icon src={getIconPathByAction(item)} />
              <MobileContainer>
                <Date>{moment(item.dateTime).format("DD-MM-YYYY HH:mm")}</Date>
                <Description>{item.description2 || item.description }</Description>
              </MobileContainer>
            </Item>
          ))}
    </Wrapper>
  )
}

function getIconPathByAction(data) {
  switch(data.actionType) {
    case 'QueuePnr_ChangeStopOvers':
    case 'SupportTicket_ChangeDates':
    case 'UnsuccessfulIssueOrderPosition':
    case 'InsuranceFailedIssue':
    case 'QueuePnr_ChangeDates':        
      return STATUS_ICON_ERROR;
    case 'PaymentNotified':  
    case 'Payment':
      return STATUS_ICON_PAYMENT;
    case 'RewritePnr':
    case 'UpdatePnr_ChangeTariff':
    case 'UpdatePnr_ChangeAmount':
    case 'UpdatePnr_Remarks':
    case 'UpdatePnr_ChangePassengers':
    case 'UpdatePnr_ChangeAncillaryServices':
    case 'ChangeInsurancePolicyStatus':
    case 'ChangeRoleUserAgent':
    case 'BlockAllowUserAgent':
    case 'UpdatePnr':
    case 'AddMarginCalculation':
      return STATUS_ICON_CHANGING;
    case 'IssueOrderPosition':
      return STATUS_ICON_PNR;
    case 'RefundAncillaryService':
    case 'FullRefund':
    case 'Refund':
      return STATUS_ICON_REFUND;
    case 'PartiallyExchange':
    case 'FullExchange':
      return STATUS_ICON_EXCHANGE;
    case 'AddSupportTicket': 
      return STATUS_ICON_TICKET_CREATED;
    case 'UpdateSupportTicketStatus': 
      return STATUS_ICON_TICKET_STATUS;
    case 'AddSupportTicketCalculation': 
      return STATUS_ICON_CALCULATION_CREATED;
    case 'UpdateSupportTicketCalculationStatus': 
      return STATUS_ICON_CALCULATION_STATUS; 
    case 'AddAncillaryService':
    case 'SmsServiceAdded':
    case 'AdditionalServiceAdded':    
      return STATUS_ICON_ADD_SERVICE;
    case 'CreateOrder':
    case 'QueueCreated':
      return STATUS_ICON_PLANE;                        
    default: 
      return STATUS_ICON_CHECKED;    
  }
}

export default LogHistory
