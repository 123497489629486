import React from 'react';
import { SvgIcon } from "@mui/material";

function Requirement(props) {
    return <SvgIcon {...props} viewBox="0 0 18 18" fill="none" style={{ width: "18px", height: "18px" }} >
        <g clipPath="url(#clip0_930_14624)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 16.875C11.0886 16.875 13.0916 16.0453 14.5685 14.5685C16.0453 13.0916 16.875 11.0886 16.875 9C16.875 6.91142 16.0453 4.90838 14.5685 3.43153C13.0916 1.95468 11.0886 1.125 9 1.125C6.91142 1.125 4.90838 1.95468 3.43153 3.43153C1.95468 4.90838 1.125 6.91142 1.125 9C1.125 11.0886 1.95468 13.0916 3.43153 14.5685C4.90838 16.0453 6.91142 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z" fill="#3C3C3C" />
            <path d="M7.8772 12.375C7.8772 12.2273 7.9063 12.081 7.96283 11.9445C8.01937 11.808 8.10224 11.684 8.2067 11.5795C8.31117 11.475 8.43519 11.3922 8.57168 11.3356C8.70817 11.2791 8.85446 11.25 9.0022 11.25C9.14993 11.25 9.29623 11.2791 9.43272 11.3356C9.56921 11.3922 9.69323 11.475 9.79769 11.5795C9.90216 11.684 9.98503 11.808 10.0416 11.9445C10.0981 12.081 10.1272 12.2273 10.1272 12.375C10.1272 12.6734 10.0087 12.9595 9.79769 13.1705C9.58671 13.3815 9.30057 13.5 9.0022 13.5C8.70383 13.5 8.41768 13.3815 8.2067 13.1705C7.99572 12.9595 7.8772 12.6734 7.8772 12.375ZM7.98745 5.61938C7.97247 5.47746 7.98749 5.33396 8.03155 5.19822C8.0756 5.06247 8.14771 4.9375 8.24318 4.83142C8.33865 4.72534 8.45536 4.64052 8.58573 4.58245C8.71611 4.52439 8.85723 4.49438 8.99995 4.49438C9.14266 4.49438 9.28379 4.52439 9.41416 4.58245C9.54453 4.64052 9.66124 4.72534 9.75671 4.83142C9.85219 4.9375 9.92429 5.06247 9.96835 5.19822C10.0124 5.33396 10.0274 5.47746 10.0124 5.61938L9.6187 9.56476C9.60547 9.71975 9.53455 9.86414 9.41997 9.96935C9.3054 10.0746 9.1555 10.1329 8.99995 10.1329C8.84439 10.1329 8.6945 10.0746 8.57992 9.96935C8.46534 9.86414 8.39443 9.71975 8.3812 9.56476L7.98745 5.61938Z" fill="#3C3C3C" />
        </g>
        <defs>
            <clipPath id="clip0_930_14624">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>

}

export default Requirement
