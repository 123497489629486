import React, { useState } from 'react';
import emptyImage from '../../../asset/images/hotelRoomPlug.svg'
import { isEmpty } from "lodash";
import Square from "../../../asset/icons/Square";
import HotelView from "../../../asset/icons/Hotel-view";
import HotelBed from "../../../asset/icons/Hotel-bed";
import RoomPopup from "./RoomPopup";
import { useMediaQuery } from 'react-responsive'

const RoomCard = ({ room, images, objectType, hotelId, amenities }) => {

	const [open, setOpen] = useState(false)
	const isMobile = useMediaQuery({maxWidth: 576})
	const createLinkToImage = (data) => {
		if (isEmpty(images)) {
			return emptyImage
		} else if (data) {
			return `https://apigate-tui.fstravel.com/api/geocontent/static/${objectType}/${hotelId}/${data?.imageType}-${data?.resolutionType}-${data?.fileName}`
		} else {
			return emptyImage
		}
	}

	return (
		<div className={'room-card'}>
			<div className={'room-card__images'} onClick={() => setOpen(true)}>
				{!isMobile && <> 
					<div className={`room-card__images-big ${images.length < 3 ? 'one-image' : ''} `}>
						<img src={createLinkToImage(images.find(item => item.id === room.images[0].id))} alt={room.name} />
					</div>
					{images.length > 2 && <div className={'room-card__images-small'}>
						<img src={createLinkToImage(images.find(item => item.id === room.images[1].id))} alt={room.name} />
						<img src={createLinkToImage(images.find(item => item.id === room.images[2].id))} alt={room.name} />
					</div>}
				</>}
				{isMobile && <> 
					<div className={'room-card__images-mobile'}>
						<img style={{width: '100%', height: '100%', borderRadius: '12px 0 0 12px'}} src={createLinkToImage(images.find(item => item.id === room.images[0]?.id))} alt={room.name} />
					</div>
					<div className={'room-card__images-mobile'}>
						<img style={{width: '100%', height: '100%', borderRadius: '0 12px 12px 0'}}  src={createLinkToImage(images.find(item => item.id === room.images[1]?.id))} alt={room.name} />
					</div>
				</>}
			</div>
			<div className={'room-card__description'}>
				<div className={'room-card__name'}>
					{room.name}
				</div>
				<div className={'room-card__description-info'}>
					{!!room.square && <div className={'square'}><Square />{room.square}м<sup>2</sup></div>}
					{!!room.view?.id && <div><HotelView />{room.view?.name}</div>}
					{!!room.numberOfBeds && <div><HotelBed />Максимальное размещение: {room.numberOfBeds}</div>}
				</div>
				{(!isMobile && (!!room.square || !!room.view?.name || !!room.numberOfBeds || !isEmpty(images) || !isEmpty(room.amenities))) &&
					<div className={'room-card__more'} onClick={() => setOpen(true)}>
						Ещё о номере
					</div>
				}
			</div>
			<div className={'room-card__includes'}>
				{room.amenities.map(item => {
					const ametity = amenities.find(a => a.id === item.id)
					if (!ametity) return null
					return (
						<span key={item.id}>{ametity.name} </span>
					)
				}
				)}
				{(isMobile && (!!room.square || !!room.view?.name || !!room.numberOfBeds || !isEmpty(images) || !isEmpty(room.amenities))) &&
					<div className={'room-card__more'} onClick={() => setOpen(true)}>
						Ещё о номере
					</div>
				}
			</div>
			{open &&
				<RoomPopup
					open={open}
					handleClose={() => setOpen(false)}
					room={room}
					images={images}
					hotelId={hotelId}
					objectType={objectType}
					amenities={amenities}
				/>
			}
		</div>
	);
};

export default RoomCard;