import React from "react"
import AgentInfoBlock from "../components/avia-agents/agent-finance/AgentInfoBlock"
import styled from "styled-components"
import { Navigation} from '../components/ui'
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom"
import { getAgentMainInformation } from "../store/modules/agents/agent-finance/duck"
import {Outlet} from 'react-router-dom'
import {getUserDataState} from "../store/modules/user";

const Container = styled.div`

  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 50px;
  }
`

const StyledNavigation = styled(Navigation)`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
`

const navigation = [
  { name: "Финансовая информация", path: `/cabinet/avia-agent-info` },
  {
    name: "Настройки правил и сборов",
    path: `/cabinet/avia-agent-info/fees`,
    subroutes: ['/cabinet/avia-agent-info/rules']
  },
  // {
  //   name: "Пользователи агента",
  //   path: `/cabinet/avia-agent-info/users`
  // },
  {
    name: "История действий",
    path: `/cabinet/avia-agent-info/history`
  }
]

const AgentLayout = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector(getUserDataState);
  const canView = user?.role !== 'ViewManager';

  React.useEffect(() => {
    dispatch(getAgentMainInformation(location.pathname))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      {canView
        ? <Container>
            <AgentInfoBlock />
            <StyledNavigation items={navigation} />
            <Outlet/>
          </Container>
        : <h1>Данный раздел вам не доступен</h1>
      }
    </>
  )
}

export default AgentLayout
