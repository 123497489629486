import React from 'react';
import styled from 'styled-components';
import { Text, Header } from '../ui/Typography';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'
import PenaltyDescription from "./components/PenaltyDescription";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px 0 ;
    border-radius: 8px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      border-radius: 0;
      padding: 20px;
    }
  }
`;

const PopupFieldsWrapper = styled.div`
  padding: 0 40px;
  overflow: auto;

  &::-webkit-scrollbar {
      width: 2.786px;
  }
  &::-webkit-scrollbar-thumb {
      background: #4872f2;
      border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
      background: #E9EAEC;
      width: 2.786px;
      border-radius: 2px;
  }

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 35px;
  }
`;

const FareDescription = styled(Text)`
  white-space: pre-line;
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 40px;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;


const FareConditionModal = ({
  fare,
  routes,
  open,
  onClose,
}) => {
  const [routeIndex, setRoute] = React.useState(0);

  React.useEffect(() => {
    setRoute(0);
  }, [routes]);

  return (
    <StyledPopup open={open} onClose={onClose}>
      <PopupHead>
        <Header size="h3">Условия тарифа</Header>
        <CloseButton
          aria-label="close"
          onClick={(e)=>{
            e.preventDefault();
            typeof onClose === 'function' && onClose();
          }}
        >
          <Icon/>
        </CloseButton>
      </PopupHead>

      <PopupFieldsWrapper>
        <PenaltyDescription />
        <Text size="big">
          <Text size="big" fontWeight="600">Тариф:{' '}</Text>
          {fare}
        </Text>
        <FareDescription dangerouslySetInnerHTML={{
          __html: routes[routeIndex]?.description || '',
        }}>
        </FareDescription>
      </PopupFieldsWrapper>
    </StyledPopup>
  );
};

export default FareConditionModal;
