import React from 'react';
import styled from 'styled-components';
import RegularGDSContacts from './contactBlocks/RegularGDSContacts';
import PremiumContacts from './contactBlocks/PremiumContacts';
import CharterPopularContacts from './contactBlocks/CharterPopularContacts/CharterPopularContacts';

const Title = styled.div`
    font-family: Open Sans;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2e2e32;

    margin-bottom: 40px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 24px;
    }
`;

const Container = styled.div`
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        padding: 24px 16px;
    }
`;
export default function CuratorContacts() {
    return (
        <Container>
            <Title>Контакты кураторов</Title>
            <CharterPopularContacts />
            <RegularGDSContacts />
            <PremiumContacts />
        </Container>
    );
}
