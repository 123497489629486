import React from 'react';
import useBookingStore from "../../store";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const Tourists = ({onSelected, touristId, seats, onDelete}) => {

	const paxes = useBookingStore(state => state.peoples.filter(pax => pax.age !==3))
	const boarding = useBookingStore(({ boarding }) => boarding)

	const handleDeleteSeat = (e) => {
		onDelete(e.currentTarget.id)
	}

	return (
		<div className="block-body">
			<div className="grid-boarding header">
				<div>Турист</div>
				<div>Место</div>
				<div></div>
			</div>
			{paxes.map(pax => {
				const key = seats.find(item => item.id === pax.key)?.seats?.seatKey
				const seat = boarding.find(item => item.key === key)
				return (
					<div
						className={`grid-boarding body ${pax.id === touristId ? `selected` : ''}`}
						onClick={() => onSelected(pax.key)}
					>
						<div>
							{pax.lname || pax.name ? (`${pax.lname || pax.name}`) : `NO NAME ${pax.id}`}
						</div>
						{seat && <div>
							<strong>{seat?.cellName}</strong>
							<em> +{seat?.price} {seat?.currencyAlias} {seat?.groupName} {seat?.typeName}</em>
						</div>
						}
						{seat && <div>
							<DeleteForeverOutlinedIcon id={pax.key} onClick={handleDeleteSeat} />
						</div>}
					</div>)
				}
			)}
		</div>
	);
};

export default Tourists;