import './styles.scss'

const HotelRow = ({ item, hotelIds, changeStatus }) => {
	const handleLink = (e) => {
		e.preventDefault()
		window.open(`/hotel/${item.id}`, "_blank")
	}

	return (
		<div>
			<label
				htmlFor={item?.id}
				style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
				title={item?.star ? item?.name + ' ' + item?.star : item?.name}>
				<input
					id={item?.id}
					type='checkbox'
					checked={hotelIds?.includes(item?.id.toString())}
					onChange={() => {
						changeStatus(item?.id.toString())
					}}
				/>
				{item?.icon ? item?.icon : <span style={{ marginRight: 5 }}></span>}
				<span className='hotel-name' onClick={handleLink}>{item?.name} {item?.star}</span>
			</label>
		</div>
	)
}

export default HotelRow
