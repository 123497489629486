import React from "react"
import styled from "styled-components"
import Flight from "./Flight"

const Wrapper = styled.div`
  & > div {
    margin-bottom: 25px;
    ${({ withSeparator }) =>
      withSeparator
        ? `padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;`
        : ""}
  }
  & > :last-child {
    margin-bottom: 0;
    ${({ withSeparator }) =>
      withSeparator
        ? `padding-bottom: 0;
        border-bottom: none;`
        : ""}
  }
`

const FlightList = ({ items, withSeparator }) => (
  <Wrapper withSeparator={withSeparator}>
    {items.map((item, key) => (
      <Flight key={key} {...item} />
    ))}
  </Wrapper>
)

export default FlightList
