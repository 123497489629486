import React from 'react'
import { ReactComponent as LocationIcon } from '../../../../../asset/icons/locationHeader.svg'
import { ReactComponent as ArrowIcon } from '../../../../../asset/icons/arrow-right.svg'
import useGeoStore from '../../../store'
import { useNavigate } from 'react-router'
import '../style.css'
import { format } from 'date-fns'

export default function CountryInput() {
	const content = useGeoStore(({ content }) => content)
	const navigate = useNavigate()
	const searchTour = () => {
		const currentDate = new Date()
		const endDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate() + 7
		)
		navigate(
			`/search_tour?clientId=b2b%3Aru&lang=ru&departureCityId=274286&arrivalCountryId=${
				content.countryId
			}&adults=2&minStartDate=${format(
				endDate,
				'yyyy-MM-dd'
			)}&maxStartDate=${format(
				endDate,
				'yyyy-MM-dd'
			)}&minNightsCount=7&maxNightsCount=7&currencyId=1&noStopOfSales=true&withoutPromo=false&hideToursWithoutFreights=true&momentConfirm=false&includePriorityFilter=false&searchScope=b2b${
				content?.resortId ? `&arrivalRegionIds%5B0%5D=${content.resortId}` : ''
			}${content.cityId ? `&arrivalCityIds%5B0%5D=${content.cityId}` : ''}`
		)
	}
	if (!content) return null
	// console.log('content', content)

	return (
		<div className='v-banner-where-field'>
			<div className='v-icon-country-location'>
				<LocationIcon className='icon-location' />
			</div>
			<div className='v-city-select'>
				<div className='v-select__wrap'>
					<div className='v-input-country__wrap'>
						<input
							className='v-input-country'
							placeholder='Москва'
							type='text'
							value={content?.cityFromName}
							disabled
						/>
					</div>
				</div>
			</div>
			<div className='v-banner-icon' onClick={searchTour}>
				<ArrowIcon className='v-icon-arrow-right' />
			</div>
		</div>
	)
}
