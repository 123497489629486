import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';

/** Flight Search */

//Actions

export const search = createAction(
  '@search-result/search'
);

export const setFlightSearchStatus = createAction('@search-result/status');
export const setFlightSearchError = createAction('@search-result/error')


export const setFlightSearchData = createAction(
  '@search-result/data'
);

export const purifySearchState = createAction('@search-result/purify');

//Filter actions
export const setTimeOneWayFilter = createAction('@search-result/filter/time-one-way');

export const setTransferDurationFilter = createAction(
  '@search-result/filter/transfer-duration'
);

export const setTransferFilter = createAction(
  '@search-result/filter/transfer'
);
export const setPricesFilter = createAction(
  '@search-result/filter/price'
);

export const setFlightTypeFilter = createAction(
  '@search-result/filter/flight-type'
);
export const setAirportFilter = createAction(
  '@search-result/filter/airport'
);
export const setAirlineFilter = createAction(
  '@search-result/filter/airlines'
);
export const setTimeFilter = createAction('@search-result/filter/time');

export const setBaggageFilter = createAction(
  '@search-result/filter/baggage'
);
export const resetFilter = createAction('@search-result/filter/reset');

export const runFilter = createAction('@search-result/filter/run');

export const getNext = createAction('@search-result/getNext');

export const setList = createAction('@search-result/setList');
export const purifyList = createAction('@@flightSearch/purifyList');


const loadStatus = createReducer({},{
  code: 'Idle',
  error: null,
});


loadStatus.on(setFlightSearchStatus,(_,code) => ({code,error: null}))
loadStatus.on(setFlightSearchError,(_,error) => ({code: 'Failure',error: error}))


export const flightSearch = createReducer({}, {filter: {
  isFiltered: false,
}});

const list = createReducer(
  {},
  { items: [], visibleItems: [], page: 1, pageCount: 1 }
);
list.on(setList, (_, payload) => payload);

flightSearch.on(purifySearchState, () => ({filter: {
  isFiltered: false,
}}));


flightSearch.on(setFlightSearchData, (state, payload) => {
  return { ...state, ...payload };
});

flightSearch.on(setBaggageFilter, (s, payload) => {
  const state = JSON.parse(JSON.stringify(s))
  const isDefault = !Object.entries(payload).reduce(
    (prev, [_, val]) => prev && val,
    true
  );
  Object.entries(payload).forEach(([key, val]) => {
    state.filter.baggage[parseInt(key)].checked = val;
  });
  state.filter.isDefault.baggage = isDefault;
  return state;
});

flightSearch.on(setFlightTypeFilter, (state, payload) => {
  const isDefault = payload.length === 0;
  const newState = {...state, filter : {...state.filter,values: {...state.filter.values,flightTypes : payload},isDefault: {...state.filter.isDefault,flightTypes: isDefault}}};
  return { ...newState };
});

flightSearch.on(setTimeFilter, (s, payload) => {
  const state = JSON.parse(JSON.stringify(s))
  const newFilterTime = state.filter.time
  newFilterTime[payload.index][payload.type].values = payload.values;

  const isDefault = state.filter.time.reduce((prev, next) => {
    return (
      prev &&
      next.from.min === next.from.values[0] &&
      next.from.max === next.from.values[1] &&
      next.to.min === next.to.values[0] &&
      next.to.max === next.to.values[1]
    );
  }, true);

  return {
    ...state,
    filter: {
      ...state.filter,
      time: newFilterTime,
      isDefault: {
        ...state.filter.isDefault,
        time: isDefault,
      },
    },
  };
});

flightSearch.on(setAirlineFilter, (state, payload) => {
  const isFiltered =
    Object.entries(payload).find(([_, val]) => val) === undefined;

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        airlines: isFiltered,
      },
      values: {
        ...state.filter.values,
        airlines: payload,
      },
    },
  };
});

flightSearch.on(setTimeOneWayFilter, (s, payload) => {

  const state = JSON.parse(JSON.stringify(s));

  const vals = state.filter.flightsDurationsList;
  state.filter.values.flightsDuration[payload.index] = payload.values
  const isDefault = state.filter.values.flightsDuration.reduce(
    (acc, x, index) =>
      acc && x[0] === vals[index].min && x[1] === vals[index].max,
    true
  );

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: { ...state.filter.isDefault, flightDuration: isDefault },
    },
  };
});

flightSearch.on(setTransferDurationFilter, (s, payload) => {
  const state = JSON.parse(JSON.stringify(s));
  const isDefault =
    payload[0] === state.filter.transferDuration.min &&
    payload[1] === state.filter.transferDuration.max;

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: { ...state.filter.isDefault, transferDuration: isDefault },
      values: {
        ...state.filter.values,
        transferDuration: [...payload],
      },
    },
  };
});

flightSearch.on(setTransferFilter, (s, payload) => {
  const state = JSON.parse(JSON.stringify(s))
  const isDefault = !Object.entries(payload).reduce(
    (prev, [_, val]) => prev && val,
    true
  );

  return {
    ...state,
    filter: {
      ...state.filter,
      transfers: {
        ...state.filter.transfers,
        list: state.filter.transfers.list.map((el) => {
          return { ...el, checked: payload[el.val] };
        }),
      },
      values: {
        ...state.filter.values,
        transfer: Object.entries(payload)
          .filter(([_, val]) => val)
          .map(([key]) => parseInt(key)),
      },
      isDefault: {
        ...state.filter.isDefault,
        transfers: isDefault,
      },
    },
  };
});

flightSearch.on(setPricesFilter, (state, payload) => {
  const isDefault =
    state.filter.prices.max === payload[1] &&
    state.filter.prices.min === payload[0];
  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        prices: isDefault,
      },
      values: {
        ...state.filter.values,
        prices: [...payload],
      },
    },
  };
});

flightSearch.on(setAirportFilter, (state, payload) => {
  let isDefault = true;

  const airports = state.filter.airports.map((el, key) => {
    return {
      ...el,
      airports: el.airports.map((airport) => {
        if (payload[key][airport.code]) {
          isDefault = false;
        }

        return { ...airport, checked: payload[key][airport.code] };
      }),
    };
  });

  return {
    ...state,
    filter: {
      ...state.filter,
      airports: airports,
      isDefault: { ...state.filter.isDefault, airport: isDefault },
    },
  };
});

flightSearch.on(resetFilter, (s, payload) => {
  const state = JSON.parse(JSON.stringify(s));

  if (payload === 'airport') {
    state.filter.isDefault.airport = true;
    const isFiltered =
      Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
      undefined;

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered,
        isDefault: { ...state.filter.isDefault },

        airports: state.filter.airports.map((el) => {
          return {
            ...el,
            airports: el.airports.map((airport) => {
              return { ...airport, checked: false };
            }),
          };
        }),
      },
    };
  } else if (payload === 'airlines') {
    state.filter.isDefault.airlines = true;
    const isFiltered =
      Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
      undefined;
    Object.entries(state.filter.values.airlines).forEach(([key, val]) => {
      state.filter.values.airlines[key] = false;
    });

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered,
        values: { ...state.filter.values },
        isDefault: { ...state.filter.isDefault },
      },
    };
  } else if (payload === 'transfers') {
    state.filter.isDefault.transfers = true;

    state.filter.values.transfer = [];

    state.filter.transfers = {
      ...state.filter.transfers,

      list: state.filter.transfers.list.map((el) => {
        return { ...el, checked: false };
      }),
    };

    return { ...state };
  } else if (payload === 'prices') {
    state.filter.isDefault.prices = true;
    state.filter.values.prices = [
      state.filter.prices.min,
      state.filter.prices.max,
    ];

    return { ...state };
  } else if (payload === 'flightDuration') {
    state.filter.isDefault.flightDuration = true;
    state.filter.values.flightsDuration = state.filter.flightsDurationsList.map(
      (el) => [el.min, el.max]
    );

    return { ...state };
  } else if (payload === 'time') {
    state.filter.isDefault.time = true;
    state.filter.time = state.filter.time.map((group) => {
      return {
        ...group,
        from: { ...group.from, values: [group.from.min, group.from.max] },
        to: { ...group.to, values: [group.to.min, group.to.max] },
      };
    });

    return { ...state };
  } else if (payload === 'transferDuration') {
    state.filter.isDefault.transferDuration = true;
    state.filter.values.transferDuration = [
      state.filter.transferDuration.min,
      state.filter.transferDuration.max,
    ];

    return { ...state };
  } else if (payload === 'flightTypes') {
    state.filter.isDefault.flightTypes = true;
    state.filter.values.flightTypes = [];

    return { ...state };
  } else if (payload === 'baggage') {
    state.filter.isDefault.baggage = true;
    state.filter.baggage = state.filter.baggage.map((f) => {
      return { ...f, checked: false };
    });

    return { ...state };
  } else {
    Object.entries(state.filter.values.airlines).forEach(([key, val]) => {
      state.filter.values.airlines[key] = true;
    });

    state.filter.values = {
      ...state.filter.values,
      prices: [state.filter.prices.min, state.filter.prices.max],
      flightsDuration: state.filter.flightsDurationsList.map((el) => [
        el.min,
        el.max,
      ]),
      flightTypes: [],
      transferDuration: [
        state.filter.transferDuration.min,
        state.filter.transferDuration.max,
      ],
      transfer: [],
    };

    Object.keys(state.filter.isDefault).forEach((key) => {
      state.filter.isDefault[key] = true;
    });

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered: false,
        isDefault: { ...state.filter.isDefault },
        transfers: {
          ...state.filter.transfers,
          list: state.filter.transfers.list.map((el) => {
            return { ...el, checked: true };
          }),
        },

        baggage: state.filter.baggage.map((f) => {
          return { ...f, checked: true };
        }),

        time: state.filter.time.map((group) => {
          return {
            ...group,
            from: { ...group.from, values: [group.from.min, group.from.max] },
            to: { ...group.to, values: [group.to.min, group.to.max] },
          };
        }),

        airports: state.filter.airports.map((el) => {
          return {
            ...el,
            airports: el.airports.map((airport) => {
              return { ...airport, checked: true };
            }),
          };
        }),
      },
    };
  }
});

flightSearch.on(runFilter, (state) => {

  const {
    airports,
    airlinesTickets,
    transfers,
    time,
    values: { prices, transferDuration, transfer, airlines, flightTypes },
    ...arrs
  } = state.filter;

  const isFiltered =
    Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
    undefined;

  const isBaggageDefault = state.filter.baggage.reduce(
    (acc, x) => acc && x.checked === false,
    true
  );

  const checkedBaggageType = state.filter.baggage.find(
    (item) => item.checked
  ) || { checked: true, label: '' };

  const itemBaggageFiltration = (key) => {
    const item = state.filter.baggageFilter[key];

    // if (isBaggageDefault) {
    //   return true;
    // }
    if (item === null) {
      return true;
    } else {
      if (isBaggageDefault) {
        return true;
      }
      if (checkedBaggageType.label === 'C багажом') {
        return item;
      } else if (checkedBaggageType.label === 'Без багажа') {
        return !item;
      } else {
        return false;
      }
    }
  };

  let keys = state.filter.flightsDurationsList[0].tickets
    .map((_, key) => key)
    .filter((key) => {
      // flightType
      const flightTypeFilter = flightTypes.length > 0 ? flightTypes.includes(arrs.flightTypes[key]) : true;

      //prices
      const pricesFilter = arrs.prices.tickets[key] >= prices[0] && arrs.prices.tickets[key] <= prices[1];

      //transferDuration
      const transferDurationFilter = arrs.transferDuration.tickets[key] >= transferDuration[0] && arrs.transferDuration.tickets[key] <= transferDuration[1];

      //airlines
      const airlinesFilter = Object.values(airlines).find(x => x) ? airlinesTickets[key].some((val) => val.some((v) => airlines[v])) : true;

      //transfer
      const transferFilter = transfer.length > 0 ? transfer.find((val) => transfers.tickets[key] === val) !== undefined : true;


      const firstExpression =
        pricesFilter && transferDurationFilter && flightTypeFilter &&
        itemBaggageFiltration(key) && airlinesFilter && transferFilter;

      if (firstExpression) {
        let secondExpression = true;

        time.forEach((group) => {
          const { from, to } = group;
          secondExpression =
            secondExpression &&
            from.values[0] <= from.tickets[key] &&
            from.values[1] >= from.tickets[key] &&
            to.values[0] <= to.tickets[key] &&
            to.values[1] >= to.tickets[key];
        });

        return firstExpression && secondExpression;
      } else {
        return false;
      }
    });
  state.filter.flightsDurationsList.forEach((flight, key) => {
    const values = state.filter.values.flightsDuration[key];
    keys = flight.tickets
      .map((val, key) => {
        return { val, key };
      })
      .filter((item, key) => {
        return (
          keys.find((k) => k === key) !== undefined &&
          item.val >= values[0] &&
          item.val <= values[1]
        );
      })
      .map(({ key }) => key);
  });

  airports.forEach((el) => {
    if (Object.values(el.airports).find(x => x.checked)) {
      keys = el.tickets
        .map((val, key) => {
          return { val, key };
        })
        .filter(({ val }, key) => {
          return (
            keys.find((k) => k === key) !== undefined &&
            el.airports.find(({ code, checked }) => code === val && checked)
          );
        })
        .map(({ key }) => key);
    }
  });

  return {
    ...state,
    flightsList: {
      ...state.flightsList,
      items: state.flightsList.notFilteredItems.filter(
        (_, key) => keys.find((k) => k === key) !== undefined
      ),
    },
    filter: {
      ...state.filter,
      isFiltered,
      isDefault: { ...state.filter.isDefault },
    },
  };
});



/** Combine Reducers */
export const searchResultReducer = combineReducers({
  loadStatus,
  flightSearch,
  ticketList: list,
});
