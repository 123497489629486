import React, {useState} from 'react';
import './style.css'
import { format } from "date-fns";
import HotelSelect from "./HotelSelect";
import useBookingStore from "../../store";
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from 'lodash';

const HotelBlock = ({ hotel, hotels, variants }) => {

	const cityHotel = variants.filter(item => item.townKey === hotels[0].townKey)
	const beachHotels = variants.filter(item => item.townKey === hotels[1].townKey)
	const actions = useBookingStore(({ actions }) => actions)

	return (
		<>
			<div className="block-header">
				<h3 className="title">Проживание</h3>
			</div>
			<div className="block-body">
				<div className="grid-hotel header">
					<div>Гостиница</div>
					<div>Город</div>
					<div>Номер</div>
					<div>Размещение</div>
					<div>Питание</div>
					<div>Период проживания</div>
				</div>
				{cityHotel.length === 0 && <div className="grid-hotel body">
					<div data-label="Гостиница"><a target={'_blank'} rel={'noreferrer'} style={{ textDecoration: 'none', color: 'inherit' }}
						href={`/hotel/${hotels[0].uid}`}>{hotels[0].name} {hotels[0].star}</a>
					</div>
					<div data-label="Город">{hotels[0].town}</div>
					<div data-label="Номер">{hotels[0].room}</div>
					<div data-label="Размещение">{hotels[0].htplace}</div>
					<div data-label="Питание">{hotels[0].meal}</div>
					<div data-label="Период проживания">
						{format(new Date(hotels[0].datebeg), 'dd.MM.yyyy')} - {format(new Date(hotels[0].dateend), 'dd.MM.yyyy')}
					</div>
				</div>}
				{!isEmpty(cityHotel) && cityHotel.length > 1 && <div style={{ display: 'flex', padding: '10px 0px', alignItems: 'center', justifyContent: "space-between" }}>
					<div style={{ width: 900, display: 'flex', alignItems: 'center' }}>
						<HotelSelect
							value={hotels[0]}
							options={cityHotel}
							onChange={actions.changeSelectedCityHotels}
						/>
						<a target={'_blank'} rel={'noreferrer'} style={{ textDecoration: 'none', color: 'inherit' }} href={`/hotel/${hotels[0].key}`}><InfoIcon style={{ marginLeft: 10 }} fontSize={'small'} /></a>
					</div>
					<div style={{ marginRight: 35 }}>{format(new Date(hotels[0].datebeg), 'dd.MM.yyyy')} - {format(new Date(hotels[0].dateend), 'dd.MM.yyyy')}</div>
				</div>}
				{!isEmpty(beachHotels) && !!beachHotels.length && <div style={{ display: 'flex', padding: '10px 0px', alignItems: 'center', justifyContent: "space-between" }}>
					<div style={{ width: 900, display: 'flex', alignItems: 'center' }}>
						<HotelSelect
							value={hotels[1]}
							options={beachHotels}
							onChange={actions.changeSelectedBeachHotels}
						/>
						<a target={'_blank'} rel={'noreferrer'} style={{ textDecoration: 'none', color: 'inherit' }} href={`/hotel/${hotels[1].key}`}><InfoIcon style={{ marginLeft: 10 }} fontSize={'small'} /></a>
					</div>
					<div style={{ marginRight: 35 }}>{format(new Date(hotels[1]?.datebeg), 'dd.MM.yyyy')} - {format(new Date(hotels[1]?.dateend), 'dd.MM.yyyy')}</div>
				</div>}
			</div>
		</>
	);
};

export default HotelBlock;