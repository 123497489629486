import { useState } from "react";
import useBookingStore from "../../store";
import { format, parseISO } from "date-fns";
import { Checkbox, Button, FormControlLabel } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import "./style.css";

function ServiceModal({ services, onClose, title, grouped = false }) {
  const paxes = useBookingStore((state) => state.paxes);
  const actions = useBookingStore(({ actions }) => actions);
  const [selectedService, setService] = useState(
    paxes.map((paxe) => {
      return { id: paxe.id, selectedServiceIds: paxe.selectedServiceIds };
    })
  );

  const handleChange = (id) => {
    const newState = selectedService.map((item) => {
      if (item.selectedServiceIds.includes(id)) {
        const filteredItem = item.selectedServiceIds.filter((i) => i !== id);
        return { ...item, selectedServiceIds: filteredItem };
      }
      item.selectedServiceIds.push(id);
      return item;
    });
    setService(newState);
  };

  const handleExcursionChange = (id, count) => {
    const newState = [...selectedService];
    const existPaxeIndex = newState.findIndex((i) => i.id === count);
    const existPaxe = newState[existPaxeIndex];

    if (newState[existPaxeIndex].selectedServiceIds.includes(id)) {
      const filteredServices = newState[
        existPaxeIndex
      ].selectedServiceIds.filter((i) => i !== id);

      newState[existPaxeIndex].selectedServiceIds = filteredServices;

      setService(newState);
      return;
    }

    existPaxe.selectedServiceIds.push(id);
    newState[existPaxeIndex] = existPaxe;

    setService(newState);
  };

  const onSave = () => {
    actions.updateServices(selectedService);
    onClose();
  };

  const renderGrouped = () => {
    const groupedServices = Object.values(
      services.reduce((acc, item) => {
        const typeId = item.type.id;
        const typeName = item.type.name;
        if (!acc[typeId]) {
          acc[typeId] = {
            id: typeId,
            name: typeName,
            items: [],
          };
        }
        acc[typeId].items.push(item);
        return acc;
      }, {})
    );

    return (
      <>
        {groupedServices.map((item) => (
          <div key={item.id}>
            <div className="grouped title">{item.name}</div>
            <div>
              {item.items.map((service) => {
                return (
                  <>
                    <div key={service.id} className="grid-service body">
                      <div>{service.name}</div>
                      <div>
                        {format(parseISO(service.startDate), "dd.MM.yyyy")} -{" "}
                        {format(parseISO(service.endDate), "dd.MM.yyyy")}
                      </div>
                      <div>
                        {!service.isRequired &&
                          `+${service.price.amount} ${service.price.currencyAlias}`}
                      </div>
                    </div>

                    {service.note && (
                      <div className="baggage-info">
                        {service.note.split("\n").map((service) => (
                          <div>{service}</div>
                        ))}
                      </div>
                    )}
                    {paxes.map((paxe, index) => (
                      <div className="paxe" key={paxe.id}>
                        <Checkbox
                          checked={selectedService[
                            index
                          ].selectedServiceIds.includes(service.id)}
                          onChange={() =>
                            handleExcursionChange(service.id, paxe.id)
                          }
                        />
                        Турист {paxe.id}
                      </div>
                    ))}
                  </>
                );
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="modal-overlay" />
      <div className="service-modal block-body">
        <div className="modal-title">
          <div
            style={{
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
              fontSize: "1.17em",
              fontWeight: "bold",
            }}
          >
            {title}
          </div>
          <div
            style={{ flex: 0.1, textAlign: "end", cursor: "pointer" }}
            onClick={onClose}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="grid-service header">
          <div>Название</div>
          <div>Даты</div>
          <div></div>
        </div>
        <div className="modal_body">
          {grouped
            ? renderGrouped()
            : services.map((item) => {
              const isIncluded = selectedService.some((select) => select.selectedServiceIds.includes(item.id));
              return (
                <>
                  <div key={item.id} className="grid-service insurance-body">
                    <div>
                      <FormControlLabel
                        label={item.name}
                        control={
                          <Checkbox
                            defaultChecked={item.isRequired}
                            checked={
                              isIncluded
                            }
                            onChange={() => handleChange(item.id)}
                            disabled={!item.isRemovable && item.isRequired}
                          />
                        }
                      />
                    </div>
                    <div>
                      {format(parseISO(item.startDate), "dd.MM.yyyy")} -{" "}
                      {format(parseISO(item.endDate), "dd.MM.yyyy")}
                    </div>
                    <div>
                      {!item.isRequired &&
                        item.perPerson ?
                        `+${item.price.amount} ${item.price.currencyAlias}`
                        : item.price.amount && `+${item.price.amount} ${item.price.currencyAlias}`
                      }
                    </div>
                  </div>

                  {item.note && (
                    <div className="baggage-info">
                      {item.note.split("\n").map((item) => (
                        <div>{item}</div>
                      ))}
                    </div>
                  )}
                  {item.perPerson && paxes.map((paxe, index) => (
                    <div className="paxe">
                      <Checkbox
                        checked={selectedService[
                          index
                        ].selectedServiceIds.includes(item.id)}
                        onChange={() =>
                          handleExcursionChange(item.id, paxe.id)
                        }
                      />
                      Турист {paxe.id}
                    </div>
                  ))}
                </>)
            })}
        </div>
        <div className="modal-button">
          <Button onClick={onSave} variant="contained" color="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
}

export default ServiceModal;
