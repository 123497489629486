
import React, { useState, useRef, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Keyboard } from 'swiper/modules';
import { CloseOutlined } from '@material-ui/icons';
import SliderReview from './SliderReview'

import './style.scss'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'

const BASE_IMAGE_URL = process.env.REACT_APP_FS_IMAGE_URL

function HotelSlider({ images, id }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [open, setOpen] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0)
    const swiperRef = useRef(null);
    const isWidthLess576 = useMediaQuery({maxWidth: 576})

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
        }
		return () => {
			document.body.style.overflow = ''
		}
	}, [open])

    const handleOpenSlide = (id) => {
        if (!images.length) {
            return
        }
        setOpen(!open)
        if (swiperRef) {
            setCurrentSlide(id)
        }
    }

    const handleKeyPress = (event, code) => {
        if (code === 27) {
            setOpen(false)
            setThumbsSwiper(null)
        }
    }

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.activeIndex)
    }

    const filteredImages = images
        .filter(image => image?.resolutionType === "Source")
        .sort((a, b) => {
        if (a.imageType === "MainPhoto") {
            return -1; 
        } else if (b.imageType === "MainPhoto") {
            return 1; 
        } else {
            return a.sortOrder - b.sortOrder; 
        }
        });
    const mainPhoto = images.find(image => image?.imageType === 'MainPhoto' && image?.resolutionType === "Source")

    return <div className='hotel_slider'>
        {!isWidthLess576 && <> 
            <div className='slider-main-photo'>
                <SliderReview />
                <div
                    className='slider-slide photo-main'
                    onClick={() => handleOpenSlide(0)}
                >
                    <img
                        className='slider-img'
                        src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${mainPhoto?.imageType || images[0]?.imageType}-${mainPhoto?.resolutionType || images[0]?.resolutionType}-${mainPhoto?.fileName || images[0]?.fileName}`}
                        alt={mainPhoto?.alt}
                        onError={(e) => {
                            e.target.style.visibility = 'hidden';
                        }}
                    />
                </div>
            </div>
            <div className='bottom-photo-block'>
                <div className='slider-slide photo-bottom' onClick={() => handleOpenSlide(1)}>
                    <img
                        className='slider-img'
                        src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${mainPhoto ? filteredImages[1]?.imageType : filteredImages[2]?.imageType}-${mainPhoto ? filteredImages[1]?.resolutionType : filteredImages[2]?.resolutionType}-${mainPhoto ? filteredImages[1]?.fileName : filteredImages[2]?.fileName}`}
                        alt={filteredImages[1]?.alt}
                        onError={(e) => {
                            e.target.style.visibility = 'hidden';
                        }}
                    />
                </div>
                <div className='slider-slide photo-bottom' onClick={() => handleOpenSlide(2)}>
                    <img
                        className='slider-img'
                        src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${mainPhoto ? filteredImages[2]?.imageType : filteredImages[3]?.imageType}-${mainPhoto ? filteredImages[2]?.resolutionType : filteredImages[3]?.resolutionType}-${mainPhoto ? filteredImages[2]?.fileName : filteredImages[3]?.fileName}`}
                        alt={filteredImages[2]?.alt}
                        onError={(e) => {
                            e.target.style.visibility = 'hidden';
                        }}
                    />
                </div>
                <div className='slider-slide photo-bottom' style={{ position: 'relative' }} onClick={() => handleOpenSlide(3)}>
                    <img
                        className='slider-img'
                        src={`${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${mainPhoto ? filteredImages[3]?.imageType : filteredImages[4]?.imageType}-${mainPhoto ? filteredImages[3]?.resolutionType : filteredImages[4]?.resolutionType}-${mainPhoto ? filteredImages[3]?.fileName : filteredImages[4]?.fileName}`}
                        alt={filteredImages[3]?.alt}
                        onError={(e) => {
                            e.target.style.visibility = 'hidden';
                        }}
                    />
                    {filteredImages.length && <div className='slider-photos-count'>{filteredImages.length} фото</div>}
                </div>

            </div>
        </>}
        {isWidthLess576 && <>
            <div style={{ marginBottom: '20px'}}>
                <Swiper
                    initialSlide={currentSlide}
                    ref={swiperRef}
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        marginBottom: '20px',
                    }}
                    className='swiper-hotel-slider'
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs, Keyboard]}
                    keyboard={{
                        enabled: true
                    }}
                    onKeyPress={handleKeyPress}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    speed={0} 
                    onSlideChange={handleSlideChange}
                >
                    {filteredImages.map((image, ind) => {
                        const imageSrc = `${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${image.imageType}-${image.resolutionType}-${image.fileName}`
                        return <SwiperSlide key={image.fileName} style={{ display: "flex", justifyContent: 'center' }}>
                                <div
                                    onClick={() => handleOpenSlide(ind)}
                                    style={{
                                        background: `url(${imageSrc}) center center / cover no-repeat`,
                                        width: "100%",
                                        height: "100%",
                                        maxHeight: "324px",
                                    }}
                                ></div>
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>
        </>}
        {open && <div className='slider-popup' onClick={() => { setOpen(false); setThumbsSwiper(null) }}>
            <div className='slider-popup-content' onClick={(e) => { e.stopPropagation() }}>
                <div className='slider-popup-top'>{currentSlide + 1}/{filteredImages.length}
                    <div onClick={() => { setOpen(false); setThumbsSwiper(null) }} className='close'><CloseOutlined /></div>
                </div>
                <div>
                    <div>
                        <Swiper
                            initialSlide={currentSlide}
                            ref={swiperRef}
                            style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                                marginBottom: '20px',
                            }}
                            spaceBetween={10}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs, Keyboard]}
                            keyboard={{
                                enabled: true
                            }}
                            speed={0}
                            onKeyPress={handleKeyPress}
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            onSlideChange={handleSlideChange}
                        >
                            {filteredImages.map((image) => {
                                const imageSrc = `${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${image.imageType}-${image.resolutionType}-${image.fileName}`
                                return <SwiperSlide key={image.fileName} style={{ display: "flex", justifyContent: 'center' }}>
                                    <div className='slider-slide slide-main'>
                                        <img
                                            className='slider-img '
                                            src={imageSrc}
                                            alt={image?.alt}
                                            onError={(e) => {
                                                e.target.style.visibility = 'hidden';
                                            }}
                                        />
                                        {image?.description && <div className='photo-description'>{image?.description}</div>}
                                    </div>
                                </SwiperSlide>
                            })}

                        </Swiper>
                        <div className="nav-btn swiper-button-next"> <span></span></div>
                        <div className="nav-btn swiper-button-prev"><span></span></div>
                    </div>
                    {!isMobile && <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={20}
                        slidesPerView={5}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs, Keyboard]}
                        keyboard={{
                            enabled: true
                        }}
                        speed={0}
                        onKeyPress={handleKeyPress}
                        className="mySwiper"
                    >
                        {filteredImages.map((image) => {
                            const imageSrc = `${BASE_IMAGE_URL}geocontent/static/Hotel/${id}/${image.imageType}-${image.resolutionType}-${image.fileName}`
                            return <SwiperSlide key={image.fileName}>
                                <div className='slider-slide photo-bottom'>
                                    <img
                                        className='slider-img'
                                        src={imageSrc}
                                        alt={image?.alt}
                                        onError={(e) => {
                                            e.target.style.visibility = 'hidden';
                                        }}
                                    /></div>
                            </SwiperSlide>
                        })}

                    </Swiper>}
                </div>
            </div>
        </div>}
    </div>
}

export default HotelSlider