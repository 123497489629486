import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'
import {groupBy, isDate, isEmpty, isNumber, uniqBy} from 'lodash'
import { format, getYear } from 'date-fns'
import { transliterateToUppercase } from '../../../functions/convertLang'
import { latinNameRegExp } from '../../../constants/reg-exps'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useClaimsStore = create(
	devtools((set, get) => ({
		agent: JSON.parse(localStorage.getItem('agentInfo')),
		claims: null,
		claimsCount: null,
		page: 1,
		pagesLimit: 30,
		loadingClaimError: [],
		loading: false,
		authError: false,
		claim: [],
		errorGis: [],
		currentTourist: null,
		claimsDocuments: [],
		payments: [],
		filterConfig: {
			isFilterActive: false,
			isButtonApply: true,
			status: { id: 1, title: 'Все' },
			paymentStatus: '',
			searchValue: '',
			arrivalDate: '',
			reservationDate: '',
			country: '',
			managerName: '',
			operatorName: ''
		},
		selectCountries: [],
		selectOperatorNames: [],
		selectManagerNames: [],
		cancelReasons: [],
		messages: [],
		departments: [],
		messagesTypes: [],
		files: [],
		feedbackExist: [],
		bonus: null,
		paymentBonusStatus: null,
		payVariants: [],
		paymentInsuranceStatus: true,
		freights: {},
		seatBoarding: [],
		currentPeople: null,
		boardingPeoples: [],
		currentSeat: null,
		poPeoples: {},
		actions: {
			getClaims: async pageNumber => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const claimId = isFinite(get().filterConfig.searchValue)
					? get().filterConfig.searchValue
					: ''

				set({ claims: [] })
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/claims`,
						method: 'GET',
						params: {
							Page: pageNumber || get().page,
							PageSize: get().pagesLimit,
							PartpassId: partpass,
							ClaimId: claimId,
							ClaimStatus: get().filterConfig.status.id || 1,
							PayStatus: get().filterConfig?.paymentStatus?.id || null
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ claims: uniqBy(res.data, 'claimId') })
					set({
						selectCountries: uniqBy(
							res.data.map(claim => claim?.country),
							'id'
						).filter(item => item.title)
					})
					set({
						selectOperatorNames: uniqBy(
							res.data.map((claim, index) => ({
								title: claim?.operatorName,
								id: index
							})),
							'title'
						).filter(item => item.title)
					})
					set({
						selectManagerNames: uniqBy(
							res.data.map((claim, index) => ({
								title: claim?.managerName,
								id: index
							})),
							'title'
						).filter(item => item.title)
					})
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
					get().actions.getClaimsCount()
				}
			},
			getClaimsCount: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const claimId = isFinite(get().filterConfig.searchValue)
					? get().filterConfig.searchValue
					: ''
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/claims/count`,
						method: 'GET',
						params: {
							// Page: 1,
							// PageSize: 2000,
							PartpassId: partpass,
							ClaimId: claimId,
							ClaimStatus: get().filterConfig.status.id || 1,
							PayStatus: get().filterConfig?.paymentStatus?.id || null
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ claimsCount: res.data })
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getReasons: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/catalogs/reasons`,
						method: 'GET',
						params: {
							PartpassId: partpass
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ cancelReasons: res.data })
				} catch (e) {
					if (e.response.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				}
			},

			cancelClaim: async (id, reasonNum) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/claims/cancel`,
						method: 'POST',

						data: {
							claimId: id,
							partpassId: partpass,
							reason: reasonNum,
							ip: 'null'
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
				} catch (e) {
					if (e.response.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				}
			},
			editTourist: async id => {
				const tourist = get().currentTourist
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const currrentDate = tourist.passportValidDate
				const date = isDate(currrentDate)
					? format(currrentDate, 'yyyy-MM-dd')
					: currrentDate
				let latinName = tourist.edit_name.split(' ')
				latinName.pop()
				latinName = latinName.join(' ')
				const data = {
					id: tourist.id,
					bornDate: tourist.bornDate,
					human: tourist.human,
					isMale: tourist.isMale,
					latinName: latinNameRegExp.test(tourist.edit_name)
						? tourist.edit_name
						: transliterateToUppercase(latinName),
					name: tourist.edit_name,
					passportNumber: tourist.passportNumber,
					passportSerie: tourist.passportSerie,
					passportValidDate: date,
					phones: tourist.phones,
					email: tourist.email,
					documentType: tourist.documentType
				}
				set({ loading: true })
				try {
					await axios({
						url: `${BASE_URL}/claims/people`,
						method: 'PATCH',
						data: data,
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					const currentClaim = get().claim.find(item => item.id === id)
					const claims = get().claim.filter(item => item.id !== id)
					set({
						claim: [
							...claims,
							{
								...currentClaim,
								people: currentClaim.people.map(item =>
									item.id === tourist.id ? { ...item, ...data } : item
								)
							}
						]
					})
					// set({ claim: get().claim.filter(item => item.id !== id) })
					// await get().actions.getClaim(id)
				} catch (e) {
					if (e.response.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			setTourist: tourist => {
				set({ currentTourist: tourist })
			},
			getClaim: async (id, reload = false) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				set({ loadingClaimError: [] })
				if (reload) {
					set({ claim: get().claim.filter(item => item.id !== id) })
				}
				let claim = get().claim
				let errorGis = get().errorGis
				if (!claim.find(item => item.id === id) || reload) {
					set({ loading: true })
					try {
						const res = await axios({
							url: `${BASE_URL}/claims/${id}`,
							method: 'GET',
							params: {
								PartpassId: partpass
							},
							headers: {
								Authorization: `Bearer ${token}`
							}
						})
						let errorData = res.data.notes.find(note => note.inc === 1)
						if (errorData) {
							errorGis = [
								...errorGis,
								{
									id: id,
									msgText: errorData.msgText
								}
							]
						}
						claim = [...get().claim, { ...res.data, id: id }]
						set({ claim: claim })
						set({ errorGis: errorGis })

						if (isEmpty(res.data.hotels) && isEmpty(res.data.flights) && !isEmpty(res.data.insurances)) {
							try {
								const hash = await axios({
									url: `${BASE_URL}/Claim/${id}/insurance/hash`,
									method: 'GET',
								})
								try {
									const status = await axios({
										url: `${BASE_URL}/sravniinsurance/info/${hash.data}/status`,
										method: 'GET',
									})
									set({paymentInsuranceStatus: status.data.isComplete === 'true' && status.data.readyForPaid})
								} catch (e) {
									set({paymentInsuranceStatus: false})
								}
							} catch (e) {
								set({paymentInsuranceStatus: false})
							}
						}
					} catch (e) {
						set({ loadingClaimError: [...get().loadingClaimError, id] })
						if (e.response.status === 401) {
							set({ authError: true })
						}
						console.log(e?.response?.data)
					} finally {
						set({ loading: false })
					}
				}
			},
			getPayments: async (id, reload = false) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				let payments = reload ? get().payments.filter(item => item.claimId !== id) : get().payments
				if (!payments.find(item => item.id === id) || reload) {
					try {
						const res = await axios({
							url: `${BASE_URL}/payments`,
							method: 'GET',
							params: {
								PartpassId: partpass,
								FilterClaimId: id,
								RateTypeId: 1
							},
							headers: {
								Authorization: `Bearer ${token}`
							}
						})
						payments = [...payments, { ...res.data[0], id: id }]
						console.log(payments, 'payments')
						set({ payments: payments })
					} catch (e) {
						set({ loadingClaimError: [...get().loadingClaimError, id] })
						if (e.response.status === 401) {
							set({ authError: true })
						}
						console.log(e?.response?.data)
					}
				}
			},
			getClaimDocuments: async id => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				let claimsDocuments = get().claimsDocuments
				if (!claimsDocuments?.find(item => item.id === id)) {
					try {
						const res = await axios({
							url: `${BASE_URL}/claims/${id}/documents`,
							method: 'GET',
							params: {
								PartpassId: partpass
							},
							headers: {
								Authorization: `Bearer ${token}`
							}
						})

						claimsDocuments = [
							...get().claimsDocuments,
							{ documents: res.data, id: id }
						]
						// console.log(claimsDocuments, 'claimsDocuments')
						set({ claimsDocuments: claimsDocuments })
					} catch (e) {
						if (e?.response?.status === 401) {
							set({ authError: true })
						}
						console.log(e?.response?.data)
					}
				}
			},
			clearAuthError: () => {
				set({ authError: false })
			},
			clearClaims: () => {
				set({ claims: null })
			},
			setFilterConfig: (prop, value) => {
				// const apply = ['status', 'paymentStatus', 'searchValue'].includes(prop)
				set({
					filterConfig: {
						...get().filterConfig,
						isFilterActive: true,
						isButtonApply: true,
						[prop]: value
					}
				})
			},
			clearFilterConfig: () => {
				set({
					filterConfig: {
						isFilterActive: false,
						isButtonApply: true,
						status: { id: 1, title: 'Все' },
						paymentStatus: '',
						searchValue: '',
						arrivalDate: '',
						reservationDate: '',
						country: '',
						managerName: '',
						operatorName: ''
					}
				})
				get().actions.getClaims()
			},
			// setTourist: tourist => {
			// 	const phone = tourist.phones
			// 	const getPhoneNumber = phone => {
			// 		if (!phone) {
			// 			return { preffix: '+7', number: null }
			// 		}
			// 		if (phone.startsWith('+7')) {
			// 			return { preffix: '+7', number: phone.slice(2) }
			// 		}
			// 		if (phone.startsWith('7')) {
			// 			return { preffix: '+7', number: phone.slice(1) }
			// 		}
			// 		if (phone.startsWith('9')) {
			// 			return { preffix: '+7', number: phone }
			// 		}
			// 		if (phone.startsWith('8')) {
			// 			return { preffix: '+7', number: phone.slice(1) }
			// 		}
			// 	}
			// 	set({
			// 		currentTourist: {
			// 			...tourist,
			// 			preffix: getPhoneNumber(phone)?.preffix,
			// 			number: getPhoneNumber(phone)?.number
			// 		}
			// 	})
			// },
			paymentHold: async (id, amountPayment) => {
				const currentClaim = get().claims.find(item => item.claimId === id)
				const destinationCountryName = currentClaim.country.title
				const beginDate = currentClaim.dateBeg
				const { phones, email } = get().claim.find(item => item.id === id)
					.people[0]
				const { invoiceAmount, invoiceCurrencyAlias } = get().payments.find(
					item => item.claimId === id
				)

				const payload = {
					paymentProviderCode: 'Tinkoff',
					paymentProviderType: 'b2b',
					orderInformation: {
						id: id,
						destinationCountryName: destinationCountryName,
						beginDate: beginDate,
						description: `Путевка в ${destinationCountryName}`,
						customerEmail: email || 'test@Mail.ru',
						customerPhoneNumber: phones || '+79999999999',
						isRegularFlight: false
					},
					amount: amountPayment || invoiceAmount,
					currencyCode: invoiceCurrencyAlias,
					currencyRate: 1,
					successOperationRedirectionUrl: `${window.location.origin}/cabinet/claims?claimId=${id}`,
					failOperationRedirectionUrl: `${window.location.origin}/cabinet/claims?claimId=${id}&PaymentModalVisible=true`,
					callbackUrl: 'https://agentb2b.fstravel.com',
					expirationDate: '2024-02-09T00:00:00',
					paymentType: 0
				}
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/Payments/Hold`,
						method: 'POST',
						data: { ...payload },
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					if (res.data.formUrl) {
						window.open(res.data.formUrl, '_blank')
					}
				} catch (e) {
					if (e.response.status === 401) {
						set({ authError: true })
					}
					console.log(e?.response?.data)
				}
			},
			getPaymentInvoice: async id => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token

				const invoice = get()
					.claimsDocuments.find(item => item.id === id)
					.documents.find(doc => doc.docCategory === 1)
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/documents/createprintreportjob`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							reportId: Number(invoice.template),
							parameters: invoice.templateVars
						}
					})
					if (res.data.jobId) {
						const timeoutId = setTimeout(async () => {
							await get().actions.getDocumentsByJobId(res.data.jobId, id)
							clearTimeout(timeoutId)
						}, 3000)
					}
				} catch (e) {
					console.error('Error in createprintreportjob:', e?.response?.data)
					set({ loading: false })
				}
			},
			getDocumentsByJobId: async (jobId, claimId) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const url = `${BASE_URL}/documents/getprintjobresult?jobId=${jobId}`
					const res = await axios({
						url,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						},
						responseType: 'blob'
					})

					if (res.status !== 200) {
						return res.status
					}
					const urlLink = window.URL.createObjectURL(res.data)
					const link = document.createElement('a')
					link.href = urlLink
					link.download = isNumber(claimId)
						? `invoice_№${claimId}.pdf`
						: `${claimId.documentName}.pdf`
					document.body.appendChild(link)
					link.click()
					link.remove()
					window.URL.revokeObjectURL(urlLink)

					return res.status
				} catch (e) {
					console.error('Error in getprintjobresult:', e?.response?.data)
					set({ loading: false })
				} finally {
					set({ loading: false })
				}
			},
			getPrintDocument: async doc => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				set({ loading: doc.documentName })
				try {
					const res = await axios({
						url: `${BASE_URL}/documents/createprintreportjob`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							reportId: Number(doc.template),
							parameters: doc.templateVars
						}
					})
					if (res.data.jobId) {
						const timeoutId = setTimeout(async () => {
							await get().actions.getDocumentsByJobId(res.data.jobId, doc)
							clearTimeout(timeoutId)
						}, 4000)
					}
				} catch (e) {
					console.error('Error in createprintreportjob:', e?.response?.data)
					set({ loading: false })
				}
			},
			printSelectedDocuments: async (docs, id) => {
				const printDocuments = get()
					.claimsDocuments.find(item => item.id === id)
					.documents.filter(doc => docs.includes(doc.documentName))
				// console.log(printDocuments, 'print')
				for (let i = 0; i < printDocuments.length; i++) {
					await get().actions.getPrintDocument(printDocuments[i])
				}
			},
			getPaymentsCommission: async id => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				let paymentsCommission = get().paymentsCommission
				if (paymentsCommission?.find(item => item.id === id)) {
					return
				}
				try {
					const response = await axios({
						url: `${BASE_URL}/payments/commission`, //метод не работает ожидает https://jira-new.fstravel.com/browse/NEB-1697
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass
						}
					})
					set({ paymentsCommission: [...paymentsCommission, response.data] })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			setPage: page => {
				if (get().page === page) return
				set({
					filterConfig: {
						isFilterActive: false,
						isButtonApply: true,
						status: { id: 1, title: 'Все' },
						paymentStatus: '',
						searchValue: '',
						arrivalDate: '',
						reservationDate: '',
						country: '',
						managerName: '',
						operatorName: ''
					}
				})
				set({ page })
			},
			getMessages: async claimId => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const response = await axios({
						url: `${BASE_URL}/messages`,
						method: 'GET',
						params: {
							PartpassId: partpass,
							ClaimId: claimId
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({
						messages: [
							...get().messages.filter(i => i.claimId !== claimId),
							{ claimId: claimId, messages: response?.data }
						]
					})
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e)
				}
			},
			getDepartments: async (claimId) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token

				try {
					const response = await axios({
						url: `${BASE_URL}/messages/categoies`,
						method: 'GET',
						params: {
							PartpassId: partpass,
							ClaimdId: claimId,
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})

					const messagesTypes = groupBy(response.data, "depratmentId")

					const departments = response.data.reduce((acc, item) => {
						if (acc?.find(i => i?.id === item.depratmentId)) {
							return acc;
						}
						return [...acc, { name: item.departmentName, id: item.depratmentId }];
					}, []);
					set({ messagesTypes: messagesTypes })
					set({ departments: departments })
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.log(e)
				}
			},
			sendMessage: async formData => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))

				try {
					// eslint-disable-next-line no-unused-vars
					const res = await axios.post(
						`${BASE_URL}/messages?PartpassId=${partpass}`,
						formData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
								'X-TUI-ClientId': 'b2c:ru',
								'Content-Type': 'multipart/form-data'
							}
						}
					)
					return true
				} catch (e) {
					if (e?.response?.status === 401) {
						set({ authError: true })
					}
					console.error(e)
					return false
				}
			},
			markRead: async messageIds => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					await axios({
						url: `${BASE_URL}/messages/markRead`,
						method: 'POST',
						data: {
							partpassId: partpass,
							messageIds: messageIds
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
				} catch (e) {
					console.error(e)
				}
			},
			getReceipt: async (values, claimId) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/payments/receipt`,
						method: 'POST',
						data: {
							fio: values.name,
							address: values.address,
							birthDate: values.bdate,
							phone: values.phone,
							passportSeries: values.pseries,
							passportNumber: values.pnumber,
							passportIssuedBy: values.pgivenorg,
							passportValidUntil: values.pexpiration,
							orderNumber: claimId,
							orderDate: get().claims.find(item => item.claimId === claimId)
								?.reservationDate,
							amount: values.amount
						},
						headers: {
							Authorization: `Bearer ${token}`
						},
						responseType: 'blob'
					})
					const urlLink = window.URL.createObjectURL(res.data)
					const link = document.createElement('a')
					link.href = urlLink
					link.download = isNumber(claimId)
						? `invoice_№${claimId}.pdf`
						: `${claimId.documentName}.pdf`
					document.body.appendChild(link)
					link.click()
					link.remove()
					window.URL.revokeObjectURL(urlLink)
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getRatingTO: async claimId => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				let feedbackExist = get().feedbackExist
				if (!get().feedbackExist.find(item => item.id === claimId)) {
					try {
						const res = await axios({
							url: `${BASE_URL}/feedbacks/${claimId}`,
							method: 'GET',
							headers: {
								Authorization: `Bearer ${token}`
							}
						})
						if (res.status === 204) {
							feedbackExist = [
								...feedbackExist,
								{
									id: claimId,
									isExist: false
								}
							]
						} else if (res.status === 200 && res.data.rating) {
							feedbackExist = [
								...feedbackExist,
								{
									id: claimId,
									isExist: true
								}
							]
						}
						set({ feedbackExist: feedbackExist })
					} catch (e) {
						console.log(e?.response?.data)
					}
				}
			},
			getCheckPayVariant: async claimId => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/DbFunctions/check-pay-variant`,
						method: 'GET',
						params: {
							claim: claimId,
							partpass: partpass
						}
					})
					set({
						payVariants: [
							...get().payVariants.filter(i => i.claimId !== claimId),
							{ claimId: claimId, payVariant: res.data }
						]
					})
				} catch (error) {
					console.error(error)
				}
			},
			refreshRatingTO: async claimId => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				let feedbackExist = get().feedbackExist
				try {
					const res = await axios({
						url: `${BASE_URL}/feedbacks/${claimId}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					if (res.status === 204) {
						feedbackExist = feedbackExist.map(item =>
							item.id === claimId
								? {
									id: claimId,
									isExist: false
								}
								: item
						)
					} else if (res.status === 200 && res.data.rating) {
						feedbackExist = feedbackExist.map(item =>
							item.id === claimId
								? {
									id: claimId,
									isExist: true
								}
								: item
						)
					}
					set({ feedbackExist: feedbackExist })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getRatingTOExist: async claimId => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/feedbacks/${claimId}/exist`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			sendRatingTO: async (claimId, rating, comment) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					await axios({
						url: `${BASE_URL}/feedbacks`,
						method: 'POST',
						data: {
							claimId: claimId,
							rating: rating,
							comment: comment
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					await get().actions.refreshRatingTO(claimId)
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getMessageFile: async messageIds => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/Messages/${messageIds}/files`,
						method: 'GET',
						params: { PartpassId: partpass },
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ files: [...get().files, ...res.data] })
				} catch (error) {
					console.error(error)
				}
			},
			getFile: async (fileId, messageIds) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				return await axios({
					url: `${BASE_URL}/Messages/${messageIds}/files/${fileId}`,
					method: 'GET',
					responseType: 'blob',
					params: { PartpassId: partpass },
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
			},
			getBonus: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/DbFunctions/get_bonus`,
						method: 'GET',
						params: { claim: id }
					})
					set({ bonus: res.data })
				} catch (error) {
					console.error(error)
					set({ bonus: null })
				}
			},
			payBonus: async (id, amount, currency) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const year = getYear(new Date(get().agent.agents.registrationDate))
				try {
					await axios({
						url: `${BASE_URL}/DbFunctions/pay_with_bonus`,
						method: 'GET',
						params: {
							claim: id,
							managerId: Number(year + partpass),
							amount: amount,
							currency: currency
						}
					})
					await get().actions.getBonus(id)
					set({ paymentBonusStatus: 'success' })
				} catch (error) {
					set({ paymentBonusStatus: 'fail' })
					console.error(error)
				}
			},
			cancelPaymentStatus: () => {
				set({ paymentBonusStatus: null })
			},
			getFreights: async (id) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/freights/${id}/${partpass}`,
						method: "GET",
						headers: {
							Authorization: `Bearer ${token}`
						}

					})
					set({ freights: { ...get().freights, [id]: res?.data?.items } })
					// await get().actions.getBoarding()

				} catch (error) {
					console.error(error)
				}
			},
			getBoarding: async (claimId, id, tourId) => {
				set({ boardingPeoples: [] })
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const freight = get().freights[claimId]?.find(item => item.freightInc === +id)
				const flight = get().claim.find(item => item.id === claimId)?.flights?.find(flight => flight.flightID === +id)
				try {
					const res = await axios({
						url: `${BASE_URL}/freights/boarding-list`,
						method: "POST",
						data: {
							"freightId": freight?.freightInc,
							"dateBeg": new Date(flight.dateBeg.split('T')[0]).toISOString(),
							"dateEnd": new Date(flight.dateEnd.split('T')[0]).toISOString(),
							"classId": freight?.classInc,
							"tourId": tourId,
							"cDate": "2023-12-27T00:00:00Z",
							"seatKey": 0
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ seatBoarding: res?.data?.items })
				} catch (error) {
					console.error(error)
				}
			},
			getPoPeople: async (orderId, peopleId) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/freight/place/${peopleId}/${orderId}`,
						method: "GET",
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ poPeoples: {...get().poPeoples, [peopleId]: res?.data} })
					set({ boardingPeoples: [...get().boardingPeoples, {
							"pOpeople": res.data || 0,
							"partpassId": +partpass,
							"pPlacementCode": get().seatBoarding?.find(item => item.peopleInc === peopleId)?.inc || 0,
							"pPlacementName": get().seatBoarding?.find(item => item.peopleInc === peopleId)?.cellName || "",
							"placeGroup": get().seatBoarding?.find(item => item.peopleInc === peopleId)?.placeGroup || 0,
							"external": get().seatBoarding?.find(item => item.peopleInc === peopleId)?.external || 0
						}]
					})
				} catch (error) {
					console.error(error)
				}
			},
			handleSaveSeat: async (id) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const payload = get().boardingPeoples?.find(item => item.pOpeople === id)
				try {
					const res = await axios({
						url: `${BASE_URL}/freight/board`,
						method: "POST",
						data: payload,
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
				} catch (error) {
					console.error(error)
				}

			},
			setCurrentPeople: (id) => {
				set({currentPeople: id})
			},
			clearBoarding: () => {
				set({boardingPeoples: {}})
				set({seatBoarding: []})
				set({poPeoples: {}})
				set({currentPeople: null})
				set({currentSeat: null})
			},
			setNewSeat: (place) => {
				const peopleId = get().currentPeople
				const pOpeople = get().poPeoples[peopleId]
				const newBoardingPeoplesData = get().boardingPeoples.map(people =>
					people.pOpeople === pOpeople
						? {...people, pPlacementCode: place.inc, pPlacementName: place.cellName, placeGroup: place.placeGroup, external: place.external}
						: people
				)
				set({ boardingPeoples: newBoardingPeoplesData})
				set({ currentSeat: place})
			},
			deleteSeat: () => {
				const peopleId = get().currentPeople
				const pOpeople = get().poPeoples[peopleId]
				const newBoardingPeoplesData = get().boardingPeoples.map(people =>
					people.pOpeople === pOpeople
						? {...people, pPlacementCode: 0, pPlacementName: '', placeGroup: 0, external: 0}
						: people
				)
				set({ boardingPeoples: newBoardingPeoplesData})
			}
		}
	}))
)

export default useClaimsStore
