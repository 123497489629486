import useSearchTourStore from "../../../SearchTour/store";
import Card from "../../../../components/Card/Card";
import './style.css'
import { useState, useEffect } from 'react'
import {uniqBy} from "lodash";

const CardRegion = ({ name, towns, id, prefix }) => {
	const { addRegion, addTown, filterRegions,resetTown } = useSearchTourStore((state) => state.actions);
	const arrivalRegionIds = useSearchTourStore((state) => state.arrivalRegionIds)
	const arrivalCityIds = useSearchTourStore((state) => state.arrivalCityIds)
	const [ firstClick, setFirstClick ] = useState(true)
	const regionIsChecked = () => {
		const isChecked = Boolean(towns.find((town) => arrivalCityIds.includes(town.cityId.toString())))
		if (!isChecked) {
			filterRegions(id)
		}
		return isChecked
	}

	const changeReginCheboxStatus = (event) => {
		addRegion(id, event.target.checked, towns.map((town) => town.cityId.toString()))
	}

	const changeStatus = (id, event) => {
		if(firstClick && towns.length > 1){
			resetTown(id, towns)
			setFirstClick(false)
			return
		}
		addTown(id, event.target.checked)
	}

	useEffect(() => {
		if(!arrivalRegionIds.includes(id)){
			setFirstClick(true)
		} 
	},[id,arrivalRegionIds])
  
	return (
		<div>
			<label style={{display:'flex', whiteSpace: 'nowrap'}} htmlFor={prefix + id}>
				<input id={prefix + id} type="checkbox" checked={arrivalRegionIds.includes(id) && regionIsChecked()} onChange={changeReginCheboxStatus} />
				{name}
			</label>
			<div className="towns__container">
				{arrivalRegionIds.includes(id) ? uniqBy(towns, 'cityId').map((town) => <Card key={town.cityId} id={town.cityId.toString()} isChecked={arrivalCityIds.includes(town.cityId.toString())} name={town.cityName} changeStatus={changeStatus} prefix={'town_'}/>) : <></>}
			</div>
		</div>
	);
};

export default CardRegion;
