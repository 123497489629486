import React, {useState} from 'react';
import EducationBanner from '../../../asset/images/cabinet/education.jpg'

import './style.scss'
import {Box} from "@mui/material";
import {Button} from "@material-ui/core";
import styled from "styled-components";
import useEducationStore from "./store";
import ErrorModal from "./ErrorModal";

const StyledButton = styled(Button)`
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  background-color: #4872f2;
  padding: 10px 20px;
  color: #FFFFFF;
  &:hover {
    background-color: #567df5;
  }
`;

const Education = () => {

	const actions = useEducationStore(({actions}) => actions)
	const [open, setOpen] = useState({error: false, errorAccess: false, errorText: ''})

	const closeModal = () => {
		setOpen({error: false, errorAccess: false, errorText: ''})
	}

	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const getToken = () => {
		actions.createToken(agentInfo?.agents?.email).then(res => {
			if (res?.token) {
				window.open('https://education.fstravel.com/external/oauth?access_token=' + res.token, '_blank')
			} else if (res?.error) {
				setOpen({error: true, errorAccess: true, errorText: 'Нет доступа'})
			} else {
				setOpen({error: true, errorAccess: false, errorText: 'Что-то пошло не так'})
			}
		})
	}

	return (
		<div className={'edication'}>
			<img src={EducationBanner} alt="" style={{width: '100%'}}/>
			<p>Добро пожаловать в нашу новую систему обучения «Академия FUN&SUN»!</p>
			<p>Теперь учиться будет интересно, удобно и просто: все материалы по продуктам и продажам собраны в одном месте. Но главное, мы превратили обучение в увлекательный квест, где все участники – спецагенты.</p>
			<p>Включайтесь в игру – выполняйте задания, переходите по уровням, соревнуйтесь с коллегами, зарабатывайте баллы и обменивайте их на вознаграждения.</p>
			<p>Станьте самым крутым агентом – Агентом 007! И пусть вам сопутствуют авантюризм, решительность и удача в лучших традициях бондианы.</p>
			<Box pt={1} pb={1} textAlign={'center'}>
				<StyledButton variant={'contained'} onClick={getToken}>Академия FUN&SUN</StyledButton>
			</Box>
			<p>Внимание! В случае если кнопка не работает, обратитесь к Вашему аккаунт-менеджеру.</p>
			<p>Подключение к системе обучения происходит постепенно. Мы работаем над тем, чтобы в самое ближайшее время вы начали обучение.</p>
			{open.error && <ErrorModal title={open.errorText} onClose={closeModal} errorAccess={open.errorAccess} />}
		</div>
	);
};

export default Education;