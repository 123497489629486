import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Text,FlightTypeIcon,LowcosterPopup} from '../../../../ui';
import MainSegment from './MainSegment';
import SegmentList from './SegmentList';
import { useTicketProps } from '../../useTicketProps';
import { getFeatureIcon } from '../../utils';
import getAirlineLogo from '../../../../../functions/getAirlineLogo';
import _ from 'lodash';

import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import { styled as MUIStyled } from '@mui/material/styles';

// console.log(tooltipClasses);
// tooltipPlacementBottom
// : 
// "MuiTooltip-tooltipPlacementBottom"
// tooltipPlacementLeft
// : 
// "MuiTooltip-tooltipPlacementLeft"
// tooltipPlacementRight
// : 
// "MuiTooltip-tooltipPlacementRight"
// tooltipPlacementTop
// : 
// "MuiTooltip-tooltipPlacementTop"

const LightTooltip = MUIStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    "&::before": {
        backgroundColor: 'white',
        border: "1px solid #999"
      }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 15px 20px 0px rgba(16, 16, 16, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    padding: 14,
   
  },
  // [`& .${tooltipClasses.tooltipPlacementTop}`]: {
  // },
}));

const Head = styled(Text)`
  display: block;
  padding-left: 7px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadWhenActive = styled(Text)`
  display: block;
  margin-bottom: 21px;
  margin-top: 10px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    :not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const Flex = styled.div`
  display: flex;

  & > div + div {
    margin-left: 6px;
  }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadContainerMobile = styled(HeadContainer)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const AirlineLogo = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
`;

const ItemActive = (props) => {
  const { segments, stops } = props;
  const fromDate = moment(`${segments[0].fromDate} ${segments[0].fromTime}`);
  const { flight_Type, features, _isLowcoster } = useTicketProps();

  const toDate = moment(
    `${segments[segments.length - 1].toDate} ${
      segments[segments.length - 1].toTime
    }`
  );

  const flightNumbers = segments.map((x) => x?.airlineInfo?.operatingAirlineCode + ' ' + x.flightNo);

  const tooltipTitle = props.flightIndex === 0
  ? props?.airlinesInfo[0]?.name
  : props.flightIndex !== 0 && props?.airlinesInfo[1]
  ? props?.airlinesInfo[1]?.name
  : props?.airlinesInfo[0]?.name;

  return (
    <Container>
      <HeadContainer>
      <LightTooltip title={tooltipTitle} arrow placement="top">
          <AirlineLogo
            src={
              props.flightIndex === 0
                ? getAirlineLogo(
                  props?.airlinesInfo[0]?.code,
                  {width: 50,height: 50, styleType: 'squar'}
                  )
                : props.flightIndex !== 0 && props?.airlinesInfo[1]
                ? getAirlineLogo(
                    props?.airlinesInfo[1]?.code,
                    {width: 50,height: 50, styleType: 'squar'}
                  )
                : getAirlineLogo(
                    props?.airlinesInfo[0]?.code,
                    {width: 50,height: 50, styleType: 'squar'}
                  )
            }
          />
        </LightTooltip>
        <Head bold color="dark-gray">
          {props.flightIndex ? 'Обратно' : 'Туда'}: {props.from.city} —{' '}
          {props.to.city}
        </Head>
        <Flex>
          <FlightTypeIcon flightType={flight_Type} />
          {_isLowcoster && <LowcosterPopup />}
        </Flex>
      </HeadContainer>

      <HeadContainerMobile>
        <Head bold color="dark-gray">
          {props.flightIndex ? 'Обратно' : 'Туда'}: {props.from.city} —{' '}
          {props.to.city}
        </Head>
        <Flex>
          <FlightTypeIcon flightType={flight_Type} />
          {_isLowcoster && <LowcosterPopup />}
        </Flex>
        <FeaturesWrapper>
          {features.map((feature, index) => {
            return <span key={index}>{getFeatureIcon(feature)}</span>;
          })}
        </FeaturesWrapper>
      </HeadContainerMobile>
      {props.multy && props.active && (
        <HeadWhenActive bold color="dark-gray">
          {props.flightIndex ? 'Обратно' : 'Туда'}: {props.from.city} —{' '}
          {props.to.city}
        </HeadWhenActive>
      )}
      {!props.active && (
        <MainSegment
          // flightNumber={se}
          from={segments[0].from}
          to={segments[segments.length - 1].to}
          fromDate={fromDate}
          toDate={toDate}
          stops={stops}
          duration={props.duration}
          airlines={_(segments)
            .map((x) => x.airlineInfo)
            .uniqBy((x) => x.operatingAirlineCode)
            .value()}
          flightNumbers={flightNumbers}
        />
      )}
      {props.active && <SegmentList stops={stops} items={segments} />}
    </Container>
  );
};

export default ItemActive;
