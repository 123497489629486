import { Grid } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ExpandMore } from '@material-ui/icons'
import { MenuItem } from '@mui/material'
import React, { useState } from 'react'
import useAuthStore from '../../../Auth/store'
import MuiBirthDate from '../components/MuiBirthDate'
import { MuiTextField } from '../components/MuiTextField/MuiTextField'

import '../style.css'

const ownershipWorker = [
	'Устав',
	'Свидетельства о регистрации ИП',
	'Лист записи ЕГРИП',
	'Доверенность'
]

const ownershipsValues = [
	{ id: 103, title: 'АО', titleLatin: 'АО' },
	{ id: 3, title: 'ЗАО', titleLatin: 'ZAO' },
	{ id: 1, title: 'ИП', titleLatin: 'IP' },
	{ id: 102, title: 'ОАО', titleLatin: 'ОАО' },
	{ id: 2, title: 'ООО', titleLatin: 'OOO' },
	{ id: 117, title: 'Самозанятые', titleLatin: 'Самозанятые' },
	{
		id: 118,
		title: 'Товарищество с Ограниченой Ответственностью',
		titleLatin: 'Товарищество с Ограниченой Ответственностью'
	}
]

export default function AboutAgency() {
	const [open, setOpen] = React.useState(false)
	const actions = useAuthStore(({ actions }) => actions)
	const agents = useAuthStore(({ agent }) => agent?.agents)

	const directorPositionsValues = useAuthStore(state => state.directorPositions)
	const activitiesValues = useAuthStore(state => state.activities)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	// console.log(agents, 'agents')
	const [errors, setErrors] = useState({
		kpp: false,
		okpo: false,
		okvd: false,
		fio: false
	})

	React.useEffect(() => {
		actions.getDirectorPositions()
		actions.getActivities()
		actions.getOwnerships()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const changeNumberHandler = prop => event => {
		setErrors({ ...errors, [prop]: false })

		if (!/^\d+$/.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Введен некорректный символ`
			})
		} else {
			setErrors({ ...errors, [prop]: false })
			switch (prop) {
				case 'kpp':
					actions.changeAgentKPP(event.target.value)
					break
				case 'okpo':
					actions.changeAgentOKPO(event.target.value)
					break
				case 'okvd':
					actions.changeAgentOKONH(event.target.value)
					break
				default:
					return
			}
		}
	}

	const changeFIOHandler = prop => event => {
		setErrors({ ...errors, [prop]: false })
		if (
			!/^[а-яА-ЯёЁa-zA-Z\s-']+$/.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Введен некорректный символ`
			})
		} else {
			setErrors({ ...errors, [prop]: false })
			actions.changeAgentBoss(event.target.value)
		}
	}

	const handleChangeDirectorPositions = event => {
		if (directorPositionsValues?.length) {
			const currentDirectorPositions = directorPositionsValues.find(
				item => item.title === event.target.value
			)
			actions.changeAgentDirectorPosition(currentDirectorPositions)
		}
	}

	const handleChangeActivities = event => {
		if (activitiesValues?.length) {
			const currentActivities = activitiesValues.find(
				item => item.title === event.target.value
			)
			actions.changeAgentActivity(currentActivities)
		}
	}

	const handleChangeOwnership = event => {
		if (ownershipsValues?.length) {
			const currentOwnership = ownershipsValues.find(
				item => item.title === event.target.value
			)
			actions.changeAgentOwnership(currentOwnership)
		}
	}

	if (!agents) return null

	actions.refreshToken()

	return (
		<div
			style={{
				backgroundColor: '#F6F8FA',
				padding: 24,
				borderRadius: 16,
				margin: '24px 0'
			}}>
			<div className='agency-title'>
				<div className='about-agency'>Общая информация</div>
				{!open ? (
					<ExpandMoreIcon
						style={{ fontSize: 24, color: '#2E2E32' }}
						type='button'
						className='newagency-open-btn'
						onClick={() => setOpen(prev => !prev)}></ExpandMoreIcon>
				) : (
					<ExpandLessIcon
						style={{ fontSize: 24, color: '#2E2E32' }}
						type='button'
						className='newagency-close-btn'
						onClick={() => setOpen(prev => !prev)}></ExpandLessIcon>
				)}
			</div>
			{open && (
				<>
					<Grid
						container
						spacing={3}
						style={{ marginTop: '12px', marginBottom: '8px' }}>
						<Grid item xs={6} align=''>
							<MuiTextField
								label='Рекламное название компании'
								disabled
								required
								value={agents.name}
								variant='filled'
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								label='Официальное название компании'
								required
								value={agents.officialName}
								onChange={e => {
									actions.changeAgentOfficialName(e.target.value)
								}}
								variant='filled'
								fullWidth
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} style={{ marginBottom: '8px' }}>
						<Grid item xs={12}>
							<MuiTextField
								label='Юридический адрес'
								required
								value={agents.pAddress}
								onChange={e => {
									actions.changeAgentPAdress(e.target.value)
								}}
								variant='filled'
								fullWidth
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={4} align='center'>
							<MuiTextField
								label='КПП'
								value={agents.kpp}
								onChange={changeNumberHandler('kpp')}
								onBlur={changeNumberHandler('kpp')}
								variant='filled'
								fullWidth
								error={!!errors.kpp}
								helperText={errors.kpp}
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
						<Grid item xs={4}>
							<MuiTextField
								label='ИНН'
								disabled
								value={agents.inn || ''}
								variant='filled'
								fullWidth
							/>
						</Grid>
						<Grid item xs={4} align='right'>
							<MuiTextField
								label='ОГРН'
								disabled
								value={agents.ogrn || ''}
								variant='filled'
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} align='left'>
							<MuiTextField
								label='Код по ОКПО'
								value={agents.okpo || ''}
								onChange={changeNumberHandler('okpo')}
								onBlur={changeNumberHandler('okpo')}
								variant='filled'
								fullWidth
								error={!!errors.okpo}
								helperText={errors.okpo}
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
						<Grid item xs={6} align='right'>
							<MuiTextField
								label='Код по ОКВЭД'
								value={agents.okonh || ''}
								onChange={changeNumberHandler('okvd')}
								onBlur={changeNumberHandler('okvd')}
								variant='filled'
								fullWidth
								error={!!errors.okvd}
								helperText={errors.okvd}
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiBirthDate
								fullWidth
								onChange={actions.changeAgentRegistrationDate}
								label='Дата регистрации'
								value={new Date(agents?.registrationDate)}
								required
								disable={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								label='Система налогообложения'
								required
								disabled
								value={agents.taxation.title}
								variant='filled'
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								label='ФИО руководителя'
								required
								value={agents.boss}
								onChange={changeFIOHandler('fio')}
								onBlur={changeFIOHandler('fio')}
								variant='filled'
								fullWidth
								error={!!errors.fio}
								helperText={errors.fio}
								disabled={agentInfo?.agents?.name.includes('F&S')}
							/>
						</Grid>

						<Grid item xs={6}>
							<MuiTextField
								required
								SelectProps={{
									IconComponent: ExpandMore,
									MenuProps: {
										PaperProps: {
											className: 'myCustomList-actions',
											sx: {
												'& .MuiMenuItem-root': {
													fontFamily: 'Open Sans'
												}
											}
										},
										style: {
											maxHeight: '288px',
											top: 1
										}
									}
								}}
								select
								onChange={handleChangeDirectorPositions}
								fullWidth
								value={agents.directorPosition?.title}
								variant='filled'
								disabled={agentInfo?.agents?.name.includes('F&S')}
								label='Должность подписанта договора'>
								{directorPositionsValues?.map(item => (
									<MenuItem value={item.title} key={item.id}>
										{item.title}
									</MenuItem>
								))}
							</MuiTextField>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								required
								SelectProps={{
									IconComponent: ExpandMore,
									MenuProps: {
										PaperProps: {
											className: 'myCustomList-actions',
											sx: {
												'& .MuiMenuItem-root': {
													fontFamily: 'Open Sans'
												}
											}
										},
										style: {
											maxHeight: '288px',
											maxWidth: 392,
											top: 1
										}
									}
								}}
								select
								onChange={handleChangeOwnership}
								fullWidth
								value={agents.ownership?.title}
								variant='filled'
								disabled={agentInfo?.agents?.name.includes('F&S')}
								label='Форма собственности'>
								{ownershipsValues?.map(item => (
									<MenuItem
										value={item.title}
										key={item.id}
										style={{ whiteSpace: 'normal' }}>
										{item.title}
									</MenuItem>
								))}
							</MuiTextField>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								required
								SelectProps={{
									IconComponent: ExpandMore,
									MenuProps: {
										PaperProps: {
											className: 'myCustomList-actions',
											sx: {
												'& .MuiMenuItem-root': {
													fontFamily: 'Open Sans'
												}
											}
										},
										style: {
											maxHeight: '288px',
											maxWidth: 392,
											top: 1
										}
									}
								}}
								select
								onChange={event =>
									actions.changeAgentWorker(event.target.value)
								}
								fullWidth
								value={agents.worker}
								variant='filled'
								disabled={agentInfo?.agents?.name.includes('F&S')}
								label='Основание права подписи'>
								{ownershipWorker?.map((item, i) => (
									<MenuItem
										value={item}
										key={i}
										style={{ whiteSpace: 'normal' }}>
										{item}
									</MenuItem>
								))}
							</MuiTextField>
						</Grid>
						<Grid item xs={12} align='left'>
							<MuiTextField
								required
								SelectProps={{
									IconComponent: ExpandMore,
									MenuProps: {
										PaperProps: {
											className: 'myCustomList-actions',
											sx: {
												'& .MuiMenuItem-root': {
													fontFamily: 'Open Sans'
												}
											}
										},
										style: {
											maxHeight: '288px',
											top: 1
										}
									}
								}}
								select
								onChange={handleChangeActivities}
								fullWidth
								value={agents.activity.title}
								variant='filled'
								disabled={agentInfo?.agents?.name.includes('F&S')}
								label='Вид деятельности'>
								{activitiesValues?.map(item => (
									<MenuItem
										value={item.title}
										key={item.id}
										style={{ whiteSpace: 'normal' }}>
										{item.title}
									</MenuItem>
								))}
							</MuiTextField>
						</Grid>
					</Grid>
				</>
			)}
		</div>
	)
}
