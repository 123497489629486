import React from 'react';
import {SvgIcon} from "@mui/material";

const PdfIcon = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 26 34" style={{width: 26, height: 34, marginRight: 17}}>
			<path d="M26 9V32C26 33.1045 25.1045 34 24 34H2C0.895508 34 0 33.1045 0 32V2C0 0.895508 0.895508 0 2 0H17L26 9Z" fill="#E2564C"/>
			<path d="M17 7V0L26 9H19C17.8954 9 17 8.10457 17 7Z" fill="#B7382A"/>
			<path d="M7.87244 16.9801H8.21111C8.52764 16.9801 8.76449 16.9181 8.92166 16.7941C9.07882 16.668 9.1574 16.4854 9.1574 16.2463C9.1574 16.005 9.09099 15.8268 8.95818 15.7117C8.82758 15.5966 8.62172 15.5391 8.3406 15.5391H7.87244V16.9801ZM10.1967 16.2098C10.1967 16.7322 10.0329 17.1317 9.70525 17.4084C9.37986 17.6851 8.91612 17.8234 8.31404 17.8234H7.87244V19.55H6.84314V14.6957H8.39373C8.98253 14.6957 9.42966 14.823 9.73513 15.0775C10.0428 15.3299 10.1967 15.7073 10.1967 16.2098Z" fill="white"/>
			<path d="M15.1406 17.0764C15.1406 17.8755 14.9126 18.4875 14.4566 18.9125C14.0028 19.3375 13.3465 19.55 12.4877 19.55H11.1131V14.6957H12.6371C13.4295 14.6957 14.0449 14.9049 14.4832 15.3232C14.9215 15.7416 15.1406 16.326 15.1406 17.0764ZM14.0715 17.1029C14.0715 16.0604 13.611 15.5391 12.6902 15.5391H12.1424V18.7H12.584C13.5756 18.7 14.0715 18.1676 14.0715 17.1029Z" fill="white"/>
			<path d="M17.1594 19.55H16.1467V14.6957H18.9291V15.5391H17.1594V16.7908H18.8062V17.6309H17.1594V19.55Z" fill="white"/>
		</SvgIcon>
	);
};

export default PdfIcon;