import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, Collapse, Grid, Box } from '@mui/material'
import TilesItem from './TilesItem'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { isArray } from 'lodash'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`
const buttonLeftStyle = {
	p: 1,
	mr: 1,
	borderRadius: '50%',
	minWidth: '40px',
	height: '40px'
}

const buttonRightStyle = {
	p: 1,
	borderRadius: '50%',
	minWidth: '40px',
	height: '40px'
}

const NEWS_PER_PAGE = 9

export default function TilesBlock({ content }) {
	const [isOpen, setIsOpen] = React.useState(true)
	const [page, setPage] = useState(1)
	const [scroll, setScroll] = useState(false)
	const scrollRef = useRef(null)

	useEffect(() => {
		if (scroll && scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [page, scroll])

	const handleBackPage = () => {
		setPage(prev => prev - 1)
		setScroll(true)
	}

	const handleNextPage = () => {
		setPage(prev => prev + 1)
		setScroll(true)
	}
	const tiles = useMemo(() => {
		let data = content?.content

		if (!isArray(data)) {
			data = Object.values(data)
		}
		// console.log(data, 'data')
		const actualTiles = []
		for (let i = 0; i < data.length; i += NEWS_PER_PAGE) {
			actualTiles.push(data.slice(i, i + NEWS_PER_PAGE))
		}
		return actualTiles
	}, [content])

	const pageQty = useMemo(() => {
		return Math.ceil(tiles?.length)
	}, [tiles])

	const isPaginate = useMemo(() => {
		if (tiles.length < 2) {
			return false
		} else return true
	}, [tiles])
	// console.log(tiles, 'tiles')
	if (!tiles) return null
	return (
		<Container>
			<Row
				onClick={() => setIsOpen(!isOpen)}
				style={{ cursor: 'pointer' }}
				ref={scrollRef}>
				<FlexRow>
					<Title>{content?.title}</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => setIsOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<Box sx={{ mt: 3 }}>
					<Grid container rowSpacing={3} columnSpacing={3} sx={{ mb: 3 }}>
						{tiles[page - 1]?.map(item => (
							<TilesItem key={item.order} content={item} />
						))}
					</Grid>
					{isPaginate && (
						<Grid container align='end' rowSpacing={2}>
							<Grid item xs={12}>
								<Button
									sx={buttonLeftStyle}
									variant='outlined'
									onClick={handleBackPage}
									disabled={page === 1}>
									<KeyboardArrowLeft />
								</Button>
								<Button
									sx={buttonRightStyle}
									variant='outlined'
									onClick={handleNextPage}
									disabled={page === pageQty}>
									<KeyboardArrowRight />
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>
			</Collapse>
		</Container>
	)
}
