import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/Close'
import './style.css'
import useAuthStore from '../../../../Auth/store'
import { Box, Grid, MenuItem, TextField } from '@mui/material'
import BirthDateInput from '../../components/BirthDate'
import { Close, ExpandMore } from '@material-ui/icons'
import { emailRegExp, phoneRegExp } from '../../../../../constants/reg-exps'
import PhoneInput from './PhoneInput'
import TownsSelect from './TownsSelect'

export const FormInput = styled(TextField)(() => ({
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.75)'
	},
	'& .MuiSvgIcon-root': {
		right: 16
	},
	'& label': {
		fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
		color: '#7E8389'
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(',')
	}
}))

const RecoveryButton = styled(Button)({
	borderRadius: '8px',
	background: '#ffffff',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '8px 20px 8px 20px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:hover': {
		backgroundColor: '#ffffff',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const DeleteButton = styled(Button)({
	borderRadius: '8px',
	background: '#ffffff',
	color: '#E73C3E',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '8px 20px 8px 20px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	borderColor: '#E73C3E',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:hover': {
		backgroundColor: '#ffffff',
		borderColor: '#E73C3E'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const SaveButton = styled(Button)({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '8px 20px 8px 20px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '1068px'
		}
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(3)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(3),
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 0
	}
}))

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props

	return (
		<DialogTitle sx={{ m: 0, p: 3 }} {...other}>
			<Grid container>
				<Grid item align='end' xs={12}>
					<IconButton aria-label='close' onClick={onClose} size='small'>
						<CloseOutlinedIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item align='center' xs={12}>
					{children}
				</Grid>
			</Grid>
		</DialogTitle>
	)
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
}

export default function EditAgentPopup({ handleClose, openPopup }) {
	const agent = useAuthStore(({ currentAgent }) => currentAgent)
	// console.log('AGENT', agent)
	const [error, setError] = React.useState({
		phone: false,
		email: false
	})
	const checkPhoneError = value => {
		if (phoneRegExp.test(value) || !value) {
			setError(prev => ({ ...prev, phone: false }))
		} else {
			setError(prev => ({ ...prev, phone: 'Неверный формат телефона' }))
		}
	}
	const checkEmailError = value => {
		if (!emailRegExp.test(value) && value) {
			setError(prev => ({ ...prev, email: 'Неверный формат почты' }))
		} else {
			setError(prev => ({ ...prev, email: false }))
		}
	}
	const actions = useAuthStore(({ actions }) => actions)

	const saveHandler = async () => {
		await actions.editAgentsUser()
		handleClose()
	}
	const recoveryHandler = async () => {
		await actions.recoveryAgentUser()
		handleClose()
	}
	const deleteHandler = async () => {
		await actions.deleteAgentUser()
		handleClose()
	}

	if (!agent) return null
	return (
		<div>
			<BootstrapDialog
				PaperProps={{
					style: { borderRadius: 16 }
				}}
				fullWidth
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={openPopup}>
				<DialogTitle
					sx={{ m: 0, p: 3, pb: 0 }}
					className='add-agent-info-container'
					id='customized-dialog-title'>
					<div className='add-agent-info'>Редактирование данных агента</div>
					<IconButton aria-label='close' onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>

				<Box>
					<DialogContent className='container_agents'>
						<Grid container rowSpacing={5} columnSpacing={3}>
							<Grid item xs={4}>
								<FormInput
									value={agent?.lastName}
									onChange={e => actions.changeLastName(e.target.value)}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Фамилия'
									name='lastName'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									onChange={e => actions.changeFirstName(e.target.value)}
									value={agent?.firstName}
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Имя'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									onChange={e => actions.changePatronymic(e.target.value)}
									value={agent?.patronymic}
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Отчество'
								/>
							</Grid>

							<Grid item xs={4}>
								<BirthDateInput
									fullWidth
									onChange={actions.changeBirthday}
									label='Дата рождения'
									value={new Date(agent?.birthday)}
									required
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									SelectProps={{ IconComponent: ExpandMore }}
									select
									onChange={e => actions.changeGender(e.target.value)}
									fullWidth
									value={agent?.isMale}
									variant='outlined'
									label='Пол'>
									<MenuItem value={'Мужской'}>Мужской</MenuItem>
									<MenuItem value={'Женский'}>Женский</MenuItem>
								</FormInput>
							</Grid>

							<Grid item xs={4}></Grid>

							<Grid item xs={4}>
								<FormInput
									onChange={e => actions.changePosition(e.target.value)}
									value={agent?.position}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Должность'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									onChange={e => {
										checkEmailError(e.target.value)
										actions.changeEmail(e.target.value)
									}}
									value={agent?.email}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Email'
									name='email'
									error={!!error.email}
									helperText={error.email}
								/>
							</Grid>
							<Grid item xs={4}>
								<TownsSelect />
							</Grid>
							<Grid item xs={4}>
								<PhoneInput
									handleChangeSelectPhone={actions.changePhonePrefix}
									handleChangePhone={actions.changePhoneNumber}
									phone={agent?.phone}
									prefix={agent?.prefix}
									number={agent?.number}
									error={error}
									checkPhoneError={checkPhoneError}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									SelectProps={{ IconComponent: ExpandMore }}
									select
									onChange={e => actions.isAgentAdmin(e.target.value)}
									fullWidth
									value={agent?.isAdministrator || ''}
									variant='outlined'
									id='outlined-required'
									label='Доступ'>
									<MenuItem value={'Полный'}>Все заявки</MenuItem>
									<MenuItem value={'Частичный'}>Свои заявки</MenuItem>
								</FormInput>
								{/* <FormControlLabel
									control={
										<BpCheckbox
											checked={agent?.isOnlineAccess}
											onChange={e =>
												actions.changeOnlineAccess(e.target.checked)
											}
										/>
									}
									label='Разрешить доступ к личному кабинету'
								/> */}
							</Grid>
						</Grid>
					</DialogContent>
				</Box>
				<DialogActions>
					<Grid container spacing={2}>
						<Grid item xs={3}></Grid>
						<Grid item xs={3}>
							{agent?.isArchived ? (
								<RecoveryButton
									fullWidth
									variant='outlined'
									onClick={recoveryHandler}>
									Восстановить агента
								</RecoveryButton>
							) : (
								<DeleteButton
									fullWidth
									variant='outlined'
									onClick={deleteHandler}>
									Удалить агента
								</DeleteButton>
							)}
						</Grid>
						<Grid item xs={3}>
							<SaveButton
								fullWidth
								onClick={saveHandler}
								disabled={Boolean(
									!agent?.number ||
										!agent?.position ||
										!agent?.email ||
										!agent?.patronymic ||
										!agent?.firstName ||
										!agent?.lastName ||
										error.email ||
										error.phone
								)}>
								Сохранить
							</SaveButton>
						</Grid>
						<Grid item xs={3}></Grid>
					</Grid>
				</DialogActions>
			</BootstrapDialog>
		</div>
	)
}
