import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import Legend from './Legend';
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as ArrowIcon } from '../../../../../../../asset/icons/arrow-right.svg'
import useClaimsStore from '../../../../store'
import People from './People';
import SeatGrid from './SeatGrid';
import {useEffect} from "react";

const ModalWrapper = styled.div`
	display:flex;
	gap: 32px;
	flex-direction: column;
	width: 770px;
	min-height: 634px;
    max-height: 850px;
	background: rgba(255, 255, 255, 1);
	border-radius: 16px;
	padding: 32px;
`

const Title = styled.div`
    display:flex;
    justify-content: space-between;

    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(46, 46, 50, 1);
`

const Text = styled.div`
    font-family: "Open Sans", serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(46, 46, 50, 1);
`

const ContentWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: space-between;
`


const TextBold = styled(Text)`
    font-weight: 600;
`

const BoardingHeader = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg{
        width: 24px;
        height: 24px;
        path{
            fill:rgba(46, 46, 50, 1);
        }
    }
`

const Footer = styled.div`
    padding-top: 24px;
    border-top: 1px solid rgba(219, 224, 229, 1);
    
    display: flex;
    align-items: center;
    gap: 24px;
`

const Button = styled.button`
    padding: 12px 24px 12px 24px;
    border: 1px solid rgba(72, 114, 242, 1);
    border-radius: 8px;
    color: rgba(72, 114, 242, 1);
    max-width: 130px;

    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: unset;
    &:hover{
        background-color: rgba(72, 114, 242, 1);
        color: white;
    }
`

const BodyWrapper = styled.div`
    overflow-y: auto;
    height: 70vh;

    &::-webkit-scrollbar {
    width: 4px;
    }
    &::-webkit-scrollbar-thumb { 
        border-radius: 4px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);    
        }
`

function Boarding({ open, onClose, claim, id }) {

    const seatBoarding = useClaimsStore(({ seatBoarding }) => seatBoarding)
    const freight = useClaimsStore(({ freights }) => freights[claim?.id]?.find(item => item.freightInc === +id))
    const actions = useClaimsStore(({ actions }) => actions)
    const poPeoples = useClaimsStore(({ poPeoples }) => poPeoples)
    const currentSeat = useClaimsStore(({ currentSeat }) => currentSeat)
    const boardingPeoples = useClaimsStore(({ boardingPeoples }) => boardingPeoples)

    const currencyAlias = currentSeat?.price.split(' ')[1]
    const defaultPrice = seatBoarding.filter(item => boardingPeoples?.find(people => people.pOpeople === item.oPeopleInc)).reduce((acc, seat) => {
        return acc + (+seat?.price.split(' ')[0]);
    }, 0);

    const newPrice = seatBoarding.filter(item => boardingPeoples?.find(people => people.pPlacementName === item.cellName)).reduce((acc, seat) => {
        return acc + (+seat?.price.split(' ')[0]);
    }, 0);

    useEffect(() => {
        return () => {
            actions.clearBoarding()
        }
        // eslint-disable-next-line
    }, [])

    const handleSaveSeat = () => {
        claim?.people.forEach((item, index) => {
            actions.handleSaveSeat(poPeoples[item.id]).then(() => {
                if (index === claim?.people.length - 1) {
                    actions.getPayments(claim?.id, true)
                    actions.getClaim(claim?.id, true)
                    onClose()
                }
            })
        })
    }

    return <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        <ModalWrapper>
            <Title>
                Забронируйте места <div><CloseIcon onClick={onClose} style={{ cursor: "pointer" }} /></div>
            </Title>
            <BodyWrapper>
                <div>
                    <BoardingHeader>
                        <TextBold>{freight?.isBack ? 'Обратно' : 'Туда'} ･ {freight?.townSrcName}</TextBold>
                        <ArrowIcon />
                        <TextBold> {freight?.townTrgName} </TextBold>
                    </BoardingHeader>
                    <ContentWrapper>
                        <SeatGrid boarding={seatBoarding} claim={claim} />
                        <div>
                            {claim?.people.map((people, index) => <People people={people} count={index + 1} boarding={seatBoarding} claimId={claim.id} pOpeople={poPeoples[people.id]} />)}
                        </div>
                    </ContentWrapper>
                    <Legend />
                </div>
            </BodyWrapper>
            <Footer>
                <TextBold style={{ flex: '1' }}>Итого </TextBold>
                {currencyAlias && <TextBold>{(newPrice - defaultPrice) > 0 ? '+' : ''} {newPrice - defaultPrice} {currencyAlias}</TextBold>}
                <Button onClick={handleSaveSeat}>Изменить</Button>
            </Footer>
        </ModalWrapper>
    </Modal>
}

export default Boarding