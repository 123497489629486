import { useState, Fragment, useEffect } from "react";
import { FormControlLabel, Checkbox, Box, Button } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import useBookingStore from "../../store";
import { INSURANCE_LINK } from './index'
import "./style.css";
import { isEmpty } from "lodash";
import { getInsuranceProgram } from './index'

const sortArr = (a, b) => {
  if (a.name.includes("Ингосстрах") && !b.name.includes("Ингосстрах")) {
    return 1
  }
  if (!a.name.includes("Ингосстрах") && b.name.includes("Ингосстрах")) {
    return -1
  }

  return a.key - b.key
}

function InsurancesModal({ onClose }) {
  const peoples = useBookingStore(({ peoples }) => peoples)
  const services = useBookingStore(({ selectedServices }) => selectedServices)
  const insurance = useBookingStore(state => state.insurances)
  const medicalGroups = useBookingStore(({ medicalGroups }) => medicalGroups)
  const actions = useBookingStore(({ actions }) => actions);

  const selectedInsurances = services.filter(i => medicalGroups.some(el => el.id === i.groupId))
  const insurances = [...insurance, ...selectedInsurances]
  const [insurancesState, setInsurancesState] = useState(selectedInsurances)
  const [program, setProgram] = useState(getInsuranceProgram(insurances))

  const onSave = () => {
    actions.updateInsurance(insurancesState)
    onClose();
  };

  const addNewInsure = (uid) => {
    const existInsure = insurances.find(i => i.uid === uid)
    if (!existInsure) {
      return insurancesState
    }
    const options = insurances.filter(item => item.parentUid === uid && item.required)
    const filteredInsurance = insurancesState
      .filter(insure => insure.required || insure.groupId !== existInsure.groupId)
      .filter((insure, i, arr) => !insure.parentUid || arr.some(el => el.uid === insure.parentUid))

    return [...filteredInsurance, existInsure, ...options]
  }

  const removeInsure = (uid) => {
    let newState = insurancesState
      .filter(i => i.uid !== uid)
      .filter(i => i.parentUid !== uid)
    return newState
  }

  const handleChange = (id) => {
    let newState = []
    if (insurancesState.find(i => i?.uid === id)) {
      newState = removeInsure(id)
    } else {
      newState = addNewInsure(id)
    }
    setInsurancesState(newState)
  }

  const updateOptions = (parentUid, optionUid, peopleKey) => {
    let newState = []
    const existInsure = insurancesState.find(insure => insure.uid === optionUid)
    if (existInsure && existInsure.clients.some(client => client.peopleKey === peopleKey)) {
      newState = removeOptions(optionUid, peopleKey)
      setInsurancesState(newState)
      return
    }

    if (insurancesState.some(element => element?.uid === parentUid)) {
      addOptions(optionUid, peopleKey)
      return
    } else {
      const existInsure = insurances.find(i => i.uid === optionUid)
      newState = addNewInsure(parentUid)
      if (existInsure?.personal) {
        existInsure.clients = existInsure.clients.filter(client => client.peopleKey === peopleKey)
        newState.push(existInsure)
      }
    }
    setInsurancesState(newState)
  }

  const removeOptions = (uid, peopleKey) => {
    const existInsure = insurancesState.find(i => i.uid === uid)
    existInsure.clients = existInsure.clients.filter(client => client.peopleKey !== peopleKey)

    const newState = insurancesState.filter(i => i.uid !== uid)

    if (existInsure.clients.length) {
      return [...newState, existInsure]
    }
    else if (isEmpty(existInsure.clients)) {
      return newState
    }
    else {
      return newState
    }
  }

  const addOptions = (uid, peopleKey) => {
    const existInsure = insurances.find(i => i.uid === uid)
    if (existInsure.personal) {
      existInsure.clients = existInsure.clients.filter(client => client.peopleKey === peopleKey)
      if (existInsure.clients.length < 1) {
        existInsure.clients.push({ peopleKey: peopleKey, common: 0 })
      }
    } else {
      existInsure.clients = peoples.map((people) => ({ peopleKey: people.key, common: 0 }))
    }
    setInsurancesState(prev => [...prev, existInsure])
  }

  const getInsuranceOptions = (uid) => {
    const insurance = [...insurances]
    return insurance.filter(el => el?.parentUid === uid)
  }

  useEffect(() => {
    setProgram(getInsuranceProgram([...insurance, ...selectedInsurances]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services])

  return (
    <Fragment>
      <div className="modal-overlay" />
      <div className="modal">
        <div className="modal-title">
          <div
            style={{
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
              fontSize: "1.17em",
              fontWeight: "bold",
            }}
          >
            Страховка
          </div>
          <div
            style={{ flex: 0.1, textAlign: "end", cursor: "pointer" }}
            onClick={onClose}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="modal_body">
          {peoples.map((people) => {
            return (
              <>
                <div className="paxe-title">Турист {people.key}</div>
                <div className="insurance-body">
                  {program?.sort(sortArr)?.map((insure) => {
                    return (
                      <div key={insure.uid}>
                        <div className="insurance-option">
                          <FormControlLabel
                            label={
                              <><a
                                className='insurance-link'
                                href={INSURANCE_LINK}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {insure.name?.replace(': ', '')}
                              </a>
                                {` (${insure?.price + insure?.currencyAlias})`}

                                {insure.price
                                  ? ` +${insure?.price} ${insure?.currencyAlias}`
                                  : ""}
                              </>
                            }
                            control={
                              <Checkbox
                                defaultChecked={insure.required}
                                checked={!!insurancesState.find(i => insure.uid === i.uid) ?? false} //не работает
                                onChange={() => handleChange(insure.uid)}
                                disabled={!insure.remove}
                              />
                            }
                          />
                        </div>
                        {getInsuranceOptions(insure.uid).map((item) => {
                          const defaultChecked = !!item.required
                          const isChecked = !!(insurancesState.find(element => element?.uid === item.uid && element.clients.some(client => client.peopleKey === people.key)))

                          return <div key={item.uid} className="insurance-subOption">
                            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                              <FormControlLabel
                                label={`${item.name.replace(": ", "")} ${item?.fsPricePerPerson
                                  ? `+${item?.fsPricePerPerson} ${item?.currencyAlias}`
                                  : ""
                                  }`}
                                disabled={item.required}
                                control={
                                  <Checkbox
                                    defaultChecked={defaultChecked}
                                    checked={isChecked || defaultChecked}
                                    onChange={() => updateOptions(insure.uid, item.uid, people.key)}
                                  />
                                }
                              />
                            </Box>
                          </div>
                        })}
                      </div>
                    );
                  })}
                </div>
              </>
            )
          })}
        </div>
        <div className="modal-button">
          <Button
            onClick={onSave}
            variant="contained" color="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </Fragment>
  );
}


export default InsurancesModal;