import React from 'react'

import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button } from '@material-ui/core';
import PaymentMethodModal from './PaymentMethodModal'
import useClaimsStore from '../store'
import { format } from "date-fns";
import { isEmpty } from 'lodash';

const CollapseContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #F6F8FA;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.h3`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

const ButtonPay = styled(Button)`
	align-self: center;
    background-color: #4872f2;
    padding: 16px 32px;
    border-radius: 8px;
    box-shadow: none;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
    margin-top: 40px;
    gap: 40px;
`

const Table = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 80px 128px;
    ${({ withoutBorder }) => {
        return `${withoutBorder ?
            'margin-bottom: 8px;'
            :
            `border-bottom: 1px solid rgba(219, 224, 229, 1);
                 padding-bottom: 16px;`
            }
    `
    }}	
`
const Text = styled.p`
	${({ bold, size = 14, height = 20, color = 'rgba(46, 46, 50, 1)' }) => {
        return `font-size: ${size}px;
	line-height: ${height}px;
	font-weight: ${bold ? '600' : '400'};
    color: ${color};
	padding: 0;
	margin: 0;
	`
    }}	
`
export default function PriceAndPayment({ id, status, paymentModalVisible }) {
    const [modalVisible, setModalVisible] = React.useState(paymentModalVisible);
    const [isOpen, toggleOpen] = React.useState(paymentModalVisible);
    const payment = useClaimsStore(({ payments }) => payments).find(item => item.claimId === id)
    const claim = useClaimsStore(({ claim }) => claim.find(i => i?.id === id))
    const paymentInsuranceStatus = useClaimsStore(({ paymentInsuranceStatus }) => paymentInsuranceStatus)
    const pay = claim?.claims ? claim?.claims[0]?.pay : null
    const partPay = claim?.claims ? claim?.claims[0]?.partPay : null
    // const actions = useClaimsStore(({ actions }) => actions)
    const currenDate = () => format(new Date(), 'dd.MM.yyyy')

    const isNoRubTour = payment?.currencyAlias !== "RUB"
    const commission = payment?.paymentDetails?.CLAIM[0]?.CLAIM_COMISS.find(item => item?.CurrencyAlias !== 'RUB')
    const commissionRub = payment?.paymentDetails?.CLAIM[0]?.CLAIM_COMISS.find(item => item?.CurrencyAlias === 'RUB')
    const commissionDetail = payment?.paymentDetails?.CLAIM[0]?.CLAIM_COMMISS_DETAL

    const isButtonVisible = payment?.status?.id !== 3 && payment?.status?.id !== 1

    // useEffect(() => {
    //     actions.getPaymentsCommission()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    return (
        <>
            <CollapseContainer>
                <Row onClick={() => toggleOpen(!isOpen)}>
                    <FlexRow>
                        <Title style={{ margin: 0 }}>
                            Стоимость и сроки оплаты
                        </Title>
                    </FlexRow>
                    <ExpandMoreIcon
                        onClick={() => toggleOpen(!isOpen)}
                        style={isOpen ? { transform: 'rotate(180deg)' } : null}
                        isActive={isOpen}
                    />
                </Row>
                <Collapse in={isOpen}>
                    <ContentWrapper>
                        <Table>
                            <TableRow withoutBorder>
                                <div />
                                {isNoRubTour && <Text height='24' bold>{payment?.currencyAlias}</Text>}
                                <Text height='24' bold>{payment?.invoiceCurrencyAlias ?? "RUB"}{isNoRubTour && " на " + currenDate()}</Text>
                            </TableRow>
                            <TableRow>
                                <Text>Стоимость по каталогу</Text>
                                {isNoRubTour && <Text>{payment?.cost?.toFixed()}</Text>}
                                <Text>{payment?.invoiceCost?.toFixed()}</Text>
                            </TableRow>
                            <TableRow>
                                <Text>Всего</Text>
                                {isNoRubTour && <Text>{payment?.amount?.toFixed()}</Text>}
                                <Text>{payment?.invoiceAmount?.toFixed()}</Text>
                            </TableRow>
                            <TableRow>
                                <Text>Оплачено</Text>
                                {isNoRubTour && <Text>{(payment?.amount - payment?.debt).toFixed()}</Text>}
                                <Text>{(payment?.invoiceAmount - payment?.invoiceDebt).toFixed()}
                                </Text>
                            </TableRow>
                            <TableRow>
                                <Text color={payment?.debt > 0 ? 'rgba(231, 60, 62, 1)' : 'green'}>
                                    {
                                        payment?.debt > 0 ?
                                            'К доплате ' + (payment?.isPayTimeLimit && payment?.nextPaymentDate ? `(оплатить до ${format(new Date(payment?.nextPaymentDate), 'dd.MM.yy')})` : '')
                                            :
                                            "К возврату"
                                    }


                                </Text>
                                {isNoRubTour && <Text>{payment?.debt}</Text>}
                                <Text>{payment?.invoiceDebt?.toFixed()}</Text>
                            </TableRow>
                            {partPay && <TableRow>
                                <Text>{partPay}</Text>
                            </TableRow> }
                            {pay && <TableRow>
                                <Text>{pay}</Text>
                            </TableRow>}
                        </Table>

                        <Table>
                            <TableRow withoutBorder>
                                <Text height='24' bold>Детализация комиссий и скидок</Text>
                                {isNoRubTour && <Text height='24' bold>{payment?.currencyAlias ?? "EUR"}</Text>}
                                <Text height='24' bold>{payment?.invoiceCurrencyAlias ?? "RUB"} {isNoRubTour && " на " + currenDate()}</Text>
                            </TableRow>
                            {/* <TableRow>
                                <Text>Скидка</Text>
                                {isNoRubTour && <Text>{Math.abs(discount?.value ?? 0)}</Text>}
                                <Text>{discountRub.value}</Text>
                            </TableRow> */}
                            <TableRow>
                                <Text>Комиссия ({commissionRub?.CommissPercent ?? commission?.CommissPercent}%)</Text>
                                {isNoRubTour && <Text>{commission?.TotalCommiss?.toFixed()}</Text>}
                                <Text>{commissionRub?.TotalCommiss?.toFixed()}</Text>
                            </TableRow>
                            <TableRow>
                                <Text>Итого</Text>
                                {isNoRubTour && <Text>{commission?.TotalCommiss}</Text>}
                                <Text>{commissionRub?.TotalCommiss}</Text>
                            </TableRow>
                        </Table>

                        <Table>
                            {!isEmpty(commissionDetail) && <TableRow withoutBorder>
                                <Text height='24' bold>Детализация комиссий и скидок по услугам</Text>
                            </TableRow>}

                            {commissionDetail?.map(line =>
                                <TableRow>
                                    <Text>{line?.type}
                                        <span style={{ paddingLeft: '32px' }}>{line?.description}</span>
                                    </Text>
                                    <Text></Text>
                                    <Text style={{ textAlign: 'end' }}>{line?.value} {line?.unit}</Text>
                                </TableRow>
                            )}
                        </Table>

                        {isButtonVisible && <ButtonPay
                            onClick={() => setModalVisible(true)}
                            variant="contained"
                            color="primary"
                            disabled={!paymentInsuranceStatus}
                        >
                            Оплатить
                        </ButtonPay>}
                        {!paymentInsuranceStatus && <div style={{textAlign: 'center', marginTop: -35}}>заявка находится в обработке</div>}
                    </ContentWrapper>
                </Collapse>
            </CollapseContainer>
            {modalVisible && (
                <PaymentMethodModal claimId={id} onClose={setModalVisible} />
            )}
        </>
    )
}
