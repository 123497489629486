export function getNormalizeMessages(messages) {
  return messages.reverse().map(({ authorName, ...message }) => {
    return {
      id: message.id,
      text: message.text,
      date: message.createdDate,
      owner: message.isOwner,
      status: message.status.name,
      author: {
        name: authorName
      },
      files: message.files
    }
  })
}
