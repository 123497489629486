import Api from '../../../../functions/fetchApi'
import {
  convertToRequestData,
  feesUiMapper
} from "./utils"

// const END_POINTS = {
//   getFee: process.env.REACT_APP_AGENTS_GET_FEE, // http://agent.tvm-tte-k8s.fstravel.com/api/AgentFee/get-fee
//   getTaxes: process.env.REACT_APP_AGENTS_GET_TAXES, // http://agent.tvm-tte-k8s.fstravel.com/api/AgentTaxes/get-taxes
//   updateTaxes: process.env.REACT_APP_AGENTS_UPDATE_TAXES, // http://agent.tvm-tte-k8s.fstravel.com/api/AgentTaxes/update-taxes
//   addFee: process.env.REACT_APP_AGENTS_CREATE_FEE, // http://agent.tvm-tte-k8s.fstravel.com/api/AgentFee/add-fee
//   updateFee: process.env.REACT_APP_AGENT_UPDATE_FEE, // http://agent.tvm-tte-k8s.fstravel.com/api/AgentFee/update-fee
//   deleteFee: process.env.REACT_APP_AGENT_DELETE_FEE // http://agent.tvm-tte-k8s.fstravel.com/api/AgentFee/delete-fee
// }

// fee api http://agent.tvm-tte-k8s.fstravel.com/api/AgentFee
// taxes api http://agent.tvm-tte-k8s.fstravel.com/api/AgentTaxes


export function getAgentFee(agentId) {
  return Api.get(process.env.REACT_APP_AGENTS_GET_FEE)
    .query({agentId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(data => {
      return data?.agentFees?.length ? feesUiMapper(data.agentFees) : null
    });
}

export function getAgentTax(agentId) {
  return Api.get(process.env.REACT_APP_AGENTS_GET_TAXES)
    .query({agentId})
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}

export function updateTaxes(data) {
  return Api.put(process.env.REACT_APP_AGENTS_UPDATE_TAXES)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send();
}

export function createFee(data, agentId) {
  const transformedData = convertToRequestData(data)

  return Api.post(process.env.REACT_APP_AGENTS_CREATE_FEE)
    .body({ ...transformedData, agentId })
    .setContentType('application/json')
    .addAuthGuard()
    .dontParseResponse()
    .send();
}

export function editFee(data) {
  const transformedData = convertToRequestData(data)

  return Api.put(process.env.REACT_APP_AGENT_UPDATE_FEE)
    .body(transformedData)
    .setContentType('application/json')
    .addAuthGuard()
    .dontParseResponse()
    .send();
}

export function deleteFee(id) {
  return Api.post(process.env.REACT_APP_AGENT_DELETE_FEE)
    .body({id})
    .setContentType('application/json')
    .addAuthGuard()
    .dontParseResponse()
    .send();
}
