import React from "react"
import styled from "styled-components"
import Select from "react-select"
import { Field, Button } from "../../components/ui"
import { getUserState } from "../../store/modules/user"
import AgentUsersTable from "../../components/avia-agents/agent-users/AgentUsersTable"
import AgentAddToSamo from "../../components/avia-agents/agent-users/AgentAddToSamo"
import AgentUsersPopUp from "../../components/avia-agents/agent-users/AgentUsersPopUp"

import { useDispatch, useSelector } from "react-redux"
import {
  getAgentUserRolesRequest,
  getAgentUsersRequest,
  addAgentUsersRequest
} from "../../store/modules/agents/agent-users/duck"
import blueInfoIcon from "../../asset/images/avia/agents/blueInfoIcon.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1124px) {
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
    max-height: unset;
  }
`

const Title = styled.div`
  margin-top: 41px;
  margin-bottom: 25px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
`

const StyledSelect = styled(Select)`
  width: 256px;
  position: relative;
  margin-right: 20px;
  margin-top: 15px;
`

const StyledField = styled(Field)`
  width: 256px;
  margin-right: 20px;
  height: 38px;
`

const StyledButton = styled(Button)`
  height: 40px;
  text-align: center;
  margin-top: 15px;
  color: #ffffff;
  font-size: 16px;
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-bottom: 24px;
`

const BlueInfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  margin-right: 7px;
`

const AgentUsers = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = React.useState("")
  const [roleId, setRoleId] = React.useState("")
  const [addAgentUserToSamo, setAddAgentUserToSamo] = React.useState(false)
  const [agentUsersPopUpOpen, setAgentUsersPopUpOpen] = React.useState(false)

  const userState = useSelector(getUserState)

  const roles = useSelector(state => state.agents.roles)
  const addAgentUserStatus = useSelector(
    state => state.agents.addAgentUserStatus
  )

  const disabled = !email

  const isLoading = useSelector(state => state.agents.loadingsUser)

  React.useEffect(() => {
    dispatch(getAgentUserRolesRequest())
    dispatch(getAgentUsersRequest({ AgentId: userState?.data?.agentId }))
  }, [userState])

  const handleSelectChange = data => {
    setRoleId(data.value)
  }

  React.useEffect(() => {
    if (addAgentUserStatus && addAgentUserStatus?.status === -1) {
      setAddAgentUserToSamo(true)
    }
  }, [addAgentUserStatus])

  const options = roles?.map(item => {
    return {
      value: item.id,
      label: item.description
    }
  })

  const submit = () => {
    dispatch(
      addAgentUsersRequest({
        emails: [email],
        partnerId: userState?.data?.agentId,
        roleId: roleId
      })
    )
  }

  return (
    <>
      <Container>
        {addAgentUserToSamo && (
          <AgentAddToSamo
            addAgentUserToSamo={addAgentUserToSamo}
            setAddAgentUserToSamo={setAddAgentUserToSamo}
            email={email}
            setEmail={setEmail}
          />
        )}
        {agentUsersPopUpOpen && (
          <AgentUsersPopUp
            agentUsersPopUpOpen={agentUsersPopUpOpen}
            setAgentUsersPopUpOpen={setAgentUsersPopUpOpen}
          />
        )}
        <Title>Добавление нового пользователя</Title>
        <Text>
          Добавляйте пользователей к агенту, заполнив email и назначив роль. Мы
          выполним поиск по совпадению в Самотуре и добавим нового пользователя
          автоматически, либо предложим завести самостоятельно.
        </Text>
        <FlexRow>
          <StyledField
            value={email}
            autoComplete="off"
            onChange={event => {
              setEmail(event.target.value)
            }}
            label="Введите email пользователя"
          />
          <StyledSelect
            isSearchable={false}
            onChange={handleSelectChange}
            placeholder="Выберите роль"
            options={options}
          />
          <StyledButton
            disabled={disabled}
            onClick={() => {
              submit()
            }}
            isLoading={isLoading.createUserInSamo}
          >
              Найти в Самотуре
          </StyledButton>
        </FlexRow>
        <FlexRow
          style={{
            color: "#4872F2",
            marginTop: 20,
            cursor: "pointer",
            maxWidth: 350,
            marginBottom: 20
          }}
          onClick={() => setAgentUsersPopUpOpen(true)}
        >
          <BlueInfoIcon /> Какую роль назначить пользователю?
        </FlexRow>
      </Container>
      <AgentUsersTable />
    </>
  )
}

export default AgentUsers
