import React from 'react';
import useBookingStore from "../../store";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const Tourists = ({onSelected, touristId, seats, onDelete}) => {

	const paxes = useBookingStore(state => state.paxes.filter(pax => pax.person.ageGroup !==3))
	const boarding = useBookingStore(({ boarding }) => boarding)

	const handleDeleteSeat = (e) => {
		onDelete(e.currentTarget.id)
	}

	return (
		<div className="block-body">
			<div className="grid-boarding header">
				<div>Турист</div>
				<div>Место</div>
				<div></div>
			</div>
			{paxes.map(pax => {
				const key = seats.find(item => item.id === pax.id)?.seats?.seatKey
				const seat = boarding.find(item => item.key === key)
				return (
					<div
						className={`grid-boarding body ${pax.id === touristId ? `selected` : ''}`}
						onClick={() => onSelected(pax.id)}
					>
						<div>
							{pax.person.lastNameLatin || pax.person.firstNameLatin ? (`${pax.person.lastNameLatin} ${pax.person.firstNameLatin}`) : `NO NAME ${pax.id}`}
						</div>
						{seat && <div>
							<strong>{seat?.cellName}</strong>
							<em> +{seat?.price} {seat?.currencyAlias} {seat?.groupName}</em>
						</div>
						}
						{seat && <div>
							<DeleteForeverOutlinedIcon id={pax.id} onClick={handleDeleteSeat} />
						</div>}
					</div>)
				}
			)}
		</div>
	);
};

export default Tourists;