import React, { useEffect, useRef, useState } from 'react'
import { IconButton, InputAdornment } from '@material-ui/core'
import { compareAsc, format } from 'date-fns'
import { ReactComponent as CalendarImage } from '../../../../../asset/images/cabinet/calendar.svg'
import Calendar from 'react-calendar'
import { ru } from 'date-fns/locale'
import './style.scss'
import { FormInput } from '../../ClaimFilter'
import { Close } from '@material-ui/icons'
import InputMask from 'react-input-mask-v2'

const DateInputRange = ({ label, onChange, value, disable = false }) => {
	const [selectedDate, handleDateChange] = useState(value)
	const [showCalendar, setShowCalendar] = useState(false)
	const [inputDate, setInputDate] = useState('')
	let mask = 'dD.mM.YYYY - dD.mM.YYYY'
	let formatChars = {
		Y: '[0-9]',
		d: '[0-3]',
		D: '[0-9]',
		m: '[0-1]',
		M: '[1-9]'
	}

	let beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState

		let dateParts = value.split('.')
		let dayPart = dateParts[0]
		let monthPart = dateParts[1]

		if (dayPart?.startsWith('3')) formatChars['D'] = '[0-1]'
		else if (dayPart?.startsWith('0')) formatChars['D'] = '[1-9]'
		else formatChars['D'] = '[0-9]'

		if (monthPart?.startsWith('1')) formatChars['M'] = '[0-2]'
		else formatChars['M'] = '[1-9]'

		return { value, selection: newState.selection }
	}

	const calendarRef = useRef(null)

	useEffect(() => {
		setShowCalendar(false)
	}, [selectedDate])

	useEffect(() => {
		handleDateChange(value)
	}, [value])

	useEffect(() => {
		function handleClick(event) {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setShowCalendar(false)
			}
		}
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [calendarRef, setShowCalendar])

	const handleChange = date => {
		handleDateChange(date)
		onChange(date)
	}

	const handleShowCalendar = () => {
		setShowCalendar(!showCalendar)
	}

	const handleClearInput = event => {
		event.stopPropagation()
		handleDateChange('')
		setInputDate('')
		onChange('')
	}

	const onChangeHandler = event => {
		const value = event.target.value
		handleDateChange('')
		setInputDate(value)
		if (value.length === 23) {
			const first = new Date(
				value.split(' - ')[0].split('.').reverse().join('.')
			)
			const second = new Date(
				value.split(' - ')[1].split('.').reverse().join('.')
			)
			const compareDates = compareAsc(first, second)
			compareDates > 0
				? handleChange([second.setHours(23, 59, 59), first])
				: handleChange([first, second.setHours(23, 59, 59)])
		}
	}
	const onBlurHandler = () => {
		if (inputDate.length === 13) {
			const first = new Date(
				inputDate.split(' - ')[0].split('.').reverse().join('.')
			)
			const second = first
			handleChange([first.setHours(0, 0, 0), second.setHours(23, 59, 59)])
		} else return
	}
	return (
		<div className='DateInputRange-wrapper' style={{ width: '100%' }}>
			<InputMask
				mask={mask}
				maskChar=''
				value={
					selectedDate
						? selectedDate
								.map(date => format(date, 'dd.MM.yyyy', { locale: ru }))
								.join(' - ')
						: inputDate
				}
				onChange={onChangeHandler}
				onBlur={onBlurHandler}
				formatChars={formatChars}
				beforeMaskedValueChange={beforeMaskedValueChange}>
				{inputProps => (
					<FormInput
						{...inputProps}
						placeholder='ДД.ММ.ГГГГ - ДД.ММ.ГГГГ'
						id='outlined-adornment-password'
						label={label}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									{(selectedDate || inputDate) && (
										<IconButton
											aria-label='toggle password visibility'
											edge='end'
											onClick={handleClearInput}>
											<Close className='icon-CalendarImage' />
										</IconButton>
									)}
									<IconButton
										aria-label='toggle password visibility'
										edge='end'
										onClick={handleShowCalendar}>
										<CalendarImage className='icon-CalendarImage' />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				)}
			</InputMask>
			{showCalendar && (
				<div className='date-input-calendar' ref={calendarRef}>
					<Calendar
						value={selectedDate}
						date={selectedDate}
						onChange={date => handleChange(date)}
						selectRange
						showDoubleView
					/>
				</div>
			)}
		</div>
	)
}

export default DateInputRange
