import { createAction, createReducer } from "redux-act"
import { combineReducers } from "redux"

export const getAppealReferencesRequest = createAction(
  "@references/getAppealReferencesRequest"
)
export const getAppealSubjectsSuccess = createAction(
  "@references/getAppealSubjectsSuccess"
)

export const getAppealStatusesSuccess = createAction(
  "@references/getAppealStatusesSuccess"
)

export const getOrderReferencesRequest = createAction(
  "@references/getAppealStatusesRequest"
)
export const getPaymentStatusesSuccess = createAction(
  "@references/getAppealStatusesSuccess"
)
const paymentStatuses = createReducer({}, [])
paymentStatuses.on(getPaymentStatusesSuccess, (_, payload) => payload)

const subjects = createReducer({}, [])
subjects.on(getAppealSubjectsSuccess, (_, payload) => payload)

const statuses = createReducer({}, [])
statuses.on(getAppealStatusesSuccess, (_, payload) => payload)

const appeals = combineReducers({
  subjects,
  statuses
})

const order = combineReducers({
  paymentStatuses
})

export default combineReducers({
  appeals,
  order
})
