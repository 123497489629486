import React from 'react';
import { SvgIcon } from "@mui/material";

function Price(props) {
    return <SvgIcon {...props} viewBox="0 0 20 20" fill="none" style={{ width: "20px", height: "20px" }} >
        <path fillRule="evenodd" clipRule="evenodd" d="M0.625 2.5C0.625 2.00272 0.822544 1.52581 1.17417 1.17417C1.52581 0.822544 2.00272 0.625 2.5 0.625H8.2325C8.72961 0.625438 9.20621 0.823269 9.5575 1.175L18.3075 9.925C18.6586 10.2766 18.8559 10.7531 18.8559 11.25C18.8559 11.7469 18.6586 12.2234 18.3075 12.575L12.5763 18.3075C12.4021 18.4817 12.1954 18.62 11.9678 18.7143C11.7402 18.8086 11.4963 18.8571 11.25 18.8571C11.0037 18.8571 10.7598 18.8086 10.5322 18.7143C10.3046 18.62 10.0979 18.4817 9.92375 18.3075L1.17375 9.5575C0.822477 9.206 0.625106 8.72943 0.625 8.2325V2.5ZM2.5 1.875C2.33424 1.875 2.17527 1.94085 2.05806 2.05806C1.94085 2.17527 1.875 2.33424 1.875 2.5V8.2325C1.87504 8.39794 1.94066 8.55662 2.0575 8.67375L10.8075 17.4238C10.8656 17.482 10.9345 17.5281 11.0105 17.5596C11.0864 17.5912 11.1678 17.6074 11.25 17.6074C11.3322 17.6074 11.4136 17.5912 11.4895 17.5596C11.5655 17.5281 11.6344 17.482 11.6925 17.4238L17.4237 11.6925C17.482 11.6344 17.5281 11.5655 17.5596 11.4895C17.5911 11.4136 17.6074 11.3322 17.6074 11.25C17.6074 11.1678 17.5911 11.0864 17.5596 11.0105C17.5281 10.9345 17.482 10.8656 17.4237 10.8075L8.67375 2.0575C8.55662 1.94066 8.39794 1.87504 8.2325 1.875H2.5Z" fill="#3C3C3C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.125 5.625C3.125 4.96196 3.38839 4.32607 3.85723 3.85723C4.32607 3.38839 4.96196 3.125 5.625 3.125C6.28804 3.125 6.92393 3.38839 7.39277 3.85723C7.86161 4.32607 8.125 4.96196 8.125 5.625C8.125 6.28804 7.86161 6.92393 7.39277 7.39277C6.92393 7.86161 6.28804 8.125 5.625 8.125C4.96196 8.125 4.32607 7.86161 3.85723 7.39277C3.38839 6.92393 3.125 6.28804 3.125 5.625ZM5.625 4.375C5.29348 4.375 4.97554 4.5067 4.74112 4.74112C4.5067 4.97554 4.375 5.29348 4.375 5.625C4.375 5.95652 4.5067 6.27446 4.74112 6.50888C4.97554 6.7433 5.29348 6.875 5.625 6.875C5.95652 6.875 6.27446 6.7433 6.50888 6.50888C6.7433 6.27446 6.875 5.95652 6.875 5.625C6.875 5.29348 6.7433 4.97554 6.50888 4.74112C6.27446 4.5067 5.95652 4.375 5.625 4.375Z" fill="#3C3C3C" />
    </SvgIcon>
}

export default Price