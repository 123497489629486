import { isMobile } from 'react-device-detect'
import './style.scss'


const getHeightByArrayLength  = (arr) => {
    let height;
    switch (arr.length) {
        case 4:
            height = "180px";
          break;
        case 3:
            height = "240px";
          break;
        case 2:
            height = "310px";
          break;
        default:
            height = "100px";
          break;
      }
      return height;
}
function Image1to4Old({ content, title, show_mobile, show_desktop, id }) {
    if ((!isMobile && !show_desktop) || (isMobile && !show_mobile)) return null

    if (content.images.length === 1) {
        let imgWidth = '50%'
        let contentWith = '50%'
        if (content.widthImg) {
            imgWidth = content.widthImg + '%'
            contentWith = 100 - content.widthImg + '%'
        }
        return <div className='image1to4_container' id={id}>
            {title && <h2>{title}</h2>}
            {content.images.map(item =>
                <div className='image1to4_count-one' key={item?.guid}>
                    <div style={{ width: imgWidth }}>
                        {item?.preview && <img
                            className='image1to4_img'
                            src={item?.preview} alt=''
                        />}
                    </div>
                    <div 
                        style={{ width: contentWith }} 
                        className='image1to4_content' >
                        <p className='image1to4_content-text' 
                        dangerouslySetInnerHTML={{ __html: item?.imagesText}} ></p>
                        {item.link_text && (
                            <a
                                style={{background: item.color_button? item.color_button : '#FFE733'}}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={item.link ? 'button-images-many' : 'button-images-many disabled'}
                            >
                                {item.link_text}
                            </a>
                        )}

                    </div>
                </div>
            )}
        </div>
    }

    const heightImg = getHeightByArrayLength(content.images)

    return <div className='image1to4_container' id={id}>
        {title && <h2>{title}</h2>}
        <div className='image1to4_grid'>
            {content.images.map(item =>
                <div key={item?.preview} className='image1to4_item'>
                    <div style={{ height: heightImg}} className='image1to4_image-container'>
                        {item?.preview && <img className='image1to4_img' src={item?.preview} alt='' />}
                    </div>
                    {item?.imagesText &&  <div className='image1to4_content' >
                        <p 
                            className='image1to4_content-text' 
                            dangerouslySetInnerHTML={{ __html: item?.imagesText}} 
                            ></p>
                        {item.imagesNameButton && (
                            <a
                                style={{background: item.color_button? item.color_button : '#FFE733'}}
                                href={item.imagesLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={item.imagesLink ? 'button-images-many' : 'button-images-many disabled'}
                            >
                                {item.imagesNameButton}
                            </a>
                        )}
                    </div>}               
                </div>
            )}
        </div>
    </div>
}

export default Image1to4Old;