import Stars from '../../../../../asset/icons/Stars'

function StarsBlockCopy({ count = 0 }) {
	if (!count || count === 'NO') return null

	const starsArray = new Array(count).fill(0)

	return (
		<div style={{ display: 'flex', gap: '4px' }}>
			{starsArray.map((_, index) => (
				<Stars
					key={index}
					style={{ marginRight: 5, fill: '#FFE874' }}
					fontSize='small'
				/>
			))}
		</div>
	)
}

export default StarsBlockCopy
