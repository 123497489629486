import useBookingStore from "../../store";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import './style.scss';

function PreReserve() {

    const actions = useBookingStore(({ actions }) => actions)
    const checkedPreReserve = useBookingStore(({ checkedPreReserve }) => checkedPreReserve)
    const claimNew = useBookingStore(({ claimNew }) => claimNew)

    return <div className="prereserve">
        <div>
            <FormControlLabel
                label={'Предварительная заявка'}
                control={
                    <Checkbox
                        checked={checkedPreReserve}
                        onChange={() => actions.checkPreReserve(!checkedPreReserve)}
                    />
                }
            />

            <div className="prereserve__desc" dangerouslySetInnerHTML={{__html: claimNew?.claimDocument?.noteDraftClaim}} />
        </div>
    </div>
}

export default PreReserve