import React from 'react';
import styled from 'styled-components';
import {Text, Button} from '../../components/ui';
import {Link,useParams,useNavigate,useLocation} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {confirmPaymentRequest,isOrdersLoading,setLoadingStatus,STATUS} from '../../store/modules/orders'
import formatPrice from '../../functions/formatPrice';
import Loader from '../../components/ui/Loader';

const Wrapper = styled.div`
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 64px;
    width: 410px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
`;

const Image = styled.img.attrs({src: '/media/success-payment-icon.png'})`
    margin-bottom: 60px;
`;

const Description = styled(Text)`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #2E2E32;

    display: block;
    margin-bottom: 40px;

    & > strong, & > a {
        color: #4872F2;
        font-weight: 600;
    }
`;

const BackToOrder = styled(Button)`
    padding: 16px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`

export default function PaymentConfirmation() {
    const params = useParams();
    const location = useLocation();
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(isOrdersLoading);

    const searchParams = new URLSearchParams(decodeURIComponent(location.search));
    const amount = parseInt(searchParams.get('amount') || '0');

    React.useEffect(() => {
        const number = parseInt(searchParams.get('paymentNumber') || '0');
        dispatch(confirmPaymentRequest({number}));

        return () => {
            dispatch(setLoadingStatus(STATUS.loading))
        }
    },[])

    return (
        <Wrapper>
            {isLoading && <Loader/>}
            <Image/>
            <Description>
                Заявка <Link to={`/cabinet/orders/${params.orderNumber}`}>№ {params.orderNumber}</Link> успешно оплачена! Сумма оплаты: <strong>{formatPrice(amount)}</strong>
            </Description>
            <BackToOrder onClick={() => navigateTo(`/cabinet/orders/${params.orderNumber}`)}>Вернуться к заявке</BackToOrder>
        </Wrapper>
    )
}
