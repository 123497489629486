import React, { useEffect, useState } from 'react'
import { Alert } from '@mui/material'

import './style.css'
import CustomSelect from '../../../../../../components/Select'
import { Box, Snackbar, TextField } from '@material-ui/core'
import {
	emailRegExp,
	latinNameRegExp,
	// phoneRegExp,
	interPassportNumberRegExp,
	cyrillicRegExp,
	seriesBirthCertificatesRegExp,
	rfPassportSeriesRegExp,
	interPassportSeriesRegExp,
	rfPassportNumberRegExp,
	birthCertNumberRegExp,
	latinUpperAndNumberRegExp,
	latinAndNumberRegExp,
	newPhoneRegExp
} from '../../../../../../constants/reg-exps'
import { useErrorStore } from './store'
import { nationality } from './nationalities'
import useLoyaltyStore from '../../../../../Loyalty/store'
import { convertTo } from '../../../../../../functions/convertLang'
import useTransferStore from '../../../store'
import { format } from 'date-fns'
import BirthDateInputKiwi from '../../../../../../components/BirthDateInputKiwi'

const MembersBlock = ({ count, title, valid }) => {
	const paxe = useTransferStore(state =>
		state.travelers.find(item => item.id === count)
	)

	const [typeDoc, setTypeDoc] = React.useState('internationalPassport')

	const [errors, setErrors] = useState({
		// lastName: null,
		firstName: null,
		lastNameLatin: null,
		firstNameLatin: null,
		patronymic: null,
		phone: null,
		email: null,
		series: null,
		number: null,
		birthDay: null,
		expirationDate: null
	})

	const setErrorTouristForm = useErrorStore(state => state.setErrorTouristForm)

	const actions = useTransferStore(({ actions }) => actions)

	const changeFirstNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только латиницу`
			})
			setErrorTouristForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			if (Object.values(errors).every(item => item)) {
				setErrorTouristForm(false)
			}
		}
		actions.changeFirstNameLatin(event.target.value, count)
	}
	const changeLastNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только латиницу`
			})
			setErrorTouristForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			if (Object.values(errors).every(item => item)) {
				setErrorTouristForm(false)
			}
		}
		actions.changeLastNameLatin(event.target.value, count)
	}

	const changePhoneHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		actionsPL.setMemberId(null)
		if (!newPhoneRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Номер телефона указан некорректно`
			})
			setErrorTouristForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			if (Object.values(errors).every(item => item)) {
				setErrorTouristForm(false)
			}
		}
		actions.changePhone(event.target.value, count)
	}
	const changeEmailHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		actionsPL.setMemberId(null)
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Email указан некорректно`
			})
			setErrorTouristForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			if (Object.values(errors).every(item => item)) {
				setErrorTouristForm(false)
			}
		}
		actions.changeEmail(event.target.value, count)
	}

	const changeNationHandler = (e, n) => {
		actions.changeNation(e, n)
	}
	const changeGenderHandler = (e, n) => {
		actions.changeGender(e, n)
	}
	const changeTypeDocHandler = (e, n) => {
		actions.changeDoc(e, n)
		setTypeDoc(e)
	}

	const changeSeriesHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		setErrorTouristForm(false)
		switch (typeDoc) {
			case 'internationalPassport':
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorTouristForm(true)
				}
				break
			case 2:
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 4 цифры`
					})
					setErrorTouristForm(true)
				}
				break
			case 3:
				if (!seriesBirthCertificatesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать римское число и две кириллические буквы, через дефис`
					})
					setErrorTouristForm(true)
				}
				break
			case 4:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorTouristForm(true)
				}
				break
			case 6:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorTouristForm(true)
				}
				break
			case 7:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorTouristForm(true)
				}
				break
			default:
				setErrors({ ...errors, [prop]: null })
				if (Object.values(errors).every(item => item)) {
					setErrorTouristForm(false)
				}
				break
		}
		actions.changeSeries(value, count)
	}

	const changeNumberHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		if (Object.values(errors).every(item => item)) {
			setErrorTouristForm(false)
		}
		switch (typeDoc) {
			case 'internationalPassport':
				if (!interPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 7 цифр`
					})
					setErrorTouristForm(true)
				}
				break
			case 2:
				if (!rfPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorTouristForm(true)
				}
				break
			case 3:
				if (!birthCertNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorTouristForm(true)
				}
				break
			case 4:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorTouristForm(true)
				}
				break
			case 6:
				if (!latinAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
					})
					setErrorTouristForm(true)
				}
				break
			case 7:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorTouristForm(true)
				}
				break
			default:
				setErrors({ ...errors, [prop]: null })
				if (Object.values(errors).every(item => item)) {
					setErrorTouristForm(false)
				}
				break
		}
		actions.changeNumber(value, count)
	}

	const actionsPL = useLoyaltyStore(({ actions }) => actions)

	const isRussianPassportEnabled = true

	const [error, setError] = useState(null)

	useEffect(() => {
		const checkValidationError = paxe => {
			if (!paxe?.birthDay || errors.birthDay) {
				setErrors(errors => ({
					...errors,
					birthDay: `Дата должна иметь формат ДД.ММ.ГГГГ`
				}))
				setErrorTouristForm(true)
			} else {
				setErrors(errors => ({
					...errors,
					birthDay: null
				}))
				setErrorTouristForm(false)
			}
			if (!paxe?.document?.expirationDate || errors.expirationDate) {
				setErrors(errors => ({
					...errors,
					expirationDate: `Дата должна иметь формат ДД.ММ.ГГГГ`
				}))
				setErrorTouristForm(true)
			} else {
				setErrors(errors => ({
					...errors,
					expirationDate: null
				}))
				setErrorTouristForm(false)
			}
			if (
				!latinNameRegExp.test(paxe?.document?.lastNameLatin) ||
				!Boolean(paxe?.document?.lastNameLatin)
				// paxe?.document?.lastNameLatin === '' &&
				// true
			) {
				setErrors(errors => ({
					...errors,
					lastNameLatin: `Фамилия должна содержать только латиницу`
				}))
				setErrorTouristForm(true)
			}
			if (
				!latinNameRegExp.test(paxe?.document?.firstNameLatin) ||
				!Boolean(paxe?.document?.firstNameLatin)
			) {
				setErrors(errors => ({
					...errors,
					firstNameLatin: `Имя должно содержать только латиницу`
				}))
				setErrorTouristForm(true)
			}
			// if (
			// 	!cyrillicRegExp.test(paxe?.document?.lastName) ||
			// 	!Boolean(paxe?.document?.lastName)
			// ) {
			// 	setErrors(errors => ({
			// 		...errors,
			// 		lastName: `Фамилия должна содержать только кириллицу`
			// 	}))
			// 	// setErrorTouristForm(true)
			// }
			if (!newPhoneRegExp.test(paxe?.phones) || !Boolean(paxe?.phones)) {
				setErrors(errors => ({
					...errors,
					phone: `Номер телефона указан некорректно`
				}))
				setErrorTouristForm(true)
			}
			if (!emailRegExp.test(paxe?.email) || !Boolean(paxe?.email)) {
				setErrors(errors => ({
					...errors,
					email: `Email указан некорректно`
				}))
				setErrorTouristForm(true)
			}
			if (
				!cyrillicRegExp.test(paxe?.document?.middleName) &&
				!!paxe?.document?.middleName
			) {
				setErrors(errors => ({
					...errors,
					patronymic: `Отчество должно содержать только кириллицу`
				}))
				// setErrorTouristForm(true)
			}

			switch (typeDoc) {
				case 'internationalPassport':
					if (
						!interPassportSeriesRegExp.test(paxe?.document?.series) ||
						!Boolean(paxe?.document?.series)
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorTouristForm(true)
					}
					break
				case 2:
					if (
						!rfPassportSeriesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 4 цифры`
						}))
						setErrorTouristForm(true)
					}
					break
				case 3:
					if (
						!seriesBirthCertificatesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать римское число и две кириллические буквы, через дефис`
						}))
					}
					break
				case 4:
					if (paxe?.document?.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
					}
					break
				case 6:
					if (
						!interPassportSeriesRegExp.test(paxe?.document?.series) &&
						!!paxe?.document?.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
					}
					break
				case 7:
					if (paxe?.document?.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
					}
					break
				default:
					setErrors(errors => ({ ...errors, series: null }))
					setErrorTouristForm(false)
					break
			}

			switch (typeDoc) {
				case 'internationalPassport':
					if (
						!interPassportNumberRegExp.test(paxe?.document?.number) ||
						!Boolean(paxe?.document?.number)
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 7 цифр`
						}))
						setErrorTouristForm(true)
					}
					break
				case 2:
					if (
						!rfPassportNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
					}
					break
				case 3:
					if (
						!birthCertNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
					}
					break
				case 4:
					if (
						!latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
					}
					break
				case 6:
					if (
						!latinAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
						}))
					}
					break
				case 7:
					if (
						!latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
						!!paxe?.document?.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
					}
					break
				default:
					setErrors(errors => ({ ...errors, number: null }))
					setErrorTouristForm(false)
					break
			}
			if (Object.values(errors).some(item => item)) {
				setErrorTouristForm(true)
			} else {
				setErrorTouristForm(false)
			}
		}
		valid && checkValidationError(paxe)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valid])

	const changeBirthDay = (date, number) => {
		actions.changeBirthDate(date, number)
		setErrors(errors => ({ ...errors, birthDay: null }))
	}

	const changeExpirationDate = (date, number) => {
		actions.changeExpirationDate(date, number)
		setErrors(errors => ({ ...errors, expirationDate: null }))
	}

	const setBirthDateError = value => {
		setErrors({ ...errors, birthDay: 'Дата должна иметь формат ДД.ММ.ГГГГ' })
		setErrorTouristForm(value)
	}

	const setExpirationDateError = value => {
		setErrors({ ...errors, expirationDate: 'Дата должна иметь формат ДД.ММ.ГГГГ' })
		setErrorTouristForm(value)
	}

	const onBlur = (prop, event) => {
		if (prop === 'firstNameLatin') {
			event.target.value = convertTo(
				'translit',
				event.target.value
			).toUpperCase()
			changeFirstNameLatinHandler('firstNameLatin')(event)
		}
		if (prop === 'lastNameLatin') {
			event.target.value = convertTo(
				'translit',
				event.target.value
			).toUpperCase()
			changeLastNameLatinHandler('lastNameLatin')(event)
		}
	}

	return (
		<>
			<Snackbar
				open={error}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert
					onClose={() => setError(null)}
					severity='error'
					sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
			<div className='block-header'>
				<h3 className='title'>
					<span>{title ? title : `Информация о туристе ${count}`}</span>
				</h3>
			</div>
			<Alert severity='error' sx={{ width: '100%' }}>
				Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
				информации о туристе!
			</Alert>
			<div className='members-form'>
				{isRussianPassportEnabled ? (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={paxe?.sex || 'male'}
								options={[
									{ id: 'male', name: 'Муж.' },
									{ id: 'female', name: 'Жен.' }
								]}
								title='Пол'
								count={count}
								onChange={changeGenderHandler}
								required
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-латински'
								variant='outlined'
								fullWidth
								value={paxe?.document?.lastNameLatin || ''}
								onChange={changeLastNameLatinHandler('lastNameLatin')}
								helperText={errors.lastNameLatin}
								error={!!errors.lastNameLatin}
								onBlur={event => onBlur('lastNameLatin', event)}
								required
								title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-латински'
								variant='outlined'
								fullWidth
								value={paxe?.document?.firstNameLatin || ''}
								onChange={changeFirstNameLatinHandler('firstNameLatin')}
								helperText={errors.firstNameLatin}
								error={!!errors.firstNameLatin}
								onBlur={event => onBlur('firstNameLatin', event)}
								required
								title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						{/*<Box style={{ marginBottom: 15 }}>*/}
						{/*	<TextField*/}
						{/*		label='Фамилия по-русски'*/}
						{/*		variant='outlined'*/}
						{/*		fullWidth*/}
						{/*		value={''}*/}
						{/*		onChange={changeLastNameHandler('lastName')}*/}
						{/*		helperText={errors.lastName}*/}
						{/*		error={!!errors.lastName}*/}
						{/*		required*/}
						{/*	// title='В фамилии допустимы только прописные латинские буквы'*/}
						{/*	/>*/}
						{/*</Box>*/}
						{/*<Box style={{ marginBottom: 15 }}>*/}
						{/*	<TextField*/}
						{/*		label='Имя по-русски'*/}
						{/*		variant='outlined'*/}
						{/*		fullWidth*/}
						{/*		value={''}*/}
						{/*		onChange={changeFirstNameHandler('firstName')}*/}
						{/*		helperText={errors.firstName}*/}
						{/*		error={!!errors.firstName}*/}
						{/*		required*/}
						{/*	// title='В имени допустимы только прописные латинские буквы'*/}
						{/*	/>*/}
						{/*</Box>*/}
						{/*<Box style={{ marginBottom: 15 }}>*/}
						{/*	<TextField*/}
						{/*		label='Отчество по-русски'*/}
						{/*		variant='outlined'*/}
						{/*		fullWidth*/}
						{/*		value={''}*/}
						{/*		onChange={changePatronymicHandler('patronymic')}*/}
						{/*		helperText={errors.patronymic}*/}
						{/*		error={!!errors.patronymic}*/}
						{/*		required*/}
						{/*	// title='В о допустимы только прописные латинские буквы'*/}
						{/*	/>*/}
						{/*</Box>*/}
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInputKiwi
								onChange={changeBirthDay}
								count={count}
								label='Дата рождения'
								value={
									paxe?.birthDay
										? format(new Date(paxe?.birthDay), 'dd.MM.yyyy')
										: null
								}
								setDateError={setBirthDateError}
								required
								error={!!errors.birthDay}
								helperText={errors.birthDay}
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Телефон'
								variant='outlined'
								fullWidth
								helperText={errors.phone}
								error={!!errors.phone}
								value={paxe?.phones || ''}
								onChange={changePhoneHandler('phone')}
								required
							/>
						</Box>
						<Box>
							<TextField
								label='E-Mail'
								variant='outlined'
								fullWidth
								helperText={errors.email}
								error={!!errors.email}
								value={paxe?.email || ''}
								onChange={changeEmailHandler('email')}
								required
							/>
						</Box>
					</div>
				) : (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={'male'}
								options={[
									{ id: 'male', name: 'Муж.' },
									{ id: 'female', name: 'Жен.' }
								]}
								title='Пол'
								count={count}
								onChange={changeGenderHandler}
								required
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-латински'
								variant='outlined'
								fullWidth
								value={paxe?.document?.lastNameLatin || ''}
								onChange={changeLastNameLatinHandler('lastNameLatin')}
								helperText={errors.lastNameLatin}
								onBlur={event => onBlur('lastNameLatin', event)}
								error={!!errors.lastNameLatin}
								required
								title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-латински'
								variant='outlined'
								fullWidth
								value={paxe?.document?.firstNameLatin || ''}
								onChange={changeFirstNameLatinHandler('firstNameLatin')}
								helperText={errors.firstNameLatin}
								error={!!errors.firstNameLatin}
								onBlur={event => onBlur('firstNameLatin', event)}
								required
								title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInputKiwi
								onChange={changeBirthDay}
								count={count}
								label='Дата рождения'
								value={
									paxe?.birthDay
										? format(new Date(paxe?.birthDay), 'dd.MM.yyyy')
										: null
								}
								setDateError={setBirthDateError}
								required
								error={!!errors.birthDay}
								helperText={errors.birthDay}
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Телефон'
								variant='outlined'
								fullWidth
								helperText={errors.phone}
								error={!!errors.phone}
								value={paxe?.phones || ''}
								onChange={changePhoneHandler('phone')}
								required
							/>
						</Box>
						<Box>
							<TextField
								label='E-Mail'
								variant='outlined'
								fullWidth
								helperText={errors.email}
								error={!!errors.email}
								value={paxe?.email || ''}
								onChange={changeEmailHandler('email')}
								required
							/>
						</Box>
					</div>
				)}
				<div className='docs'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={paxe?.nationalityId || 210357}
							options={nationality}
							title='Гражданство'
							count={count}
							onChange={changeNationHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={paxe?.document?.type || 'internationalPassport'}
							options={[
								{ id: 5, name: 'Иной документ' },
								{ id: 2, name: 'Паспорт' },
								{ id: 3, name: 'Свидетельство о рождении' },
								{ id: 'internationalPassport', name: 'Заграничный паспорт' },
								{ id: 4, name: 'ID карта' }
							]}
							title='Тип документа'
							count={count}
							onChange={changeTypeDocHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Серия документа'
							variant='outlined'
							fullWidth
							helperText={errors.series}
							error={!!errors.series}
							value={paxe?.document?.series || ''}
							onChange={changeSeriesHandler('series')}
							required
							title='Серия паспорта может содержать только цифры и прописные латинские буквы'
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер документа'
							variant='outlined'
							fullWidth
							helperText={errors.number}
							error={!!errors.number}
							value={paxe?.document?.number || ''}
							onChange={changeNumberHandler('number')}
							required
							title='Номер паспорта должен содержать только цифры и заглавные латинские буквы'
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<BirthDateInputKiwi
							onChange={changeExpirationDate}
							count={count}
							label='Действителен до'
							value={
								paxe?.document?.expirationDate
									? format(
											new Date(paxe?.document?.expirationDate),
											'dd.MM.yyyy'
									  )
									: null
							}
							setDateError={setExpirationDateError}
							isExpiration={true}
							required
							error={!!errors.expirationDate}
							helperText={errors.expirationDate}
						/>
					</Box>
				</div>
			</div>
		</>
	)
}

export default MembersBlock
