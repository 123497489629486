import React from "react"
import styled from "styled-components"
import { Text,Separator,InfoBlock } from "../../ui"
import Position from "./Position"
import { getTotalSum, getProductCompanies } from "./utils"
import formatPrice from "../../../functions/formatPrice"
import AvailableOffersList from "../../AviaBooking/Insurances/Slider"
import { useSelector, useDispatch } from "react-redux"
import { addInsurances, deleteInsurances } from "../../../store/modules/orders"

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`

const Wrapper = styled(InfoBlock)`
  @media (max-width: 767px) {
    padding: 20px 14px;
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`

const List = styled.div`
  & > div {
    padding-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  & > :last-child {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    & > div {
      padding: 15px 0;
    }
    margin-bottom: 5px;
  }
`

const TotalSum = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  & > strong {
    color: #4872f2;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  & > img {
    margin-right: 10px;

    @media (max-width: 767px) {
      height: 20px;
    }
  }
  & > :last-child {
    margin-right: 0;
  }
`

const NotAddedInsurancesOffers = styled.div`
  ${Title} {
    margin-bottom: 15px;
    display: block;
  }

  ${Subtitle} {
    margin-bottom: 20px;
    display: block;
  }

  ${TotalSum} {
    margin-top: 30px;
    display: block;
  }
`

const Insurances = ({ items }) => {
  const companies = getProductCompanies(items)
  const availableInsurancesState = useSelector(
    s => s.orders.orderDetail.availableInsurances
  )

  const dispatch = useDispatch()
  

  return (
    <Wrapper>
      {/* <div> */}
      {items.filter(x => x.paymentStatus.code === "Paid" || x.paymentStatus.code === "PaidByCredit").length > 0 && (
        <>
          <Head>
            <Title>Ваше страхование</Title>
            <IconsContainer>
              {companies.map(x => (
                <img key={x.name} alt={x.name} src={x.logo} />
              ))}
            </IconsContainer>
          </Head>
          <List>
            {items
              .filter(x => x.paymentStatus.code === "Paid" || x.paymentStatus.code === "PaidByCredit")
              .map((x, key) => (
                <Position {...x} key={key} />
              ))}
          </List>
          <TotalSum>
            Общая сумма страховок:{" "}
            <strong>{formatPrice(getTotalSum(items))}</strong>
          </TotalSum>
        </>
      )}
      {availableInsurancesState && availableInsurancesState.items.length > 0 && items.filter(x => x.paymentStatus.code === "Paid" || x.paymentStatus.code === "PaidByCredit").length > 0 && <Separator />}
      {availableInsurancesState && availableInsurancesState.items.length > 0 && (
        <NotAddedInsurancesOffers>
          <Title>Еще больше видов страхования</Title>
          <Subtitle>
            Отдых пройдет спокойнее с поддержкой надежной страховой компании.
            Полис обеспечит вам финансовую защиту в случае болезни, утраты
            багажа, отмены поездки и в других непредвиденных ситуациях.
          </Subtitle>
          <AvailableOffersList
            items={availableInsurancesState.items}
            onChange={(a, payload) => {
              if (payload.checked) {
                dispatch(deleteInsurances([a]))
              } else {
                dispatch(addInsurances([a]))
              }
            }}
            disabled={false}
          />
          <TotalSum>
            Общая сумма страховок:{" "}
            <strong>
              {formatPrice(
                availableInsurancesState.items
                  .filter(x => x.checked)
                  .reduce((acc, x) => acc + x.totalRate.value, 0)
              )}
            </strong>
          </TotalSum>
        </NotAddedInsurancesOffers>
      )}
    </Wrapper>
  )
}

export default Insurances
