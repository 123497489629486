import React, { useEffect } from 'react'
import useSearchTourStore from '../../store'
import MessageSnackbar from './MessageSnackbar'

const ListFilters = ({ tourRef }) => {
	const actions = useSearchTourStore(({ actions }) => actions)
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const {
		momentConfirm,
		hideToursWithoutFreights,
		withoutPromo,
		noStopOfSales,
		freightType
	} = searchParams

	useEffect(() => {
		const filtersFromLocalStorage = localStorage.getItem('filters')
		if (filtersFromLocalStorage) {
			actions.setAllFilters(JSON.parse(filtersFromLocalStorage))
		}
	}, [actions])

	useEffect(() => {
		actions.clearFreights()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const filters = {
			momentConfirm,
			hideToursWithoutFreights,
			withoutPromo,
			noStopOfSales,
			freightType
		}
		localStorage.setItem('filters', JSON.stringify(filters))
	}, [
		momentConfirm,
		hideToursWithoutFreights,
		withoutPromo,
		noStopOfSales,
		freightType
	])

	const [open, setOpen] = React.useState(false)

	const handleClick = () => {
		if (freightType === 2) {
			setOpen(true)
		} else return
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpen(false)
	}

	return (
		<div style={{ width: '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginRight: 10,
					marginBottom: 2
				}}>
				<span>Фильтры</span>
			</div>
			<div className="hotels__container" style={{ height: 120 }}>
				{window.location.pathname !== '/search_hotel' &&
					<div>
						<label htmlFor={'hideToursWithoutFreights'} style={{ display: 'flex', alignItems: "center" }}>
							<input
								id={'hideToursWithoutFreights'}
								type="checkbox"
								checked={hideToursWithoutFreights}
								onChange={(e) => { actions.setFilters(e.target.checked, 'hideToursWithoutFreights') }}
							/>
							есть места на рейсы
						</label>
					</div>
				}
				<div>
					<label
						htmlFor={'noStopOfSales'}
						style={{ display: 'flex', alignItems: 'center' }}>
						<input
							id={'noStopOfSales'}
							type='checkbox'
							checked={noStopOfSales}
							onChange={e => {
								actions.setFilters(e.target.checked, 'noStopOfSales')
							}}
						/>
						нет остановки продажи
					</label>
				</div>
				<div>
					<label
						htmlFor={'momentConfirm'}
						style={{ display: 'flex', alignItems: 'center' }}>
						<input
							id={'momentConfirm'}
							type='checkbox'
							checked={momentConfirm}
							onChange={e => {
								actions.setFilters(e.target.checked, 'momentConfirm')
							}}
						/>
						мгновенное подтверждение
					</label>
				</div>
				<div>
					<label
						htmlFor={'withoutPromo'}
						style={{ display: 'flex', alignItems: 'center' }}>
						<input
							id={'withoutPromo'}
							type='checkbox'
							checked={withoutPromo}
							onChange={e => {
								actions.setFilters(e.target.checked, 'withoutPromo')
							}}
						/>
						не отображать PROMO
					</label>
				</div>
				<div onClick={handleClick}>
					<label
						htmlFor={'freightType'}
						style={{ display: 'flex', alignItems: 'center' }}>
						<input
							id={'freightType'}
							type='checkbox'
							checked={freightType === 1}
							disabled={freightType === 2}
							onChange={e => {
								actions.setFilters(e.target.checked, 'freightType')
							}}
						/>
						не отображать туры GDS
					</label>
				</div>
			</div>
			<MessageSnackbar
				tourRef={tourRef}
				open={open}
				handleClose={handleClose}
			/>
		</div>
	)
}

export default ListFilters
