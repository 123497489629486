import { createAction, createReducer } from "redux-act"
import { combineReducers } from "redux"
import { default as orderDetail } from "./slices/detailOrderSlice"
import { default as orderList } from "./slices/orderListSlice"

export const setLoadingStatus = createAction("@orders/setLoadingStatus")

const status = createReducer({}, null)
status.on(setLoadingStatus, (_, payload) => payload)

export * from "./slices/detailOrderSlice"
export * from "./slices/orderListSlice"
export default combineReducers({
  status,
  orderList,
  orderDetail
})
