import React, {useEffect} from 'react';
import useErskStore from "../store";
import {Box} from "@mui/material";
import {Button} from "@material-ui/core";

const UserNotFound = () => {

	const actions = useErskStore(({ actions }) => actions)
	const userId = useErskStore(({ userId }) => userId)

	useEffect(() => {
		if (userId) {
			actions.uploadContract()
		}
		// eslint-disable-next-line
	}, [userId])

	return (
		<div className={'upload-documents__form'}>
			<Box sx={{margin: '15px auto 30px', width: '100%', textAlign: 'center', fontSize: 16, lineHeight: '22px'}}>
				Клиент по указанным данным не найден.<br/>Возможны следующие варианты действий:
			</Box>
			<Box>
				<Button
					className={'button-upload'}
					variant={'contained'}
					fullWidth
					onClick={actions.updateForm}
				>
					Исправить email/телефон
				</Button>
			</Box>
			<Box mt={1}>
				<Button
					className={'button-signup'}
					fullWidth
					onClick={actions.userSignup}
				>
					Создать новый ЛК клиента
				</Button>
			</Box>
		</div>
	);
};

export default UserNotFound;