import React from 'react';
import styledComponents from "styled-components";
import GalleryFour from "./GalleryFour";
import GalleryThree from "./GalleryThree";
import GallerySeven from "./GallerySeven";

const Title = styledComponents.div`
  	color: #2e2e32;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 32px;
    
    @media (max-width: 992px) {
    	font-size: 24px;
    	line-height: 34px;
    	margin-bottom: 24px;
    }
`;

const AdditionalServicesByLocation = ({ content, title }) => {

	const getTemplate = () => {
		switch (content.template) {
			case 'gallery4':
				return <GalleryFour content={content} count={4} />
			case 'gallery3':
				return <GalleryThree content={content} />
			case 'gallery7':
				return <GallerySeven content={content} />
			case 'gallery8':
				return <GalleryFour content={content} count={8} />
			default:
				return null
		}
	}

	return (
		<div>
			<Title>{title}</Title>
			{getTemplate()}
		</div>
	);
};

export default AdditionalServicesByLocation;