import React from 'react';
import {Text} from '../../ui';
import styled, {keyframes} from 'styled-components';
import { useSelector,useDispatch } from 'react-redux';
import { getPriceDetail, getSelectedFare,setAgentCommissionVisibility } from '../../../store/modules/booking';
import formatPrice from '../../../functions/formatPrice';
import AnimatedNumber from 'animated-number-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #DCDCDC;
  margin: 24px 0;
  @media (max-width: 1024px) {
    margin: 20px 0;
  }
`;

const PassengerPrices = styled(TransitionGroup).attrs({
  className: 'price-list',
  mode: 'ease-in-out',
})`
  overflow: hidden;
  margin: 24px 0;
  & > div, & > button {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  padding: 0 24px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  &.price-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  &.price-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 500ms, opacity 300ms;
  }
  &.price-exit {
    transform: translateX(0);
    opacity: 1;
  }
  &.price-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 500ms, opacity 500ms;
  }
`;

const Wrapper = styled.div``;
const WrapperWithPadding = styled.div`
  padding: 0 24px;
`;

const Title = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const show = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const hide = keyframes`
  to {
    stroke-dashoffset: 23;
  }
`;

const ActionButton = styled.button`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: none;
  background: transparent;
  padding: 0;
  display: flex; 
  align-items: center;
  color: #4872F2;

  & > svg {
    margin-right: 5px;
  }

  .tte-svg-eye {
    .tte-svg-eye-line {
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      &.hide {
        stroke-dashoffset: 0;
        animation: ${hide} 0.3s linear forwards;
      }
      &.show {
        animation: ${show} 0.3s linear forwards;
      }
    }
  }
`;

const PassengerPrice = () => {
  const { total, detailList,subagentTotal,hideAgentCommission } = useSelector(getPriceDetail);
  const fare = useSelector(getSelectedFare);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setAgentCommissionVisibility(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleActionButtonClick = () => {
    dispatch(setAgentCommissionVisibility(!hideAgentCommission));
  }

  const agentCommission = detailList.find(x => x.type === 'subagent-cost');


  return (
    <Wrapper>
      <WrapperWithPadding>
        <Title fontWeight="600" size="big">
          Тариф{' '}
          <Title fontWeight="600" size="big" color="blue">
            {fare.name}
          </Title>
        </Title>
      </WrapperWithPadding>
      <PassengerPrices>
        {detailList.filter(x => x.type !== 'subagent-cost').map((value) => (
          <CSSTransition key={value.id} timeout={500} classNames="price">
            <PriceContainer className="price">
              <Row>
                <Text>{value.description}</Text>
                <Text>
                  {value.quantity > 1 && `${value.quantity} X `}{' '}
                  {formatPrice(value.cost)}
                </Text>
              </Row>
            </PriceContainer>
          </CSSTransition>
        ))}

        <ActionButton type='button' onClick={handleActionButtonClick}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="tte-svg-eye">
          <g id="Frame">
          <path id="Vector" d="M15.9424 8.2215C16.2979 8.6865 16.2979 9.31425 15.9424 9.7785C14.8226 11.2403 12.1361 14.25 8.99964 14.25C5.86314 14.25 3.17664 11.2403 2.05689 9.7785C1.88393 9.55585 1.79004 9.28194 1.79004 9C1.79004 8.71806 1.88393 8.44415 2.05689 8.2215C3.17664 6.75975 5.86314 3.75 8.99964 3.75C12.1361 3.75 14.8226 6.75975 15.9424 8.2215Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
          <path stroke="#4872F2" d="M3 15L15 3"  stroke-linecap="round" stroke-linejoin="round" className={`tte-svg-eye-line ${!hideAgentCommission ? 'show': 'hide'}`}/>
          <path id="Vector_2" d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          </svg>
          <span>{hideAgentCommission ? 'Открыть' : 'Скрыть'} детали для агента</span>
        </ActionButton>
      </PassengerPrices>
      <Separator />
      <WrapperWithPadding>
        <Row>
          <Text fontWeight="600" color="#3C3C3C">
            Итог
          </Text>
          <Text fontWeight="600" color="blue">
            <AnimatedNumber
              duration={500}
              value={total}
              formatValue={(value) => formatPrice(value)}
            />
          </Text>
        </Row>
        { !hideAgentCommission && (<Row>
            <Text fontWeight="600" color="#3C3C3C">
              Сумма оплаты для агента
            </Text>
            <Text fontWeight="600" color="blue">
              <AnimatedNumber
                duration={500}
                value={subagentTotal}
                formatValue={(value) => formatPrice(value)}
              />
            </Text>
          </Row>)}

        {agentCommission && !hideAgentCommission && (
          <CSSTransition key={agentCommission.id} timeout={500} classNames="price">
            <PriceContainer className="price">
              <Row>
                <Text>{agentCommission.description}</Text>
                <Text>
                  {agentCommission.quantity > 1 && `${agentCommission.quantity} X `}{' '}
                  {formatPrice(agentCommission.cost)}
                </Text>
              </Row>
            </PriceContainer>
          </CSSTransition>
        )}
        
      </WrapperWithPadding>
    </Wrapper>
  );
};

export default PassengerPrice;
