import PaxesBlock from '../../../components/Services/PaxesBlock'
import ServiceInfo from './components/ServiceInfo'
import { Button } from '@material-ui/core'
import { useState } from 'react'
import BuyerBlock from '../../../components/Services/BuyerBlock'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { checkValidationError, checkValidationBuyerError } from '../../../components/Services/PaxesBlock/checkValidationError'
import { Alert, Snackbar } from '@mui/material'
import CustomModal from '../../../components/CustomModal'
import { useNavigate } from 'react-router-dom'
import BookingSuccessPopup from './components/BookingSuccessPopup'
import useAirportServiceBookingStore from './store'
import {useErrorStore} from '../../../components/Services/PaxesBlock/store';

function AirportServicesBooking() {
	const loading = useAirportServiceBookingStore(({ loading }) => loading)
	const paxes = useAirportServiceBookingStore(({ paxes }) => paxes)
	const excursion = useAirportServiceBookingStore(({ excursion }) => excursion)
	const actions = useAirportServiceBookingStore(({ actions }) => actions)
	const buyer = useAirportServiceBookingStore(({ buyer }) => buyer)
	const store = useAirportServiceBookingStore((state) => state)
	const additional = useAirportServiceBookingStore(
		({ additional }) => additional
	)

	// const isReadyForBooking = useAirportServiceBookingStore(
	// 	({ isReadyForBooking }) => isReadyForBooking
	// )
	const isBookingError = useAirportServiceBookingStore(
		({ isBookingError }) => isBookingError
	)
	const bookingLoadingError = useAirportServiceBookingStore(
		({ bookingLoadingError }) => bookingLoadingError
	)
	const orderId = useAirportServiceBookingStore(({ orderId }) => orderId)
	const navigate = useNavigate()
	// const errorForm = useErrorStore(state => state.error)
	const checkValid = useErrorStore(state => state.checkValid)


	const backToSearch = () => {
		actions.clearError()
		navigate('/searchairportservices')
	}

	const [errors, setErrors] = useState(null)

	useEffect(() => {
		actions.getExcursion()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!excursion) {
		return
	}

	const handleBooking = () => {
		checkValid()
		actions.clearError()
		let error = {} 
		let paxCountWithError = []
		paxes?.forEach((paxe, index) => {
			const paxError = checkValidationError(paxe)
			if (paxError) {
				if (!paxCountWithError.includes(index + 1)) {
                    paxCountWithError.push(index + 1);
                }
				error = { ...error, ...paxError }
			}
		})

		const buyerErrors = checkValidationBuyerError(buyer)

		if (!isEmpty(buyerErrors)) {
			error = { ...error, ...buyerErrors }
		}

		if (!isEmpty(error)) {
			setErrors(error)
			document.getElementById(`info-${paxCountWithError[0]}`)?.scrollIntoView({ behavior: 'smooth' })
			return
		}

		// actions.changeIsReadyForBooking(true)
		actions.reserve()
	}


	if (bookingLoadingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingLoadingError}
				successButtonTitle='Понятно'
				onSuccess={backToSearch}
			/>
		)
	}

	return (
		<div className='container'>
			<div>
				<ServiceInfo excursion={excursion} additional={additional} />
				{!isEmpty(paxes) && (
					<>
						<div>
							{paxes?.map((paxe, i) => (
								<PaxesBlock key={paxe + i} count={i + 1}  store={store}/>
							))}
						</div>
						<div>
							<BuyerBlock store={store} />
						</div>
					</>
				)}
			</div>

			<div
				style={{
					textAlign: 'center',
					marginBottom: 20,
					fontSize: 24,
					fontWeight: 600
				}}>
				{Math.ceil(excursion?.price).toLocaleString('ru', {})}{' '}
				{excursion.currency.currencyAlias}
			</div>
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				{/* <Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={handleRecountDraft}
					disabled={loading}>
					Пересчитать
				</Button> */}
				<Button
					variant='contained'
					onClick={handleBooking}
					disabled={loading}
				>
					Бронировать
				</Button>
			</div>
			{errors && (
				<Snackbar
					open={Object.values(errors).some(item => item !== '')}
					autoHideDuration={6000}>
					<Alert
						onClose={() => {
							setErrors(null)
						}}
						severity='error'
						sx={{ width: '100%' }}>
						{Object.values(errors).filter(item => item !== '')[0]}
					</Alert>
				</Snackbar>
			)}
			{orderId && <BookingSuccessPopup claim={orderId} />}
			{isBookingError && (
				<CustomModal
					title='Ошибка бронирования'
					text={isBookingError}
					successButtonTitle='Понятно'
					onSuccess={() => actions.clearError()}
				/>
			)}
		</div>
	)
}

export default AirportServicesBooking
