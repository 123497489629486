import {
  call,
  all,
  takeLatest,
  put,
  select,
  spawn,
  delay,
  race,
  take,
} from "redux-saga/effects"
import {
  setLoadingStatus,
  getList,
  setList,
  getDetail,
  setMessageList,
  setMessageStatus,
  sendMessage,
  setDetail,
  addAppealRequest,
  addAppealFailure,
  addAppealSuccess,
  getHistoryRequest,
  getHistorySuccess,
  cancelAppealRequest,
  cancelAppealSuccess,
  detailPurify,
  sendMessageSuccess,
  getAppealsListNext,
  applyCalculationRequest,
  applyCalculationSuccess,
  applyCalculationFailure,
  declineCalculationRequest,
  declineCalculationSuccess,
  declineCalculationFailure
} from "./duck"
import { STATUS } from "./constants"
import * as Manager from "./manager"
import { getDataForAddTicketBody } from "../orders"
import { refreshOrderWorker } from "../orders/sagas"
import { getAppealDetail } from "./selectors"
import moment from "moment"
import { getAppealReferencesRequestWorker } from "../references"

/**
 * Create new support ticker
 * @param param0 Action<CreatePayload>
 */
export function* addAppealRequestWorker({ payload }) {
  try {
    if (payload?.orderPositionId) {
      const order = yield select(getDataForAddTicketBody)

      const body = {
        ...payload,
        orderId: order.id
      }
      const data = yield call(Manager.addTicket, body)
      yield put(addAppealSuccess(data))
    } else if (!payload?.orderPositionId) {
      const data = yield call(Manager.addTicket, payload)
      yield put(addAppealSuccess(data))
    }
  } catch (e) {
    yield put(addAppealFailure())
  }
}

export function* getListWorker({
  payload: { page, pageSize, status, subject, dateSorting, number }
}) {
  yield spawn(getAppealReferencesRequestWorker)

  try {
    const data = yield call(Manager.getCustomerTickets, {
      page: setPage(page),
      pageSize: setPageSize(pageSize),
      status,
      subject,
      dateSorting,
      number: Number(number)
    })

    yield put(setList({ ...data, currPage: setPage(page) }))
    yield put(setLoadingStatus(STATUS.success))
  } catch (e) {
  }
}

export function* getAppealsListNextWorker(action) {
  try {
    const { currPage, pageCount, tickets } = yield select(
      state => state.appeals.list
    )
    if (currPage === pageCount - 1) return
    const params = {
      page: currPage + 1,
      pageSize: 10,
      ...action.payload
    }

    const data = yield call(Manager.getCustomerTickets, params)
    yield put(
      setList({
        ...data,
        tickets: [...(tickets || []), ...(data.tickets || [])],
        currPage: currPage + 1
      })
    )
  } catch (e) {
  }
}

export function* getDetailWorker({ payload }) {
  while (true) {
    yield put(setLoadingStatus(STATUS.loading))
    try {
      const { messages, ...detail } = yield call(
        Manager.getTicketByNumber,
        payload
      )

      messages
        .filter(value => !value.owner && value.status !== "Read")
        .forEach(value => {
          Manager.setRead(value.id)
        })

      const calculation =
        detail.calculations.length === 0
          ? null
          : {
              ...detail.calculations.sort((a, b) =>
                moment(a.createdDate).diff(moment(b.createdDate))
              )[detail.calculations.length - 1],
              isLoading: false,
              error: null
            }

      for (const c of detail.calculations) {
        if (!c.isRead) {
          yield spawn(Manager.setCalculationRead, c.id)
        }
      }

      yield put(
        setDetail({
          ...detail,
          calculation: calculation
        })
      )

      yield put(setMessageList(messages))
      yield put(setLoadingStatus(STATUS.success))
    } catch (e) {
      console.log(e);
    }
    yield delay(5000)
  }
}

export function* sendMessageWorker({ payload }) {
  try {
    yield put(setMessageStatus(STATUS.loading))
    const { id, number } = yield select(getAppealDetail)
    yield call(Manager.sendMessage, payload.message, id, payload.files)
    const { messages } = yield call(Manager.getTicketByNumber, number)
    yield put(sendMessageSuccess(true))
    yield put(setMessageList(messages))
    yield put(setMessageStatus(STATUS.success))
  } catch (e) {
    yield put(setMessageStatus(STATUS.failure))
  }
}

function* getHistoryRequestWorker() {
  const state = yield select(getAppealDetail)
  try {
    const data = yield call(Manager.getHistory, state.id)

    yield put(getHistorySuccess(data.event))
  } catch (e) {
  }
}

function* cancelAppealRequestWorker({ payload }) {
  try {
    yield call(Manager.cancelAppeal, payload)
    yield put(cancelAppealSuccess())
  } catch (e) {
    
  }
}

function* applyCalculationRequestWorker({ payload }) {
  console.log(payload)
  try {
    yield call(Manager.applySupportTicketCalculation, payload.id)
    if(payload.page === 'order') {
      yield call(refreshOrderWorker)
    } 
    yield put(applyCalculationSuccess())
  } catch (e) {
    console.log(e);
    yield put(applyCalculationFailure())
    if(payload.page === 'order') {
      yield call(refreshOrderWorker)
    } 
  }
}

function* declineCalculationRequestWorker({ payload }) {
  try {
    yield call(Manager.declineSupportTicketCalculation, payload.id)
    if(payload.page === 'order') {
      yield call(refreshOrderWorker)
    } 
    yield put(declineCalculationSuccess())
  } catch (e) {
    console.log(e);
    yield put(declineCalculationFailure())
    if(payload.page === 'order') {
      yield call(refreshOrderWorker)
    } 
  }
}


export default function* appealFlow() {
  yield all([
    takeLatest(addAppealRequest.getType(), addAppealRequestWorker),
    // takeLatest(getList.getType(), getListWorker),
    takeLatest(getDetail.getType(), function*(action) {
      yield race([call(getDetailWorker, action), take(detailPurify.getType())])
    }),
    takeLatest(sendMessage.getType(), sendMessageWorker),
    takeLatest(getHistoryRequest.getType(), getHistoryRequestWorker),
    takeLatest(cancelAppealRequest.getType(), cancelAppealRequestWorker),
    // takeLatest(getAppealsListNext.getType(), getAppealsListNextWorker),
    takeLatest(applyCalculationRequest.getType(), applyCalculationRequestWorker),
    takeLatest(declineCalculationRequest.getType(), declineCalculationRequestWorker),
  ])
}

function setPage(page) {
  return page ? page : 0
}

function setPageSize(pageSize) {
  return pageSize ? pageSize : 0
}
