import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { IconButton } from '@mui/material'
import { format, parseISO } from 'date-fns'
import React from 'react'

export default function TouristPassportItem({
	passportSerie,
	passportNumber,
	expirationDate
}) {
	const [showPassword, setShowPassword] = React.useState(true)

	const handleClickShowPassword = () => {
		setShowPassword(show => !show)
	}
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<IconButton
				size='small'
				aria-label='toggle password visibility'
				onClick={handleClickShowPassword}>
				{showPassword ? (
					<VisibilityOffOutlinedIcon />
				) : (
					<VisibilityOutlinedIcon />
				)}
			</IconButton>
			<div>
				{expirationDate && (
					<span
						style={{
							opacity: showPassword ? 0 : 1,
							marginLeft: '5px'
						}}>
						{passportSerie} {passportNumber} до{' '}
						{format(parseISO(expirationDate), 'dd.MM.yyyy')}
					</span>
				)}
			</div>
		</div>
	)
}
