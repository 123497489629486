import man1 from "../../../../../asset/images/avia/avia-passengers/man-1.svg"
import man2 from "../../../../../asset/images/avia/avia-passengers/man-2.svg"
import man3 from "../../../../../asset/images/avia/avia-passengers/man-3.svg"
import woman1 from "../../../../../asset/images/avia/avia-passengers/woman-1.svg"
import woman2 from "../../../../../asset/images/avia/avia-passengers/woman-2.svg"
import woman3 from "../../../../../asset/images/avia/avia-passengers/woman-3.svg"
import boy1 from "../../../../../asset/images/avia/avia-passengers/boy-1.svg"
import boy2 from "../../../../../asset/images/avia/avia-passengers/boy-2.svg"
import girl1 from "../../../../../asset/images/avia/avia-passengers/girl-1.svg"
import girl2 from "../../../../../asset/images/avia/avia-passengers/girl-2.svg"

import moment from "moment"

const MEN_ICONS = [man1, man2, man3]
const BOYS_ICONS = [boy1, boy2]
const WOMEN_ICONS = [woman1, woman2, woman3]
const GIRLS_ICONS = [girl1, girl2]

let lastImage = null

export function generateIconPath(gender, birthDate) {
  const images = generateImageMassive(gender, calculateAge(birthDate))
  let number

  do {
    number = generateNumber(images.length)
  } while (images[number] === lastImage)

  lastImage = images[number]
  return lastImage
}

function calculateAge(date) {
  const birthDate = moment(date)
  const duration = moment.duration(moment().diff(birthDate))
  return duration.years()
}

function generateNumber(length) {
  return Math.floor(Math.random() * length)
}

function generateImageMassive(gender, age) {
  switch (gender) {
    case "Male":
      return age >= 18 ? MEN_ICONS : BOYS_ICONS
    default:
      return age >= 18 ? WOMEN_ICONS : GIRLS_ICONS
  }
}
