import { Box, styled, Tab, Tabs } from "@material-ui/core"

import PropTypes from 'prop-types'

export const MenuTabs = styled(Tabs)({
	borderRight: '1px solid #DCDCDC',
	width: 270,
	flexShrink: 0,
	'& .MuiTabs-indicator': {
		backgroundColor: '#3C3C3C',
	},
	'& .MuiTab-wrapper': {
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'center',
		marginLeft: '50px'
	},
	'.MuiTab-wrapper:first-child': {}
})

export const MenuTab = styled(props => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		width: '270px',
		minWidth: 0,
		[theme.breakpoints.up('sm')]: {
			minWidth: 0
		},
		fontWeight: 600,
		fontSize: 14,
		lineHeight: '20px',
		padding: '0px',
		height: '24px',
		color: '#B3B9C0',
		fontFamily: [
			'Open Sans', 'sans-serif'
		].join(','),
		'&:hover': {
			color: '#3C3C3C',
			backgroundColor: '#F6F8FA',
			opacity: 1
		},
		'&.Mui-selected': {
			color: '#3C3C3C',
			backgroundColor: '#F6F8FA',
			fontWeight: 600
		},
		'&.Mui-focusVisible': {
			backgroundColor: '#4872F2'
		}
	})
)

export function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

export function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	}
}
