import React from 'react';

import styled from 'styled-components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@mui/material/Collapse';

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3c3c3c;
    padding: 32px 24px 32px 0;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const Separator = styled.div`
    height: 1px;
    width: 100%;
    background: ${({ isActive }) => (isActive ? '#4872F2' : '#dcdcdc')};
`;

const TabelContacts = styled.div`
    width: 100%;
    background: #f6f8fa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    margin-bottom: 32px;
`;
const TabelContactsHead = styled.div`
    border-bottom: 1px solid #dbe0e5;
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
`;

const TabelContactsTitle = styled.div`
    width: 50%;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`;

const TabelContactsRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const TabelContactsText = styled.div`
    width: 50%;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    padding-top: 16px;
`;
const GoldClubCurators = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [hover, setHover] = React.useState(false);

    return (
        <>
            <Row
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer' }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            >
                <FlexRow>
                    <Title>Кураторы сетевых агентств (sales7@fstravel.com)</Title>
                </FlexRow>
                <ExpandMoreIcon
                    onClick={() => setIsOpen(!isOpen)}
                    style={isOpen ? { transform: 'rotate(180deg)' } : null}
                    isActive={isOpen}
                />
            </Row>
            <Collapse in={isOpen}>
                <div>
                    <TabelContacts>
                        <TabelContactsHead>
                            <TabelContactsTitle>
                                ФИО кураторов
                            </TabelContactsTitle>
                            <TabelContactsTitle>
                                Добавочный номер
                            </TabelContactsTitle>
                        </TabelContactsHead>
                        <TabelContactsRow>
                            <TabelContactsText>
							Леонова Ольга
                            </TabelContactsText>
                            <TabelContactsText>5615</TabelContactsText>
                        </TabelContactsRow>
                        <TabelContactsRow>
                            <TabelContactsText>
							Ольга Бруцкая-Стемпковская 
                            </TabelContactsText>
                            <TabelContactsText>5641</TabelContactsText>
                        </TabelContactsRow>
                    </TabelContacts>
                </div>
            </Collapse>
            <Separator isActive={hover} />
        </>
    );
};

export default GoldClubCurators;
