import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React from "react";
import styledComponents, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styledComponents.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin: auto;
  border-top: 2px solid ${({color}) => color || 'grey'};
  border-right: 2px solid ${({color}) => color || 'grey'};
  border-bottom: 2px solid ${({color}) => color || 'grey'};
  border-left: 2px solid lightgray;
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  @media(max-width: 1124px) {
      width: 16px;
      height: 18px;
      margin: auto;

      border-top: 1px solid grey;
      border-right: 1px solid grey;
      border-bottom: 1px solid grey;
      border-left: 1px solid lightgray;
    }
`;

const SpinnerContainer = styledComponents.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CustomButton = styled(Button)(({theme,...props}) => {
    const color = props.color || 'primary';
    return {
        fontFamily: "Open Sans",
        letterSpacing: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        padding: '7px 16px',
        textTransform: 'none',
        borderColor: color === 'gray' ? theme.palette[color].light : 'currentColor',
        color: props.loading ? 'transparent': undefined,
        border: props.loading ? '1px solid #4872f2': undefined,

        '& .MuiButton-startIcon': {
            pointerEvents: 'none',
        },

        '&:hover': {
            color: props.loading ? 'transparent': theme.palette[color].dark,
            border: props.loading ? '1px solid #4872f2': '1px solid currentColor',
        },
    };
});

function OutlinedButton({variant,children,...props}) {
    return (
        <CustomButton variant='outlined' {...props}>
            {children}
            {props.loading && (
              <SpinnerContainer>
                  <Spinner color={props.spinnerColor} />
              </SpinnerContainer>
            )}
        </CustomButton>
    )
}

export default OutlinedButton;
