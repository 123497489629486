import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from '../../components/ui';
import {ReactComponent as FireIcon} from '../../asset/images/avia/main/fire.svg';
import {ReactComponent as RedCircleIcon} from '../../asset/images/avia/main/red-circle.svg';
import {NavLink} from 'react-router-dom';
import formatPrice from "../../functions/formatPrice";
import getAirlineLogo from "../../functions/getAirlineLogo";
import axios from "axios";
// import {useSelector} from 'react-redux';
// import ReactGA from 'react-ga4';

const Wrapper = styled.div`
  background: #fef5f5;
  margin-top: 65px;
  padding: 45px;
  position: relative;
  border-radius: 16px 0 16px 16px;

  @media (max-width: 1169px) {
    padding: 30px 50px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    margin: 0 20px;
    border-radius: 16px;
  }
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  & > span {
    display: block;
  }
`;

const GrayText = styled(Text)`
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #7E8389;
    z-index: 2;
`;

const Title = styled(Text)`
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2E2E32; 
    z-index: 2;
    position: relative;

    @media(max-width: 1169px) {
        font-size: 20px;
        line-height: 26px;
    }
`;

const Fire = styled(FireIcon)`
  position: absolute;
  top: -76px;
  right: 44px;
  z-index: 1;

  @media (max-width: 1169px) {
    width: 156px;
    height: 156px;
    top: -55px;
    right: 25px;
  }

  @media (max-width: 767px) {
    width: 108px;
    height: 108px;
    top: -31px;
    right: -10px;
  }
`;

const RedCircle = styled(RedCircleIcon)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 1169px) {
    display: none;
  }

  @media (min-width: 768px) {
    widht: 251px;
    height: 251px;
  }
`;

const RedCircleMobileWrapper = styled.div`
  width: 194px;
  height: 194px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  @media (min-width: 1170px) {
    display: none;
  }

  @media (min-width: 768px) {
    widht: 251px;
    height: 251px;
  }
`;

const RedCircleMobile = styled.div`
  border-radius: 50%;
  background: #d40e14;
  opacity: 0.1;
  position: absolute;
  z-index: 1;
  right: -59%;
  top: -33px;
  width: 194px;
  height: 194px;
  @media (min-width: 768px) {
    width: 251px;
    height: 251px;
    right: -35%;
    top: -72px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 37px;

  @media (max-width: 1169px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 27px;

    & > :nth-child(5),
    > :nth-child(6) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: block;
    & > a {
      margin-bottom: 20px;
    }
    margin-bottom: -20px;
  }
`;

const Flight = styled(NavLink)`
  padding: 16px;
  box-sizing: border-box;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid rgba(223, 227, 248, 1);
  transition: 0.2s;
  &:hover {
    border-color: rgb(184 191 231);
    box-shadow: 0 0 5px 3px rgb(0, 0, 0, 5%);
  }
`;

const FlightInfo = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 4px;
  }
`;

const FlightName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3c3c3c;
`;

const FlightInfoText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #737373;

  & > strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #4872f2;
  }
`;

export default function AviaSpecialOffers () {
  const [items, setItems] = useState([])

  const getPromotions = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_GET_AVIA_PROMOTIONS,
        method: 'GET',
      })
      setItems(res.data.data)
    } catch (e) {
      console.log('error', e)
    }
  }


  React.useEffect(() => {
    getPromotions()
  }, [])

  // const items = useSelector((s: ApplicationState) => s.mainReducer.promotions.data);

  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <RedCircle/>
      <RedCircleMobileWrapper>
        <RedCircleMobile/>
      </RedCircleMobileWrapper>
      <Fire/>
      <Container>
        <GrayText>Не упустите шанс</GrayText>
        <Title>Спецпредложения авиакомпаний</Title>
        <Grid>
          {items.map((item, key) => (
            <Flight
              key={key}
              to={`/avia${item.url}`}
              // onClick={() => {
              //   ReactGA.event({
              //     category: 'promotions',
              //     action: 'select_promotion',
              //   });
              // }}
            >
              <FlightInfo>
                <FlightName>{item.fromTo}</FlightName>
                <FlightInfoText>
                  {item.date} —{' '}
                  <strong> от {formatPrice(item.price)}</strong>
                </FlightInfoText>
              </FlightInfo>
              <img
                width={64}
                height={48}
                src={getAirlineLogo(item.code, {width: 128,height: 96,styleType: true})}
                alt={item.code}
              ></img>
            </Flight>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
}
