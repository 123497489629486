import { useState } from 'react'

import './style.scss'
import Calendar from '../../../../asset/icons/ExcursionTour/Calendar'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import declination from '../../../../functions/declination'
import { isEmpty } from 'lodash'
import ResultCardModal from './ResultCardModal'

function ResultCard({
	otherParams,
	title,
	price,
	startDate,
	country,
	city,
	child,
	adults,
	item
}) {
	const [showModal, setShow] = useState(false)
	const description = otherParams?.descriptions?.at(1)

	return (
		<div className='HotelServices-result-card'>
			<div className='HotelServices-result-card__wrapper-photo'>
				{item?.image_url && (
					<img src={item?.image_url} alt={title} loading='lazy' />
				)}
				{item?.image_url && <div className='image-count'>1 фото</div>}
			</div>
			<div className='HotelServices-result-card__info-block'>
				<div>
					<h4 className='heading-4'>{title}</h4>
					<p className='text-l'>
						{country?.name}, {city?.name}
					</p>
				</div>
				<div>
					<div className='HotelServices-result-card__filters'>
						{otherParams?.serviceType?.map(i => (
							<p className='text-l HotelServices-result-card__filters-item'>{i.name}</p>
						))}
					</div>
				</div>

				<div style={{ flexShrink: 1 }}>
					<div
						style={{
							display: 'flex',
							gap: '8px',
							marginBottom: '8px',
							alignItems: 'center'
						}}>
						<Calendar />
						<p className='text-l' style={{ color: '#2E2E32' }}>
							{format(new Date(startDate), 'dd MMMM yyyy', { locale: ru })}
						</p>
					</div>
					<p
						className='text-l text-overflow'
						dangerouslySetInnerHTML={{ __html: description?.text }}></p>
				</div>

				<div className='HotelServices-result-card__price'>
					<div>
						<h4 className='heading-4'>от {Math.ceil(price?.value).toLocaleString('ru')} ₽</h4>
						<p className='text-l'>
							За {adults} {declination(adults, ['взрослого', 'взрослых', 'взрослых'])}{' '}
							{!isEmpty(child) &&
								'・ ' +
									child.length +
									' ' +
									declination(Number(child.length), ['ребенка', 'детей', 'детей'])}
						</p>
					</div>

					<div className='HotelServices-result-card__btn' onClick={() => setShow(!showModal)}>
						Подробнее
					</div>
				</div>
			</div>
			{showModal && (
				<ResultCardModal
					otherParams={otherParams}
					title={title}
					price={price}
					startDate={startDate}
					item={item}
					adults={adults}
					open={showModal}
					child={child}
					onClose={() => setShow(!showModal)}
				/>
			)}
		</div>
	)
}

export default ResultCard
