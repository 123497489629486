import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/ui';
import Dialog from '@mui/material/Dialog';
import {Close} from "@material-ui/icons";
import {
  getNotification,
  closeNotification,
} from '../../store/modules/notification';
import { url } from '../../store/modules/booking/utils';
import { bookFlightRequest } from '../../store/modules/booking';
import formatPrice from "../../functions/formatPrice";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border: 1px solid #d7d7d7;
    border-radius: 16px;
  }
`;

const Comment = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > span {
    text-align: center;
  }

  @media (max-width: 767px) {
    & > span {
      text-align: left;
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: start;
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #3c3c3c;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
  }
`;

const Wrapper = styled.div`
  width: 569px;
  min-height: 261px;
  padding: 40px 30px;
  box-sizing: border-box;
  border-radius: 8px;

  & > ${Comment} {
    margin-top: 25px;
  }

  & > ${ButtonBlockWrapper} {
    margin-top: 30px;
  }
`;

const Cross = styled(Close)`
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
`;

const PriceBlock = ({data}) => {
  return (
    <>
      <Text style={{marginTop:20}}>
        <span style={{fontWeight:'bold'}}>{' '}Старая стоимость перелета за всех пассажиров:{' '}
          <span style={{color:'#737373'}}>{formatPrice(data.OldPrice)}</span>{' '}
        </span>
      </Text>
      <Text style={{marginTop:10}}>
        <span style={{ fontWeight: 'bold' }}>{' '}Новая стоимость перелета за всех пассажиров:{' '}
          <span style={{ color: '#4872F2' }}>{formatPrice(data.NewPrice)}</span>{' '}
        </span>
      </Text>
    </>
  )
}

const BodyBlock = ({data, body}) => {
  const hasPriceChanged = data?.OldPrice !== data?.NewPrice

  return (
    <Comment>
      {!data?.NewPrice && <Text>{data?.message || body}</Text>}

      {data?.BookingType === 'GetOfferWithRightPassengerTypesAndBook_12years' &&
        <Text>
          Ребенок-пассажир должен быть <span style={{fontWeight:'bold'}}>старше 2 и младше 12 лет</span><br/>
          на момент первого отправления.
        </Text>
      }
      {data?.BookingType === 'GetOfferWithRightPassengerTypesAndBook_14years' &&
        <Text>
          Для некоторых лоукостеров взрослый пассажир должен<br />
          быть <span style={{fontWeight:'bold'}}>старше 14 лет</span> на момент первого отправления. Для<br />
          пассажиров младше тип будет изменен на ребенка.
        </Text>
      }
      {data?.BookingType === 'GetActualOfferAndBook' &&
        <Text>
          Места по ранее выбранному тарифу закончились.<br />Стоимость перелета изменилась.
        </Text>
      }
      {hasPriceChanged && <PriceBlock data={data}/>}
    </Comment>
  )
}

const ButtonBlock = ({data, body, handleBooking, handleNewSearch}) => {
  const hasPriceChanged = data?.OldPrice !== data?.NewPrice

  return (
    <ButtonBlockWrapper>
      {hasPriceChanged && (data?.BookingType === 'GetActualOfferAndBook' ||
        data?.BookingType === 'GetOfferWithRightPassengerTypesAndBook_12years' ||
        data?.BookingType === 'GetOfferWithRightPassengerTypesAndBook_14years') &&
        <>
          <Button type="primary" style={{padding:'8px 20px',fontWeight:600,fontSize:14,lineHeight:'18px'}} onClick={handleBooking}>
            Продолжить бронирование
          </Button>
          <Button type="outlined" style={{marginLeft:20,padding:'8px 20px',fontWeight:600,fontSize:14,lineHeight:'18px'}} onClick={handleNewSearch}>
            Новый поиск
          </Button>
        </>
      }

      {!hasPriceChanged && data?.BookingType &&
        <Button type="primary" onClick={handleBooking}>Продолжить</Button>
      }

      {(data?.message === 'Не удалось забронировать заявку. Пожалуйста, повторите поиск и создайте бронирование повторно' ||
        body === 'Не удалось забронировать заявку. Пожалуйста, повторите поиск и создайте бронирование повторно' ||
        data?.message === 'Истек срок действия предложения. Информация будет обновлена') &&
        !data?.BookingType &&
        <Button type="primary" onClick={handleNewSearch}>Попробовать новый поиск</Button>
      }
    </ButtonBlockWrapper>
  )
}

const ErrorNotification = () => {
  const { label, body, searchRequest, data, booking } = useSelector(getNotification);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    dispatch(closeNotification({ searchRequest: searchRequest }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewSearch = useCallback(() => {
    if (searchRequest) {
      handleClose();
      navigate(`/avia/search/${url(searchRequest)}`);
      window.location.reload();
    } else {
      handleClose();
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleBooking = () => {
    const newBooking = {
      ...booking,
      OfferPrice: data?.NewPrice,
      BookingType: data?.BookingType,
    };
    dispatch(bookFlightRequest(newBooking));

    handleClose();
  }

  return (
    <StyledPopup open={true} onClose={handleClose}>
      <Wrapper>
        <Cross onClick={handleClose} />
        <Heading>{label}</Heading>

        <BodyBlock data={data} body={body} />

        <ButtonBlock
          data={data}
          body={body}
          handleBooking={handleBooking}
          handleNewSearch={handleNewSearch}
        />

      </Wrapper>
    </StyledPopup>
  );
};

export default ErrorNotification;
