import React from 'react';

const Logo = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip92076)">
        <path d="M23.1663 12.408C23.2773 12.1196 23.298 11.8044 23.2258 11.504C23.1535 11.2036 22.9916 10.9322 22.7615 10.726L19.9779 8.18468C19.6791 7.91273 19.3253 7.70812 18.9406 7.58468C18.5559 7.46125 18.1491 7.42187 17.7478 7.46922L4.36119 8.8938L1.78435 7.26123C1.57654 7.13113 1.33303 7.06981 1.08839 7.08598C0.843756 7.10215 0.610434 7.195 0.421567 7.35132C0.2327 7.50765 0.0978908 7.71951 0.0362865 7.95682C-0.0253177 8.19412 -0.0105848 8.44481 0.0783935 8.67326L1.56166 12.3756C1.65862 12.9602 1.91977 13.0096 9.00936 13.1851L6.56063 18.5929C6.48846 18.7373 6.45875 18.8992 6.47498 19.0598C6.4912 19.2205 6.55268 19.3732 6.65227 19.5003C6.72431 19.591 6.81326 19.667 6.9142 19.7239C7.11976 19.8344 7.35836 19.8666 7.58588 19.8148L9.56751 19.3802C9.7659 19.3351 9.94465 19.2277 10.0776 19.0737L14.9985 13.2973C17.5646 13.3305 19.9899 13.3693 21.7284 13.3809C22.0386 13.3844 22.3425 13.2931 22.5994 13.1193C22.8564 12.9454 23.0542 12.6972 23.1663 12.408ZM21.7363 12.1255C21.6915 12.1252 21.6462 12.1248 21.6005 12.1245C19.8076 12.1119 17.3415 12.0946 14.7202 12.0359L14.4254 12.0322L9.18702 18.1829L7.96845 18.4498L10.9144 11.9676L9.97878 11.9199C4.83034 11.8441 3.16854 11.7246 2.6103 11.663L1.34416 8.46265L4.03422 10.1751L17.9056 8.72619C18.1256 8.70003 18.3486 8.72059 18.5601 8.78655C18.7716 8.8525 18.9668 8.96236 19.1329 9.10893L21.9151 11.6276C21.9589 11.6648 21.9897 11.715 22.0031 11.7708C22.0164 11.8267 22.0115 11.8854 21.9892 11.9383C21.9727 11.9926 21.9393 12.0401 21.8937 12.0738C21.8482 12.1075 21.793 12.1256 21.7363 12.1255Z" />
        <path d="M6.16752 5.76545L7.78745 7.42488L9.05132 7.29282L7.24258 5.40852L8.56512 5.37552L11.5862 7.00385L13.359 6.81805L9.0637 4.51196C8.93481 4.42795 8.78405 4.38373 8.63021 4.38479L6.72204 4.39108C6.56356 4.39285 6.40915 4.4415 6.27828 4.5309C6.1474 4.62029 6.04592 4.74643 5.98662 4.89341C5.92731 5.04038 5.91285 5.20163 5.94504 5.35682C5.97723 5.512 6.05465 5.65419 6.16752 5.76545Z" />
      </g>
      <defs>
        <clipPath id="clip92076">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;