import { Box, Drawer, IconButton, makeStyles } from '@material-ui/core'
import Logo from '../../asset/images/Fun-Sun-logo.png'
import * as React from 'react'
import { Link } from 'react-router-dom'
import useGeoMenuStore from '../CompanyPopup/store'
import './style.css'
import CloseIcon from '@material-ui/icons/Close'
import AccountAuth from '../NewNav/AccountAuth'
import useAuthStore from '../../pages/Auth/store'
import { ReactComponent as LocationIcon } from '../../asset/icons/locationHeader.svg'
import { ReactComponent as PhoneIcon } from '../../asset/icons/phoneHeader.svg'
import MobileList from './components'
import MobileSearchPopup from './components/MobileSearchPopup'
import MobileCompanyPopup from './components/MobileCompanyPopup'

const useStyles = makeStyles(theme => ({
	customizedButton: {
		position: 'absolute',
		right: '105%',
		top: '1%',
		backgroundColor: 'hsla(0,0%,100%,.3)',
		color: '#FFF',
		'&:hover': { backgroundColor: 'hsla(0,0%,100%,.3)' }
	}
}))

export default function BurgerMenu({
	openDrawer,
	toggleDrawer,
	city,
	setOpenDrawer
}) {
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	const actions = useAuthStore(({ actions }) => actions)
	const refreshLink = (id, url) => {
		actions.refreshSamoLink(id, url)
	}
	const mainMenu = useGeoMenuStore(({ mainMenu }) => mainMenu)

	const [openSearch, setOpenSearch] = React.useState(false)

	const openCityHandler = () => {
		setOpenSearch(true)
	}
	const closeCityHandler = () => {
		setOpenSearch(false)
	}

	const [openCompany, setOpenCompany] = React.useState(false)
	const openCompanyHandler = () => {
		setOpenCompany(true)
	}
	const closeCompanyHandler = () => {
		setOpenCompany(false)
	}

	const list = () => {
		return (
			<Box sx={{ width: 248 }} role='presentation'>
				<div className='header__menu-container'>
					<div className='logo' onClick={toggleDrawer(false)}>
						<a
							href='https://agentb2b.fstravel.com/'
							target={'_blank'}
							rel={'noreferrer'}>
							<img src={Logo} className='logo' alt='' />
						</a>
					</div>
					{agentInfo && (
						<AccountAuth
							onCloseDrawer={toggleDrawer(false)}
							openCabinet={() =>
								refreshLink('cl_refer', 'https://b2b.fstravel.com/cl_refer')
							}
						/>
					)}
				</div>
				<div className='header__menu-container'>
					<ul className='header__menu-categories'>
						{mainMenu
							?.filter(
								elem => elem?.user_roles?.includes('agent') || !elem.user_roles
							)
							.map(item => {
								return (
									<MobileList
										item={item}
										key={item.id}
										setOpenDrawer={setOpenDrawer}
									/>
								)
							})}
					</ul>
				</div>
				<div className='header__menu-container'>
					<div className='elem__location' onClick={openCityHandler}>
						<LocationIcon className='location-icon' />
						<div className='v-language-item'>
							<span>{city}</span>
						</div>
					</div>
					<Link to={'tel: 8 495 660 5 660'} className='v-header-phone'>
						<PhoneIcon className='phone-icon' />
						<span>8 495 660 5 660</span>
					</Link>
				</div>
				<div className='header__menu-container'>
					<div className='v-header-left-link' onClick={openCompanyHandler}>
						<span>Информация для агентов</span>
					</div>
					<Link
						onClick={toggleDrawer(false)}
						className='v-header-left-link'
						to={'/nacalo-raboty'}
						target={'_self'}>
						<span>Сотрудничество</span>
					</Link>
					<div style={{ marginTop: '12px' }}>
						{agentInfo && agentInfo.agents?.name.includes('F&S') && (
							<Link className='v-header-left-link' to='/loyalty'>
								<span>Программа лояльности</span>
							</Link>
						)}
					</div>
				</div>
			</Box>
		)
	}
	const classes = useStyles()
	return (
		<div>
			<Drawer
				anchor={'right'}
				open={openDrawer}
				onClose={toggleDrawer(false)}
				PaperProps={{
					style: { overflowY: 'unset' }
				}}>
				<IconButton
					size='small'
					disableRipple
					className={classes.customizedButton}
					onClick={toggleDrawer(false)}>
					<CloseIcon />
				</IconButton>
				{openSearch ? (
					<MobileSearchPopup closeCityHandler={closeCityHandler} />
				) : (
					list()
				)}
			</Drawer>
			<MobileCompanyPopup
				open={openCompany}
				onClose={closeCompanyHandler}
				setOpenDrawer={setOpenDrawer}
			/>
		</div>
	)
}
