import React, { useCallback, useEffect, useState } from 'react';
import {ReactComponent as Arrow} from '../../../asset/images/avia/search-result/triangle.svg';
import styled from 'styled-components';


const CategoryWrapper = styled.div`
  ${({ disableBottomLine }) =>
    !disableBottomLine ? `border-bottom: 1px solid #f2f2f2;` : ''}
`;
const CategoryContainer = styled.li`
  ${({ active}) => `
        list-style-type: none;
        padding: 24px 0;
        // border-bottom: 1px solid #F2F2F2;
        text-align: left;
        & .range-time-with-plane {
            margin-bottom: 24px;
        }
        & > :first-child {
            margin-bottom: ${active ? '24px' : '0px'};
            & > svg {
                fill: #B9B9B9;
                transform: rotateX(${!active ? '0' : '180deg'});
            }
        }
        & > :last-child {
            display: ${active ? 'block' : 'none'};
        }
    `}
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & > svg {
    transition: transform 150ms;
    cursor: pointer;
    width: 8.7px;
    height: 5.61px;
  }
`;

const CategoryName = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  color: #3C3C3C;
  margin-right: 9px;
`;

const CategoryBody = styled.div`
  & > label {
    display: block;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }
  ${({scroll}) => scroll ? `max-height: ${scroll}px; overflow-y: auto;` : ''}
`;

const CategoryResetButton = styled.button`
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #b9b9b9;
    border-radius: 1px;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const CategoryResetButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const Category = ({
  name,
  active = false,
  children,
  resetAvailable = false,
  onReset,
  disableBottomLine,
  scroll,
}) => {
  const [isActive, setActive] = useState(active);

  useEffect(() => {
    setActive(active);
  }, [active]);

  const handleClick =
    useCallback(() => {
      setActive((isActive) => !isActive);
    }, []);

  const handleReset = (e) => {
    e.stopPropagation();
    typeof onReset == 'function' && onReset();
  };

  return (
    <CategoryWrapper
      className="category-wrapper"
      disableBottomLine={disableBottomLine}
    >
      <CategoryContainer active={isActive} className="category-container">
        <CategoryHeader onClick={handleClick}>
          <CategoryName>{name}</CategoryName>
          <Arrow />
          {resetAvailable && (
            <CategoryResetButtonWrapper>
              <CategoryResetButton onClick={handleReset} />
            </CategoryResetButtonWrapper>
          )}
        </CategoryHeader>
        <CategoryBody scroll={scroll}>{children}</CategoryBody>
      </CategoryContainer>
    </CategoryWrapper>
  );
};

export default Category;
