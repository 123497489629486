import {
  checkAvailablityNormalizer,
  normalizeGetInsuranceCalculationResponse
} from "./utils"
import Api from '../../../functions/fetchApi'

/**
 * action: @booking/selectFlightRequest
 * @param id id search result
 * @param index index of selected group
 * @param indexesOfFlightsInGroups index of selected flights in selected group
 */
export function checkAvailablity(id, index, indexesOfFlightsInGroups) {
  return Api.get(`${process.env.REACT_APP_CHECK_AVAILABILITY}/${id}/${index}/${indexesOfFlightsInGroups.join(",")}`)
  .setContentType('application/json')
  .send()
  .then(data => {
    if (!data.fares || (data.fares && !data.fares[0]?.isAvailable)) {
      return data
    }
    return data
  })
  .then(checkAvailablityNormalizer);
}

/**
 * action: @booking/create
 * @param data
 */
export function bookTicket(data) {
  return Api.post(process.env.REACT_APP_BOOK_AVIA_ORDER)
  .body({
    ...data,
    PromoCode: "z",
    PaymentType: "CASH"
  })
  .addAuthGuard()
  .setContentType('application/json')
  .send();
}

/**
 * action: @booking/create
 * @param data
 */
export function getInsurances(calculationId) {
  return Api.get(process.env.REACT_APP_GET_INSURANCE_SERVICES)
  .query({calculationId})
  .setContentType('application/json')
  .send()
  .then(normalizeGetInsuranceCalculationResponse)
}

export function getAddictionalServices (orderId) {
  return Api.get(process.env.REACT_APP_GET_ADDICTIONAL_SERVICES)
  .query({orderId})
  .setContentType('application/json')
  .send()
}


export function getAgentOrderBill (orderId) { 
  return Api.get(process.env.REACT_APP_GET_AGENT_BILL_BY_ORDER_ID)
    .query({orderId})
    .addAuthGuard()
    .setContentType('application/json')
    .send()
}
// export const getAgentOrderBill = (orderId, token) =>
//   trackPromise(
//     Api.request(
//       {
//         url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-order-id?orderId=${orderId}`,
//         method: "GET",
//         headers: {
//           "Content-Type": CONTENT_TYPE.JSON,
//           Authorization: `Bearer ${token}`
//         }
//       },
//       "AUTH"
//     )
//       .then(handleStatuses())
//       .then(data => {
//         return data.blob()
//       })
//       .then(blob => {
//         const url = window.URL.createObjectURL(blob)
//         const a = document.createElement("a")
//         a.href = url
//         a.download = "file.pdf"
//         document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
//         a.click()
//         a.remove() //afterwards we remove the element again
//       })
//   )

// export const getAgentOrderBillWithoutTrackPromise = (orderId, token) =>
//   Api.request(
//     {
//       url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-order-id?orderId=${orderId}`,
//       method: "GET",
//       headers: {
//         "Content-Type": CONTENT_TYPE.JSON,
//         Authorization: `Bearer ${token}`
//       }
//     },
//     "AUTH"
//   )
//     .then(Api.statuses)
//     .then(data => {
//       return data.blob()
//     })
//     .then(blob => {
//       const url = window.URL.createObjectURL(blob)
//       const a = document.createElement("a")
//       a.href = url
//       a.download = "file.pdf"
//       document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
//       a.click()
//       a.remove() //afterwards we remove the element again
//     })

// export function getAgentBillBySamoId(samoNumber, token) {
//   return Api.request(
//     {
//       url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-samo-number?samoNumber=${samoNumber}`,

//       method: "GET",
//       headers: {
//         "Content-Type": CONTENT_TYPE.JSON,
//         Authorization: `Bearer ${token}`
//       }
//     },
//     "AUTH"
//   )
//     .then(handleStatuses())
//     .then(data => {
//       return data.blob()
//     })
//     .then(blob => {
//       const url = window.URL.createObjectURL(blob)
//       const a = document.createElement("a")
//       a.href = url
//       a.download = "file.pdf"
//       document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
//       a.click()
//       a.remove() //afterwards we remove the element again
//     })
// }

// export function getAgentBillByTteNumber(samoNumber, token) {
//   return Api.request(
//     {
//       url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-tte-number?tteNumber=${samoNumber}`,

//       method: "GET",
//       headers: {
//         "Content-Type": CONTENT_TYPE.JSON,
//         Authorization: `Bearer ${token}`
//       }
//     },
//     "AUTH"
//   )
//     .then(handleStatuses())
//     .then(data => {
//       return data.blob()
//     })
//     .then(blob => {
//       const url = window.URL.createObjectURL(blob)
//       const a = document.createElement("a")
//       a.href = url
//       a.download = "file.pdf"
//       document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
//       a.click()
//       a.remove() //afterwards we remove the element again
//     })
// }

export function setInsuranceAgreement(payload) {
  return Api.post('/insurance/set-insurance-agreement')
  .body(payload)
  .setContentType('application/json')
  .send();
}
