import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'

const HotelSelect = ({options, onChange, value}) => {

	return (
		<FormControl variant="outlined" style={{width: '100%'}}>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				className="custom-select"
			>
				{options.map((item) =>
					<MenuItem
						value={JSON.stringify({
							routeIndex: item.routeIndex,
							hotelId: item.id,
							roomId: item.room.id,
							placeId: item.place.id,
							mealTypeId: item.mealType.id,
						})
						}
					>
						{
							item.name
						} {
						item.categoryStarsCount
					}* ({item.city.name}) {item.room.name} / {item.place.name} {item.mealType.name}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default HotelSelect;