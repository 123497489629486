

import React, {useEffect} from 'react';
import { Outlet } from "react-router-dom";

export default function DownloadTemplate() {

	useEffect(() => {
		const hostname = 'https://agentb2b.fstravel.com'
		window.location.href = hostname + window.location.pathname
	}, [])

	return <Outlet/>
}