import React, { useEffect } from 'react'
import CustomizedTabs from '../AgentInfo/components/Tabs/CustomizedTabs'
import NewClaim from './components/NewClaim'
import OpenClaims from './components/OpenClaims'
import styled from 'styled-components'
import { Alert } from '@mui/material'
import useFitClaimStore from './store'
import { isEmpty } from 'lodash'

const Title = styled.div`
	font-family: Open Sans;
	font-weight: 600;
	font-size: 28px;
	font-style: normal;
	line-height: 38px;
	color: #2e2e32;

	margin-bottom: 12px;
`

const Container = styled.div`
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
`

export default function FitClaims() {
	const [tab, setTab] = React.useState(0)
	const actions = useFitClaimStore(({ actions }) => actions)
	const issues = useFitClaimStore(({ issues }) => issues)
	const handleChange = (event, newValue) => {
		setTab(newValue)
	}

	useEffect(() => {
		actions.getFit()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Container>
			<div>
				<Title>Заявки FIT</Title>

				<Alert
					variant='outlined'
					severity='info'
					style={{ textAlign: 'justify' }}>
					<div>
						По вопросам бронирования группового размещения от 10 чел или 10
						номеров, организации конференции, ивентов и мероприятий просьба
						обращаться в <strong>Отдел MICE</strong>{' '}
						<a href='mailto: mice.request@fstravel.com?subject=Бронирование группового размещения'>
							mice.request@fstravel.com
						</a>
					</div>
					<div>
						По вопросам туров, входящих в FUNS&SUN Premium, просьба обращаться в{' '}
						<strong>Отдел Premium</strong>{' '}
						<a href='mailto: premium@fstravel.com?subject=Premium'>
							premium@fstravel.com
						</a>
					</div>
				</Alert>

				<CustomizedTabs
					value={tab}
					handleChange={handleChange}
					label1='Новая заявка'
					label2={isEmpty(issues) ? null : "Открытые заявки"}
				/>
				{tab === 0 ? <NewClaim /> : <OpenClaims />}
			</div>
		</Container>
	)
}
