import { put, select } from "redux-saga/effects"
import {
  initPriceDetail,
  updatePriceDetailItem,
  setSubagentTotal,
  setAgentCommissionVisibility
} from "../../duck"
import {
  getSelectedFare,
  getTotalPrice,
  getSubAgentCost
} from "../../selectors"
import { getUserDataState } from "../../../user"
import quantityCaseFormat from "../../../../../functions/quantityCaseFormat"

const getPassengerName = (type, quantity) => {
  switch (type) {
    case "Adult":
      return quantityCaseFormat(quantity, "взрослый", "взрослых", "взрослых")
    case "Child":
      return quantityCaseFormat(quantity, "ребёнок", "ребёнка", "детей")
    default:
      return quantityCaseFormat(quantity, "младенец", "младенца", "младенцов")
  }
}

export function* initPriceDetailWorker() {
  const fare = yield select(getSelectedFare)
  const user = yield select(getUserDataState)
  const subAgentExtraCharge = yield select(getSubAgentCost)

  const passengerPricesFormated = fare.prices.reduce(
    (acc, x) => [
      ...acc,
      {
        quantity: 1,
        description: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        // cost: x.subAgentExtraChargeBenefit * x.count,
        cost: x.total * x.count,
        type: "pasenger-type",
        id: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        subAgentExtraCharge: x?.subAgentExtraCharge
      }
    ],
    []
  )

  const detailList = [...passengerPricesFormated]

  let totalPrice = yield select(getTotalPrice)

  if (user && user.agentId) {
    detailList.push({
      quantity: 0,
      description: `Сумма сборов субагента`,
      cost: subAgentExtraCharge,
      type: "subagent-cost",
      id: `subagent`
    })
  }


  yield put(
    initPriceDetail({
      total: totalPrice,
      detailList,
      subagentTotal:
        user && user.agentId ? totalPrice - subAgentExtraCharge : null,
      hideAgentCommission: user && user.isHideCommission,
    })
  )
}

export function* updateFareData() {
  const fare = yield select(getSelectedFare)
  const user = yield select(getUserDataState)
  const subAgentExtraCharge = yield select(getSubAgentCost)

  const passengerPricesFormated = fare.prices.reduce(
    (acc, x) => [
      ...acc,
      {
        quantity: 1,
        description: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        // cost: x.subAgentExtraChargeBenefit * x.count,
        cost: x.total * x.count,
        type: "pasenger-type",
        id: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        subAgentExtraCharge: x?.subAgentExtraCharge
      }
    ],
    []
  )

  let totalPrice = yield select(getTotalPrice)

  const detailList = [...passengerPricesFormated]

  if (user && user.agentId) {
    detailList.push({
      quantity: 0,
      description: `Сумма сборов субагента`,
      cost: subAgentExtraCharge,
      type: "subagent-cost",
      id: `subagent`
    })

    // yield put(setAgentCommissionVisibility(user.isHideCommission));
  }

  for (const x of detailList) {
    yield put(updatePriceDetailItem({ total: totalPrice, id: x.id, item: x }))
  }

  yield put(
    setSubagentTotal(
      user && user.agentId ? totalPrice - subAgentExtraCharge : null
    )
  )
}
