import React, { useEffect, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'
import './style.scss'
import TouristsChangeModal from './TouristsChangeModal'
import TouristsDropDown from './TouristsDropDown'

const TouristsField = ({ adults, children, title, ...props }) => {
	const [showBody, setShowBody] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const field = useRef(null)

	useEffect(() => {
		function handleClick(event) {
			if (field.current && !field.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [field])

	const toggle = () => {
		if (window.innerWidth > 766) {
			setShowBody(!showBody)
		} else {
			setModalVisible(true)
		}
	}

	const touristsValue = () => {
		if (!children.length) {
			return `${adults} ${adults === 1 ? 'взрослый' : 'взрослых'}`
		}
		return `${adults} взр, ${children.length} реб.`
	}

	return (
		<div className='v-departure-base' ref={field}>
			<div className='v-departure'>
				<div onClick={toggle}>
					<div>
						<TextField
							id={'tourist'}
							label={title}
							variant='outlined'
							fullWidth
							autoComplete={'off'}
							inputProps={
								{ readOnly: true }
							}
							value={touristsValue()}
							{...props}
						/>
					</div>
				</div>

				{showBody && (
					<TouristsDropDown adults={adults} children={children} />
				)}
			</div>
			{modalVisible && (
				<TouristsChangeModal onClose={setModalVisible}>
					<TouristsDropDown adults={adults} children={children} />
				</TouristsChangeModal>
			)}
		</div>
	)
}

export default TouristsField
