import React from "react"
import styled from "styled-components"
import AgentRulesForm from "../../components/avia-agents/agent-rules/AgentRulesForm"
import blueInfoIcon from "../../asset/images/avia/agents/blueInfoIcon.svg"
import Skeleton from '@mui/material/Skeleton';
import {useSelector} from 'react-redux'
import {useLocation} from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  margin-bottom: 25px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const BlueBlock = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 30px;
`

const BlueInfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  margin-right: 7px;
`

const BlueBlockText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 12px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
`;

const Flex= styled.div`
  display: flex;
  
  ${(props) => props.spaceBetween ? 'justify-content: space-between;': ''}
  ${(props) => props.marginRight ? 'margin-right: 50px;': ''}
  ${(props) => props.alignCenter ? 'align-items: center;': ''}
  ${(props) => props.column ? `
    flex-direction: column;
    & > * {
      margin-bottom :16px;
    }
  `: ''}

`;

const AgentRulesSkeleton = () => (
  <Wrapper>
    <Skeleton width={209} sx={{ fontSize: '18px', lineHeight: '120%', marginBottom: '20px' }}/>
    <Skeleton sx={{ fontSize: '16px', lineHeight: '22px' }}/>
    <Skeleton height={198} sx={{ transform: 'none', margin: '24px 0' }}/>
    <Flex marginRight spaceBetween>
      <div>
        <Skeleton width={94} sx={{ fontSize: '14px', lineHeight: '18px', marginBottom: '7px' }}/>
        <Flex column>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
        </Flex>
      </div>
      <div>
        <Skeleton width={94} sx={{ fontSize: '14px', lineHeight: '18px', marginBottom: '7px' }}/>
        <Flex column>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={125} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={113} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={81} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
        </Flex>
      </div>
      <div>
        <Skeleton width={94} sx={{ fontSize: '14px', lineHeight: '18px', marginBottom: '7px' }}/>
        <Flex column>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={70} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={208} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
          <Flex alignCenter>
            <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '8px' }}/>
            <Skeleton width={170} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
          </Flex>
        </Flex>
      </div>
    </Flex>
  </Wrapper>
)


const AgentRules = () => {
  const isLoading = useSelector(state => state.agents.agentInfoLoadingState)
  const location = useLocation();
  const [prevPathname,setPrevPathname] = React.useState(null);

  React.useEffect(()=> {
    if(location.pathname !== prevPathname) {
      window.scrollTo(0,0);
      setPrevPathname(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])

  if(isLoading) {
    return <AgentRulesSkeleton/>
  }
  return (
      <Container>
        <Title>Настройки сборов по авиации</Title>
        <Text>
          В данном блоке вам доступно создание и редактирование агентский сборов
          по авиации.
        </Text>
        <BlueBlock>
          <BlueInfoIcon />
          <Column>
            <BlueBlockText style={{ marginTop: 0 }}>
              Для создания нового правила сборов заполните пункты ниже. Вы може
              выбрать к чему применять сборы: класс, тип маршрута, тип перелета,
              сегмент или пассажир. Задать даты начала и конца продажи, выбрать
              конкретные города и аэропорты. Оставьте поле пустыми, если
              какой-либо из параметров не имеет значения.
            </BlueBlockText>
            <BlueBlockText>
              Для более детальной установки правила откройте дополнительные
              условия по соответствующей кнопке. Установленные сборы будут
              применяться к каждому купленному перелету.
            </BlueBlockText>
          </Column>
        </BlueBlock>
        <AgentRulesForm />
      </Container>
  )
}

export default AgentRules
