import React from 'react'
import './style.css'
import { ReactComponent as DownLoad } from '../../../../asset/images/download.svg'
import { ReactComponent as FileDownload } from '../../../../asset/images/fileFill.svg'
import { Link } from 'react-router-dom'

function DownloadIcon() {
	return (
		<div className='v-icon-file-fill'>
			<FileDownload className='download-file' />
		</div>
	)
}

function DownloadIconFile() {
	return (
		<div className='v-icon-download'>
			<DownLoad className='download-icon' />
		</div>
	)
}

export default function DownloadFiles({ content, title }) {
	// console.log('DownloadFiles', content, title)
	return (
		<>
			<h4 className='title'>{title}</h4>
			<div
				className='blockWithDownloadLinks'
				style={{ background: content.background.gradient }}>
				{content?.links?.map(link => (
					<Link
						to={
							link?.link?.includes('http')
								? `${link.link}`
								: `https://fstravel.com/${link.link}`
						}
						key={link.order}>
						<div className='blockDownloadFiles'>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
								<DownloadIcon />
								<div>{link.link_text}</div>
							</div>
							<DownloadIconFile size='large' />
						</div>
					</Link>
				))}
			</div>
		</>
	)
}
