import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const AntTabs = styled(Tabs)({
	borderBottom: '1px solid #DCDCDC',
	'& .MuiTabs-indicator': {
		backgroundColor: '#4872F2'
	}
})

const AntTab = styled(props => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		minWidth: 0,
		[theme.breakpoints.up('sm')]: {
			minWidth: 0
		},
		fontWeight: 600,
		fontSize: 14,
		paddingRight: '0px',
		paddingLeft: '0px',
		marginRight: theme.spacing(4),
		color: '#737373',
		lineHeight: '18px',
		// '&:hover': {
		// 	color: '#40a9ff',
		// 	opacity: 1
		// },
		'&.Mui-selected': {
			color: '#4872F2',
			fontWeight: 600
		},
		'&.Mui-focusVisible': {
			backgroundColor: '#4872F2'
		}
	})
)

export default function CustomizedTabs({ value, handleChange }) {
	return (
		<Box sx={{ width: '100%', padding: '0px' }}>
			<Box sx={{ bgcolor: '#fff' }}>
				<AntTabs value={value} onChange={handleChange} aria-label='ant example'>
					<AntTab label='Данные агенства' />
					<AntTab label='Все агенты' />
				</AntTabs>
				<Box sx={{ p: 2 }} />
			</Box>
		</Box>
	)
}
