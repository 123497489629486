import React, { useEffect, useState } from 'react';
import useGeoStore from "../pages/LandingPage/store";
import MainBannerSlider from "../pages/LandingPage/components/MainBannerSlider";
import StaticBlocks from "../pages/LandingPage/StaticBlocks";
import {Helmet} from "react-helmet";
import {landingType} from "../pages/LandingPage/components/CountryMainBanner";
import MainBanner from "../pages/LandingPage/components/MainBanner";
import InsuranceSearchForm from "../pages/Partner/Insurances/components/SearchForm";
import SearchResults from "../pages/Partner/Insurances/components/SearchResults";
import useInsuranceStore from "../pages/Partner/Insurances/store";
import {Link} from "react-router-dom";
import {Box, Breadcrumbs, Typography} from "@mui/material";

const InsuranceTemplate = ({ path }) => {

	const actions = useGeoStore(({ actions }) => actions)
	const content = useGeoStore(({ content }) => content)
	const insurances = useInsuranceStore(({ insurances }) => insurances)
	const currency = useInsuranceStore(({ currency }) => currency)
	const [isUpdated, setIsUpdated] = useState(false)

	useEffect(() => {
		actions.getPage(path).then(setIsUpdated(true))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		return () => {
			actions.clearContent()
		}
		// eslint-disable-next-line
	}, [])

	if (!content || !isUpdated)
		return null;

	return (
		<div className={'landing landing-container'}>
			<Box mb={2} mt={2}>
				<Breadcrumbs aria-label='breadcrumb' className={'breadcrumbs-action'}>
					<Link style={{ color: '#4872f2' }} to={'/searchinsurances'}>Страхование</Link>
					<Typography>
						{content?.title}
					</Typography>
				</Breadcrumbs>
			</Box>
			<Helmet title={content?.meta_title || content?.title} />
			{content.main_banner &&
				JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner &&
				JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner.boolSliderMainBanner &&
				<MainBannerSlider content={JSON.parse(content.main_banner.content)} />
			}
			{content.search_frame &&
				<div className={'container'}>
					<InsuranceSearchForm />
				</div>
			}
			{content.main_banner && <MainBanner content={JSON.parse(content.main_banner.content)} showBanner={!insurances}/>}
			{insurances &&
				<SearchResults
					items={insurances.items}
					isCompleted={insurances.isCompleted}
					currency={currency}
				/>
			}
			<StaticBlocks blocks={content.blocks.filter(block => block.show_desktop)} />
		</div>
	);
};

export default InsuranceTemplate;

