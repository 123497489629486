import { Skeleton } from '@mui/material'
import './HotelCard.scss'

function LoadingCard() {


    return <div className='hotel-card'>
        <div className='image-container'>
            <Skeleton animation='wave' variant='rectangular' width={300} height={160} />
        </div>
        <div className='hotel-card__content'>
            <div className='stars'>
                <Skeleton animation='wave' variant='text' width={100} />
            </div>
            <div className='title-block'>
                <Skeleton animation='wave' variant='text' width={200} />
            </div>
            <div className='navigation'>
                <div className='navigation-item location'>
                    <div className='navigation-icon'>
                        <Skeleton animation='wave' variant='circular' width={20} height={20} />
                    </div>
                    <Skeleton animation='wave' variant='text' width={200} />
                </div>
                <div className='navigation-item'>
                    <div className='navigation-icon'>
                        <Skeleton animation='wave' variant='circular' width={20} height={20} />
                    </div>
                    <Skeleton animation='wave' variant='text' width={200} />
                </div>
            </div>
            <div className='price'>
                <div className='price-btn'>
                    <Skeleton animation='wave' variant='text' width={100} height={40} />
                </div>
            </div>
        </div>
    </div>
}

export default LoadingCard