import React from "react"
import { useDispatch,useSelector } from "react-redux"
import { getAgentFeesInfoRequest } from "../../store/modules/agents/agent-fees/duck"
import FeeForm from "../../components/avia-agents/agent-fees/FeeForm"
import FeesTable from "../../components/avia-agents/agent-fees/FeesTable"
import {default as MUISkeleton} from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import {useLocation} from "react-router-dom";

const Wrapper = styled.div`
`;

const Skeleton = styled(MUISkeleton)`
  // transform: none;
`

const AgentsFeesSkeleton = () => (
  <Wrapper>
    <Skeleton width={209} sx={{ fontSize: '18px', lineHeight: '120%', marginBottom: '20px' }}/>
    <Skeleton sx={{ fontSize: '16px', lineHeight: '22px' }}/>
    <Skeleton width={100} sx={{ fontSize: '16px', lineHeight: '22px' }}/>
    <Skeleton height={72} sx={{ transform: 'none', margin: '24px 0' }}/>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton sx={{transform: 'scale(1, 0.7)',  fontSize: '14px', lineHeight: '18px',width: '75%' }}/>
        <Skeleton height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
      <Grid item xs={4}>
        <Skeleton width='86%' height={40} sx={{transform: 'scale(1, 0.9)' }}/>
      </Grid>
    </Grid>
    <Skeleton width={409} sx={{ fontSize: '18px', lineHeight: '120%', marginTop: '25px' }}/>
    <Skeleton sx={{ fontSize: '16px', lineHeight: '22px',marginTop: '20px' }}/>
    <Skeleton height={169} sx={{ transform: 'none', margin: '24px 0' }}/>
  </Wrapper>
)

const AgentFees = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.agents.agentInfoLoadingState)
  const [prevPathname,setPrevPathname] = React.useState(null);

  React.useEffect(()=> {
    if(location.pathname !== prevPathname) {
      window.scrollTo(0,0);
      setPrevPathname(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])

  // React.useEffect(() => {
  //   dispatch(getAgentFeesInfoRequest())
  // }, [])

  if(isLoading) {
    return <AgentsFeesSkeleton/>
  }

  return (
    <>
      <FeeForm />
      <FeesTable />
    </>
  )
}

export default AgentFees
