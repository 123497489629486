import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Text } from '../../../ui';
import formatPrice from '../../../../functions/formatPrice';
import CheckIconSrc from '../../../../asset/images/avia/booking/check.svg';
import ChevronIconSrc from '../../../../asset/images/avia/insurances/chevron-up.svg';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as CloseIcon} from '../../../../asset/images/avia/ui/close.svg'
import {styled as MUIstyled} from "@mui/material/styles";
import PrimaryButton from "../../../ui/PrimaryButton";

const Icon = styled.img.attrs({ alt: 'icon' })`
  width: 35px;
  height: 35px;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 8px;
  }
`;

const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const BaseText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > a {
    color: #4872f2;
    text-decoration: none;
  }

  & > strong {
    font-weight: 600;
  }
`;

const Paragraph = styled(BaseText)`
  display: block;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  margin-bottom: 19px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const Feature = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 5px;
  }
`;

const CheckIcon = styled.img.attrs({ src: CheckIconSrc, height: 9, width: 13 })`
  margin-top: 5px;
  margin-right: 5px;
`;

const Agreement = styled(Paragraph)`
  // white-space: nowrap;

  // @media (max-width: 767px) {
  //   white-space: inherit;
  // }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    position: relative;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 518px;
    display: block;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #C4C4C4;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-track {
      background: #C4C4C4;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4872f2;
      border-radius: 16px;
    }

    ${Header} {
      margin-bottom: 24px;
    }

    ${Paragraph} {
      margin: 20px 0;
    }

    ${FeaturesContainer} {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    ${Agreement} {
      margin-top: 40px;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      overflow: scroll;
      border-radius: 0;
      border: none;
      padding: 44px 20px;

      ${Header} {
        margin-bottom: 15px;
      }

      .first {
        margin-top: 15px;
      }

      .second {
        margin-bottom: 10px;
      }

      ${FeaturesContainer} {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
`;

const Badge = styled.div`
  width: fit-content;
  margin-top: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: #BFE8FF;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
`

const BadgeIcon = styled.img.attrs({ alt: 'badge' })`
  margin-right: 4px;
`

const Description = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  margin: 16px 0;

  @media (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const DescriptionItem = styled(Text)`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`

const HowToBlock = styled.div`
  margin-top: 20px;
  height: 312px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const HowToDropdown = styled.div`
  display: flex;
  font-size: 14px;
  color: #4872f2;
  cursor: pointer;
`
const Chevron = styled.img.attrs({ src: ChevronIconSrc, alt: 'chevron' })`
  margin-left: 8px;
`

const InstructionTitle = styled.div`
  margin-top: 12px;
  font-size: 14px;
`

const InstructionElement = styled.div`
  margin-top: 12px;
  font-size: 14px;
`

const StyledButton = MUIstyled(PrimaryButton)(()=>({
  fontSize: '14px',
  lineHeight: '18px',
  padding: '7px 16px',
  display: 'block',
  marginTop: '30px',
}))


export default function InfoModal({ open, onClose, data, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  const handleClickCross = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleClick = () => {
    onChange(data?.product.id, data);
  };

  useEffect(() => {
    setIsOpen(false)
  }, [onClose])

  return (
    <StyledPopup open={open} onClose={onClose}>
      <CloseButton
        aria-label="close"
        onClick={handleClickCross}
      >
        <CloseIcon/>
      </CloseButton>
      <Header>
        <Icon src={data?.iconSrc} />
        <HeaderText>
          {data?.product.name}{' '}
          <strong>{formatPrice(data?.totalRate.value)}</strong>
        </HeaderText>
      </Header>

      <Badge style={{background: data?.cardRenderData.iconBackground}}>
        <BadgeIcon src={data?.cardRenderData.iconSrc}/>
        <span>{data?.cardRenderData.iconText}</span>
      </Badge>

      {data?.cardRenderData.detailsItemsTitle && <Description>{data?.cardRenderData.detailsItemsTitle}</Description>}
      {data?.cardRenderData.detailsItemsFull.map(el => (
        <DescriptionItem>
          <CheckIcon />
          <span dangerouslySetInnerHTML={{__html: el}}/>
        </DescriptionItem>
      ))}

      <HowToBlock style={{background: `url(${data?.cardRenderData.storyIconSrc}) center no-repeat`}}>
        <HowToDropdown onClick={() => setIsOpen(!isOpen)}>
          Как получить {data?.product.name === 'Полёт под защитой' && 'моментальную'} выплату?
          <Chevron style={isOpen ? {transform: 'rotate(180deg)'} : {}} />
        </HowToDropdown>
      </HowToBlock>

      {isOpen && data?.cardRenderData.howtoTitle && <InstructionTitle>
        <span dangerouslySetInnerHTML={{__html: data?.cardRenderData.howtoTitle}}/>
      </InstructionTitle>}
      {isOpen && data?.cardRenderData.howtoInstructions.map((el, idx) => (
        <InstructionElement key={idx}><b>{idx + 1}.</b> <span dangerouslySetInnerHTML={{__html: el}}/></InstructionElement>
      ))}

      <Agreement>
        Читать подробные{' '}
        <a href={data?.termsUri} target="_blank" rel="noreferrer">
          условия страховки
        </a>
      </Agreement>
      <Bottom>
        {!!data?.company.phone && (
          <BaseText>
            Номер страховщика: <br />
            <strong>{data?.company.phone}</strong>
          </BaseText>
        )}
        <img alt={data?.company.name} src={data?.company.logo} />
      </Bottom>
      <StyledButton
        type="button"
        loading={data?.loading}
        color={data?.checked ? 'lightBlue' : 'primary'}
        onClick={handleClick}
      >
        {data?.checked ? 'Убрать страховку' : 'Добавить страховку'}
      </StyledButton>
    </StyledPopup>
  );
}
