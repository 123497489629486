import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'

const HotelSelect = ({ options, onChange, value, itemKey }) => {
	const [selected, setSelected] = React.useState(value.uid)

	const handleChange = (event) => {
		if (itemKey) {
			onChange(event.target.value, itemKey)
		} else {
			onChange(event.target.value)
		}
		setSelected(event.target.value)
	}

	return (
		<FormControl variant="outlined" style={{ width: '100%' }}>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={selected}
				onChange={handleChange}
				className="custom-select"
			>
				{options.map((item) =>
					<MenuItem
						value={item.uid}
						key={item.uid}
					>
						{
							item.name
						} {
							item.star
						} ({item.town}) {item.room} / {item.htplace} {item.meal}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default HotelSelect;