import * as React from 'react'
import {
	YMaps,
	Map,
	Placemark,
	FullscreenControl,
	ZoomControl
} from '@pbe/react-yandex-maps'

import {
	Dialog,
	DialogContent,
	IconButton,
	makeStyles,
	styled
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useHotelDescriptionStore from '../store'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(0),
		borderRadius: 16
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(0)
	}
}))

const useStyles = makeStyles(theme => ({
	customizedButton: {
		position: 'absolute',
		left: '103%',
		top: '0%',
		backgroundColor: '#FFF',
		color: '#2E2E32',
		'&:hover': { backgroundColor: '#FFF' }
	}
}))

export default function HotelLocationPopup({ open, handleClose }) {
	const location = useHotelDescriptionStore(({ location }) => location)
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const defaultState = {
		center: location.geometry,
		zoom: location.zoom,
		controls: []
	}
	const classes = useStyles()
	return (
		<BootstrapDialog
			PaperProps={{
				style: { borderRadius: 16, overflowY: 'unset', minWidth: '1000px' }
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			open={open}
			width='lg'>
			<DialogContent>
				<IconButton
					disableRipple
					className={classes.customizedButton}
					onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				<YMaps
					query={{
						apikey: process.env.REACT_APP_YANDEX_MAP,
						lang: 'ru_RU'
					}}>
					<Map
						defaultState={defaultState}
						width={1000}
						height={800}
						modules={[
							'geoObject.addon.balloon',
							'geoObject.addon.hint',
							'geolocation',
							'geocode'
						]}>
						<FullscreenControl />
						<ZoomControl options={{ float: 'right' }} />
						<Placemark
							geometry={location.geometry}
							properties={{
								hintContent: `${hotel?.localizedName}`
							}}
						/>
					</Map>
				</YMaps>
			</DialogContent>
		</BootstrapDialog>
	)
}
