import React, { useState } from 'react'
import useAuthStore from './store'

import './style.css'
import { Box, Button, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ForgotPassword from './ForgotPassword'

const Auth = () => {
	const actions = useAuthStore(({ actions }) => actions)
	const error = useAuthStore(({ error }) => error)

	const [open, setOpen] = useState(false)
	const [value, setValue] = useState({ login: '', pass: '' })
	const [showPassword, setShowPassword] = useState(false)

	const handleClickShowPassword = () => setShowPassword(show => !show)

	const handleChangeLogin = e => {
		actions.clearError()
		setValue({ ...value, login: e.target.value })
	}

	const handleChangePassword = e => {
		actions.clearError()
		setValue({ ...value, pass: e.target.value })
	}

	const handleClickOpen = () => {
		// setOpen(true)
		window.open('https://b2b.fstravel.com/profile?samo_action=recovery_password', '_blank')
	}

	return (
		<div className={'signin-form'}>
			{!open ? (
				<>
					<h3>Войти</h3>
					<Box mb={2}>
						<TextField
							label='Введите логин'
							variant='outlined'
							fullWidth
							value={value.login}
							onChange={handleChangeLogin}
						/>
					</Box>
					<Box mb={2}>
						<TextField
							label='Введите пароль'
							variant='outlined'
							fullWidth
							value={value.pass}
							onChange={handleChangePassword}
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										{showPassword ? (
											<VisibilityOff
												className={'icon_pointer'}
												onClick={handleClickShowPassword}
											/>
										) : (
											<Visibility
												className={'icon_pointer'}
												onClick={handleClickShowPassword}
											/>
										)}
									</InputAdornment>
								)
							}}
						/>
					</Box>
					{!!error && <p className={'error_signin'}>{error}</p>}
					<p
						style={{ cursor: 'pointer' }}
						className='forgot_password'
						onClick={handleClickOpen}>
						Не помню пароль
					</p>

					<Button
						variant={'contained'}
						color={'primary'}
						fullWidth
						onClick={() => actions.signin(value.login, value.pass)}
						disabled={!value.login || !value.pass}
					>
						Войти
					</Button>
				</>
			) : (
				<ForgotPassword setOpen={setOpen} />
			)}
		</div>
	)
}

export default Auth
