import { createAction, createReducer } from "redux-act"
import { combineReducers } from "redux"
import { DetailInitialState } from "../constants"

/** ACTIONS BEGIN */

export const getOrderByNumber = createAction("@orders/detail/setData")
export const setDetailData = createAction("@orders/detail/getByNumber")
export const purifyDetailData = createAction("@orders/detail/getByNumber")
export const setLoadingStatusCredit = createAction("@orders/detail/setLoadingStatus")

export const payOrderRequest = createAction("@orders/payOrderRequest")
export const confirmPaymentRequest = createAction(
  "@orders/confirmPaymentRequest"
)

export const printOrderRequest = createAction("@orders/printOrderRequest")
export const printOrderSuccess = createAction("@orders/printOrderSuccess")

export const cancelOrderPositionRequest = createAction(
  "@orders/cancelPositionRequest"
)

export const cancelOrderPositionSuccess = createAction(
  "@orders/cancelPositionSuccess"
)
export const cancelOrderPositionFailure = createAction(
  "@orders/cancelPositionFailure"
)

export const printRefundRequest = createAction("@orders/printRefundRequest")
export const printRefundSuccess = createAction("@orders/printRefundSuccess")
export const printRefundFailure = createAction("@orders/printRefundFailure")

export const getHistoryRequest = createAction("@orders/getHistoryRequest")
export const getHistorySuccess = createAction("@orders/getHistorySuccess")
export const historyPurify = createAction("@orders/historyPurify")

export const updateAddictionalServices = createAction(
  "@orders/detail/addAddictionalServicesSuccess"
)

export const purifyAddictionalServices = createAction(
  "@orders/detail/purifyAddictionalServicesSuccess"
)

export const cancelNotification = createAction(
  "@orders/detail/cancelNotification"
)
export const approveNotification = createAction(
  "@orders/detail/approveNotification"
)

export const deleteAddictionalService = createAction(
  "@orders/detail/deleteAddictionalService"
)
export const addAddictionalService = createAction(
  "@orders/detail/addAddictionalService"
)

export const setCalculationStatus = createAction(
  "@orders/detail/setCalculationStatus"
)

export const getCalcutionSuccess = createAction(
  "@orders/detail/getCalcutionSuccess"
)

export const addictionalServiceActionsSuccess = createAction(
  "@orders/detail/addictionalServiceActionsSuccess"
)

export const refreshOrder = createAction("@orders/detail/refreshOrder")

export const getActiveCalculationsRequest = createAction(
  "@orders/getActiveCalculationsRequest"
)
export const getActiveCalculationsSuccess = createAction(
  "@orders/getActiveCalculationsSuccess"
)

export const issueTicketsRequest = createAction("@orders/issueTicketsRequest")

export const issueTicketsSuccess = createAction("@orders/issueTicketsSuccess")

export const issueTicketsPurify = createAction("@orders/issueTicketsPurify")

export const changeStatusOfActiveCalculation = createAction(
  "@orders/changeStatusOfActiveCalculation"
)

export const getOrderPaymentsRequest = createAction("@orders/getOrderPayments")

export const getOrderPaymentsSuccess = createAction(
  "@orders/getOrderPaymentsSuccess"
)

export const issueOrderPositionRequest = createAction(
  "@order/issueOrderPositionRequest"
)
export const issueOrderPositionSuccess = createAction(
  "@order/issueOrderPositionSuccess"
)
export const setAvailableInsurances = createAction(
  "@order/setAvailableInsurances"
)

export const purifyAvailableInsurances = createAction(
  "@order/purifyAvailableInsurances"
)

export const addInsurances = createAction("@order/addInsurances")
export const deleteInsurances = createAction("@order/deleteInsurances")
export const deleteInsurancesSuccess = createAction(
  "@order/deleteInsurancesSuccess"
)

export const getModalErrorText = createAction("@@order/getModalErrorText")

export const showModalError = createAction("@@order/showModalError")

export const getAgentOrderBillRequest = createAction(
  "order-legacy/getAgentOrderBillRequest"
)

export const promocodeApply = createAction('@@order/promocodeApply');

export const promocodeApplySuccess = createAction('@@order/promocodeApplySuccess');

export const createPaymentSuccess = createAction('@@order/createPaymentRequest');
export const createPaymentTinkoffSuccess =createAction('@@order/createPaymentTinkoffSuccess');
export const getQrRequest = createAction('@@order/getQrRequest');
export const getQrSuccess = createAction('@@order/getQrSuccess');
export const changePaymentMethod = createAction('@@order/changePaymentMethod');

/** ACTIONS FINISH */

/** REDUCERS BEGIN */

const orderDetail = createReducer({}, DetailInitialState)
orderDetail.on(setDetailData, (_, payload) => ({ ...payload }))
orderDetail.on(purifyDetailData, () => DetailInitialState)

const printedOrder = createReducer({}, "")
printedOrder.on(printOrderSuccess, (_, payload) => payload)
printedOrder.on(printOrderRequest, () => "")

const orderPositionCancelStatus = createReducer({}, false)
orderPositionCancelStatus.on(cancelOrderPositionRequest, () => true)
orderPositionCancelStatus.on(cancelOrderPositionSuccess, () => false)
orderPositionCancelStatus.on(cancelOrderPositionFailure, () => false)

const history = createReducer({}, [])
history.on(getHistorySuccess, (_, payload) => payload)
history.on(historyPurify, () => [])

const addictionalServices = createReducer({}, [])

addictionalServices.on(updateAddictionalServices, (s, p) => [...s, ...p])
addictionalServices.on(addAddictionalService, (s, p) =>
  s.map(x => {
    if (p.services.includes(x.type)) {
      return { ...x, isLoading: true }
    }
    return x
  })
)
addictionalServices.on(deleteAddictionalService, (s, p) =>
  s.map(x => {
    if (p.includes(x.type)) {
      return { ...x, isLoading: true }
    }
    return x
  })
)
addictionalServices.on(addictionalServiceActionsSuccess, (s, p) =>
  s.map(x => ({
    ...x,
    checked: p.includes(x.type) ? !x.checked : x.checked,
    isLoading: p.includes(x.type) ? false : x.isLoading
  }))
)
addictionalServices.on(purifyAddictionalServices, () => [])

const calculation = createReducer({}, null)
const calculationLoadStatus = createReducer({}, "Idle")
calculation.on(getCalcutionSuccess, (_, p) => p)
calculationLoadStatus.on(setCalculationStatus, (_, p) => p)

const activeCalulations = createReducer(
  {},
  { totalAmount: 0, items: [], totalAmountWithSubAgentExtraCharge: 0 }
)

activeCalulations.on(getActiveCalculationsSuccess, (_, p) => p)
activeCalulations.on(changeStatusOfActiveCalculation, (s, p) => {
  return {
    ...s,
    items: [
      ...(s.items || []).map(x => {
        if (x.supportTicketCalculation.id === p.id) {
          x.supportTicketCalculation.status = {
            code: p.status,
            description: ""
          }
        }
        return { ...x }
      })
    ]
  }
})

const getOrderPayments = createReducer({}, null)

getOrderPayments.on(getOrderPaymentsSuccess, (_, s) => ({ ...s }))

const issueStatus = createReducer({}, null)
issueStatus.on(issueTicketsSuccess, (_, payload) => payload)
issueStatus.on(issueTicketsPurify, () => null)

const availableInsurances = createReducer({}, null)
availableInsurances.on(setAvailableInsurances, (_, p) => ({ ...p }))
availableInsurances.on(addInsurances, (s, p) => {
  if (!s) {
    return null
  }
  return {
    items: s.items.map(x => {
      if (p.includes(x.product.id)) {
        return { ...x, loading: true }
      }
      return x
    }),
    total: s.total
  }
})

availableInsurances.on(deleteInsurances, (s, p) => {
  if (!s) {
    return null
  }
  return {
    items: s.items.map(x => {
      if (p.includes(x.product.id)) {
        return { ...x, loading: true }
      }
      return x
    }),
    total: s.total
  }
})

availableInsurances.on(purifyAvailableInsurances, () => null)

export const modalErrorVisible = createReducer({}, null)
modalErrorVisible.on(showModalError, (_, payload) => payload)

export const modalErrorText = createReducer({}, null)
modalErrorText.on(getModalErrorText, (_, payload) => payload)

const additionsLoading = createReducer({}, false)
additionsLoading.on(deleteInsurances, () => true)
additionsLoading.on(deleteInsurancesSuccess, () => false)
additionsLoading.on(deleteAddictionalService, () => true)
additionsLoading.on(addictionalServiceActionsSuccess, () => false)


const creditLoading = createReducer({},false);
creditLoading.on(setLoadingStatusCredit,(_,payload) => payload);

export const promocodeApplyStatus = createReducer({}, false);
promocodeApplyStatus.on(promocodeApplySuccess, (_, payload) => payload);

export const paymentTinkoffUrl = createReducer({}, null);
paymentTinkoffUrl.on(createPaymentTinkoffSuccess,(_, payload) => payload.paymentURL);
export const paymentTinkoffQrNumber = createReducer({}, null);
paymentTinkoffQrNumber.on(createPaymentSuccess,(_, payload) => payload.paymentNumber);
export const paymentQr = createReducer({}, null);
paymentQr.on(getQrSuccess, (_, payload) => payload);
export const paymentMethod = createReducer({}, 'Card');
paymentMethod.on(changePaymentMethod, (_, payload) => payload);

/** REDUCERS END */

export default combineReducers({
  data: orderDetail,
  addictionalServices,
  printedOrder,
  orderPositionCancelStatus,
  history,
  calculation,
  calculationLoadStatus,
  activeCalulations,
  issueStatus,
  getOrderPayments,
  availableInsurances,
  modalErrorText,
  modalErrorVisible,
  additionsLoading,
  creditLoading,
  promocodeApplyStatus,
  paymentTinkoffUrl,
  paymentTinkoffQrNumber,
  paymentQr,
  paymentMethod,
})
