const groupTowns = (arrWithTowns) => {

	return arrWithTowns?.reduce((accumulator, town) => {
		if (!accumulator[town.regionName]) {
			accumulator[town.regionName] = {
				id: town.regionId,
				towns: []
			}
		}
		accumulator[town.regionName].towns.push(town)
		return accumulator
	}, {})
}

export default groupTowns
