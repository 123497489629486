import { create } from "zustand";
import axios from "axios";
import { devtools } from "zustand/middleware";
import { format } from "date-fns";
import useAuthStore from "../Auth/store";
import {isArray} from "lodash";

const BASE_URL = process.env.REACT_APP_FS_APIGATE + "/v1/";
const BASE_URL_APIGATE = process.env.REACT_APP_FS_APIGATE;

const useLoyaltyStore = create(devtools((set, get) => ({
	loading: false,
	memberId: null,
	phoneFound: '',
	emailFound: '',
	isMember: '',
	client: null,
	preorder: null,
	history: null,
	spentPoints: null,
	promocode: '',
	errorCreateUser: '',
	subscriptions: [
		{
			brand: "FUNSUN",
			pointOfContact: "Email"
		}
	],
	actions: {
		setMemberId: (id) => {
			set(() => ({ memberId: id }))
			set(() => ({ phoneFound: '' }))
			set(() => ({ emailFound: '' }))
			set(() => ({ client: null }))
			set({ isMember: '' })
			set({ spentPoints: null })
		},
		getTourTypeName: async (form, to, tourKey) => {
			if (form === 1) {
				try {
					const res = await axios({
						url: `${BASE_URL_APIGATE}/FiltersByHotel/tours?departureCountryId=210357&arrivalCountryId=${to}`,
						method: 'GET',
					})
					set({ tourTypeName: res.data?.find(item => item.id === tourKey)?.type })
				} catch (e) {

				}
			} else {
				try {
					const res = await axios({
						url: `${BASE_URL_APIGATE}/FiltersByTour/tours?departureCityId=${form}&arrivalCountryId=${to}`,
						method: 'GET',
					})
					set({ tourTypeName: res.data?.find(item => item.id === tourKey)?.type })
				} catch (e) {

				}
			}

		},
		preorderNew: async (payload, currentTour, promocode = '') => {
			const tourDescription = localStorage.getItem('tour')
			const hotel = payload?.hotels && isArray(payload?.hotels) ? payload?.hotels[0] : null
			// await get().actions.getTourTypeName(payload?.townFromKey, payload?.stateKey, payload?.tourKey)
			const money = payload?.moneys?.find(i => i?.currencyKey === 1)
			let req = {
				pointOfContact: useAuthStore.getState().agent.agents.id.toString(),
				customer: {
					mobilePhone: get().phoneFound,
					email: get().emailFound,
					// ids: {...get().client?.customer?.ids, userwebsiteid: ''}
				},
				order: {
					bonusPoints: get().spentPoints ? [{ amount: get().spentPoints }] : [],
					totalPrice: money?.price,
					customFields: {
						loyalty: !!get().client,
						tourName: currentTour?.name || JSON.parse(tourDescription)?.name || payload.tour,
						adult: payload.peoples.filter(item => item.age === 1).length,
						child: payload.peoples.filter(item => item.age === 2).length,
						infant: payload.peoples.filter(item => item.age === 3).length,
						tourStateName: payload.state,
						typeTariff: JSON.parse(tourDescription)?.accommodationPriceOffer ? JSON.parse(tourDescription)?.accommodationPriceOffer[0].programTypeName : '',
						dataBeg: payload.dateBeg,
						dataEnd: payload.dateEnd,
						tourTypeName: payload?.townFromKey === 1 ? "hotel" : "tour"
					},
					lines: [
						{
							product: {
								ids: {
									website: hotel?.key
								},
							},
							status: {
								ids: {
									externalId: "Не Оплачено"
								}
							},
							customFields: {
								hotelOrderInc: hotel?.key,
								hotelOrderName: "Отель",
								hotelState: hotel?.state,
								// hotelResort: payload.tour.hotel.resort?.name,
								hotelTown: hotel?.town,
								hotelName: hotel?.name,
								hotelCategory: hotel?.star,
								roomType: hotel?.htplace,
								meal: hotel?.meal,
								hotelDatabeg: hotel?.datebeg,
								hoteldataend: hotel?.dateend
							},
							basePricePerItem: +(money?.price / payload.peopleCount).toFixed(2),
							quantity: payload.peopleCount,
							lineId: "1",
							lineNumber: "1"
						},
					],
					pointOfContact: 'b2b',
					promocodeFS: true
				}
			}
			if (promocode) {
				req = {...req, order: {...req.order, coupons: [{
							ids: {
								code: promocode
							}
						}],}}
			}

			try {
				const res = await axios({
					url: `${BASE_URL}order/preorder`,
					method: 'POST',
					data: req
				})
				set({ preorder: res.data })

			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		preorderTransactionNew: async (payload, claimId, promocode = '') => {
			const money = payload?.moneys?.find(i => i?.currencyKey === 1)
			// const basePricePerItem = money.price / payload.peopleCount
			let req = {
				pointOfContact: useAuthStore.getState().agent.agents.id.toString(),
				customer: {
					mobilePhone: get().phoneFound
				},
				order: {
					...get().preorder.order,
					bonusPoints: get().spentPoints ? [{ amount: get().spentPoints }] : [],
					lines: get().preorder.order.lines,
					ids: { claim: claimId },
					transaction: {
						ids: { externalId: claimId },
					},
					pointOfContact: 'b2b',
					promocodeFS: true
				}
			}
			if (promocode) {
				req = {...req, order: {...req.order, coupons: [{
							ids: {
								code: promocode
							}
						}],}}
			}
			try {
				await axios({
					url: `${BASE_URL}order/begin-order-transaction`,
					method: 'POST',
					data: req
				})
				await get().actions.preorderCommit(claimId)
			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		preorder: async (payload, currentTour, promocode = '') => {
			const tourDescription = JSON.parse(localStorage.getItem('tour'))
			let req = {
				customer: {
					mobilePhone: get().phoneFound,
					email: get().emailFound,
					// ids: {...get().client?.customer?.ids, userwebsiteid: ''}
				},
				order: {
					bonusPoints: get().spentPoints ? [{ amount: get().spentPoints }] : [],
					totalPrice: payload.price.amount,
					customFields: {
						loyalty: !!get().client,
						tourName: currentTour?.name || tourDescription?.name || payload.tour.name,
						tourTypeName: payload.tour.tourType.name,
						dataBeg: payload.tour.accommodation.beginDate,
						dataEnd: payload.tour.accommodation.endDate,
						adult: payload.tour.paxes.filter(item => item.person.ageGroup === 1).length,
						child: payload.tour.paxes.filter(item => item.person.ageGroup === 2).length,
						infant: payload.tour.paxes.filter(item => item.person.ageGroup === 3).length,
						tourStateName: payload.tour.hotel.country.name,
						typeTariff: tourDescription?.accommodationPriceOffer ? tourDescription?.accommodationPriceOffer[0].programTypeName : tourDescription?.programType?.name
					},
					lines: [
						{
							product: {
								ids: {
									website: payload.tour.hotel.id
								},
							},
							status: {
								ids: {
									externalId: "Не Оплачено"
								}
							},
							customFields: {
								hotelOrderInc: payload.tour.hotel.id,
								hotelOrderName: "Отель",
								hotelState: payload.tour.hotel.country?.name,
								hotelResort: payload.tour.hotel.resort?.name,
								hotelTown: payload.tour.hotel.city?.name,
								hotelName: payload.tour.hotel.name,
								hotelCategory: payload.tour.hotel.categoryStarsCount,
								roomType: payload.tour.hotel.place.name,
								meal: payload.tour.hotel.mealType.name,
								hotelDatabeg: payload.tour.hotel.checkinDate,
								hoteldataend: payload.tour.hotel.checkoutDate
							},
							basePricePerItem: payload.price.amount / payload.tour.paxes.length,
							quantity: payload.tour.paxes.length,
							lineId: "1",
							lineNumber: "1"
						},
					],
					pointOfContact: 'b2b',
					promocodeFS: true
				}
			}
			if (promocode) {
				req = {...req, order: {...req.order, coupons: [{
							ids: {
								code: promocode
							}
						}],}}
			}
			try {
				const res = await axios({
					url: `${BASE_URL}order/preorder`,
					method: 'POST',
					data: req
				})
				set({ preorder: res.data })
			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		preorderTransaction: async (payload, claimId) => {
			// const basePricePerItem = payload.price.amount / payload.tour.paxes.length
			const req = {
				customer: {
					mobilePhone: get().phoneFound
				},
				order: {
					...get().preorder.order,
					totalPrice: payload.price.amount,
					bonusPoints: get().spentPoints ? [{ amount: get().spentPoints }] : [],
					lines: get().preorder.order.lines,
					ids: { claim: claimId },
					transaction: {
						ids: { externalId: claimId },
					},
					pointOfContact: 'b2b',
					promocodeFS: true
				}
			}
			try {
				await axios({
					url: `${BASE_URL}order/begin-order-transaction`,
					method: 'POST',
					data: req
				})
				await get().actions.preorderCommit(claimId)
			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		preorderCommit: async (transactionId) => {
			try {
				await axios({
					url: `${BASE_URL}order/commit-order-transaction`,
					method: 'POST',
					data: {
						pointOfContact: useAuthStore.getState().agent.agents.id.toString(),
						orderTransactionExternalId: transactionId
					}
				})
			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		checkLoyalty: async (phone, email) => {
			if (phone) {
				await get().actions.checkPhone(phone)
			}
			if ((email && get().phoneFound === 'NotFound') || (email && !phone)) {
				await get().actions.checkEmail(email)
			}
			if (
				(get().phoneFound === 'NotFound' && get().emailFound === 'NotFound')
				|| (get().phoneFound === '' && get().emailFound === 'NotFound')
				|| (get().phoneFound === 'NotFound' && get().emailFound === '')
			) {
				set({ isMember: 'NotFound' })
				set(() => ({ client: null }))
			}
		},
		checkPhone: async (phone) => {
			try {
				const res = await axios({
					url: `${BASE_URL}client/check-phone?phone=${phone}&SubscriberTypeFS=b2b&PointOfContact=${useAuthStore.getState().agent.agents.id.toString()}`,
					method: 'GET'
				})
				set({ client: res.data })
				set({ phoneFound: phone })
				set({ isMember: '' })
			} catch (e) {
				if (e?.response?.status === 404) {
					set({ phoneFound: 'NotFound' })
				}
				console.log(e?.response?.data, 'e')
			}
		},
		checkEmail: async (email) => {
			try {
				const res = await axios({
					url: `${BASE_URL}client/check-email?email=${email}`,
					method: 'GET'
				})
				set({ client: res.data })
				set({ isMember: '' })
				set({ emailFound: email })
			} catch (e) {
				if (e?.response?.status === 404) {
					set({ emailFound: 'NotFound' })
				}
				console.log(e?.response?.data, 'e')
			}
		},
		createClient: async (payload) => {
			get().actions.clearErrorCreateUser()
			const req = {
				...payload,
				birthDate: format(payload.birthDate, 'dd.MM.yyyy'),
				subscriptions: [
					{
						brand: "FUNSUN",
					}
				]
			}
			try {
				await axios({
					url: `${BASE_URL}client/create?pointOfContact=${useAuthStore.getState().agent.agents.id.toString()}`,
					method: 'POST',
					data: req
				})
				get().actions.checkPhone(payload.mobilePhone)
			} catch (e) {
				if (e?.response?.status === 400) {
					set({ errorCreateUser: e?.response?.data?.errorMessage.split(':')[1] })
				}
				if (e?.response?.status === 500 || e?.response?.data?.status === 'InternalServerError' || e?.response?.data?.errorMessage === 'Service unavailable') {
					set({ errorCreateUser: 'Сервис недоступен, повторите попытку позже.' })
				}
				console.log(e?.response?.data, 'e')

			}
		},
		getPointsHistory: async () => {
			try {
				const res = await axios({
					url: `${BASE_URL}client/customer-bunus-points-history?phone=${get().phoneFound}&Page.PageNumber=1&Page.ItemsPerPage=20&PointOfContact=${useAuthStore.getState().agent.agents.id.toString()}`,
					method: 'GET'
				})
				set({ history: res.data })
			} catch (e) {
				console.log(e?.response?.data, 'e')
			}
		},
		setSpentPoints: (points) => {
			set({ spentPoints: points })
		},
		applyPromoCode: (code) => {
			set({ promocode: code })
		},
		clearErrorCreateUser: () => {
			set({ errorCreateUser: '' })
		},
	}
})))

export default useLoyaltyStore;
