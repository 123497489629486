import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Baggage1 from './Baggage';
import {ReactComponent as Baggage2} from '../../../../asset/images/avia/search-result/baggage2.svg';
import Head from './Head';
import formatPrice from '../../../../functions/formatPrice'
import FlightsList from '../Flight';
import { useNavigate } from 'react-router-dom';
import { useTicketProps } from '../useTicketProps';
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../../../ui/PrimaryButton';

const shim = keyframes`
  0% {
    background-color: #C8C8C8;
  }

  99% {
    background-color: transparent;
  }

  100% {
    display: none;
  }
`;

const ItemContainer = styled.li`
  position: relative;
  ${() => `
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
        border-radius: 10px;
        font-family: "Open Sans";
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        position: relative;
        @media screen and (max-width: 767px) {
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        }
    `}
`;

const Overlay = styled.div`
  position: absolute;
  animation: ${shim} 0.2s linear forwards;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
`;

const ItemLeftSide = styled.div`
  border-right: 1px solid #DCDCDC;
  padding: 24px 20px;
  text-align: left;
  & .segments {
    min-height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > :first-child > h6 {
      margin-top: 0;
    }
  }
  width: 70%;
  @media screen and (min-width: 1143px) {
    width: 600px;
  }
  @media screen and (max-width: 767px) {
    border-right: none;
    padding: 14px 0 9px 0;
    width: 100%;
  }
`;

const ItemRightSide = styled.div`
  width: 225px;
  position: relative;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BodyForm = styled.form`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  & > div {
    margin-bottom: 24px;
  }
`;


const CloseDetailWrapper = styled.div`
  position: absolute;
  bottom: 0;
`;

const CloseDetailLink = styled.a`
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  align-self: stretch;
  color: #737373;
  text-decoration: none;
`;
const BodyFormLink = styled.a`
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  align-self: stretch;
  color: #4872F2;
  text-decoration: none;
`;

const BaggageContainer = styled.div`
  display: flex;
  height: 83px;
  justify-content: center;
  align-items: flex-end;
`;

const Button = MUIstyled(PrimaryButton)(()=>({
  padding: '8px 20px',
}))

const Item = () => {
  const {
    flights,
    prices,
    airlinesInfo,
    onClick,
    ...props
  } = useTicketProps();

  const navigate = useNavigate()

  const totalPrice = useMemo(() => {
    return formatPrice(prices[0]);
  }, [prices]);

  const [active, setActive] = useState(false);
  // const [debug, setDebug] = useState(false);
  // const closeDebug = () => setDebug(false);

  const [offsetBeforeClick, setOffset] = useState(0);
  const [cachedActive, seCachedActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (active !== cachedActive && ref.current) {
      const { y } = ref.current.getBoundingClientRect();
      const windowOffset = window.scrollY;
      window.scrollTo({ top: windowOffset + (y - offsetBeforeClick) });
      seCachedActive(active);
    }
  }, [active, offsetBeforeClick, cachedActive]);

  const handleBuyClick = (e) => {

    const params = flights.map(x=>['flights',x.index]);
    params.push(['group',props.groupIndex])
    params.push(['id',props.searchId])
    var search = new URLSearchParams(params);
    navigate(`/avia/booking?${search.toString()}`);
    e.preventDefault();
  };

  const sendToggleHideEvent = (action) => {
    // ReactGA.event({
    //   category: 'Search',
    //   action,
    // });
  };

  const handleDetailClick = (e) => {
    e.preventDefault();
    if (ref.current) {
      setOffset(ref.current.getBoundingClientRect().y);
    }
    setActive((a) => {
      if (a) {
        sendToggleHideEvent('flights_search_item_details_hide');
      } else {
        sendToggleHideEvent('flights_search_item_details_show');
      }

      return !a;
    });
    typeof onClick === 'function' && onClick();
  };

  return (
    <ItemContainer ref={ref}>
      <Overlay
        onAnimationEnd={(e) => {
          e.target.style.display = 'none';
        }}
      />
      <ItemLeftSide>
        <Head />
        <FlightsList active={active} className="segments" items={flights} airlinesInfo={airlinesInfo}/>
      </ItemLeftSide>
      <ItemRightSide>
        <BodyForm>
          <BaggageContainer>
            {props.baggage ? (
              props.baggage.value > 0 ? (
                <Baggage1 value={props.baggage.value} />
              ) : (
                <Baggage2 />
              )
            ) : (
              <Baggage1 value="?" />
            )}
          </BaggageContainer>
          <Button
            data-cy={`buyAviaTicketButton_${props.id}`}
            onClick={handleBuyClick}
            color="secondary"
            type="submit"
          >
            Купить за {totalPrice}
          </Button>
          
          <CloseDetailWrapper>
            {!active && (<BodyFormLink href="test.ru" onClick={handleDetailClick}>
              Детали перелета
            </BodyFormLink>)}
            {active && (<CloseDetailLink href="test.ru" onClick={handleDetailClick}>
              Скрыть
            </CloseDetailLink>)}
          </CloseDetailWrapper>
          
        </BodyForm>
      </ItemRightSide>
    </ItemContainer>
  );
};

export default Item;
