import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import './style.scss'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



function BannerHorFull({ content, title = '' }) {
    const handleClick = (link) => {
        if (link) {
            window.location.href = link;
        }
    }
    return <div className="banner-hor-full">
        <h3 className='title'>{title}</h3>
        <Swiper
            spaceBetween={10}
            modules={[Autoplay]}
            rewind={true}
        // autoplay={{
        //     delay: 5000,
        //     disableOnInteraction: false,
        // }}
        >
            {content.map(item => <SwiperSlide>
                <div style={{ backgroundImage: `url(${item?.img})` }} className='banner'>
                    <div onClick={() => handleClick(item.link)} className='banner-text'>{item?.text}</div>
                </div>
            </SwiperSlide>)}
        </Swiper>
    </div>

    /* 
    на случай если не нужен слайдер
    return <div className="banner-hor-full">
        <h3 className='title'>{title}</h3>
        <div style={{ backgroundImage: `url(${content[0].img})` }} className='banner'>
            <div className='banner-text'>{content[0]?.text}</div>
        </div>
    </div>
    */
}



export default BannerHorFull