import React from 'react';
import styled from 'styled-components';
import Text from '../../Typography';

const StyledTextarea = styled.textarea`
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 12px;
  outline: none;
  font-family: "Open Sans";
  color: #3C3C3C;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #B9B9B9;
  }
  &:focus {
    border: 1px solid #3C3C3C;
  }
  &.error {
    border: 1px solid #E73C3E;
    &::placeholder {
      color: #E73C3E;
    }
  }
`;

const Label = styled(Text)`
  display: block;
  margin-bottom: 5px;
  &.error {
    color: #E73C3E;
  }
`;

const Textarea = ({
  error,
  label,
  children,
  marginTop,
  ...props
}) => {
  const className = error ? 'error' : '';
  return (
    <Label style={{ marginTop }}>
      {label && (
        <Label className={className} color="dark-gray">
          {label}
        </Label>
      )}
      <StyledTextarea className={className} {...props} />
      {children}
    </Label>
  );
};

export default Textarea;
