import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { format } from "date-fns";
import { generate } from '@wcj/generate-password'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useErskStore = create(devtools((set, get) => ({
	dateFrom: format(new Date(), 'yyyy-MM-dd'),
	dateTo: format(new Date(), 'yyyy-MM-dd'),
	report: '',
	documents: [],
	userId: null,
	uploadSuccess: false,
	uploadFail: false,
	uploadFailError: '',
	userNotFound: false,
	userData: {
		phone: '',
		email: '',
		dogovor: ''
	},
	actions: {
		setUserData: (fields) => {
			set({ userData: fields })
		},
		clearUserData: () => {
			set({
				userData: {
					phone: '',
					email: '',
					dogovor: ''
				}
			})
		},
		changeDateFrom: date => {
			set({ dateFrom: date ? format(date, 'yyyy-MM-dd') : null })
		},
		changeDateTo: date => {
			set({ dateTo: date ? format(date, 'yyyy-MM-dd') : null })
		},
		generateReport: async () => {
			const agents = JSON.parse(localStorage.getItem('agentsUsers'))
			const partPass = JSON.parse(localStorage.getItem('partPass'))
			const agentId = agents.find(item => item.partpassId === Number(partPass))?.partner
			try {
				const res = await axios({
					url: `${BASE_URL}/v1/report/${agentId}`,
					method: 'GET',
					params: {
						from:get().dateFrom,
						to: get().dateTo,
						phone: get().userData.phone ? `+7${get().userData.phone}` : '',
						email: get().userData.email,
						agreement: get().userData.dogovor
					}
				})
				set({ report: res.data })
				const blob = new Blob([res.data], { type: 'text/html' });
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`Report_${agentId}_${get().dateFrom}_${get().dateTo}.html`,
				);
				document.body.appendChild(link);

				link.click();

				link.parentNode.removeChild(link);
			} catch (e) {
				console.log(e?.response?.data)
			}
		},
		uploadFiles: async (file) => {
			const data = new FormData();
			data.append('Files', file)
			try {
				const res = await axios({
					url: `${BASE_URL}/v1/document/upload`,
					method: 'POST',
					data,
					headers: { 'Content-Type': 'multipart/form-data' },
				})
				set({ documents: [...get().documents, { ...res.data?.[0], fileId: res.data?.[0].id }] })
			} catch (e) {
				console.log(e?.response?.data)
			}
		},
		deleteFile: (id) => {
			set({ documents: get().documents.filter(item => item.id !== id) })
		},
		getUserId: async (params) => {
			try {
				const res = await axios({
					url: `https://auth.fstravel.com/api/account/buyer-samo-id?email=${params}`,
					method: 'GET',
				})
				set({ userId: res.data })
			} catch (e) {
				set({ userNotFound: true })
				console.log(e?.response?.data)
			}
		},
		uploadContract: async () => {
			const agents = JSON.parse(localStorage.getItem('agentsUsers'))
			const partPass = JSON.parse(localStorage.getItem('partPass'))
			const agentId = agents.find(item => item.partpassId === Number(partPass))?.partner
			const data = {
				number: get().userData.dogovor,
				userId: get().userId.toString(),
				agentId: agentId,
				documents: get().documents,
				email: get().userData.email,
				phone: '+7' + get().userData.phone,
			}
			try {
				const findContract = await get().actions.getContract(agentId, data.number)
				if (findContract) {
					const updateData = {
						number: data.number,
						documents: data.documents
					}
					await axios({
						url: `${BASE_URL}/v1/contract/documents`,
						method: 'POST',
						data: updateData,
					})
				} else {
					await axios({
						url: `${BASE_URL}/v1/contract`,
						method: 'POST',
						data
					})
				}
				set({ uploadFail: false })
				set({ uploadSuccess: true })
			} catch (e) {
				set({ uploadFail: true })
				// if (e.response.data.detail.includes('Контракт с номером')) {
				// 	set({ uploadFailError: `Пакет документов по договору №${get().userData.dogovor} уже загружен` })
				// }

				// Обход ошибки 500 с бека https://jira-new.fstravel.com/browse/NEB-1705
				if (e?.response?.status === 500 &&
					e?.response?.data?.detail?.includes("The required column 'AccountEmail' was not present in the results of a 'FromSql' operation.")) {
					const findContract = await get().actions.getContract(agentId, data.number)
					if (findContract) {
						set({ uploadFail: false })
						set({ uploadSuccess: true })
					}
				}
				console.log(e?.response?.data)
			}
		},
		getContract: async (agentId, number) => {
			try {
				const res = await axios({
					url: `${BASE_URL}/v1/contract/${agentId}/${number}`,
					method: 'GET',
				})
				return res.data
			}
			catch (e) {
				return false
			}
		},
		userSignup: async () => {
			const data = {
				email: get().userData.email,
				password: generate({
					length: 8,
					numbers: true
				}),
				phone: '+7' + get().userData.phone.replace(/[\D]+/g, ''),
				emailing: true,
				termsConfirm: true,
				returnUrl: '/'
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/v1/auth/signup`,
					method: 'POST',
					data
				})
				set({ userId: res.data.samoid })
				set({ userNotFound: false })
			} catch (e) {
				console.log(e?.response?.data)
			}
		},
		addNewDocuments: () => {
			set({ uploadSuccess: false })
			set({ uploadFail: false })
			set({ documents: [] })
			set({ userId: null })
			get().actions.clearUserData()
		},
		updateForm: () => {
			set({ userNotFound: false })
			set({ uploadFailError: '' })
			set({ uploadFail: false })
			set({ userId: null })
		}
	}

})))

export default useErskStore