import React, {useState} from 'react';
import ArrowThink from "../../../../../asset/icons/Arrow-think";
import DetailsItem from "./DetailsItem";
import Collapse from '@mui/material/Collapse';


const Order = ({details}) => {

  const [isOpen, setIsOpen] = useState(false)
  const isSet = Object.keys(details || {}).length

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 'Ожидает оплаты':
      case 'Требуется доплата':
      case 'Не оплачен':
        return 'red'
      case 'Оплачен':
      case 'Частично возвращен':
      case 'Возвращен':
      case 'Переплата':
      case 'Оплачено в кредит':
      case 'Оплата в обработке':
        return 'green'
      default:
        return ''
    }
  }
  return (
    <>
      <div className={'avia-order-block__item'}>
        <div className={'header'}>
          <div>
            Детали стоимости заявки
            {isSet && <span className={`status ${getPaymentStatusColor(details?.paymentStatus.description)}`}>{details?.paymentStatus.description}</span>}
          </div>
        </div>
        <div className={`avia-order-icon ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
          {isSet && <ArrowThink/>}
        </div>
      </div>
      <Collapse in={isOpen}>
        <div style={{padding: '0 15px 15px'}}>
          <div className="orders-header">
            <div className="orders">Наименование</div>
            <div className="price">Стоимость</div>
            <div className="status">Cтатус</div>
          </div>
          {details?.orderAmount?.detalization.map((el, idx) =>
            <DetailsItem key={idx} status={details?.orderPositions[0]?.status.description} data={el} total={details?.amount}/>
          )}
        </div>
      </Collapse>
    </>

  );
};

export default Order;
