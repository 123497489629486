import { Breadcrumbs, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import StaticBlocks from '../LandingPage/StaticBlocks'
import useSelectActionStore from './store'
import { isMobile } from 'react-device-detect'
import './style.css'
import { Helmet } from 'react-helmet'
import FUN from '../../asset/images/FUN.png'
// import FUNNY from '../../asset/images/faviconNY.png'

export default function ActionPage() {
	const actions = useSelectActionStore(({ actions }) => actions)
	const slugAction = useSelectActionStore(({ slugAction }) => slugAction)
	const { pathname: slugURL } = useLocation()
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	useEffect(() => {
		actions.getSlugActions(slugURL.slice(9))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	// console.log(slugAction, 'slugAction')

	if (!slugAction) return null
	return (
		<>
			<Helmet
				link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
				title={slugAction?.meta_title || slugAction?.title}
				meta={[
					{ name: 'keywords', content: slugAction?.keywords },
					{ name: 'H1', content: slugAction?.h1 },
					{ name: 'robots', content: slugAction?.meta_robots }
				]}
			/>
			<div className='landing landing-container'>
				<Breadcrumbs aria-label='breadcrumb' className={'breadcrumbs-action'}>
					<Link color='inherit' to={'/'}>
						Главная
					</Link>
					<Link style={{ color: '#4872f2' }} to={'/actions'}>Акции</Link>
					<Typography style={{ color: '#4872f2' }}>
						{slugAction?.title}
					</Typography>
				</Breadcrumbs>
				<div className='new-title-action'>
					{slugAction.meta_title || slugAction.title}
				</div>
				<div className='wrap-action-bg'>
					{!isSmallScreen
						? slugAction?.image_preview_banner && (
							<img
								className={'action-bg'}
								alt=''
								src={`${slugAction?.image_preview_banner}`}
							/>
						)
						: slugAction?.image_preview && (
							<img
								className={'action-bg'}
								alt=''
								src={`${slugAction?.image_preview}`}
							/>
						)}
				</div>
				<StaticBlocks
					blocks={slugAction.blocks.filter(block =>
						isMobile ? block.show_mobile : block.show_desktop
					)}
					countryId={slugAction?.countryId}
				/>
			</div>
		</>
	)
}
