
import React from 'react';
import { SvgIcon } from "@mui/material";

const WideIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" style={{ width: '24px', height: '24px' }}>
            <path d="M2.5 5V9H4V4.5H8.5V3H4.5C3.4 3 2.5 3.9 2.5 5ZM4 15H2.5V19C2.5 20.1 3.4 21 4.5 21H8.5V19.5H4V15ZM19 19.5H14.5V21H18.5C19.6 21 20.5 20.1 20.5 19V15H19V19.5ZM18.5 3H14.5V4.5H19V9H20.5V5C20.5 3.9 19.6 3 18.5 3Z" fill="#737373" />
        </SvgIcon>
    );
};

export default WideIcon;