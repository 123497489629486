import  { useState, useEffect  } from 'react'
import ContactsBlock from './ContactsBlock'
import useAuthStore from '../../../Auth/store'
import { Box, TextField } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomInput from '../components/CustomInput/CustomInput';
import CustomSelect from '../components/CustomSelect/CustomSelect';
import PhoneInput from '../AllAgents/components/PhoneInput'
import BpCheckbox from '../../AgentInfo/components/Checkbox/CustomizedCheckbox'
import { Grid, MenuItem } from '@material-ui/core'
import './style.scss'

function Contacts() {
    const [showBlock, toggle] = useState(false)
    const agents = useAuthStore(({ agent }) => agent?.agents)
    const actions = useAuthStore(({ actions }) => actions)

    const townsValues = useAuthStore((state) => state.towns);

    useEffect(() => {
        actions.getTowns()
    }, []);

    const getOptions = (arr) => {
        if (arr?.length) {
            return arr.map((item) => {
                return { label: item.title, value: item.id }
            })
        }
    }

    const [error, setError] = useState({
		phone: false,
        phone1: false,
		email: false,
        email1: false
	})

    const [selectPhone, setSelectPhone] = useState('+7')
    const [selectPhone1, setSelectPhone1] = useState('+7')

	const handleChangeSelectPhone = value => {
		setSelectPhone(value)
        actions.changeAgentPhonePrefix(value)
	}

    // const handleChangeSelectPhone1 = value => {
	// 	// setSelectPhone1(event?.target?.value)
    //     actions.changeContactInfo('phone1',  event.target.value + phone1)
    //     actions.changeAgentPhonePrefix(event.target.value)
	// }

	const [phone, setPhone] = useState(agents?.phone)
    const [phone1, setPhone1] = useState(agents?.phone1)

	const handleChangePhone = event => {
		if (/^[0-9]+[0-9]*$/.test(event.target.value) || !event.target.value) {
			setPhone(event.target.value)
			setError(prev => ({ ...prev, phone: false }))
            actions.changeAgentPhone(event.target.value)
		} else {
			setPhone(event.target.value)
			setError(prev => ({ ...prev, phone: 'Неверный формат телефона' }))
		}
	}

    const handleChangePhone1 = event => {
		if (/^[0-9]+[0-9]*$/.test(event.target.value) || !event.target.value) {
			setPhone1(event.target.value)
			setError(prev => ({ ...prev, phone1: false }))
            actions.changeContactInfo('phone1', selectPhone1 + event.target.value)
		} else {
			setPhone(event.target.value)
			setError(prev => ({ ...prev, phone: 'Неверный формат телефона' }))
		}
	}

    return (
        <div 
        style={{
            backgroundColor: '#F6F8FA',
            padding: 24,
            borderRadius: 16,
            marginBottom: 24 
        }}
        >
            <div className='v-dropdown'>
                <div className="about-agency">Для связи</div>
                <div>
                {showBlock ? (
                        <ExpandLessIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => toggle(!showBlock)}
                            type="button"
                            className="newagency-close-btn"
                        />
                    ) : (
                        <ExpandMoreIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => toggle(!showBlock)}
                            type="button"
                            className="newagency-open-btn"
                        />
                    )}
                </div>
            </div>
            {showBlock &&
                <div style={{ marginTop: '24px' }}>
                    <div className='contacts-grid'>
                        <CustomSelect
                            label='Город'
                            required
                            value={agents?.town.id}
                            options={getOptions(townsValues)}
                            onChange={value => {
                                actions.changeAgentTown(value)
                                }
                            } 
                        />
                       
                        <CustomInput
                            label='Станция метро'
                            className={!agents?.officialName ? 'empty-input' : ''}
                            // onChange={e => {
                            //     actions.changeAgentOfficialName(e.target.value)
                            //     }
                            // } 
                        />
                    </div>
                    <div className='contacts-grid__fullWith'>
                         <CustomInput
                            label='Фактический адрес'
                            required
                            value={agents?.fAddress}
                            className={!agents?.fAddress ? 'empty-input' : ''}
                            onChange={e => {
                                actions.changeAgentFAddress(e.target.value)
                                }
                            } 
                         
                         />
                    </div>
                    <div className='contacts-grid__fullWith'>
                         <CustomInput
                            label='Почтовый индекс'
                            required
                            value={agents?.addressCode}
                            className={!agents?.addressCode? 'empty-input' : ''} 
                            onChange={e => {
                                actions.changeAgentAdressCode(e.target.value)
                                }
                            } 
                         
                         />
                    </div>
                    <div className='contacts-grid'>
                    <Grid item xs={4}>
                    <PhoneInput
                            // selectPhone={selectPhone}
                            handleChangeSelectPhone={actions.changeAgentPhonePrefix}
                            // phone={agents?.phone}
                            phone={'+79032222858'}
                            handleChangePhone={actions.changeAgentPhone}
                            prefix={agents?.prefix}
                            // error={error.phone}
                        />
                    </Grid>

                        <CustomInput
                            label='Email для заявки'
                            required
                            value={agents?.email}
                            onChange={e => {
                                actions.changeAgentEmail(e.target.value)
                                }
                            } 
                        />
                    </div>
                    <div className='contacts-grid'>
                        <PhoneInput
                            // selectPhone={selectPhone1}
                            // handleChangeSelectPhone={handleChangeSelectPhone1}
                            // phone={phone1}
                            // handleChangePhone={handleChangePhone1}
                            // error={error.phone1}
                        />
                        <CustomInput
                            label='Email для рассылки'
                            value={agents?.email1}
                            onChange={e => {
                                actions.changeAgentEmail1(e.target.value)
                                }
                            } 
                        />
                    </div>

                    <div className='contacts-grid__fullWith'>
                         <CustomInput
                            label='ФИО бухгалтера'
                            value={agents?.accountName}
                            onChange={e => {
                                actions.changeAgentAccountName(e.target.value)
                                }
                            } 

                         />
                    </div>

                    <BpCheckbox       
                    /> Получать информацию о заявках

                </div>
            }
        </div>
    )
}

export default Contacts