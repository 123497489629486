import { Button, useYMaps } from '@pbe/react-yandex-maps'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import '../style.css'
import { CountrySelect } from './CountrySelect'

const PortalSearch = ({ children, getHTMLElementId }) => {
	const mount = document.getElementById(getHTMLElementId)
	const el = document.createElement('div')
	useEffect(() => {
		if (mount) mount.appendChild(el)
		return () => {
			if (mount) mount.removeChild(el)
		}
	}, [el, mount])

	if (!mount) return null

	return createPortal(children, el)
}

export default function CustomSearch() {
	const ymaps = useYMaps()
	const [openSearch, setOpenSearch] = useState(false)
	return (
		<>
			<Button
				instanceRef={ref => {
					if (ref) {
						setTimeout(() => {
							setOpenSearch(true)
						}, 0)
					}
				}}
				options={{
					position: {
						top: '10px',
						right: '10px'
					},
					layout: ymaps?.templateLayoutFactory.createClass(
						`<div class="map-input-search" id="popover"></div>`
					)
				}}
			/>
			{openSearch && (
				<PortalSearch getHTMLElementId={'popover'}>
					<CountrySelect />
				</PortalSearch>
			)}
		</>
	)
}
