import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import useMessagesStore from './store'
import MessagesModal from './MessagesModal'

const Container = styled.div`
	padding: 16px;
	display: flex;
	flex-direction: column;
`
const MessagesBlock = styled.div`
	padding: 16px;
	border-radius: 16px;
	background-color: #fffce6;
	display: flex;
	flex-direction: column;
	gap: 16px;
`
const Info = styled.div`
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	text-wrap: wrap;
`

const OpenButton = styled(Button)`
	padding: 8px 20px;
	background-color: #ffe100;
	border-radius: 8px;
	box-shadow: none;

	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: capitalize;
	color: #2e2e32;

	&:hover {
		background-color: #ffcd00;
		box-shadow: none;
	}
`

export default function MessagesTab() {
	const actions = useMessagesStore(({ actions }) => actions)
	const unreadMessages = useMessagesStore(({ unreadMessages }) => unreadMessages)
	const messages = useMessagesStore(({ messages }) => messages)
	const [modalVisible, setModalVisible] = React.useState(false);

	React.useEffect(() => {
		actions.getMessages()
		// eslint-disable-next-line
	}, [])

	return ( 
		unreadMessages.length > 0 && (
			<Container>
				<MessagesBlock>
					<Info>У вас {unreadMessages.length} непрочитанных сообщений</Info>
					<OpenButton
						variant='contained'
						color='primary'
						fullWidth
						onClick={() => setModalVisible(true)}
					>
						Открыть
					</OpenButton>
				</MessagesBlock>
				{modalVisible && <MessagesModal unreadMessages={unreadMessages} messages={messages} onClose={() => setModalVisible(false)} />}
			</Container>
		)
	)
}
