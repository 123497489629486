import React,{ useState, useEffect } from 'react';
import {InputAdornment} from '@material-ui/core';
import useAuthStore from '../../../Auth/store';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiTextField } from '../components/MuiTextField/MuiTextField'
import './style.scss';

function Payment() {
    const [showBlock, setShowBlock] = useState(false);
    const [bicValue, setBicValue] = useState('');
    const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
    
    const actions = useAuthStore(({ actions }) => actions);
    const banks = useAuthStore((state) => state.banks);
    const agentsBankId = useAuthStore(({ agent }) => agent?.agents?.bank?.id);
    const agentsRs = useAuthStore(({ agent }) => agent?.agents?.rs);
    const validateErrorsRS = useAuthStore(({ validateErrors }) => validateErrors?.rs)
    const validateErrorsBik = useAuthStore(({ validateErrors }) => validateErrors?.bik)
    
    useEffect(() => {
        actions.getBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (banks && agentsBankId) {
            setBicValue(banks.find((item) => item.id === agentsBankId)?.bik);
        }
    }, [banks, agentsBankId]);

    const onChangeRs = prop => event => {
        actions.setValidateErrors(prop, false )
        if (event.target.value.length > 20) return;
        else if (!/^\d*$/.test(event.target.value)) {
            actions.setValidateErrors(prop, 'Введите числовое значение' )
		}
        else {
            actions.changeContactInfo('rs', event.target.value)
		}
    };

    const [targetBankName, setTargetBankName] = useState('');

    const onChangeBIC = prop => event => {
        actions.setValidateErrors(prop, false )
        setTargetBankName('');
        const value = event.target.value;
        const targetBank = banks?.find((item) => item.bik === value);
        
        if (event.target.value.length > 9) return;
        else if (!/^\d*$/.test(event.target.value)) {
            actions.setValidateErrors(prop, 'Введите числовое значение')
		}
        else {
            setBicValue(event.target.value);
            actions.changeAgentBank(targetBank);
		}   

        if (event.target.value.length === 9 && !targetBank) {
        setTargetBankName('');
        actions.setValidateErrors(prop, 'Банк не найден. Проверьте корректность БИК')
        return;
        }

        if (targetBank?.title)  { 
            setTargetBankName(
            targetBank.title.length > 24
                ? targetBank.title.slice(0, 24) + '...'
                : targetBank.title
         );
        }  
    };


    return (
        <div
            className="cabinet-payment-block"
        >
            <div className="v-dropdown">
                <div className="cabinet-payment-block__title">Банковские переводы</div>
                <div>
                    {showBlock ? (
                        <ExpandLessIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => setShowBlock(!showBlock)}
                            type="button"
                            className="newagency-close-btn"
                        />
                    ) : (
                        <ExpandMoreIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => setShowBlock(!showBlock)}
                            type="button"
                            className="newagency-open-btn"
                        />
                    )}
                </div>
            </div>

            {showBlock && (
                <div style={{ marginTop: '24px' }}>
                    <div className="payment-grid">
  
                            <MuiTextField
                                label="Рассчётный счет"
                                required
                                fullWidth
                                variant='filled'
                                value={agentsRs}
                                onChange={onChangeRs('rs')}
                                helperText={validateErrorsRS}
                                error={!!validateErrorsRS}
                                onBlur={onChangeRs('rs')}
                                disabled={agentInfo?.agents?.name.includes('F&S')}
                            />

                            <MuiTextField
                                label="БИК"
                                required
                                fullWidth
                                variant='filled'
				                value={bicValue}
                                onChange={onChangeBIC('bik')}
                                onBlur={onChangeBIC('bik')}
                                bankName={targetBankName}
                                error={!!validateErrorsBik}
                                helperText={validateErrorsBik}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment  position="end">
                                        {targetBankName && <span className='target-bank-span'>({targetBankName})</span>}
                                    </InputAdornment>
                                    ), disableUnderline: 'true'}}
                                disabled={agentInfo?.agents?.name.includes('F&S')}
                            />
      
                    </div>
                </div>
            )}
        </div>
    );
}

export default React.memo(Payment)