import { ArrowForwardIosSharp } from '@material-ui/icons'
import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import useHotelDescriptionStore from '../../../store'

import { ReactComponent as Description } from '../../../../../asset/images/hotel/Description.svg'
import { ReactComponent as Location } from '../../../../../asset/images/hotel/Location.svg'
import { ReactComponent as Meal } from '../../../../../asset/images/hotel/Meal.svg'
import { ReactComponent as Children } from '../../../../../asset/images/hotel/Children.svg'
import { ReactComponent as Beach } from '../../../../../asset/images/hotel/Beach.svg'
import { ReactComponent as Territory } from '../../../../../asset/images/hotel/Territory.svg'
import { ReactComponent as Mountain } from '../../../../../asset/images/hotel/Mountain.svg'
import { ReactComponent as Information } from '../../../../../asset/images/hotel/Information.svg'
import { ReactComponent as Conference } from '../../../../../asset/images/hotel/Сonference.svg'
import { ReactComponent as SPA } from '../../../../../asset/images/hotel/SPA.svg'
import MobileCategoryPopup from './MobileCategoryPopup'
import styled from 'styled-components'

const Title = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 24px;
	line-height: 34px;
`

export default function MobileTabsBlock({ close }) {
	const [open, setOpen] = React.useState(false)
	const [labelBlock, setLabelBlock] = useState('')
	const handleClickOpen = name => {
		setLabelBlock(name)
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const hotelDescText = useHotelDescriptionStore(
		({ hotelDescText }) => hotelDescText?.text
	)
	const checkTime = useHotelDescriptionStore(({ checkTime }) => checkTime)
	const comfort = useHotelDescriptionStore(({ comfort }) => comfort)
	const deposit = useHotelDescriptionStore(({ deposit }) => deposit)
	const cards = useHotelDescriptionStore(({ cards }) => cards)
	const restaurants = useHotelDescriptionStore(({ restaurants }) => restaurants)
	const forChildren = useHotelDescriptionStore(({ forChildren }) => forChildren)
	const beach = useHotelDescriptionStore(({ beach }) => beach)
	const distance = useHotelDescriptionStore(({ distance }) => distance)
	const territory = useHotelDescriptionStore(({ territory }) => territory)
	const waterEntertainment = useHotelDescriptionStore(
		({ waterEntertainment }) => waterEntertainment
	)
	const mealTypes = useHotelDescriptionStore(({ mealTypes }) => mealTypes)
	const locationHotel = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel
	)
	const thatAround = useHotelDescriptionStore(({ thatAround }) => thatAround)
	const addInfo = useHotelDescriptionStore(({ addInfo }) => addInfo)
	const roomType = useHotelDescriptionStore(({ roomType }) => roomType)
	const inRoom = useHotelDescriptionStore(({ inRoom }) => inRoom)
	const sport = useHotelDescriptionStore(({ sport }) => sport)
	const mountain = useHotelDescriptionStore(({ mountain }) => mountain)
	const language = useHotelDescriptionStore(({ language }) => language)
	const conferency = useHotelDescriptionStore(({ conferency }) => conferency)
	const spa = useHotelDescriptionStore(({ spa }) => spa)
	const healthy = useHotelDescriptionStore(({ healthy }) => healthy)
	const benefits = useHotelDescriptionStore(({ benefits }) => benefits)
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)
	//проверка, чтобы не отрисовывать FilterBlocks с пустыми значениями
	const isFilterBlocksEmpty = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return !array?.every(item => tag.find(elem => elem.id === item.id))
	}

	const isEmpty = item => {
		if (
			!item ||
			(item?.filters?.length === 0 && item?.text === '') ||
			(item?.text === '' &&
				isFilterBlocksEmpty(
					item?.descriptionBlockTypeId,
					item?.descriptionBlockFilters
				))
		) {
			return false
		} else return true
	}

	const list = useMemo(() => {
		const arr = []
		if (
			isEmpty(checkTime) ||
			isEmpty(comfort) ||
			isEmpty(cards) ||
			isEmpty(roomType) ||
			isEmpty(hotelDescText) ||
			isEmpty(inRoom)
		) {
			arr.push({
				icon: <Description style={{ marginRight: '10px' }} />,
				label: 'Описание'
			})
		}
		if (isEmpty(locationHotel)) {
			arr.push({
				icon: (
					<Location
						style={{
							marginRight: '5px'
						}}
					/>
				),
				label: 'Расположение'
			})
		}
		if (isEmpty(restaurants) || isEmpty(mealTypes)) {
			arr.push({
				icon: <Meal style={{ marginRight: '10px' }} />,
				label: 'Питание'
			})
		}
		if (isEmpty(forChildren)) {
			arr.push({
				icon: <Children style={{ marginRight: '10px' }} />,
				label: 'Для детей'
			})
		}
		if (isEmpty(beach) || isEmpty(distance)) {
			arr.push({
				icon: <Beach style={{ marginRight: '10px' }} />,
				label: 'Пляж'
			})
		}
		if (isEmpty(waterEntertainment) || isEmpty(territory) || isEmpty(sport)) {
			arr.push({
				icon: <Territory style={{ marginRight: '10px' }} />,
				label: 'Территория'
			})
		}
		if (isEmpty(mountain)) {
			arr.push({
				icon: <Mountain style={{ marginRight: '10px' }} />,
				label: 'Горнолыжная инфраструктура'
			})
		}
		if (
			isEmpty(language) ||
			isEmpty(addInfo) ||
			isEmpty(benefits) ||
			isEmpty(cards)
		) {
			arr.push({
				icon: <Information style={{ marginRight: '10px' }} />,
				label: 'Важная информация'
			})
		}
		if (isEmpty(conferency)) {
			arr.push({
				icon: <Conference style={{ marginRight: '10px' }} />,
				label: 'Возможности для конференций'
			})
		}
		if (isEmpty(spa) || isEmpty(healthy)) {
			arr.push({
				icon: <SPA style={{ marginRight: '10px' }} />,
				label: 'SPA и лечение'
			})
		}
		return arr
	}, [])

	return (
		<List>
			{list?.map((item, i) => (
				<ListItem
					key={i}
					onClick={() => handleClickOpen(item.label)}
					disablePadding
					secondaryAction={
						<IconButton edge='end' aria-label='arrow' size='small'>
							<ArrowForwardIosSharp />
						</IconButton>
					}>
					<ListItemButton role={undefined}>
						{item.icon}
						<ListItemText
							primary={item.label}
							primaryTypographyProps={{
								fontSize: 14,
								fontWeight: 600,
								lineHeight: '20px',
								fontFamily: 'Open Sans',
								color: '#7E8389'
							}}
						/>
					</ListItemButton>
				</ListItem>
			))}
			<MobileCategoryPopup
				handleClose={handleClose}
				open={open}
				close={close}
				label={labelBlock}
			/>
		</List>
	)
}
