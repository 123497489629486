import React, {useState} from 'react';
import {Button, FormHelperText, OutlinedInput, InputLabel} from "@material-ui/core";
import {Box} from "@mui/material";
import {CloseOutlined} from "@material-ui/icons";
import {moneySumRegExp} from "../../../../../constants/reg-exps";
import FormControl from "@material-ui/core/FormControl";

const CreateMessageModal = ({onClose, sendMessage}) => {

  const [value, setValue] = useState({orderNumber: '', amount: ''})
  const [errors, setErrors] = useState('')

  const handleChangeAmount = (amount) => {
    setErrors('')
    setValue({...value, amount: amount})
  }

  const handleChangeOrderNumber = (number) => {
    setErrors('')
    setValue({...value, orderNumber: number})
  }

  const validateAmount = (amount) => {
    if (!moneySumRegExp.test(amount) && amount !== '') {
      setErrors(`Сумма переноса указана некорректно`)
    }
  }

  return (
    <div className="popup-bg">
      <div className="popup-block">
        <div style={{position: 'absolute', right: 14, top: 14, cursor: 'pointer'}}>
          <CloseOutlined onClick={onClose}/>
        </div>
        <h3 className="popup-name">
          Запрос на перенос денежных средств
        </h3>
        <p className={'popup-text'}>Для переноса денежных средств введите номер для оплаты, с которого вы хотите перенести денежные средства, и укажите необходимую сумму.</p>
        <Box mt={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Введите номер для оплаты</InputLabel>
            <OutlinedInput
              type={'number'}
              label="Введите номер для оплаты"
              value={value.orderNumber}
              onChange={(e) => handleChangeOrderNumber(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mt={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Сумма переноса</InputLabel>
            <OutlinedInput
              type={'number'}
              label="Сумма переноса"
              value={value.amount}
              onChange={(e) => handleChangeAmount(e.target.value)}
              onBlur={(e) => validateAmount(e.target.value)}
              fullWidth
            />
            {!!errors && (
              <FormHelperText style={{marginLeft: 0}} error id="accountId-error">
                {errors}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box style={{marginTop: '30px', textAlign: 'start'}}>
          <Button
            className={`create-msg-btn ${!value.amount || !!errors ? 'active' : ''}`}
            variant={!value.amount || !!errors ? 'containedPrimary' : 'outlined'}
            color={'primary'}
            onClick={() => sendMessage(value)}
            disabled={!value.amount || !!errors}
          >
            Отправить запрос
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default CreateMessageModal;
