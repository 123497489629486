import { Box, Grid, TextField } from '@mui/material'

function AppliedPromotions({ lines, scores }) {
	const getTotalPromotionsAmount = lines => {
		let amount = scores || 0
		lines.forEach(line => {
			amount += line.appliedPromotions
				.filter(i => i.type === 'discount')
				.reduce((accumulator, promo) => accumulator + promo.amount, 0)
		})
		return amount.toFixed(1)
	}

	const groupedPromotions = () => {
       return lines.reduce((acc, item) => {
				item?.appliedPromotions?.forEach(value => {
					if (value.type === 'discount') {
						let key = value.promotion.name
						acc[key] =  Math.round(((acc[key] || 0) + value.amount ) * 100) / 100 
					}
				})
				return acc
			}, {})
    }

	return (
		<div className='members-form'>
			<div className='info' style={{ marginRight: 0 }}>
				<Box sx={{ width: '320px', fontWeight: 'bold', marginBottom: '15px' }}>
					<div>Примененные скидки:</div>
				</Box>
				{!!scores && (
					<Box sx={{ mb: 2 }}>
						<Grid
							container
							alignItems={'center'}
							rowSpacing={2}
							columnSpacing={2}>
							<Grid item xs={4}>
								<Box>Списано баллов</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<TextField
										disabled
										value={scores}
										variant='outlined'
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={2}></Grid>
						</Grid>
					</Box>
				)}
				<div >
					{Object.entries(groupedPromotions()).map(([promoName, value]) => {
						return (
								<Grid
									key={promoName}
									sx={{ mb: 2 }}
									container
									alignItems={'center'}
									rowSpacing={2}
									columnSpacing={2}>
									<Grid item xs={4}>
										<Box sx={{ fontWeight: 'normal' }}>
											{promoName}
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box>
											<TextField
												value={value}
												variant='outlined'
												disabled
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
							)
					})}
				</div>
				 
				<Box sx={{ fontWeight: 'bold', textAlign: 'end' }}>
					<div>Общая сумма скидки: {getTotalPromotionsAmount(lines)}</div>
				</Box>
			</div>
		</div>
	)
}

export default AppliedPromotions
