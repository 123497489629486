import { format, parseISO } from "date-fns";

function ServiceLine({ item, paxesCount, babyCount, selected, button }) {
    const price = item.price ? item.price + " " + item.currencyAlias : "";
    const personalPrice = item?.fsPricePerPerson && selected ? item.fsPricePerPerson * item?.clients?.length + " " + item.currencyAlias : price

    return (
        <div>
            <div className="grid-insurances body">
                <div>{item?.name}</div>
                <div>
                    {format(parseISO(item.datebeg), "dd.MM.yyyy")} -{" "}
                    {format(parseISO(item.dateend), "dd.MM.yyyy")}
                </div>
                <div>{item?.personal ? personalPrice : price}</div>
                <div className="paxes">
                    {paxesCount}
                    {babyCount && "+INF"}
                </div>
                {!item.required && <div>{button(item.uid)}</div>}
            </div>
            {item.note && (
                <div className="baggage-info">
                    {item.note.split("\n").map((item) => (
                        <div>{item}</div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ServiceLine;
