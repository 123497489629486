import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useDocumentsStore = create(
	devtools((set, get) => ({
		error: false,
		agent: JSON.parse(localStorage.getItem('agentInfo')),
		agentsUsers: JSON.parse(localStorage.getItem('agentsUsers')),
		agents: null,
		token: JSON.parse(localStorage.getItem('token'))?.access_token,
		loading: false,
		officialName: JSON.parse(localStorage.getItem('officialName')),
		actions: {
			uploadDocuments: async files => {
				set({ loading: true })
				set({ error: false })

				const agentsINN = get().agent.agents.inn
				const agentsName = get().agent.agents.name
				const formData = new FormData()
				formData.append('AgentName', agentsName)
				formData.append('Inn', agentsINN)
				for (let i = 0; i < files.length; i++) {
					formData.append('Files', files[i])
				}

				// console.log(files, 'files')
				try {
					const res = await axios({
						url: `${BASE_URL}/documents/upload`,
						method: 'POST',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${get().token}`
						}
					})
					console.log(res, 'data')
					if (res.status === 200) {
						set({ error: res.status })
					}
				} catch (e) {
					console.log(e, 'e')
					set({ error: e?.message })
				} finally {
					set({ loading: false })
				}
			}
		}
	}))
)

export default useDocumentsStore
