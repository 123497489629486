import {create} from "zustand";
import {devtools} from "zustand/middleware";
import axios from "axios";

const BASE_URL = 'https://api-education.fstravel.com/api/v1/auth/token/create/'

const useEducationStore = create(
	devtools((set, get) => ({
		actions: {
			createToken: async email => {
				try {
					const res = await axios({
						url: `${BASE_URL}`,
						method: 'POST',
						data: {
							email: email
						}
					})
					return res.data
				} catch (e) {
					return e.response?.data
				}
			}
		}
	}))
)

export default useEducationStore