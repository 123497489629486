import {
	FullscreenControl,
	Map,
	Placemark,
	TypeSelector,
	YMaps,
	ZoomControl
} from '@pbe/react-yandex-maps'
import React from 'react'
import useExcursionTourStore from '../../store'
import './style.css'

export default function ExcursionYandexMap() {
	const excursionTour = useExcursionTourStore(
		({ excursionTour }) => excursionTour
	)

	const coordinates = excursionTour?.latitude.includes(',')
		? [
				Number(excursionTour?.latitude.split(',').join('.')),
				Number(excursionTour?.longitude.split(',').join('.'))
		  ]
		: [+excursionTour?.latitude, +excursionTour?.longitude]
	const defaultState = {
		center: coordinates,
		zoom: excursionTour.mapZoomLevel || 10,
		controls: []
	}
	return (
		<>
			<div className='offset-line'></div>
			<div className='excursion-map'>
				<YMaps
					query={{
						apikey: process.env.REACT_APP_YANDEX_MAP,
						lang: 'ru_RU'
					}}>
					<Map
						defaultState={defaultState}
						width='100vw'
						height={700}
						modules={[
							'geoObject.addon.balloon',
							'geoObject.addon.hint',
							'geolocation',
							'geocode'
						]}>
						<FullscreenControl />
						<TypeSelector options={{ float: 'right' }} />
						<ZoomControl options={{ float: 'right', size: 'small' }} />
						<Placemark
							geometry={coordinates}
							properties={{
								hintContent: `${excursionTour?.localizedName}`
							}}
						/>
					</Map>
				</YMaps>
			</div>
		</>
	)
}
