import React from "react"
import { Separator, InfoBlock } from "../../../../ui"
import styled from "styled-components"
import Head from "../Head"
import Tariff from "../Tarrif"
import Passengers from "../Passengers"
import PriceDetail from "../PriceDetail"
import { useOrderPosition } from "../useItemProps"
import Collapse from '@mui/material/Collapse';

const BlockWithPadding = styled.div`
  padding: 0 24px;
`

const Wrapper = styled.div`
  position: relative;
`

const Container = styled(InfoBlock)`
  padding: 24px 0;
`

const Item = () => {
  const props = useOrderPosition()

  return (
    <Wrapper style={{ background: props.disabled ? "#FAFAFA" : "transparent" }}>
      {/* {isInactive && <Overlay />} */}
      <Container>
        <Head />
        <Collapse in={props.isOpen} timeout={{appear: 1000, enter: 1000,  exit: 500}}>
          <div>
            <Separator />
            <BlockWithPadding>
              <Tariff {...props.tariff} />
            </BlockWithPadding>
            <Separator />
            <BlockWithPadding>
              <Passengers
                items={props.passengers}
                itemWrapper={<div />}
                separator
              />
            </BlockWithPadding>
            <Separator />
            <BlockWithPadding>
              <PriceDetail {...props.priceDetail} />
            </BlockWithPadding>
          </div>
        </Collapse>
      </Container>
    </Wrapper>
  )
}

export default Item
