
import {
  getOrderByNumberNormalizer,
  getOrdersListNormalizer,
  availableInsurancesNormalizer
} from "./normalizer"
import Api from '../../../functions/fetchApi'

export const getOrderByNumber = orderNumber =>
  Api.get(process.env.REACT_APP_GET_ORDER_BY_NUMBER)
    .query({orderNumber})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(getOrderByNumberNormalizer)

export const getOrdersList = (args) =>
  Api.get(process.env.REACT_APP_GET_ORDER_LIST)
    .query({
      Page: args.page,
      PageSize: args.pageSize,
      'Filter.OrderPaymentStatus': args.paymentStatus,
      SortByCreateDate: args.dateSorting,
      'SearchFilter.OrderNumber': args.number,
    })
    .addAuthGuard()
    .setContentType('application/json')
    .send()
    .then(getOrdersListNormalizer)

export const payOrder = args =>
  Api.put(process.env.REACT_APP_CREATE_PAYMENT_LINK)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()
// Аналогичен payOrder, только смотрит на другой url. Подходит для последующей генерации QR кода
export const createPayment = args =>
  Api.put(process.env.REACT_APP_CREATE_PAYMENT)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const printOrder = (args) =>
  Api.get(process.env.REACT_APP_PRINT_ORDER)
    .query(args)
    .addAuthGuard()
    .send()

export const notifyPayment = args => {
  return Api.put(process.env.REACT_APP_NOTIFY_PAYMENT)
    .body(args)
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export const cancelOrderPosition = args =>
  Api.delete(process.env.REACT_APP_CANCEL_ORDER_POSITION)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const printRefund = args =>
  Api.get(process.env.REACT_APP_PRINT_REFUND)
    .query(args)
    .addAuthGuard()
    .send()
export const getAddictionalServicesByOrderNumber = orderNumber =>
  Api.get(process.env.REACT_APP_GET_EXTRA_SERVICES)
    .query({orderNumber})
    .setContentType('application/json')
    .send();

export const getHistory = eventStreamId =>
  Api.get(process.env.REACT_APP_GET_ORDER_HISTORY)
    .query({eventStreamId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const deleteAddictionalService = args =>
  Api.delete(process.env.REACT_APP_DELETE_EXTRA_SERVICES)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const addAddictionalServicer = args =>
  Api.post(process.env.REACT_APP_ADD_EXTRA_SERVICES)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const getActiveCalculations = OrderId =>
  Api.get(process.env.REACT_APP_GET_ACTIVE_CALCULATIONS)
    .query({OrderId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const agentDoPayment = orderId =>
  Api.post(process.env.REACT_APP_AGENT_DO_PAYMENT)
    .body({orderId})
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const issueTicketsByCreditOrInvoice = (orderId, typePaid, userId) =>
  Api.post(process.env.REACT_APP_ISSUE_TICKET)
    .body({ orderId, typePaid, userId })
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const issueTicketsByCredit = (orderId, userId) =>
  Api.post(process.env.REACT_APP_ISSUE_TICKET_BY_CREDIT)
    .body({ orderId, userId })
    .addAuthGuard()
    .setContentType('application/json')
    .send()

export const getAgentBillByOrderId = orderId =>
  Api.get(process.env.REACT_APP_GET_AGENT_BILL_BY_ORDER_ID)
    .query({orderId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const getOrderPayments = orderNumber =>
  Api.get(process.env.REACT_APP_GET_ORDER_PAYMENTS)
    .query({orderNumber})
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const issueOrderPosition = (orderNumber, orderPositionId) =>
  Api.post(process.env.REACT_APP_ISSUE_ORDER_POSITION)
  .body({ orderNumber, orderPositionId })
  .addAuthGuard()
  .setContentType('application/json')
  .send()

export function getAvailableInsurances(orderPositionId) {
  return  Api.get(process.env.REACT_APP_GET_AVAILABLE_INSURANCE_BY_POSITION_ID)
    .query({orderPositionId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(availableInsurancesNormalizer)
}

export function addInsurance(orderPositionId, insuranceProductIds) {
  return  Api.post(process.env.REACT_APP_ADD_INCURANCES)
  .body({ orderPositionId,  insuranceProductIds })
  .addAuthGuard()
  .setContentType('application/json')
  .send()
}

export function deleteInsurance(orderPositionId, insuranceProductIds) {
  return  Api.delete(process.env.REACT_APP_DELETE_INCURANCES)
    .body({ orderPositionId,  insuranceProductIds })
    .addAuthGuard()
    .setContentType('application/json')
    .send()
}

export function getAgentOrderBill (orderId) {
  return Api.get(process.env.REACT_APP_GET_AGENT_BILL_BY_ORDER_ID)
    .query({orderId})
    .addAuthGuard()
    .setContentType('application/octet-stream')
    .send()
    .then(Api.loadFile)
}


export function getAgentBillBySamoId(samoNumber) {
  return Api.get(process.env.REACT_APP_GET_AGENT_BILL_BY_ORDER_ID)
  .query({samoNumber})
  .addAuthGuard()
  .setContentType('application/octet-stream')
  .send()
  .then(Api.loadFile)
}


export function getAgentBillByTteNumber(tteNumber) {
  return Api.get(process.env.REACT_APP_GET_AGENT_BILL_BY_ORDER_ID)
  .query({tteNumber})
  .addAuthGuard()
  .setContentType('application/octet-stream')
  .send()
  .then(Api.loadFile)
}

export function promocodeApply(args) {
  return Api.post(process.env.REACT_APP_PROMOCODE_APPLY)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()
}

export function getQr(args) {
  return Api.post(process.env.REACT_APP_GET_QR_CODE)
    .body(args)
    .addAuthGuard()
    .setContentType('application/json')
    .send()
}
