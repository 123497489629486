import React from "react"
import RefundTemplate from './templates/Refund';
import ExtraPaymentTemplate from './templates/ExtraPayment'
import {createCalculationParams} from './utils'
import { CalculationType } from "./constants";
import CalculationModal from '../../modals/calculations/CalculationModal'
import DeclineSuccessModal from '../../modals/calculations/DeclineSuccessModal'
import ApplySuccessModal from '../../modals/calculations/ApplySuccessModal'
import CalculationFailureModal from '../../modals/calculations/CalculationFailureModal'
import { Loader } from "../../ui"
import {
    applyCalculationRequest,
    declineCalculationRequest,
    getCalculationModalsState,
    hideCalculationModal,
    MODALS,
    showCalculationModal
  } from '../../../store/modules/appeals'
import { useDispatch,useSelector } from "react-redux"
import * as OrderManager from '../../../store/modules/orders/manager'

const Calculation = props => {
  const dispatch = useDispatch();  
  const {visibility} = useSelector(getCalculationModalsState)

  const handlePay = () => {
    OrderManager.payOrder({
        OrderNumber: props.orderNumber,
        SupportTicketCalculationId: props.id
    })
    .then(data => {
        if (data.paymentURL) {
        window.history.pushState({}, "Обращение № 1", window.location.href)
        window.location.replace(data.paymentURL)
        }
    })
    .catch(() => {
        console.log('error')
    })
  }

  return(
    <>
        {visibility[MODALS.LOADING] && <Loader opacity="0.8" zIndex={999}/>}
        {props?.type?.code === CalculationType.ExtraPayment && (
            <ExtraPaymentTemplate 
                {...props}
                onCalculationShow={() => dispatch(showCalculationModal(MODALS.CALCULATION))} 
                onPay={handlePay}
            />)
        }
        {props?.type?.code === CalculationType.Refund && (
            <RefundTemplate
                {...props}
                onCalculationShow={() => dispatch(showCalculationModal(MODALS.CALCULATION))} 
                onPay={handlePay}
            />
        )}
        <CalculationModal
            open={visibility[MODALS.CALCULATION]}
            onApplyCalculation={(id) => dispatch(applyCalculationRequest({id, page: 'appeal'}))}
            onDeclineCalculation={(id) => dispatch(declineCalculationRequest({id, page: 'appeal'}))}
            onClose={() => {
              dispatch(hideCalculationModal(MODALS.CALCULATION))
            }}
            data={createCalculationParams(props)}
            interaction={props.status.code !== "ApprovedByClient"}
        />
        <DeclineSuccessModal 
            open={visibility[MODALS.DECLINE_SUCCESS]} 
            onClose={() => dispatch(hideCalculationModal(MODALS.DECLINE_SUCCESS))}
            type={props.type.code === "ExtraPayment" ? 'payment' : 'refund'}
            closeButtonText='Вернуться к обращению'
        />
        <ApplySuccessModal 
          open={visibility[MODALS.CONFIRM_SUCCESS]}
          onClose={() => dispatch(hideCalculationModal(MODALS.CONFIRM_SUCCESS))}
          type={props.type.code === "ExtraPayment" ? 'payment' : 'refund'}
          closeButtonText='Вернуться к обращению'
          onPay={()=> {
            handlePay();
            dispatch(hideCalculationModal(MODALS.CONFIRM_SUCCESS))
          }}
        />
        <CalculationFailureModal 
            open={visibility[MODALS.FAILURE]} 
            onClose={() => dispatch(hideCalculationModal(MODALS.FAILURE))}
            closeButtonText='Вернуться к обращению'
        />
    </>
)}

export default Calculation
