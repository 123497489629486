import React from 'react';
import styled from 'styled-components';
import { Text,InfoBlock } from '../../ui';
import SmsService from './SmsService';
import RefundService from './RefundService';
import PrintService from './PrintService';
import AviaServices from './AviaServices';
import {
  addictionalServicesSelector,
  switchAddictionalServiceState,
  getBookingAncillaryServicesState,
  getSelectedFare,
  getSeatMapState,
  findEmptyPlace,
  AddictionalServiceTypes
} from '../../../store/modules/booking';
import { useSelector, useDispatch } from 'react-redux';
import {
  ServiceRulesModal,
  SeatMapNotificationModal,
} from '../../modals';
import { useModalState } from './context';
import SeatMapService, { SeatMapModal } from './SeatMapService';

const Wrapper = styled(InfoBlock)`
  background: #edf1fe;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export default function AddictionalServices() {
  const items = useSelector(addictionalServicesSelector);
  const services = useSelector(getBookingAncillaryServicesState);
  const seatMap = useSelector(getSeatMapState);
  const fare = useSelector(getSelectedFare);
  const dispatch = useDispatch();
  const { visibility, hideModal, showModal } = useModalState();
  const [notification, setNotification] = React.useState(false);

  const [price,setPrice] = React.useState(0);

  const onClick = (index, checked) => {
    // ReactGA.event({
    //   category: 'Booking',
    //   action: checked
    //     ? 'booking_select_addictional'
    //     : 'booking_deselect_addictional',
    //   label: items[index].type,
    // });

    dispatch(
      switchAddictionalServiceState({
        index,
        value: checked,
      })
    );
  };

  React.useEffect(()=> {
    switch (visibility) {
      case AddictionalServiceTypes.Payment_Receipt:
      case AddictionalServiceTypes.Return_Guarantee:
      case AddictionalServiceTypes.SMS_Service:{
        console.log(items)
        const item = items.find(x => x.type === visibility);
        if(item) {
          setPrice(item.amount)
        }
        break;
      }
      default:
        {
          setPrice(0)
          break;
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visibility])

  // const meals = Object.keys(services.meal);
  // const luggage = Object.keys(services.luggage);

  return !!services.length || !!items.length ? (
    <Wrapper>
      <Title>Дополнительные услуги</Title>
      <SubTitle>
        Сделайте Ваше путешествие наиболее комфортным и создайте для себя
        максимум удобств с помощью дополнительных услуг.
      </SubTitle>
      <List>
        {items.map((x, index) => (
          <React.Fragment key={x.id}>
            {getTemplate({ ...x, index }, onClick)}
          </React.Fragment>
        ))}
        <AviaServices />
        {fare.seatMap.length > 0 && <SeatMapService />}
      </List>
      <ServiceRulesModal
        price={price}
        open={visibility !== null && visibility !== AddictionalServiceTypes.SeatMap}
        onClose={hideModal}
        type={visibility}
      />
      {/* <LuggageServiceModal open /> */}
      {seatMap.available && (
        <>
          <SeatMapModal
            open={visibility === AddictionalServiceTypes.SeatMap}
            onClose={() => {
              if (
                seatMap.availableSeatCount > 0 &&
                seatMap.selectedSeatCount > 0
              ) {
                setNotification(true);
              }
              hideModal();
            }}
            fare={fare}
          />

          <SeatMapNotificationModal
            data={seatMap.segmentsAccessibility}
            title="Выбраны не все места"
            subtitle="Не забудьте выбрать ваши места на перелетах"
            buttonsText={['Выбрать места', 'Закончить выбор мест']}
            onConfirm={() => {
              setNotification(false);
              dispatch(findEmptyPlace());
              showModal(AddictionalServiceTypes.SeatMap);
            }}
            onClose={() => setNotification(false)}
            open={notification}
          />
        </>
      )}
    </Wrapper>
  ) : null;
}

function getTemplate(x, cb) {
  switch (x.type) {
    case AddictionalServiceTypes.SMS_Service:
      return <SmsService {...x} onClick={cb} />;
    case AddictionalServiceTypes.Return_Guarantee:
      return <RefundService {...x} onClick={cb} />;
    case AddictionalServiceTypes.Payment_Receipt:
      return <PrintService {...x} onClick={cb} />;
    default:
      return null;
  }
}
