import { MenuItem } from '@material-ui/core'
import { InputAdornment, Select } from '@mui/material'
import React from 'react'

import { FormInput } from './AddAgentPopup'
import { ExpandMore } from '@material-ui/icons'

export default function PhoneAddInput({
	handleChangeSelectPhone,
	phone,
	handleChangePhone,
	selectPhone,
	error
}) {
	const countriesPreffix = [
		{ code: 'BY', label: 'Belarus', phone: '375' },
		{ code: 'EE', label: 'Estonia', phone: '372' },
		// { code: 'KZ', label: 'Kazakhstan', phone: '7' },
		{ code: 'LT', label: 'Lithuania', phone: '370' },
		{ code: 'LV', label: 'Latvia', phone: '371' },
		{ code: 'RU', label: 'Russian Federation', phone: '7' },
		{ code: 'UA', label: 'Ukraine', phone: '380' },
		{ code: 'KZ', label: 'Kazachstan', phone: '997' }
	]

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
			<Select
				startAdornment={
					<InputAdornment position='start'>
						<img
							loading='lazy'
							width='25'
							srcSet={`https://flagcdn.com/w40/${countriesPreffix
								.find(item => `+${item.phone}` === selectPhone)
								?.code.toLowerCase()}.png 2x`}
							src={`https://flagcdn.com/w20/${countriesPreffix
								.find(item => `+${item.phone}` === selectPhone)
								.code.toLowerCase()}.png`}
							alt=''
						/>
					</InputAdornment>
				}
				IconComponent={ExpandMore}
				style={{
					minWidth: '120px',
					borderRadius: '8px',
					marginRight: '10px',
					height: '57px'
				}}
				variant='outlined'
				value={selectPhone}
				onChange={handleChangeSelectPhone}
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}>
				<MenuItem value={'+7'}>+7</MenuItem>
				<MenuItem value={'+370'}>+370</MenuItem>
				<MenuItem value={'+371'}>+371</MenuItem>
				<MenuItem value={'+372'}>+372</MenuItem>
				<MenuItem value={'+375'}>+375</MenuItem>
				<MenuItem value={'+380'}>+380</MenuItem>
				<MenuItem value={'+997'}>+997</MenuItem>
			</Select>

			<FormInput
				fullWidth
				variant='outlined'
				id='outlined-required'
				label='Телефон'
				name='phone'
				value={phone}
				onChange={handleChangePhone}
				required
				title='В номере телефона допустим ввод только цифр'
				error={!!error.phone}
				helperText={error.phone}
			/>
		</div>
	)
}
