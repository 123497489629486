import React from 'react'

import './style.css'
import SliderIcons from './SliderIcons'
import GridThreeColIcons from './GridThreeColIcons'
import ThreeWithIcons from './ThreeWithIcons'
import { useMediaQuery } from '@mui/material'

const TilesIcons = ({ content, title }) => {
	const getTemplate = () => {
		switch (content.template) {
			case 'sliderIcons':
				return <SliderIcons content={content} />
			case 'gridThreeColIcons':
				return <GridThreeColIcons content={content} />
			case 'threeWithIcons':
				return <ThreeWithIcons content={content} />
			default:
				return null
		}
	}
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	return (
		<>
			<div
				className={'tiles-icons__header'}
				style={{
					marginBottom: isSmallScreen ? '40px' : '24px',
					fontSize: isSmallScreen ? '28px' : '24px',
					lineHeight: isSmallScreen ? '38px' : '34px'
				}}>
				{title}
			</div>
			{getTemplate()}
		</>
	)
}

export default TilesIcons
