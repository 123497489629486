import './style.scss'
import { useState } from 'react'
import { isEmpty } from 'lodash'

function BlockTable({ content, title }) {
	const [show, setShow] = useState(false)
	const handleShow = () => {
		setShow(!show)
	}
	const tableKey = show ? content?.table && Object.keys(content?.table) : content?.table && Object.keys(content?.table).slice(0, 20)
	const countRows =
		content?.table && Object.values(content?.table)?.filter(i => i.hidden)

	if (isEmpty(countRows)) {
		return title ? (
			<div className='block-table'>
				<h2 className='block-table__title'>{title}</h2>
			</div>
		) : null
	}

	return (
		<div className='block-table'>
			<h2 className='block-table__title'>{title}</h2>
			<div className={`block-table__wrapper ${show || countRows.length < 21 ? 'open' : ''}`}>
				<table className='block-table__table'>
					{tableKey.map(rowKey => {
						const row = content?.table[rowKey]
						if (!row?.hidden) return null
						return (
							<tr key={rowKey}>
								{Object.keys(row.cols).map(colKey => {
									const col = row.cols[colKey]
									const isHidden = !col.value
									const style = isHidden
										? { ...col?.style, display: 'none' }
										: col?.style
									return (
										<td
											key={colKey}
											colSpan={col.colSpan}
											rowSpan={col.rowSpan}
											style={style}
											className={col.name === 'CA' ? 'th' : ''}>
											<span>{col.value}</span>
										</td>
									)
								})}
							</tr>
						)
					})}
				</table>
			</div>
			{countRows?.length > 20 && (
				<div className='block-table__btn-wrap' onClick={handleShow}>
					{show ? 'Скрыть' : 'Смотреть полностью'}
				</div>
			)}
		</div>
	)
}

export default BlockTable
