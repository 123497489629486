import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HistoryLog } from "../../components/ui"
import { getHistoryRequest } from "../../store/modules/appeals"

const OrderDetail = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.appeals.history);
  const id = useSelector((state) => state.appeals.detail.id);

  useEffect(() => {
    dispatch(getHistoryRequest(id))

  }, [])

  return <HistoryLog items={data} />
}

export default OrderDetail
