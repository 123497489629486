import {
  PassengerAgeCategory,
  AncillaryServiceType,
  AddictionalServiceTypes
} from "./const"
import moment from "moment"
import _, { memoize } from "lodash"
import formatDuration from "../../../functions/formatDuration"
import translatePassengerAgeCategory from "../../../functions/translatePassengerAgeCategory"

import insuranceCancelSrc from "../../../asset/images/avia/insurances/insurance1.svg"
import insuranceProtectionSrc from "../../../asset/images/avia/insurances/insurance2.svg"
import insuranceCovidSrc from "../../../asset/images/avia/insurances/insurance3.svg"
import insuranceDelaySrc from "../../../asset/images/avia/insurances/insurance4.svg"
import insuranceWeatherSrc from "../../../asset/images/avia/insurances/insurance5.svg"
import insuranceDoctorSrc from "../../../asset/images/avia/insurances/insurance6.svg"
import insurance7 from "../../../asset/images/avia/insurances/insurance7.svg"
import insurance7TabIcon from "../../../asset/images/avia/insurances/insurance7TabIcon.svg";
import CashIconPath from '../../../asset/images/avia/insurances/cash.svg';
import CrossIconPath from '../../../asset/images/avia/insurances/cross.svg';
import InsuranceCancelIconPath from '../../../asset/images/avia/insurances/insurance_case1.svg';
import InsuranceFlightIconPath from '../../../asset/images/avia/insurances/insurance_case2.svg';
import InsuranceMedicalIconPath from '../../../asset/images/avia/insurances/insurance_case3.svg';


import alphastrahPath from "../../../asset/images/avia/insurances/alphastrah.png"
import insurionPath from "../../../asset/images/avia/insurances/insurion.png"
import CovidDeath from "../../../asset/images/avia/booking/covidDeath.svg"
import Sun from "../../../asset/images/avia/booking/sun.svg"
import Hearth from "../../../asset/images/avia/insurances/hearth.svg"
import {v4 as uuid} from "uuid";

export const createBookingDto = (
  ticket,
  ancillaryServices,
  upsellIndex,
  fare,
  payload,
  addictionalServices,
  seatMapState,
  user,
  agentId
) => {
  const ancillaryKey = _(ancillaryServices)
    .map(x =>
      _(x.offers)
        .map(y =>
          _(y)
            .map(z => z.filter(z => z.checked).map(z => z.key))
            .value()
        )
        .value()
    )
    .flattenDeep()
    .value()

  const seats = _(seatMapState.list)
    .map(x =>
      x
        .filter(y => y.seat != null)
        .map(y => ({
          personUid: y.uid,
          segmentUid: y.segmentId,
          number: y.seat?.number,
          rfisc: y.seat?.rfisc,
          onlyForAdult: y.seat?.onlyForAdult
        }))
    )
    .flatten()
    .value()

  const isSmsService = addictionalServices.find(
    x => x.checked && x.type === AddictionalServiceTypes.SMS_Service
  )

  const result = {
    CheckAvailabilityResponseId: ticket.id,
    UpsaleResponseIndex: upsellIndex,

    Passengers: payload.passengers.map((passenger) => {
      const result = {
        Uid: passenger.uid,
        FirstName: passenger.nameEng,
        LastName: passenger.surnameEng,
        MiddleName: passenger.secondNameEng,
        PatronymicName: passenger.secondNameEng,
        FullName:
          passenger.surnameEng +
          " " +
          passenger.nameEng +
          " " +
          passenger.secondNameEng,
        DateOfBirth: moment(passenger.birthDate).format(
          "YYYY-MM-DDT00:00:00"
        ),
        Sex: passenger.sex === "m" ? "MALE" : "FEMALE",
        DocumentType: passenger.documentType,
        DocumentInfo:
          passenger.documentInfo !== ""
            ? passenger.documentInfo
            : passenger.number.replace(/_/g, ""),

        DocumentSeries: passenger.documentSeries,
        Citizenship: passenger.citizenship,
        AdditionalInfo:
          passenger.loyality.code !== ""
            ? `${passenger.loyality.code}:${passenger.loyality.value}`
            : null,
        AgeCategory: passenger.ageCategory.toUpperCase()
      }
      if(passenger.dateOfIssue) {
        result.DocumentExpiryDate = moment(passenger.dateOfIssue).format(
          "YYYY-MM-DDT00:00:00"
        );
      }
      return result;
    }),
    agentId: agentId,

    seats,
    InsuranceProductIds: payload.InsuranceProductIds,
    ContactEmail: payload.email,
    ContactPhone: payload.phone.replaceAll(/\+|\(|\)|-|\s/g, ""),
    contactName: payload.name,
    salesChannel: payload.salesChannel,
    OfferPrice: payload.OfferPrice,
    BookingType: payload.BookingType,
    insuranceAgreement: payload.isInsurancesChecked,
    changeAgreementToMailing: {
      isNeedToChange: true,
      value: payload.emailOfferta
    },
    AdditionalServiceIds: _(addictionalServices)
      .filter(x => x.checked)
      .map(x => x.id)
      .value(),
    PromoCode: "",
    PushXGUID: "",
    AncillaryKey: ancillaryKey.length > 0 ? ancillaryKey.join(",") : null,
    additionalContactInfo: null
  }
  if (payload.marker !== null) {
    result.marker = payload.marker
  }

  if (isSmsService) {
    result.additionalContactInfo = {
      name: payload.nameTwo,
      phone: payload.phoneTwo.replaceAll(/\(|\)|-|\s/g, "")
    }

    if (payload.sameContactInfo) {
      result.additionalContactInfo = {
        name: payload.name,
        phone: payload.phone.replaceAll(/\(|\)|-|\s/g, "")
      }
    }
  }

  return result
}

// Check availability response normalizer

const createSeatMap = args => {
  const arr = args.groups
    .map(x =>
      x.flights
        .map(y =>
          y.segments
            .map(z => {
              if (z.seatMap === null) {
                return null
              }
              return { ...z.seatMap, ...z, groupIndex: x.index }
            })
            .filter(z => z !== null)
        )
        .flat()
    )
    .flat()
    .filter(x => x !== null)
  return arr
}

/**
 * Extract flights from unnecessary groups
 * Required for ticket reducer state
 * @param x group of flights, structure from avi search
 */
const createFlightsList = (x, flight_Type) =>
  x.reduce(
    (acc, x) => [
      ...acc,
      ...x.flights.map(x => ({
        ...x,
        flight_Type: x.segments[0].flight_Type,
        isLowcoster: !!x.segments.find(s => s.isLowcoster)
      }))
    ],
    []
  )

/**
 * Creating array of avia fares for fare reducer state
 * @param data CheckAvailabilityDTO
 */
const createFares = data => {
  const totalSum = memoize(arr => arr.reduce((acc, x) => acc + x.total, 0))
  const getPriceDiff = x0 => x1 => x1 - x0
  const diffWithBasePrice =
    data.fares && getPriceDiff(totalSum(data.fares[0]?.tariff.prices || 0))

  function createFromToString(segmentGroups) {
    return segmentGroups
      .map(value => {
        if (value.flights.length === 1) {
          return [...value.flights, ...value.flights].map(extractCity)
        } else {
          return value.flights.map(extractCity)
        }
      })
      .map(_.flatten)
      .map((value, index, arr) => removeDuplicates(value, arr[index + 1]))
      .map(value => value?.join(" - "))
      .join(" - ")
  }

  function extractCity(value, index, arr) {
    if (index !== 0 && index !== arr.length - 1)
      return [
        value.segments[0].from.city,
        value.segments[value.segments.length - 1].to.city
      ]
    return index === 0 ? value.segments[0].from.city : value.segments[0].to.city
  }

  function removeDuplicates(x1, x2) {
    if (!x2) {
      return x1
    }
    if (x1[x1.length - 1] === x2[0]) {
      const x1Clone = [...x1]
      x1Clone.length = x1.length - 1
      return x1Clone
    }
  }

  const createFare = upsellIndex => (x, upt, timeLimitDuration) => {
    const fareFamily =
      x.fareFamilies && x.fareFamilies[0]
        ? x.fareFamilies[0]
        : {
            code: "",
            name: x.serviceClass,
            carrier: "",
            serviceClass: x.serviceClass,
            isExchangeable: null,
            isRefundable: null,
            routes: [],
            features: []
          }

    const durationMinutes = Math.trunc(
      moment.duration(timeLimitDuration).asMinutes()
    )

    const result = {
      extraPrice: diffWithBasePrice(totalSum(x.prices)),
      prices: x.prices,
      ...fareFamily,
      fromTo: createFromToString(x.groups),
      upt: upt,
      gds: x.gds,
      groups: x.groups,
      timeLimitDuration: formatDuration(durationMinutes),
      upsellIndex,
      seatMap: createSeatMap(x),
      isExchangeable: x.isExchangeable,
      isRefundable: x.isRefundable,
      extraCharge: x.extraCharge,
      services: x.ancillaryServices,
      isLowcoster: x.isLowcoster
    }
    return result
  }

  return data?.fares?.map(fare => {
    return createFare(fare.upsellIndex)(
      {
        ...fare.tariff,
        ancillaryServices: fare.ancillaryServices
      },
      fare.upt,
      fare.timeLimitDuration
    )
  })
}

/** Passengers */

/**
 * Create passenger list, required for passenger reducer state
 * @param passengers
 */

/** Services */

const groupServicesByType = services => {
  return _(services)
    .groupBy("type")
    .map((value, key) => {
      return {
        type: key,
        offers: _(value)
          .map(({ type, offers, ...rest }) => {
            return offers.map(x => ({ ...x, ...rest }))
          })
          .flatten()
          .value()
      }
    })
    .value()
}

const createServiceFunc = tariff => service => {
  const segments = _(tariff.groups)
    .reduce(
      (acc, x) => [
        ...acc,
        ...x.flights.reduce((acc, x) => [...acc, ...x.segments], [])
      ],
      []
    )
    .map(x => ({
      id: x.id,
      route: `${x.from.city} - ${x.to.city}`,
      fulfilled: false
    }))
  if (segments.length !== 1) {
    segments.push({
      id: segments.reduce((acc, x) => acc + x.id, ""),
      route: "ВСЕ СЕГМЕНТЫ",
      fulfilled: false
    })
  }

  const groupedSegments = segments.reduce(
    (acc, x) => ({ ...acc, [x.id]: x }),
    {}
  )

  const offers = _(service.offers)
    .groupBy(x => x.flightSegmentsUid.join("-"))
    .mapValues(x => {
      return _(x)
        .groupBy(x => x.passengerId)
        .mapValues(x => {
          return x.map(x => ({
            ...x,
            checked: false,
            segmentName: groupedSegments[x.flightSegmentsUid[0]]?.route,
            cost: x.total,
            title:
              service.type === AncillaryServiceType.Luggage
                ? x.luggageInfo
                  ? `Багаж до ${x.luggageInfo?.onePlaceWeight} кг`
                  : x.name
                : x.name
          }))
        })
        .value()
    })
    .value()

  const MIN_PRICE = Math.min(...service.offers.map(x => x.total))

  const availableSegmentsIds = _.uniq(
    service.offers.map(x => x.flightSegmentsUid.join("-"))
  )
  const availableSegments = [...segments].filter(x =>
    availableSegmentsIds.includes(x.id)
  )

  return {
    segments: availableSegments,
    groupedSegments,
    offers,
    minPrice: MIN_PRICE,
    type: service.type
  }
}

export function createServiceAddictionalInfo(service) {
  const result = _(service.offers)
    .map((x, key) => {
      const passengers = _(x)
        .map((x, key) => {
          const offers = _(x)
            .filter(x => x.checked)
            .map(x => {
              let name = x.name
              if (service.type === AncillaryServiceType.Luggage) {
                name = x.luggageInfo
                  ? `Багаж до ${x.luggageInfo.onePlaceWeight} кг`
                  : "Без багажа"
              }
              return { price: x.cost, name, key: x.key }
            })
            .value()

          return {
            key,
            offers,
            price: _(offers).reduce((acc, x) => acc + x.price, 0)
          }
        })
        .filter(x => x.offers.length > 0)
        .value()
      return {
        segmentName: service.groupedSegments[key]?.route,
        key,
        passengers,
        price: _(passengers).reduce((acc, x) => acc + x.price, 0)
      }
    })
    .filter(x => x.passengers.length > 0)
    .value()
  return result
}

export const createServices = (services, tariff) => {
  const cb = createServiceFunc(tariff)
  return groupServicesByType(services || [])
    .map(cb)
    .map(x => ({ ...x, addictionalInfo: createServiceAddictionalInfo(x) }))
}

/**
 * if argument is null generate default object, else normalize it
 * Required for passenger and services reducers state
 * @param arg
 */

export function createPassengersArray(args) {
  if ((args.passengers || []).length < 1) {
    return []
  }

  const passengers = args.passengers.map(x => ({
    name: "",
    nameEng: "",
    surname: "",
    surnameEng: "",
    secondName: "",
    secondNameEng: "",
    ageCategoryDescription: translatePassengerAgeCategory(x.ageCategory),
    documentType: x.ageCategory === "ADULT" ? "PASSPORT" : "BIRTH_CERTIFICATE",
    ageCategory: x.ageCategory,
    uid: x.uid
  }))


  return passengers
}

export function setSegmentsAccessibility(state) {
  let availableSeatCount = 0
  const segments = Object.entries(state.list).map(([key, x]) => {
    const count = x.filter(x => x.seat === null).length
    availableSeatCount += count
    return {
      name: x[0].segmentName,
      haveEmptySelectedPlaces: count > 0,
      id: key
    }
  })
  return {
    availableSeatCount,
    segmentsAccessibility: segments
  }
}

export const checkAvailablityNormalizer = arg => {
  return {
    ...arg,
    frequentFlyerAirlines: arg.frequentFlyerAirlines,
    fares: createFares(arg),
    ancillaryServices:
      arg.fares &&
      createServices(arg.fares[0].ancillaryServices, arg.fares[0].tariff),
    passengers: createPassengersArray(arg),
    flights:
      arg.fares &&
      createFlightsList(arg.fares[0].tariff.groups, arg.flight_Type),
    flightSearchParameters: arg.flightSearchParameters
  }
}

export function createSeatMapState(fare, passengers) {
  const segments = {}

  fare.seatMap.forEach(x => {
    segments[x.flightNo] = passengers
      .filter(x => x.ageCategory !== PassengerAgeCategory.Infant)
      .map(y => ({
        ...y,
        seat: null,
        segmentId: x.id,
        segmentName: `${x.from.code}-${x.to.code}`
      }))
  })

  const pricesArr = _(fare.seatMap || [])
    .map(x =>
      x.decks?.map(y =>
        y.rows.map(z => z.seatGroups.map(d => d.seats.map(g => g.price.amount)))
      )
    )
    .flattenDeep()
    .sort((a, b) => a - b)
    .value()

  const priceArrUnique = _(pricesArr)
    .filter(Boolean)
    .uniq()
    .value()
  const CHUNK_COUNT = priceArrUnique.length > 2 ? 3 : priceArrUnique.length
  const CHUNK_SIZE = Math.ceil(priceArrUnique.length / CHUNK_COUNT)
  const pricesForColor = []
  let count = 0

  let i = 0
  let j = 0

  while (count < CHUNK_COUNT || i < priceArrUnique.length) {
    if (j === 0) {
      pricesForColor.push(priceArrUnique[i])
      count += 1
    }
    j += 1
    i += 1
    if (j === CHUNK_SIZE) {
      j = 0
    }
  }

  if (!pricesForColor[CHUNK_COUNT - 1]) {
    pricesForColor[CHUNK_COUNT - 1] = priceArrUnique[priceArrUnique.length - 1]
  }

  const minPrice = Math.min(...pricesArr)
  const result = {
    minPrice,
    selectedPassengerId: passengers[0].uid,
    selectedPassengerType: passengers[0].ageCategory,
    selectedSegmentNumber: fare.seatMap[0]?.flightNo || "",
    available: fare.seatMap.length > 0,
    totalPrice: 0,
    selectedSeatCount: 0,
    availableSeatCount: 0,
    segmentsAccessibility: [],
    loading: false,
    list: segments,
    priceArrForColors: pricesForColor
  }

  return { ...result, ...setSegmentsAccessibility(result) }
}

function getInsuranceIcon(productType) {
  switch (productType) {
    case "ANTICOVID":
      return insuranceCovidSrc
    case "Защита на время полета":
    case "Полёт под защитой":
      return insuranceProtectionSrc
    case "Отмена поездки":
      return insuranceCancelSrc
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return insuranceDelaySrc
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return insuranceWeatherSrc
    case "Врач-online в поездку":
    case "Медицинские расходы":
    case "Online-врач в поездку":
      return insuranceDoctorSrc
    case "Страховка на время полёта":
      return insurance7
    default:
      return null;
  }
}

// function getInsuranceDescription(productType) {
//   switch (productType) {
//     case "ANTICOVID":
//       return "Нет ничего важнее Вашего здоровья. Получите выплату при заболевании в поездке."
//     case "Защита на время полета":
//       return "Получите выплату на карту при задержке рейса, потере багажа и других страховых случаях."
//     case "Отмена поездки":
//       return "Обезопасьте себя на случай отмены запланированной поездки."
//     case "Врач-online в поездку":
//       return "Online-врач окажет вам помощь дистанционно в любой точке мира."
//     case "Страховка от задержки рейса":
//     case "Задержка рейса":
//       return "Моментальная выплата от 3000 ₽ на карту, в случае задержки рейса от 3ч."
//     case "Гарантия хорошей погоды для туристов":
//     case "Гарантия хорошей погоды":
//       return "Моментальная выплата 5000 ₽ на карту, в случае плохой погоды во время отдыха."
//     case "Страховка на время полёта":
//       return "Моментальная выплата на карту при задержке рейса. Защита багажа и здоровья."
//     default:
//       return '';
//   }
// }

function getCompany(productType) {
  switch (productType) {
    case "Задержка рейса":
    case "Гарантия хорошей погоды":
    case "Гарантия хорошей погоды для туристов":
    case "Страховка от задержки рейса":
      return { name: "Insurion", logo: insurionPath, phone: null }
    default:
      return {
        name: "АльфаСтрахование",
        logo: alphastrahPath,
        phone: "8-499-785-0999"
      }
  }
}

function getLabel(productType) {
  switch (productType) {
    case "ANTICOVID":
      return { color: "#daf0a2", text: "Самое важное", icon: CovidDeath }
    case "Гарантия хорошей погоды":
    case "Гарантия хорошей погоды для туристов":
      return { color: "#96D9FF", text: "Без дождя", icon: Sun }
    case "Врач-online в поездку":
      return { color: "#DAF0A2", text: "Для всей семьи", icon: Hearth }
    case "Страховка на время полёта":
      return {
        color: "#FFBCBC",
        text: "Выплата сразу",
        icon: insurance7TabIcon
      }
    default:
      return null
  }
}

function formatRisks(productType, risks) {
  switch (productType) {
    case "ANTICOVID":
      return [
        {
          id: "",

          coverage: { value: 15000, currency: "RUB" },
          description: "в случае диагностики COVID-19"
        },
        {
          id: "",
          coverage: { value: 30000, currency: "RUB" },
          description: "в случае госпитализации с COVID-19"
        }
      ]
    case "Защита на время полета":
      return risks.map(x => {
        let description = x.description

        if (x.description.includes("Потеря багажа")) {
          description = "при пропаже багажа"
        } else if (x.description.includes("Задержка рейса")) {
          description = "при задержке рейса"
        } else if (x.description.includes("Несчастный случай")) {
          description = "в несчастном случае"
        } else if (x.description.includes("Задержка багажа")) {
          description = "при задержке багажа"
        }

        return { ...x, description }
      })
    case "Отмена поездки":
      return risks.map(x => ({
        ...x,
        description:
          "в случае внезапной отмены поездки из-за отказа в визе, травмы, госпитализации и еще 13 причин"
      }))
    default:
      return risks
  }
}

function getWordBeforPrice(productType) {
  switch (productType) {
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return "от"
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return ""
    default:
      return "до"
  }
}

function getSpecificationDoc(productType) {
  switch (productType) {
    case "Страховка от задержки рейса":
    case "Задержка рейса":
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return "/docs/reno.pdf";
    case "Страховка на время полёта":
    case "Полёт под защитой":
      return 'https://www.alfastrah.ru/docs/Offer_tui_own.pdf ';
    default:
      return "http://www.alfastrah.ru/docs/rules_SP_230617.pdf"
  }
}

function getOffertaUri(productType) {
  switch (productType) {
    case "ANTICOVID":
      return "https://www.alfastrah.ru/docs/Offer_anticovid_2_avia_247.pdf"
    case "Защита на время полета":
      return "https://www.alfastrah.ru/docs/Offer_bg_zv_ns_247.pdf"
    case "Отмена поездки":
      return "http://www.alfastrah.ru/docs/Offer_nsp_247_se.pdf"
    case "Врач-online в поездку":
      return "https://www.alfastrah.ru/docs/telemed_ns_offer.pdf"
    default:
      return null
  }
}
 function getCardRenderData(productType) {
   switch (productType) {
     case 'Отмена поездки':
       return {
         iconSrc: CashIconPath,
         iconBackground: '#BFE8FF',
         iconText: 'Выплата до 5 000 ₽',
         detailsTitle: '19 причин отмены поездки, в том числе:',
         detailsItems: [
           'Призыв или суд',
           'Хищение документов',
           'Госпитализация',
           'Отказ в визе',
         ],
         detailsItemsTitle: 'Компенсация до 5 000 ₽ при отмене или изменении сроков перелёта по одной из причин:',
         detailsItemsFull: [
           'Госпитализация',
           'Инфекционные заболевания',
           'Травма',
           'Уход из жизни',
           'Вакцинация',
           'Отказ в визе',
           'Ущерб имуществу',
           'Сокращение',
           'Хищение документов',
           'Авария общественного транспорта',
           'ДТП',
           'Стихийные бедствия',
           'Судебное разбирательство',
           'Следственные действия',
           'Содействие представителям органов власти',
           'Призыв',
           'Метеоусловия',
           'Респираторные инфекционные заболевания',
           'Обсервация в связи с диагностированным COVID-19',
         ],
         storyIconSrc: InsuranceCancelIconPath,
         howtoTitle: false,
         howtoInstructions: [
           'Обратитесь в Службу поддержки по заявке и оформите возврат или обмен авиабилетов на новую дату по одной из 19 причин.',
           'После возврата денег за авиабилеты <a href="https://www.alfastrah.ru/accident/insurance_of_passengers/?utm_referrer=http%3A%2F%2Ftvm-docker-01%3A28512%2F" target="_blank" style="color: #4872f2;cursor: pointer">отправьте заявление</a> в “Альфа Cтрахование”.',
           'Приложите подтверждающие и удостоверяющие документы (полис, паспорт, маршрутная квитанция, справки, полученные от Службы поддержки с указанием суммы возврата / доплаты денежных средств, документы, подтверждающие наступление страхового случая, реквизиты для получения выплаты). После получения заявления “Альфа Страхование” может запросить дополнительные документы согласно правилам страхования.',
           'Получите деньги - обычно это занимает не более 10 дней. '
         ]
       }
     case 'Полёт под защитой':
       return {
         iconSrc: CashIconPath,
         iconBackground: '#FFEDBF',
         iconText: 'Выплата до 500 000 ₽',
         detailsTitle: false,
         detailsItems: [
           'Моментальная выплата до <b style="white-space: nowrap">6 000 ₽</b> на карту если рейс задержали',
           'Выплаты при утере багажа до <b>40 000 ₽</b>',
           'Компенсация при несчастном случае до <b>500 000 ₽</b>',
         ],
         detailsItemsTitle: false,
         detailsItemsFull: [
           '<b>Моментальная выплата на карту до 6 000 ₽ если рейс задержали!</b>',
           'Выплаты при потере и утрате багажа до <b>40 000 ₽</b>',
           'Выплаты при отмене рейса до <b>4 800 ₽</b>',
           'Выплаты в случае, если самолёт перенаправили в другой аэропорт до <b>5 000 ₽</b>',
           'Выплаты при несчастном случае в аэропорту или самолёте до <b>500 000 ₽</b>',
         ],
         storyIconSrc: InsuranceFlightIconPath,
         howtoTitle: '<div>При задержке или отмене рейса вам автоматически придет смс от “Альфа Страхования”. Перейдите по ссылке из смс, проверьте свои данные, введите данные карты и получите выплату.</div><div style="margin-top: 12px"><b>Если вы не получили смс или у вас страховое событие по другим рискам:</b></div>',
         howtoInstructions: [
           '<a href="https://www.alfastrah.ru/accident/insurance_of_passengers/?utm_referrer=http%3A%2F%2Ftvm-docker-01%3A28512%2F" target="_blank" style="color: #4872f2;cursor: pointer">Отправьте заявление</a> в “Альфа Cтрахование”.',
           'Приложите подтверждающие и удостоверяющие документы (полис, паспорт, маршрутная квитанция, справки подтверждающие задержку / отмену рейса или неприятности со здоровьем, реквизиты для получения выплаты). После получения заявления “Альфа Страхование” может запросить дополнительные документы согласно правилам страхования.',
           'Получите деньги - обычно это занимает не более 10 дней. '
         ]
       }
     case 'Медицинские расходы':
     case 'Online-врач в поездку':
       return {
         iconSrc: CrossIconPath,
         iconBackground: '#DAF0A2',
         iconText: 'Компенсация до 50 000 $',
         detailsTitle: false,
         detailsItems: [
           'Оплата экстренного лечения и стоматологии',
           'Защита при потере документов',
           'Транспортные расходы',
           'Защита при активных видах отдыха',
         ],
         detailsItemsTitle: 'Будьте уверены, что, находясь в поездке, вы всегда сможете получить неотложную медицинскую помощь.',
         detailsItemsFull: [
           'Оплата медицинской помощи при получении травмы, отравлении, внезапном остром заболевании',
           'Оплата экстренной стоматологической помощи',
           'Оплата медицинской транспортировки или эвакуации',
           'Защита при активных видах отдыха',
           'Транспортные расходы',
           'Расходы при потере или похищении документов',
           'Оплата срочных сообщений',
           'Получение юридической помощи'
         ],
         storyIconSrc: InsuranceMedicalIconPath,
         howtoTitle: false,
         howtoInstructions: [
           'Свяжитесь с оператором сервисной службы по контактам, указанным в полисе.',
           'Назовите оператору номер вашего полиса и коротко опишите, какая помощь вам необходима. Специалист вызовет врача или назовёт адрес ближайшей клиники.',
           'Следуйте указаниям оператора и не оплачивайте счета самостоятельно - расходы по страховым случаям возместит страховая компания. Если вы обратились в клинику без звонка в сервисную службу, внимательно оформляйте финансовые документы. В течении 30 дней предоставьте подтверждение расходов в страховую компанию. ',
           '“АльфаСтрахование” примет решение о компенсации затрат в течение нескольких дней после вашего обращения.'
         ]
       }
     default:
       return {}
   }
 }

export function normalizeGetInsuranceCalculationResponse(args) {
  const res = _(args.offers)
    .map(item => {
      return {
        ...item,
        offerByProducts: item.offerByProducts.map(offer => {
          const productType = (offer.product.name || "").trim()

          return {
            ...offer,
            id: uuid(),
            checked: false,
            wordBeforePrice: getWordBeforPrice(productType),
            iconSrc: getInsuranceIcon(productType),
            company: getCompany(productType),
            label: getLabel(productType),
            loading: false,
            offertaUri: getOffertaUri(productType),
            specificationUri: getSpecificationDoc(productType),
            termsUri: 'https://www.alfastrah.ru/docs/rules_SP_230617.pdf',
            risks: formatRisks(productType, offer.risks || []).sort(
              (a, b) => a.coverage.value - b.coverage.value
            ),
            cardRenderData: getCardRenderData(productType),
          }
        })
      }
    })
    .value()

  return res
}


export const url = searchRequest => {
  return (
    searchRequest.route[0].from +
    moment(searchRequest.route[0].date)
      .format("YYYY-DD-MM")
      .split("-")
      .join("")
      .slice(-4) +
    (searchRequest.route[0].to ? searchRequest.route[0].to : "") +
    (searchRequest?.route[1]?.date
      ? moment(searchRequest?.route[1]?.date)
          .format("YYYY-DD-MM")
          .split("-")
          .join("")
          .slice(-4)
      : "") +
    (searchRequest.passengers.adults ? searchRequest.passengers.adults : "") +
    (searchRequest.passengers.children
      ? searchRequest.passengers.children
      : "") +
    (searchRequest.passengers.infants ? searchRequest.passengers.infants : "")
  )
}
