import React from 'react';
import {SvgIcon} from "@mui/material";

const HotelBed = (props) => {
	return (
		<SvgIcon {...props} >
			<path d="M3 19C2.71667 19 2.47933 18.904 2.288 18.712C2.096 18.5207 2 18.2833 2 18V13C2 12.55 2.09167 12.1417 2.275 11.775C2.45833 11.4083 2.7 11.0833 3 10.8V8C3 7.16667 3.29167 6.45833 3.875 5.875C4.45833 5.29167 5.16667 5 6 5H10C10.3833 5 10.7417 5.071 11.075 5.213C11.4083 5.35433 11.7167 5.55 12 5.8C12.2833 5.55 12.5917 5.35433 12.925 5.213C13.2583 5.071 13.6167 5 14 5H18C18.8333 5 19.5417 5.29167 20.125 5.875C20.7083 6.45833 21 7.16667 21 8V10.8C21.3 11.0833 21.5417 11.4083 21.725 11.775C21.9083 12.1417 22 12.55 22 13V18C22 18.2833 21.904 18.5207 21.712 18.712C21.5207 18.904 21.2833 19 21 19C20.7167 19 20.4793 18.904 20.288 18.712C20.096 18.5207 20 18.2833 20 18V17H4V18C4 18.2833 3.90433 18.5207 3.713 18.712C3.521 18.904 3.28333 19 3 19ZM13 10H19V8C19 7.71667 18.904 7.479 18.712 7.287C18.5207 7.09567 18.2833 7 18 7H14C13.7167 7 13.4793 7.09567 13.288 7.287C13.096 7.479 13 7.71667 13 8V10ZM5 10H11V8C11 7.71667 10.9043 7.479 10.713 7.287C10.521 7.09567 10.2833 7 10 7H6C5.71667 7 5.479 7.09567 5.287 7.287C5.09567 7.479 5 7.71667 5 8V10ZM4 15H20V13C20 12.7167 19.904 12.479 19.712 12.287C19.5207 12.0957 19.2833 12 19 12H5C4.71667 12 4.479 12.0957 4.287 12.287C4.09567 12.479 4 12.7167 4 13V15ZM20 15H4C4 15 4.09567 15 4.287 15C4.479 15 4.71667 15 5 15H19C19.2833 15 19.5207 15 19.712 15C19.904 15 20 15 20 15Z" fill="#7E8389"/>
		</SvgIcon>
	);
};

export default HotelBed;