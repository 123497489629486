import { createAction, createReducer } from "redux-act"
import {
  AGENT_LOADING_ERRORS,
  AGENT_LOADING_STATE
} from "./constants"

export const setAgentAuthInformation = createAction(
  "agents/setAgentInformation"
)

export const getAgentMainInformation = createAction(
  "agents/getAgentMainInformation"
)


export const setAgentMainInfo = createAction("agents/setAgentMainInfo")
export const getAgentInfo = createAction('agents/getAgentInfo');
export const getAgentInfoSuccess = createAction('agents/getAgentInfoSuccess');
export const setAgentBalance = createAction("agents/setAgentBalance")
export const getAgentOverPaidOrders = createAction("agents/getAgentsOverPaid")
export const setAgentOverPaidOrders = createAction("agents/setAgentsOverPaid")

export const getAgentNotPaidOrders = createAction(
  "agents/getAgentNotPaidOrders"
)
export const setAgentNotPaidOrders = createAction(
  "agents/setAgentNotPaidOrders"
)

export const getAgentCreditHistoryRequest = createAction(
  "agents/getAgentCreditHistoryRequest"
)
export const getAgentCreditHistorySuccess = createAction(
  "agents/getAgentCreditHistorySuccess"
)
export const purifyAgentCreditHistory = createAction(
  "agents/purifyAgentCreditHistory"
)
export const getAgentCreditHistoryFailure = createAction(
  "agents/getAgentCreditHistoryFailure"
)

export const getAgentBillByOrderIdRequest = createAction(
  "agents/getAgentBillByOrderId"
)
export const getAgentBillByOrderIdSuccess = createAction(
  "agents/getAgentBillByOrderIdSuccess"
)
export const getAgentBillByOrderIdFailure = createAction(
  "agents/getAgentBillByOrderIdSuccess"
)

export const getAgentReport = createAction("agents/getAgentReport")

export const getAgentReportResponse = createAction(
  "agents/getAgentReportResponse"
)

export const getAgentReportFailure = createAction("agents/getAgentReportFail")

export const getAgentPaymentHistoryRequest = createAction(
  "agents/getAgentPaymentHistory"
)

export const getAgentPaymentHistorySuccess = createAction(
  "agents/setAgentPaymentHistory"
)
export const purifyAgentPaymentHistory= createAction(
  "agents/purifyAgentPaymentHistorySuccess"
)
export const getAgentPaymentHistoryFailure = createAction(
  "agents/getAgentPaymentHistoryFailure"
)

export const getAgentBillBySamoNumberRequest = createAction(
  "agents/getAgentBillBySamoNumberRequest"
)

export const getAgentBillBySamoNumberFail = createAction(
  "agents/getAgentBillBySamoNumberRequest"
)

export const getAgentBillByTteNumberRequest = createAction(
  "agents/getAgentBillBySamoNumberRequest"
)

export const getAgentBillByTteNumberFail = createAction(
  "agents/getAgentBillBySamoNumberFAil"
)

export const getAgentBillSuccess = createAction("agents/getAgentBillSuccess")

export const getAquiringCommission = createAction(
  "agents/getAquiringCommission"
)
export const getAquiringCommissionSuccess = createAction(
  "agents/getAquiringCommissionSuccess"
)

export const addAgentRequest = createAction("agents/addAgentRequest")
export const addAgentSuccess = createAction("agents/addAgentSuccess")
export const addAgentFailure = createAction("agents/addAgentFailure")

export const getAgentDeposit = createAction("agents/getAgentDeposit")
export const getAgentDepositSuccess = createAction(
  "agents/getAgentDepositSuccess"
)

export const getAgentDepositList = createAction("agents/getAgentDepositList")
export const getAgentDepositListSuccess = createAction(
  "agents/getAgentDepositListSuccess"
)
export const purifyAgentDepositList = createAction(
  "agents/purifyAgentDepositList"
)


export const getAgentsBalance = createAction('@agents/getAgentBalance');

export const showSnackbar = createAction(
  "agents/showSnackbars"
)
export const hideSnackbar = createAction(
  "agents/hideSnackbars"
)

export const resetData = createAction('agents/finance/resetData');

const agentSigninInfo = createReducer({}, null)
agentSigninInfo.on(setAgentAuthInformation, (_, payload) => payload)

const agent = createReducer({}, null)
agent.on(setAgentMainInfo, (_, payload) => payload)

const balance = createReducer({}, null)
balance.on(setAgentBalance, (_, payload) => payload)

const agentRequestsInfo = createReducer({}, null);
agentRequestsInfo.on(getAgentInfoSuccess, (_, payload) => payload);

const overPaidOrders = createReducer({}, [])
overPaidOrders.on(setAgentOverPaidOrders, (_, payload) => payload)

const notPaidOrders = createReducer({}, [])
notPaidOrders.on(setAgentNotPaidOrders, (_, payload) => payload)

const agentsCreditLimitHistory = createReducer({}, [])
agentsCreditLimitHistory.on(
  getAgentCreditHistorySuccess,
  (_, payload) => payload
)
agentsCreditLimitHistory.on(purifyAgentCreditHistory,() => ([]));

const agentPaymentHistory = createReducer({}, [])
agentPaymentHistory.on(getAgentPaymentHistorySuccess, (_, payload) => payload)
agentPaymentHistory.on(purifyAgentPaymentHistory, () => ([]))

const loadings = createReducer({}, AGENT_LOADING_STATE)
loadings.on(getAgentBillByOrderIdRequest, state => ({
  ...state,
  billByOrderId: true
}))
loadings.on(getAgentBillByOrderIdSuccess, state => ({
  ...state,
  billByOrderId: false
}))

loadings.on(getAgentCreditHistoryRequest, state => ({
  ...state,
  creditHistory: true
}))
loadings.on(getAgentCreditHistorySuccess, state => ({
  ...state,
  creditHistory: false
}))


loadings.on(getAgentCreditHistoryFailure, state => ({
  ...state,
  creditHistory: false
}))

loadings.on(getAgentBillByTteNumberRequest, state => ({
  ...state,
  getBillByOrderOrTTE: true
}))
loadings.on(getAgentBillBySamoNumberRequest, state => ({
  ...state,
  getBillByOrderOrTTE: true
}))
loadings.on(getAgentBillSuccess, state => ({
  ...state,
  getBillByOrderOrTTE: false
}))

// loadings.on(showModalError, state => ({
//   ...state,
//   getBillByOrderOrTTE: false
// }))

loadings.on(getAgentReport, state => ({
  ...state,
  getAgentReport: true
}))
loadings.on(getAgentReportResponse, state => ({
  ...state,
  getAgentReport: false
}))
loadings.on(getAgentReportFailure, state => ({
  ...state,
  getAgentReport: false
}))

loadings.on(getAgentPaymentHistoryRequest, state => ({
  ...state,
  paymentHistory: true
}))
loadings.on(getAgentPaymentHistorySuccess, state => ({
  ...state,
  paymentHistory: false
}))
loadings.on(getAgentPaymentHistoryFailure, state => ({
  ...state,
  paymentHistory: false
}))

const errors = createReducer({}, AGENT_LOADING_ERRORS)

const errorSnackbar = createReducer({}, false);
errorSnackbar.on(showSnackbar,() => true);
errorSnackbar.on(hideSnackbar,() => false)

export const addAgentError = createReducer({}, null)
addAgentError.on(addAgentFailure, (_, payload) => payload)

export const addAgentSuccessDone = createReducer({}, null)
addAgentSuccessDone.on(addAgentSuccess, (_, payload) => payload)

const errorModal = createReducer({}, null)
errorModal.on(getAgentReportFailure, (_, payload) => payload)

const aquiringInfo = createReducer({}, null)
aquiringInfo.on(
  getAquiringCommissionSuccess,
  (_, payload) => {

    // prod  и test api возвращали разную структуру данных, пока оставил...
    // const data = process.env.REACT_APP_ENV === 'development' ? payload.acquiringCommissions.find(x => x.paymentType === 'Rus') : payload.acquiringCommission;
    // return process.env.REACT_APP_ENV === 'development' ?  data?.acquiringCommission: data;
    const data = payload.acquiringCommissions.find(x => x.paymentType === 'Rus');
    return data?.acquiringCommission;
  }
)

export const agentDeposits = createReducer({}, null)
agentDeposits.on(getAgentDepositListSuccess, (_, payload) => payload?.deposits)
agentDeposits.on(purifyAgentDepositList, () => null)

const financeAgentsReducers = {
  agentSigninInfo,
  agent,
  balance,
  notPaidOrders,
  overPaidOrders,
  agentsCreditLimitHistory,
  agentPaymentHistory,
  loadings,
  errors,
  errorModal,
  aquiringInfo,
  addAgentError,
  addAgentSuccessDone,
  agentDeposits,
  agentRequestsInfo,
  errorSnackbar,
}

export default financeAgentsReducers
