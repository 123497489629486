
import { useState, useEffect } from 'react'
import HotelCard from './hotelCard'
import useGeoStore from '../../store'

import './style.scss'
import { isMobile } from 'react-device-detect'
import { isEmpty } from 'lodash'
import ArrowRight from './../../../../asset/icons/lp/LandingVisa/ArrowRight'

function TabsSmallSixCards({ content, title }) {
    const [activeTab, setActiveTab] = useState(!isEmpty(content.categories) ? { content: content.categories[0].hotels, index: 0 } : [])
    const actions = useGeoStore(({ actions }) => actions)
    const [isShow, setShow] = useState(false);
    const maxCardCount = isMobile ? 3 : 6

    useEffect(() => {
        actions.getCountry()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isEmpty(content.categories)) {
        return <div><h2>{title}</h2></div>
    }

    return <div className='six_card'>
        <h2>{title}</h2>
        <div >
            <div className='six_card__head'>
                <div className='six-card__tabs'>
                    {content?.categories?.map((item, index) => {
                        if (isEmpty(item?.hotels) || isEmpty(item?.hotels?.filter(hotel => hotel.urlName))) return null
                        return <div
                            className={activeTab.index === index ? 'item active' : 'item'}
                            onClick={() => setActiveTab({ content: item.hotels, index })}
                            key={item.categoryName}
                        >
                            {item.categoryName}
                        </div>
                    }
                    )}
                </div>
                {content.linkUrl && <div className='six-card__link'> <a className='link' href={content.linkUrl}>{content.linkName} <ArrowRight className='link-arrow' /></a></div>}
            </div>
            <div className='six-card__content'>
                {activeTab?.content?.map((item, index) => {
                    if (!isShow && index + 1 > maxCardCount) return null
                    return <HotelCard key={item.id + index} hotel={item} />
                })}

            </div>
            {activeTab?.content.length > maxCardCount && <div onClick={() => setShow(!isShow)} className='six-card__more-btn'>{isShow ? 'Показать меньше' : 'Подробнее'}</div>}
        </div>
    </div>
}

export default TabsSmallSixCards