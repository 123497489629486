import React from 'react';
import {Box, TextField} from "@material-ui/core";

import './style.css'
import useBookingStore from "../../store";
import useLoyaltyStore from "../../../Loyalty/store";
import LightTooltip from "../../../../components/LightTooltip";

const PromoCode = () => {

	const {actions} = useBookingStore()
	const response = useBookingStore(({ response }) => response)
	const memberId = useLoyaltyStore(({ memberId }) => memberId)

	return (
		<div className='promo-code'>
			{!memberId &&
				<Box>
					<TextField
						id="promo-code"
						label="Промокод"
						variant="outlined"
						onChange={(e) => actions.applyPromoCode(e.target.value)}
						helperText={!response.discount && response.tour.promoCodeComment}
					/>
					{response.discount && <p>Сертификат на сумму: <span
						className='discount'>{response.discount.amount} {response.discount.currencyAlias}</span></p>}
					<p>{response.discount && response.tour.promoCodeComment}</p>
				</Box>
			}
		</div>
	);
};

export default PromoCode;