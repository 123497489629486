import { Checkbox } from '../../../../../components/ui';

const FilterSection = (props) => {
  const { list, properties } = props;
  return list.map((x, i) => (
    <div key={i} style={{ marginTop: i === 0 ? 2 : 12 }}>
      <Checkbox
        onChange={() => {
          if (x?.name) {
            props.setProperty(x.name);
          } else if (x?.code) {
            props.setProperty(x.code);
          }
        }}
        checked={
          x?.name ? properties.includes(x?.name) : x?.code ? properties.includes(x?.code) : false
        }
        type="squar"
        label={x.description}
      />
    </div>
  ));
};

export default FilterSection;
