import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'
import { generate } from '@wcj/generate-password'
import { getCookie } from 'react-use-cookie'
import reduxStore from '../../store/configs'
import { checkAuthStatus } from '../../store/modules/user'
import { format } from 'date-fns'
import { isDate, sortBy, sumBy } from 'lodash'
import { cyrillicRegExp } from '../../constants/reg-exps'

const BASE_URL = process.env.REACT_APP_FS_APIGATE
const API_AUTH_USERINFO = process.env.REACT_APP_FS_AUTH + '/connect/userinfo'
const API_REFRESH_TOKEN = process.env.REACT_APP_FS_AUTH + '/connect/token'
const FORGOT_URL =
	process.env.REACT_APP_FS_AUTH + '/api/account/forgot-password'

const useAuthStore = create(
	devtools((set, get) => ({
		samoLink: '',
		samoLinks: {},
		error: '',
		forgotError: '',
		validateErrors: {},
		agent: JSON.parse(localStorage.getItem('agentInfo')),
		agentsUsers: JSON.parse(localStorage.getItem('agentsUsers')),
		agents: null,
		token: JSON.parse(localStorage.getItem('token')),
		tokenTTE: JSON.parse(localStorage.getItem('tte_token')),
		banks: null,
		directorPositions: null,
		activities: null,
		ownerships: null,
		currentAgent: null,
		towns: null,
		loading: null,
		isLogOut: false,
		bonuses: null,
		bonusesDetails: null,
		currentCurrency: 'RUB',
		currencys: null,
		bonusesItog: null,
		crmAuthLogOut: false,
		officialName: JSON.parse(localStorage.getItem('officialName')),
		owners: [],
		offices: [],
		selectTowns: null,
		currentTown: null,
		isRequestSuccess: false,
		actions: {
			clearError: () => {
				set({ error: '' })
			},
			logout: () => {
				localStorage.removeItem('agentInfo')
				localStorage.removeItem('token')
				localStorage.removeItem('partPass')
				localStorage.removeItem('tte_token')
				localStorage.removeItem('authSid')
				set({ isLogOut: true })
				set({ crmAuthLogOut: true })
				set({ agent: null })
				set({ samoLinks: {} })
				const auth = new URLSearchParams(window.location.search)
				auth.set('auth', '')
				// window.location.href = window.location.origin + window.location.pathname
			},
			signin: async (login, password) => {
				set({ isLogOut: false })
				try {
					const res = await axios({
						url: `${BASE_URL}/auth/signin`,
						method: 'POST',
						data: {
							login,
							password,
							client_id: 'b2b.public.client'
						}
					})
					const newToken = res.data
					set({ token: newToken })
					localStorage.setItem('token', JSON.stringify(newToken))
					set({ error: '' })
					await get().actions.getUserInfo(res.data)
					await get().actions.getAgents(res.data)
					await get().actions.getAgentsUsers(res.data)
					await get().actions.getAuthSid(res.data, login, password)
					try {
						const tteToken = await axios({
							url: process.env.REACT_APP_TTE_SIGN_IN,
							method: 'POST',
							data: {
								login,
								password,
								grant_Type: 'password'
							}
						})
						set({ tokenTTE: tteToken.data })
						localStorage.setItem('tte_token', JSON.stringify(tteToken.data))
						reduxStore.dispatch(checkAuthStatus())
						set({ isRequestSuccess: true })
						if (window.location.pathname.includes('/cabinet')) {
							window.location.reload()
						}
					} catch (e) {
						if (e?.response?.data?.code) {
							localStorage.setItem('tte_auth_error', e?.response?.data)
						} else {
							localStorage.setItem(
								'tte_auth_error',
								JSON.stringify({ code: 0, message: e?.response?.data })
							)
						}
						console.log(e?.response?.data, 'e')
						const isRestrictedPath = [
							'/cabinet/orders',
							'/avia',
							'/cabinet'
						].includes(window.location.pathname)
						if (
							e?.response?.data === 'Некорректный E-mail или пароль' &&
							isRestrictedPath
						) {
							window.location.href = '/cabinet/avia-sale-request'
						} else {
							const error = e?.response?.data.message || e?.response?.data
							set({ error: 'Ошибка ТТЕ: ' + error })
						}
					}
				} catch (e) {
					const response = e.response?.data?.detail
					const error = response !== undefined ? JSON.parse(e.response?.data?.detail).message : 'Что-то пошло не так...'
					set({ error: error })
					console.log(e?.response?.data, 'e')
				} finally {

				}
			},
			getAgents: async req => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents?PartpassId=${get().agent.partPass}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					const result = { ...get().agent, agents: res.data }
					set({ agent: result })
					set({ officialName: res.data.officialName })
					localStorage.setItem(
						'officialName',
						JSON.stringify(get().officialName)
					)
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			changeAgents: async () => {
				const req = get().agent?.agents
				try {
					await axios({
						url: `${BASE_URL}/agents?PartpassId=${get().agent.partPass}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					await get().actions.getUserInfo(get().token)
					await get().actions.getAgents(get().token)
					await get().actions.getAgentsUsers(get().token)
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			getAgentsUsers: async req => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/users?PartpassId=${get().agent.partPass}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					const result = { ...get().agent, agentsUsers: res.data }
					set({ agent: result })
					localStorage.setItem('agentInfo', JSON.stringify(get().agent))
					set({ agentsUsers: res.data })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			deleteAgentsUser: async partPass => {
				for (let i = 0; i < partPass.length; i++) {
					const partPassId = partPass[i]
					const newUser = get().agent?.agentsUsers?.find(
						agent => agent.partpassId === partPassId
					)
					const req = { ...newUser, isArchived: true }
					try {
						const res = await axios({
							url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
							method: 'PATCH',
							headers: {
								Authorization: `Bearer ${get().token?.access_token}`
							},
							data: req
						})
						console.log(res.data)
						const result = [...get().agentsUsers].map(item => {
							if (item.partpassId === partPassId) {
								return res.data
							} else return item
						})
						set({ agentsUsers: result })
						localStorage.setItem(
							'agentsUsers',
							JSON.stringify(get().agentsUsers)
						)
					} catch (e) {
						console.log(e?.response?.data, 'e')
					}
				}
			},
			recoveryAgentUser: async () => {
				const partPassId = get().currentAgent?.partpassId
				const newUser = get().agent?.agentsUsers?.find(
					agent => agent.partpassId === partPassId
				)
				const req = { ...newUser, isArchived: false }
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					const result = [...get().agentsUsers].map(item => {
						if (item.partpassId === partPassId) {
							return res.data
						} else return item
					})
					set({ agentsUsers: result })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			deleteAgentUser: async () => {
				const partPassId = get().currentAgent?.partpassId
				const newUser = get().agent?.agentsUsers?.find(
					agent => agent.partpassId === partPassId
				)
				const req = { ...newUser, isArchived: true }
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					console.log(res.data)
					const result = [...get().agentsUsers].map(item => {
						if (item.partpassId === partPassId) {
							return res.data
						} else return item
					})
					set({ agentsUsers: result })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			addAgentUser: async agent => {
				const pass = generate({
					length: 6,
					numbers: true
				})
				const town = get()?.currentTown?.id || null
				set({ loading: true })
				const req = {
					partnerId: get().agent?.agents.id,
					creatorPartpassId: 0,
					fullName:
						agent.user.lastName +
						' ' +
						agent.user.name +
						' ' +
						agent.user.patronymic,
					phones: agent.selectPhone + agent.phone,
					faxes: agent.user.fax,
					email: agent.user.email,
					position: agent.user.job,
					isAdministrator: agent.access === 'Полный',
					login: agent?.login,
					birthDate: format(agent.birthday, 'yyyy-MM-dd'),
					isMale: agent.gender === 'Мужской',
					onlineAccess: agent.onlineAccess,
					townId: town,
					password: pass
				}
				try {
					await axios({
						url: `${BASE_URL}/agents/user`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					await get().actions.getAgentsUsers(get().token)
					set({ currentTown: null })
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			editAgentsUser: async () => {
				const newPrefix = get().currentAgent?.prefix
				const phoneNumber = get().currentAgent?.number
				const currrentDate = get().currentAgent.birthday
				const date = isDate(currrentDate)
					? format(currrentDate, 'yyyy-MM-dd')
					: currrentDate
				const town = get()?.currentTown?.id || null

				if (newPrefix && phoneNumber) {
					const newPhone = newPrefix + phoneNumber
					const newAgent = {
						occupation: get().currentAgent.occupation,
						phone: newPhone,
						fax: '',
						email: get().currentAgent.email,
						position: get().currentAgent.position,
						isArchived: get().currentAgent.isArchived,
						isBookKeeper: get().currentAgent.isBookKeeper,
						fullName:
							get().currentAgent.lastName +
							' ' +
							get().currentAgent.firstName +
							' ' +
							get().currentAgent.patronymic,
						isMale: get().currentAgent.isMale === 'Мужской',
						isAdministrator: get().currentAgent.isAdministrator === 'Полный',
						onlineAccess: get().currentAgent.isOnlineAccess,
						birthday: date,
						townId: town,
						partpassId: get().currentAgent.partpassId
					}
					set({ loading: true })
					try {
						const res = await axios({
							url: `${BASE_URL}/agents/user?PartpassId=${newAgent.partpassId}`,
							method: 'PATCH',
							headers: {
								Authorization: `Bearer ${get().token?.access_token}`
							},
							data: newAgent
						})
						const result = [...get().agentsUsers].map(item => {
							if (item.partpassId === newAgent.partpassId) {
								return res.data
							} else return item
						})
						set({ agentsUsers: result })
						localStorage.setItem(
							'agentsUsers',
							JSON.stringify(get().agentsUsers)
						)
						set({ loading: false })
						set({ currentTown: null })
					} catch (e) {
						console.log(e?.response?.data, 'e')
						set({ loading: false })
					}
				}
			},
			setAgent: agent => {
				const newAgent = {
					...agent,
					firstName: agent?.fullName?.split(' ')[1],
					lastName: agent?.fullName?.split(' ')[0],
					patronymic: agent?.fullName?.split(' ')[2],
					isMale: agent?.isMale ? 'Мужской' : 'Женский',
					isAdministrator: agent?.isAdministrator ? 'Полный' : 'Частичный'
				}
				set({ currentAgent: newAgent })
				const currentCity = get().selectTowns?.find(
					town => town?.id === agent?.town
				)
				set({ currentTown: currentCity })
			},
			changeFullName: name => {
				set({ currentAgent: { ...get().currentAgent, fullName: name } })
			},
			changeFirstName: name => {
				set({ currentAgent: { ...get().currentAgent, firstName: name } })
			},
			changeLastName: name => {
				set({ currentAgent: { ...get().currentAgent, lastName: name } })
			},
			changePatronymic: name => {
				set({ currentAgent: { ...get().currentAgent, patronymic: name } })
			},
			changeGender: name => {
				set({
					currentAgent: {
						...get().currentAgent,
						isMale: name
					}
				})
			},
			changeTownName: value => {
				set({ currentAgent: { ...get().currentAgent, townName: value } })
			},
			changeOnlineAccess: value => {
				set({ currentAgent: { ...get().currentAgent, isOnlineAccess: value } })
			},
			changeBirthday: day => {
				set({ currentAgent: { ...get().currentAgent, birthday: day } })
			},
			changePosition: job => {
				set({ currentAgent: { ...get().currentAgent, position: job } })
			},
			changeEmail: value => {
				set({ currentAgent: { ...get().currentAgent, email: value } })
			},
			changeFax: value => {
				set({ currentAgent: { ...get().currentAgent, fax: value } })
			},
			isAgentAdmin: value => {
				set({ currentAgent: { ...get().currentAgent, isAdministrator: value } })
			},
			changePhoneNumber: number => {
				set({ currentAgent: { ...get().currentAgent, number: number } })
			},
			changePhonePrefix: prefix => {
				set({ currentAgent: { ...get().currentAgent, prefix: prefix } })
			},
			getBanks: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/Banks`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ banks: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			setTown: town => {
				set({ currentTown: town })
			},
			getTowns: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/catalogs/townsbycountry?countryId=210357`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ towns: res.data })

					const ids = ['210357', '367404', '325801']
					Promise.all(
						ids.map(async item => {
							const res = await axios({
								url: `${BASE_URL}/catalogs/townsbycountry?countryId=${item}`,
								method: 'GET',
								headers: {
									Authorization: `Bearer ${get().token?.access_token}`
								}
							})
							return res.data
						})
					)
						.then(results => {
							const [rusTowns, kazTowns, belTowns] = results
							const rus = sortBy(
								rusTowns
									.filter(item => cyrillicRegExp.test(item.title))
									.map(item => ({
										...item,
										country: 'Россия'
									})),
								'title'
							)
							const kaz = sortBy(
								kazTowns
									.filter(item => cyrillicRegExp.test(item.title))
									.map(item => ({
										...item,
										country: 'Казахстан'
									})),
								'title'
							)
							const bel = sortBy(
								belTowns
									.filter(item => cyrillicRegExp.test(item.title))
									.map(item => ({
										...item,
										country: 'Белорусь'
									})),
								'title'
							)
							set({
								selectTowns: [...rus, ...kaz, ...bel]
							})
						})
						.catch(err => console.error(err))
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getAgentOwners: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/catalogs/owners`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ owners: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},

			getDirectorPositions: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/DirectorPositions`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ directorPositions: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getActivities: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/Activities`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ activities: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},

			getOwnerships: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/ownerships`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ ownerships: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},

			getUserInfo: async req => {
				try {
					const res = await axios({
						url: `${API_AUTH_USERINFO}`,
						method: 'GET',
						withCredentials: true,
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					if (
						[
							'Agent',
							'NewPartner',
							'Partner',
							'Supplier',
							'Moderator'
						].includes(res.data.role)
					) {
						set({ agent: res.data })
						localStorage.setItem('partPass', JSON.stringify(res.data.partPass))
					} else {
						set({ error: 'Авторизация только для агентов' })
					}
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			forgotPassword: async login => {
				const head = await axios({
					url: `https://auth.fstravel.com`,
					method: 'HEAD',
					withCredentials: true
				})
				console.log(head)
				try {
					await axios({
						url: `${FORGOT_URL}`,
						method: 'POST',
						withCredentials: true,
						data: {
							login: login,
							postResetUrl: '/',
							clientId: 'b2b.public.client'
						},
						headers: {
							RequestVerificationToken: head.config.headers['X-XSRF-TOKEN']
						}
					})
					set({ forgotError: '' })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({
						forgotError: e?.response?.data?.message
							? e?.response?.data?.message
							: `Указаннный пользователь ${login} не найден`
					})
				}
			},
			setForgotError: () => {
				set({ forgotError: '' })
			},
			changeLatinName: () => {
				const officialName = get().agent?.agents.officialName
				const newAgent = { ...get().agent?.agents, latinName: officialName }
				set({ agent: { ...get().agent, agents: newAgent } })
			},
			changeAgentOfficialName: (value, checked) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, officialName: value }
					}
				})
				if (checked) {
					set({
						agent: {
							...get().agent,
							agents: { ...get().agent?.agents, latinName: value }
						}
					})
				}
			},
			changeAgentOKPO: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, okpo: value }
					}
				})
			},
			changeAgentKPP: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, kpp: value }
					}
				})
			},
			changeAgentOKONH: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, okonh: value }
					}
				})
			},
			changeAgentRegistrationDate: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							registrationDate: format(value, 'yyyy-MM-dd')
						}
					}
				})
			},
			changeAgentBoss: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, boss: value }
					}
				})
			},
			changeAgentINN: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, inn: value }
					}
				})
			},
			changeAgentPAdress: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, pAddress: value }
					}
				})
			},
			changeAgentFAddress: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, fAddress: value }
					}
				})
			},
			changeAgentBank: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							bankId: value?.id,
							bank: value
						}
					}
				})
			},
			changeAgentWorker: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							worker: value
						}
					}
				})
			},
			setError: error => {
				set(() => ({ error: error }))
			},
			setValidateErrors: (prop, error) => {
				set({ validateErrors: { ...get().validateErrors, [prop]: error } })
			},

			changeAgentDirectorPosition: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							directorPositionId: value?.id,
							directorPosition: value
						}
					}
				})
			},
			changeAgentOwnership: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							ownershipId: value?.id,
							ownership: value
						}
					}
				})
			},
			changeAgentActivity: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							activityId: value.id,
							activity: value
						}
					}
				})
			},
			changeAgentTown: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							townId: value
						}
					}
				})
			},
			refreshToken: async () => {
				const token = localStorage.getItem('token')
				if (token) {
					const data = new FormData()
					data.append('refresh_token', get().token?.refresh_token)
					data.append('client_id', 'b2b.public.client')
					data.append('grant_type', 'refresh_token')
					try {
						const res = await axios({
							url: `${API_REFRESH_TOKEN}`,
							method: 'POST',
							data
						})
						const newToken = res.data
						set({ token: newToken })
						localStorage.setItem('token', JSON.stringify(newToken))
						return res.data
					} catch (e) {
						console.log(e?.response?.data, 'e')
					}
				} else {
					get().actions.logout()
				}
			},
			refreshTokenTTE: async () => {
				let refreshToken
				try {
					refreshToken = JSON.parse(
						localStorage.getItem('tte_token')
					)?.refreshToken
				} catch (e) {
					refreshToken = getCookie('refresh_token_tte')
				}
				// refreshToken = JSON.parse(localStorage.getItem('tte_token'))?.refreshToken || getCookie('refresh_token_tte')
				if (!!refreshToken) {
					try {
						const res = await axios({
							url: process.env.REACT_APP_TTE_SIGN_IN,
							method: 'POST',
							data: {
								refreshToken,
								grant_Type: 'refresh_token'
							}
						})
						const newToken = res.data
						set({ tokenTTE: newToken })
						localStorage.setItem('tte_token', JSON.stringify(newToken))
						reduxStore.dispatch(checkAuthStatus())
						return res.data
					} catch (e) {
						console.log(e?.response?.data, 'e')
						get().actions.logout()
					}
				}
			},
			changeContactInfo: (key, value) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, [key]: value }
					}
				})
			},
			getSamoLink: async url => {
				const token = getCookie('access_token')
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/link?partpassId=${get().agent.partPass}`,
						method: 'POST',
						data: {
							siteCode: 327,
							url: url
						},
						headers: {
							Authorization: `Bearer ${get().token?.access_token || token}`
						}
					})
					set({ samoLink: res.data.url })
					return res.data.url
				} catch (e) {
					console.log(e?.response?.data, 'e')
					if (e.response?.status === 401) {
						get().actions.logout()
					}
					console.log(e?.response?.data, 'e')
				}
			},
			refreshSamoLink: async (id, url) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/link?partpassId=${get().agent?.partPass}`,
						method: 'POST',
						data: {
							siteCode: 327,
							url: url
						},
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ samoLinks: { ...get().samoLinks, [id]: res.data.url } })
					return res.data.url
				} catch (e) {
					console.log(e?.response?.data, 'e')
					if (e.response?.status === 401) {
						get().actions.logout()
					}
					console.log(e?.response?.data, 'e')
				}
			},
			externalSignin: async token => {
				await get().actions.getUserInfo({ access_token: token })
				await get().actions.getAgents({ access_token: token })
				await get().actions.getAgentsUsers({ access_token: token })
			},
			getAgentBonuses: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Sales/TotalBonuses?PartpassId=${id}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ bonuses: res.data })
					const details = await axios({
						url: `${BASE_URL}/Sales/BonusDetails?PartpassId=${id}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ bonusesDetails: details.data })
					const current = get().currentCurrency
					get().actions.changeCurrency(current)
					// if (get().currentCurrency === 'RUB') {
					// 	const itogInCurrencyies = res.data.map(item => {
					// 		if (item.currencyName === 'RUB') return item
					// 		else if (item.currencyName === 'USD') {
					// 			const current = get().currencys.dollarPerRub
					// 			return {
					// 				...item,
					// 				accruedBonuses: item.accruedBonuses * current,
					// 				availableBonuses: item.availableBonuses * current,
					// 				burnedBonuses: item.burnedBonuses * current,
					// 				notAvailableBonuses: item.notAvailableBonuses * current,
					// 				totalBonuses: item.totalBonuses * current,
					// 				usedBonuses: item.usedBonuses * current
					// 			}
					// 		} else if (item.currencyName === 'EUR') {
					// 			const current = get().currencys.euroPerRub
					// 			return {
					// 				...item,
					// 				accruedBonuses: item.accruedBonuses * current,
					// 				availableBonuses: item.availableBonuses * current,
					// 				burnedBonuses: item.burnedBonuses * current,
					// 				notAvailableBonuses: item.notAvailableBonuses * current,
					// 				totalBonuses: item.totalBonuses * current,
					// 				usedBonuses: item.usedBonuses * current
					// 			}
					// 		} else return item
					// 	})
					// 	const itog = {
					// 		accruedBonuses: sumBy(itogInCurrencyies, 'accruedBonuses'),
					// 		availableBonuses: sumBy(itogInCurrencyies, 'availableBonuses'),
					// 		burnedBonuses: sumBy(itogInCurrencyies, 'burnedBonuses'),
					// 		notAvailableBonuses: sumBy(
					// 			itogInCurrencyies,
					// 			'notAvailableBonuses'
					// 		),
					// 		totalBonuses: sumBy(itogInCurrencyies, 'totalBonuses'),
					// 		usedBonuses: sumBy(itogInCurrencyies, 'usedBonuses')
					// 	}
					// 	set({ bonusesItog: itog })
					// }
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			changeCurrency: value => {
				const itog = get().bonuses
				if (value === 'RUB') {
					const newItog = itog.map(item => {
						if (item.currencyName === 'RUB') return item
						else if (item.currencyName === 'USD') {
							const currentInDollar = get().currencys.dollarPerRub
							return {
								...item,
								accruedBonuses: item.accruedBonuses * currentInDollar,
								availableBonuses: item.availableBonuses * currentInDollar,
								burnedBonuses: item.burnedBonuses * currentInDollar,
								notAvailableBonuses: item.notAvailableBonuses * currentInDollar,
								totalBonuses: item.totalBonuses * currentInDollar,
								usedBonuses: item.usedBonuses * currentInDollar
							}
						} else if (item.currencyName === 'EUR') {
							const currentInEuro = get().currencys.euroPerRub
							return {
								...item,
								accruedBonuses: item.accruedBonuses * currentInEuro,
								availableBonuses: item.availableBonuses * currentInEuro,
								burnedBonuses: item.burnedBonuses * currentInEuro,
								notAvailableBonuses: item.notAvailableBonuses * currentInEuro,
								totalBonuses: item.totalBonuses * currentInEuro,
								usedBonuses: item.usedBonuses * currentInEuro
							}
						} else return item
					})
					const bonusesInRub = {
						accruedBonuses: sumBy(newItog, 'accruedBonuses'),
						availableBonuses: sumBy(newItog, 'availableBonuses'),
						burnedBonuses: sumBy(newItog, 'burnedBonuses'),
						notAvailableBonuses: sumBy(newItog, 'notAvailableBonuses'),
						totalBonuses: sumBy(newItog, 'totalBonuses'),
						usedBonuses: sumBy(newItog, 'usedBonuses')
					}
					set({ bonusesItog: bonusesInRub })
				}
				if (value === 'USD') {
					const newItog = itog.map(item => {
						if (item.currencyName === 'USD') return item
						else if (item.currencyName === 'RUB') {
							const currentInDollar = get().currencys.dollarPerRub
							return {
								...item,
								accruedBonuses: item.accruedBonuses / currentInDollar,
								availableBonuses: item.availableBonuses / currentInDollar,
								burnedBonuses: item.burnedBonuses / currentInDollar,
								notAvailableBonuses: item.notAvailableBonuses / currentInDollar,
								totalBonuses: item.totalBonuses / currentInDollar,
								usedBonuses: item.usedBonuses / currentInDollar
							}
						} else if (item.currencyName === 'EUR') {
							const currentInEuro = get().currencys.euroPerRub
							const currentInDollar = get().currencys.dollarPerRub
							return {
								...item,
								accruedBonuses:
									(item.accruedBonuses / currentInEuro) * currentInDollar,
								availableBonuses:
									(item.availableBonuses / currentInEuro) * currentInDollar,
								burnedBonuses:
									(item.burnedBonuses / currentInEuro) * currentInDollar,
								notAvailableBonuses:
									(item.notAvailableBonuses / currentInEuro) * currentInDollar,
								totalBonuses:
									(item.totalBonuses / currentInEuro) * currentInDollar,
								usedBonuses:
									(item.usedBonuses / currentInEuro) * currentInDollar
							}
						} else return item
					})
					const bonusesInDollar = {
						accruedBonuses: sumBy(newItog, 'accruedBonuses').toFixed(1),
						availableBonuses: sumBy(newItog, 'availableBonuses').toFixed(1),
						burnedBonuses: sumBy(newItog, 'burnedBonuses').toFixed(1),
						notAvailableBonuses: sumBy(newItog, 'notAvailableBonuses').toFixed(
							1
						),
						totalBonuses: sumBy(newItog, 'totalBonuses').toFixed(1),
						usedBonuses: sumBy(newItog, 'usedBonuses').toFixed(1)
					}
					set({ bonusesItog: bonusesInDollar })
				}
				if (value === 'EUR') {
					const newItog = itog.map(item => {
						if (item.currencyName === 'EUR') return item
						else if (item.currencyName === 'RUB') {
							const currentInEuro = get().currencys.euroPerRub
							return {
								...item,
								accruedBonuses: item.accruedBonuses / currentInEuro,
								availableBonuses: item.availableBonuses / currentInEuro,
								burnedBonuses: item.burnedBonuses / currentInEuro,
								notAvailableBonuses: item.notAvailableBonuses / currentInEuro,
								totalBonuses: item.totalBonuses / currentInEuro,
								usedBonuses: item.usedBonuses / currentInEuro
							}
						} else if (item.currencyName === 'USD') {
							const currentInEuro = get().currencys.euroPerRub
							const currentInDollar = get().currencys.dollarPerRub
							return {
								...item,
								accruedBonuses:
									(item.accruedBonuses * currentInDollar) / currentInEuro,
								availableBonuses:
									(item.availableBonuses * currentInDollar) / currentInEuro,
								burnedBonuses:
									(item.burnedBonuses * currentInDollar) / currentInEuro,
								notAvailableBonuses:
									(item.notAvailableBonuses * currentInDollar) / currentInEuro,
								totalBonuses:
									(item.totalBonuses * currentInDollar) / currentInEuro,
								usedBonuses:
									(item.usedBonuses * currentInDollar) / currentInEuro
							}
						} else return item
					})
					const bonusesInEuro = {
						accruedBonuses: sumBy(newItog, 'accruedBonuses').toFixed(1),
						availableBonuses: sumBy(newItog, 'availableBonuses').toFixed(1),
						burnedBonuses: sumBy(newItog, 'burnedBonuses').toFixed(1),
						notAvailableBonuses: sumBy(newItog, 'notAvailableBonuses').toFixed(
							1
						),
						totalBonuses: sumBy(newItog, 'totalBonuses').toFixed(1),
						usedBonuses: sumBy(newItog, 'usedBonuses').toFixed(1)
					}
					set({ bonusesItog: bonusesInEuro })
				}
				set({ currentCurrency: value })
			},
			getCurrencies: async () => {
				try {
					const currentDate = new Date()
					const startDate = new Date().setMonth(currentDate.getMonth() - 1)

					const dollarPerRub = await axios({
						url: `${BASE_URL}/currency/rates`,
						method: 'POST',
						data: {
							clientId: 'b2c:ru',
							currencyFrom: 1,
							currencyTo: 2,
							dateFrom: new Date(startDate),
							dateTo: currentDate
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					const euroPerRub = await axios({
						url: `${BASE_URL}/currency/rates`,
						method: 'POST',
						data: {
							clientId: 'b2c:ru',
							currencyFrom: 1,
							currencyTo: 3,
							dateFrom: new Date(startDate),
							dateTo: currentDate
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})

					set(() => ({
						currencys: {
							dollarPerRub: dollarPerRub.data.currencyRates.at(-1).rate,
							euroPerRub: euroPerRub.data.currencyRates.at(-1).rate
						}
					}))
				} catch (error) {
					console.log(error)
				}
			},
			getAuthSid: async (req, login, password) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/auth-info`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${req.access_token}`
						},
						data: {
							clientId: "b2c:ru",
							login: login,
							password: password
						},
					});

					localStorage.setItem('authSid', JSON.stringify(res.data.onlineSid))
				} catch (e) {
					console.error('Error in getAuthSid:', e);
				} finally {
				}
			},
		}
	}))
)

export default useAuthStore
