import { useState } from 'react'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { Select, MenuItem } from '@mui/material'

function SelectInput({ label, list, value, onChange, required, error }) {
	const [selectValue, changeValue] = useState(value ?? '')
	const handleChange = e => {
		onChange(e.target.value)
		changeValue(e.target.value)
	}
	return (
		<div className='visa-input'>
			<InputLabel id='demo-simple-select-label'>{label}</InputLabel>
			<FormControl fullWidth required={required}>
				<Select
					MenuProps={{
						PaperProps: {
							className: 'myCustomList-actions'
						},
						style: {
							maxHeight: '360px'
						}
					}}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					className='mui-date-input'
					value={selectValue}
					onChange={handleChange}
					error={error}>
					{list?.map(item => (
						<MenuItem key={item?.ebgNodeId || item?.id} value={item?.ebgNodeId || item?.id}>
							{item?.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	)
}

export default SelectInput
