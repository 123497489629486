import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './style.scss'
import { ReactComponent as CloseIcon } from '../../../../asset/images/avia/ui/close.svg'
import useYachtRentServicesStore from '../../store'
import quantityCaseFormat from '../../../../functions/quantityCaseFormat'

const TouristsDropDown = ({ adults, children, ...props }) => {
	const [showAddChildren, setShowAddChildren] = useState(false)

	const actions = useYachtRentServicesStore(({ actions }) => actions)

	const handleAddChildren = value => {
		actions?.childInc(value.split(' ')[0])
		setShowAddChildren(!showAddChildren)
	}

	const handleRemoveChildren = ind => {
		actions?.childDec(ind)
	}

	const arrayChildren = [
		'1 год',
		'2 года',
		'3 года',
		'4 года',
		'5 лет',
		'6 лет',
		'7 лет',
		'8 лет',
		'9 лет',
		'10 лет',
		'11 лет',
		'12 лет',
		'13 лет',
		'14 лет',
		'15 лет',
		'16 лет',
		'17 лет'
	]

	return (
		<div className='v-departure__body-container tourists'>
			<div className='tourists__body'>
				<div className={'adults'}>
					<div className={'button-block'}>
						<RemoveIcon
							className={`icon-button ${adults > 1 && 'active'}`}
							onClick={adults > 1 ? actions?.adultDec : null}
						/>
						<div className='button-text'>
							{`${adults} ${adults === 1 ? 'взрослый' : 'взрослых'}`}
							<br />
							<span>Старше 18 лет</span>
						</div>
						<AddIcon
							className={`icon-button ${
								adults + children.length < 15 && 'active'
							}`}
							onClick={adults + children.length < 15 ? actions?.adultInc : null}
						/>
					</div>
				</div>

				<div className={'children-block'}>
					{children.length > 0 && (
						<div className={'children-list'}>
							{children.map((el, index) => (
								<div key={index} className={'children-item'}>
									Ребенок, {el} {quantityCaseFormat(el, 'год', 'года', 'лет')}{' '}
									<CloseIcon onClick={() => handleRemoveChildren(index)} />
								</div>
							))}
						</div>
					)}
					{children.length + adults < 15 && (
						<div
							className={'button-addChildren'}
							onClick={() => setShowAddChildren(!showAddChildren)}>
							Добавить ребенка <ExpandMoreIcon />
						</div>
					)}
					{showAddChildren && (
						<div className={'add-children-list'}>
							{arrayChildren.map(el => (
								<div
									key={el}
									onClick={() => handleAddChildren(el)}
									className={'add-children-item'}>
									{el}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default TouristsDropDown
