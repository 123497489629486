import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NotFoundEx } from '../../../../../asset/icons/NotFoundEx.svg'
import { Box, Button } from '@mui/material'
import useExcursionStore from '../../../store'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 32px;
	gap: 20px;
	color: #2e2e32;
`

export const CancelButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	borderColor: '#4872f2',
	borderRadius: '8px',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: '16px',
	fontWeight: 600,
	height: '48px',
	lineHeight: '24px',
	padding: '12px 24px',
	textAlign: 'center',
	textDecoration: 'none',
	fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(',')
}))

export default function NotFindExcursion() {
	const actions = useExcursionStore(({ actions }) => actions)

	return (
		<Container>
			<Box>
				<NotFoundEx />
			</Box>
			<Box>
				<Box
					style={{
						fontWeight: 600,
						fontSize: '18px',
						lineHeight: '26px',
						textAlign: 'center',
						marginBottom: '4px'
					}}>
					Нет подходящих экскурсий
				</Box>
				<Box
					style={{
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						textAlign: 'center'
					}}>
					Попробуйте изменить параметры поиска
				</Box>
			</Box>
			<Box>
				<CancelButton
					variant='outlined'
					onClick={() => {
						actions.clearFilters()
					}}>
					Сбросить параметры
				</CancelButton>
			</Box>
		</Container>
	)
}
