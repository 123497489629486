import React from 'react';

const Logo = (props) => {
  return (
    <svg {...props} viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.564403 0.329295C0.486797 0.406705 0.425226 0.498664 0.383215 0.599906C0.341204 0.701148 0.31958 0.809683 0.31958 0.919295C0.31958 1.02891 0.341204 1.13744 0.383215 1.23868C0.425226 1.33993 0.486797 1.43189 0.564403 1.5093L7.3331 8.27632L0.564403 15.0433C0.407925 15.1998 0.320016 15.412 0.320016 15.6333C0.320016 15.8546 0.407925 16.0669 0.564403 16.2233C0.720881 16.3798 0.93311 16.4677 1.1544 16.4677C1.3757 16.4677 1.58793 16.3798 1.7444 16.2233L9.10143 8.86632C9.17904 8.78891 9.24061 8.69695 9.28262 8.5957C9.32463 8.49446 9.34625 8.38593 9.34625 8.27632C9.34625 8.1667 9.32463 8.05817 9.28262 7.95693C9.24061 7.85569 9.17904 7.76373 9.10143 7.68632L1.7444 0.329295C1.66699 0.25169 1.57503 0.190118 1.47379 0.148107C1.37255 0.106097 1.26402 0.0844726 1.1544 0.0844726C1.04479 0.0844726 0.936255 0.106097 0.835013 0.148107C0.733771 0.190118 0.641812 0.25169 0.564403 0.329295Z"
      />
    </svg>
  );
};

export default Logo;
