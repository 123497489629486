import React, {useState} from 'react';
import {ReactComponent as IconLoading} from '../../asset/icons/Mask.svg'
import CloseIcon from '@material-ui/icons/Close'

import './style.scss'

const AlertLoading = ({ title, isOpen } ) => {

	const [open, setOpen] = useState(isOpen)

	return (
		<>
			{open && <div className={'alert-loading'}>
				<div><IconLoading/></div>
				<div>
					{title && <div style={{fontSize: 16, fontWeight: 600, lineHeight: '22px'}}>{title}</div>}
					<div>Ожидайте загрузки</div>
				</div>
				<div><CloseIcon style={{cursor: 'pointer'}} onClick={() => setOpen(false)}/></div>
			</div>}
		</>

	);
};

export default AlertLoading;