import React from "react"
import { useSelector} from "react-redux"
import {
  getOverPaidsOrders,
} from "../../../store/modules/agents/agent-finance/selectors"
import styled from "styled-components"
import { TableUi } from "../TableUi"
import agentOverPaidListImage from "../../../asset/images/avia/agents/agentOverPaidListImage.svg"
import ArrowButton from "./ArrowButton"
import Collapse from '@mui/material/Collapse';
import ExtraPaidOrdersTable from './tables/ExtraPaidOrdersTable'
import quantityCaseFormat from '../../../functions/quantityCaseFormat'


const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 20px;
  margin-bottom: 25px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const OrderCounter = styled.div`
  color: ${({ isActive }) => (isActive ? "#4872F2" : "#737373")};

  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderCounterMobile = styled.div`
  color: ${({ isActive }) => (isActive ? "#4872F2" : "#737373")};

  @media screen and (min-width: 767px) {
    display: none;
  }
`

const AgentOverPaidListImage = styled.img.attrs({
  src: agentOverPaidListImage
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const AgentOverPaidOrders = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const data = useSelector(getOverPaidsOrders)

  let ordersQuantityStr = ''; 
  if(data?.length === 0 || data?.length === undefined) {
    ordersQuantityStr = '(Нет заявок)';
  } else {
    ordersQuantityStr = `(${data?.length} ${quantityCaseFormat(data?.length,'заявка','заявки','заявок')})`;
  }

  return (
    <TableUi.Container>
      <Row onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
        <FlexRow>
          <AgentOverPaidListImage /> <Title>Список заявок с переплатой </Title>{" "}
          <OrderCounter
            isActive={data?.length !== 0 && data?.length !== undefined}
          >
            {" "}
            &nbsp;
            {ordersQuantityStr}
          </OrderCounter>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Отслеживайте заявки с переплатой.
      </SubTitle>
      <OrderCounterMobile isActive={data?.length !== 0}>
        {" "}
        &nbsp;
        {data?.length === 0 || data?.length === undefined
          ? "(Нет заявок)"
          : "(" + data?.length + " заявок)"}
      </OrderCounterMobile>
      <Collapse in={isOpen}>
        <div >
          {" "}
          <Text style={{marginTop: 0}}>
            В данном блоке вам доступен список заявок c переплатой. При нажатии
            на номер вы сможете перейти к деталям заявки.
          </Text>
          {!data?.length || !data ? (
            <TableUi.SubTitle>Заявки не найдены</TableUi.SubTitle>
          ) : (
            <ExtraPaidOrdersTable items={data}/>
          )}
        </div>
      </Collapse>
    </TableUi.Container>
  )
}

export default AgentOverPaidOrders
