import { Button } from '@material-ui/core'
import React, { useMemo } from 'react'
import useTransferStore from '../../../store'
import { useErrorStore } from '../MembersBlock/store'

export default function ReserveBlock() {
	const actions = useTransferStore(({ actions }) => actions)
	const loading = useTransferStore(({ loading }) => loading)
	const loadingUpdateBasket = useTransferStore(({ loadingUpdateBasket }) => loadingUpdateBasket)
	const isReadyForBooking = useTransferStore(
		({ isReadyForBooking }) => isReadyForBooking
	)
	const errorForm = useErrorStore(state => state.error)
	const touristError = useErrorStore(state => state.touristError)
	const draft = useTransferStore(({ draft }) => draft)
	const travelers = useTransferStore(({ travelers }) => travelers)

	const childAgreement = useTransferStore(
		({ childAgreement }) => childAgreement
	)

	const isEmptyTravellers = useMemo(() => {
		return travelers
			?.map(item => ({
				birthday: item.birthDay,
				expirationDate: item.document.expirationDate,
				firstNameLatin: item.document.firstNameLatin,
				lastNameLatin: item.document.lastNameLatin,
				series: item.document.series,
				number: item.document.number,
				phones: item.phones,
				email: item.email
			}))
			.map(item => Object.values(item).some(item => !item))
			.some(item => item)
	}, [travelers])

	return (
		<Button
			variant='contained'
			onClick={() => actions.reserveDraft(draft.id)}
			disabled={
				!isReadyForBooking ||
				loading ||
				loadingUpdateBasket ||
				errorForm ||
				touristError ||
				isEmptyTravellers ||
				(!!draft.draftBody.transfers?.[0].passengers?.children &&
					!childAgreement)
			}>
			Бронировать
		</Button>
	)
}
