import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import useBookingStore from "../../store";
import { Button } from "@material-ui/core";
import "./style.css";
import ServiceModal from "./ServiceModal";
import { v4 as uuid } from 'uuid';

const ServicesBlock = ({ services, title, isExcursions = false, currentSelectedCurrency }) => {
	const [modalVisible, setModalVisible] = useState(false);
	// const {paxes, actions} = useBookingStore()
	const paxes = useBookingStore((state) => state.paxes);
	const [includeBaby, setIncludeBaby] = useState(paxes.some(pax => pax.person.ageGroup === 3))
	const currencys = useBookingStore(({ currencys }) => currencys)

	const actions = useBookingStore(({ actions }) => actions);

	const showModal = () => {
		setModalVisible(!modalVisible);
	};

	//ререндер компонента если добавлен младенец 
	useEffect(() => {
		setIncludeBaby(paxes.some(pax => pax.person.ageGroup === 3))
	}, [paxes])

	useEffect(() => {
		const unsubscribe = useBookingStore.subscribe(
			(state) => {
				setIncludeBaby(state.tour.paxes.some(pax => pax.person.ageGroup === 3))
			},
			(state) => console.log(state.tour.paxes)
		);

		return () => {
			unsubscribe();
		};
	}, [paxes]);

	const priceInCurrency = value => {
		if (currentSelectedCurrency === 1) {
			return value + ' RUB'
		} else {
			const currency = Object.values(currencys)
				.map(item => item[0])
				.find(el => el.currencyKey === currentSelectedCurrency);
			return currency ? `${(value / currency.rate).toFixed(0)} ${currency.currency}` : value + ' RUB'
		}
	}

	const renderButton = (id) => {
		const isIncluded = paxes.some((item) =>
			item.selectedServiceIds.includes(id)
		);
		if (isIncluded) {
			return (
				<Button
					onClick={() => actions.removeServeices(id)}
					variant="contained"
					color="secondary"
					fullWidth
				>
					Удалить
				</Button>
			);
		} else {
			return (
				<Button
					onClick={() => actions.addServeices(id)}
					variant="contained"
					color="primary"
					fullWidth
				>
					Добавить
				</Button>
			);
		}
	};

	const renderItem = (item, paxesCount, babyCount) => {
		return (
			<div key={item.id}>
				<div className="grid-insurances body">
					<div>{item.name}</div>
					<div>
						{format(parseISO(item.startDate), "dd.MM.yyyy")} -{" "}
						{format(parseISO(item.endDate), "dd.MM.yyyy")}
					</div>
					<div>
						{item.price.amount &&
							item.pricePerPerson
							? item.pricePerPerson.amount && `+${priceInCurrency(Number(paxesCount) * Number(item.pricePerPerson.amount))}`
							: item.price.amount && `+${priceInCurrency(item.price.amount)}`
						}
					</div>
					<div className="paxes">{paxesCount ?? paxes.length}{babyCount && "+INF"}</div>
					{!item.isRequired && <div>{renderButton(item.id)}</div>}
				</div>
				{item.note && (
					<div className="baggage-info">
						{item.note.split("\n").map((item) => (
							<div key={uuid()}>{item}</div>
						))}
					</div>
				)}
			</div>
		);
	}

	const renderContent = (content) => {
		const isSelected = paxes.some((pax) =>
			content.some(i => !!pax.selectedServiceIds.includes(i.id))
		);

		if (isSelected) {
			return (
				<>
					{content.map(item => {
						const isIncluded = paxes.some((pax) => pax.selectedServiceIds.includes(item.id));
						if (!isIncluded) {
							return null
						}

						const paxesLength = paxes.reduce((accumulator, pax) => {
							if (pax.selectedServiceIds.includes(item.id)) {
								if (includeBaby && pax.person.ageGroup === 3) {
									return accumulator
								}
								return accumulator + 1;
							} else {
								return accumulator;
							}
						}, 0);

						return renderItem(item, paxesLength, includeBaby)
					})}
				</>)
		} else {
			const firstItem = content.sort(
				(a, b) => new Date(a.startDate) - new Date(b.startDate)
			)[0];
			if (includeBaby) {
				return renderItem(firstItem, paxes.length - 1)
			}

			return renderItem(firstItem, paxes.length)
		}

	};

	const servicesSort = (a) => {
		if (a.type.id === 21 || a.type.id === 12) {
			return 1
		}
		return 0
	}
	

	return (
		<>
			<div className="block-header">
				<h3 className="title">{title}</h3>
			</div>
			<div className="block-body">
				<div className="grid-insurances header">
					<div>Название</div>
					<div>Даты</div>
					<div></div>
					<div className="paxes">Количество</div>
					<div></div>
				</div>
				{isExcursions
					? renderContent(services)
					: services.sort(servicesSort).map((item) => {
						const isIncluded = paxes.some((pax) => pax.selectedServiceIds.includes(item.id));
						const paxesLength = paxes.reduce((accumulator, pax) => {
							if (pax.selectedServiceIds.includes(item.id)) {
								if (includeBaby && pax.person.ageGroup === 3) {
									return accumulator
								}
								return accumulator + 1;
							} else {
								return accumulator;
							}
						}, 0);
						if (!item.isRequired && !isIncluded && !(item.type.id === 21 || item.type.id === 12)) {
							return null;
						}
						return renderItem(item, isIncluded ? paxesLength : paxes.length, includeBaby);
					})
				}
				<div className="button-change">
					{" "}
					<Button onClick={showModal} variant="contained" color="primary">
						Изменить услуги
					</Button>
				</div>
			</div>
			{modalVisible && (
				<ServiceModal
					grouped={isExcursions}
					services={services}
					onClose={showModal}
					title={title}
				/>
			)}
		</>
	);
};

export default ServicesBlock;
