import React, { useState } from "react"
import styled from "styled-components"
import {Button} from '../Button';
import {Textarea} from '../form'
import { useMediaQuery } from "react-responsive"
import paperAirplaneSrc from "./assets/media/paper-airplane.svg"
import clipSrc from "./assets/media/clip.png"
import clipMobileSrc from "./assets/media/clip-mobile.svg"
import { useSelector } from "react-redux"

const Wrapper = styled.div`
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto 34px;
    column-gap: 10px;
    align-items: center;
    & > :first-child {
      margin-bottom: 0;
      height: 40px;
      position: relative;
    }
  }
`
const SendBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  height: 40px;
`

const StyledTextarea = styled(Textarea)`
  width: 100%;
  resize: none;
  height: 90px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    height: auto;
  }
`

const Round = styled.button.attrs({ type: "submit" })`
  border: none;
  outline: none;
  background: linear-gradient(90deg, #5890f4 0%, #1753be 48.96%, #0e3b8a 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  & > img {
    pointer-events: none;
  }
`

const ClipButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border: none;
  outline: none;
  background: transparent;

  @media (max-width: 767px) {
    width: 22px;
    height: 22px;
    right: 8px;
    padding: 0;
  }
`

const FileButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > img {
    display: block;
    margin-right: 6px;
  }
`

const FileButtonText = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
`

const Input = ({ onSubmit, submit, disabled }) => {
  const [value, setValue] = useState("")
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const ref = React.useRef(null)
  const [files, setFiles] = React.useState([])

  const loading = useSelector(s => s.appeals.chatLoadings)

  const handleChange = e => {
    setValue(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (typeof onSubmit === "function") {
      onSubmit(value, files)
      setValue("")
      setFiles([])
    }
  }

  const handleFileButton = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        onChange={e => {
          const files = e.target.files || []
          if (files.length > 0) {
            setFiles(s => [...files])
          }
        }}
      />
      <Wrapper>
        {isMobile ? (
          <>
            <StyledTextarea
              placeholder="Cообщение"
              onChange={handleChange}
              value={value}
              name="message"
              rows={isMobile ? 1 : 5}
            >
              <ClipButton onClick={handleFileButton}>
                <img src={clipMobileSrc} alt="" />
              </ClipButton>
            </StyledTextarea>
            <Round disabled={disabled}>
              <img src={paperAirplaneSrc} alt="" />
            </Round>
          </>
        ) : (
          <>
            <StyledTextarea
              onChange={handleChange}
              value={value}
              name="message"
              rows={isMobile ? 1 : 5}
            />
            <SendBlock>
              <FileButton onClick={handleFileButton}>
                <img src={clipSrc} alt="" />
                <FileButtonText>
                  {files.length > 0 ? files[0].name : "Вложить файл"}
                </FileButtonText>
              </FileButton>
              <Button type="primary" htmlType="submit" disabled={disabled} isLoading={loading}>
                  {submit ? "Отправка" : "Отправить"}
              </Button>
            </SendBlock>
          </>
        )}
      </Wrapper>
    </form>
  )
}

export default Input
