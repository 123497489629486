import {useState, useEffect} from 'react';
import './style.css'
import {format} from "date-fns";
import moment from 'moment';
import useClaimsStore from "../../store";
import ArrowThink from "../../../../../asset/icons/Arrow-think";
import Messages from "./Messages";
import Order from "./Order";
import Passengers from "./Passengers";
import Appeals from "./Appeals";
import axios from "axios";
import {useDispatch} from "react-redux";
import {getOrderByNumber} from "../../../../../store/modules/orders";
import CircularProgress from "@mui/material/CircularProgress";
import formatDuration from "../../../../../functions/formatDuration";
import formatPrice from "../../../../../functions/formatPrice";
import {Button} from "@material-ui/core";
import Collapse from '@mui/material/Collapse';
import { useInView } from 'react-intersection-observer';
import DocumentSelector from "./DocumentSelector";
import dateToLocal from "../../../../../functions/dateToLocal";


export default function AviaOrder({order, onOpen, isOpen}) {

  const actions = useClaimsStore(({ actions }) => actions)
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSet, setIsSet] = useState(false)
  const [canceling, setCanceling] = useState(false)
  const [orderStatus, setOrderStatus] = useState(order.orderStatus.description)
  const [showPaymentTime, setShowPaymentTime] = useState(false)
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    setOrderStatus(details?.orderStatus?.description)
    setIsSet(!!details?.orderNumber)
  }, [details])

  const isCancelAvailable = details?.paymentStatus?.code === 'NotPaid' && details?.orderStatus?.code === 'Active'  //availableActions.includes('CANCEL')
  const localCancellationTime = dateToLocal(details?.cancellationTime)
  const duration = localCancellationTime.diff(moment(), "minutes")
  const agentPrice = details?.orderAmount?.detalization?.filter(el => el.priceType === 16)[0]?.value || 0

  const redirectToDetails = (e) => {
    e.stopPropagation()
    window.open(`/cabinet/orders/${order.orderNumber}`)
  }

  const handleGetOrder = async (id) => {
    if (!isOpen) {
      setLoading(true)
      if (!isSet) {
        setDetails(await actions.getOrderByNumber(id))
        dispatch(getOrderByNumber(id))
      }
      setLoading(false)
    }

    onOpen(id)
    setShowPaymentTime(!['Paid', 'PaidByCredit', 'WontPaid'].includes(order.paymentStatus.code))
  }

  const handleChange = (orderData) => {
    setDetails(orderData)
  }

  const cancelOrder = async () => {
    setCanceling(true)
    const tte_token = JSON.parse(window.localStorage.getItem('tte_token'))?.accessToken
    const payload = {
      'orderId': details.id,
      'orderPositionId': details.orderPositions[0].id,
    }
    await axios({
      url: process.env.REACT_APP_CANCEL_ORDER_POSITION,
      method: 'DELETE',
      data: payload,
      headers: {
        Authorization: `Bearer ${tte_token}`
      }
    })
    setDetails(await actions.getOrderByNumber(order.orderNumber))
    setCanceling(false)
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Активна':
        return 'active'
      case 'Не активна':
        return 'inactive'
      default:
        return ''
    }
  }

  return (
    <div ref={ref}>
      <div className={`avia-order ${isOpen ? 'opened' : ''}`} onClick={() => handleGetOrder(order.orderNumber)}>
        <div className={'avia-order-img'}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#4872F2"/>
            <path d="M11.8763 17.1751L10.4572 23.7362C10.4188 23.9135 10.4628 24.0986 10.5767 24.2398C10.6906 24.3809 10.8622 24.463 11.0436 24.463H13.014C13.2256 24.463 13.4215 24.3516 13.5297 24.1697L17.6901 17.1751H22.2748C23.4763 17.1751 24.4511 16.2013 24.4511 14.9994C24.4511 13.7995 23.4756 12.8236 22.2748 12.8236H17.6901L13.5297 5.82902C13.4215 5.64718 13.2256 5.53574 13.014 5.53574H11.0436C10.8622 5.53574 10.6906 5.61781 10.5767 5.75898C10.4628 5.90016 10.4188 6.08529 10.4572 6.26258L11.8763 12.8236H9.20658L6.3779 11.5013C6.17307 11.4055 5.9318 11.4331 5.75381 11.5725C5.57581 11.7119 5.49131 11.9396 5.53523 12.1614L6.09716 14.9994L5.53523 17.8374C5.49131 18.0592 5.57581 18.2868 5.75381 18.4263C5.9318 18.5657 6.17307 18.5932 6.3779 18.4975L9.20658 17.1751H11.8763Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className={'avia-order-img-text'}>Авиабилеты</p>
        </div>
        <div className={'avia-order-id'}>№ заявки:{' '}{order.orderNumber}</div>
        <div className={'avia-order-date'}>{format(new Date(order.createdDate), 'dd.MM.yyyy')}</div>
        <div className={`avia-order-status ${getStatusColor(orderStatus || order.orderStatus.description)}`}>{orderStatus || order.orderStatus.description}</div>
        <Button className={'open-btn'} variant={'outlined'} color={'primary'} onClick={redirectToDetails}>Открыть</Button>
        <div className={`avia-order-icon ${isOpen ? 'open' : ''}`}>
          {loading ? <CircularProgress size={18} /> : <ArrowThink htmlColor={isOpen ? '#3c3c3c': '#737373'} />}
        </div>
      </div>
      { (inView || isOpen)  && (
        <Collapse in={isOpen}>
          <div className={'avia-order-block'}>
            <div className={'avia-order-description'}>
              <div className={'left-block'}>
                <p>Маршрут: <span>{order?.orderPositions[0]?.segmentGroupInfos[0].departureCity.name} - {order.orderPositions[0]?.segmentGroupInfos[0].arrivalCity.name}</span>
                </p>
                <p>Статус оплаты: <span>{order?.paymentStatus.description}</span></p>
                {showPaymentTime && <p>Время для оплаты: <span>{formatDuration(duration) || 'просрочено'}</span></p>}
                <p>№ для оплаты: <span
                  style={{color: details?.backOfficeId ? '' : '#FF0000'}}>{details?.backOfficeId || 'Не сформирован'}</span>
                </p>
              </div>
              <div className={'right-block'}>
                <p className={'block-info'}>
                  <p>Менеджер: <span>{order?.customer.email}</span></p>
                  <p>Стоимость: <span>{formatPrice(details?.amount)}</span></p>
                  <p>Стоимость для агента: <span>{formatPrice(agentPrice)}</span></p>
                </p>
                {/*<div className={`block-btns ${!isCancelAvailable ? 'flex-end' : ''}`}>*/}
                {/*  {<Button className={'open'} variant={'containedPrimary'} color={'primary'}*/}
                {/*          onClick={redirectToDetails}>Открыть</Button>}*/}
                {/*  {isCancelAvailable &&*/}
                {/*  <Button variant={'outlined'} color={'primary'} onClick={cancelOrder}>*/}
                {/*    Отменить заявку*/}
                {/*    {canceling ? <CircularProgress size={24} className={'position_absolute'} /> : false}*/}
                {/*  </Button>}*/}
                {/*</div>*/}
              </div>
            </div>

            <div className={`block-btns ${!isCancelAvailable ? 'flex-end' : ''}`}>
              {isCancelAvailable &&
                <Button variant={'outlined'} color={'primary'} onClick={cancelOrder}>
                  Отменить заявку
                  {canceling ? <CircularProgress size={24} className={'position_absolute'}/> : false}
                </Button>}
              <DocumentSelector variant={'outlined'} details={details} />
            </div>

            <Messages details={details} onChange={handleChange}/>
            <Order details={details}/>
            <Passengers details={details}/>
            <Appeals details={details} onChange={handleChange}/>
          </div>
        </Collapse>
      )}
    </div>
  );
}
