import styled from 'styled-components';

const LegendColor = styled.div`
    ${({ color = 'fff', border }) => {
        return `
                background: ${color};
                ${border ? "border: 1px solid rgba(219, 224, 229, 1) ;" : ""}
            `
    }}

    width: 24px;
    height: 24px;
    border-radius:4px;
`

const LegendItem = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
`

const LegendContainer = styled.div`
    padding: 16px;
    border: 1px solid rgba(219, 224, 229, 1);
    border-radius: 8px;
    display: flex;
    gap: 12px;
    margin-top: 24px;
    flex-wrap: wrap;
`

function Legend() {
    return <LegendContainer>
        <LegendItem><LegendColor border /> Проход</LegendItem>
        <LegendItem><LegendColor color={"lightgreen"} /> Свободно</LegendItem>
        <LegendItem><LegendColor color={'#a9a9a9'} /> Занято</LegendItem>
        <LegendItem><LegendColor color={"#bf0000"} /> Выбрано</LegendItem>
        <LegendItem><LegendColor color={'rgba(144, 238, 144, 0.38)'} /> Места другого класса </LegendItem>
        <LegendItem> <LegendColor color={"#52b567"} /> Свободно с ограничениями </LegendItem>
    </LegendContainer>
}

export default Legend