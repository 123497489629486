import { all, takeLatest, call, put, select } from "redux-saga/effects"
import * as AgentsManager from "./manager"
import {
  getAgentUserRolesRequest,
  getAgentUserRolesSuccess,
  getAgentUsersRequest,
  getAgentUsersSuccess,
  addAgentUsersRequest,
  addAgentUsersSuccess,
  setRoleToUserRequest,
  setRoleToUserSuccess,
  setAgentUsersRequest,
  setAgentUsersSuccess,
  createUserInSamoRequest,
  getAgentLogRequest,
  getAgentLogSuccess,
  getUsefulInfoRequest
} from "./duck"
import { getAgentSiginState } from "../agent-finance/selectors"

function* getAgentUserRolesSaga() {
  try {
    const result = yield call(AgentsManager.getAgentUserRoles)
    yield put(getAgentUserRolesSuccess(result))
  } catch (err) {
    // console.log(err);
  }
}

function* getAgentUsersSaga(action) {
  const { payload } = action

  try {
    const result = yield call(AgentsManager.getAgentUsers, payload.AgentId)
    yield put(getAgentUsersSuccess(result))
  } catch (err) {
    // console.log(err);
  }
}

function* addAgentUsersSaga(action) {
  try {
    const result = yield call(AgentsManager.addAgentUser, action.payload)
    yield put(addAgentUsersSuccess(result))
  } catch (err) {
    //
  }
}

function* createUserInSamoSaga(action) {
  const { payload } = action
  try {
    yield call(AgentsManager.createUserInSamo, payload)
  } catch (err) {
    //
  }
}

function* setRoleToUserSaga(action) {
  const { payload } = action
  try {
    yield call(AgentsManager.setRoleToUser, payload)
    yield put(setRoleToUserSuccess(true))
  } catch (err) {
    //
  }
}

function* setAgentUsersSaga(action) {
  const { payload } = action
  const agent = yield select(getAgentSiginState)

  try {
    const result = yield call(AgentsManager.setAgentUsers, payload)
    yield put(setAgentUsersSuccess(result))
    const update = yield call(AgentsManager.getAgentUsers, agent?.agentId)
    yield put(getAgentUsersSuccess(update))
  } catch (err) {
    //
  }
}

export function* getAgentLogSaga(action) {
  try {
    const response = yield call(
      AgentsManager.getAgentLog,
      action.payload.AgentId
    )
    yield put(getAgentLogSuccess(response))
  } catch (err) {
    //
  }
}

function* getUsefulInfoSaga() {
  try {
    yield call(AgentsManager.getUsefulInfo)
  } catch (err) {
    //
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getAgentUserRolesRequest.getType(), getAgentUserRolesSaga),
    takeLatest(getAgentUsersRequest.getType(), getAgentUsersSaga),
    takeLatest(addAgentUsersRequest.getType(), addAgentUsersSaga),
    takeLatest(createUserInSamoRequest.getType(), createUserInSamoSaga),
    takeLatest(setRoleToUserRequest.getType(), setRoleToUserSaga),
    takeLatest(setAgentUsersRequest.getType(), setAgentUsersSaga),
    takeLatest(getAgentLogRequest.getType(), getAgentLogSaga),
    takeLatest(getUsefulInfoRequest.getType(), getUsefulInfoSaga)
  ])
}
