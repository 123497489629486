import Api from '../../../functions/fetchApi';

export const getAppealSubjects = () =>
  Api.get(process.env.REACT_APP_GET_APPEAL_SUBJECTS)
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const getAppealStatuses = () =>
  Api.get(process.env.REACT_APP_GET_APPEAL_STATUSES)
  .setContentType('application/json')
  .addAuthGuard()
  .send()

export const getPaymentStatuses = () =>
  Api.get(process.env.REACT_APP_GET_PAYMENT_STATUSES)
  .setContentType('application/json')
  .addAuthGuard()
  .send()
