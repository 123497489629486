import * as yup from "yup"

export const feesSchema = yup.object().shape({
  ReturnGuaranteeService: yup.number().required("Необходимо заполнить поле"),
  SmsService: yup
    .number()
    .required("Необходимо заполнить поле")
    .nullable(),
  PaymentReceipt: yup
    .number()
    .required("Необходимо заполнить поле")
    .nullable(),
  AviaAdditionalService1: yup
    .number()
    .required("Необходимо заполнить поле")
    .nullable(),
  AviaAdditionalService2: yup
    .number()
    .required("Необходимо заполнить поле")
    .nullable(),
  AviaAdditionalService3: yup
    .number()
    .required("Необходимо заполнить поле")
    .nullable()
})

export const FEES_TABLES_COLUMNS = [
  {
    title: "Действия",
    dataIndex: "id"
  },
  { title: "Дата создания", dataIndex: "date" },
  {
    title: "Сбор",
    dataIndex: "fees"
  },
  { title: "Ответственный", dataIndex: "user" },

  { title: "Параметры правила", dataIndex: "parameters" }
]
