import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ background }) => background};
  border-radius: 8px;
  padding: 6px 8px;
  cursor: pointer;

  color: #000000;
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;

  & > svg {
    margin-right: 3px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    padding: 5px 6px;
  }
`;





export default function FlightTypeIcon({
  flightType,
  className,
}) {
  let title;
  let background;
  switch (flightType) {
    case 'Lowcoster':
      title = 'Лоукостер';
      background = '#EDFAF9'
      break;
    case 'Charter':
      title = 'Чартер';
      background = '#FFF3CD'
      break;
    default:
      title = 'Регулярный';
      background = '#E3EAFF'
      break;
  }

      return (
    <Wrapper
      className={className}
      background={background}
    >
      <span>{title}</span>
    </Wrapper>
  );
}
