import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/Close'
import useBookingStore from '../../../../store'
import FaresTabs from './FaresTabs'
import './style.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		paddingLeft: '32px'
	},
	'& .MuiDialogTitle-root': {
		padding: '0px 0px 0px 16px'
	},
	'& .MuiButton-containedPrimary': {
		color: '#fff',
		backgroundColor: '#3f51b5'
	}
}))

export default function FaresPopup({ open, handleClose }) {
	const [fareId, setFareId] = React.useState('')
	const [requestId, setRequestId] = React.useState('')
	const [price, setPrice] = React.useState(0)
	const [baggage, setBaggage] = React.useState([])
	const faresError = useBookingStore(({ faresError }) => faresError)

	const actions = useBookingStore(({ actions }) => actions)
	const handleSetFare = async () => {
		await actions.setPacketFare(fareId, requestId, price, baggage)
		handleClose()
	}
	// console.log("faresError", faresError);
	return (
		<BootstrapDialog
			style={{ whiteSpace: 'pre-wrap' }}
			maxWidth={'md'}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			open={open}>
			{faresError ? (
				<DialogContent className='title-container'>
					<DialogTitle id='alert-dialog-title'>
						{'Тарифы не найдены'}
					</DialogTitle>
					<IconButton onClick={handleClose}>
						<CloseOutlinedIcon />
					</IconButton>
				</DialogContent>
			) : (
				<>
					<DialogContent className='title-container'>
						<DialogTitle id='alert-dialog-title'>
							{'Заменить тариф'}
						</DialogTitle>
						<IconButton onClick={handleClose}>
							<CloseOutlinedIcon />
						</IconButton>
					</DialogContent>
					<DialogContent dividers style={{ maxHeight: '390px' }}>
						<FaresTabs
							setFareId={setFareId}
							setRequestId={setRequestId}
							setPrice={setPrice}
							setBaggage={setBaggage}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							onClick={handleSetFare}
							variant='contained'
							color='primary'>
							Заменить тариф
						</Button>
					</DialogActions>
				</>
			)}
		</BootstrapDialog>
	)
}
