import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../services/api'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useActionsStore = create(
	devtools((set, get) => ({
		geo: null,
		action: null,
		loading: false,
		countries: null,
		allRegions: [],
		actions: {
			getCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=1&ClientId=b2c:ru`,
						method: 'GET',
						params: {
							LoadCities: false
						}
					})
					// console.log(res.data, 'COUNTRIES')
					set({ countries: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getRegions: async country => {
				const countryId = get().countries?.find(
					item => item.name === country
				)?.id
				// console.log(countryId, 'ID')
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetFilters
						?DepartureCityId=1
						&ArrivalCountryId=${countryId}`,
						method: 'GET'
					})
					if (countryId) {
						set({
							allRegions: [
								...new Set(res.data.availableTowns.map(item => item.region))
							]
						})
					}
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			// getActionsGeo: async () => {
			// 	const data = {
			// 		isAgentContent: true,
			// 		isClientContent: false,
			// 		Continent: 1,
			// 		Country: 1,
			// 		Resort: 1,
			// 		Hotel: 1,
			// 	}
			// 	const res = await geoApiInstance().post('/actions/geo', data)
			// 	console.log(res.data, 'RES')
			// 	set({ geo: res.data })
			// },
			getActions: async () => {
				const data = {
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance().post('/actions/list', data)
				set({ action: res.data.filter(item => !item.is_deleted) })
			}
			// getSlugActions: async () => {
			// 	const data = {
			// 		isAgentContent: true,
			// 		isClientContent: false,
			// 		slug: 'pokupai-so-sberom-action'
			// 	}
			// 	const res = await geoApiInstance().post('/actions/actions', data)
			// 	console.log('slug', res.data)
			// 	set({ slugActions: res.data })
			// }
		}
	}))
)

export default useActionsStore
