import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import { ReactComponent as ArrowIcon } from '../../asset/icons/footer/arrow-ios-down.svg'

const Item = ({ item, onClose, setOpenDrawer }) => (
	<>
		{item.is_active && (
			<div
				key={item.id}
				className='list-footer-container'
				onClick={() => {
					if (!!setOpenDrawer) {
						onClose()
						setOpenDrawer(false)
					}
				}}>
				<Link
					className='list-footer-link'
					to={`${item.url}`}
					target={item.target}>
					{item.title}
				</Link>
			</div>
		)}
	</>
)

export default function MobileList({ item, setOpenDrawer, onClose }) {
	const [open, setOpen] = useState(false)
	const openHandler = () => {
		setOpen(prev => !prev)
	}

	return (
		<>
			<div
				onClick={openHandler}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
				<p className='menu__title-mobile'>{item?.title}</p>
				<ArrowIcon />
			</div>
			{open &&
				item?.childs
					.filter(
						elem =>
							elem?.user_roles?.includes('agent') || elem.user_roles === null
					)
					.map(item => {
						return (
							<Item
								item={item}
								key={item.id}
								onClose={onClose}
								setOpenDrawer={setOpenDrawer}
							/>
						)
					})}
		</>
	)
}
