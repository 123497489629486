import React from "react"
import styled from "styled-components"
import Text from '../../ui/Typography';
import formatPrice from "../../../functions/formatPrice"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const RowText = styled(Text)`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7E8389;

  & > span {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #2E2E32;

    ${(props) => props.blue ? 'color: #4872F2; font-weight: 600;' : ''}
  }
`

const RowTextContainer = styled.div`
  ${RowText} {
    margin-bottom: 8px;
    display: block;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  margin-bottom: 16px;
`;


const TaxRowHead = styled.div`
  display: flex;
  justify-content: space-between;
  & > span {
    color: #2E2E32;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const TaxRow = styled.div`
  display: flex;
  justify-content: space-between;
  

  & > span {
    color:  #2E2E32;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`


const Taxes = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 15px;
  background: #edf1fe;
  border-radius: 6px;

  > :last-child {
    margin-bottom: 0px;
    margin-top: 8px;
  }

  & > div {
    margin-bottom: 8px;
  }
`



const Separator = styled.div`
  margin: 24px 0 26px 0;
  width: 100%;
  height: 1px;
  background: #DBE0E5;

  &.small {
    margin: 8px 0px 8px 0px;
  }
`;

const TableByTickets = (props) => {
  return (
    <Container>
      <Separator />
      <RowTextContainer>
        {props.rows.map((x,key) => (<RowText>
          {x.title}: <span>{x.value}</span>
        </RowText>))}
      </RowTextContainer>
      <Taxes>
        <TaxRowHead>
          <span>Наименование</span>
          <span>Цена</span>
        </TaxRowHead>
        {props.taxRows.map((x, i) => {
          return (
            !!x?.label && (
              <TaxRow key={i}>
                <span>{x.label}</span>
                <span>{formatPrice(x.cost)}</span>
              </TaxRow>
            )
          )
        })}
        {!!props.total && ( <>
        <Separator className="small"/>
        <TaxRowHead>
          <span>Итого:</span>
          <span> {formatPrice(props.total)}</span>
        </TaxRowHead>
      </>)}
      </Taxes>
      {props.showFinalPrice && <RowText blue>{props.finalPrice?.title}: <span>{props.finalPrice?.value}</span></RowText>}
    </Container>
  )
}

export default TableByTickets

