import React, { useEffect, useState } from 'react'
import Logo from '../../asset/images/Fun-Sun-logo.png'
// import FUNNY from '../../asset/images/FUNNY.png'
import './style.scss'
import { Link, useLocation } from 'react-router-dom'
import AccountAuth from './AccountAuth'
import { Button, useMediaQuery } from '@material-ui/core'
import useSearchTourStore from '../../pages/SearchTour/store'
import { encodeQueryString } from '../../functions/sequelize'
import useAuthStore from '../../pages/Auth/store'
// import axios from 'axios'
// import FeedBack from '../FeedBack'
import CompanyPopup from '../CompanyPopup'
import {agency, claims, claimsNew, info, reports, search} from './samoMenu'
import MenuIcon from '@material-ui/icons/Menu'
// import MainMenu from '../MainMenu'
import BurgerMenu from '../BurgerMenu'
import { addDays, format } from 'date-fns'
import CurrencyChart from '../СurrencyСhart'

const Nav = ({ showBtn = false }) => {
	const location = useLocation()
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const arrivalCityIds = useSearchTourStore(
		({ arrivalCityIds }) => arrivalCityIds
	)
	const tours = useSearchTourStore(({ tours }) => tours)

	const actions = useAuthStore(({ actions }) => actions)
	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)
	const agent = useAuthStore(({ agent }) => agent)

	// const [isOpen, setIsOpen] = useState(false)

	const { getCurrencies } = useSearchTourStore(state => state.actions)
	const { euro, dollar } = useSearchTourStore(state => state.currencys)
	const [showCurrencyEur, setCurrencyEur] = useState(false)
	const [showCurrencyUsd, setCurrencyUsd] = useState(false)

	const franch = agentInfo?.agents?.name.includes('F&S') || agentInfo?.partPass === '91554' || agentInfo?.partPass === '121374'

	const getCourse = currency => {
		if (!currency) {
			return
		}
		const yesTodayCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(addDays(new Date(), -1), 'dd.MM.yyyy')
		)
		const todayCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(new Date(), 'dd.MM.yyyy')
		)
		const tomorrowCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(addDays(new Date(), 1), 'dd.MM.yyyy')
		)

		return {
			yesTodayCourse,
			todayCourse,
			tomorrowCourse
		}
	}
	const dollars = getCourse(dollar)
	const euros = getCourse(euro)

	useEffect(() => {
		getCurrencies()
	}, [getCurrencies])

	const goToOldVersion = () => {
		// window.open('https://b2b.fstravel.com/search_tour', '_lank')
		const url = 'https://b2b.fstravel.com'
		const { pathname, search } = location
		const paramsHotels = {
			STATEINC: searchParams.arrivalCountryId,
			CHECKIN_BEG: searchParams.minStartDate.replaceAll('-', ''),
			CHECKIN_END: searchParams.maxStartDate.replaceAll('-', ''),
			NIGHTS_FROM: searchParams.minNightsCount,
			NIGHTS_TILL: searchParams.maxNightsCount,
			ADULT: searchParams.adults,
			CHILD: searchParams.children.length,
			AGES: searchParams.children.join(),
			MEALS: searchParams.meals ? searchParams.meals.join() : '',
			TOURTYPE: searchParams.tourType,
			TOURINC: searchParams.tours ? searchParams.tours.join() : '',
			PROGRAMINC: searchParams.program,
			STARS: searchParams.stars ? searchParams.stars.join() : '',
			TOWNS: arrivalCityIds.join(),
			MOMENT_CONFIRM: searchParams.momentConfirm ? 1 : 0,
			WITHOUT_PROMO: searchParams.withoutPromo ? 1 : 0,
			HOTELS: searchParams.hotelIds.join(),
			DOLOAD: 1
		}
		const paramsTours = {
			...paramsHotels,
			TOWNFROMINC: searchParams.departureCityId
		}

		switch (pathname) {
			case '/search_tour':
				actions
					.getSamoLink(
						`${url}${pathname}?${tours ? encodeQueryString(paramsTours) : ''}`
					)
					.then(res => {
						window.open(res)
					})
				break
			case '/search_hotel':
				actions
					.getSamoLink(
						`${url}${pathname}?${tours ? encodeQueryString(paramsHotels) : ''}`
					)
					.then(res => {
						window.open(res)
					})
				break
			case '/booking':
				window.open(
					`${url}/bron${search.replace('priceId', 'CATCLAIM')}`,
					'_blank'
				)
				break
			case '/bookingnew':
				window.open(
					`${url}/bron${search.replace('priceId', 'CATCLAIM')}`,
					'_blank'
				)
				break
			default:
				actions.getSamoLink(url).then(res => {
					window.open(res)
				})
		}
	}

	const refreshLink = (id, url) => {
		actions.refreshSamoLink(id, url)
	}

	useEffect(() => {
		if (agent) {
			claims?.forEach(item => {
				actions.refreshSamoLink(item.id, item.url)
			})
			agency?.forEach(item => {
				actions.refreshSamoLink(item.id, item.url)
			})
			reports?.forEach(item => {
				actions.refreshSamoLink(item.id, item.url)
			})
			info?.forEach(item => {
				actions.refreshSamoLink(item.id, item.url)
			})
			search?.forEach(item => {
				if (item.id) {
					actions.refreshSamoLink(item.id, item.url)
				}
			})
		}

		// eslint-disable-next-line
	}, [agent])

	// const handleClose = () => {
	// 	setIsOpen(false)
	// }

	const [open, setOpen] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setOpen(previousOpen => !previousOpen)
	}

	const [openDrawer, setOpenDrawer] = React.useState(false)

	const isSmallScreen = useMediaQuery('(min-width:993px)')

	const toggleDrawer = open => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setOpenDrawer(open)
	}

	return (
		<>
			<div id='header'>
				<div className='bottom-menu'>
					<div className='menu'>
						<div style={{ display: 'flex' }}>
							<div className='logo'>
								<Link to={'/'}>
									<img src={Logo} className='logo' alt='' />
								</Link>
							</div>
							{isSmallScreen && (
								<nav className='nav'>
									<ul className='nav-center'>
										<li
											className={
												location.pathname.replace('/', '') === 'search_tour' ||
													location.pathname.replace('/', '') === 'search_hotel'
													? 'selected'
													: ''
											}>
											<span className='menu-caret'>Поиск</span>
											<ul>
												{search.map(item => (
													<li
														key={item.url}
														className={`menu-search_tour ${location.pathname === item.url ? 'selected' : ''
															} oc5`}>
														<Link
															to={samoLinks[item.id] || item.url}
															target={item.target}
															rel={'noreferrer'}
															onClick={() =>
																item.id && refreshLink(item.id, item.url)
															}>
															{item.title}
														</Link>
													</li>
												))}
											</ul>
										</li>
										<li className=''>
											<span className='menu-caret'>Заявки</span>
											<ul>
												{!franch && claims.map(item => (
													<li
														key={item.url}
														className='auth menu-cl_refer oc16'>
														<a
															href={samoLinks[item.id] || item.url}
															target={'_blank'}
															rel={'noreferrer'}
															onClick={() => refreshLink(item.id, item.url)}>
															{item.title}
														</a>
													</li>
												))}
												{franch && claimsNew.map(item => (
													<li
														key={item.url}
														className='auth menu-cl_refer oc16'>
														<a
															href={item.url}
															rel={'noreferrer'}
														>
															{item.title}
														</a>
													</li>
												))}
												<li className='auth menu-cl_refer oc16'>
													<a href={'/cabinet/orders'} rel={'noreferrer'}>
														Транспортный движок
													</a>
												</li>
											</ul>
										</li>
										<li className=''>
											<span className='menu-caret'>Агентство</span>
											<ul>
												{agency.map(item => (
													<li
														key={item.url}
														className='auth menu-cl_refer oc16'>
														<a
															href={samoLinks[item.id] || item.url}
															target={'_blank'}
															rel={'noreferrer'}
															onClick={() => refreshLink(item.id, item.url)}>
															{item.title}
														</a>
													</li>
												))}
											</ul>
										</li>
										<li className=''>
											<span className='menu-caret'>Отчёты</span>
											<ul>
												{reports.map(item => (
													<li
														key={item.url}
														className='auth menu-cl_refer oc16'>
														<a
															href={samoLinks[item.id] || item.url}
															target={'_blank'}
															rel={'noreferrer'}
															onClick={() => refreshLink(item.id, item.url)}>
															{item.title}
														</a>
													</li>
												))}
											</ul>
										</li>
										<li className=''>
											<span className='menu-caret'>Информация</span>
											<ul>
												{info.map(item => (
													<li
														key={item.url}
														className='auth menu-cl_refer oc16'>
														<a
															href={samoLinks[item.id] || item.url}
															target={'_blank'}
															rel={'noreferrer'}
															onClick={() => refreshLink(item.id, item.url)}>
															{item.title}
														</a>
													</li>
												))}
											</ul>
										</li>
										{/*<li onClick={handleClick}>*/}
										{/*	<span>О компании</span>*/}
										{/*</li>*/}
										{agentInfo && agentInfo.agents?.name.includes('F&S') && (
											<li
												className={
													location.pathname.replace('/', '') === 'loyalty'
														? 'selected'
														: ''
												}>
												<Link to='/loyalty' className='menu-caret'>
													Программа лояльности
												</Link>
											</li>
										)}
										<li className=''>
											<span className='menu-caret'>Документы на подпись</span>
											<ul>
												<li className='menu-hotel_stopsale oc9'>
													<Link to='esrk/upload'>Документы на подпись</Link>
												</li>
												<li className='menu-freight_monitor oc10'>
													<Link to='/esrk/report'>Отчет</Link>
												</li>
											</ul>
										</li>
										<li className=''>
											<span className='menu-caret empty'>
												<div
													className='menu-rate'
													onClick={() => setCurrencyUsd(true)}>
													$ {dollars?.todayCourse?.rate}
												</div>{' '}
												/{' '}
												<div
													className='menu-rate'
													onClick={() => setCurrencyEur(true)}>
													{' '}
													€ {euros?.todayCourse?.rate}
												</div>
											</span>
										</li>
									</ul>
								</nav>
							)}
						</div>
						{agentInfo && isSmallScreen && (
							<AccountAuth
								openCabinet={() =>
									refreshLink('cl_refer', 'https://b2b.fstravel.com/cl_refer')
								}
							/>
						)}
						{!isSmallScreen && <MenuIcon onClick={toggleDrawer(true)} />}
					</div>
				</div>
			</div>
			<CompanyPopup
				open={open}
				anchorEl={anchorEl}
				handleClick={handleClick}
				setOpen={setOpen}
			/>
			{/*<MainMenu />*/}
			<div className={'old-version'}>
				{showBtn && (
					<Button variant={'contained'} onClick={goToOldVersion}>
						Вернуться на старую версию
					</Button>
				)}
				{/*<Button variant={'contained'} onClick={() => setIsOpen(true)}>*/}
				{/*	Оставить отзыв о работе сайта*/}
				{/*</Button>*/}
			</div>
			{/*{isOpen && <FeedBack sendMessage={sendMessage} onClose={handleClose} />}*/}
			<BurgerMenu openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
			{showCurrencyUsd && dollar && (
				<CurrencyChart
					targetCurrency={dollar[0].currencyBase}
					name={'USD'}
					data={dollar}
					onClose={setCurrencyUsd}
				/>
			)}
			{showCurrencyEur && euro && (
				<CurrencyChart
					targetCurrency={euro[0].currencyBase}
					name={'EUR'}
					data={euro}
					onClose={setCurrencyEur}
				/>
			)}
		</>
	)
}

export default Nav
