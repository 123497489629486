import React, {useEffect} from 'react';

import './style.scss'
import CountryField from "./CountryField";
import {Button} from "@material-ui/core";
import useTransferStore from "../../store";
// import useGetTransferRegions from "../../../../../hooks/useGetTransferRegions";
import TouristsField from "./TouristsField";
import {isEmpty} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {decodeQueryString} from "../../../../../functions/sequelize";

const TransferSearchForm = () => {

	const actions = useTransferStore(({ actions }) => actions)
	const countriesKiwi = useTransferStore(({ countriesKiwi }) => countriesKiwi)
	const countriesSamo = useTransferStore(({ countriesSamo }) => countriesSamo)
	const currentCountry = useTransferStore(({ currentCountry }) => currentCountry)
	// const regionsKiwi = useTransferStore(({ regionsKiwi }) => regionsKiwi)
	const placeTypes = useTransferStore(({ placeTypes }) => placeTypes)
	const currentRegionTo = useTransferStore(({ currentRegionTo }) => currentRegionTo)
	const currentRegionFrom = useTransferStore(({ currentRegionFrom }) => currentRegionFrom)
	const currentPlaceType = useTransferStore(({ currentPlaceType }) => currentPlaceType)
	const emptySearch = useTransferStore(({ emptySearch }) => emptySearch)
	const adults = useTransferStore(({ adults }) => adults)
	const children = useTransferStore(({ children }) => children)
	const transfers = useTransferStore(({ transfers }) => transfers)
	const searchComplete = useTransferStore(({ searchComplete }) => searchComplete)
	const samoCities = useTransferStore(({ samoCities }) => samoCities)
	const loadingSearch = useTransferStore(({ loadingSearch }) => loadingSearch)

	const { search } = useLocation();
	const navigate = useNavigate()

	const handleChangeCountry = (id) => {
		actions.setCountry(id)
	}

	const handleChangeRegionFrom = (id) => {
		actions.setRegionFrom(id)
	}

	const handleChangeRegionTo = (id) => {
		actions.setRegionTo(id)
	}

	const handleChangePlaceType = (id) => {
		actions.setPlaceType(id)
	}

	const handleSearch = () => {
		navigate(`?countryId=${currentCountry}&regionFrom=${currentRegionFrom}&regionTo=${currentRegionTo}&placeType=${currentPlaceType}&adults=${adults}&children=${children.length}`)
		actions.getPlaces()
	}

	const filteredCountries = countriesSamo?.map(item => {
		return {
			id: countriesKiwi?.find(kiwi => kiwi.name === item.name)?.id,
			samoId: item.id,
			name: countriesKiwi?.find(kiwi => kiwi.name === item.name)?.name,
		}
	}).filter(item => item.id)

	useEffect(() => {
		const newSearchParams = decodeQueryString(search)
		const {countryId, regionFrom, regionTo, placeType, adults, children} = newSearchParams
		if (search) {
			actions.getCountries().then((kiwi) => {
				actions.getCountriesSamo(countryId).then((countries) => {
					if (countryId) {
						actions.setCountry(Number(countryId))
					}
					if (regionFrom) {
						actions.setRegionFrom(Number(regionFrom))
					}
					if (regionTo) {
						actions.setRegionTo(Number(regionTo))
					}
					if (placeType) {
						actions.setPlaceType(placeType)
					}
					if (adults) {
						actions.setAdult(Number(adults))
					}
					if (children) {
						actions.setChild(Number(children))
					}
					const filtered = countries?.map(item => {
						return {
							id: kiwi?.find(kiwiitem => kiwiitem.name === item.name)?.id,
							samoId: item.id,
							name: kiwi?.find(kiwiitem => kiwiitem.name === item.name)?.name,
						}
					}).filter(item => item.id)
					const samoId = filtered?.find(item => item.id === Number(countryId))?.samoId
					if (samoId) {
						actions.getSamoCities(samoId).then((res) => {
							actions.getPlaces(res)
						})
					}
				})
			})
		} else {
			actions.getCountries().then(() => {
				actions.getCountriesSamo().then(() => {
					const samoId = filteredCountries?.find(item => item.id === currentCountry)?.samoId
					if (samoId) {
						actions.getSamoCities(samoId)
					}
				})
			})
		}
		actions.getPlaceTypes(placeType)
		// eslint-disable-next-line
	}, [])

	// console.log(samoCities, 'samoCities')
	//
	// console.log(filteredCountries, 'filteredCountries')

	useEffect(() => {
		if (currentCountry) {
			actions.getSamoCities(filteredCountries?.find(item => item.id === currentCountry)?.samoId)
		}
		// eslint-disable-next-line
	}, [currentCountry])

	if (!countriesKiwi || !samoCities || !countriesSamo) return

	return (
		<div className={'transfer-page'}>
			<h3>Найдите самый удачный трансфер:</h3>
			<div className={'transfer-search-form'}>
				<CountryField className={'transfer-countries'} lists={filteredCountries} onChange={handleChangeCountry} value={currentCountry} title={'Страна'} />
				<CountryField lists={samoCities} onChange={handleChangeRegionFrom} value={currentRegionFrom} title={'Откуда'} />
				<CountryField lists={samoCities} onChange={handleChangeRegionTo} value={currentRegionTo} title={'Куда'} />
				<CountryField lists={placeTypes} onChange={handleChangePlaceType} value={currentPlaceType} title={'Выберите тип'} />
				<TouristsField className={'tourists'} onChange={() => {}} adults={adults} children={children.length} title={'Туристы'} />
				<Button onClick={handleSearch} variant={'contained'} className={'search-button'} disabled={loadingSearch}>Найти</Button>
			</div>
			{(emptySearch || (searchComplete && isEmpty(transfers))) && <div className={'transfer-search-results'}>Поиск не дал результатов.</div>}
		</div>
	);
};

export default TransferSearchForm;