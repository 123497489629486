import React from "react";
import styled from "styled-components";
import Text from './Typography';

const NotyContainer = styled.div`
  padding: 14px 24px;
  background: #edfaf9;
  border: 1px solid #8ac5c0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const NotyTextBlock = styled.div`
  margin-top: 15px;

  &:first-child {
    margin-top: initial;
  }
`;

export const RegularNotification = () => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Обращаем внимание!<br/><br/>
          После оплаты в течение 6-8 часов вы получите маршрутную квитанцию, в которой номер заявки является и PNR, и номером билета.<br/>
          Если в течение 6 часов, по каким-либо причинам, авиакомпания всё ещё не подтвердит бронирование, мы Вас об этом проинформируем.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};
