import React, { useEffect, useMemo } from 'react'
import Logo from '../../asset/images/Fun-Sun-logo.png'
// import FUNNY from '../../asset/images/FUNNY.png'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import AccountAuth from './AccountAuth'
import { Button, useMediaQuery } from '@material-ui/core'
import useSearchTourStore from '../../pages/SearchTour/store'
import { encodeQueryString } from '../../functions/sequelize'
import useAuthStore from '../../pages/Auth/store'
import CompanyPopup from '../CompanyPopup'
import { agency, claims, info, reports, search } from './samoMenu'
import MenuIcon from '@material-ui/icons/Menu'
import MainMenu from '../MainMenu'
import BurgerMenu from '../BurgerMenu'
import { ReactComponent as LocationIcon } from '../../asset/icons/locationHeader.svg'
import { ReactComponent as PhoneIcon } from '../../asset/icons/phoneHeader.svg'
import './style.css'
import DeaprtureCityPopup from './DepartureCityPopup'
import { addDays, format } from 'date-fns'
import CurrencyChart from '../СurrencyСhart'

const NewNav = ({ showBtn = false }) => {
	const location = useLocation()
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const arrivalCityIds = useSearchTourStore(
		({ arrivalCityIds }) => arrivalCityIds
	)

	const tours = useSearchTourStore(({ tours }) => tours)
	const actions = useAuthStore(({ actions }) => actions)
	const agent = useAuthStore(({ agent }) => agent)
	const navigate = useNavigate()

	const goToOldVersion = () => {
		const url = 'https://b2b.fstravel.com'
		const { pathname, search } = location
		const paramsHotels = {
			STATEINC: searchParams.arrivalCountryId,
			CHECKIN_BEG: searchParams.minStartDate.replaceAll('-', ''),
			CHECKIN_END: searchParams.maxStartDate.replaceAll('-', ''),
			NIGHTS_FROM: searchParams.minNightsCount,
			NIGHTS_TILL: searchParams.maxNightsCount,
			ADULT: searchParams.adults,
			CHILD: searchParams.children.length,
			AGES: searchParams.children.join(),
			MEALS: searchParams.meals ? searchParams.meals.join() : '',
			TOURTYPE: searchParams.tourType,
			TOURINC: searchParams.tours ? searchParams.tours.join() : '',
			PROGRAMINC: searchParams.program,
			STARS: searchParams.stars ? searchParams.stars.join() : '',
			TOWNS: arrivalCityIds.join(),
			MOMENT_CONFIRM: searchParams.momentConfirm ? 1 : 0,
			WITHOUT_PROMO: searchParams.withoutPromo ? 1 : 0,
			HOTELS: searchParams.hotelIds.join(),
			DOLOAD: 1
		}
		const paramsTours = {
			...paramsHotels,
			TOWNFROMINC: searchParams.departureCityId
		}

		switch (pathname) {
			case '/search_tour':
				actions
					.getSamoLink(
						`${url}${pathname}?${tours ? encodeQueryString(paramsTours) : ''}`
					)
					.then(res => {
						window.open(res)
					})
				break
			case '/search_hotel':
				actions
					.getSamoLink(
						`${url}${pathname}?${tours ? encodeQueryString(paramsHotels) : ''}`
					)
					.then(res => {
						window.open(res)
					})
				break
			case '/booking':
				window.open(
					`${url}/bron${search.replace('priceId', 'CATCLAIM')}`,
					'_blank'
				)
				break
			default:
				actions.getSamoLink(url).then(res => {
					window.open(res)
				})
		}
	}

	// const refreshLink = (id, url) => {
	// 	actions.refreshSamoLink(id, url)
	// }

	// useEffect(() => {
	// 	if (agent) {
	// 		claims.forEach(item => {
	// 			actions.refreshSamoLink(item.id, item.url)
	// 		})
	// 		agency.forEach(item => {
	// 			actions.refreshSamoLink(item.id, item.url)
	// 		})
	// 		reports.forEach(item => {
	// 			actions.refreshSamoLink(item.id, item.url)
	// 		})
	// 		info.forEach(item => {
	// 			actions.refreshSamoLink(item.id, item.url)
	// 		})
	// 		search.forEach(item => {
	// 			if (item.id) {
	// 				actions.refreshSamoLink(item.id, item.url)
	// 			}
	// 		})
	// 	}
	// 	// eslint-disable-next-line
	// }, [agent])

	const cities = useSearchTourStore(({ departureCities }) => departureCities)
	const city = useMemo(() => {
		return cities.find(
			item => item.id.toString() === searchParams.departureCityId.toString()
		)
			? cities.find(
					item => item.id.toString() === searchParams.departureCityId.toString()
			  )?.name
			: cities[0]?.name
	}, [searchParams, cities])

	const [open, setOpen] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setOpen(previousOpen => !previousOpen)
	}

	const [openDrawer, setOpenDrawer] = React.useState(false)

	const isSmallScreen = useMediaQuery('(min-width:993px)')

	const toggleDrawer = open => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setOpenDrawer(open)
	}

	const [openCityPopup, setOpenCityPopup] = React.useState(false)
	const [anchorCity, setAnchorCity] = React.useState(null)

	const handleClickCity = event => {
		setAnchorCity(event.currentTarget)
		setOpenCityPopup(previousOpen => !previousOpen)
	}

	const { getCurrencies } = useSearchTourStore(state => state.actions)
	const { euro, dollar } = useSearchTourStore(state => state.currencys)
	const [showCurrencyEur, setCurrencyEur] = React.useState(false)
	const [showCurrencyUsd, setCurrencyUsd] = React.useState(false)
	const getCourse = currency => {
		if (!currency) {
			return
		}
		const yesTodayCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(addDays(new Date(), -1), 'dd.MM.yyyy')
		)
		const todayCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(new Date(), 'dd.MM.yyyy')
		)
		const tomorrowCourse = currency.find(
			item =>
				format(new Date(item.date), 'dd.MM.yyyy') ===
				format(addDays(new Date(), 1), 'dd.MM.yyyy')
		)

		return {
			yesTodayCourse,
			todayCourse,
			tomorrowCourse
		}
	}
	const dollars = getCourse(dollar)
	const euros = getCourse(euro)

	useEffect(() => {
		getCurrencies()
	}, [getCurrencies])

	if (!agentInfo) {
		return (
			<div className='header'>
				<div style={{ borderBottom: 'none' }} className='container-wrapper'>
					<div className='v-header-left'>
						<div style={{ display: 'flex' }}>
							<div className='logo'>
								<img
									src={Logo}
									className='logo'
									style={{ cursor: 'pointer' }}
									alt=''
									onClick={() => navigate('')}
								/>
							</div>
						</div>
						<div className='v-header-nav'>
							<Link
								style={{ marginBottom: 0 }}
								className='v-header-left-link'
								to={'/obshhie-voprosy-nacala-raboty'}
								target={'_self'}>
								<span>Сотрудничество</span>
							</Link>
							<Link
								style={{ marginBottom: 0 }}
								className='v-header-left-link'
								to={'https://fstravel.com/ '}
								target={'_self'}>
								<span>Туристам</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className='header'>
				<div className='container-wrapper'>
					<div className='v-header-left'>
						<div style={{ display: 'flex' }}>
							<div className='logo'>
								<Link to={'/'}>
									<img src={Logo} className='logo' alt='' />
								</Link>
							</div>
						</div>
						{isSmallScreen && (
							<div className='v-header-nav'>
								<div className='v-header-left-link' onClick={handleClick}>
									<span>Информация для агентов</span>
								</div>
								<Link
									className='v-header-left-link'
									to={'/nacalo-raboty'}
									target={'_self'}>
									<span>Сотрудничество</span>
								</Link>
								{agentInfo && agentInfo.agents?.name.includes('F&S') && (
									<Link className='v-header-left-link' to='/loyalty'>
										<span>Программа лояльности</span>
									</Link>
								)}
								<div className='currencies_rates'>
									<div className='currencies_rates__wrapper'>
										<div
											className='menu-rate'
											onClick={() => setCurrencyUsd(true)}>
											$ {dollars?.todayCourse?.rate}{' '}
										</div>{' '}
										/{' '}
										<div
											className='menu-rate'
											onClick={() => setCurrencyEur(true)}>
											{' '}
											€ {euros?.todayCourse?.rate}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					{isSmallScreen ? (
						<div className='v-header-right'>
							<div className='elem__location' onClick={handleClickCity}>
								<LocationIcon className='location-icon' />
								<div className='v-language-item'>
									<span>{city}</span>
								</div>
							</div>
							<Link to={'tel: 8 495 660 5 660'} className='v-header-phone'>
								<PhoneIcon className='phone-icon' />
								<span>8 495 660 5 660</span>
							</Link>
							{agentInfo && (
								<AccountAuth
									openCabinet={() =>
										actions.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer')
									}
									onCloseDrawer={toggleDrawer(false)}
								/>
							)}
						</div>
					) : (
						<div className='v-header-right'>
							<Link to={'tel: 8 495 660 5 660'} className='v-header-phone'>
								<span className='phone-text'>8 495 660 5 660</span>
							</Link>
							<MenuIcon onClick={toggleDrawer(true)} />
						</div>
					)}
				</div>
			</div>
			<CompanyPopup
				open={open}
				anchorEl={anchorEl}
				handleClick={handleClick}
				setOpen={setOpen}
			/>
			<DeaprtureCityPopup
				open={openCityPopup}
				anchorEl={anchorCity}
				handleClick={handleClickCity}
				setOpen={setOpenCityPopup}
			/>
			<MainMenu />
			{showBtn && <div className={'old-version'}>
				<Button variant={'contained'} onClick={goToOldVersion}>
					Вернуться на старую версию
				</Button>
			</div>}
			<BurgerMenu
				openDrawer={openDrawer}
				toggleDrawer={toggleDrawer}
				city={city}
				setOpenDrawer={setOpenDrawer}
			/>
			{showCurrencyUsd && dollar && (
				<CurrencyChart
					targetCurrency={dollar[0].currencyBase}
					name={'USD'}
					data={dollar}
					onClose={setCurrencyUsd}
				/>
			)}
			{showCurrencyEur && euro && (
				<CurrencyChart
					targetCurrency={euro[0].currencyBase}
					name={'EUR'}
					data={euro}
					onClose={setCurrencyEur}
				/>
			)}
		</>
	)
}

export default NewNav
