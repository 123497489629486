import * as React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { Button, CircularProgress, styled } from '@mui/material'
import { isEmpty } from 'lodash'
import BpCheckbox from '../../../AgentInfo/components/Checkbox/CustomizedCheckbox'
import useClaimsStore from '../../store'
import RatingTo from './RatingTO'
import useAuthStore from "../../../../Auth/store";

export const PrintButton = styled(Button)(({ theme }) => ({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '16px 32px 16px 32px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontFamily: ['Open Sans', 'Roboto', '"Helvetica Neue"'].join(','),
		color: '#7E8389',
		borderColor: '#DBE0E5',
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '26px',
		backgroundColor: '#EDF1FE',
		padding: '8px 8px'
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: ['Open Sans', 'Roboto', '"Helvetica Neue"'].join(','),
		fontSize: 16,
		borderColor: '#DBE0E5',
		fontWeight: 400,
		color: '#2E2E32',
		padding: '7px 8px',
		lineHeight: '22px'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	},
	'&.MuiTableRow-root.Mui-selected': {
		backgroundColor: 'white'
	},
	'&.MuiTableRow-root.Mui-selected:hover': {
		backgroundColor: 'white'
	}
}))

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index])
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis?.map(el => el[0])
}

const headCells = [
	{
		id: 'documentCategoryName',
		label: 'Тип'
	},
	{
		id: 'documentName',
		label: 'Описание'
	},
	{
		id: 'inprintable',
		label: 'Печать'
	}
]

function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort
	} = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<StyledTableCell padding='checkbox'>
					<BpCheckbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all agents'
						}}
					/>
				</StyledTableCell>
				{headCells.map((headCell, i) => (
					<StyledTableCell
						style={{ paddingLeft: i === 0 ? 0 : '8px' }}
						key={headCell.id}
						align={'left'}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

export default function DocumentsTable({ id }) {
	const claimDocuments = useClaimsStore(({ claimsDocuments }) =>
		claimsDocuments
			?.find(claim => claim.id === id)
			?.documents?.filter(item => item.isVisible && item.docCategory !== 1)
	)
	const actions = useClaimsStore(({ actions }) => actions)
	const actionsSamo = useAuthStore(({ actions }) => actions)
	const loading = useClaimsStore(({ loading }) => loading)

	const feedbackExist = useClaimsStore(
		({ feedbackExist }) => feedbackExist.find(item => item.id === id)?.isExist
	)

	const errorGis = useClaimsStore(
		({ errorGis }) => errorGis.find(item => item.id === id)?.msgText
	)

	// console.log('docs', claimDocuments)
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('')
	const [selected, setSelected] = React.useState([])

	const navigateLink = (e, doc) => {
		e.stopPropagation()
		if (doc.externalUrl) {
			window.open(doc.externalUrl, '_blank', 'rel=noopener noreferrer')
		} else if (doc?.mimeType && doc?.content) {
			const downloadLink = document.createElement("a");

			downloadLink.href = 'data:' + doc.mimeType + ';base64,' + doc.content;
			downloadLink.download = doc.fileName;
			downloadLink.click();
		} else {
			actions.getPrintDocument(doc)
		}
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelected = claimDocuments?.map(n => n.documentName)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleClick = (event, name, disable) => {
		if (disable) {
			return
		}
		const selectedIndex = selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			)
		}

		setSelected(newSelected)
	}

	const isSelected = name => selected.indexOf(name) !== -1

	const visibleRows = React.useMemo(
		() => stableSort(claimDocuments, getComparator(order, orderBy)),
		[order, orderBy, claimDocuments]
	)
	const getPrintSelected = () => {
		actions.printSelectedDocuments(selected, id)
	}

	const refreshLink = () => {
		actionsSamo.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer?CLAIM=' + id).then((url) => {
			window.open(url, '_blank', 'noreferrer')
		})
	}

	return (
		<Box sx={{ width: '100%' }}>
			{errorGis?.includes('ОШИБКА') &&
				<Box mt={2} mb={2}>
					<Box mb={2} textAlign={'center'} dangerouslySetInnerHTML={{__html: errorGis}} />
					<PrintButton fullWidth onClick={refreshLink}>Заполнить</PrintButton>
				</Box>
			}
			{!errorGis?.includes('ОШИБКА') && <RatingTo id={id}/>}
			{feedbackExist && !errorGis?.includes('ОШИБКА') && (
				<Paper
					sx={{ width: '100%', mt: 2, borderRadius: '16px' }}
					elevation={2}>
					<TableContainer sx={{ borderRadius: '16px', overflowY: 'hidden' }}>
						<Table sx={{ maxWidth: 780 }} aria-labelledby='tableTitle'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={claimDocuments?.length || 0}
							/>
							<TableBody>
								{visibleRows?.map((row, index) => {
									const isPrint =
										(row.externalUrl) ||
										(row.template && row.templateVars && row.isEnabled) ||
										(row.mimeType && row.content)
									const isSave =
										!!row.externalUrl || !!row?.content || (row.template && row.templateVars) 
									const isItemSelected = isSelected(row.documentName)
									const labelId = `enhanced-table-checkbox-${index}`
									return (
										<StyledTableRow
											style={{ borderColor: '#DBE0E5' }}
											hover={!!isPrint}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={labelId}
											onClick={event =>
												handleClick(event, row.documentName, !isPrint)
											}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}>
											<StyledTableCell padding='checkbox'>
												<BpCheckbox
													disabled={!isPrint}
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId
													}}
												/>
											</StyledTableCell>
											<StyledTableCell
												id={labelId}
												scope='row'
												style={{ paddingLeft: 0 }}>
												{row.documentCategoryName}
											</StyledTableCell>
											<StyledTableCell align='left'>
												{row.documentName}
											</StyledTableCell>
											<StyledTableCell align='left'>
												{loading === row.documentName ? (
													<CircularProgress size='20' />
												) : !isSave ? (
													<span style={{ color: '#E73C3E' }}> Не готов</span>
												) : (
													<span
														style={{ color: '#4872F2' }}
														onClick={e => navigateLink(e, row)}>
														Скачать
													</span>
												)}
											</StyledTableCell>
										</StyledTableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			)}
			{feedbackExist && (
				<Box sx={{ mt: 2 }}>
					<PrintButton
						disabled={isEmpty(selected) || !!loading}
						variant='contained'
						fullWidth
						onClick={getPrintSelected}>
						Распечатать
					</PrintButton>
				</Box>
			)}
		</Box>
	)
}
