export const TOP_COUNTRIES = [
    "Турция",
    "Россия",
    "Египет",
    "ОАЭ",
    "Абхазия",
    "Таиланд",
    "Кипр",
    "Шри-Ланка",
    "Мальдивы",
    "Куба",
    "Казахстан",
    "Армения",
    "Греция",
    "Тунис",
]