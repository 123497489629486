import styled from 'styled-components';

const Block = styled.div`
  margin-bottom: 20px!important;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: ${({ padding }) => (padding ? `${padding}px` : '24px')};
  @media (max-width: 767px){
    padding: 14px;
  }
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
`;

export default Block;
