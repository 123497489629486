/* eslint-disable import/named */
import {
  call,
  put,
  all,
  takeLatest,
  spawn,
  race,
  take,
  select,
} from 'redux-saga/effects';
import * as FlightSearchManager from './manager';
import { searchFormUpdate } from '../search-form/sagas';
import moment, { HTML5_FMT } from 'moment';
import {
  setFlightSearchStatus,
  setFlightSearchError,
  setFlightSearchData,
  setTimeOneWayFilter,
  setTransferDurationFilter,
  setPricesFilter,
  setAirportFilter,
  setAirlineFilter,
  setFlightTypeFilter,
  resetFilter,
  runFilter,
  setTransferFilter,
  setTimeFilter,
  setBaggageFilter,
  getNext,
  setList,
  purifySearchState,
  search,
} from './duck';
import parseSearchParams from '../../../functions/parseSearchParams';
import {getUserDataState} from '../user/selectors'

function createMomentDate(str) {
  if (!str) return null;

  const today = moment();
  let res = moment({
    month: parseInt(str.substring(2, 4)) - 1,
    days: parseInt(str.substring(0, 2)),
  });
  if (res.isBefore(today) && !moment().isSame(res, 'day')) {
    res = moment({
      month: parseInt(str.substring(2, 4)) - 1,
      days: parseInt(str.substring(0, 2)),
      year: today.year() + 1,
    });
  }
  return res;
}

function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}


function* flightSearchSaga(
  action
) {
  yield put(
    setFlightSearchStatus('Loading')
  );
  try {
    yield call(waitFor, state => state.user.data !== null);
    const user = yield select(getUserDataState);
    const parsedParams =
      parseSearchParams(action.payload.url);
    const paramsForRequest = {
        origin: parsedParams.origin,
        destination: parsedParams.destination,
        passengers: {
          adults: parsedParams.adults,
          children: parsedParams.children,
          infants: parsedParams.infants,
        },
        forwardDate: createMomentDate(parsedParams.forwardDate)?.format(
          HTML5_FMT.DATE
        ),
        backwardDate: createMomentDate(parsedParams.backwardDate)?.format(
          HTML5_FMT.DATE
        ),
        category: parsedParams.flightClass,
        saleChannel: action.payload?.saleChannel,
        agentSamoCode: user.agentSamoCode,
      };

    yield spawn(searchFormUpdate, parsedParams);

    const data = yield call(
      FlightSearchManager.fetchFlights,
      paramsForRequest
    );


    window.localStorage.setItem('origin', parsedParams.origin);
    window.localStorage.setItem('destination', parsedParams.destination);

    if (data?.flightsList?.items?.length) {
      yield put(setFlightSearchData(data));
      yield put(
        setList({
          items: [...data.flightsList.items],
          visibleItems: [...data.flightsList.items].slice(0, 10),
          page: 1,
          pageCount: Math.ceil(data.flightsList.items.length / 10),
        })
      );
      yield put(
        setFlightSearchStatus('Success')
      );
    } else {
      yield put(
        setFlightSearchError(new Error('Нету перелётов'))
      );
    }
  } catch (e) {
    console.log(e?.response?.data);
    yield put(
      setFlightSearchError(e)
    );
  }
}

export function* runFilterSaga() {
  try {

  yield put(runFilter());
  const items = yield select((state) => state.searchResult.flightSearch.flightsList.items);
  yield put(
    setList({
      items: [...items],
      visibleItems: [...items].slice(0, 10),
      page: 1,
      pageCount: Math.ceil(items.length / 10),
    })
  );
  } catch(e) {
    console.log(e?.response?.data);
  }
}

function* getNextWorker() {
  const data = yield select(state => state.searchResult.ticketList);
  if (data.page === data.pageCount) {
    return;
  }
  const visibleItems = [...data.items].slice(0, (data.page + 1) * 10);

  yield put(
    setList({
      ...data,
      visibleItems,
      page: data.page + 1,
    })
  );
}

export default function* rootSaga() {
  yield all([
    takeLatest(search, function* (action) {
      yield race([
        call(flightSearchSaga, action),
        take(purifySearchState.getType()),
      ]);
    }),
    takeLatest(
      [
        resetFilter,
        setTimeOneWayFilter,
        setTransferDurationFilter,
        setPricesFilter,
        setAirportFilter,
        setAirlineFilter,
        setTransferFilter,
        setTimeFilter,
        setBaggageFilter,
        setFlightTypeFilter,
      ],
      runFilterSaga
    ),
    takeLatest(getNext.getType(), getNextWorker),
  ]);
}
