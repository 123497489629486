import { useState,useEffect } from "react";
import { FormControlLabel, Checkbox, Box, Button } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import useBookingStore from "../../store";
import { INSURANCE_LINK } from './index'
import "./style.css";

function InsurancesModal({ insurances, onClose }) {
  const paxes = useBookingStore((state) => state.paxes);
 
  const [selectedInsurances, setInsurances] = useState(paxes.map(paxe => {return {id: paxe.id,selectedInsurances: paxe.selectedInsurances }}))
  const actions = useBookingStore(({ actions }) => actions);

  const baseOptions = insurances
  .filter((insurance) => !insurance.isRemovable)
  .map((insurance) => {
    const optionIds = insurance.options.filter( i=> i.isRequired).map((option) => option.id);
    return { id: insurance.id, optionIds };
  });

  const medInsuranceIds = insurances.filter(item => item.type.id === 1).map(item => item.id)

  const updateSubOption = (optionId, subOptionId, paxeId) => {
    const existPaxe = selectedInsurances.find((paxe) => paxe.id === paxeId);
    const existOption = existPaxe?.selectedInsurances.find((option) => option.id === optionId);
    const insurance = insurances.find((insurance) => insurance.id === optionId)
    const baseOptions = insurances
      .filter((insurance) => !insurance.isRemovable)
      .map((insurance) => ({ id: insurance.id, optionIds: insurance.options.filter((option) => option.isRequired).map((option) => option.id) }));
  
    if (!existOption && medInsuranceIds.includes(optionId)) {
      const baseOption = insurances
        .find((insurance) => insurance.id === optionId)
        .options.filter((option) => option.isRequired)
        .map((option) => option.id);
  
      const newState = selectedInsurances.map((paxe) => {
        if (paxe.id === paxeId) {
          return { id: paxe.id, selectedInsurances: [...paxe.selectedInsurances, { id: optionId, optionIds: [...baseOption, subOptionId] }] };
        }
  
        return { id: paxe.id, selectedInsurances: [...paxe.selectedInsurances.filter((option) => baseOptions.some((baseOption) => baseOption.id === option.id) || medInsuranceIds.includes(option.id)), { id: optionId, optionIds: baseOption }] };
      });
  
      setInsurances(newState);
      return;
    }
  
    const existIndex = selectedInsurances.findIndex((paxe) => paxe.id === paxeId);
    const existOptionIndex = existPaxe.selectedInsurances.findIndex((option) => option.id === optionId);
    const newState = [...selectedInsurances];
  
    if (!existOption) {
      const filteredInsurance = existPaxe.selectedInsurances.filter((option) => baseOptions.some((baseOption) => baseOption.id === option.id) || medInsuranceIds.includes(option.id));
      const baseOption = insurance.options
        .filter((option) => option.isRequired)
        .map((option) => option.id);
  
      newState.forEach((paxe, index) => {
        if (paxe.id === paxeId) {
          newState[index] = { id: paxe.id, selectedInsurances: [...filteredInsurance, { id: optionId, optionIds: [...baseOption, subOptionId] }] };
          return;
        }
        newState[index].selectedInsurances = [...paxe.selectedInsurances.filter((option) => baseOptions.some((baseOption) => baseOption.id === option.id) || medInsuranceIds.includes(option.id)), { id: optionId, optionIds: baseOption }];
      });
      setInsurances(newState);
      return;
    }
  
    if (existOption.optionIds.includes(subOptionId)) {
      const filteredId = existOption.optionIds.filter((id) => id !== subOptionId);
  
      newState[existIndex].selectedInsurances[existOptionIndex] = { id: existOption.id, optionIds: filteredId };
      setInsurances(newState);
      return;
    }
  
    newState[existIndex].selectedInsurances[existOptionIndex].optionIds.push(subOptionId);
    setInsurances(newState);
  };

  const handleChange = (id) => {
    let newState = []
    console.log(selectedInsurances, 'selectedInsurances')
    selectedInsurances.forEach(item => {
        const existItem = item.selectedInsurances.find((item) => item.id === id)
        const insurance = insurances.find((item) => item.id === id)
        const baseOption =  insurance.options.filter(item => item.isRequired);

        let filterInsurance = item.selectedInsurances.filter(i => baseOptions.some(base =>  base.id === i.id) || medInsuranceIds.includes(i.id));

        if(existItem && medInsuranceIds.includes(id)) {
          filterInsurance = item.selectedInsurances.filter(i => i.id !== id)
          newState.push({id: item.id, selectedInsurances: filterInsurance})
          return
        }

        if(medInsuranceIds.includes(id)){
          const newInsurance = { id: id, optionIds: baseOption.map((item) => item.id)}
          newState.push({id: item.id, selectedInsurances: [newInsurance]})
          return
        }

        if(existItem){
          newState.push({id: item.id, selectedInsurances: filterInsurance})
          return
        }
      
      const newInsurance = {
          id: id,
          optionIds: baseOption
            .map((i) => i.id),
        }

      newState.push({id: item.id, selectedInsurances: [newInsurance]})

    })

    setInsurances(newState);
  };
  
  const onSave = () => {
    actions.updateInsurance(selectedInsurances);
    onClose();
  };

  useEffect(() => {
    return () => {
      setInsurances(paxes.map(paxe => {return {id: paxe.id,selectedInsurances: paxe.selectedInsurances }})); 
    };
  }, [paxes]);

  const sortedInsurance = insurances.sort((a,b) => a.type.id - b.type.id)

  return (
    <>
      <div className="modal-overlay" />
      <div className="modal">
        <div className="modal-title">
          <div
            style={{
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
              fontSize: "1.17em",
              fontWeight: "bold",
            }}
          >
            Страховка
          </div>
          <div
            style={{ flex: 0.1, textAlign: "end", cursor: "pointer" }}
            onClick={onClose}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="modal_body">
          {paxes.map((paxe) => 
          {
            const existPaxe = selectedInsurances.find(i => i.id === paxe.id)
            return(
            <>
              <div className="paxe-title">Турист {paxe.id}</div>
              <div className="insurance-body">
                {sortedInsurance.map((option) => {
                  return (
                    <div key={option.id}>
                      <div className="insurance-option">
                        <FormControlLabel
                          label={
                            <><a
                              className='insurance-link'
                              href={INSURANCE_LINK}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {option.name}
                            </a>
                              {` (${option.insuranceSum.amount + option.insuranceSum.currencyAlias})`}

                              {option.perPerson && option.pricePerPerson.amount
                                ? ` +${option.pricePerPerson.amount} ${option.pricePerPerson.currencyAlias}`
                                : ""}
                              {!option.perPerson && option.price.amount
                                  ? ` +${option.price.amount} ${option.price.currencyAlias}`
                                  : ""}
                            </>
                          }
                          control={
                            <Checkbox
                              defaultChecked={option.isRequired}
                              checked={
                                !!existPaxe.selectedInsurances.find(
                                  (i) => i.id === option.id
                                ) ?? false
                              }
                              onChange={() => handleChange(option.id)}
                              disabled={!option.isRemovable}
                            />
                          }
                        />
                      </div>
                      {option.options.map((item) => (
                        <div className="insurance-subOption">
                          <RenderSubOption
                            subOption={item}
                            parentId={option.id}
                            handleUpdate={updateSubOption}
                            count={paxe.id}
                            isChecked={
                              !!existPaxe.selectedInsurances
                                .find((i) => i.id === option.id)
                                ?.optionIds.includes(item.id ?? false)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          )})}
        </div>
        <div className="modal-button">
          <Button onClick={onSave} variant="contained" color="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
}

function RenderSubOption({
  subOption,
  parentId,
  handleUpdate,
  isChecked = false,
  count
}) {
  const checked = isChecked || subOption.isRequired;
  const label = `${subOption.name.replace(": ", "")} ${
    subOption.price.amount
      ? `+${subOption.price.amount} ${subOption.price.currencyAlias}`
      : ""
  }`;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        key={subOption.id}
        label={label}
        disabled={subOption.isRequired}
        control={
          <Checkbox
            defaultChecked={subOption.isRequired}
            checked={!!checked}
            onChange={() => handleUpdate(parentId, subOption.id, count)}
          />
        }
      />
    </Box>
  );
}

export default InsurancesModal;
