import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseImg } from '../../../asset/images/cabinet/close.svg'
import { ReactComponent as SupportAgent } from '../../../asset/images/cabinet/support-agent.svg'
import { ReactComponent as Download } from '../../../asset/images/cabinet/download.svg'
import { ReactComponent as Document } from '../../../asset/images/cabinet/document.svg'
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import useMessagesStore from './store'

const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 32px;
	background-color: #fff;
	width: 780px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-height: 100vh;
	overflow-y: auto;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const PopupTitle = styled.h2`
	color: #4872F2;
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	cursor: pointer;
`

const Subtitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	color: #2E2E32;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding-bottom: 16px;
	border-bottom: 1px solid #DBE0E5;
`
const GrayText = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	color: #b3b9c0;
	font-size: 14px;
	line-height: 137%;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`
const Message = styled.div`
	display: flex;
	background-color: #EFF2F6;
	border-radius: 8px;
	padding: 16px;

	color: #2E2E32;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-wrap: wrap;
`
const Attachment = styled(Message)`
	flex-direction: row;
	justify-content: space-between;
	gap: 8px;
	align-items: center;
	width: 50%;
	word-break: break-all;

`
const DownloadImage = styled(Download)`
	width: 16px;
	height: 16px;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`
 

export default function SingleMessageModal({ onClose, message, onCloseAllModals}) {
	const actions = useMessagesStore(({actions}) => actions)
	const files = useMessagesStore(({files}) => files)
	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	React.useEffect(() => {
		if (message.isRead) {
			return
		}
		actions.markread(message.messageId)
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (message.filesCount) {
			actions.getFileId(message.messageId)
		}
		// eslint-disable-next-line
	}, [])

	const handleDownloadFile = (messageId, fileId, fileName) => {
		actions.getFile(messageId, fileId, fileName)
	}

	const handleClose = () => {
		onClose()
		actions.clearFiles()
	}

	const handleOpenClaim = (claimId) => {
		onCloseAllModals()
		window.location.href =`${window.location.origin}/cabinet/claims?claimId=${claimId}`
	}

	return (
	
			<PopupBackground>
				<PopupContent>
					<Row>
						<PopupTitle onClick={() => handleOpenClaim(message?.claimId)}>Заявка {message?.claimId}</PopupTitle>
						<CloseBtn onClick={handleClose} />
					</Row>
					 <Subtitle>
						{message?.author} 
					 	<GrayText><SupportAgent/> {message?.type}</GrayText>
					 </Subtitle>
					 <Message>{message?.body}</Message>
					 {files?.map(item => <Attachment><Document/>{item.fileName}<DownloadImage onClick={() => handleDownloadFile(item.messageId, item.fileId, item.fileName)}/></Attachment>)}
					 <div style={{color: '#7e8389'}}>{format(new Date(message?.createDate), 'dd MMM hh:mm', { locale: ru })}</div>
				</PopupContent>
			</PopupBackground>
		)
}
