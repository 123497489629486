import React from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'
import ErrorIcon from "../../asset/images/avia/shared/error.svg";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 340px;
    min-height: 220px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
  }
  & img {
    width: 70px;
    margin: auto;
  }
`
const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;
const Title = styled.div`
  margin-top: 20px;
  text-align: center;
  line-height: 28px;
  font-weight: 600;
`
const ErrorModal = ({setIsOpenModal, ...props}) => {
  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="close"
        onClick={() => setIsOpenModal(false)}
      >
        <Icon/>
      </CloseButton>
      <img src={ErrorIcon} alt=""/>
      <Title>Что-то пошло не так... Пожалуйста попробуйте позднее. Иногда так бывает :(</Title>
    </StyledPopup>
  )
}

export default ErrorModal
