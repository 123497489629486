import styled from 'styled-components';
import { ReactComponent as Trash } from '../../../../../../../asset/icons/trash.svg'
import useClaimsStore from "../../../../store";

const PeopleWrapper = styled.div`
    padding: 8px 16px;
    border-radius: 8px;
    width: 250px;
    margin-bottom: 15px;
    cursor: pointer;
    
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`
const Text = styled.div`
font-family: "Open Sans", serif;
font-size: 16px;
line-height: 22px;
font-weight: 400;
color: rgba(46, 46, 50, 1);
`

const TextGray = styled(Text)`
    font-size: 12px;
    line-height: 18px;
    color: rgba(126, 131, 137, 1);
`


function People({ people, count, boarding, pOpeople }) {
    const currentPeople = useClaimsStore(({ currentPeople }) => currentPeople)
    const actions = useClaimsStore(({ actions }) => actions)
    const boardingPeoples = useClaimsStore(({ boardingPeoples }) => boardingPeoples)

    const handleDeleteSeat = () => {
        actions.deleteSeat()
    }

    if (!pOpeople) return

    return (
        <PeopleWrapper
            style={people.id === currentPeople ? {border: "2px solid rgba(72, 114, 242, 1)"} : {border: "1px solid rgba(212, 217, 222, 1)"}}
            onClick={() => actions.setCurrentPeople(people.id)}
        >
        <Header>
            <TextGray>Турист {count} {people?.name}</TextGray>
            <Trash style={{ cursor: 'pointer' }} onClick={handleDeleteSeat} />
        </Header>
        <Text>{boardingPeoples?.find(item => item.pOpeople === pOpeople)?.pPlacementName || ''}</Text>
    </PeopleWrapper>)

}

export default People