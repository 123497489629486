import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { tooltipClasses } from '@mui/material'
import './style.css'

const LightTooltip = styled(({ className, children, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }}>
		{children}
	</Tooltip>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[2],
		fontSize: 14
	}
}))

const Promo = () => {
	return (
		<LightTooltip
			title={
				<>
					<div>
						<span className='promo'>ПРОМО</span> Тариф «Промо»
					</div>
					<p>Условия применения тарифа:</p>
					<ul>
						<li>действует фиксированная комиссия 7%</li>
						<li>акции к тарифу не применяются</li>
						<li>в бонусной программе не участвует</li>
					</ul>
					<p>
						<a
							href='https://agentb2b.fstravel.com/actions/tarif-promo-broniruite-tury-na-privlekatelnyx-usloviyax'
							target='_blank'
							rel='noreferrer'>
							Подробнее...
						</a>
					</p>
				</>
			}
			arrow
			placement='top'>
			<span className='promo'>ПРОМО</span>
		</LightTooltip>
	)
}

export default Promo

export const StandartFare = () => {
	return (
		<LightTooltip
			title={
				<>
					<div>Тариф «Стандарт»</div>
					<p>Условия применения тарифа:</p>
					<ul>
						<li>действующая базовая комиссия туристического агентства</li>
						<li>гибкие условия аннуляции </li>
					</ul>
					<p>
						<a
							href='https://agentb2b.fstravel.com/actions/tarif-promo-broniruite-tury-na-privlekatelnyx-usloviyax'
							target='_blank'
							rel='noreferrer'>
							Подробнее...
						</a>
					</p>
				</>
			}
			arrow
			placement='top'>
			<a
				className='link-tour-fare'
				target='_blank'
				href='https://agentb2b.fstravel.com/actions/tarif-promo-broniruite-tury-na-privlekatelnyx-usloviyax'
				rel='noreferrer'>
				Тариф «Стандарт»
			</a>
		</LightTooltip>
	)
}
