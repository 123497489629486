import React from "react"
import styled from "styled-components"
import Flight from "./Flight"

const Body = styled.div`
  & > div {
    margin-bottom: 20px;
    border-bottom: 1px solid #DCDCDC;
  }
  & > :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`

const DescriptionDetail = React.memo(({ items }) => {
  return (
    <Body>
      {items.map((props, key) => (
        <Flight className="flight" key={key} {...props} />
      ))}
    </Body>
  )
})

export default DescriptionDetail
