import React, {useEffect, useRef, useState} from "react"
import { getCityListByStr } from "../../../store/modules/search-form"
import AsyncSelect from "react-select/async"
import styled from "styled-components"

const Row = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Wrapper = styled.div``

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const StyledSelect = styled(AsyncSelect).attrs(() => ({
  defaultOptions: false,
  cacheOptions: true,
  isMulti: true,
  placeholder: "",
  noOptionsMessage: () => "Введите название города",
  loadingMessage: () => "Выполняется поиск города",
  components: { ClearIndicator: () => null }
}))`
  width: 256px;
  height: 40px;
  position: relative;
  margin-right: 20px;
  z-index: 1;
  @media (max-width: 1124px) {
    width: 100%;
    margin-top: 5px;
  }
`

const BottomLabel = styled(Label)`
  margin-bottom: 0;
  margin-top: 4px;
  max-width: 256px;
  white-space: pre-line;
`

const CitiesSearchRow = props => {
  const [selectedDeparture, setSelectedDeparture] = useState([]);
  const [selectedArrival, setSelectedArrival] = useState([]);
  const abortControllerRef = useRef(new AbortController())

  useEffect(() => {
    if (!!props?.values?.arrival) {
      getDefaultValues({data: props.values.arrival, direction: 'arrival'})
    }
    if (!!props?.values?.departure) {
      getDefaultValues({data: props.values.departure, direction: 'departure'})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultValues = async ({data, direction}) => {
    try {
      const values = data.replaceAll(' ', '').split(',')
      for (const el of values) {
        const res = await getCityListByStr(el)
        const value = res?.data?.filter((x) => x.iataCode === el).map((x) => ({
          label: x.nameRus,
          value: x.iataCode,
        }));

        direction === 'departure' && setSelectedDeparture(cur => [...cur, ...value]);
        direction === 'arrival' && setSelectedArrival(cur => [...cur, ...value]);
      }

    } catch (e) {
      console.log('error', e)
    }
  }

  const loadSuggestions = async (str, signal) => {
    const response = await getCityListByStr(str, signal);
    return response?.data?.slice(0, 5)?.map((x) => ({
      label: x.nameRus,
      value: x.iataCode,
    }));
  };

  return (
    <Row>
      <Wrapper>
        <Label>Города/аэропорты вылета</Label>
        <StyledSelect
          onChange={changes => {
            if (changes?.length === 1) {
              props.setFieldValue("departure", changes[0]?.value)
            } else {
              const changed = changes?.map(x => x?.value)
              props.setFieldValue("departure", changed)
            }
            setSelectedDeparture(changes);
          }}
          loadOptions={async (inputValue, callback) => {
            callback(await loadSuggestions(inputValue, abortControllerRef.current));
          }}
          value={selectedDeparture}
        />
        <BottomLabel>
          Оставьте поле пустым, если параметр не имеет значения
        </BottomLabel>
      </Wrapper>
      <Wrapper>
        <Label>Города/аэропорты прилета</Label>
        <StyledSelect
          isLoading={false}
          onChange={changes => {
            if (changes?.length === 1) {
              props.setFieldValue("arrival", changes[0]?.value)
            } else {
              const changed = changes.map(x => x.value)
              props.setFieldValue("arrival", changed)
            }
            setSelectedArrival(changes);
          }}
          loadOptions={value => {
            return loadSuggestions(value, abortControllerRef.current)
          }}
          value={selectedArrival}
        />
        <BottomLabel>
          Оставьте поле пустым, если параметр не имеет значения
        </BottomLabel>
      </Wrapper>
    </Row>
  )
}

export default CitiesSearchRow
