import React from 'react';
import styled from 'styled-components';
import { take } from 'lodash';
import { SuggestionItem } from './suggestion-item';


const SuggestionWrapper = styled.div`
  ${({ position }) => {
    switch (position) {
      case 'origin':
        return 'left: 0;';
      case 'destination':
        return 'right: 0;';
      default:
        return 'display: none;';
    }
  }}
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  z-index: 100;
  width: 330px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 1024px) {
    width: 347px;
    top: 50px;
  }

  @media (max-width: 767px) {
    width: 347px;

    top: ${({ position }) => (position === 'origin' ? 60 : 130)}px;
    left: 0;
  }
`;
const CitySelector = styled.ul`
  border-bottom: 1px solid #f2f2f2;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;


export const Suggestion = ({
  currentFocus,
  onSelect,

  ...props
}) => {
  const items = take(props.items, 6).map((item, key) => (
    <SuggestionItem
      indexNumber={key}
      focus={currentFocus}
      data={item}
      onSelect={onSelect}
      key={item.id}
      active={key === props.suggestionIndex}
      setSuggestionIndex={props.setSuggestionIndex}
    />
  ));

  React.useEffect(
    () => () => {
      props.setSuggestionIndex(null);
    },
      // eslint-disable-next-line
    []
  );

  return (
    <SuggestionWrapper position={currentFocus} {...props}>
      <CitySelector>{items}</CitySelector>
    </SuggestionWrapper>
  );
};
