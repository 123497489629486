import { useState, useRef, useMemo, useEffect } from 'react'
// import declination from "../../../../functions/declination";
import Pagination from '@mui/material/Pagination'

import ResultCard from './ResultCard'
import { isEmpty } from 'lodash'
import declination from '../../../../functions/declination'
import { uuid4 } from '@sentry/utils'
import useYachtRentServicesStore from '../../store'

function SearchYachtRentResult({ lists }) {
	const itemPerPage = 10
	const [currentPage, changeCurrentPage] = useState(1)
	const scrollRef = useRef(null)
	const timerRef = useRef(null)
	const searchResultCountry = useYachtRentServicesStore(
		({ searchResultCountry }) => searchResultCountry
	)
	const adultsCount = useYachtRentServicesStore(({ adults }) => adults)
	const childrenCount = useYachtRentServicesStore(({ children }) => children)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const adults = useMemo(() => adultsCount, [lists])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const children = useMemo(() => childrenCount, [lists])

	const handleChange = (event, value) => {
		changeCurrentPage(value)
		if (scrollRef?.current) {
			timerRef.current = setTimeout(() => {
				scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			}, 0)
		}
	}

	useEffect(() => {
		return () => clearTimeout(timerRef.current)
	}, [])

	const sortDatePrice = (a, b) => {
		const dateComparison = new Date(a?.startDate) - new Date(b?.startDate)
		if (dateComparison !== 0) {
			return dateComparison
		}
		return a?.price?.value - b?.price?.value
	}

	if (isEmpty(lists)) {
		return null
	}

	return (
		<div className='search-result' ref={scrollRef}>
			<div>
				<h5 className='search-result__find-count'>
					{lists?.length
						? `Аренда яхт в ${searchResultCountry?.name}: найдено ${lists?.length
						} ${declination(lists?.length, [
							'предложение',
							'предложения',
							'предложений'
						])}.`
						: 'Совпадений не найдено'}
				</h5>
			</div>
			<div className='search-result__content'>
				{lists
					.sort(sortDatePrice)
					.slice(
						(currentPage - 1) * itemPerPage,
						(currentPage - 1) * itemPerPage + itemPerPage
					)
					.map((item, index) => (
						<ResultCard
							// key={item.id + index}
							key={uuid4()}
							otherParams={item?.other_params}
							title={item?.name}
							price={item?.price}
							startDate={item?.startDate}
							country={item?.country}
							city={item?.city}
							adults={adults}
							child={children}
							item={item}
						/>
					))}
			</div>
			<div className='search-result__pagination-block'>
				<Pagination
					count={Math.round(lists.length / itemPerPage)}
					defaultPage={1}
					variant='outlined'
					shape='rounded'
					page={currentPage}
					onChange={handleChange}
					boundaryCount={1}
				/>
			</div>
		</div>
	)
}

export default SearchYachtRentResult
