import React from 'react';
import TimeRange from './TimeRange';
import {ReactComponent as PlaneLogo} from '../../../../asset/images/avia/search-result/plane.svg'
import styled from 'styled-components';

const TopWrapper = styled.div`
  margin-bottom: 12px;
`;

const TextWrapper = styled.div`
  & > svg {
    margin: 0 8px;
  }
  display: inline-flex;
  align-items: center;
`;

const Text = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3C3C3C;
`;

const TimeRangeWithPlane = ({
  from,
  to,
  ...props
}) => {
  return (
    <div className="range-time-with-plane">
      <TopWrapper>
        <TextWrapper>
          <Text>{from.city}</Text>
          <PlaneLogo width={24} height={24}/>
          <Text>{to.city}</Text>
        </TextWrapper>
      </TopWrapper>
      <TimeRange {...props} />
    </div>
  );
};

export default TimeRangeWithPlane;
