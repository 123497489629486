export const sendAnalyticsAdjust = (error, data) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'booking_error',
		'error_type': error,
		'hotel_country': data?.country,
		'departure_city': data?.departureCity,
		'hotel_name': data?.hotel
	});
}

export const sendAnalyticsReserve = (error, data) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'booking_error_basket_right',
		'error_type': error,
		'hotel_country': data?.country,
		'departure_city': data?.departureCity,
		'hotel_name': data?.hotel
	});
}