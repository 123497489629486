const unzipFLightCLass = (char) => {
    switch (char) {
      case 'E':
        return 'economy';
      case 'C':
        return 'comfort';
      case 'B':
        return 'business';
      case 'F':
        return 'first';
      default:
        return 'economy';
    }
};

export default function parseSearchParams(str) {
    const origin = str.substring(0, 3);
    const forwardDate = str.substring(3, 9);
    const destination = str.substring(9, 12);

    str = str.replace(origin + forwardDate + destination, '');

    let backwardDate = str.substring(0, 6);
    let flightClass = 'economy';

    const searchResultFlightClass = str.match(/[A-Z]/);
    if (searchResultFlightClass) {
      flightClass = unzipFLightCLass(searchResultFlightClass[0]);
  
      str = str.replace(searchResultFlightClass[0], '');
    }

    if (backwardDate.length < 4 || !!backwardDate.match(/[A-Z]/)) {
      backwardDate = null;
    } else {
      str = str.replace(backwardDate, '');
    }

    const adults = parseInt(str[0]);
    const children = str[1] ? parseInt(str[1]) : 0;
    const infants = str[2] ? parseInt(str[2]) : 0;

    return {
      origin,
      destination,
      forwardDate,
      backwardDate,
      adults,
      children,
      infants,
      flightClass,
    };
}
