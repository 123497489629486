import { Button, Snackbar } from '@material-ui/core'
import { Alert } from '@mui/material'
import * as React from 'react'
import useSearchTourStore from '../../store'

export default function MessageSnackbar({ open, handleClose, tourRef }) {
	const tourName = useSearchTourStore(({ tourName }) => tourName)
	const scrollHandler = () => {
		tourRef.current.scrollIntoView({ behavior: "smooth" })
		handleClose()
	}
	return (
		<>
			<Snackbar open={open} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
					<div>
						Вы выбрали <b>{tourName}</b>.
						<br /> Измените параметры поиска
					</div>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							color='secondary'
							style={{ margin: '8px' }}
							variant='contained'
							size='small'
							onClick={scrollHandler}>
							Изменить
						</Button>
					</div>
				</Alert>
			</Snackbar>
		</>
	)
}
