const IntlObj = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: 'rub',
  });
  
  const IntlObjWithoutStyle = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: 'rub',
  });
  
  /**
   * Price formatter
   * @param {number} price
   * @param {boolean} [noStyle=false] убрать значек рубля
   * @returns formatted price in rub currency
   * @example formatPrice(1000000); //1 000 000₽
   */
  export default function formatPrice(price, noStyle) {
    return noStyle ? IntlObjWithoutStyle.format(price) : IntlObj.format(price);
  }