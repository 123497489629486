import React, {useEffect, useRef, useState} from 'react';
import InsuranceDescriptionBlock from "./components/InsuranceDescriptionBlock";
import { Button } from "@material-ui/core";
import MembersBlock from "./components/MembersBlock";
// import { isEmpty } from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Snackbar } from "@mui/material";
import useAuthStore from "../../../Auth/store";
import BookingInsuranceSuccess from "./components/BookingInsuranceSuccess";
import useInsuranceStore from "../store";

import './style.scss'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useErrorStore} from "./components/MembersBlock/store";

const BookingInsurance = () => {
	const actions = useInsuranceStore(({ actions }) => actions)
	const authActions = useAuthStore(({ actions }) => actions)
	const draft = useInsuranceStore(({ draft }) => draft)
	const loading = useInsuranceStore(({ loading }) => loading)
	const loadingUpdateBasket = useInsuranceStore(({ loadingUpdateBasket }) => loadingUpdateBasket)
	const isReadyForBooking = useInsuranceStore(({ isReadyForBooking }) => isReadyForBooking)
	const travellers = useInsuranceStore(({ travellers }) => travellers)
	const status = useInsuranceStore(({ status }) => status)
	const authError = useInsuranceStore(({ authError }) => authError)
	const insurances = useInsuranceStore(({ insurances }) => insurances)
	const paxes = useInsuranceStore(state => state.travellers)
	const scrollRef = useRef(null)
	const phoneError = useInsuranceStore(({ phoneError }) => phoneError)
	const birthDayError = useErrorStore(({ birthDayError }) => birthDayError)
	const reserveInsuaranceLoading = useInsuranceStore(({ reserveInsuaranceLoading }) => reserveInsuaranceLoading)
	const [errors, setErrors] = useState({
		firstName: '',
		lastName: '',
	})


	const isEnabled = paxes && paxes.every(item => item.document?.firstName && item.document?.lastName)

	useEffect(() => {
		actions.getDraft().then(res => {
			actions.searchInsurance(res?.insurance[0]?.searchId)
			// if (isEmpty(res?.insurance[0]?.travellers)) {
			// 	actions.setTravelers()
			// }
			actions.setTravelers()
		})
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (authError) {
			authActions.logout()
		}
		// actions.clearAuthError()
		// eslint-disable-next-line
	}, [authError])

	const handleRecountDraft = () => {
		let err = {
			firstName: '',
			lastName: '',
		}
		if (insurance.needRussianFullName && paxes.some(item => !item.document.firstName)) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
			err = {...err, firstName: 'Имя по-русски не может быть пустым'}
		}

		if (insurance.needRussianFullName && paxes.some(item => !item.document.lastName)) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
			err = {...err, lastName: 'Фамилия по-русски не может быть пустым'}
		}

		setErrors(err)
		actions.updateBasket(draft.id)
	}

	if (!draft || !insurances) return

	const insurance = insurances?.items?.find(item => item.id === draft?.draftBody?.insurance[0]?.id)



	return (
		<div className='container'>
			<InsuranceDescriptionBlock draft={draft} insurance={draft?.draftBody?.insurance[0]} />
			<div className="insurances-detail">
				<Box mr={4}><span className={'options-title'}>Ассистанс</span> - {draft?.draftBody?.insurance[0]?.assistanceNames}</Box>
				{insurance?.rulesLink && <Box mr={4}><a href={insurance?.rulesLink} target={'_blank'} rel='noreferrer noopener nofollow'>Правила страхования</a></Box>}
				{insurance?.policyExampleLink && <Box><a href={insurance?.policyExampleLink} target={'_blank'} rel='noreferrer noopener nofollow'>Пример полиса</a></Box>}
			</div>
			<Accordion className={'insurances-accordion'}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					className={'insurances-accordion__summary'}
				>
					<div>Опции</div>
				</AccordionSummary>
				<AccordionDetails>
					<ul className={'insurnce-options'}>
						{insurance?.options.map(item => <li key={item.optionType}>{item.name} - {item.limit.toLocaleString('ru', {})} {draft.draftBody.insurance[0].currencyIsoCode}</li>)}
					</ul>
				</AccordionDetails>
			</Accordion>
			<div ref={scrollRef} />
			{travellers?.map(item => <MembersBlock insurance={insurance} key={item.id} count={item.id} errorForm={errors} />)}
			<div style={{ textAlign: 'center', marginBottom: 20, fontSize: 24, fontWeight: 600 }}>
				{draft.draftBody.insurance[0].price.toLocaleString('ru', {})}{' '}
				{draft.draftBody.insurance[0].currency.currencyAlias}
			</div>
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				<Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={handleRecountDraft}
					disabled={loading || loadingUpdateBasket || isReadyForBooking || reserveInsuaranceLoading}
				>
					Пересчитать
				</Button>
				<Button
					variant='contained'
					onClick={() => actions.reserveDraft(draft.id)}
					disabled={!isReadyForBooking || loading || loadingUpdateBasket || !isEnabled || phoneError || birthDayError || reserveInsuaranceLoading}
				>
					Бронировать
				</Button>
				{(loading && isReadyForBooking) && <p>Выполняется бронирование . . .</p>}
			</div>
			{status && <Snackbar open={status?.status === 'UndoCompleted' || status.message} autoHideDuration={6000}>
				<Alert
					onClose={() => {
						actions.clearError()
					}}
					severity='error'
					sx={{ width: '100%' }}>
					{status?.trace[0]?.message}
				</Alert>
			</Snackbar>}
			{errors && <Snackbar open={Object.values(errors).some(item => item !== '')} autoHideDuration={6000}>
				<Alert
					onClose={() => {
						setErrors({
							firstName: '',
							lastName: '',
						})
					}}
					severity='error'
					sx={{ width: '100%' }}>
					{Object.values(errors).filter(item => item !== '')[0]}
				</Alert>
			</Snackbar>}
			{
				status && (status?.status === 'ReservationCompleted' || status?.status === 'PartiallyPaid') &&
				<BookingInsuranceSuccess
					claim={status?.backOfficeId}
					price={`${draft?.draftBody?.insurance[0]?.price} ${draft?.draftBody?.insurance[0]?.currency?.currencyAlias}`}
				/>
			}
		</div>
	);
};

export default BookingInsurance;