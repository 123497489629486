import * as React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import useAuthStore from '../../../Auth/store'
import BpCheckbox from '../components/Checkbox/CustomizedCheckbox'
import {
	Button,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	styled,
	Toolbar,
	Typography
} from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import AddAgentPopup, { FormInput } from './components/AddAgentPopup'
import Search from '@material-ui/icons/Search'
import EditAgentPopup from './components/EditAgentPopup'
import { Close, MoreVert } from '@material-ui/icons'
import './components/style.css'

export function wordEndingFilter(value, words) {
	let result

	if (
		(value % 100 > 9 && value % 100 < 20) ||
		(value % 10 > 4 && value % 10 < 10) ||
		value % 10 === 0
	) {
		result = words[2]
	} else if (value % 10 === 1) {
		result = words[0]
	} else {
		result = words[1]
	}

	return result
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		color: '#7E8389',
		fontWeight: 600,
		fontSize: 16,
		backgroundColor: '#EDF1FE',
		padding: '6px'
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		fontSize: 16,
		color: '#2E2E32',
		padding: '6px',
		lineHeight: '30px'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

const headCells = [
	{
		id: 'partpassId',
		label: 'ID'
	},
	{
		id: 'fullName',
		label: 'ФИО'
	},
	{
		id: 'phone',
		label: 'Телефон'
	},
	{
		id: 'email',
		label: 'Email'
	}
]

function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort
	} = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<StyledTableCell padding='checkbox'>
					<BpCheckbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all agents'
						}}
					/>
				</StyledTableCell>
				{headCells.map(headCell => (
					<StyledTableCell
						key={headCell.id}
						align={'left'}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

function EnhancedTableToolbar(props) {
	const { numSelected, setSelected, selected } = props
	const actions = useAuthStore(({ actions }) => actions)
	return (
		<Container style={{ width: '450px', margin: '30px auto' }}>
			{!!numSelected && (
				<Paper
					elevation={2}
					// className='table-toolbar-paper'
					style={{
						width: '100%',
						padding: '0 30px',
						borderRadius: '95px',
						height: '60px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}>
					<div className='table-toolbar-container'>
						<div className='toolbar-container-items'>{numSelected}</div>
						<div style={{ fontSize: '16px' }}>
							{wordEndingFilter(numSelected, [
								'агент выделен',
								'агента выделено',
								'агентов выделено'
							])}
						</div>
					</div>
					<Button
						onClick={() => {
							setSelected([])
							actions.deleteAgentsUser(selected)
						}}
						variant='outlined'
						// className='toolbar-delete-button'
						style={{
							background: '#ffffff',
							color: '#E73C3E',
							borderColor: '#E73C3E',
							borderRadius: '8px',
							textTransform: 'none',
							fontSize: 14,
							fontWeight: 600,
							fontFamily: 'Open Sans',
							height: '40px'
						}}>
						Удалить
					</Button>
					<IconButton
						size='small'
						onClick={() => {
							setSelected([])
						}}>
						<Close />
					</IconButton>
				</Paper>
			)}
		</Container>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
}

function TableToolbar(props) {
	const { numSelected } = props

	return (
		<>
			{numSelected === 0 && (
				<Toolbar
					sx={{
						pl: { sm: 2 },
						pr: { xs: 1, sm: 1 }
					}}>
					{numSelected === 0 ? (
						<Typography
							sx={{ flex: '1 1 100%' }}
							color='inherit'
							variant='subtitle1'
							component='div'>
							Ничего не найдено
						</Typography>
					) : null}
				</Toolbar>
			)}
		</>
	)
}

export default function AllAgents() {
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('partpassId')
	const [selected, setSelected] = React.useState([])
	const actions = useAuthStore(({ actions }) => actions)
	const agents = useAuthStore(({ agentsUsers }) => agentsUsers)
	const [openAgent, setOpenAgent] = React.useState([])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelected = agents
				.filter(item => !item.isArchived)
				.map(n => n.partpassId)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleClick = (event, name, disable) => {
		if (disable) {
			return
		}
		const selectedIndex = selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			)
		}

		setSelected(newSelected)
	}

	const isSelected = name => selected.indexOf(name) !== -1

	const [search, setSearch] = React.useState('')
	const filterAgents = React.useMemo(() => {
		return agents.filter(
			agent =>
				agent.partpassId
					.toString()
					.toLowerCase()
					.includes(search.toLowerCase()) ||
				agent?.login?.toLowerCase().includes(search.toLowerCase()) ||
				agent?.email?.toLowerCase().includes(search.toLowerCase()) ||
				agent?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				agent?.fullName?.toLowerCase().includes(search.toLowerCase())
		)
	}, [agents, search])

	const visibleRows = React.useMemo(
		() => stableSort(filterAgents, getComparator(order, orderBy)),
		[order, orderBy, filterAgents]
	)

	const addAgentInfo = value => {
		setOpenAgent(prev => [...prev, value.partpassId])
	}
	const deleteAgentInfo = value => {
		setOpenAgent(prev => prev.filter(partpassId => partpassId !== value))
	}

	const [openPopup, setOpenPopup] = React.useState(false)

	const handleClickOpen = () => {
		setOpenPopup(true)
	}
	const handleClose = () => {
		setOpenPopup(false)
	}

	const [openEditPopup, setOpenEditPopup] = React.useState(false)

	const handleClickEditOpen = id => {
		actions.setAgent(id)
		setOpenEditPopup(true)
	}
	const handleClickEditClose = () => {
		setOpenEditPopup(false)
	}
	return (
		<Box sx={{ width: '100%' }}>
			<EditAgentPopup
				handleClose={handleClickEditClose}
				openPopup={openEditPopup}
			/>
			<AddAgentPopup handleClose={handleClose} openPopup={openPopup} />
			<div className='agency-title' style={{ marginBottom: '24px' }}>
				<div style={{ minWidth: '300px' }}>
					<FormInput
						fullWidth
						onChange={e => setSearch(e.target.value)}
						placeholder='Введите ФИО или ID агента'
						variant='outlined'
						id='outlined-start-adornment'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Search color='disabled' />
								</InputAdornment>
							)
						}}
					/>
				</div>

				<Button
					onClick={handleClickOpen}
					variant='contained'
					size='large'
					style={{
						height: '56px',
						fontWeight: 600,
						borderRadius: '8px',
						background: '#4872f2',
						color: '#ffffff',
						textTransform: 'none',
						fontSize: 16,
						fontFamily: 'Open Sans'
					}}>
					Добавить агента
				</Button>
			</div>
			<Paper sx={{ width: '100%', mb: 2, borderRadius: '16px' }} elevation={2}>
				<TableContainer sx={{ borderRadius: '16px 16px 0 0' }}>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={agents.filter(item => !item.isArchived).length}
						/>
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = isSelected(row.partpassId)
								const labelId = `enhanced-table-checkbox-${index}`
								if (!openAgent.includes(row.partpassId)) {
									return (
										<StyledTableRow
											hover
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.partpassId}
											onClick={event =>
												handleClick(event, row.partpassId, row.isArchived)
											}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}>
											<StyledTableCell padding='checkbox'>
												<BpCheckbox
													disabled={row.isArchived}
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId
													}}
												/>
											</StyledTableCell>
											<StyledTableCell id={labelId} scope='row'>
												{row.partpassId}
											</StyledTableCell>
											<StyledTableCell align='left'>
												{row.fullName}
											</StyledTableCell>
											<StyledTableCell align='left'>
												{row.phone}
											</StyledTableCell>
											<StyledTableCell>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between'
													}}>
													<div>{row.email}</div>
													<div>
														<IconButton
															size='small'
															onClick={e => {
																e.stopPropagation()
																addAgentInfo(row)
															}}>
															<MoreVert />
														</IconButton>
													</div>
												</div>
											</StyledTableCell>
										</StyledTableRow>
									)
								} else
									return (
										<StyledTableRow
											hover
											onClick={event =>
												handleClick(event, row.partpassId, row.isArchived)
											}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.partpassId}
											style={{ verticalAlign: 'top' }}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}>
											<StyledTableCell padding='checkbox'>
												<BpCheckbox
													disabled={row.isArchived}
													style={{ marginTop: '6px' }}
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId
													}}
												/>
											</StyledTableCell>
											<StyledTableCell id={labelId} scope='row'>
												<div style={{ padding: '12px 0px' }}>
													{row.partpassId}
												</div>
											</StyledTableCell>
											<StyledTableCell align='left'>
												<Grid container style={{ padding: '12px 0px' }}>
													<Grid item xs={12}>
														{row.fullName}
													</Grid>
													<Grid item xs={12}>
														<span
															style={
																!row.isArchived
																	? { color: '#28BF68' }
																	: { color: '#E73C3E' }
															}>
															{!row.isArchived ? 'Активен' : 'Удален'}
														</span>
													</Grid>
													<Grid item xs={12}>
														<span>Должность: </span>
														<span>{row.position}</span>
													</Grid>
													<Grid item xs={12}>
														<span>Дата рождения: </span>
														{row?.birthday && (
															<span>
																{format(parseISO(row?.birthday), 'dd.MM.yyyy')}
															</span>
														)}
													</Grid>
													<Grid item xs={12}>
														<span>Дата создания: </span>
														{row?.createdDate && (
															<span>
																{format(
																	parseISO(row?.createdDate),
																	'dd.MM.yyyy'
																)}
															</span>
														)}
													</Grid>
												</Grid>
											</StyledTableCell>
											<StyledTableCell align='left'>
												<div style={{ padding: '12px 0px' }}>{row.phone}</div>
											</StyledTableCell>
											<StyledTableCell align='right'>
												<div
													className='table-cell-email'
													// style={{
													// 	display: 'flex',
													// 	justifyContent: 'space-between',
													// 	alignItems: 'start',
													// 	paddingTop: '12px'
													// }}
												>
													<div
														className='table-cell-email-button'
														// style={{
														// 	display: 'flex',
														// 	flexDirection: 'column',
														// 	alignItems: 'start'
														// }}
													>
														<div>{row.email}</div>

														<div>
															<span>Login: </span>
															<span>{row.login}</span>
														</div>
													</div>
													<IconButton
														size='small'
														onClick={e => {
															e.stopPropagation()
															deleteAgentInfo(row.partpassId)
														}}>
														<Close />
													</IconButton>
												</div>

												<div>
													<Button
														variant='outlined'
														style={{
															margin: '46px 5px 0px 0px',
															background: '#ffffff',
															color: '#4872f2',
															borderColor: '#4872f2',
															textTransform: 'none',
															fontSize: 14,
															fontFamily: 'Open Sans',
															borderRadius: '8px',
															fontWeight: 600,
															height: '40px',
															lineHeight: '24px'
														}}
														onClick={e => {
															e.stopPropagation()
															handleClickEditOpen(row)
														}}>
														Редактировать
													</Button>
												</div>
											</StyledTableCell>
										</StyledTableRow>
									)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TableToolbar numSelected={visibleRows.length} />
			</Paper>
			<EnhancedTableToolbar
				numSelected={selected.length}
				setSelected={setSelected}
				selected={selected}
			/>
		</Box>
	)
}
