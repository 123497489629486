import React from "react"
import styled from "styled-components"
import { Button, DatePicker, Select } from "../../ui"
import { useDispatch, useSelector } from "react-redux"
import { getAgentReport } from "../../../store/modules/agents/agent-finance/duck"
import { getAgentSiginState } from "../../../store/modules/agents/agent-finance/selectors"
import moment from "moment"
import agentReportImage from "../../../asset/images/avia/agents/agentReportImage.svg"
import ArrowButton from "./ArrowButton"
import Collapse from '@mui/material/Collapse';
import PrimaryButton from "../../ui/PrimaryButton"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(PrimaryButton)`
  min-width: 204px;
  min-height: 40px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isActive }) => (isActive ? "0" : "25px")};
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const AgentReportImage = styled.img.attrs({
  src: agentReportImage
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const SubText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 350;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  margin-top: 25px
`

const GridItem = styled.div`
  display: inherit;
  align-items: flex-end;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${({ isActive }) => (isActive ? "#4872F2" : "#dcdcdc")};
`

const AgentReport = ({
  dateReport,
  setDateReport,
  margin,
  typeReport,
  setTypeReport
}) => {
  const dispatch = useDispatch()
  const agent = useSelector(getAgentSiginState)
  const user = useSelector(x => x.user)
  const agentRequestsInfo = useSelector(state => state.agents.agentRequestsInfo)
  const [isOpen, setIsOpen] = React.useState(false)
  const [hover, setHover] = React.useState(false)
  const options = [
    {
      label: "Единый (все заявки в одном файле)",
      value: "Single",
      code: "Single"
    },
    {
      label: "Раздельный (каждая заявка в отдельном файле)",
      value: "Multiple",
      code: "Multiple"
    }
  ]

  const pluginOptions = [
    {
      label: "Единый (все заявки в одном файле)",
      value: "Single",
      code: "Single"
    }
  ]
  const initialOption = {
    label: "Единый (все заявки в одном файле)",
    value: "Single",
    code: "Single"
  }
  const isLoading = useSelector(state => state.agents.loadings.getAgentReport)

  return (
    <Container>
      <Row
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        isActive={margin}
      >
        <FlexRow>
          <AgentReportImage /> <Title>Получить отчет агента за месяц</Title>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Формируйте отчет агента за период.
      </SubTitle>
      <Collapse in={isOpen}>
        <div >
          <SubText style={{ marginBottom: 0 }}>
            Задайте интересующий вас период и получите pdf документ с отчетом
            агента. Отчет формируется за календарный месяц и только по
            оплаченным заявкам.
          </SubText>
          <Grid>
              <GridItem>
                <DatePicker
                  customInput={true}
                  isOnlyMonth
                  value={dateReport}
                  onChange={date => {
                    // @ts-ignore
                    setDateReport(date)
                  }}
                  label="Период от"
                  placeholder="мм.гггг"
                  icon
                />
              </GridItem>
              <GridItem>
                <Select
                  isSearchable={false}
                  options={
                    agentRequestsInfo?.finance?.reportChoice?.enabled
                      ? options
                      : pluginOptions
                  }
                  defaultValue={initialOption}
                  onChange={value =>
                    // @ts-ignore
                    setTypeReport(value.value)
                  }
                  label="Формат отчета"
                />
              </GridItem>
              <GridItem>
                <StyledButton
                  htmlType="submit"
                  disabled={!dateReport}
                  onClick={() => {
                    dispatch(
                      getAgentReport({
                        date: moment(dateReport).format("YYYY-MM"),
                        agentId: agent?.agentId ?? "",
                        ReportType: typeReport
                      })
                    )
                  }}
                  loading={isLoading}
                >
                    Экспортировать xls файл
                </StyledButton>
            </GridItem>
          </Grid>
        </div>
      </Collapse>
      <Separator isActive={hover} />
    </Container>
  )
}

export default AgentReport
