import * as React from 'react'
import { Box, Dialog } from '@material-ui/core'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import './style.css'
import { Link } from 'react-router-dom'

const MenuItems = ({ item, handleClickLink }) => (
	<>
		{item.is_active && (
			<li className={'header__submenu-item'} onClick={handleClickLink}>
				<Link className='v-text-links' to={item.url} target={item.target}>
					{item.title}
				</Link>
			</li>
		)}
	</>
)

export default function MobileMenuPopup({
	open,
	handleClose,
	list,
	title,
	setOpenDrawer
}) {

	const handleClickLink = () => {
		setOpenDrawer(false)
		handleClose()
	}
	return (
		<div>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<Box sx={{ p: 0 }}>
					<div className='header__submenu-return'>
						<ArrowBackIosRoundedIcon
							onClick={handleClose}
							fontSize='small'
							style={{ marginRight: '16px' }}
						/>
						<span className='v-text-links'>{title}</span>
					</div>
					<ul className='header__submenu-list'>
						{list
							?.filter(
								elem => elem?.user_roles?.includes('agent') || !elem.user_roles
							)
							.map(item => (
								<MenuItems
									key={item.id}
									item={item}
									handleClickLink={handleClickLink}
								/>
							))}
					</ul>
				</Box>
			</Dialog>
		</div>
	)
}
