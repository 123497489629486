import { isEmpty } from 'lodash'
import {
    emailRegExp,
    // phoneRegExp,
    interPassportNumberRegExp,
    cyrillicRegExp,
    seriesBirthCertificatesRegExp,
    rfPassportSeriesRegExp,
    interPassportSeriesRegExp,
    rfPassportNumberRegExp,
    birthCertNumberRegExp,
    latinUpperAndNumberRegExp,
    latinAndNumberRegExp
} from '../../../constants/reg-exps'

const phoneRegExp =
    /^7?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

export const checkValidationError = paxe => {
    let errors = {}
    if (!cyrillicRegExp.test(paxe.person.lastName) || !paxe.person.lastName) {
        errors = {
            ...errors,
            lastName: `Фамилия должна содержать только кириллицу`
        }
    }
    if (!cyrillicRegExp.test(paxe.person.firstName) || !paxe.person.firstName) {
        errors = {
            ...errors,
            firstName: `Имя должно содержать только кириллицу`
        }
    }
    if (
        !phoneRegExp.test(paxe.contactInformation?.phone) ||
        !paxe.contactInformation?.phone ||
        paxe.contactInformation?.phone.length !== 11
    ) {
        errors = {
            ...errors,
            phone: `Номер телефона указан некорректно`
        }
    }
    if (
        !emailRegExp.test(paxe.contactInformation?.email) ||
        !paxe.contactInformation?.email
    ) {
        errors = {
            ...errors,
            email: `Email указан некорректно`
        }
    }
    if (!cyrillicRegExp.test(paxe.person.patronymic) && !!paxe.person.patronymic) {
        errors = {
            ...errors,
            patronymic: `Отчество должно содержать только кириллицу`
        }
    }
    if (!paxe?.document?.expirationDate) {
        errors = {
            ...errors,
            expirationDate: `Дата не может быть пустой`
        }
    }
    if (!paxe?.person?.birthDate) {
        errors = {
            ...errors,
             birthDate: `Дата не может быть пустой`
        }
    }

    switch (paxe?.document?.type) {
        case 3:
            if (
                !interPassportSeriesRegExp.test(paxe?.document?.series) &&
                !!paxe?.document?.series
            ) {
                errors = {
                    ...errors,
                    series: `Серия должна содержать 2 цифры`
                }
            }
            break
        case 1:
            if (
                !rfPassportSeriesRegExp.test(paxe?.document?.series) &&
                !!paxe?.document?.series
            ) {
                errors = {
                    ...errors,
                    series: `Серия должна содержать 4 цифры`
                }
            }
            break
        case 2:
            if (
                !seriesBirthCertificatesRegExp.test(paxe?.document?.series) &&
                !!paxe?.document?.series
            ) {
                errors = {
                    ...errors,
                    series: `Серия должна содержать римское число и две кириллические буквы, через дефис`
                }
            }
            break
        case 4:
            if (paxe?.document?.series.length <= 5) {
                errors = {
                    ...errors,
                    series: `Серия не менее 5 знаков`
                }
            }
            break
        case 6:
            if (
                !interPassportSeriesRegExp.test(paxe?.document?.series) &&
                !!paxe?.document?.series
            ) {
                errors = {
                    ...errors,
                    series: `Серия должна содержать 2 цифры`
                }
            }
            break
        case 7:
            if (paxe?.document?.series.length <= 5) {
                errors = {
                    ...errors,
                    series: `Серия не менее 5 знаков`
                }
            }
            break
        default:
            break
    }

    switch (paxe?.document?.type) {
        case 3:
            if (
                !interPassportNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен быть 7 цифр`
                }
            }
            break
        case 1:
            if (
                !rfPassportNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен быть 6 цифр`
                }
            }
            break
        case 2:
            if (
                !birthCertNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен быть 6 цифр`
                }
            }
            break
        case 4:
            if (
                !latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен содержать только цифры и заглавные латинские буквы`
                }
            }
            break
        case 6:
            if (
                !latinAndNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
                }
            }
            break
        case 7:
            if (
                !latinUpperAndNumberRegExp.test(paxe?.document?.number) &&
                !!paxe?.document?.number
            ) {
                errors = {
                    ...errors,
                    number: `Номер должен содержать только цифры и заглавные латинские буквы`
                }
            }
            break
        default:
            break
    }
    return isEmpty(errors) ? null : errors
}

export function checkValidationBuyerError(buyer) {

    let errors = {}
    switch(buyer?.type) {
        case 0: 
            if (buyer.name === '') {
                errors = {
                    ...errors,
                    buyerName: `Имя клиента не должно быть пустым`
                }
            }
            if (
                !cyrillicRegExp.test(buyer.name)
            ) {
                errors = {
                    ...errors,
                    buyerName: `В имени покупателя допускается использовать только кириллицу`
                }
            } else if (!/^\S+\s+\S+(?:\s+\S+)?$/.test(buyer.name)
            ) {
                if (!/^\s*\S+(\s+\S+){0,2}\s*$/.test(buyer.name)) {
                    errors = {
                        ...errors,
                        buyerName: `В имени покупателя допускается не более 3 слова через пробел`
                    }
                } else {
                    errors = {
                        ...errors,
                        buyerName: `Укажите фамилию, имя и отчество клиента полностью`
                    }
                }
        
            }
            break  
        
        default:
            break
    }


    return isEmpty(errors) ? null : errors
}