import React from 'react';

import './style.scss'

const TransferDescriptionBlock = ({ draft, transfer }) => {

	return (
		<div className={'transfer-kiwi'}>
			<div className="block-header">
				<h3 className="title">Трансфер</h3>
			</div>
			<div className="block-body">
				<div className="grid-tour header">
					<div>Класс</div>
					<div>Маршрут</div>
					<div>Вместимость</div>
					<div>Кол-во пассажиров</div>
				</div>
				<div className="grid-tour body">
					<div>{transfer.type.name.ru}</div>
					<div>{transfer.route.placeFrom.name.ru} - {transfer.route.placeTo.name.ru}</div>
					<div>{transfer.type.pax} человека<br />{transfer.type.baggage} багажных места</div>
					<div>{draft.passengers.adults + draft.passengers.children + draft.passengers.infants}</div>
				</div>
			</div>
		</div>
	);
};

export default TransferDescriptionBlock;