// eslint-disable react-hooks/exhaustive-deps
import React from 'react';
import { Outlet } from "react-router-dom";
import NewNav from "../components/NewNav";

export default function UnAuthTemplate() {


	return (<>
		<NewNav/>
		<Outlet/>
	</>);
}