import React from 'react'
import HistoryItem from "./HistoryItem";

const HistoryBlock = ({statuses}) => {
  return (
    <>
    {statuses &&
      <div className="tte-request-history-container">
        <h4 className="tte-request-history-header">История заявок</h4>
        <div className="tte-request-history-items">
          {statuses.map((item, key) => (
            <HistoryItem key={key} data={item} id={key} />
          ))}
        </div>
      </div>
    }
    </>
  )
}

export default HistoryBlock
