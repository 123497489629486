import React, { useEffect } from 'react';
import useGeoStore from "../../store";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { isMobile } from "react-device-detect";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './style.scss'

const EachByCategory = ({ content, title }) => {
	const actions = useGeoStore(({ actions }) => actions)
	const oneNews = useGeoStore(({ oneNews }) => oneNews)

	useEffect(() => {
		content.news.forEach(item => {
			actions.getOneNews(item.category?.slug)
		})
		// eslint-disable-next-line
	}, [])

	if (isEmpty(oneNews)) return

	return (
		<div className={'news-each-category_wrapper'}>
			<div className='slider-header'>
				<h2>
					{title}
				</h2>
				{(oneNews.length > 4 && !isMobile) &&
					<div className='nav'>
						<div className='swiper-button-each-prev nav-button-news'>
							<div className='prev_chevron' />
						</div>
						<div className='swiper-button-each-next nav-button-news'>
							<div className='next_chevron' />
						</div>
					</div>
				}
			</div>
			<Swiper
				spaceBetween={30}
				slidesPerView={isMobile ? 1 : 4}
				modules={[FreeMode, Navigation]}
				navigation={oneNews.length > 4 ? {
					nextEl: ".swiper-button-each-next",
					prevEl: ".swiper-button-each-prev",
				} : false}
				watchOverflow={true}
			>
				{/* eslint-disable-next-line array-callback-return */}
				{oneNews.filter(news => news).map((item, index) =>
					<SwiperSlide key={index}>
						<Link key={item.id} to={`/expertmedia/${item.alias}`} className={'news-by-category__item'}>
							<div className={'news-by-category__item-wrapper'}>
								<div className={'news-by-category__item-img'}
									style={{ backgroundImage: `url(${content.news[index]?.icon || item.image_preview})` }} />
								{item.expert_news_tags[0].name && <div className={'news-by-category__item-tag'}>
									<span>{item.expert_news_tags[0].name}</span>
								</div>}
							</div>
							<p className={'news-by-category__item-title'}>{item.title}</p>
							<p className={'news-by-category__item-text'}>{item.description}</p>
						</Link>
					</SwiperSlide>
				)}
			</Swiper>
		</div>

	);
};

export default EachByCategory;