import useSearchTourStore from '../../SearchTour/store.js'
import CardRegion from "./CardRegion";
import groupTowns from '../../../functions/groupTowns.js';
import {convertTo} from "../../../functions/convertLang"
import {isMobile} from "react-device-detect";

const ListTowns = () => {
	const cities = useSearchTourStore((state) => state.cities)
	const citySearch = useSearchTourStore((state) => state.citySearch)
	const groupedTowns = groupTowns(cities)
	const arrivalRegionIds = useSearchTourStore((state) => state.arrivalRegionIds)
	const { clearFilterTowns, filterCity, clearFilterCityName } = useSearchTourStore((state) => state.actions)

	const findCity = (event) => {
		filterCity(event.target.value.toLowerCase())
	}

	const resetCityFilter = (event) => {
		if (event.target.checked)
			clearFilterTowns()
			clearFilterCityName()
	}

	const filteredGruppenTowns = () => {
		return Object.entries(groupedTowns)
			?.filter(el => el[0].toLowerCase()
			.includes(convertTo('ru', citySearch.toLowerCase()))) ||
		Object.entries(groupedTowns)
			?.filter(el => el[0].toLowerCase()
			.includes(convertTo('eng', citySearch.toLowerCase()))) 	
	}
	return (
		<div style={{ width: !isMobile ? '15%' : '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between", marginRight: !isMobile ? '10' : '0', marginBottom: 2 }}>
				<div style={{ display: 'flex',  justifyContent: "space-between"  }}>
					<span>
						Город
					</span>
					<label htmlFor="resetTownsFilter">
					<input id='resetTownsFilter' type='checkbox' checked={!arrivalRegionIds.length} onChange={resetCityFilter} />
						Любой
					</label>
				</div>
					<input type="text" value={citySearch} onChange={findCity} />
			</div>
			<div className="hotels__container cities">
				{cities && filteredGruppenTowns()?.map(([region, regionTown]) => {
					return <CardRegion key={regionTown.id} id={regionTown?.id?.toString()} name={region} towns={regionTown.towns} prefix={'region_'} />
				})}
			</div>
		</div>
	);
};

export default ListTowns;
