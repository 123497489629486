import React, { useState } from 'react';

import './style.scss'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { isIOS, isMobile } from "react-device-detect";
import useGetBanners from "../../../../hooks/useGetBanners";

const MainBannerSlider = ({ content }) => {

	const [isHover, setIsHover] = useState(false);
	const [isPressed, setIsPressed] = useState(false);

	const handleClick = (link) => {
		window.open(link, '_blank')
	}

	const photos = useGetBanners(content.mainBanner.sliderMainBanner?.photos)

	if (content.mainBanner.boolSliderMainBanner && !content.mainBanner.sliderMainBanner) {
		return null
	}
	const mobileShow = content.mainBanner.sliderMainBanner?.mobileShow

	const swiperSpeed = content.mainBanner.sliderMainBanner?.speed ? content.mainBanner.sliderMainBanner.speed : 2500
	const swiperDelay = content.mainBanner.sliderMainBanner?.timeOut * 1000
	const swiperAutoplay = content.mainBanner.sliderMainBanner?.boolAuto

	return <div style={isMobile && !mobileShow ? { display: 'none' } : null} className={`slider-main-banner ${content.mainBanner.sliderMainBanner.fullPage ? 'fullPage' : ''}`}>
		<div className={'wrap-slider'}>
			<Swiper
				spaceBetween={10}
				modules={[Autoplay, Pagination, Navigation]}
				pagination={{ clickable: true }}
				navigation={!isIOS}
				loop={true}
				speed={swiperSpeed}
				className="main-banner-slider"
				autoplay={swiperAutoplay ? {
					delay: swiperDelay,
					disableOnInteraction: false,
				} : false}
			>
				{photos?.map(item => <SwiperSlide key={item.uid}>
					<div className={'slider-main-banner__item'} style={{ backgroundImage: `url(${isMobile ? item.mobile : item.preview})`, alignItems: item.textAlign }}>
						<p className="sub-title-slide" style={{ textAlign: 'left', color: item.colorText }}>
							{item.subTitle}
						</p>
						<h2 className={'title-slide'} style={{ textAlign: 'left', color: item.colorText }}>
							{item.title}
						</h2>
						<p className="text-slide" style={{ textAlign: 'left', color: item.colorText }}>
							{item.text}
						</p>
						{item.buttonLink && item.buttonName && <button
							className={'button-slide'}
							style={{ color: item.colorButton?.text, backgroundColor: isHover ? (isPressed ? item.colorButton?.press : item.colorButton?.norm) : item.colorButton?.hover }}
							onMouseOver={() => setIsHover(true)}
							onMouseLeave={() => {
								setIsHover(false)
								setIsPressed(false)
							}}
							onMouseDown={() => setIsPressed(true)}
							onMouseUp={() => setIsPressed(false)}
							onClick={() => handleClick(item.buttonLink)}
						>
							{item.buttonName}
						</button>}
					</div>
				</SwiperSlide>)}
			</Swiper>
		</div>
		<Link style={content.mainBanner.sliderMainBanner.fullPage ? { display: 'none' } : null} className={`photo-link ${!content.mainBanner.sliderMainBanner.smallPhotoUp.link ? 'disabled' : ''}`} to={content.mainBanner.sliderMainBanner.smallPhotoUp.link} target="_blank">
			<div className={'small-photo-up'} style={{ backgroundImage: `url(${content.mainBanner.sliderMainBanner.smallPhotoUp.preview})` }} />
		</Link>
		<Link style={content.mainBanner.sliderMainBanner.fullPage ? { display: 'none' } : null} className={`photo-link ${!content.mainBanner.sliderMainBanner.smallPhotoDown.link ? 'disabled' : ''}`} to={content.mainBanner.sliderMainBanner.smallPhotoDown.link} target="_blank">
			<div className={'small-photo-down'} style={{ backgroundImage: `url(${content.mainBanner.sliderMainBanner.smallPhotoDown.preview})` }} />
		</Link>
	</div>

}

export default MainBannerSlider;