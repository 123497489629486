import React from 'react';

import './style.scss'
import {isMobile} from "react-device-detect";
import ArrowRight from '../../../../asset/icons/arrow-right-big.svg'
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

const MainBanner = ({ content, children, showBanner = true }) => {
    const isBannerWithText = !isEmpty(content.mainBanner.content.textBold) || !isEmpty(content.mainBanner.content.text)
    const isBannerVisible = isBannerWithText || content.mainBanner.image

    if (content.mainBanner.boolSliderMainBanner) {
        return null
    }
    const background = content.mainBanner.second_background ?
        `linear-gradient(180deg, ${content.mainBanner.background} 49.86%, ${content.mainBanner.second_background} 100%), ${content.mainBanner.background}` :
        content.mainBanner.background
    return (
        <div className={'container'}>
            {children}
            {showBanner && isBannerVisible &&  <div
                className={'static-main-banner__background'}
                style={{
                    background: background
                }}

            >
                <div className={'static-main-banner__wrapper'}>
                    {isBannerWithText && <div className={'static-banner-text'}>
                        <h1 className={'static-banner-text__big'}
                            style={{color: content.mainBanner.content.textBoldColor}}>{content.mainBanner.content.textBold}</h1>
                        <p className={'static-banner-text__small'}
                           style={{color: content.mainBanner.content.textColor}}>{content.mainBanner.content.text}</p>
                        {content.mainBanner.button.buttonLink && <div className={'button-block'}>
                            <div className={'button-block__button'}
                                 style={{background: content.mainBanner.button.buttonBackground}}>
                                <Link to={content.mainBanner.button.buttonLink} style={{WebkitMask: `url(${ArrowRight})`}}/>
                            </div>
                        </div>}
                    </div>}
                    {isMobile && content.mainBanner.mobileImage && <div className={'static-banner-image'}>
                        {content.mainBanner.mobileImage && <img
                            src={`${process.env.REACT_APP_GEO_CDN_PATH}${content.mainBanner.mobileImage}`}
                            alt=""/>}
                    </div>}
                    {!isMobile && <div className={'static-banner-image'} style={{width: isBannerWithText ? '50%' : '100%'}}>
                        {content.mainBanner.image && <img
                            src={`${process.env.REACT_APP_GEO_CDN_PATH}${content.mainBanner.image}`}
                            alt=""/>}
                    </div>}
                </div>
            </div>}
            {showBanner &&  <div className={'main-banner-plates'}>
                {!isEmpty(content.mainBanner.plates) && content.mainBanner.plates.map((item, index) =>
                    <React.Fragment key={index}>
                        {item.link && <a href={item.link} className={'main-banner-plate'}
                            style={{background: `${item.backgroundColour ? item.backgroundColour : '#EDFAF9'}`}}>
                            <div className={'main-banner-plate__icon'}>
                                <img src={`${item.icon}`} alt=""/>
                            </div>
                            <div className={'main-banner-plate__text'} style={{color: `${item.colourName}`}}>
                                <h3>{item.name}</h3>
                                <div>{item.previewText}</div>
                            </div>
                        </a>}
                        {!item.link && <div className={'main-banner-plate'}
                              style={{background: `${item.backgroundColour ? item.backgroundColour : '#EDFAF9'}`}}>
                            <div className={'main-banner-plate__icon'}>
                                <img src={`${item.icon}`} alt=""/>
                            </div>
                            <div className={'main-banner-plate__text'} style={{color: `${item.colourName}`}}>
                                <h3>{item.name}</h3>
                                <div>{item.previewText}</div>
                            </div>
                        </div>}
                    </React.Fragment>
                )
                }
            </div>}
        </div>
    );
};

export default MainBanner;