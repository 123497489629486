import React from 'react';
import styled from 'styled-components';
import CrossIconSrc from '../../asset/images/avia/tariff-features/cross.svg';
import CheckIconSrc from '../../asset/images/avia/tariff-features/check.svg';
import RubleIconSrc from '../../asset/images/avia/tariff-features/ruble.svg';
import { Text } from '../ui';
import getAirlinesLogo from '../../functions/getAirlineLogo'
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'
import PenaltyDescription from "./components/PenaltyDescription";


const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 655px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px 9px 24px 24px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      border-radius: 0;
      padding: 20px;
    }
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const List = styled.div`
  overflow: auto;
  max-height: 80vh;

  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 2.786px;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #E9EAEC;
    width: 2.786px;
    border-radius: 2px;
  }
  
  & > div {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const Item = styled.div``;

const ItemName = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
`;

const FeaturesList = styled.div`
  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
const Feature = styled.div`
  display: flex;

  & > img {
    margin-right: 5px;
  }
`;

const FeatureText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;
const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-right: 8px;
`;

const TariffsInfoModal = ({
  fares,
  ...props
}) => {

  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="close"
        onClick={(e)=>{
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      >
        <Icon/>
      </CloseButton>
      <PopupHead>
        <Title>
          <TitleText>Виды тарифных планов</TitleText>
          <img
            alt=""
            width={80}
            height={30}
            src={getAirlinesLogo(fares[0].carrier, {width: 160,height: 60,styleType: 'normal'})}
          />
        </Title>
      </PopupHead>
      <MobileHead>
        <MobileTitle>Тарифные планы</MobileTitle>{' '}
        <img
          alt=""
          width={40}
          height={15}
          src={getAirlinesLogo(fares[0].carrier,{width: 160,height: 60,styleType: 'normal'})}
        />
      </MobileHead>
      <List>
        <PenaltyDescription />
        {fares.map((x, key) => (
          <Item key={key}>
            <ItemName>{x.name}</ItemName>
            <FeaturesList>
              {x.features.map((x, key) => (
                <Feature key={key}>
                  <img width={18} height={18} alt="" src={getIcon(x.applicability)} />
                  <FeatureText>{`${getTitle(x.type)}: ${
                    x.descriptionRus
                  }`}</FeatureText>
                </Feature>
              ))}
            </FeaturesList>
          </Item>
        ))}
      </List>
    </StyledPopup>
  );
};

function getIcon(x) {
  switch (x) {
    case 'Included':
      return CheckIconSrc;
    case 'NotOffered':
      return CrossIconSrc;
    case 'AtCharge':
      return RubleIconSrc;
    default: 
      return RubleIconSrc;        
  }
}

function getTitle(type) {
  switch (type) {
    case 'Baggage':
      return 'Багаж';
    case 'CarryOn':
      return 'Ручная кладь';
    case 'Exchange':
      return 'Обмен';
    case 'Refund':
      return 'Возврат';
    default:
      return '';
  }
}

export default TariffsInfoModal;
