import React, { useState, useEffect } from 'react';

import Collapse from '@mui/material/Collapse';
import { CollapseContainer, Text } from '../../ui';
import HeaderContainer from '../../ui/HeaderContainer'
import useClaimsStore from '../../../store'
import { isEmpty } from 'lodash';
import MessagesBlock from './MessagesBlock';
import NewMessages from './newMessages';


function groupByType(array, key) {
	const groupedArray = [];

	if (isEmpty(array)) {
		return []
	}
	array.forEach(item => {
		const groupKey = item[key];
		const existingGroup = groupedArray.find(group => group.type === groupKey);

		if (existingGroup) {
			existingGroup.data.push(item);
		} else {
			groupedArray.push({ type: groupKey, data: [item] });
		}
	});
	return groupedArray;
}


function Messages({ id }) {
	const messages = useClaimsStore(({ messages }) => messages)
	const actions = useClaimsStore(({ actions }) => actions)
	const [isOpen, setIsOpen] = useState(false)
	const claimMessages = messages?.find(message => message.claimId === id)?.messages

	const nonRead = claimMessages?.filter(item => !item.isRead && item.isIncoming)

	useEffect(() => {
		actions.getMessages(id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const groupedMessages = groupByType(claimMessages, 'type')

	return (
		<CollapseContainer>
			<HeaderContainer isOpen={isOpen} onOpen={(open) => setIsOpen(open)}>
				<Text bold size={18} height={26}>Сообщения {isEmpty(nonRead) ? "" : <span style={{ color: 'rgba(72, 114, 242, 1)' }}>({nonRead.length})</span>}</Text>
			</HeaderContainer>
			<Collapse in={isOpen}>
				<NewMessages claim={id} />
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					{groupedMessages?.map((item) => <MessagesBlock key={item.type} message={item.data} type={item.type} claimId={id}></MessagesBlock>)}
				</div>
			</Collapse>

		</CollapseContainer>
	);
};

export default Messages