import * as React from 'react'
// import * as ReactDOM from "react-dom";
import { createBrowserRouter } from 'react-router-dom'
import FUN from '../asset/favicon.ico'
// import FUNNY from '../asset/images/faviconNY.png'

import SearchTour from '../pages/SearchTour'
import Booking from '../pages/Booking'
import BookingNew from '../pages/newBooking'
import Boarding from '../pages/Booking/components/Boarding'
import { MainTemplate, AviaTemplate, CabinetTemplate } from '../templates'
import Loyalty from '../pages/Loyalty'
import aviaRoutes from './aviaRoutes'
import cabinetRoutes from './cabinetRoutes'
import SalesAccents from '../pages/SalesAccents'
import Report from '../pages/ESRK/Report'
import UploadDocuments from '../pages/ESRK/UploadDocuments'
// import Hotel from "../pages/Hotel";
import MainPage from '../pages/MainPage'
import LandingPage from '../pages/LandingPage'
import ExpertNews from '../pages/ExpertNews'
import UnAuthTemplate from '../templates/UnAuthTemplate'
import unAuthRoutes from './unAuthRoutes'
import PaymentSuccessConfirmaition from '../pages/Avia/PaymentSuccessConfirmaition'
import PaymentFailureConfirmation from '../pages/Avia/PaymentFailureConfirmation'
import { Helmet } from 'react-helmet'
import Transfer from "../pages/Partner/TransferKiwi";
import PartnerBooking from "../pages/Partner/TransferKiwi/Booking";
import Insurances from "../pages/Partner/Insurances";
import NotFound from '../pages/NotFound'
import BookingInsurance from "../pages/Partner/Insurances/Booking";
import Country from '../pages/Country'
import DownloadTemplate from '../templates/DownloadTemplate'
import Visa from '../pages/Visa'
import Actions from '../pages/Actions'
import ActionPage from '../pages/ActionPage'
import Cabinet from '../pages/NewCabinet/Cabinet'
import UploadPlan from '../pages/NewCabinet/UploadPlan'
import lkRoutes from "./lkRoutes";
import NewCabinetTemplate from "../templates/NewCabinetTemplate";
import Excursion from "../pages/Excursion";
import InsuranceTemplate from "../templates/InsuranceTemplate";
import OnBoardServicesTemplate from "../templates/OnBoardServicesTemplate";
import OnBoardServicesRoutes from './OnBoardServicesRoutes'
import ExcursionBooking from "../pages/Excursion/Booking";
import AirportServices from "../pages/AirportServices";
import AirportServicesBooking from "../pages/AirportServices/Booking";
import HotelServices from "../pages/HotelServices";
import HotelServiceBooking from "../pages/HotelServices/Booking";
import YachtRentServices from "../pages/YachtRentServices";
import YachtRentBooking from "../pages/YachtRentServices/Booking";

const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
const franch = agentInfo?.agents?.name.includes('F&S') || agentInfo?.partPass === '91554' || agentInfo?.partPass === '121374'

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<>
				<Helmet
					title='FSTRAVEL B2B'
					link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
				/>
				<MainTemplate />
			</>
		),
		errorElement: <MainTemplate errorElement={<NotFound />} />,
		children: [
			{
				path: '',
				element: (
					<>
						<MainPage />
					</>
				)
			},
			{
				path: 'search_tour',
				element: (
					<>
						<Helmet title='Туры с перелетом' />
						<SearchTour withFlights={true} />
					</>
				)
			},
			{
				path: 'search_hotel',
				element: (
					<>
						<Helmet title='Отели | Круизы FUN&SUN' />
						<SearchTour withFlights={false} />
					</>
				)
			},
			{
				path: 'crm_search_tour',
				element: (
					<>
						<Helmet title='Туры с перелетом' />
						<SearchTour withFlights={true} fromCrm={true} />
					</>
				)
			},
			{
				path: 'bookingOld',
				element: (
					<>
						<Helmet title='Сайт для агентств - Туроператор FUN&SUN ' />
						<Booking />
					</>
				)
			},
			{
				path: 'booking',
				element: (
					<>
						<Helmet title='Сайт для агентств - Туроператор FUN&SUN ' />
						<BookingNew />
					</>
				)
			},
			{
				path: 'booking/transfer',
				element: (
					<>
						<Helmet title='Сайт для агентств - Туроператор FUN&SUN ' />
						<PartnerBooking />
					</>
				)
			},
			{
				path: 'booking/insurance',
				element: (
					<>
						<Helmet
							title='Сайт для агентств - Туроператор FUN&SUN '
							link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
						/>
						<BookingInsurance />
					</>
				)
			},
			{
				path: 'cabinet/plan-upload',
				element: <UploadPlan />,
			},
			// {
			// 	path: 'cabinet',
			// 	element: <CabinetTemplate />,
			// 	children: cabinetRoutes
			// },
			{
				path: 'boarding',
				element: <Boarding />
			},
			{
				path: 'loyalty',
				element: (
					<>
						<Helmet title='Программа лояльности FUN&SUN' />
						<Loyalty />
					</>
				)
			},
			{
				path: 'esrk/report',
				element: (
					<>
						<Helmet
							title='Документы на подпись - Отчет'
							link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
						/>
						<Report />
					</>
				)
			},
			{
				path: 'esrk/upload',
				element: (
					<>
						<Helmet
							title='Документы на подпись'
							link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
						/>
						<UploadDocuments />
					</>
				)
			},
			{
				path: '/countries',
				element: <Country />
			},
			{
				path: 'sales-accents',
				element: <SalesAccents />
			},
			{
				path: 'avia',
				element: <AviaTemplate />,
				children: aviaRoutes
			},
			{
				path: '/expertmedia/:alias',
				element: <ExpertNews />
			},
			{
				path: '/actions',
				element: <Actions />
			},
			{
				path: '/cabinet',
				element: franch ? <NewCabinetTemplate /> : <CabinetTemplate />,
				children: franch ? lkRoutes : cabinetRoutes
			},
			{
				path: '/actions/*',
				element: <ActionPage />
			},
			{
				path: '/searchtransfers',
				element: <Transfer />
			},
			{
				path: '/searchexcursions',
				element: <Excursion />
			},
			{
				path: 'booking/excursion',
				element: <ExcursionBooking />

			},
			{
				path: '/searchyachtrent',
				element: <YachtRentServices />
			},
			{
				path: '/booking/yachtrent',
				element: <YachtRentBooking />
			},

			{
				path: 'searchinsurances',
				element: <Insurances />
			},
			{
				path: '/searchhotelservices',
				element: <HotelServices />
			},
			{
				path: '/booking/hotelservices',
				element: <HotelServiceBooking />
			},
			{
				path: '/searchairportservices',
				element: <AirportServices />
			},
			{
				path: 'booking/airportservices',
				element: <AirportServicesBooking />
			},
			{
				path: 'ingosstrakhinsurance',
				element: <InsuranceTemplate path={'/ingosstrakhinsurance'} />
			},
			{
				path: 'compareinsurance',
				element: <InsuranceTemplate path={'/compareinsurance'} />
			},
			{
				path: 'alfainsurance',
				element: <InsuranceTemplate path={'/alfainsurance'} />
			},

			{
				path: 'ingosstrakhinsurance',
				element: <InsuranceTemplate path={'/ingosstrakhinsurance'} />
			},
			{
				path: 'compareinsurance',
				element: <InsuranceTemplate path={'/compareinsurance'} />
			},
			{
				path: 'alfainsurance',
				element: <InsuranceTemplate path={'/alfainsurance'} />
			},
			{
				path: '/countries/visa/*',
				element: <Visa />
			},
			{
				path: '/countries/visa',
				element: <Visa isMainPage />
			},
			{
				path: '*',
				element: <LandingPage />
			},
			{
				path: '/orders/:orderNumber/success-payment',
				element: (
					<>
						<Helmet title='Успешная оплата' />
						<PaymentSuccessConfirmaition />
					</>
				)
			},
			{
				path: '/orders/:orderNumber/failure-payment',
				element: (
					<>
						<Helmet title='Неуспешная оплата' />
						<PaymentFailureConfirmation />
					</>
				)
			},
		]
	},
	{
		path: '',
		element: <OnBoardServicesTemplate />,
		children: OnBoardServicesRoutes
	},
	{
		path: '',
		element: <UnAuthTemplate />,
		children: unAuthRoutes
	},
	{
		path: 'download/*',
		element: <DownloadTemplate />
	},
	{
		component: <NotFound />
	}
])

export default router
