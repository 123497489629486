import React from 'react';
import {SvgIcon} from "@mui/material";

const SortIcon = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 17 16" style={{width: 17, height: 16, marginLeft: 7}} >
			<path d="M17 15C17 14.45 16.55 14 16 14L0.999999 14C0.45 14 -6.3163e-07 14.45 -6.55671e-07 15C-6.79712e-07 15.55 0.45 16 0.999999 16L16 16C16.55 16 17 15.55 17 15ZM13 8C13 7.45 12.55 7 12 7L1 7C0.45 7 -3.2565e-07 7.45 -3.49691e-07 8C-3.73732e-07 8.55 0.45 9 1 9L12 9C12.55 9 13 8.55 13 8ZM8 -3.93402e-07C8.55 -3.69361e-07 9 0.45 9 1C9 1.55 8.55 2 8 2L1 2C0.450001 2 -6.77527e-08 1.55 -4.37114e-08 0.999999C-1.96701e-08 0.449999 0.450001 -7.23423e-07 1 -6.99382e-07L8 -3.93402e-07Z" fill="#2E2E32"/>
		</SvgIcon>
	);
};

export default SortIcon;