import { useState, useEffect } from 'react'

import { Alert } from '@mui/material'
import { Box, TextField } from '@material-ui/core'
import useBookingStore from '../../store'
import CustomSelect from '../../../../components/Select'
import { nationality } from '../PaxesBlock/components/MembersBlock/nationalities'
import { emailRegExp, phoneRegExp } from '../../../../constants/reg-exps'
import BirthDateInput from './BirthDateInput'
import { transliterateLatinToCirillic } from '../../../../functions/convertLang'
import { useErrorStore } from '../PaxesBlock/components/MembersBlock/store'

const BUYER_TYPE = [
	{
		name: 'Физическое лицо',
		id: '0'
	},
	{
		name: 'Юридическое лицо',
		id: '1'
	},
	{
		name: 'Индивидуальный предприниматель',
		id: '2'
	}
]

function BuyerBlock() {
	const buyer = useBookingStore(({ buyer }) => buyer)
	const peoples = useBookingStore(({ peoples }) => peoples)
	const actions = useBookingStore(({ actions }) => actions)
	const valid = useErrorStore(({ valid }) => valid)
	const isReadyToReserve = useBookingStore(({ isReadyToReserve }) => isReadyToReserve)

	console.log(buyer)

	const errors = useBookingStore(({ buyerErrors }) => buyerErrors)
	// const notesErrors = useBookingStore(({ notesErrors }) => notesErrors)


	const [selectedPaxe, setSelectedPaxe] = useState(null)

	const changeBuyerSelect = props => event => {
		if (props === 'nationalityKey') {
			actions.changeBuyer(props, event.toString())
		} else {
			actions.changeBuyer(props, event)
		}
	}

	const changeBuyerType = props => event => {
		actions.clearCurrentError()
		actions.clearBuyerField()
		actions.changeBuyer(props, Number(event))
	}

	const changeNameHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		const value = transliterateLatinToCirillic(event.target.value)
		switch (prop) {
			case 'name':
				if (!/^[а-яА-Я-–Ё\u0020]+$/.test(value) && value !== '') {
					actions.setBuyerErrors(prop, `ФИО должно содержать только кириллицу`)
					// return
				} else {
					actions.clearBuyerErrors(prop)
				}
				actions.changeBuyer(prop, value)

				break
			case 'boss':
				if (!/^[а-яА-Я-–Ё\u0020]+$/.test(value) && value !== '') {
					actions.setBuyerErrors(
						prop,
						`ФИО руководителя должно содержать только кириллицу`
					)
					// return
				} else {
					actions.clearBuyerErrors(prop)
				}
				actions.changeBuyer(prop, value)

				break
			case 'firmName':
				const firmValue = event.target.value.toUpperCase()
				if (
					!/^[а-яА-Яa-zA-Z0-9-–.,"Ё\u0020]+$/.test(firmValue) &&
					firmValue !== ''
				) {
					actions.setBuyerErrors(
						prop,
						`Официальное название должно содержать только кириллицу, латиницу, цифры или спец.символы`
					)
					// return
				} else {
					actions.clearBuyerErrors(prop)
				}
				actions.changeBuyer('firmName', firmValue)

				break
			default:
				break
		}
	}
	const changeAddressHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		if (
			!/^[a-zA-Zа-яА-ЯёЁ0-9-–,./\u0020]+$/.test(event.target.value) &&
			event.target.value !== ''
		) {
			actions.setBuyerErrors(
				prop,
				`Адрес должнен содержать только латиницу, кириллицу или спец.символы`
			)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changePhoneHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		if (!phoneRegExp.test(event.target.value) && event.target.value !== '') {
			actions.setBuyerErrors(prop, `Номер телефона указан некорректно`)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changeEmailHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			actions.setBuyerErrors(prop, `Email указан некорректно`)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changeNumberHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		if (
			!/^[0-9a-zA-Z]+$/.test(event.target.value) &&
			event.target.value !== ''
		) {
			actions.setBuyerErrors(
				prop,
				`Указан некорректно. Допустим ввод цифр и латиницы`
			)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changeInnHandler = prop => event => {
		actions.clearBuyerErrors(prop)
		if (!/^[0-9]+$/.test(event.target.value) && event.target.value !== '') {
			actions.setBuyerErrors(
				prop,
				`ИНН указан неверно. Допустим ввод только цифр`
			)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changeDocSum = prop => event => {
		actions.clearBuyerErrors(prop)
		if (
			!/^[0-9\s\-\–\.,\/]+$/.test(event.target.value) &&
			event.target.value !== ''
		) {
			actions.setBuyerErrors(
				prop,
				`Сумма договора с клиентом указана неверно. Допустим ввод цифр и спец.символов`
			)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const changeDocNumber = prop => event => {
		actions.clearBuyerErrors(prop)
		if (
			!/^[a-zA-Zа-яА-Я0-9\s\-\–\.,\/]+$/.test(event.target.value) &&
			event.target.value !== ''
		) {
			actions.setBuyerErrors(
				prop,
				`Номер договора с клиентом указан неверно. Допустим латиницы, кириллицы, ввод цифр и спец.символов`
			)
		} else {
			actions.clearBuyerErrors(prop)
		}
		actions.changeBuyer(prop, event.target.value)
	}

	const selectPaxe = value => {
		const existPeople = peoples?.find(paxe => paxe.id === value)
		if (!existPeople) {
			return
		}
		setSelectedPaxe(value)
	}
	return (
		<>
			{/* <Snackbar
				open={!isEmpty(notesErrors)}
				autoHideDuration={4000}
				onClose={() => actions.clearNotesErrors()}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Stack flexDirection='column' gap={1}>
					{notesErrors.map(item => {
						return (
							<Alert
								onClose={() => actions.clearNotesErrors()}
								severity={'error'}
								sx={{ width: '300px' }}>
								{`Заказчик: ${item}`}
							</Alert>
						)
					})}
				</Stack>
			</Snackbar> */}
			<div
				style={{ marginTop: '32px' }}
				className='block-header'
				data-errorsValidation={Object.values(errors).some(el => el)}
				id='buyer-block-info'>
				<h3 className='title'>
					<span>Информация о заказчике</span>
				</h3>
			</div>

			<Alert severity='error' sx={{ width: '100%' }}>
				Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
				информацию о заказчике!
			</Alert>
			<div className='members-form'>
				<div className='info'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={buyer?.buyerType.toString()}
							options={BUYER_TYPE}
							title='Тип клиента'
							count={1}
							onChange={changeBuyerType('buyerType')}
							required
						/>
					</Box>
					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={selectedPaxe?.key}
								options={peoples.map(people => ({
									id: people.key,
									name: `Турист №${people.key}`
								}))}
								title='Турист из заявки'
								count={1}
								onChange={selectPaxe}
								required
							/>
						</Box>
					)}

					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='ФИО по-русски'
								variant='outlined'
								fullWidth
								value={buyer?.name || ''}
								onChange={changeNameHandler('name')}
								helperText={errors.name}
								// onBlur={(event) => onBlur('name', event)}
								error={!!errors.name}
								required
								title='Допустим ввод кириллицы и спец.символов "-", " ", "–"'
							/>
						</Box>
					)}

					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Адрес'
							variant='outlined'
							fullWidth
							value={buyer?.address || ''}
							onChange={changeAddressHandler('address')}
							helperText={errors?.address}
							// onBlur={(event) => onBlur('name', event)}
							error={!!errors?.address}
							required
							title=''
						/>
					</Box>
					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Серия документа'
								variant='outlined'
								fullWidth
								helperText={errors.pserie}
								error={!!errors.pserie}
								value={buyer?.pserie || ''}
								onChange={changeNumberHandler('pserie')}
								title='Серия паспорта может содержать только цифры и латинские буквы'
								required
							/>
						</Box>
					)}
					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Номер документа'
								variant='outlined'
								fullWidth
								helperText={errors.pnumber}
								error={!!errors.pnumber}
								value={buyer?.pnumber || ''}
								onChange={changeNumberHandler('pnumber')}
								title='Номер документа должен содержать только цифры и латинские буквы'
								required
							/>
						</Box>
					)}
					{buyer?.buyerType === 1 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Официальное название'
								variant='outlined'
								fullWidth
								helperText={errors.firmName}
								error={!!errors.firmName}
								value={buyer?.firmName || ''}
								onChange={changeNameHandler('firmName')}
								required
							/>
						</Box>
					)}

					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Телефон'
							variant='outlined'
							fullWidth
							helperText={errors.phone}
							error={!!errors.phone}
							value={buyer?.phone || ''}
							onChange={changePhoneHandler('phone')}
							required
						/>
					</Box>
					{buyer?.buyerType === 1 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='ФИО руководителя'
								variant='outlined'
								fullWidth
								value={buyer?.boss || ''}
								onChange={changeNameHandler('boss')}
								helperText={errors.boss}
								// onBlur={(event) => onBlur('name', event)}
								error={!!errors.boss}
								title='Допустим ввод кириллицы'
							/>
						</Box>
					)}
					<Box>
						<TextField
							label='E-Mail'
							variant='outlined'
							fullWidth
							helperText={errors.email}
							error={!!errors.email}
							value={buyer?.email || ''}
							onChange={changeEmailHandler('email')}
							required
						/>
					</Box>
				</div>
				<div className='docs'>
					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={buyer?.nationalityKey || '210357'}
								options={nationality}
								title='Гражданство'
								count={1}
								// onChange={actions.changeNationality}
								onChange={changeBuyerSelect('nationalityKey')}
								required
							/>
						</Box>
					)}
					{buyer?.buyerType === 1 && (
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={buyer?.firm_state || '210357'}
								options={nationality}
								title='Страна'
								count={1}
								// onChange={actions.changeNationality}
								onChange={changeBuyerSelect('firm_state')}
								required
							/>
						</Box>
					)}
					{buyer?.buyerType !== 1 && (
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={buyer?.identity_document || 1}
								options={[
									{ id: '0', name: 'Иной документ' },
									{ id: 1, name: 'Паспорт' },
									{ id: 3, name: 'Свидетельство о рождении' },
									{ id: 4, name: 'Заграничный паспорт' },
									{ id: 5, name: 'ID карта' }
								]}
								title='Тип документа'
								count={1}
								onChange={changeBuyerSelect('identity_document')}
								required
							/>
						</Box>
					)}
					{buyer?.buyerType !== 0 && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='ИНН'
								variant='outlined'
								fullWidth
								helperText={errors.inn}
								error={!!errors.inn}
								value={buyer?.inn || ''}
								onChange={changeInnHandler('inn')}
								required
							/>
						</Box>
					)}
					<Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={changeBuyerSelect('agencyDocDate')}
							count={1}
							label='Дата договора с клиентом'
							value={buyer?.agencyDocDate || null}
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер договора с клиентом'
							variant='outlined'
							fullWidth
							helperText={errors.agencyDocNumber}
							error={!!errors.agencyDocNumber}
							value={buyer?.agencyDocNumber || ''}
							onChange={changeDocNumber('agencyDocNumber')}
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Сумма договора с клиентом'
							variant='outlined'
							fullWidth
							helperText={errors.agencyDocSum}
							error={!!errors.agencyDocSum}
							value={buyer?.agencyDocSum || ''}
							onChange={changeDocSum('agencyDocSum')}
						/>
					</Box>
				</div>
			</div>
		</>
	)
}

export default BuyerBlock
