import React, {useEffect, useState} from "react"
import styled from "styled-components"
import { Text, Button,Separator } from "../../../ui"
import OutlinedButton from '../../../ui/OutlinedButton'
import moment from "moment"
import { useOrderPosition } from "./useItemProps"
import Flights from "./FligthsList"
import DetailFLightList from "./FlightsListDetailed"
import {
  getFlightsInfo,
  AvailableActions,
  cancelOrderPositionRequest, refreshOrder
} from "../../../../store/modules/orders"
import formatPrice from "../../../../functions/formatPrice"
import formatDuration from "../../../../functions/formatDuration"
import Api from '../../../../functions/fetchApi'
import { getUserState } from "../../../../store/modules/user"
import { CreateAppealModal } from "../../../modals"
import { createFromToString } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
// import ReactGA from 'react-ga4';
import {ReactComponent as PrinterIcon} from "../../../../asset/images/avia/order-detail/printer.svg"
import {ReactComponent as  CircleIcon} from "../../../../asset/images/avia/order-detail/info-circle.svg"
import paymentIcon from "../../../../asset/images/avia/order-detail/payment.svg"
import { ReactComponent as CancelIcon} from "../../../../asset/images/avia/order-detail/cancel.svg"
import refundIcon from "../../../../asset/images/avia/order-detail/refund.svg"
import {ReactComponent as  ReturnIcon} from "../../../../asset/images/avia/order-detail/change.svg"
import {ReactComponent as  ExchangeIcon} from "../../../../asset/images/avia/order-detail/return.svg"
import SvgIcon from '@mui/material/SvgIcon';
import useClaimsStore from "../../../../pages/Cabinet/Claims/store";

const BlockWithPadding = styled.div`
  padding: 0 24px;
`

const Wrapper = styled.div`
  position: relative;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }

  margin-bottom: 25px;
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`

const TextTop = styled.div`
  margin-bottom: 10px;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const GrayText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`

const BlueText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(72, 114, 242, 1);

  ${({ red }) => (red ? "color: #F64C50;" : "color: rgba(72, 114, 242, 1);")}
`

const PriceTitle = styled(GrayText)`
  display: block;
`

const ActionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;

  & > button {
    margin-right: 12px;
    margin-bottom: 12px;
  }
`

const BlueActionButton = styled(Button).attrs({ type: "primary" })`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;

  & > img {
    margin-right: 6px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-tems: center;
  flew-wrap: wrap;
`

const Head = () => {
  const props = useOrderPosition()
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate();
  const userState = useSelector(getUserState)
  const isCancellingOrder = useSelector(
    x => x.orders.orderDetail.orderPositionCancelStatus
  )
  const [isInitial, setIsInitial] = useState(true)
  const [isProcessingCancel, setIsProcessingCancel] = useState(false)

  const isAccountant = userState.data && userState.data.role === "Accountant"

  // eslint-disable-next-line no-unused-vars
  const [appeal, setAppeal] = React.useState({ open: false })
  const fromTo = createFromToString(props.segmentGroups)

  const stillUtc = moment.utc(props.cancellationTime).toDate()
  const localCancellationTime = moment(stillUtc).local()
  const duration = localCancellationTime.diff(moment(), "minutes")

  // eslint-disable-next-line no-unused-vars
  const handleAppealClose = () => setAppeal({ open: false })

  const flightListProps = getFlightsInfo(props)

  const printLink = Api.get(process.env.REACT_APP_PRINT_ORDER).query({ orderId: props.orderId, orderPositionId: props.id, format: "pdf" }).toString()

  const printRefundLink = Api.get(process.env.REACT_APP_PRINT_REFUND_BLANK).query({
    orderId: props.orderId,
    orderPositionId: props.id,
    format: 1
  }).toString();

  // если рейс чартерный и статус позиции "отложенная выписка", то открываем только три темы в поддержке
  // TODO: причесать это правило, доступное действие должно приходить с бэка
  // eslint-disable-next-line no-unused-vars
  const onlyOtherRefundReturnSubjects =
    props.flight_Type === "Charter" && props.status.code === "DelayedIssue"
  // eslint-disable-next-line no-unused-vars
  const onlyOtherSubject = (props.availableActions || []).includes(
    "CREATE_APPEAL_WITH_ONLY_OTHER_THEME"
  )

  useEffect( () => {
    if (!isCancellingOrder && !isInitial) {
      setIsProcessingCancel(true)
      dispatch(refreshOrder())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancellingOrder]);

  const handleActionClick = e => {
    e.stopPropagation()
    const target = e.target
    const value = target.dataset.value
    const actionType = target.dataset.action


    switch (actionType) {
      case "payment": {
        navigate(
          `/redirect-payment?orderId=${Number(params.id)}&orderPositionId=${
            props.id
          }`
        )
        break
      }
      case "cancel": {
        dispatch(
          cancelOrderPositionRequest({
            orderId: props.orderId,
            orderPositionId: props.id
          })
        )
        setIsInitial(false)
        break
      }
      case "appeal": {
        setAppeal({ open: true, subject: value })
        break
      }

      case "print":
        window.open(printLink, "_blank")
        break
      case "refund-print":
        window.open(printRefundLink, "_blank")
        break
      default:
        window.open(printRefundLink, "_blank")
        break
    }
  }

  const title = React.useMemo(() => {
    if (!props.status) {
      return "Аннулированная позиция"
    }
    switch (props.status.code) {
      case "Canceled":
        return "Отмененная позиция"
      case "Annulated":
      case "Refunded":
        return "Аннулированная позиция"
      default:
        return "Позиция заявки"
    }
  }, [props.status])

  return (
    <Wrapper>
      <BlockWithPadding>
        <Top>
          <div>
            <TextTop>
              <Title color={props.disabled ? "#F64C50" : undefined}>
                {title}:{" "}
                <Title color={props.disabled ? "#F64C50" : "blue"}>
                  {fromTo}
                </Title>
              </Title>
            </TextTop>
            <Bottom>
              {props.paymentStatus?.code === "Paid" &&
                props?.airlineOnlineBookingCode?.fullName === null && (
                  <GrayText>
                    Бронь № {props.bookingCode} от{" "}
                    {moment(props.createdDate).format("DD.MM.YYYY")}
                  </GrayText>
                )}
              <GrayText>
                Cтатус позиции:{" "}
                <BlueText red={props.disabled}>
                  {props.status?.description}
                </BlueText>
              </GrayText>
              <GrayText>
                Статус оплаты:{" "}
                <BlueText red={props.disabled}>
                  {props.paymentStatus?.description}
                </BlueText>
              </GrayText>
              {duration > 0 &&
                (props.availableActions || []).includes("PAYMENT") && (
                  <GrayText>
                    Время для оплаты:{" "}
                    <BlueText red={props.disabled}>
                      {formatDuration(duration)}
                    </BlueText>
                  </GrayText>
                )}
              {props?.airlineOnlineBookingCode?.fullName !== null && (
                <FlexContainer>
                  <GrayText>
                    Номер для онлайн регистрации: &nbsp;
                    {props?.airlineOnlineBookingCode?.bookingCodes?.map(
                      (code, index) => (
                        <>
                          {code?.airlines?.length === 1 ? (
                            <>
                              <span style={{ color: "#4872F2" }}>
                                {code.code}
                              </span>
                              &nbsp;
                              <span style={{ fontWeight: "600" }}>
                                ({code?.airlines[0].name}) &nbsp;
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: "#4872F2" }}>
                                {code.code}
                              </span>{" "}
                              &nbsp;
                              {code?.airlines?.map((airline, i) => (
                                <>
                                  <span style={{ fontWeight: "600" }}>
                                    ({airline.name}) &nbsp;
                                  </span>
                                </>
                              ))}
                            </>
                          )}
                        </>
                      )
                    )}
                  </GrayText>
                </FlexContainer>
              )}
            </Bottom>
          </div>
          <div>
            <PriceTitle>Стоимость:</PriceTitle>
            <Title color={props.disabled ? "#3C3C3C" : "blue"}>
              {formatPrice(props.amount)}
            </Title>
          </div>
        </Top>
        {(props.availableActions || []).length > 0 && (
          <ActionsList>
            {props.availableActions.includes(AvailableActions.PRINT) && (
              <OutlinedButton startIcon={<SvgIcon sx={{width: '18px', height: '18px',userSelect: 'none'}}>
              <PrinterIcon/>
            </SvgIcon>} variant="outlined" data-action="print" onClick={handleActionClick}>
                Печать билета
              </OutlinedButton>
            )}
            {!isAccountant && (
              <>
                {props.availableActions.includes(
                  AvailableActions.REFUND_BLANK
                ) && (
                  <BlueActionButton
                    data-action="refund-print"
                    onClick={handleActionClick}
                  >
                    <img src={refundIcon} alt="" />
                    Квитанция на возврат
                  </BlueActionButton>
                )}
                {props.availableActions.includes(AvailableActions.PAYMENT) &&
                  !userState?.data?.agentId && (
                    <BlueActionButton
                      data-action="payment"
                      onClick={handleActionClick}
                    >
                      <img src={paymentIcon} alt="" />
                      Оплатить позицию
                    </BlueActionButton>
                  )}
                {props.availableActions.includes(AvailableActions.CANCEL) && (
                  <OutlinedButton
                    startIcon={<CancelIcon/>}
                    loading={isCancellingOrder || isProcessingCancel}
                    spinnerColor={'#4872F2'}
                    data-action="cancel"
                    onClick={handleActionClick}
                  >
                    Отменить позицию
                  </OutlinedButton>
                )}
                {props.availableActions.includes(
                  AvailableActions.CREATE_APPEAL_WITH_ANY_THEME
                ) && (
                  <>
                    <OutlinedButton
                      startIcon={<ExchangeIcon/>}
                      data-value="FullExchange"
                      data-action="appeal"
                      onClick={handleActionClick}
                    >
                      Обмен
                    </OutlinedButton>
                    <OutlinedButton startIcon={<ReturnIcon/>} data-value="FullRefund" data-action="appeal"  onClick={handleActionClick}>
                      Возврат
                    </OutlinedButton>
                  </>
                )}
                {(props.availableActions.includes(
                  AvailableActions.CREATE_APPEAL_WITH_ANY_THEME
                ) ||
                  props.availableActions.includes(
                    AvailableActions.CREATE_APPEAL_WITH_ONLY_OTHER_THEME
                  )) && (
                  <OutlinedButton
                    startIcon={<CircleIcon/>}
                    data-action="appeal"
                    data-value="FullExchange"
                    onClick={handleActionClick}
                  >
                    Служба поддержки
                  </OutlinedButton>
                )}
              </>
            )}
          </ActionsList>
        )}
      </BlockWithPadding>

      {(props.availableActions || []).length > 0 && <Separator />}
      <BlockWithPadding>
        {(!props.isOpen || flightListProps.transferCount === 0) && (
          <Flights
            {...flightListProps}
            onClick={() => {
              props.setOpen(!props.isOpen)
            }}
          />
        )}
        {props.isOpen && flightListProps.transferCount > 0 && (
          <DetailFLightList items={props.segmentGroups} />
        )}
      </BlockWithPadding>
      <CreateAppealModal
        insurances={props.insurances}
        onlyOtherSubject={
          onlyOtherRefundReturnSubjects ? false : onlyOtherSubject
        }
        onlyOtherRefundReturnSubjects={onlyOtherRefundReturnSubjects}
        open={appeal.open}
        onClose={handleAppealClose}
        orderPosition={props}
        defaultSubject={appeal.subject}
      />
    </Wrapper>
  )
}

export default Head
