import { Box, FormControl, MenuItem, Select } from '@material-ui/core'
import React, { useMemo } from 'react'
import {
	useAviaCompanyStore,
	useBackwardFlightStore,
	useForwardFlightStore
} from './store'
import '../style.css'
import Slider from '@mui/material/Slider'
import { alpha, styled } from '@mui/material/styles'
import { marks } from '../../../../../constants/marks'
// import useBookingStore from "../../../store";
// import {isEmpty} from "lodash";

function valuetext(value) {
	return value
}

const SuccessSlider = styled(Slider)(() => ({
	color: '#e6d196',
	'& .MuiSlider-thumb': {
		'&:hover, &.Mui-focusVisible': {
			boxShadow: `0px 0px 0px 8px ${alpha('#e6d196', 0.2)}`
		},
		'&.Mui-active': {
			boxShadow: `0px 0px 0px 14px ${alpha('#e6d196', 0.2)}`
		}
	}
}))

const valueLabelFormat = value => {
	return marks?.find(mark => mark.value === value)?.label
}

export default function AviaFilter({ flights }) {
	const [aviaFilter, setAviaFilter] = React.useState(true)

	const [value1, setValue1] = React.useState([0, 100])
	const setForwardFlightDate = useForwardFlightStore(
		state => state.setForwardFlightDate
	)
	const setBackwardFlightDate = useBackwardFlightStore(
		state => state.setBackwardFlightDate
	)

	// const fares = useBookingStore(({fares}) => fares)

	// const isBusinessEnabled = !isEmpty(fares?.filter(item => item.name.includes('BUSINESS')))

	const handleChange1 = (event, newValue) => {
		setValue1(newValue)
		setForwardFlightDate([
			valueLabelFormat(newValue[0]),
			valueLabelFormat(newValue[1])
		])
	}

	const [value2, setValue2] = React.useState([0, 100])

	const handleChange2 = (event, newValue) => {
		setValue2(newValue)
		setBackwardFlightDate([
			valueLabelFormat(newValue[0]),
			valueLabelFormat(newValue[1])
		])
	}

	// const [classFlight, setClassFlight] = React.useState('')
	// const handleChangeClass = event => {
	// 	setClassFlight(event.target.value)
	// }
	
	const [aviaCompany, setAviaCompany] = React.useState('')
	const setCurrentAviaCompany = useAviaCompanyStore(
		state => state.setCurrentAviaCompany
	)
	const handleChangeAviaCompany = event => {
		setAviaCompany(event.target.value)
		setCurrentAviaCompany(event.target.value)
	}
	// console.log(aviaCompany)

	const aviaCompanies = useMemo(() => {
		return [
			...new Set(
				flights.map(item => item.forwardFlight.segments[0].aviacompany)
			)
		].reduce((acc, cur) => {
			if (!acc.find(v => v.id === cur.id)) {
				acc.push(cur)
			}
			return acc
		}, [])
	}, [flights])
	return (
		<>
			<div className='filter-header'>
				<div>Фильтр</div>
				{aviaFilter ? (
					<div className='open-filter' onClick={() => setAviaFilter(false)}>
						Свернуть фильтр
					</div>
				) : (
					<div className='open-filter' onClick={() => setAviaFilter(true)}>
						Развернуть фильтр
					</div>
				)}
			</div>
			{aviaFilter && (
				<>
					{/* {isBusinessEnabled && <div className='flex-avia'>
						<div className='flight-date'>Класс обслуживания</div>
						<Box
							sx={{
								width: 550,
								margin: '10px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<FormControl
								variant='outlined'
								style={{width: '100%'}}
								size='small'>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									value={classFlight}
									onChange={handleChangeClass}
									displayEmpty
									inputProps={{'aria-label': 'Without label'}}>
									<MenuItem value=''>
										<em>Все</em>
									</MenuItem>
									<MenuItem value={'Бизнес'}>Бизнес</MenuItem>
									<MenuItem value={'Эконом'}>Эконом</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</div>} */}
					<div className='flex-avia'>
						<div className='flight-date'>Авиакомпания</div>
						<Box
							sx={{
								width: 550,
								margin: '10px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<FormControl
								variant='outlined'
								style={{ width: '100%' }}
								size='small'>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									value={aviaCompany}
									onChange={handleChangeAviaCompany}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}>
									<MenuItem value=''>
										<em>Все</em>
									</MenuItem>
									{aviaCompanies?.map(item => (
										<MenuItem value={item.id} key={item.id}>
											{item.name}
										</MenuItem>
									))}
									{/* <MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>
						</Box>
					</div>
					<div className='flex-avia'>
						<div className='flight-date'>Время вылета тура</div>
						<Box
							sx={{
								width: 540,
								margin: '10px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<SuccessSlider
								value={value1}
								onChange={handleChange1}
								valueLabelFormat={valueLabelFormat}
								valueLabelDisplay='auto'
								getAriaValueText={valuetext}
								disableSwap
								marks={marks}
								step={null}
								size='large'
								sx={{
									// color: '#ff9100',
									'& .MuiSlider-thumb': {
										borderRadius: '1px'
									}
								}}
							/>
							{/* <div className='flight-date'>
        От {valueLabelFormat(value1[0])} до {valueLabelFormat(value1[1])}
      </div> */}
						</Box>
					</div>
					<div className='flex-avia'>
						<div className='flight-date'>Время вылета обратно</div>
						<Box
							sx={{
								width: 540,
								margin: '10px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<SuccessSlider
								value={value2}
								onChange={handleChange2}
								valueLabelFormat={valueLabelFormat}
								valueLabelDisplay='auto'
								getAriaValueText={valuetext}
								disableSwap
								marks={marks}
								step={null}
								size='large'
								sx={{
									// color: 'success.main',
									'& .MuiSlider-thumb': {
										borderRadius: '1px'
									}
								}}
							/>
							{/* <div className='flight-date'>
        От {valueLabelFormat(value1[0])} до {valueLabelFormat(value1[1])}
      </div> */}
						</Box>
					</div>
				</>
			)}
		</>
	)
}
