import TopCountry from './components/Countries'
import AllCountry from './components/Countries/AllCountry'
import useCountryStore from './store'
import React, { useEffect } from 'react'
import YandexMap from './components/YandexMap'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Country() {
	const countriesList = useCountryStore(({ countriesList }) => countriesList)
	const countries = useCountryStore(({ countries }) => countries)
	const actions = useCountryStore(({ actions }) => actions)

	useEffect(() => {
		actions.getCountryList()
	}, [actions])

	useEffect(() => {
		actions.getCountries()
	}, [actions])

	return (
		<div className='container'>
			<Helmet title={'Страны'} />
			<Breadcrumbs aria-label='breadcrumb' className={'breadcrumbs-maps'}>
				<Link color='inherit' to={'/'}>
					Главная
				</Link>
				<Typography style={{ color: "#4872f2" }}>Страны</Typography>
			</Breadcrumbs>
			<h1 className='overview-maps-title'>Обзор направлений</h1>
			<YandexMap />
			<TopCountry country={countriesList} />
			<AllCountry country={countries} />
		</div>
	)
}

export default Country
