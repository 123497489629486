import React from 'react';
import {
  CityItemTitle,
  CityItemText,
  CityItemDescription,
  CityCode,
} from './styles';
import styled from 'styled-components';

const CityItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  cursor: pointer;
  ${({ active }) => (active ? 'background-color: #f2f2f2;' : '')}
  padding: 0 16px 0 12px;
`;



export const SuggestionItem = ({
  data,
  onSelect,
  focus,
  active,
  setSuggestionIndex,
  indexNumber,
}) => {
  const handleOnEnter = () => {
    setSuggestionIndex(indexNumber);
  };

  const handleOnLeave = () => {
    setSuggestionIndex(null);
  };

  const handleClick = () => {
    onSelect(data, focus);
  };

  return (
    <CityItem
      active={active}
      onMouseEnter={handleOnEnter}
      onMouseLeave={handleOnLeave}
      onClick={handleClick}
    >
      <CityItemText>
        <CityItemTitle>{data.nameRus}</CityItemTitle>
        <CityItemDescription>{data.country.nameRus}</CityItemDescription>
      </CityItemText>
      <CityCode>{data.iataCode}</CityCode>
    </CityItem>
  );
};
