import React from 'react';
import './style.css'

const CustomInput = ({ label, required, disabled, bankName, className, ...props }) => {
    const [inputValue, setInputValue] = React.useState('');
    const handleChange = (event) => {
      setInputValue(event.target.value);  
    };

    return (
        <div className="CustomInput-wrapper">
            <input
                className={`CustomInput ${className} ${disabled ? ' disabled' : ''}`}
                value={inputValue}
                onChange={handleChange}
                required={true}
                {...props}
            />
            <label className="CustomInput-lable">
                {label}
                {required && <span style={{ color: '#E73C3E' }}>*</span>}
            </label>
            {props.value?.length === 9 && bankName && (
                <span className="CustomInput-bank">{`(${bankName})`}</span>
            )}
        </div>
    );
};

export default CustomInput;