import { create } from 'zustand'

export const useErrorStore = create(set => ({
	error: null,
	birthDayError: false,
	setErrorForm: value => {
		set({ error: value })
	},
	setBirthDayError: value => {
		set({ birthDayError: value })
	},
}))
