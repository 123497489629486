import React from 'react';
import {Link} from "react-router-dom";
import {FreeMode, Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import './style.scss'

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {isMobile} from "react-device-detect";

const NewsManual = ({ content, title }) => {
	return (
		<div className={'news-manual_wrapper'}>
			<div className='slider-header'>
				<h2>
					{title}
				</h2>
				{(content.news.length > 4 && !isMobile) && <div className='nav'>
					<div className='swiper-button-news-prev nav-button-news'>
						<div className='prev_chevron'/>
					</div>
					<div className='swiper-button-news-next nav-button-news'>
						<div className='next_chevron'/>
					</div>
				</div>}
			</div>
			<Swiper
				spaceBetween={30}
				slidesPerView={isMobile ? 1 : 4}
				modules={[FreeMode, Navigation]}
				navigation={content.news.length > 4 ? {
					nextEl: ".swiper-button-news-next",
					prevEl: ".swiper-button-news-prev",
				} : false}
				className="photo-tiles_slider"
				watchOverflow={true}
			>
				{content.news.map(item =>
					<SwiperSlide key={item.icon}>
						{item.linkNews ?
							<Link key={item.id} to={`/expertmedia/${item.linkNews}`} className={'news-by-category__item'}>
								<div className={'news-by-category__item-wrapper'}>
									<div className={'news-by-category__item-img'}
										 style={{backgroundImage: `url(${item.icon})`}} />
									{item.tag.name && <div className={'news-by-category__item-tag'}>
										<span>{item.tag.name}</span>
									</div>}
								</div>
							</Link> :
							<div key={item.id} className={'news-by-category__item'}>
								<div className={'news-by-category__item-wrapper'}>
									<div className={'news-by-category__item-img'}
										 style={{backgroundImage: `url(${item.icon})`}} />
									{item.tag.name && <div className={'news-by-category__item-tag'}>
										<span>{item.tag.name}</span>
									</div>}
								</div>
							</div>
						}
					</SwiperSlide>
				)}
			</Swiper>
		</div>
	);
};

export default NewsManual;