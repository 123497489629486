import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../services/api'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { formatISO } from 'date-fns'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useGeoStore = create(
	devtools((set, get) => ({
		content: null,
		news: null,
		action: null,
		oneNews: [],
		simpleTours: [],
		arrivalCountries: [],
		isNotFound: false,
		currentCaseForms: null,
		actions: {
			getCountryList: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/geocontent/country/list`,
						method: 'GET',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`,
							'Accept-Language': 'ru-RU'
						}
					})
					// console.log(res.data, 'DATA')
					const caseForms = res.data.find(
						country => country.ebgNodeId === id
					)?.caseForms
					set({ currentCaseForms: caseForms })
				} catch (e) {
					console.log(e)
				}
			},
			getPage: async link => {
				set({ isNotFound: false })
				const data = {
					isMainPage: false,
					link: link,
					defaultCityEbgId: 274286,
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance().post('/pge-by-link', data)
				set({ content: res.data })
				if (res?.response?.status === 404 || res?.response?.status === 505) {
					set({ isNotFound: true })
				}
			},
			getNews: async category => {
				const data = {
					sortBy: 'show_from', // - имя колонки в БД (используется с sortDir), необязательный
					sortDir: 'desc', // - порядок сортировки asc или desc (используется с sortBy), необязательный
					limit: 4,
					category: category
				}
				const res = await geoApiInstance().post('/news/expert-news', data)
				set({ news: res.data })
			},
			getOneNews: async category => {
				const data = {
					sortBy: 'show_from', // - имя колонки в БД (используется с sortDir), необязательный
					sortDir: 'desc', // - порядок сортировки asc или desc (используется с sortBy), необязательный
					limit: 1,
					category: category
				}
				const res = await geoApiInstance().post('/news/expert-news', data)
				set({ oneNews: [...get().oneNews, res.data[0]] })
			},
			getOneNewsPage: async url => {
				const data = {
					sortBy: 'show_from', // - имя колонки в БД (используется с sortDir), необязательный
					sortDir: 'desc' // - порядок сортировки asc или desc (используется с sortBy), необязательный
				}
				const res = await geoApiInstance().post('/news/expert-news', data)
				return res.data.find(item => item.alias === url)
			},
			getSimpleSelectionOfTours: async (payload, settings) => {
				const additionalSettings = {
					departureCityId: settings?.departureCityId.toString(),
					adults: settings?.adults.toString(),
					minStartDate: settings?.minStartDate,
					maxStartDate: settings?.maxStartDate,
					minNightsCount: settings?.minNightsCount.toString(),
					maxNightsCount:
						settings?.maxNightsCount?.toString() ??
						settings?.minNightsCount.toString()
				}
				let minStartDate = new Date()
				let maxStartDate = minStartDate
				if (settings.autoDate) {
					minStartDate = new Date(
						minStartDate.getFullYear(),
						minStartDate.getMonth(),
						minStartDate.getDate() + settings.autoStartDate ?? 0
					)
					maxStartDate = new Date(
						minStartDate.getFullYear(),
						minStartDate.getMonth(),
						minStartDate.getDate() + settings.diffDate ?? 0
					)
				}
				const defaultData = {
					clientId: 'b2b:ru',
					lang: 'ru',
					departureCityId: '274286',
					arrivalCountryId: payload.countryEbg?.toString(),
					adults: '2',
					children: [],
					minStartDate: formatISO(minStartDate, { representation: 'date' }),
					maxStartDate: formatISO(maxStartDate, { representation: 'date' }),
					minNightsCount: '7',
					maxNightsCount: '7',
					currencyId: '1',
					hotelIds: [payload.hotelEbg?.toString()],
					searchScope: 'b2b'
				}

				const data = { ...defaultData, ...additionalSettings }
				Object.keys(data).forEach(key => {
					if (data[key] === null) {
						data[key] =
							additionalSettings[key] !== null
								? additionalSettings[key]
								: defaultData[key]
					}
				})

				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetByCountry`,
						method: 'POST',
						data
					})
					if (!isEmpty(res.data.tours)) {
						return { ...res.data, searchParam: data }
					} else {
						return null
					}
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			clearContent: () => {
				set({ content: null })
			},
			getCountry: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=274286&ClientId=b2c:ru`,
						method: 'GET'
					})
					set({ arrivalCountries: res.data })
				} catch (error) {
					console.log(error)
				}
			},
			getActions: async () => {
				const data = {
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance().post('/actions/list', data)
				set({ action: res.data })
			},

			getMinPrice: async (arrivalCountry, arrivalRegionId) => {
				const formattedDate = new Date().toISOString()

				const data = {
					departureCityId: 274286,
					arrivalCountry: arrivalCountry,
					arrivalCityIds: [0],
					arrivalRegionId: arrivalRegionId,
					hotelIds: [0],
					minNightsCount: 7,
					maxNightsCount: 7,
					minStartDate: formattedDate,
					maxStartDate: formattedDate,
					adults: 2,
					children: [0],
					currencyId: 1,
					tourTypeId: 0,
					fullSearch: true,
					tourIds: [0],
					userAgent: process.env.REACT_APP_USER_AGENT,
					remoteIpAddress: 'string',
					fsRequestMethod: 'string'
				}

				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetMinPrices`,
						method: 'POST',
						data
					})

					if (!isEmpty(res.data)) {
						return res.data[0].price.value
					} else {
						return null
					}
				} catch (e) {
					console.error(e)
				}
			}
		}
	}))
)

export default useGeoStore
