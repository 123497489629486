import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import useClaimsStore from '../../store';
import TouristPassportItem from './TouristPassportItem';
import CircularProgress from '@mui/material/CircularProgress';

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHead = styled.thead`
    background-color: #fff;
`;

const TableRow = styled.tr`
    /* &:nth-child(even) {
    background-color: #f9f9f9;
  } */
`;

const TableHeader = styled.th`
    padding: 16px 24px 0 0;
    text-align: left;
    &:last-child {
        padding: 16px 0 0 0;
    }

    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #2e2e32;
`;

const TableCell = styled.td`
    padding: 16px 24px 0 0;
    &:last-child {
        padding: 16px 0 0 0;
    }
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #2e2e32;
`;

export default function TouristsTableCancel({ id }) {
    const tourists = useClaimsStore(
        ({ claim }) => claim?.find((claim) => claim.id === id)?.people
    );

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>MR/MRS</TableHeader>
                        <TableHeader>ФИО</TableHeader>
                        <TableHeader>Дата рождения</TableHeader>
                        <TableHeader>Документ</TableHeader>
                    </TableRow>
                </TableHead>
                <tbody>
                    {tourists ? (
                        tourists.map((tourist, i) => (
                            <TableRow key={i}>
                                <TableCell>{tourist.human}</TableCell>
                                <TableCell>{tourist.name}</TableCell>
                                <TableCell>
                                    {format(
                                        parseISO(tourist.bornDate),
                                        'dd.MM.yyyy'
                                    )}
                                </TableCell>
                                <TableCell>
                                    <TouristPassportItem
                                        passportSerie={tourist.passportSerie}
                                        passportNumber={tourist.passportNumber}
                                        expirationDate={tourist.passportValidDate}     
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <CircularProgress size={20} />
                    )}
                </tbody>
            </Table>
        </div>
    );
}
