import React from 'react';
import './style.css'
// import useBookingStore from "../../store";
import { format, parseISO } from "date-fns";

const TourBlock = ({ tour, notes }) => {

	// const currentTour = useBookingStore(({currentTour}) => currentTour)
	// const tourDescription = localStorage.getItem('tour')

	return (
		<>
			<div className="block-header">
				<h3 className="title">Тур</h3>
			</div>
			<div className="block-body">
				<div className="grid-tour header">
					<div>Описание тура</div>
					<div>СПО</div>
					<div>Страна</div>
					<div>Продолжительность</div>
					<div>Ночей</div>
					<div>Примечание</div>
				</div>
				<div className="grid-tour body">
					<div>{tour?.name}</div>
					<div dangerouslySetInnerHTML={{ __html: tour?.hotel?.spo }} />
					<div>{tour.hotel.country.name}</div>
					<div>{format(parseISO(tour.accommodation.beginDate), 'dd.MM.yyyy')} - {format(parseISO(tour.accommodation.endDate), 'dd.MM.yyyy')}</div>
					<div>{tour.accommodation.nightsCount}</div>
					<div>{notes?.spogNote}</div>
				</div>
				<div className="claim_banner">
					<span className="bold"><b>Доплаты за внесение изменений:</b></span>
					<div className="payment_note"><br />
						При редактировании данных в подтвержденной заявке в личном кабинете самостоятельно (серия,
						номер, дата окончания загранпаспорта, одной буквы в имени/фамилии) - сервисный сбор не
						взимается. Редактирование доступно для туров до даты начала которых не менее 8 дней.<br />
						<br />
						При внесении изменений в подтвержденную заявку, сопроводительные документы, включая
						изменение фамилий туристов, перевыписку авиабилетов, исправление неточностей в записях по
						вине турагента, турагентом оплачивается услуга по внесению изменений в Заявку, стоимость
						услуги составляет 20 у.е. (1500 руб по России, Абхазии) (оплата производится в рублях по
						внутреннему курсу туроператора) за каждое изменение, внесенное через 2(два) дня с момента
						подтверждения заявки.<br />
						<br />
						В случае, если турагент бронирует заявку с датой вылета менее чем 3 (три) дня с даты
						бронирования, стоимость услуги составит 20у.е. (1500 руб по России, Абхазии) (оплата
						производится в рублях по внутреннему курсу туроператора) за каждое изменение в
						документах, указанных выше, с момента подтверждения бронирования.<br />
						<br />
						Внесение изменений производятся при условии подтверждения туроператором возможности
						осуществить изменения. Если требования произвести изменения сведений и/или состава
						заказанных услуг не могут быть осуществлены туроператором из-за договорных
						ограничений, наложенных на данные действия поставщиками услуг (например, замена
						места размещения, сроков размещения, полное изменение фамилии и т.п.), турагент
						должен отказаться от заказанных услуг и оформить новую заявку. Аннуляция заявки
						производится с удержанием фактически понесенных расходов.<br />
						<br />
						Замена всех туристов приравнивается к аннуляции тура.<br />
						<br />
						Условия внесения изменений в параметры GDS туров, нестандартных туров,
						индивидуальных туров и групповых туров рассчитываются индивидуально по
						каждому туру.</div>
				</div>
				{tour.note && <div className="note_container">
					<div dangerouslySetInnerHTML={{ __html: tour.note }} />
				</div>}
			</div>
		</>

	);
};

export default TourBlock;