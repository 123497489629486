import React from 'react';
import CurveIcon from '../../../asset/images/avia/shared/curve.svg'
import FSIcon from '../../../asset/images/avia/shared/fs-logo.svg'
import './Loader.scss';

export default function Loader(props) {

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (  
    <div className='fs-ui-loader' style={{zIndex: props.zIndex}}>
      <div className='fs-ui-loader__overlay' style={{opacity: props.opacity}}/>
      <div className='fs-ui-loader__icon'>
        <div className='fs-ui-loader-circle'>
          <img className='fs-ui-loader-circle__curve' src={CurveIcon} alt=""/>
        </div>
        <img className='fs-ui-loader__icon__company' src={FSIcon} alt=""/>
      </div>
    </div>
  );
};