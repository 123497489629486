import React from 'react';

import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@mui/material/Collapse';
import calendar from '../../../../asset/images/cabinet/calendar.svg'
import tourist from '../../../../asset/images/cabinet/tourist.svg'
import call from '../../../../asset/images/cabinet/call.svg'
import alertCircle from '../../../../asset/images/cabinet/alertCircle.svg'
import useContractsStore from '../store';
import {format} from "date-fns";


const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #F6F8FA;
	border-radius: 16px;
`;

const YellowContainer = styled(Container)`
	margin-top: 24px;
	padding: 16px;
	border-radius: 16px;
	background: #FFFCE6;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
    /* padding: 24px; */
`;

const Contract = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid #D4D9DE;
	margin-top: 24px;
`

const ContractTextBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`

const ContractText = styled.div`
	display: flex;
	align-items: center;

	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #2E2E32;
`

const AlertText = styled(ContractText)`
	font-size: 14px;
	line-height: 20px;
`
const BoldText = styled(ContractText)`
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
`

const Circle = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: #2E2E32;
	margin: 0 8px;
`
const CalendarImage = styled.img.attrs({
	src: calendar
  })`
	width: 24px;
	height: 24px;
	margin-right: 8px;
  `

const TouristImage = styled.img.attrs({
	src: tourist
  })`
	width: 24px;
	height: 24px;
	margin-right: 8px;
  `

const CallImage = styled.img.attrs({
	src: call
  })`
	width: 24px;
	height: 24px;
	margin-right: 4px;
  `

const AlertImage = styled.img.attrs({
	src: alertCircle
  })`
	width: 24px;
	height: 24px;
	margin-right: 8px;
  `
const Link = styled.a`
font-size: 16px;
line-height: 22px;
font-weight: 400;
color: rgba(72, 114, 242, 1);
`
 
export default function FranchContractsList() {
    const [isOpen, setIsOpen] = React.useState(true);

	const franchContracts = useContractsStore((state) => state.offices).map(el => el.franchising);

    return (
        <Container>
            <Row
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer' }}
            >
                <FlexRow>
                    <Title>Франчайзинговые договоры</Title>
                </FlexRow>
                <ExpandMoreIcon
                    onClick={() => setIsOpen(!isOpen)}
                    style={isOpen ? { transform: 'rotate(180deg)' } : null}
                    isActive={isOpen}
                />
            </Row>
            <Collapse in={isOpen}>
				{franchContracts.slice(0, 1).map((contract, index) => (
					<Contract key={contract.id + index}>
						<ContractTextBlock>
							<ContractText>
								<BoldText>№{contract.agrNumber}</BoldText> <Circle/> Франчайзинговый договор
							</ContractText>
							<ContractText><CalendarImage/>{format(new Date(contract.agrDate), 'dd.MM.yyyy')}</ContractText>
							<ContractText><TouristImage/>Специалист поддержки:&nbsp;<b>{contract.respName}</b><Circle/><Link href={`mailto:${contract.respEmail}`}>{contract.respEmail}</Link></ContractText>
							<ContractText><CallImage/>{contract.respPhone}</ContractText>
						</ContractTextBlock>
					</Contract>
				))}
				<YellowContainer>
					<Row>
						<AlertImage /> 
						<AlertText>
							Если меняется локация Вашего офиса, юридическое лицо, график работы, контактные данные, необходимо сообщить Вашему специалисту группы поддержки.
						</AlertText>
					</Row>
				</YellowContainer>
            </Collapse>
        </Container>
    );
};

