import React from 'react';
import {SvgIcon} from "@mui/material";

const FailIcon = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 40 40" style={{width: 40, height: 40}}>
			<path d="M23.78 13.4L20 17.18L16.22 13.4C15.44 12.62 14.18 12.62 13.4 13.4C12.62 14.18 12.62 15.44 13.4 16.22L17.18 20L13.4 23.78C12.62 24.56 12.62 25.82 13.4 26.6C14.18 27.38 15.44 27.38 16.22 26.6L20 22.82L23.78 26.6C24.56 27.38 25.82 27.38 26.6 26.6C27.38 25.82 27.38 24.56 26.6 23.78L22.82 20L26.6 16.22C27.38 15.44 27.38 14.18 26.6 13.4C25.82 12.64 24.54 12.64 23.78 13.4ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z" fill="#E73C3E"/>
		</SvgIcon>
	);
};

export default FailIcon;