import React from "react"
import styled from "styled-components"
import { InfoBlock,Text } from "../ui"
import formatPrice from "../../functions/formatPrice"
import {useDispatch, useSelector} from "react-redux"
import { getDetailOrder } from "../../store/modules/orders"
import arrowSrc from "../../asset/images/avia/order-detail/arrow1.svg"
import commissionEye from '../../asset/images/avia/order-detail/commissionEye.svg';
import commissionEyeOpen from '../../asset/images/avia/order-detail/commissionEyeOpen.svg';
import {setCommissionStatus} from "../../store/modules/user";


const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const DetailContent = styled.div`
  margin-top: 24px;
  & > div {
    margin-bottom: 22px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 12px;
    }
  }
`

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`

const HeaderWrapper = styled.div`
  display: flex;
`

const Title = styled(Text)`
  margin-right: 10px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`

const PositionNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;

  ${({ interactable }) => (interactable ? "cursor: pointer;" : "")}
`

const PositionName = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  position: relative;
`

const SecondLevelList = styled(DetailContent)`
  margin-top: 22px;
  padding-left: 11px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`

const Icon = styled.img`
  position: absolute;
  right: -13px;
  top: 7px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? "transform: rotate(180deg);" : "")}
`

const AgentBlock = styled.div`
  margin-top: 22px;
  padding-top: 22px;
  box-sizing: border-box;
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    padding-top: 15px;
    margin-top: 15px;
  }
`

const AgentText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const AgentTextBlue = styled(AgentText)`
  color: #4872f2;
`

const ShowCommissionBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  max-width: 250px;
`;

const CommissionEye = styled.img.attrs({ src: commissionEye })`
  margin-right: 5px;
`;

const CommissionEyeOpen = styled.img.attrs({ src: commissionEyeOpen })`
  margin-right: 5px;
`;

const PriceBlock = ({ amount }) => {
  const { totalDetalization } = useSelector(getDetailOrder)
  const dispatch = useDispatch();
  const [state, setState] = React.useState(
    totalDetalization.positionList.map((_, key) => false)
  )

  React.useEffect(() => {
    setState(totalDetalization.positionList.map((_, key) => false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDetalization.positionList.length])

  const handleClick = key => {
    if ((totalDetalization.positionList[key].detalizationList || []).length > 0) {
      setState(s => s.map((x, i) => (i === key ? !x : x)))
    }
  }

  const isShowCommission = useSelector(state => state?.user?.userCommissionStatus);

  const AMOUNT_COMMISSION_AGENT = 17;
  const AGENT_PRICE_TYPES = [7, 8, 9, 12, 16, AMOUNT_COMMISSION_AGENT]

  return (
    <InfoBlock>
      <Head>
        <HeaderWrapper>
          <Title>Стоимость заявки</Title>
        </HeaderWrapper>
        <Title style={{ marginRight: 0 }} fontWeight="600" color="blue" bold>
          {formatPrice(amount)}{" "}
        </Title>
      </Head>
      <DetailContent>
        {totalDetalization.positionList.map((item, key) => {
          const mustShowPrice = isShowCommission || !AGENT_PRICE_TYPES.includes(item.priceType);
          const hasDetailItems = (item.detalizationList || []).length > 0;
          const canExpandDetailItems = hasDetailItems && (isShowCommission || item.detalizationList.some(x => !AGENT_PRICE_TYPES.includes(x.priceType)));
          return (
            <div key={key}>
              {item.priceType === 'AMOUNT_COMMISSION_AGENT' &&  // оставил как заглушку, если что-то придется скрывать
              isShowCommission ? (
                <></>
              ) : (
                mustShowPrice && (
                  <PositionNameWrapper
                    interactable={(item.detalizationList || []).length > 0}
                    onClick={() => handleClick(key)}
                  >
                    <PositionName style={{ fontWeight: item.isBold ? 600 : 400 }}>
                      {item.positionName}
                      {canExpandDetailItems && (
                        <Icon reverse={!state[key]} src={arrowSrc} alt="arrow"/>
                      )}
                    </PositionName>
                    <PositionName color="#3C3C3C" style={{ fontWeight: item.isBold ? 600 : 400 }}>
                      {formatPrice(item.total)}
                    </PositionName>
                  </PositionNameWrapper>
                )
              )}

              {state[key] && hasDetailItems && (
                <SecondLevelList>
                  {item.detalizationList.map((item, key) => {
                    const mustShowPrice = isShowCommission || !AGENT_PRICE_TYPES.includes(item.priceType);
                    return (
                      mustShowPrice && (
                        <>
                          <DetailRow key={key}>
                            <StyledText color="dark-gray">
                              {item.type === 'FirstBag'
                                ? `Доп. багаж до ${item.name} кг`
                                : item.name}
                            </StyledText>
                            <StyledText color="dark-gray">
                              {item.quantity > 0 && item.type !== 'passenger'
                                ? `${item.quantity} x ${formatPrice(
                                  item.price
                                )}`
                                : formatPrice(item.price)}
                            </StyledText>
                          </DetailRow>
                          {item?.detalization?.map((x, key) => {
                            const mustShowPrice = isShowCommission || !AGENT_PRICE_TYPES.includes(x.priceType);
                            return (
                              mustShowPrice && (
                                <DetailRow style={{ marginLeft: 20 }}>
                                  <StyledText color="dark-gray">
                                    {x.description}
                                  </StyledText>
                                  <StyledText color="dark-gray">
                                    {item.quantity > 0
                                      ? `${item.quantity} x ${formatPrice(x.value)}`
                                      : formatPrice(x.value)}
                                  </StyledText>
                                </DetailRow>
                              )
                            );
                          })}
                        </>
                      )
                    );
                  })}
                </SecondLevelList>
              )}
            </div>
          );
        })}
        <ShowCommissionBlock
          onClick={() => dispatch(setCommissionStatus(!isShowCommission))}
        >
          {!isShowCommission ? <CommissionEyeOpen /> : <CommissionEye />}{' '}
          <div>
            {isShowCommission
              ? 'Скрыть детали для агента'
              : 'Открыть детали для агента'}{' '}
          </div>
        </ShowCommissionBlock>
      </DetailContent>
      {!!totalDetalization.agentTotal && isShowCommission && (
        <AgentBlock>
          <AgentText>Сумма оплаты для агента</AgentText>
          <AgentTextBlue data-cy={'orderDetailPriceForAgent'}>
            {formatPrice(totalDetalization.agentTotal)}
          </AgentTextBlue>
        </AgentBlock>
      )}
    </InfoBlock>
  )
}

export default PriceBlock
