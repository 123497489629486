import React, { useMemo } from "react"
import styled from "styled-components"
import Terminals from "./Terminals"
import SegmentInfo from "./SegmentInfo"
import AdditionalInfo from "./AdditionalInfo"
import { InfoBlock } from "../../../../ui"
import Transfer from '../../../../ui/flights/Transfer'
import getAirlineLogo from "../../../../../functions/getAirlineLogo"
import { useOrderPosition } from "../useItemProps"

const Container = styled.div``

const Body = styled(InfoBlock)`
  display: grid;
  grid-template-columns: 4fr 60px 1.25fr;
  row-gap: 14px;
  border: none;
  padding: 0;
  box-shadow: none;
`

const StyledTransfer = styled(Transfer)`
  margin: 15px 0 20px 0;
`

const Segment = ({ className, ...props }) => {
  const orderPosition = useOrderPosition()
  const baggage = orderPosition.tariff.fareFamilies
    ? (orderPosition.tariff.fareFamilies[0].features || []).find(
        el => el.type === "Baggage"
      )
    : null

  const baggageFormatted = baggage ? baggage.descriptionRus : "неизвестно"
  const transferProps = useMemo(() => {
    return props.stopOvers[0]
      ? {
          ...props.stopOvers[0],
          city: props.arrivalCity.name || ""
        }
      : null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stopOvers])

  const logoUrl = getAirlineLogo(props.operatingAirline.code)
  return (
    <Container className={className}>
      <Body>
        <SegmentInfo {...props} logoUrl={logoUrl} />
        <div />
        <AdditionalInfo {...props} baggage={baggageFormatted} />
        <Terminals {...props} />
      </Body>
      {transferProps && <StyledTransfer {...transferProps} />}
    </Container>
  )
}

export default Segment
