import React from "react"
import { useSelector } from "react-redux"
import { TableUi } from "../TableUi"
import AgentUsersItem from "./AgentUsersItem"
import styled from "styled-components"

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-bottom: 24px;
`

const AgentUsersTable = () => {
  const agentUsers = useSelector(state => state.agents.agentUsers)

  return (
    <TableUi.Container>
      <TableUi.Title>Список пользователей агента</TableUi.Title>
      <Text>
        В данном блоке вам доступна таблица с информацией по всем пользователям
        агента. При нажатии на соответствующую колонку вы можете изменить роль
        или заблокировать пользователя.
      </Text>
      {agentUsers?.length === 0 || !agentUsers ? (
        <TableUi.SubTitle>Пользователи не найдены</TableUi.SubTitle>
      ) : (
        <TableUi.Table style={{ overflowX: "auto", display: "block" }}>
          <TableUi.Tr>
            <TableUi.TitleTh>ФИO</TableUi.TitleTh>
            <TableUi.TitleTh>Email</TableUi.TitleTh>
            <TableUi.TitleTh>Телефон</TableUi.TitleTh>
            <TableUi.TitleTh>Роль пользователя</TableUi.TitleTh>
            <TableUi.TitleTh>Логин</TableUi.TitleTh>
            <TableUi.TitleTh>Partpassid</TableUi.TitleTh>
            <TableUi.TitleTh></TableUi.TitleTh>
          </TableUi.Tr>
          {agentUsers?.map((agent, i) => (
            <AgentUsersItem agent={agent} key={agent.userId} />
          ))}
        </TableUi.Table>
      )}
    </TableUi.Container>
  )
}

export default AgentUsersTable
