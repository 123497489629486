export const claims = [
	{
		id: 'cl_refer',
		url: 'https://b2b.fstravel.com/cl_refer',
		title: 'Просмотр заявок'
	},
	{
		id: 'claim_unpaid',
		url: 'https://b2b.fstravel.com/claim_unpaid',
		title: 'Неоплаченные заявки'
	}
]

export const claimsNew = [
	{
		id: 'cl_refer',
		url: '/cabinet/claims',
		title: 'Просмотр заявок'
	},
	{
		id: 'claim_unpaid',
		url: '/cabinet/claims?payStatus=3',
		title: 'Неоплаченные заявки'
	}
]

export const agency = [
	{
		id: 'edit_agency',
		url: 'https://b2b.fstravel.com/edit_agency',
		title: 'Редактирование партнера'
	},
	{
		id: 'rating_position',
		url: 'https://b2b.fstravel.com/rating_position',
		title: 'Рейтинги агентства'
	},
	{
		id: 'agreement',
		url: 'https://b2b.fstravel.com/agreement',
		title: 'Печать договора'
	},
	{
		id: 'partner_curator',
		url: 'https://b2b.fstravel.com/partner_curator',
		title: 'Кураторы'
	},
	{
		id: 'employees',
		url: 'https://b2b.fstravel.com/employees',
		title: 'Сотрудники'
	}
]

export const reports = [
	{
		id: 'reports',
		url: 'https://b2b.fstravel.com/reports',
		title: 'Отчеты'
	},
	{
		id: 'bonus_manager',
		url: 'https://b2b.fstravel.com/bonus_manager',
		title: 'Бонусы менеджеров'
	}
]

export const info = [
	{
		id: 'hotel_stopsale',
		url: 'https://b2b.fstravel.com/hotel_stopsale',
		title: 'Остановки продаж в гостиницах'
	},
	{
		id: 'freight_monitor',
		url: 'https://b2b.fstravel.com/freight_monitor',
		title: 'Наличие мест на рейсах'
	},
	{
		id: 'freight_time',
		url: 'https://b2b.fstravel.com/freight_time',
		title: 'Расписание рейсов'
	},
	{
		id: 'freight_changes',
		url: 'https://b2b.fstravel.com/freight_changes',
		title: 'Изменения в расписании рейсов'
	}
]

export const search = [
	{
		id: null,
		url: '/search_tour',
		target: '_self',
		title: 'Туры с перелетом'
	},
	{
		id: null,
		url: '/search_hotel',
		target: '_self',
		title: 'Отели | Круизы Fun&Sun'
	},
	{
		id: null,
		url: '/avia',
		target: '_self',
		title: 'Регулярные авиабилеты'
	},
	{
		id: 'tickets',
		url: 'https://b2b.fstravel.com/tickets',
		target: '_blank',
		title: 'Чартерные авиабилеты'
	},
	{
		id: 'cl_wizard',
		url: 'https://b2b.fstravel.com/cl_wizard',
		target: '_blank',
		title: 'Конструктор'
	},
	{
		id: null,
		url: 'https://cruises.fstravel.com/searchcruises',
		target: '_blank',
		title: 'Круизы'
	},
	{
		id: 'modern_search',
		url: 'https://b2b.fstravel.com/modern_search?samo_action=services',
		target: '_blank',
		title: 'Доп. услуги'
	},
	{
		id: 'fit',
		url: 'https://b2b.fstravel.com/fit',
		target: '_blank',
		title: 'Туры других ТО'
	}
]
