import React, { useState } from 'react';
import styled from 'styled-components';
import { MButton, Text } from '../../ui';
import useClaimsStore from '../../../store'
import Modal from '@mui/material/Modal';
import CustomSelect from './CustomSelect'
import { TextField } from '@mui/material';
import { ReactComponent as PaperClip } from '../../../../../../asset/images/cabinet/paperclip.svg'
import File from './File'
import { ReactComponent as IconClose } from '../../../../../../asset/images/avia/ui/close.svg'
import { isEmpty } from 'lodash';

const ModalWrapper = styled.div`
	display:flex;
	gap: 32px;
	flex-direction: column;
	width: 700px;
	min-height: 634px;
	background: rgba(255, 255, 255, 1);
	border-radius: 16px;
	padding: 32px;
`
const TextFieldContainer = styled.div`
    border: 1px solid rgba(212, 217, 222, 1);
    border-radius: 8px;
    padding: 8px 16px;
    height: 146px;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
`
const FileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 78px;
  width: 78px;
  border-radius: 8px;
  padding: 16px;
  background: rgba(246, 248, 250, 1);
  & > svg {
    display: block;
    & > path {
        stroke: rgba(72, 114, 242, 1);
    }
  }
`
const FullButton = styled(MButton)`width: 100%;`

function NewMessages({ claim }) {
    const departments = useClaimsStore(({ departments }) => departments)
    const messagesTypes = useClaimsStore(({ messagesTypes }) => messagesTypes)
  
    const actions = useClaimsStore(({ actions }) => actions)

    const [isShowNewMessage, setShowMessage] = useState(false)
    const [selectedDepartment, setDepartment] = useState("")
    const [selectedMessagesTypes, setMessagesTypes] = useState("")
    const [text, setText] = useState("")
    const [files, setFiles] = useState([])

    const openNewMessage = () => {
        setShowMessage(true)
        actions.getDepartments(claim)
    }
    const ref = React.useRef(null)

    const sendMessage = async () => {
        const data = {
            ClaimId: claim,
            Body: text,
            TypeId: selectedMessagesTypes,
        }


        let formData = Object.entries(data).reduce((fd, n) => (fd.append(...n), fd), new FormData())
        if (!isEmpty(files)) {
            files.forEach((file) => {
                formData.append('Attachments', file);
            });

        }
        const message = await actions.sendMessage(formData)
        if (message) {
            handleClose()
            actions.getMessages(claim)
        }
    }
    const addAttachments = (e) => {
        const files = e.target.files || []

        if (files.length > 0) {
            setFiles(s => [...s, ...files])
        }
    }

    const removeFile = (file) => {
        setFiles(prev => prev.filter(f => f.name !== file))
    }

    const changeDepartment = (event) => {
        setDepartment(event.target.value)
    }
    const handleChangeText = event => {
        setText(event.target.value)
    }
    const changeMessagesTypes = (event) => {
        setMessagesTypes(event.target.value)
    }
    const handleFileButton = () => {
        if (ref.current) {
            ref.current.click()
        }
    }
    const handleClose = () => {
        setShowMessage(false)
        setDepartment("")
        setMessagesTypes("")
        setText("")
        setFiles([])
        setShowMessage(false)
    }

    const disabledSendButton = selectedDepartment && selectedMessagesTypes && ((text && text.trim().length > 0) || !isEmpty(files))

    return <div style={{ margin: "24px 0" }}>
        <FullButton fullWidth onClick={openNewMessage}>Написать сообщение</FullButton>
        <Modal
            open={isShowNewMessage}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <ModalWrapper>
                <Row>
                    <Text bold size={28} height={38} style={{ width: '604px' }}>
                        Новое сообщение
                    </Text>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <IconClose
                            onClick={handleClose}
                            width={32}
                            height={32}
                        />
                    </div>
                </Row>
                <CustomSelect
                    value={selectedDepartment}
                    options={departments}
                    id="select-departments"
                    onChange={changeDepartment}
                    label='Выберите отдел'
                />
                <CustomSelect
                    options={selectedDepartment ? messagesTypes[selectedDepartment] : []}
                    label='Выберите тип сообщения'
                    id="select-messages-type"
                    value={selectedMessagesTypes ?? null}
                    onChange={changeMessagesTypes}
                    disabled={!isEmpty(departments) && !selectedDepartment}
                />

                <TextFieldContainer>
                    <div style={{ display: 'flex', gap: "10px", alignItems: 'center' }}>
                        <PaperClip />
                        <Text size={12} height={18} color='rgba(126, 131, 137, 1)'>
                            Текст обращения
                        </Text>
                    </div>
                    <TextField
                        id="message-textarea"
                        variant="standard"
                        multiline
                        fullWidth
                        value={text}
                        onChange={handleChangeText}
                        style={{ marginLeft: '42px' }}
                        rows={4}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                </TextFieldContainer>
                <Row>
                    <input
                        ref={ref}
                        type="file"
                        style={{ display: "none" }}
                        onChange={addAttachments}
                    />
                    {files.map((file, index) =>
                        <File
                            key={file?.name + file?.size}
                            fileSize={file?.size}
                            fileName={file?.name ?? 'Приложение ' + index}
                            onRemove={() => removeFile(file?.name)}
                        />
                    )}
                    {files.length !== 2 &&
                        <FileButton onClick={handleFileButton}>
                            <PaperClip width={34} height={34} />
                        </FileButton>
                    }
                </Row>
                <MButton
                    disabled={!disabledSendButton}
                    color='yellow'
                    onClick={sendMessage}
                >
                    Отправить
                </MButton>
            </ModalWrapper>
        </Modal>
    </div>
}

export default NewMessages