import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Text } from '../../../ui';
import formatPrice from '../../../../functions/formatPrice';
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../../../ui/PrimaryButton';
import InfoIconSrc from '../../../../asset/images/avia/insurances/info.svg'
import CheckIconPath from '../../../../asset/images/avia/insurances/arrow.svg';

const Wrapper = styled.div`
  border: 1px solid
    ${({ isActive }) => (isActive ? '#4872F2' : '#dcdcdc')};
  box-sizing: border-box;
  padding: 14px 10px 14px 14px;
  border-radius: 10px;
  font-family: Open Sans;
  flex-shrink: 0;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 769px) {
    padding: 14px;
  }
`;

const Description = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  margin-top: 12px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const DescriptionItem = styled(Text)`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`

const FooterDescription = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  display: block;
  margin-top: auto;
  margin-bottom: ${({ withMargin }) => (withMargin ? '-3px' : 0)};
  white-space: nowrap;

  & > a {
    color: #4872f2;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }
`;

const DesktopFooterDescription = styled(FooterDescription)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileFooterDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: none;
  }

  ${FooterDescription} {
    display: inline;
    margin-bottom: 0;
  }
`;

const Overlay = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #80808014;
`;

const StyledButton = MUIstyled(PrimaryButton)(()=>({
  maxWidth: 99,
  fontSize: '14px',
  lineHeight: '18px',
  padding: '7px 16px',
  display: 'block',
  marginTop: '16px',
}))

const DesktopBr = styled.br`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: flex-start;

  & > * {
    margin-right: 5px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    align-items: center;
  }
`;

const MobileName = styled(Text)`
  font-weight: 600;
`;

const MobilePrice = styled(MobileName)`
  color: #4872f2;
`;

const Title = styled.span`
  margin-left: 10px;
  margin-top: 6px;
  & > span {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    margin-left: 5px;
  }
`;

const Badge = styled.div`
  width: fit-content;
  margin-top: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: #BFE8FF;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`

const InfoIcon = styled.img.attrs({ src: InfoIconSrc, alt: 'info' })`
  margin-right: 4px;
`

const BadgeIcon = styled.img.attrs({alt: 'badge'})`
  margin-right: 4px;
`

const CheckIcon = styled.img.attrs({ src: CheckIconPath, alt: 'check' })`
  margin-right: 6px;
`

const CovidTip = styled.div`
  position: absolute;
  top: -12px;
  right: 0;
  height: 24px;
  padding: 0 8px;
  border-radius: 8px;
  background: ${({ background }) => background};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CovidText = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  margin-left: 5px;
`;

// const CompanyLogo = styled.img.attrs({ width: 91 })`
//   display: block;
//   margin-bottom: 10px;
//
//   @media (max-width: 767px) {
//     display: none;
//   }
// `;

const CompanyLogoMobile = styled.img`
  height: 31px;
`;

const Link = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  color: #4872f2;
  text-decoration: none;
`;

const Item = React.memo((props) => {
  const isProtection =
    props.product.name.includes('Защита на время полета') ||
    props.product.name.includes('Гарантия хорошей погоды') ||
    props.product.name.includes('Врач-online в поездку') ||
    props.product.name.includes('Медицинские расходы') ||
    props.product.name.includes('Страховка на время полёта') ;

  const isInsuranceCancel = props.product.name.includes('Отмена');

  useEffect(() => {
    if (props.upsale === 'UpsaleOn' && !!document.location.pathname.match(/avia\/booking/)) {
      handleClick()
    }
  }, []);

  const handleClick = () => {
    props.onChange(props.product.id, props);
  };

  return (
    <Wrapper isActive={props.checked}>
      {props.disabled && <Overlay />}
      <MobileHead>
        <img alt="icon" src={props.iconSrc} width={30} height={30} />
        <Title>
          <MobileName>{props.product.name}</MobileName>
          {!isProtection && <DesktopBr />}
          <MobilePrice>{formatPrice(props.totalRate.value)}</MobilePrice>
        </Title>
      </MobileHead>
      <Badge style={{background: props.cardRenderData?.iconBackground}}>
        <BadgeIcon src={props.cardRenderData?.iconSrc} />
        <span>{props.cardRenderData?.iconText}</span>
      </Badge>
      {props?.cardRenderData?.detailsTitle && <Description>{props?.cardRenderData?.detailsTitle}</Description>}
      {props?.cardRenderData?.detailsItems?.map(el => (
        <DescriptionItem>
          <CheckIcon />
          <span dangerouslySetInnerHTML={{__html: el}}/>
        </DescriptionItem>
      ))}
      <DesktopFooterDescription withMargin={isInsuranceCancel}>
      {/*<CompanyLogo src={props.company.logo} alt={props.company.name} />*/}
        <InfoWrapper>
          <InfoIcon />
          <a href="/404" target="_blank" rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              props.onClick(props.index);}}
          >Условия
          </a>
        </InfoWrapper>
      </DesktopFooterDescription >
      <MobileFooterDescription>
        <Link
          href="/404"
          onClick={(e) => {
            e.preventDefault();
            props.onClick(props.index);
          }}
          target="_blank"
          rel="noreferrer"
        >
          Условия страховки
        </Link>

        <CompanyLogoMobile src={props.company.logo} alt={props.company.name} />
      </MobileFooterDescription>
      <StyledButton
        type="button"
        loading={props.loading}
        color={props.checked ? 'lightBlue' : 'primary'}
        onClick={handleClick}
      >
        {props.checked ? 'Выбрано' : 'Выбрать'}
      </StyledButton>
      {!!props.label && (
        <CovidTip background={props.label.color}>
          <img alt="icon" src={props.label.icon} />
          <CovidText>{props.label.text}</CovidText>
        </CovidTip>
      )}
    </Wrapper>
  );
},(prev,next)=> {
  return prev.checked === next.checked && prev.loading === next.loading
} );

export default Item;
