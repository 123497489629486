import React from "react"
import { PositionContextProvider } from "./useItemProps"
import Desktop from "./Desktop"

const Item = ({ defaultOpen = false, ...props }) => {
  const [isOpen, setOpen] = React.useState(defaultOpen)
  return (
    <PositionContextProvider {...props} isOpen={isOpen} setOpen={setOpen}>
      <Desktop/>
    </PositionContextProvider>
  )
}

export default Item
