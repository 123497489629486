import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, Virtual } from 'swiper/modules';
import { v4 as uuid } from 'uuid';
import { useRef } from 'react'

import './style.scss'
import './PhotoTilesCardNewSlider.style.scss'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { isMobile } from 'react-device-detect';


const SLIDES_VIEW_COUNT = 4

export default function PhotoTilesCardNewSlider({ slider }) {
    const ref = useRef(null)
    const autoplay = slider?.boolAutoList ? { delay: 0 } : false
    const id = uuid()

    return <div className='photo-tiles-card-new-slider'>
        <div className='slider-header'>
            <div dangerouslySetInnerHTML={{ __html: slider?.titleConfig?.fullTitle }}></div>
            {!isMobile && <div className='nav'>
                <div className={`swiper-button-prev id-${id} nav-button`} ><div className='prev_chevron' /></div>
                <div className={`swiper-button-next id-${id} nav-button`} ><div className='next_chevron' /></div>
            </div>}
        </div>
        <Swiper
            ref={ref}
            slidesPerView={1.3}
            spaceBetween={30}
            modules={[Pagination, Navigation, Autoplay, Virtual]}
            autoplay={autoplay}
            speed={slider.speed ?? 1500}
            breakpoints={{
                780: {
                    slidesPerView: SLIDES_VIEW_COUNT,
                    pagination: false,
                },
            }}

            navigation={{
                nextEl: `.swiper-button-next.id-${id} `,
                prevEl: `.swiper-button-prev.id-${id} `,
            }}
            className="photo-tiles_card"
            watchOverflow={true}
        >
            {slider?.photos?.map((item, index) =>
                <SwiperSlide key={item.uid} >
                    <Card
                        badge={item?.badge}
                        title={item?.title}
                        text={item?.text}
                        linkBtn={item?.linkBtn}
                        textBtn={item?.textBtn}
                        preview={item?.preview}
                        colorTextBtn={item?.colorTextBtn}
                        colorBackgroundBtn={item?.colorBackgroundBtn}
                    />
                </SwiperSlide>)}
        </Swiper>
    </div>
}

const Card = ({
    badge,
    title,
    text,
    textBtn,
    linkBtn,
    colorTextBtn,
    colorBackgroundBtn,
    preview
}) => {
    const isEmptyBody = title || text || textBtn
    return <div className='photo-tiles-card-new'>
        <div className='photo-tiles-card-new__img-block'>
            <img className='photo-tiles-card-new__img-block__img' src={preview} alt="" />
            {badge && <div className='photo-tiles-card-new__img-block__badge'>
                {badge}
            </div>}
        </div>
        {isEmptyBody && <div className='photo-tiles-card-new__body'>
            {title && <div className='photo-tiles-card-new__body__title'>{title}</div>}
            {text && <div className='photo-tiles-card-new__body__text'>{text}</div>}
            {textBtn && <a
                style={{ backgroundColor: colorBackgroundBtn, color: colorTextBtn }}
                className='photo-tiles-card-new__body__btn' href={linkBtn}>{textBtn}</a>}
        </div>}
    </div>
}