import { create } from 'zustand'
import axios from 'axios'
import { loyalSale } from '../../constants/reg-exps'
import { uniqBy } from "lodash";
import { sendAnalyticsAdjust, sendAnalyticsReserve } from "../../functions/analytics";
import { formatDateToUTC } from '../../functions/formateDate'
import useAuthStore from '../Auth/store'

const BASE_URL = process.env.REACT_APP_FS_APIGATE
const PART_PASS = localStorage.getItem('agentInfo')
const TOUR = localStorage.getItem('tour')

const useBookingStore = create((set, get) => ({
	currencys: {},
	phoneErrorGds: false,
	buyerErrors: {},
	abortRequest: null,
	loading: false,
	bookingError: false,
	bookingLoadingError: false,
	currentTour: {},
	forwardFlightId: null,
	backwardFlightId: null,
	selectedFlightOptionId: null,
	tour: null,
	insurances: null,
	excursions: null,
	services: null,
	oldPrice: true,
	paxes: null,
	promocode: '',
	claimId: null,
	readyToBooking: false,
	faresError: null,
	applicationNotes: null,
	selectedHotels: [],
	isInsurancesSelect: false,
	boarding: [],
	buyer: {
		buyer_type: "0",
		nationalityKey: "210357",
		send_gis: "1",
	},
	defaultPayload: {
		userAgent: process.env.REACT_APP_USER_AGENT,
		partpassId: Number(JSON.parse(PART_PASS)?.partPass),
		clientId: 'b2c:ru',
		lang: 'ru',
		tourKey: '',
		tour: {
			currencyId: localStorage.getItem('currencyBookingId'),
			client: null
		}
	},
	flightClassId: 2,
	payload: {
		userAgent: process.env.REACT_APP_USER_AGENT,
		partpassId: Number(JSON.parse(PART_PASS)?.partPass),
		clientId: 'b2c:ru',
		lang: 'ru',
		tourKey: '',
		tour: {
			currencyId: 2,
			client: null
		},
		fares: null
	},
	response: null,
	partnerCommission: null,
	tourKey: null,
	visas: null,
	paxesVisa: null,
	applyContactsForAll: false,
	actions: {
		setCurrencyId: id => {
			set({
				defaultPayload: {
					...get().defaultPayload,
					tour: { ...get().defaultPayload.tour, currencyId: id }
				}
			})
		},
		setPhoneError: value => {
			set({ phoneErrorGds: value })
		},
		getPartnerCommission: async () => {
			const data = {
				partpassId: Number(JSON.parse(PART_PASS)?.partPass),
				dateBeg: JSON.parse(TOUR)?.hotel?.checkInDate,
				tourId: JSON.parse(TOUR)?.id,
				// "adultCount": get().searchParams.adult,
				// "childCount": get().searchParams.children.length,
				// "infantCount": get().searchParams.children.length,
				nightCount: JSON.parse(TOUR)?.hotel?.nightCount
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/TourDetails/GetPartnerCommission`,
					method: 'POST',
					data: data
				})
				set({ partnerCommission: res.data.partnerCommission })
			} catch (e) {
				console.log(e?.response?.data)
			}
		},
		clearError: () => {
			set({ bookingError: false })
			set({ bookingLoadingError: false })
		},
		backToSearch: () => {
			if (get().abortRequest) {
				get().abortRequest.abort()
			}
			localStorage.removeItem('tour')
			set({ response: null })
			set({ payload: get().defaultPayload })
			set({ claimId: null })
		},
		clearPayload: () => {
			if (get().abortRequest) {
				get().abortRequest.abort()
			}
			set({ payload: get().defaultPayload })
		},
		adjustReservation: async (tourKey, currency, adults, children) => {
			set({ tourKey: tourKey })
			set({ loading: true })
			// const tourkey = localStorage.getItem('tour')
			const req = {
				...get().defaultPayload,
				partpassId: useAuthStore.getState().agent.partPass,
				tour: { ...get().defaultPayload.tour, currencyId: currency },
				tourKey: `TUI::${tourKey}`
			}

			set({ payload: req })
			await get().actions.getCurrenciesValues()
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				await get().actions.getVisasSchedule(res.data.tour)
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				set({ flightClassId: res.data.tour.selectedFlightOptionId?.split('::')[0]?.split(".")[1] })
				// set({
				// 	forwardFlightId: res.data.tour.selectedFlightOptionId
				// 		?.split('::')[0]
				// 		.replace('.2', '')
				// })
				// set({
				// 	backwardFlightId: res.data.tour.selectedFlightOptionId
				// 		?.split('::')[1]
				// 		.replace('.2', '')
				// })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[0]
							.replace(`.${get().flightClassId}`, '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[1]
							.replace(`.${get().flightClassId}`, '')
					})
				} else {
					const firstForward = res.data.tour.selectedFlightOptionId
						?.split('::')[0]
						.replace(`.${get().flightClassId}`, '')
					const secondForward = res.data.tour.selectedFlightOptionId
						?.split('::')[1]
						.replace(`.${get().flightClassId}`, '')
					set({
						forwardFlightId: [firstForward, secondForward]?.join('::')
					})
					const firstBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[2]
						.replace(`.${get().flightClassId}`, '')
					const secondBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[3]
						.replace(`.${get().flightClassId}`, '')
					set({
						backwardFlightId: [firstBackward, secondBackward]?.join('::')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })
				set({ tour: res.data.tour })
				const paxesAdults = res.data.tour.paxes.slice(0, Number(adults)).map(pax => {
					return {
						...pax,
						person: {
							...pax.person,
							ageGroup: 1,
							gender: 1
						},
						document: {
							...pax.document
						}
					}
				})
				const paxesInf = res.data.tour.paxes.filter(pax => pax.person.ageGroup === 3)
				const paxInfWithoutDoc = paxesInf.length > 0
					? [{ ...paxesInf[0], document: { ...paxesInf[0].document, type: '' } }]
					: [];
				const paxesChild = res.data.tour.paxes.slice(adults, Number(children) + Number(adults)).map(pax => {
					return {
						...pax,
						person: {
							...pax.person,
							ageGroup: 2,
							gender: 1
						},
						document: {
							...pax.document
						}
					}
				})

				//неучтенный младенец, infant c бэка приходит только 1, даже если при бронировании было 2
				const addChildrev = res.data.tour.paxes.length - Number(adults) - Number(children) - paxInfWithoutDoc.length
				const paxesAddChild = addChildrev === 0
					? []
					: res.data.tour.paxes.slice(Number(children) + Number(adults), Number(children) + Number(adults) + addChildrev).map(pax => {
						return {
							...pax,
							person: {
								...pax.person,
								ageGroup: 3,
								gender: 1
							},
							document: {
								...pax.document,
								type: ''
							}
						}
					})
				set({ paxes: [...paxesAdults, ...paxesChild, ...paxesAddChild, ...paxInfWithoutDoc] })
				set({ insurances: res.data.tour.insurances })
				set({
					excursions: res.data.tour.services.filter(item => item.type.id === 2)
				})
				set({
					services: res.data.tour.services.filter(item => item.type.id !== 2)
				})
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsAdjust(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
				set({ bookingLoadingError: e.response?.data?.errors[0]?.userMessage })
			} finally {
				set({ loading: false })
			}
		},
		getServicesReceipt: async (tourKey, currency) => {
			set({ tourKey: tourKey })
			set({ loading: true })
			const req = {
				...get().defaultPayload,
				partpassId: useAuthStore.getState().agent.partPass,
				tour: { ...get().defaultPayload.tour, currencyId: currency },
				tourKey: `TUI::${tourKey}`
			}

			set({ payload: req })
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[0]
							.replace(`.${get().flightClassId}`, '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[1]
							.replace(`.${get().flightClassId}`, '')
					})
				} else {
					const firstForward = res.data.tour.selectedFlightOptionId
						?.split('::')[0]
						.replace(`.${get().flightClassId}`, '')
					const secondForward = res.data.tour.selectedFlightOptionId
						?.split('::')[1]
						.replace(`.${get().flightClassId}`, '')
					set({
						forwardFlightId: [firstForward, secondForward]?.join('::')
					})
					const firstBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[2]
						.replace(`.${get().flightClassId}`, '')
					const secondBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[3]
						.replace(`.${get().flightClassId}`, '')
					set({
						backwardFlightId: [firstBackward, secondBackward]?.join('::')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })
				set({ tour: res.data.tour })
				set({ paxes: res.data.tour.paxes })
				set({ insurances: res.data.tour.insurances })
				set({
					services: res.data.tour.services
				})
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsAdjust(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
				set({ bookingLoadingError: e.response?.data?.errors[0]?.userMessage })
			} finally {
				set({ loading: false })
			}
		},
		getRegularFlights: async () => {
			const controller = new AbortController()
			set({ abortRequest: controller })
			// const tourkey = localStorage.getItem('tour')
			set({ loading: true })
			const req = {
				...get().payload,
				tourKey: `TUI::${get().tourKey}`,
				id: get().tour.id,
				gdsId: get().tour.gdsId
			}
			set({ payload: req })
			await get().actions.getCurrenciesValues()
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					signal: controller.signal,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				set({
					forwardFlightId: res.data.tour.flightOptions[0]?.forwardFlight.id
				})
				set({
					backwardFlightId: res.data.tour.flightOptions[0]?.backwardFlight.id
				})
				const { euro, dollar } = get().currencys
				if (res.data.tour.currencyId === 1) {
					set({ tour: res.data.tour })
				}
				if (res.data.tour.currencyId === 2) {
					const newFlights = res.data.tour.flightOptions.map(flight => ({
						...flight,
						surcharge: Math.round(flight.surcharge * dollar[0].rate)
					}))
					const newTour = { ...res.data.tour, flightOptions: newFlights }
					set({ tour: newTour })
				}
				if (res.data.tour.currencyId === 3) {
					const newFlights = res.data.tour.flightOptions.map(flight => ({
						...flight,
						surcharge: Math.floor(flight.surcharge * euro[0].rate)
					}))
					const newTour = { ...res.data.tour, flightOptions: newFlights }
					set({ tour: newTour })
				}
				set({ services: res.data.tour.services.filter(item => item.type.id !== 2) })
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
				await get().actions.getFares()
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsAdjust(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
				set({ bookingLoadingError: e.response?.data?.errors[0]?.userMessage })
			} finally {
				set({ loading: false })
			}
		},
		// getRegularFlights: async () => {
		// 	const controller = new AbortController()
		// 	set({ abortRequest: controller })
		// 	// const tourkey = localStorage.getItem('tour')
		// 	set({ loading: true })
		// 	// const req = {
		// 	// 	...get().payload,
		// 	// 	tourKey: `TUI::${get().tourKey}`,
		// 	// 	id: get().tour.id,
		// 	// 	gdsId: get().tour.gdsId
		// 	// }
		// 	const req = {
		// 		...get().payload,
		// 		tour: { ...get().payload.tour, currencyId: 1 },
		// 		tourKey: `TUI::${get().tourKey}`,
		// 		id: get().tour.id,
		// 		gdsId: get().tour.gdsId
		// 	}
		// 	set({ payload: req })
		// 	try {
		// 		const res = await axios({
		// 			url: `${BASE_URL}/tour/adjustReservation`,
		// 			method: 'POST',
		// 			data: req,
		// 			signal: controller.signal
		// 		})
		// 		set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
		// 		set({
		// 			forwardFlightId: res.data.tour.flightOptions[0].forwardFlight.id
		// 		})
		// 		set({
		// 			backwardFlightId: res.data.tour.flightOptions[0].backwardFlight.id
		// 		})
		// 		const newTour = {
		// 			...get().tour,
		// 			flightOptions: res.data.tour.flightOptions
		// 		}
		// 		set({ tour: newTour })
		// 		const newData = { ...get().payload, tour: res.data.tour }
		// 		set({ payload: newData })
		// 		await get().actions.getFares()
		// 	} catch (e) {
		// 		set({ bookingLoadingError: e.response.data.errors[0].userMessage })
		// 	} finally {
		// 		set({ loading: false })
		// 	}
		// },
		reserve: async loyaltyData => {
			set({ readyToBooking: true })
			// const tourkey = localStorage.getItem('tour')
			const buyer = get().buyer
			let client = null

			const showBuyer = get().tour?.hotels[0]?.country.id !== 210357
			if (showBuyer) {
				const splitName = buyer?.name?.split(" ")
				client = {
					person: {
						firstName: splitName[1],
						lastName: splitName[0]
					},
					contactInformation: {
						phone: buyer.phone,
						email: buyer.email
					},
					passport: {
						series: buyer.pserie,
						number: buyer.number,
						address: buyer.address,
						issueDate: '1901-01-01T00:00:00Z',
					},
					nationalityId: Number(buyer.nationalityKey),
					agencyDocDate: buyer.agencydocdate,
					agencyDocNumber: buyer.agency_docsum,
					agencyDocSum: buyer.agency_docnumber,
					buyerType: buyer.buyer_type,
					sendGis: buyer.send_gis,
				}
			}
			if (loyaltyData.client) {
				const loyaltyClient = [...get().paxes].find(item => item.id === loyaltyData.memberId)
				client = {
					...client,
					person: {
						...buyer,
						...loyaltyClient.person,
						identificationNumber: String(loyaltyData.client?.customer?.ids.mindboxId)
					},
					contactInformation: {
						email: buyer.email ?? loyaltyClient.person.email,
						phone: buyer.phone ?? loyaltyClient.person.phone,
					},
					passport: {
						issueDate: '1901-01-01T00:00:00Z', ...loyaltyClient.document
					},
					nationalityId: loyaltyClient?.nationalityId || 210357,
				}
			}
			set({ loading: true })
			let reg = loyaltyData?.preorder?.order?.lines[0].appliedPromotions.find(
				item => item.type === 'earnedBonusPoints'
			)
			const percentSale = reg?.promotion?.name.match(loyalSale)
			const promoDiscount = loyaltyData.preorder?.order?.lines.reduce(
				(acc, item) => {
					item?.appliedPromotions?.forEach(value => {
						if (value.type === 'discount') {
							acc = acc + value.amount
						}
					}
					)
					return acc
				}, 0)
			const earnedAmount = loyaltyData.preorder?.order?.bonusPointsChanges[0]?.earnedAmount
			const spentPoints = loyaltyData.preorder?.order?.bonusPointsChanges[0]?.spentPoints
			await get().applicationNotes
			const notePL = loyaltyData.client
				? `#LP#${loyaltyData.memberId - 1};${loyaltyData.client?.customer?.ids.mindboxId
				};${percentSale ? percentSale[1].replace('%', '') : '0'};${Math.floor(Number(loyaltyData.spentPoints)) || Math.floor(Number(spentPoints)) || '0'
				};${Math.floor(promoDiscount)};${Math.floor(Number(earnedAmount)) || '0'}#LP#`
				: ''
			const tour = {
				...get().tour,
				paxes: get().paxesVisa || get().paxes,
				client: client
			}
			const payload = {
				userAgent: process.env.REACT_APP_USER_AGENT,
				partpassId: Number(useAuthStore.getState().agent.partPass),
				clientId: 'b2b:ru',
				partner: Number(useAuthStore.getState().agent?.agents?.id),
				lang: 'ru',
				tour: tour,
				note: `${notePL} ${get().applicationNotes}`
			}
			const req = {
				...payload,
				tourKey: `TUI::${get().tourKey}`
			}
			set({ payload: req })
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/reserve`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ claimId: res.data.orderId })
			} catch (e) {
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsReserve(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.errors[0]?.userMessage })
			} finally {
				set({ loading: false })
				set({ readyToBooking: false })
			}
		},
		getFares: async () => {
			set({ faresError: false })
			set({ loading: true })
			const req = {
				clientId: 'b2c:ru',
				tourId: get().tour.id,
				partpassId: Number(useAuthStore.getState().agent.partPass)
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/getFares`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ fares: res.data.fares })
			} catch (e) {
				console.log(e?.response?.data)
				set({ faresError: true })
				// set({ bookingError: e.response.data.errors[0].userMessage })
			} finally {
				set({ loading: false })
			}
		},
		getServices: async () => {
			const req = {
				...get().payload,
				tourKey: `TUI::${get().tourKey}`,
				tour: get().tour,
				id: get().tour.id,
				gdsId: get().tour.gdsId
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ services: res.data.tour.services.filter(item => item.type.id !== 2) })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsAdjust(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
			}
		},
		setFaresError: value => {
			set({ faresError: value })
		},
		setFare: async (fareId, requestId, price, baggage) => {
			// const tourkey = localStorage.getItem('tour')
			set({ loading: true })
			const payload = {
				clientId: 'b2c:ru',
				tourId: get().tour.id,
				partpassId: Number(useAuthStore.getState().agent.partPass),
				fareId: fareId,
				requestId: requestId
			}
			const req = {
				...payload,
				tourKey: get().tourKey
			}
			try {
				await axios({
					url: `${BASE_URL}/tour/setFare`,
					method: 'POST',
					data: req,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				const newFlights = get().tour.flightOptions?.map(item => {
					if (item.key === get().selectedFlightOptionId) {
						const newPrice = item.surcharge + Number(price)
						const [, forward, , backward] = baggage
						item.forwardFlight.segments[0].baggage.value = parseInt(forward)
						if ((!backward || !parseInt(backward)) && backward !== '0Kg') {
							item.backwardFlight.segments[0].baggage.value = parseInt(forward)
						} else {
							item.backwardFlight.segments[0].baggage.value = parseInt(backward)
						}
						return {
							...item,
							surcharge: newPrice
						}
					} else {
						return item
					}
				})
				const newTour = { ...get().tour, flightOptions: newFlights }
				set({ tour: newTour })
				set({ oldPrice: true })
			} catch (e) {
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.errors[0]?.userMessage })
			} finally {
				set({ loading: false })
			}
		},
		setTourData: tour => {
			set({ currentTour: tour })
		},
		changeForwardFlights: id => {
			set({ forwardFlightId: id })
		},
		changeBackwardFlights: id => {
			set({ backwardFlightId: id })
		},
		changeSelectedBeachHotels: id => {
			let hotels = [...get().selectedHotels]
			hotels.splice(1, 1, JSON.parse(id))
			set({ selectedHotels: hotels })
			const newData = {
				...get().tour,
				selectedHotels: get().selectedHotels
			}
			set({ tour: newData })
		},
		changeSelectedCityHotels: id => {
			let hotels = [...get().selectedHotels]
			hotels.splice(0, 1, JSON.parse(id))
			set({ selectedHotels: hotels })
			const newData = {
				...get().tour,
				selectedHotels: get().selectedHotels
			}
			set({ tour: newData })
		},
		addPaxe: newPaxe => {
			const newPaxes = get().paxes
			newPaxes.push(newPaxe)
			set({ paxes: newPaxes })
		},
		removePaxe: id => {
			const newPaxes = get().paxes
			set({ paxes: newPaxes.filter(item => item.id !== id) })
		},
		setSelectedFlightId: () => {
			if (get().selectedFlightOptionId?.split('::').length === 2) {
				set({
					selectedFlightOptionId:
						get().forwardFlightId + `.${get().flightClassId}::` + get().backwardFlightId + `.${get().flightClassId}`
				})

				const newData = {
					...get().tour,
					selectedFlightOptionId: get().selectedFlightOptionId
				}
				set({ tour: newData })
			}
		},
		setSelectedRegularFlightId: key => {
			set({
				selectedFlightOptionId: key
			})
			const newData = {
				...get().tour,
				selectedFlightOptionId: get().selectedFlightOptionId
			}
			set({ tour: newData })
			get().actions.getServices()
		},
		tourRecount: async () => {
			set({ loading: true })
			const tour = {
				...get().tour,
				paxes: get().paxes,
				promoCode: get().promocode
			}
			const data = {
				...get().payload,
				tour: tour,
				tourKey: `TUI::${get().tourKey}`
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: data,
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							.split('::')[0]
							.replace(`.${get().flightClassId}`, '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							.split('::')[1]
							.replace(`.${get().flightClassId}`, '')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })

				const paxesWithContacts = res.data.tour.paxes.map(item => {
					const paxe = tour.paxes.find(i => i.id === item.id)
					return {
						...item,
						person: {
							...item.person,
							phone: paxe.person.phone,
							email: paxe.person.email
						}
					}
				})
				set({ paxes: paxesWithContacts })

				const newData = { ...get().payload, tour: res.data.tour }

				set({ payload: newData })
				set({ oldPrice: false })
			} catch (e) {
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(localStorage.getItem('departureCityId'))
				sendAnalyticsAdjust(
					e.response?.data?.errors[0]?.userMessage,
					{
						country: tour?.name,
						departureCity: departureCityId,
						hotel: tour?.hotel?.name
					})
				set({ bookingError: e.response?.data?.errors[0]?.userMessage })
				console.log(e?.response?.data)
			} finally {
				set({ loading: false })
				setTimeout(() => {
					set({ oldPrice: false })
				}, 100)
			}
		},
		updateInsurance: newInsurance => {
			const newPaxes = []
			const baseOptionsLength = get().insurances.filter(
				insurance => !insurance.isRemovable
			).length

			get().paxes.forEach(item => {
				const insurance = newInsurance.find(paxe => paxe.id === item.id)
				newPaxes.push({
					...item,
					selectedInsurances: insurance.selectedInsurances
				})
				const isInsurancesSelect =
					insurance.selectedInsurances.length > baseOptionsLength
				set({ paxes: newPaxes })
				set({ isInsurancesSelect: isInsurancesSelect })
				set({ oldPrice: true })
			})
		},
		addInsurance: id => {
			const newPaxes = []
			const insurance = get().insurances.find(item => item.id === Number(id))
			const optionIds = insurance.options.filter(item => item.isRequired)
			const baseOptions = get()
				.insurances.filter(insurance => !insurance.isRemovable)
				.map(insurance => insurance.id)
			get().paxes.forEach(item => {
				const newData = item.selectedInsurances.filter(option =>
					baseOptions.some(baseOption => baseOption === option.id)
				)
				newData.push({ id: id, optionIds: optionIds.map(item => item.id) })
				newPaxes.push({ ...item, selectedInsurances: newData })
				set({ paxes: newPaxes })
				set({ isInsurancesSelect: true })
				set({ oldPrice: true })
			})
		},
		removeInsurance: id => {
			const newPaxes = []
			const baseOptionsLength = get().insurances.filter(
				insurance => !insurance.isRemovable
			).length
			get().paxes.forEach(item => {
				const newData = item.selectedInsurances.filter(
					item => item.id !== Number(id)
				)
				newPaxes.push({ ...item, selectedInsurances: newData })
				const isInsurancesSelect = newData.length > baseOptionsLength
				set({ isInsurancesSelect: isInsurancesSelect })
				set({ paxes: newPaxes })
				set({ oldPrice: true })
			})
		},
		updateServices: newSelectedServiceIds => {
			const newPaxes = []
			get().paxes.forEach((item, index) => {
				newPaxes.push({
					...item,
					selectedServiceIds: newSelectedServiceIds[index].selectedServiceIds
				})
				set({ paxes: newPaxes })
				set({ oldPrice: true })
			})
		},
		removeServeices: id => {
			const newPaxes = []
			get().paxes.forEach(item => {
				const newData = item.selectedServiceIds.filter(
					item => item !== Number(id)
				)
				newPaxes.push({ ...item, selectedServiceIds: newData })
				set({ paxes: newPaxes })
				set({ oldPrice: true })
			})
		},
		addServeices: id => {
			const newPaxes = []
			get().paxes.forEach(item => {
				const newData = item.selectedServiceIds
				newData.push(id)
				newPaxes.push({ ...item, selectedServiceIds: newData })
				set({ paxes: newPaxes })
				set({ oldPrice: true })
			})
		},
		setNewPrice: () => {
			set({ oldPrice: true })
		},
		setApplicationNotes: value => {
			const { message, checked, numbers } = value
			const notes = checked.map(note => {
				if (note === 'Соседний номер с' && numbers.roomNumber) {
					return `${note} ${numbers.roomNumber}`
				}
				if (note === 'Вместе с заявкой #' && numbers.noteNumber) {
					return `${note} ${numbers.noteNumber}`
				}
				if (note === 'Телефон менеджера' && numbers.managerNumber) {
					return `${note}: ${numbers.managerNumber}`
				}
				if (note === 'Телефон туриста' && numbers.touristNumber) {
					return `${note}: ${numbers.touristNumber}`
				}
				if (
					note === 'Номер карты постоянного гостя отеля (если есть)' &&
					numbers.cardNumber
				) {
					return `${note}: ${numbers.cardNumber}`
				} else return note
			})
			if (message) {
				notes.push(message)
			}
			set({ applicationNotes: notes.join('; ') })
		},
		applyPromoCode: value => {
			set({ promocode: value })
		},
		changeFirstNameLatin: (name, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.firstNameLatin = name
						return item
					}
					return item
				})
			})
		},
		changeLastNameLatin: (lastName, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.lastNameLatin = lastName
						return item
					}
					return item
				})
			})
		},
		changeFirstName: (name, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.firstName = name
						return item
					}
					return item
				})
			})
		},
		changeLastName: (lastName, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.lastName = lastName
						return item
					}
					return item
				})
			})
		},
		changePatronymic: (patronymic, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.patronymic = patronymic
						return item
					}
					return item
				})
			})
		},
		changeGender: (gender, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.gender = gender
						return item
					}
					return item
				})
			})
		},
		changeBirthDate: (date, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.birthDate = formatDateToUTC(date)
						return item
					}
					return item
				})
			})
		},
		changePhone: (phone, number) => {
			if (get().applyContactsForAll) {
				set({
					paxes: get().paxes.map(item => ({ ...item, person: { ...item.person, phone: phone } }))
				})
			} else {
				set({
					paxes: get().paxes.map(item => {
						if (item.id === number) {
							item.person.phone = phone
							return item
						}
						return item
					})
				})
			}
		},
		changeEmail: (email, number) => {
			if (get().applyContactsForAll) {
				set({
					paxes: get().paxes.map(item => ({ ...item, person: { ...item.person, email: email } }))
				})
			} else {
				set({
					paxes: get().paxes.map(item => {
						if (item.id === number) {
							item.person.email = email
							return item
						}
						return item
					})
				})
				// const newClientInfo = { ...get().tour.client, contactInformation: {phone: get().tour.client.contactInformation.phone, email: email}}
				// const newSearchParams = { ...get().tour, client: newClientInfo }
				// const newData = { ...get().payload, tour: newSearchParams }
				// set({ payload: newData })
			}
		},
		changeNationality: (nation, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.person.nationality = nation
						return item
					}
					return item
				})
			})
		},
		changeTypeDocument: (type, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.document.type = type
						return item
					}
					return item
				})
			})
		},
		changeSeries: (series, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.document.series = series
						return item
					}
					return item
				})
			})
		},
		changeNumber: (pNumber, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.document.number = pNumber
						return item
					}
					return item
				})
			})
		},
		changeExpirationDate: (date, number) => {
			set({
				paxes: get().paxes.map(item => {
					if (item.id === number) {
						item.document.expirationDate = formatDateToUTC(date)
						return item
					}
					return item
				})
			})
		},
		getBoarding: async (freight, dateFreight, currency) => {
			set({ loading: true })
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/GetPacketBoarding`,
					method: 'POST',
					data: {
						ClientId: 'b2c:ru',
						claim: get().tourKey,
						freight: freight,
						dateBeg: dateFreight,
						dateEnd: dateFreight,
						class: 2,
						currencyId: currency,
						partpassId: Number(JSON.parse(PART_PASS)?.partPass),
					},
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set({ boarding: res.data.seats })
			} catch (e) {
				console.log(e?.response?.data)
			} finally {
				set({ loading: false })
			}
		},
		setFlightSeats: (selectedSeats) => {
			set({
				paxes: get().paxes.map(item => {
					if (selectedSeats.find(seat => seat.id === item.id)?.seats?.seatKey) {
						item.selectedFlightsSeats = item.selectedFlightsSeats.filter(del => del.freight !== selectedSeats.find(f => f.id === item.id)?.seats?.freight)
						item.selectedFlightsSeats.push(selectedSeats.find(seat => seat.id === item.id)?.seats)
						item.selectedFlightsSeats = uniqBy(item.selectedFlightsSeats, 'freight')
						return item
					}
					item.selectedFlightsSeats = item.selectedFlightsSeats.filter(del => del.freight !== selectedSeats.find(f => !f.seats?.seatKey)?.seats?.freight)
					return item
				})
			})
		},
		getCurrenciesValues: async () => {
			try {
				const currentDate = new Date()
				// const startDate = new Date().setMonth(currentDate.getMonth() - 1)
				const dollar = await axios({
					url: `${BASE_URL}/currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2c:ru',
						currencyFrom: 1,
						currencyTo: 2,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				const euro = await axios({
					url: `${BASE_URL}/currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2c:ru',
						currencyFrom: 1,
						currencyTo: 3,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})
				set(() => ({
					currencys: {
						...get().currencys,
						dollar: dollar.data.currencyRates.reverse(),
						euro: euro.data.currencyRates.reverse()
					}
				}))
			} catch (error) {
				console.log(error)
			}
		},
		getLocalCurrencieValue: async (currencyTo) => {
			try {
				const currentDate = new Date()
				const localCurrencieValue = await axios({
					url: `${BASE_URL}currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2c:ru',
						currencyFrom: 1,
						currencyTo: currencyTo,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})

				set(() => ({
					currencys: {
						...get().currencys,
						localCurrencieValue: localCurrencieValue.data.currencyRates.reverse(),
					}
				}))
			} catch (error) {
				console.log(error)
			}
		},
		getVisasSchedule: async (payload) => {
			try {
				const result = await axios({
					url: `${BASE_URL}/Visas/schedule`,
					method: 'GET',
					params: {
						DepartureCityId: payload.departureTown?.id || 1,
						ArrivalCountryId: payload.hotel.country.id,
						ArrivalDate: payload.accommodation.beginDate,
					},
					headers: {
						"X-User-Agent": process.env.REACT_APP_USER_AGENT
					}
				})

				set({ visas: result.data })
			} catch (error) {
				console.log(error)
			}
		},
		changeVisa: (id, number) => {
			set({
				paxesVisa: get().paxes.map(item => {
					if (item.id === number) {
						item.selectedVisaId = id
						item.visaOptions = [{ ...get().response.tour.visaOptions.find(item => item.id === id), nationalityId: 210357 }]
						return item
					}
					return item
				})
			})
		},
		changeBuyer: (key, value) => {
			set({ buyer: { ...get().buyer, [key]: value } })
		},
		changeApplyContactsForAll: (value) => {
			set({ applyContactsForAll: value })
			if (value) {
				const commonPhone = get().paxes.find(item => item.id === value)?.person?.phone
				const commonEmail = get().paxes.find(item => item.id === value)?.person?.email
				set({ paxes: get().paxes.map(item => ({ ...item, person: { ...item.person, phone: commonPhone, email: commonEmail } })) })
			}
		},
		changeBuyerErrors: (key, value) => {
			set({ buyerErrors: { ...get().buyerErrors, [key]: value } })
		}
	}
}))

export default useBookingStore
