import declination from '../../../../functions/declination'

import { ReactComponent as BtnArrow } from '../../../../asset/images/services/btn_arrow.svg'
import { ReactComponent as Bed } from '../../../../asset/images/services/bed.svg'
import { ReactComponent as Room } from '../../../../asset/images/services/room_preferences.svg'

import "./styles.scss"

function YachtSearchByLocation({ title, content }) {
    let currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 7);

    let endDate = new Date(currentDate)
    endDate.setDate(currentDate.getDate() + 14)

    const link = `/searchyachtrent?countryId=${content?.country?.ebgNodeId}&departureCityId=${content?.city?.ebgNodeId}&startDate=${currentDate?.toISOString()}&endDate=${endDate?.toISOString()}&adults=${2}`

    return <div className='yacht-search'>
        <div className='yacht-search__title'>
            {title}
        </div>
        <div className='yacht-search__content'>
            {content.items.map((item) => {
                return <div className='yacht-card'>
                    <a className='yacht-card__img' style={{ backgroundImage: `url(${item.img})` }} href={link}> </a>
                    <div className='yacht-card__body'>
                        <div>
                            {item?.title && <div className='yacht-card__title'>{item.title}</div>}
                            {item?.desc && <div className='yacht-card__text-s'>{item.desc}</div>}
                            <div className='yacht-card__tags'>
                                {item.cabinsCount &&
                                    <span className='yacht-card__tags__item'><Room />{item.cabinsCount}
                                        {declination(item.cabinsCount, [' каюта', ' каюты', ' кают'])}</span>}
                                {item.personsCount
                                    && <span className='yacht-card__tags__item'><Bed />{item.personsCount}
                                        {declination(item.personsCount, [' персона', ' персоны', ' персон'])}
                                    </span>} {item.canCaptain ? "С капитаном" : ""}
                            </div>
                        </div>
                        <div className="yacht-card__link">
                            <a href={link}><BtnArrow /></a>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default YachtSearchByLocation