import React from 'react'
import './style.scss'
import HotelSelect from './HotelSelect'
import useExcursionBookingStore from '../../store'
import { isEmpty } from 'lodash'

function HotelInfo(){

    const hotels = useExcursionBookingStore(({ hotels }) => hotels)
    const selectedHotel = useExcursionBookingStore(({ selectedHotel }) => selectedHotel)
    const actions = useExcursionBookingStore(({ actions }) => actions)

    if(isEmpty(hotels)){
        return
    }

    return <div>
        <div className="excursion-header__title">
            <h3>Отель проживания</h3>
        </div>
        <div className='excursion-hotels-info'>
            <HotelSelect 
             lists={hotels}
             value={selectedHotel}
            //  title="Адресс"
             onChange={actions.changeHotel}
             id={"excursion-hotels"}
             empty
            />
        </div>
    </div>
}

export default HotelInfo