import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import {getAgentBalance, getAgentInfo} from "../../../store/modules/agents/agent-finance/selectors"
import yellowInfoIcon from "../../../asset/images/avia/agents/yellowInfoIcon.svg"
import {ReactComponent as DownloadIcon} from "../../../asset/images/avia/shared/download.svg"
import AgentBalancePopUp from "./AgentBalancePopUp"
import formatPrice from "../../../functions/formatPrice"
import InfoIcon from './InfoIcon';
import Skeleton from '@mui/material/Skeleton';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  //width: 750px;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 0px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 380px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: auto;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const GrayText = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  margin-bottom: 25px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    line-height: 18px;
    margin-bottom: 10px;
  }
`

const TextBlock = styled.div`
  align-self: flex-start;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  > span {
    color: #3c3c3c;
    font-weight: 600;
  }

  > div {
    display: inline-block;
    color: #4872f2;
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    width: auto;
    margin-top: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 5px;
  width: 20px;
  height: 20px;
`;

const YellowBlock = styled.div`
  background: #fff3cd;
  border: 1px solid #ffb800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
`

const YellowInfoIcon = styled.img.attrs({ src: yellowInfoIcon })`
  margin-right: 7px;
`

const YellowBlockText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
`

const BlueText = styled.div`
  font-family: "Open Sans", serif;
  color: #4872f2;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 5px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const ServiceFee = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #737373;
  margin-top: 24px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const LinkText = styled.a`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #4872f2;
    & > span {
        margin-left: 6px;
    }

    & > svg > path {
        stroke: #4872f2;
    }
`
export const AgentBalanceSkeleton = () => (
  <Container>
    <Skeleton width={209} sx={{ fontSize: '18px', lineHeight: '120%', marginBottom: '8px' }}/>
    <Skeleton width={404} sx={{ fontSize: '14px', lineHeight: '20px', marginBottom: '25px' }}/>
    <Row>
      <Skeleton width={380} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
      <Skeleton width={380} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
    </Row>
    <Row>
      <Skeleton width={380} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
      <Skeleton width={380} sx={{ fontSize: '16px', lineHeight: '20px' }}/>
    </Row>
    <Skeleton width={305} height={20} sx={{ marginTop: '15px' }}/>
    <Skeleton height={147} sx={{ marginTop: '24px',transform: 'scale(1)' }}/>
  </Container>
)
//извиняюсь за бойлерплейт делал быстро

const AgentBalance = () => {
  const [balancePopUpOpen, setBalancePopUp] = React.useState(false)

  const data = useSelector(getAgentBalance)
  const agentInfo = useSelector(getAgentInfo)

  return (
    <Container>
      {balancePopUpOpen && (
        <AgentBalancePopUp
          balancePopUpOpen={balancePopUpOpen}
          setBalancePopUp={setBalancePopUp}
        />
      )}
      <Row>
        <Title>Личный баланс агента</Title>
        {agentInfo?.offerDocumentLink && <LinkText href={agentInfo?.offerDocumentLink}>
          <DownloadIcon />
          <span>Скачать оферту агента</span>
        </LinkText>}
      </Row>
      {/* <Text>
        Контролируйте взаиморасчеты: сумму кредита и остаток по лимиту, сумму
        переплат и общую задолженность компании.
      </Text> */}
      <GrayText>* Время обновления информации от 1С составляет 10 мин</GrayText>
      <Row>
        <FlexRow>
          <TextBlock>
            Остаток по кредитному лимиту:{" "}
            <span>
              {formatPrice(data?.creditLimitRest)}
            </span>
          </TextBlock>
          <StyledInfoIcon width={259} title='Денежные средства доступные для использования по кредитному лимиту.'/>
        </FlexRow>
        <FlexRow>
          <TextBlock>
            Сумма переплаты:{" "}
            <span>
            {formatPrice(data?.overpayment)}
            </span>
          </TextBlock>
          <StyledInfoIcon width={225} title='Денежные средства, оплаченные сверх стоимости заявки.'/>
        </FlexRow>
      </Row>

      <Row>
        <FlexRow>
          <TextBlock>
            Сумма кредита:{" "}
            <span>
            {formatPrice(data?.creditLimit)}
            </span>
          </TextBlock>
          <StyledInfoIcon title='Общая сумма кредитного лимита.'/>

          {/* <InfoIconContainer>
            {info3 && (
              <InfoContainer>
                <InfoText>Общая сумма кредитного лимита. </InfoText>
              </InfoContainer>
            )}
            <InfoIcon
              onMouseEnter={() => setInfo3(true)}
              onMouseLeave={() => setInfo3(false)}
            />
          </InfoIconContainer> */}
        </FlexRow>
        <FlexRow>
          <TextBlock>
            Общая задолженность компании:{' '}
            <div>
              {formatPrice(data?.debt)}
            </div>
          </TextBlock>
          <StyledInfoIcon title='Ваша общая задолженность по всем заявкам.'/>

          {/* <InfoIconContainer>
            {info4 && (
              <InfoContainer style={{ left: "-155px" }}>
                <InfoText>Ваша общая задолженность по всем заявкам. </InfoText>
              </InfoContainer>
            )}
            <InfoIcon
              onMouseEnter={() => setInfo4(true)}
              onMouseLeave={() => setInfo4(false)}
            />
          </InfoIconContainer> */}
        </FlexRow>
      </Row>
      <FlexRow style={{ marginTop: 15 }} onClick={() => setBalancePopUp(true)}>
        <InfoIcon />
        <BlueText>Как пользоваться балансом агента?</BlueText>
      </FlexRow>
      <YellowBlock>
        <YellowInfoIcon />
        <Column>
          <YellowBlockText>
            В расчете остатка по кредитному лимиту учитываются все активные
            неоплаченные заявки, по которым выставлен счет. Поэтому мы
            рекомендуем делать запрос на выставление счета только перед
            непосредственной выпиской билетов.
          </YellowBlockText>
          <YellowBlockText style={{ marginTop: 15 }}>
            Восстановление или пополнение остатка кредитного лимита происходит в
            случае оплаты выписанной заявки и в случае аннуляции неоформленной
            заявки при истечении срока таймлимита.
          </YellowBlockText>
        </Column>
      </YellowBlock>
      <ServiceFee>
        Обращаем Ваше внимание, cервисный сбор за процедуру возврата или обмена составляет <span style={{fontWeight:600}}>100 ₽.</span>
      </ServiceFee>
    </Container>
  )
}

export default AgentBalance
