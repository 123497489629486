import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Grid, TextField } from '@mui/material'
import useLoyaltyStore from '../../store'
import AppliedPromotions from './AppliedPromotions'

import './style.css'

const LoyaltyForm = ({ client }) => {
	const preorder = useLoyaltyStore(({ preorder }) => preorder)
	const actions = useLoyaltyStore(({ actions }) => actions)
	const [spentPoints, setSpentPoints] = useState('')
	const [promocode, setPromocode] = useState('')
	const [error, setError] = useState('')
	const [errorPromo, setErrorPromo] = useState(false)
	const [promoHelperText, setPromoHelperText] = useState('')
	const [levelAmount, setLevelAmount] = useState('')

	const handleChangeSpentPoints = e => {
		setError('')
		setSpentPoints(e.target.value.replace(',', '.'))
	}

	const handleChangePromoCode = e => {
		setErrorPromo(false)
		setPromoHelperText('')
		setPromocode(e.target.value)
	}

	const handleClickSpentPoints = () => {
		if (
			spentPoints >
			preorder?.order?.totalBonusPointsInfo?.availableAmountForCurrentOrder
		) {
			setError('Превышено максимальное значение')
		} else if (!/^\d+$/.test(spentPoints.trim())) {
			setError('Введите числовое значение')
		} else {
			actions.setSpentPoints(spentPoints)
		}
	}

	const handleApplyPromoCode = () => {
		actions.applyPromoCode(promocode)
	}
	const handleCancelPromoCode = () => {
		actions.applyPromoCode('')
		setPromocode('')
		setPromoHelperText('')
	}

	const handleCancelSpentPoints = () => {
		actions.setSpentPoints('0.0')
	}

	const status = client.customerSegmentations.filter(item => item.segment)

	const getLevel = () => {
		let amount
		switch (status[status.length - 1]?.segment?.name) {
			case 'Friend':
				amount = 200000 - Number(client.retailOrderStatistics.totalPaidAmount)
				break
			case 'Good Friend':
				amount = 500000 - Number(client.retailOrderStatistics.totalPaidAmount)
				break
			case 'Best Friend':
				amount = 'Достигнут максимальный уровень'
				break
			default:
				amount = ' '
		}
		return amount
	}

	useEffect(() => {
		setLevelAmount(getLevel)
		// eslint-disable-next-line
	}, [client])

	const statusPromo = preorder?.order?.couponsInfo?.find(
		item => item.coupon?.ids?.code === promocode
	)?.coupon

	const isBookingPage = window.location.pathname.includes('booking')

	useEffect(() => {
		if (statusPromo) {
			switch (statusPromo?.status) {
				case 'CanBeUsed':
					setPromoHelperText('Промокод успешно применен')
					break
				case 'CanNotBeUsedForCurrentOrder':
					setPromoHelperText(
						'Промокод не соответствует условиям данного заказа'
					)
					setErrorPromo(true)
					break
				case 'Unavailable':
				case 'NotFound':
				case 'NotIssued':
				case 'Used':
				case 'YetNotActive':
				case 'AlreadyNotActive':
				case 'Blocked':
					setPromoHelperText(
						'Данного промокода не существует или он был уже использован'
					)
					setErrorPromo(true)
					break
				default:
					setPromoHelperText('')
			}
		}
		// eslint-disable-next-line
	}, [statusPromo])

	return (
		<>
			<Box>
				<Alert
					severity='success'
					sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
					Информация об участнике программы лояльности:
				</Alert>
			</Box>
			<div className='members-form'>
				<div className='info'>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Уровень участника'
							value={
								status[status.length - 1]?.segment?.name ||
								client.balances[0].systemName
							}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
					{preorder && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Вернется баллами'
								value={
									preorder?.order?.bonusPointsChanges[0]?.earnedAmount || ' '
								}
								variant='outlined'
								fullWidth
								disabled
							/>
						</Box>
					)}
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Доступно'
							value={client.balances[0].available}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
					<Box>
						<TextField
							label='Заблокировано'
							value={client.balances[0].blocked}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
				</div>
				<div className='docs'>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='До следующего уровня, руб.'
							value={levelAmount}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Предпочтительный тип отдыха'
							value={
								client?.customer.customFields?.favoriteTypeRest?.join(' ,') ||
								' '
							}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
					<Box>
						<TextField
							label='С кем предпочитает путешествовать'
							value={client?.customer.customFields?.favoriteTravelWith || ' '}
							variant='outlined'
							fullWidth
							disabled
						/>
					</Box>
				</div>
			</div>
			{!!preorder?.order?.totalBonusPointsInfo
				?.availableAmountForCurrentOrder && isBookingPage && (
				<div className='members-form'>
					<div className='info' style={{ marginRight: 0 }}>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={2}
							alignItems={'center'}>
							<Grid item xs={4}>
								<Box sx={{ fontWeight: 'bold' }}>
									<div>Списать баллы</div>
									{!!preorder?.order?.totalBonusPointsInfo
										?.availableAmountForCurrentOrder && (
										<span style={{ fontWeight: 'normal' }}>
											(Максимальное значение:{' '}
											{
												preorder?.order?.totalBonusPointsInfo
													?.availableAmountForCurrentOrder
											}
											)
										</span>
									)}
								</Box>
							</Grid>
							<Grid item xs={4}>
								{!preorder?.order?.totalBonusPointsInfo
									?.spentAmountForCurrentOrder && (
									<Box>
										<TextField
											value={spentPoints}
											variant='outlined'
											fullWidth
											onChange={handleChangeSpentPoints}
											helperText={error}
											error={!!error}
										/>
									</Box>
								)}
							</Grid>
							<Grid item xs={4} align='right'>
								{!preorder?.order?.totalBonusPointsInfo
									?.spentAmountForCurrentOrder ? (
									<Box>
										<Button
											variant={'outlined'}
											color={'primary'}
											onClick={handleClickSpentPoints}>
											Списать баллы
										</Button>
									</Box>
								) : (
									<Button
										variant={'outlined'}
										color='error'
										onClick={handleCancelSpentPoints}>
										Отменить списание баллов
									</Button>
								)}
							</Grid>
						</Grid>
					</div>
				</div>
			)}
			{isBookingPage && <div className='members-form'>
				<div className='info' style={{marginRight: 0}}>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={2}
						alignItems={'center'}>
						<Grid item xs={4}>
							<Box sx={{fontWeight: 'bold'}}>Применить промокод</Box>
						</Grid>
						<Grid item xs={4}>
							<Box>
								<TextField
									value={promocode}
									variant='outlined'
									fullWidth
									onChange={handleChangePromoCode}
									helperText={promoHelperText}
									error={errorPromo}
									className={
										!errorPromo && promoHelperText ? 'promo_success' : ''
									}
									style={{
										transform: promoHelperText ? 'translateY(12px)' : ''
									}}
								/>
							</Box>
						</Grid>
						<Grid item xs={4} align={'right'}>
							{statusPromo?.status !== 'CanBeUsed' ? (
								<Box>
									<Button
										variant={'outlined'}
										color={'primary'}
										onClick={handleApplyPromoCode}>
										Применить промокод
									</Button>
								</Box>
							) : (
								<Box>
									<Button
										variant={'outlined'}
										color='error'
										onClick={handleCancelPromoCode}>
										Отменить примение промокода
									</Button>
								</Box>
							)}
						</Grid>
					</Grid>
				</div>
			</div>}
			{preorder?.order?.lines && isBookingPage && (
				<AppliedPromotions
					lines={preorder?.order?.lines}
					scores={
						preorder?.order?.totalBonusPointsInfo?.spentAmountForCurrentOrder
					}
				/>
			)}
		</>
	)
}

export default LoyaltyForm
