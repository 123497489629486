import React from 'react';

import './style.css'

const StaticText = ({title, content, id}) => {
	return (
		<div className={'container static-text'} id={id}>
			<h3 align={'center'}>{title}</h3>
			<div dangerouslySetInnerHTML={{__html: content.text}} />
		</div>
	);
};

export default StaticText;