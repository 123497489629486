import React, { useEffect, useState } from 'react'
import useBookingStore from './store'
import TourBlock from './components/TourBlock'
import HotelBlock from './components/HotelBlock'
import AviaBlock from './components/AviaBlock'
import InsurancesBlock from './components/InsurancesBlock'
import Snackbar from '@mui/material/Snackbar'
import { Alert, Stack } from '@mui/material'
import { isEmpty } from 'lodash'
import ServicesBlock from './components/ServicesBlock'
import PaxesBlock from './components/PaxesBlock'

import './style.css'
// import { format } from 'date-fns'
import AviaRegBlock from './components/AviaBlock/RegularFlights/AviaRegBlock'
import PromoCode from './components/PromoCode'
import CustomModal from '../../components/CustomModal'
import { useLocation, useNavigate } from 'react-router-dom'
import useLoyaltyStore from '../Loyalty/store'
import ButtonsBlock from './components/ButtonsBlock'
import RegButtonsBlock from './components/ButtonsBlock/RegButtonsBlock'
import BookingSuccessPopup from "./components/BookingSuccessPopup";
import ApplicationNoteBlock from './components/ApplicationNoteBlock/ApplicationNoteBlock'
import BuyerBlock from './components/BuyerBlock'

const RUSSIA_ID = 210357

const Booking = () => {
	const [open, setOpen] = React.useState(false)
	const [isBookingSuccess, setIsBookingSuccess] = React.useState(false)
	const [isRegFlights, setIsRegFlights] = useState(false)


	const navigate = useNavigate()

	const actions = useBookingStore(({ actions }) => actions)
	const client = useLoyaltyStore(({ client }) => client)
	const response = useBookingStore(({ response }) => response)
	const insurances = useBookingStore(state => state.insurances)
	const excursions = useBookingStore(state => state.excursions)
	const services = useBookingStore(state => state.services)
	const bookingError = useBookingStore(({ bookingError }) => bookingError)
	const bookingLoadingError = useBookingStore(({ bookingLoadingError }) => bookingLoadingError)
	const claimId = useBookingStore(({ claimId }) => claimId)
	const promocode = useLoyaltyStore(({ promocode }) => promocode)

	const actionsPL = useLoyaltyStore(({ actions }) => actions)
	const spentPoints = useLoyaltyStore(({ spentPoints }) => spentPoints)
	const currentTour = useBookingStore(({ currentTour }) => currentTour)

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const currentSelectedCurrency = Number(searchParams.get('currentSelectedCurrency'))

	const handleClose = (event, reason) => { if (reason === 'clickaway') { return } setOpen(false) }

	useEffect(() => {
		if (currentSelectedCurrency !== 1 && currentSelectedCurrency !== 2 && currentSelectedCurrency !== 3) {
			actions.getLocalCurrencieValue(currentSelectedCurrency);
		}
	}, [currentSelectedCurrency, actions]);

	useEffect(() => {
		const query = new URLSearchParams(search)
		actions.adjustReservation(query.get('priceId'), query.get('currencyId'), query.get('adults'), query.get('children'))
		// actions.getPartnerCommission()
	}, [actions, search])

	useEffect(() => {
		if (!isEmpty(response?.notifications)) {
			setOpen(true)
		}
		if (isEmpty(response?.tour?.gdsId)) {
			setIsRegFlights(false)
		} else {
			setIsRegFlights(true)
		}
	}, [response])

	useEffect(() => {
		if (response) {
			console.log(promocode, 'promocode')
			actionsPL.preorder(response, currentTour, promocode)
		}
	}, [response, actionsPL, currentTour, client, spentPoints, promocode])

	useEffect(() => {
		if (claimId) {
			actionsPL.preorder(response, currentTour, promocode)
			if (client) {
				actionsPL.preorderTransaction(response, claimId);
			}
		  setIsBookingSuccess(true);
		}
		// eslint-disable-next-line
	}, [claimId])

	const backToSearch = () => {
		actions.backToSearch()
		actions.clearError()
		navigate('/search_tour')
	}

	const onCloseErrorModal = () => {
		actions.clearError()
		actions.clearPayload()
	}

	// const handleSubmit = event => {event.preventDefault(): actions.tourRecount() }

	if (bookingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingError}
				successButtonTitle='Понятно'
				onSuccess={onCloseErrorModal}
			/>
		)
	}
	if (bookingLoadingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingLoadingError}
				successButtonTitle='Понятно'
				onSuccess={backToSearch}
			/>
		)
	}

	if (!response) return null

	const showBuyer = response?.tour?.hotels[0]?.country?.id !== RUSSIA_ID

	return (
		<div className='container'>
			<TourBlock tour={response.tour} notes={response?.notes} />
			<HotelBlock hotel={response.tour.hotel} hotels={response.tour.hotels} />
			{isRegFlights ? (
				<AviaRegBlock
					// flights={response.tour.flightOptions}
					tour={response.tour}
				/>
			) : (
				<AviaBlock flights={response.tour.flightOptions} tour={response.tour} />
			)}
			{!isEmpty(insurances) && <InsurancesBlock insurances={insurances} currentSelectedCurrency={currentSelectedCurrency} />}
			{!isEmpty(excursions) && (
				<ServicesBlock services={excursions} currentSelectedCurrency={currentSelectedCurrency} isExcursions title='Экскурсия' />
			)}
			{!isEmpty(services) && (
				<ServicesBlock currentSelectedCurrency={currentSelectedCurrency} services={services} title='Дополнительные услуги' />
			)}
			<form>
				<PaxesBlock
					paxes={response.tour.paxes}
					freeinfant={response.tour.freeinfant}
				/>
				{showBuyer && <BuyerBlock />}
				<ApplicationNoteBlock />
				{response.notes?.spogMessage && <div className={'notes-spog'}>{response.notes.spogMessage}</div>}
				{response.tour.promoCodeCanBeApplied && <PromoCode />}
				{isRegFlights ? (
					<RegButtonsBlock response={response} />
				) : (
					<ButtonsBlock response={response} currentSelectedCurrency={currentSelectedCurrency} />
				)}
			</form>
			<Snackbar
				open={open}
				autoHideDuration={16000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Stack flexDirection='column' gap={1}>
					{response.notifications.map(item => {
						return (
							<Alert
								onClose={handleClose}
								severity={item.targetType === 'price' ? 'error' : 'success'}
								sx={{ width: '300px' }}>
								{item.description}
							</Alert>
						)
					})}
				</Stack>
			</Snackbar>
			{isBookingSuccess && <BookingSuccessPopup claim={claimId} />}
		</div>
	)
}

export default Booking
