import { createAction, createReducer } from "redux-act"
import { OrdersListIntialState } from "../constants"

export const getOrdersList = createAction("@orders/getList")

export const getOrdersListNext = createAction("@orders/getListNext")

export const setOrdersList = createAction("@orders/setList")

const orderList = createReducer({}, OrdersListIntialState)
orderList.on(setOrdersList, (_, payload) => ({ ...payload, isLoading: false }))
orderList.on(getOrdersListNext, state => ({ ...state, isLoading: true }))

export default orderList
