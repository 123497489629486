import {useRef, useState} from 'react';
import styled from 'styled-components';
import { Text } from '../../../../../components/ui';
import { useSelector } from 'react-redux';
import FilterSection from './FilterSection';
import arrowDownSquare from '../../../../../asset/images/arrowDownSquare.svg';
import { referencesSelector } from '../../../../../store/modules/references';

const Container = styled.div`
  margin-top: 30px;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
`;

const FilterElement = styled.div`
  margin-right: 92px;
  cursor: pointer;
  position: relative;

  @media (max-width: 1080px) {
    margin-right: 32px;
  }
`;

const FilterText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: flex;
  align-items: center;

  @media (max-width: 1080px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Triangle = styled.span`
  margin-left: 4px;
  width: 0;
  height: 0;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-bottom: ${({ isOpen }) => (isOpen ? 'none' : '9px solid #b9b9b9')};
  border-top: ${({ isOpen }) => (!isOpen ? 'none' : '9px solid #b9b9b9')};
`;

const Dropdown = styled.div`
  width: 215px;
  background: #ffffff;
  box-shadow: 2px 6px 16px rgba(0, 0, 0, 0.29);
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  padding: 14px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

const Link = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #4872f2;
  cursor: pointer;

  @media (max-width: 1080px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Arrow = styled.img`
  margin-left: 4px;
  transform: ${({ isAsc }) => (!isAsc ? 'rotate (0deg)' : 'rotate(180deg)')};
`;

const DesktopFilters = (props) => {
  const { statuses, setDateSorting, dateSorting } = props;
  const [isStatusesOpen, setStatusesOpen] = useState(false);
  const { order: { paymentStatuses } } = useSelector(referencesSelector);

  const statusesRef = useRef()

  const setStatusProperty = (property) => {
    setStatusesOpen(false);

    props.setStatuses(property);
  };

  return (
    <Container>
      <FilterElement>
        <FilterText
          onClick={() => {
            setStatusesOpen(!isStatusesOpen);
          }}
        >
          Статус оплаты <Triangle isOpen={isStatusesOpen} />
        </FilterText>
        {isStatusesOpen && (
          <Dropdown ref={statusesRef}>
            <FilterSection
              list={paymentStatuses}
              properties={statuses}
              setProperty={setStatusProperty}
            />
            {!!statuses?.length && (
              <Link
                style={{ marginTop: 12 }}
                onClick={() => {
                  props.setStatuses('none');
                  setStatusesOpen(false);
                }}
              >
                Сбросить фильтр
              </Link>
            )}
          </Dropdown>
        )}
      </FilterElement>

      <FilterElement>
        <FilterText onClick={() => {setDateSorting(dateSorting === 'asc' ? 'desc' : 'asc');}}>
          Время и дата <Arrow isAsc={dateSorting === 'asc'} src={arrowDownSquare} />
        </FilterText>
      </FilterElement>

      {!!statuses?.length && (
        <Link
          onClick={() => {
            props.setStatuses('none');
            setStatusesOpen(false);
          }}
        >
          Сбросить все фильтры
        </Link>
      )}
    </Container>
  );
};

export default DesktopFilters;
