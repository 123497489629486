import * as React from "react";
import Search from '../pages/Avia/Search'
import Booking from '../pages/Avia/Booking'
import SpecialOffers from '../pages/Avia/AviaSpecialOffers'

const routes = [
    {
        path: "",
        element: <SpecialOffers/>,
    },
    {
        path: "search/:params",
        element: <Search/>,
    },
    {
        path: "booking",
        element: <Booking/>,
    },
];

export default routes;
