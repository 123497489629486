import { all, call } from 'redux-saga/effects';
import searchFormSaga from './modules/search-form/sagas'
import searchResultSaga from './modules/search-result/sagas';
import userSaga from './modules/user/sagas'
import bookingSaga from './modules/booking/sagas'
import referencesSaga from './modules/references/sagas'
import ordersSaga from './modules/orders/sagas'
import appealsSaga from './modules/appeals/sagas'
import agentsSaga from './modules/agents/sagas'
import notificationSaga from './modules/notification/sagas'

export default function* rootSaga() {
    yield all([
      call(searchFormSaga),
      call(searchResultSaga),
      call(userSaga),
      call(bookingSaga),
      call(referencesSaga),
      call(ordersSaga),
      call(appealsSaga),
      call(agentsSaga),
      call(agentsSaga),
      call(notificationSaga),
    ]);
  }
