import React from "react"
import {Text} from "../../../../ui"
import styled from "styled-components"
import getAirlineLogo from "../../../../../functions/getAirlineLogo"
import baggageSrc from "../../../../../asset/images/avia/shared/baggage.svg"

const Container = styled.div`
  & > span {
    display: block;
  }
  max-width: 204px;
  overflow: hidden;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 5px;
  }
  & > span {
    font-weight: 600;
  }
`

const AdditionalInfo = props => (
  <Container>
    <Row>
      <img
        src={getAirlineLogo(
          props.operatingAirline.code,
          {width: 160,height: 60,styleType: 'normal'}
        )}
        width={80}
        height={30}
        alt={props.operatingAirline.code}
      />
    </Row>
    <Row>
      <>
        <img src={baggageSrc} alt="" />
        <Text
          fontWeight="600"
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
        >
          {props.baggage}
        </Text>
      </>
    </Row>
  </Container>
)

export default AdditionalInfo
