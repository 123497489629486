import React, { useEffect, useState } from 'react';
import useGeoStore from "../LandingPage/store";
import MainBanner from "../LandingPage/components/MainBanner";
import StaticBlocks from "../LandingPage/StaticBlocks";
import MainBannerSlider from "../LandingPage/components/MainBannerSlider";
import { Helmet } from "react-helmet";
import ExcursionsSearchForm from "./components/SearchForm";
import { useLocation } from "react-router-dom";
import { decodeQueryString } from "../../functions/sequelize";
import useExcursionStore from "./store";
import { isEmpty } from 'lodash';
import SearchResultsWithFilters from './components/SearchResultsWithFilters';
import {isMobile} from "react-device-detect";
import MobileSearchForm from "./components/SearchForm/MobileSearchForm";
import SearchNotFound from "../../components/SearchNotFound";



const Excursion = () => {

	const geo = useGeoStore(({ actions }) => actions)
	const actions = useExcursionStore(({ actions }) => actions)
	const content = useGeoStore(({ content }) => content)
	const result = useExcursionStore(({ excursions }) => excursions)
	const loading = useExcursionStore(({ loading }) => loading)
	const searchComplete = useExcursionStore(({ searchComplete }) => searchComplete)

	const [isUpdated, setIsUpdated] = useState(false)
	const handleSearch = useExcursionStore(({ actions }) => actions.postSearch)

	const { search } = useLocation();

	useEffect(() => {
		geo.getPage('/searchexcursions').then(setIsUpdated(true))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		return () => {
			geo.clearContent()
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (search) {
			const newSearchParams = decodeQueryString(search)
			if (newSearchParams.countryId) {
				actions.setCountry(Number(newSearchParams.countryId))
			}
			if (newSearchParams.departureCityId) {
				actions.setCity(Number(newSearchParams.departureCityId))
			}
			if (newSearchParams.startDate) {
				actions.setDate([new Date(newSearchParams.startDate), new Date(newSearchParams.startDate)])
			}
			if (newSearchParams.endDate) {
				actions.setDate([new Date(newSearchParams.startDate), new Date(newSearchParams.endDate)])
			}
			if (newSearchParams.adults) {
				actions.setAdult(Number(newSearchParams.adults))
			}
			if (newSearchParams.childrens) {
				actions.setChild(newSearchParams.childrens.split(','))
			}
			handleSearch()
		}
	}, [actions, search])

	if (!content || !isUpdated)
		return null;

	return (
		<div className={'landing'}>
			<Helmet title={content?.meta_title || content?.title} />
			{content.search_frame &&
				<div>
					<div className={!result && !isMobile ? 'container' : ''}>
						{(!result || (!isMobile && !!result)) &&  <ExcursionsSearchForm/>}
						{!!result && isMobile && <MobileSearchForm/>}
					</div>
					{!isEmpty(result) && <div className={'container'}><SearchResultsWithFilters result={result} /></div>}
					{searchComplete && isEmpty(result) && <SearchNotFound title={'Нет подходящих экскурсий'}/>}
				</div>
			}
			{JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner ?
				<MainBannerSlider content={JSON.parse(content.main_banner.content)} />
				:
				<MainBanner content={JSON.parse(content.main_banner.content)} />
			}
			<div className={'container'}>
				<StaticBlocks blocks={content.blocks.filter(block => block.show_desktop)} />
			</div>
		</div>
	);
};

export default Excursion;

