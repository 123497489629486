import React, {useEffect} from 'react';
import useGeoStore from "../../store";
import './style.scss'
import {Link} from "react-router-dom";
import {isMobile} from "react-device-detect";
import ArrowRight from "../../../../asset/icons/arrow-right.svg";

const NewsByCategory = ({ content, title }) => {

	const actions = useGeoStore(({ actions }) => actions)
	const news = useGeoStore(({ news }) => news)

	useEffect( () => {
		actions.getNews(content.category.slug)
		// eslint-disable-next-line
	}, [])

	if (!news) return

	return (
		<>
			<div className={'tiles-photo-text-title-wrapper'}>
				<h2>{title}</h2>
				{!isMobile && content.linkHref &&
					<div className={'wrapper-link'}>
						<Link to={content.linkHref}>{content.linkText}</Link>
						<span className={'arrow__right'} style={{ WebkitMask: `url(${ArrowRight}) no-repeat center/cover` }} />
					</div>
				}
			</div>
			<div className={'news-by-category'}>
				{news.map(item =>
					<Link key={item.id} to={`/expertmedia/${item.alias}`} className={'news-by-category__item'}>
						<div className={'news-by-category__item-wrapper'}>
							<div className={'news-by-category__item-img'} style={{backgroundImage: `url(${item.image_preview})`}} />
							{item.expert_news_tags[0].name && <div className={'news-by-category__item-tag'}>
								<span>{item.expert_news_tags[0].name}</span>
							</div>}
						</div>

						<p className={'news-by-category__item-title'}>{item.title}</p>
						<p className={'news-by-category__item-text'}>{item.description}</p>
					</Link>
				)}
			</div>
			{isMobile && content.linkHref && content.linkText && <Link className={'mobile-button'} to={content.linkHref}>{content.linkText}</Link>}
		</>

	);
};

export default NewsByCategory;