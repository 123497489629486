import React from 'react'
import moment from 'moment'

const PassengersItem = ({data}) => {

  return (
    <div className="orders-body">
      <div className="full-name">{data.lastName} {data.firstName}</div>
      <div className="gender">{data.gender === 'Male' ? 'M' : 'Ж'}</div>
      <div className="b-date">{moment(data.birthDate).format('DD.MM.YYYY')}</div>
      <div className="document">{getDocumentTitle(data.document.type)} {data.document.number}</div>
    </div>
  )

}

function getDocumentTitle(type) {
  let title
  switch (type) {
    case 'InternationalPassport':
      title = 'Загран паспорт';
      break;
    case 'BirthCertificate':
      title = 'Св-во о рожд';
      break;
    default:
      title = 'Паспорт РФ';
      break;
  }
  return title

}

export default PassengersItem
