
export const searchFormInitialState = {
  passengers: {
    adults: {
      min: 1,
      max: 9,
      count: 1,
    },
    children: {
      min: 0,
      max: 2,
      count: 0,
    },
    infants: {
      min: 0,
      max: 1,
      count: 0,
    },
  },
  ticketClass: {
    id: 'economy',
    description: 'эконом',
  },
  dates: {
    from: null,
    to: null,
  },
  returnTicket: true,
  from: '',
  to: '',
};