import React from 'react';
import ExpansionPanel from '../components/ExpansionPanel/ExpansionPanel';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Open Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #2e2e32;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const Text = styled.div`
    margin-top: 24px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c3c3c;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export default function PremiumContacts() {
    return (
        <ExpansionPanel
            title={<Title>Обращения по пакетам PREMIUM</Title>}
            body={
                <Text>
                    <b>Почта отдела: </b> premium@fstravel.com
                    <br />
                    <b>Почта для обращений по отелям цепочки Rixos: </b> rixos@fstravel.com
                    <br />
                    <b>Телефон отдела:</b> +7 (495) 974-79-72
                    <br />
                    <b>WhatsApp:</b> +7 (495) 974-79-72
                </Text>
            }
        />
    );
}
