import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import useMainMenuStore from '../../MainMenu/store'
import MobileMenuPopup from './MobileMenuPopup'

export default function MobileList({ item, setOpenDrawer }) {
	const [open, setOpen] = React.useState(false)
	const actions = useMainMenuStore(({ actions }) => actions)

	const handleClickOpen = (array, id) => {
		if (isEmpty(array)) {
			setOpenDrawer(false)
			actions.addActiveLink(id)
		} else setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			{item.is_active && (
				<li key={item.title} className='header__menu-categories-item'>
					<Link
						to={item.url}
						target={item.target}
						onClick={() => handleClickOpen(item.childs, item.id)}>
						<h5>{item.title}</h5>
					</Link>
				</li>
			)}
			<MobileMenuPopup
				key={item.id}
				list={item.childs}
				open={open}
				handleClose={handleClose}
				title={item.title}
				setOpenDrawer={setOpenDrawer}
			/>
		</>
	)
}
