import React, { useCallback } from 'react';
import styled from 'styled-components';
import {Button} from '../../components/ui';
import Dialog from '@mui/material/Dialog';
import {useNavigate} from 'react-router-dom';
import {url} from "../../store/modules/booking/utils";
import formatPrice from "../../functions/formatPrice";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border: 1px solid #d7d7d7;
    border-radius: 16px;
  }
`;

const Wrapper = styled.div`
  width: 519px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 8px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #3C3C3C;
    text-align: center;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Text = styled.div`
`;

const ButtonBlock = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  & > button {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
      border-radius: 8px;
  }
  & > :last-child {
    margin-left: 20px;
    color: #4872F2;
    background-color: #FFFFFF;
    border: 1px solid #4872F2;
  }
`;

const WarningNotification = ({isShowWarning, setIsShowWarning, warnings, searchRequest,}) => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setIsShowWarning(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = useCallback(() => {
    handleClose();
    navigate(`/avia/search/${url(searchRequest)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const oldPrice = warnings?.find(x => x.code === 'checkavailability_price_changed').additionalInfo.oldPrice
  const newPrice = warnings?.find(x => x.code === 'checkavailability_price_changed').additionalInfo.newPrice

  return (
    <StyledPopup open={isShowWarning}>
      <Wrapper>
        <Title>{warnings.find(x => x.code === 'checkavailability_price_changed') && 'Обращаем внимание!'}</Title>
        <Body>
          {warnings.find(x => x.code === 'checkavailability_price_changed') && (
            <>
              <Text>Места по ранее выбранному тарифу закончились.</Text>
              <Text>Стоимость полета изменилась.</Text>
              <Text style={{marginTop:20}}>
                <span style={{fontWeight: 'bold'}}>Старая стоимость перелета:</span>{' '}
                <span style={{color: '#737373', fontWeight: 'bold'}}>{formatPrice(oldPrice)}</span>
              </Text>
              <Text style={{marginTop:10}}>
                <span style={{fontWeight: 'bold'}}>Новая стоимость перелета:</span>{' '}
                <span style={{color: '#4872F2', fontWeight: 'bold'}}>{formatPrice(newPrice)}</span>
              </Text>
            </>
          )}
        </Body>
        <ButtonBlock>
          <Button type="primary" onClick={handleClose}>Продолжить бронирование</Button>
          <Button type="primary" onClick={handleButtonClick}>Новый поиск</Button>
        </ButtonBlock>
      </Wrapper>
    </StyledPopup>
  );
};

export default WarningNotification;
