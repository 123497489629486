import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './stylesSlider.scss'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import required modules
import { Pagination, Navigation } from 'swiper/modules'

export default function SliderPhoto({ gallery }) {
	if (gallery?.length === 1)
		return (
			<div>
				<div className='onePhoto-slider'>
					<div className='onePhoto-slider__wrapper'>
						<img
							className='onePhoto-slider__wrapper__img'
							src={gallery[0].image}
							alt={gallery[0].text}
						/>
					</div>
					<p className='onePhoto-slider__text'>
						{gallery[0].text && <span>{gallery[0].text}</span>}
					</p>
				</div>
			</div>
		)
	else
		return (
			<div className='slider-photo-gallery'>
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					loop={true}
					pagination={{
						clickable: true
					}}
					navigation={true}
					modules={[Pagination, Navigation]}
					className='gallery'>
					{gallery.map((item, index) => (
						<SwiperSlide key={item.order} className='wrapper-photo'>
							<div className='onePhoto-slider'>
								<div className='onePhoto-slider__wrapper'>
									<img
										className='onePhoto-slider__wrapper__img'
										src={item.image}
										alt={item.text}
									/>
								</div>
								<p className='onePhoto-slider__text'>
									{item.text && <span>{item.text}</span>}
								</p>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		)
}
