import CryptoJS from "crypto-js";

export function decrypt (encryptedText) {
	const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AUTH_CRM);
	const iv  = CryptoJS.enc.Utf8.parse("");
	const cipherParams = CryptoJS.lib.CipherParams.create({
		ciphertext: CryptoJS.enc.Base64.parse(encryptedText)
	});

	return CryptoJS.AES.decrypt(cipherParams, key,
		{ iv: iv,
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		}).toString(CryptoJS.enc.Utf8);
}