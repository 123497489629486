import PhotoTilesSlider from './PhotoTilesSlider'

import "./style.scss";

function PhotoTiles({ content, title, id }) {
    const handleClick = (link) => {
        window.open(link, "_blank");
    };

    if (content.typeTilesTpl === "threePhotoTilesGeneralText") {
        return (
            <div className='photo-tiles' id={id}>
                {content.threePhotoTilesTitle && <div className='photo-tiles-title'>{content.threePhotoTilesTitle}</div>}
                <div className="photo-tiles_three-grid">
                    {content.images.map((photo) => (
                        <div key={photo?.text} className="photo-tiles_three-item">
                            <img
                                src={photo?.preview}
                                onClick={() => handleClick(photo.link)}
                                alt=""
                            />
                            <p className="photo-tile_three-text">{photo?.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    if (content.typeTilesTpl === "staticFourPhotos") {
        return (
            <div className='photo-tiles' id={id}>
                {content.title && <div className='photo-tiles-title'>{content.title}</div>}
                <div className="photo-tiles_four-grid">
                    {content?.fourPhotos?.photos?.map((photo) => (
                        <div key={photo?.signature} className="photo-tiles_four-item">
                            <img
                                src={photo?.preview}
                                onClick={() => handleClick(photo.link)}
                                alt=""
                            />
                            <p className="photo-tiles_four-signature">{photo?.signature}</p>
                        </div>
                    ))}
                </div>
                {content?.fourPhotos?.text && <div dangerouslySetInnerHTML={{ __html: content?.fourPhotos?.text }} />}
            </div>
        );
    }

    if (content.typeTilesTpl === 'staticSliderTiles') {
        return (
            <div id={id}>
                <h2 className='staticSliderTiles-title'>{content.title}</h2>
                <PhotoTilesSlider slider={content.slider} />
            </div>)
    }

    return null;
}
export default PhotoTiles;
