import React from 'react';
import NewTextField  from '../NewTextField';
import { NumericFormat } from 'react-number-format';



export default function PriceTextField(props) {
  return  (
    <NumericFormat
      {...props}
      customInput={NewTextField}
      thousandSeparator=" "
      suffix={' ₽'}
    />
  )
}