import { call, all, takeLatest, put, select, spawn } from "redux-saga/effects"
import * as Manager from "./manager"
import {
  getAppealStatusesSuccess,
  getAppealSubjectsSuccess,
  getPaymentStatusesSuccess,
  getAppealReferencesRequest,
  getOrderReferencesRequest
} from "./duck"
import { referencesSelector } from "./selectors"

export function* getAppealReferencesRequestWorker() {
  const { appeals } = yield select(referencesSelector)
  if (appeals.statuses.length > 0 && appeals.subjects.length > 0) {
    return
  }

  yield spawn(function*() {
    const statuses = yield call(Manager.getAppealStatuses)
    yield put(getAppealStatusesSuccess(statuses))
  })

  yield spawn(function*() {
    const subjects = yield call(Manager.getAppealSubjects)
    yield put(getAppealSubjectsSuccess(subjects))
  })
}

export function* getOrderReferencesRequestWorker() {
  const { order } = yield select(referencesSelector)
  if (order.paymentStatuses.length > 0) {
    return
  }
  try {
    const statuses = yield call(Manager.getPaymentStatuses)

    yield put(getPaymentStatusesSuccess(statuses))
  } catch (e) {
    // console.log(e);
  }
}

export default function* referencesFlow() {
  yield all([
    takeLatest(getAppealReferencesRequest.getType(), getAppealReferencesRequestWorker),
    takeLatest(getOrderReferencesRequest.getType(), getOrderReferencesRequestWorker)
  ])
}
