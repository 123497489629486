import React,{ useState, useEffect } from 'react';

import './style.css'
import {format, parseISO} from "date-fns";
import OptionsBlock from "./OptionsBlock";
import InsurancesModal from './InsurancesModal'
import useBookingStore from "../../store";
import {Button} from "@material-ui/core";

export const INSURANCE_LINK = 'https://fstravel.com/searchinsurances'

const sortArr = (a, b) => {
	if(a.name.includes("Ингосстрах") && !b.name.includes("Ингосстрах")){
		return 1
	}
	if(!a.name.includes("Ингосстрах") && b.name.includes("Ингосстрах")){
		return -1
	}
	return b.type.id - a.type.id
}

const InsurancesBlock = ({insurances, currentSelectedCurrency}) => {
	const [modalVisible, setVisible] = useState(false)
	const [filteredInsurances, filterInsurances] = useState(insurances.sort(sortArr).filter(item => item.name.includes('BOX') || item.name.includes('Ингосстрах')))
	const paxes = useBookingStore((state) => state.paxes)
	const isInsurancesSelect = useBookingStore((state) => state.isInsurancesSelect)
	const actions = useBookingStore(({ actions }) => actions)
	const [ paxesLength,setPaxesLength ] = useState(paxes.length)
	const currencys = useBookingStore(({ currencys }) => currencys)

	const renderButton = (id) => {
		const isIncluded = paxes.some(item => item.selectedInsurances.find(f => f.id === Number(id)))
		if (isIncluded) {
			return <Button onClick={() => {actions.removeInsurance(id); filterInsurances(prev => prev.filter(i => i.id !== id))}} variant='contained' color='secondary' fullWidth>Удалить</Button>
		} else {
			return <Button onClick={() => actions.addInsurance(id)} variant='contained' color='primary' fullWidth>Добавить</Button>
		}
	}

	const onShowModal = () => setVisible(prev => !prev) 

	useEffect(()=>{
		if(isInsurancesSelect){
			filterInsurances(insurances.sort(sortArr).filter(item => paxes.some(paxe => paxe.selectedInsurances.find(i=> i.id === item.id))))
		}
	},[paxes,insurances,isInsurancesSelect])

	//ререндер компонента если добавлен младенец 
	useEffect(()=>{
		setPaxesLength(prev => paxes.length === prev ? prev : paxes.length);
	}, [paxes])

	useEffect(() => {
		const unsubscribe = useBookingStore.subscribe(
		  (state) => {
			setPaxesLength(state.tour.paxes?.length)
		  },
		  (state) => state.tour.paxes
		);
		return () => {
		  unsubscribe();
		};
	  }, [paxes]);

	  const priceInCurrency = value => {
		if (currentSelectedCurrency === 1) {
			return value + ' RUB'
		} else {
			const currency = Object.values(currencys)
				.map(item => item[0])
				.find(el => el.currencyKey === currentSelectedCurrency);
			return currency ? `${(value / currency.rate).toFixed(0)} ${currency.currency}` : value + ' RUB'
		}
	}
	
	return (
		<>
			<div className="block-header">
				<h3 className="title">Страхование</h3>
			</div>
			<div className="block-body">
				<div className="grid-insurances header">
					<div>Название</div>
					<div>Даты</div>
					<div></div>
					<div className="paxes">Количество</div>
					<div></div>
				</div>
				{filteredInsurances.map((item) =>{
					const isInclude = paxes.some(paxe => paxe.selectedInsurances.find(i=> i.id === item.id))
					const date = format(parseISO(item.startDate), 'dd.MM.yyyy') + "-" + format(parseISO(item.endDate), 'dd.MM.yyyy')
					
					return <React.Fragment key={item.name + paxes.length}>
						<div  className="grid-insurances body">
							<div>
								<a
									className='insurance-link'
									href={INSURANCE_LINK}
									target='_blank'
									rel='noreferrer'
								>
									{item.name}
								</a>
							</div>
							<div>{date}</div>
							<div>{!item.isRequired && `+${priceInCurrency(item.price.amount)}`}</div>
							<div className="paxes">{paxesLength}</div>
							{item.isRemovable && <div>
								{renderButton(item.id)}
							</div>}
						</div>
						<OptionsBlock options={item.options} isInclude={isInclude} date={date}/>
					</React.Fragment>}
				)}
				<div className='button-change'> <Button onClick={onShowModal} variant="contained" color="primary">Изменить страховки</Button></div>
				{modalVisible && 
							<InsurancesModal
								insurances={insurances}
								onClose={onShowModal}
							/>}
			</div>
		</>
	);
};

export default InsurancesBlock;