import React, { useMemo } from 'react'

import Grid from '@mui/material/Grid'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { ReactComponent as Print } from '../../../../asset/icons/Print.svg'

import { ReactComponent as AccordionCustomIcon } from '../../../../asset/icons/ArrowAccordion.svg'
import {
	Box,
	Divider,
	ImageList,
	ImageListItem,
	Tab,
	Tabs,
	styled as styledMui,
	useMediaQuery
} from '@mui/material'
import styled from 'styled-components'
import './style.css'
import { wordEndingFilter } from '../../../../functions/helpers'
import { PaidTooltip } from '../../../NewCabinet/Claims/components/ClaimTooltip'
import { Link } from 'react-router-dom'
import CruiseSwiper from './CruiseSwiper'

const Title = styled.div`
	line-height: 32px;
	font-size: 20px;
	font-weight: 700;
`
const RoutesInfo = styled.div`
	line-height: 32px !important;
	font-size: 20px !important;
`
const ColorButton = styled(Link)`
	display: block;
	align-items: center;
	background: ${({ $colorButton }) =>
		$colorButton ? $colorButton : '#4872f2'};
	border-radius: 8px;
	color: ${({ $colorButtonText }) =>
		$colorButtonText ? $colorButtonText : '#fff'};
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	width: auto;
	text-align: center;
	line-height: 24px;
	padding: 18px 28px;
	text-decoration: none;
	&:hover {
		color: ${({ $colorButtonText }) =>
			$colorButtonText ? $colorButtonText : '#2e2e32'};
	}
	@media (max-width: 600px) {
		font-weight: 400;
		font-size: 12px;
		line-height: 24px;
		padding: 5px 15px;
		width: auto;
	}
`

const Accordion = styledMui(props => (
	<MuiAccordion
		disableGutters
		elevation={0}
		{...props}
		style={{
			borderRadius: `${props.borderaccordition}px`,
			border: `2px solid ${props.bordercolor}`
		}}
	/>
))(({ theme }) => ({
	color: '#2E2E32',
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&::before': {
		display: 'none'
	}
}))

const AccordionSummary = styledMui(props => (
	<MuiAccordionSummary
		{...props}
		sx={{ m: 0 }}
		expandIcon={
			<AccordionCustomIcon
				fill={props.arrowcolor || '#D9D9D9'}
				stroke={props.arrowcolor || '#D9D9D9'}
			/>
		}
	/>
))(({ theme }) => ({
	color: '#2E2E32',
	fontWeight: 700,
	padding: '28px 40px',
	backgroundColor: 'rgba(255, 255, 255, .05)',
	[theme.breakpoints.down('sm')]: {
		padding: '18px 28px 18px 13px'
	},
	'& .MuiAccordionSummary-content': {
		margin: 0,
		lineHeight: '32px',
		fontSize: '20px',
		fontWeight: 400,
		[theme.breakpoints.down('sm')]: {
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '24px'
		}
	},
	'& .Mui-expanded .MuiGrid-item': {
		fontWeight: 700
	}
}))

const StyledTabs = styledMui(Tabs)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		padding: '0px 16px'
	},
	'& .MuiTabs-indicator': {
		backgroundColor: 'transparent'
	}
}))

const StyledTab = styledMui(props => <Tab disableRipple {...props} />)(
	({ theme, color }) => ({
		minWidth: 0,
		opacity: 0.6,
		textTransform: 'none',
		fontWeight: 700,
		fontSize: '20px',
		padding: '8px',
		paddingBottom: 0,
		color: color,
		[theme.breakpoints.down('sm')]: {
			color: '#D9D9D9',
			fontWeight: 700,
			fontSize: '14px',
			lineHeight: '24px',
			paddingBottom: '8px',
			'& .MuiTabs-scrollButtons': {
				marginBottom: '8px'
			}
		},
		'&.Mui-selected': {
			color: color,
			fontSize: '30px',
			paddingBottom: '8px',
			opacity: 1,
			[theme.breakpoints.down('sm')]: {
				color: '#2E2E32',
				fontWeight: 700,
				fontSize: '14px',
				lineHeight: '24px',
				paddingBottom: '8px'
			}
		}
	})
)

const AccordionDetails = styledMui(MuiAccordionDetails)(({ theme }) => ({
	padding: '0px 40px',
	[theme.breakpoints.down('sm')]: {
		padding: '0px 12px'
	}
}))

function srcset(image, size, rows = 1, cols = 1) {
	return {
		src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
		srcSet: `${image}?w=${size * cols}&h=${
			size * rows
		}&fit=crop&auto=format&dpr=2 2x`
	}
}

function CustomTabPanel(props) {
	const { children, value, index, isSmallScreen, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box
					sx={{
						mt: isSmallScreen ? '14px' : 0,
						maxHeight: 366,
						overflowY: 'auto'
					}}>
					{children}
				</Box>
			)}
		</div>
	)
}

export default function CruiseRoutes({ content, title }) {
	const isSmallScreen = useMediaQuery('(min-width:600px)')

	const dateRange = useMemo(() => {
		return `${content.dateStart
			.split('-')
			.reverse()
			.join('.')} - ${content.dateEnd.split('-').reverse().join('.')}`
	}, [content])

	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const routes = useMemo(() => {
		return content.routeText.join(' - ')
	}, [content])

	const itemData = useMemo(() => {
		return content.photos.map((photo, i) =>
			i
				? {
						img: photo,
						title: title,
						rows: 1,
						cols: 1
				  }
				: {
						img: photo,
						title: title,
						rows: 3,
						cols: 3
				  }
		)
	}, [content, title])

	function createMarkup(value) {
		return { __html: value }
	}

	const saveDocument = () => {
		window.open(content.fileLink, '_blank', 'rel=noopener noreferrer')
	}

	return (
		<Box sx={{ pb: '14px' }}>
			<Accordion
				borderaccordition={content.form.borderStyle.borderRadius}
				bordercolor={content.form.borderStyle.color.hex}>
				<AccordionSummary
					aria-controls='panel1-content'
					id='panel1-header'
					arrowcolor={content.form.borderStyle.color.hex}>
					<Grid container spacing={isSmallScreen ? 2 : 0} sx={{ pr: 1 }}>
						<Grid item xs={isSmallScreen ? 3 : 12}>
							<div>{dateRange}</div>
						</Grid>
						<Grid item xs={isSmallScreen ? 9 : 12}>
							<span>{routes}</span>
							<br />
							<span>
								{`${content.days} `}
								{wordEndingFilter(+content.days, ['день', 'дня', 'дней'])}/
								{`${content.nights} `}
								{wordEndingFilter(+content.nights, ['ночь', 'ночи', 'ночей'])}
							</span>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					{isSmallScreen && <Divider />}
					<Grid
						container
						sx={{ p: isSmallScreen ? 4 : 0, pl: 0 }}
						columnSpacing={isSmallScreen ? 10 : 0}>
						<Grid item xs={isSmallScreen ? 5 : 12}>
							{isSmallScreen ? (
								<ImageList
									sx={{ width: 412, height: 406 }}
									variant='quilted'
									cols={3}
									gap={14}
									rowHeight={90}>
									{itemData.map(item => (
										<ImageListItem
											key={item.img}
											cols={item.cols || 1}
											rows={item.rows || 1}>
											<img
												src={item.img}
												{...srcset(item.img, 90, item.rows, item.cols)}
												alt={item.title}
												loading='lazy'
											/>
										</ImageListItem>
									))}
								</ImageList>
							) : (
								<CruiseSwiper photos={itemData} />
							)}
						</Grid>
						<Grid
							item
							xs={isSmallScreen ? 7 : 12}
							style={{
								display: 'flex',
								flexDirection: isSmallScreen ? 'column' : 'column-reverse',
								justifyContent: 'space-between'
							}}>
							{content?.daysProgram?.map((item, index) => (
								<CustomTabPanel
									key={item.title}
									value={value}
									index={index}
									isSmallScreen={isSmallScreen}>
									<RoutesInfo>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}>
											{isSmallScreen && (
												<Title>{item.title.toUpperCase()}</Title>
											)}

											{content?.fileLink && isSmallScreen && (
												<PaidTooltip
													title={
														<React.Fragment>
															<div>Скачать программу маршрута</div>
														</React.Fragment>
													}
													placement='top'>
													<Print
														className='cruise-print-icon'
														onClick={saveDocument}
													/>
												</PaidTooltip>
											)}
										</Box>

										<Box
											className='container-routes'
											dangerouslySetInnerHTML={createMarkup(item.text)}></Box>
									</RoutesInfo>
								</CustomTabPanel>
							))}
							<Box>
								<StyledTabs
									scrollButtons
									allowScrollButtonsMobile
									variant={
										isSmallScreen
											? 'standard'
											: content?.daysProgram?.length > 3
											? 'scrollable'
											: 'standard'
									}
									value={value}
									onChange={handleChange}
									centered={isSmallScreen}>
									{content.daysProgram.map((item, index) => (
										<StyledTab
											key={item.title}
											label={
												isSmallScreen ? index + 1 : item.title.toUpperCase()
											}
											color={content.form.colorNumbers.hex}
										/>
									))}
								</StyledTabs>
							</Box>
						</Grid>
					</Grid>
					{content.button.title && (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								pr: isSmallScreen ? 4 : 0,
								pb: isSmallScreen ? '45px' : '43px',
								pt: isSmallScreen ? 0 : 2
							}}>
							<ColorButton
								to={content?.button.link ? content?.button.link : '#'}
								$colorButtonText={content?.button.colorText.hex}
								$colorButton={content?.button.color.hex}>
								{content.button.title}
							</ColorButton>
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}
