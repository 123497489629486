import React, {useEffect, useRef, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {IconButton, InputAdornment, OutlinedInput} from "@material-ui/core";
import {format} from "date-fns";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import Calendar from "react-calendar";
import {ru} from "date-fns/locale";
import DateChangeModal from './DateChangeModal'
import './style.scss'

const DateInputRange = ({ label, onChange, value }) => {

	const [selectedDate, handleDateChange] = useState(value);
	const [showCalendar, setShowCalendar] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)

	const calendarRef = useRef(null)

	useEffect(() => {
		setShowCalendar(false)
	}, [selectedDate])

	// useEffect(() => {
	// 	handleDateChange(new Date(value))
	// }, [value])

	useEffect(() => {
		function handleClick(event) {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setShowCalendar(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [calendarRef,setShowCalendar]);


	const handleChange = (date) => {
		handleDateChange(date)
		onChange(date)
	}

	const handleShowCalendar = () => {
		if (window.innerWidth > 766) {
			setShowCalendar(!showCalendar)
		} else {
			setModalVisible(true)
		}
	}

	return (
		<div style={{width: '100%', position: 'relative'}} ref={calendarRef} >
			<FormControl variant="outlined" style={{width: '100%'}}>
				<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
				<OutlinedInput
					label={label}
					id="outlined-adornment-password"
					value={selectedDate.map(date => format(date, 'dd MMM', {locale: ru})).join(' - ')}
					className="mui-date-input"
					onClick={handleShowCalendar}
					readOnly
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								edge="end"
							>
								<EventAvailableOutlinedIcon />
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
			{showCalendar && <div className="SearchExcursionCalendarWrapper" >
				<Calendar
					value={selectedDate}
					date={selectedDate}
					onChange={(date) => handleChange(date)}
					tileDisabled={({date}) => (date < new Date())}
					selectRange
					showDoubleView 
					showFixedNumberOfWeeks={false}
				/>
			</div>}
			{modalVisible && (
				<DateChangeModal dateRange={selectedDate} onClose={setModalVisible}>
					<Calendar
						value={selectedDate}
						date={selectedDate}
						onChange={(date) => handleChange(date)}
						tileDisabled={({date}) => (date < new Date())}
						selectRange
						// showDoubleView 
						showFixedNumberOfWeeks={false}
					/>
				</DateChangeModal>
			)}
		</div>
	);
};

export default DateInputRange;