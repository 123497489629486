import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import useGeoMenuStore from '../CompanyPopup/store'
import useMainMenuStore from './store'
import { ReactComponent as ArrowSmall } from '../../asset/icons/ArrowSmall.svg'
import './style.css'
import { isEmpty } from 'lodash'
import ym from 'react-yandex-metrika'

const MenuList = ({ item, clickActiveHandler }) => {
	const isActive = useMainMenuStore(({ isActive }) => isActive)
	const { pathname } = useLocation()
	const sendMenuMetrics = prop => {
		switch (prop) {
			case 'Поиск тура':
				ym('reachGoal', 'serach_plus_newSearch_main_page')
				break
			default:
				return
		}
	}
	return (
		<>
			{item?.is_active && (
				<div className='wrapper'>
					<div
						className={'v-search-tab-menu'}
						onClick={() => {
							clickActiveHandler(item.id)
							sendMenuMetrics(item.title)
						}}>
						<Link
							className={
								isActive === item.id &&
								(pathname === item.url || item.url === '#')
									? 'v-search-tab v-text-16 v-search-tab-active'
									: 'v-search-tab v-text-16 '
							}
							to={item.url}
							target={item.target}>
							<span>{item.title}</span>
							{!isEmpty(item.childs) && (
								<ArrowSmall className='v-icon-arrow_down_small' />
							)}
						</Link>
					</div>
					{!isEmpty(item.childs) && (
						<div className='v-search-tab-sub-menu'>
							{item?.childs
								?.filter(
									elem =>
										elem?.user_roles?.includes('agent') || !elem.user_roles
								)
								.map(item => (
									<MenuItem item={item} key={item.id} />
								))}
						</div>
					)}
				</div>
			)}
		</>
	)
}

const MenuItem = ({ item }) => {
	const sendChildMetrics = prop => {
		switch (prop) {
			case 'Новый поиск тура':
				ym('reachGoal', 'serach_plus_newSearch_main_page')
				break
			case 'Старый поиск тура':
				ym('reachGoal', 'old_search_main_page')
				break
			case 'Туры в Крым':
				ym('reachGoal', 'crimea_search_toura')
				break
			default:
				return
		}
	}
	return (
		<>
			{item.is_active && (
				<Link
					onClick={() => sendChildMetrics(item.title)}
					key={item.id}
					className={'v-search-tab-menu v-search-tab'}
					to={item.url}
					target={item.target}>
					<span>{item.title}</span>
				</Link>
			)}
		</>
	)
}

export default function MainMenu() {
	const mainMenu = useGeoMenuStore(({ mainMenu }) => mainMenu)
	const actions = useMainMenuStore(({ actions }) => actions)

	const clickActiveHandler = id => {
		actions.addActiveLink(id)
	}

	return (
		<div className='main-menu'>
			<nav className={'v-search-tabs v-text-16'}>
				{mainMenu
					?.filter(
						elem => elem?.user_roles?.includes('agent') || !elem.user_roles
					)
					.map(item => {
						return (
							<MenuList
								key={item.id}
								clickActiveHandler={clickActiveHandler}
								item={item}
							/>
						)
					})}
			</nav>
		</div>
	)
}
