import { CloseOutlined } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import declination from '../../../../../functions/declination'
import { isEmpty } from 'lodash'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import Calendar from '../../../../../asset/icons/ExcursionTour/Calendar'
import { Modal } from '@material-ui/core'
import CardSlider from './CardSlider'
import useYachtRentServicesStore from '../../../store'
import { ReactComponent as Alert } from '../../../../../asset/icons/alert-circle.svg'

function ResultCardModal({
	otherParams,
	title,
	price,
	startDate,
	child,
	adults,
	open = false,
	onClose,
	item
}) {
	const actions = useYachtRentServicesStore(({ actions }) => actions)
	const importantInfo = otherParams?.descriptions?.find(
		i => i?.title === 'Важная информация'
	)
	const description = otherParams?.descriptions?.find(
		i => i?.title === 'Описание'
	)
	const takeWithYou = otherParams?.descriptions?.find(
		i => i?.title === 'Взять с собой'
	)
	const additionalCost = otherParams?.descriptions?.find(
		i => i?.title === 'За дополнительную стоимость'
	)
	const program = otherParams?.descriptions?.find(i => i?.title === 'Программа')

	const capacity = otherParams?.descriptions?.find(i => i?.title === 'Вместимость яхты')

	const [isFlatFooter, setFlatFooter] = useState(false)
	const [isFlatHeader, setFlatHeader] = useState(true)

	const handleScroll = event => {
		const flat =
			event.target.scrollHeight - event.target.scrollTop - 1 <=
			event.target.clientHeight
		setFlatFooter(flat)
		setFlatHeader(!event.target.scrollTop)
	}

	const handleBooking = () => {
		actions.addToBasket(item)
	}

	return (
		<Modal
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'>
			<div className='result-card__modal'>
				<div className='result-card__modal-content'>
					<div className={`modal-header ${isFlatHeader ? '' : 'shadow'}`}>
						<div className='modal-header__title'>{title}</div>
						<CloseOutlined style={{ cursor: 'pointer' }} onClick={onClose} />
					</div>
					<div className='result-card__modal-content__wrapper'>
						<div
							className='result-card__modal-content__info'
							onScroll={handleScroll}>
							<div className='result-card__modal-slider-wrapper'>
								<CardSlider slides={otherParams?.images} />
							</div>
							<div>
								<div className='result-card__modal-date'>
									<Calendar />
									<p className='text-l'>
										{format(new Date(startDate), 'dd MMMM yyyy', {
											locale: ru
										})}
									</p>
								</div>
							</div>
							{description && <Description item={description} />}
							{program && <Description item={program} />}
							{takeWithYou && <Description item={takeWithYou} />}

							{importantInfo?.text && (
								<div className='important-info'>
									<h4 className='heading-4'><Alert />{importantInfo?.title}</h4>
									<div className='important-info__text'>
										{importantInfo.text}
									</div>
								</div>
							)}
							{additionalCost && <Description item={additionalCost} />}
							{capacity && <Description style={{ marginBottom: '16px' }} item={capacity} />}

							<div className='questions-line' />
							<div className='questions'>
								<div className='questions__content'>
									<h2 className='heading-2'>Остались вопросы?</h2>
									<p>
										{' '}
										По всем интересующим вас вопросам Вы можете связаться с нами
										по телефону Колл-центра:{' '}
										<a
											style={{ color: 'rgb(72, 114, 242)' }}
											href={'tel:78007757758'}>
											8 800 775 775 8
										</a>
									</p>
								</div>
							</div>
							<div className='questions-line' />
						</div>
					</div>
					<div className={`price-block ${isFlatFooter ? 'flat' : ''}`}>
						<div className=''>
							<h2 className='heading-2'>{price?.value} ₽</h2>
							<span className='price-block__adult-count'>
								За {adults} {declination(adults, ['взрослого', 'взрослых'])}{' '}
								{!isEmpty(child) &&
									'・ ' + child?.length +
									declination(child?.length, [' ребенка', ' детей'])}
							</span>
						</div>
						<div onClick={handleBooking} className='result-card__btn'>
							Забронировать
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

function Description({ item, style }) {
	const ref = useRef(null)
	const [show, setShow] = useState(false)
	const [showButton, setShowButton] = useState(false)

	useEffect(() => {
		if (ref?.current) {
			setShowButton(ref?.current?.scrollHeight > ref?.current?.clientHeight)
		}
	}, [item])

	if (!item?.text) {
		return null
	}

	return (
		<div className='description_wrapper' style={style}>
			<h4 className='description_title'>{item?.title}</h4>
			<div className={`description_text ${show ? 'show' : ''}`} ref={ref}>
				{item?.text}
			</div>
			{showButton && (
				<p className='show-btn' onClick={() => setShow(!show)}>
					{show ? 'Скрыть' : 'Подробнее'}
				</p>
			)}
		</div>
	)
}

export default ResultCardModal
