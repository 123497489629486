import React from "react"
import Text, { Header } from "../../ui/Typography"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useSelector,useDispatch } from "react-redux"
import { getAddAppealState } from "../../../store/modules/appeals"
import { refreshOrder } from "../../../store/modules/orders"
import checkIconSrc from "../../../asset/images/avia/appeal-forms/check.svg"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledLink = styled(Link)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #4872F2;
  text-decoration: none;
`

const Check = styled.img`
  margin-left: 8px;
  width: 32px;
  height: 32px;
`

const SecondRow = styled(Text)`
  color: #7E8389;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  display: block;
  margin-top: 30px;
  margin-bottom: 20px;

  & > strong {
    color: #4872F2;
    font-weight: 600;
  }
`;

const LastRow = styled(Text)`
  color: #3C3C3C;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 20px 

  a > strong {
    color: #4872F2;
    font-weight: 600;
  }
`;

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 8px;
    padding: 40px;
    padding-bottom: 30px;
    width: 569px;
    box-sizing: border-box;
    position: relative;
    display: block;
  
    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
      padding: 25px 21px;
      height: auto;
      min-height: 100%;
      display: flex;
      // align-items: center;
    }
  }
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const CreateFormSuccess = (props) => {
  const { response } = useSelector(getAddAppealState)
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(refreshOrder());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <StyledPopup {...props}>
        <CloseButton
          aria-label="close"
          onClick={props.onClose}
        >
            <Icon/>
        </CloseButton>
        <Head>
          <Header size="h3">Обращение принято в обработку</Header>
          <Check src={checkIconSrc} alt="" />
        </Head>
        <SecondRow>
          Номер обращения: <strong>{response?.number || "неизвестно"}</strong>
        </SecondRow>
        <LastRow>
          Мы в ближайшее время ответим на ваш запрос. Следить за статусом обращения можно в разделе <StyledLink to={`/cabinet/orders/${response?.orderNumber}/tickets`}>“Обращения”</StyledLink> в Личном кабинете.
        </LastRow>
    </StyledPopup>
  )
}

export default CreateFormSuccess
