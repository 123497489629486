import * as React from 'react'
import Popper from '@mui/material/Popper'
import { Paper } from '@mui/material'
import useGeoMenuStore from './store'
import { Grid } from '@material-ui/core'
import './style.css'
import { Link } from 'react-router-dom'

const ItemMenu = ({ item, clickHandler }) => (
	<>
		{item.is_active && (
			<Grid item xs={4}>
				<p className='list-title'>{item?.title}</p>
				{item?.childs
					.filter(
						elem =>
							elem?.user_roles?.includes('agent') || elem.user_roles === null
					)
					.sort((a, b) => a.priority - b.priority)
					.map(item => {
						return (
							<ItemChild
								item={item}
								clickHandler={clickHandler}
								key={item.id}
							/>
						)
					})}
			</Grid>
		)}
	</>
)

const ItemChild = ({ item, clickHandler }) => (
	<>
		{item.is_active && (
			<div className='list-container'>
				<Link
					className='list-link'
					onClick={clickHandler}
					to={`${item.url}`}
					target={item.target}>
					{item.title}
				</Link>
			</div>
		)}
	</>
)

export default function CompanyPopup({ open, handleClick, anchorEl, setOpen }) {
	const contentMenu = useGeoMenuStore(({ contentMenu }) => contentMenu)
	const actions = useGeoMenuStore(({ actions }) => actions)

	React.useEffect(() => {
		actions.getMenuContent()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const canBeOpen = open && Boolean(anchorEl)
	const id = canBeOpen ? 'transition-popper' : undefined

	const clickHandler = () => {
		setOpen(false)
	}

	return (
		<div onMouseLeave={handleClick}>
			<Popper
				modifiers={[
					{
						name: 'offset',
						enabled: true,
						options: {
							offset: [456, 22]
						}
					}
				]}
				id={id}
				disablePortal={true}
				open={open}
				anchorEl={anchorEl}
				className='popper'>
				<Paper
					sx={{
						pl: 6,
						pr: 6,
						pt: 4,
						pb: 6,
						bgcolor: 'background.paper'
					}}
					elevation={0}>
					<Grid container spacing={0}>
						{contentMenu?.map(item => {
							return (
								<ItemMenu
									item={item}
									clickHandler={clickHandler}
									key={item.id}
								/>
							)
						})}
					</Grid>
				</Paper>
			</Popper>
		</div>
	)
}
