import * as React from 'react'
import { Dialog, DialogContent, styled } from '@material-ui/core'
import VerticalTabs from './VerticalTabs'
import './style.css'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(0),
		'&::-webkit-scrollbar': {
			backgroundColor: 'transparent',
			width: '4px',
			height: '4px'
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: 'transparent'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#B3B9C0',
			borderRadius: '4px',
			maxHeight: '64px',
			width: '4px'
		},
		'&::-webkit-scrollbar-button': {
			display: 'none'
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: "#253861",
		}
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(0)
	}
}))

export default function HotelPopup({ open, handleClose }) {
	return (
		<BootstrapDialog
			PaperProps={{
				style: { borderRadius: 16, minWidth: '1000px' }
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			open={open}>
			<DialogContent>
				<VerticalTabs handleClose={handleClose} />
			</DialogContent>
		</BootstrapDialog>
	)
}
