import { create } from 'zustand'

export const useErrorStore = create((set, get) => ({
    error: null,
    valid: 0,
    setErrorForm: value => {
        set({ error: value })
    },
    checkValid: () => set({ valid: get().valid + 1 }),
}))
