import React from 'react';
import styled from 'styled-components';
import Text from './Typography';

const NotyContainer = styled.div`
  padding: 14px;
  background: #FFF3CD;
  border: 1px solid #FFB800;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;

const StyledText = styled(Text)`
  & span {
    font-weight: 600;
  }
`

export const AppealNotification = () => {
  return (
    <NotyContainer>
      <StyledText>
        <span>Внимание! Служба поддержки по вопросам проведения обмена и возврата работает ежедневно с 09:00 до 21:00 по мск.</span>{' '}
        Если у вас срочное обращение, рекомендуем обратиться к авиаперевозчику.
      </StyledText>
    </NotyContainer>
  );
};
