import { Icon } from '@mui/material'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components';


const StyledLink = styled(NavLink)`
	display: flex;
	align-items: center;
	min-height: 56px;
  	color: #B3B9C0;

	&.active {
		color: rgb(60, 60, 60);
		opacity: 1;
		background-color: rgb(237, 241, 254);
    	img {
        	opacity: 1;
    	}
	}
  
	&:hover {
		img {
        	opacity: 1;
		}
	}
`

const StyledIcon = styled(Icon)`
  	img {
      opacity: .5;
	}
`

const LinkContent = styled.span`
	margin-left: 16px;
    display: flex;
    align-items: center;
`

const TabLink = ({icon, label, value, styles, ...props}) => {
	return (
		<StyledLink {...props} value={value} style={{display: 'flex'}} >
			<LinkContent>
				<StyledIcon style={{marginRight:"15px", marginBottom:"0px"}}>
					<img alt='' src={icon} />
				</StyledIcon>
				<span style={styles} dangerouslySetInnerHTML={{__html: label}} value={value} />
			</LinkContent>
		</StyledLink>
	);
}

export default TabLink;
