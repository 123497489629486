import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import { sortBy } from 'lodash'
import { arrivalCountriesMap } from './components/YandexMap/components/CountrySelect'

// const BASE_URL_GEO = "http://10.20.20.24:32000"
const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useCountryStore = create(
	devtools((set, get) => ({
		countriesList: [],
		countries: [],
		allCountries: [],
		openCountries: [],
		actions: {
			getCountryList: async () => {
				const res = await axios({
					url: `${BASE_URL}/geocontent/country/list`,
					method: 'GET',
					headers: {
						'Content-Type': `application/json`,
						'X-TUI-Storefront-Id': `b2c:ru`,
						'Accept-Language': 'ru-RU'
					}
				})
				set({ countriesList: res.data })

				const allFilterCountries = res.data.filter(
					country =>
						arrivalCountriesMap?.includes(country.name) &&
						parseInt(country?.latitude) < 90 &&
						parseInt(country?.latitude) > 0
				)

				set({
					allCountries: sortBy(allFilterCountries, 'name')
				})
				set({
					openCountries: sortBy(
						allFilterCountries.filter(country => country.isCovidAvailable),
						'name'
					)
				})
			},
			getCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=1&ClientId=b2c:ru`,
						method: 'GET',
						params: {
							LoadCities: false
						}
					})
					set({ countries: res.data })
				} catch (e) {
					console.log(e)
				}
			}
		}
	}))
)

export default useCountryStore
