import React, { useEffect, useState } from 'react'

import './style.css'
import { format } from 'date-fns'
import ForwardFlightSelect from './ForwardFlightSelect'
import { isEmpty } from 'lodash'
import BackwardFlightSelect from './BackwardFlightSelect'
import Baggage from './Baggage'
import useBookingStore from '../../store'
import TransferFlight from './TransferFlight/TransferFlight'
import EventSeatOutlinedIcon from '@material-ui/icons/EventSeatOutlined';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import Boarding from "../Boarding";


const AviaBlock = () => {
	// const { forwardFlightId, backwardFlightId } = useBookingStore()
	const actions = useBookingStore(({ actions }) => actions)
	const tour = useBookingStore(({ claimNew }) => claimNew.claimDocument)
	const transports = useBookingStore(({ selectedTransport }) => selectedTransport)
	const variants = useBookingStore(({ transports }) => transports)

	const peoples = useBookingStore(({ peoples }) => peoples)
	const [openBoarding, setOpenBoarding] = useState(false)
	const [boardingData, setBoardingData] = useState({ freight: null, dateFreight: null })

	if (isEmpty(transports)) return

	const forwardFlight = transports.find(item => !item?.direction)
	const backwardFlight = transports.find(item => item?.direction)
	const forwardFlightId = forwardFlight?.key;
	const backwardFlightId = backwardFlight?.key;

	const closeBoarding = () => {
		setOpenBoarding(false)
		actions.clearBoarding()
	}

	const getBoarding = (id, date) => {
		setBoardingData({ freight: id, dateFreight: date })
		setOpenBoarding(true)
	}

	// useEffect(() => {
	// 	actions.setSelectedFlightId()
	// }, [forwardFlightId, backwardFlightId, actions])

	if (isEmpty(transports)) return null

	const fullBoardingForward = () => {
		return forwardFlight?.clients.filter(client => client.seatKey).length === tour.peopleCount
	}

	const fullBoardingBackward = () => {
		return backwardFlight?.clients.filter(client => client.seatKey).length  === tour.peopleCount
	}

	const isFullBoardingBackward = fullBoardingBackward()
	const isFullBoardingForward = fullBoardingForward()

	return (
		<>
			<div className='block-header'>
				<h3 className='title'>Транспорт</h3>
			</div>
			{isEmpty(transports) ? (
				<div className='no-flights'>Нет доступных перелетов</div>
			) : !isEmpty(variants) ? (
				<div className='block-body'>
					<div className='grid-avia header'>
						<div>Рейс</div>
						<div>Дата</div>
						<div>Кол-во</div>
						<div></div>
					</div>
					<div className='grid-avia body'>
						<div className='select-flight'>
							<ForwardFlightSelect
								value={forwardFlightId}
								options={[...transports, ...variants].filter(i => !i.direction)}
								title='Туда'
								onChange={actions.changeForwardFlights}
							/>
						</div>
						<div data-label="Дата" className='flight-date'>
							{format(new Date(tour.dateBeg), 'dd.MM.yyyy')}
						</div>
						<div data-label="Кол-во" className='flight-paxes'>{tour.peoples.filter(pax => pax.age !== 3).length}</div>
						<div data-label="Выбор места" style={{ display: 'flex', alignItems: "center" }}>
							{transports.find(item => item.key === forwardFlightId)?.boardingAvailable &&
								(!isFullBoardingForward ? <EventSeatOutlinedIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(forwardFlightId, tour.dateBeg)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/> : <EventSeatIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(forwardFlightId, tour.dateBeg)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/>)}
						</div>
					</div>
					<Baggage
						baggages={
							transports.find(item => item.key === forwardFlightId)
								?.tariffs
						}
					/>
					<div className='grid-avia body'>
						<div className='select-flight'>
							<BackwardFlightSelect
								value={backwardFlightId}
								options={[...transports, ...variants].filter(i => i.direction)}
								title='Обратно'
								onChange={actions.changeBackwardFlights}
							/>
						</div>
						<div data-label="Дата" className='flight-date'>
							{format(new Date(tour.dateEnd), 'dd.MM.yyyy')}
						</div>
						<div data-label="Кол-во" className='flight-paxes'>{peoples.filter(pax => pax.age !== 3).length}</div>
						<div data-label="Выбор места" style={{ display: 'flex', alignItems: "center" }}>
							{transports.find(item => item.key === backwardFlightId)?.boardingAvailable &&
								(!isFullBoardingBackward ? <EventSeatOutlinedIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(backwardFlightId, tour.dateEnd)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/> : <EventSeatIcon
									style={{ cursor: "pointer" }}
									onClick={() => getBoarding(backwardFlightId, tour.dateEnd)}
									titleAccess={'Нажмине чтобы выбрать места'}
								/>)}
						</div>
					</div>
					<Baggage
						baggages={
							transports.find(
								item => item.key === backwardFlightId
							)?.tariffs
						}
					/>
				</div>
			) : (
				<TransferFlight flight={transports} />
			)}
			{openBoarding && <Boarding dateFreight={boardingData.dateFreight} freight={Number(boardingData.freight)} onClose={closeBoarding} />}
		</>
	)
}

export default AviaBlock
