import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  display: flex;
  align-items: center;
`;

const Checkmark = styled.div`
  ${({ theme}) => `
    display: inline-block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: #ffffff;
    transition: all 150ms;
    cursor: pointer;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        width: 14.7px;
        height: 14.7px;
        background-color: transparent;
        transition: background-color .26s ease;
    }
    & > div {
        transition: all 150ms;
        display: block;
        background: #4872F2;
    }
    `}
`;

const Input = styled.input.attrs({type: 'radio',})`
${({theme}) => `
display: none;
  & ~ ${Checkmark} {
    border: 1px solid #B9B9B9;
    & > div {
      transform: scale(0);
    }
  }
  &:checked ~ ${Checkmark} {
    border: 1px solid #4872F2;
    ${theme === 'checkbox' ? `background: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3231 4.3699L5.97566 12.6148L0.878401 8.16658L2.24029 6.66123L5.95716 9.90479L12.9416 3.00617L14.3231 4.3699Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center #4872F2;` : ''}
    &:after {
      ${theme !== 'checkbox' ? 'background-color: #4872F2;' : ""}
    }
  }
`}
`;

const Label = styled.span`
  padding-left: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;

const Radio = ({ label, customLabel, type, theme, ...props }) => (
  <Wrapper>
    <Input {...props} theme={theme} />
    <Checkmark theme={theme}/>
    {label && <Label>{label}</Label>}
    {customLabel && <Label dangerouslySetInnerHTML={{__html: customLabel}}/>}
  </Wrapper>
);

export default Radio;
