import React from 'react';
import styled from 'styled-components';
import {useParams } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {search} from '../../store/modules/search-result/duck'
import {getSearchResult} from '../../store/modules/search-result/selectors'
import {Skeleton,Filter,TicketList,RequestError} from '../../components/AviaSearchResult'


const Wrapper = styled.div`
    padding-left: 12px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
`;

/* eslint-disable */
export default function() {
    const data = useSelector(getSearchResult);
    const dispatch = useDispatch();
    const {params} = useParams();

    React.useEffect(() => {
        dispatch(search({url: params}));
    },[params])

    return (
        <Wrapper>
            { data.loadStatus.code === 'Idle' || data.loadStatus.code  === 'Loading' && <Skeleton/>}
            { data.loadStatus.code === 'Failure'  && <RequestError/>}
            {data.loadStatus.code === 'Success' && (<>
                {data.flightSearch.flightsList.notFilteredItems.length > 0 && <Filter />}
                {data.flightSearch.flightsList.items.length > 0 && <TicketList />}
            </>)}
        </Wrapper>
    )
}