import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTicketProps } from '../useTicketProps';
import {Text} from '../../../ui';
import formatPrice from '../../../../functions/formatPrice'
import { getFeatureIcon } from '../utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: ${({ isActive }) => (isActive ? '0px' : '22px')};
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;




const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872F2;
`;


const FlagsWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const Head = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    prices,
    isActive,
    features,
  } = useTicketProps();


  return (
    <Wrapper isActive={isActive}>
      {isMobile && <Price>{formatPrice(prices[0])} </Price>}

      {!isMobile && (
        <FlagsWrapper>
          {features.map((feature, index) => {
            return (
              <span key={index} style={{ marginRight: 5 }}>
                {getFeatureIcon(feature)}
              </span>
            );
          })}
        </FlagsWrapper>
      )}
    </Wrapper>
  );
};

export default Head;
