import React from "react"
import styled from "styled-components"
import { Field, RadioGroup } from "../../ui"
import {
  APPLY_TO,
  TYPE_AMOUNT
} from "../../../store/modules/agents/agent-fees/constants"
import blueInfoIcon from "../../../asset/images/avia/agents/blueInfoIcon.svg"
import AgentFeesModal from "../../modals/agentModals/AgentFeesModal"

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
`

const Heading = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  margin-bottom: 20px;
`

const SubHeading = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-bottom: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoBlock = styled.div`
  border: 1px solid #4872f2;
  border-radius: 8px;
  padding: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  display: flex;
  align-items: center;
`

const InfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  margin-right: 7px;
`

const StyledField = styled(Field)`
  width: 256px;
  height: 40px;
  margin-right: 20px;
  @media (max-width: 1146px) {
    width: 335px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
  margin-bottom: 27px;
  margin-top: 12px;
  cursor: pointer;
`

const PriceRow = props => {
  const { handleBlur, handleChange, values, setFieldValue } = props
  const typeAmount = values.applyTo
  const [agentFeesPopUpOpen, setAgentFeesPopUp] = React.useState(false)

  return (
    <>
      <AgentFeesModal
        agentFeesPopUpOpen={agentFeesPopUpOpen}
        setAgentFeesPopUp={setAgentFeesPopUp}
      />
      <Heading>Настройка применения сборов</Heading>
      <SubHeading>
        Выберите один из вариантов применения сборов: на заявку целиком, на
        сегменты и/или пассажиров.
      </SubHeading>
      <SubHeading>
        Для вашего удобства подготовлены примеры расчетов всех предложенных
        сценариев.{" "}
      </SubHeading>
      <FlexRow onClick={() => setAgentFeesPopUp(true)}>
        <InfoIcon /> Посмотреть пример установки сборов{" "}
      </FlexRow>
      <Row>
        <StyledField
          label="Размер сбора"
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount ?? ""}
          error={
            props.errors.amount && props.touched.amount
              ? props.errors.amount
              : undefined
          }
        />
        <RadioGroup
          name="calculateType"
          flex
          defaultValue={values.calculateType ?? ""}
          onChange={value => {
            setFieldValue("calculateType", value)
          }}
          items={TYPE_AMOUNT}
        />
      </Row>
      <Column>
        <RadioGroup
          name="applyTo"
          defaultValue={values.applyTo ?? ""}
          onChange={value => {
            setFieldValue("applyTo", value)
          }}
          items={APPLY_TO}
        />
      </Column>
      {typeAmount && (
        <InfoBlock>
          <InfoIcon />{" "}
          {typeAmount === "segment"
            ? `Расчет сбора сработает по формуле: ${values.amount} ${
                values.calculateType === "rub" ? "₽" : "%"
              }  х количество сегментов в заявке`
            : typeAmount === "passanger"
            ? `Расчет сбора сработает по формуле: ${values.amount} ${
                values.calculateType === "rub" ? "₽" : "%"
              } х количество пассажиров в заявке`
            : typeAmount === "all"
            ? `Расчет сбора сработает по формуле: ${values.amount} ${
                values.calculateType === "rub" ? "₽" : "%"
              } х количество сегментов х количество пассажиров`
            : `Расчет сбора сработает по формуле: ${values.amount} ${
                values.calculateType === "rub" ? "₽" : "%"
              } + стоимость перелета`}
        </InfoBlock>
      )}
    </>
  )
}

export default PriceRow
