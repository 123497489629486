import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { format } from 'date-fns'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useReportsStore = create(
	devtools((set, get) => ({
		loading: false,
		onlineSid: null,
		startDate: '',
		endDate: '',
		claimNumber: '',
		formatFile: 'PDF',
		actions: {

			agentReportList: async ( ) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const authSid = JSON.parse(localStorage.getItem('authSid'))

				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/list`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							clientId: "b2c:ru",
							authSid: authSid,
						},
					});

					console.log('agentReportList', res.data);
				
				} catch (e) {
					console.error('Error in agentReportList:', e?.response?.data);
				} finally {
							set({ loading: false })
				}
			},	

			agentReportParams: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const authSid = JSON.parse(localStorage.getItem('authSid'))
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/params`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							clientId: "b2c:ru",
							authSid: authSid,
							reportId: 300,
						},
					});

					console.log('AgentReportParams', res.data);
				
				} catch (e) {
					console.error('Error in AuthInfo:', e?.response?.data);
				} finally {
							set({ loading: false })
				}
			},	

			agentReportGenerate: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const partner = JSON.parse(localStorage.getItem('agentInfo'))?.agents?.id
				const authSid = JSON.parse(localStorage.getItem('authSid'))
				const start = format(get().startDate, 'yyyyMMdd')
				const end = format(get().endDate, 'yyyyMMdd')

				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/generate`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							clientId: "b2c:ru",
							reportId: 300,  
							authSid: authSid,
							partpassId: partpass,
							reportParams: `datebeg=${start};dateend=${end};claim_list=${get().claimNumber};OutputFormat=PDF;partner=${partner};partpass=${partpass};user=327;auth_type=agency;lang_id=21;ip=84.18.115.56`,
						},
					});

					if (res.data.report.jobId) {
						const timeoutId = setTimeout(async () => {
							await get().actions.agentReportResult(res.data.report.jobId)
							clearTimeout(timeoutId);
						}, 8000);
					}
				
				} catch (e) {
					console.error('Error in agentReportGenerate:', e?.response?.data);
					set({ loading: false })
				} finally {
				}
			},	

			agentReportResult: async (id) => {
				set({ loading: true })

				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const authSid = JSON.parse(localStorage.getItem('authSid'))
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/result`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`
						},
						data: {
							clientId: "b2c:ru",
							authSid: authSid,
							jobId: id,
						},
						// responseType: 'blob',
					})


					if (res.status !== 200){
						return  res.status
					}  
						const link = document.createElement('a');
						link.href = "data:application/pdf;base64," + res.data.result.content;
						link.download = res.data.result.fileName;
 						document.body.appendChild(link);
						link.click();
						link.remove();
				
				} catch (e) {
					console.error('Error in agentReportResult:', e );
				} finally {
					set({ loading: false })
					get().actions.clearReportParams()
				}
			},
			changeReportParams: (key, value) => {
				set({ [key]: value})
			},
			clearReportParams: () => {
				set({ startDate: '', endDate: '', claimNumber: '' })
			}
		}
	}))
)

export default useReportsStore