import { select, call } from "redux-saga/effects"
import _ from "lodash"
import {
  getBookingTicketState,
  getSelectedFare,
  addictionalServicesSelector,
  bookingInsuranceSelector,
  getBookingAncillaryServicesState,
  getSeatMapState
} from "../../selectors"
import * as Manager from "../../manager"
import { createBookingDto } from "../../utils"
import {
  getUserDataState
} from "../../../user"

function navigate(url) {
  window.location.href = url;
}

export function* bookTicket(data) {
  const ticket = yield select(getBookingTicketState)
  const fare = yield select(getSelectedFare)
  const addictionalServices = yield select(addictionalServicesSelector)
  const seatMapState = yield select(getSeatMapState)
  const insurance = yield select(bookingInsuranceSelector)
  const ancillaryServices = yield select(getBookingAncillaryServicesState)
  const user = yield select(getUserDataState)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(decodeURIComponent(queryString))

  const urlParamsfull = new URLSearchParams(window.location.search)
  const agentId = urlParamsfull.get("agentId")
  const salesChannel = urlParams.get("partner") || "tui"
  const marker = urlParams.get("marker") || null

  const isInsurancesChecked = insurance.list.offers.some(item => item.checked)

  const result = yield call(
    Manager.bookTicket,
    createBookingDto(
      ticket,
      ancillaryServices,
      fare.upsellIndex,
      fare,
      {
        ...data,
        salesChannel,
        isInsurancesChecked,
        marker,
        fareName: fare.name,
        InsuranceProductIds: _(insurance.list.offers)
          .filter(item => item.checked)
          .map(item => item.product.id)
      },
      addictionalServices,
      seatMapState,
      user,
      agentId
    )
  )

  if (!!result?.orderNumber) {
    yield call(navigate, `/cabinet/orders/${result.orderNumber}`)
  }
}
