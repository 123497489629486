import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'
import { generate } from '@wcj/generate-password'
import { getCookie } from 'react-use-cookie'
import { format, isDate } from 'date-fns'

const BASE_URL = process.env.REACT_APP_FS_APIGATE
const API_AUTH_USERINFO = process.env.REACT_APP_FS_AUTH + '/connect/userinfo'
const API_REFRESH_TOKEN = process.env.REACT_APP_FS_AUTH + '/connect/token'
const FORGOT_URL =
	process.env.REACT_APP_FS_AUTH + '/api/account/forgot-password'

const useAuthStore = create(
	devtools((set, get) => ({
		samoLink: '',
		samoLinks: {},
		error: '',
		forgotError: '',
		agent: JSON.parse(localStorage.getItem('agentInfo')),
		agentsUsers: JSON.parse(localStorage.getItem('agentsUsers')),
		agents: null,
		token: JSON.parse(localStorage.getItem('token')),
		banks: null,
		directorPositions: null,
		activities: null,
		ownerships: null,
		currentAgent: null,
		loading: null,
		isLogOut: false,
		officialName: JSON.parse(localStorage.getItem('officialName')),
		actions: {
			clearError: () => {
				set({ error: '' })
			},
			logout: () => {
				localStorage.removeItem('agentInfo')
				localStorage.removeItem('token')
				set({ isLogOut: true })
				set({ agent: null })
				set({ samoLinks: {} })
			},
			signin: async (login, password) => {
				set({ isLogOut: false })
				try {
					const res = await axios({
						url: `${BASE_URL}/auth/signin`,
						method: 'POST',
						data: {
							login,
							password,
							client_id: 'b2b.public.client'
						}
					})
					const newToken = res.data
					set({ token: newToken })
					localStorage.setItem('token', JSON.stringify(newToken))
					set({ error: '' })
					await get().actions.getUserInfo(res.data)
					await get().actions.getAgents(res.data)
					await get().actions.getAgentsUsers(res.data)
					try {
						const tteToken = await axios({
							url: process.env.REACT_APP_TTE_SIGN_IN,
							method: 'POST',
							data: {
								login,
								password,
								grant_Type: 'password'
							}
						})
						localStorage.setItem('tte_token', tteToken.data.accessToken)
					} catch (e) {
						console.log(e?.response?.data, 'e')
					}
				} catch (e) {
					set({ error: JSON.parse(e.response.data.detail).message })
					console.log(e?.response?.data, 'e')
				} finally {
				}
			},
			getAgents: async req => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents?PartpassId=${get().agent.partPass}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					const result = { ...get().agent, agents: res.data }
					set({ agent: result })
					set({ officialName: res.data.officialName })
					localStorage.setItem(
						'officialName',
						JSON.stringify(get().officialName)
					)
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			changeAgents: async () => {
				const req = get().agent?.agents
				try {
					await axios({
						url: `${BASE_URL}/agents?PartpassId=${get().agent.partPass}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					await get().actions.getUserInfo(get().token)
					await get().actions.getAgents(get().token)
					await get().actions.getAgentsUsers(get().token)
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			getAgentsUsers: async req => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/users?PartpassId=${get().agent.partPass}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					const result = { ...get().agent, agentsUsers: res.data }
					set({ agent: result })
					localStorage.setItem('agentInfo', JSON.stringify(get().agent))
					set({ agentsUsers: res.data })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			deleteAgentsUser: async partPass => {
				for (let i = 0; i < partPass.length; i++) {
					const partPassId = partPass[i]
					const newUser = get().agent?.agentsUsers?.find(
						agent => agent.partpassId === partPassId
					)
					const req = { ...newUser, isArchived: true }
					try {
						const res = await axios({
							url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
							method: 'PATCH',
							headers: {
								Authorization: `Bearer ${get().token?.access_token}`
							},
							data: req
						})
						console.log(res.data)
						const result = [...get().agentsUsers].map(item => {
							if (item.partpassId === partPassId) {
								return res.data
							} else return item
						})
						set({ agentsUsers: result })
						localStorage.setItem(
							'agentsUsers',
							JSON.stringify(get().agentsUsers)
						)
					} catch (e) {
						console.log(e?.response?.data, 'e')
					}
				}
			},
			recoveryAgentUser: async () => {
				const partPassId = get().currentAgent?.partpassId
				const newUser = get().agent?.agentsUsers?.find(
					agent => agent.partpassId === partPassId
				)
				const req = { ...newUser, isArchived: false }
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					const result = [...get().agentsUsers].map(item => {
						if (item.partpassId === partPassId) {
							return res.data
						} else return item
					})
					set({ agentsUsers: result })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			deleteAgentUser: async () => {
				const partPassId = get().currentAgent?.partpassId
				const newUser = get().agent?.agentsUsers?.find(
					agent => agent.partpassId === partPassId
				)
				const req = { ...newUser, isArchived: true }
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/user?PartpassId=${partPassId}`,
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					console.log(res.data)
					const result = [...get().agentsUsers].map(item => {
						if (item.partpassId === partPassId) {
							return res.data
						} else return item
					})
					set({ agentsUsers: result })
					localStorage.setItem('agentsUsers', JSON.stringify(get().agentsUsers))
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			addAgentUser: async agent => {
				const pass = generate({
					length: 6,
					numbers: true
				})
				set({ loading: true })
				const req = {
					partnerId: get().agent?.agents.id,
					creatorPartpassId: 0,
					fullName:
						agent.user.lastName +
						' ' +
						agent.user.name +
						' ' +
						agent.user.patronymic,
					phones: agent.selectPhone + agent.phone,
					faxes: agent.user.fax,
					email: agent.user.email,
					position: agent.user.job,
					isAdministrator: agent.access === 'Полный',
					login: agent?.login,
					birthDate: format(agent.birthday, 'yyyy-MM-dd'),
					isMale: agent.gender === 'Мужской',
					onlineAccess: agent.onlineAccess,
					townName: agent.user.townName,
					password: pass
				}
				// console.log(req,"req");
				try {
					await axios({
						url: `${BASE_URL}/agents/user`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						},
						data: req
					})
					// console.log(res)
					await get().actions.getAgentsUsers(get().token)
					set({ loading: false })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({ loading: false })
				}
			},
			editAgentsUser: async () => {
				const newPrefix = get().currentAgent?.prefix
				const phoneNumber = get().currentAgent?.number
				const currrentDate = get().currentAgent.birthday
				const date = isDate(currrentDate)
					? format(currrentDate, 'yyyy-MM-dd')
					: currrentDate
				if (newPrefix && phoneNumber) {
					const newPhone = newPrefix + phoneNumber
					const newAgent = {
						...get().currentAgent,
						phone: newPhone,
						fullName:
							get().currentAgent.lastName +
							' ' +
							get().currentAgent.firstName +
							' ' +
							get().currentAgent.patronymic,
						isMale: get().currentAgent.isMale === 'Мужской',
						isAdministrator: get().currentAgent.isAdministrator === 'Полный',
						// isOnlineAccess: get().currentAgent.isOnlineAccess,
						onlineAccess: get().currentAgent.isOnlineAccess,
						// birthday: format(date, 'yyyy-MM-dd')
						birthday: date
					}
					delete newAgent.prefix
					delete newAgent.number
					delete newAgent.firstName
					delete newAgent.lastName
					delete newAgent.patronymic
					// console.log('NewAgent', newAgent)
					set({ loading: true })
					try {
						const res = await axios({
							url: `${BASE_URL}/agents/user?PartpassId=${newAgent.partpassId}`,
							method: 'PATCH',
							headers: {
								Authorization: `Bearer ${get().token?.access_token}`
							},
							data: newAgent
						})
						const result = [...get().agentsUsers].map(item => {
							if (item.partpassId === newAgent.partpassId) {
								return res.data
							} else return item
						})
						set({ agentsUsers: result })
						localStorage.setItem(
							'agentsUsers',
							JSON.stringify(get().agentsUsers)
						)
						set({ loading: false })
					} catch (e) {
						console.log(e?.response?.data, 'e')
						set({ loading: false })
					}
				}
			},
			setAgent: agent => {
				const newAgent = {
					...agent,
					firstName: agent?.fullName?.split(' ')[1],
					lastName: agent?.fullName?.split(' ')[0],
					patronymic: agent?.fullName?.split(' ')[2],
					isMale: agent?.isMale ? 'Мужской' : 'Женский',
					isAdministrator: agent?.isAdministrator ? 'Полный' : 'Частичный'
				}
				set({ currentAgent: newAgent })
			},
			changeFullName: name => {
				set({ currentAgent: { ...get().currentAgent, fullName: name } })
			},
			changeFirstName: name => {
				set({ currentAgent: { ...get().currentAgent, firstName: name } })
			},
			changeLastName: name => {
				set({ currentAgent: { ...get().currentAgent, lastName: name } })
			},
			changePatronymic: name => {
				set({ currentAgent: { ...get().currentAgent, patronymic: name } })
			},
			changeGender: name => {
				set({
					currentAgent: {
						...get().currentAgent,
						isMale: name
					}
				})
			},
			changeTownName: value => {
				set({ currentAgent: { ...get().currentAgent, townName: value } })
			},
			changeOnlineAccess: value => {
				set({ currentAgent: { ...get().currentAgent, isOnlineAccess: value } })
			},
			changeBirthday: day => {
				set({ currentAgent: { ...get().currentAgent, birthday: day } })
			},
			changePosition: job => {
				set({ currentAgent: { ...get().currentAgent, position: job } })
			},
			changeEmail: value => {
				set({ currentAgent: { ...get().currentAgent, email: value } })
			},
			changeFax: value => {
				set({ currentAgent: { ...get().currentAgent, fax: value } })
			},
			isAgentAdmin: value => {
				set({ currentAgent: { ...get().currentAgent, isAdministrator: value } })
			},
			changePhoneNumber: number => {
				set({ currentAgent: { ...get().currentAgent, number: number } })
			},
			changePhonePrefix: prefix => {
				set({ currentAgent: { ...get().currentAgent, prefix: prefix } })
			},
			getBanks: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/Banks`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ banks: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getDirectorPositions: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/DirectorPositions`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ directorPositions: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getActivities: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/Activities`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ activities: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},

			getOwnerships: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Catalogs/ownerships`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ ownerships: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},

			getUserInfo: async req => {
				try {
					const res = await axios({
						url: `${API_AUTH_USERINFO}`,
						method: 'GET',
						withCredentials: true,
						headers: {
							Authorization: `Bearer ${req.access_token}`
						}
					})
					if (['Agent', 'NewPartner'].includes(res.data.role)) {
						set({ agent: res.data })
						localStorage.setItem('partPass', JSON.stringify(res.data.partPass))
					} else {
						set({ error: 'Авторизация только для агентов' })
					}
				} catch (e) {
					console.log(e?.response?.data, 'e')
				}
			},
			forgotPassword: async login => {
				const head = await axios({
					url: `https://auth.fstravel.com`,
					method: 'HEAD',
					withCredentials: true
				})
				console.log(head)
				try {
					await axios({
						url: `${FORGOT_URL}`,
						method: 'POST',
						withCredentials: true,
						data: {
							login: login,
							postResetUrl: '/',
							clientId: 'b2b.public.client'
						},
						headers: {
							RequestVerificationToken: head.config.headers['X-XSRF-TOKEN']
						}
					})
					set({ forgotError: '' })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					set({
						forgotError: e?.response?.data?.message
							? e?.response?.data?.message
							: `Указаннный пользователь ${login} не найден`
					})
				}
			},
			setForgotError: () => {
				set({ forgotError: '' })
			},
			changeLatinName: () => {
				const officialName = get().agent?.agents.officialName
				const newAgent = { ...get().agent?.agents, latinName: officialName }
				set({ agent: { ...get().agent, agents: newAgent } })
			},
			changeAgentOfficialName: (value, checked) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, officialName: value }
					}
				})
				if (checked) {
					set({
						agent: {
							...get().agent,
							agents: { ...get().agent?.agents, latinName: value }
						}
					})
				}
			},
			changeAgentOKPO: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, okpo: value }
					}
				})
			},
			changeAgentKPP: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, kpp: value }
					}
				})
			},
			changeAgentRegistrationDate: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, registrationDate: value }
					}
				})
			},
			changeAgentBoss: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, boss: value }
					}
				})
			},
			changeAgentINN: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, inn: value }
					}
				})
			},
			changeAgentPAdress: (value, checked) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, pAddress: value }
					}
				})
				if (checked) {
					set({
						agent: {
							...get().agent,
							// agents: { ...get().agent?.agents, address: value }
							agents: {
								...get().agent?.agents,
								address: get().agent?.agents.addressCode
									? get().agent?.agents.addressCode + ', ' + value
									: value
							}
						}
					})
				}
			},
			changeAgentFAddress: (value, checked) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, fAddress: value }
					}
				})
				if (checked) {
					set({
						agent: {
							...get().agent,
							agents: {
								...get().agent?.agents,
								address: get().agent?.agents.addressCode
									? get().agent?.agents.addressCode + ', ' + value
									: value
							}
						}
					})
				}
			},
			changeAgentRs: rs => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							rs: rs
						}
					}
				})
			},
			changeAgentBank: bank => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							bank: bank
						}
					}
				})
			},
			changeAgentWorker: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							worker: value
						}
					}
				})
			},
			setError: error => {
				set(() => ({ error: error }))
			},
			changeAgentAdressCode: value => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, addressCode: value }
					}
				})
			},
			changeAgentDirectorPosition: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							directorPosition: value
						}
					}
				})
			},
			changeAgentOwnership: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							ownership: value
						}
					}
				})
			},
			changeAgentActivity: value => {
				set({
					agent: {
						...get().agent,
						agents: {
							...get().agent?.agents,
							activity: value
						}
					}
				})
			},
			refreshToken: async () => {
				const token = localStorage.getItem('token')
				if (token) {
					const data = new FormData()
					data.append('refresh_token', get().token.refresh_token)
					data.append('client_id', 'b2b.public.client')
					data.append('grant_type', 'refresh_token')
					try {
						const res = await axios({
							url: `${API_REFRESH_TOKEN}`,
							method: 'POST',
							data
						})
						const newToken = res.data
						set({ token: newToken })
						localStorage.setItem('token', JSON.stringify(newToken))
						return res.data
					} catch (e) {
						console.log(e?.response?.data, 'e')
					}
				} else {
					get().actions.logout()
				}
			},
			changeContactInfo: (key, value) => {
				set({
					agent: {
						...get().agent,
						agents: { ...get().agent?.agents, [key]: value }
					}
				})
			},
			getSamoLink: async url => {
				const token = getCookie('access_token')
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/link?partpassId=${get().agent.partPass}`,
						method: 'POST',
						data: {
							siteCode: 0,
							url: url
						},
						headers: {
							Authorization: `Bearer ${get().token?.access_token || token}`
						}
					})
					set({ samoLink: res.data.url })
					return res.data.url
				} catch (e) {
					console.log(e?.response?.data, 'e')
					if (e.response.status === 401) {
						get().actions.logout()
					}
					console.log(e?.response?.data, 'e')
				}
			},
			refreshSamoLink: async (id, url) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/agents/link?partpassId=${get().agent?.partPass}`,
						method: 'POST',
						data: {
							siteCode: 0,
							url: url
						},
						headers: {
							Authorization: `Bearer ${get().token?.access_token}`
						}
					})
					set({ samoLinks: { ...get().samoLinks, [id]: res.data.url } })
				} catch (e) {
					console.log(e?.response?.data, 'e')
					if (e.response?.status === 401) {
						get().actions.logout()
					}
					console.log(e?.response?.data, 'e')
				}
			},
			externalSignin: async token => {
				await get().actions.getUserInfo({ access_token: token })
				await get().actions.getAgents({ access_token: token })
				await get().actions.getAgentsUsers({ access_token: token })
			}
		}
	}))
)

export default useAuthStore
