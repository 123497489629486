
import MembersBlock from './components/MembersBlock'
import BabyBlock from './components/BabyBlock'
import { isEmpty } from 'lodash';


function PaxesBlock({ peoples, freeInfant, freeInfantChecked }) {
    const baby = peoples.find((people) => people.age === 3);
    return (
        <>
            {!isEmpty(peoples) && peoples.map((member) => {
                if (member.key === baby?.key) return null
                return (
                    <MembersBlock peopleKey={member.key} key={member.key} membersCount={peoples.length}  />
                )
            })}
            {freeInfant &&
                <BabyBlock
                    babyId={baby?.key}
                    freeInfantChecked={freeInfantChecked}
                />
            }
            {baby && !freeInfant && <MembersBlock peopleKey={baby.key} title={'Младенец'}/>}
        </>
    )
}

export default PaxesBlock