import useHotelDescriptionStore from '../../../store'

const BASE_IMAGE_URL = `${process.env.REACT_APP_FS_IMAGE_URL}geocontent/static/HotelDescriptionBlockType/`

function HotelIncludesCopy() {
	const blocks = useHotelDescriptionStore(({ blocks }) => blocks)
	const distance = useHotelDescriptionStore(({ distance }) => distance)
	const comfort = useHotelDescriptionStore(({ comfort }) => comfort)
	const locationHotel = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel
	)
	const beach = useHotelDescriptionStore(({ beach }) => beach)
	const waterEntertainment = useHotelDescriptionStore(
		({ waterEntertainment }) => waterEntertainment
	)
	const sport = useHotelDescriptionStore(({ sport }) => sport)
	const spa = useHotelDescriptionStore(({ spa }) => spa)
	const territory = useHotelDescriptionStore(({ territory }) => territory)
	const forChildren = useHotelDescriptionStore(({ forChildren }) => forChildren)
	const hotelTerritory = useHotelDescriptionStore(
		({ hotelTerritory }) => hotelTerritory
	)

	const IncludesArr = [
		comfort,
		distance,
		locationHotel,
		beach,
		waterEntertainment,
		sport,
		spa,
		territory,
		forChildren,
		hotelTerritory
	]

	return (
		<div className='hotel_includes-block'>
			{IncludesArr.map(item => {
				if (!item?.filters?.length) return null
				const existBlock = blocks?.find(
					block => block?.id === item?.descriptionBlockTypeId
				)

				if (!existBlock) return null
				const existFilter = existBlock?.filters?.find(
					filter => filter.id === item?.filters[0]
				)

				if (!existFilter) return null

				return (
					<div
						key={item.descriptionBlockTypeId}
						className='hotel_includes-item'>
						<div>
							<img
								crossOrigin='anonymous'
								className='hotel_includes-icon'
								src={`${BASE_IMAGE_URL}${
									existBlock?.id
								}/HotelDescriptionBlockTypeFilter/${
									existFilter?.iconFileName
								}?${new Date()}`}
								alt={existFilter?.name}
							/>
						</div>
						<div className='hotel_includes-text'>{existFilter?.name}</div>
					</div>
				)
			})}
		</div>
	)
}

export default HotelIncludesCopy
