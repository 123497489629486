import React, { useState } from 'react'
import './style.css'

import { ReactComponent as ArrowSvg } from '../../../../asset/images/arrow_down_small.svg'

function ArrowIcon({ isRotate }) {
	return (
		<div
			className={
				isRotate ? 'v-spoiler-arrow v-spoiler-rotate' : 'v-spoiler-arrow'
			}>
			<ArrowSvg className='arrow' />
		</div>
	)
}

export default function StaticSpoiler({ content, id }) {
	const [isRotate, setIsRotate] = useState(false)
	const toggleHandler = () => {
		setIsRotate(prev => !prev)
	}

	return (
		<div className='v-spoiler-container' id={id}>
			<>
				<div className='v-spoiler-box' onClick={toggleHandler}>
					<div className='v-bold'>{content?.spoiler?.title}</div>
					<ArrowIcon isRotate={isRotate} />
				</div>
				{isRotate && (
					<div
						dangerouslySetInnerHTML={{
							__html: content?.spoiler?.content
						}}></div>
				)}
			</>
		</div>
	)
}
