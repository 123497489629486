import React from 'react';
import {format} from "date-fns";

import './style.css'
import {Box} from "@mui/material";

const HistoryPoints = ({history}) => {
	return (
		<Box className={'container'} mb={5}>
			<h2>История начисления и списания баллов</h2>
			{history.map(item =>
				item.customerBalanceChanges.map(block => {
					const removePoints = block.changeAmount < 0
					const plus = block.changeAmount > 0
					let description
					if (new Date(block.availableFromDateTimeUtc) > new Date()) {
						description = 'Станут активны ' + format(new Date(block.availableFromDateTimeUtc), 'dd.MM.yyyy HH:mm:ss')
					} else if (new Date(block.availableFromDateTimeUtc) < new Date() && block.expirationDateTimeUtc === '0001-01-01T00:00:00') {
						description = 'Активны бессрочно'
					} else if (new Date(block.availableFromDateTimeUtc) <= new Date()  ) {
						description = 'Активны до ' + format(new Date(block.expirationDateTimeUtc), 'dd.MM.yyyy HH:mm:ss')
					}  
					return (
						<div className={'history-block'} key={item.ids.mindboxId + block.changeAmount}>
							<div>
								<Box mb={1}>
									{format(new Date(item.dateTimeUtc), 'dd.MM.yyyy')}
								</Box>
								<div className={'comment'} style={{marginBottom: 5}}>{item.order?.customFields?.tourStateName} </div>
								{item.order?.ids?.claim && <div className={'comment'}>Заказ №{item.order?.ids?.claim} </div>}
								<div className={'comment'}>{block.comment}</div>
							</div>
							<div>
								<div className={`points ${removePoints ? 'remove' : ''}`}>
									{plus ? '+' : ''}{block.changeAmount}
								</div>
								{plus && <div  className={`points-description`}>
									{description}
								</div>}
							</div>
						</div>

					)
				})

			)}
		</Box>
	);
};

export default HistoryPoints;