import React from "react"
import styled from "styled-components"
import { Text,RoundIcon } from "../../ui"
import formatPrice from "../../../functions/formatPrice"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteAddictionalService,
  deleteInsurances,
  cancelNotification
} from "../../../store/modules/orders"
import {ReactComponent as DeleteServiceIcon} from "../../../asset/images/avia/insurances/deleteServiceIcon.svg"
import IconButton from '@mui/material/IconButton'

const Item = styled.div`
  padding: 20px 14px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 238px;
  box-sizing: border-box;
  position: relative;
`

const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ItemTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const BaseText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`

const ItemDescription = styled(BaseText)`
  display: block;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Information = styled.div`
  margin-bottom: 52px;

  & > span {
    margin-bottom: 5px;
    display: block;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 100;
`

const ServicesList = styled.div`
  margin-left: -7px;
  
  & > div {
    margin-bottom: 6px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SubRow = styled.div`
  display: flex;
  align-items: center;
`

export function getServiceNameByType(type) {
  switch (type) {
    case "SMS_Service":
      return "SMS-информирование"
    case "Return_Guarantee":
      return "Гарантия возврата билета"
    case "Payment_Receipt":
      return "Справка о стоимости заявки"
    default:
      return type
  }
}

export default function Calculations({
  onPay,
  additionalServivesInfo,
  insuranceInfo,
  supportTicketCalculation
}) {
  const allAdditions = React.useCallback(() => {
    return (additionalServivesInfo?.additionalServices
      ? additionalServivesInfo?.additionalServices
      : []
    )
      ?.concat(insuranceInfo?.insurances ? insuranceInfo?.insurances : [])
      ?.concat(supportTicketCalculation ? supportTicketCalculation : [])
      .filter(Boolean)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServivesInfo, insuranceInfo])

  const additionalLoading = useSelector(
    s => s.orders.orderDetail.additionsLoading
  )

  const dispatch = useDispatch()

  return (
    <Item>
      {!additionalLoading ? (
        <>
          <ItemHead>
            <ItemTitle>Доплата по заявке</ItemTitle>
          </ItemHead>
          <ItemDescription>
            Необходимо внести доплату по заявке.
          </ItemDescription>
          <Information>
            <BaseText>
              Сумма доплаты:{" "}
              <strong>
                {allAdditions()?.reduce(
                  (acc, x) =>
                    acc +
                    (x.price ? x.price : x.supportTicketCalculation.total),
                  0
                )}{" "}
                ₽
              </strong>{" "}
            </BaseText>
            <ServicesList>
              {(allAdditions() || []).map(x => (
                <Row style={{paddingLeft: !x.type && !x.productId ? 7 : undefined}}>
                  <SubRow>
                    {(x.type || x.productId) && (
                      <IconButton 
                        onClick={() => {
                          if(x.type) {
                            dispatch(deleteAddictionalService([x.type]))
                          } else if(x.productId) {
                            dispatch(deleteInsurances([x.productId]))
                          } else {
                            dispatch(
                              cancelNotification(
                                x.supportTicketCalculation.supportTicketId
                              )
                            )
                          }
                        }}
                        sx={{padding: '7px'}}
                      >
                        <DeleteServiceIcon/>
                      </IconButton>
                    )}{" "}
                    <BaseText >
                      {x.type
                        ? getServiceNameByType(x.type)
                        : x?.name
                        ? x?.name
                        : x.supportTicketCalculation.type.description +
                          " по обращению № " +
                          x.number}
                    </BaseText>
                  </SubRow>
                  <BaseText>
                    {formatPrice(
                      x.price ? x.price : x.supportTicketCalculation.total
                    )}
                  </BaseText>
                </Row>
              ))}
            </ServicesList>
          </Information>
        </>
      ) : (
        <LoaderWrapper>
          <RoundIcon />
        </LoaderWrapper>
      )}

      {/* <ButtonBlock>
        <PrimaryButton
          onClick={() => {
            onPay({
              s: null,
              a: additionalServivesInfo && additionalServivesInfo,
              c: insuranceInfo && insuranceInfo,
            });
          }}
        >
          Внести доплату по расчету
        </PrimaryButton>
      </ButtonBlock> */}
    </Item>
  )
}
