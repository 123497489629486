import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'

const ForwardFlightSelect = ({ options, title, onChange, value }) => {


	return (
		<FormControl variant="outlined" style={{ width: '100%' }}>
			<InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				label={title}
				className="custom-select"
			>
				{options.map((item) =>
					<MenuItem
						key={item.key}
						value={item.key}
						className="custom-select-item"
					>
						[Эконом] (Места: есть) {
							item.name
						} ({
							item.departure.state
						} {
							item.departure.portAlias
						} {
							item.departure.time
						} - {
							item.arrival.state
						} {
							item.arrival.portAlias
						} {
							item.arrival.time
						})
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default ForwardFlightSelect;