import React, { useEffect, useState } from 'react';
import TopHotelReviews from "./TopHotelReviews";
import FSReviews from "./FSReviews";
import useHotelDescriptionStore from "../store";

import './style.css'

const Reviews = () => {

	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)

	const [tab, setTab] = useState('fs')

	// useEffect(() => {
	// 	if (!hotel.topHotels) {
	// 		setTab('fs')
	// 		setTimeout(() => {
	// 			const fsEmpty = document.querySelector('.sp-blankslate-note')

	// 			if (fsEmpty) {
	// 				fsEmpty.innerHTML = 'Отзывов пока нет.'
	// 			}
	// 		}, 200)

	// 	} else {
	// 		setTab('tophotels')
	// 	}
	// }, [hotel])

	useEffect(() => {
		// if (hotel.topHotels) {
		// 	let topHotelsScript = document.createElement("script");
		// 	let url =
		// 		"https://partner.tophotels.ru/rates/ver8/" +
		// 		hotel.topHotels?.id +
		// 		"?uid=b6f8ab20102b45f8676dffade4ab255f&sec=1";
		// 	topHotelsScript.setAttribute("src", url);
		// 	document.head.appendChild(topHotelsScript);
		// }

		if (hotel.ebgNodeId) {
			const storeId = '6336a630c8cbde0018b1446e';

			// const params = getParamsByUrl();
			const departureCityId = '274286';
			const hotelId = departureCityId + hotel.ebgNodeId.toString();
			window._shoppilot = window._shoppilot || [];

			// Стили виджетов
			window._shoppilot.push(['_addStyles', 'widgets']);
			// Идентификатор товара в карточке товара
			window._shoppilot.push(['_setProductId', hotelId]);

			// Команды для отображения виджетов
			window._shoppilot.push(['_addProductWidget', 'product-reviews', '#sp-reviews-container', '#sp-inline-rating-container']);

			// Загрузка
			const themeId = 'default';
			const aplautScript = document.createElement('script');
			aplautScript.type = 'text/javascript';
			aplautScript.async = true;
			aplautScript.src = '//get.aplaut.io/f/v1/' + storeId + '/' + themeId + '/app.js';
			const firstScript = document.getElementsByTagName('script')[0];
			firstScript.parentNode.insertBefore(aplautScript, firstScript);
		}
	}, [hotel])

	const fsEmpty = document.querySelector('.sp-blankslate-note')

	if (fsEmpty) {
		fsEmpty.innerHTML = 'Отзывов пока нет.'
	}

	return (
		<div id={'reviews'} className={'container reviews'}>
			<h2>Отзывы об отеле {hotel.localizedName} {hotel.hotelCategory?.name}</h2>
			<div className={'reviews-tabs'}>
				<div className={`reviews-tabs__tab ${tab === 'fs' ? 'active' : ''}`} onClick={() => setTab('fs')}>FUN&SUN</div>
				{/* {!!hotel.topHotels?.ratesCount && <div className={`reviews-tabs__tab ${tab === 'tophotels' ? 'active' : ''}`}
					onClick={() => setTab('tophotels')}>TopHotels</div>} */}
			</div>
			<FSReviews isShow={tab === 'fs'} />
			<TopHotelReviews isShow={tab === 'tophotels'} />
		</div>
	);
};

export default Reviews;