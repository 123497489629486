import * as React from 'react'
import styled from 'styled-components'

import Button from '@mui/material/Button'
import { styled as styledMUI } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import {
	Alert,
	Box,
	Collapse,
	Grid,
	MenuItem,
	TextField,
	Tooltip,
	tooltipClasses
} from '@mui/material'
import { Close, ExpandMore } from '@material-ui/icons'
import { MuiTextField } from '../../../AgentInfo/components/MuiTextField/MuiTextField'

import {
	birthCertNumberRegExp,
	cyrillicRegExp,
	emailRegExp,
	interPassportNumberRegExp,
	interPassportSeriesRegExp,
	latinAndNumberRegExp,
	latinNameRegExp,
	latinUpperAndNumberRegExp,
	phoneRegExp,
	rfPassportNumberRegExp,
	rfPassportSeriesRegExp
} from '../../../../../constants/reg-exps'
import useClaimsStore from '../../store'
import BirthDateInput from './BirthDate'
import { nationality } from '../../../../../components/Services/PaxesBlock/nationalities'

const CustomTooltip = styledMUI(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		padding: '8px 16px 8px 16px',
		color: '#2E2E32',
		boxShadow: theme.shadows[1],
		textAlign: 'center',
		fontSize: 12,
		fontFamily: 'Open Sans',
		lineHeight: '18px'
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.white,
		'&:before': {
			boxShadow: theme.shadows[1]
		}
	}
}))

const PopupTitle = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const FormInput = styledMUI(TextField)(() => ({
	'& .MuiSvgIcon-root': {
		right: 16
	},
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.75)'
	},
	'& label': {
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		color: '#7E8389'
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	}
}))

const SaveButton = styledMUI(Button)({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	width: '152px',
	padding: '16px 32px 16px 32px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const BootstrapDialog = styledMUI(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '770px',
			maxHeight: '900px'
		}
	},
	'& .MuiDialogContent-root': {
		padding: '0 24px 32px 24px'
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(4),
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 0,
		paddingBottom: 24
	}
}))

const Title = styled.div`
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	padding: 32px 0 24px 0;
`

export default function EditTouristPopup({ openPopup, handleClose, id }) {
	const actions = useClaimsStore(({ actions }) => actions)
	const currentTourist = useClaimsStore(({ currentTourist }) => currentTourist)
	const loading = useClaimsStore(({ loading }) => loading)

	console.log(currentTourist)

	const [errors, setErrors] = React.useState({
		edit_name: null,
		phones: null,
		email: null,
		passportSerie: null,
		passportNumber: null,
		passportValidDate: null
	})
	const [fioError, setFioError] = React.useState(null)
	const changeExpirationDate = prop => day => {
		actions.setTourist({ ...currentTourist, [prop]: day })
	}
	const checkEmptyFields = () => {
		const values = [
			'email',
			'phones',
			'passportSerie',
			'passportNumber',
			'passportValidDate'
		]

		values.forEach(item => {
			if (!currentTourist[item]) {
				setErrors(prev => ({ ...prev, [item]: 'Обязательно для заполнения' }))
			}
		})
		const { email, phones, passportSerie, passportNumber, passportValidDate } =
			currentTourist

		return (
			[email, phones, passportSerie, passportNumber, passportValidDate].some(
				item => !item
			) || Object.values(errors).some(item => item)
		)
	}
	const changeHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		switch (prop) {
			case 'edit_name':
				if (latinNameRegExp.test(currentTourist?.name)) {
					if (!latinNameRegExp.test(event.target.value)) {
						setErrors({
							...errors,
							[prop]: `ФИО должно содержать только латиницу`
						})
					} else {
						setErrors({ ...errors, [prop]: null })
					}
				} else {
					if (
						!cyrillicRegExp.test(event.target.value) &&
						event.target.value !== ''
					) {
						setErrors({
							...errors,
							[prop]: `ФИО должно содержать только кириллицу`
						})
					} else {
						setErrors({ ...errors, [prop]: null })
					}
				}
				break
			case 'phones':
				setErrors({ ...errors, [prop]: null })
				if (
					!phoneRegExp.test(event.target.value) ||
					event.target.value.includes('+')
				) {
					setErrors({
						...errors,
						[prop]: `Номер телефона указан некорректно`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'email':
				setErrors({ ...errors, [prop]: null })
				if (!emailRegExp.test(event.target.value)) {
					setErrors({
						...errors,
						[prop]: `Email указан некорректно`
					})
				} else {
					setErrors({ ...errors, [prop]: null })
				}
				break
			case 'passportSerie':
				setErrors({ ...errors, [prop]: null })
				const value = event.target.value
				switch (currentTourist.documentType) {
					case 3:
						if (!interPassportSeriesRegExp.test(value)) {
							setErrors({
								...errors,
								[prop]: `Серия должна содержать 2 цифры`
							})
						}
						break
					case 1:
						if (!rfPassportSeriesRegExp.test(value) && value !== '') {
							setErrors({
								...errors,
								[prop]: `Серия должна содержать 4 цифры`
							})
						}
						break
					case 2:
						if (!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(value) && value !== '') {
							setErrors({
								...errors,
								[prop]: `Серия должна содержать римское число и две кириллические буквы`
							})
						}
						break
					case 4:
						if (value.length <= 5) {
							setErrors({
								...errors,
								[prop]: `Серия не менее 5 знаков`
							})
						}
						break
					case 6:
						if (!interPassportSeriesRegExp.test(value)) {
							setErrors({
								...errors,
								[prop]: `Серия должна содержать 2 цифры`
							})
						}
						break
					case 7:
						if (value.length <= 5) {
							setErrors({
								...errors,
								[prop]: `Серия не менее 5 знаков`
							})
						}
						break
					default:
						setErrors({ ...errors, [prop]: null })
						break
				}
				break
			case 'passportNumber':
				const values = event.target.value

				setErrors({ ...errors, [prop]: null })
				switch (currentTourist.documentType) {
					case 3:
						if (!interPassportNumberRegExp.test(values)) {
							setErrors({
								...errors,
								[prop]: `Номер должен быть 7 цифр`
							})
						}
						break
					case 1:
						if (!rfPassportNumberRegExp.test(values) && values !== '') {
							setErrors({
								...errors,
								[prop]: `Номер должен быть 6 цифр`
							})
						}
						break
					case 2:
						if (!birthCertNumberRegExp.test(values)) {
							setErrors({
								...errors,
								[prop]: `Номер должен быть 6 цифр`
							})
						}
						break
					case 4:
						if (!latinUpperAndNumberRegExp.test(values)) {
							setErrors({
								...errors,
								[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
							})
						}
						break

					case 6:
						if (!latinAndNumberRegExp.test(values)) {
							setErrors({
								...errors,
								[prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
							})
						}
						break
					case 7:
						if (!latinUpperAndNumberRegExp.test(values)) {
							setErrors({
								...errors,
								[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
							})
						}
						break
					default:
						setErrors({ ...errors, [prop]: null })
						break
				}
				break
			default:
				break
		}
		actions.setTourist({ ...currentTourist, [prop]: event.target.value })
	}
	const checkFIO = () => {
		if (
			currentTourist?.name
				.split('')
				.filter((symbol, i) => symbol !== currentTourist?.edit_name[i]).length >
				1 ||
			currentTourist?.edit_name.length !== currentTourist?.name.length ||
			currentTourist?.edit_name[0] !== currentTourist?.name[0] ||
			currentTourist?.edit_name.split(' ')[1][0] !==
				currentTourist?.name.split(' ')[1][0]
		) {
			setFioError(true)
			actions.setTourist({ ...currentTourist, edit_name: currentTourist.name })
		} else {
			setFioError(false)
			actions.setTourist({
				...currentTourist,
				edit_name: currentTourist.edit_name.toUpperCase()
			})
		}
	}

	const editHandler = () => {
		if (checkEmptyFields() || fioError) return

		actions.editTourist(id)
		handleClose()
	}

	if (!currentTourist) return null
	return (
		<div>
			<BootstrapDialog
				PaperProps={{
					style: { borderRadius: 16 }
				}}
				fullWidth
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={openPopup}>
				<DialogTitle
					sx={{
						m: 0,
						p: 3,
						pb: 0
					}}
					id='customized-dialog-title'>
					<PopupTitle>
						<Box>Редактирование данных туриста</Box>
						<IconButton aria-label='close' size='small' onClick={handleClose}>
							<Close />
						</IconButton>
					</PopupTitle>
				</DialogTitle>

				<DialogContent>
					<Title>Персональная информация</Title>
					<Grid container rowSpacing={3} columnSpacing={3}>
						<Grid item xs={6}>
							<FormInput
								SelectProps={{ IconComponent: ExpandMore }}
								select
								fullWidth
								disabled
								value={currentTourist?.isMale ? 'Мужской' : 'Женский'}
								variant='outlined'
								label='Пол'>
								<MenuItem value={'Мужской'}>Мужской</MenuItem>
								<MenuItem value={'Женский'}>Женский</MenuItem>
							</FormInput>
						</Grid>
						<Grid item xs={6}>
							<BirthDateInput
								fullWidth
								label='Дата рождения'
								value={new Date(currentTourist?.bornDate)}
								disable={true}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormInput
								value={currentTourist?.edit_name || ''}
								required
								fullWidth
								variant='outlined'
								id='outlined-required'
								label='ФИО'
								name='FIO'
								onBlur={checkFIO}
								onChange={changeHandler('edit_name')}
								helperText={errors.edit_name}
								error={!!errors.edit_name}
							/>
						</Grid>

						<Grid item xs={6}>
							<CustomTooltip
								title='"+" не указывать, пример 79031111111'
								placement='top'>
								<FormInput
									value={currentTourist?.phones || ''}
									required
									fullWidth
									variant='outlined'
									label='Телефон'
									name='number'
									onChange={changeHandler('phones')}
									helperText={errors.phones}
									error={!!errors.phones}
								/>
							</CustomTooltip>
						</Grid>
						<Grid item xs={6}>
							<FormInput
								value={currentTourist?.email || ''}
								required
								fullWidth
								variant='outlined'
								id='outlined-required'
								label='Email'
								name='email'
								onChange={changeHandler('email')}
								helperText={errors.email}
								error={!!errors.email}
							/>
						</Grid>
					</Grid>
					<Title>Документ</Title>
					<Grid container rowSpacing={3} columnSpacing={3}>
						<Grid item xs={6}>
							<MuiTextField
								label='Гражданство'
								value={nationality.find(el => el.id === currentTourist?.stateID)?.name }
								variant='filled'
								fullWidth
								disabled
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiTextField
								SelectProps={{
									IconComponent: ExpandMore,
									MenuProps: {
										PaperProps: {
											className: 'myCustomList-actions',
											sx: {
												'& .MuiMenuItem-root': {
													fontFamily: 'Open Sans'
												}
											}
										},
										style: {
											maxHeight: '288px',
											top: 1
										}
									}
								}}
								select
								label='Тип документа'
								value={currentTourist.documentType}
								onChange={changeHandler('documentType')}
								variant='filled'
								fullWidth>
								{documentTypes.map(item => (
									<MenuItem key={item.id} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</MuiTextField>
						</Grid>
						<Grid item xs={6}>
							<FormInput
								value={currentTourist?.passportSerie || ''}
								required
								fullWidth
								variant='outlined'
								id='outlined-required'
								label='Серия'
								name='Serie'
								onChange={changeHandler('passportSerie')}
								helperText={errors.passportSerie}
								error={!!errors.passportSerie}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormInput
								value={currentTourist?.passportNumber || ''}
								required
								fullWidth
								variant='outlined'
								id='outlined-required'
								label='Номер'
								name='passportNumber'
								onChange={changeHandler('passportNumber')}
								helperText={errors.passportNumber}
								error={!!errors.passportNumber}
							/>
						</Grid>
						<Grid item xs={6}>
							<BirthDateInput
								fullWidth
								id='calendar-refs'
								label='Действителен до'
								onChange={changeExpirationDate('passportValidDate')}
								value={new Date(currentTourist?.passportValidDate) || null}
								disable={false}
								isExpiration={true}
								required
								error={errors.passportValidDate}
								setError={setErrors}
							/>
						</Grid>
					</Grid>
					<Collapse in={fioError}>
						<Grid container sx={{ mt: 3 }}>
							<Alert
								severity='error'
								action={
									<IconButton
										aria-label='close'
										color='inherit'
										size='small'
										onClick={() => {
											setFioError(false)
										}}>
										<Close fontSize='inherit' />
									</IconButton>
								}>
								В ФИО туриста {currentTourist.name} разрешено изменить не более
								1 символов. Обратитесь к туроператору.
							</Alert>
						</Grid>
					</Collapse>
				</DialogContent>
				<DialogActions sx={{ pb: 3 }}>
					<SaveButton fullWidth onClick={editHandler} disabled={loading}>
						Сохранить
					</SaveButton>
				</DialogActions>
			</BootstrapDialog>
		</div>
	)
}

const documentTypes = [
	{ id: 0, name: 'Иной документ' },
	{ id: 1, name: 'Паспорт' },
	{ id: 2, name: 'Свидетельство о рождении' },
	{ id: 3, name: 'Заграничный паспорт' }
]
