import React from 'react';
import { useNavigate } from "react-router-dom";
import useExcursionBookingStore from '../../store';


const BookingSuccessPopup = ({ claim }) => {

    const actions = useExcursionBookingStore(({ actions }) => actions)
    const excursion = useExcursionBookingStore(({ excursion }) => excursion)
    const navigate = useNavigate()

    const backToSearch = () => {
        actions.clearError()
        navigate('/searchexcursions')
    }

    const showOrder = () => {
        window.location.href = `https://b2b.fstravel.com/cl_refer?CLAIM=${claim}`
    }

    return (
        <div className="popup-bg">
            <div className="popup-block">
                <h2 className="popup-name">
                    Ваша заявка забронирована
                </h2>
                <p className="popup-text">Номер вашей заявки: <span style={{ fontWeight: 'bold' }}>{claim}</span></p>
                <p className="popup-text">Цена составляет: <span style={{ fontWeight: 'bold' }}>{excursion.price} {excursion?.currency?.currencyAlias}</span></p>
                <div style={{ display: 'flex' }}>
                    <button className="popup-btn" onClick={showOrder}>Посмотреть заявку</button>
                    <button className="popup-btn close" onClick={backToSearch}>Еще одна заявка</button>
                </div>
            </div>
        </div>
    );
};

export default BookingSuccessPopup;