import React from 'react';
import Stats from '../../../../asset/images/stats.png'
import Places from '../../../../asset/images/icons_place.png'

import './style.css'
import {isMobile} from "react-device-detect";

const Symbols = () => {
	return (
		<div className={'symbols-container'}>
			<div className="place" style={{ width: !isMobile ? '40%' : '100%' }}>
				<span className="icons_place" style={{backgroundImage: `url(${Places})`}} />
				<span className={'text'}> - есть места / нет мест / по запросу / мало мест</span>
			</div>
			<div className="place" style={{width: !isMobile ? '30%' : '100%'}}>
				<span className="best_price_img green_row" />
				<span className={'text'}> - Мгновенное подтверждение</span>
			</div>
			<div className="place" style={{width: !isMobile ? '30%' : '100%'}}>
				<span className="best_price_img red_row" />
				<span className={'text'}> - Остановка продаж</span>
			</div>
			<div className="place" style={{width: !isMobile ? '40%' : '100%'}}>
				<span className={`hotel_availability helpalt hotel_availability_Y`} />
				<span className={`hotel_availability helpalt hotel_availability_N`} />
				<span className={`hotel_availability helpalt hotel_availability_R`} />
				<span className={`hotel_availability helpalt hotel_availability_F`} />
				<span className={'text'}> - наличие мест в отеле: по запросу / нет / мало / есть</span>
			</div>
			<div className="place" style={{width: !isMobile ? '30%' : '100%'}}>
				<img src={Stats} alt=""/>
				<span className={'text'}> - Динамика цены, наличие мест</span>
			</div>
			<div className="place" style={{width: !isMobile ? '30%' : '100%'}}>
				<span className="best_price_img" />
				<span className={'text'}> - Лучшее предложение</span>
			</div>
		</div>
	);
};

export default Symbols;