// eslint-disable-next-line import/named
import { all, put, call, select, takeLatest } from "redux-saga/effects"
import {
  createFeeRequest,
  deleteFeeRequest,
  editFeesRequest,
  getAgentFeesInfoRequest,
  getAgentFeesInfoSuccess,
  getAgentTaxesSuccess,
  updateTaxesFailure,
  updateTaxesRequest,
  updateTaxesSuccess,
  editFeesSuccess,
  createFeeSuccess
} from "./duck"
import { getAgentSiginState } from "../agent-finance/selectors"
import * as Manager from "./manager"

function* getAgentFeesInfo() {

  const agent = yield select(getAgentSiginState)

  try {
    if (agent) {
      const [fee, tax] = yield all([
        call(Manager.getAgentFee, agent.agentId),
        call(Manager.getAgentTax, agent.agentId)
      ])

      yield put(getAgentFeesInfoSuccess(fee))
      yield put(getAgentTaxesSuccess(tax))
    }


  } catch (err) {
    
  }
}

function* updateTaxesSaga(action) {
  try {
    const agent = yield select(getAgentSiginState)

    yield call(Manager.updateTaxes, action.payload)
    if (agent) {
      const taxes = yield call(Manager.getAgentTax, agent?.agentId)
      yield put(getAgentTaxesSuccess(taxes))
      yield put(updateTaxesSuccess())
    }
  } catch (err) {
    yield put(updateTaxesFailure())
  }
}

function* addFeeSafa(action) {
  try {
    const agent = yield select(getAgentSiginState)
    const {onSuccess,...payload} = action.payload
    if (agent) {
      yield call(Manager.createFee, payload, agent.agentId)
      const fee = yield call(Manager.getAgentFee, agent?.agentId)
      yield put(getAgentFeesInfoSuccess(fee))
      yield put(createFeeSuccess(true))
      // onSuccess();
    }
  } catch (err) {
    console.log(err);
    //
  }
}

function* editFeeSafa(action) {
  try {
    const agent = yield select(getAgentSiginState)
    const {onSuccess, ...payload} = action.payload

    if (agent) {
      yield call(Manager.editFee, payload)
      const fee = yield call(Manager.getAgentFee, agent?.agentId)
      yield put(getAgentFeesInfoSuccess(fee))
      yield put(editFeesSuccess(true))
      // onSuccess();

      // yield call(NavigationService.navigate, "/profile/agent/fees")
    }
  } catch (err) {
    //
  }
}

function* deleteFeeSafa(action) {
  try {
    const agent = yield select(getAgentSiginState)

    if (agent) {
      yield call(Manager.deleteFee, action.payload)
      const fee = yield call(Manager.getAgentFee, agent?.agentId)
      yield put(getAgentFeesInfoSuccess(fee))
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getAgentFeesInfoRequest.getType(), getAgentFeesInfo),
    takeLatest(updateTaxesRequest.getType(), updateTaxesSaga),
    takeLatest(createFeeRequest.getType(), addFeeSafa),
    takeLatest(editFeesRequest.getType(), editFeeSafa),
    takeLatest(deleteFeeRequest.getType(), deleteFeeSafa)
  ])
}
