import React, {useEffect, useState} from 'react';
import './style.scss'
import { Autocomplete, Box, Popper } from "@mui/material";
import InsuranceCard from "./InsuranceCard";
import useInsuranceStore from "../../store";
import { Button, Checkbox, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {isEmpty, isNumber, orderBy, uniqBy, isEqual} from "lodash";
import {SportActivity, ProfessionalSports} from "./Tooltips";

const SearchResults = ({ items, currency }) => {

	const CustomPopper = (props) => { return (<Popper {...props} placement="bottom" sx={{ height: "10px", }} style={{ width: props.anchorEl.clientWidth, height: "5px" }} />) }


	const actions = useInsuranceStore(({ actions }) => actions)
	const data = useInsuranceStore(({ data }) => data)
	const insurancesAmounts = useInsuranceStore(({ insurancesAmounts }) => insurancesAmounts.find(i => i.currencyIsoCode === currency))
	const activities = useInsuranceStore(({ activities }) => activities)
	const selectedActivites = useInsuranceStore(({ selectedActivites }) => selectedActivites)
	const selectedSport = useInsuranceStore(({ selectedSport }) => selectedSport)
	const sport = useInsuranceStore(({ sport }) => sport)
	const anotherOptions = useInsuranceStore(({ anotherOptions }) => orderBy(uniqBy(anotherOptions, 'optionType'), 'name'))
	const anotherFilters = useInsuranceStore(({ anotherFilters }) => anotherFilters)
	const loading = useInsuranceStore(({ loading }) => loading)
	const [sports, setSports] = useState([])
	const [previouesSports, setPreviouesSports] = useState([])
	const [profSports, setProfSports] = useState([])
	const [previouesProfSports, setPreviouesProfSports] = useState([])

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const clearFilters = () => {
		actions.clearFilters()
		actions.setAmountLife(40000)
		actions.setAmountAccident(0)
		actions.setAmountBaggage(0)
		actions.setAmountLiability(0)
		actions.setAmountTripCancellation(0)
		actions.setActivites([])
		actions.setAnotherFilters([])
		const localFilter = document.querySelector('.local-filter .MuiAutocomplete-clearIndicator')
		const sportFilter = document.querySelector('.sport-filter .MuiAutocomplete-clearIndicator')
		sportFilter?.click()
		localFilter?.click()
		actions.calculate()
	}

	const handleChangeAmountLife = (value) => {
		actions.setAmountLife(value.value)
		actions.calculate()
	}

	const handleChangeAccident = (value) => {
		actions.setAmountAccident(value?.value || 0)
		actions.calculate()
	}

	const handleChangeBaggage = (value) => {
		actions.setAmountBaggage(value?.value || 0)
		actions.calculate()
	}

	const handleChangeLiability = (value) => {
		actions.setAmountLiability(value?.value || 0)
		actions.calculate()
	}

	const handleChangeTripCancelation = (value) => {
		actions.setAmountTripCancellation(value?.value || 0)
		actions.calculate()
	}

	const handleChangeActivites = (value) => {
		setSports(value.map(item => {
			return item.id
		}))
		// сброс значений, если все удалили нажатием на крестик, без события onClose
		if (value.length === 0) {
			actions.setActivites([])
			setPreviouesSports([])
		}
	}

	const handleChangeSport = (value) => {
		setProfSports(value.map(item => {
			return {id: item.id}
		}))
		if (value.length === 0) {
			actions.setProfessionalSports([])
			setPreviouesProfSports([])
		}
	}

	const handleSearchWithActivites = () => {
		if (!isEqual(sports, previouesSports)){
			actions.setActivites(sports)
			setPreviouesSports(sports)
		}
	}
	useEffect(() => {
		if (selectedActivites || selectedSport) {
			actions.calculate()
		}
	}, [selectedActivites, selectedSport])

	const handleSearchWithSport = () => {
		if (!isEqual(profSports, previouesProfSports)) {
			actions.setProfessionalSports(profSports)
			setPreviouesProfSports(profSports)
		}
	}

	const handleChangeLocalFilter = (value) => {
		actions.setAnotherFilters(value)
	}

	const getFilteredResult = (data) => {
		if (!isEmpty(anotherFilters)) {
			return data.filter(item => anotherFilters.every(filter => item.options.some(x => x.optionType === filter)))
		} else {
			return data
		}
	}

	const filteredResult = getFilteredResult(items)

	const disabledOptions = () => {
		let arr = []
		filteredResult.forEach(item => {
			arr = [...arr, ...item.options]
		})
		return orderBy(uniqBy(arr, 'optionType'), 'name')
	}

	const insurancesAmountsModel = (array) => {
		const filtered = array.map(value => {
			if (value) {
				return {
					label: `${value.toLocaleString('ru', {})} ${currency}`,
					value: value
				}
			}
			return null
		})
		return filtered.filter(item => item)
	}

	return (
		<div className={'insurance-result container'}>
			<Box mt={4} style={{ display: "flex" }}>
				<div className={'insurance-filter'}>
					<div className={'insurance-filter__title'}>Сумма покрытия <Box component={'span'} sx={{ fontSize: 14, color: '#7e8389', fontStyle: 'italic', fontWeight: 'normal' }}>(на каждого)</Box></div>
					<div>
						<Autocomplete
							disablePortal
							PopperComponent={CustomPopper}
							options={insurancesAmountsModel(insurancesAmounts.life)}
							sx={{ fontSize: 18 }}
							// inputValue={data.lifeInsuranceAmount}
							defaultValue={{ label: `${data.lifeInsuranceAmount.toLocaleString('ru', {})} ${currency}`, value: data.lifeInsuranceAmount }}
							disableClearable
							onChange={(e, value) => handleChangeAmountLife(value)}
							renderInput={(params) => <TextField variant={'outlined'} {...params} />}
							disabled={loading}
						/>
					</div>
					<Box mt={2} className={'insurance-filter__title'}>
						Дополнительная защита
					</Box>
					<Autocomplete
						disablePortal
						PopperComponent={CustomPopper}
						options={insurancesAmountsModel(insurancesAmounts.accident)}
						inputValue={data.accidentInsuranceAmount ? `${data.accidentInsuranceAmount.toLocaleString('ru', {})} ${currency}` : ''}
						defaultValue={{ label: data.accidentInsuranceAmount ? `${data.accidentInsuranceAmount.toLocaleString('ru', {})} ${currency}` : '', value: data.accidentInsuranceAmount }}
						sx={{ fontSize: 18 }}
						onChange={(e, value) => handleChangeAccident(value)}
						renderInput={(params) => <TextField variant={'outlined'} {...params} label={'Несчастный случай'} />}
						disabled={loading}
					/>
					<Box mt={2} className={'insurance-filter__title'}>
						<Autocomplete
							disablePortal
							PopperComponent={CustomPopper}
							options={insurancesAmountsModel(insurancesAmounts.baggage)}
							inputValue={data.baggageInsuranceAmount ? `${data.baggageInsuranceAmount.toLocaleString('ru', {})} ${currency}` : ''}
							defaultValue={{ label: data.baggageInsuranceAmount ? `${data.baggageInsuranceAmount.toLocaleString('ru', {})} ${currency}` : '', value: data.baggageInsuranceAmount }}
							sx={{ fontSize: 18 }}
							onChange={(e, value) => handleChangeBaggage(value)}
							renderInput={(params) => <TextField variant={'outlined'} {...params} label={'Защита багажа'} />}
							disabled={loading}
						/>
					</Box>
					<Box mt={2} className={'insurance-filter__title'}>
						<Autocomplete
							disablePortal
							PopperComponent={CustomPopper}
							options={insurancesAmountsModel(insurancesAmounts.liability)}
							inputValue={data.liabilityInsuranceAmount ? `${data.liabilityInsuranceAmount.toLocaleString('ru', {})} ${currency}` : ''}
							defaultValue={{ label: data.liabilityInsuranceAmount ? `${data.liabilityInsuranceAmount.toLocaleString('ru', {})} ${currency}` : '', value: data.liabilityInsuranceAmount }}
							sx={{ fontSize: 18 }}
							onChange={(e, value) => handleChangeLiability(value)}
							renderInput={(params) => <TextField variant={'outlined'} {...params} label={'Гражданская ответственность'} />}
							disabled={loading}
						/>
					</Box>
					<Box mt={2} className={'insurance-filter__title'}>
						Отмена поездки
					</Box>
					{/* <Box mt={2} className={'insurance-filter__title'}>
						<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
							<InputLabel htmlFor="outlined-adornment-password">Стоимость поездки (по чекам)</InputLabel>
							<OutlinedInput
								fullWidth
								onChange={(e) => handleChangeTrip(e.target.value)}
								error={error}
								value={trip}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											edge="end"
											onClick={handleChangeTripCancelation}
										>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Стоимость поездки (по чекам)"
								disabled={loading}
							/>
							{error && <FormHelperText style={{ marginLeft: 0 }} error id="accountId-error">
								Укажите сумму до 4000
							</FormHelperText>}
						</FormControl>
					</Box> */}
					<Box mt={2} className={'insurance-filter__title'}>
						<Autocomplete
							disablePortal
							PopperComponent={CustomPopper}
							options={insurancesAmountsModel(insurancesAmounts.tripCancellation)}
							inputValue={data.tripCancellationInsuranceAmount ? `${data.tripCancellationInsuranceAmount.toLocaleString('ru', {})}  ${currency}` : ''}
							defaultValue={{ label: data.tripCancellationInsuranceAmount ? `${data.tripCancellationInsuranceAmount.toLocaleString('ru', {})} ${currency}` : '', value: data.tripCancellationInsuranceAmount }}
							sx={{ fontSize: 18 }}
							onChange={(e, value) => handleChangeTripCancelation(value)}
							renderInput={(params) => <TextField variant={'outlined'} {...params} label={'Стоимость поездки (по чекам)'} />}
							disabled={loading}
						/>
					</Box>
					<Box mt={2} className={'insurance-filter__title'}>
						Спорт и активности
						<SportActivity/>
					</Box>
					<Autocomplete
						className={'sport-filter'}
						multiple
						PopperComponent={CustomPopper}
						options={orderBy(activities, 'name')}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						onChange={(e, value) => handleChangeActivites(value)}
						onClose={handleSearchWithActivites}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
									color={'primary'}
								/>
								{option.name}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} label="Спорт и активности" variant={'outlined'} />
						)}
						disabled={loading || !isEmpty(selectedSport)}
					/>
					<Box mt={2} className={'insurance-filter__title'}>
						Профессиональный спорт
						<ProfessionalSports/>
					</Box>
					<Autocomplete
						className={'sport-filter'}
						multiple
						PopperComponent={CustomPopper}
						options={orderBy(sport, 'name')}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						onChange={(e, value) => handleChangeSport(value)}
						onClose={handleSearchWithSport}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
									color={'primary'}
								/>
								{option.name}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} label="Профессиональный спорт" variant={'outlined'} />
						)}
						disabled={loading || !isEmpty(selectedActivites)}
					/>
					<Box mt={2} className={'insurance-filter__title'}>
						Другие опции
					</Box>
					<Autocomplete
						className={'local-filter'}
						multiple
						PopperComponent={CustomPopper}
						place
						options={anotherOptions}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						getOptionDisabled={(option) =>
							!disabledOptions().some(item => item.optionType === option.optionType)
						}
						onChange={(e, value) => handleChangeLocalFilter(value)}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
									color={'primary'}
								/>
								{option.name}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} label="Другие опции" variant={'outlined'} />
						)}
						disabled={loading}
					/>
					<Box mt={2}>
						<Button fullWidth color={'primary'} onClick={clearFilters}>Сбросить все фильтры</Button>
					</Box>

				</div>
				<div style={{ width: '75%' }}>
					{filteredResult.map(insurance => <InsuranceCard key={insurance.id} insurance={insurance} />)}
				</div>
			</Box>
		</div>
	);
};

export default SearchResults;