import _ from "lodash"
import quantityCaseFormat from '../../../functions/quantityCaseFormat'
import insuranceCancelSrc from "../../../asset/images/avia/insurances/insurance1.svg"
import insuranceProtectionSrc from "../../../asset/images/avia/insurances/insurance2.svg"
import insuranceCovidSrc from "../../../asset/images/avia/insurances/insurance3.svg"
import insuranceDelaySrc from "../../../asset/images/avia/insurances/insurance4.svg"
import insuranceWeatherSrc from "../../../asset/images/avia/insurances/insurance5.svg"
import insuranceDoctorSrc from "../../../asset/images/avia/insurances/insurance6.svg"
import alphastrahPath from "../../../asset/images/avia/insurances/alphastrah.png"
import insurionPath from "../../../asset/images/avia/insurances/insurion.png"
import Arrow from "../../../asset/images/avia/insurances/arrow.svg"
import CashIconPath from "../../../asset/images/avia/insurances/cash.svg";
import InsuranceCancelIconPath from "../../../asset/images/avia/insurances/insurance_case1.svg";
import InsuranceFlightIconPath from "../../../asset/images/avia/insurances/insurance_case2.svg";
import CrossIconPath from "../../../asset/images/avia/insurances/cross.svg";
import InsuranceMedicalIconPath from "../../../asset/images/avia/insurances/insurance_case3.svg";
import {v4 as uuid} from "uuid";
import {getServiceNameByType} from "../../../components/AviaOrder/Calculations/AddictionalServicesTemplate";

function normalizePassengers(args) {
  const getSegmentNameFromToById = segmentId => {
    const segment = args.segmentGroups
      .map(x => x.segments.find(y => y.id === segmentId))
      .find(x => !!x)
    if (segment) {
      return `${segment.departureCity.name} - ${segment.arrivalCity.name}`
    }
  }

  return {
    ...args,
    passengers: args.passengers.map(passenger => {
      const luggageList = args.luggages
        ? args.luggages.find(
          value => value.passengerId === passenger.passengerId
        ) || {
          passengerId: "",
          items: []
        }
        : {
          passengerId: "",
          items: []
        }
      const mealList = args.meals
        ? args.meals.find(
          value => value.passengerId === passenger.passengerId
        ) || {
          passengerId: "",
          items: []
        }
        : {
          passengerId: "",
          items: []
        }

      const seatsListTemp = (args.seatsAssignment || []).find(
        value => value.passengerId === passenger.passengerId
      )

      const seatsList = {
        ...seatsListTemp,
        items: seatsListTemp?.items?.map(x => {
          const seatNumberArr = x.name.split(" ")
          return {
            ...x,
            seatNumber: x.seatNumber || seatNumberArr[seatNumberArr.length - 1],
            segmentFromTo: getSegmentNameFromToById(x.aviaSegmentIds[0])
          }
        })
      }

      const otherAncillaryServices = args.otherAncillaryServices
        ? args.otherAncillaryServices.find(
          value => value.passengerId === passenger.passengerId
        ) || {
          passengerId: "",
          items: []
        }
        : {
          passengerId: "",
          items: []
        }

      return {
        ...passenger,
        luggage: {
          ...luggageList
        },
        seatsList,
        meals: {
          ...mealList
        },
        otherAncillaryServices: {
          ...otherAncillaryServices
        }
      }
    })
  }
}

function formatPriceDetalizationItem(args) {
  const typeMap = [
    ["взрослый", "взрослых", "взрослых"],
    ["ребёнок", "ребёнка", "детей"],
    ["младенец", "младенца", "детей"]
  ]
  let name = args.description
  const detalization = args.detalization
  // if (args.priceType === 3) {
  //   name = `${args.quantity} ${quantityCaseFormat(
  //     args.quantity || 0,
  //     ...typeMap[0]
  //   )}`
  // }
  if (args.priceType === 4) {
    name = `${args.quantity} ${quantityCaseFormat(
      args.quantity || 0,
      ...typeMap[1]
    )}`
  }
  if (args.priceType === 5) {
    name = `${args.quantity} ${quantityCaseFormat(
      args.quantity || 0,
      ...typeMap[2]
    )}`
  }
  return { quantity: 0, price: args.value, name, priceType: args.priceType, detalization }
}

function createPriceArrayFortotalDetalization(args) {
  return {
    total: args.value,
    agentTotal: args.detalization.find(x => x.priceType === 16)?.value,
    agentFee: args.detalization.find(x => x.priceType === 17)?.value,
    positionList: args.detalization
      .filter(x => x.priceType !== 16)
      // .filter(x => x.priceType !== 16 && x.priceType !== 20)
      .map(item => ({
        positionName: item.description,
        isBold: item.priceType === 17,
        total: item.value,
        priceType: item.priceType,
        detalizationList: item.detalization.map(formatPriceDetalizationItem),
      }))
  }
}

function createPriceArrayForAgentFees(args) {
  const positions = args.orderPositions[0].orderPositionAmount.detalization
    .filter(x => [2,3].includes(x.priceType))
    .map(y => ({
      title: y.description,
      price: y.detalization.filter(el => el.priceType === 8)[0].value
    }))

  const services = args.additionalServices.map(x => ({
    title: getServiceNameByType(x.type),
    price: x.subAgentExtraCharge
  }))

  return [...positions, ...services]
}

function createPriceArrayForPosition(state) {
  const priceArray = _(state.orderPositionAmount?.detalization)
    ?.map(formatPriceDetalizationItem)
    ?.value()

  return {
    ...state,
    priceDetail: {
      amount: state.orderPositionAmount?.value,
      detailList: priceArray
    }
  }
}

export const isActive = value => value.isActive

/**
 *  Filter and modify order positions
 */
export function getOrderByNumberNormalizer(args) {
  return {
    ...args,
    totalDetalization: createPriceArrayFortotalDetalization(args.orderAmount),
    orderPositions: _(args.orderPositions)
      .map(_.flow(normalizePassengers, createPriceArrayForPosition))
      .value(),
    annulatedAviaOrderPositions: _(args.annulatedAviaOrderPositions)
      .map(_.flow(normalizePassengers, createPriceArrayForPosition))
      .value(),
    agentFees: createPriceArrayForAgentFees(args),
  }
}

/**
 * Filtering order positions
 */
export function getOrdersListNormalizer(args) {
  return {
    ...args,
    orders: args.orders || []
  }
}

function getInsuranceIcon(productType) {
  switch (productType) {
    case "ANTICOVID":
      return insuranceCovidSrc
    case "Защита на время полета":
    case "Полёт под защитой":
      return insuranceProtectionSrc
    case "Отмена поездки":
      return insuranceCancelSrc
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return insuranceDelaySrc
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return insuranceWeatherSrc
    case "Врач-online в поездку":
    case "Медицинские расходы":
    case 'Online-врач в поездку':
      return insuranceDoctorSrc
    default:
      return insuranceDoctorSrc
  }
}

function getInsuranceDescription(productType) {
  switch (productType) {
    case "ANTICOVID":
      return "Нет ничего важнее Вашего здоровья. Получите выплату при заболевании в поездке."
    case "Защита на время полета":
      return "Получите выплату на карту при задержке рейса, потере багажа и других страховых случаях."
    case "Отмена поездки":
      return "Обезопасьте себя на случай отмены запланированной поездки."
    case "Врач-online в поездку":
      return "Online-врач окажет вам помощь дистанционно в любой точке мира."
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return "Моментальная выплата от 3000 ₽ на карту, в случае задержки рейса от 3ч."
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return "Моментальная выплата 5000 ₽ на карту, в случае плохой погоды во время отдыха."
    default:
      return "Моментальная выплата 5000 ₽ на карту, в случае плохой погоды во время отдыха."
  }
}

function getCompany(productType) {
  switch (productType) {
    case "Задержка рейса":
    case "Гарантия хорошей погоды":
    case "Гарантия хорошей погоды для туристов":
    case "Страховка от задержки рейса":
      return { name: "Insurion", logo: insurionPath }
    default:
      return { name: "АльфаСтрахование", logo: alphastrahPath }
  }
}

function getLabel(checked) {
  if (checked) {
    return { color: "#C9DEFF", text: "Ждет оплаты", icon: Arrow }
  }
  return null
}

function formatRisks(productType, risks) {
  switch (productType) {
    case "ANTICOVID":
      return [
        {
          id: "",

          coverage: { value: 15000, currency: "RUB" },
          description: "в случае диагностики COVID-19"
        },
        {
          id: "",
          coverage: { value: 30000, currency: "RUB" },
          description: "в случае госпитализации с COVID-19"
        }
      ]
    case "Защита на время полета":
      return risks.map(x => {
        let description = x.description

        if (x.description.includes("Потеря багажа")) {
          description = "при пропаже багажа"
        } else if (x.description.includes("Задержка рейса")) {
          description = "при задержке рейса"
        } else if (x.description.includes("Несчастный случай")) {
          description = "в несчастном случае"
        } else if (x.description.includes("Задержка багажа")) {
          description = "при задержке багажа"
        }

        return { ...x, description }
      })
    case "Отмена поездки":
      return risks.map(x => ({
        ...x,
        description:
          "в случае внезапной отмены поездки из-за отказа в визе, травмы, госпитализации и еще 13 причин"
      }))
    default:
      return risks
  }
}

function getWordBeforPrice(productType) {
  switch (productType) {
    case "Страховка от задержки рейса":
    case "Задержка рейса":
      return "от"
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return ""
    default:
      return "до"
  }
}

function getSpecificationDoc(productType) {
  switch (productType) {
    case "Страховка от задержки рейса":
    case "Задержка рейса":
    case "Гарантия хорошей погоды для туристов":
    case "Гарантия хорошей погоды":
      return "/docs/reno.pdf"
    default:
      return "http://www.alfastrah.ru/docs/rules_SP_230617.pdf"
  }
}

function getOffertaUri(productType) {
  switch (productType) {
    case "ANTICOVID":
      return "https://www.alfastrah.ru/docs/Offer_anticovid_2_avia_247.pdf"
    case "Защита на время полета":
      return "https://www.alfastrah.ru/docs/Offer_bg_zv_ns_247.pdf"
    case "Отмена поездки":
      return "http://www.alfastrah.ru/docs/Offer_nsp_247_se.pdf"
    case "Врач-online в поездку":
      return "https://www.alfastrah.ru/docs/telemed_ns_offer.pdf"
    default:
      return null
  }
}

function getCardRenderData(productType) {
  switch (productType) {
    case 'Отмена поездки':
      return {
        iconSrc: CashIconPath,
        iconBackground: '#BFE8FF',
        iconText: 'Выплата до 5 000 ₽',
        detailsTitle: '19 причин отмены поездки, в том числе:',
        detailsItems: [
          'Призыв или суд',
          'Хищение документов',
          'Госпитализация',
          'Отказ в визе',
        ],
        detailsItemsTitle: 'Компенсация до 5 000 ₽ при отмене или изменении сроков перелёта по одной из причин:',
        detailsItemsFull: [
          'Госпитализация',
          'Инфекционные заболевания',
          'Травма',
          'Уход из жизни',
          'Вакцинация',
          'Отказ в визе',
          'Ущерб имуществу',
          'Сокращение',
          'Хищение документов',
          'Авария общественного транспорта',
          'ДТП',
          'Стихийные бедствия',
          'Судебное разбирательство',
          'Следственные действия',
          'Содействие представителям органов власти',
          'Призыв',
          'Метеоусловия',
          'Респираторные инфекционные заболевания',
          'Обсервация в связи с диагностированным COVID-19',
        ],
        storyIconSrc: InsuranceCancelIconPath,
        howtoTitle: false,
        howtoInstructions: [
          'Обратитесь в Службу поддержки по заявке и оформите возврат или обмен авиабилетов на новую дату по одной из 19 причин.',
          'После возврата денег за авиабилеты <a href="https://www.alfastrah.ru/accident/insurance_of_passengers/?utm_referrer=http%3A%2F%2Ftvm-docker-01%3A28512%2F" target="_blank" style="color: #4872f2;cursor: pointer">отправьте заявление</a> в “Альфа Cтрахование”.',
          'Приложите подтверждающие и удостоверяющие документы (полис, паспорт, маршрутная квитанция, справки, полученные от Службы поддержки с указанием суммы возврата / доплаты денежных средств, документы, подтверждающие наступление страхового случая, реквизиты для получения выплаты). После получения заявления “Альфа Страхование” может запросить дополнительные документы согласно правилам страхования.',
          'Получите деньги - обычно это занимает не более 10 дней. '
        ]
      }
    case 'Полёт под защитой':
      return {
        iconSrc: CashIconPath,
        iconBackground: '#FFEDBF',
        iconText: 'Выплата до 500 000 ₽',
        detailsTitle: false,
        detailsItems: [
          'Моментальная выплата до <b style="white-space: nowrap">6 000 ₽</b> на карту если рейс задержали',
          'Выплаты при утере багажа до <b>40 000 ₽</b>',
          'Компенсация при несчастном случае до <b>500 000 ₽</b>',
        ],
        detailsItemsTitle: false,
        detailsItemsFull: [
          '<b>Моментальная выплата на карту до 6 000 ₽ если рейс задержали!</b>',
          'Выплаты при потере и утрате багажа до <b>40 000 ₽</b>',
          'Выплаты при отмене рейса до <b>4 800 ₽</b>',
          'Выплаты в случае, если самолёт перенаправили в другой аэропорт до <b>5 000 ₽</b>',
          'Выплаты при несчастном случае в аэропорту или самолёте до <b>500 000 ₽</b>',
        ],
        storyIconSrc: InsuranceFlightIconPath,
        howtoTitle: '<div>При задержке или отмене рейса вам автоматически придет смс от “Альфа Страхования”. Перейдите по ссылке из смс, проверьте свои данные, введите данные карты и получите выплату.</div><div style="margin-top: 12px"><b>Если вы не получили смс или у вас страховое событие по другим рискам:</b></div>',
        howtoInstructions: [
          '<a href="https://www.alfastrah.ru/accident/insurance_of_passengers/?utm_referrer=http%3A%2F%2Ftvm-docker-01%3A28512%2F" target="_blank" style="color: #4872f2;cursor: pointer">Отправьте заявление</a> в “Альфа Cтрахование”.',
          'Приложите подтверждающие и удостоверяющие документы (полис, паспорт, маршрутная квитанция, справки подтверждающие задержку / отмену рейса или неприятности со здоровьем, реквизиты для получения выплаты). После получения заявления “Альфа Страхование” может запросить дополнительные документы согласно правилам страхования.',
          'Получите деньги - обычно это занимает не более 10 дней. '
        ]
      }
    case 'Медицинские расходы':
    case 'Online-врач в поездку':
      return {
        iconSrc: CrossIconPath,
        iconBackground: '#DAF0A2',
        iconText: 'Компенсация до 50 000 $',
        detailsTitle: false,
        detailsItems: [
          'Оплата экстренного лечения и стоматологии',
          'Защита при потере документов',
          'Транспортные расходы',
          'Защита при активных видах отдыха',
        ],
        detailsItemsTitle: 'Будьте уверены, что, находясь в поездке, вы всегда сможете получить неотложную медицинскую помощь.',
        detailsItemsFull: [
          'Оплата медицинской помощи при получении травмы, отравлении, внезапном остром заболевании',
          'Оплата экстренной стоматологической помощи',
          'Оплата медицинской транспортировки или эвакуации',
          'Защита при активных видах отдыха',
          'Транспортные расходы',
          'Расходы при потере или похищении документов',
          'Оплата срочных сообщений',
          'Получение юридической помощи'
        ],
        storyIconSrc: InsuranceMedicalIconPath,
        howtoTitle: false,
        howtoInstructions: [
          'Свяжитесь с оператором сервисной службы по контактам, указанным в полисе.',
          'Назовите оператору номер вашего полиса и коротко опишите, какая помощь вам необходима. Специалист вызовет врача или назовёт адрес ближайшей клиники.',
          'Следуйте указаниям оператора и не оплачивайте счета самостоятельно - расходы по страховым случаям возместит страховая компания. Если вы обратились в клинику без звонка в сервисную службу, внимательно оформляйте финансовые документы. В течении 30 дней предоставьте подтверждение расходов в страховую компанию. ',
          '“АльфаСтрахование” примет решение о компенсации затрат в течение нескольких дней после вашего обращения.'
        ]
      }
    default:
      return {}
  }
}

export function availableInsurancesNormalizer(args) {
  const res = {
    total: _(args.items)
      .filter(x => x.checked)
      .reduce((acc, x) => acc + x.totalRate.value, 0),
    items: _(args.items)
      .map(x => {
        const productType = (x.product.name || "").trim()

        return {
          ...x,
          id: uuid(),
          wordBeforePrice: getWordBeforPrice(productType),
          iconSrc: getInsuranceIcon(productType),
          company: getCompany(productType),
          loading: false,
          label: getLabel(x.checked),
          offertaUri: getOffertaUri(productType),
          specificationUri: getSpecificationDoc(productType),
          termsUri: 'https://www.alfastrah.ru/docs/rules_SP_230617.pdf',
          risks: formatRisks(productType, x.risks || []).sort(
            (a, b) => a.coverage.value - b.coverage.value
          ),
          cardRenderData: getCardRenderData(productType),
          product: {
            ...x.product,
            description: getInsuranceDescription(productType)
          }
        }
      })
      .value()
  }

  return res
}
