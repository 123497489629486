import React from 'react'
import HotelInfo from './HotelDescription/HotelInfo'
import Reviews from './Reviews'
import useHotelDescriptionStore from './store'
import { useLocation } from 'react-router'
import { decodeQueryString } from '../../functions/sequelize'
import FUN from '../../asset/favicon.ico'
// import FUNNY from '../../asset/images/faviconNY.png'
import Rooms from "./Rooms";
import useAuthStore from "../Auth/store";
import {useNavigate, useParams} from "react-router-dom";

const Hotel = () => {
	const { pathname, search } = useLocation()

	const actions = useHotelDescriptionStore(({ actions }) => actions)
	const actionsAuth = useAuthStore(({ actions }) => actions)
	const standardizedName = useHotelDescriptionStore(
		({ standardizedName }) => standardizedName
	)

	const { id } = useParams()
	const navigate = useNavigate()

	React.useEffect(() => {
		actionsAuth.refreshToken().then(res => {
			actions.getAmenities(res)
			actions.getHotelBlocks(res)
			if (id) {
				actions.getHotelByEbgId(id, res).then(res => {
					navigate('/' + res?.urlName)
					actions.getHotelInfo(res?.urlName, res)
				})
			} else {
				actions.getHotelInfo(pathname.slice(1), res)
			}
		})
		return () => {
			document.title = `Туры с перелетом`
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		const searchParams = decodeQueryString(search)
		if (searchParams?.IsInstantConfirmation) {
			actions.changeMomentConfirm(searchParams?.IsInstantConfirmation)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (standardizedName) {
		document.title = standardizedName + `: забронировать тур в ...`
		let link = document.createElement('link')
		link.rel = 'icon'
		link.href = FUN
		document.head.appendChild(link)
	}

	return (
		<div
			style={{
				backgroundColor: 'fff',
				position: 'relative',
				zIndex: 1
			}}>
			<HotelInfo />
			<Rooms />
			<Reviews />
		</div>
	)
}

export default Hotel
