import React, { useEffect, useState } from 'react';
import Range from './RCSlider';
import {ReactComponent as PlaneLogo} from '../../../../asset/images/avia/search-result/plane.svg'
import styled from 'styled-components';
import moment from 'moment';

const TopWrapper = styled.div`
  margin-bottom: 12px;
`;

const TextWrapper = styled.div`
  & > svg {
    margin: 0 8px;
  }
  display: inline-flex;
  align-items: center;
`;

const Text = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3C3C3C;
`;

const TextContaner = styled.div`
  font-family: "Open Sans";
  color: #3C3C3C;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const TimeText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`;

const DateRangeLabel = styled.div`
  color: #3C3C3C;
  margin-bottom: 8px;
`;

const DateRangeWrapper = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const DateRangeWithPlane = ({
  from,
  to,
  onChange,
}) => {
  const [values, setValues] = useState({ from: from.values, to: to.values });
  useEffect(() => {
    setValues({ from: from.values, to: to.values });
  }, [from, to]);

  const handleChangeFrom = (val) => {
    setValues((vals) => {
      return { ...vals, from: val };
    });
  };

  const handleChangeTo = (val) => {
    setValues((vals) => {
      return { ...vals, to: val };
    });
  };

  const handleOnAfterChangeFrom = (val) => {
    onChange('from', val);
  };

  const handleOnAfterChangeTo = (val) => {
    onChange('to', val);
  };

  return (
    <DateRangeWrapper className="range-time-with-plane">
      <TopWrapper>
        <TextWrapper>
          <Text>{from.city}</Text>
          <PlaneLogo width={24} height={24}/>
          <Text>{to.city}</Text>
        </TextWrapper>
      </TopWrapper>
      <div>
        <DateRangeLabel>
          <TimeText>{from.label}</TimeText>
        </DateRangeLabel>
        <TextContaner>
          <TimeText>от {timeFormatted(values.from[0])}</TimeText>
          <TimeText>до {timeFormatted(values.from[1])}</TimeText>
        </TextContaner>
        <Range
          onAfterChange={handleOnAfterChangeFrom}
          step={100}
          min={from.min}
          max={from.max}
          onChange={handleChangeFrom}
          value={values.from}
          range
        />
      </div>
      <div>
        <DateRangeLabel>
          <TimeText>{to.label}</TimeText>
        </DateRangeLabel>
        <TextContaner>
          <TimeText>от {timeFormatted(values.to[0])}</TimeText>
          <TimeText>до {timeFormatted(values.to[1])}</TimeText>
        </TextContaner>
        <Range
          range
          onAfterChange={handleOnAfterChangeTo}
          step={100}
          min={to.min}
          max={to.max}
          onChange={handleChangeTo}
          value={values.to}
        />
      </div>
    </DateRangeWrapper>
  );
};

function timeFormatted(val) {
  return moment.unix(val).format('HH:mm D MMM');
}

export default DateRangeWithPlane;
