import React from 'react';

import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@mui/material/Collapse';
import calendar from '../../../../asset/images/cabinet/calendar.svg'
import tourist from '../../../../asset/images/cabinet/tourist.svg'
import {ReactComponent as Download} from '../../../../asset/images/cabinet/download.svg'
import useContractsStore from '../store';
import {format} from "date-fns";


const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #F6F8FA;
	border-radius: 16px;
	margin-bottom: 24px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`;

const Contract = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid #D4D9DE;
	margin-top: 24px;
`

const ContractTextBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`

const ContractText = styled.div`
	display: flex;
	align-items: center;

	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #2E2E32;
`
const BoldText = styled(ContractText)`
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
`

const Circle = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: #2E2E32;
	margin: 0 8px;
`
const CalendarImage = styled.img.attrs({
	src: calendar
  })`
	width: 24px;
	height: 24px;
	margin-right: 8px;
  `

const TouristImage = styled.img.attrs({
	src: tourist
  })`
	width: 24px;
	height: 24px;
	margin-right: 8px;
  `
const DownloadImage = styled(Download)`
	width: 16px;
	height: 16px;
	fill: ${({ isDisabled }) => isDisabled ? '#B5B8BC' : '#4872F2'};
	
`
const Button = styled.button`
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	background-color: transparent; 
	border-radius: 8px;
	border: 1px solid;
	border-color: ${({ isDisabled }) => isDisabled ? '#E9EAEC' : '#4872F2' };
	color: ${({ isDisabled }) => isDisabled ? '#B5B8BC' : '#4872F2'};
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; 
	transition: 0.2s;
	pointer-events: ${({ isDisabled }) => isDisabled ? 'none' : 'auto'};
	&:hover {
		border-color: #0943f4;
		color: #0943f4;
	}
	&:active {
    	background-color: #0943f4;
		color: #fff;
  }
`
 
export default function ContractsList() {
    const [isOpen, setIsOpen] = React.useState(true);
	const actions = useContractsStore(({ actions }) => actions);
	const agentContracts = useContractsStore((state) => state.agentContracts);

	React.useEffect(() => {
        actions.getAgentContracts();
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Row
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer' }}
            >
                <FlexRow>
                    <Title>Договоры</Title>
                </FlexRow>
                <ExpandMoreIcon
                    onClick={() => setIsOpen(!isOpen)}
                    style={isOpen ? { transform: 'rotate(180deg)' } : null}
                    isActive={isOpen}
                />
            </Row>
            <Collapse in={isOpen}>
				{agentContracts.filter((contract) => contract.dogovorNumber).map((contract) => (
					<Contract key={contract.id}>
						<ContractTextBlock>
							<ContractText>
								<BoldText>№{contract.dogovorNumber}</BoldText> <Circle/> {contract.contractTypeName} договор
							</ContractText>
							<ContractText> <CalendarImage/>{format(new Date(contract.dateBegin), 'dd.MM.yyyy')} — {format(new Date(contract.dateEnd), 'dd.MM.yyyy')}</ContractText>
							<ContractText><TouristImage/>{contract.contractOwner}</ContractText>
							{contract.haveCopy  && <ContractText><b>В наличии</b>&nbsp;- копия</ContractText>}
							{contract.haveOriginal  && <ContractText><b>В наличии </b>&nbsp;- оригинал </ContractText>}
						</ContractTextBlock>
						<Button isDisabled={!contract.haveCopy } onClick={() => actions.createPrintContractJob(contract.id, contract.contractType, contract.owner)}><DownloadImage isDisabled={!contract.haveCopy }/>Скачать договор</Button>	
					</Contract>
				))}
            </Collapse>
        </Container>
    );
};

