import {
	createTheme,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	makeStyles,
	OutlinedInput,
	styled
} from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import React from 'react'
import useAuthStore from '../../../Auth/store'
import BpCheckbox from '../components/Checkbox/CustomizedCheckbox'
import '../style.css'

export const HelperTextInput = styled(FormHelperText)(({ theme }) => ({
	marginLeft: '0px',
	marginBottom: '4px',
	color: '#737373',
	fontSize: 14,
	lineHeight: '16px'
}))

export const theme = createTheme({
	typography: {
		body1: {
			fontFamily: "'Open Sans', sans-serif",
			fontWeight: 400,
			fontSize: 14,
			lineHeight: '16.8px',
			color: '#3C3C3C'
		}
	}
})

export const useInputStyles = makeStyles(() => ({
	root: {
		'&$focused $notchedOutline': {
			borderColor: theme.palette.text.primary,
			borderWidth: 1,
			caretColor: '#4872F2'
		}
	},
	focused: {},
	notchedOutline: {}
}))

export default function AboutAgency() {
	const [open, setOpen] = React.useState(false)
	const actions = useAuthStore(({ actions }) => actions)
	const agents = useAuthStore(({ agent }) => agent?.agents)

	const [checkedName, setCheckedName] = React.useState(false)

	const handleChangeName = event => {
		setCheckedName(event.target.checked)
		actions.changeLatinName()
	}

	const [checkedAdress, setCheckedAdress] = React.useState(false)

	const handleChangeAdress = event => {
		setCheckedAdress(event.target.checked)
		if (event.target.checked) {
			actions.changeAgentFAddress(agents.pAddress, checkedFAdress)
		} else {
			actions.changeAgentFAddress('')
		}
	}
	React.useEffect(() => {
		const checkAdress = () => {
			console.log()
			if (
				agents?.address?.split(', ').slice(1).join(', ') === agents?.pAddress
			) {
				setCheckedPAdress(true)
				return
			} else if (
				agents?.address?.split(', ').slice(1).join(', ') === agents?.fAddress
			) {
				setCheckedFAdress(true)
				return
			} else return
		}
		checkAdress()
		const matchAddress = () => {
			if (agents?.pAddress === agents?.fAddress) {
				setCheckedAdress(true)
				return
			} else {
				setCheckedAdress(false)
				return
			}
		}
		matchAddress()
		const matchNameCompany = () => {
			if (agents?.officialName === agents?.latinName) {
				setCheckedName(true)
				return
			} else {
				setCheckedName(false)
				return
			}
		}
		matchNameCompany()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const inputStyles = useInputStyles()

	const [checkedPAdress, setCheckedPAdress] = React.useState(false)

	const handleChangePAdress = event => {
		setCheckedPAdress(event.target.checked)
		actions.changeAgentPAdress(agents.pAddress, event.target.checked)
		if (event.target.checked) {
			setCheckedFAdress(!event.target.checked)
		}
	}

	const [checkedFAdress, setCheckedFAdress] = React.useState(false)

	const handleChangeFAdress = event => {
		setCheckedFAdress(event.target.checked)
		actions.changeAgentFAddress(agents.fAddress, event.target.checked)
		if (event.target.checked) {
			setCheckedPAdress(!event.target.checked)
		}
	}

	if (!agents) return null
	actions.refreshToken()
	return (
		<>
			<div className='agency-title'>
				<div className='about-agency'>Об агентстве</div>
				{!open ? (
					<button
						type='button'
						className='agency-open-btn'
						onClick={() => setOpen(prev => !prev)}>
						Подробнее
					</button>
				) : (
					<button
						type='button'
						className='agency-close-btn'
						onClick={() => setOpen(prev => !prev)}>
						Скрыть
					</button>
				)}
			</div>
			{open && (
				<>
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<FormControl>
								<HelperTextInput>ИНН</HelperTextInput>
								<OutlinedInput
									disabled
									value={agents.inn}
									className='outlined-INN'
									classes={inputStyles}
									onChange={e => actions.changeAgentINN(e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4} align='center'>
							<FormControl variant='outlined' className='custom-helperText'>
								<HelperTextInput className='custom-helperText'>
									КПП
								</HelperTextInput>
								<OutlinedInput
									value={agents.kpp}
									disabled
									className='outlined-INN'
									classes={inputStyles}
									id='outlined-ОГРН'
									aria-describedby='outlined-weight-helper-text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4} align='right'>
							<FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
								<HelperTextInput
									id='outlined-weight-helper-text'
									className='custom-helperText'>
									ОГРН
								</HelperTextInput>
								<OutlinedInput
									disabled
									value={agents.ogrn}
									classes={inputStyles}
									className='outlined-INN'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl variant='outlined'>
								<HelperTextInput>Дата регистрации</HelperTextInput>
								<OutlinedInput
									value={format(
										parseISO(agents?.registrationDate),
										'dd.MM.yyyy'
									)}
									disabled
									classes={inputStyles}
									className='outlined-INN'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4} align='center'>
							<FormControl variant='outlined'>
								<HelperTextInput>Форма собственности</HelperTextInput>
								<OutlinedInput
									disabled
									value={agents.ownership.title}
									classes={inputStyles}
									className='outlined-INN'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4} align='right'>
							<FormControl variant='outlined'>
								<HelperTextInput>Вид деятельности</HelperTextInput>
								<OutlinedInput
									disabled
									value={agents.activity.title}
									classes={inputStyles}
									className='outlined-INN'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant='outlined'>
								<HelperTextInput id='outlined-weight-helper-text'>
									Система налогообложения
								</HelperTextInput>
								<OutlinedInput
									disabled
									value={agents.taxation.title}
									classes={inputStyles}
									style={{ width: '530px', height: '40px' }}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={3}
						style={{ marginTop: '12px', marginBottom: '8px' }}>
						<Grid item xs={6}>
							<FormControl variant='outlined'>
								<HelperTextInput>Юридическое название компании</HelperTextInput>
								<OutlinedInput
									// disabled
									value={agents.officialName}
									classes={inputStyles}
									style={{ width: '392px', height: '40px' }}
									onChange={e =>
										actions.changeAgentOfficialName(e.target.value, checkedName)
									}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6} align=''>
							<FormControl variant='outlined'>
								<HelperTextInput>
									Маркетинговое название компании
								</HelperTextInput>
								<OutlinedInput
									disabled
									value={!checkedName ? agents.latinName : agents.officialName}
									classes={inputStyles}
									style={{ width: '392px', height: '40px' }}
									className='custom-label'
								/>
							</FormControl>
							<FormControlLabel
								control={
									<BpCheckbox
										// disabled
										checked={checkedName}
										onChange={handleChangeName}
									/>
								}
								label='Названия совпадают'
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={{ marginBottom: '8px' }}>
						<Grid item xs={8}>
							<FormControl variant='outlined'>
								<HelperTextInput>Адрес регистрации</HelperTextInput>
								<OutlinedInput
									value={agents.pAddress}
									classes={inputStyles}
									style={{ width: '530px', height: '40px' }}
									placeholder='Mocква, улица 1905 года, 111'
									onChange={e => {
										actions.changeAgentPAdress(e.target.value, checkedPAdress)
										if (checkedAdress) {
											actions.changeAgentFAddress(
												e.target.value,
												checkedFAdress
											)
										}
									}}
								/>
							</FormControl>
							<FormControlLabel
								control={
									<BpCheckbox
										checked={checkedPAdress}
										onChange={handleChangePAdress}
									/>
								}
								label='Получать почту на этот адрес'
							/>
						</Grid>
						<Grid item xs={4} align='right'>
							<FormControl variant='outlined'>
								<HelperTextInput>Почтовый индекс</HelperTextInput>
								<OutlinedInput
									value={agents.addressCode}
									classes={inputStyles}
									style={{ width: '254px', height: '40px' }}
									placeholder='101000'
									onChange={e => actions.changeAgentAdressCode(e.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<FormControl variant='outlined'>
								<HelperTextInput>Фактический адрес</HelperTextInput>
								<OutlinedInput
									disabled={checkedAdress}
									placeholder='Mocква, проспект Мира, 999'
									value={
										checkedAdress
											? agents.pAddress || ''
											: agents.fAddress || ''
									}
									classes={inputStyles}
									style={{ width: '530px', height: '40px' }}
									id='outlined-adornment-weight'
									aria-describedby='outlined-weight-helper-text'
									inputProps={{
										'aria-label': 'weight'
									}}
									onChange={e =>
										actions.changeAgentFAddress(e.target.value, checkedFAdress)
									}
								/>
							</FormControl>
							<FormControlLabel
								control={
									<BpCheckbox
										onChange={handleChangeAdress}
										checked={checkedAdress}
									/>
								}
								label='Адреса совпадают'
							/>
							<FormControlLabel
								control={
									<BpCheckbox
										checked={checkedFAdress}
										onChange={handleChangeFAdress}
									/>
								}
								label='Получать почту на этот адрес'
							/>
						</Grid>
						<Grid item xs={4} align='right'>
							<FormControl variant='outlined'>
								<HelperTextInput>Почтовый индекс</HelperTextInput>
								<OutlinedInput
									disabled
									value={checkedAdress ? agents.addressCode : ''}
									classes={inputStyles}
									style={{ width: '254px', height: '40px' }}
									// placeholder='101000'
									// onChange={e => actions.changeAgentAdressCode(e.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}
