import styled, {keyframes} from "styled-components";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCommissionStatus, setCommissionStatus} from "../../store/modules/user";
import {getDetailOrder} from "../../store/modules/orders";
import formatPrice from "../../functions/formatPrice";
import blueInfoIcon from "../../asset/images/avia/agents/blueInfoIcon.svg"
import Tippy from '@tippyjs/react';

const Wrapper = styled.div`
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const show = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const hide = keyframes`
  to {
    stroke-dashoffset: 23;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  color: #4872F2;

  .tte-svg-eye {
    .tte-svg-eye-line {
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      &.hide {
        stroke-dashoffset: 0;
        animation: ${hide} 0.3s linear forwards;
      }
      &.show {
        animation: ${show} 0.3s linear forwards;
      }
    }
  }
  & span {
    margin-left: 6px;
    font-size: 14px;
  }
`;

const TaxesWrapper = styled.div`
  background-color: #F7F7F7;
  border-radius: 8px;
  padding: 22px 10px;
  margin-top: 20px;

  &.show {
    display: flex;
    flex-direction: column;
  }
  &.hide {
      display: none;
  }
`

const Row = styled.div`
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
`

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
`
const TotalTitle = styled.div`
  display: flex;
  align-items: center;
`
const TotalPrice = styled.div`
  color: #4872F2;
`

const BlueInfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  width: 20px;
  margin-left: 6px;
  &:hover {
    cursor: pointer;
  }
`

const AgentTaxesBlock = () => {
  const dispatch = useDispatch();
  const isShowCommission = useSelector(getCommissionStatus);
  const fees = useSelector(getDetailOrder).agentFees

  return (
    <Wrapper>
      <Header>
        <Title>Информация для агента</Title>
        <ActionButton onClick={() => dispatch(setCommissionStatus(!isShowCommission))}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="tte-svg-eye">
            <g id="Frame">
              <path id="Vector" d="M15.9424 8.2215C16.2979 8.6865 16.2979 9.31425 15.9424 9.7785C14.8226 11.2403 12.1361 14.25 8.99964 14.25C5.86314 14.25 3.17664 11.2403 2.05689 9.7785C1.88393 9.55585 1.79004 9.28194 1.79004 9C1.79004 8.71806 1.88393 8.44415 2.05689 8.2215C3.17664 6.75975 5.86314 3.75 8.99964 3.75C12.1361 3.75 14.8226 6.75975 15.9424 8.2215Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
              <path stroke="#4872F2" d="M3 15L15 3" stroke-linecap="round" stroke-linejoin="round" className={`tte-svg-eye-line ${isShowCommission ? 'show' : 'hide'}`}/>
              <path id="Vector_2" d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
          <span>{isShowCommission ? 'Скрыть' : 'Открыть'} детали для агента</span>
        </ActionButton>
      </Header>
      <TaxesWrapper className={`${isShowCommission ? 'show' : 'hide'}`}>
        {fees.map(x => {
          return (
            <Row>
              <div>{x.title}</div>
              <div>{formatPrice(x.price)}</div>
            </Row>
          )
        })}
        <TotalWrapper>
          <TotalTitle>
            Сумма сборов:
            <Tippy
              maxWidth={232}
              theme="light"
              interactive={true}
              content={
                <div style={{fontWeight: 400}}>
                  Размеры сборов по умолчанию рассчитаны в соответствии с вашими правилами или правилами вашей группы.
                  Правила группы применяются, если у вас не созданы правила сборов.<br/><br/>
                  Настройки сборов Вы можете посмотреть в разделе <a href={`/cabinet/avia-agent-info/fees`}
                                                                     rel={'noreferrer'} target={'_blank'}>
                  <span color={'#4872F2'}>Финансы и настройки</span></a>.
                </div>
              }
            >
              <BlueInfoIcon/>
            </Tippy>
          </TotalTitle>
          <TotalPrice>{formatPrice(fees.reduce((acc, x) => acc + x.price, 0))}</TotalPrice>
        </TotalWrapper>
      </TaxesWrapper>
    </Wrapper>
  )
}

export default AgentTaxesBlock