import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'
import { Text } from '../ui';
import PenaltyDescription from "./components/PenaltyDescription";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px 20px 40px 40px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      padding: 20px 10px 20px 20px;
      box-sizing: border-box;
      border-radius: 0;
    }
  }
`;

const OverflowContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding-right: 20px;

  &::-webkit-scrollbar {
      width: 2.786px;
  }
  &::-webkit-scrollbar-thumb {
      background: #4872f2;
      border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
      background: #E9EAEC;
      width: 2.786px;
      border-radius: 2px;
  }

  @media (max-width: 767px) {
    max-height: 90vh;
    padding-right: 10px;
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TextHelper = styled.div`
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  color: #3c3c3c;
`;

const TextContainer = styled.p`
  margin-bottom: 25px;
`;

const MarkedList = styled.ul`
  padding-left: 24px;
  margin-bottom: 0px;
`;

const MarkedListItem = styled.li`
  padding-bottom: 15px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

const AccentBluePanel = styled.div`
  border: 1px solid #4872f2;
  background-color: #edf1fe;
  padding: 14px;
  border-radius: 8px;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  & > :last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-right: 8px;
`;

const CharterTariffsModal = (props) => {

  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="Закрыть окно"
        onClick={props.onClose}
      >
        <Icon/>
      </CloseButton>
      <PopupHead>
        <Title>
          <TitleText>Условия применения тарифа</TitleText>
        </Title>
      </PopupHead>
      <MobileHead>
        <MobileTitle>Условия применения тарифа</MobileTitle>{' '}
      </MobileHead>
      <OverflowContainer>
        <PenaltyDescription />
        <List>
          <TextContainer>
            <Text>
              Клиент предупреждён, что в исключительных случаях возможна замена
              перевозчика, типа воздушного судна на аналогичные услуги без
              взимания какой-либо доплаты со стороны Клиента.
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Клиент уведомлен, что вправе в любое время отказаться
              (аннулировать) от заявки, бронирование которой было подтверждено,
              при условии выплаты фактически понесенных расходов, связанных с
              исполнением обязательств по аннулируемой заявке.
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Под издержками, в данном случае, понимаются фактически понесенные
              расходы агента/консолидатора, в том числе денежные средства,
              потраченные агентом/консолидатором в целях организации туристского
              обслуживания до момента получения письменного извещения от туриста
              об аннулировании заявки.
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Клиент уведомлен, что стоимость авиабилета возвращается частично,
              за исключением суммы фактически понесенных расходов составляющих
              полную или частичную стоимость авиабилетов. Расчет фактически
              понесенных расходов, а также их минимизация производится после
              даты совершения вылета рейса.
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Клиент предупрежден, что если договором воздушной перевозки
              предусмотрено условие о возврате пассажиру стоимости авиабилета и
              пассажир уведомил агента/консолидатора об отказе от перелета,
              расчет денежных средств подлежащих возврату за вычетом фактически
              понесенных расходов происходит по следующей таблице:
            </Text>
          </TextContainer>

          <AccentBluePanel>
            <Text fontWeight={'600'}>
              Аннуляция авиабилетов по направлениям Кипр, Индия, ОАЭ, Мальдивы,
              Таиланд, Шри-Ланка, Казахстан, Куба:
            </Text>
            <MarkedList>
              <MarkedListItem>
                <Text>
                  За 22 дня и более до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 25%
                </Text>
              </MarkedListItem>
              <MarkedListItem>
                <Text>
                  За 21 день и менее до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 100%
                </Text>
              </MarkedListItem>
            </MarkedList>
            <TextHelper>
              Аннуляция авиабилетов по направлениям Турция, Египет, Россия:
            </TextHelper>

            <MarkedList>
              <MarkedListItem>
                <Text>
                  За 22 дня и более до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 25%
                </Text>
              </MarkedListItem>
              <MarkedListItem>
                <Text>
                  От 21 дня до 10 дней до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 50%
                </Text>
              </MarkedListItem>
              <MarkedListItem>
                <Text>
                  За 9 дней и менее до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 100%{' '}
                </Text>
              </MarkedListItem>
            </MarkedList>
          </AccentBluePanel>

          <TextContainer>
            <Text>
              Клиент предупрежден, что если заключен договор воздушной перевозки
              пассажира, предусматривающий условие о невозврате провозной платы
              при расторжении договора воздушной перевозки пассажира, уплаченная
              за воздушную перевозку пассажира провозная плата не возвращается,
              и удерживается авиакомпанией.
            </Text>
          </TextContainer>

          <TextContainer>
            <Text>
              Клиент уведомлен, что он имеет право получить возврат денежных
              средств по невозвратному тарифу в случае вынужденного отказа от
              перевозки. Вынужденным отказом признается отказ вызванный в
              следующих случаях:
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              <MarkedList>
                <li>
                  отказ в связи с болезнью пассажира или члена его семьи либо
                  близкого родственника, совместно следующего с ним на воздушном
                  судне, что подтверждается медицинскими документами; либо
                </li>
                <li>
                  смерти члена его семьи или близкого родственника, что
                  подтверждается документально, при условии уведомления об этом
                  перевозчика до окончания времени регистрации пассажиров на
                  указанный в билете рейс.
                </li>
              </MarkedList>
            </Text>
          </TextContainer>
        </List>
      </OverflowContainer>
    </StyledPopup>
  );
};

export default CharterTariffsModal;
