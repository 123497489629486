import React, { useEffect, useRef, useState } from 'react'
import TransferDescriptionBlock from './components/TransferDescriptionBlock'
import MoreInformation from './components/MoreInformation'
import AdditionalServices from './components/AdditionalServices'
import useTransferStore from '../store'
import { Button } from '@material-ui/core'
import MembersBlock from './components/MembersBlock'
import { isEmpty } from 'lodash'
import { Alert, Snackbar } from '@mui/material'
import useAuthStore from '../../../Auth/store'
import BookingTransferSuccess from './components/BookingTransferSuccess'
import ReserveBlock from './components/ReserveBlock.js'
import ChildAgreement from './components/ChildAgreement/index.js'

const PartnerBooking = () => {
	const actions = useTransferStore(({ actions }) => actions)
	const authActions = useAuthStore(({ actions }) => actions)
	const draft = useTransferStore(({ draft }) => draft)
	const transfer = useTransferStore(({ transfer }) => transfer)
	const loading = useTransferStore(({ loading }) => loading)
	const loadingUpdateBasket = useTransferStore(({ loadingUpdateBasket }) => loadingUpdateBasket)

	const travelers = useTransferStore(({ travelers }) => travelers)
	const status = useTransferStore(({ status }) => status)
	const moreInfo = useTransferStore(({ moreInfo }) => moreInfo)
	const authError = useTransferStore(({ authError }) => authError)
	const fullPrice = useTransferStore(({ fullPrice }) => fullPrice)

	const scrollRef = useRef(null)
	const [valid, setValid] = useState(0)

	const [errors, setErrors] = useState({
		addressFrom: '',
		addressTo: '',
		clientPhone: '',
		flightNumber: '',
		labelName: '',
		startDate: ''
	})

	useEffect(() => {
		actions.getDraft().then(res => {
			actions.getTransfer(res?.transfers[0].transferId)
			if (isEmpty(res?.transfers[0]?.travellers)) {
				actions.setTravelers()
			}
		})
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (authError) {
			authActions.logout()
		}
		actions.clearAuthError()
		// eslint-disable-next-line
	}, [authError])

	const handleRecountDraft = () => {
		let err = {
			addressFrom: '',
			addressTo: '',
			clientPhone: '',
			flightNumber: '',
			labelName: '',
			startDate: ''
		}
		if (!moreInfo.addressFrom) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			err = { ...err, addressFrom: 'Адрес не может быть пустым' }
		}

		if (!moreInfo.addressTo) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			err = { ...err, addressTo: 'Адрес не может быть пустым' }
		}

		if (!moreInfo.clientPhone) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			err = { ...err, clientPhone: 'Номер телефона не может быть пустым' }
		}

		if (
			[2, 3, 4, 5].includes(transfer[0].route.placeFrom.type.id) ||
			transfer[0].route.placeTo.type.id === 2
		) {
			if (!moreInfo.flightNumber) {
				scrollRef.current.scrollIntoView({ behavior: 'smooth' })
				err = { ...err, flightNumber: 'Номер рейса не может быть пустым' }
			}
		}

		if ([2, 3, 4, 5].includes(transfer[0].route.placeFrom.type.id)) {
			if (!moreInfo.labelName) {
				scrollRef.current.scrollIntoView({ behavior: 'smooth' })
				err = { ...err, labelName: 'Имя не может быть пустым' }
			}
		}

		if (!moreInfo.startDate) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			err = { ...err, startDate: 'Дата не может быть пустой' }
		}

		setValid(prev => prev + 1)
		setErrors(err)

		actions.updateBasket(draft.id)
	}

	if (!draft || !transfer) return
	return (
		<div className='container'>
			<TransferDescriptionBlock
				transfer={transfer[0]}
				draft={draft.draftBody.transfers[0]}
			/>
			<div ref={scrollRef} />
			<MoreInformation
				transfer={transfer[0]}
				draft={draft.draftBody.transfers[0]}
				validateError={errors}
			/>
			<AdditionalServices draft={draft.draftBody} transfer={transfer[0]} valid={valid}/>
			{travelers.map(item => (
				<MembersBlock count={item.id} valid={valid} />
			))}
			<div
				style={{
					textAlign: 'center',
					marginBottom: 20,
					fontSize: 24,
					fontWeight: 600
				}}>
				{fullPrice} {draft.draftBody.transfers[0].currency.currencyAlias}
			</div>
			{!!draft?.draftBody?.transfers[0].passengers.children && (
				<ChildAgreement />
			)}
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				<Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={handleRecountDraft}
					disabled={loading || loadingUpdateBasket}>
					Пересчитать
				</Button>
				<ReserveBlock />
				{/* <Button
					variant='contained'
					onClick={() => actions.reserveDraft(draft.id)}
					disabled={!isReadyForBooking || loading || errorForm}
				>
					Бронировать
				</Button> */}
			</div>
			{status && (
				<Snackbar open={status.trace[0].message} autoHideDuration={6000}>
					<Alert
						onClose={() => {
							actions.clearError()
						}}
						severity='error'
						sx={{ width: '100%' }}>
						{status.trace[0].message}
					</Alert>
				</Snackbar>
			)}
			{status && status.status === 'ReservationCompleted' && (
				<BookingTransferSuccess
					claim={status.backOfficeId}
					price={`${fullPrice} ${draft.draftBody.transfers[0].currency.currencyAlias}`}
				/>
			)}
		</div>
	)
}

export default PartnerBooking
