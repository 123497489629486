
import { useState } from 'react';
import ActionsCard from './ActionsCard';

import './style.scss'
import { useEffect } from 'react';
import _ from 'lodash';
import ArrowDownSmall from '../../../../asset/icons/ArrowDownSmall'
import Pagination from '@mui/material/Pagination';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const sortInfinity = (a, b) => {
    if (!a.show_to)
        return -1
    if (a.show_to > b.show_to)
        return -1
    return 0
}

const usePaginationStyle = makeStyles((theme) =>
    createStyles({
        item: {
            '& .MuiPaginationItem-root': {
                width: '40px',
                height: '40px',
                borderRadius: '25px',
                color: '#b3b9c0',
                fontSize: '16px',
                marginLeft: '8px',
            },
            '& .MuiPaginationItem-ellipsis': {
                margin: '0',
                paddingTop: "10px",
            },
            '& .Mui-selected': {
                backgroundColor: 'unset !important',
                color: '#4872f2 !important',
                borderColor: '#4872f2 !important'
            },
        },
    }),
);

const ContentWithPagination = ({ actionsList, displayOption = 'grid' }) => {
    const [currentAction, setCurrentAction] = useState(1);
    const [actionsPerView] = useState(9);
    const paginationStyle = usePaginationStyle()

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(actionsList.length / actionsPerView); i++) {
        pageNumbers.push(i);
    }

    const indexOfLastAction = currentAction * actionsPerView;
    const indexOfFirstAction = indexOfLastAction - actionsPerView;
    const currentActionsList = actionsList.sort(sortInfinity).slice(indexOfFirstAction, indexOfLastAction);

    const paginate = (event, pageNumber) => {
        setCurrentAction(pageNumber);
    };

    const groupByYear = (array) => {
        return _(array).groupBy((item) => item.show_to ? new Date(item.show_to.replace(" ", "T") + "Z")?.getFullYear() : 'Infinity')
            .map((items, title) => ({ title: title, actions: items }))
            .value()
            .sort((a, b) => a.title === 'Infinity' ? -1 : a.title > b.title ? -1 : 0)
    }

    useEffect(() => {
        setCurrentAction(1)
    }, [actionsList])

    const PaginationBlock = () => {
        return <div>
            <nav className="pagination-container">
                <Pagination
                    count={pageNumbers.length}
                    variant="outlined"
                    shape='circular'
                    color="primary"
                    className={paginationStyle.item}
                    page={currentAction}
                    onChange={paginate}
                    hidePrevButton
                    hideNextButton
                />
            </nav>
        </div>
    }

    const GroupSpoiler = ({ title, children }) => {
        const [open, toggle] = useState(true)
        return (
            <div>
                <div className='name-year' onClick={() => toggle(!open)}>
                    {title.replace('Infinity', 'Бессрочная')}
                    <div className={`spoiler-arrow ${open ? 'rotate' : ''}`}>
                        <ArrowDownSmall />
                    </div>
                </div>
                {open && children}
            </div>)
    }

    if (displayOption === 'table') {
        const tableContent = groupByYear(currentActionsList.sort(sortInfinity))
        return <>
            <div className='actions-block_table'>
                {tableContent.map(item =>
                    <GroupSpoiler title={item.title}>
                        {item.actions.map(action => <ActionsCard key={action?.slug} content={action} view={displayOption} />)
                        }
                    </GroupSpoiler>)}
            </div>
            <PaginationBlock />
        </>
    }


    return (
        <>
            <div className='actions-block_grid'>
                {currentActionsList.sort(sortInfinity).map(action =>
                    <ActionsCard key={action.slug} content={action} view={displayOption}></ActionsCard>
                )}
            </div>
            <PaginationBlock />
        </>
    );
};

export default ContentWithPagination;