import { MenuItem } from '@material-ui/core'
import { InputAdornment, Select } from '@mui/material'
import React from 'react'

import { FormInput } from './AddAgentPopup'
import { ExpandMore } from '@material-ui/icons'

export default function PhoneInput({
	handleChangeSelectPhone,
	phone,
	handleChangePhone,
	number,
	prefix,
	error,
	checkPhoneError
}) {
	const setPhoneNumber = () => {
		if (phone?.startsWith('+7')) {
			return { select: '+7', number: phone.slice(2) }
		}
		if (phone?.startsWith('+370')) {
			return { select: '+370', number: phone.slice(4) }
		}
		if (phone?.startsWith('+371')) {
			return { select: '+371', number: phone.slice(4) }
		}
		if (phone?.startsWith('+372')) {
			return { select: '+372', number: phone.slice(4) }
		}
		if (phone?.startsWith('+375')) {
			return { select: '+375', number: phone.slice(4) }
		}
		if (phone?.startsWith('+380')) {
			return { select: '+380', number: phone.slice(4) }
		}
		if (phone?.startsWith('7')) {
			return { select: '+7', number: phone.slice(1) }
		}
		if (phone?.startsWith('+997')) {
			return { select: '+997', number: phone.slice(4) }
		}
	}
	React.useEffect(() => {
		const setPhoneNumber = () => {
			if (phone?.startsWith('+7')) {
				handleChangeSelectPhone('+7')
				handleChangePhone(phone?.slice(2))
				return { select: '+7', number: phone?.slice(2) }
			}
			if (phone?.startsWith('+370')) {
				handleChangeSelectPhone('+370')
				handleChangePhone(phone?.slice(4))
				return { select: '+370', number: phone?.slice(4) }
			}
			if (phone?.startsWith('+371')) {
				handleChangeSelectPhone('+371')
				handleChangePhone(phone?.slice(4))
				return { select: '+371', number: phone?.slice(4) }
			}
			if (phone?.startsWith('+372')) {
				handleChangeSelectPhone('+372')
				handleChangePhone(phone?.slice(4))
				return { select: '+372', number: phone?.slice(4) }
			}
			if (phone?.startsWith('+375')) {
				handleChangeSelectPhone('+375')
				handleChangePhone(phone?.slice(4))
				return { select: '+375', number: phone?.slice(4) }
			}
			if (phone?.startsWith('+997')) {
				handleChangeSelectPhone('+997')
				handleChangePhone(phone?.slice(4))
				return { select: '+997', number: phone?.slice(4) }
			}
			if (phone?.startsWith('+380')) {
				handleChangeSelectPhone('+380')
				handleChangePhone(phone?.slice(4))
				return { select: '+380', number: phone?.slice(4) }
			}
			if (phone?.startsWith('7')) {
				handleChangeSelectPhone('+7')
				handleChangePhone(phone?.slice(1))
				return { select: '+7', number: phone?.slice(1) }
			}
		}
		setPhoneNumber()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const countriesPreffix = [
		{ code: 'BY', label: 'Belarus', phone: '375' },
		{ code: 'EE', label: 'Estonia', phone: '372' },
		// { code: 'KZ', label: 'Kazakhstan', phone: '7' },
		{ code: 'LT', label: 'Lithuania', phone: '370' },
		{ code: 'LV', label: 'Latvia', phone: '371' },
		{ code: 'RU', label: 'Russian Federation', phone: '7' },
		{ code: 'UA', label: 'Ukraine', phone: '380' },
		{ code: 'KZ', label: 'Kazachstan', phone: '997' }
	]

	if (!prefix) return null
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
			<Select
				startAdornment={
					<InputAdornment position='start'>
						<img
							loading='lazy'
							width='25'
							srcSet={`https://flagcdn.com/w40/${countriesPreffix
								.find(item => `+${item.phone}` === prefix)
								.code?.toLowerCase()}.png 2x`}
							src={`https://flagcdn.com/w20/${countriesPreffix
								.find(item => `+${item.phone}` === prefix)
								.code?.toLowerCase()}.png`}
							alt=''
						/>
					</InputAdornment>
				}
				IconComponent={ExpandMore}
				style={{
					minWidth: '120px',
					borderRadius: '8px',
					marginRight: '10px',
					height: '57px'
				}}
				variant='outlined'
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}
				value={prefix || setPhoneNumber()?.select}
				onChange={e => handleChangeSelectPhone(e.target.value)}>
				<MenuItem value={'+7'}>+7</MenuItem>
				<MenuItem value={'+370'}>+370</MenuItem>
				<MenuItem value={'+371'}>+371</MenuItem>
				<MenuItem value={'+372'}>+372</MenuItem>
				<MenuItem value={'+375'}>+375</MenuItem>
				<MenuItem value={'+380'}>+380</MenuItem>
				<MenuItem value={'+997'}>+997</MenuItem>
			</Select>
			<FormInput
				fullWidth
				value={number ? number || setPhoneNumber()?.number : ''}
				variant='outlined'
				id='outlined-required'
				label='Телефон'
				name='phone'
				onChange={e => {
					checkPhoneError(e.target.value)
					handleChangePhone(e.target.value)
				}}
				required
				title='В номере телефона допустим ввод только цифр'
				error={!!error.phone}
				helperText={error.phone}
			/>
		</div>
	)
}
