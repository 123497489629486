import React from 'react';
import {SvgIcon} from "@mui/material";

const SuccessIcon = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 30 22" style={{width: 30, height: 22}}>
			<path d="M10.9667 15.125L4.73073 8.82895C4.20509 8.29825 3.54852 8.04544 2.76102 8.07053C1.97161 8.09369 1.31409 8.37061 0.788455 8.90132C0.262819 9.43202 0 10.1075 0 10.9276C0 11.7478 0.262819 12.4232 0.788455 12.9539L8.95971 21.2039C9.48535 21.7346 10.1543 22 10.9667 22C11.779 22 12.448 21.7346 12.9737 21.2039L29.2445 4.77632C29.7701 4.24562 30.0215 3.58175 29.9986 2.78474C29.9737 1.98965 29.6985 1.32676 29.1728 0.796054C28.6472 0.265352 27.9782 0 27.1659 0C26.3535 0 25.6845 0.265352 25.1589 0.796054L10.9667 15.125Z" fill="#28BF68"/>
		</SvgIcon>
	);
};

export default SuccessIcon;