import React from 'react';
import { v4 as uuid } from 'uuid';

import useBookingStore from "../../store";

const OptionsBlock = ({options,isInclude, date }) => {
	const paxes = useBookingStore((state) => state.paxes);
	if(isInclude){
		return <>
		{options.map(item =>{
			const paxesLength = paxes.reduce((accumulator, pax) => {
				if (pax.selectedInsurances.some(insurance => insurance.optionIds.includes(item.id))) {
				  return accumulator + 1;
				} else {
				  return accumulator;
				}
			  }, 0);
			if(paxesLength < 1){
				return null
			}
			return(
			<div key={uuid()} className='grid-insurances insurances-options'>
				<div>{item.name.replace(': ', '')}</div>
				<div>{date}</div>
				<div>{!item.isRequired && `+${item.price.amount} ${item.price.currencyAlias}`}</div>
				<div className="paxes">{paxesLength}</div>		
		</div>)})}
		</>
	}

	return (
		<>
			{options.map(item => item.isRequired && <div key={uuid()} className='grid-insurances insurances-options'>
			<div>{item.name.replace(': ', '')}</div>
				<div>{date}</div>
				<div>{!item.isRequired && `+${item.price.amount} ${item.price.currencyAlias}`}</div>
				<div className="paxes">{paxes.length}</div>	
			</div>)}
		</>
	);
};

export default OptionsBlock;