import React from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@mui/material/Collapse'
import { format } from 'date-fns'


const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 100px 452px 164px;
	gap: 48px;
`

const TabelText = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`

const TabelRow = styled(TableGrid)`
	grid-template-columns: 100px 452px 92px 24px;
	align-items: center;

	&:last-child {
		border-radius: 0px 0px 16px 16px;
	}
`
const ExpandedTableRow = styled(TableGrid)`
	grid-template-columns: 229px 217px 199px;
	border-top: 1px solid #dbe0e5;
	margin-top: 24px;
	padding-top: 24px;

	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;

	& span {
		font-weight: 600;
		line-height: 26px;
	}
`

const ExpandedTabelBlock = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #dbe0e5;
	border-left: 1px solid #dbe0e5;
	border-right: 1px solid #dbe0e5;
	padding: 24px;
	cursor: pointer;

	background-color: #fff;
	&:nth-child(odd) {
		background-color: #f6f8fa;
	}

	&:last-child {
		border-radius: 0px 0px 16px 16px;
	}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default function ExpandedBlock({ item }) {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
				<ExpandedTabelBlock onClick={() => setIsOpen(!isOpen)}>
					<TabelRow>
						<TabelText>{!item?.toState ? 'Все' : item?.toState?.title}</TabelText>
						<TabelText>
							{item?.tourName}
						</TabelText>
						<TabelText>{`${item?.commission !== null ? item?.commission + '%' : ''}`}</TabelText>
						<ExpandMoreIcon
							style={
								isOpen ? { transform: 'rotate(180deg)' } : null
							}
						/>
					</TabelRow>
					<Collapse in={isOpen}>
						<ExpandedTableRow>
							<Column>
								<div>
									<span>Страна отправления: </span>{!item?.fromState ? 'Все' : item?.fromState?.title}
								</div>
								<div>
									<span>Город отправления: </span>{!item?.fromTown ? 'Все' : item?.fromTown?.title}
								</div>
							</Column>
							<Column>
								<div>
									<span>Отели: </span>{item?.hotel && item?.hotel.title}
								</div>
								<div>
									<span>Тип: </span>{item?.partnerGroupName}
								</div>
							</Column>
							<Column>
								<div>
									<span>Даты начала тура: </span>
									{(item?.dateBeg ? format(new Date(item?.dateBeg), "dd.MM.yyyy") + " - " : '') + (item?.dateEnd ? format(new Date(item?.dateEnd), "dd.MM.yyyy") : '')}
								</div>
								<div>
									<span>Даты бронирования: </span>
									{(item?.reservationDateBeg ? format(new Date(item?.reservationDateBeg), "dd.MM.yyyy") + " - " : '') + (item?.reservationDateEnd ? format(new Date(item?.reservationDateEnd), "dd.MM.yyyy") : '')}
								</div>   
							</Column>
						</ExpandedTableRow>
					</Collapse>
				</ExpandedTabelBlock>
	)
}
