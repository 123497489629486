import { combineReducers } from 'redux';
import financeAgentsReducers from './agent-finance/duck';
import feesAgentsReducer from './agent-fees/duck';
import userAgentsReducer from './agent-users/duck';
import { createAction, createReducer } from "redux-act"


export const setAgentInfoLoadingState = createAction('@agents/setLoadingState');

const agentInfoLoadingState = createReducer({}, false)

agentInfoLoadingState.on(setAgentInfoLoadingState, (_,payload) => payload)


export default combineReducers({
  ...financeAgentsReducers,
  ...feesAgentsReducer,
  ...userAgentsReducer,
  agentInfoLoadingState,
});
