import * as React from 'react'
import { Box, Divider } from '@material-ui/core'

import useHotelDescriptionStore from '../../../store'

function ContentTitle(props) {
	const { children, onClose, ...other } = props

	return (
		<div
			style={{
				padding: 0,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: '24px'
			}}
			{...other}>
			<h2>{children}</h2>
		</div>
	)
}

const FilterBlocks = ({ id, array }) => {
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)
	const findElementById = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return array?.map(item => ({
			...item,
			name: tag?.find(elem => elem.id === item.id)?.name
		}))
	}
	return (
		<>
			<ul>
				{findElementById(id, array)?.map((item, i) => (
					<li key={i}>
						{item.payType === 'Paid' ? item.name + ' (платно)' : item.name}
					</li>
				))}
			</ul>
		</>
	)
}

export default function VerticalTabsCopy() {
	const hotelDescText = useHotelDescriptionStore(
		({ hotelDescText }) => hotelDescText?.text
	)
	const checkTime = useHotelDescriptionStore(({ checkTime }) => checkTime)
	const comfort = useHotelDescriptionStore(({ comfort }) => comfort)
	const cards = useHotelDescriptionStore(({ cards }) => cards)
	const restaurants = useHotelDescriptionStore(({ restaurants }) => restaurants)
	const forChildren = useHotelDescriptionStore(({ forChildren }) => forChildren)
	const beach = useHotelDescriptionStore(({ beach }) => beach)
	const distance = useHotelDescriptionStore(({ distance }) => distance)
	const territory = useHotelDescriptionStore(({ territory }) => territory)
	const waterEntertainment = useHotelDescriptionStore(
		({ waterEntertainment }) => waterEntertainment
	)
	const mealTypes = useHotelDescriptionStore(({ mealTypes }) => mealTypes)
	const locationHotel = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel
	)
	const thatAround = useHotelDescriptionStore(({ thatAround }) => thatAround)
	const addInfo = useHotelDescriptionStore(({ addInfo }) => addInfo)
	const roomType = useHotelDescriptionStore(({ roomType }) => roomType)
	const inRoom = useHotelDescriptionStore(({ inRoom }) => inRoom)
	const sport = useHotelDescriptionStore(({ sport }) => sport)
	const mountain = useHotelDescriptionStore(({ mountain }) => mountain)
	const language = useHotelDescriptionStore(({ language }) => language)
	const conferency = useHotelDescriptionStore(({ conferency }) => conferency)
	const spa = useHotelDescriptionStore(({ spa }) => spa)
	const healthy = useHotelDescriptionStore(({ healthy }) => healthy)
	const benefits = useHotelDescriptionStore(({ benefits }) => benefits)

	const isEmpty = item => {
		if (!item || (item?.filters?.length === 0 && item?.text === '')) {
			return false
		} else return true
	}
	function createMarkup(value) {
		return { __html: value }
	}

	return (
		<Box
			sx={{
				bgcolor: 'background.paper'
			}}>
			{(isEmpty(checkTime) ||
				isEmpty(comfort) ||
				isEmpty(cards) ||
				isEmpty(roomType) ||
				isEmpty(hotelDescText) ||
				isEmpty(inRoom)) && (
				<ContentTitle id='customized-dialog-title'>Описание</ContentTitle>
			)}
			{isEmpty(hotelDescText) && (
				<>
					<h4>Расширенное описание</h4>
					<div
						style={{ textAlign: 'justify' }}
						dangerouslySetInnerHTML={createMarkup(hotelDescText)}></div>
					{(isEmpty(cards) ||
						isEmpty(comfort) ||
						isEmpty(roomType) ||
						isEmpty(checkTime) ||
						isEmpty(inRoom)) && <Divider style={{ margin: '24px 0px' }} />}
				</>
			)}
			{isEmpty(checkTime) && (
				<>
					<h4>Время заезда/выезда</h4>
					{checkTime?.text ? (
						<>
							<div
								dangerouslySetInnerHTML={createMarkup(checkTime?.text)}></div>
							{(isEmpty(cards) ||
								isEmpty(comfort) ||
								isEmpty(roomType) ||
								isEmpty(inRoom)) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					) : (
						<>
							<FilterBlocks
								id={checkTime?.descriptionBlockTypeId}
								array={checkTime?.descriptionBlockFilters}
							/>
							{(isEmpty(cards) ||
								isEmpty(comfort) ||
								isEmpty(roomType) ||
								isEmpty(inRoom)) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(comfort) && (
				<>
					<h4>Удобства</h4>
					{comfort?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(comfort?.text)}></div>
							{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={comfort?.descriptionBlockTypeId}
								array={comfort?.descriptionBlockFilters}
							/>
							{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(roomType) && (
				<>
					<h4>Номерной фонд</h4>
					{roomType?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(roomType?.text)}></div>
							{(isEmpty(cards) || isEmpty(inRoom)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={roomType?.descriptionBlockTypeId}
								array={roomType?.descriptionBlockFilters}
							/>
							{(isEmpty(cards) || isEmpty(inRoom)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(cards) && (
				<>
					<h4>Кредитные карты</h4>
					{cards?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
							{isEmpty(inRoom) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					) : (
						<>
							<FilterBlocks
								id={cards?.descriptionBlockTypeId}
								array={cards?.descriptionBlockFilters}
							/>
							{isEmpty(inRoom) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(inRoom) && (
				<>
					<h4>В номере</h4>
					{inRoom?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(inRoom?.text)}></div>
						</>
					) : (
						<>
							<FilterBlocks
								id={inRoom?.descriptionBlockTypeId}
								array={inRoom?.descriptionBlockFilters}
							/>
						</>
					)}
				</>
			)}

			{isEmpty(locationHotel) && (
				<ContentTitle id='customized-dialog-title'>Расположение</ContentTitle>
			)}
			{isEmpty(locationHotel) && (
				<>
					<h4>Где находится</h4>
					{locationHotel?.text ? (
						<>
							<div
								dangerouslySetInnerHTML={createMarkup(
									locationHotel?.text
								)}></div>
							{isEmpty(thatAround) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={locationHotel?.descriptionBlockTypeId}
								array={locationHotel?.descriptionBlockFilters}
							/>
							{isEmpty(thatAround) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(thatAround) && (
				<>
					<h4>Что вокруг</h4>
					{thatAround?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(thatAround?.text)}></div>
					) : (
						<FilterBlocks
							id={thatAround?.descriptionBlockTypeId}
							array={thatAround?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{(isEmpty(restaurants) || isEmpty(mealTypes)) && (
				<ContentTitle id='customized-dialog-title'>Питание</ContentTitle>
			)}
			{isEmpty(mealTypes) && (
				<>
					<h4>Типы питания</h4>
					{mealTypes?.text ? (
						<>
							<div
								dangerouslySetInnerHTML={createMarkup(mealTypes?.text)}></div>
							{isEmpty(restaurants) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={mealTypes?.descriptionBlockTypeId}
								array={mealTypes?.descriptionBlockFilters}
							/>
							{isEmpty(restaurants) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(restaurants) && (
				<>
					<h4>Рестораны и бары</h4>
					{restaurants?.text ? (
						<div
							dangerouslySetInnerHTML={createMarkup(restaurants?.text)}></div>
					) : (
						<FilterBlocks
							id={restaurants?.descriptionBlockTypeId}
							array={restaurants?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{isEmpty(forChildren) && (
				<ContentTitle id='customized-dialog-title'>Для детей</ContentTitle>
			)}
			<p></p>
			{isEmpty(forChildren) && (
				<>
					{forChildren?.text ? (
						<div
							dangerouslySetInnerHTML={createMarkup(forChildren?.text)}></div>
					) : (
						<FilterBlocks
							id={forChildren?.descriptionBlockTypeId}
							array={forChildren?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{(isEmpty(beach) || isEmpty(distance)) && (
				<ContentTitle id='customized-dialog-title'>Пляж</ContentTitle>
			)}
			{isEmpty(beach) && (
				<>
					<h4>Описание пляжа</h4>
					{beach?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(beach?.text)}></div>
							{isEmpty(distance) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					) : (
						<>
							<FilterBlocks
								id={beach?.descriptionBlockTypeId}
								array={beach?.descriptionBlockFilters}
							/>
							{isEmpty(distance) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(distance) && (
				<>
					<h4>Расстояние до моря</h4>
					{distance?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(distance?.text)}></div>
					) : (
						<FilterBlocks
							id={distance?.descriptionBlockTypeId}
							array={distance?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{(isEmpty(waterEntertainment) ||
				isEmpty(territory) ||
				isEmpty(sport)) && (
				<ContentTitle id='customized-dialog-title'>Территория</ContentTitle>
			)}
			{isEmpty(territory) && (
				<>
					<h4>Описание территории</h4>
					{territory?.text ? (
						<>
							<div
								dangerouslySetInnerHTML={createMarkup(territory?.text)}></div>
							{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={territory?.descriptionBlockTypeId}
								array={territory?.descriptionBlockFilters}
							/>
							{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(waterEntertainment) && (
				<>
					<h4>Водные развлечения</h4>
					{waterEntertainment?.text ? (
						<div>
							<div
								dangerouslySetInnerHTML={createMarkup(
									waterEntertainment?.text
								)}></div>
							{isEmpty(sport) && <Divider style={{ margin: '24px 0px' }} />}
						</div>
					) : (
						<>
							<FilterBlocks
								id={waterEntertainment?.descriptionBlockTypeId}
								array={waterEntertainment?.descriptionBlockFilters}
							/>
							{isEmpty(sport) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(sport) && (
				<>
					<h4>Развлечения и спорт</h4>
					{sport?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(sport?.text)}></div>
					) : (
						<FilterBlocks
							id={sport?.descriptionBlockTypeId}
							array={sport?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{isEmpty(mountain) && (
				<ContentTitle id='customized-dialog-title'>
					Горнолыжная инфраструктура
				</ContentTitle>
			)}
			{isEmpty(mountain) && (
				<>
					{mountain?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(mountain?.text)}></div>
					) : (
						<FilterBlocks
							id={mountain?.descriptionBlockTypeId}
							array={mountain?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{(isEmpty(language) ||
				isEmpty(addInfo) ||
				isEmpty(benefits) ||
				isEmpty(cards)) && (
				<ContentTitle id='customized-dialog-title'>
					Важная информация
				</ContentTitle>
			)}
			{isEmpty(addInfo) && (
				<>
					<h4>Дополнительная информация</h4>
					{addInfo?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(addInfo?.text)}></div>
							{(isEmpty(language) || isEmpty(cards) || isEmpty(benefits)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={addInfo?.descriptionBlockTypeId}
								array={addInfo?.descriptionBlockFilters}
							/>
							{(isEmpty(language) || isEmpty(cards) || isEmpty(benefits)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(language) && (
				<>
					<h4>Язык</h4>
					{language?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(language?.text)}></div>
							{(isEmpty(benefits) || isEmpty(cards)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					) : (
						<>
							<FilterBlocks
								id={language?.descriptionBlockTypeId}
								array={language?.descriptionBlockFilters}
							/>
							{(isEmpty(benefits) || isEmpty(cards)) && (
								<Divider style={{ margin: '24px 0px' }} />
							)}
						</>
					)}
				</>
			)}
			{isEmpty(cards) && (
				<>
					<h4>Кредитные карты</h4>
					{cards?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
							{isEmpty(benefits) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					) : (
						<>
							<FilterBlocks
								id={cards?.descriptionBlockTypeId}
								array={cards?.descriptionBlockFilters}
							/>
							{isEmpty(benefits) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(benefits) && (
				<>
					<h4>Особенности и преимущества</h4>
					{benefits?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(benefits?.text)}></div>
					) : (
						<FilterBlocks
							id={benefits?.descriptionBlockTypeId}
							array={benefits?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{isEmpty(conferency) && (
				<ContentTitle id='customized-dialog-title'>
					Возможности для конференций
				</ContentTitle>
			)}
			{isEmpty(conferency) && (
				<>
					{conferency?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(conferency?.text)}></div>
					) : (
						<FilterBlocks
							id={conferency?.descriptionBlockTypeId}
							array={conferency?.descriptionBlockFilters}
						/>
					)}
				</>
			)}

			{(isEmpty(spa) || isEmpty(healthy)) && (
				<ContentTitle id='customized-dialog-title'>SPA и лечение</ContentTitle>
			)}
			{isEmpty(spa) && (
				<>
					<h4>Услуги SPA</h4>
					{spa?.text ? (
						<>
							<div dangerouslySetInnerHTML={createMarkup(spa?.text)}></div>
							{isEmpty(healthy) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					) : (
						<>
							<FilterBlocks
								id={spa?.descriptionBlockTypeId}
								array={spa?.descriptionBlockFilters}
							/>
							{isEmpty(healthy) && <Divider style={{ margin: '24px 0px' }} />}
						</>
					)}
				</>
			)}
			{isEmpty(healthy) && (
				<>
					<h4>Лечение и оздоровление</h4>
					{healthy?.text ? (
						<div dangerouslySetInnerHTML={createMarkup(healthy?.text)}></div>
					) : (
						<FilterBlocks
							id={healthy?.descriptionBlockTypeId}
							array={healthy?.descriptionBlockFilters}
						/>
					)}
				</>
			)}
		</Box>
	)
}
