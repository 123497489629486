import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import '../style.css'
import useYandexMapStore from '../store'
import useCountryStore from '../../../store'
import { Paper, useMediaQuery } from '@material-ui/core'

const BASE_IMAGE_URL =
	'https://apigate-tui.fstravel.com/api/geocontent/static/Country'

export const CountrySelect = () => {
	const countries = useCountryStore(({ openCountries }) => openCountries)
	const allCountries = useCountryStore(({ allCountries }) => allCountries)
	const openBtn = useYandexMapStore(({ openBtn }) => openBtn)
	const actions = useYandexMapStore(({ actions }) => actions)
	const activeCountry = useYandexMapStore(({ activeCountry }) => activeCountry)

	function closeCurrentBalloon() {
		let close = document.querySelector('ymaps[class$="-balloon__close-button"]')
		if (close != null) {
			close.click()
		}
	}
	const [inputValue, setInputValue] = React.useState('')

	const isSmallScreen = useMediaQuery('(min-width:700px)')

	const currentInput = React.useRef(null)
	// const handleTouchStart = () => {
	// currentInput.current.ref.current.blur()
	// }
	// console.log(allCountries, );
	return (
		<Autocomplete
			inputValue={inputValue}
			blurOnSelect='touch'
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			PaperComponent={props => (
				<Paper
					elevation={4}
					{...props}
					// onTouchStart={handleTouchStart}
				/>
			)}
			onKeyDown={event => {
				if (event.key === 'Enter' && !isSmallScreen) {
					// Prevent's default 'Enter' behavior.
					event.defaultMuiPrevented = true
					const activeCountry = countries.find(
						country => country.name.toLowerCase() === inputValue.toLowerCase()
					)
					if (activeCountry) {
						actions.setCountry(activeCountry)
						currentInput.current.ref.current.blur()
					} else currentInput.current.ref.current.blur()
				} else return
			}}
			popupIcon={null}
			freeSolo={!isSmallScreen}
			selectOnFocus={false}
			size={isSmallScreen ? 'medium' : 'small'}
			noOptionsText={'Совпадений не найдено'}
			value={activeCountry}
			onChange={(event, newValue) => {
				actions.setCountry(newValue)
			}}
			disableClearable={!isSmallScreen}
			id='country-select-demo'
			sx={{ width: 300 }}
			options={openBtn ? countries : allCountries}
			ListboxProps={{
				className: 'myCustomList',
				style: {
					maxHeight: isSmallScreen ? '190px' : '244px'
				}
			}}
			getOptionLabel={option => option.name || ''}
			renderOption={(props, option) => (
				<Box
					component='li'
					sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
					{...props}>
					<img
						loading='lazy'
						width='15'
						src={`${BASE_IMAGE_URL}/${option.id}/${option.countryFlagFileName}`}
						alt=''
					/>
					{option.name}
				</Box>
			)}
			renderInput={params => {
				const { inputProps } = params
				currentInput.current = inputProps
				return (
					<TextField
						onClick={closeCurrentBalloon}
						variant='outlined'
						{...params}
						label='Найти страну'
						InputProps={{
							...params.InputProps,
							type: isSmallScreen ? 'text' : 'search'
						}}
					/>
				)
			}}
		/>
	)
}

export const arrivalCountriesMap = [
	'Абхазия',
	'Австралия',
	'Азербайджан',
	'Аргентина',
	'Армения',
	'Бахрейн',
	'Беларусь',
	'Бразилия',
	'Венгрия',
	'Вьетнам',
	'Греция',
	'Грузия',
	'Доминиканская Республика',
	'Египет',
	'Израиль',
	'Индия',
	'Индонезия',
	'Испания',
	'Италия',
	'Казахстан',
	'Камбоджа',
	'Катар',
	'Кения',
	'Кипр',
	'Киргизия',
	'Китай',
	'Колумбия',
	'Круизы',
	'Куба',
	'Маврикий',
	'Малайзия',
	'Мальдивы',
	'Марокко',
	'Мексика',
	'Намибия',
	'ОАЭ',
	'Оман',
	'Португалия',
	'Пуэрто-Рико',
	'Россия',
	'Сейшелы',
	'Сербия',
	'Сингапур',
	'Таиланд',
	'Танзания',
	'Турция',
	'Узбекистан',
	'Филиппины',
	'Хорватия',
	'Черногория',
	'Шри-Ланка',
	'Южная Африка',
	'Япония'
]
