import React from 'react';
import { SvgIcon } from "@mui/material";

function Plane(props) {
    return <SvgIcon {...props} viewBox="0 0 16 17" fill="none" style={{ width: "16px", height: "17px", fill: '#ffffff' }} >
        <g clipPath="url(#clip0)">
            <path d="M15.4442 8.77201C15.5182 8.57978 15.532 8.3696 15.4838 8.16934C15.4356 7.96907 15.3277 7.78819 15.1744 7.65067L13.3186 5.95648C13.1194 5.77518 12.8836 5.63877 12.6271 5.55648C12.3706 5.47419 12.0994 5.44794 11.8319 5.4795L2.90746 6.42923L1.18956 5.34085C1.05103 5.25411 0.888687 5.21323 0.725595 5.22401C0.562504 5.2348 0.406956 5.29669 0.281045 5.40091C0.155133 5.50513 0.0652605 5.64637 0.024191 5.80457C-0.0168782 5.96278 -0.00705633 6.1299 0.0522625 6.2822L1.04111 8.75041C1.10575 9.14015 1.27985 9.17308 6.00624 9.29006L4.37375 12.8953C4.32564 12.9915 4.30584 13.0995 4.31665 13.2066C4.32747 13.3137 4.36845 13.4155 4.43485 13.5002C4.48287 13.5607 4.54217 13.6114 4.60946 13.6493C4.7465 13.7229 4.90557 13.7445 5.05725 13.7099L6.37834 13.4202C6.5106 13.3901 6.62977 13.3185 6.71841 13.2158L9.99902 9.36489C11.7097 9.38702 13.3266 9.41289 14.4856 9.42065C14.6924 9.42296 14.895 9.3621 15.0663 9.2462C15.2376 9.13029 15.3694 8.96486 15.4442 8.77201ZM14.4909 8.58367C14.461 8.58346 14.4308 8.58325 14.4003 8.58303C13.2051 8.57464 11.561 8.56308 9.81348 8.52395L9.61694 8.52152L6.12468 12.622L5.3123 12.7999L7.27624 8.47843L6.65252 8.44661C3.22023 8.39606 2.11236 8.31645 1.7402 8.27538L0.896109 6.14179L2.68948 7.28341L11.9371 6.31749C12.0837 6.30004 12.2324 6.31376 12.3734 6.35773C12.5144 6.40169 12.6445 6.47493 12.7553 6.57265L14.6101 8.25179C14.6393 8.27655 14.6598 8.31 14.6687 8.34726C14.6776 8.38452 14.6743 8.42364 14.6594 8.45892C14.6485 8.49509 14.6262 8.52675 14.5958 8.54923C14.5654 8.5717 14.5286 8.58378 14.4909 8.58367Z" />
            <path d="M14.4909 8.58367C14.5286 8.58378 14.5654 8.5717 14.5958 8.54923C14.6262 8.52675 14.6485 8.49509 14.6594 8.45892C14.6743 8.42364 14.6776 8.38452 14.6687 8.34726C14.6598 8.31 14.6393 8.27655 14.6101 8.25179L12.7553 6.57265C12.6445 6.47493 12.5144 6.40169 12.3734 6.35773C12.2324 6.31376 12.0837 6.30004 11.9371 6.31749L2.68948 7.28341L0.896108 6.14179L1.7402 8.27538C2.11236 8.31645 3.22023 8.39606 6.65252 8.44661L7.27624 8.47843L5.3123 12.7999L6.12468 12.622L9.61694 8.52152L9.81348 8.52395C11.561 8.56308 13.2051 8.57464 14.4003 8.58303M14.4909 8.58367L14.4003 8.58303M14.4909 8.58367C14.461 8.58346 14.4308 8.58325 14.4003 8.58303M15.4442 8.77201C15.5182 8.57978 15.532 8.3696 15.4838 8.16934C15.4356 7.96907 15.3277 7.78819 15.1744 7.65067L13.3186 5.95648C13.1194 5.77518 12.8836 5.63877 12.6271 5.55648C12.3706 5.47419 12.0994 5.44794 11.8319 5.4795L2.90746 6.42923L1.18956 5.34085C1.05103 5.25411 0.888687 5.21323 0.725595 5.22401C0.562504 5.2348 0.406956 5.29669 0.281045 5.40091C0.155133 5.50513 0.0652605 5.64637 0.0241911 5.80457C-0.0168783 5.96278 -0.0070563 6.1299 0.0522625 6.2822L1.04111 8.75041C1.10575 9.14015 1.27985 9.17308 6.00624 9.29006L4.37375 12.8953C4.32564 12.9915 4.30584 13.0995 4.31665 13.2066C4.32747 13.3137 4.36845 13.4155 4.43485 13.5002C4.48287 13.5607 4.54217 13.6114 4.60946 13.6493C4.7465 13.7229 4.90557 13.7445 5.05725 13.7099L6.37834 13.4202C6.5106 13.3901 6.62977 13.3185 6.71841 13.2158L9.99902 9.36489C11.7097 9.38702 13.3266 9.41289 14.4856 9.42065C14.6924 9.42296 14.895 9.3621 15.0663 9.2462C15.2376 9.13029 15.3694 8.96486 15.4442 8.77201Z" stroke="#737373" strokeWidth="0.5" />
            <path d="M4.11162 4.34361L5.19157 5.44989L6.03415 5.36185L4.82833 4.10565L5.71002 4.08365L7.72404 5.1692L8.90593 5.04534L6.0424 3.50795C5.95648 3.45194 5.85597 3.42246 5.75341 3.42317L4.4813 3.42735C4.37565 3.42854 4.27271 3.46097 4.18546 3.52057C4.09821 3.58017 4.03055 3.66426 3.99102 3.76224C3.95148 3.86023 3.94184 3.96772 3.9633 4.07118C3.98476 4.17464 4.03637 4.26943 4.11162 4.34361Z" stroke="#737373" strokeWidth="0.5" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </SvgIcon>
}
export default Plane