import React, { useEffect, useState } from 'react'
import { Alert, tooltipClasses, Checkbox, FormControlLabel } from '@mui/material'
import Switch from '@material-ui/core/Switch'

import './style.css'
import CustomSelect from '../../../../../../components/Select'
import { Box, Snackbar, TextField } from '@material-ui/core'
import useBookingStore from '../../../../store'
import BirthDateInput from '../../../../../../components/BirthDateInput'
import {
	emailRegExp,
	latinNameRegExp,
	phoneRegExp,
	interPassportNumberRegExp,
	cyrillicRegExp,
	seriesBirthCertificatesRegExp,
	rfPassportSeriesRegExp,
	interPassportSeriesRegExp,
	rfPassportNumberRegExp,
	birthCertNumberRegExp,
	latinUpperAndNumberRegExp,
	latinAndNumberRegExp
} from '../../../../../../constants/reg-exps'
import { useErrorStore } from './store'
import { nationality } from './nationalities'
import useLoyaltyStore from '../../../../../Loyalty/store'
import RegistrationForm from '../../../../../Loyalty/components/RegistrationForm'
import LoyaltyForm from '../../../../../Loyalty/components/LoyaltyForm'
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { transliterateToUppercase } from '../../../../../../functions/convertLang'
import MembersBlockModal from './MembersBlockModal'
import { isEmpty } from "lodash";
import { format } from "date-fns";
import VisaSelect from "./VisaSelect";

const LightTooltip = styled(({ className, color, children, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }}>{children}</Tooltip>
))(({ theme, color }) => ({
	[`& .${tooltipClasses.tooltipArrow}`]: {
		backgroundColor: color ? 'red' : 'white',
		color: color ? 'white' : 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[7],
		fontSize: 14,
		padding: 10,
		maxWidth: 500
	},
	"& .MuiTooltip-arrow": {
		transform: 'translate(20px, 0px)!important',
		color: color ? 'red' : 'white'
	},
	color
}));

const MembersBlock = ({ count, title, membersCount }) => {
	const paxe = useBookingStore(state =>
		state.paxes.find(item => item.id === count)
	)
	const response = useBookingStore(({ response }) => response)
	const visas = useBookingStore(({ visas }) => visas)
	const [gender, setGender] = React.useState('0')
	const [nation, setNation] = React.useState(false)
	const [typeDoc, setTypeDoc] = React.useState(paxe.document.type || 5)
	const [visa, setVisa] = React.useState(paxe.selectedVisaId || -1)

	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const visaOptions = response?.tour?.visaOptions.map(item => {
		return {
			id: item.id,
			name: `${item.type.name.split(':')[1]} ${visas?.find(visa => visa.visaId === item.id) ? ' (подача документов до ' + format(new Date(visas?.find(visa => visa.visaId === item.id).deadlineDate), 'dd.MM.yyyy') + ')' : ''}`
		}
	})

	const isRussianPassportEnabled = [233909, 325801, 210357, 266539].includes(
		response?.tour?.hotel?.country?.id
	)

	const actions = useBookingStore(({ actions }) => actions)

	const applyContactsForAll = useBookingStore(({applyContactsForAll}) => applyContactsForAll)

	const handleChangeApplyContactsForAll = (event) => {
		if (event.target.checked) {
			actions.changeApplyContactsForAll(count)
		} else {
			actions.changeApplyContactsForAll(false)
		}
	}

	const setErrorForm = useErrorStore(state => state.setErrorForm)
	const [errors, setErrors] = useState({
		lastName: null,
		firstName: null,
		lastNameLatin: null,
		firstNameLatin: null,
		patronymic: null,
		phone: null,
		email: null,
		series: null,
		number: null
	})

	const [phoneErrorGds, setPhoneErrorGds] = useState(false)
	const [openTooltip, setOpenTooltip] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)

	const changeFirstNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeFirstNameLatin(event.target.value, count)
	}
	const changeLastNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (
			!latinNameRegExp.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeLastNameLatin(event.target.value, count)
	}

	const changeFirstNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeFirstName(event.target.value, count)
	}
	const changeLastNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeLastName(event.target.value, count)
	}
	const changePatronymicHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Отчество должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changePatronymic(event.target.value, count)
	}

	const changePhoneHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		setError(null)
		setPhoneErrorGds(false)
		actionsPL.setMemberId(null)
		actions.setPhoneError(false)
		if (event.target.value.includes('+')) {
			setPhoneErrorGds(true)
			actions.setPhoneError(true)
			actions.changePhone(event.target.value, count)
			return
		}
		if (!phoneRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Номер телефона указан некорректно`
			})
			setErrorForm(true)
			actions.setPhoneError(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
			actions.setPhoneError(false)
		}
		actions.changePhone(event.target.value, count)
	}
	const changeEmailHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		actionsPL.setMemberId(null)
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Email указан некорректно`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeEmail(event.target.value, count)
	}

	const changeNationHandler = (e, n) => {
		if (e !== 210357) {
			setModalVisible(true)
		}
		setNation(e)
		actions.changeNationality(e, n)
	}
	const changeGenderHandler = (e, n) => {
		setGender(e)
		actions.changeGender(Number(e), n)
	}
	const changeTypeDocHandler = (e, n) => {
		setTypeDoc(e)
		actions.changeTypeDocument(e, n)
	}

	const changeVisaHandler = (e, n) => {
		setVisa(e)
		actions.changeVisa(e, n)
	}

	const changeSeriesHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 1:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				}
				break
			case 2:
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 4 цифры`
					})
					setErrorForm(true)
				}
				break
			case 3:
				if (!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать римское число и две кириллические буквы`
					})
					setErrorForm(true)
				}
				break
			case 4:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorForm(true)
				}
				break
			//   case 5:
			//     if (!interPassportSeriesRegExp.test(value) && value !== "") {
			//       setErrors({
			//         ...errors,
			//         [prop]: `Серия не более 2 цифры`,
			//       });
			//       setErrorForm(true);
			//     }
			//     break;
			case 6:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				}
				break
			case 7:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorForm(true)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}

		actions.changeSeries(value, count)
	}

	const changeNumberHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 1:
				if (!interPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 7 цифр`
					})
					setErrorForm(true)
				}
				break
			case 2:
				if (!rfPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				}
				break
			case 3:
				if (!birthCertNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				}
				break
			case 4:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			//   case 5:
			//     if (!latinAndNumberRegExp.test(value) && value !== "") {
			//       setErrors({
			//         ...errors,
			//         [prop]: `Номер должен содержать только цифры и прописные латинские буквы`,
			//       });
			//       setErrorForm(true);
			//     }
			//     break;
			case 6:
				if (!latinAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			case 7:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorForm(true)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}

		actions.changeNumber(value, count)
	}

	const memberId = useLoyaltyStore(({ memberId }) => memberId)
	const isMember = useLoyaltyStore(({ isMember }) => isMember)
	const client = useLoyaltyStore(({ client }) => client)
	const actionsPL = useLoyaltyStore(({ actions }) => actions)

	const handleChangePL = event => {
		actionsPL.applyPromoCode('')
		if (event.target.checked) {
			actionsPL.setMemberId(Number(event.target.value))
			if (!paxe.person.phone) {
				setError('Необходимо указать номер телефона туриста')
				actionsPL.setMemberId(null)
				return
			}
			if (errors.phone) {
				setError(errors.phone)
				actionsPL.setMemberId(null)
				return
			}
			actionsPL.checkLoyalty(paxe.person.phone)
		} else {
			actionsPL.setMemberId(null)
		}
	}

	const [error, setError] = useState(null)

	useEffect(() => {
		const checkValidationError = paxe => {
			if (
				!latinNameRegExp.test(paxe.person.lastNameLatin) &&
				!!paxe.person.lastNameLatin
			) {
				setErrors(errors => ({
					...errors,
					lastNameLatin: `Фамилия должна содержать только латиницу`
				}))
				setErrorForm(true)
			}
			if (
				!latinNameRegExp.test(paxe.person.firstNameLatin) &&
				!!paxe.person.firstNameLatin
			) {
				setErrors(errors => ({
					...errors,
					firstNameLatin: `Имя должно содержать только латиницу`
				}))
				setErrorForm(true)
			}
			if (
				!cyrillicRegExp.test(paxe.person.lastName) &&
				!!paxe.person.lastName
			) {
				setErrors(errors => ({
					...errors,
					lastName: `Фамилия должна содержать только кириллицу`
				}))
				setErrorForm(true)
			}
			if (!phoneRegExp.test(paxe.person.phone) && !!paxe.person.phone) {
				setErrors(errors => ({
					...errors,
					phone: `Номер телефона указан некорректно`
				}))
				setErrorForm(true)
			}
			if (!emailRegExp.test(paxe.person.email) && !!paxe.person.email) {
				setErrors(errors => ({
					...errors,
					email: `Email указан некорректно`
				}))
				setErrorForm(true)
			}
			if (
				!cyrillicRegExp.test(paxe.person.patronymic) &&
				!!paxe.person.patronymic
			) {
				setErrors(errors => ({
					...errors,
					patronymic: `Отчество должно содержать только кириллицу`
				}))
				setErrorForm(true)
			}

			switch (typeDoc) {
				case 1:
					if (
						!interPassportSeriesRegExp.test(paxe.document.series) &&
						!!paxe.document.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 2:
					if (
						!rfPassportSeriesRegExp.test(paxe.document.series) &&
						!!paxe.document.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 4 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 3:
					if (
						!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(paxe.document.series) &&
						!!paxe.document.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать римское число и две кириллические буквы`
						}))
						setErrorForm(true)
					}
					break
				case 4:
					if (paxe.document.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
						setErrorForm(true)
					}
					break
				case 6:
					if (
						!interPassportSeriesRegExp.test(paxe.document.series) &&
						!!paxe.document.series
					) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (paxe.document.series.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, series: null }))
					break
			}

			switch (typeDoc) {
				case 1:
					if (
						!interPassportNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 7 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 2:
					if (
						!rfPassportNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 3:
					if (
						!birthCertNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
					}
					break
				case 4:
					if (
						!latinUpperAndNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				case 6:
					if (
						!latinAndNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (
						!latinUpperAndNumberRegExp.test(paxe.document.number) &&
						!!paxe.document.number
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, number: null }))
					break
			}
		}
		checkValidationError(paxe)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onBlur = (prop, event) => {
		const upperCaseValue = event.target.value.toUpperCase()
		switch (prop) {
			case "firstNameLatin":
				changeFirstNameLatinHandler('firstNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(upperCaseValue) } })
				break;
			case "lastNameLatin":
				changeLastNameLatinHandler('lastNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(upperCaseValue) } })
				break;
			case "firstName":
				changeFirstNameHandler('firstName')({ ...event, target: { ...event.target, value: upperCaseValue } })
				changeFirstNameLatinHandler('firstNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(upperCaseValue) } })
				break;
			case "lastName":
				changeLastNameHandler('lastName')({ ...event, target: { ...event.target, value: upperCaseValue } })
				changeLastNameLatinHandler('lastNameLatin')({ ...event, target: { ...event.target, value: transliterateToUppercase(upperCaseValue) } })
				break;
			case "patronymic":
				changePatronymicHandler('patronymic')({ ...event, target: { ...event.target, value: upperCaseValue } })
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Snackbar
				open={error}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert
					onClose={() => setError(null)}
					severity='error'
					sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
			<div className='block-header'>
				<h3 className='title'>
					<span>{title ? title : `Информация о туристе ${count}`}</span>
					{agentInfo && agentInfo.agents?.name.includes('F&S') && <span className={'switcher-pl'}>
						Участник программы лояльности{' '}
						<Switch
							checked={memberId === count}
							value={count}
							onChange={handleChangePL}
							color='primary'
						/>
					</span>}
				</h3>
			</div>
			<Alert severity='error' sx={{ width: '100%' }}>
				Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
				информации о туристе!
			</Alert>
			<div className='members-form'>
				{isRussianPassportEnabled || (client && memberId === count) ? (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={paxe.person.gender || '1'}
								options={[
									{ id: '1', name: 'Муж.' },
									{ id: '2', name: 'Жен.' }
								]}
								title='Пол'
								count={count}
								// onChange={actions.changeGender}
								onChange={changeGenderHandler}
								required
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-латински'
								variant='outlined'
								fullWidth
								value={paxe.person.lastNameLatin || ''}
								onChange={changeLastNameLatinHandler('lastNameLatin')}
								helperText={errors.lastNameLatin}
								error={!!errors.lastNameLatin}
								onBlur={(event) => onBlur('lastNameLatin', event)}
								required
								title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						{/* </FormControl> */}
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-латински'
								variant='outlined'
								fullWidth
								value={paxe.person.firstNameLatin || ''}
								onChange={changeFirstNameLatinHandler('firstNameLatin')}
								helperText={errors.firstNameLatin}
								error={!!errors.firstNameLatin}
								onBlur={(event) => onBlur('firstNameLatin', event)}
								required
								title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-русски'
								variant='outlined'
								fullWidth
								value={paxe.person.lastName || ''}
								onChange={changeLastNameHandler('lastName')}
								helperText={errors.lastName}
								error={!!errors.lastName}
								onBlur={(event) => !errors.lastName && onBlur('lastName', event)}
								required
							// title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-русски'
								variant='outlined'
								fullWidth
								value={paxe.person.firstName || ''}
								onChange={changeFirstNameHandler('firstName')}
								helperText={errors.firstName}
								error={!!errors.firstName}
								onBlur={(event) => !errors.firstName && onBlur('firstName', event)}
								required
							// title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Отчество по-русски'
								variant='outlined'
								fullWidth
								value={paxe.person.patronymic || ''}
								onChange={changePatronymicHandler('patronymic')}
								helperText={errors.patronymic}
								error={!!errors.patronymic}
								onBlur={(event) => !errors.patronymic && onBlur('patronymic', event)}
							// required
							// title='В о допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInput
								onChange={actions.changeBirthDate}
								count={count}
								label='Дата рождения'
								value={paxe.person.birthDate ? new Date(paxe.person.birthDate) : null}
								required
							/>
						</Box>
						{(!applyContactsForAll || applyContactsForAll === count) && <> 
							<Box style={{ marginBottom: 15 }}>
								<LightTooltip
									open={phoneErrorGds || openTooltip}
									color={phoneErrorGds}
									onFocus={() => setOpenTooltip(true)}
									onBlur={() => setOpenTooltip(false)}
									title={
										<>
											{phoneErrorGds &&
												<div style={{ whiteSpace: 'nowrap' }}>
													Телефон в формате код страны + код оператора/города + номер
												</div>
											}
											<div>"+" не указывать, пример 79031111111</div>
										</>
									}
									arrow
									disableHoverListener
									placement="top-start"
								>
									<TextField
										label='Телефон'
										variant='outlined'
										fullWidth
										helperText={errors.phone}
										error={!!errors.phone}
										value={paxe.person.phone || ''}
										onChange={changePhoneHandler('phone')}
										required
									/>
								</LightTooltip>
							</Box>
							<Box>
								<TextField
									label='E-Mail'
									variant='outlined'
									fullWidth
									helperText={errors.email}
									error={!!errors.email}
									value={paxe.person.email || ''}
									onChange={changeEmailHandler('email')}
									required
								/>
							</Box>
						</>}
					</div>
				) : (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={paxe.person.gender || '1'}
								options={[
									{ id: '1', name: 'Муж.' },
									{ id: '2', name: 'Жен.' }
								]}
								title='Пол'
								count={count}
								// onChange={actions.changeGender}
								onChange={changeGenderHandler}
								required
								isSex={true}
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-латински'
								variant='outlined'
								fullWidth
								value={paxe.person.lastNameLatin || ''}
								onChange={changeLastNameLatinHandler('lastNameLatin')}
								helperText={errors.lastNameLatin}
								onBlur={(event) => onBlur('lastNameLatin', event)}
								error={!!errors.lastNameLatin}
								required
								title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						{/* </FormControl> */}
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-латински'
								variant='outlined'
								fullWidth
								value={paxe.person.firstNameLatin || ''}
								onChange={changeFirstNameLatinHandler('firstNameLatin')}
								helperText={errors.firstNameLatin}
								error={!!errors.firstNameLatin}
								onBlur={(event) => onBlur('firstNameLatin', event)}
								required
								title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInput
								onChange={actions.changeBirthDate}
								count={count}
								label='Дата рождения'
								value={paxe.person.birthDate ? new Date(paxe.person.birthDate) : null}
								required
							/>
						</Box>
						{(!applyContactsForAll || applyContactsForAll === count) && <> 
							<Box style={{ marginBottom: 15 }}>
								<LightTooltip
									open={phoneErrorGds || openTooltip}
									color={phoneErrorGds}
									onFocus={() => setOpenTooltip(true)}
									onBlur={() => setOpenTooltip(false)}
									title={
										<>
											{phoneErrorGds &&
												<div style={{ whiteSpace: 'nowrap' }}>
													Телефон в формате код страны + код оператора/города + номер
												</div>
											}
											<div>"+" не указывать, пример 79031111111</div>
										</>
									}
									arrow
									disableHoverListener
									placement="top-start"
								>
									<TextField
										label='Телефон'
										variant='outlined'
										fullWidth
										helperText={errors.phone}
										error={!!errors.phone}
										value={paxe.person.phone || ''}
										onChange={changePhoneHandler('phone')}
										required
									/>
								</LightTooltip>
							</Box>
							<Box>
								<TextField
									label='E-Mail'
									variant='outlined'
									fullWidth
									helperText={errors.email}
									error={!!errors.email}
									value={paxe.person.email || ''}
									onChange={changeEmailHandler('email')}
									required
								/>
							</Box>
						</>}
					</div>
				)}
				<div className='docs'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={nation || paxe.person.nationality || '210357'}
							options={nationality}
							title='Гражданство'
							count={count}
							// onChange={actions.changeNationality}
							onChange={changeNationHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={typeDoc || paxe.document.type || 5}
							options={[
								{ id: 5, name: 'Иной документ' },
								{ id: 2, name: 'Паспорт' },
								{ id: 3, name: 'Свидетельство о рождении' },
								{ id: 1, name: 'Заграничный паспорт' },
								{ id: 4, name: 'ID карта' }
								// { id: 6, name: 'Дипломатический паспорт' },
								// { id: 7, name: 'Служебный паспорт' },
							]}
							title='Тип документа'
							count={count}
							// onChange={actions.changeTypeDocument}
							onChange={changeTypeDocHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Серия документа'
							variant='outlined'
							fullWidth
							helperText={errors.series}
							error={!!errors.series}
							value={paxe.document.series || ''}
							onChange={changeSeriesHandler('series')}
							required
							title='Серия паспорта может содержать только цифры и прописные латинские буквы'
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер документа'
							variant='outlined'
							fullWidth
							helperText={errors.number}
							error={!!errors.number}
							value={paxe.document.number || ''}
							onChange={changeNumberHandler('number')}
							required
							title='Номер паспорта должен содержать только цифры и заглавные латинские буквы'
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={actions.changeExpirationDate}
							count={count}
							label='Действителен до'
							value={paxe.document.expirationDate ? new Date(paxe.document.expirationDate) : null}
							isExpiration={true}
							required
						/>
					</Box>
					{!isEmpty(visaOptions) && <Box style={{ marginBottom: 15 }}>
						<VisaSelect
							value={visa || paxe.selectedVisaId || -1}
							options={visaOptions}
							title='Виза'
							count={count}
							onChange={changeVisaHandler}
							required
						/>
					</Box>}
					{((!applyContactsForAll || applyContactsForAll === count) && membersCount !== 1) && <Box>
						<FormControlLabel 
							control={
								<Checkbox checked={applyContactsForAll === count} onChange={handleChangeApplyContactsForAll}  />
							} 
							label="Использовать контакты для всех туристов" />
					 </Box>}
				</div>
			</div>
			{memberId === count && isMember === 'NotFound' && (
				<RegistrationForm count={count} />
			)}
			{client && memberId === count && <LoyaltyForm client={client} />}
			{modalVisible && <MembersBlockModal
				title='Сообщение'
				onClose={setModalVisible}
			/>}
		</>
	)
}

export default MembersBlock
