import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'
// import {format} from "date-fns";

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const usePlanStore = create(
	devtools((set, get) => ({
		error: false,
		department: 'План',
		// timePeriod: '',
		token: JSON.parse(localStorage.getItem('token'))?.access_token,
		loading: false,
		actions: {
			uploadPlan: async files => {
				set({ loading: true })
				set({ error: false })

				const formData = new FormData()
				// formData.append('StartPeriodDate', format(new Date(get().timePeriod[0]), 'yyyy-MM-dd'))
				// formData.append('EndPeriodDate', format(new Date(get().timePeriod[1]), 'yyyy-MM-dd'))
				// formData.append('ReportDocumentType', "1")

				for (let i = 0; i < files.length; i++) {
					formData.append('File', files[i])
				}

				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/sales/plan/upload`,
						method: 'POST',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${get().token}`
						}
					})
					if (res.status === 200) {
						set({ error: res.status })
					}
				} catch (e) {
					console.log(e, 'e')
					set({ error: e?.message })
				} finally {
					set({ loading: false })
					set({ department: '' })
					// set({ timePeriod: '' })
				}
			},
			setNewParams: (key, value) => {
				set({
					[key]: value
				})
			},
		}
	}))
)

export default usePlanStore
