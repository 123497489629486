import React from 'react';

const Legend = () => {
	return (
		<div className={'boarding-legend'}>
			<h3>Места</h3>
			<span className="pass icon">&nbsp;</span>
			<span className="pass text"> - проход</span>
			<span className=" available icon">&nbsp;</span>
			<span className=" available text"> - свободно</span>
			<span className=" occupied  icon">&nbsp;</span>
			<span className=" occupied  text"> - занято</span>
			<span className=" selected  icon">&nbsp;</span>
			<span className=" selected  text"> - выбрано</span>
			<span className=" otherclass  icon">&nbsp;</span>
			<span className="legend otherclass  text"> - места другого класса</span>
			<span className=" emergency_seat  icon">&nbsp;</span>
			<span className=" emergency_seat  text"> - свободно с ограничениями</span>
		</div>
	);
};

export default Legend;