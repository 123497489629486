import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'
import { uniqBy } from "lodash";
import { getClassName } from './helper';

const ForwardFlightSelect = ({ options, title, onChange, value }) => {

	const uniqOptions = uniqBy(options, 'forwardFlight.id')

	return (
		<FormControl variant="outlined" style={{ width: '100%' }}>
			<InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				label={title}
				className="custom-select"
			>
				{uniqOptions.map((item) =>
					<MenuItem
						key={item.key}
						value={item.forwardFlight.id.toString()}
					>
						[{getClassName(item.forwardFlight.segments[0].class.id)}] (Места: есть) {
							item.forwardFlight.segments[0].flightNumber
						} ({
							item.forwardFlight.segments[0].startWayPoint.city.name
						} {
							item.forwardFlight.segments[0].startWayPoint.port.alias
						} {
							item.forwardFlight.segments[0].startTime.slice(0, -3)
						} - {
							item.forwardFlight.segments[0].endWayPoint.city.name
						} {
							item.forwardFlight.segments[0].endWayPoint.port.alias
						} {
							item.forwardFlight.segments[0].endTime.slice(0, -3)
						})
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default ForwardFlightSelect;