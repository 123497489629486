import styled from 'styled-components';
import {groupBy, isEmpty, orderBy, pick} from "lodash";

import Seat from './Seat';
import {useState} from "react";
import useClaimsStore from "../../../../store";
import CircularProgress from "@mui/material/CircularProgress";

const ScrollBar = styled.div`
overflow-y: auto;
border: 1px solid rgba(219, 224, 229, 1);
border-radius: 12px;
max-height: 436px;
    &::-webkit-scrollbar {
    width: 4px;
    }
    &::-webkit-scrollbar-thumb { 
        border-radius: 4px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);    
        }
`

const BoardingWrapper = styled.div`
    padding:16px;
    display: flex;
    gap: 8px;
`

function SeatGrid({ boarding, claim, isBackFlight = false }) {
    const sortedSeats = orderBy(boarding, 'row', 'desc')
    const seats = groupBy(sortedSeats, 'row')
    const boardingPeoples = useClaimsStore(({ boardingPeoples }) => boardingPeoples)

    const isColumnEmpty = (column) => column.every(item => !item.cellName)

    const getColorForPlace = (place) => {
        if (boardingPeoples?.find(seat => seat.pPlacementName && seat.pPlacementName === place.cellName)) return '#bf0000'
        if (!place.available) return '#a9a9a9'
        if (!place.onlineEnabled && place.frPlacementTypeInc !== 7) return '#a9a9a9'
        if (place.frPlacementTypeInc === 8) return 'navy'
        if (place.noAddInfant) return '#52b567'
        switch (place.frPlacementTypeInc) {
            case 1:
                return "lightgreen"
            case 7:
                return "#fff"
            case 8:
                return 'navy'
            default:
                return "#fff"
        }
    }

    if (isEmpty(boarding)) return <CircularProgress />

    return <ScrollBar>
        <BoardingWrapper>
            {Object.keys(seats).reverse().map(row => {
                const letters = [...new Set(
                    seats[row]
                        .map(item => item.cellName ? item.cellName.match(/[A-Z]/)[0] : undefined)
                        .filter(Boolean)
                )];
                const isEmptyColumn = isColumnEmpty(seats[row])

                return <div key={row}>
                    <Seat
                        letters={letters[0] ?? " "}
                    />
                    {seats[row].map((cols, index) => {
                        const colName = isEmptyColumn ? seats[Number(row) + 1] ? seats[Number(row) + 1][index]?.cellName : '' : null
                        return <Seat
                            key={cols.key}
                            item={cols}
                            isEmptyCol={isEmptyColumn}
                            colName={colName}
                            claimId={claim?.id}
                            color={getColorForPlace(cols)}
                            isLastCol={(Number(row) === Object.keys(seats).length - 1) || (Number(row) !== 0)}
                        />
                    }
                    )}
                </div>
            }
            )}

        </BoardingWrapper>
    </ScrollBar>
}

export default SeatGrid