import React, {useState} from 'react';
import styled from 'styled-components';
import { Button, Field } from '../ui';

import { useSelector, useDispatch } from 'react-redux';
import {
  getDetailOrder,
  promocodeApply,
  promocodeApplySuccess,
} from '../../store/modules/orders';

const Container = styled.div`
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;

  position: absolute;
  top: 40px;
  width: max-content;
  max-width: 300px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Margin = styled.div`
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledField = styled(Field)`
  width: 185px;
  height: 40px;
`;

const StyledButton = styled(Button)`
  min-width: 130px;
  width: 130px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  margin-left: 15px;
`;

const PromoCode = () => {
  const dispatch = useDispatch();
  const [promocodeValue, setPromocodeValue] = useState('');
  const { id, orderAmount } = useSelector(getDetailOrder);
  const promocodeStatus = useSelector(state => state.orders.orderDetail.promocodeApplyStatus);
  const isPromocodeApplied = orderAmount.detalization.some(x => x.priceType === 20);

  return (
    <Container>
      <Row>
        <Column>
          <StyledField
            placeholder="% Промокод"
            value={promocodeValue}
            isSuccess={promocodeStatus.success}
            isError={promocodeStatus.error}
            onChange={(e) => {
              setPromocodeValue(e.target.value);
              dispatch(promocodeApplySuccess(false));
            }}
          />
          {promocodeStatus?.message && promocodeValue !== '' && (
            <>
              <Label style={{ color: promocodeStatus.success ? '#21A049' : '#e73c3e' }}>{promocodeStatus.message} </Label>
              <Margin />
            </>
          )}
        </Column>

        <StyledButton
          type={promocodeStatus.success || isPromocodeApplied ? 'outlined' : ''}
          disabled={promocodeStatus.success || isPromocodeApplied}
          onClick={() => dispatch(promocodeApply({orderId: id, promocode: promocodeValue,}))}
        >
          {promocodeStatus.success || isPromocodeApplied ? 'Применен' : 'Применить'}
        </StyledButton>
      </Row>
    </Container>
  );
};

export default PromoCode;
