import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useContractsStore = create(
	devtools((set, get) => ({
		agentContracts: [],
		offices: [],
		actions: {	
			getAgentContracts: async () => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/documents/getagentcontracts`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						},
						params: {
							PartpassId: partpass,
						}
					})
					set({ agentContracts: res.data })
				} catch (e) {
					console.error('Error in getAgentContracts:', e?.response?.data);
				}
			},
			createPrintContractJob: async (idDogovor, type, owner) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const res = await axios({
						url: `${BASE_URL}/documents/createprintcontractjob`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						data: {
							partner: owner,
							inc: idDogovor,
							agreementYear: 2020,
							contractType: type,
							doccategory: 5,
							claim: 0,
							opeople: 0,
							visa: 0
						},
					});


					if (res.data.jobId) {
						const timeoutId = setTimeout(async () => {
							await get().actions.getContractByJobId(res.data.jobId)
							clearTimeout(timeoutId);
						}, 3000);
					}
				
				} catch (e) {
					console.error('Error in createPrintContractJob:', e?.response?.data);
				}
			},		
			
			getContractByJobId: async (id) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				try {
					const url = `${BASE_URL}/documents/getprintjobresult?jobId=${id}`
					const res = await axios({
						url,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						},
						responseType: 'blob',
					})

					if (res.status !== 200){
						return  res.status
					}  

						const link = document.createElement('a');
						link.href = window.URL.createObjectURL(res.data);
						link.download = 'contract.pdf';
 						document.body.appendChild(link);
						link.click();
						link.remove();
						window.URL.revokeObjectURL(url);
					
						return  res.status
				
				} catch (e) {
					console.error('Error in getContractByJobId:', e?.response?.data);
				}
			},
			getOfficeByAgency: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const id = JSON.parse(localStorage.getItem('agentInfo'))?.agents?.id
				try {
					const res = await axios({
						url: `https://geo.fstravel.com/api/offices/office-by-agency/${id}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ offices: res.data })
				} catch (e) {
					console.error('Error in getOfficeByAgency:', e?.response?.data);
				}
			},
		}
	}))
)

export default useContractsStore

