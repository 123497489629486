import Claims from "../pages/Cabinet/Claims";
import Agency from '../pages/Cabinet/AgentInfo/Agency'
import OrderDetail from '../pages/Avia/OrderDetail';
import OrderAppeals from '../pages/Avia/OrderAppeals';
import OrderHistory from '../pages/Avia/OrderHistory'
import AppealDetail from '../pages/Avia/AppealDetail'
import AppealHistory from '../pages/Avia/AppealHistory'
import AgentFinance from '../pages/Avia/AgentFinance';
import AgentHistory from "../pages/Avia/AgentHistory";
import AgentUsers from "../pages/Avia/AgentUsers";
import AgentFees from "../pages/Avia/AgentFees";
import AgentRules from '../pages/Avia/AgentRules'
import { AviaOrderTemplate,AviaAppealTemplate,AgentInfoTemplate,PassengersTemplate } from '../templates'
import AviaSaleRequest from "../pages/Cabinet/AviaSaleRequest";
import MyPassengers from "../pages/Cabinet/MyPassengers";
import CreatePassenger from "../pages/Cabinet/MyPassengers/components/CreatePassenger";
import EditPassenger from "../pages/Cabinet/MyPassengers/components/EditPassenger";


const routes = [
    {
        path: "profile",
        element: <Agency/>,
    },
    {
        path: "orders",
        element: <Claims/>,
    },
    {
        path: "orders/:orderNumber",
        element: <AviaOrderTemplate/>,
        children: [
            {
                path: "",
                element: <OrderDetail />,
            },
            {
                path: "history",
                element: <OrderHistory />,
            },
            {
                path: "tickets",
                element: <OrderAppeals />,
            },
        ]
    },
    {
        path: "tickets/:ticketNumber",
        element: <AviaAppealTemplate/>,
        children: [
            {
                path: "",
                element: <AppealDetail />,
            },
            {
                path: "history",
                element: <AppealHistory />,
            },
        ]
    },
    {
        path: "avia-agent-info",
        element: <AgentInfoTemplate/>,
        children: [
            {
                path: "",
                element: <AgentFinance />,
            },
            {
                path: "history",
                element: <AgentHistory />,
            },
            {
                path: "users",
                element: <AgentUsers />,
            },
            {
                path: "fees",
                element: <AgentFees />,
            },
            {
                path: "rules",
                element: <AgentRules />,
            },
            {
                path: "rules/:id",
                element: <AgentRules />,
            },
        ]
    },
    {
        path: "avia-sale-request",
        element: <AviaSaleRequest/>,
    },
    {
        path: "my-passengers",
        element: <PassengersTemplate />,
        children: [
            {
                path: "",
                element: <MyPassengers />,
            },
            {
                path: "create",
                element: <CreatePassenger />,
            },
            {
                path: "edit",
                element: <EditPassenger />,
            }
        ],
    },
];

export default routes;
