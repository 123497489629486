import * as React from 'react'
import PropTypes from 'prop-types'
import {
	Box,
	IconButton,
	Tab,
	Tabs,
	DialogTitle,
	styled,
	Divider
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import Description from '../../../../asset/images/hotel/Description.svg'
import Beach from '../../../../asset/images/hotel/Beach.svg'
import Information from '../../../../asset/images/hotel/Information.svg'
import Children from '../../../../asset/images/hotel/Children.svg'
import Meal from '../../../../asset/images/hotel/Meal.svg'
import Mountain from '../../../../asset/images/hotel/Mountain.svg'
import SPA from '../../../../asset/images/hotel/SPA.svg'
import Territory from '../../../../asset/images/hotel/Territory.svg'
import Conference from '../../../../asset/images/hotel/Сonference.svg'
import Location from '../../../../asset/images/hotel/Location.svg'
import HotelIcon from './HotelIcon'
import './style.css'
import useHotelDescriptionStore from '../../store'

export const PopupTabs = styled(Tabs)({
	padding: '32px',
	background: '#eff2f6',
	'& .MuiTabs-indicator': {
		backgroundColor: 'transparent'
	},
	'& .MuiTab-wrapper': {
		padding: '8px',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'center',
		textAlign: 'left'
	},
	'.MuiTab-wrapper:first-child': {}
})

export const PopupTab = styled(props => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		width: '206px',
		borderRadius: '8px',
		minWidth: 0,
		[theme.breakpoints.up('sm')]: {
			minWidth: 0
		},
		padding: 0,
		fontWeight: 600,
		fontSize: 16,
		lineHeight: '22px',
		marginBottom: '4px',
		minHeight: 0,
		color: '#7E8389',
		fontFamily: ['Open Sans', 'sans-serif'].join(','),
		'&:hover': {
			color: '#2E2E32',
			backgroundColor: 'white',
			opacity: 1
		},
		'&.Mui-selected': {
			color: '#2E2E32',
			backgroundColor: 'white',
			fontWeight: 600
		},
		'&.Mui-focusVisible': {
			backgroundColor: '#4872F2'
		}
	})
)

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			style={{ flexGrow: 1, color: '#2E2E32', width: '680px' }}
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 4 }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

export function ContentTitle(props) {
	const { children, onClose, ...other } = props

	return (
		<div
			style={{
				padding: 0,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: '28px'
			}}
			{...other}>
			<h2>{children}</h2>
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					size='small'
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500]
					}}>
					<Close />
				</IconButton>
			) : null}
		</div>
	)
}

DialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
}

const FilterBlocks = ({ id, array }) => {
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)
	const findElementById = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return array?.map(item => ({
			...item,
			name: tag?.find(elem => elem.id === item.id)?.name
		}))
	}
	// console.log(findElementById(id, array))
	return (
		<>
			<ul>
				{findElementById(id, array)?.map((item, i) => (
					<li key={i}>
						{item.payType === 'Paid' ? item.name + ' (платно)' : item.name}
					</li>
				))}
			</ul>
		</>
	)
}

export default function VerticalTabs({ handleClose }) {
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	const hotelDescText = useHotelDescriptionStore(
		({ hotelDescText }) => hotelDescText?.text
	)
	const checkTime = useHotelDescriptionStore(({ checkTime }) => checkTime)
	const comfort = useHotelDescriptionStore(({ comfort }) => comfort)
	const deposit = useHotelDescriptionStore(({ deposit }) => deposit)
	const cards = useHotelDescriptionStore(({ cards }) => cards)
	const restaurants = useHotelDescriptionStore(({ restaurants }) => restaurants)
	const forChildren = useHotelDescriptionStore(({ forChildren }) => forChildren)
	const beach = useHotelDescriptionStore(({ beach }) => beach)
	const distance = useHotelDescriptionStore(({ distance }) => distance)
	const territory = useHotelDescriptionStore(({ territory }) => territory)
	const waterEntertainment = useHotelDescriptionStore(
		({ waterEntertainment }) => waterEntertainment
	)
	const mealTypes = useHotelDescriptionStore(({ mealTypes }) => mealTypes)
	const locationHotel = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel
	)
	const thatAround = useHotelDescriptionStore(({ thatAround }) => thatAround)
	const addInfo = useHotelDescriptionStore(({ addInfo }) => addInfo)
	const roomType = useHotelDescriptionStore(({ roomType }) => roomType)
	const inRoom = useHotelDescriptionStore(({ inRoom }) => inRoom)
	const sport = useHotelDescriptionStore(({ sport }) => sport)
	const mountain = useHotelDescriptionStore(({ mountain }) => mountain)
	const language = useHotelDescriptionStore(({ language }) => language)
	const conferency = useHotelDescriptionStore(({ conferency }) => conferency)
	const spa = useHotelDescriptionStore(({ spa }) => spa)
	const healthy = useHotelDescriptionStore(({ healthy }) => healthy)
	const benefits = useHotelDescriptionStore(({ benefits }) => benefits)
	const hotelBlocks = useHotelDescriptionStore(({ blocks }) => blocks)

	//проверка, чтобы не отрисовывать FilterBlocks с пустыми значениями
	const isFilterBlocksEmpty = (id, array) => {
		const tag = hotelBlocks?.find(elem => elem.id === id)?.filters
		return !array?.every(item => tag.find(elem => elem.id === item.id))
	}

	const isEmpty = item => {
		if (
			!item ||
			(item?.filters?.length === 0 && item?.text === '') ||
			(item?.text === '' &&
				isFilterBlocksEmpty(
					item?.descriptionBlockTypeId,
					item?.descriptionBlockFilters
				))
		) {
			return false
		} else return true
	}
	function createMarkup(value) {
		return { __html: value }
	}

	return (
		<Box
			sx={{
				// flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				height: '100%'
			}}>
			<PopupTabs
				orientation='vertical'
				variant='scrollable'
				value={value}
				onChange={handleChange}
				aria-label='Vertical tabs example'>
				{(isEmpty(checkTime) ||
					isEmpty(comfort) ||
					isEmpty(cards) ||
					isEmpty(roomType) ||
					isEmpty(hotelDescText) ||
					isEmpty(inRoom)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Description} />}
						label='Описание'
						value={0}
					/>
				)}
				{isEmpty(locationHotel) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Location} />}
						label='Расположение'
						value={1}
					/>
				)}
				{(isEmpty(restaurants) || isEmpty(mealTypes)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Meal} />}
						label='Питание'
						value={2}
					/>
				)}
				{isEmpty(forChildren) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Children} />}
						label='Для детей'
						value={3}
					/>
				)}
				{(isEmpty(beach) || isEmpty(distance)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Beach} />}
						label='Пляж'
						value={4}
					/>
				)}
				{(isEmpty(waterEntertainment) ||
					isEmpty(territory) ||
					isEmpty(sport)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Territory} />}
						label='Территория'
						value={5}
					/>
				)}
				{isEmpty(mountain) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Mountain} />}
						label='Горнолыжная инфраструктура'
						value={6}
					/>
				)}
				{(isEmpty(language) ||
					isEmpty(addInfo) ||
					isEmpty(benefits) ||
					isEmpty(cards)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Information} />}
						label='Важная информация'
						value={7}
					/>
				)}
				{isEmpty(conferency) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={Conference} />}
						label='Возможности для конференций'
						value={8}
					/>
				)}
				{(isEmpty(spa) || isEmpty(healthy)) && (
					<PopupTab
						icon={<HotelIcon menuIconSVG={SPA} />}
						label='SPA и лечение'
						value={9}
					/>
				)}
			</PopupTabs>
			<TabPanel value={value} index={0}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Описание
				</ContentTitle>
				{isEmpty(hotelDescText) && (
					<>
						<h4>Расширенное описание</h4>
						<div
							style={{ textAlign: 'justify' }}
							dangerouslySetInnerHTML={createMarkup(hotelDescText)}></div>
						{(isEmpty(cards) ||
							isEmpty(comfort) ||
							isEmpty(roomType) ||
							isEmpty(checkTime) ||
							isEmpty(inRoom)) && <Divider style={{ margin: '24px 0px' }} />}
					</>
				)}
				{isEmpty(checkTime) && (
					<>
						<h4>Время заезда/выезда</h4>
						{checkTime?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(checkTime?.text)}></div>
								{/* <Divider style={{ margin: '24px 0px' }} /> */}
								{(isEmpty(cards) ||
									isEmpty(comfort) ||
									isEmpty(roomType) ||
									isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={checkTime?.descriptionBlockTypeId}
									array={checkTime?.descriptionBlockFilters}
								/>
								{(isEmpty(cards) ||
									isEmpty(comfort) ||
									isEmpty(roomType) ||
									isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(deposit) && (
					<>
						<h4>Депозит</h4>
						{deposit?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(deposit?.text)}></div>
								{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={comfort?.descriptionBlockTypeId}
									array={comfort?.descriptionBlockFilters}
								/>
								{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(comfort) && (
					<>
						<h4>Удобства</h4>
						{comfort?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(comfort?.text)}></div>
								{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={comfort?.descriptionBlockTypeId}
									array={comfort?.descriptionBlockFilters}
								/>
								{(isEmpty(cards) || isEmpty(roomType) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(roomType) && (
					<>
						<h4>Номерной фонд</h4>
						{roomType?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(roomType?.text)}></div>
								{(isEmpty(cards) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={roomType?.descriptionBlockTypeId}
									array={roomType?.descriptionBlockFilters}
								/>
								{(isEmpty(cards) || isEmpty(inRoom)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(cards) && (
					<>
						<h4>Кредитные карты</h4>
						{cards?.text ? (
							<>
								<div dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
								{isEmpty(inRoom) && <Divider style={{ margin: '24px 0px' }} />}
							</>
						) : (
							<>
								<FilterBlocks
									id={cards?.descriptionBlockTypeId}
									array={cards?.descriptionBlockFilters}
								/>
								{isEmpty(inRoom) && <Divider style={{ margin: '24px 0px' }} />}
							</>
						)}
					</>
				)}
				{isEmpty(inRoom) && (
					<>
						<h4>В номере</h4>
						{inRoom?.text ? (
							<>
								<div dangerouslySetInnerHTML={createMarkup(inRoom?.text)}></div>
							</>
						) : (
							<>
								<FilterBlocks
									id={inRoom?.descriptionBlockTypeId}
									array={inRoom?.descriptionBlockFilters}
								/>
							</>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Расположение
				</ContentTitle>
				{isEmpty(locationHotel) && (
					<>
						<h4>Где находится</h4>
						{locationHotel?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(
										locationHotel?.text
									)}></div>
								{isEmpty(thatAround) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={locationHotel?.descriptionBlockTypeId}
									array={locationHotel?.descriptionBlockFilters}
								/>
								{isEmpty(thatAround) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(thatAround) && (
					<>
						<h4>Что вокруг</h4>
						{thatAround?.text ? (
							<div
								dangerouslySetInnerHTML={createMarkup(thatAround?.text)}></div>
						) : (
							<FilterBlocks
								id={thatAround?.descriptionBlockTypeId}
								array={thatAround?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={2}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Питание
				</ContentTitle>
				{isEmpty(mealTypes) && (
					<>
						<h4>Типы питания</h4>
						{mealTypes?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(mealTypes?.text)}></div>
								{isEmpty(restaurants) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={mealTypes?.descriptionBlockTypeId}
									array={mealTypes?.descriptionBlockFilters}
								/>
								{isEmpty(restaurants) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(restaurants) && (
					<>
						<h4>Рестораны и бары</h4>
						{restaurants?.text ? (
							<div
								dangerouslySetInnerHTML={createMarkup(restaurants?.text)}></div>
						) : (
							<FilterBlocks
								id={restaurants?.descriptionBlockTypeId}
								array={restaurants?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={3}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Для детей
				</ContentTitle>
				<p></p>
				{isEmpty(forChildren) && (
					<>
						{forChildren?.text ? (
							<div
								dangerouslySetInnerHTML={createMarkup(forChildren?.text)}></div>
						) : (
							<FilterBlocks
								id={forChildren?.descriptionBlockTypeId}
								array={forChildren?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={4}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Пляж
				</ContentTitle>
				{isEmpty(beach) && (
					<>
						<h4>Описание пляжа</h4>
						{beach?.text ? (
							<>
								<div dangerouslySetInnerHTML={createMarkup(beach?.text)}></div>
								{isEmpty(distance) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={beach?.descriptionBlockTypeId}
									array={beach?.descriptionBlockFilters}
								/>
								{isEmpty(distance) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(distance) && (
					<>
						<h4>Расстояние до моря</h4>
						{distance?.text ? (
							<div dangerouslySetInnerHTML={createMarkup(distance?.text)}></div>
						) : (
							<FilterBlocks
								id={distance?.descriptionBlockTypeId}
								array={distance?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={5}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Территория
				</ContentTitle>
				{isEmpty(territory) && (
					<>
						<h4>Описание территории</h4>
						{territory?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(territory?.text)}></div>
								{/* <Divider style={{ margin: '24px 0px' }} /> */}
								{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={territory?.descriptionBlockTypeId}
									array={territory?.descriptionBlockFilters}
								/>
								{(isEmpty(waterEntertainment) || isEmpty(sport)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(waterEntertainment) && (
					<>
						<h4>Водные развлечения</h4>
						{waterEntertainment?.text ? (
							<div>
								<div
									dangerouslySetInnerHTML={createMarkup(
										waterEntertainment?.text
									)}></div>
								{isEmpty(sport) && <Divider style={{ margin: '24px 0px' }} />}
							</div>
						) : (
							<>
								<FilterBlocks
									id={waterEntertainment?.descriptionBlockTypeId}
									array={waterEntertainment?.descriptionBlockFilters}
								/>
								{isEmpty(sport) && <Divider style={{ margin: '24px 0px' }} />}
							</>
						)}
					</>
				)}
				{isEmpty(sport) && (
					<>
						<h4>Развлечения и спорт</h4>
						{sport?.text ? (
							<div dangerouslySetInnerHTML={createMarkup(sport?.text)}></div>
						) : (
							<FilterBlocks
								id={sport?.descriptionBlockTypeId}
								array={sport?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={6}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Горнолыжная инфраструктура
				</ContentTitle>
				{isEmpty(mountain) && (
					<>
						{mountain?.text ? (
							<div dangerouslySetInnerHTML={createMarkup(mountain?.text)}></div>
						) : (
							<FilterBlocks
								id={mountain?.descriptionBlockTypeId}
								array={mountain?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={7}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Важная информация
				</ContentTitle>
				{isEmpty(addInfo) && (
					<>
						<h4>Дополнительная информация</h4>
						{addInfo?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(addInfo?.text)}></div>
								{(isEmpty(language) || isEmpty(cards) || isEmpty(benefits)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={addInfo?.descriptionBlockTypeId}
									array={addInfo?.descriptionBlockFilters}
								/>
								{(isEmpty(language) || isEmpty(cards) || isEmpty(benefits)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(language) && (
					<>
						<h4>Язык</h4>
						{language?.text ? (
							<>
								<div
									dangerouslySetInnerHTML={createMarkup(language?.text)}></div>
								{(isEmpty(benefits) || isEmpty(cards)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={language?.descriptionBlockTypeId}
									array={language?.descriptionBlockFilters}
								/>
								{(isEmpty(benefits) || isEmpty(cards)) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(cards) && (
					<>
						<h4>Кредитные карты</h4>
						{cards?.text ? (
							<>
								<div dangerouslySetInnerHTML={createMarkup(cards?.text)}></div>
								{isEmpty(benefits) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						) : (
							<>
								<FilterBlocks
									id={cards?.descriptionBlockTypeId}
									array={cards?.descriptionBlockFilters}
								/>
								{isEmpty(benefits) && (
									<Divider style={{ margin: '24px 0px' }} />
								)}
							</>
						)}
					</>
				)}
				{isEmpty(benefits) && (
					<>
						<h4>Особенности и преимущества</h4>
						{benefits?.text ? (
							<div dangerouslySetInnerHTML={createMarkup(benefits?.text)}></div>
						) : (
							<FilterBlocks
								id={benefits?.descriptionBlockTypeId}
								array={benefits?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={8}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					Возможности для конференций
				</ContentTitle>
				{isEmpty(conferency) && (
					<>
						{conferency?.text ? (
							<div
								dangerouslySetInnerHTML={createMarkup(conferency?.text)}></div>
						) : (
							<FilterBlocks
								id={conferency?.descriptionBlockTypeId}
								array={conferency?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={9}>
				<ContentTitle id='customized-dialog-title' onClose={handleClose}>
					SPA и лечение
				</ContentTitle>
				{isEmpty(spa) && (
					<>
						<h4>Услуги SPA</h4>
						{spa?.text ? (
							<>
								<div dangerouslySetInnerHTML={createMarkup(spa?.text)}></div>
								{isEmpty(healthy) && <Divider style={{ margin: '24px 0px' }} />}
							</>
						) : (
							<>
								<FilterBlocks
									id={spa?.descriptionBlockTypeId}
									array={spa?.descriptionBlockFilters}
								/>
								{isEmpty(healthy) && <Divider style={{ margin: '24px 0px' }} />}
							</>
						)}
					</>
				)}
				{isEmpty(healthy) && (
					<>
						<h4>Лечение и оздоровление</h4>
						{healthy?.text ? (
							<div dangerouslySetInnerHTML={createMarkup(healthy?.text)}></div>
						) : (
							<FilterBlocks
								id={healthy?.descriptionBlockTypeId}
								array={healthy?.descriptionBlockFilters}
							/>
						)}
					</>
				)}
			</TabPanel>
		</Box>
	)
}
