import React from 'react'
import { alpha } from '@mui/material/styles'
import { styled as styledMUI } from '@mui/material/styles'
import AddIcon from '@material-ui/icons/Add'
import { isEmpty, uniqBy } from 'lodash'

import styled from 'styled-components'
import { ExpandMore } from '@material-ui/icons'
import TextField from '@mui/material/TextField'
import { MenuItem, Alert, Button, Snackbar, Grid  } from '@mui/material'
import { ReactComponent as CloseImg } from '../../../asset/images/cabinet/close.svg'
// import DateInputRange from './DateInputRange'
import DocumentItem from './DocumentItem'
import usePlanStore from './store'

const UploadButton = styledMUI(Button)({
	borderRadius: '8px',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '12px 24px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'Roboto'].join(','),
	'&:hover': {
		backgroundColor: '#f5f1f1',
		borderColor: '#4872f2',
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: 'transparent',
		borderColor: '#E7E7E7',
		color: '#B9B9B9',
	},
})

const VisuallyHiddenInput = styledMUI('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

const MuiTextField = styledMUI((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& label': {
		fontFamily: "'Open Sans', 'sans-serif'",
		color: '#7E8389',
		left: 5,
	},
	'& .MuiInputLabel-filled.MuiInputLabel-shrink': {
		transform: 'translate(12px, 8px) scale(0.75)',
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red',
	},
	'& .MuiInputBase-input': {
		fontFamily: "'Open Sans', 'sans-serif'",
		position: 'relative',
	},
	'& .MuiFilledInput-root': {
		overflow: 'hidden',
		borderRadius: 8,
		backgroundColor: '#FFF',
		border: '1px solid',
		borderColor: '#E9EAEC',
		paddingLeft: 16,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {
			borderColor: '#282828',
			backgroundColor: '#FFF',
		},
		'&.Mui-focused': {
			boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 1px`,
			borderColor: theme.palette.primary.main,
			backgroundColor: '#FFF',
		},
		'&.Mui-disabled': {
			borderColor: '#B5B8BC',
			backgroundColor: '#FFF',
		},
		'& .MuiFilledInput-input': {
			paddingLeft: 0,
		},
		'& :focus': {
			backgroundColor: '#FFF',
		},
		'& .MuiSvgIcon-root': {
			right: 16,
		},
		'& .MuiAutocomplete-popupIndicator': {
			marginRight: 4,
		},
	},
}))

const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 32px;
	background-color: #fff;
	width: 780px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;
	/* max-height: 100vh; */
	min-height: 400px;
	/* overflow-y: auto; */
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const PopupTitle = styled.h2`
	color: #2e2e32;
	font-family: Open Sans;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 38px;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`

export default function UploadPlan() {
	const actions = usePlanStore(({ actions }) => actions)
	// const loading = usePlanStore(({ loading }) => loading)
	const error = usePlanStore(({ error }) => error)
	const department = usePlanStore(({ department }) => department)
	// const timePeriod = usePlanStore(({ timePeriod }) => timePeriod)

	const [selectedFiles, setSelectedFiles] = React.useState([])
	const [openStatus, setOpenStatus] = React.useState(false)

	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const handleClickStatus = () => {
		setOpenStatus(true)
	}

	const handleCloseStatus = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpenStatus(false)
	}
	React.useEffect(() => {
		if (!!error) {
			handleClickStatus()
			setSelectedFiles([])
		}
	}, [error])

	const handleChange = (event) => {
		if (selectedFiles.length) {
			return
		}
		const files = [...event.target.files]
		if (files.some((file) => file.size > 30000000)) {
			const newFiles = files.filter((file) => file.size < 30000000)
			setSelectedFiles((prev) => uniqBy([...prev, ...newFiles], 'name'))
		} else {
			setSelectedFiles((prev) => uniqBy([...prev, ...files], 'name'))
		}
	}
	const handleDelete = React.useCallback((name) => {
		setSelectedFiles((prev) => prev.filter((file) => file.name !== name))
	}, [])

	const handleLink = () => {
		window.location.href = '/cabinet'
	}

	const handleUpload = () => {
		actions.uploadPlan(selectedFiles)
		setSelectedFiles([])
	}

	const availableAccounts = ['b2b_Smoke', 'ekaterina.arnaut1', 'novikovafs2507', 'dogovor@t', 'liliya.galanina']
	if (!availableAccounts.includes(agentInfo?.agentsUsers[0]?.login)) {
		return null
	}
	return (
		<PopupBackground>
			<PopupContent>
				<Row>
					<PopupTitle>Загрузить планы на период</PopupTitle>
					<CloseBtn onClick={handleLink} />
				</Row>
				<MuiTextField
					SelectProps={{ IconComponent: ExpandMore }}
					select
					fullWidth
					label='Тип'
					value={department}
					onChange={(event) => actions.setNewParams(('department'), event.target.value)}
					variant='filled'>
					{['План'].map((value) => (
						<MenuItem key={value} value={value}>
							{value}
						</MenuItem>
					))}
				</MuiTextField>
				{/* <DateInputRange
					onChange={(value) => actions.setNewParams('timePeriod', value)}
					label='Период'
					value={timePeriod}
				/> */}
				<Grid container columnSpacing={3} rowSpacing={3}>
					{selectedFiles &&
						selectedFiles.map((file) => (
							<DocumentItem
								key={file.name}
								file={file}
								handleDelete={handleDelete}
							/>
					))}
				</Grid>
				{isEmpty(selectedFiles) && (
					<UploadButton
						component='label'
						variant='outlined'
						startIcon={<AddIcon style={{ fontSize: 24 }} />}>
						Добавить файл
						<VisuallyHiddenInput
							type='file'
							accept='.png,.jpg,.jpeg,.pdf,.docx'
							onChange={handleChange}
						/>
					</UploadButton>
				)}
				{!isEmpty(selectedFiles) && (
					<UploadButton
						component='label'
						variant='outlined'
						onClick={handleUpload}>
						Загрузить
					</UploadButton>
				)}

				<Snackbar
					open={openStatus}
					autoHideDuration={6000}
					onClose={handleCloseStatus}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
					<Alert
						onClose={handleCloseStatus}
						severity={error === 200 ? 'success' : 'error'}
						sx={{ width: '100%' }}>
						{error === 200 ? 'Документы успешно отправлены' : error}
					</Alert>
				</Snackbar>
			</PopupContent>
		</PopupBackground>
	)
}
