import React from 'react'
import {
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Popper
} from '@mui/material'
import { IconButton, Paper } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import PersonIcon from '@material-ui/icons/Person'
import useAuthStore from '../../pages/Auth/store'
import { ReactComponent as AgentIcon } from '../../asset/icons/agentIcon.svg'
import './style.css'
import {Link} from "react-router-dom";
import ym from "react-yandex-metrika";
import {userLogout} from "../../store/modules/user";
import {useDispatch} from "react-redux";

const AccountAuth = ({ openCabinet, onCloseDrawer }) => {
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const actions = useAuthStore(({ actions }) => actions)
	const agent = localStorage.getItem('agentInfo')
	const agentsUsers = JSON.parse(agent)?.agentsUsers
	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	const dispatch = useDispatch()

	const franch = agentInfo?.agents?.name.includes('F&S') || agentInfo?.partPass === '91554' || agentInfo?.partPass === '121374'

	const handleToggleCabinet = () => {
		setOpen(prevOpen => !prevOpen)
	}

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	const Logout = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		actions.logout()
		document.cookie = 'access_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
		dispatch(userLogout())
		setOpen(false)
		onCloseDrawer(event)
	}

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open)
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		}

		prevOpen.current = open
	}, [open])

	return (
		<div data-cy={'lk'}>
			<IconButton
				disableRipple
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup='true'
				onClick={handleToggleCabinet}
				style={{ textTransform: 'unset' }}>
				<AgentIcon className='agent-icon' />
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 1000 }}
				modifiers={[
					{
						name: 'offset',
						enabled: true,
						options: {
							offset: [0, 20]
						}
					}
				]}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom'
						}}>
						<Paper elevation={5}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id='menu-list-grow'
									onKeyDown={handleListKeyDown}>
									<MenuItem onClick={() => onCloseDrawer(false)}>
										{franch ? <a
											rel={'noreferrer'}
											href={'/cabinet/my-details'}
											style={{display: 'flex', textAlign: 'left', padding: 0}}
											onClick={() => {
												setOpen(false);
												ym('reachGoal', 'click_LK_newb2b')
											}}

											>
											<PersonIcon
												fontSize={'small'}
												style={{marginRight: 5, color: '#2e2e32'}}
											/>
											<div style={{color: '#2e2e32'}}>
												<span className='cabinet-text'>
													{
														agentsUsers?.find(
															item =>
																item.partpassId ===
																Number(JSON.parse(agent).partPass)
														).fullName
													}
												</span>
												<div style={{fontSize: 10}}>Турагент</div>
											</div>
										</a> :
										<a
											rel={'noreferrer'}
											href={samoLinks['cl_refer'] || 'https://b2b.fstravel.com/cl_refer'}
											target={franch ? '_self' : '_blank'}
											style={{display: 'flex', textAlign: 'left', padding: 0}}
											onClick={() => {
												openCabinet();
												ym('reachGoal', 'click_LK_newb2b')
											}}

										>
											<PersonIcon
												fontSize={'small'}
												style={{marginRight: 5, color: '#2e2e32'}}
											/>
											<div style={{color: '#2e2e32'}}>
											<span className='cabinet-text'>
												{
													agentsUsers?.find(
														item =>
															item.partpassId ===
															Number(JSON.parse(agent).partPass)
													).fullName
												}
											</span>
												<div style={{fontSize: 10}}>Турагент</div>
											</div>
										</a>}
									</MenuItem>
									<MenuItem onClick={Logout}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<ClearIcon
												fontSize={'small'}
												style={{ marginRight: 5 }}
											/>
											<span className='cabinet-text'>Покинуть систему</span>
										</div>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}

export default AccountAuth
