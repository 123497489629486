import React from 'react';
import styled from 'styled-components';
import promocodePercent from '../../asset/images/avia/order-detail/promocodePercent.svg';
import { useSelector } from 'react-redux';
import { getDetailOrder } from '../../store/modules/orders';

const Container = styled.div`
  background: #edf1fe;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c3c3c;
  border-radius: 8px;
`;

const PromoCodeApplied = () => {
  const { orderAmount } = useSelector(getDetailOrder);
  const promocodeDetails = orderAmount.detalization.find(x => x.priceType === 20);

  return (
    <Container>
      <img src={promocodePercent} alt={'percent'} style={{ marginRight: 5 }} />
      <div>
        {promocodeDetails?.description}. Скидка по промокоду составляет{' '}
        {promocodeDetails?.value} ₽
      </div>
    </Container>
  );
};

export default PromoCodeApplied;
