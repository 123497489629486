import React from "react"
import styled from "styled-components"
import { DatePicker } from "../../ui"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getAgentCreditHistoryRequest } from "../../../store/modules/agents/agent-finance/duck"
import {
  getAgentCreditHistory,
  getAgentLoadings,
  getAgentSiginState
} from "../../../store/modules/agents/agent-finance/selectors"
import agentCreditLimitHistory from "../../../asset/images/avia/agents/agentCreditLimitHistory.svg"
import Collapse from '@mui/material/Collapse';
import ArrowButton from "./ArrowButton"
import PrimaryButton from "../../ui/PrimaryButton"
import CreditLimitTable from './tables/CreditLimitTable'


const StyledButton = styled(PrimaryButton)`
`

const DateWrapper = styled.div`
  width: 256px;
  margin-right: 20px;

  @media (max-width: 1024px) {
    //margin-top: 30px;
    margin: 0;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`


const ColumnRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 25px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`

const AgentCreditLimitHistory = styled.img.attrs({
  src: agentCreditLimitHistory
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`



const TableWrapper = styled.div`
  margin-top: 25px;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${({ isActive }) => (isActive ? "#4872F2" : "#dcdcdc")};
`

const AgentCreditHistory = ({ dateCreditLimit, setDateCreditLimit }) => {
  const dispatch = useDispatch()
  const agent = useSelector(getAgentSiginState)
  const data = useSelector(getAgentCreditHistory)
  const isLoading = useSelector(getAgentLoadings)
  const [isOpen, setIsOpen] = React.useState(false)
  const [hover, setHover] = React.useState(false)


  const onSubmit = () => {
    const formatedDate = moment(dateCreditLimit).format("YYYY-MM-DD")
    dispatch(
      getAgentCreditHistoryRequest({
        date: formatedDate,
        agentId: agent?.agentSamoCode ?? ""
      })
    )
  }


  return (
    <>
      <Row
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <FlexRow>
          <AgentCreditLimitHistory />{" "}
          <Title>История установки кредитного лимита</Title>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Формируйте историю кредитного лимита за указанную дату.
      </SubTitle>
      <Collapse in={isOpen}>
        <div>
          <Text style={{marginTop: 0}}> 
            В данном блоке вам доступно получение истории установки кредитного
            лимита за период. Для получения отчетности укажите дату, начиная с
            которой будет выдаваться история лимита.
          </Text>
          <ColumnRow>
            <DateWrapper>
              <DatePicker
                value={dateCreditLimit}
                onChange={date =>
                  // @ts-ignore
                  setDateCreditLimit(date)
                }
                label="Выберите дату"
                placeholder="дд.мм.гггг"
                icon
              />
            </DateWrapper>
            <StyledButton
              disabled={!dateCreditLimit}
              onClick={() => onSubmit()}
              loading={isLoading.creditHistory}
            >
                Выполнить
            </StyledButton>
          </ColumnRow>
          {!!data?.length && !!data && (
            <TableWrapper>
              <CreditLimitTable items={data}/>
            </TableWrapper>
          )}
        </div>
      </Collapse>
      <Separator isActive={hover} />
    </>
  )
}

export default AgentCreditHistory
