import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE + '/geocontent'

const useHotelDescriptionStore = create(
	devtools((set, get) => ({
		error: null,
		loading: null,
		hotel: {},
		blocks: [],
		checkTime: {},
		comfort: {},
		cards: {},
		restaurants: {},
		forChildren: {},
		beach: {},
		distance: {},
		territory: {},
		waterEntertainment: {},
		sport: {},
		mountain: {},
		language: {},
		conferency: {},
		spa: {},
		healthy: {},
		locationText: '',
		location: {},
		standardizedName: '',
		mealTypes: {},
		locationHotel: {},
		thatAround: {},
		roomType: {},
		addInfo: {},
		benefits: {},
		hotelTerritory: {},
		inRoom: {},
		FSHotel: {},
		formatHotel: {},
		momentConfirm: false,
		recreationTypes: {},
		amenities: [],
		hotelDescText: '',
		parentList: [],
		deposit: {},
		actions: {
			getHotelInfo: async (url) => {
				try {
					const res = await axios({
						// url: `http://10.20.20.24:32000/hotel/getbyurlname`,
						url: `${BASE_URL}/hotel/getbyurlname`,
						method: 'POST',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`
						},
						data: decodeURI(url)
					})

					set({ hotel: res.data })
					set({
						checkTime: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'd8771865-e91c-4aea-aab7-a83f312297ec'
						)
					})
					set({
						locationText: res.data.descriptions?.find(
							elem => elem.name === 'Расположение'
						)?.text
					})
					set({ standardizedName: res.data.localizedName || res.data.standardizedName })
					set({
						location: {
							...get().location,
							geometry: [
								Number(res.data.latitude?.split(',').join('.')),
								Number(res.data.longitude?.split(',').join('.'))
							],
							zoom: +res.data.mapZoomLevel
						}
					})
					set({
						hotelDescText: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'5b971489-bdb0-42a8-a6e3-43e8100f780e'
						)
					})
					set({
						comfort: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'7cde75f0-f3f6-4d0f-adbb-756988dadac7'
						)
					})
					set({
						hotelDescText: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'5b971489-bdb0-42a8-a6e3-43e8100f780e'
						)
					})
					set({
						mealTypes: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'c8d33506-5557-4a0d-a93f-15eb547e7dcb'
						)
					})
					set({
						locationHotel: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'6b62db75-7860-46b5-8671-6012a3e046bd'
						)
					})
					set({
						thatAround: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'57ce8b64-ed50-4cc9-a281-d47c1ade5523'
						)
					})
					set({
						roomType: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'44a68948-52f7-47cf-8bb4-819899914001'
						)
					})
					set({
						addInfo: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'783adb6d-4064-4d88-affd-8ff6f6835022'
						)
					})
					set({
						benefits: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'0b8316ba-b15c-4139-ac7f-490e665eba24'
						)
					})
					set({
						inRoom: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'f4d32fbb-8f74-4b0f-89bf-67aaa87b3a40'
						)
					})
					set({
						cards: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'b344ebf9-ba53-4c47-80f5-c134ef9ae264'
						)
					})
					set({
						restaurants: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'8563111a-5b58-453f-ab73-45d44a31ddc1'
						)
					})
					set({
						forChildren: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'f85534d8-0503-4547-90dc-cf06e5d22ac2'
						)
					})
					set({
						beach: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'144dd88c-f0cf-4c5f-a9b6-aea6f061261d'
						)
					})
					set({
						distance: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'9d6f2921-fba3-4358-8eaf-bf92f301c408'
						)
					})
					set({
						territory: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'a420d6a0-6b63-4e4a-9a90-e2edad491028'
						)
					})
					set({
						waterEntertainment: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'17267f54-902e-4047-b610-112ddd38b9c6'
						)
					})
					set({
						sport: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'9b3b7b85-dd1d-4e72-ac65-f4115d19580f'
						)
					})
					set({
						mountain: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'14d71298-9385-4afc-bbbf-b50d8297462f'
						)
					})
					set({
						language: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'0742629f-048d-4040-92bc-d2f3dcf090df'
						)
					})
					set({
						conferency: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'752ce771-a4c3-4f73-be53-c493285a9a50'
						)
					})
					set({
						spa: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'83a960ca-675b-4e26-9378-3bf400686f26'
						)
					})
					set({
						healthy: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'634dcbb5-0237-40f3-ba7d-3cef317afe4f'
						)
					})
					set({
						hotelTerritory: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'7097c94a-e80b-4b14-8026-d1f2e5ecf8a7'
						)
					})
					set({
						FSHotel: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'5a7a26f4-3607-4339-b105-186138e2fed4'
						)
					})
					set({
						formatHotel: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'550b7914-b6e6-4f16-8ae8-9819f880ab1c'
						)
					})
					set({
						recreationTypes: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'1337675e-55c3-402c-83ea-32878e2a7187'
						)
					})
					set({
						deposit: res.data.descriptionBlocks?.find(
							elem =>
								elem.descriptionBlockTypeId ===
								'0ce3851b-0b29-43bf-aeab-01d82c76f591'
						)
					})
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getHotelBlocks: async () => {
				try {
					const res = await axios({
						// url: `http://10.20.20.24:32000/hoteldescriptiontypes`,
						url: `${BASE_URL}/hoteldescriptiontypes`,
						method: 'GET',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`,
						}
					})
					set({ blocks: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getAmenities: async () => {
				try {
					const res = await axios({
						url: `${process.env.REACT_APP_FS_APIGATE}/amenity/list`,
						method: 'GET',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`,
						}
					})
					set({ amenities: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getHotelByEbgId: async (id) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/geo-tree/ebgNodeId-match?ebgNodeId=${id}`,
						method: 'GET',
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`
						}
					})
					return res.data
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getParentInfo: async (id) => {

				try {
					const res = await axios({
						url: `${BASE_URL}/geo-tree/${id}/parents`,
						headers: {
							'Content-Type': `application/json`,
							'X-TUI-Storefront-Id': `b2c:ru`
						}
					})
					set({ parentList: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			changeMomentConfirm: (momentConfirm) => {
				set({ momentConfirm: momentConfirm })
			}
		}
	}))
)

export default useHotelDescriptionStore
