import React from 'react';

import './style.css'

const FSReviews = ({ isShow }) => {

	return (
		<div style={isShow ? {display: 'block'}: {display: 'none'}}>
			<div id='sp-reviews-container' />
		</div>
	);
};

export default FSReviews;