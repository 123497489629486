import React, {useEffect} from 'react';
import CountryField from "../CountryField";
import TouristsField from "../TouristsField";
import {Button} from "@material-ui/core";
import useExcursionStore from "../../store";
import DateInputRange from "../DateInputRange";
// import {uniqBy} from "lodash";
// import {addDays} from "date-fns";
import declination from "../../../../functions/declination";
import {Box} from "@mui/material";
import LinearProgress from "@material-ui/core/LinearProgress";
import './style.scss'
import {isEmpty} from "lodash";
import {useNavigate} from "react-router-dom";

const ExcursionsSearchForm = () => {

	const actions = useExcursionStore(({ actions }) => actions)
	const countries = useExcursionStore(({ countries }) => countries)
	const currentCountry = useExcursionStore(({ currentCountry }) => currentCountry)
	const cities = useExcursionStore(({ cities }) => cities)
	const currentCity = useExcursionStore(({ currentCity }) => currentCity)
	const adults = useExcursionStore(({ adults }) => adults)
	const children = useExcursionStore(({ children }) => children)
	const excursions = useExcursionStore(({ excursions }) => excursions)
	const loadingSearch = useExcursionStore(({ loadingSearch }) => loadingSearch)
	const date = useExcursionStore(({ date }) => date)

	const navigate = useNavigate()
	

	useEffect(() => {
		actions.postCountries()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (currentCountry) {
			actions.postCities(currentCountry)
		}
		// eslint-disable-next-line
	}, [currentCountry])

	const handleChangeCountry = (id) => {
		actions.setCountry(id)
	}

	const handleChangeDate = (value) => {
		actions.setDate(value)
	}

	const handleChangeTourists = (value, id) => {
		console.log(value, 'handleChangeTourists value')
		console.log(id, 'handleChangeDate id')
	}

	const handleSearch = () => {
		navigate(`?countryId=${currentCountry}&startDate=${date.dateFrom}&endDate=${date.dateTo}&adults=${adults}&childrens=${children}&departureCityId=${currentCity}`)
		// actions.postSearch()
	}

	if (!countries || !cities) return

	return (
		<div className={'excursions-page'}>
			<div className={`excursions-search-form-wrapper ${!excursions ? 'background-color' : ''}`}>
				<div
					className={`excursions-search-form ${!!excursions ? 'container' : ''}`}
				>
					<CountryField
						className={'excursions-countries'}
						lists={countries}
						value={currentCountry}
						title={'Страна поездки'}
						onChange={handleChangeCountry}
					/>
					<CountryField
						className={'excursions-sity'}
						lists={cities}
						value={currentCity}
						title={'Откуда'}
						onChange={(id) =>actions.setCity(id)}
					/>
					<DateInputRange
						label="Даты поездки"
						value={[new Date(date.dateFrom), new Date(date.dateTo)]}
						onChange={handleChangeDate}
					/>
					<TouristsField
						className={'tourists'}
						onChange={handleChangeTourists}
						adults={adults}
						children={children}
						title={'Туристы'}
					/>
					<Button
						onClick={handleSearch}
						variant={'contained'}
						className={'search-button'}
						// disabled={loading}
					>
						Найти
					</Button>
				</div>
			</div>
			<Box className={'container'} mt={2}>
				{((!excursions && loadingSearch) || (isEmpty(excursions) && loadingSearch)) &&
					<div style={{color: '#49c8c0', fontSize: 16}}>
						Идет поиск.
					</div>
				}
				{loadingSearch   &&
					<Box sx={{width: '100%'}}>
						<LinearProgress variant="determinate" value={excursions?.length * 6.25}/>
					</Box>
				}
			</Box>

		</div>
	);
};

export default ExcursionsSearchForm;