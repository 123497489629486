import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const MuiTextField = styled(props => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& label': {
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		color: '#7E8389'
	},
	'& .MuiInputLabel-filled.MuiInputLabel-shrink': {
		transform: 'translate(16px, 8px) scale(0.75)'
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& .MuiInputBase-input': {
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		position: 'relative',
	},
	'& .MuiFilledInput-root': {
		overflow: 'hidden',
		borderRadius: 8,
		backgroundColor: '#FFF',
		border: '1px solid',
		borderColor: '#E9EAEC',
		paddingLeft: 16,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow'
		]),
		'&:hover': {
			borderColor: '#282828',
			backgroundColor: '#FFF'
		},
		'&.Mui-focused': {
			boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 1px`,
			borderColor: theme.palette.primary.main,
			backgroundColor: '#FFF'
		},
		'&.Mui-disabled': {
			borderColor: '#B5B8BC',
			backgroundColor: '#FFF'
		}, 
		'& .MuiFilledInput-input': {	
			paddingLeft: 0,
		},
		'& :focus': {
			backgroundColor: '#FFF'
		},
		'& .MuiSvgIcon-root': {
			right: 16
		},
	}
}))
