import React from 'react';
import Segment from './Segment';
import styled from 'styled-components';

const Wrapper = styled.div`
  & > div {
    margin-bottom: 22px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const SegmentList = ({ items, stops }) => {
  return (
    <Wrapper>
      {items.map((item, key) => {
        const stopProps = stops[key] && {
          ...stops[key],
          isDifferentAirports:
            items[key + 1] && item.to.code !== items[key + 1].from.code,
        };
        return <Segment key={key} segment={item} stop={stopProps} />;
      })}
    </Wrapper>
  );
};

export default SegmentList;
