import { getNormalizeMessages } from "./utils"
import Api from '../../../functions/fetchApi'

/**
 * Create new appeal
 * @appeals/create action
 * @param data
 */
export const addTicket = args => {
  const formData = new FormData()
  Object.entries(args).forEach(([key, item]) => {
    if (key === "files") {
      item.forEach(x => {
        formData.append("Files", x)
      })
    } else if (key === "insuranceIds") {
      if (item.length > 0) {
        formData.append(key, JSON.stringify(item))
      }
    } else if (typeof item === "object") {
      formData.append(key, JSON.stringify(item))
    } else {
      formData.append(key, item)
    }
  })

  return Api.post(process.env.REACT_APP_ADD_TICKET_WITH_FILE)
    .body(formData)
    .setContentType('multipart/form-data')
    .addAuthGuard()
    .send();
}

/**
 * Get list of customer tickets
 * action: @appeals/getList
 * saga: runCreateAppeal
 * @param data
 */
export const getCustomerTickets = data =>
  Api.get(process.env.REACT_APP_GET_CUSTOMER_APPEALS)
    .query({
      Page: data.page,
      PageSize: data.pageSize,
      "Filter.Subject": data.subject,
      "Filter.Status": data.status,
      SortByCreateDate: data.dateSorting,
      "Search.SupportTicketNumber": data.number
    })
    .setContentType('application/json')
    .addAuthGuard()
    .send();

/**
 * Get ticket by number
 * action: @appeals/getDetail
 * saga: runDetailLoad
 * @param number
 */
export const getTicketByNumber = ticketNumber =>
  Api.get(process.env.REACT_APP_GET_TICKET_BY_NUMBER)
    .query({ ticketNumber })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(result => ({
      ...result,
      messages: getNormalizeMessages(result.messages)
    }));

/**
 * Apply refund proposal
 * @param calculationId calculation id
 */
export const applySupportTicketCalculation = calculationId =>
  Api.post(process.env.REACT_APP_APPLY_SUPPORT_TICKET_CALCULATION)
    .body({ calculationId })
    .setContentType('application/json')
    .dontParseResponse()
    .addAuthGuard()
    .send();

/**
 * Cancel refund proposal
 * @param calculationId calculation id
 */
export const declineSupportTicketCalculation = calculationId =>
  Api.post(process.env.REACT_APP_DECLINE_SUPPORT_TICKET_CALCULATION)
    .setContentType('application/json')
    .dontParseResponse()
    .body({ calculationId })
    .addAuthGuard()
    .send();

/**
 * Surcharge
 * @param calculationId calculation id
 */
export const surcharge = (orderId, supportTicketCalculationId) =>
  Api.post(process.env.REACT_APP_SET_ADDITIONAL_PAYMENT)
    .body({ orderId, supportTicketCalculationId })
    .addAuthGuard()
    .send();

/**
 * Send new user message
 * action: @appeals/sendMessage
 * saga: runMessageSend
 */
export const sendMessage = async (text, ticketId, files) => {
  const formData = new FormData()
  files.forEach(x => {
    formData.append("Files", x)
  })
  formData.append("Text", text)
  formData.append("TicketId", ticketId)

  return Api.post(process.env.REACT_APP_SEND_MESSAGE)
    .body(formData)
    .setContentType('multipart/form-data')
    .addAuthGuard()
    .send();
}
/**
 * Set user message as read
 * action: @appeals/getDetail
 * saga: runDetailLoad
 */
export const setRead = messageId => {
  return Api.put(process.env.REACT_APP_SET_MESSAGE_READ)
    .body({MessageId: messageId})
    .setContentType('application/json')
    .addAuthGuard()
    .dontParseResponse()
    .send()
    .catch(e => console.log(e));
}

/**
 * Set user message as read
 * action: @appeals/getDetail
 * saga: runDetailLoad
 */
export const getFile = (fileId, name) => {
  return Api.get(process.env.REACT_APP_GET_FILE)
    .query({fileId})
    .setContentType('application/octet-stream')
    .addAuthGuard()
    .send()
    .then(Api.loadFile)
}

export const getCalculationByNumber = id =>
  Api.get(process.env.REACT_APP_ADD_GET_APPEAL_CALCULATION)
    .query( { id })
    .setContentType('application/json')
    .addAuthGuard()
    .send()

export const setCalculationRead = supportTicketCalculationId =>
  Api.put(process.env.REACT_APP_SET_CALCULATION_READ)
    .body( { supportTicketCalculationId })
    .setContentType('application/json')
    .dontParseResponse()
    .addAuthGuard()
    .send()

export const getHistory = actionSourceId => {
  return Api.get(process.env.REACT_APP_GET_APPEAL_HISTORY)
    .query( { actionSourceId, actionSource: 'SupportTicketProcessing'})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export const cancelAppeal = supportTicketId => {
  return Api.put(process.env.REACT_APP_CANCEL_APPEAL)
    .body( { supportTicketId })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}
