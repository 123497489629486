import { Button, Collapse, Grid } from '@mui/material'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useFranchisingStore from '../store'
import NewsItem from './NewsItem'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	/* padding: 24px; */
`
const NewsBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 24px;
	margin-top: 24px;
`

const buttonLeftStyle = {
	p: 1,
	mr: 1,
	borderRadius: '50%',
	minWidth: '40px',
	height: '40px'
}

const buttonRightStyle = {
	p: 1,
	borderRadius: '50%',
	minWidth: '40px',
	height: '40px'
}

export default function News() {
	const [isOpen, setIsOpen] = React.useState(true)
	const news = useFranchisingStore(({ news }) => news)
	const actualNews = useFranchisingStore(({ actualNews }) => actualNews)

	const [page, setPage] = useState(1)

	const pageQty = useMemo(() => {
		return Math.ceil(actualNews?.length)
	}, [actualNews])

	const handleBackPage = () => {
		setPage(prev => prev - 1)
	}

	const handleNextPage = () => {
		setPage(prev => prev + 1)
	}

	const isPaginate = useMemo(() => {
		if (actualNews.length < 2) {
			return false
		} else return true
	}, [actualNews])
	
	if (!news) return null
	return (
		<Container>
			<Row onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
				<FlexRow>
					<Title>{news?.title}</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => setIsOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<NewsBlock>
					{actualNews[page - 1]?.map((item, i) => (
						<NewsItem key={i} content={item} />
					))}
					{isPaginate && (
						<Grid container align='end'>
							<Grid item xs={12}>
								<Button
									sx={buttonLeftStyle}
									variant='outlined'
									onClick={handleBackPage}
									disabled={page === 1}>
									<KeyboardArrowLeft />
								</Button>
								<Button
									sx={buttonRightStyle}
									variant='outlined'
									onClick={handleNextPage}
									disabled={page === pageQty}>
									<KeyboardArrowRight />
								</Button>
							</Grid>
						</Grid>
					)}
				</NewsBlock>
			</Collapse>
		</Container>
	)
}
