import { InputAdornment, MenuItem } from '@mui/material'
import React from 'react'

import { styled } from '@mui/material/styles'
import { Select } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'
import { TextField } from '@mui/material';

export const CustomSelect = styled(Select)(() => ({
    minWidth: '152px',
    borderRadius: '8px',
    marginRight: '8px',
    background: '#FFF',
    height: '57px',
    '& fieldset': {
        borderRadius: '8px',
        borderColor: '#D4D9DE'
    },
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        background: 'white',
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    }, '&.MuiInputBase-colorPrimary': {
        paddingLeft: 16,
    },
    '& .MuiSvgIcon-root': {
        right: 16
    }
}))

const MuiTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '8px',
        borderColor: 'rgba(212, 217, 222, 1)',
    },
    '& .MuiInputBase-input': {
        background: 'white',
        fontFamily: "'Open Sans', 'sans-serif'",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)'
    },
    '& label': {
        fontFamily: "'Open Sans', 'sans-serif'",
        color: '#7E8389'
    },
}));

export function splitPhoneNumber(phoneNumber) {
    const prefixes = ['+7', '+370', '+371', '+372', '+375', '+380', '7', '+997'];

    if (!phoneNumber) {
        return { select: '+7', number: '' };
    }

    for (const prefix of prefixes) {
        if (phoneNumber?.startsWith(prefix)) {
            return { select: prefix, number: phoneNumber.slice(prefix.length) };
        }
    }

    return { select: '+7', number: phoneNumber }
}

export default function PhoneInput({
    phone,
    onChange,
    error,
    required,
    label,
    ...props
}) {


    const [phoneNumberValue, setPhoneNumberValue] = React.useState({ select: '+7', number: '' })

    React.useEffect(() => {
        const phoneNumber = splitPhoneNumber(phone)
        setPhoneNumberValue(prev => ({
            ...prev,
            select: phoneNumber.select,
            number: phoneNumber.number
        }))
    }, [phone])

    const onChangePrefix = (e) => {
        setPhoneNumberValue(prev => ({
            ...prev,
            select: e.target.value
        }))
        onChange(e.target.value + phoneNumberValue.number)
    }

    const onChangePhone = (e) => {
        setPhoneNumberValue(prev => ({
            ...prev,
            number: e.target.value
        }))
        onChange(phoneNumberValue.select + e.target.value)
    }

    const countriesPreffix = [
        { code: 'BY', label: 'Belarus', phone: '375' },
        { code: 'EE', label: 'Estonia', phone: '372' },
        // { code: 'KZ', label: 'Kazakhstan', phone: '7' },
        { code: 'LT', label: 'Lithuania', phone: '370' },
        { code: 'LV', label: 'Latvia', phone: '371' },
        { code: 'RU', label: 'Russian Federation', phone: '7' },
        { code: 'UA', label: 'Ukraine', phone: '380' },
        { code: 'KZ', label: 'Kazachstan', phone: '997' }
    ]

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
            <CustomSelect
                startAdornment={
                    <InputAdornment position='start'>
                        <img
                            loading='lazy'
                            width='34'
                            height='24'
                            style={{
                                borderRadius: "6px",
                                // border: '.1px solid #D4D9DE',
                            }}
                            srcSet={`https://flagcdn.com/w40/ ${countriesPreffix
                                .find(item => `+${item.phone}` === phoneNumberValue?.select)
                                ?.code?.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${countriesPreffix
                                .find(item => `+${item.phone}` === phoneNumberValue?.select)
                                ?.code?.toLowerCase()}.png`}
                            alt=''
                        />
                    </InputAdornment>
                }
                IconComponent={ExpandMore}
                variant='outlined'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={phoneNumberValue?.select}
                onChange={(e) => onChangePrefix(e)}
                {...props}
            >
                <MenuItem value={'+7'}>+7</MenuItem>
                <MenuItem value={'+370'}>+370</MenuItem>
                <MenuItem value={'+371'}>+371</MenuItem>
                <MenuItem value={'+372'}>+372</MenuItem>
                <MenuItem value={'+375'}>+375</MenuItem>
                <MenuItem value={'+380'}>+380</MenuItem>
                <MenuItem value={'+997'}>+997</MenuItem>
            </CustomSelect>
            <MuiTextField
                fullWidth
                value={phoneNumberValue?.number}
                variant='outlined'
                id='outlined-required'
                label={label}
                name='phone'
                onChange={e => {
                    onChangePhone(e)
                }}
                required={required}
                title='В номере телефона допустим ввод только цифр'
                error={!!error}
                helperText={error}
                {...props}
            />
        </div>
    )
}
