import styled from "styled-components"
import { TextField } from '@mui/material'
import { ReactComponent as Copy } from '../../../../asset/images/cabinet/Copy.svg'

const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 32px 32px 16px;
	background-color: #fff;
	width: 707px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
	gap: 16px;
	max-height: 100vh;
	overflow-y: auto;
`
const Title = styled.h4`
    font-family: Open Sans;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
`

const TextS = styled.div`
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
`

const Link = styled.div`
    font-size: 14px;
    line-height: 24px;
    color: rgba(72, 114, 242, 1);
    padding: 8px 20px;
    font-weight: 600;
    cursor: pointer;
`

// const FormInput = styled(TextField)(() => ({
//     cursor: 'pointer',
//     '& fieldset': {
//         borderRadius: '8px',
//         borderColor: 'rgba(72, 114, 242, 1)',

//     },
//     '& .MuiInputBase-adornedEnd': {
//         gap: "10px",
//         cursor: 'pointer',
//     },
//     '& .MuiInputBase-input': {
//         background: 'white',
//         fontFamily: "'Open Sans', 'sans-serif'",
//         fontSize: '20px',
//         lineHeight: '28px',
//         padding: "16px 0px 14px 16px",
//         width: "91px",
//         textAlign: 'center',
//         cursor: 'pointer',
//     },
//     '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
//         transform: 'translate(14px, -9px) scale(0.75)'
//     },

// }));

function AcceptFitClaim({ onClose }) {
    const handleClickOutSide = () => {
        onClose(false)
    }

    // async function copyTextToClipboard(text) {
    //     let id = document.getElementById('claimId');
    //     id.focus();
    //     id.select();
    //     if ('clipboard' in navigator) {
    //         return await navigator.clipboard.writeText(text);
    //     } else {
    //         return document.execCommand('copy', true, text);
    //     }
    // }
    return <PopupBackground onClick={handleClickOutSide}>
        <PopupContent onClick={(e) => e.stopPropagation()}>
            <Title>Ваша заявка принята.</Title>
            {/* <FormInput
                readOnly
                id="claimId"
                value={claimId}
                onClick={() => { copyTextToClipboard(claimId) }}
                InputProps={{
                    endAdornment: <Copy />
                }}
            /> */}
            <TextS>Ответ будет направлен на указанную в запросе почту.</TextS>
            <Link onClick={() => onClose(false)}>В личный кабинет</Link>
        </PopupContent>
    </PopupBackground>
}

export default AcceptFitClaim