/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { CloseOutlined } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Promo from '../../../Booking/components/Promo'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import axios from 'axios'
import './style.scss'
import Chart from 'react-apexcharts'
import useSearchTourStore from '../../store'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import {
	availabilityPlaceColor,
	availabilityPlace,
	chartOptions,
	formatPrice
} from './config'
import IconSuitecase from '../../../../asset/images/icon-suitecase.png'

function PricesPlacesInfo({ withFlights, handleGetOffers, onClose }) {
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const [routes, setRoutes] = useState([])
	const [pricesRange, setPricesRange] = useState([])
	const [loading, setLoading] = useState(true)
	const actions = useSearchTourStore(({ actions }) => actions)
	const item = useSearchTourStore(({ selectedTour }) => selectedTour)

	const param = {
		withFly: withFlights,
		departureCityId: searchParams.departureCityId,
		arrivalCountryId: searchParams.arrivalCountryId,
		minStartDate: item.hotel.checkInDate,
		maxStartDate: item.hotel.checkOutDate,
		minNightsCount: searchParams.minNightsCount,
		maxNightsCount: searchParams.maxNightsCount,
		adults: searchParams.adults,
		children: searchParams.children,
		isInstantConfirmation: searchParams.momentConfirm
	}
	const query = new URLSearchParams(param).toString()

	const tooltip = {
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				const currencyAlias =
					item.accommodationPriceOffer[0].fullPrice.currencyAlias
				return (
					'<div class="arrow_box">' +
					'<div class="arrow_box_title">' +
					format(parseISO(w.globals.labels[dataPointIndex]), 'dd.MM.yyyy, EE', {
						locale: ru
					})
						.slice(0, -1)
						.replace('су', 'сб') +
					'</div>' +
					'<div class="arrow_box_content">' +
					Math.round(series[seriesIndex][dataPointIndex]) +
					' ' +
					currencyAlias +
					' ' +
					formatPrice(
						series[seriesIndex][dataPointIndex],
						series[seriesIndex][0],
						currencyAlias
					) +
					'</div>' +
					'</div>'
				)
			}
		},
		yaxis: {
			labels: {
				formatter: function (val) {
					return (
						val + ' ' + item.accommodationPriceOffer[0].fullPrice.currencyAlias
					)
				}
			}
		},
		xaxis: {
			labels: {
				formatter: function (val) {
					return format(parseISO(val), 'MMM dd', { locale: ru })
				}
			}
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)

				const pricesRangeResponse = await axios.post(
					`${process.env.REACT_APP_FS_APIGATE}/TourDetails/GetPricesRange`,
					{
						departureCityId: searchParams.departureCityId,
						arrivalCountryId: searchParams.arrivalCountryId,
						minStartDate: item.hotel.checkInDate,
						maxStartDate: item.hotel.checkOutDate,
						minNightsCount: searchParams.minNightsCount,
						maxNightsCount: searchParams.maxNightsCount,
						adults: searchParams.adults,
						children: searchParams.children,
						hotelId: item.hotel.id.toString(),
						tourId: item.id.toString(),
						priceId: item.priceId,
						currencyId: searchParams.currencyId,
						searchScope: 'b2c:ru'
					}
				)

				setPricesRange(
					pricesRangeResponse.data.sort(
						(a, b) => new Date(a.checkinDate) - new Date(b.checkinDate)
					)
				)

				if (withFlights) {
					const freightsResponse = await axios.get(
						`${process.env.REACT_APP_FS_APIGATE}/TourDetails/FreightsByPacket?priceId=${item.priceId}`
					)

					setRoutes(freightsResponse.data.routes)
				}

				setLoading(false)
			} catch (error) {
				console.log(error)
				setLoading(false)
			}
		}

		fetchData()
	}, [])
	if (loading) {
		return (
			<div className='loader'>
				<CircularProgress size={120} color='primary' />
			</div>
		)
	}
	return (
		<>
			<div className='ModalOverlay' />
			<div id='PricesPlacesInfo'>
				<div className='head'>
					<div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
						Динамика цены, наличие мест
					</div>
					<div
						style={{ flex: 0.1, textAlign: 'end', cursor: 'pointer' }}
						onClick={onClose}>
						<CloseOutlined />
					</div>
				</div>
				<div className='body'>
					<table>
						<thead>
							<tr>
								<th>Заезд</th>
								<th>Тур</th>
								<th>Ночей</th>
								<th>Гостиница</th>
								<th>Питание</th>
								<th>Номер/Размещение</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{format(parseISO(item.startDate), 'dd.MM.yyyy, EE', {
										locale: ru
									})
										.slice(0, -1)
										.replace('су', 'сб')}
								</td>
								<td>
									{item.name}{' '}
									{item.accommodationPriceOffer[0].programTypeId === 114 && (
										<Promo />
									)}{' '}
								</td>
								<td className='c'>{item.hotel.nightsCount}</td>
								<td>
									<a
										href={`https://agentb2b.fstravel.com/${item.hotel.urlFriendlyName}?${query}`}
										target='_blank'
										rel='noreferrer'>
										{item.hotel.name}{' '}
										{!!item.hotel.stars && item.hotel.stars + '*'}
									</a>
								</td>

								<td>{item.accommodationPriceOffer[0].mealTypeName}</td>
								<td>{item.accommodationPriceOffer[0].room}</td>
								<td className='price'>
									<div
										className='button'
										onClick={() => handleGetOffers(item, withFlights)}>
										<AddShoppingCartIcon />
										<div>
											{item.accommodationPriceOffer[0].price.oldValue >
												item.accommodationPriceOffer[0].price.value && (
												<span className='oldPrice'>
													{item.accommodationPriceOffer[0].price.oldValue}{' '}
													{item.accommodationPriceOffer[0].price.currencyAlias}
												</span>
											)}
											{Math.round(item.accommodationPriceOffer[0].price.value)}{' '}
											{item.accommodationPriceOffer[0].price.currencyAlias}
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					{pricesRange && pricesRange.length !== 0 && (
						<Chart
							type='bar'
							options={{
								...chartOptions,
								...tooltip,
								chart: {
									events: {
										dataPointSelection: async (event, chartContext, config) => {
											const minDate =
												pricesRange[config.dataPointIndex].checkinDate
											actions.getSelectedTour(minDate, item.hotel.id)
										}
									}
								}
							}}
							series={[
								{
									data: pricesRange.map(element => {
										return {
											x: element.checkinDate,
											y: element.price.toFixed(0),
											fillColor: availabilityPlaceColor(
												element.hotelAvailablity[0]
											)
										}
									})
								}
							]}
							height={250}
						/>
					)}
					{pricesRange && pricesRange.length !== 0 && (
						<div>
							<div className='legend_hotel'>
								<div>Места в отеле</div>
								<div className='legend_symbol'>
									<span className='hotel_availability green_row' />
									<span className={'text'}> - есть</span>
								</div>
								<div className='legend_symbol'>
									<span className='hotel_availability yellow_row' />
									<span className={'text'}> - мало</span>
								</div>
								<div className='legend_symbol'>
									<span className='hotel_availability red_row' />
									<span className={'text'}> - нет</span>
								</div>
								<div className='legend_symbol'>
									<span className='hotel_availability grey_row' />
									<span className={'text'}> - по запросу</span>
								</div>
							</div>

							<div className='hotelAvailablity'>
								{pricesRange.map(element => (
									<div>
										<div className='hotelAvailablity_head'>
											{format(new Date(element.checkinDate), 'MMM dd', {
												locale: ru
											})}
										</div>
										<div
											className={`hotelAvailablity_element availablity_${element.hotelAvailablity[0]}`}
											title={availabilityPlace(element.hotelAvailablity[0])}
										/>
									</div>
								))}
							</div>
						</div>
					)}
					{withFlights && routes.length !== 0 && (
						<div>
							<div className='legend_hotel'>Места в транспорте</div>
							<div className='legend_seat'>
								<div>Места</div>
								<div className='legend_symbol'>
									<span className='seat_availability green_row' />
									<span className={'text'}> - есть</span>
								</div>
								<div className='legend_symbol'>
									<span className='seat_availability yellow_row' />
									<span className={'text'}> - мало</span>
								</div>
								<div className='legend_symbol'>
									<span className='seat_availability red_row' />
									<span className={'text'}> - нет</span>
								</div>
								<div className='legend_symbol'>
									<span className='seat_availability grey_row' />
									<span className={'text'}> - по запросу</span>
								</div>
							</div>

							<div className='freights'>
								{routes.map(({ info, freights }) => {
									return (
										<>
											<div className='freights_head'>
												{format(parseISO(info.date), 'dd.MM.yyyy, EE', {
													locale: ru
												})
													.slice(0, -1)
													.replace('су', 'сб')}{' '}
												{info.sourceTown} {' --> '}
												{info.targetTown}
											</div>
											{freights.map(item => (
												<div className='freights_body'>
													<div className='freights_block freights_img'>
														<img
															src={freights.transportCompany}
															alt={freights.transportCompany}
														/>
													</div>
													<div className='freights_block freights_content'>
														<div className='freights_name'>{item.name}</div>
														<div className='freights_time'>
															<span>{item.departure.time} </span>
															{item.departure.portAlias} {' - '}{' '}
															<span>{item.arrival.time}</span>{' '}
															{item.arrival.portAlias}{' '}
														</div>
													</div>
													<div className='freights_block freights_transport'>
														<div
															className={`freights_class availablity_${freights.status}`}>
															{freights.className}
														</div>
														<div
															className='suitecase'
															style={{ background: `url(${IconSuitecase})` }}
															title=''
														/>
													</div>
												</div>
											))}
										</>
									)
								})}
							</div>

							<div></div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default PricesPlacesInfo
