import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HistoryLog } from "../../components/ui"
import { getHistoryRequest, historyPurify } from "../../store/modules/orders"

const OrderDetail = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.orders.orderDetail.history)

  useEffect(() => {
    dispatch(getHistoryRequest())
    return () => {
      dispatch(historyPurify())
    }
  }, [])

  return <HistoryLog items={data} />
}

export default OrderDetail
