import React, { useEffect, useState } from 'react'
import useGeoStore from '../LandingPage/store'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import useHotelServicesStore from './store'
import { decodeQueryString } from '../../functions/sequelize'
import { isEmpty } from 'lodash'
import MainBanner from "../LandingPage/components/MainBanner";
import StaticBlocks from "../LandingPage/StaticBlocks";
import MainBannerSlider from "../LandingPage/components/MainBannerSlider";
// import SearchResultsWithFilters from './components/SearchResultsWithFilters'
import { isMobile } from 'react-device-detect';
import HotelServiceSearchForm from './components/SearchForm'
import MobileSearchForm from './components/SearchForm/MobileSearchForm'
import SearchResultsWithFilters from './components/SearchResultsWithFilters'
import SearchNotFound from "../../components/SearchNotFound";


function HotelServices() {
    const geo = useGeoStore(({ actions }) => actions)
    const content = useGeoStore(({ content }) => content)
    const actions = useHotelServicesStore(({ actions }) => actions)
    const [isUpdated, setIsUpdated] = useState(false)

    const { search } = useLocation()

    const excursions = useHotelServicesStore(({ excursions }) => excursions)
    const searchComplete = useHotelServicesStore(({ searchComplete }) => searchComplete)

    useEffect(() => {
        geo.getPage('/searchhotelservices').then(setIsUpdated(true))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (search) {
            const newSearchParams = decodeQueryString(search)
            if (newSearchParams.countryId) {
                actions.setCountry(Number(newSearchParams.countryId))
            }
            if (newSearchParams.departureCityId) {
                actions.setCity(Number(newSearchParams.departureCityId))
            }
            if (newSearchParams.startDate) {
                actions.setDate([
                    new Date(newSearchParams.startDate),
                    new Date(newSearchParams.startDate)
                ])
            }
            if (newSearchParams.endDate) {
                actions.setDate([
                    new Date(newSearchParams.startDate),
                    new Date(newSearchParams.endDate)
                ])
            }
            if (newSearchParams.adults) {
                actions.setAdult(Number(newSearchParams.adults))
            }
            if (newSearchParams.childrens) {
                actions.setChild(newSearchParams.childrens.split(','))
            }
            actions.postSearch().then(() => {
                actions.setResultCountry()
            })
        }
    }, [actions, search])

    if (!content || !isUpdated) return null

    return <div className='landing'>
        <Helmet title={content?.meta_title || content?.title} />
        {content.search_frame && (
            <div>
                <div className={!isMobile ? 'container' : ''}>
                    {(!excursions || (!isMobile && !!excursions)) && <HotelServiceSearchForm />}
                    {isMobile && !!excursions && <MobileSearchForm />}
                </div>
                {!isEmpty(excursions) && (
                    <div className={'container'}>
                        <SearchResultsWithFilters result={excursions} />
                    </div>
                )}
                {searchComplete && isEmpty(excursions) && <SearchNotFound title={'Нет подходящих услуг в отеле'}/>}
            </div>
        )}
        {JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner ?
            <MainBannerSlider content={JSON.parse(content.main_banner.content)} />
            :
            <MainBanner content={JSON.parse(content.main_banner.content)} />
        }
        <div className={'container'}>
            <StaticBlocks
                blocks={content?.blocks?.filter(block => block?.show_desktop)}
            />
        </div>
    </div>
}

export default HotelServices