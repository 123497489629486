import { createAction, createReducer } from "redux-act"
import { combineReducers } from "redux"
import {
  AppealListInitialState,
  AppealDetailInitialState,
  addAppealInitialState,
  APPEAL_STEP,
  CALCULATION_MODALS_INITIAL_STATE,
  DEFAULT_VISIBILITY,
  MODALS
} from "./constants"

export const setLoadingStatus = createAction("@appeals/setLoadingStatus")
export const setMessageStatus = createAction("@appeals/setMessageStatus")

export const setMessageList = createAction("@appeals/setMessageList")

export const sendMessage = createAction("@appeals/sendMessage")

export const sendMessageSuccess = createAction("@appeals/sendMessageSuccess")

export const getDetail = createAction("@appeals/getDetail")
export const getList = createAction("@appeals/getList")
export const setList = createAction("@appeals/setList")
export const getAppealsListNext = createAction("@appeals/getAppealsListNext")

export const setDetail = createAction("@appeals/setDetail")
export const detailPurify = createAction("@appeals/detailPurify")
export const addAppealRequest = createAction("@appeals/addAppealRequest")
export const addAppealSuccess = createAction("@appeals/addAppealSuccess")
export const addAppealFailure = createAction("@appeals/addAppealFailure")

export const addAppealPurify = createAction("@appeal/getAppealPurify")

export const getCalculationByTicketNumberRequest = createAction(
  "@appeals/getCalculationByTicketNumberRequest"
)
export const getCalculationByTicketNumberSuccess = createAction(
  "@appeals/getCalculationByTicketNumberSuccess"
)
export const getCalculationByTicketNumberFailure = createAction(
  "@appeals/getCalculationByTicketNumberFailure"
)
export const supportTicketCalculationPurify = createAction(
  "@appeals/supportTicketCalculationPurify"
)

export const getHistoryRequest = createAction("@appeals/getHistoryRequest")
export const getHistorySuccess = createAction("@appeals/getHistorySuccess")

export const cancelAppealRequest = createAction("@appeals/cancelAppealRequest")
export const cancelAppealSuccess = createAction("@appeals/cancelAppealRequest")


export const setCalculationModalsData = createAction('@appeals/setCalculationModalType');
export const purifyCalculationModals = createAction('@appeals/purifyCalculationModal');
export const showCalculationModal = createAction('@appeals/showCalculationModal');
export const showOnlyOneCalculationModal = createAction('@appeals/showOnlyOneCalculationModal');
export const hideCalculationModal = createAction('@appeals/hideCalculationModal');
export const applyCalculationRequest = createAction('@appeals/applyCalculationRequest');
export const applyCalculationSuccess = createAction('@appeals/applyCalculationSuccess');
export const applyCalculationFailure = createAction('@appeals/applyCalculationFailure');
export const declineCalculationRequest = createAction('@appeals/declineCalculationRequest');
export const declineCalculationSuccess = createAction('@appeals/declineCalculationSuccess');
export const declineCalculationFailure = createAction('@appeals/declineCalculationFailure');


const history = createReducer({}, [])
history.on(getHistorySuccess, (_, payload) => payload)

const status = createReducer({}, null)
status.on(setLoadingStatus, (_, payload) => payload)

const messageStatus = createReducer({}, null)
status.on(setMessageStatus, (_, payload) => payload)

const list = createReducer({}, AppealListInitialState)
list.on(setList, (_, payload) => ({ ...payload, isLoading: false }))
list.on(getAppealsListNext, state => ({ ...state, isLoading: true }))

const messagesList = createReducer({}, [])
messagesList.on(setMessageList, (_, payload) => payload)
messagesList.on(detailPurify, () => [])

const detail = createReducer({}, AppealDetailInitialState)
detail.on(setDetail, (_, payload) => payload)
detail.on(detailPurify, () => AppealDetailInitialState)

const messages = combineReducers({
  items: messagesList,
  status: messageStatus
})

const addAppeal = createReducer({}, addAppealInitialState)
addAppeal.on(addAppealRequest, state => ({
  ...state,
  STEP: APPEAL_STEP.REQUEST
}))
addAppeal.on(addAppealSuccess, (_, payload) => ({
  STEP: APPEAL_STEP.SUCCESS,
  response: payload
}))
addAppeal.on(addAppealFailure, () => ({
  STEP: APPEAL_STEP.FAILURE,
  response: null
}))
addAppeal.on(addAppealPurify, () => addAppealInitialState)

const supportTicketCalculation = createReducer({}, null)

supportTicketCalculation.on(
  getCalculationByTicketNumberSuccess,
  (_, payload) => payload
)

supportTicketCalculation.on(supportTicketCalculationPurify, () => null)

const chatLoadings = createReducer({}, false)
chatLoadings.on(sendMessage, () => true)
chatLoadings.on(sendMessageSuccess, () => false)


const calculationModals = createReducer({},CALCULATION_MODALS_INITIAL_STATE);
calculationModals.on(setCalculationModalsData,(state,payload) => ({...state,data: payload}))
calculationModals.on(purifyCalculationModals,() => CALCULATION_MODALS_INITIAL_STATE)
calculationModals.on(showCalculationModal,(state,payload) => ({...state,visibility: {...state.visibility,[payload] : true}}))
calculationModals.on(showOnlyOneCalculationModal,(state,payload) => ({...state,visibility: {...DEFAULT_VISIBILITY,[payload] : true}}))
calculationModals.on(hideCalculationModal,(state,payload) => ({...state,visibility: {...state.visibility,[payload] : false}}))
calculationModals.on(applyCalculationRequest,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.LOADING]: true} }))
calculationModals.on(applyCalculationSuccess,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.CONFIRM_SUCCESS]: true} }))
calculationModals.on(applyCalculationFailure,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.FAILURE]: true} }))
calculationModals.on(declineCalculationRequest,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.LOADING]: true} }))
calculationModals.on(declineCalculationSuccess,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.DECLINE_SUCCESS]: true} }))
calculationModals.on(declineCalculationFailure,(state) => ({...state, visibility: {...DEFAULT_VISIBILITY,[MODALS.FAILURE]: true} }))


export default combineReducers({
  status,
  messages,
  list,
  detail,
  addAppeal,
  supportTicketCalculation,
  history,
  chatLoadings,
  calculationModals
})
