import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Containter = styled.div`
  padding: 32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: #FFFFFF;
  min-width: 553px;
  box-sizing: border-box;
  color: #3C3C3C;
  align-self: baseline;
  @media (max-width: 767px) {
    padding: 14px;
    min-width: calc(100% - 28px);
  }
`;

const Title = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

const SubTitle = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const Ul = styled.ul`
  margin-top: 20px;
  padding-left: 16px;

  & > li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
`;

export default function RequestError()  {
  return (
    <Wrapper>
      <Containter>
        <Title>Билеты не найдены</Title>
        <SubTitle>Это могло произойти из-за следующих причин:</SubTitle>
        <Ul>
          <li>На эти даты все билеты куплены. Попробуйте поискать на другие даты.</li>
          <li>Неправильно выбраны даты. Проверьте год вылета и прилета.</li>
          <li>В этот аэропорт не летают самолёты.</li>
        </Ul>
      </Containter>
    </Wrapper>
  );
};
