import React from "react"
import styled from "styled-components"
import { DatePicker, Field } from "../../ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
`

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const BottomLabel = styled(Label)`
  margin-bottom: 0;
  margin-top: 4px;
  max-width: 256px;
  white-space: pre-line;
`

const BigBottomLabel = styled(BottomLabel)`
  max-width: 532px;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 35px;
`

const FieldWrapper = styled.div`
  position: relative;
  margin-right: 20px;

  @media (max-width: 1146px) {
    margin-bottom: 20px;
  }
`

const StyledField = styled(Field)`
  width: 256px;
  height: 40px;
  @media (max-width: 1146px) {
    width: 335px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const SuperBigField = styled(Field)`
  //margin-top: 20px;
  width: 532px;
  height: 40px;
  @media (max-width: 1146px) {
    width: 335px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const StyledPicker = styled(DatePicker)`
  width: 256px;
  height: 40px;
  margin-right: 20px;
`

const AdditionalFields = props => {
  const { handleBlur, handleChange, values, setFieldValue } = props

  return (
    <Container>
      <Row>
        <FieldWrapper>
          <StyledField
            label="Страны вылета"
            name="departureCountry"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.departureCountry ?? ""}
          />
          <BottomLabel>
            Оставьте поле пустым, если параметр не имеет значения
          </BottomLabel>
        </FieldWrapper>
        <FieldWrapper>
          <StyledField
            label="Страны прилета"
            name="arrivalCountry"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.arrivalCountry ?? ""}
          />
          <BottomLabel>
            Оставьте поле пустым, если параметр не имеет значения
          </BottomLabel>
        </FieldWrapper>
      </Row>
      <Row>
        <StyledPicker
          value={values.departureStart}
          onChange={date => {
            setFieldValue("departureStart", date)
          }}
          label="Начало вылета"
        />
        <StyledPicker
          value={values.departureEnd}
          onChange={date => {
            setFieldValue("departureEnd", date)
          }}
          label="Конец вылета"
        />
      </Row>
      <Row>
        <FieldWrapper style={{ marginTop: 20 }}>
          <SuperBigField
            label="Маршруты"
            name="route"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.route ?? ""}
          />
          <BigBottomLabel>
            Цепочка из IATA кодов городов или аэропртов, описывающая весь
            маршрут перелета с учетом пересадок, без разрывов и повторов.
            <br />
            <br />
            Всегда следует использовать IATA код города, если он имеется
            (агрегирующий аэропорт).
            <br /> <br />К примеру перелет VKO-ORY, ORY-VKO приводится к
            маршруту MOW-PAR-MOW.
          </BigBottomLabel>
        </FieldWrapper>
      </Row>
    </Container>
  )
}

export default AdditionalFields
