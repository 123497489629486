import './style.scss'
import {Link} from "react-router-dom";

function StaticButton({ content, title, id }) {
    return <div className='static-btn-wrapper'id={id} >
        <h3>{title}</h3>
        <div className='static-btn'>
            {content?.button?.link ?
                <Link type="button" className='static-btn-link' to={content?.button?.link}>{content?.button?.text}</Link>
                :
                <div className='static-btn-link'>{content?.button?.text}</div>
            }
        </div>
    </div>
}

export default StaticButton