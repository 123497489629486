/* eslint-disable no-unused-vars */
import { put, call, select } from "redux-saga/effects"
import { InsuranceLoadStatus } from "../../const"
import { bookTicket } from "../workers"
import { setStatus, showInsuranceNotification } from "../../duck"
import {
  bookingInsuranceSelector,
  getBookingTicketState
} from "../../selectors"
import {showNotification} from "../../../notification";

//ANCHOR bookFlightSaga используется когда пользователь заполняет данные о пассажирах, опциях на странице /booking и хочет его забронировать
export function* bookFlightRequestFlow({ payload }) {
  yield put(setStatus(true))
  try {
    const insurances = yield select(bookingInsuranceSelector)
    const ticketState = yield select(getBookingTicketState)

    if (
      ticketState.insuranceNotification !== false &&
      insurances.status === InsuranceLoadStatus.Success &&
      insurances.list.offers.filter(x => x.checked).length === 0
    ) {
      yield put(showInsuranceNotification())
    } else {
      yield call(bookTicket, payload)
    }
  } catch (e) {
    yield put(setStatus(false))
    yield put(showNotification({
      code: e.code,
      data: e.data,
      booking: payload,
    }));
  }
  yield put(setStatus(false))
}
