import React, {useState} from 'react';
// import useBookingStore from "../../store";
import {Box} from "@mui/material";

const Commission = ({price, currency, partnerCommission, nativeCurrencyPrice}) => {

	const [isOpen, setOpen] = useState(false)

	// const partnerCommission = useBookingStore(state => state.partnerCommission)

	return (
		<div style={{margin: '0 auto', textAlign: 'center', marginBottom: 20}}>
			<div onClick={() => setOpen(!isOpen)} style={{cursor: 'pointer'}}>Комиссия (детали)</div>
			{isOpen && <div style={{width: 400, backgroundColor: '#F6F9FB', margin: '10px auto 0 auto'}}>
				<Box pb={1} pt={1} sx={{borderBottom: '1px solid #888888'}}>
					<div>Сумма к оплате {Math.round(nativeCurrencyPrice.net)} {currency}</div>
				</Box>
				<Box pb={1} pt={1} sx={{borderBottom: '1px solid #888888', backgroundColor: '#e2f3fe'}}>
					<div style={{fontWeight: 'bold'}}>Комиссия партнёра {partnerCommission}%</div>
				</Box>
				<Box pb={1} pt={1} sx={{borderBottom: '1px solid #888888'}}>
					<div>Сумма комиссии {Math.round(nativeCurrencyPrice.sumCommission)} {currency}</div>
				</Box>
			</div>}
		</div>
	);
};

export default Commission;