import React from 'react'

import styled from 'styled-components'
import { InputAdornment } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@mui/material/Collapse'
import DateInputRange from '../components/DateInputRange/index'
import { ReactComponent as SearchIcon } from '../../../../asset/images/cabinet/search.svg'
import useClaimsStore from '../store'
import { useNavigate } from 'react-router-dom'
import AutocompleteSelect from './AutocompleteSelect'
import { Button } from '@mui/material'
import { styled as styledMUI } from '@mui/material'

export const FormInput = styled(TextField)(() => ({
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: "'Open Sans', 'sans-serif'",
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.75)'
	},
	'& label': {
		fontFamily: "'Open Sans', 'sans-serif'",
		color: '#7E8389'
	}
}))

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid #dbe0e5;
	background: #fff;
	margin-bottom: 24px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	color: #2e2e32;
`

const FilterContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const FilterRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 24px;
	margin-top: 16px;
`

const FilterRowContainer = styled(FilterRow)`
	min-width: 602px;
	margin-top: 0px;
`

export const MyButton = styledMUI(Button)({
	marginTop: '16px',
	borderRadius: '8px',
	background: '#4872f2',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '10px 20px',
	border: '1px solid',
	height: '48px',
	lineHeight: '24px',
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont'].join(','),
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const claimsStatusOptions = [
	{ id: 1, title: 'Все' },
	{ id: 2, title: 'Актуальные' },
	{ id: 3, title: 'Не подтвержденные' },
	{ id: 4, title: 'Предварительные' },
	{ id: 6, title: 'Отмененные' }
]
const paymentStatusOptions = [
	{ id: 1, title: 'Оплачена' },
	{ id: 2, title: 'Частичная оплата' },
	{ id: 3, title: 'Не оплачена' },
	{ id: 4, title: 'С просроченной оплатой' },
	{ id: 5, title: 'С переплатой' }
]

export default function ClaimFilter() {
	const [isOpen, setIsOpen] = React.useState(true)
	const filterConfig = useClaimsStore(state => state.filterConfig)
	const actions = useClaimsStore(state => state.actions)

	const selectCountries = useClaimsStore(
		({ selectCountries }) => selectCountries
	)
	const selectOperatorNames = useClaimsStore(
		({ selectOperatorNames }) => selectOperatorNames
	)
	const selectManagerNames = useClaimsStore(
		({ selectManagerNames }) => selectManagerNames
	)

	const navigate = useNavigate()

	const handleClick = () => {
		if (filterConfig.isButtonApply) {
			navigate(window.location.pathname)
			actions.getClaims(1)
			actions.setFilterConfig('isButtonApply', false)
		} else {
			navigate(window.location.pathname)
			actions.clearFilterConfig()
		}
	}

	return (
		<Container>
			<Row onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
				<FlexRow>
					<Title>Фильтр</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => setIsOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<FilterContent>
					<FilterRow>
						<FilterRowContainer>
							<FormInput
								value={filterConfig.searchValue}
								onChange={event =>
									actions.setFilterConfig('searchValue', event.target.value)
								}
								fullWidth
								variant='outlined'
								label='Поиск'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<SearchIcon />
										</InputAdornment>
									),
									disableUnderline: 'true'
								}}
							/>
						</FilterRowContainer>
						<DateInputRange
							onChange={value => actions.setFilterConfig('arrivalDate', value)}
							label='Дата заезда'
							value={filterConfig.arrivalDate}
						/>
					</FilterRow>
					<FilterRow>
						<FilterRowContainer>
							<AutocompleteSelect
								label='Статус заявки'
								value={filterConfig.status}
								options={claimsStatusOptions}
								onChange={actions.setFilterConfig}
								propId='status'
							/>
							<AutocompleteSelect
								label='Статус оплаты'
								value={filterConfig.paymentStatus}
								options={paymentStatusOptions}
								onChange={actions.setFilterConfig}
								propId='paymentStatus'
							/>
						</FilterRowContainer>

						<DateInputRange
							fullWidth
							onChange={value =>
								actions.setFilterConfig('reservationDate', value)
							}
							label='Дата создания'
							value={filterConfig.reservationDate}
						/>
					</FilterRow>

					<FilterRow>
						<AutocompleteSelect
							label='Страна пребывания'
							value={filterConfig.country}
							options={selectCountries}
							onChange={actions.setFilterConfig}
							propId='country'
						/>
						<AutocompleteSelect
							label='Менеджер Агенства'
							value={filterConfig.operatorName}
							options={selectOperatorNames}
							onChange={actions.setFilterConfig}
							propId='operatorName'
						/>
						<AutocompleteSelect
							label='Менеджер оператора'
							value={filterConfig.managerName}
							options={selectManagerNames}
							onChange={actions.setFilterConfig}
							propId='managerName'
						/>
					</FilterRow>
					{/* <FilterRow>
                        <FormControll
                            control={
                                <CustomizedCheckbox
                                // checked={onlineAccess}
                                // onChange={handleChangeAccess}
                                />
                            }
                            label="Показать заявки с новыми сообщениями в первую очередь"
                        />
                    </FilterRow> */}

					<MyButton
						disabled={!filterConfig.isFilterActive}
						variant='contained'
						color='primary'
						fullWidth
						onClick={handleClick}>
						{filterConfig.isButtonApply ? 'Применить' : 'Сбросить'}
					</MyButton>
				</FilterContent>
			</Collapse>
		</Container>
	)
}
