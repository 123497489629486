import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import {
    // BrowserRouter as Router,
    RouterProvider,

} from "react-router-dom";
import mainRoutes from './routes/mainRoutes';
import { Provider } from 'react-redux';
import store from './store/configs'
import { registerLocale,setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru)
setDefaultLocale('ru')

function App() {
    return (
        <Provider store={store}>
            <RouterProvider router={mainRoutes}/>
        </Provider>
    );
}
export default App;
