import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { Text } from '../ui';
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../ui/PrimaryButton';
import OutlinedButton from '../ui/OutlinedButton';

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      padding: 20px;
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 26px;
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
`;

const BlueText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
  font-weight: 600;
`;

const ActionsBlock = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const StyledPrimaryButton = MUIstyled(PrimaryButton)(()=>({
  fontSize: '14px',
  lineHeight: '25px',
  padding: '8px 30.5px',
}))

const StyledOutlinedButton = MUIstyled(OutlinedButton)(()=>({
  fontSize: '14px',
  lineHeight: '25px',
  padding: '8px 30.5px',
}))


const Modal = ({
  onConfirm,
  title,
  subtitle,
  buttonsText,
  data,
  ...props
}) => {

  return (
    <StyledPopup {...props}>
      <Title>{title}</Title>
      <Question>
        {subtitle}{' '}
        {data
          .filter(
            (x) =>
              (x.haveEmptySelectedPlaces !== undefined &&
                x.haveEmptySelectedPlaces) ||
              (x.fulfilled !== undefined && !x.fulfilled)
          )
          .map((x, key, arr) => {
            if (key === 0) {
              return (
                <React.Fragment key={x.id}>
                  <BlueText>{x.name || x.route}</BlueText>
                </React.Fragment>
              );
            }
            if (key === arr.length - 1) {
              return (
                <React.Fragment key={x.id}>
                  {' '}
                  и <BlueText>{x.name || x.route}</BlueText>
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={x.id}>
                {' '}
                ,<BlueText>{x.name || x.route}</BlueText>
              </React.Fragment>
            );
          })}
      </Question>
      <ActionsBlock>
        <StyledPrimaryButton onClick={onConfirm} >
          {buttonsText[0]}
        </StyledPrimaryButton>
        <StyledOutlinedButton onClick={props.onClose} >
          {buttonsText[1]}
        </StyledOutlinedButton>
      </ActionsBlock>
    </StyledPopup>
  );
};

export default Modal;
