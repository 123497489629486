
const BASE_IMAGE_URL = process.env.REACT_APP_FS_IMAGE_URL + 'geocontent/static/Country'

function CountryItem({ content }) {
    const imageUrl = `${BASE_IMAGE_URL}/${content.id}/${content.countryFlagFileName}`

    const url = window.location.protocol + '//' + window.location.host + '/' + content.urlName

    return (
        <div>
            <a className='item-link' href={url}>
                <div className="item-flag" style={{ backgroundImage: `url(${imageUrl})` }}>
                </div>
                <div>
                    {content.name}
                </div>
            </a>
        </div>
    )
}

export default CountryItem