import FetchApi from '../../../functions/fetchApi'

const dataCollection = {};

export const getCityListByStr = async (
  value,
  abortController
) => {
  try {
    if (dataCollection[value]) return dataCollection[value];
    const params = {};

    if(abortController) {
      params.signal = abortController.signal;
    }

    const response = await FetchApi.get( process.env.REACT_APP_CITIES_SEARCH, params )
      .query({value})
      .setContentType('application/json')
      .send();

    dataCollection[value] = response;
    return dataCollection[value];
  } catch(e) {
    return {data: []}
  }
};

export const getCityByCode =  value => {
  return FetchApi.get(process.env.REACT_APP_GET_AIRPORT_BY_CODE)
    .query({value})
    .setContentType('application/json')
    .send();
};
