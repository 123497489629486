import { memo, useEffect, useState } from 'react'
import useGetFiltersHotels from '../../../hooks/useGetFiltersHotels.js'
import useSearchTourStore from '../../SearchTour/store.js'
import './style.css'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import { decodeQueryString } from '../../../functions/sequelize'
import memoizeOne from 'memoize-one'
import { FixedSizeList as List, areEqual } from 'react-window'
import HotelRow from '../../../components/Card/HotelRow.js'
import {isMobile} from "react-device-detect";

const createItemData = memoizeOne((items, changeStatus, hotelIds) => ({
	items,
	changeStatus,
	hotelIds
}))

const ListHotels = () => {
	const hotelSearch = useSearchTourStore(state => state.hotelSearch)

	const { search } = useLocation()
	const hotels = decodeQueryString(search)

	const filteredHotels = useGetFiltersHotels()
	const [showFilter, setShowFilter] = useState(!!(search && hotels.hotelIds))
	const { clearFilterHotel, addHotels, filterHotel, clearFilterHotelName } =
		useSearchTourStore(state => state.actions)
	const hotelIds = useSearchTourStore(state => state.hotelIds)

	const findHotel = event => {
		filterHotel(event.target.value.toLowerCase())
		if (showFilter) {
			setShowFilter(false)
		}
	}

	const changeStatus = id => {
		addHotels(id)
	}

	const showCheckedHotels = () => {
		setShowFilter(prev => !prev)
		clearFilterHotelName()
	}

	const resetHotelFilter = event => {
		if (event.target.checked) clearFilterHotel()
		clearFilterHotelName()
		setShowFilter(false)
	}

	useEffect(() => {
		if (isEmpty(hotelIds)) {
			setShowFilter(false)
		}
	}, [hotelIds])

	const itemData = createItemData(filteredHotels, changeStatus, hotelIds)
	const showFilterItemData = createItemData(
		filteredHotels?.filter(hotel => hotelIds.includes(hotel.id.toString())),
		changeStatus,
		hotelIds
	)

	return (
		<div style={{ width: !isMobile ? '45%' : '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginRight: !isMobile ? '10' : '0'
				}}>
				<div>
					<span style={{ marginRight: 10 }}>Гостиница</span>
					<input
						type='text'
						value={hotelSearch}
						onChange={findHotel}
						style={{ transform: 'translateY(-2px)' }}
					/>
				</div>
				<label htmlFor='showHotelFilter'>
					<input
						id='showHotelFilter'
						type='checkbox'
						checked={showFilter}
						onChange={showCheckedHotels}
					/>
					Выбранные
				</label>
				<label htmlFor='resetHotelFilter'>
					<input
						id='resetHotelFilter'
						type='checkbox'
						checked={!hotelIds.length}
						onChange={resetHotelFilter}
					/>
					Любая
				</label>
			</div>
			{showFilter ? (
				<List
					style={{
						overflowX: 'hidden',
						border: '1px solid #ccc',
						marginRight: !isMobile ? '10' : '0'
					}}
					itemData={showFilterItemData}
					innerElementType='div'
					itemCount={
						filteredHotels?.filter(hotel => hotelIds.includes(hotel.id.toString())).length
					}
					itemSize={20}
					height={200}
					overscanCount={5}>
					{memo(({ data, index, style }) => {
						const { items, changeStatus, hotelIds } = data
						const item = items[index]
						return (
							<div style={style}>
								<HotelRow
									item={item}
									hotelIds={hotelIds}
									changeStatus={changeStatus}
								/>
							</div>
						)
					}, areEqual)}
				</List>
			) : (
				<List
					style={{
						overflowX: 'hidden',
						border: '1px solid #ccc',
						marginRight: !isMobile ? '10' : '0'
					}}
					itemData={itemData}
					innerElementType='div'
					itemCount={filteredHotels.length}
					itemSize={20}
					height={200}
					overscanCount={5}>
					{memo(({ data, index, style }) => {
						const { items, changeStatus, hotelIds } = data
						const item = items[index]
						return (
							<div style={style}>
								<HotelRow
									item={item}
									hotelIds={hotelIds}
									changeStatus={changeStatus}
								/>
							</div>
						)
					}, areEqual)}
				</List>
			)}
		</div>
	)
}

export default ListHotels
