import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SortDown } from '../../../../asset/images/cabinet/sort-down.svg'
import useCommissionStore from '../store'

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 24px;
`
const SortItem = styled(Row)`
	align-items: center;
	gap: 8px;
	cursor: pointer;
	font-family: 'Open Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: ${({ $active }) => ($active ? '#2E2E32' : '#7E8389')};
	&:hover {
		color: #4872f2;
	}
`

const SortDownBtn = styled(SortDown)`
	fill: ${({ $active }) => ($active ? '#2E2E32' : '#7E8389')};
	transition: all 0.2s ease-in-out;
	&:hover {
		fill: #4872f2;
	}
`

export default function SortingBlock() {
	const actions = useCommissionStore((state) => state.actions)
	const activeSort = useCommissionStore((state) => state.activeSort)

	const haddleSetActive = (value) => {
		actions.setActiveSort(value)
	}

	return (
		<Row>
			<SortItem
				$active={activeSort === 'сity' || activeSort === 'сity-decrease'}
				onClick={() => haddleSetActive(activeSort === 'сity' ? 'сity-decrease' : 'сity')}>
				<SortDownBtn 
					$active={activeSort === 'сity' || activeSort === 'сity-decrease'} 							
					style={	activeSort === 'сity' ? { transform: 'rotate(180deg)' } : null }/>
				Страна
			</SortItem>
			<SortItem
				$active={activeSort === 'commmission' || activeSort === 'commmission-decrease'}
				onClick={() => haddleSetActive(activeSort === 'commmission' ? 'commmission-decrease' : 'commmission')}>
				<SortDownBtn 
					$active={activeSort === 'commmission' || activeSort === 'commmission-decrease'} 							
					style={	activeSort === 'commmission' ? { transform: 'rotate(180deg)' } : null }/>
				Комиссия
			</SortItem>
		</Row>
	)
}
