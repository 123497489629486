import React, { useState, useEffect } from 'react';

import './style.css'
import { format, parseISO } from "date-fns";
import OptionsBlock from "./OptionsBlock";
import InsurancesModal from './InsurancesModal'
import useBookingStore from "../../store";
import { Button } from "@material-ui/core";

export const INSURANCE_LINK = 'https://fstravel.com/searchinsurances'

const sortArr = (a, b) => {
	if (a.name.includes("Ингосстрах") && !b.name.includes("Ингосстрах")) {
		return 1
	}
	if (!a.name.includes("Ингосстрах") && b.name.includes("Ингосстрах")) {
		return -1
	}
	return b.type.id - a.type.id
}
export const getInsuranceProgram = (insurance) => {
	return insurance.filter(el => !el.parentUid)
}

const InsurancesBlock = ({ insurances }) => {
	const services = useBookingStore(({ selectedServices }) => selectedServices)
	const isInsurancesSelect = useBookingStore(({ isInsurancesSelect }) => isInsurancesSelect)
	const medicalGroups = useBookingStore(({ medicalGroups }) => medicalGroups)
	const actions = useBookingStore(({ actions }) => actions)

	const selectedInsurances = services.filter(i => medicalGroups.some(el => el.id === i.groupId))

	const [modalVisible, setVisible] = useState(false)
	const [filteredInsurances, filterInsurances] = useState(getInsuranceProgram([...selectedInsurances, ...insurances]).sort(sortArr).filter(item => item.name.includes('BOX') || item.name.includes('Ингосстрах')))

	const getInsuranceOptions = (uid) => {
		const insurance = [...selectedInsurances, ...insurances]
		return insurance.filter(el => el?.parentUid === uid)
	}

	const renderButton = (id) => {
		const isIncluded = selectedInsurances.some(i => i.uid === id)
		if (isIncluded) {
			return <Button onClick={
				() => { actions.removeInsurance(id) }
			} variant='contained' color='secondary' fullWidth>Удалить</Button>
		} else {
			return <Button onClick={() => actions.addInsurance(id)} variant='contained' color='primary' fullWidth>Добавить</Button>
		}
	}

	const onShowModal = () => setVisible(prev => !prev)

	useEffect(() => {
		if (isInsurancesSelect) {
			filterInsurances(getInsuranceProgram(selectedInsurances).sort(sortArr))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInsurancesSelect, services])

	return (
		<div>
			<div className="block-header">
				<h3 className="title">Страхование</h3>
			</div>

			<div className="block-body">
				<div className="grid-insurances header">
					<div>Название</div>
					<div>Даты</div>
					<div></div>
					<div className="paxes">Количество</div>
					<div></div>
				</div>

				<div>
					{filteredInsurances.map((item) =>
						<React.Fragment>
							<div key={item.uid} className="grid-insurances body">
								<div>
									<a
										className='insurance-link'
										href={item?.url ?? INSURANCE_LINK}
										target='_blank'
										rel='noreferrer'
									>
										{item.name.replace(': ', '')}
									</a>
								</div>
								<div>{format(parseISO(item.datebeg), 'dd.MM.yyyy')} - {format(parseISO(item.dateend), 'dd.MM.yyyy')}</div>
								<div>{!item.required && `+${item.price} ${item.currencyAlias}`}</div>
								<div className="paxes">{item.clients.length}</div>
								{item.remove && <div>
									{renderButton(item.uid)}
								</div>}
							</div>
							<OptionsBlock
								key={item.uid + 'options'}
								options={getInsuranceOptions(item.uid)}
								date={format(new Date(item.datebeg), 'dd.MM.yyyy') + " - " + format(new Date(item.dateend), 'dd.MM.yyyy')}
								isInclude={selectedInsurances.some(el => el.uid === item.uid)}
							/>
						</React.Fragment>
					)}
				</div>
				<div className='button-change'> <Button onClick={onShowModal} variant="contained" color="primary">Изменить страховки</Button></div>
				{modalVisible &&
					<InsurancesModal
						onClose={onShowModal}
					/>}
			</div>
		</div>
	);
};

export default InsurancesBlock;