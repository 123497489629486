import * as React from 'react'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList } from 'react-window'
import Typography from '@mui/material/Typography'
import useAuthStore from '../../../../Auth/store'
import { ExpandMore } from '@material-ui/icons'
import { FormInput } from './EditAgentPopup'

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
	const { data, index, style } = props
	const dataSet = data[index]
	const inlineStyleHeader = {
		...style,
		top: style.top + LISTBOX_PADDING,
		fontWeight: 600,
		color: '#2e2e32',
		fontSize: '16px'
	}
	const inlineStyle = {
		...style,
		top: style.top + LISTBOX_PADDING
	}

	if (dataSet.hasOwnProperty('group')) {
		return (
			<ListSubheader
				key={dataSet.key}
				component='div'
				style={inlineStyleHeader}>
				{dataSet.group}
			</ListSubheader>
		)
	}

	return (
		<Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
			{`${dataSet[1]}`}
		</Typography>
	)
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext)
	return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
	const ref = React.useRef(null)
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true)
		}
	}, [data])
	return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
	props,
	ref
) {
	const { children, ...other } = props
	const itemData = []
	children.forEach(item => {
		itemData.push(item)
		itemData.push(...(item.children || []))
	})

	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true
	})
	const itemCount = itemData.length
	const itemSize = smUp ? 36 : 48

	const getChildSize = child => {
		if (child.hasOwnProperty('group')) {
			return 48
		}

		return itemSize
	}

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
	}

	const gridRef = useResetCache(itemCount)

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width='100%'
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType='ul'
					itemSize={index => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	)
})

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		fontFamily: 'Open Sans',
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0
		},
		'&::-webkit-scrollbar': {
			backgroundColor: '#dbe0e5',
			width: '5px'
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 0,
			boxShadow: `inset 0 1px 1px #fff`,
			background: '#4872f2'
		}
	}
})

export default function TownsSelect() {
	const selectTowns = useAuthStore(({ selectTowns }) => selectTowns)
	const [inputValue, setInputValue] = React.useState('')
	const actions = useAuthStore(({ actions }) => actions)
	const currentTown = useAuthStore(({ currentTown }) => currentTown)
	return (
		<Autocomplete
			id='virtualize-demo'
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			value={currentTown || ''}
			onChange={(event, newValue) => {
				actions.setTown(newValue)
			}}
			PopperComponent={StyledPopper}
			ListboxComponent={ListboxComponent}
			options={selectTowns}
			popupIcon={<ExpandMore />}
			sx={{
				width: '100%',
				'& .MuiAutocomplete-endAdornment': { paddingRight: '8px' }
			}}
			noOptionsText={'Совпадений не найдено'}
			groupBy={option => option.country}
			getOptionLabel={option => option.title || ''}
			renderInput={params => <FormInput {...params} label='Город' />}
			renderOption={(props, option) => [props, option.title]}
			renderGroup={params => params}
		/>
	)
}
