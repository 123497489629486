import  React, { useState, useEffect  } from 'react'
import useAuthStore from '../../../Auth/store'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSelect from '../components/CustomSelect/CustomSelect';
import PhoneInput  from '../../AgentInfo/components/PhoneInputNew/PhoneInput'
import { MuiTextField } from '../components/MuiTextField/MuiTextField'
import {splitPhoneNumber} from '../../AgentInfo/components/PhoneInputNew/PhoneInput'
import {emailRegExp, issuedByRegExp} from '../../../../constants/reg-exps'
import './style.scss'

function Contacts() {
    const [showBlock, setShowBlock] = useState(false)
    const agents = useAuthStore(({ agent }) => agent?.agents)
    const validateErrors = useAuthStore(({ validateErrors }) => validateErrors)
    const actions = useAuthStore(({ actions }) => actions)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
    
    const townsValues = useAuthStore((state) => state.towns);

    useEffect(() => {
        actions.getTowns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOptions = (arr) => {
        if (arr?.length) {
            return arr.map((item) => {
                return { label: item.title, value: item.id }
            })
        }
    }


	const changePhoneHandler = prop => value => {
        const phoneWithoutPrefix = splitPhoneNumber(value).number
        actions.setValidateErrors(prop, false )
        if (phoneWithoutPrefix.length < 6) {
            actions.setValidateErrors(prop, 'Длина номера минимум 6 цифр' )
            return
        } 
		if (/^[0-9]+[0-9]*$/.test(phoneWithoutPrefix) || !phoneWithoutPrefix) {
            actions.setValidateErrors(prop, false )
            actions.changeContactInfo(prop, value)
		} else {
             actions.setValidateErrors(prop, 'Неверный формат телефона'  )
		} 
	}

    const changeAdditionalPhoneHandler = prop => value => {
        const phoneWithoutPrefix = splitPhoneNumber(value).number
        actions.setValidateErrors(prop, false )
        if (phoneWithoutPrefix.length < 6 && phoneWithoutPrefix !== '') {
            actions.setValidateErrors(prop, 'Длина номера минимум 6 цифр' )
            return
        } 
		if (/^[0-9]+[0-9]*$/.test(phoneWithoutPrefix) || phoneWithoutPrefix === '') {
            actions.setValidateErrors(prop, false )
            actions.changeContactInfo(prop, value)
		} else {
             actions.setValidateErrors(prop, 'Неверный формат телефона'  )
		} 
	}

    const changeFAddressHandler = prop => event => {
        actions.setValidateErrors(prop, false )

        if (!issuedByRegExp.test(event.target.value) && event.target.value !== '') {
            actions.setValidateErrors(prop,  'Введен некорректный символ')

        } else {
            actions.setValidateErrors(prop, false )
            actions.changeContactInfo(prop, event.target.value)
        }
    }

    const changeEmailHandler = prop => event => {
		actions.setValidateErrors(prop, false )

		if (!emailRegExp.test(event.target.value) || event.target.value === '') {
            actions.setValidateErrors(prop, 'Неверный формат электронной почты' )

		} else {
		    actions.setValidateErrors(prop, false )
		}
        actions.changeContactInfo(prop, event.target.value)
	}

    const changeAdditionalEmailHandler = prop => event => {
		actions.setValidateErrors(prop, false )

		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
            actions.setValidateErrors(prop, 'Неверный формат электронной почты' )

		} else {
		    actions.setValidateErrors(prop, false )
		}
        actions.changeContactInfo(prop, event.target.value)
	}

    const changeAddressCodeHandler = prop => event => {
        actions.setValidateErrors(prop, false )

		if (!/^\d+$/.test(event.target.value) && event.target.value !== '') {
            actions.setValidateErrors(prop, 'Введен некорректный символ' )
		} else {
            actions.setValidateErrors(prop, false )
            actions.changeContactInfo(prop, event.target.value)
		}
	}

    const changeAccountNameHandler = prop => event => {
        actions.setValidateErrors(prop, false )

		if (!/^[а-яА-ЯёЁa-zA-Z\s-']+$/.test(event.target.value) && event.target.value !== '') {
			actions.setValidateErrors(prop, 'Введен некорректный символ' )

		} else {
            actions.setValidateErrors(prop, false )
            actions.changeContactInfo(prop, event.target.value)
		}
	}

    if (!agents) return null
    
    return (
        <div 

        className='cabinet-contacts-block'
        >
            <div className='v-dropdown'>
                <div className="cabinet-contacts-block__title">Для связи</div>
                <div>
                {showBlock ? (
                        <ExpandLessIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => setShowBlock(!showBlock)}
                            type="button"
                            className="newagency-close-btn"
                        />
                    ) : (
                        <ExpandMoreIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => setShowBlock(!showBlock)}
                            type="button"
                            className="newagency-open-btn"
                        />
                    )}
                </div>
            </div>
            {showBlock &&
                <div style={{ marginTop: '24px' }}>
                    <div className='contacts-grid__fullWith'>
                        <CustomSelect
                            label='Город'
                            required
                            value={agents?.town.id}
                            options={getOptions(townsValues)}
                            onChange={value => {
                                actions.setValidateErrors('town', false )
                                actions.changeAgentTown(value)
                                }
                            }
							isDisabled={agentInfo?.agents?.name.includes('F&S')}
                        />
                    </div>
                    <div className='contacts-grid__fullWith'>
                         <MuiTextField
                            label='Фактический адрес'
                            required
                            fullWidth
                            variant='filled'
                            value={agents?.fAddress}
                            className={!agents?.fAddress ? 'empty-input' : ''}
                            onChange={changeFAddressHandler('fAddress')}  
                            error={!!validateErrors?.fAddress}   
                            helperText={validateErrors?.fAddress}   
                            onBlur={changeFAddressHandler('fAddress')}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                         />
                    </div>
                    <div className='contacts-grid__fullWith'>
                         <MuiTextField
                            label='Почтовый индекс'
                            required
                            fullWidth
                            variant='filled'
                            value={agents?.addressCode}
                            onChange={changeAddressCodeHandler('addressCode')} 
                            error={!!validateErrors?.addressCode}
                            helperText={validateErrors?.addressCode}
                            onBlur={changeAddressCodeHandler('addressCode')}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                         />
                    </div>
                    <div className='contacts-grid'>
                        <PhoneInput
                                onChange={changePhoneHandler('phone')}
                                phone={agents?.phone}
                                required
                                error={validateErrors?.phone}
                                label={'Телефон'}
								disabled={agentInfo?.agents?.name.includes('F&S')}
                        />
              
                        <MuiTextField
                            label='Email для заявки'
                            required
                            fullWidth
                            variant='filled'
                            value={agents?.email}
                            onChange={ changeEmailHandler('email') }
                            error={!!validateErrors?.email} 
                            helperText={validateErrors?.email}
                            onBlur={changeEmailHandler('email')}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                        />
                    </div>
                    <div className='contacts-grid'>
                        <PhoneInput
                            onChange={changeAdditionalPhoneHandler('phone1')}
                            phone={agents?.phone1} 
                            error={validateErrors?.phone1}
                            label={'Доп. телефон'}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                        />
                        <MuiTextField
                            label='Email для рассылки'
                            fullWidth
                            variant='filled'
                            value={agents?.email1}
                            onChange={ changeAdditionalEmailHandler('email1') }
                            error={validateErrors?.email1}
                            helperText={validateErrors?.email1}
                            onBlur={changeAdditionalEmailHandler('email1')}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                        />
                    </div>

                    <div className='contacts-grid__fullWith' style={{marginBottom: 0}}>
                         <MuiTextField
                            label='ФИО бухгалтера'
                            fullWidth
                            variant='filled'
                            value={agents?.accountName}
                            onChange={changeAccountNameHandler('accountName')} 
                            error={validateErrors?.accountName}
                            helperText={validateErrors?.accountName}
                            onBlur={changeAccountNameHandler('accountName')}
							disabled={agentInfo?.agents?.name.includes('F&S')}
                         />
                    </div>
                </div>
            }
        </div>
    )
}

export default React.memo(Contacts) 