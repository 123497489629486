import { CardActionArea, Grid } from '@mui/material'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import plug from '../../../../asset/images/plug.png'

const Tile = styled.div`
	height: 298px;
	border-radius: 16px;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
	width: 100%;
	object-fit: cover;
	position: relative;
`
const Title = styled.div`
	position: absolute;
	color: ${({ $isEmpty }) => ($isEmpty ? '#ffffff' : '#2E2E32')};
	width: 100%;
	padding: 0 16px;
	bottom: 16;
	word-wrap: break-word;
	text-align: start;
	font-size: 18px;
	line-height: 26px;
	font-weight: 600;
`

export default memo(function TilesItem({ content }) {
	return (
		<Grid item xs={4}>
			{content?.link ? (
				<CardActionArea
					sx={{
						width: '100%',
						height: '298px',
						borderRadius: 4
					}}>
					<Link to={content?.link || ''} target={content?.link ? '_blank' : ''}>
						<Tile
							style={{
								background: `url(${content?.image ? content?.image : plug})`
							}}>
							<Title $isEmpty={!!content?.image}>{content?.title}</Title>
						</Tile>
					</Link>
				</CardActionArea>
			) : (
				<Tile
					style={{
						background: `url(${content?.image ? content?.image : plug})`
					}}>
					<Title $isEmpty={!!content?.image}>{content?.title}</Title>
				</Tile>
			)}
		</Grid>
	)
})
