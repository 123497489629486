import React, { useCallback, useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import './style.css'
import { Alert, Button, Collapse, Grid, Paper, Snackbar } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import DocumentItem from './components/DocumentItem'
import { isEmpty, uniqBy } from 'lodash'
import useDocumentsStore from './store'
import { ReactComponent as Upload } from '../../../../asset/icons/upload.svg'

const UploadButton = styledMUI(Button)({
	borderRadius: '8px',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '4px 24px 4px 24px',
	border: '1px solid',
	height: '48px',
	lineHeight: 1.5,
	borderColor: '#4872f2',
	fontFamily: ['Open Sans', '-apple-system', 'Roboto'].join(','),
	'&:hover': {
		backgroundColor: '#ffffff',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: 'transparent',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const VisuallyHiddenInput = styledMUI('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
})

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
	margin-bottom: 24px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`

export default function UploadDocuments() {
	const [isOpen, setIsOpen] = React.useState(true)
	const [drag, setDrag] = useState(false)
	const [selectedFiles, setSelectedFiles] = useState([])
	const actions = useDocumentsStore(({ actions }) => actions)
	const loading = useDocumentsStore(({ loading }) => loading)
	const [openMessage, setOpenMessage] = React.useState(false)
	const error = useDocumentsStore(({ error }) => error)

	const handleClick = () => {
		setOpenMessage(true)
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpenMessage(false)
	}

	const [openStatus, setOpenStatus] = React.useState(false)

	const handleClickStatus = () => {
		setOpenStatus(true)
	}

	const handleCloseStatus = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpenStatus(false)
	}

	useEffect(() => {
		if (!!error) {
			handleClickStatus()
			setSelectedFiles([])
		}
	}, [error])

	const handleChange = event => {
		const files = [...event.target.files]
		if (files.some(file => file.size > 30000000)) {
			const newFiles = files.filter(file => file.size < 30000000)
			setSelectedFiles(prev => uniqBy([...prev, ...newFiles], 'name'))
			handleClick()
		} else {
			setSelectedFiles(prev => uniqBy([...prev, ...files], 'name'))
		}
	}

	const handleDelete = useCallback(name => {
		setSelectedFiles(prev => [...prev].filter(file => file.name !== name))
	}, [])

	const onDragStartHandler = event => {
		event.preventDefault()
		setDrag(true)
	}

	const onDragLeaveHandler = event => {
		event.preventDefault()
		setDrag(false)
	}

	const onDropHandler = event => {
		event.preventDefault()
		const files = [...event.dataTransfer.files]
		if (files.some(file => file.size > 30000000)) {
			const newFiles = files.filter(file => file.size < 30000000)
			handleClick()
			setSelectedFiles(prev => uniqBy([...prev, ...newFiles], 'name'))
		} else {
			setSelectedFiles(prev => uniqBy([...prev, ...files], 'name'))
		}
		setDrag(false)
	}

	const handleUpload = () => {
		actions.uploadDocuments(selectedFiles)
		// setSelectedFiles([])
	}

	return (
		<>
			<Container>
				<Row onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
					<FlexRow>
						<Title>Загрузка документов</Title>
					</FlexRow>
					<ExpandMoreIcon
						onClick={() => setIsOpen(!isOpen)}
						style={isOpen ? { transform: 'rotate(180deg)' } : null}
					/>
				</Row>
				<Collapse in={isOpen}>
					<>
						<Paper
							elevation={drag ? 3 : 0}
							style={{
								backgroundColor: 'transparent',
								padding: '24px 16px',
								border: '1px solid #D4D9DE',
								marginTop: '16px',
								marginBottom: '24px',
								borderRadius: '8px'
							}}
							onDragStart={onDragStartHandler}
							onDragLeave={onDragLeaveHandler}
							onDragOver={onDragStartHandler}
							onDrop={onDropHandler}>
							<Grid container rowSpacing={2}>
								<Grid item xs={12} align='center'>
									<span className='upload-text'>
										Перетащите документ сюда
										<br />
										(форматы: png, jpeg, jpg, pdf, размер файла - не более 30Mb)
									</span>
								</Grid>
								<Grid item xs={12} align='center'>
									<span className='upload-text'>или</span>
								</Grid>
								<Grid item xs={12} align='center'>
									<UploadButton
										component='label'
										variant='outlined'
										startIcon={<AddIcon style={{ fontSize: 24 }} />}>
										Загрузите документ
										<VisuallyHiddenInput
											type='file'
											accept='.png,.jpg,.jpeg,.pdf,.docx'
											multiple
											onChange={handleChange}
										/>
									</UploadButton>
								</Grid>
							</Grid>
						</Paper>
						<Grid container columnSpacing={3} rowSpacing={3}>
							{selectedFiles &&
								selectedFiles.map(file => (
									<DocumentItem
										key={file.name}
										file={file}
										handleDelete={handleDelete}
									/>
								))}
						</Grid>
						{!isEmpty(selectedFiles) && (
							<UploadButton
								disabled={loading}
								fullWidth
								sx={{ mt: 3, height: '40px' }}
								onClick={handleUpload}
								startIcon={
									<Upload
										className={loading ? 'upload-icon-disabled' : 'upload-icon'}
									/>
								}>
								Отправить
							</UploadButton>
						)}
					</>
				</Collapse>
			</Container>

			<Snackbar
				open={openMessage}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
					Размер файла - не более 30Mb
				</Alert>
			</Snackbar>

			<Snackbar
				open={openStatus}
				autoHideDuration={6000}
				onClose={handleCloseStatus}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert
					onClose={handleCloseStatus}
					severity={error === 200 ? 'success' : 'error'}
					sx={{ width: '100%' }}>
					{error === 200 ? 'Документы успешно отправлены' : error}
				</Alert>
			</Snackbar>
		</>
	)
}
