import React, { useEffect } from 'react'
import './style.css'
import { format } from 'date-fns'
import { Button } from '@material-ui/core'
import useBookingStore from '../../store'
import useLoyaltyStore from "../../../Loyalty/store";
import { useErrorStore } from '../../../Partner/Insurances/Booking/components/MembersBlock/store'
import Commission from "../Commission";

export default function ButtonsBlock({ response }) {
	const { forwardFlightId, backwardFlightId, selectedFlightOptionId } =
		useBookingStore()
	const oldPrice = useBookingStore(state => state.oldPrice)
	const phoneErrorGds = useBookingStore(({ phoneErrorGds }) => phoneErrorGds)
	const buyerErrors = useBookingStore(({ buyerErrors }) => buyerErrors)
	const buyer = useBookingStore(({ buyer }) => buyer)
	const birthDayError = useErrorStore(({ birthDayError }) => birthDayError)
	const currencys = useBookingStore(({ currencys }) => currencys)
	const actions = useBookingStore(({ actions }) => actions)
	const loading = useBookingStore(({ loading }) => loading)
	const paxes = useBookingStore(({ paxes }) => paxes)

	const preorder = useLoyaltyStore(({ preorder }) => preorder)
	const memberId = useLoyaltyStore(({ memberId }) => memberId)
	const client = useLoyaltyStore(({ client }) => client)
	const spentPoints = useLoyaltyStore(({ spentPoints }) => spentPoints)

	const price = preorder?.order?.totalPrice ? preorder?.order?.totalPrice : response?.price?.amount ?? 0

	useEffect(() => {
		actions.setNewPrice()
	}, [forwardFlightId, backwardFlightId, actions, selectedFlightOptionId])

	const getRates = () => {
		if (response.nativeCurrencyPrice.currencyId === 1) {
			return 1
		}
		let currency = null
		Object.values(currencys).forEach(item => {
			const foundCurrency = item.find(value => value.currencyKey === response.nativeCurrencyPrice.currencyId);
			if (foundCurrency) {
				currency = foundCurrency.rate;
			}
		})
		if (currency) {
			return currency
		}
	}

	const RUSSIA_ID = 210357
	const isBuyerExist = response?.tour?.hotels[0]?.country?.id !== RUSSIA_ID

	const isPhoneNumbersFilled = paxes.every(el => el.person.phone)

	const isBuyerFildsWithErrors = () => {
		if (!isBuyerExist) {
			return false
		}
		const isBuyerPasspostFilled = buyer.number && buyer.pserie
		const isBuyerErrors = Object.values(buyerErrors).some(el => !!el)

		return !isBuyerPasspostFilled || isBuyerErrors
	}
	return (
		<>
			<div className={`price-amount ${oldPrice ? 'stroke' : ''}`}>
				<div className='eur'>
					{Math.round(price / getRates())}{' '}
					{response.nativeCurrencyPrice.currencyAlias}
				</div>
				{response.price.currencyAlias !==
					response.nativeCurrencyPrice.currencyAlias && (
						<div className='rub'>
							{Math.round(price)} {response.price.currencyAlias} (курс на{' '}
							{format(new Date(), 'dd.MM.yyyy')})
						</div>
					)}
			</div>
			{!oldPrice && (
				<Commission
					price={Math.round(price / getRates())}
					currency={response.nativeCurrencyPrice.currencyAlias}
					partnerCommission={response.comissionPercent}
					nativeCurrencyPrice={response.nativeCurrencyPrice}
				/>
			)}
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				<Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={actions.tourRecount}>
					Пересчитать
				</Button>
				<Button
					variant='contained'
					onClick={() => actions.reserve({ memberId: memberId, client: client, spentPoints: spentPoints, preorder: preorder })}
					disabled={oldPrice || phoneErrorGds || loading || birthDayError || !isPhoneNumbersFilled || isBuyerFildsWithErrors()}>
					Бронировать
				</Button>
			</div>
		</>
	)
}
