import React, {useState} from 'react';

const TilesIcon = ({ item }) => {

	const [isHover, setIsHover] = useState(false);
	const [colorHover, setColorHover] = useState();

	const onMouseOver = (color) => {
		setIsHover(true)
		let newOpacity = color.color.split(',')
		newOpacity[newOpacity.length-1] = '0.55)'
		setColorHover(newOpacity.join())
	}

	return (
		<div className={'container-tiles-icons__item'}>
			<a
				className={'container-tiles-icons__item-icon'}
				href={item.link}
				style={{backgroundColor: isHover ? colorHover : item.color}}
				target={'_blank'}
				rel={'noreferrer'}
				onMouseOver={() => onMouseOver(item)}
				onMouseLeave={() => setIsHover(false)}
			>
				<span style={{background: `url(${item.img}) center center / contain no-repeat`}}></span>
			</a>
			<div className={'container-tiles-icons__item-text'}>
				{item.title} {item.subTitle}
			</div>
		</div>
	);
};

export default TilesIcon;