import React, { useRef, useState, useEffect } from 'react';
import './style.scss'
import { InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import iconSearch from '../../../../asset/images/chosen-sprite.png'
import { isEmpty } from "lodash";
import { latinRegExp, cyrillicRegExp } from '../../../../constants/reg-exps'
import { convertTo } from '../../../../functions/convertLang'

const FromFieldCity = ({ lists, onChange, title, value }) => {
	const [showBody, setShowBody] = useState(false)
	const [inputValue, setInputValue] = useState(lists.find(item => item.id.toString() === value.toString()) ? lists.find(item => item.id.toString() === value.toString())?.name : lists[0]?.name)
	const [selectValue, setSelectValue] = useState(isEmpty(lists)
		? 'Москва'
		: lists.find(item =>
			item.id.toString() === value.toString())
			? lists.find(item => item.id.toString() === value.toString())?.name
			: lists[0]?.name)
	const [useFilter, setUseFilter] = useState(false)
	const ref = useRef(null);
	const textInput = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const selectedElementRef = useRef(null);

	useEffect(() => {
		function handleClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [ref]);

	useEffect(() => {
		if (textInput.current) {
			textInput.current.focus();
		}
	}, [textInput, showBody])


	useEffect(() => {
		if (selectedElementRef.current) {
			selectedElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	}, [selectedIndex]);

	useEffect(() => {
		if (!showBody) {
			setSelectedIndex(-1)
		}
		if (showBody && value) {

			let targetIndex = -1;
			filtered.findIndex((country) => {
				const foundIndex = country.cites.findIndex((city) => city.id.toString() === value.toString());
				if (foundIndex !== -1) {
					targetIndex += foundIndex + 1;
					return true;
				}
				targetIndex += country.cites.length;
				return false;
			});

			setSelectedIndex(targetIndex)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showBody, selectValue])

	useEffect(() => {
		if (!isEmpty(lists)) {
			if (lists.find(item => item?.id?.toString() === value?.toString())) {
				setSelectValue(lists.find(item => item?.id?.toString() === value?.toString()).name)
			} else {
				setSelectValue(lists[0].name)
			}
		} else {
			setSelectValue('Москва')
		}
	}, [lists, value])

	if (!lists) return null

	const toggle = () => {
		setUseFilter(false)
		setShowBody(!showBody)
	}

	const handleChange = (id) => {
		const item = lists.find(item => item.id === id)
		setShowBody(false)
		setSelectValue(item?.name)
		setUseFilter(false)
		onChange(item?.id)
	}


	const changeInput = (e) => {
		setInputValue(e.target.value)
		setUseFilter(true)
	}

	const handleKeyPress = (e) => {
		let length = filtered.reduce((acc, country) => acc + country.cites.length, 0) - 1;
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			setSelectedIndex((prevIndex) => (prevIndex < length ? prevIndex + 1 : 0));
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : length));
		} else if (e.key === 'Enter') {
			e.preventDefault();
			if (selectedIndex !== -1) {
				const selectedItem = lists.find(i => i.name === selectedElementRef?.current?.innerText);
				handleChange(selectedItem.id);
			}
		}
		if(e.key === "Tab"){
			setShowBody(false)
		}
	};
	
	const handleOpen = e => {
		if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
		  e.preventDefault();
		 setShowBody(true)
		} 
	  }

	const getFiltered = () => {
		let result
		if (!inputValue || !useFilter) {
			result = lists
		} else {
			result = lists.filter(el => {
				let value = inputValue.toLowerCase()
				let name = el.name ? el.name.toLowerCase() : null
				let nameAlt = el.nameAlt ? el.nameAlt.toLowerCase() : null

				if (!name) {
					return false
				}
				if (latinRegExp.test(value) && (name.includes(convertTo('ru', value)) || nameAlt?.includes(convertTo('ru', value)))) {
					return true
				}
				if (cyrillicRegExp.test(value) && (name.includes(convertTo('eng', value)) || nameAlt?.includes(convertTo('eng', value)))) {
					return true
				}
				if (nameAlt?.includes(value)) return true
				if (name.includes(value)) return true
				return false
			})
		}
		result = result.reduce((acc, city) => {
			const key = city.stateFromNameAlt;
			if (!acc[key]) {
				acc[key] = { name: city.stateFromName, cites: [] };
			}
			acc[key].cites.push(city);
			return acc;
		}, {});

		return Object.values(result)
	}

	const filtered = getFiltered()

	return (
		<div className="v-departure-base" ref={ref}>
			<div className="v-departure">
				<div onClick={toggle}>
					<div>
						<TextField
							id={'departure'}
							label={title}
							variant="outlined"
							fullWidth
							autoComplete={'off'}
							value={selectValue}
							onKeyDown={handleOpen}
							InputProps={{
								endAdornment: <InputAdornment position="end"><ArrowDropDownIcon /></InputAdornment>,
							}}
						/>
					</div>
				</div>
				{showBody && <div className="v-departure__body-container">
					<div className={'chosen-search'}>
						<input
							type="text"
							onChange={changeInput}
							autoComplete={'off'}
							className={'chosen-search__field'}
							ref={textInput}
							onKeyDown={handleKeyPress}
							style={{ backgroundImage: `url(${iconSearch})` }}
						/></div>
					<div className="v-departure__body">

						{useFilter && isEmpty(filtered) && <div className={'empty-search'}>Совпадений не найдено</div>}
						{filtered.map((country, indexCountry) => {
							return (<>
								<div
									key={country.name}
									className={`v-departure__elem-grouped-title v-text-16`}
								>
									{country.name}
								</div>
								{country.cites.map((city, indexCity) => {
									const index = indexCountry ? filtered[indexCountry - 1]?.cites?.length + indexCity : indexCity
									return (
										<div
											key={city.id}
											className={`v-departure__elem v-text-16`}
											onClick={() => handleChange(city.id)}
											style={index === selectedIndex ? { backgroundColor: '#EDF1FE', paddingLeft: '25px' } : { paddingLeft: '25px' }}
											tabIndex={index}
											ref={index === selectedIndex ? selectedElementRef : null}
										>
											{city.name}
										</div>)
								}
								)}
							</>
							)
						})}
					</div>
				</div>}
			</div>
		</div>
	);
};

export default FromFieldCity;

