import { Box, Dialog } from '@material-ui/core'
import React from 'react'
import useGeoMenuStore from '../../CompanyPopup/store'
import MobileList from '../../Footer/MobileList'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'

export default function MobileCompanyPopup({ open, onClose, setOpenDrawer }) {
	const contentMenu = useGeoMenuStore(({ contentMenu }) => contentMenu)
	return (
		<Dialog fullScreen open={open} onClose={onClose}>
			<div className='header__submenu-return'>
				<ArrowBackIosRoundedIcon
					onClick={onClose}
					fontSize='small'
					style={{ marginRight: '16px' }}
				/>
				<span className='v-text-links'>Информация для агентов</span>
			</div>
			<Box sx={{ pl: 3, pr: 3 }}>
				<ul className='footer-list'>
					{contentMenu?.map(item => (
						<MobileList
							item={item}
							key={item.id}
							setOpenDrawer={setOpenDrawer}
							onClose={onClose}
						/>
					))}
				</ul>
			</Box>
		</Dialog>
	)
}
