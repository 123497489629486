import React from 'react';
import styled from 'styled-components';
import {ReactComponent as ArrowSVG} from "../../../asset/images/avia/agents/openArrow.svg"

const Arrow = styled(ArrowSVG)` 
  transition: transform .5s, fill .2s;
  fill: #737373;
  user-select: none;
  transform: rotateX(0);
`

const ButtonContainer = styled.button.attrs({type: 'button'})`
  background: #edf1fe;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: background .2s, box-shadow .2s;
//   box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.18);

  &:hover {
    background: #e3e9ff;
    box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.18);
    ${Arrow} {
        // fill: #474646;
    }
  }

  ${(props) => props.isActive ? `
    ${Arrow} {
        transform: rotateX(180deg);
    }
  ` : ''}
`

export default function ArrowButton(props) {
    return (
        <ButtonContainer {...props}>
            <Arrow/>
        </ButtonContainer>
    )
}