import React from "react"
import styled from "styled-components"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 400px;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const Title = styled.div`
  font-family: "Open Sans", serif;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px !important;
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  margin-left: 20px;
  display: block;
  margin-bottom: 30px !important;
`
const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const ReportError = ({ errorModal, setErrorModal }) => {
  return (
    <StyledPopup onClose={() => setErrorModal(false)} open={errorModal}>
      <CloseButton
        aria-label="Закрыть окно"
        onClick={() => setErrorModal(false)}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Title>Внимание</Title>
        <Text> За данный период заявки не найдены.</Text>
      </Container>
    </StyledPopup>
  )
}

export default ReportError
