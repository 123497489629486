import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Item from "./Item"

const Container = styled.div`
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);

  @media (max-width: 1169px) {
    padding: 20px;
  }

  @media (max-width: 769px) {
    padding: 20px 14px;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 19px;
  row-gap: 20px;

  @media (max-width: 1169px) {
    display: block;

    & > div {
      margin-bottom: 20px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`

const Title = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #3c3c3c;
  margin-bottom: 12px;
`

const Description = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  display: block;
  margin-bottom: 20px;
`

export default function Calculations() {
  const data = useSelector(s => s.orders.orderDetail.activeCalulations)
  const { id } = useParams()
  const navigate = useNavigate()
  const handleGoInsideAppeal = data => {
    navigate(`/cabinet/tickets/${data.number}`)
  }
  const mergeData = React.useCallback(
    data => {
      if (data && data.items) {
        const test = data?.items.map(item =>
          Object.entries(item)
            // eslint-disable-next-line array-callback-return
            .map(subItem => {
              if (subItem[1] !== null) {
                return { [subItem[0]]: subItem[1] }
              }
            })
            .filter(Boolean)
        )
        if (test.flat().length !== 0) {
          const result = Object.assign(
            test?.flat()?.find(item => item?.additionalServivesInfo)
              ? test?.flat()?.find(item => item?.additionalServivesInfo)
              : {},
            test?.flat()?.find(item => item?.insuranceInfo)
              ? test?.flat()?.find(item => item?.insuranceInfo)
              : {}
          )
          if (
            test.flat().find(item => item?.supportTicketCalculation)
              ?.supportTicketCalculation?.supportTicketCalculation?.status
              ?.code === "WaitingForApproval"
          ) {
            return [result]
              .concat([
                test.flat().find(item => item?.supportTicketCalculation)
              ])
              .filter(Boolean)
          } else if (
            test.flat().find(item => item?.supportTicketCalculation)
              ?.supportTicketCalculation?.supportTicketCalculation?.status
              ?.code === "ApprovedByClient"
          ) {
            return [
              Object.assign(
                result,
                test.flat().find(item => item?.supportTicketCalculation)
              )
            ]
          } else return [result]
        } else return []
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.items]
  )


  const handlePay = ({ s, a, c }) => {
    if (s) {
      navigate(
        `/redirect-payment?orderId=${s.orderNumber}&calculationId=${s.supportTicketCalculation.id}`
      )
    } else if (a) {
      navigate(
        `/redirect-payment?orderId=${id}${a.additionalServices
          ?.map(x => `&orderPositionId=${x.id}`)
          .join("")}`
      )
    } else if (c) {
      navigate(
        `/redirect-payment?orderId=${id}${c.insurances
          ?.map(x => `&orderPositionId=${x.insurancePositionId}`)
          .join("")}`
      )
    }
  }

  if ((data.items || []).length <= 0) {
    return null
  }

  const mergedData = mergeData(data)

  
  return (
    <Container>
      <Title>Расчеты по заявке</Title>
      <Description>
        У вас есть несогласованные или неоплаченные расчеты по заявке. Отмените
        расчет если он неактуален для вас.
      </Description>
      {Array.isArray(mergedData) && !!mergedData?.length && (
        <Content>
          {mergedData.map((x, key) => (
            <Item
              key={
                x.supportTicketCalculation?.id
                  ? x.supportTicketCalculation?.id +
                    x.supportTicketCalculation.status.code
                  : key
              }
              {...x}
              onGoInsideAppeal={handleGoInsideAppeal}
              onPay={handlePay}
            />
          ))}
        </Content>
      )}
    </Container>
  )
}
