import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/Close'
import useBookingStore from '../../../Booking/store'
import './style.css'
import { Grid } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Box } from '@material-ui/core'
import FlightIcon from '@material-ui/icons/Flight'
import { Security } from '@material-ui/icons'
import { House } from '@material-ui/icons'
import { CreateOutlined } from '@material-ui/icons'
import useSearchTourStore from '../../store'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}))

export const ServiceItem = ({ title }) => {
	const services = useBookingStore(({ services }) => services)

	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const currencyAlias = useSearchTourStore(({ currencyAlias }) => currencyAlias)
	const filters = useSearchTourStore(({ filters }) => filters)

	const priceInCurrency = value => {
		const currentCurrencyId = Number(value.currencyId)
		const ratesToCurrencyId = Number(searchParams.currencyId)
		if (currentCurrencyId !== ratesToCurrencyId) {
			const ratesTo = filters?.currencies
				.find(item => item.id === currentCurrencyId)
				?.rates.find(item => item.id === ratesToCurrencyId)?.value
			return {
				value: value.amount * ratesTo
			}
		} else {
			return { value: value.amount }
		}
	}
	return (
		<>
			{!!services?.filter(
				service =>
					service.name.split(':')[0] === title &&
					service.name.split(':')[0] !== 'Топливный сбор' &&
					service.name.split(':')[0] !== 'Трансфер'
			).length && (
				<>
					<div className='grid-avia header'>
						<div>{title}</div>
					</div>
					<Box sx={{ mb: 2 }}>
						{services
							?.filter(
								service =>
									service.name.split(':')[0] === title &&
									service.name.split(':')[0] !== 'Топливный сбор' &&
									service.name.split(':')[0] !== 'Трансфер'
							)
							.map((service, i) => (
								<Grid
									container
									spacing={0}
									className={i % 2 ? 'block-bodys' : 'block-bodys odd-color'}
									key={i}>
									<Grid item xs={3}>
										{service.startDate === service.endDate
											? format(parseISO(service.startDate), 'dd.MM.yyyy')
											: `${format(
													parseISO(service.startDate),
													'dd.MM.yyyy'
											  )} - ${format(
													parseISO(service.endDate),
													'dd.MM.yyyy'
											  )}`}
									</Grid>
									<Grid item xs={7}>
										<div
											style={{
												display: 'flex',
												paddingRight: '10px',
												alignItems: 'center'
											}}>
											<CreateOutlined
												fontSize='small'
												style={{ marginRight: '10px' }}
											/>
											{service.name
												.split(': ')
												.filter((item, i) => i !== 0)
												.join(': ')}
										</div>
									</Grid>
									<Grid item xs={2}>
										{Math.round(priceInCurrency(service?.price)?.value)}{' '}
										{currencyAlias}
									</Grid>
								</Grid>
							))}
					</Box>
				</>
			)}
		</>
	)
}

const TripItem = ({ service, i, tours }) => {
	return (
		<>
			{!service.name.includes('undefined') ? (
				<Grid
					container
					spacing={0}
					className={i % 2 ? 'block-bodys' : 'block-bodys odd-color'}
					key={i}>
					<Grid item xs={3}>
						{service?.startDate === service?.endDate
							? format(parseISO(service?.startDate), 'dd.MM.yyyy')
							: `${format(
									parseISO(service?.startDate),
									'dd.MM.yyyy'
							  )} - ${format(parseISO(service?.endDate), 'dd.MM.yyyy')}`}
					</Grid>
					<Grid item xs={9}>
						{service.name.includes('*') ? (
							<div className='container-name'>
								<House fontSize='small' style={{ marginRight: '10px' }} />
								<a
									href={`https://agentb2b.fstravel.com/${tours?.hotel?.urlFriendlyName}`}
									target='_blank'
									rel='noreferrer'
									style={{
										textDecoration: 'none',
										color: '#000000'
									}}>
									{service.name}
								</a>
							</div>
						) : service?.name?.includes('Медицинская') ? (
							<div className='container-name'>
								<Security fontSize='small' style={{ marginRight: '10px' }} />
								<a
									href={`${service.url}`}
									target='_blank'
									rel='noreferrer'
									style={{
										textDecoration: 'none',
										color: '#000000'
									}}>
									{service?.name}
								</a>
							</div>
						) : service?.name?.includes('ECONOM') ? (
							<div className='container-name'>
								<FlightIcon fontSize='small' style={{ marginRight: '10px' }} />
								{service.name}
							</div>
						) : (
							<div className='container-name'>
								<CreateOutlined
									fontSize='small'
									style={{ marginRight: '10px' }}
								/>
								{service.name}
							</div>
						)}
					</Grid>
				</Grid>
			) : null}
		</>
	)
}

export const TripPackage = ({ tours }) => {
	const tour = useBookingStore(({ tour }) => tour)
	const services = useBookingStore(({ services }) => services)
	const insurances = useBookingStore(({ insurances }) => insurances)
	const packages = React.useMemo(() => {
		const arr = []
		insurances?.forEach(item => {
			if (item.isRequired) {
				arr.push(item)
			}
		})
		arr.push({
			name:
				tour?.hotel.name +
				' ' +
				tours?.hotel?.stars +
				'*' +
				', ' +
				tour?.hotel.room.name +
				', ' +
				tour?.hotel.mealType.name,
			startDate: tour?.accommodation.beginDate,
			endDate: tour?.accommodation.endDate
		})
		services?.forEach(item => {
			if (item.isRequired) {
				if (item.startDate === tour?.accommodation.beginDate) {
					arr.unshift(item)
				}
				if (item.startDate === tour?.accommodation.endDate) {
					arr.push(item)
				}
			}
		})
		arr.unshift({
			name:
				tour?.departureTown?.name +
				' -> ' +
				tour?.hotel?.city?.name +
				', ECONOM',
			startDate: tour?.accommodation?.beginDate,
			endDate: tour?.accommodation?.beginDate
		})
		arr.push({
			name:
				tour?.hotel?.city?.name +
				' -> ' +
				tour?.departureTown?.name +
				', ECONOM',
			startDate: tour?.accommodation.endDate,
			endDate: tour?.accommodation.endDate
		})
		return arr
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box sx={{ mb: 2 }}>
				{packages.map((service, index) => (
					<TripItem service={service} key={index} i={index} tours={tours} />
				))}
			</Box>
		</>
	)
}

export default function ServicesPackageInfo({ open, handleClose, tour }) {
	const services = useBookingStore(({ services }) => services)
	const servicesList = React.useMemo(() => {
		return [...new Set(services?.map(service => service.name.split(':')[0]))]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [services])

	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				scroll={'paper'}
				maxWidth={'lg'}
				fullWidth>
				<DialogContent>
					<div
						className='block-header'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<h3 className='title'>{'Состав пакета'}</h3>
						<IconButton aria-label='close' onClick={handleClose} size='small'>
							<CloseOutlinedIcon />
						</IconButton>
					</div>
					<TripPackage title={'Услуги в аэропорту'} tours={tour} />
					{!!servicesList.length && (
						<>
							<div
								className='block-header'
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
								<h3 className='title'>{'Дополнительные услуги'}</h3>
							</div>
							{servicesList.map((item, i) => (
								<ServiceItem title={item} key={i} />
							))}
						</>
					)}
				</DialogContent>
				<DialogActions></DialogActions>
			</BootstrapDialog>
		</div>
	)
}
