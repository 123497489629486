import React from 'react';
import AgentLogTable from '../../components/avia-agents/agent-users/AgentLogTable';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider'
import { getAgentLogRequest } from "../../store/modules/agents/agent-users/duck"
import { getUserState } from "../../store/modules/user";
import { useSelector, useDispatch } from "react-redux"

const Loader = () => (
  <>
    <Skeleton width={235} sx={{ fontSize: '18px', lineHeight: '120%', marginBottom: '25px' }}/>
    <Skeleton variant='rectangular' height={70} sx={{ }}/>
    <Skeleton variant='rectangular' height={44.500} sx={{margin: '17px 0'}}/>
    <Divider sx={{ borderColor: '#dcdcdc'}}/>
    <Skeleton variant='rectangular' height={44.500} sx={{margin: '17px 0'}}/>
    <Divider sx={{ borderColor: '#dcdcdc'}}/>
    <Skeleton variant='rectangular' height={44.500} sx={{margin: '17px 0'}}/>
    <Divider sx={{ borderColor: '#dcdcdc'}}/>

  </>
)

const AgentHistory = () => {
  const dispatch = useDispatch()

  const agentLog = useSelector(state => state.agents.agentLog)
  const isLoading = useSelector(state => state.agents.agentInfoLoadingState)


  const userState = useSelector(getUserState)

  React.useEffect(() => {
    dispatch(getAgentLogRequest({ AgentId: userState?.data?.agentId }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  return (
    <>
      {isLoading && <Loader/>}
      {!isLoading && <AgentLogTable logs={agentLog}/>}
    </>
  );
};

export default AgentHistory;
