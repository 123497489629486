import './style.scss';
import { format } from 'date-fns';

function LandingAnnounce({ content, title, countryId }) {

    const currentDate = new Date()
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)
    const searchTourLink =  window.location.origin + `/search_tour?clientId=b2b%3Aru&lang=ru&departureCityId=274286&arrivalCountryId=${countryId}&adults=2&minStartDate=${format(endDate, 'yyyy-MM-dd')}&maxStartDate=${format(endDate, 'yyyy-MM-dd')}&minNightsCount=7&maxNightsCount=7&currencyId=1&noStopOfSales=true&withoutPromo=false&hideToursWithoutFreights=true&momentConfirm=false&includePriorityFilter=false&searchScope=b2b`

    return (
        <div className="announce-container">
            <div className="announce-img"></div>
            <div className="announce-content">
                {content.headText && <h4 className='announce-subtitle'>{content.headText}</h4>}
                {title && <h2 className='announce-title'>{title}</h2>}
                {content.desc && <div className='announce-text' dangerouslySetInnerHTML={{ __html: content.desc }}/> }  
                <div className='announce-buttons'>
                    <a href={searchTourLink} className='btn-find'>Найти тур</a>
                    {content.linkAboutCountry && <a href={content.linkAboutCountry} className='btn-more'>Читать о стране</a>}
                </div>
            </div>
        </div>
    );
}

export default LandingAnnounce
