import React from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import { styled as MUIStyled } from '@mui/material/styles';


const LogoWrapper = styled.div`
  display: inline;
  height: 20px;
  width: 20px;
`;

const LightTooltip = MUIStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    "&::before": {
        backgroundColor: 'white',
        border: "1px solid #999"
      }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 15px 20px 0px rgba(16, 16, 16, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    padding: '8px',
   
  },
}));

const Logo = ({ children, tip }) => {
  return (
    <LightTooltip title={tip} arrow placement="top">
      <LogoWrapper>{children}</LogoWrapper>
    </LightTooltip>
  );
};

export default Logo;
