import React, { useRef } from 'react';
import { Dialog, DialogContent, styled } from "@material-ui/core";
import RoomSlider from "./RoomSlider";
import { CloseOutlined } from "@material-ui/icons";
import { Box } from "@mui/material";
import useHotelDescriptionStore from "../store";
import { isEmpty } from "lodash";
import emptyImage from "../../../asset/images/hotelRoomPlug.svg";
import { useMediaQuery } from 'react-responsive'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(4)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(0)
	}
}))

const RoomPopup = ({ open, handleClose, room, amenities }) => {
	const isMobile = useMediaQuery({maxWidth: 576})

	const modalRef = useRef(null)
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)

	const images = room.images.map(item => {
		return hotel.images.find(image => image.id === item.id)
	})

	const onClickOutsideListener = () => {
		handleClose()
		document.removeEventListener('click', onClickOutsideListener);
	};

	return (
		<BootstrapDialog
			PaperProps={{
				style: !isMobile ? { borderRadius: 16, minWidth: '1000px' } : { borderRadius: 0, margin: 0, maxWidth: '100%', maxHeight: '100%' }
			}}
			aria-labelledby='customized-dialog-title'
			open={open}
			ref={modalRef}>
			<DialogContent
				style={isMobile ? { padding: 16 } : null}
				onMouseLeave={() => {
					document.addEventListener('click', onClickOutsideListener);
				}}
			>
				<div className={'room-card-popup__header'}>
					<h2>{room.name}</h2>
					<CloseOutlined style={{ cursor: 'pointer' }} onClick={handleClose} />
				</div>
				<div className={'room-card-popup__body'}>
					<div className={'room-card-popup__body-slider'}>
						{isEmpty(images) ? <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={emptyImage} alt="" /> : <RoomSlider room={room} />}
					</div>
					<Box pl={!isMobile ? 1 : 0}>
						{room.description && <><h3>Описание</h3>
							<p className='room-card-popup__description'>{room.description}</p>
							</>}
						{(!!room.square || !!room.view?.name || !!room.numberOfBeds) && <Box pt={2}>
							<h3>Характеристики</h3>
							{!!room.square && <p>Площадь: {room.square}м<sup>2</sup></p>}
							{!!room.view?.name && <p>Вид из номера: {room.view?.name}</p>}
							{!!room.numberOfBeds && <p>Максимальное размещение: {room.numberOfBeds}</p>}
						</Box>}
					</Box>
				</div>
				{!isEmpty(room.amenities) && <Box pt={1}>
					<h3 className={'room-card-popup__amenities-title'}>{`Удобства ${!isMobile ? ' в номере' : ''}`}</h3>
					<div className={'room-card-popup__amenities'}>
						{room.amenities.map(item => {
							const ametity = amenities.find(a => a.id === item.id)
							if (!ametity) return null
							return (
								<div className={'room-card-popup__amenities-item'}>
									<span><img
										src={`https://apigate-tui.fstravel.com/api/geocontent/static/amenity/${item.id}/${ametity?.imageFile?.fileName}`}
										alt="" /></span>
									<div>{ametity?.name} {item.payType === 'Paid' && <sup>платно</sup>}</div>
								</div>)
						}
						)}
					</div>
				</Box>}
			</DialogContent>
		</BootstrapDialog>
	);
};

export default RoomPopup;