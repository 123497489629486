import React from "react"
import DesktopHead from "./Desktop"
import { useSelector } from "react-redux"
import { getInfoBlockData } from "../../../store/modules/orders"

const Description = React.memo((resultRef) => {
    const props = useSelector(getInfoBlockData)
    return (
      <DesktopHead props={props} resultRef={resultRef} />
    );
});      

export default Description
