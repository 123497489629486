import React from "react"
import styled from "styled-components"
import {Text} from "../../../ui"
import formatPrice from "../../../../functions/formatPrice"
import { useOrderPosition } from "./useItemProps"

const Wrapper = styled.div``

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const DetailContent = styled.div`
  margin-top: 24px;
  & > div {
    margin-bottom: 22px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 10px;
    }
  }
`

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderWrapper = styled.div`
  display: flex;
`

const Title = styled(Text)`
  margin-right: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const PriceBlock = props => {
  const { disabled } = useOrderPosition()

  return (
    <Wrapper>
      <Head>
        <HeaderWrapper>
          <Title>Итого за перелет</Title>
        </HeaderWrapper>
        <Title
          fontWeight="600"
          color={disabled ? "#3C3C3C" : "blue"}
          style={{ marginRight: 0 }}
        >
          {formatPrice(props.amount)}
        </Title>
      </Head>
      {/*<DetailContent>*/}
      {/*  {props.detailList.map((item, key) => (*/}
      {/*    <DetailRow key={key}>*/}
      {/*      <StyledText color="dark-gray">*/}
      {/*        {item.type === "FirstBag"*/}
      {/*          ? `Доп. багаж до ${item.name} кг`*/}
      {/*          : item.name}*/}
      {/*      </StyledText>*/}
      {/*      <StyledText color="dark-gray">*/}
      {/*        {item.quantity > 0 && item.type !== "passenger"*/}
      {/*          ? `${item.quantity} x ${formatPrice(item.price)}`*/}
      {/*          : formatPrice(item.price)}*/}
      {/*      </StyledText>*/}
      {/*    </DetailRow>*/}
      {/*  ))}*/}
      {/*</DetailContent>*/}
    </Wrapper>
  )
}

export default PriceBlock
