import React from 'react';
import styled from 'styled-components';
import formatPrice from "../../functions/formatPrice";
import {useSelector} from "react-redux";
import {getAgentBalance} from "../../store/modules/agents/agent-finance/selectors";
import Skeleton from "@material-ui/lab/Skeleton";
import {getUserDataState} from "../../store/modules/user";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #edf1fe;
  border-radius: 5px;
  padding: 14px;
  margin: 16px;
`;

const TextBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  margin-top: 10px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  font-weight: 600;
`;

const AviaFinancialBalanceBlock = () => {
  const data = useSelector(getAgentBalance)
  const user = useSelector(getUserDataState);
  const userCanView = user?.role !== 'ViewManager';

  return (
    userCanView &&
    <>
      {data ?
        <Container>
          <Heading>Баланс по ТТЕ</Heading>
          <TextBlock>
            Остаток по кредиту:&nbsp;
            <span style={{ color: '#4872F2', fontWeight: 600 }}>{formatPrice(data?.creditLimitRest)}</span>
          </TextBlock>
          <TextBlock>
            Задолженность:&nbsp;
            <span style={{ color: '#4872F2', fontWeight: 600 }}>{formatPrice(data?.debt)}</span>
          </TextBlock>
          <TextBlock>
            Сумма переплаты:&nbsp;
            <span style={{ color: '#4872F2', fontWeight: 600 }}>{formatPrice(data?.overpayment)}</span>
          </TextBlock>
        </Container>
        :
        <div style={{margin: '16px'}}>
          <Skeleton variant="rounded" width={238} height={130}/>
        </div>
      }
    </>
  )
}

export default AviaFinancialBalanceBlock
