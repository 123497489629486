import { orderBy } from "lodash";
// import useSearchTourStore from "../pages/SearchTour/store";

const useGetGroupedTours = (bigTour, groupByHotel, sort = 1) => {
	// const actions = useSearchTourStore(({ actions }) => actions)
	const tours = bigTour.data
	if (groupByHotel) {
		// actions.setGroupedResult(true)
		return tours.filter(item => item.accommodationPriceOffer[0].tourTypeId !== 31 && item.accommodationPriceOffer[0].tourTypeId !== 42)
	}
	return tours.reduce((accumulator, tour) => {
		if (tour.accommodationPriceOffer.length > 1) {
			tour.accommodationPriceOffer.forEach((price) => {
				accumulator.push({ ...tour, accommodationPriceOffer: [price] })

			});
		} else {
			accumulator.push(tour)
		}
		// actions.setGroupedResult(false)
		return orderBy(
			accumulator.filter(item =>
				item.accommodationPriceOffer[0].tourTypeId !== 31 &&
				!item.accommodationPriceOffer[0].tour.includes('ж/д')
			), 'accommodationPriceOffer[0].price.value', sort === 1 ? 'asc' : 'desc')
	}, [])
}

export default useGetGroupedTours
