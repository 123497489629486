import React from 'react'

import './style.css'

export default function StaticH1({ content, title }) {

	const style = {
		textAlign: 'center'
	}

	return (
		<div className={'static-h1'}>
			{title && <h3>{title}</h3>}
			<h2 style={style}>{content.text}</h2>
		</div>

	)
}
