import { create } from 'zustand'
import axios from 'axios'
import { groupBy, isEmpty, uniqBy, values } from 'lodash'
import {
	sendAnalyticsAdjust,
	sendAnalyticsReserve
} from '../../functions/analytics'
import { loyalSale } from '../../constants/reg-exps'
import { formatDateToUTC } from '../../functions/formateDate'
import useAuthStore from '../Auth/store'

const BASE_URL = process.env.REACT_APP_FS_APIGATE
const PART_PASS = localStorage.getItem('agentInfo')
const TOUR = localStorage.getItem('tour')

const useBookingStore = create((set, get) => ({
	currencys: {},
	phoneErrorGds: false,
	abortRequest: null,
	loading: false,
	flightLoading: false,
	newBookingLoading: false,
	bookingError: false,
	bookingLoadingError: false,
	currentTour: {},
	forwardFlightId: null,
	backwardFlightId: null,
	selectedFlightOptionId: null,
	tour: null,
	insurances: null,
	excursions: null,
	services: [],
	oldPrice: true,
	paxes: null,
	checkFields: null,
	peoples: null,
	promocode: '',
	claimId: null,
	readyToBooking: false,
	faresError: null,
	applicationNotes: null,
	selectedHotels: [],
	isInsurancesSelect: false,
	medicalServices: [],
	medicalGroups: [],
	visaGroups: [],
	visaServices: [],
	servicesGroups: [],
	selectedServices: [],
	boarding: [],
	selectedBeachHotels: null,
	notifications: [],
	isFirstRecount: true,
	bookingNote: '',
	isReadyToReserve: false,
	buyer: {
		buyerType: 0,
		nationalityId: '210357',
		sendGis: '1',
		identity_document: 1,
		born: '0001-01-01',
		pgiven: '0001-01-01',
		pexpire: '0001-01-01',
		nationalityKey: '210357',
		firmState: '210357',
	},
	buyerErrors: {
		name: null,
		phone: null,
		email: null,
		pserie: null,
		pnumber: null,
		address: null,
		nationalityId: null,
		agencyDocDate: null,
		agencyDocNumber: null,
		agencyDocSum: null,
		inn: null,
		boss: null
	},
	notesErrors: [],
	defaultPayload: {
		userAgent: process.env.REACT_APP_USER_AGENT,
		partpassId: Number(JSON.parse(PART_PASS)?.partPass),
		clientId: 'b2b:ru',
		lang: 'ru',
		tourKey: '',
		tour: {
			currencyId: localStorage.getItem('currencyBookingId'),
			client: null
		}
	},
	payloadNew: {
		partpassId: Number(JSON.parse(PART_PASS)?.partPass),
		clientId: 'b2b:ru',
		claim: {}
	},
	payload: {
		userAgent: process.env.REACT_APP_USER_AGENT,
		partpassId: Number(JSON.parse(PART_PASS)?.partPass),
		clientId: 'b2b:ru',
		lang: 'ru',
		tourKey: '',
		tour: {
			currencyId: 2,
			client: null
		},
		fares: null
	},
	response: null,
	partnerCommission: null,
	tourKey: null,
	paxesVisa: null,
	selectedTransport: null,
	transportsGDS: [],
	transports: [],
	selectedFlightOptionIdNew: null,
	claimNew: null,
	prevClaim: null,
	visas: [],
	applyContactsForAll: false,
	checkedPreReserve: false,
	actions: {
		getPacket: async (tourKey, currency, withFlight) => {
			set({ flightLoading: true })
			set({ newBookingLoading: true })
			set({ tourKey: tourKey })
			const req = {
				partpassId: Number(useAuthStore.getState().agent.partPass),
				clientId: 'b2b:ru',
				claim: tourKey,
				currencyId: Number(currency),
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/GetPacket`,
					method: 'POST',
					data: req
				})

				const medicalGroups = res.data?.claim?.groups.filter(
					i =>
						i.description === 'Комплексная' || i.description === 'Медицинская'
				)
				const visaGroups = res.data.claim.groups.filter(
					i => i.description === 'Виза'
				)

				let selectedServices = res.data?.claim?.claimDocument?.services
				const serviceVisa = selectedServices.find(visa =>
					visaGroups?.some(el => el?.id === visa.groupId)
				)
				if (serviceVisa) {
					selectedServices = selectedServices.filter(
						i => i.uid !== serviceVisa.uid
					)
					serviceVisa.clients = res.data?.claim?.claimDocument?.peoples.map(
						people => ({ peopleKey: people.key, common: 0 })
					)
					selectedServices.push(serviceVisa)
				}

				set({ claimNew: res.data.claim })
				set({ prevClaim: res.data.claim })
				set({ checkFields: res.data?.claim?.checkFields })
				const peoples = res.data?.claim?.claimDocument?.peoples.map(people => {
					return {...people, nationalityKey: 210357}
				})
				set({ peoples: peoples })
				set({ visaGroups: visaGroups })
				set({ medicalGroups: medicalGroups })
				set({
					servicesGroups: res.data?.claim?.groups.filter(
						i =>
							!medicalGroups.some(el => el.id === i.id) &&
							!visaGroups?.some(el => el?.id === i.id)
					)
				})
				set({
					services: res.data?.claim?.variants?.services?.filter(
						i =>
							!medicalGroups.some(el => i.groupId === el.id) &&
							!visaGroups?.some(el => el?.id === i.id)
					)
				})
				set({
					insurances: res.data?.claim?.variants?.services?.filter(i =>
						medicalGroups.some(el => i.groupId === el.id)
					)
				})
				set({
					visaServices: res.data?.claim?.variants?.services?.filter(i =>
						visaGroups?.some(el => el?.id === i.groupId)
					)
				})
				set({ selectedServices: selectedServices })
				set({ isInsurancesSelect: false })
				set({
					transports: res.data.claim.claimDocument.transports.map(item => {
						return {
							...item,
							clients: res.data?.claim?.claimDocument?.peoples.map(people => ({
								peopleKey: people.key,
								common: 0,
								place: '',
								seatKey: ''
							}))
						}
					})
				})

				if (
					res.data.claim.claimDocument.freightExternal &&
					res.data.claim.claimDocument.stateKey !== 266539 &&
					withFlight
				) {
					const req = {
						claim: res.data.claim,
						clientId: 'b2b:ru',
						partpassId: Number(useAuthStore.getState().agent.partPass),
						userAgent: process.env.REACT_APP_USER_AGENT
					}
					const transport = await axios({
						url: `${BASE_URL}/PacketTour/GetExternalFreights`,
						method: 'POST',
						data: req
					})
					const transports = transport.data.claim.variants.transports
					const flightOptionsNew = values(
						groupBy(transports, u => u.externalOfferId)
					).map(item => ({
						forwardFlightNew: item[0].details,
						backwardFlightNew: item[1].details,
						selectedFlightsNew: item[0].externalOfferId,
						surcharge: isNaN(+item[0].name.split('.')[0])
							? 0
							: +item[0].name.split('.')[0],
						duration: item[0].details[0].flyDuration
					}))
					set({ transportsGDS: flightOptionsNew })
					const selectedTransportClaim =
						transport?.data?.claim?.claimDocument?.transports
					const selectedTransportFirst = transports?.filter(
						i => i?.externalOfferId === flightOptionsNew[0]?.selectedFlightsNew
					)

					set({ transports: transports })
					set({
						selectedTransport: !isEmpty(selectedTransportClaim)
							? selectedTransportClaim
							: selectedTransportFirst
							? selectedTransportFirst
							: []
					})
					set({
						selectedFlightOptionIdNew: flightOptionsNew[0].selectedFlightsNew
					})

					set({ claimNew: transport.data.claim })
					set({ prevClaim: transport.data.claim })
				} else {
					set({
						selectedTransport: res.data.claim.claimDocument.transports.map(
							item => {
								return {
									...item,
									clients: res.data?.claim?.claimDocument?.peoples.map(
										people => ({
											peopleKey: people.key,
											common: 0,
											place: '',
											seatKey: ''
										})
									)
								}
							}
						)
					})
					set({
						transports: res?.data?.claim?.variants?.transports.map(item => {
							return {
								...item,
								clients: res.data?.claim?.claimDocument?.peoples.map(
									people => ({
										peopleKey: people.key,
										common: 0,
										place: '',
										seatKey: ''
									})
								)
							}
						})
					})
				}
				await get().actions.GetTourDescription(
					res?.data?.claim?.claimDocument?.tourKey
				)
				await get().actions.getVisasSchedule(res?.data?.claim?.claimDocument)
				await get().actions.ActualizePacket()
				await get().actions.getOtherService()
			} catch (e) {
				console.error(e)
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingLoadingError: e.response?.data?.detail })
			} finally {
				set({ flightLoading: false })
				set({ newBookingLoading: false })
			}
		},
		setCurrencyId: id => {
			set({
				defaultPayload: {
					...get().defaultPayload,
					tour: { ...get().defaultPayload.tour, currencyId: id }
				}
			})
		},
		setPhoneError: value => {
			set({ phoneErrorGds: value })
		},
		getPartnerCommission: async () => {
			const data = {
				partpassId: Number(useAuthStore.getState().agent.partPass),
				dateBeg: JSON.parse(TOUR)?.hotel?.checkInDate,
				tourId: JSON.parse(TOUR)?.id,
				// "adultCount": get().searchParams.adult,
				// "childCount": get().searchParams.children.length,
				// "infantCount": get().searchParams.children.length,
				nightCount: JSON.parse(TOUR)?.hotel?.nightCount
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/TourDetails/GetPartnerCommission`,
					method: 'POST',
					data: data
				})
				set({ partnerCommission: res.data.partnerCommission })
			} catch (e) {
				console.log(e?.response?.data)
			}
		},
		clearError: () => {
			set({ bookingError: false })
			set({ bookingLoadingError: false })
		},
		backToSearch: () => {
			if (get().abortRequest) {
				get().abortRequest.abort()
			}
			localStorage.removeItem('tour')
			set({ response: null })
			set({ payload: get().defaultPayload })
			set({ claimId: null })
		},
		clearPayload: () => {
			if (get().abortRequest) {
				get().abortRequest.abort()
			}
			set({ payload: get().defaultPayload })
		},
		adjustReservation: async (tourKey, currency, adults, children) => {
			set({ tourKey: tourKey })
			set({ loading: true })
			// const tourkey = localStorage.getItem('tour')
			const req = {
				...get().defaultPayload,
				tour: { ...get().defaultPayload.tour, currencyId: currency },
				tourKey: `TUI::${tourKey}`
			}

			set({ payload: req })
			await get().actions.getCurrenciesValues()
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				await get().actions.getVisasSchedule(res.data.tour)
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				// set({
				// 	forwardFlightId: res.data.tour.selectedFlightOptionId
				// 		?.split('::')[0]
				// 		.replace('.2', '')
				// })
				// set({
				// 	backwardFlightId: res.data.tour.selectedFlightOptionId
				// 		?.split('::')[1]
				// 		.replace('.2', '')
				// })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[0]
							.replace('.2', '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[1]
							.replace('.2', '')
					})
				} else {
					const firstForward = res.data.tour.selectedFlightOptionId
						?.split('::')[0]
						.replace('.2', '')
					const secondForward = res.data.tour.selectedFlightOptionId
						?.split('::')[1]
						.replace('.2', '')
					set({
						forwardFlightId: [firstForward, secondForward]?.join('::')
					})
					const firstBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[2]
						.replace('.2', '')
					const secondBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[3]
						.replace('.2', '')
					set({
						backwardFlightId: [firstBackward, secondBackward]?.join('::')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })
				set({ tour: res.data.tour })
				const paxesAdults = res.data.tour.paxes
					.slice(0, Number(adults))
					.map(pax => {
						return {
							...pax,
							person: {
								...pax.person,
								ageGroup: 1,
								gender: 1
							}
						}
					})
				const paxesChild = res.data.tour.paxes
					.slice(adults, Number(children) + Number(adults))
					.map(pax => {
						return {
							...pax,
							person: {
								...pax.person,
								ageGroup: 2,
								gender: 1
							}
						}
					})
				const paxesInf = res.data.tour.paxes.filter(
					pax => pax.person.ageGroup === 3
				)
				set({ paxes: [...paxesAdults, ...paxesChild, ...paxesInf] })
				set({ insurances: res.data.tour.insurances })
				set({
					// excursions: res.data.tour.services.filter(item => item.type.id === 2)
				})
				set({
					// services: res.data.tour.services.filter(item => item.type.id !== 2)
				})
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingLoadingError: e.response?.data?.detail })
			} finally {
				set({ loading: false })
			}
		},
		getServicesReceipt: async (tourKey, currency) => {
			set({ tourKey: tourKey })
			set({ loading: true })
			const req = {
				...get().defaultPayload,
				tour: { ...get().defaultPayload.tour, currencyId: currency },
				tourKey: `TUI::${tourKey}`
			}

			set({ payload: req })
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[0]
							.replace('.2', '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							?.split('::')[1]
							.replace('.2', '')
					})
				} else {
					const firstForward = res.data.tour.selectedFlightOptionId
						?.split('::')[0]
						.replace('.2', '')
					const secondForward = res.data.tour.selectedFlightOptionId
						?.split('::')[1]
						.replace('.2', '')
					set({
						forwardFlightId: [firstForward, secondForward]?.join('::')
					})
					const firstBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[2]
						.replace('.2', '')
					const secondBackward = res.data.tour.selectedFlightOptionId
						?.split('::')[3]
						.replace('.2', '')
					set({
						backwardFlightId: [firstBackward, secondBackward]?.join('::')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })
				set({ tour: res.data.tour })
				set({ paxes: res.data.tour.paxes })
				// set({ insurances: res.data.tour.insurances })
				set({
					// services: res.data.tour.services
				})
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingLoadingError: e.response?.data?.detail })
			} finally {
				set({ loading: false })
			}
		},
		getRegularFlights: async () => {
			const controller = new AbortController()
			set({ abortRequest: controller })
			// const tourkey = localStorage.getItem('tour')
			set({ loading: true })
			const req = {
				...get().payload,
				tourKey: `TUI::${get().tourKey}`,
				id: get().tour.id,
				gdsId: get().tour.gdsId
			}
			set({ payload: req })
			await get().actions.getCurrenciesValues()
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					signal: controller.signal,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				set({
					forwardFlightId: res.data.tour.flightOptions[0]?.forwardFlight.id
				})
				set({
					backwardFlightId: res.data.tour.flightOptions[0]?.backwardFlight.id
				})
				const { euro, dollar } = get().currencys
				if (res.data.tour.currencyId === 1) {
					set({ tour: res.data.tour })
				}
				if (res.data.tour.currencyId === 2) {
					const newFlights = res.data.tour.flightOptions.map(flight => ({
						...flight,
						surcharge: Math.round(flight.surcharge * dollar[0].rate)
					}))
					const newTour = { ...res.data.tour, flightOptions: newFlights }
					set({ tour: newTour })
				}
				if (res.data.tour.currencyId === 3) {
					const newFlights = res.data.tour.flightOptions.map(flight => ({
						...flight,
						surcharge: Math.floor(flight.surcharge * euro[0].rate)
					}))
					const newTour = { ...res.data.tour, flightOptions: newFlights }
					set({ tour: newTour })
				}
				// set({
				// 	services: res.data.tour.services.filter(item => item.type.id !== 2)
				// })
				const newData = { ...get().payload, tour: res.data.tour }
				set({ payload: newData })
				// await get().actions.getFares()
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingLoadingError: e.response?.data?.detail })
			} finally {
				set({ loading: false })
			}
		},
		getPacketFares: async () => {
			set({ faresError: false })
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: get().claimNew,
				currencyId: 1,
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/GetPacketFares`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				// console.log(res.data, 'fares')
				set({ fares: res.data.fares })
			} catch (e) {
				console.log(e?.response?.data)
				set({ faresError: true })
				// set({ bookingError: e.response.data.errors[0].userMessage })
			} finally {
				set({ newBookingLoading: false })
			}
		},
		getFares: async () => {
			set({ faresError: false })
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				tourId: get().tour?.id,
				partpassId: Number(useAuthStore.getState().agent.partPass)
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/getFares`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set({ fares: res.data.fares })
			} catch (e) {
				console.log(e?.response?.data)
				set({ faresError: true })
				// set({ bookingError: e.response.data.errors[0].userMessage })
			} finally {
				set({ newBookingLoading: false })
			}
		},
		getServices: async () => {
			const req = {
				...get().payload,
				tourKey: `TUI::${get().tourKey}`,
				tour: get().tour,
				id: get().tour.id,
				gdsId: get().tour.gdsId
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				// set({
				// 	services: res.data.tour.services.filter(item => item.type.id !== 2)
				// })
			} catch (e) {
				console.log(e?.response?.data)
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
			}
		},
		setFaresError: value => {
			set({ faresError: value })
		},
		setPacketFare: async (fareId, requestId, price, baggage) => {
			set({ newBookingLoading: true })
			const payload = {
				clientId: 'b2b:ru',
				claim: get().tourKey,
				partpassId: Number(useAuthStore.getState().agent.partPass),
				fareId: fareId,
				requestId: requestId,
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				await axios({
					url: `${BASE_URL}/PacketTour/SetPacketFare`,
					method: 'POST',
					data: payload
				})
				const newFlights = get().transportsGDS.map(item => {
					if (item.selectedFlightsNew === get().selectedFlightOptionIdNew) {
						let newPrice = item.surcharge + Number(price)
						if (newPrice <= 0) {
							newPrice = 0
						}
						const [, forward, , backward] = baggage
						isNaN(parseInt(forward))
							? (item.forwardFlightNew[0].bagage = 'Нет')
							: forward.includes('Kg')
							? (item.forwardFlightNew[0].bagage = parseInt(forward) + 'Kg')
							: (item.forwardFlightNew[0].bagage = parseInt(forward) + 'PC')
						if ((!backward || !parseInt(backward)) && backward !== '0Kg') {
							isNaN(parseInt(forward))
								? (item.backwardFlightNew[0].bagage = 'Нет')
								: forward.includes('Kg')
								? (item.backwardFlightNew[0].bagage = parseInt(forward) + 'Kg')
								: (item.backwardFlightNew[0].bagage = parseInt(forward) + 'PC')
						} else {
							isNaN(parseInt(backward))
								? (item.backwardFlightNew[0].bagage = 'Нет')
								: forward.includes('Kg')
								? (item.backwardFlightNew[0].bagage = parseInt(backward) + 'Kg')
								: (item.backwardFlightNew[0].bagage = parseInt(backward) + 'PC')
						}
						return {
							...item,
							surcharge: newPrice
						}
					} else {
						return item
					}
				})
				set({ transportsGDS: newFlights })
			} catch (e) {
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.detail })
			} finally {
				set({ newBookingLoading: false })
			}
		},
		setFare: async (fareId, requestId, price, baggage) => {
			// const tourkey = localStorage.getItem('tour')
			set({ loading: true })
			const payload = {
				clientId: 'b2b:ru',
				tourId: get().tour.id,
				partpassId: Number(useAuthStore.getState().agent.partPass),
				fareId: fareId,
				requestId: requestId
			}
			const req = {
				...payload,
				tourKey: get().tourKey
			}
			try {
				await axios({
					url: `${BASE_URL}/tour/setFare`,
					method: 'POST',
					data: req,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				const newFlights = get().tour.flightOptions?.map(item => {
					if (item.key === get().selectedFlightOptionId) {
						const newPrice = item.surcharge + Number(price)
						const [, forward, , backward] = baggage
						item.forwardFlight.segments[0].baggage.value = parseInt(forward)
						if ((!backward || !parseInt(backward)) && backward !== '0Kg') {
							item.backwardFlight.segments[0].baggage.value = parseInt(forward)
						} else {
							item.backwardFlight.segments[0].baggage.value = parseInt(backward)
						}
						return {
							...item,
							surcharge: newPrice
						}
					} else {
						return item
					}
				})
				const newTour = { ...get().tour, flightOptions: newFlights }
				set({ tour: newTour })
				set({ oldPrice: true })
			} catch (e) {
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.detail })
			} finally {
				set({ loading: false })
			}
		},
		setTourData: tour => {
			set({ currentTour: tour })
		},
		changeForwardFlights: id => {
			const existTransport = get().transports?.find(item => item.key === id)

			if (!existTransport) {
				return null
			}

			const movedTransport = get().selectedTransport.find(
				el => el?.direction === existTransport.direction
			)

			const newTransports = [
				...get().transports.filter(i => i.key !== id),
				movedTransport
			]
			const newSelectedTransports = [
				...get().selectedTransport.filter(i => i.key !== movedTransport?.key),
				existTransport
			]

			set({ transports: newTransports })
			set({ selectedTransport: newSelectedTransports })
			set({ oldPrice: true })
		},
		changeBackwardFlights: id => {
			const existTransport = get().transports?.find(item => item.key === id)

			if (!existTransport) {
				return null
			}

			const movedTransport = get().selectedTransport.find(
				el => el?.direction === existTransport.direction
			)

			const newTransports = [
				...get().transports.filter(i => i.key !== id),
				movedTransport
			]
			const newSelectedTransports = [
				...get().selectedTransport.filter(i => i.key !== movedTransport?.key),
				existTransport
			]

			set({ transports: newTransports })
			set({ selectedTransport: newSelectedTransports })
			set({ oldPrice: true })
		},
		changeSelectedBeachHotels: uid => {
			let newHotels = get().claimNew?.claimDocument?.hotels
			const selectedHotel = get().claimNew?.variants?.hotels.find(
				i => i.uid === uid
			)
			newHotels.splice(1, 1, selectedHotel)
			const newState = {
				...get().claimNew,
				claimDocument: {
					...get().claimNew.claimDocument,
					hotels: newHotels
				}
			}
			set({ claimNew: newState })
		},
		changeSelectedCityHotels: uid => {
			let newHotels = get().claimNew?.claimDocument?.hotels
			const selectedHotel = get().claimNew?.variants?.hotels.find(
				i => i.uid === uid
			)
			newHotels.splice(0, 1, selectedHotel)
			const newState = {
				...get().claimNew,
				claimDocument: {
					...get().claimNew.claimDocument,
					hotels: newHotels
				}
			}
			set({ claimNew: newState })
		},
		addPaxe: newPaxe => {
			const newPaxes = get().paxes
			newPaxes.push(newPaxe)
			set({ paxes: newPaxes })
		},
		removePaxe: id => {
			const newPaxes = get().paxes
			set({ paxes: newPaxes.filter(item => item.id !== id) })
		},
		setSelectedFlightId: () => {
			if (get().selectedFlightOptionId?.split('::').length === 2) {
				set({
					selectedFlightOptionId:
						get().forwardFlightId + '.2::' + get().backwardFlightId + '.2'
				})
				const newData = {
					...get().tour,
					selectedFlightOptionId: get().selectedFlightOptionId
				}
				set({ tour: newData })
			}
		},
		setSelectedRegularFlightId: key => {
			set({
				selectedFlightOptionId: key
			})
			const newData = {
				...get().tour,
				selectedFlightOptionId: get().selectedFlightOptionId
			}
			set({ tour: newData })
			get().actions.getServices()
		},
		setSelectedRegularFlightIdNew: key => {
			set({ selectedFlightOptionIdNew: key })
			console.log(key)
			let newTransport = get().transports.filter(
				item => item.externalOfferId !== key
			)
			const newSelected = get().transports.filter(
				item => item.externalOfferId === key
			)
			if (!isEmpty(get().selectedTransport)) {
				newTransport = [...newTransport, ...get().selectedTransport]
			}
			const newData = {
				...get().claimNew,
				claimDocument: {
					...get().claimNew.claimDocument,
					transports: newSelected
				},
				variants: {
					...get().claimNew.variants,
					transports: newTransport
				}
			}
			console.log(newData)
			set({ transports: newTransport })
			set({ selectedTransport: newSelected })
			set({ claimNew: newData })
		},
		tourRecount: async () => {
			set({ loading: true })
			const tour = {
				...get().tour,
				paxes: get().paxes,
				promoCode: get().promocode
			}
			const data = {
				...get().payload,
				tour: tour,
				tourKey: `TUI::${get().tourKey}`
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/tour/adjustReservation`,
					method: 'POST',
					data: data,
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set({ selectedFlightOptionId: res.data.tour.selectedFlightOptionId })
				if (res.data.tour.selectedFlightOptionId?.split('::').length === 2) {
					set({
						forwardFlightId: res.data.tour.selectedFlightOptionId
							.split('::')[0]
							.replace('.2', '')
					})
					set({
						backwardFlightId: res.data.tour.selectedFlightOptionId
							.split('::')[1]
							.replace('.2', '')
					})
				}
				set({ selectedHotels: res.data.tour.selectedHotels })
				set({ response: res.data })

				const paxesWithContacts = res.data.tour.paxes.map(item => {
					const paxe = tour.paxes.find(i => i.id === item.id)
					return {
						...item,
						person: {
							...item.person,
							phone: paxe.person.phone,
							email: paxe.person.email
						}
					}
				})
				set({ paxes: paxesWithContacts })

				const newData = { ...get().payload, tour: res.data.tour }

				set({ payload: newData })
				set({ oldPrice: false })
			} catch (e) {
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingError: e.response?.data?.detail })
				console.log(e?.response?.data)
			} finally {
				set({ loading: false })
				setTimeout(() => {
					set({ oldPrice: false })
				}, 100)
			}
		},
		updateInsurance: newSelected => {
			const medicalGroups = get().medicalGroups
			const prevSelectedInsurances = get().selectedServices.filter(i =>
				medicalGroups.some(el => el.id === i.groupId)
			)
			const prevInsurances = get().insurances

			const movedObject = prevSelectedInsurances.filter(
				prev => !newSelected.some(newItem => newItem.uid === prev.uid)
			)

			const removedObject = prevInsurances.filter(prev =>
				newSelected.some(newItem => newItem.uid === prev.uid)
			)

			let newInsurance = prevInsurances.filter(
				item => !removedObject.some(el => item.uid === el.uid)
			)

			movedObject.forEach(item => {
				if (!newInsurance.some(el => el.uid !== item.uid)) {
					return
				}
				if (item.clients.length < get().peoples.length) {
					item.clients = get().peoples.map(people => ({
						peopleKey: people.key,
						common: 0
					}))
				}
				newInsurance.push(item)
			})
			const anotherServices = get().selectedServices.filter(
				i => !medicalGroups.some(el => el.id === i.groupId)
			)

			const newSelectedInsurances = [...anotherServices, ...newSelected]

			set({ selectedServices: newSelectedInsurances })
			set({ isInsurancesSelect: true })
			set({ insurances: newInsurance })
			set({ oldPrice: true })
		},
		addInsurance: id => {
			const selectedInsurance = get().selectedServices
			const insurances = get().insurances
			const movedObject = insurances.filter(
				insure =>
					insure.uid === id || (insure?.parentUid === id && insure.required)
			)
			set({
				insurances: insurances.filter(
					insure => !movedObject.some(obj => obj.uid === insure.uid)
				)
			})
			set({ selectedServices: [...selectedInsurance, ...movedObject] })
			set({ isInsurancesSelect: true })
			set({ oldPrice: true })
		},
		removeInsurance: id => {
			const selectedInsurance = get().selectedServices
			const peoples = get().peoples
			const insurances = get().insurances

			const movedObject = selectedInsurance
				.filter(insure => insure.uid === id || insure?.parentUid === id)
				.map(item => {
					if (item.clients.length < peoples.length) {
						item.clients = peoples.map(people => ({
							peopleKey: people.key,
							common: 0
						}))
					}
					return item
				})

			set({ insurances: [...insurances, ...movedObject] })
			set({
				selectedServices: selectedInsurance.filter(
					insure => !movedObject.some(obj => obj.uid === insure.uid)
				)
			})
			set({ oldPrice: true })
		},
		updateServices: newSelected => {
			const medicalGroups = get().medicalGroups
			const prevSelectedService = get().selectedServices.filter(
				i => !medicalGroups.some(el => el.id === i.groupId)
			)
			const prevServices = get().services
			const movedObject = prevSelectedService.filter(
				prev => !newSelected.some(newItem => newItem.uid === prev.uid)
			)

			const removedObject = prevServices.filter(prev =>
				newSelected.some(newItem => newItem.uid === prev.uid)
			)

			let newServices = prevServices.filter(
				item => !removedObject.some(el => item.uid === el.uid)
			)

			movedObject.forEach(item => {
				if (!newServices.some(el => el.uid !== item.uid)) {
					return
				}
				newServices.push(item)
			})
			const newSelectedServices = [...newSelected]

			set({ selectedServices: newSelectedServices })
			set({ services: newServices })
			set({ oldPrice: true })
		},
		addServices: uid => {
			const selectedServices = get().selectedServices
			const peoples = get().peoples
			const services = get().services

			const movedObject = services
				.filter(service => service.uid === uid)
				.map(item => {
					if (item.clients.length < peoples.length) {
						item.clients = peoples.map(people => ({
							peopleKey: people.key,
							common: 0
						}))
					}
					return item
				})
			set({
				services: services.filter(
					insure => !movedObject.some(obj => obj.uid === insure.uid)
				)
			})
			set({ selectedServices: [...selectedServices, ...movedObject] })
			set({ oldPrice: true })
		},
		removeServices: uid => {
			const selectedServices = get().selectedServices
			const services = get().services

			const movedObject = selectedServices
				.filter(service => service.uid === uid)
				.map(item => {
					item.clients = []
					return item
				})
			set({ services: [...services, ...movedObject] })
			set({
				selectedServices: selectedServices.filter(
					insure => !movedObject.some(obj => obj.uid === insure.uid)
				)
			})
			set({ oldPrice: true })
		},
		setNewPrice: () => {
			set({ oldPrice: true })
		},
		setApplicationNotes: value => {
			const { message, checked, numbers } = value
			const notes = checked.map(note => {
				if (note === 'Соседний номер с' && numbers.roomNumber) {
					return `${note} ${numbers.roomNumber}`
				}
				if (note === 'Вместе с заявкой #' && numbers.noteNumber) {
					return `${note} ${numbers.noteNumber}`
				}
				if (note === 'Телефон менеджера' && numbers.managerNumber) {
					return `${note}: ${numbers.managerNumber}`
				}
				if (note === 'Телефон туриста' && numbers.touristNumber) {
					return `${note}: ${numbers.touristNumber}`
				}
				if (
					note === 'Номер карты постоянного гостя отеля (если есть)' &&
					numbers.cardNumber
				) {
					return `${note}: ${numbers.cardNumber}`
				} else return note
			})
			if (message) {
				notes.push(message)
			}
			set({ applicationNotes: notes.join('; ') })
		},
		applyPromoCode: value => {
			set({ promocode: value })
		},
		changeLname: (name, peopleKey) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === peopleKey) {
						if (name === ' ') {
							item.lname = ''
						} else {
							item.lname = name
						}
						return item
					}
					return item
				})
			})
		},
		changeName: (name, peopleKey) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === peopleKey) {
						if (name === ' ') {
							item.name = ''
						} else {
							item.name = name
						}
						return item
					}
					return item
				})
			})
		},
		// changeFirstName: (name, number) => {
		// 	set({
		// 		paxes: get().paxes.map(item => {
		// 			if (item.id === number) {
		// 				item.person.firstName = name
		// 				return item
		// 			}
		// 			return item
		// 		})
		// 	})
		// },
		// changeLastName: (lastName, number) => {
		// 	set({
		// 		paxes: get().paxes.map(item => {
		// 			if (item.id === number) {
		// 				item.person.lastName = lastName
		// 				return item
		// 			}
		// 			return item
		// 		})
		// 	})
		// },
		// changePatronymic: (patronymic, number) => {
		// 	set({
		// 		paxes: get().paxes.map(item => {
		// 			if (item.id === number) {
		// 				item.person.patronymic = patronymic
		// 				return item
		// 			}
		// 			return item
		// 		})
		// 	})
		// },
		changeFreeInfantChecked: value => {
			set({
				claimNew: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						freeInfantChecked: value
					}
				}
			})
		},
		changeGender: (gender, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.human = gender
						item.sex = gender
						return item
					}
					return item
				})
			})
		},
		changeBirthDate: (date, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.born = date ? formatDateToUTC(date) : date
						return item
					}
					return item
				})
			})
		},
		changePhone: (phone, number) => {
			if (get().applyContactsForAll) {
				set({
					peoples: get().peoples.map(item => ({ ...item, phone: phone }))
				})
			} else {
				set({
					peoples: get().peoples.map(item => {
						if (item.key === number) {
							item.phone = phone
							return item
						}
						return item
					})
				})
			}
		},
		changeEmail: (email, number) => {
			if (get().applyContactsForAll) {
				set({
					peoples: get().peoples.map(item => ({ ...item, email: email }))
				})
			} else {
				set({
					peoples: get().peoples.map(item => {
						if (item.key === number) {
							item.email = email
							return item
						}
						return item
					})
				})
			}
			// const newClientInfo = { ...get().tour.client, contactInformation: {phone: get().tour.client.contactInformation.phone, email: email}}
			// const newSearchParams = { ...get().tour, client: newClientInfo }
			// const newData = { ...get().payload, tour: newSearchParams }
			// set({ payload: newData })
		},
		changeNationality: (nation, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.nationalityKey = nation
						return item
					}
					return item
				})
			})
		},
		changeTypeDocument: (type, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.identityDocument = type
						return item
					}
					return item
				})
			})
		},
		changeSeries: (series, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.pserie = series
						return item
					}
					return item
				})
			})
		},
		changeNumber: (pNumber, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.pnumber = pNumber
						return item
					}
					return item
				})
			})
		},
		changeExpirationDate: (date, number) => {
			set({
				peoples: get().peoples.map(item => {
					if (item.key === number) {
						item.pexpire = formatDateToUTC(date)
						return item
					}
					return item
				})
			})
		},
		getBoarding: async (freight, dateFreight, currency) => {
			set({ newBookingLoading: true })
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/GetPacketBoarding`,
					method: 'POST',
					data: {
						ClientId: 'b2c:ru',
						claim: get().tourKey,
						freight: freight,
						dateBeg: dateFreight,
						dateEnd: dateFreight,
						class: 2,
						currencyId: currency,
						partpassId: Number(useAuthStore.getState().agent.partPass),
						userAgent: process.env.REACT_APP_USER_AGENT
					},
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set({ boarding: res.data.seats })
			} catch (e) {
				console.log(e?.response?.data)
			} finally {
				set({ newBookingLoading: false })
			}
		},
		setFlightSeats: selectedSeats => {
			set({
				selectedTransport: get().selectedTransport.map(item => {
					if (item.key === selectedSeats[0].seats.freight) {
						return {
							...item,
							clients: selectedSeats.map(el => {
								return {
									common: 0,
									peopleKey: el.id,
									seatKey: el.seats.seatKey
								}
							})
						}
					} else return item
				})
			})
		},
		getCurrenciesValues: async () => {
			try {
				const currentDate = new Date()
				// const startDate = new Date().setMonth(currentDate.getMonth() - 1)
				const dollar = await axios({
					url: `${BASE_URL}/currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2b:ru',
						currencyFrom: 1,
						currencyTo: 2,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				const euro = await axios({
					url: `${BASE_URL}/currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2b:ru',
						currencyFrom: 1,
						currencyTo: 3,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})
				set(() => ({
					currencys: {
						...get().currencys,
						dollar: dollar.data.currencyRates.reverse(),
						euro: euro.data.currencyRates.reverse()
					}
				}))
			} catch (error) {
				console.log(error)
			}
		},
		changeServices: async () => {
			set({ flightLoading: true })
			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples,
						services: get().selectedServices,

						transports: get().selectedTransport
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports
					}
				},
				currencyId: get().currency,
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/ChangeService`,
					method: 'POST',
					data: req
				})
				set({ claimNew: res.data.claim })
			} catch (error) {
				console.log(error)
			} finally {
				set({ flightLoading: false })
			}
		},
		addBaby: baby => {
			set({ peoples: [...get().peoples, baby] })
			set({
				selectedServices: get().selectedServices.map(item => {
					item.clients.push({ peopleKey: baby.key, common: 0 })
					return item
				})
			})
			set({
				claimNew: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						freeInfantChecked: true,
						infant: get().claimNew?.claimDocument.infant + 1,
						peopleCount: get().claimNew?.claimDocument.peopleCount + 1
					}
				}
			})
		},
		removeBaby: key => {
			set({ peoples: get().peoples.filter(people => people.key !== key) })
			set({
				selectedServices: get().selectedServices.map(item => {
					item.clients = item.clients.filter(people => people.peopleKey !== key)
					return item
				})
			})
			set({
				claimNew: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						freeInfantChecked: false,
						infant: get().claimNew?.claimDocument.infant - 1,
						peopleCount: get().claimNew?.claimDocument.peopleCount - 1
					}
				}
			})
		},
		getReservationPrice: async () => {
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples.map(item => {
							return {
								...item,
								human: item.age === 3 ? 4 : item.age === 2 ? 3 : item.sex
							}
						}),
						services: get().selectedServices,
						transports: get().selectedTransport
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports
					}
				},
				currencyId: get().currency,
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/GetReservationPrice`,
					method: 'POST',
					data: req
				})
				set({ oldPrice: false })
				set({ claimNew: res.data.claim })

				get().actions.getNotification(get().prevClaim, res.data.claim)

				set({ prevClaim: res.data.claim })
			} catch (error) {
				set({ bookingError: error.response?.data?.detail })
				console.log(error)
			} finally {
				set({ newBookingLoading: false })
			}
		},
		ActualizePacket: async () => {
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples.map(item => {
							return {
								...item,
								human: item.age === 3 ? 4 : item.age === 2 ? 3 : item.sex
							}
						}),
						services: get().selectedServices,
						transports: get().selectedTransport
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports
					}
				},
				currencyId: get().currency,
				userAgent: process.env.REACT_APP_USER_AGENT
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/ActualizePacket`,
					method: 'POST',
					data: req
				})
				set({ claimNew: res.data.claim })
				set({ selectedServices: res.data?.claim?.claimDocument?.services })
			} catch (error) {
				set({ bookingError: error.response?.data?.detail })
				console.log(error)
			} finally {
				set({ newBookingLoading: false })
			}
		},
		checkReservation: async loyaltyData => {
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),

				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples.map(item => {
							return {
								...item,
								human: item.age === 3 ? 4 : item.age === 2 ? 3 : item.sex
							}
						}),
						services: get().selectedServices,
						buyer: get().buyer,
						transports: get().selectedTransport
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports
					}
				},
				currencyId: get().currency,
				userAgent: get().payload.userAgent
			}
			set({ payloadNew: req })
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/CheckReservation`,
					method: 'POST',
					data: req
				})

				set({ claimNew: res.data.claim })
				if (get().checkedPreReserve) {
					await get().actions.preReserve(loyaltyData)
				} else {
					await get().actions.reserve(loyaltyData)
				}
			} catch (error) {
				const tour = JSON.parse(localStorage.getItem('tour'))
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsAdjust(error.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotel?.name
				})
				set({ bookingError: error.response?.data?.detail })
				console.error(error)
			} finally {
				set({ newBookingLoading: false })
			}
		},
		reserve: async loyaltyData => {
			set({ newBookingLoading: true })
			let reg = loyaltyData?.preorder?.order?.lines[0].appliedPromotions.find(
				item => item.type === 'earnedBonusPoints'
			)
			const percentSale = reg?.promotion?.name.match(loyalSale)
			const promoDiscount = loyaltyData.preorder?.order?.lines?.reduce((acc, item) => {
				item?.appliedPromotions?.forEach(value => {
					if (value.type === 'discount') {
						acc = acc + value.amount
					}
				})
				return acc
			}, 0)
			const earnedAmount =
				loyaltyData.preorder?.order?.bonusPointsChanges[0]?.earnedAmount
			const spentPoints =
				loyaltyData.preorder?.order?.bonusPointsChanges[0]?.spentPoints

			await get().applicationNotes

			const notePL = loyaltyData.client
				? `#LP#${loyaltyData.memberId - 1};${
						loyaltyData.client?.customer?.ids.mindboxId
				  };${percentSale ? percentSale[1].replace('%', '') : '0'};${
						Math.floor(Number(loyaltyData.spentPoints)) ||
						Math.floor(Number(spentPoints)) ||
						'0'
				  };${Math.floor(promoDiscount)};${
						Math.floor(Number(earnedAmount)) || '0'
				  }#LP#`
				: ''

			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples.map(item => {
							return {
								...item,
								human: item.age === 3 ? 4 : item.age === 2 ? 3 : item.sex
							}
						}),
						services: get().selectedServices,
						buyer: get().buyer,
						transports: get().selectedTransport,
						note: `${notePL} ${get().applicationNotes}`
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports
					}
				},
				currencyId: get().currency,
				userAgent: get().payload.userAgent
			}

			try {
				set({ loading: true })
				const res = await axios({
					url: `${BASE_URL}/PacketTour/Reserve`,
					method: 'POST',
					data: req
					// headers: {
					// 	"X-User-Agent": process.env.REACT_APP_USER_AGENT
					// }
				})
				set({ claimId: res?.data?.claim?.claimDocument?.providerNumber })
			} catch (e) {
				const tour = get().claimNew?.claimDocument
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsReserve(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotels[0]?.name
				})
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.detail })
			} finally {
				set({ newBookingLoading: false })
				set({ readyToBooking: false })
			}
		},
		preReserve: async loyaltyData => {
			set({ newBookingLoading: true })
			let reg = loyaltyData?.preorder?.order?.lines[0].appliedPromotions.find(
				item => item.type === 'earnedBonusPoints'
			)
			const percentSale = reg?.promotion?.name.match(loyalSale)
			const promoDiscount = loyaltyData.preorder?.order?.lines.reduce((acc, item) => {
						item?.appliedPromotions?.forEach(value => {
							if (value.type === 'discount') {
								acc = acc + value.amount
							}
						})
						return acc
					}, 0)
	
			const earnedAmount =
				loyaltyData.preorder?.order?.bonusPointsChanges[0]?.earnedAmount
			const spentPoints =
				loyaltyData.preorder?.order?.bonusPointsChanges[0]?.spentPoints

			await get().applicationNotes

			const notePL = loyaltyData.client
				? `#LP#${loyaltyData.memberId - 1};${
						loyaltyData.client?.customer?.ids.mindboxId
				  };${percentSale ? percentSale[1].replace('%', '') : '0'};${
						Math.floor(Number(loyaltyData.spentPoints)) ||
						Math.floor(Number(spentPoints)) ||
						'0'
				  };${Math.floor(promoDiscount)};${
						Math.floor(Number(earnedAmount)) || '0'
				  }#LP#`
				: ''

			const req = {
				clientId: 'b2b:ru',
				partpassId: Number(useAuthStore.getState().agent.partPass),
				claim: {
					...get().claimNew,
					claimDocument: {
						...get().claimNew.claimDocument,
						peoples: get().peoples.map(item => {
							return {
								...item,
								human: item.age === 3 ? 4 : item.sex
							}
						}),
						services: get().selectedServices,
						buyer: get().buyer,
						transports: get().selectedTransport.map(item => {
							return {
								...item,
								boardingAvailable: item.boardingAvailable ? 1 : 0
							}
						}),
						note: `${notePL} ${get().applicationNotes}`
					},
					variants: {
						...get().claimNew.variants,
						services: [
							...get().services,
							...get().insurances,
							...get().visaServices
						],
						transports: get().transports.map(item => {
							return {
								...item,
								boardingAvailable: item.boardingAvailable ? 1 : 0
							}
						})
					}
				},
				currencyId: get().currency,
				userAgent: get().payload.userAgent
			}

			try {
				set({ loading: true })
				const res = await axios({
					url: `${BASE_URL}/PacketTour/PreReserve`,
					method: 'POST',
					data: req
					// headers: {
					// 	"X-User-Agent": process.env.REACT_APP_USER_AGENT
					// }
				})
				set({ claimId: res?.data?.claim?.claimDocument?.providerNumber })
			} catch (e) {
				const tour = get().claimNew?.claimDocument
				const departureCityId = JSON.parse(
					localStorage.getItem('departureCityId')
				)
				sendAnalyticsReserve(e.response?.data?.detail, {
					country: tour?.name,
					departureCity: departureCityId,
					hotel: tour?.hotels[0]?.name
				})
				console.log(e?.response?.data)
				set({ bookingError: e.response?.data?.detail })
			} finally {
				set({ newBookingLoading: false })
				set({ readyToBooking: false })
			}
		},

		getOtherService: async () => {
			set({ newBookingLoading: true })
			try {
				const res = await axios({
					url: `${BASE_URL}/PacketTour/getReservationServices`,
					method: 'POST',
					data: {
						claim: get().claimNew,
						clientId: 'b2b:ru',
						partpassId: Number(useAuthStore.getState().agent.partPass),
						userAgent: process.env.REACT_APP_USER_AGENT
					}
				})
				const newServices = res.data?.claim?.variants?.services

				const oldServices = get().services.filter(
					oldService => !newServices.find(i => i.uid === oldService.uid)
				)
				const newServicesGroups = res.data?.claim?.groups.filter(
					i =>
						!get()?.medicalGroups.some(el => el?.id === i.id) &&
						!get().visaGroups?.some(el => el?.id === i.id)
				)
				const oldServicesGroups = get().servicesGroups.filter(
					group =>
						!newServicesGroups.find(
							oldGroup =>
								oldGroup.description === group.description ||
								oldGroup.id === group.id
						)
				)
				set({ prevClaim: res.data.claim })
				set({ servicesGroups: [...oldServicesGroups, ...newServicesGroups] })
				set({ services: [...oldServices, ...newServices] })
				const medicalGroups = res.data?.claim?.groups.filter(
					i =>
						i.description === 'Комплексная' || i.description === 'Медицинская'
				)
				const visaGroups = res.data.claim.groups.filter(
					i => i.description === 'Виза'
				)

				let selectedServices = res.data?.claim?.claimDocument?.services
				const serviceVisa = selectedServices.find(visa =>
					visaGroups?.some(el => el?.id === visa.groupId)
				)
				if (serviceVisa) {
					selectedServices = selectedServices.filter(
						i => i.uid !== serviceVisa.uid
					)
					serviceVisa.clients = res.data?.claim?.claimDocument?.peoples.map(
						people => ({ peopleKey: people.key, common: 0 })
					)
					selectedServices.push(serviceVisa)
				}

				set({ visaGroups: visaGroups })
				set({ medicalGroups: medicalGroups })
				set({
					servicesGroups: res.data?.claim?.groups.filter(
						i =>
							!medicalGroups.some(el => el.id === i.id) &&
							!visaGroups?.some(el => el?.id === i.id)
					)
				})
				set({
					services: res.data?.claim?.variants?.services?.filter(
						i =>
							!medicalGroups.some(el => i.groupId === el.id) &&
							!visaGroups?.some(el => el?.id === i.id)
					)
				})
				set({
					insurances: res.data?.claim?.variants?.services?.filter(i =>
						medicalGroups.some(el => i.groupId === el.id)
					)
				})
				set({
					visaServices: res.data?.claim?.variants?.services?.filter(i =>
						visaGroups?.some(el => el?.id === i.groupId)
					)
				})
				set({ selectedServices: selectedServices })
			} catch (error) {
				console.error(error)
			} finally {
				set({ newBookingLoading: false })
			}
		},
		getLocalCurrencieValue: async currencyTo => {
			try {
				const currentDate = new Date()
				const localCurrencieValue = await axios({
					url: `${BASE_URL}currency/rates`,
					method: 'POST',
					data: {
						clientId: 'b2b:ru',
						currencyFrom: 1,
						currencyTo: currencyTo,
						dateFrom: currentDate,
						dateTo: currentDate
					},
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})

				set(() => ({
					currencys: {
						...get().currencys,
						localCurrencieValue:
							localCurrencieValue.data.currencyRates.reverse()
					}
				}))
			} catch (error) {
				console.log(error)
			}
		},
		getVisasSchedule: async payload => {
			try {
				const result = await axios({
					url: `${BASE_URL}/Visas/schedule`,
					method: 'GET',
					params: {
						DepartureCityId: payload.townFromKey || 1,
						ArrivalCountryId: payload.stateKey,
						ArrivalDate: payload.dateBeg
					},
					headers: {
						'X-User-Agent': process.env.REACT_APP_USER_AGENT
					}
				})

				set({ visas: result.data })
			} catch (error) {
				console.log(error)
			}
		},
		changeVisa: (id, peopleKey) => {
			let newVisaServices = get().visaServices
			let newSelectedServices = get()?.selectedServices
			let existVisa = newVisaServices?.find(service => service?.key === id)
			if (!existVisa) {
				existVisa = newSelectedServices.find(service => service?.key === id)
			}

			newVisaServices = newVisaServices.filter(
				service => service.uid !== existVisa.uid
			)
			newSelectedServices = newSelectedServices.filter(
				service => service.uid !== existVisa.uid
			)

			const removedVisa = newSelectedServices.find(
				i =>
					get().visaGroups.some(group => group.id === i.groupId) &&
					i.clients.some(client => client.peopleKey === peopleKey)
			)
			if (removedVisa) {
				newSelectedServices = newSelectedServices.filter(
					i => i.uid !== removedVisa.uid
				)
				removedVisa.clients = removedVisa.clients.filter(
					client => client.peopleKey !== peopleKey
				)
				console.log(removedVisa)
				isEmpty(removedVisa.clients)
					? newVisaServices.push(removedVisa)
					: newSelectedServices.push(removedVisa)
			}

			existVisa.clients.push({ peopleKey: peopleKey, common: 0 })
			newSelectedServices.push(existVisa)

			set({ visaServices: newVisaServices })
			set({ selectedServices: newSelectedServices })
		},
		changeBuyer: (key, value) => {
			set({ buyer: { ...get().buyer, [key]: value } })
		},
		getNotification: (prevClaim, newClaim) => {
			if (!prevClaim || !newClaim) return

			let newServices = newClaim.claimDocument.services
				.filter(
					item =>
						!prevClaim.claimDocument.services.find(el => el.uid === item.uid)
				)
				?.filter(i => !i?.parentUid)

			const removedServices = newClaim.variants.services
				.filter(
					item => !prevClaim.variants.services.find(el => el.uid === item.uid)
				)
				?.filter(i => !i?.parentUid)

			const newMoney = newClaim.claimDocument.moneys.find(
				item => item.currencyKey === newClaim.claimDocument.currencyKey
			)
			const prevMoney = prevClaim.claimDocument.moneys.find(
				item => item.currencyKey === prevClaim.claimDocument.currencyKey
			)

			const deltaPrice = newMoney.price - prevMoney.price
			const currencyAlias = newMoney.currency
			const notification = []

			//work around для Топливного сбора
			if (!get().isFirstRecount) {
				newServices = newServices.filter(i => !i?.required)
			}

			newServices.forEach(item => {
				const peopleCount =
					prevClaim?.claimDocument?.services.find(
						service => item.uid === service.uid
					)?.clients?.length ?? newClaim?.claimDocument?.peopleCount

				notification.push({
					targetType: 'service',
					targetStatus: 'Added',
					targetId: item.uid,
					targetName: item.name,
					description: `Добавлена ${
						item?.serviceCategoryName ? item?.serviceCategoryName : ''
					} ${item.name} для ${peopleCount} туриста(ов) стоимостью ${
						item.price
					} ${currencyAlias}`
				})
			})
			removedServices.forEach(item => {
				const peopleCount =
					prevClaim?.claimDocument?.services.find(
						service => item.uid === service.uid
					)?.clients?.length ?? newClaim?.claimDocument?.peopleCount
				notification.push({
					targetType: 'service',
					targetStatus: 'Removed',
					targetId: item.uid,
					targetName: item.name,
					description: `Удалена услуга ${
						item?.serviceCategoryName ? item?.serviceCategoryName : ''
					} ${item.name} для ${item.clients?.length} туриста(ов) стоимостью ${
						item.price
					} ${currencyAlias}`
				})
			})

			if (deltaPrice !== 0) {
				notification.push({
					description: `Изменена стоимость тура : ${
						deltaPrice > 0 ? ' + ' + deltaPrice : deltaPrice
					} ${currencyAlias}`,
					newPrice: 86557,
					oldPrice: 79487,
					targetStatus: 'Modified',
					targetType: 'price',
					targetId: 'price'
				})
			}

			set({ notifications: notification })
			set({ isFirstRecount: false })
		},
		closeNotification: uid => {
			set({
				notifications: get().notifications?.filter(i => i.targetId !== uid)
			})
		},
		clearNotification: () => {
			set({ notifications: [] })
		},
		changeApplyContactsForAll: value => {
			set({ applyContactsForAll: value })
			if (value) {
				const commonPhone = get().peoples.find(
					item => item.key === value
				)?.phone
				const commonEmail = get().peoples.find(
					item => item.key === value
				)?.email
				set({
					peoples: get().peoples.map(item => ({
						...item,
						phone: commonPhone,
						email: commonEmail
					}))
				})
			}
		},
		checkPreReserve: value => {
			set({ checkedPreReserve: value })
		},
		clearBuyerErrors: prop => {
			set({ buyerErrors: { ...get().buyerErrors, [prop]: null } })
		},
		// clearNotesErrors: () =>
		// 	set({
		// 		notesErrors: []
		// 	}),
		setNotesErrors: () => {
			// set({
			// 	notesErrors: []
			// })

			let requiredFields = [
				'name',
				'pserie',
				'pnumber',
				'address',
				'email',
				'phone'
			]
			switch (get().buyer.buyerType) {
				case 0:
					requiredFields.forEach(item => {
						if (!get().buyer[item]) {
							set({
								buyerErrors: {
									...get().buyerErrors,
									[item]:
										item === 'name'
											? 'ФИО обязательно для заполнения'
											: item === 'pserie'
											? 'Серия обязательна для заполнения'
											: item === 'pnumber'
											? 'Номер обязателен для заполнения'
											: item === 'email'
											? 'Email обязателен для заполнения'
											: item === 'phone'
											? 'Телефон обязателен для заполнения'
											: 'Адрес обязателен для заполнения'
								}
							})
						}
					})
					break
				case 2:
					requiredFields = [
						'name',
						'pserie',
						'pnumber',
						'address',
						'inn',
						'email',
						'phone'
					]
					requiredFields.forEach(item => {
						if (!get().buyer[item]) {
							set({
								buyerErrors: {
									...get().buyerErrors,
									[item]:
										item === 'name'
											? 'ФИО обязательно для заполнения'
											: item === 'pserie'
											? 'Серия обязательна для заполнения'
											: item === 'pnumber'
											? 'Номер обязателен для заполнения'
											: item === 'inn'
											? 'Инн обязателен для заполнения'
											: item === 'email'
											? 'Email обязателен для заполнения'
											: item === 'phone'
											? 'Телефон обязателен для заполнения'
											: 'Адрес обязателен для заполнения'
								}
							})
						}
					})

					break
				case 1:
					requiredFields = ['firmName', 'email', 'address', 'inn', 'phone']
					requiredFields.forEach(item => {
						if (!get().buyer[item]) {
							set({
								buyerErrors: {
									...get().buyerErrors,
									[item]:
										item === 'firmName'
											? 'Официальное название обязательно для заполнения'
											: item === 'email'
											? 'Email обязателен для заполнения'
											: item === 'inn'
											? 'Инн обязателен для заполнения'
											: item === 'phone'
											? 'Телефон обязателен для заполнения'
											: 'Адрес обязателен для заполнения'
								}
							})
						}
					})
					break
				default:
					// set({
					// 	notesErrors: []
					// })
					break
			}

			// const errors = Object.values(get().buyerErrors).filter(item => !!item)
			// if (!isEmpty(errors)) {
			// 	set({ notesErrors: errors })
			// 	const element = document.getElementById(`buyer-block-info`)
			// 	element.scrollIntoView({ behavior: 'smooth' })
			// } else return
		},
		GetTourDescription: async tourKey => {
			set({ newBookingLoading: true })
			const req = {
				clientId: 'b2b:ru',
				tourId: tourKey
			}
			try {
				const res = await axios({
					url: `${BASE_URL}/TourSearch/GetTourDescription`,
					method: 'POST',
					data: req
				})
				set({ bookingNote: res.data.note })
			} catch (e) {
			} finally {
				set({ newBookingLoading: false })
			}
		},
		setBuyerErrors: (prop, value) => {
			set({ buyerErrors: { ...get().buyerErrors, [prop]: value } })
		},
		clearCurrentError: () => {
			set({
				buyerErrors: {
					name: null,
					phone: null,
					email: null,
					pserie: null,
					pnumber: null,
					address: null,
					nationalityId: null,
					agencyDocDate: null,
					agencyDocNumber: null,
					agencyDocSum: null,
					inn: null,
					boss: null
				}
			})
		},
		clearBuyerField: () => {
			set({
				buyer: {
					buyerType: 0,
					nationalityId: '210357',
					sendGis: '1',
					identity_document: 1,
					born: '0001-01-01',
					pgiven: '0001-01-01',
					pexpire: '0001-01-01',
					nationalityKey: '210357',
					firmState: '210357',
				}
			})
		},
		clearBoarding: () => {
			set({ boarding: [] })
		},
		setIsReadyToReserve: value => {
			set({ isReadyToReserve: value })
		}
	}
}))

export default useBookingStore
