import React, { useEffect } from 'react'
import CountryField from '../../../../components/Services/CountryField'
import TouristsField from '../TouristsField'
import { Button } from '@material-ui/core'
import useYachtRentServicesStore from '../../store'
import DateInputRange from '../../../../components/Services/DateInputRange'
// import declination from "../../../../functions/declination";
import { Box } from '@mui/material'
import LinearProgress from '@material-ui/core/LinearProgress'
import './style.scss'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

const HotelServiceSearchForm = () => {
	const actions = useYachtRentServicesStore(({ actions }) => actions)
	const countries = useYachtRentServicesStore(({ countries }) => countries)
	const currentCountry = useYachtRentServicesStore(
		({ currentCountry }) => currentCountry
	)
	const cities = useYachtRentServicesStore(({ cities }) => cities)
	const currentCity = useYachtRentServicesStore(
		({ currentCity }) => currentCity
	)
	const adults = useYachtRentServicesStore(({ adults }) => adults)
	const children = useYachtRentServicesStore(({ children }) => children)
	const excursions = useYachtRentServicesStore(({ excursions }) => excursions)
	const loadingSearch = useYachtRentServicesStore(
		({ loadingSearch }) => loadingSearch
	)
	const date = useYachtRentServicesStore(({ date }) => date)

	const navigate = useNavigate()

	useEffect(() => {
		actions.postCountries()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (currentCountry) {
			actions.postCities(currentCountry)
		}
		// eslint-disable-next-line
	}, [currentCountry])

	const handleChangeCountry = id => {
		actions.setCountry(id)
	}

	const handleChangeDate = value => {
		actions.setDate(value)
	}

	const handleSearch = () => {
		navigate(
			`?countryId=${currentCountry}&startDate=${date.dateFrom}&endDate=${date.dateTo}&adults=${adults}&childrens=${children}&departureCityId=${currentCity}`
		)
		actions.postSearch()
	}

	if (!countries || !cities) return

	return (
		<div className={'hotel-service-page'}>
			<div className={`hotel-service-search-form-wrapper background-color`}>
				<div className={`hotel-service-search-form `}>
					<CountryField
						className={'hotel-services-countries'}
						lists={countries}
						value={currentCountry}
						title={'Страна поездки'}
						onChange={handleChangeCountry}
					/>
					<CountryField
						className={'hotel-service-city'}
						lists={cities}
						value={currentCity}
						title={'Куда'}
						onChange={id => actions.setCity(id)}
					/>
					<DateInputRange
						label='Даты поездки'
						value={[new Date(date.dateFrom), new Date(date.dateTo)]}
						onChange={handleChangeDate}
					/>
					<TouristsField
						className={'tourists'}
						actions={actions}
						adults={adults}
						children={children}
						title={'Туристы'}
					/>
					<Button
						onClick={handleSearch}
						variant={'contained'}
						className={'search-button'}
						// disabled={loading}
					>
						Найти
					</Button>
				</div>
			</div>
			<Box className={'container'} mt={2}>
				{((!excursions && loadingSearch) ||
					(isEmpty(excursions) && loadingSearch)) && (
					<div style={{ color: '#49c8c0', fontSize: 16 }}>Идет поиск.</div>
				)}
				{loadingSearch && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress
							variant='determinate'
							value={excursions?.length * 6.25}
						/>
					</Box>
				)}
			</Box>
		</div>
	)
}

export default HotelServiceSearchForm
