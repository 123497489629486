import React, {useEffect} from 'react';
import CountryField from "../CountryField";
import TouristsField from "../Tourists";
import {Button} from "@material-ui/core";
import useInsuranceStore from "../../store";
import DateInputRange from "../DateInputRange";
import {uniqBy} from "lodash";
import {addDays} from "date-fns";
import declination from "../../../../../functions/declination";
import {Box} from "@mui/material";
import LinearProgress from "@material-ui/core/LinearProgress";
import './style.scss'

const InsuranceSearchForm = ({ withTitle= true }) => {

	const actions = useInsuranceStore(({ actions }) => actions)
	const countries = useInsuranceStore(({ countries }) => countries)
	const currentCountry = useInsuranceStore(({ currentCountry }) => currentCountry)
	const adults = useInsuranceStore(({ adults }) => adults)
	const loading = useInsuranceStore(({ loading }) => loading)
	const countCompany = useInsuranceStore(({ countCompany }) => countCompany)
	const isCompleted = useInsuranceStore(({ isCompleted }) => isCompleted)
	const insurances = useInsuranceStore(({ insurances }) => insurances)
	const countItem = useInsuranceStore(({ countItem }) => countItem)
	const ages = useInsuranceStore(({ ages }) => ages)

	useEffect(() => {
		actions.getCountries()
		actions.getInsuranceAmount()
		actions.getActivites()
		actions.getSports()
		actions.getCompanies()
		// eslint-disable-next-line
	}, [])

	const handleChangeDate = (value) => {
		actions.setDate(value)
	}

	const handleChangeTourists = (value, id) => {
		console.log(value, 'handleChangeTourists value')
		console.log(id, 'handleChangeDate id')
	}

	const handleSearch = () => {
		localStorage.setItem('ages', JSON.stringify(ages))
		actions.calculate()
	}

	if (!countries) return

	return (
		<div className={'insurance-page'}>
			{/* <h3>Найдите страховку на все случаи жизни:</h3> */}
			<div className="insurance-search-form-wrapper">
				<div className={'insurance-search-form'}>
					<CountryField
						className={'insurance-countries'}
						lists={uniqBy(countries, 'value')}
						value={currentCountry}
						title={'Страна'}
						onChange={actions.setCountry}
					/>
					<DateInputRange
						label="Даты поездки"
						value={[addDays(new Date(),1), addDays(new Date(),14)]}
						onChange={handleChangeDate}
					/>
					<TouristsField
						className={'tourists_insurance'}
						onChange={handleChangeTourists}
						adults={adults}
						title={'Туристы'}
					/>
					<Button
						onClick={handleSearch}
						variant={'contained'}
						className={'search-button'}
						disabled={loading}
					>
						Найти
					</Button>
				</div>
			</div>

			{!insurances && loading &&
				<div style={{color: '#49c8c0', fontSize: 16}}>
					Идет поиск.
				</div>
			}
			{insurances &&
				<div style={{color: '#49c8c0', fontSize: 16}}>
					{(isCompleted || !loading) ? 'Поиск завершен. ' : 'Идет поиск. '}
					{!!countItem && `Найдено ${countItem} ${declination(countItem, ['предложение', 'предложения', 'предложений'])}.`}
					{isCompleted && !countItem && 'Предложений не найдено'}
				</div>
			}
			{loading && !isCompleted &&
				<Box sx={{width: '100%'}}>
					<LinearProgress variant="determinate" value={countCompany * 6.25}/>
				</Box>
			}
		</div>
	);
};

export default InsuranceSearchForm;