import React from "react"
import { transformDecksToSelectedSeatsObject } from "./utils"

const SeatsMapContext = React.createContext(null)

export const useSeatsMap = () => React.useContext(SeatsMapContext)

export function SeatMapProvider({ children, ...props }) {
  const [selectedSeats, setSelectedSeats] = React.useState(
    transformDecksToSelectedSeatsObject(props.decks)
  )
  const [selectedDeckIndex, setSelectedDeckIndex] = React.useState(0)

  const handlePlaceClick = number => {
    if (selectedSeats[props.decks[selectedDeckIndex].type][number]) {
      selectedSeats[props.decks[selectedDeckIndex].type][number] = false
    } else {
      selectedSeats[props.decks[selectedDeckIndex].type][number] = true
    }
    setSelectedSeats({ ...selectedSeats })
  }

  return (
    <SeatsMapContext.Provider
      value={{
        selectedSeats: props.selectedSeats,
        selectedDeckIndex,
        setSelectedDeckIndex,
        handlePlaceClick,
        onPlaceSelect: props.onPlaceSelect,
        pricesArr: props.pricesArr
      }}
    >
      {children({
        selectedSeats: props.selectedSeats,
        selectedDeckIndex,
        setSelectedDeckIndex,
        handlePlaceClick,
        onPlaceSelect: props.onPlaceSelect,
        pricesArr: props.pricesArr
      })}
    </SeatsMapContext.Provider>
  )
}
