import { useState, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import PaxesBlock from '../../../components/Services/PaxesBlock/'
import ServiceInfo from './components/ServiceInfo'
import useHotelServiceStore from './store'
import { Button } from '@material-ui/core'
import BuyerBlock from '../../../components/Services/BuyerBlock'
import { isEmpty } from 'lodash'
import { checkValidationError, checkValidationBuyerError } from '../../../components/Services/PaxesBlock/checkValidationError'
import { Alert, Snackbar } from '@mui/material'
import HotelInfo from './components/HotelInfo'
import CustomModal from '../../../components/CustomModal'
import BookingSuccessPopup from './components/BookingSuccessPopup'
import {useErrorStore} from '../../../components/Services/PaxesBlock/store';

function YachtRentBooking() {
	const store = useHotelServiceStore((state) => state)
	const loading = useHotelServiceStore(({ loading }) => loading)
	const paxes = useHotelServiceStore(({ paxes }) => paxes)
	const excursion = useHotelServiceStore(({ excursion }) => excursion)
	const actions = useHotelServiceStore(({ actions }) => actions)
	const additional = useHotelServiceStore(({ additional }) => additional)
	const selectedHotel = useHotelServiceStore(
		({ selectedHotel }) => selectedHotel
	)
	// const isReadyForBooking = useHotelServiceStore(
	// 	({ isReadyForBooking }) => isReadyForBooking
	// )
	const buyer = useHotelServiceStore(({ buyer }) => buyer)
	const isBookingError = useHotelServiceStore(
		({ isBookingError }) => isBookingError
	)
	const bookingLoadingError = useHotelServiceStore(
		({ bookingLoadingError }) => bookingLoadingError
	)
	// const errorForm = useErrorStore(state => state.error)
	const orderId = useHotelServiceStore(({ orderId }) => orderId)
	const navigate = useNavigate()
    const checkValid = useErrorStore(state => state.checkValid)


	const backToSearch = () => {
		actions.clearError()
		navigate('/searchyachtrent')
	}

	const [errors, setErrors] = useState(null)

	useEffect(() => {
		actions.getService()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!excursion) {
		return
	}

	const handleBooking = () => {
		checkValid()
		actions.clearError()
		let error = {}
		let paxCountWithError = []

		paxes?.forEach((paxe, index) => {
			const paxError = checkValidationError(paxe)
			if (paxError) {
				if (!paxCountWithError.includes(index + 1)) {
                    paxCountWithError.push(index + 1);
                }
				error = { ...error, ...paxError }
			}
		})

		const buyerErrors = checkValidationBuyerError(buyer)

		if (!isEmpty(buyerErrors)) {
			error = { ...error, ...buyerErrors }
		}

		if (!selectedHotel) {
			error = { ...error, hotel: "Укажите адрес проживания" }
		}

		if (!isEmpty(error)) {
			setErrors(error)
			document.getElementById(`info-${paxCountWithError[0]}`)?.scrollIntoView({ behavior: 'smooth' })
			return
		}

		// actions.changeIsReadyForBooking(true)
		actions.reserve()
	}



	if (bookingLoadingError) {
		return (
			<CustomModal
				title='Ошибка бронирования'
				text={bookingLoadingError}
				successButtonTitle='Понятно'
				onSuccess={backToSearch}
			/>
		)
	}

	return (
		<div className='container'>
			<div>
				<ServiceInfo service={excursion} additional={additional} />
				<HotelInfo />
				{!isEmpty(paxes) && (
					<Fragment>
						<div>
							{paxes?.map((paxe, i) => (
								<PaxesBlock key={paxe + i} count={i + 1} store={store} />
							))}
						</div>
						<div>
							<BuyerBlock store={store} />
						</div>
					</Fragment>
				)}
			</div>

			<div
				style={{
					textAlign: 'center',
					marginBottom: 20,
					fontSize: 24,
					fontWeight: 600
				}}>
				{excursion?.price?.toLocaleString('ru', {})}{' '}
				{excursion?.currency?.currencyAlias}
			</div>
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				{/* <Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={handleRecountDraft}
					disabled={loading}>
					Пересчитать
				</Button> */}
				<Button
					variant='contained'
					onClick={handleBooking}
					disabled={loading }>
					Бронировать
				</Button>
			</div>
			{errors && (
				<Snackbar
					open={Object.values(errors).some(item => item !== '')}
					autoHideDuration={6000}>
					<Alert
						onClose={() => {
							setErrors(null)
						}}
						severity='error'
						sx={{ width: '100%' }}>
						{Object.values(errors).filter(item => item !== '')[0]}
					</Alert>
				</Snackbar>
			)}
			{orderId && <BookingSuccessPopup claim={orderId} />}
			{isBookingError  &&
					<CustomModal
						title='Ошибка бронирования'
						text={isBookingError}
						successButtonTitle='Понятно'
						onSuccess={() => actions.clearError()}
					/>}
		</div>
	)
}

export default YachtRentBooking
