import React from "react"
import styled from "styled-components"
import { Text, Button } from "../../ui"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import OutlinedButton from "../../ui/OutlinedButton";

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    display: block;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
    @media screen and (min-width: 375px) and (max-width: 1439px) {
      width: 335px;
      height: 218px;
    }
  }
`

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;
  @media screen and (min-width: 375px) and (max-width: 1439px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }
`

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
  max-width: 430px;
  @media screen and (min-width: 375px) and (max-width: 1439px) {
    max-width: 313px;
    margin-bottom: 20px;
    line-height: 22px;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const IssueModal = ({ issueStatus, onClose, ...props }) => {
  return (
    <StyledPopup {...props} onClose={onClose}>
      <CloseButton
        aria-label="Закрыть окно"
        onClick={onClose}
      >
        <Icon/>
      </CloseButton>
      <Title>
        {issueStatus?.ticketIssue === true
          ? "Успешная выписка"
          : "Выписка недоступна"}
      </Title>
      <Question>
        {issueStatus?.ticketIssue === true
          ? "Выписка билетов по данной заявке прошла успешно. Статус заявки обновлен."
          : `${issueStatus?.errorText}`}
      </Question>
      <OutlinedButton  onClick={() => onClose()}>
        Вернуться к деталям заявки
      </OutlinedButton>
    </StyledPopup>
  )
}

export default IssueModal
