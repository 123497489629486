import React, { useEffect, useState } from 'react';
import useGeoStore from "../LandingPage/store";
import MainBannerSlider from "../LandingPage/components/MainBannerSlider";
import StaticBlocks from "../LandingPage/StaticBlocks";
import {Helmet} from "react-helmet";

const MainPage = () => {

	const actions = useGeoStore(({ actions }) => actions)
	const content = useGeoStore(({ content }) => content)
	const [isUpdated, setIsUpdated] = useState(false)

	useEffect(() => {
		actions.getPage('/').then(setIsUpdated(true))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		return () => {
			actions.clearContent()
		}
		// eslint-disable-next-line
	}, [])

	if (!content || !isUpdated)
		return null;

	return (
		<div className={'landing landing-container'}>
			<Helmet title={content?.meta_title || content?.title} />
			{JSON.parse(content.main_banner.content).mainBanner &&
				<MainBannerSlider content={JSON.parse(content.main_banner.content)} />
			}
			<StaticBlocks blocks={content.blocks.filter(block => block.show_desktop)} />
		</div>
	);
};

export default MainPage;

