import React from 'react';

const DescriptionModal = ({ onClose, content }) => {

	React.useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => document.body.style.overflow = '';
	})
	const titleBanner = content?.listIcons?.map(item => item?.title).join(' / ')

	return (
		<div 
			className="DescriptionModal-bg"  
			onClick={() => onClose(false)}  >

			<div className="DescriptionModal-content" onClick={(e) => {e.stopPropagation()}}>
				<div onClick={() => onClose(false)} className='popup-cross'></div>
				{content.bannerImg && (
					<div 
						className='DescriptionModal-banner' 
						style={{ backgroundImage: `url(${content.bannerImg})` }}>
							<div className='DescriptionModal-header'>
							</div>
							<div className="DescriptionModal-banner-title">{titleBanner}</div>
					</div>
				)}
				<div className='IconListPopup_listIcons'>
					{content.listIcons?.map((item, index) =>
						<div key={item?.id} className='IconListPopup_iconItem'>
							<div style={{ backgroundColor: item?.bgColor }} className='IconListPopup_iconItem_image-container'>
								{item?.bgImg && <div className='IconListPopup_iconItem_bgImg' style={{ backgroundImage: `url(${item?.bgImg})` }}></div>}
							</div>
							<div>
								{item?.title && <h4 className='IconListPopup_iconItem_title' dangerouslySetInnerHTML={{ __html: item?.title }} ></h4>}
								{item?.text && <p className='IconListPopup_iconItem_text' dangerouslySetInnerHTML={{ __html: item?.text }} ></p>}
							</div>
						</div>
					)}
       			</div>
			</div>
		</div>
	);
};

export default DescriptionModal;
