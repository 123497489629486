import React from 'react';
import { SvgIcon } from "@mui/material";

function Deadlines(props) {
    return <SvgIcon {...props} viewBox="0 0 18 18" fill="none" style={{ width: "18px", height: "18px" }} >
        <g clipPath="url(#clip0_930_14621)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 16.875C11.0886 16.875 13.0916 16.0453 14.5685 14.5685C16.0453 13.0916 16.875 11.0886 16.875 9C16.875 6.91142 16.0453 4.90838 14.5685 3.43153C13.0916 1.95468 11.0886 1.125 9 1.125C6.91142 1.125 4.90838 1.95468 3.43153 3.43153C1.95468 4.90838 1.125 6.91142 1.125 9C1.125 11.0886 1.95468 13.0916 3.43153 14.5685C4.90838 16.0453 6.91142 16.875 9 16.875ZM18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9Z" fill="#3C3C3C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.4375 3.375C8.58668 3.375 8.72976 3.43426 8.83525 3.53975C8.94074 3.64524 9 3.78832 9 3.9375V9.79875L12.654 11.8867C12.7798 11.9626 12.8709 12.0847 12.9078 12.2268C12.9448 12.369 12.9247 12.5199 12.8518 12.6475C12.779 12.775 12.6591 12.869 12.5179 12.9093C12.3766 12.9496 12.2252 12.9331 12.096 12.8632L8.1585 10.6132C8.07241 10.5641 8.00084 10.493 7.95106 10.4073C7.90127 10.3215 7.87503 10.2241 7.875 10.125V3.9375C7.875 3.78832 7.93426 3.64524 8.03975 3.53975C8.14524 3.43426 8.28832 3.375 8.4375 3.375Z" fill="#3C3C3C" />
        </g>
        <defs>
            <clipPath id="clip0_930_14621">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>
}

export default Deadlines