import {
  initializeSearchForm,
  setDestination,
  setDate,
  setPassengers,
  setTicketClass,
  updateSearchFormState,
  setPromotions
} from './duck';
import parseSearchParams from '../../../functions/parseSearchParams'
import { call, put, all, takeLatest, select, spawn } from 'redux-saga/effects';
import * as simpleSearchManager from './manager';
import moment from 'moment';


export function* inisializeWorker({ payload }) {
  try {
    const queryParams = new URLSearchParams(
      decodeURIComponent(window.location.search)
    );
    const originFromQueryParams = queryParams.get('origin');
    const originFromLocalStorage = window.localStorage.getItem('origin');

    const destinationFromQueryParams = queryParams.get('destination');
    const destinationFromLocalStorage = window.localStorage.getItem(
      'destination'
    );

    const finalOrigin = originFromQueryParams || originFromLocalStorage;
    if (!finalOrigin) {
      return;
    }

    const cityList = yield call(
      simpleSearchManager.getCityByCode,
      finalOrigin
    );

    if (cityList) {
      yield put(
        setDestination({
          from: cityList,
          withoutChangeQueryParams: true,
        })
      );
      if (
        originFromQueryParams !== cityList.iataCode &&
        window.location.pathname === '/'
      ) {
        queryParams.set('origin', cityList.iataCode);
        window.history.replaceState(
          { path: window.location.pathname + '?' + queryParams.toString() },
          '',
          window.location.pathname + '?' + queryParams.toString()
        );
      }
    }

    if (
      (destinationFromLocalStorage && window.location.pathname !== '/') ||
      destinationFromQueryParams
    ) {
      const cityList = yield call(
        simpleSearchManager.getCityByCode,
        destinationFromQueryParams || destinationFromLocalStorage
      );
      if (cityList) {
        yield put(
          setDestination({
            to: cityList,
            withoutChangeQueryParams: true,
          })
        );
      }
    }
  } catch (err) {
    console.log(err);
    //
  }
}
// TODO пока заглушка, надо модуль переработать
function* setDestionationWorker({ payload }) {
  if (window.location.pathname !== '/' || payload.withoutChangeQueryParams)
    return;
  const { from, to } = yield select(
    (state) => state.aviaSearch.searchform
  );
  const origin = payload.from || from;
  const destination = payload.to || to;
  const url = new URL(window.location.href);
  const prevParams = url.search;

  if (origin && typeof origin === 'object') {
    if (origin.iataCode !== url.searchParams.get('origin')) {
      url.searchParams.set('origin', origin.iataCode);
    }
  } else {
    url.searchParams.delete('origin');
  }

  if (destination && typeof destination === 'object') {
    if (destination.iataCode !== url.searchParams.get('destination')) {
      url.searchParams.set('destination', destination.iataCode);
    }
  } else {
    url.searchParams.delete('destination');
  }

  if (url.search !== prevParams) {
    window.history.replaceState(
      { path: window.location.pathname + url.search },
      '',
      window.location.pathname + url.search
    );
  }
}

export function* searchFormUpdate(params) {
  yield spawn(function* () {
    const city = yield call(simpleSearchManager.getCityByCode, params.origin);
    yield put(
      setDestination({
        from: city,
        withoutChangeQueryParams: true,
      })
    );
  });

  yield spawn(function* () {
    const city = yield call(
      simpleSearchManager.getCityByCode,
      params.destination
    );
    yield put(
      setDestination({
        to: city,
        withoutChangeQueryParams: true,
      })
    );
  });

  yield put(
    setDate({
      from: moment({
        month: parseInt(params.forwardDate.substring(2, 4)) - 1,
        days: parseInt(params.forwardDate.substring(0, 2)),
      }),
      to: params.backwardDate
        ? moment({
          month: parseInt(params.backwardDate.substring(2, 4)) - 1,
          days: parseInt(params.backwardDate.substring(0, 2)),
        })
        : null,
    })
  );

  yield put(
    setPassengers({
      adults: params.adults,
      children: params.children,
      infants: params.infants,
    })
  );

  yield put(
    setTicketClass({
      id: params.flightClass,
      description: getFlightClassDesc(params.flightClass),
    })
  );
}

function getFlightClassDesc(str) {
  switch (str) {
    case 'economy':
      return 'эконом';
    case 'comfort':
      return 'комфорт';
    case 'business':
      return 'бизнес';
    case 'first':
      return 'первый класс';
    default:
      return 'эконом';
  }
}

function* updateFormSearchStateFlow({ payload }) {
  const params = parseSearchParams(payload);

  yield spawn(function* () {
    const city = yield call(simpleSearchManager.getCityByCode, params.origin);
    yield put(
      setDestination({
        from: city,
        withoutChangeQueryParams: true,
      })
    );
  });

  yield spawn(function* () {
    const city = yield call(
      simpleSearchManager.getCityByCode,
      params.destination
    );
    yield put(
      setDestination({
        to: city,
        withoutChangeQueryParams: true,
      })
    );
  });

  yield put(
    setDate({
      from: moment({
        month: parseInt(params.forwardDate.substring(2, 4)) - 1,
        days: parseInt(params.forwardDate.substring(0, 2)),
      }),
      to: params.backwardDate
        ? moment({
          month: parseInt(params.backwardDate.substring(2, 4)) - 1,
          days: parseInt(params.backwardDate.substring(0, 2)),
        })
        : null,
    })
  );

  yield put(
    setPassengers({
      adults: params.adults,
      children: params.children,
      infants: params.infants,
    })
  );

  yield put(
    setTicketClass({
      id: params.flightClass,
      description: getFlightClassDesc(params.flightClass),
    })
  );
}

export default function* searchFormFlow() {
  yield all([
    takeLatest(initializeSearchForm.getType(), inisializeWorker),
    takeLatest(setDestination.getType(), setDestionationWorker),
    takeLatest(updateSearchFormState.getType(), updateFormSearchStateFlow)
  ]);
}
