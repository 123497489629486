import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
	AppBar,
	Container,
	Slide,
	Toolbar,
	Tabs ,
	Tab,
	Box,
	useScrollTrigger
} from '@mui/material'

export default function HideOnScrollBar({  window, sections, handleClickScroll }) {
	const isMobile = useMediaQuery('(max-width:480px)')
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 200,
		target: window ? window() : undefined
	})

	return (
		<Slide appear={false} direction='down' in={trigger}>
			<AppBar sx={{ bgcolor: 'unset', padding: 0, boxShadow: 'none' }}>
					<Toolbar
						style={{ minHeight: 0, padding: 0 }}
					>
						<Container 
							style={{maxWidth :'1170px', padding: 0 }}>
							<Box
								sx={{ bgcolor: '#EFF2F6',  }}
								style={{ padding: !isMobile ? '0 64px' : '0 0px', borderRadius: !isMobile ? '0 0 16px 16px' : '0 0 8px 8px',  }}
								>
								<Tabs
									className='app-bar__tabs'
									variant="scrollable"
									scrollButtons
									allowScrollButtonsMobile
									aria-label="scrollable force tabs example"
									value={false}
								>
									{sections?.map(item => (
										<Tab 
											key={item.blockId}
											label={item.name}
											onClick={() => handleClickScroll(item.blockId)}
											className='app-bar__item'>
										</Tab>
									))}
								</Tabs>
							</Box>
						</Container>
					</Toolbar>
				</AppBar>
		</Slide>
	)
}
