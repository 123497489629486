import { Clusterer, Map, YMaps, ZoomControl } from '@pbe/react-yandex-maps'
import React, { useRef } from 'react'
import './style.css'
import CustomPlacemark from './components/CustomPlacemark'
import useCountryStore from '../../store'
import CustomButton from './components/CustomButton'
import useYandexMapStore from './store'
import CustomSearch from './components/CustomSearch'

export default function YandexMap() {
	const openCountries = useCountryStore(({ openCountries }) => openCountries)
	const allCountries = useCountryStore(({ allCountries }) => allCountries)
	const map = useRef(null)
	const params = useYandexMapStore(({ params }) => params)
	const openBtn = useYandexMapStore(({ openBtn }) => openBtn)

	function closeCurrentBalloon() {
		let close = document.querySelector('ymaps[class$="-balloon__close-button"]')
		if (close != null) {
			close.click()
		}
	}

	return (
		<div className='map-contain'>
			<YMaps
				query={{
					apikey: process.env.REACT_APP_YANDEX_MAP,
					lang: 'ru_RU'
				}}>
				<Map
					instanceRef={map}
					options={{
						yandexMapDisablePoiInteractivity: true
					}}
					state={params}
					defaultState={{
						center: [20, 20],
						zoom: 2,
						controls: []
					}}
					width='100vw'
					height={600}
					modules={[
						'geoObject.addon.balloon',
						'geoObject.addon.hint',
						'geolocation',
						'geocode',
						'templateLayoutFactory'
					]}
					onClick={closeCurrentBalloon}
					onMouseDown={closeCurrentBalloon}
					onTouchMove={closeCurrentBalloon}>
					<CustomButton />
					<ZoomControl options={{ size: 'small' }} />
					<CustomSearch />
					{openBtn && (
						<Clusterer
							options={{
								preset: 'islands#darkBlueClusterIcons'
							}}>
							{openCountries?.map((item, index) => {
								return (
									<CustomPlacemark
										key={index}
										item={item}
										map={map}
										closeCurrentBalloon={closeCurrentBalloon}
									/>
								)
							})}
						</Clusterer>
					)}
					{!openBtn && (
						<Clusterer
							options={{
								preset: 'islands#darkBlueClusterIcons'
							}}>
							{allCountries?.map((item, index) => {
								return (
									<CustomPlacemark
										key={index}
										item={item}
										map={map}
										closeCurrentBalloon={closeCurrentBalloon}
									/>
								)
							})}
						</Clusterer>
					)}
				</Map>
			</YMaps>
		</div>
	)
}
