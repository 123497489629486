import { Grid, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import useExcursionStore from '../../store'
import { isEmpty } from 'lodash'
import SearchResult from '../SearchResult'
import NotFindExcursion from './components/NotFindExcursion'
import ExursionFiltersBlock from './components/ExcursionFiltersBlock'
import MobileFiltersBlock from './components/MobileFiltersBlock'

export default function SearchResultsWithFilters({ result }) {
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	const checkedForWhom = useExcursionStore(
		({ checkedForWhom }) => checkedForWhom
	)
	const checkedExcursionType = useExcursionStore(
		({ checkedExcursionType }) => checkedExcursionType
	)
	const checkedActivity = useExcursionStore(
		({ checkedActivity }) => checkedActivity
	)
	const checkedServiceType = useExcursionStore(
		({ checkedServiceType }) => checkedServiceType
	)
	const filteredExcursions = useMemo(() => {
		if (
			isEmpty(checkedForWhom) &&
			isEmpty(checkedActivity) &&
			isEmpty(checkedExcursionType) &&
			(isEmpty(checkedServiceType) || checkedServiceType.length === 2)
		) {
			return result
		} else {
			return result?.filter(excursion => {
				const excursionActivity = excursion?.other_params?.activity?.map(
					item => item?.name
				)
				const excursionForWhom = excursion?.other_params?.forWhom?.map(
					item => item?.name
				)
				const excursionType = excursion?.other_params?.excursionType?.map(
					item => item?.name
				)
				const serviceType = excursion?.serviceType
				return (
					checkedActivity.every(item => excursionActivity?.includes(item)) &&
					checkedForWhom.every(item => excursionForWhom?.includes(item)) &&
					checkedExcursionType.every(item => excursionType?.includes(item)) &&
					(checkedServiceType.every(item => serviceType?.includes(item)) || checkedServiceType.length === 2)
				)
			})
		}
	}, [checkedActivity, checkedExcursionType, checkedForWhom, checkedServiceType, result])

	return (
		<Grid
			direction={isSmallScreen ? 'row' : 'column'}
			container
			sx={{ mt: 4, mb: 2 }}
			columnSpacing={'20px'}
			columns={26}>
			<Grid item xs={5}>
				{isSmallScreen && <ExursionFiltersBlock />}
			</Grid>
			<Grid item xs={21}>
				{isEmpty(filteredExcursions) ? (
					<NotFindExcursion />
				) : (
					<SearchResult lists={filteredExcursions} />
				)}
			</Grid>
		</Grid>
	)
}
