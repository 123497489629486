import './actionsCard.style.scss'
import { format } from 'date-fns';

function ActionsCard({ content, view }) {
    const time = !content.show_to ? 'Бессрочная' : format(new Date(content.show_from.replace(" ", "T") + "Z"), 'dd.MM') + " - " + format(new Date(content.show_to.replace(" ", "T") + "Z"), 'dd.MM')

    const Arrow = () => {
        return <svg data-v-13020d34="" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle data-v-13020d34="" cx="16" cy="16" r="16" fill="white" />
            <path data-v-13020d34="" fillRule="evenodd" clipRule="evenodd" d="M18.0163 9.77759L24 15.9998L18.0163 22.222L16.8421 21.0011L20.7956 16.8887H8V15.1109H20.7964L16.8421 10.9985L18.0163 9.77759Z" fill="#2E2E32" />
        </svg>
    }

    const handleLink = () => {
        window.open(window.location.origin + '/actions/' + content.link, '_blank')
    }

    if (view === 'table') {
        return <div onClick={handleLink}>
            <div className='action-card_table'>
                <h3 className='name'>{content.title}</h3>
                <div className='time'>
                    {time}
                    <div className='arrow-link_table'>
                        <Arrow />
                    </div>
                </div>

            </div>
            <div className='divider' />
        </div>
    }

    return <div className='action-card' onClick={handleLink}>
        <div className='action-head'>
            <p className='time'>{time}</p>
            <p className='title'>{content.title}</p>
        </div>
        <div className='action-img_container'>
            <img className='action-img' src={content.image_preview_banner} alt='' />
            <div className='arrow-link'>
                <Arrow />
            </div>
        </div>
    </div>
}

export default ActionsCard