import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Paper
} from '@mui/material'
import { format } from 'date-fns'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ru } from 'date-fns/locale'
import plug from '../../../../asset/images/plug.png'

const TextLink = styled.div`
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #4872f2;
`

const DateText = styled.div`
	font-family: Open Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: #7e8389;
`

export default memo(function NewsItem({ content }) {
	// console.log(content, 'itemcontent')
	return (
		<Paper
			sx={{ p: 2, borderRadius: '16px', maxHeight: 185, width: '100%' }}
			elevation={0}>
			<Card sx={{ display: 'flex' }} elevation={0}>
				{content?.link ? (
					<CardActionArea
						sx={{
							maxWidth: '239px',
							height: '153px',
							borderRadius: '8px'
						}}>
						<Link to={content?.link} target={'_blank'}>
							<CardMedia
								component='img'
								sx={{
									maxWidth: '239px',
									borderRadius: '8px',
									maxHeight: '153px'
								}}
								image={content?.image || plug}
								alt='news content'
							/>
						</Link>
					</CardActionArea>
				) : (
					<CardMedia
						component='img'
						sx={{
							maxWidth: '239px',
							borderRadius: '8px',
							maxHeight: '153px'
						}}
						image={content?.image || plug}
						alt='news content'
					/>
				)}

				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<CardContent sx={{ flex: '1 0 auto', pt: 0 }}>
						{content?.link ? (
							<Link to={content?.link} target={'_blank'}>
								<TextLink>{content?.title || ''}</TextLink>
							</Link>
						) : (
							<TextLink>{content?.title || ''}</TextLink>
						)}

						{content?.date && (
							<DateText>
								{format(new Date(content.date), 'd MMMM yyyy', { locale: ru })}
							</DateText>
						)}
					</CardContent>
				</Box>
			</Card>
		</Paper>
	)
})
