import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ArrowThink from "../../../../../asset/icons/Arrow-think";
import {Button} from "@material-ui/core";
import MessagesItem from "./MessagesItem";
import CreateMessageModal from "./CreateMessageModal";
import axios from "axios";
import useClaimsStore from "../../store";
import Refresh from "../../../../../asset/icons/refresh.svg";
import CircularProgress from "@mui/material/CircularProgress";
import IconWithPopover from "../../../../../components/AviaSearchResult/TicketList/IconWithPopover";
import {Alert, Stack} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {v4 as uuid} from "uuid";
import {isEmpty} from "lodash";
import {getAgentOrderBillRequest} from "../../../../../store/modules/orders"
import OutlinedButton from "../../../../../components/ui/OutlinedButton";

const Messages = ({onChange, details}) => {

  const actions = useClaimsStore(({ actions }) => actions)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [messages, setMessages] = useState([])
  const [isAwaitingAnswer, setAwaitingAnswer] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [notification, setNotification] = useState([])
  const [isOpenAlert, setOpenAlert] = useState(false)
  const dispatch = useDispatch()
  const isCreditLoading = useSelector(state => state.orders.orderDetail.creditLoading)

  useEffect(() => {
    if (!!details) {
      const fetchData = async () => {
        setMessages(await actions.getOrderMessages(details))
      }

      fetchData()
        .catch(console.error);
    }
  }, [details])

  useEffect(() => {
    if (messages[messages.length - 1]?.incoming) {
      setAwaitingAnswer(true)
    } else {
      setAwaitingAnswer(false)
    }
  }, [messages])

  useEffect(() => {
    if (!isEmpty(notification)) {
      setOpenAlert(true)
    }
  }, [notification])

  useEffect( () => {
    if (!isCreditLoading && !!details) {
      setLoading(true)
      setTimeout(async () => {
        onChange(await actions.getOrderByNumber(details.orderNumber))
        setLoading(false)
      }, 100)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditLoading]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleNewMessageClick = () => {
    setIsOpenModal(true)
    setNotification([])
  }

  const sendMessage = async (value, files = []) => {
    try {
      const token = JSON.parse(localStorage.getItem('tte_token'))?.accessToken
      const formData = new FormData()
      formData.append("Text", value.orderNumber + ' ' + value.amount)
      formData.append("OrderNumber", details.orderNumber)
      formData.append("Type", 19)
      files.forEach(x => {
        formData.append("Files", x)
      })

      await axios({
        url: process.env.REACT_APP_ADD_CUSTOMER_MESSAGES,
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })

      setIsOpenModal(false)
      setMessages(await actions.getOrderMessages(details))
      setNotification(cur => [...cur, {id: uuid(), type: 'success', description: 'Запрос успешно отправлен'}])
    } catch (e) {
      setNotification(cur => [...cur, {id: uuid(), type: 'error', description: 'Не получилось отправить запрос'}])
      setIsOpenModal(false)
    }
  }

  const handleClose = () => {
    setIsOpenModal(false)
  }

  const handleRefresh = async () => {
    setLoading(true)
    setMessages(await actions.getOrderMessages(details))
    setTimeout(() => setLoading(false), 500)
  }

  const handleCreateInvoice = async () => {
    dispatch(getAgentOrderBillRequest({ orderId: details.id }))
  }

  return (
    <>
      <div className={'avia-order-block__item'}>
        <div className={'header'}>Запросы на перенос денег</div>
        <div className={`avia-order-icon ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
          { !!messages ? <ArrowThink/> : false}
        </div>
      </div>

      {isOpen && <div className={'messages'} style={{padding: '0 24px 15px'}}>
        {!details?.backOfficeId && <div style={{marginBottom: '20px'}}>
          Для отправки запроса на перенос денег сначала нужно сформировать счет или оплатить заявку в кредит.
        </div>}
        <div className={'messages-block'}>
          {<Button className={'create'} variant={'containedPrimary'} color={'primary'}
                   onClick={handleNewMessageClick}
          disabled={!details?.backOfficeId}>
            Отправить новый запрос
          </Button>}

          {!details?.backOfficeId && <OutlinedButton style={{marginLeft: 20}}
            startIcon={false}
            loading={isCreditLoading || isLoading}
            spinnerColor={'#4872F2'}
            onClick={handleCreateInvoice}
          >
            Сформировать счет
          </OutlinedButton>}

          {isAwaitingAnswer &&
            <>
              {isLoading
                ? <CircularProgress sx={{marginLeft: '12px'}} size={18}/>
                : <div style={{marginLeft: '12px'}}>
                    <IconWithPopover tip={'Обновить'}>
                      <img className={'refresh'} src={Refresh} onClick={handleRefresh} alt=""/>
                    </IconWithPopover>
                  </div>}
            </>
          }
        </div>
        {isAwaitingAnswer && <p style={{color: 'red'}}>Ваш запрос отправлен. Ожидайте ответа</p>}

        {isOpenModal && <CreateMessageModal sendMessage={sendMessage} onClose={handleClose}/>}

        {messages.map(el => <MessagesItem key={el.Key} message={el}/>)}

      </div>}

      <Snackbar
        open={isOpenAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Stack flexDirection='column' gap={1}>
          {notification.map(item => {
            return (
              <Alert
                key={item.id}
                onClose={handleAlertClose}
                severity={item.type}
                sx={{ width: '300px' }}>
                {item.description}
              </Alert>
            )
          })}
        </Stack>
      </Snackbar>
    </>
  );
};

export default Messages;
