import React from "react"
import styled from "styled-components"
import moment from "moment"
import {ReactComponent as OperatorIcon} from "./assets/media/operator.svg"
import Typography from "../Typography"
import { Manager } from "../../../store/modules/appeals"

const MainWrapper = styled.div`
  display: flex;
  ${({ owner }) => (owner ? "justify-content: flex-end;" : "")}
  @media (max-width: 767px) {
    justify-content: flex-end;
  }
`

const Name = styled(Typography)`
  align-self: flex-start;
  display: block;
  margin-bottom: 12px;
  margin-left: 50px;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  font-family: 'Open Sans';
  color: #737373;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`

const Text = styled.div`
  padding: 12px 20px;
  background: ${({...props }) =>
    props.owner ? "rgb(255,201,62,0.18)" : "#edf1fe"};
  border-radius: 8px;
  white-space: pre-line;
  border: none;
  text-align: left;
  flex: 1;
  @media (max-width: 767px) {
    padding: 9px 10px;
  }
`

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding-left: ${({ owner }) => (owner ? "min(30px,5%)" : "0")};
`

const Date = styled.span`
  margin-top: 12px;
  color: #DCDCDC;
  align-self: flex-end;
  @media (max-width: 767px) {
    margin-top: 1px;
    font-size: 13px;
    align-self: flex-start;
  }
`

const OperatorIconWrapper = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #4872F2;
`

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  & > div {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`

const File = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: #4872F2;
  cursor: pointer;
  padding: 0;
`

const Message = ({ text, owner, date, icon, author, files }) => {
  const stillUtc = moment.utc(date).toDate()
  const formatedDate = moment(stillUtc)
    .local()
    .format("HH:mm")

  return (
    <MainWrapper owner={owner}>
      <Container owner={owner}>
        {!owner && <Name color="#B9B9B9">{`Оператор ${author.name}`}</Name>}
        <Wrapper owner={owner}>
          {!owner && (
            <OperatorIconWrapper>
              <OperatorIcon />
            </OperatorIconWrapper>
          )}
          <Text owner={owner}>
            <span>{text}</span>
            {files.length > 0 && (
              <Files>
                {files.map(x => (
                  <File
                    type="button"
                    onClick={() => {
                      Manager.getFile(x.fieldId, x?.name)
                    }}
                    key={x.fieldId}
                  >
                    {x.name}
                  </File>
                ))}
              </Files>
            )}
          </Text>
        </Wrapper>
        <Date>{formatedDate}</Date>
      </Container>
    </MainWrapper>
  )
}

export default Message
