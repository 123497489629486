import { useEffect } from "react"
import useVisaStore from "./store"
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumbs } from '@mui/material'
import Typography from '@mui/material/Typography'
import CountryBlock from "./components/CountryBlock"
import { isEmpty } from 'lodash';
import VisaMain from "./components/VisaMain"
import FUN from "../../asset/favicon.ico";

function Visa({ isMainPage }) {
    const location = useLocation()
    const actions = useVisaStore(({ actions }) => actions)
    const content = useVisaStore(({ content }) => content)
    const link = location.pathname.substring().substring(location.pathname.lastIndexOf('/') + 1)

    useEffect(() => {
        if (isMainPage) {
            actions.getMainPage()
            window.scrollTo(0, 0);
        } else {
            actions.getPage(link)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMainPage])

    if (isEmpty(content)) return null

    const showCalculator = content?.calcExp?.includes(link)

    return <div className="landing landing-container">
        <Helmet
            title={content?.title || "Визы"}
            link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
        />
        <Breadcrumbs aria-label='breadcrumb' className='breadcrumb' style={{ marginBottom: '32px' }}>
            <Link color='inherit' to={'/'}>
                Главная
            </Link>
            <Link to={'/countries'}>
                <Typography style={{ color: '#4872f2' }}>
                    Страны
                </Typography>
            </Link>
            {content?.title
                ?
                <Link to={'/countries/visa'}>
                    <Typography style={{ color: '#4872f2' }}>
                        Визы
                    </Typography>
                </Link>
                :
                <Typography style={{ color: '#4872f2' }}>
                    Визы
                </Typography>
            }
            {content?.title && <Typography style={{ color: '#4872f2' }} >
                {content?.title}
            </Typography>}

        </Breadcrumbs>
        <h1 style={{ fontSize: '36px', fontWeight: "600" }}>{content?.title ?? 'Визы'}</h1>
        {!isMainPage && content.tabs && <CountryBlock content={content.tabs} available={content?.calcExp} showCalculator={showCalculator} ></CountryBlock>}
        {isMainPage && content?.available && <VisaMain content={content} />}
    </div>
}

export default Visa