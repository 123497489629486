import React from 'react'
import './style.css'

export default function StaticFrame({ content, title }) {

	return (
		<div>
			{title && <h3 className='v-center'>{title}</h3>}
			<div
				className='v-frame '
				dangerouslySetInnerHTML={{
					__html: content?.text
				}}></div>
		</div>
	)
}
