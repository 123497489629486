import React from 'react'
import styled from 'styled-components'
import FranchContacts from './FranchContacts'
import SalesRatings from './SalesRatings'
import useFranchisingStore from './store'
import News from './News'
import Comission from './Comission'
import TilesBlock from './TilesBlock'

const Title = styled.div`
	font-family: Open Sans;
	font-weight: 600;
	font-size: 28px;
	font-style: normal;
	line-height: 38px;
	color: #2e2e32;
`

const Container = styled.div`
	width: 900px;
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const renderBlocks = arr => {
	if (!arr) return

	return arr
		.sort((a, b) => a.order - b.order)
		.map(item => {
			switch (item.block) {
				case 'curators':
					return <FranchContacts />
				case 'commissions':
					return <Comission />
				case 'news':
					return <News />
				case 'rating':
					return <SalesRatings />
				case 'tiles':
					return <TilesBlock content={item} />
				default:
					return null
			}
		})
}
export default function Franchising() {
	const franchisingData = useFranchisingStore(state => state.franchising)
	const blocks = React.useMemo(
		() => renderBlocks(franchisingData),
		[franchisingData]
	)

	return (
		<Container>
			<Title>Франчайзинг</Title>
			{blocks}
		</Container>
	)
}
