import React, {useEffect} from 'react';
import { Outlet, useLocation } from "react-router-dom";
import {matchRoutes} from "react-router";
import { a11yProps, MenuTabCustom, MenuTabs } from "../pages/NewCabinet/AgentInfo/components/MenuTabs/MenuTabs";
// import MenuIcon from "../pages/NewCabinet/AgentInfo/components/icons/IconSVG";
import PersonIcon from "../asset/images/Person.svg";
import PencilSquareIcon from "../asset/images/PencilSquare.svg";
import AirplaneIcon from "../asset/images/airplane.svg";
import TagIcon from "../asset/images/Tag.svg";
import BriefcaseIcon from "../asset/images/Briefcase.svg";
import { isEmpty } from "lodash";
import TwoPersonIcon from "../asset/images/twoPerson.svg";
import BarChartIcon from "../asset/images/cabinet/bar-chart-2.svg";
import InboxIcon from "../asset/images/Inbox.svg";
import CuratorsIcon from "../asset/images/curatorContacts.svg";
import StarIcon from "../asset/images/cabinet/star.svg";
import Home from '../asset/images/cabinet/home.svg'
import EducationIcon from '../asset/images/education.svg'
import MessagesTab from "../pages/NewCabinet/Messages/MessagesTab";
// import Agency from "../pages/NewCabinet/AgentInfo/Agency";
// import Claims from "../pages/NewCabinet/Claims";
// import Bonuses from "../pages/NewCabinet/Bonuses";
// import Contracts from "../pages/NewCabinet/Contracts";
// import Franchising from "../pages/NewCabinet/Franchising";
// import Reports from "../pages/NewCabinet/Reports";
// import Comission from "../pages/NewCabinet/Comission";
// import CuratorContacts from "../pages/NewCabinet/CuratorContacts";
// import FitClaims from "../pages/NewCabinet/FitClaims";
// import Plans from "../pages/NewCabinet/Plans";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import useAuthStore from "../pages/Auth/store";
import useFranchisingStore from "../pages/NewCabinet/Franchising/store";
import { AviaFinancialBalanceBlock, TabLink } from "../components/Cabinet";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus, getPassanagersRequest, updateUserStatusToken } from "../store/modules/user";
import { getAgentsBalance } from "../store/modules/agents/agent-finance/duck";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SamoLink from "../components/Cabinet/SamoLink";
import {claims} from "../components/NewNav/samoMenu";

const theme = createTheme({
	palette: {
		primary: {
			main: '#4872f2',
			light: '#deebff',
			dark: '#0052cc'
		},
		secondary: {
			main: '#ffe100',
			dark: '#ffcd00',
		},
		gray: {
			light: '#D9D9D9',
			main: '#3c3c3c',
			// dark: '#ffcd00',
		},
		lightBlue: {
			main: '#EDF1FE',
			contrastText: '#4872F2',
			// light: '#deebff',
			dark: '#d7dffa'
		}
	}
})

const Content = styled.div`
	flex-grow: 1;
	padding: 24px;
  width: 100%;
`;

const Alert = styled.div`
	background-color: #FFFBB9;
	width: 1170px;
    margin: auto auto 16px;
	border-radius: 12px;
	
	text-align: center;
	padding: 14px 28px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #2e2e32;
	a{
		color: #4872F2;
	}

`

const NewCabinetTemplate = () => {
	const [value, setValue] = React.useState(0)
	const [isOpenMenu, setIsOpenMenu] = React.useState({ claims: false, tte: false })
	const [showTTEFinance, setShowTTEFinance] = React.useState(false)
	const actions = useAuthStore(({ actions }) => actions)
	const agent = useAuthStore(({ agent }) => agent)
	const actionsFranchising = useFranchisingStore(({ actions }) => actions)
	const franchising = useFranchisingStore(({ franchising }) => franchising)
	const dispatch = useDispatch();
	const TTEToken = useAuthStore(({ tokenTTE }) => tokenTTE)
	const location = useLocation()
	const isOwnRetail = useSelector(x => x.user.data?.salesChannel) === 'Собственная розница'

	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)

	// const handleChange = (event, newValue) => {
	// 	setValue(newValue)
	// }

	React.useEffect(() => {
		if (!!TTEToken) {
			checkAuthStatus()
			dispatch(updateUserStatusToken())
			dispatch(getAgentsBalance())
			dispatch(getPassanagersRequest())
		}
		// eslint-disable-next-line
	}, [TTEToken])

	React.useEffect(() => {
		const isMatch = !!matchRoutes([
			{path: '/cabinet/orders'},
			{path: '/cabinet/orders/:id'},
			{path: '/cabinet/avia-agent-info'},
			{path: '/cabinet/my-passengers'},
			{path: '/cabinet/avia-sale-request}'},
		], location)
		setShowTTEFinance(isMatch && !isOwnRetail)
	}, [location, isOwnRetail])

	React.useEffect(() => {
		actions.getCurrencies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (agent && (agent.agents?.name?.includes('F&S-') || agent.agents?.name?.includes('F&S -'))) {
			actionsFranchising.getFranchising()
		}
	}, [agent, actionsFranchising])

	const openMenu = e => {
		e.preventDefault()
		setIsOpenMenu(cur => {
			const type = e.target.getAttribute('value')
			return { ...cur, [type]: !cur[type] }
		})
	}

	const refreshLink = (e) => {
		e.preventDefault()
		actions.refreshSamoLink('cl_refer', 'https://b2b.fstravel.com/cl_refer').then((url) => {
			window.open(url, '_blank', 'noreferrer')
		})
	}

	return (
		<ThemeProvider theme={theme}>
			<Alert>
				<div>Уважаемые агенты!</div>
				<div>Вы находитесь в тестовой версии нового личного кабинета. Некоторые функции еще находятся в разработке, но скоро будут внедрены: добавление доп.услуг (страховок, трансферов, экскурсий, дополнительного багажа); выбор мест на рейсе; изменение рейса; добавление виз; редактирование данных по электронной путевке; создание предварительных заявок. Данным функционалом можно воспользоваться в <a
					href="https://b2b.fstravel.com/cl_refer" onClick={refreshLink}>старом личном кабинете</a></div>
				<p>Сообщить о технической проблеме на сайте вы можете на почту <a href='mailto: newb2breport@fstravel.com?subject=Ошибка работы сайта newb2b.fstravel.com'>newb2breport@fstravel.com</a></p>
			</Alert>
			<Box
				sx={{
					flexGrow: 1,
					bgcolor: 'background.paper',
					display: 'flex',
					justifyContent: 'start',
					width: '1170px',
					margin: 'auto',
					marginBottom: '30px'
				}}>

				<MenuTabs
					orientation='vertical'
					value={value}
					// onChange={handleChange}
					aria-label='Vertical tabs example'
				>
					<MenuTabCustom
						icon={PersonIcon}
						label='Мои данные'
						to={'/cabinet/my-details'}
						value={'my-details'}
						{...a11yProps('/cabinet/my-details')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={PencilSquareIcon}
						label='Заявки'
						value={'claims'}
						to={'/cabinett/'}
						{...a11yProps('/cabinett/')}
						component={TabLink}
						onClick={openMenu}
					/>
					<MenuTabCustom
						label='Заявки по турам'
						to={'/cabinet/claims'}
						value={'claims'}
						{...a11yProps('/cabinet/claims')}
						styles={{ marginLeft: "10px" }}
						component={TabLink}
						className={!isOpenMenu['claims'] ? 'hidden' : ''}
					/>
					<MenuTabCustom
						label='Заявки ТТЕ'
						to={'/cabinet/orders'}
						value={'orders'}
						{...a11yProps('/cabinet/orders')}
						styles={{ marginLeft: "10px" }}
						component={TabLink}
						className={!isOpenMenu['claims'] ? 'hidden' : ''}
					/>
					<MenuTabCustom
						icon={AirplaneIcon}
						label="Транспортный <br/> движок (ТТЕ)"
						value={'tte'}
						to={'/tte/'}
						{...a11yProps('/tte/')}
						component={TabLink}
						onClick={openMenu}
					/>
					{!isOwnRetail && <MenuTabCustom
						label="Баланс, сборы и <br/> отчет ТТЕ"
						value="avia-agent-info"
						to="/cabinet/avia-agent-info"
						{...a11yProps("/cabinet/avia-agent-info/finances")}
						styles={{ marginLeft: "10px" }}
						component={TabLink}
						className={!isOpenMenu['tte'] ? 'hidden' : ''}
					/>}
					<MenuTabCustom
						label='Мои пассажиры ТТЕ'
						value="my-passengers"
						to="/cabinet/my-passengers"
						{...a11yProps("/cabinet/my-passengers")}
						styles={{ marginLeft: "10px" }}
						component={TabLink}
						className={!isOpenMenu['tte'] ? 'hidden' : ''}
					/>
					<MenuTabCustom
						label="Запросы на <br/> авторизацию ТТЕ"
						value="avia-sale-request"
						to="/cabinet/avia-sale-request"
						{...a11yProps("/cabinet/avia-sale-request")}
						styles={{ marginLeft: "10px" }}
						component={TabLink}
						className={!isOpenMenu['tte'] ? 'hidden' : ''}
					/>
					<MenuTabCustom
						icon={TagIcon}
						label='Бонусы'
						to={'/cabinet/bonus'}
						value={'bonus'}
						{...a11yProps('/cabinet/bonus')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={BriefcaseIcon}
						label='Договоры'
						to={'/cabinet/agreement'}
						value={'agreement'}
						{...a11yProps('/cabinet/agreement')}
						component={TabLink}
					/>
					{(agent && (agent.agents?.name?.includes('F&S-') || agent.agents?.name?.includes('F&S -'))) && !isEmpty(franchising) && <MenuTabCustom
						icon={TwoPersonIcon}
						label='Франчайзинг'
						to={'/cabinet/franchising'}
						value={'franchising'}
						{...a11yProps('/cabinet/franchising')}
						component={TabLink}
					/>}
					<MenuTabCustom
						icon={BarChartIcon}
						label='Отчеты'
						to={'/cabinet/reports'}
						value={'reports'}
						{...a11yProps('/cabinet/reports')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={InboxIcon}
						label='Комиссия'
						to={'/cabinet/commission'}
						value={'commission'}
						{...a11yProps('/cabinet/commission')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={CuratorsIcon}
						label='Контакты кураторов'
						to={'/cabinet/curator-contacts'}
						value={'curator-contacts'}
						{...a11yProps('/cabinet/curator-contacts')}
						component={TabLink}
					/>
					<MenuTabCustom
					icon={PencilSquareIcon}
					label='Заявки FIT'
					to={'/cabinet/fit'}
					value={'fit'}
					{...a11yProps('/cabinet/fit')}
					component={TabLink}
					/> 
					<MenuTabCustom
						icon={StarIcon}
						label='Мои планы'
						to={'/cabinet/my-plans'}
						value={'my-plans'}
						{...a11yProps('/cabinet/my-plans')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={EducationIcon}
						label='Обучение'
						to={'/cabinet/education'}
						value={'education'}
						{...a11yProps('/cabinet/education')}
						component={TabLink}
					/>
					<MenuTabCustom
						icon={Home}
						label='Вернуться на <br/> старую версию'
						target={'_blank'}
						value={'old-cabinet'}
						{...a11yProps('/old-cabinet')}
						component={SamoLink}
					/>
					<MessagesTab />
					{showTTEFinance && <AviaFinancialBalanceBlock />}
				</MenuTabs>
				<Content>
					<Outlet />
				</Content>
			</Box>
		</ThemeProvider>
	);
};

export default NewCabinetTemplate;
