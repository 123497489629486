import styled from "styled-components";
import React, {forwardRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  changePaymentMethod,
  getAgentOrderBillRequest,
  getDetailOrder,
  getQrRequest,
  issueTicketsRequest,
  payOrderRequest,
  setLoadingStatusCredit,
} from "../../store/modules/orders";
import formatPrice from "../../functions/formatPrice";
import {styled as styledMUI} from "@mui/material/styles";
import PrimaryButton from "../ui/PrimaryButton";
import ErrorModal from "../ui/ErrorModal";
import QrCodePayModal from "../modals/QrCodePayModal";
import AgentTaxesBlock from './AgentTaxesBlock'
import {createAcquiringCommissionsData} from "../../store/modules/orders/utils";
import {getUserDataState} from "../../store/modules/user";
import {ReactComponent as ClockIcon} from "../../asset/images/avia/order-detail/clock.svg"
import {ReactComponent as OkIcon} from "../../asset/images/avia/order-detail/okIcon.svg"
import {getAgentBalance} from "../../store/modules/agents/agent-finance/selectors";

const Wrapper = styled.div`
  border: 1px solid rgb(220, 220, 220);
  border-radius: 10px;
  padding: 24px 14px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 24px;
    margin-right: 10px;
  }
`
const RowTitle = styled.div`
  width: 190px;
`
const Price = styled.div`
  margin-left: 6px;
  font-weight: 600;
  color: #4872f2;
`
const Fee = styled.div`
  color: #737373;
`

const Title = styled.div`
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`

const PaymentWrapper = styled.div`
`
const Row = styled.div`
  padding: 12px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 8px;
    &:hover {
      cursor: pointer;
    }
    &.selected {
      background-color: #EDF1FE;
      border-color: #4872f2;
    }
`
const ButtonsWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`
const PaymentButton = styledMUI(PrimaryButton)(()=>({
  marginLeft: '10px',
  fontWeight: '600',
}));
const IssueButton = styledMUI(PrimaryButton)(()=>({
  fontWeight: '600',
  marginLeft: '20px',
}));

const TaxesWrapper = styled.div`
  margin-top: 30px;
  border-top: 1px solid #DCDCDC;
  padding-top: 20px;
`

const PaymentBlockAdvanced = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [isOpenErrorModal, setIsOpenErrorModal] = React.useState(false)
  const [isSbpModalOpen, setSbpModalOpen] = React.useState(false)
  const [qrCodeIsLoading, setQrCodeIsLoading] = React.useState(false)
  const {
    availableActions,
    amount,
    amountExtraPayment,
    acquiringCommissions,
    id,
    orderNumber,
    salesChannel,
    availableShipmentAllowed,
    orderPositions,
  } = useSelector(getDetailOrder)
  const user = useSelector(getUserDataState)
  const balance = useSelector(getAgentBalance)
  const commissions = createAcquiringCommissionsData(acquiringCommissions)
  const paymentMethod = useSelector(x => x.orders.orderDetail.paymentMethod)
  const extraPayment = useSelector(x => x.orders.orderDetail.activeCalulations.totalAmount) > 0
  const paymentTinkoffQrNumber = useSelector(x => x.orders.orderDetail?.paymentTinkoffQrNumber)
  const paymentQr = useSelector(x => x.orders.orderDetail?.paymentQr?.svg);
  const isBasicPayment = (availableActions || []).includes("PAYMENT") && !extraPayment
  const isAvailableIssue = isBasicPayment && salesChannel !== "Собственная розница" && availableShipmentAllowed?.availableIssue && user?.agentId;
  const paymentAmount = amountExtraPayment > 0 ? amountExtraPayment : amount
  const orderPositionIds = orderPositions.map((x) => x.id);

  React.useEffect(() => {
    if (paymentQr) {
      setQrCodeIsLoading(false);
    }
  }, [paymentQr]);

  React.useEffect(() => {
    const socket = new WebSocket(
      `ws://${process.env.REACT_APP_SOCKET_ORDER_PAYMENT_UPDATE}?number=${paymentTinkoffQrNumber}`
    );
    socket.onmessage = function(event) {
      if (JSON.parse(event.data).IsProcessing === true) {
        socket.close()
        window.location.reload()
      }
    };

    return () => {
      socket.close()
    }
  }, [paymentTinkoffQrNumber])

  const handlePay = () => {
    switch (paymentMethod) {
      case 'Credit': {
        dispatch(issueTicketsRequest({
            orderId: id,
            typePaid: "credit",
            userId: user?.agentId,
          })
        )
        break
      }
      case "Card": {
        dispatch(setLoadingStatusCredit(true))
        dispatch(payOrderRequest({OrderNumber: orderNumber,}))
        break
      }
      case "Bill": {
        dispatch(setLoadingStatusCredit(true))
        dispatch(getAgentOrderBillRequest({ orderId: id }))
        break
      }
      case "Sbp": {
        if (!paymentQr) {
          setQrCodeIsLoading(true);
          dispatch(getQrRequest({
              orderNumber: orderNumber,
              orderPositionIds: orderPositionIds,
              tinkoffSettingType: 1,
            })
          );
        }
        setSbpModalOpen(true)
        break
      }
      default: {
        setIsOpenErrorModal(true)
        break
      }
    }
  }

  return (
    <Wrapper ref={ref}>
      <Title>Оплата заявки</Title>
      <PaymentWrapper>
        {commissions?.map((x) => {
          return (
            <Row key={x.id}
              onClick={() => dispatch(changePaymentMethod(x.paymentType))}
              className={paymentMethod === x.paymentType ? 'selected' : ''}
            >
              <TitleWrapper>
                <img src={x.iconUrl} alt=""/>
                <RowTitle>{x.title}:</RowTitle>
                <Price>{x.amount ? formatPrice(x.amount) : formatPrice(paymentAmount)}</Price>
              </TitleWrapper>
              {!!x.acquiringAmount && <Fee>комиссия за оплату {formatPrice(x.acquiringAmount)}</Fee>}
              {x.paymentType === 'Credit' && <Fee>остаток по лимиту {formatPrice(balance.creditLimitRest)}</Fee>}
            </Row>
          )
        })}
      </PaymentWrapper>

      <ButtonsWrapper>
        {(availableActions.includes("PAYMENT") || availableActions.includes("EXTRAPAYMENT")) && (
          <PaymentButton
            color='secondary'
            onClick={handlePay}>
            {paymentMethod === 'Bill' ? 'Сформировать счет' : 'Оплатить заявку'}
          </PaymentButton>
        )}
        {isAvailableIssue && ['Credit', 'Bill'].includes(paymentMethod) && (
          <IssueButton
            color="secondary"
            startIcon={availableShipmentAllowed?.ticketIssue ? <OkIcon/> : <ClockIcon width={20} height={20}/>}
            onClick={() => dispatch(issueTicketsRequest({
              orderId: id,
              typePaid: 'bill',
              userId: user?.agentId
            }))}
            disabled={!availableShipmentAllowed?.ticketIssue}
          >
            Выписать заявку
          </IssueButton>
        )}
      </ButtonsWrapper>

      <TaxesWrapper>
        <AgentTaxesBlock />
      </TaxesWrapper>

      <QrCodePayModal
        open={isSbpModalOpen}
        onClose={() => setSbpModalOpen(false)}
        setIsOpenModal={setSbpModalOpen}
        qrCodeIsLoading={qrCodeIsLoading}
        qrCode={paymentQr}
        amount={commissions.find(x => x.paymentType === 'Sbp')?.amount}
      />

      <ErrorModal
        open={isOpenErrorModal}
        onClose={() => setIsOpenErrorModal(false)}
        setIsOpenModal={setIsOpenErrorModal}
      />
    </Wrapper>
  )
})

export default PaymentBlockAdvanced
