import useHotelDescriptionStore from '../../store'
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import { useMediaQuery } from 'react-responsive'

import './style.scss'

const FSHotelId = '5a7a26f4-3607-4339-b105-186138e2fed4';
const formatHotelId = '550b7914-b6e6-4f16-8ae8-9819f880ab1c'

function HotelTags() {
    const blocks = useHotelDescriptionStore(({ blocks }) => blocks)
    const FSHotel = useHotelDescriptionStore(({ FSHotel }) => FSHotel)
    const formatHotel = useHotelDescriptionStore(({ formatHotel }) => formatHotel)
    const recreationTypes = useHotelDescriptionStore(({ recreationTypes }) => recreationTypes)
    const momentConfirm = useHotelDescriptionStore(({ momentConfirm }) => momentConfirm)
    const isWidthLess576 = useMediaQuery({maxWidth: 576})

    const tags = [FSHotel, formatHotel, recreationTypes]
    return <div className='hotel_tag-block'>
        {!isWidthLess576 && momentConfirm && <div style={{ background: 'rgb(237, 241, 254)' }} className='hotel_tag-item'>Мгновенное подтверждение</div>}
        {!isWidthLess576 && tags.map(item => {
            if (!item?.filters?.length) return null
            const existBlock = blocks?.find(i => i.id === item?.descriptionBlockTypeId)
            if (!existBlock) return null

            const tagColor = item?.descriptionBlockTypeId === FSHotelId ? 'rgb(237, 241, 254)' : item?.descriptionBlockTypeId === formatHotelId ? 'rgb(237, 250, 249)' : 'rgb(239, 242, 246)'
            return item.filters.map(filter => {
                const existTag = existBlock?.filters.find(i => i.id === filter)
                return <div key={filter} style={{ background: tagColor }} className='hotel_tag-item'>{existTag?.name} </div>
            })
        })}
        {isWidthLess576 && <SliderIcons tags={tags} blocks={blocks} momentConfirm={momentConfirm} />}
    </div>
}

export default HotelTags

const SliderIcons = ({ tags, blocks, momentConfirm=false }) => {

	return (
		<div className='SliderHotelTag_swiper-container'>
			<Swiper
				spaceBetween={30}
				slidesPerView={'auto'}
				mousewheel={false}
				watchSlidesProgress={true}
				autoplay={false}
				loop={false}
				modules={[Autoplay, FreeMode, Navigation, Mousewheel, Pagination]}
				navigation={{
					nextEl: '.SliderIcons_swiper-button-next.static-banner',
					prevEl: '.SliderIcons_swiper-button-prev.static-banner',
				}}
				className='SliderHotelTag_swiper_slider'
				watchOverflow={true}>
                    
                 {momentConfirm && (
                    <SwiperSlide className='SliderHotelTag_swiper-slide'>
                        <div style={{ background: 'rgb(237, 241, 254)' }} className='hotel_tag-item'>Мгновенное подтверждение</div>
                    </SwiperSlide>
                 )}
                 
                 {tags.map(item => {
                    if (!item?.filters?.length) return null
                    const existBlock = blocks?.find(i => i.id === item?.descriptionBlockTypeId)
                    if (!existBlock) return null

                    const tagColor = item?.descriptionBlockTypeId === FSHotelId ? 'rgb(237, 241, 254)' : item?.descriptionBlockTypeId === formatHotelId ? 'rgb(237, 250, 249)' : 'rgb(239, 242, 246)'
                    return item.filters.map(filter => {
                        const existTag = existBlock?.filters.find(i => i.id === filter)
                        return (
                            <SwiperSlide className='SliderHotelTag_swiper-slide' key={filter}>
                                <div  style={{ background: tagColor }} className='hotel_tag-item'>{existTag?.name}</div>
                            </SwiperSlide>
                        )     
                    })
                })}
			</Swiper>
		</div>
	)
}