import sha1 from "js-sha1";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_KIWI_API;

const apiTransformResponse = (data) => {
	if (!data || typeof data !== 'string') {
		return data;
	}

	return JSON.parse(data);
};

export const transferApiInstance = (configTransfer = {}) => {
	const token = process.env.REACT_APP_KIWI;
	const securityCode = process.env.REACT_APP_KIWI_SECURITY_CODE
	const time = Date.now()
	const hash = sha1(token + securityCode + time)

	const object = {
		token: token,
		microtime: time,
		hash: hash
	}

	const pagerOptions = {
		page: configTransfer.page || 1,
		maxPerPage: configTransfer.maxPerPage || 500
	}

	const searchParams = {
		pagerOptions: pagerOptions,
		...configTransfer.searchParams
	}

	configTransfer = {
		baseURL: configTransfer.baseURL || BASE_URL,
		params: {
			requestParams: JSON.stringify(searchParams),
			securitySign: JSON.stringify(object)
		},
		transformResponse: apiTransformResponse,
		...configTransfer,
	};

	const apiInstanceTransfer = axios.create(configTransfer);

	apiInstanceTransfer.interceptors.response.use((res) => {
		return res;
	}, (err) => {
		if ([401, 403].includes(err?.response?.status)) {
			console.error('401 or 403 error happened');
		}
		return err;
	});

	return apiInstanceTransfer;
};