import React from 'react';
import styled from 'styled-components';
import {  AddictionalServiceTypes } from '../../store/modules/booking';
import { ReactComponent as PrintIcon } from '../../asset/images/avia/addictional-services/print-service.svg';
import { ReactComponent as ReturnIcon } from '../../asset/images/avia/addictional-services/return.svg';
import { ReactComponent as Cellphone } from '../../asset/images/avia/addictional-services/cellphone.svg';
import formatPrice from '../../functions/formatPrice';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../asset/images/avia/ui/close.svg'


import { Text } from '../ui';
import {
  RETURN_MODAL_DESC,
  SMS_MODAL_DESC,
  PRINT_MODAL_DESC,
} from './constants';

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    // padding: 40px;
    border-radius: 8px;
    padding: 0;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      // padding: 18px 20px;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      & > :last-child {
        flex-grow: 1;
      }
    }
  }
`;

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    max-width: 280px;
    align-self: center;
  }
`;

const Description = styled.div`
  strong {
    font-weight: 600;
  }

  margin: 0 20px 30px 30px;
  max-height: 50vh;
  padding-right: 10px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: #F0F0F0;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }

  @media (max-width: 767px) {
    padding-right: 0;
  }
`;

const PopupHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 30px;
  padding-bottom: 0;

  & > :first-child {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    text-align: center;
    padding: 48px;
    padding-bottom: 0;

    & > a {
      position: absolute;
      top: 18px;
      right: 26px;
    }
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;


const getServiceContent = (type) => {
  switch (type) {
    case AddictionalServiceTypes.Payment_Receipt:
      return PRINT_MODAL_DESC;
    case AddictionalServiceTypes.Return_Guarantee:
      return RETURN_MODAL_DESC;
    case AddictionalServiceTypes.SMS_Service:
      return SMS_MODAL_DESC;
    default:
      return null;
  }
};

const getServiceIcon = (type) => {
  switch (type) {
    case AddictionalServiceTypes.Payment_Receipt:
      return <PrintIcon/>;
    case AddictionalServiceTypes.Return_Guarantee:
      return <ReturnIcon/>;
    case AddictionalServiceTypes.SMS_Service:
      return <Cellphone/>;
    default:
      return null;
  }
}

const getServiceName = (type) => {
  switch (type) {
    case AddictionalServiceTypes.Payment_Receipt:
      return 'Справка о стоимости заявки';
    case AddictionalServiceTypes.Return_Guarantee:
      return 'Гарантия возврата';
    case AddictionalServiceTypes.SMS_Service:
      return 'SMS-информирование';
    default:
      return null;
  }
}

const Modal = ({
  children,
  type,
  setChosenModal,
  ...props
}) => {

  return (
    <StyledPopup {...props}>
      <PopupHead>
        {getServiceIcon(type)}
        <Title>
          <span>{getServiceName(type)}</span>
          <span style={{color: '#4872F2'}}>{formatPrice(props.price)}</span>
        </Title>
      </PopupHead>
      <Description>{getServiceContent(type)}</Description>
      <CloseButton
        aria-label="close"
        onClick={(e)=>{
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      >
          <Icon/>
      </CloseButton>
    </StyledPopup>
  );
};

export default Modal;
