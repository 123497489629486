import React from "react"
import styled from "styled-components"
import feesTable from "../../../asset/images/avia/agents/feesTable.svg"
import Dialog from '@mui/material/Dialog';
import Typography from '../../ui/Typography'
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import CardMedia from '@mui/material/CardMedia';


const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Modal = styled(Dialog)`
  & .MuiPaper-root {
    width: 963px;
    min-width: 963px;
    border: 1px solid #d7d7d7;
    padding: 30px 20px;
    border-radius: 8px;
  }
`

const Heading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #3c3c3c;
  margin-bottom: 20px;
`

const Ul = styled.ul`
  margin: 0;
  margin-top: 25px;
  padding-left: 17px;

  & > li {
    margin-top: 12px;
  }

  & > :first-child {
    margin-top: 0;
  }
`

const Li = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  & > strong {
    font-weight: 600;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const Example = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > :first-child {
    margin-right: 4px;
    flex-shrink: 0;
  }

  ${Typography} {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
  }


  & > :last-child > :first-child {
    display: block;
    margin-bottom: 4px;
  }
`;


const AgentFeesModal = ({ setAgentFeesPopUp, agentFeesPopUpOpen }) => {
  return (
    <Modal
      onClose={() => setAgentFeesPopUp(false)}
      open={agentFeesPopUpOpen}
    >
      <CloseButton
        aria-label="close"
        onClick={() => setAgentFeesPopUp(false)}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Heading>Как работает применение сборов?</Heading>
        <Example>
          <Typography fontWeight='600'>Пример 1:</Typography>
          <div>
            <Typography>
              заявка включает в себя 3 сегмента и 2 пассажира. Мы решаем установить{' '}
              <Typography fontWeight='600'>сборы в размере 100 ₽ или 2%.</Typography>
            </Typography>
            <Typography>Ниже вы можете увидеть какой расчет будет у данных сборов в зависимости от способа применения.</Typography>
          </div>
        </Example>
        <CardMedia
          component="img"
          height="423"
          image={feesTable}
          alt="Paella dish"
        />
        <Ul>
          <Li>
            <strong> К сегментам</strong> - сбор
            умножается на количество сегментов
          </Li>
          <Li>
            <strong> К пассажирам </strong> - сбор
            умножается на количество пассажиров (только на взрослых и детей, для
            младенцев сбор всегда равен нулю).
          </Li>
          <Li>
            <strong>К пассажирам и к сегментам</strong>{" "}
            - сбор умножается на количество сегментов и на количество
            пассажиров.
          </Li>
          <Li>
            <strong> К заявке</strong> - cбор
            применится к стоимости перелета вне зависимости от пассажиров и
            сегментов.
          </Li>
        </Ul>
      </Container>
    </Modal>
  )
}

export default AgentFeesModal
