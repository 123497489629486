import React from 'react';

import './style.css'

const CustomModal = ({image, title, text, successButtonTitle, closeButtonTitle, onClose, onSuccess, className}) => {

	return (
		<div className="popup-bg">
			<div className="popup-block">
				{image &&
					<div className="popup-image">
						<img src={image} alt=""/>
					</div>}
				<h2 className="popup-name">
					{title}
				</h2>
				{text && <p className={`popup-text ${className}`}>{text}</p>}
				{successButtonTitle && <button className="popup-btn" onClick={onSuccess}>{successButtonTitle}</button>}
				{closeButtonTitle && <button className="popup-btn close" onClick={() => onClose(false)}>{closeButtonTitle}</button>}
			</div>
		</div>
	);
};

export default CustomModal;