import DateInput from './DateInput'
import SelectInput from './SelectInput'
import useVisaStore from '../../store'
import { useState } from 'react'

import './style.scss'
import { Box } from "@mui/material";
import { format } from "date-fns";
import { Button } from "@material-ui/core";

function VisaCalculator({ countries }) {
	const actions = useVisaStore(({ actions }) => actions)

	const countryId = useVisaStore(({ countryId }) => countryId)
	const tourStartDate = useVisaStore(({ tourStartDate }) => tourStartDate)
	const tourEndDate = useVisaStore(({ tourEndDate }) => tourEndDate)
	const calcValidDate = useVisaStore(({ calcValidDate }) => calcValidDate)

	const [error, setError] = useState(false)

	const submit = () => {
		setError(false)
		if (countryId && tourStartDate && tourEndDate) {
			actions.getPassportValid()
		} else {
			setError(true)
		}
	}

	return (
		<div className='visa-calc'>
			<h4>Калькулятор расчета срока действия паспорта</h4>
			<div className='visa_form'>
				<SelectInput
					label='Страна тура'
					value={countryId}
					list={countries}
					onChange={actions.changeCountry}
					error={error && !countryId}
					required
				/>
				<DateInput
					label='Дата начала тура'
					value={tourStartDate}
					onChange={actions.changeStartDate}
					required
					isExpiration
					error={error && !tourStartDate}
				/>
				<DateInput
					label='Дата окончания тура'
					value={tourEndDate}
					onChange={actions.changeEndDate}
					error={error && !tourEndDate}
					isExpiration
					required
					minStartDate={tourStartDate}
				/>
				{/*<DateInput*/}
				{/*	label='Срок действия заграничного паспорта до'*/}
				{/*	value={passportValidUntilDate}*/}
				{/*	onChange={actions.changePassportValidUntilDate}*/}
				{/*	error={error && !passportValidUntilDate}*/}
				{/*	required*/}
				{/*/>*/}
			</div>
			<Button className='visa_submit-btn' onClick={submit} disabled={!countryId || !tourStartDate || !tourEndDate}>
				Проверить
			</Button>
			{calcValidDate && <Box mt={3} textAlign={'center'}>
				Срок действия заграничного паспорта должен быть до <strong>{format(new Date(calcValidDate), 'dd.MM.yyyy')}</strong>
			</Box>}
		</div>
	)
}

export default VisaCalculator
