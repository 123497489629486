import React, { useEffect, useState } from 'react';

import './style.css'
import useBookingStore from "../../store";
import { groupBy, isEmpty } from "lodash";
import Seat from "./Seat";
import { CloseOutlined } from "@material-ui/icons";
import Legend from "./Legend";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Tourists from "./Tourists";
import { Button } from "@material-ui/core";
import { Box } from "@mui/material";

const Boarding = ({ freight, dateFreight, onClose }) => {

	const actions = useBookingStore(({ actions }) => actions)
	const boarding = useBookingStore(({ boarding }) => boarding)
	const paxes = useBookingStore(state => state.peoples.filter(pax => pax.age !== 3))
	const loading = useBookingStore(state => state.loading)
	const selectedTransport = useBookingStore(state => state.selectedTransport)

	const [selectedFlightsSeats, setSelectedFlightsSeats] = useState(paxes.map((paxe, ind) => {
		return {
			id: paxe.key,
			seats: {
				seatKey: selectedTransport?.find(item => item?.key === freight)?.clients[ind].seatKey,
				freight: freight
			}
		}
	}))

	const [touristId, setTouristId] = useState(paxes.find(paxe => !paxe.selectedFlightsSeats?.find(seat => seat?.freight === freight))?.id || 0)

	const { search } = useLocation();

	useEffect(() => {
		const query = new URLSearchParams(search)
		actions.getBoarding(freight, dateFreight, query.get('currencyId'))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const id = selectedFlightsSeats.find(paxe => !paxe.seats?.seatKey)?.id
		setTouristId(id)
		// eslint-disable-next-line
	}, [selectedFlightsSeats])

	if (isEmpty(boarding)) {
		return
	}

	const handleSelectSeat = (seat) => {
		if (selectedFlightsSeats.find(item => item.seats?.seatKey === seat.key)) {
			setSelectedFlightsSeats(selectedFlightsSeats.map(item => item.seats?.seatKey === seat.key ? { ...item, seats: { freight: freight, seatKey: '' } } : item))
		} else {
			setSelectedFlightsSeats(selectedFlightsSeats.map(item => item.id === touristId ? { ...item, seats: { freight: freight, seatKey: seat.key } } : item))
		}
	}

	const handleSelectTourist = (id) => {
		setTouristId(id)
	}

	const handleSaveSeats = () => {
		actions.setFlightSeats(selectedFlightsSeats)
		onClose()
	}

	const handleDeleteSeat = (id) => {
		setSelectedFlightsSeats(selectedFlightsSeats.map(item => item.id === Number(id) ? { ...item, seats: { freight: freight, seatKey: '' } } : item))
	}

	const seats = groupBy(boarding, 'row')

	return (
		<div className="boarding-popup-bg">
			<div className="boarding-popup-block">
				<div className="modal-title">
					<div
						style={{
							flex: 1,
							textAlign: "center",
							alignSelf: "center",
							fontSize: "1.17em",
							fontWeight: "bold",
						}}
					>
						Выбор места
					</div>
					<div
						style={{ flex: 0.1, textAlign: "end", cursor: "pointer" }}
						onClick={onClose}
					>
						<CloseOutlined />
					</div>
				</div>
				<Tourists
					touristId={touristId}
					seats={selectedFlightsSeats}
					onSelected={handleSelectTourist}
					onDelete={handleDeleteSeat}
				/>
				<Legend />
				{loading ? <CircularProgress />
					: <div style={{ overflowX: 'auto' }}>
						{Object.keys(seats).map(row =>
							<div key={row} className={'seat-row'}>
								{seats[row].map(cols =>
									<Seat
										key={cols.key}
										seat={cols}
										selected={selectedFlightsSeats.some(item => item.seats?.seatKey === cols.key)}
										touristId={touristId}
										onSelected={handleSelectSeat}
									/>
								)}
							</div>
						)}
					</div>}
				<Box mt={2}>
					<Button
						variant={'contained'}
						color={'primary'}
						onClick={handleSaveSeats}
					>
						Сохранить
					</Button>
				</Box>
			</div>
		</div>
	);
};

export default Boarding;