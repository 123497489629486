import React from 'react';
import ExpansionPanel from '../components/ExpansionPanel/ExpansionPanel';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Open Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #2e2e32;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const Text = styled.div`
    margin-top: 24px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c3c3c;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export default function RegularGDSContacts() {
    return (
        <ExpansionPanel
            title={
                <Title>
                    Обращения по пакетам на регулярных рейсах (GDS) с вылетом из
                    Москвы, по индивидуальному бронированию, а также пакетам,
                    забронированным через раздел "Конструктор" (SL-пакеты),
                    круизы'
                </Title>
            }
            body={
                <Text>
                    <b>Почта отдела: </b>fit@fstravel.com
                    <br />
                    <b>Телефон отдела:</b> + 7 (495) 775 88 78
                </Text>
            }
        />
    );
}
