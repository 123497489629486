import { AvailableActions } from "../../../../store/modules/orders"
import { flatten } from "lodash"

export function createFromToString(segmentGroups) {
  return (segmentGroups || [])
    .map(value => {
      if (value.segments.length === 1) {
        return [...value.segments, ...value.segments].map(extractCity)
      } else {
        return value.segments.map(extractCity)
      }
    })
    .map(flatten)
    .map((value, index, arr) => removeDuplicates(value, arr[index + 1]))
    .map(value => value?.join(" - "))
    .join(" - ")
}

function extractCity(value, index, arr) {
  if (index !== 0 && index !== arr.length - 1)
    return [value.departureCity?.name, value.arrivalCity?.name]
  return index === 0 ? value.departureCity?.name : value.arrivalCity?.name
}

function removeDuplicates(x1, x2) {
  if (!x2) {
    return x1
  }
  if (x1[x1.length - 1] === x2[0]) {
    const x1Clone = [...x1]
    x1Clone.length = x1.length - 1
    return x1Clone
  }
}

const PRINT = {
  actions: "PRINT",
  value: "PRINT",
  label: "Печать билета"
}

const CREATE_APPEAL_WITH_ONLY_OTHER_THEME = {
  actions: "CREATE_APPEAL",
  value: "",
  label: "Служба поддержки"
}

const CREATE_APPEAL_WITH_ANY_THEME = [
  {
    actions: "CREATE_APPEAL",
    value: "FullRefund",
    label: "Возврат билета"
  },
  {
    actions: "CREATE_APPEAL",
    value: "FullExchange",
    label: "Обмен билета"
  }
]

export function createAvailableActionsOptionsList(availableActions) {
  let result = []

  if (
    availableActions.includes(AvailableActions.CREATE_APPEAL_WITH_ANY_THEME)
  ) {
    result.push(PRINT)
  }
  if (
    availableActions.includes(AvailableActions.CREATE_APPEAL_WITH_ANY_THEME)
  ) {
    result = [...result, ...CREATE_APPEAL_WITH_ANY_THEME]
  }

  if (
    availableActions.includes(
      AvailableActions.CREATE_APPEAL_WITH_ONLY_OTHER_THEME
    )
  ) {
    result.push(CREATE_APPEAL_WITH_ONLY_OTHER_THEME)
  }

  return result
}
