import React from 'react';
import styled from 'styled-components';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import Collapse from '@mui/material/Collapse';

const Wrapper = styled.div`
    border-radius: 16px;
    border: 1px solid #d4d9de;
    overflow: hidden;
    padding: 32px;
    margin-bottom: 16px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        padding: 24px;
        margin-bottom: 8px;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        margin-right: 16px;
    }
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2e2e32;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const ExpansionPanel = ({ title, body }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Wrapper>
            <Row
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer' }}
            >
                <FlexRow>
                    <Title>{title}</Title>
                </FlexRow>
                {isOpen ? <RemoveRoundedIcon /> : <AddRoundedIcon />}
            </Row>
            <Collapse in={isOpen}>
                <div>{body}</div>
            </Collapse>
        </Wrapper>
    );
};

export default ExpansionPanel;
