import React from 'react'
import styled from 'styled-components'
import SortingBlock from './SortingBlock'
import TableComission from './TableComission'
import useCommissionStore from './store'

const Title = styled.div`
	font-family: Open Sans;
	font-weight: 600;
	font-size: 28px;
	font-style: normal;
	line-height: 38px;
	color: #2e2e32;
`

const Container = styled.div`
	width: 900px;
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

export default function Comission() {
	const actions = useCommissionStore((state) => state.actions)
	React.useEffect(() => {
		actions.getCommission()
	// eslint-disable-next-line 
	}, [])

	return (
		<Container>
			<Title>Комиссия</Title>
			<SortingBlock />
			<TableComission/>
		</Container>
	)
}
