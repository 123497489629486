import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getAgentSiginState} from "../../../store/modules/agents/agent-finance/selectors";
import HistoryBlock from "./components/HistoryBlock";
import './style.scss'
import {Button} from "@material-ui/core";
import axios from "axios";
import {getUserDataState} from "../../../store/modules/user";
import {Checkbox} from "../../../components/ui";
import {ReactComponent as DownloadIcon} from "../../../asset/images/avia/shared/download.svg"
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(253, 236, 236, 1);
  margin-top: 32px;
  padding: 24px;
  font-weight: 600;
  border-radius: 16px;
  line-height: 24px;
  text-align: center;
`
const StartButton = styled(Button)`
  margin-top: 32px;
  padding: 8px 20px;
  text-transform: unset;
  font-weight: 600;
  border-radius: 8px;
  background-color: #ffe100;

  &:hover {
    background-color: #ffcd00;
  }
`

const AviaSaleRequest = () => {
  const [statusRequests, setStatusRequests] = useState(false)
  const [isAgentImported, setAgentImported] = useState(false)
  const [isRequestEnabled, setRequestEnabled] = useState(false)
  const [isTermsAccepted, setTermsAccepted] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const [agentInfo, setAgentInfo] = useState(false)
  const user = useSelector(getUserDataState);
  const isViewManager = user?.role === 'ViewManager';
  const agent = useSelector(getAgentSiginState)
  // const status = useSelector(getUserStatusState)
  const isNewPartner = JSON.parse(localStorage.getItem('agentInfo')).role === 'NewPartner'
  const isFSAgent = JSON.parse(localStorage.getItem('agentInfo')).role === 'Agent'

  const franchisee_url = process.env.REACT_APP_AGENCY_GET_FRANCHISEE_AGREEMENT

  useEffect(() => {
    fetchStatus()
  }, [])

  useEffect(() => {
    if (agent?.agentId) {
      fetchAgentInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const fetchStatus = async () => {
    const partnerId = JSON.parse(localStorage.getItem('agentInfo'))['agents']['id']
    // const partnerId = agent?.agentSamoCode ?? ""
    const request = await fetch(process.env.REACT_APP_AGENCY_GET_STATUS + '?Filter.PartnerId=' + partnerId)

    if (request.status === 200) {
      const body = await request.json()
      const data = body.items
      setStatusRequests(data)


    if (data.length > 0) {
      setAgentImported(data.some(item => item.status.code === 'Approved'))
    }
    setRequestEnabled(data.length === 0 || data[0].status.code === 'Rejected')
    }
  }

  const fetchAgentInfo = async () => {
    const agentId = agent?.agentId ?? ""
    const request = await fetch(process.env.REACT_APP_AGENTS_GET + '?id=' + agentId)
    const body = await request.json()
    setAgentInfo(body)
  }

  const requestImport = async () => {
    const agentsData = JSON.parse(localStorage.getItem('agentsUsers'))[0]
    const salesChannels = JSON
      .parse(localStorage.getItem('agentInfo'))['agents']['groups']
      .map(el => el.title).join(', ')

    const payload = {
      'partnerId': agentsData.partner,
      'partnerPass': agentsData.partpassId,
      'userName': agentsData.login,
      'salesChannel': salesChannels,
      'email': agentsData.email,
      'isAgreementProposalDeal': 'Franchising',
    }
    try {
      await axios({
        url: process.env.REACT_APP_AGENCY_ADD_REQUEST_AGENT,
        method: 'POST',
        data: payload
      })

      await fetchStatus()

    } catch (e) {
      console.log('error', e)
    }
  }

  const handleSubmit = () => {
    setSubmitCount(x => x + 1)
    isTermsAccepted && requestImport()
  }

  const handleChange = () => {
    setTermsAccepted(current => !current)
  }

  return (
    <>
      {isViewManager && <h1>Данный раздел вам не доступен</h1>}
      {(!isViewManager && (isNewPartner || isFSAgent)) && <>
        {statusRequests
          ? <div className={'tte-request-container'}>
            <div className={'tte-request-main-title'}>Продажа авиабилетов</div>
            {isAgentImported
              ? <>
                <StartButton onClick={() => window.location = '/avia'} variant="contained">
                  Начать работу
                </StartButton>
                {/*<div className={'tte-request-link tte-mt-3'}><a href="/avia">Перейти на страницу авиабилетов</a></div>*/}
                {agentInfo?.offerDocumentLink &&
                  <a href={agentInfo?.offerDocumentLink} className={'tte-request-download-link tte-mt-3'}>
                    <DownloadIcon/>
                    <span>Скачать оферту агента</span>
                  </a>
                }
                <div className={'tte-request-info-block'}>
                  <Checkbox
                    type="squar"
                    checked={true}
                    name="offertaAccept"
                    disabled={true}
                  />
                  <div style={{fontWeight: 600, marginLeft: 8}}>
                    Я ознакомлен с правилами работы в Транспортном движке и согласен с условиями оферты
                  </div>
                </div>
                {!user && <Wrapper>
                  <div>Ваша учетная запись не добавлена в Транспортный движок!</div>
                  <div style={{textAlign:'justify'}}>Просьба направить запрос на{' '}
                    <a className={'tte-request-link'} href="mailto:dogovor.avia@fstravel.com">dogovor.avia@fstravel.com</a>
                    {' '}о добавлении своего логина. В письме просьба указать логин и ИНН агента.
                  </div>
                </Wrapper>}
              </>
              : <div className={'tte-request-body'}>
                <div className={'tte-request-link-title'}>Для продажи авиабилетов необходимо:</div>
                <div className={'tte-mt-3'}>Ознакомиться и принять условия{' '}
                  <b><a href={franchisee_url} style={{color:'#4872f2',textDecoration:'underline'}} target="_blank" rel="noreferrer">Оферты</a></b>{' '}
                  для работы в Транспортном движке.
                </div>
                <div className={'tte-request-info-block'}>
                  <Checkbox
                    type="squar"
                    checked={isTermsAccepted || !isRequestEnabled}
                    name="offertaAccept"
                    error={!isTermsAccepted && submitCount > 0}
                    onChange={isRequestEnabled && handleChange}
                    disabled={!isRequestEnabled}
                  />
                  <div style={{fontWeight: 600, marginLeft: 8}}>
                    Я ознакомлен с правилами работы в Транспортном движке и согласен с условиями оферты
                  </div>
                </div>
                {(!isTermsAccepted && submitCount > 0) &&
                  <div className={'tte-request-info-block-error'}>Необходимо подтвердить согласие</div>
                }
                <Button variant={'outlined'} color={'primary'} onClick={handleSubmit}
                        className={`${!isRequestEnabled ? 'disabled' : ''}`}>
                  Отправить заявку
                </Button>
              </div>
            }
            {!!statusRequests.length && <HistoryBlock statuses={statusRequests}/>}
          </div>
          : <div style={{position: 'sticky', top: 0, zIndex: 10, width: '100%'}}>
            <LinearProgress/>
          </div>
        }
      </>}
    </>
  )
}

export default AviaSaleRequest
