import './onePhoto.style.scss'

export default function OnePhoto({ gallery }) {

    return <div className="onePhoto">
        <div className="onePhoto__wrapper">
            <img className="onePhoto__wrapper__img" src={gallery[0]?.image} alt={gallery[0]?.text} />
        </div>
        {gallery[0]?.text && <p className="onePhoto__text">Источник фото: <span>{gallery[0]?.text}</span></p>}
    </div>
}