import styled from "styled-components"

const Rect = styled.rect`
${({ color = '#DBE0E5' }) => {
        return `stroke: ${color};`
    }}
`

function LineWithCircle({ end = false, start = false }) {
    return <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Rect x="34.5" y="0.5" width="1" height="26" color={start ? 'white' : '#DBE0E5'} />
        <circle cx="35" cy="35" r="7" fill="white" stroke="#DBE0E5" strokeWidth="2" />
        <Rect x="34.5" y="43.5" width="1" height="26" color={end ? 'white' : '#DBE0E5'} />
    </svg>
}

export default LineWithCircle