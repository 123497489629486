import React, { useEffect, useMemo, useState } from 'react'
import './style.css'
import { format } from 'date-fns'
import { Button, Container, Grid, Paper } from '@material-ui/core'
import useBookingStore from '../../store'
import useLoyaltyStore from '../../../Loyalty/store'
import Commission from '../Commission'
import { useErrorStore } from '../../../Partner/Insurances/Booking/components/MembersBlock/store'

export default function RegButtonsBlock({ response }) {
	const [openFlightInfo, setOpenFlightInfo] = useState(false)
	// const { forwardFlightId, backwardFlightId, selectedFlightOptionId } =
	// 	useBookingStore()
	const forwardFlightId = useBookingStore(
		({ forwardFlightId }) => forwardFlightId
	)
	const backwardFlightId = useBookingStore(
		({ backwardFlightId }) => backwardFlightId
	)
	const selectedFlightOptionId = useBookingStore(
		({ selectedFlightOptionId }) => selectedFlightOptionId
	)
	const oldPrice = useBookingStore(state => state.oldPrice)
	const phoneErrorGds = useBookingStore(({ phoneErrorGds }) => phoneErrorGds)
	const buyerErrors = useBookingStore(({ buyerErrors }) => buyerErrors)
	const buyer = useBookingStore(({ buyer }) => buyer)
	const birthDayError = useErrorStore(({ birthDayError }) => birthDayError)
	const actions = useBookingStore(({ actions }) => actions)
	const fare = useBookingStore(({ fares }) => fares?.find(fare => fare.checked))
	const faresError = useBookingStore(({ faresError }) => faresError)
	const currencys = useBookingStore(({ currencys }) => currencys)
	const loading = useBookingStore(({ loading }) => loading)
	const paxes = useBookingStore(({ paxes }) => paxes)

	const preorder = useLoyaltyStore(({ preorder }) => preorder)
	const memberId = useLoyaltyStore(({ memberId }) => memberId)
	const client = useLoyaltyStore(({ client }) => client)
	const spentPoints = useLoyaltyStore(({ spentPoints }) => spentPoints)
	const price = preorder?.order?.totalPrice ? preorder?.order?.totalPrice : response?.price?.amount ?? 0

	const baggage = useBookingStore(
		state =>
			state?.tour?.flightOptions?.find(
				flight => flight.key === selectedFlightOptionId
			)?.forwardFlight?.segments[0].baggage.value
	)
	const countAdt = useMemo(() => {
		return response?.tour?.paxes.filter(paxe => paxe.person.ageGroup === 1)
			.length
	}, [response])
	const countInf = useMemo(() => {
		return response?.tour?.paxes.filter(paxe => paxe.person.ageGroup === 2)
			.length
	}, [response])

	const flight = useMemo(() => {
		return response?.tour?.flightOptions?.find(
			flight => flight.key === selectedFlightOptionId
		)
	}, [response, selectedFlightOptionId])

	const tourRecountHandler = async () => {
		await actions.tourRecount()
		await actions.getFares()
		setOpenFlightInfo(true)
	}

	// useEffect(() => {
	// 	if (flight) {
	// 		actions.getFares()
	// 	}
	// }, [actions, flight])

	useEffect(() => {
		actions.setNewPrice()
	}, [forwardFlightId, backwardFlightId, actions, selectedFlightOptionId])

	const isCabbinBaggage = () => {
		return fare?.service
			.split('\n')
			.find(
				elem =>
					elem.toLowerCase().includes('carryon') ||
					elem.toLowerCase().includes('cabin baggage') ||
					elem.toLowerCase().includes('carry on')
			)
	}

	const getRates = () => {
		let currency = null
		if (response.nativeCurrencyPrice.currencyId === 1) {
			return 1
		}

		Object.values(currencys).forEach(item => {
			const foundCurrency = item.find(value => value.currencyKey === response.nativeCurrencyPrice.currencyId);
			if (foundCurrency) {
				currency = foundCurrency.rate;
			}
		})
		if (currency) {
			return currency
		}
	}
	const RUSSIA_ID = 210357
	const isBuyerExist = response?.tour?.hotels[0]?.country?.id !== RUSSIA_ID

	const isPhoneNumbersFilled = paxes.every(el => el.person.phone)

	const isBuyerFildsWithErrors = () => {
		if (!isBuyerExist) {
			return false
		}
		const isBuyerPasspostFilled = buyer.number && buyer.pserie
		const isBuyerErrors = Object.values(buyerErrors).some(el => !!el)

		return !isBuyerPasspostFilled || isBuyerErrors
	}

	return (
		<>
			<div className={`price-amount ${oldPrice ? 'stroke' : ''}`}>
				<div className='eur'>
					{Math.round(price / getRates())} {response.nativeCurrencyPrice.currencyAlias}
				</div>
				{response.price.currencyAlias !==
					response.nativeCurrencyPrice.currencyAlias && (
						<div className='rub'>
							{Math.ceil(price)}{' '}
							{response.price.currencyAlias} (курс на{' '}
							{format(new Date(), 'dd.MM.yyyy')})
						</div>
					)}
			</div>
			{openFlightInfo && (
				<Container maxWidth='sm' style={{ marginBottom: '20px' }}>
					<Paper
						elevation={1}
						style={{
							padding: '15px 15px 15px 15px',
							maxHeight: 300,
							overflow: 'auto'
						}}>
						<Grid
							container
							spacing={2}
							sx={{ m: 2 }}
							style={{
								whiteSpace: 'pre-wrap'
							}}>
							<Grid item xs={12}>
								<b>
									Найден перелет на {countAdt} Взр(12-99)
									{!!countInf && ` +${countInf} Реб(2-11.99)`}
								</b>
								{`\n\nВозврат: Нет информации по возврату билета\n`}
								{baggage === 1
									? `Багаж: Включено ${baggage} багажное место на пассажира. Вес можно уточнить на сайте авиакомпании.`
									: baggage === 2
										? `Багаж: Включено ${baggage} багажных места на пассажира. Вес можно уточнить на сайте авиакомпании.`
										: baggage > 2
											? `Багаж: ${baggage} Kg`
											: `Багаж: Перевозка багажа за дополнительную плату.`}
								{!!isCabbinBaggage() &&
									`\nРучная кладь:${isCabbinBaggage().substring(
										isCabbinBaggage().lastIndexOf(':') + 1,
										isCabbinBaggage().lastIndexOf('-')
									)}`}
							</Grid>
							{fare && !faresError && (
								<Grid item xs={12}>
									<b>Тариф: {fare && fare.name}</b>
								</Grid>
							)}
							{!faresError && fare?.service && (
								<Grid item xs={12}>
									<span>{'Доступные сервисы:\n'}</span>
									{fare?.service
										.split('\n')
										.map(item => {
											if (item) {
												return `- ${item}`
											} else return item
										})
										.join('\n')}
								</Grid>
							)}
							<Grid item xs={12}>
								<b>{'Рейсы на бронирование:\n'}</b>
							</Grid>
							<Grid item xs={12}>
								{flight &&
									`${format(
										new Date(flight?.forwardFlight?.startDateTime),
										'dd.MM.yyyy'
									)} [${flight?.forwardFlight?.segments[0].class.name}]\n`}
								{flight &&
									`${flight?.forwardFlight?.segments[0].flightNumber} (${flight?.forwardFlight?.segments[0].startWayPoint.port.name} - ${flight?.forwardFlight?.segments[0].endWayPoint.port.name})\n`}
								{flight &&
									`${format(
										new Date(flight?.backwardFlight?.startDateTime),
										'dd.MM.yyyy'
									)} [${flight?.backwardFlight?.segments[0].class.name}]\n`}
								{flight &&
									`${flight?.backwardFlight?.segments[0].flightNumber} (${flight?.backwardFlight?.segments[0].startWayPoint.port.name} - ${flight?.backwardFlight?.segments[0].endWayPoint.port.name})\n`}
							</Grid>
						</Grid>
					</Paper>
				</Container>
			)}
			{!oldPrice && (
				<Commission
					price={Math.round(price / getRates())}
					currency={response.nativeCurrencyPrice.currencyAlias}
					partnerCommission={response.comissionPercent}
					nativeCurrencyPrice={response.nativeCurrencyPrice}
				/>
			)}
			<div style={{ textAlign: 'center', marginBottom: 20 }}>
				<Button
					style={{ marginRight: 10 }}
					variant='contained'
					color='primary'
					type='button'
					onClick={tourRecountHandler}>
					Пересчитать
				</Button>
				<Button
					variant='contained'
					onClick={() =>
						actions.reserve({
							memberId: memberId,
							client: client,
							spentPoints: spentPoints,
							preorder: preorder
						})
					}
					>
					Бронировать
				</Button>
			</div>
		</>
	)
}
