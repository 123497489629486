import React from "react"
import styled from "styled-components"
import { InfoBlock, Separator,Text } from "../ui"

const Header = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 650px;
  @media (max-width: 1024px) {
    display: block;
    & > div {
      margin-bottom: 20px;
    }
    & > :last-child {
      margin-bottom: 6px;
    }
  }
`

const BodyTwo = styled(Body)`
  justify-content: initial;
  & > :first-child {
    margin-right: 123px;
  }

  @media (max-width: 1024px) {
    & > :first-child {
      margin-right: 0;
    }
  }
`

const Column = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    border-bottom: 1px solid #dcdcdc;
    & > :first-child {
      margin-bottom: 3px;
    }
    padding-bottom: 5px;
  }
`

const Label = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const Description = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  @media (max-width: 1024px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
`

const Contacts = props => {
  const formatedPhone = React.useMemo(() => {
    let result = ""
    if (props.contactPhone !== null) {
      const x = (props.contactPhone || "")
        .replace(/\D/g, "")
        .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)
      if (x) {
        result = `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`
      }
    }
    return result
  }, [props.contactPhone])

  const formatedPhoneSms = React.useMemo(() => {
    if (props.sms === null) return ""
    const contactPhone = props.sms.contactPhone
    let result = ""
    if (contactPhone !== null) {
      const x = (contactPhone || "")
        .replace(/\D/g, "")
        .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)
      if (x) {
        result = `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`
      }
    }
    return result
  }, [props.sms])
  return (
    <InfoBlock>
      <Header>Контактная информация</Header>
      <Body>
        <Column>
          <Label>Имя:</Label>
          <Description>{props.contactName}</Description>
        </Column>
        <Column>
          <Label>Телефон:</Label>
          <Description>{formatedPhone}</Description>
        </Column>
        <Column>
          <Label>Email:</Label>
          <Description>{props.contactEmail}</Description>
        </Column>
      </Body>
      {!!props.sms && (
        <>
          <Separator />
          <Header>Контакт для услуги “SMS-информирование”</Header>
          <BodyTwo>
            <Column>
              <Label>Имя:</Label>
              <Description>{props.sms.contactName}</Description>
            </Column>
            <Column>
              <Label>Телефон:</Label>
              <Description>{formatedPhoneSms}</Description>
            </Column>
          </BodyTwo>
        </>
      )}
    </InfoBlock>
  )
}

export default Contacts
