import SliderPhoto from "./SliderPhoto"
import OnePhoto from "./onePhoto"
import SimpleGallery from "./simpleGallery"
import "./style.scss"

export default function PhotoGallery({ content, title, id }) {
    const getBlock = (template, gallery) => {
        switch (template) {
            case "onePhoto":
                return <OnePhoto gallery={gallery} />
            case "simpleGallery":
                return <SimpleGallery gallery={gallery} />
            case "sliderPhoto":
                return <SliderPhoto gallery={gallery} />
            default:
                return null;
        }

    }

    return <div className="photoGallery" id={id}>
        <h3 className="photoGallery-title">{title}</h3>
        {getBlock(content?.template, content?.gallery)}
    </div>
}

