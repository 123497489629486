import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {Button} from "@material-ui/core";
import {format} from "date-fns";
import {ru} from "date-fns/locale";


const DateChangeModal = ({ children,  onClose, dateRange }) => {
	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => (document.body.style.overflow = '')
	})

	return (
		<div className='DateChangeModal-bg'>
			<div className='DateChangeModal-content'>
				<div 	
					className='DateChangeModal-header'
					onClick={() => onClose(false)} >
					<ArrowBackIosIcon />
					<div className='DateChangeModal-title'>Даты поездки</div>
				</div>
				{children}
				<div className='DateChangeModal-buttonBlock'>
					{dateRange && <div>{dateRange.map(date => format(date, 'dd MMM', {locale: ru})).join(' - ')}</div>	}
					<Button
						fullWidth
						onClick={() => onClose(false)}
						variant={'contained'}
						className={'search-button'}
					>
						Готово
					</Button>
				</div>
			</div>
		</div>
	)
}

export default DateChangeModal
