import React from 'react'
import useHotelDescriptionStore from '../../store'
import {
	FullscreenControl,
	Placemark,
	YMaps,
	ZoomControl,
	Map
} from '@pbe/react-yandex-maps'
import { Box } from '@mui/material'
import './style.css'

export default function MobileVersionLocation() {
	const locationHotelText = useHotelDescriptionStore(
		({ locationHotel }) => locationHotel?.text
	)
	const location = useHotelDescriptionStore(({ location }) => location)
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const defaultState = {
		center: location.geometry,
		zoom: location.zoom || 10,
		controls: []
	}
	function createText() {
		const newValue = locationHotelText?.startsWith('<p>')
			? locationHotelText?.slice(3, locationHotelText.length - 4)
			: locationHotelText
		return {
			__html:
				newValue?.length > 233 ? newValue?.slice(0, 230) + '...' : newValue
		}
	}
	return (
		<>
			<h4 style={{ margin: '0px 0px 14px 0px' }}>Расположение</h4>
			<div className='container-map'>
				<div
					style={{ color: '#2e2e32', margin: '0px' }}
					dangerouslySetInnerHTML={createText()}></div>
			</div>
			{hotel?.localizedName && (
				<Box className='mobile-yandex-map-block'>
					<YMaps
						query={{
							apikey: process.env.REACT_APP_YANDEX_MAP,
							lang: 'ru_RU'
						}}
						style={{ borderRadius: '16px' }}>
						<Map
							width={'auto'}
							height={'268px'}
							defaultState={defaultState}
							modules={[
								'geoObject.addon.balloon',
								'geoObject.addon.hint',
								'geolocation',
								'geocode'
							]}>
							<FullscreenControl />
							<ZoomControl
								options={{
									float: 'right',
									position: { right: 10, bottom: 10 }
								}}
							/>
							<Placemark
								geometry={location.geometry}
								properties={{
									hintContent: `${hotel?.localizedName}`
								}}
							/>
						</Map>
					</YMaps>
				</Box>
			)}
		</>
	)
}
