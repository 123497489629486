import { ThemeProvider } from '@material-ui/core'
import './style.css'
import React from 'react'
import CustomizedTabs from './components/Tabs/CustomizedTabs'
import useAuthStore from '../../Auth/store'
import { Box, Divider, Snackbar, Alert } from '@mui/material'
import AboutAgency, { theme } from './AgencyInfo/AboutAgency'
import AboutAgency2 from './AgencyInfo/AboutAgency2'
// import Payment from './Payment'
import Payment2 from './Payment-new'

import Contacts from './Contacts'
import Contacts2 from './Contacts-new'
import AllAgents from './AllAgents/AllAgents'

export default function Agency() {
	const [tab, setTab] = React.useState(0)
	const actions = useAuthStore(({ actions }) => actions)
	const officialName = useAuthStore(({ officialName }) => officialName)
	const agents = useAuthStore(({ agent }) => agent?.agents)

	
	const error = useAuthStore(({ error }) => error)
	const handleChange = (event, newValue) => {
		setTab(newValue)
	}

	const checkRequiredFields = () => {
		return agents.officialName && agents.pAddress && agents?.registrationDate && 
		agents.taxation.title && agents.boss && agents.directorPosition.title 
		&& agents.ownership.title && agents.worker && agents.activity.title &&
		agents.rs && agents.bank?.id
	}
	const saveHandler = () => {
		console.log([agents.officialName, agents.pAddress, agents?.registrationDate, 
			agents.taxation.title, agents.boss, agents.directorPosition.title, 
			agents.ownership.title, agents.worker, agents.activity.title,
			agents.rs, agents.bank?.id ]);
		if (!checkRequiredFields()) {
			actions.setError('Необходимо заполнить все обязательные поля.')
			return
		}
		alert('проверка пройдена')
		actions.changeAgents()
	}

	return (
		<ThemeProvider theme={theme}>
			<Box>
				<div style={{ width: '808px' }}>
					<div>
						<p className='welcome-title'>Добро пожаловать</p>
						<p className='agent-name'>{officialName}</p>
					</div>
					<CustomizedTabs value={tab} handleChange={handleChange} />

					{tab === 0 ? (
						<>
							{/* <AboutAgency /> */}
							<AboutAgency2/>
							<Contacts2 />
							<Payment2 />
							{/* <Payment /> */}
	
							{/* <Contacts /> */}
	
							<button
								type='button'
								className='agency-save-btn'
								onClick={saveHandler}>
								Сохранить
							</button>
						</>
					) : (
						<AllAgents />
					)}
				</div>
			</Box>
			<Snackbar open={error} autoHideDuration={6000}>
				<Alert
					onClose={() => {
						actions.clearError()
					}}
					severity='error'
					sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
		</ThemeProvider>
	)
}
