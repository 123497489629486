import React from 'react'

import { Box } from '@mui/material'

import useExcursionStore from '../../../store'
import ExcursionFilter from './ExcursionFilter'

export default function ExursionFiltersBlock() {
	const actions = useExcursionStore(({ actions }) => actions)

	const serviceType = useExcursionStore(({ serviceType }) => serviceType)
	const checkedServiceType = useExcursionStore(
		({ checkedServiceType }) => checkedServiceType
	)

	const excursionType = useExcursionStore(({ excursionType }) => excursionType)
	const checkedExcursionType = useExcursionStore(
		({ checkedExcursionType }) => checkedExcursionType
	)

	const forWhom = useExcursionStore(({ forWhom }) => forWhom)
	const checkedForWhom = useExcursionStore(
		({ checkedForWhom }) => checkedForWhom
	)

	const activity = useExcursionStore(({ activity }) => activity)
	const checkedActivity = useExcursionStore(
		({ checkedActivity }) => checkedActivity
	)
	return (
		<Box>
			<ExcursionFilter
				title='Тип услуги'
				list={serviceType}
				checked={checkedServiceType}
				changeHandler={actions.changeServiceType}
			/>
			<ExcursionFilter
				title='Тип экскурсий'
				list={excursionType}
				checked={checkedExcursionType}
				changeHandler={actions.changeExcursionType}
			/>
			<ExcursionFilter
				title='Для кого'
				list={forWhom}
				checked={checkedForWhom}
				changeHandler={actions.changeForWhom}
			/>
			<ExcursionFilter
				title='Активности'
				list={activity}
				checked={checkedActivity}
				changeHandler={actions.changeActivity}
				showDivider={false}
			/>
		</Box>
	)
}
