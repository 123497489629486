import { Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useHotelDescriptionStore from "../../store";
import "./style.scss";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

function BreadcrumbsBlock({ hotelId, locations, hotelName }) {
    const navigate = useNavigate();
    const actions = useHotelDescriptionStore(({ actions }) => actions);
    const parentList = useHotelDescriptionStore(({ parentList }) => parentList);

    const continent = parentList[0] ?? null
    const country = parentList[0]?.children[0] ?? null
    const resort = parentList[0]?.children[0]?.children[0] ?? null

    useEffect(() => {
        hotelId && actions.getParentInfo(hotelId);
    }, [actions, hotelId]);

    return (
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <div className="breadcrumb-link">
                <Link to={"/"} style={{ color: "#4872f2" }}>
                    Главная
                </Link>
            </div>
            <div className="breadcrumb-link">
                <Link to={"/countries"}>Страны</Link>
            </div>
            {!isEmpty(parentList) && country && (
                <div className="breadcrumb-link">
                    <Link
                        to={`/country/${continent.standardizedName?.toLowerCase()}/${country?.standardizedName?.toLowerCase()}`}
                    >
                        <p>{country.localizedName}</p>
                    </Link>
                </div>
            )}
            {resort && (
                <div className="breadcrumb-link">
                    <Link
                        to={`/resort/${continent.standardizedName?.toLowerCase()}/${country?.standardizedName?.toLowerCase()}/${resort.standardizedName?.toLowerCase()}`}
                    >
                        <p>{resort?.localizedName}</p>
                    </Link>
                </div>
            )}
            {hotelName && <div className="breadcrumb-link" onClick={() => navigate(locations)}>{hotelName}</div>}
        </Breadcrumbs>
    );
}

export default BreadcrumbsBlock;
