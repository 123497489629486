import React from 'react'
import { isEmpty } from 'lodash'

import emptyImage from '../../../../../asset/images/hotelRoomPlug.svg'
import Square from '../../../../../asset/icons/Square'
import HotelView from '../../../../../asset/icons/Hotel-view'
import HotelBed from '../../../../../asset/icons/Hotel-bed'
import { isIOS } from 'react-device-detect';

const RoomCardCopy = ({ room, images, objectType, hotelId, amenities }) => {
	const createLinkToImage = data => {
		if (isEmpty(images)) {
			return emptyImage
		} else if (data) {
			return `https://apigate-tui.fstravel.com/api/geocontent/static/${objectType}/${hotelId}/${
				data?.imageType
			}-${data?.resolutionType}-${data?.fileName}?${new Date()}`
		} else {
			return emptyImage
		}
	}

	return (
		<div style={{display: 'flex', flexDirection: 'row'}} className={'room-card'}>
			<div style={{display: 'flex', flexDirection: 'column'}} className={'room-card__images'}>
				<div
					className={`room-card__images-big ${
						images.length < 3 ? 'one-image' : ''
					} `}>
					<img
						crossOrigin='anonymous'
						src={createLinkToImage(
							images.find(item => item.id === room.images[0].id)
						)}
						alt={room.name}
					/>
				</div>
				{!isIOS && images.length > 2 && (
					<div className={'room-card__images-small'}>
						<img
							crossOrigin='anonymous'
							src={`${createLinkToImage(
								images.find(item => item.id === room.images[1].id)
							)}`}
							alt={room.name}
						/>
						<img
							crossOrigin='anonymous'
							src={`${createLinkToImage(
								images.find(item => item.id === room.images[2].id)
							)}`}
							alt={room.name}
						/>
					</div>
				)}
			</div>
			<div className={'room-card__description'}>
				<div className={'room-card__name'}>{room.name}</div>
				<div className={'room-card__description-info'}>
					{!!room.square && (
						<div className={'square'}>
							<Square />
							{room.square}м<sup>2</sup>
						</div>
					)}
					{!!room.view?.id && (
						<div>
							<HotelView />
							{room.view?.name}
						</div>
					)}
					{!!room.numberOfBeds && (
						<div>
							<HotelBed />
							Максимальное размещение: {room.numberOfBeds}
						</div>
					)}
				</div>
				{(!!room.square ||
					!!room.view?.name ||
					!!room.numberOfBeds ||
					!isEmpty(images) ||
					!isEmpty(room.amenities)) && (
					<div className={'room-card__more'}>Ещё о номере</div>
				)}
			</div>
			<div className={'room-card__includes'}>
				{room.amenities.map(item => {
					const ametity = amenities.find(a => a.id === item.id)
					if (!ametity) return null
					return <span key={item.id}>{ametity.name} </span>
				})}
			</div>
		</div>
	)
}

export default RoomCardCopy
