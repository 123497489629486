import React from 'react';
import {Outlet} from "react-router-dom";
import NewNav from "../components/NewNav";
import Footer from "../components/Footer";

const OnBoardServicesTemplate = () => {

	return (
		<>
			<NewNav />
			<Outlet />
			<Footer />
		</>

	);
};


export default OnBoardServicesTemplate;