import React, { useEffect, useRef, useState } from 'react'
import BirthDateInput from '../../../../../../components/BirthDateInput'
import { TextField } from '@material-ui/core'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import './style.scss'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, isAfter, isBefore } from 'date-fns'
import useTransferStore from '../../../store'
import { useErrorStore } from '../MembersBlock/store'
import { newPhoneRegExp } from '../../../../../../constants/reg-exps'

const MoreInformation = ({ transfer, validateError }) => {
	const ref = useRef(null)

	const moreInfo = useTransferStore(({ moreInfo }) => moreInfo)
	const actions = useTransferStore(({ actions }) => actions)
	const draft = useTransferStore(({ draft }) => draft)

	const setErrorForm = useErrorStore(state => state.setErrorForm)

	const [errors, setErrors] = useState({
		startDate: '',
		addressFrom: null,
		addressTo: null,
		clientPhone: '',
		flightNumber: '',
		// labelName: ''
	})

	const setTime = data => {
		actions.setStartTime(format(data, 'HH:mm'))
	}

	const handleChangeDate = data => {
		actions.setReadyForBooking(false)
		if (isBefore(data, new Date())) {
			setErrors({ ...errors, startDate: 'Дата не может быть меньше текущей' })
		} else {
			setErrors({ ...errors, startDate: '' })
			actions.setStartDate(format(data, 'yyyy-MM-dd'))
		}
	}

	const handleChangeAddressFrom = event => {
		actions.setReadyForBooking(false)
		setErrors({ ...errors, addressFrom: null })
		if (
			!/^[a-zA-Zа-яА-ЯёЁ0-9\s-/\\.,]+$/g.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				addressFrom: `Адрес должен содержать только латиницу или кирилицу`
			})
		} else {
			setErrors({ ...errors, addressFrom: null })
		}
		actions.setAddressFrom(event.target.value)
	}

	const handleChangeAddressTo = event => {
		actions.setReadyForBooking(false)
		setErrors({ ...errors, addressTo: null })
		if (
			!/^[a-zA-Zа-яА-ЯёЁ0-9\s-/\\.,]+$/g.test(event.target.value) &&
			event.target.value !== ''
		) {
			setErrors({
				...errors,
				addressTo: `Адрес должен содержать только латиницу или кирилицу`
			})
		} else {
			setErrors({ ...errors, addressTo: null })
		}
		actions.setAddressTo(event.target.value)
	}

	const handleChangeFlight = e => {
		actions.setReadyForBooking(false)
		setErrors({ ...errors, flightNumber: '' })
		if (
			!/^[0-9a-zA-Z\s.,-:+_()]+$/.test(e.target.value) &&
			e.target.value !== ''
		) {
			setErrors({
				...errors,
				flightNumber: `Номер рейса указан некорректно`
			})
		} else {
			setErrors({ ...errors, flightNumber: '' })
		}
		actions.setFlightNumber(e.target.value)
	}

	const handleChangeLabel = e => {
		actions.setReadyForBooking(false)
		setErrors({ ...errors, labelName: '' })
		if (e.target.value.length > 50 && e.target.value !== '') {
			setErrors({
				...errors,
				labelName: `Слишком длинное имя`
			})
		}
		if (
			!/^[a-zA-Zа-яА-ЯёЁ\s-]+$/.test(e.target.value) &&
			e.target.value !== ''
		) {
			setErrors({
				...errors,
				labelName: `Имя указано некорректно`
			})
		}
		actions.setLabelName(e.target.value)
	}

	const handleChangePhone = e => {
		actions.setReadyForBooking(false)
		setErrors({ ...errors, clientPhone: '' })
		if (!newPhoneRegExp.test(e.target.value) && e.target.value !== '') {
			setErrors({
				...errors,
				clientPhone: `Номер телефона указан некорректно`
			})
		} else {
			setErrors({ ...errors, clientPhone: '' })
		}
		actions.setClientPhone(e.target.value)
	}

	useEffect(() => {
		if (Object.values(errors).some(item => item)) {
			setErrorForm(true)
		} else {
			setErrorForm(false)
		}
		// eslint-disable-next-line
	}, [errors])


	useEffect(() => {
		if (
			!/^[a-zA-Zа-яА-ЯёЁ0-9\s-/\.,]+$/g.test(moreInfo.addressFrom) &&
			!!moreInfo.addressFrom
		) {
			setErrors({
				...errors,
				addressFrom: `Адрес должен содержать только латиницу или кириллицу`
			})
		} else {
			setErrors({ ...errors, addressTo: null })
		}

		if (
			!/^[a-zA-Zа-яА-ЯёЁ0-9\s-/\.,]+$/g.test(moreInfo.addressTo) &&
			!!moreInfo.addressTo
		) {
			setErrors({
				...errors,
				addressTo: `Адрес должен содержать только латиницу или кириллицу`
			})
		} else {
			setErrors({ ...errors, addressTo: null })
		}
		if (
			!/^[0-9a-zA-Z\s.,-:+_()]+$/.test(moreInfo.flightNumber) &&
			!!moreInfo.flightNumber
		) {
			setErrors({
				...errors,
				flightNumber: `Номер рейса указан некорректно`
			})
		} else {
			setErrors({ ...errors, flightNumber: '' })
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setErrors({
			...errors,
			addressFrom: validateError.addressFrom || errors.addressFrom,
			addressTo: validateError.addressTo || errors.addressTo,
			clientPhone: validateError.clientPhone || errors.clientPhone,
			flightNumber: validateError.flightNumber || errors.flightNumber,
			// labelName: validateError.labelName || errors.labelName,
			startDate: validateError.startDate || errors.startDate
		})
		// eslint-disable-next-line
	}, [validateError])

	return (
		<div className={'transfer-kiwi'}>
			<div className='block-header'>
				<h3 className='title'>Дополнительная информация</h3>
			</div>
			<div className='block-body'>
				<div className={'block-wrapper'}>
					<div className={'date-picker'}>
						<BirthDateInput
							onChange={handleChangeDate}
							count={1}
							label='Дата подачи*'
							value={
								isAfter(
									new Date(draft?.draftBody?.transfers[0]?.date),
									new Date()
								)
									? format(
										new Date(draft?.draftBody?.transfers[0]?.date),
										'dd.MM.yyyy'
									)
									: null
							}
							isExpiration={true}
							required
							helperText={errors.startDate}
							error={!!errors.startDate}
						/>
					</div>
					<div className={'time-picker'}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DemoContainer components={['TimePicker']}>
								<TimePicker
									inputRef={ref}
									label='Время подачи*'
									defaultValue={new Date(draft?.draftBody?.transfers[0]?.date)}
									ampm={false}
									timeSteps={{ hours: 1, minutes: 1 }}
									onChange={data => setTime(data)}
								/>
							</DemoContainer>
						</LocalizationProvider>
					</div>
					<div className={'place-from'}>
						<TextField
							label={`${![2, 3, 4, 5].includes(transfer.route.placeFrom.type.id)
								? 'Адрес'
								: transfer.route.placeFrom.type.name.ru
								}`}
							variant='outlined'
							value={moreInfo.addressFrom}
							fullWidth
							required
							helperText={errors.addressFrom}
							error={!!errors.addressFrom}
							disabled={[2, 3, 4, 5].includes(transfer.route.placeFrom.type.id)}
							onChange={handleChangeAddressFrom}
						/>
					</div>
					<div className={'place-to'}>
						<TextField
							label={`${![2, 3, 4, 5].includes(transfer.route.placeTo.type.id)
								? 'Адрес'
								: transfer.route.placeTo.type.name.ru
								}`}
							variant='outlined'
							value={moreInfo.addressTo}
							fullWidth
							required
							helperText={errors.addressTo}
							error={!!errors.addressTo}
							disabled={[2, 3, 4, 5].includes(transfer.route.placeTo.type.id)}
							onChange={handleChangeAddressTo}
						/>
					</div>
				</div>
				<div className={'block-wrapper'}>
					<div style={{ width: '40%', marginRight: 40 }}>
						{([2, 3, 4, 5].includes(transfer.route.placeFrom.type.id) ||
							[2].includes(transfer.route.placeTo.type.id)) && (
								<div className={'place-from'} style={{ marginBottom: 20 }}>
									<TextField
										label={`Номер рейса/поезда`}
										variant='outlined'
										value={moreInfo.flightNumber}
										fullWidth
										required
										helperText={errors.flightNumber}
										error={!!errors.flightNumber}
										onChange={handleChangeFlight}
									/>
								</div>
							)}
						{/* {[2, 3, 4, 5].includes(transfer.route.placeFrom.type.id) && (
							<div className={'place-from'} style={{ marginBottom: 20 }}>
								<TextField
									label={`Имя и фамилия на табличке`}
									variant='outlined'
									value={moreInfo.labelName}
									fullWidth
									required
									helperText={errors.labelName}
									error={!!errors.labelName}
									onChange={handleChangeLabel}
								/>
							</div>
						)} */}
						<div className={'place-from'}>
							<TextField
								label={`Номер телефона для связи с водителем`}
								variant='outlined'
								value={moreInfo.clientPhone}
								fullWidth
								required
								helperText={errors.clientPhone}
								error={!!errors.clientPhone}
								onChange={handleChangePhone}
							/>
						</div>
					</div>
					{/* <div style={{width: '60%'}}>
						<div className={'place-to'}>
							<TextField
								label={`Адрес, куда нужно заехать`}
								variant='outlined'
								defaultValue={draft?.draftBody?.transfers[0]?.services?.extraStopComment}
								value={moreInfo.extraStopComment}
								fullWidth
								onChange={(e) => actions.setExtraStopComment(e.target.value)}
								helperText={'Остановку можно сделать в пределах 10 км от основного маршрута. Общее время на заезд и остановку — до 30 минут.'}
							/>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default MoreInformation
