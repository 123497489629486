import { Row } from './index'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Icon = styled.div`
${({ open }) => {
        return `
    ${open ? `` : `transform: rotate(180deg); `}
    transition: 200ms;
    `
    }}	
`

function HeaderContainer({ children, isOpen, onOpen }) {

    const handleClick = () => {
        onOpen(!isOpen)
    }

    return <Row onClick={handleClick} style={{ cursor: 'pointer' }}>
        <div>
            {children}
        </div>
        <Icon

            open={isOpen}
        >
            <ExpandMoreIcon
                style={{ transform: 'rotate(180deg)' }}
                isActive={isOpen}
            />
        </Icon>
    </Row>
}


export default HeaderContainer