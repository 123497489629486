import React from "react"
import styled from "styled-components"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 60%;
    max-width: 60%;
    /* height: 550px; */
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 20px;
`

const Heading = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #3c3c3c;
`

const BlueBlock = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-top: 25px;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }
`

const BlueBlockText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const Ul = styled.ul`
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-inline-start: 20px;
  }
`

const Li = styled.li``

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const AgentBalancePopUp = ({ setBalancePopUp, balancePopUpOpen }) => {
  return (
    <StyledPopup onClose={() => setBalancePopUp(false)} open={balancePopUpOpen}>
      <CloseButton
        aria-label="close"
        onClick={() => setBalancePopUp(false)}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Heading>Как пользоваться балансом агента?</Heading>
        <Text>
          В блоке "Финансовая информация по авиации" детально отображается
          информация по балансу агента. Для оплаты заявки вы можете использовать
          остаток по кредитному лимиту и по депозиту.
        </Text>
        <Text>
          Для корректной работы и оплаты заявок контролируйте свою
          задолженность, остаток и переплаты
        </Text>
        <BlueBlock>
          <Ul>
            <Li>
              <BlueBlockText>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Остаток по кредитному лимиту
                </span>{" "}
                - это остаток кредитных средств, которые доступны для оплаты
                заявок.{" "}
              </BlueBlockText>
            </Li>
            <Li style={{ marginTop: 20 }}>
              <BlueBlockText>
                <span style={{ fontWeight: "bold" }}> Сумма кредита </span>- это
                установленная сумма кредита.
              </BlueBlockText>
            </Li>
            <Li style={{ marginTop: 20 }}>
              <BlueBlockText>
                <span style={{ fontWeight: "bold" }}>Остаток по депозиту</span>{" "}
                - это сумма средств на депозите, которую Вы можете использовать
                для оплаты заявок.
              </BlueBlockText>
            </Li>
            <Li style={{ marginTop: 20 }}>
              <BlueBlockText>
                <span style={{ fontWeight: "bold" }}> Сумма переплаты </span>-
                это общая сумма переплат по всем заявкам, чтобы воспользоваться
                этими деньгами Вам нужно оформить взаимозачет.
              </BlueBlockText>
            </Li>
            <Li style={{ marginTop: 20 }}>
              <BlueBlockText>
                <span style={{ fontWeight: "bold" }}>
                  Общая задолженность компании{" "}
                </span>{" "}
                - это сумма неоплаченныех заявок, в том числе и заявки
                оплаченные по кредиту. Вы самостоятельно отслеживаете данную
                задолженность.
              </BlueBlockText>
            </Li>
          </Ul>
        </BlueBlock>
      </Container>
    </StyledPopup>
  )
}

export default AgentBalancePopUp
