import React, { forwardRef } from "react"
import DatePickerBase from "react-datepicker"
import styled from "styled-components"
import Input from "./Input"
import Head from "./Head"
import infoIcon from '../../../../asset/images/avia/shared/info-icon.svg';
import calendarSrc from '../../../../asset/images/avia/ui/calendar.png'

const Wrapper = styled.div`
  font-family: Open Sans;
  position: relative;

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & .tui-calendare {
    z-index: 100;

    & .react-datepicker {
      &__header {
        padding: 0;
        border: none;
        background: #ffffff;
      }

      &__day-names {
        display: none;
      }
    }
  }

  & .react-datepicker__month-text {
    padding: 8px 4px;
  }
`

const Label = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 4px;
  cursor: pointer;
`

const ErrorText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #e73c3e;
  position: absolute;
  white-space: nowrap;
`

const InfoContainer = styled.div`
  position: absolute;
  min-width: 180px;
  background: #edf1fe;
  border-radius: 4px;
  padding: 15px;
  bottom: 25px;
  left: -85px;
`

const InfoText = styled.div`
  font-size: 14px;
  line-height: 14px;

  color: #3c3c3c;
`

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const InfoIconContainer = styled.div`
  position: relative;
  margin-left: 5px;
  margin-bottom: 4px;
`

const Icon = styled.img.attrs({src: calendarSrc})`
  position: absolute;
  right: 8;
  top: 31px;
`

const DatePicker = ({
  label,
  error,
  onChange,
  value,
  placeholder,
  className,
  isBirthDate,
  isOnlyMonth,
  customInput,
  isInfo,
  selectClose,
  icon,
  dataCy,
}) => {
  const [showInfo, setShowInfo] = React.useState("0")

  const ExampleCustomInput =
    //@ts-ignore
    forwardRef(({ value, onClick, onChange }, ref) => {
      return (
        <Input
          data-cy={dataCy}
          ref={ref}
          className="example-custom-input"
          onClick={onClick}
          value={value}
          onChange={onChange}
          error={error}
          customInput={customInput}
        />
      )
    })

  const handleChange = date => {
    onChange(date);
  }

  return (
    <Wrapper className={className}>
      <Row>
        {label && <Label>{label}</Label>}
        {isInfo && (
          <InfoIconContainer>
            {showInfo !== "0" && (
              <InfoContainer>
                <InfoText>
                  {showInfo === "1" &&
                    "Укажите срок действия, если он есть в документе. Если его нет, оставьте поле пустым."}
                </InfoText>
              </InfoContainer>
            )}
            <InfoIcon
              onMouseEnter={() => setShowInfo("1")}
              onMouseLeave={() => setShowInfo("0")}
            />
          </InfoIconContainer>
        )}
      </Row>
      <DatePickerBase
        showMonthYearPicker={!!isOnlyMonth}
        placeholderText={placeholder}
        dateFormat={!isOnlyMonth ? "dd.MM.yyyy" : "MM.yyyy"}
        selected={value}
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        onChange={handleChange}
        className="tui-calendare-wrapper"
        renderCustomHeader={Head}
        customInput={<ExampleCustomInput/>}
        popperClassName="tui-calendare"
      />
      {icon && <Icon/>}
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  )
}

export default DatePicker
