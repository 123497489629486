import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import { groupBy } from 'lodash'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useMessagesStore = create(
	devtools((set, get) => ({
		error: false,
		loading: false,
		messages: [],
		unreadMessages: [],
		departments: [],
		typesByDepartments: [],
		newMessage: {
			claimId: '',
			department: '',
			type: '',
			text: '',
			// attachments: [],
		},
		files: null,
		actions: {	
			getMessages: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/messages`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass,
							IsRead: false,
							// ClaimId: 7749359
						}
					})
	
					const res2 = await axios({
						url: `${BASE_URL}/messages`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass,
							IsRead: true,
							// ClaimId: 7749359
						}
					})
					set({ unreadMessages: res.data.filter(el => el.isIncoming) })
					set({ messages: [...res.data, ...res2.data]})
				} catch (e) {
					console.error('error in getMessages', e?.response?.data)
				}
			},
			getTypes: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/messages/types`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass,
						}
					})
					// set({ messages: res.data })
					console.log('getTypes', res.data);
				} catch (e) {
					console.error('error in getTypes', e?.response?.data)
				}
			},
			getDepartments: async (claimId) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const response = await axios({
						url: `${BASE_URL}/messages/categoies`,
						method: 'GET',
						params: {
							PartpassId: partpass,
							ClaimdId: claimId,
						},
						headers: {
							Authorization: `Bearer ${token}`
						}
					})

					const messageTypes = groupBy(response.data, "depratmentId")
					const departments = response.data.reduce((acc, item) => {
						if (acc?.find(i => i?.id === item.depratmentId)) {
						  return acc; 
						}
						return [...acc, {name: item.departmentName, id: item.depratmentId}];
					  }, []);

					set({ typesByDepartments: messageTypes })
					set({ departments: departments })
				} catch (e) {
					console.error('error in getDepartments', e?.response?.data)
				}
			},
			setNewMessageParams: (key, value) => {
				set({
					newMessage: {
						...get().newMessage,
						[key]: value
					}
				})
				if (key === 'department') {
					set({
						newMessage: {
							...get().newMessage,
							type: ''
						}
					})
				}
			},
			clearMessage: () => {
				set({
					newMessage: {
						claimId: '',
						department: '',
						type: '',
						text: '',
						// attachments: [],
					}
				})
			},
			markread: async (messageId) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/messages/markread`,
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						data: {
							partpassId: partpass,
							messageIds: [
								messageId
							]
						},
					})
					if (res.status === 200){
						get().actions.getMessages()
					}  
				} catch (e) {
					console.error('error in markread', e?.response?.data)
				}
			},
			getFileId: async (messageId) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/messages/${messageId}/files`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass,
						}
					})

					set({files: res.data})
				} catch (e) {
					console.error('error in getFileId', e?.response?.data)
				}
			},
			getFile: async (messageId, fileId, flName) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/messages/${messageId}/files/${fileId}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru',
							'accept': '*/*',
						},
						responseType: 'blob',
						params: {
							PartpassId: partpass,
						}
					})
					if (res.status !== 200){
						return  res.status
					}  

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					let fileName = flName || "downloaded_file.docx"; // значение по умолчанию
					const contentDisposition = res.headers["content-disposition"];
	
					if (contentDisposition) {
					  const fileNameRegex = /filename\*?=['"]?([^;"']*)['"]?;?filename=['"]?([^;"']*)['"]?/;
					  const matches = fileNameRegex.exec(contentDisposition);
					  if (matches != null) {
						const encodedFileName = matches[1] || matches[2];
						fileName = decodeURI(encodedFileName);
					  }
					}
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
					link.remove();
		 
				} catch (e) {
					console.error('error in getFile', e?.response?.data)
				}
			},
			clearFiles: () => {
				set({files: null})
			},
			postMessage: async (files) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				// const files = get().newMessage.attachments
				set({ loading: true })
				set({ error: false })


				const formData = new FormData()
				formData.append('Body', get().newMessage.text)
				formData.append('ClaimId', get().newMessage.claimId)
				// formData.append('ParentMessageId', '')
				formData.append('TypeId', get().newMessage.type)
				// formData.append('Attachments', get().newMessage.attachments)
				formData.append('UserCode', '327')

				for (let i = 0; i < files.length; i++) {
					formData.append('Attachments', files[i])
				}

				try {
					const res = await axios({
						url: `${BASE_URL}/messages`,
						method: 'POST',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${token}`
						},
						params: {
							PartpassId: partpass,
						}
					})
					if (res.status === 200) {
						set({ error: res.status })
					}
				} catch (e) {
					console.log(e, 'e')
					set({ error: e?.message })
				} finally {
					set({ loading: false })
				}
			},
		}
	}))
)

export default useMessagesStore
