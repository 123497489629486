import React from 'react';
import styled from 'styled-components';
import {Text,Point} from '../../../../ui';
import Place from '../../Place';
import { useMediaQuery } from 'react-responsive';
import flightNumberIcon from '../../../../../asset/images/avia/search-result/flightNumberIcon.svg'
import formatDuration from '../../../../../functions/formatDuration';
import getAirlineLogo from '../../../../../functions/getAirlineLogo';
import quantityCaseFormat from '../../../../../functions/quantityCaseFormat';
import Tippy from '@tippyjs/react/headless';

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 9px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ItemContainer = styled.li`
  list-style-type: none;
  position: relative;
  font-family: "Open Sans";
`;

const FlightTime = styled.span`
  display: inline;
  font-size: 12px;
  line-height: 16px;
  color: ${({ blue }) => (blue ? '#4872F2' : '#737373')};

  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;
  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;
  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const PlaceTimeWrapper = styled.div`
  display: flex;
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > :last-child {
    text-align: right;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const FlightNumberIcon = styled.img.attrs({ src: flightNumberIcon })`
  margin-right: 7px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const TippyWrapper = styled.div`
  padding: 14px;
  padding-bottom: 13px;
  background: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 11px 0px;
  width: 202px;
  cursor: default;
`;

const TippyText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`;

const MainSegment = ({
  from,
  to,
  fromDate,
  toDate,
  flightNumbers,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const travelTime = formatDuration(props.duration);
  const stopString =
    props.stops.length > 0
      ? `${props.stops.length} ${quantityCaseFormat(
          props.stops.length,
          'пересадка',
          'пересадки',
          'пересадок'
        )}`
      : 'прямой';

  return (
    <div>
      <ItemContainer>
        <PlaceTimeWrapper>
          <Time color="#3C3C3C" bold>
            {fromDate.format('HH:mm')}
          </Time>
          <ProgressInfo>
            {/* <Text size="small" color="dark-gray">
              В пути: {travelTime}
            </Text> */}
            <ProgressLine>
              <PointsContainer>
                <Point />
                {!isMobile &&
                  props.stops.map((x, key) => (
                    <Tippy
                      key={key}
                      trigger='click'
                      render={(attrs) => (
                        <TippyWrapper {...attrs}>
                          <TippyText>
                            Пересадка {formatDuration(
                              x.duration
                            )}, аэропорт <strong>{x.city.airport}, {x.city.city}</strong>
                          </TippyText>
                        </TippyWrapper>
                      )}
                    >
                      <div>
                        <Point style={{cursor: 'pointer'}} key={key} upperText={x.city.code} />
                      </div>
                    </Tippy>
                  ))}
                {isMobile && props.stops.length > 0 && (
                  <Point
                    mobileProps={{
                      duration: props.duration,
                      stopDuration: props.stops[0].duration,
                      stopCount: props.stops.length,
                      city: {
                        name: props.stops[0].city.city,
                        code: props.stops[0].city.code,
                      },
                      airport: {
                        name: props.stops[0].city.airport,
                        code: props.stops[0].city.code,
                      },
                    }}
                  />
                )}
                {isMobile && props.stops.length === 0 && (
                  <PathTime>{formatDuration(props.duration)}</PathTime>
                )}
                <Point />
              </PointsContainer>
            </ProgressLine>
          </ProgressInfo>
          <Time color="#3C3C3C" bold>
            {toDate.format('HH:mm')}
          </Time>
        </PlaceTimeWrapper>
        <PlaceDateWrapper>
          <Place {...from} date={fromDate} />
          <FlightTime>
            В пути: {travelTime},{' '}
            <FlightTime blue={props.stops.length > 0}>{stopString}</FlightTime>{' '}
          </FlightTime>
          <Place {...to} date={toDate} />
        </PlaceDateWrapper>
      </ItemContainer>
      {props.airlines.map((x, key) => (
        <CompanyLogoMobile key={key}>
          <Image
            src={getAirlineLogo(x.operatingAirlineCode, {width: 65,height: 65})}
            alt={x.operatingAirlineName}
          />{' '}
          <LogoDescription>{x.operatingAirlineName}</LogoDescription>
        </CompanyLogoMobile>
      ))}
      <Row>
        {' '}
        <FlightNumberIcon />
        <span style={{ color: '#737373', fontSize: 14 }}>Рейс:&nbsp;</span>
        <span style={{ color: '#3C3C3C', fontSize: 14, fontWeight: 600 }}>
          {flightNumbers.toString()}
        </span>
      </Row>
    </div>
  );
};

export default MainSegment;
