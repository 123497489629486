/**
 * Функция для создания урл на картинку логотипа авиакомпании
 *
 * @param {string} code Код авиакомпании
 * @param params дополниткельные параметры
 * @param {number} [params.width=64] ширина картинки (по умолчанию 64)
 * @param {number} [params.height=64] высота картинки (по умолчанию 64)
 * @param {string} [params.styleType=squar] тип картинки (squar | normal) по умолчанию squar
 * @return {string} word with correct ending
 */
export default function getAirlineLogo (code,params = {}) {
    if(typeof params.width === 'undefined') {
        params.width = 64;
    }
    if(typeof params.height === 'undefined') {
        params.height = 64;
    }
    if(typeof params.styleType === 'undefined') {
        params.styleType = 'squar';
    }

    return `http://mpics.avs.io/${params.styleType === 'squar' ? 'al_square/' : ''}${params.width}/${params.height}/${code}.png`;
}