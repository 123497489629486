import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { Close } from '@material-ui/icons'
import { SubscribeButton } from './SubscribeBlock'
import { Box, SwipeableDrawer } from '@material-ui/core'
import '../style.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(','),
		color: '#2e2e32',
		padding: theme.spacing(2)
	},
	'& .MuiDialogTitle-root': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(','),
		fontSize: '18px',
		color: '#2e2e32',
		fontWeight: 700
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
		width: '120px',
		margin: '0 auto'
	}
}))

export default function SuccessPopup({ handleClose, open }) {
	return (
		<div>
			<BootstrapDialog
				maxWidth={'xs'}
				PaperProps={{
					style: { borderRadius: 16, padding: '8px' }
				}}
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}>
				<DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
					<div style={{ marginRight: '5px' }}>
						Спасибо, что подписались на нашу рассылку!
					</div>
					<IconButton aria-label='close' onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					Мы отправили письмо со ссылкой для подтверждения email на вашу почту
				</DialogContent>
				<DialogActions onClick={handleClose}>
					<SubscribeButton>Отлично</SubscribeButton>
				</DialogActions>
			</BootstrapDialog>
		</div>
	)
}

export function SuccessDrawer({ state, toggleDrawer }) {
	return (
		<div>
			<SwipeableDrawer
				disablePortal
				anchor={'bottom'}
				open={state.bottom}
				onClose={toggleDrawer('bottom', false)}
				onOpen={toggleDrawer('bottom', true)}>
				<Box sx={{ maxWidth: '420px' }}>
					<Box sx={{ p: 2, pb: 1 }} className='footer-drawer'>
						<div className='footer-drawer-title'>
							Спасибо, что подписались на нашу рассылку!
						</div>
						<div>
							<IconButton
								aria-label='close'
								onClick={toggleDrawer('bottom', false)}>
								<Close />
							</IconButton>
						</div>
					</Box>
					<Box style={{ color: '#2e2e32' }} sx={{ p: 2, pb: 1, pt: 1 }}>
						Мы отправили письмо со ссылкой для подтверждения email на вашу почту
					</Box>
					<Box
						sx={{ p: 2, pt: 1 }}
						className='footer-drawer-actions'
						onClick={toggleDrawer('bottom', false)}>
						<SubscribeButton>Отлично</SubscribeButton>
					</Box>
				</Box>
			</SwipeableDrawer>
		</div>
	)
}
