import React from "react"
import { NotificationsProvider } from "./useNotification"
import Queue from "./Queue"
import {transformProps,isNotificationValid} from './utils'

export default function Notification(props) {
  if(isNotificationValid(props)) {
    return <Queue {...transformProps(props)}/>
  }
  
  return null;
}

