import { Placemark, useYMaps } from '@pbe/react-yandex-maps'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import '../style.css'
import { useLatest } from './use-latest'
import { ReactComponent as Check } from '../../../../../asset/icons/check-circle.svg'
import { ReactComponent as Close } from '../../../../../asset/icons/close.svg'
import { ReactComponent as Exclamation } from '../../../../../asset/icons/exclamation-circle.svg'
import { Link } from 'react-router-dom'
import useYandexMapStore from '../store'

const PortalBalloon = ({ children, getHTMLElementId }) => {
	const mount = document.getElementById(getHTMLElementId)
	const el = document.createElement('div')

	useEffect(() => {
		if (mount) mount.appendChild(el)
		return () => {
			if (mount) mount.removeChild(el)
		}
	}, [el, mount])

	if (!mount) return null

	return createPortal(children, el)
}

const BASE_IMAGE_URL =
	process.env.REACT_APP_FS_IMAGE_URL + 'geocontent/static/Country'

function useOutsideClick(elementRef, handler, attached = true) {
	const latestHandler = useLatest(handler)

	useEffect(() => {
		if (!attached) return

		const handleClick = e => {
			if (!elementRef.current) return

			if (!elementRef.current.contains(e.target)) {
				latestHandler.current()
			}
		}

		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	}, [elementRef, latestHandler, attached])
}

export default function CustomPlacemark({ item, map, closeCurrentBalloon }) {
	const ymaps = useYMaps()
	const imageUrl = `${BASE_IMAGE_URL}/${item.id}/${item.countryFlagFileName}`
	const mainPhoto = useMemo(() => {
		if (!item) return
		const source = item?.images?.find(item => item.resolutionType === 'Source')
		return `${BASE_IMAGE_URL}/${item.id}/${source?.imageType}-${source?.resolutionType}-${source?.fileName}`
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const actions = useYandexMapStore(({ actions }) => actions)

	const [activePortal, setActivePortal] = useState(false)

	const onClose = () => {
		setActivePortal(false)
	}
	const balloonRef = useRef(null)
	useOutsideClick(balloonRef, onClose, activePortal)

	const activeCountry = useYandexMapStore(({ activeCountry }) => activeCountry)
	const placemarkRef = useRef(null)

	useEffect(() => {
		if (activeCountry?.id === item?.id && !activePortal) {
			actions.setZoom(item)
			const clearBaloon = setTimeout(() => {
				placemarkRef?.current?.balloon.open()
			}, 20)
			const clearPortal = setTimeout(() => {
				setActivePortal(true)
			}, 30)

			return () => {
				clearTimeout(clearPortal)
				clearTimeout(clearBaloon)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCountry])
	return (
		<>
			<Placemark
				instanceRef={placemarkRef}
				onClick={() => {
					// if (activePortal) {
						closeCurrentBalloon()
						setActivePortal(false)
						// return
					// }
					// ставим в очередь промисов, чтобы сработало после отрисовки балуна
					setTimeout(() => {
						setActivePortal(true)
						map.current.panTo([
							Number(item?.latitude?.split(',').join('.')),
							Number(item?.longitude?.split(',').join('.'))
						])
					}, 20)
				}}
				properties={{
					hintContent: item.name,
					balloonContent: `<div id=${item.id} class="card-country"></div>`
				}}
				geometry={[
					Number(item?.latitude?.split(',').join('.')),
					Number(item?.longitude?.split(',').join('.'))
				]}
				options={{
					//<div class="marker-image" style="background-image:url(${BASE_IMAGE_URL}/${item.id}/${item.images[0]?.imageType}-${item.images[0]?.resolutionType}-${item.images[0]?.fileName})"></div>
					hideIconOnBalloonOpen: false,
					iconLayout: ymaps?.templateLayoutFactory.createClass(
						`<div class="marker">
							<div class="marker-image" style="background-image:url(${mainPhoto})"></div>
							<div class="marker-flag" style="background-image:url(${imageUrl})"></div>
            </div>
            `
					),
					iconShape: {
						type: 'Circle',
						coordinates: [30, 30],
						radius: 30
					}
				}}
			/>
			{activePortal && (
				<PortalBalloon getHTMLElementId={item.id}>
					<Link to={`/${item.urlName}`}>
						<div className='window' ref={balloonRef}>
							<div
								className='window-image'
								style={{
									backgroundImage: `url(
										${mainPhoto})`
								}}></div>
							<div className='window-info'>
								<div
									className='window-info-flag'
									style={{
										backgroundImage: `url(${imageUrl})`
									}}></div>
								<div
									className='country-name'
									style={{ fontSize: item.name.length > 20 ? '13px' : '16px' }}>
									{item.name}
								</div>
								<div className='country-data'>
									<div className='open__close'>
										{item?.isCovidAvailable ? (
											<>
												<Check className='v-icon-open' />
												<span>Открыто</span>
											</>
										) : (
											<>
												<Close className='v-icon-open' />
												<span>Закрыто</span>
											</>
										)}
									</div>
									<div className='visa__yes__no'>
										{item?.isVisaRequired ? (
											<>
												<Exclamation className='v-icon-open' />
												<span>Нужна виза</span>
											</>
										) : (
											<>
												<Check className='v-icon-open' />
												<span>Виза не нужна</span>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</Link>
				</PortalBalloon>
			)}
		</>
	)
}
