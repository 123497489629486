import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {decrypt} from "../../functions/decrypt";
import useAuthStore from "./store";

const AuthCrm = () => {

	const actions = useAuthStore(({ actions }) => actions)

	const location = useLocation()

	useEffect(() => {
		let authData = decrypt(location.search.replace('?auth=',''))
		if (authData) {
			authData = JSON.parse(authData)
			actions.signin(authData.login, authData.password)
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className={'container'}>

		</div>
	);
};

export default AuthCrm;