import { useState } from 'react'
import MembersBlock from '../MembersBlock'
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Alert, Stack } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import useBookingStore from '../../../../store'

function BabyBlock({ babyId, freeInfantChecked }) {
    const peoples = useBookingStore(({ peoples }) => peoples);
    const selectedServices = useBookingStore(({ selectedServices }) => selectedServices)
    const actions = useBookingStore(({ actions }) => actions)
    const [baby, setBaby] = useState(peoples.find((people) => people.key === babyId))
    const [open, setOpen] = useState(false)
    const [notifications, setNotification] = useState([])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const closeNotification = (id) => {
        setNotification(prev => prev.filter(i => i.targetId !== id))
    }

    const getNotification = () => {
        const notification = []
        selectedServices?.forEach(item => {
            const notify = {
                targetType: "service",
                targetStatus: "Added",
                targetId: item.uid,
                targetName: item.name,
                description: `Младенец прекреплен к услуге ${item.name}`
            }
            notification.push(notify)
        })

        return notification
    }

    const removeNotification = () => {
        const notification = []
        selectedServices?.forEach(item => {
            const notify = {
                targetType: "service",
                targetStatus: "Removed",
                targetId: item.uid,
                targetName: item.name,
                description: `Младенец откреплен от услуги ${item.name}`
            }
            notification.push(notify)
        })

        return notification
    }


    const showModal = () => {
        if (!baby) {
            const newPeople = {
                key: peoples.length + 1,
                age: 3,
                nationality: peoples[0].nationality,
            }
            actions.addBaby(newPeople)
            setNotification(getNotification())
            setBaby(newPeople)
            setOpen(true)
            return
        }
        setNotification(removeNotification())
        actions.removeBaby(baby?.key)
        setBaby(null)
        setOpen(true)
    }

    return <>
        <div>
            <FormControlLabel
                label={'Дополнительно: младенец'}
                control={
                    <Checkbox
                        checked={freeInfantChecked || false}
                        onChange={showModal}
                    />
                }
            />
        </div>
        {baby && <div>
            <MembersBlock title={'Младенец'} peopleKey={baby?.key} />
        </div>}
        <Snackbar
            open={open}
            autoHideDuration={16000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Stack flexDirection='column' gap={1}>
                {notifications.map(item => {
                    return (
                        <Alert
                            onClose={() => closeNotification(item.targetId)}
                            severity={item.targetStatus === 'Removed' ? 'error' : 'success'}
                            sx={{ width: '300px' }}>
                            {item.description}
                        </Alert>
                    )
                })}
            </Stack>
        </Snackbar>
    </>
}

export default BabyBlock