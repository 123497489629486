//eslint-disable-next-line
import styled from 'styled-components';

const Span = styled.span`
  ${({ ...props }) => {
    const weight = getFontWeight(props.bold, props.fontWeight);
    const size = getSize(props.size);
    const lineHeight = getLineHeight(props.size);
    const color = getColor(props.color);
    return `
            font-family: "Open Sans";
            font-style: normal;
            font-weight: ${weight};
            font-size: ${size};
            line-height: ${lineHeight};
            color: ${color};
        `;
  }}
`;

function getSize(size) {
  switch (size) {
    case 'small':
      return '12px';
    case 'big':
      return '16px';
    case undefined:
    case 'normal':
      return '14px';
    default:
      return `${size}px`;
  }
}
function getLineHeight(size) {
  switch (size) {
    case 'small':
      return '16px';
    case 'big':
      return '20px';
    case undefined:
    case 'normal':
      return '18px';
    default:
      return `${size + 4}px`;
  }
}

function getColor( color) {
  switch (color) {
    case 'gray':
      return '#B9B9B9';
    case 'blue':
      return '#4872F2';
    case 'dark-gray':
      return '#737373';
    case 'black':
    case undefined: {
      return '#3C3C3C';
    }
    default:
      return color;
  }
}

function getFontWeight(bold, weight) {
  if (bold) return 'bold';
  if (weight) return weight;
  return 'normal';
}
export default Span;
