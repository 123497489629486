import { format } from 'date-fns'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../../services/api'
import { isArray } from 'lodash'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useFranchisingStore = create(
	devtools((set, get) => ({
		franchising: null,
		loading: false,
		news: null,
		actualNews: null,
		comission: null,
		actions: {
			getFranchising: async () => {
				set({ loading: true })
				try {
					const res = await geoApiInstance().get('/franchising')
					let data = res?.data

					if (!isArray(data)) {
						data = Object.values(data)
					}

					set({ franchising: data })
					const news = data?.find(item => item.block === 'news') || null
					set({ news: news })
					const filteredNews = news.content.filter(
						item =>
							item?.published &&
							item?.show_from <= format(new Date(), 'yyyy-MM-dd') &&
							(!item?.show_to
								? !item?.show_to
								: item?.show_to >= format(new Date(), 'yyyy-MM-dd'))
					)
					const actualNews = []
					const NEWS_PER_PAGE = 3
					for (let i = 0; i < filteredNews.length; i += NEWS_PER_PAGE) {
						actualNews.push(filteredNews.slice(i, i + NEWS_PER_PAGE))
					}
					set({ actualNews: actualNews })

					// console.log(actualNews, 'actualNews')
					// console.log(res.data, 'res.datafranchising');
				} catch (e) {
					console.error('error in getFranchising', e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getComission: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/agents?PartpassId=${partpass}`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					set({ comission: res.data.groups })
				} catch (e) {
					console.error('error in getComission', e?.response?.data)
				}
			},
		}
	}))
)

export default useFranchisingStore
