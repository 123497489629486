import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Link from '@mui/material/Link'
import formatPrice from '../../../../functions/formatPrice'
import moment from 'moment';
import { useNavigate } from 'react-router';

const StyledTableCell = styled(TableCell)(({lastItem}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FAFAFA',
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      height: 59,
      padding: '12px 17px',
      verticalAlign: 'center',
      borderBottom: '1px solid #00000008',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({

}));

const StyledLink = styled(Link)(()=>({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
}))


const StyledPagination = styled(TablePagination)(()=>({
  backgroundColor: '#FAFAFA',
  borderBottom: 'none',
  fontFamily: 'Open Sans',
  fontWeight: 600,
}));


export default function CustomizedTables(props) {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.items.length) : 0;

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLink = (e) => {
    e.preventDefault()
    navigate(e.target.dataset.href)
  } 

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="Список переплаченных заявок">
        <TableHead>
          <TableRow>
            <StyledTableCell>Дата заявки</StyledTableCell>
            <StyledTableCell>Номер заявки</StyledTableCell>
            <StyledTableCell>Сумма заявки</StyledTableCell>
            <StyledTableCell>Сумма переплаты</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? props.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.items).map((x,key,arr) => (
            <StyledTableRow key={x.id}>
              <StyledTableCell sx={{width: 178}}>
                {formatDate(x?.orderDate,"DD.MM.YYYY HH:mm")}
              </StyledTableCell>
              <StyledTableCell>
                <StyledLink 
                  data-href={`/cabinet/orders/${formatOrderNumber(x.orderNumber)}`} 
                  href={`/cabinet/orders/${formatOrderNumber(x.orderNumber)}`}
                  onClick={handleLink}
                >
                  {formatOrderNumber(x.orderNumber)}
                </StyledLink>
              </StyledTableCell>
              <StyledTableCell> {formatPrice(x?.orderSum || 0)}</StyledTableCell>
              <StyledTableCell> {formatPrice(x?.orderOverpayment || 0)}</StyledTableCell>
            </StyledTableRow>
          ))}
           {emptyRows > 0 && (
            <TableRow style={{ height: 59 * emptyRows }}>
              <TableCell colSpan={6} sx={{borderBottom: 'none',padding: 0}}/>
            </TableRow>
          )}
        </TableBody>
        {props.items.length > 4 && (<TableFooter>
          <TableRow>
            <StyledPagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
              colSpan={4}
              count={props.items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage='Количество строк'
              labelDisplayedRows={(values)=>{
                return `${values.from}–${values.to} из ${values.count}`
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>)}
      </Table>
    </TableContainer>
  );
}



function formatDate(value,format) {
  if(!value) {
    return 'отсутствует';
  }
  return moment(value).format(format);
}

function formatOrderNumber(str) {
  return str.replace("TTE", "");
}