import { TextField } from '@mui/material'
import styled from 'styled-components'

export const FormInput = styled(TextField)(() => ({
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE',
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: "'Open Sans', 'sans-serif'",
	},
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.75)',
	},
	'& label': {
		fontFamily: "'Open Sans', 'sans-serif'",
		color: '#7E8389',
	},
}))