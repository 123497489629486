import { useState } from "react";
import WorkTime from "../WorkTime";
import { isEmpty, sortBy } from "lodash";
import MenuTab from "./menuTab";
import VisaCalculator from "../VisaCalculator";
import useVisaStore from "../../store";
import "./style.scss";
import Spoiler from "../Spoiler";
import { isMobile } from "react-device-detect";

const countriesByExp = [
    'Индонезия',
    'Танзания',
    'Австрия',
    'Сейшелы',
    'Узбекистан',
    'Франция',
    'Хорватия',
    'Словения',
    'Чехия',
    'Финляндия'
]


function CountryBlock({ content, showCalculator, available }) {
    const [activeTab, setTab] = useState(content.t1);
    const [rightContent, setRightContent] = useState(null);
    const countriesList = useVisaStore(({ countriesList }) => countriesList);

    const filteredCountries = sortBy(countriesList?.filter((country) =>
        available.includes(country?.urlName?.split("/")[2]) && !countriesByExp.includes(country?.name)
    ), 'name')

    const ImageBlock = () => {
        return (
            <div className="image-block">
                <picture>
                    <source
                        srcSet={activeTab?.content?.image_mobile}
                        media="(max-width: 576px)"
                    />
                    <img src={activeTab?.content?.image} alt="" />
                </picture>
            </div>
        );
    };

    const showLeftContent =
        !isEmpty(activeTab.content.menu) ||
        (showCalculator && activeTab.title === content.t1.title) ||
        isMobile;

    return (
        <div className="visaTabs">
            <div className="tabs-head">
                <div
                    className={
                        activeTab.title === content.t1.title
                            ? "tabs-item active"
                            : "tabs-item"
                    }
                    onClick={() => setTab(content.t1)}
                >
                    {content.t1.title}
                </div>
                <div
                    className={
                        activeTab.title === content.t2.title
                            ? "tabs-item active"
                            : "tabs-item"
                    }
                    onClick={() => setTab(content.t2)}
                >
                    {content.t2.title}
                </div>
            </div>
            <div className={showLeftContent ? "content" : "content no-left-block"}>
                {showLeftContent && (
                    <div className="content-left">
                        {!isEmpty(activeTab.content.menu) && (
                            <MenuTab
                                changeContent={setRightContent}
                                content={activeTab.content.menu}
                            />
                        )}
                        {showCalculator && activeTab.title === content.t1.title && (
                            <VisaCalculator countries={filteredCountries} />
                        )}
                        <div className="mobile-view">
                            {activeTab?.content?.workTime && (
                                <div>
                                    <WorkTime content={activeTab.content.workTime} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!rightContent && !isMobile ? (
                    <div className="content-right">
                        {activeTab?.content?.image && <ImageBlock />}
                        {activeTab?.content?.workTime && (
                            <div className="desktop-view">
                                <WorkTime content={activeTab.content.workTime} />
                            </div>
                        )}
                        {activeTab?.content.message && (
                            <div
                                dangerouslySetInnerHTML={{ __html: activeTab?.content.message }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="content-right">
                        {isMobile && activeTab?.content?.image && <ImageBlock />}
                        {activeTab?.content.message && (
                            <div
                                className="mobile-view"
                                dangerouslySetInnerHTML={{ __html: activeTab?.content.message }}
                            />
                        )}
                        <div className="content-view desktop-view">
                            <div
                                className="content-wrap "
                                dangerouslySetInnerHTML={{ __html: rightContent }}
                            />
                        </div>
                    </div>
                )}
                <div className="content-main">
                    {activeTab?.content?.address && (
                        <div>
                            <h3 className="address-title">
                                {" "}
                                Контакты и График работы Региональных представительств
                            </h3>
                            <div className="address-block">
                                {activeTab?.content?.address.map((item) => (
                                    <div className="address-item">
                                        <h4>{item.city}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="accordion-menu">
                        {" "}
                        {activeTab?.content?.tabs?.map((item) => (
                            <Spoiler key={item.name} content={item} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CountryBlock;
