import styled from 'styled-components';

import { Button } from '../../../../../components/ui';

export const CollapseContainer = styled.div`
	border-radius: 16px;
	padding: 24px;
	background: rgba(246, 248, 250, 1);
    margin-bottom: 16px;
`

export const Text = styled.p`
${({ bold, size = 14, height = 24, color = 'rgba(46, 46, 50, 1)' }) => {
        return `font-size: ${size}px;
line-height: ${height}px;
font-weight: ${bold ? '600' : '400'};
padding: 0;
margin: 0;
color: ${color};
`
    }}	
`

export const Row = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

export const MButton = (props) => <Button style={{ padding: '8px 20px', fontSize: '14px', borderRadius: '8px', whiteSpace: 'nowrap' }} {...props} />
