import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const useMainMenuStore = create(
	devtools((set, get) => ({
		isActive: JSON.parse(localStorage.getItem('activeTab')),
		actions: {
			addActiveLink: id => {
				set({ isActive: id })
				localStorage.setItem('activeTab', JSON.stringify(get().isActive))
			}
		}
	}))
)

export default useMainMenuStore
