import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/pagination';
import {Autoplay, FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";

import './style.scss'
import {Link} from "react-router-dom";
import { isMobile } from "react-device-detect";

const StaticBannerCard = ({ content, title }) => {

	return (
		<div className='photo-tiles_slider-container static-banner-card-container'>
			{title && <h2>{title}</h2>}
			<Swiper
				spaceBetween={10}
				slidesPerView={'auto'}
				mousewheel={false}
				watchSlidesProgress={true}
				autoplay={false}
				loop={false}
				modules={[Autoplay, FreeMode, Navigation, Mousewheel, Pagination]}
				navigation={{
					nextEl: ".swiper-button-next-1.static-banner",
					prevEl: ".swiper-button-prev-1.static-banner",
				}}
				pagination={{
					clickable: true
				}}
				className="photo-tiles_slider"
				watchOverflow={true}
			>
				{content.map(item => <SwiperSlide key={item.guid}>
					<Link to={item.link} className={'photo-tiles_link'}>
						<div key={item.guid} className='photo-tiles_slide' style={{backgroundImage: `url(${item.img})`}} >
							<p className='photo-tiles_slide-title'>{item.head}</p>
							<p className='photo-tiles_slide-text'>{item.text}</p>
						</div>
					</Link>
				</SwiperSlide>)}
			</Swiper>
			<div className='swiper-pagination' />
			{!isMobile && <> 
				<div className='swiper-button-next-1 slide-btn-bg static-banner' />
			    <div className='swiper-button-prev-1 slide-btn-bg static-banner' /> 
			</>}		
		</div>
	)
}

export default StaticBannerCard;

