import React from 'react';

import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Container = styled.div`
	display: flex;
	flex-direction: column;
    padding-bottom: 24px;
`;

const CollapseContainer = styled(Container)`
background: rgba(246, 248, 250, 1);
border-radius: 16px;
padding: 24px;
`
const WarningContainer = styled(Container)`
    text-align: center;
    background: rgba(255, 252, 230, 1);
    border-radius: 16px;
    padding: 16px;
    margin-top: 24px;
`
const List = styled.ol`
    margin-top: 0px;
`

const Item = styled.li`
font-size: 16px;
line-height: 22px;
`

const Text = styled.p`
    font-size: 16px;
    line-height: 22px;
`;

const BoltText = styled.p`
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h3`
    font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`

const Link = styled.a`
font-size: 18px;
line-height: 24px;
font-weight: 400;
color: rgba(72, 114, 242, 1);
`



function ContractsInfo() {
    const [isOpen, toggleOpen] = React.useState(true);
    return (
        <div style={{ marginBottom: '24px' }}>
            <Container style={{ paddingTop: 0 }}>
                <Title style={{ margin: 0 }}>Информация для активации личного кабинета</Title>
                <Text style={{ margin: '24px 0px 8px 0px' }}>
                    ВАЖНО! ДЛЯ АКТИВАЦИИ ЛИЧНОГО КАБИНЕТА НЕОБХОДИМО ПРИСЛАТЬ СКАН ДОКУМЕНТОВ:
                </Text>

                <Text style={{ fontWeight: 600 }}>
                    Юридическим лицам:
                </Text>
                <List>
                    <Item>
                        Заявление о присоединении с подписью и печатью организации;
                    </Item>
                    <Item>
                        Копию раздела/страницы Устава о полномочиях исполнительного органа (Директора или Генерального директора или совета директоров), с заверительной надписью, которая должна содержать: надпись: «Копия верна.» / Должность/ наименование агента/ ФИО руководителя/ дата/ печать;
                    </Item>
                    <Item>
                        Доверенность на подписанта, в случае если руководитель не заключает договор самостоятельно, а уполномочил иное лицо (можно предоставить заверенную копию доверенности).
                    </Item>
                </List>

                <Text style={{ marginBottom: "6px", fontWeight: 600 }}>
                    Индивидуальным предпринимателям:
                </Text>
                <List>
                    <Item>
                        Заявление о присоединении с подписью и печатью организации;
                    </Item>
                    <Item>
                        Выписку из ЕГРИП;
                    </Item>
                    <Item>
                        Доверенность на подписанта, уполномоченного Индивидуальным предпринимателем (если договор подписывает не генеральный директор/индивидуальный предприниматель).
                    </Item>
                </List>
            </Container>
            <CollapseContainer>
                <Row
                    onClick={() => toggleOpen(!isOpen)}
                    style={{ cursor: 'pointer' }}
                >
                    <FlexRow>
                        <Title style={{ margin: 0 }}>При отправлении оригиналов документов в офисы Компании</Title>
                    </FlexRow>
                    <ExpandMoreIcon
                        onClick={() => toggleOpen(!isOpen)}
                        style={isOpen ? { transform: 'rotate(180deg)' } : null}
                        isActive={isOpen}
                    />
                </Row>
                <Collapse in={isOpen}>
                    <Text style={{ marginTop: "24px" }}>Юридическим лицам — необходимо приложить к подлинному заявлению о присоединении к агентскому договору:</Text>
                    <List>
                        <Item>Заявление о присоединении;</Item>
                        <Item>Доверенность на подписанта, в случае если руководитель не заключает договор самостоятельно, а уполномочил иное лицо (можно предоставить заверенную копию доверенности);</Item>
                        <Item>Выписку из ЕГРИП;</Item>
                        <Item>Решение/протокол о назначении руководителя (генерального директора);</Item>
                        <Item>Раздел (страницы) Устава о полномочиях руководителя исполнительного органа (Директора или Генерального директора или совета директоров).</Item>
                    </List>

                    <Text>Индивидуальным предпринимателям — необходимо приложить к подлинному заявлению о присоединении к агентскому договору:</Text>
                    <List>
                        <Item>Заявление о присоединении;</Item>
                        <Item>Доверенность на подписанта, уполномоченного Индивидуальным предпринимателем (если договор подписывает не генеральный директор);</Item>
                        <Item>Выписку из ЕГРЮЛ.</Item>
                    </List>

                    <WarningContainer>
                        <BoltText style={{ fontWeight: 600 }}>Внимание!</BoltText>
                        <BoltText>Копии документов должны быть заверены печатью турагента и подписью уполномоченного лица.
                            <br />Подробная информация на странице <Link href="https://newb2b.fstravel.com/agentskii-dogovor" target='blank'>Агентский договор</Link>
                        </BoltText>

                        <BoltText style={{ marginTop: "32px" }}>Если у Вас остались вопросы, то просим обращаться на почту: <Link href="mailto:dogovor@fstravel.com<">dogovor@fstravel.com</Link></BoltText>
                    </WarningContainer>
                </Collapse>
            </CollapseContainer>
        </div >
    )
}

export default ContractsInfo
