import { useState, useEffect } from 'react'
// import useHotelDescriptionStore from '../../store'

import './style.scss'

function SliderReview() {
    const [reviewContent, setReview] = useState(null)
    const [isReviewLoaded, setReviewLoaded] = useState(false)
    // const hotel = useHotelDescriptionStore(({ hotel }) => hotel)

    // function getReviewData() {
    //     const link = document.querySelector(".TH-widget-review-wrap__item");
    //     if (!link) {
    //         setReviewLoaded(false)
    //         return
    //     }
    //     const reviewGreen = document.querySelector(".TH-widget-review-wrap__item-ico--green");
    //     const item = reviewGreen?.parentNode?.parentNode;
    //     const reviewText = item?.querySelector(".js-small-txt p");
    //     const reviewName = item?.querySelector(".TH-widget-review-wrap__name");
    //     const reviewBodyText = "«" + reviewText?.innerHTML.slice(0, 75) + " ...»";
    //     setReview({
    //         reviewName: reviewName?.innerHTML,
    //         reviewBodyText: reviewBodyText,
    //         reviewTopNameCircle: reviewName?.innerHTML[0],
    //     })
    //     setReviewLoaded(true)
    // }

    
    function getReviewData() {
        const link = document.querySelector(".sp-review.sp-review-from-omnibox");
        if (!link) {
            setReviewLoaded(false)
            return
        }

        const reviewText = link?.querySelector(".sp-review-body-content.sp-review-text-content");
        const reviewName = link?.querySelector(".sp-review-author-name");
        const reviewLocation = link?.querySelector(".sp-review-author-location");
        const reviewBodyText = "«" + reviewText?.innerHTML.slice(0, 75) + " ...»";
        const reviewCount = document.querySelector(".sp-content-rating-disclaimer span");
        setReview({
            reviewName: reviewName?.innerHTML,
            reviewLocation: reviewLocation?.innerHTML,
            reviewBodyText: reviewBodyText,
            reviewTopNameCircle: reviewName?.innerHTML[0],
            reviewCount: reviewCount?.innerHTML
        })
        setReviewLoaded(true)
    }

    useEffect(() => {
        if (!isReviewLoaded) {
            setTimeout(getReviewData, 1000)
        }
    });

    const handleScroll = () => {
        const element = document.querySelector(".sp-review.sp-review-from-omnibox");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // const hotelRating = hotel?.topHotels?.hotelRating
    
    const hotelRating = document.querySelector(".sp-content-rating-value")?.innerHTML;

    const hotelRatingText = hotelRating > 4.5 ? "Отлично" : hotelRating > 4 ? "Очень хорошо" : hotelRating > 3 ? "Хорошо" : "Плохо"
    const hotelRatingClass = hotelRating > 4 ? "review-rate-green" : hotelRating > 3 ? "review-rate-orange" : "review-rate-red"

    return (
        <>
            {reviewContent && <div className={`slider_review`}>
                {!!Number(hotelRating) && <div className='slider_review-top review-content' onClick={handleScroll}>
                    <div className='review-top-rate-block'>
                        <div>
                            <h5 className='review-rate-text'>{hotelRatingText}</h5>
                            <div className='review-top-rates-count'>{reviewContent?.reviewCount}  отзывов</div>
                        </div>
                        <h5 className={`review-rate ${hotelRatingClass}`}>{Number(hotelRating).toFixed(1)}</h5></div>
                </div>}
                {reviewContent?.reviewName && <div className='slider_review-bottom review-content'>
                    <div>{reviewContent?.reviewBodyText}</div>
                    <div className='review-person'><div className='review-circle'>{reviewContent?.reviewTopNameCircle}</div>{reviewContent?.reviewName}, {reviewContent?.reviewLocation}</div>
                    <div className='review-link' onClick={handleScroll}>Все отзывы</div>
                </div>}
            </div>}
        </>
    )
}

export default SliderReview