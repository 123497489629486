import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../services/api'

const useSelectActionStore = create(
	devtools((set, get) => ({
		action: null,
		slugAction: null,
		loading: false,
		actions: {
			getActions: async () => {
				const data = {
					isAgentContent: true,
					isClientContent: false
				}
				const res = await geoApiInstance().post('/actions/list', data)
				set({ action: res.data })
			},
			getSlugActions: async url => {
				try {
					const data = {
						isAgentContent: true,
						isClientContent: false,
						slug: url
					}
					const res = await geoApiInstance().post('/actions/actions', data)
					set({ slugAction: res.data })
				} catch (e) {
					console.log(e)
				}
			}
		}
	}))
)

export default useSelectActionStore
