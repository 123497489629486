import React from 'react';




const ScrollBar= ({ children }) => (

    <div>{children}</div>

);

export default ScrollBar;
