import React from "react"
import styled from "styled-components"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import CardMedia from '@mui/material/CardMedia';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`


const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    max-width: 900px;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 20px;
  margin-bottom: 30px;
`

const Heading = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #3c3c3c;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const AgentFinancePopUp = ({ image, setAgentPopUp, agentPopUpOpen }) => {
  return (
    <StyledPopup onClose={() => setAgentPopUp(false)} open={agentPopUpOpen}>
      <CloseButton
        aria-label="close"
        onClick={() => setAgentPopUp(false)}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Heading>Где находится номер заявки и номер для оплаты?</Heading>
        <Text>
          Для того чтобы узнать номер заявки или номер для оплаты перейдите к
          Списку заявок и откройте интересующую вас заявку. В шапке заявки будут
          указаны все номера.
        </Text>
        <CardMedia
          component="img"
          height="502"
          image={image}
          alt="Paella dish"
        />
      </Container>
    </StyledPopup>
  )
}

export default AgentFinancePopUp
