import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import useAuthStore from '../../../Auth/store'
import { InputBase, styled } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3)
	},
	'& .MuiInputBase-input': {
		color: '#000000',
		borderRadius: 8,
		position: 'relative',
		backgroundColor: '#EFF2F6',
		border: 'none',
		fontSize: 16,
		padding: '6px 8px 4px 8px',
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		'&:focus': {
			borderRadius: 8
		}
	}
}))
const currencies = ['RUB', 'USD', 'EUR']

export default function StyledSelect() {
	const currentCurrency = useAuthStore(({ currentCurrency }) => currentCurrency)
	const actions = useAuthStore(({ actions }) => actions)

	const handleChange = event => {
		actions.changeCurrency(event.target.value)
	}

	return (
		<FormControl variant='outlined' sx={{ minWidth: 75, height: 32 }}>
			<Select
				IconComponent={ExpandMore}
				labelId='demo-simple-select-filled-label'
				id='demo-simple-select-filled'
				value={currentCurrency || ''}
				input={<BootstrapInput />}
				onChange={handleChange}>
				{currencies?.map((item, i) => (
					<MenuItem value={item} key={i}>
						{item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
