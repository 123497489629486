import PercentIconSrc from "../../../asset/images/avia/order-detail/percent.svg";
import BillIconSrc from "../../../asset/images/avia/order-detail/bill.svg";
import CardIconSrc from "../../../asset/images/avia/order-detail/card.svg";
import SBPIconSrc from "../../../asset/images/avia/order-detail/clock-icon.svg";
import BankIconSrc from "../../../asset/images/avia/order-detail/bank.svg";
import LinkIconSrc from "../../../asset/images/avia/order-detail/link.svg";

export function setPage(page) {
  return page ? page : 1;
}

export function setPageSize(pageSize) {
  return pageSize ? pageSize : 0;
}

export function createAcquiringCommissionsData(data) {
  let result = []
  data.filter(el => el.paymentType !== 'Int').forEach((el, key) => {
    result.push({
      ...el,
      id: key,
      paymentType: el.paymentType === 'Rus' ? 'Card' : el.paymentType,
      title: getPaymentType(el.paymentType),
      iconUrl: getPaymentIcon(el.paymentType),
    })
  })

  result.push(...[
    {id:3,acquiringAmount:'',acquiringPercent:'',amount:'',paymentType:'Bill',title:'Оплатить по счету',iconUrl:BillIconSrc},
    // {id:4,acquiringAmount:'',acquiringPercent:'',amount:'',paymentType:'Deposit',title:'Оплатить по депозиту',iconUrl:BankIconSrc},
    // {id:5,acquiringAmount:'',acquiringPercent:'',amount:'',paymentType:'Url',title:'Через ссылку на оплату',iconUrl:LinkIconSrc},
  ])

  return result
}

function getPaymentType(type) {
  switch (type) {
    case 'Credit':
      return 'Оплатить в кредит'
    case 'Rus':
      return 'Оплатить по карте'
    case 'Sbp':
      return 'Оплатить через СБП'
    default:
      return ''
  }
}

function getPaymentIcon(type) {
  switch (type) {
    case 'Credit':
      return PercentIconSrc
    case 'Rus':
      return CardIconSrc
    case 'Sbp':
      return SBPIconSrc
    default:
      return ''
  }
}
