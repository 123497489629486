import FiltersNewView from "./template/filtersNewView"
import FiltersNewViewHotelServices from "./template/filtersNewViewHotelServices"
import FiltersNewViewSmallHotelServices from "./template/filtersNewViewSmallHotelServices"

import './style.scss'
import SmallFiltersNew from "./template/filtersNewViewSmall"

function ExcursionsFilters({ content, title }) {
    const renderTemplate = (template) => {
        switch (template) {
            case 'bigCards':
                return null
            case 'filtersNewViewSmall':
                return <SmallFiltersNew content={content}/>
            case 'smallCards':
                return null
            case 'filtersNewView':
                return <FiltersNewView filters={content?.filters} button={content?.button} />
            case 'filtersNewViewHotelServices':
                return <FiltersNewViewHotelServices filters={content?.filters} button={content?.button} />
            case 'filtersNewViewSmallHotelServices':
                return <FiltersNewViewSmallHotelServices content={content}/>
            default:
                return null
        }
    }

    if (!content) return null
    return <div className="excursions-filters">
        {title && <div className="excursions-filters__title">{title}</div>}
        {renderTemplate(content.template)}
    </div>
}

export default ExcursionsFilters