import React from "react"
import {
  addAddictionalService,
  deleteAddictionalService,
  AddictionalServiceTypes
} from "../../../store/modules/orders"
import { useDispatch } from "react-redux"
// import ReactGA from 'react-ga4';

const ModalContext = React.createContext(null)

export const useModalState = () => React.useContext(ModalContext)

export const Provider = ({ children }) => {
  const [visibility, setVisibility] = React.useState(null)
  const [smsModalVisibility, setSmsVisibility] = React.useState(false)
  const hideSmsModal = () => setSmsVisibility(false)
  const showSmsModal = () => setSmsVisibility(true)

  const hideModal = () => setVisibility(null)
  const showModal = x => setVisibility(x)
  const dispatch = useDispatch()

  const handleActionButtonClick = x => {
    if (x.isLoading) return
    if (x.checked) {
      dispatch(deleteAddictionalService([x.type]))
    } else {
      dispatch(addAddictionalService({ services: [x.type] }))
    }
  }

  const addSmsService = x => {
    hideSmsModal()
    dispatch(
      addAddictionalService({
        services: [AddictionalServiceTypes.SMS_Service],
        additionalContactNameInfo: x.name,
        additionalContactPhoneInfo: x.phone.replaceAll(/\+|\(|\)|-|\s/g, "")
      })
    )
  }

  return (
    <ModalContext.Provider
      value={{
        visibility,
        hideModal,
        showModal,
        handleActionButtonClick,
        smsModalVisibility,
        showSmsModal,
        hideSmsModal,
        addSmsService
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
