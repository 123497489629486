import Api from '../../../../functions/fetchApi'

export function getAgentInfo(id) {
  return Api.get(process.env.REACT_APP_AGENTS_GET)
    .query({id})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function getAgentsBalance(partnerId) {
  return Api.get(process.env.REACT_APP_AGENTS_BALANCE)
    .query({partnerId})
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function getAgentNotPaidOrders(samoId, sortField, sortAsc) {
  return Api.get(process.env.REACT_APP_AGENTS_NOT_PAID_ORDERS)
    .query({
      partnerId: samoId,
      sortField: sortField,
      sortAsc: sortAsc
    })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(data => data.orders)
}

export function getAgentOverPaidOrders(samoId, sortField, sortAsc) {
  return Api.get(process.env.REACT_APP_AGENTS_OVER_PAID_ORDERS)
    .query({
      partnerId: samoId,
      sortField: sortField,
      sortAsc: sortAsc
    })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(data => data.orders)
}

export function getAgentCreditHistory(samoId, date, sortField, sortAsc) {
  return Api.get(process.env.REACT_APP_AGENTS_CREDIT_HISTORY)
    .query({
      partnerId: samoId,
      beginDate: date,
      sortField: sortField,
      sortAsc: sortAsc
    })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(data => data.items)
}

export function getAgentPaymentHistory(data) {
  return Api.get(process.env.REACT_APP_AGENTS_PAYMENT_HISTORY)
    .query({
      PartnerId: data.PartnerId,
      BeginDate: data.BeginDate,
      EndDate: data.EndDate
    })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
    .then(data => data.payments)
}

export function getAquiringCommission(AgentId) {
  return Api.get(process.env.REACT_APP_GET_AQUIRING)
    .query({ AgentId })
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function addAgentRequest(data) {
  return Api.post(process.env.REACT_APP_ADD_AGENT_REQUEST)
    .body(data)
    .setContentType('application/json')
    .addAuthGuard()
    .send()
}

export function getAgentReport(agentId, period, token) {
  return Api.get(process.env.REACT_APP_GET_AGENT_REPORT)
    .query({
      Period: period,
      AgentId: agentId,
      ReportType: 'Single',
    })
    .setContentType('application/octet-stream')
    .addAuthGuard()
    .send()
    .then(Api.loadFile)
}

export const getAgentDeposit = (AgentId, Summ, token) =>
  Api.get(process.env.REACT_APP_GET_DEPOSIT)
    .query({
      Summ: Summ,
      AgentId: AgentId
    })
    .setContentType('application/octet-stream')
    .addAuthGuard()
    .send()
    .then(Api.loadFile)

export const getDepositList = (AgentId, From, To) =>
  Api.get(process.env.REACT_APP_GET_DEPOSIT_LIST)
  .query({
    AgentId, From, To,
  })
  .setContentType('application/json')
  .addAuthGuard()
  .send()

export const getAgentInfoRequests = PartnerId =>
  Api.get(process.env.REACT_APP_GET_AGENT_INFO)
    .query({PartnerId})
    .setContentType('application/json')
    .send();


