import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Item from './Item';

const Container = styled.div`
  margin-top: 25px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

function Group(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = useCallback((data, index) => {
    setSelectedIndex(index);
    props.onChange(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Container >
        {props.items.map((itemProps, index) => (
          <Item
            type={props.type}
            key={index}
            {...itemProps}
            checked={index === selectedIndex}
            index={index}
            renderFeature={props.renderFeature}
            onClick={handleClick}
            renderLabel={props.renderLabel}
          />
        ))}
      </Container>
    </Wrapper>
  );
}

export default Group;
