import React, { memo } from 'react'
import styled from 'styled-components'

import './style.scss'
import { format } from 'date-fns'
import useClaimsStore from '../../store'
// import Contacts from './Contacts'
// import Messages from './Messages'
import Orders from './Orders'
// import Documents from './Documents'
// import Payments from './Payments'
import { Button } from '@material-ui/core'
import CancelClaimModal from './CancelClaimModal'
import Tourists from '../../Tourists'
import PriceAndPayment from '../../PriceAndPayment'
import { PaidTooltip } from '../ClaimTooltip'
import { ReactComponent as Dot } from '../../../../../asset/icons/dot.svg'
import { ReactComponent as Partial } from '../../../../../asset/icons/partialPaid.svg'
import { ReactComponent as Success } from '../../../../../asset/icons/successPaid.svg'
import { ReactComponent as Cancelled } from '../../../../../asset/icons/cancelledPaid.svg'
import { Collapse, Grid } from '@mui/material'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClaimDescription from './ClaimDescription'
import ClaimDocuments from '../../Contracts'
import Messages from './Messages'
import { useLocation } from 'react-router-dom'
// import ClaimDocuments from '../Contracts'

const CancelButton = styled(Button)`
	padding: 12px 24px;
	background-color: #fff;
	border-color: #e73c3e;
	color: #e73c3e;
	border-radius: 8px;
	box-shadow: none;
	align-self: flex-end;

	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
`

const ClaimContainer = styled.div`
	overflow: hidden;
	border-radius: 8px;
	margin-bottom: 24px;
`
const ClaimHeader = styled.div`
	background-color: #f6f8fa;
	padding: 16px 24px;
`
const ClaimNumber = styled.div`
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`

const ClaimDate = styled.div`
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`

const ClaimData = styled.div`
	display: flex;
	align-items: center;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`
const ClaimContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	background-color: #fff;
	border-radius: 0 0 8px 8px;
	border: 1px solid #d4d9de;
	border-top: none;
`

const Claim = ({ claim }) => {
	const [modalVisible, setModalVisible] = React.useState(false)
	const handleCancelClaim = () => {
		setModalVisible(true)
	}
	const [isOpen, toggleOpen] = React.useState(false)

	const actions = useClaimsStore(({ actions }) => actions)

	const handleGetClaim = id => {
		actions.getClaim(id)
		actions.getClaimDocuments(id)
		actions.getPayments(id) //TODO: уточнить при каком paymentStatus не нужно делать запрос
		actions.getRatingTO(id)
		// actions.getRatingTOExist(id)//TODO: не работает
		// actions.getPaymentsCommission(id) //TODO: метот не работает ожидает https://jira-new.fstravel.com/browse/NEB-1697
		toggleOpen(prev => !prev)
	}

	const [isClaimCanceled, setIsClaimCanceled] = React.useState(false)

	const currentDate = new Date()
	const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

	const isCancelAvailable =
		!isClaimCanceled &&
		claim.paymentStatus.id !== 3 &&
		claim.paymentStatus.id !== 4 &&
		claim.paymentStatus.id !== 5 &&
		claim.paymentStatus.id !== 8 &&
		new Date(claim.dateBeg) > tomorrow &&
		new Date(claim.dateEnd) > currentDate

	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const claimId = Number(searchParams.get('claimId'))
	const paymentModalVisible = !!searchParams.get('PaymentModalVisible')

	React.useEffect(() => {
		if (claimId) {
			handleGetClaim(claimId)
		} else {
			toggleOpen(false)
		}
		// eslint-disable-next-line
	}, [claimId])

	const getBulletClass = claim => {
		switch (claim.claimStatusName) {
			case 'Доступна для оплаты':
			case 'Оплачено':
				return 'success'
			case 'Бронирование':
			case 'Предварительная':
			case 'Предварительная заявка':
			case 'Не подтверждена':
				return 'partial'
			case 'Отменено':
			case 'Отменена':
			case 'Опл. штраф':
				return 'cancelled'
			default:
				return null
		}
	}
	// проверка по  claim.paymentStatus.id
	// =====================================
	// const getPaymentStatus = claim => {
	// 	if (claim.claimStatusName === 'Предварительная') {
	// 		return
	// 	}
	// 	switch (claim.paymentStatus.id) {
	// 		case 1: // част. оплата
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							{claim.partpaymentRule && (
	// 								<div>Оплата {claim.partpaymentRule}</div>
	// 							)}
	// 							{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
	// 							{/* <div>
	// 								Оплатить до{' '}
	// 								{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}
	// 							</div> */}
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Partial />
	// 				</PaidTooltip>
	// 			)
	// 		case 0: // оплачено
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							{claim.partpaymentRule && (
	// 								<div>Оплата {claim.partpaymentRule}</div>
	// 							)}
	// 							{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
	// 							{/* <div>{claim.claimPayStatusName}</div>
	// 							<div>{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}</div> */}
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Success />
	// 				</PaidTooltip>
	// 			)
	// 		case 6:
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							{/* <div>
	// 								{claim.claimPayStatusName}
	// 								{format(new Date(claim?.firstPayDate), 'dd.MM.yyyy')}
	// 							</div>
	// 							<div>
	// 								оплата остатка до{' '}
	// 								{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}
	// 							</div> */}
	// 							{claim.partpaymentRule && (
	// 								<div>Оплата {claim.partpaymentRule}</div>
	// 							)}
	// 							{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Partial />
	// 				</PaidTooltip>
	// 			)
	// 		case 2: //не оплачено
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							{claim.claimStatusName === 'Бронирование' && (
	// 								<div>{claim.claimPayStatusName}</div>
	// 							)}
	// 							{/* {claim.paymentDate && (
	// 								<div>
	// 									Оплатить до{' '}
	// 									{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}
	// 								</div>
	// 							)} */}
	// 							{claim.partpaymentRule && (
	// 								<div>Оплата {claim.partpaymentRule}</div>
	// 							)}
	// 							{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Cancelled />
	// 				</PaidTooltip>
	// 			)
	// 		case 5: // неоплаченный штраф
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							<div>{claim.claimPayStatusName}</div>
	// 							<div>
	// 								Отменена {format(new Date(claim.cancelDate), 'dd.MM.yyyy')}
	// 							</div>
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Cancelled />
	// 				</PaidTooltip>
	// 			)
	// 		case 3:// отменено
	// 			return (
	// 				<PaidTooltip
	// 					title={
	// 						<React.Fragment>
	// 							<div>
	// 								Отменена {format(new Date(claim.cancelDate), 'dd.MM.yyyy')}
	// 							</div>
	// 							{/* <div>по инициативе туриста</div> */}
	// 						</React.Fragment>
	// 					}
	// 					placement='top'>
	// 					<Cancelled />
	// 				</PaidTooltip>
	// 			)
	// 		default:
	// 			return null
	// 	}
	// }
	// вместо проверки по  claim.paymentStatus.id
	// проверка по claim.claimStatusName
	// =====================================
	const getPaymentStatus = claim => {
		if (claim.claimStatusName === 'Предварительная') {
			return
		}
		switch (claim.claimPayStatusName) {
			case 'Част. оплата':
				return (
					<PaidTooltip
						title={
							<React.Fragment>
								{claim.partpaymentRule && (
									<div>Оплата {claim.partpaymentRule}</div>
								)}
								{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
								{/* <div>
									Оплатить до{' '}
									{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}
								</div> */}
							</React.Fragment>
						}
						placement='top'>
						<Partial />
					</PaidTooltip>
				)
			case 'Оплачено':
				return (
					<PaidTooltip
						title={
							<React.Fragment>
								{claim.partpaymentRule && (
									<div>Оплата {claim.partpaymentRule}</div>
								)}
								{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
								{/* <div>{claim.claimPayStatusName}</div>
								<div>{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}</div> */}
							</React.Fragment>
						}
						placement='top'>
						<Success />
					</PaidTooltip>
				)

			case 'Не оплачено':
				return (
					<PaidTooltip
						title={
							<React.Fragment>
								{claim.claimStatusName === 'Бронирование' && (
									<div>{claim.claimPayStatusName}</div>
								)}
								{claim.claimStatusName === 'Не подтверждена' && (
									<div>{claim.claimPayStatusName}</div>
								)}
								{/* {claim.paymentDate && (
									<div>
										Оплатить до{' '}
										{format(new Date(claim.paymentDate), 'dd.MM.yyyy')}
									</div>
								)} */}
								{claim.partpaymentRule && (
									<div>Оплата {claim.partpaymentRule}</div>
								)}
								{claim.paymentRule && <div>Оплата {claim.paymentRule}</div>}
							</React.Fragment>
						}
						placement='top'>
						<Cancelled />
					</PaidTooltip>
				)
			case 'Неоплаченный штраф':
				return (
					<PaidTooltip
						title={
							<React.Fragment>
								<div>{claim.claimPayStatusName}</div>
								<div>
									Отменена {format(new Date(claim.cancelDate), 'dd.MM.yyyy')}
								</div>
							</React.Fragment>
						}
						placement='top'>
						<Cancelled />
					</PaidTooltip>
				)
			case 'Отменено':
				return (
					<PaidTooltip
						title={
							<React.Fragment>
								<div>
									Отменена {format(new Date(claim.cancelDate), 'dd.MM.yyyy')}
								</div>
								{/* <div>по инициативе туриста</div> */}
							</React.Fragment>
						}
						placement='top'>
						<Cancelled />
					</PaidTooltip>
				)
			default:
				return null
		}
	}

	return (
		<>
			<ClaimContainer>
				<ClaimHeader>
					<Grid container spacing={1} alignItems='center' direction='row'>
						<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
							<ClaimNumber>{`№${claim.claimId}`}</ClaimNumber>
							<Dot style={{ margin: '5px' }} />
							<ClaimDate>
								{format(new Date(claim.reservationDate), 'dd.MM.yyyy')}
							</ClaimDate>
						</Grid>
						<Grid item xs={2}>
							<ClaimData>
								{claim.country.id > 0 ? 'Туры / отели' : 'Доп. услуги'}
							</ClaimData>
						</Grid>
						<Grid item xs={2} className={getBulletClass(claim)}>
							<ClaimData>
								{claim?.deadlineLeft ? (
									<PaidTooltip
										title={
											<React.Fragment>
												<div>{claim?.deadlineLeft}</div>
											</React.Fragment>
										}
										placement='top'>
										{claim.claimStatusName}
									</PaidTooltip>
								) : (
									claim.claimStatusName
								)}
							</ClaimData>
						</Grid>
						<Grid item xs={1}>
							<ClaimData>{getPaymentStatus(claim)}</ClaimData>
						</Grid>
						<Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
							<ClaimData>{claim.clientName || claim.clientLatinName}</ClaimData>
						</Grid>
						<Grid item align='right' xs={1}>
							<ExpandMoreIcon
								onClick={() => handleGetClaim(claim.claimId)}
								style={
									isOpen
										? { transform: 'rotate(180deg)', cursor: 'pointer' }
										: { cursor: 'pointer' }
								}
								isActive={isOpen}
							/>
						</Grid>
					</Grid>
				</ClaimHeader>

				{isOpen && (
					<Collapse in={isOpen}>
						<ClaimContent>
							<ClaimDescription detail={claim} id={claim.claimId} />
							<Messages id={claim.claimId} />
							<Orders id={claim.claimId} />
							{claim.paymentStatus.id !== 3 && (
								<PriceAndPayment
									id={claim.claimId}
									paymentModalVisible={paymentModalVisible}
									status={claim?.paymentStatus?.id}
								/>
							)}
							<Tourists id={claim.claimId} />
							<ClaimDocuments id={claim.claimId} />
							{isCancelAvailable && (
								<CancelButton
									variant='outlined'
									color='secondary'
									onClick={() => handleCancelClaim()}>
									Отменить заявку
								</CancelButton>
							)}
						</ClaimContent>
					</Collapse>
				)}
			</ClaimContainer>

			{modalVisible && (
				<CancelClaimModal
					claimId={claim.claimId}
					preorder={claim.paymentStatus.id === 7}
					onClose={setModalVisible}
					setIsClaimCanceled={setIsClaimCanceled}
				/>
			)}
		</>
	)
}

export default memo(Claim)
