import React from "react"
import styled from "styled-components"
import { DatePicker } from "../../ui"
import { useDispatch, useSelector } from "react-redux"
import { getAgentSiginState } from "../../../store/modules/agents/agent-finance/selectors"
import moment from "moment"
import { getAgentPaymentHistoryRequest } from "../../../store/modules/agents/agent-finance/duck"
import infoIcon from "../../../asset/images/avia/shared/info-icon.svg"
import agentPaymentHistoryImage from "../../../asset/images/avia/agents/agentPaymentHistoryImage.svg"
import ArrowButton from "./ArrowButton"
import Collapse from '@mui/material/Collapse';
import PrimaryButton from '../../ui/PrimaryButton'
import { getAgentPaymentHistory } from "../../../store/modules/agents/agent-finance/selectors"
import PaymentHistoryTable from './tables/PaymentHistoryTable'
import {styled as MUIstyled} from '@mui/material/styles'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  //width: 750px;
`

const DateWrapper = styled.div`
  width: 256px;
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
  }
`

const StyledButton = MUIstyled(PrimaryButton)(() => ({
  whiteSpace: 'nowrap'
}))

const Margin = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 20px;
  }
`

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  margin-right: 7px;
`

const BlueBlock = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 20px;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }
`

const BlueBlockText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const AgentPaymentHistoryImage = styled.img.attrs({
  src: agentPaymentHistoryImage
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const SubText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 350;
  color: #3c3c3c;
  
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const ColumnRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 25px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`


const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${({ isActive }) => (isActive ? "#4872F2" : "#dcdcdc")};
`

const PaymentHistory = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const enabled = startDate && endDate
  const agent = useSelector(getAgentSiginState)
  const [isOpen, setIsOpen] = React.useState(false)
  const [hover, setHover] = React.useState(false)
  const data = useSelector(getAgentPaymentHistory)

  const isLoading = useSelector(state => state.agents.loadings.paymentHistory)

  const dispatch = useDispatch()
  const formatDate = date => moment(date).format("YYYY-MM-DD")
  const onSubmit = () => {
    if (startDate && endDate) {
      dispatch(
        getAgentPaymentHistoryRequest({
          BeginDate: formatDate(startDate),
          EndDate: formatDate(endDate),
          PartnerId: agent?.agentSamoCode ?? ""
        })
      )
    }
  }

  return (
    <Container>
      <Row
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <FlexRow>
          <AgentPaymentHistoryImage /> <Title>История платежей за период</Title>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Отслеживайте статусы оплат за период.
      </SubTitle>
      <Collapse in={isOpen}>
        <div>
          <SubText>
            Задайте интересующий вас период и получите историю платежей по заявке, а также платежей по депозиту.
          </SubText>
          <ColumnRow style={{ marginTop: 20 }}>
            <DateWrapper>
              <DatePicker
                value={startDate}
                onChange={date => {
                  // @ts-ignore
                  setStartDate(date)
                }}
                label="Период платежей от"
                placeholder="дд.мм.гггг"
                icon
              />
            </DateWrapper>
            <Margin />
            <DateWrapper>
              <DatePicker
                value={endDate}
                onChange={date => {
                  // @ts-ignore
                  setEndDate(date)
                }}
                label="До"
                placeholder="дд.мм.гггг"
                icon
              />
            </DateWrapper>
            <StyledButton disabled={!enabled} onClick={() => onSubmit()} loading={isLoading}>
                Получить историю платежей
            </StyledButton>
          </ColumnRow>
          <BlueBlock>
            <InfoIcon />
            <BlueBlockText>
              По умолчанию Вам отображается история платежей за сегодняшний
              день. Для просмотра другого периода введите необходимые даты.
            </BlueBlockText>
          </BlueBlock>
          {data && data.length > 0 && <PaymentHistoryTable items={data}/>}
        </div>
      </Collapse>
      <Separator isActive={hover} />
    </Container>
  )
}

export default PaymentHistory
