import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
// import { isEmpty, orderBy, sumBy } from "lodash";
import axios from 'axios'
import { addDays, format, differenceInDays } from 'date-fns'
import { geoApiInstance } from '../../services/api'
import {isEmpty, uniqBy} from 'lodash'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useExcursionStore = create(
	devtools((set, get) => ({
		authError: false,
		loading: false,
		loadingSearch: false,
		countries: null,
		currentCountry: null,
		adults: 2,
		children: [],
		date: {
			dateFrom: format(addDays(new Date(), 4), 'yyyy-MM-dd'),
			dateTo: format(addDays(new Date(), 8), 'yyyy-MM-dd'),
			daysCount: 4
		},
		cities: null,
		currentCity: null,
		excursions: null,
		categories: null,
		additionalService: null,
		activity: [],
		excursionType: [],
		forWhom: [],
		checkedExcursionType: [],
		checkedActivity: [],
		checkedForWhom: [],
		serviceType: ["Входные билеты", "Экскурсии"],
		checkedServiceType: [],
		samoExcursions: null,
		searchComplete: false,
		// currentRegionFrom: null,
		// currentRegionTo: null,
		// placeTypes: null,
		// placesFrom: null,
		// placesTo: null,
		// currentPlaceType: null,
		// placeFrom: 1,
		// placeTo: 1,
		// transfers: null,
		// transfer: null,
		// meetingData: [],
		// emptySearch: false,

		// pageFrom: 0,
		// pageTo: 0,
		// stopSearch: false,
		// searchComplete: false,
		// draft: null,
		// moreInfo: {},
		// isReadyForBooking: false,
		// travelers: [{ id: 1, sex: 'male' }],
		// status: null,
		// TransferServiceInfos: [],
		// fullPrice: 0,
		actions: {
			clearFilters: () => {
				set({ checkedExcursionType: [] })
				set({ checkedActivity: [] })
				set({ checkedForWhom: [] })
			},
			changeExcursionType: type => {
				if (!get().checkedExcursionType.some(name => name === type)) {
					set({
						checkedExcursionType: [...get().checkedExcursionType, type]
					})
				} else {
					set({
						checkedExcursionType: [
							...get().checkedExcursionType.filter(name => name !== type)
						]
					})
				}
			},
			changeActivity: activity => {
				if (!get().checkedActivity.some(name => name === activity)) {
					set({
						checkedActivity: [...get().checkedActivity, activity]
					})
				} else {
					set({
						checkedActivity: [
							...get().checkedActivity.filter(name => name !== activity)
						]
					})
				}
			},
			changeForWhom: forWhomType => {
				if (!get().checkedForWhom.some(name => name === forWhomType)) {
					set({
						checkedForWhom: [...get().checkedForWhom, forWhomType]
					})
				} else {
					set({
						checkedForWhom: [
							...get().checkedForWhom.filter(name => name !== forWhomType)
						]
					})
				}
			},
			changeServiceType: serviceType => {
				if (!get().checkedServiceType.some(name => name === serviceType)) {
					set({
						checkedServiceType: [...get().checkedServiceType, serviceType]
					})
				} else {
					set({
						checkedServiceType: [
							...get().checkedServiceType.filter(name => name !== serviceType)
						]
					})
				}
			},
			getCategories: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/Categories`,
						method: 'GET'
					})
					set({ categories: res.data })
				} catch (e) {
					console.error(e)
				}
			},

			getCategoriesAttributes: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/2/Attributes`,
						method: 'GET'
					})
					console.log('Attributes', res.data)
					// set({ cities: orderBy(res.data.availableTowns, 'name') })
				} catch (e) {
					console.error(e)
				}
			},

			postCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/2/Countries`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: {}
					})
					set({ countries: res.data?.filter(item => item.id > 0) })
					set({ currentCountry: get().currentCountry || get().countries[0]?.id })
				} catch (e) {
					console.error('error in postCountries', e)
				}
			},

			postCountriesFrom: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/2/CountriesFrom`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'GET'
					})
					console.log('postCountriesFrom', res.data)
					// set({ cities: orderBy(res.data.availableTowns, 'name') })
				} catch (e) {
					console.error('error in postCountriesFrom', e)
				}
			},

			postCities: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/2/Cities`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: {
							country: {
								id: id
							}
						}
					})

					set({ cities: res.data.filter(item => item.id > 0) })
					if (!get().currentCity) {
						set({ currentCity: get().cities[0]?.id })
					}
				} catch (e) {
					console.error('error in postCities', e)
				}
			},

			postSearch: async () => {
				set({ excursions: [] })
				set({ loadingSearch: true })
				set({ activity: [] })
				set({ excursionType: [] })
				set({ forWhom: [] })
				set({ checkedExcursionType: [] })
				set({ checkedActivity: [] })
				set({ checkedForWhom: [] })
				set({ searchComplete: false })
				const data = {
					country: {
						id: get().currentCountry
					},
					departureCityId: get().currentCity,
					adults: get().adults,
					childrens: get().children,
					startDate: get().date.dateFrom + "T00:00:00Z",
					endDate: get().date.dateTo + "T00:00:00Z",
					currencyId: 1,
					transferSourceId: 0,
					transferTargetId: 0,
					attributes: [],
					isIndividual: false
				}

				try {
					const res1 = await axios({
						url: `${BASE_URL}/Services/2/Search`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: data
					})

					const res2 = await axios({
						url: `${BASE_URL}/Services/13/Search`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: data
					})

					const excursions = [...res1.data.map(el => ({...el, serviceType: 'Экскурсии'})), ...res2.data.map(el => ({...el, serviceType: 'Входные билеты'}))]

					set({ samoExcursions: uniqBy(excursions, 'serviceKey') })
					const ids = []

					excursions.forEach(element => {
						if (!ids.includes(element.id)) {
							ids.push(element.id)
						}
					})

					get().actions.getAdditionalService(ids)
					set({ searchComplete: true })
				} catch (e) {
					console.error('error in postSearch', e)
					set({ loadingSearch: false })
				}
			},
			getAdditionalService: async ids => {
				const data = { array_ids: ids }
				try {
					const res = await geoApiInstance().post(
						'/additional-service/slice',
						data
					)
					set({ additionalService: res?.data })
					const newExcursions = get().samoExcursions.map(item => {
						const existGeoContent = res?.data.find(
							el => el.service_id === item.id
						)
						let newItem = { ...item, other_params: null }

						if (existGeoContent) {
							newItem.other_params = existGeoContent.other_params
							newItem.image_url = existGeoContent.image_url
							newItem.venue = existGeoContent.venue
							newItem.description_tui = existGeoContent.description_tui
						}

						return newItem
					})
					set({ excursions: newExcursions.filter(i => i?.other_params) })

					const excursionsFilters = res?.data?.map(item => item?.other_params)
					const filters = excursionsFilters?.reduce(
						(acc, cur) => {
							if (isEmpty(cur)) {
								return acc
							}
							return {
								...acc,
								activity: [
									...new Set(
										acc.activity.concat(cur?.activity.map(item => item.name))
									)
								],
								excursionType: [
									...new Set(
										acc.excursionType.concat(
											cur?.excursionType.map(item => item.name)
										)
									)
								],
								forWhom: [
									...new Set(
										acc?.forWhom.concat(cur?.forWhom.map(item => item.name))
									)
								]
							}
						},
						{ activity: [], excursionType: [], forWhom: [] }
					)

					set({ activity: filters.activity })
					set({ excursionType: filters.excursionType })
					set({ forWhom: filters.forWhom })


				} catch (error) {
					console.error(error)
				}
				finally {
					set({ loadingSearch: false })
				}
			},

			setCountry: id => {
				set({ currentCountry: id })
			},

			setCity: id => {
				set({ currentCity: id })
			},

			setDate: value => {
				const dateFrom = format(value[0], 'yyyy-MM-dd')
				const dateTo = format(value[1], 'yyyy-MM-dd')
				const daysCount = differenceInDays(value[1], value[0]) + 1
				set({
					date: {
						dateFrom: dateFrom,
						dateTo: dateTo,
						daysCount: daysCount
					}
				})
			},

			setAdult: id => {
				set({ adults: id })
			},
			adultInc: () => {
				set({ adults: get().adults + 1 })
			},
			adultDec: () => {
				set({ adults: get().adults - 1 })
			},
			setChild: child => {
				set({ children: child })
			},
			childInc: (age) => {
				set({ children: [...get().children, age] })
			},
			childDec: (ind) => {
				set({ children: get().children.filter((_, index) => index !== ind) })
			},
			setStartDate: date => {
				set({ moreInfo: { ...get().moreInfo, startDate: date } })
			},
			setStartTime: time => {
				set({ moreInfo: { ...get().moreInfo, startTime: time } })
			},
			setAddressFrom: address => {
				set({ moreInfo: { ...get().moreInfo, addressFrom: address } })
			},
			setAddressTo: address => {
				set({ moreInfo: { ...get().moreInfo, addressTo: address } })
			},
			setFlightNumber: num => {
				set({ moreInfo: { ...get().moreInfo, flightNumber: num } })
			},
			setLabelName: label => {
				set({ moreInfo: { ...get().moreInfo, labelName: label } })
			},
			setClientPhone: phone => {
				set({ moreInfo: { ...get().moreInfo, clientPhone: phone } })
			},
			setExtraStopComment: address => {
				set({ moreInfo: { ...get().moreInfo, extraStopComment: address } })
			},
			setReadyForBooking: value => {
				set({ isReadyForBooking: value })
			},
			changeGender: (gender, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.sex = gender
							return item
						}
						return item
					})
				})
			},
			changeDoc: (doc, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.type = doc
							return item
						}
						return item
					})
				})
			},
			changeNation: (nation, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.nationalityId = nation
							return item
						}
						return item
					})
				})
			},
			changeSeries: (series, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.series = series
							return item
						}
						return item
					})
				})
			},
			changeNumber: (num, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.number = num
							return item
						}
						return item
					})
				})
			},
			changeLastNameLatin: (lastName, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.lastNameLatin = lastName
							return item
						}
						return item
					})
				})
			},
			changeFirstNameLatin: (lastName, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.firstNameLatin = lastName
							return item
						}
						return item
					})
				})
			},
			changePhone: (phone, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.phones = phone
							return item
						}
						return item
					})
				})
			},
			changeEmail: (email, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.email = email
							return item
						}
						return item
					})
				})
			},
			changeBirthDate: (date, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.birthDay = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
			},
			changeExpirationDate: (date, number) => {
				set({
					travelers: get().travelers.map(item => {
						if (item.id === number) {
							item.document.expirationDate = format(date, 'yyy-MM-dd')
							return item
						}
						return item
					})
				})
			},
			setTravelers: () => {
				let travalersCount = 0
				Object.values(get().draft?.draftBody?.transfers[0]?.passengers).forEach(
					item => (travalersCount += item)
				)
				const travelers = Array(travalersCount)
					.fill(1)
					.map((item, index) => {
						return {
							id: index + 1,
							sex: 'male',
							document: {
								type: 'internationalPassport'
							},
							nationalityId: 210357
						}
					})
				set({ travelers: travelers })
			},
			clearError: () => {
				set({ status: null })
			},
			clearAuthError: () => {
				set({ authError: false })
			},
			addService: service => {
				set({ isReadyForBooking: false })
				if (
					!get().TransferServiceInfos.some(
						item => item.latinName === service.latinName
					)
				) {
					set({
						TransferServiceInfos: [...get().TransferServiceInfos, service]
					})
				} else {
					set({
						TransferServiceInfos: [
							...get().TransferServiceInfos.filter(
								item => item.latinName !== service.latinName
							)
						]
					})
				}
			},
			addToBasket: async (
				payload
			) => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

				let excursion = {
					id: null,
					travellers: null,
					serviceCategory: null,
					imgUrl: null,
					name: null,
					startDate: null,
					endDate: null,
					price: null,
					adults: null,
					children: null,
					contentFilters: null,
					number: null,
					currency: {
						currencyId: null,
						currencyAlias: null,
					},
					city: {
						id: null,
						name: null,
					},
					country: {
						id: null,
						name: null,
					},
				}
				excursion.id = payload.serviceKey
				excursion.travellers = []
				excursion.serviceCategory = payload?.category?.id
				excursion.serviceCategoryName = payload?.category?.code
				excursion.imgUrl = payload.imgUrl
				excursion.name = payload.name
				excursion.startDate = payload.startDate
				excursion.endDate = payload.endDate
				excursion.price = payload.price.value
				excursion.currency.currencyId = payload.price.currencyId
				excursion.currency.currencyAlias = payload.price.currencyAlias
				excursion.adults = get().adults
				excursion.children = get().children?.length
				excursion.city.id = get().currentCity
				excursion.city.name = payload.city.name
				excursion.country.id = get().currentCountry
				excursion.country.name = payload.country.name
				excursion.contentFilters = payload?.contentFilters
				excursion.number = payload.id

				try {
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: null,
							transfers: [],
							insurance: [],
							excursions: [
								// {
								// 	id: excursion?.serviceKey,
								// 	number: excursion?.id,
								// 	travellers: [],
								// 	serviceCategory: 2,
								// 	//   "serviceCategoryName": "string",
								// 	imgUrl: excursion?.photoUrls,
								// 	name: excursion?.name,
								// 	startDate: excursion?.startDate,
								// 	endDate: excursion?.endDate,
								// 	price: excursion?.price?.value,
								// 	adults: get().adults,
								// 	children: get().children,
								// 	currency: {
								// 		currencyId: excursion?.price?.currencyId,
								// 		currencyAlias: excursion?.price?.currencyAlias
								// 	},
								// 	city: excursion?.city,
								// 	country: excursion?.country,
								// }
								excursion
							],

						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
						}
					})
					window.open('/booking/excursion')
				} catch (e) {
					console.log(e)
				}
			},
		}
	}))
)

export default useExcursionStore
