import { useState } from "react"
import StaticBlocks from '../../StaticBlocks'

import './style.scss'
import Spoiler from "./Spoiler"
import { isMobile } from "react-device-detect";

function BlockWithTabs({ content, title = '',id }) {
    const blockType = content?.content?.placement || content?.placement
    const nested = content?.content?.nested || content?.nested
    const [activeTab, setTab] = useState(nested?.at(0) || nested?.at(0) || null)

    const handleChangeTab = (id, link) => {
        const existTabItem = nested?.find(item => item?.guid === id)
        if (link) {
            window.location.href = link
        }
        if (!existTabItem) return
        setTab(existTabItem)
    }

    if (blockType === 'accordion') {
        return (<div className="block-with-tabs">
            {title && <h3>{title}</h3>}
            {nested && nested.map(item => <Spoiler key={item.guid} content={item} />)}
        </div>)
    }
    return <div className="block-with-tabs" id={id}>
        {title && <h3 className="block-with-tabs_title">{title}</h3>}
        <div className="tabs-header">
            <div className="tabs-title-container">
                {nested?.map(item => {
                    if (item.is_opened === 0) {
                        return null
                    }
                    return <div key={item.guid} onClick={() => handleChangeTab(item?.guid, item?.link)} className={`tabs-title ${activeTab?.guid === item?.guid ? 'active' : ''}`}>
                        <div className={`tabs-name ${item.icon_img ? item.icon_placement : ''}`}>
                            {item?.icon_img && <img className="tabs-icon" src={item.icon_img} alt='' />}
                            {item.name}
                        </div>
                        {activeTab?.guid === item?.guid && <div className="tab-divider" />}
                    </div>
                })}
            </div>
            <div className="tabs-bar" />
        </div>

        <div className="block-with-tabs_blocks">
            {activeTab?.blocks && <StaticBlocks blocks={activeTab?.blocks?.filter(block => isMobile ? block.show_mobile : block.show_desktop)} />}
        </div>
    </div>
}

export default BlockWithTabs