import React from 'react';
// import useBookingStore from "../../store";

const Seat = ({seat, onSelected, selected}) => {

	const getColorSeat = () => {
		if (seat.cellName === 'С') {
			return 'staff'
		}
		if (!seat.available) {
			return 'unavailable'
		}
		if (seat.emergencySeat) {
			return 'emergency'
		}
		if (seat.cellName === ' ') {
			return 'empty'
		}
		return ''
	}

	const handleSelectedSeat = () => {
		const isSeatAvailable  = seat.cellName !== 'С' && seat.available && seat.cellName !== ' '
		if (isSeatAvailable) {
			onSelected(seat)
		}
	}

	return (
		<div
			className={`seat-col ${getColorSeat()} ${selected ? ' selected' : ''}`}
			style={{borderBottom: '5px solid #' + seat.color}}
			title={seat.price ? `+${seat.price} ${seat.currencyAlias} ${seat.groupName}` : ''}
			onClick={handleSelectedSeat}
		>
			{seat.cellName}
		</div>
	);
};

export default Seat;