import { Box } from '@material-ui/core'
import React from 'react'
import useSearchTourStore from '../../../pages/SearchTour/store'
import MobileDepartureCity from './MobileDepartueCity'

export default function MobileSearchPopup({ closeCityHandler }) {
	const cities = useSearchTourStore(({ departureCities }) => departureCities)
	const actions = useSearchTourStore(({ actions }) => actions)
	const handleChangeDepartureCity = id => {
		actions.changeCity(id)
	}
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)

	return (
		<Box sx={{ p: 1, width: '248px' }}>
			<MobileDepartureCity
				value={searchParams.departureCityId}
				lists={cities}
				onChange={handleChangeDepartureCity}
				onClose={closeCityHandler}
			/>
		</Box>
	)
}
