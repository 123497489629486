import React from 'react';
import styledComponents from "styled-components";

import './style.scss'
import {createSearchUrl} from "./createSearchUrl";
import {Link} from "react-router-dom";

const Container = styledComponents.div`
  	column-gap: 30px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    row-gap: 40px;
    
     @media (max-width: 992px) {
    	display: flex;
    	flex-wrap: wrap;
    	column-gap: 16px;
    	row-gap: 0;
    }
`;

const Card = styledComponents.span`
  	background-position: 50%;
    background-size: cover;
    border-radius: 16px;
    display: block;
    height: 270px;
    margin-bottom: 20px;
    width: 100%;
    
    @media (max-width: 992px) {
    	height: 164px;
    	margin-bottom: 8px;
    }
`;

const H3 = styledComponents.div`
  	font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #2e2e32;
    font-family: Open Sans;
    font-style: normal;
    
    @media (max-width: 992px) {
    	font-size: 18px;
    }
`;

const GalleryFour = ({ content, count }) => {
	return (
		<Container className={'additional-services-by-location__four'}>
			{content.items.filter(isShow => isShow.country && isShow.region && isShow.city && isShow.categoryId).slice(0, count).map((item, index) =>
				<Link key={index} to={createSearchUrl(item)} target={'_blank'}>
					<Card style={{backgroundImage: `url(${item.img})`}}/>
					<H3>{item.country.name}</H3>
				</Link>
			)}
		</Container>
	);
};

export default GalleryFour;