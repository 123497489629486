import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { resetFilter } from '../../../store/modules/search-result/duck';

const Wrapper = styled.div`
  text-align: left;
  margin-top: 24px;
`;
const Button = styled.button`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  border: none;
  outline: none;
  background: transparent;
  color: #4872F2;
  cursor: pointer;
`;

const ResetButton = () => {
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Button
        onClick={() => {
          dispatch(resetFilter(null));
        }}
      >
        Сбросить все фильтры
      </Button>
    </Wrapper>
  );
};

export default ResetButton;
