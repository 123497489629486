export const marks = [
	{
		value: 0,
		label: '00:00'
	},

	{
		value: 9,
		label: '02:00'
	},

	{
		value: 18,
		label: '04:00'
	},

	{
		value: 26,
		label: '06:00'
	},

	{
		value: 35,
		label: '08:00'
	},

	{
		value: 44,
		label: '10:00'
	},

	{
		value: 52,
		label: '12:00'
	},

	{
		value: 60,
		label: '14:00'
	},

	{
		value: 68,
		label: '16:00'
	},

	{
		value: 76,
		label: '18:00'
	},

	{
		value: 84,
		label: '20:00'
	},

	{
		value: 92,
		label: '22:00'
	},

	{
		value: 100,
		label: '23:59'
	}
]
