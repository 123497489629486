import React from 'react'

import './style.css'

const TextWithBackground = ({ content, title, id }) => {
	return (
		<div
			id={id}
			className={'textWithBackground'}
			style={{ background: content.background.gradient }}>
			{title && <div className={'title'}>{title}</div>}
			<div dangerouslySetInnerHTML={{ __html: content.text }} />
		</div>
	)
}

export default TextWithBackground
