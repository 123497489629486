import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

function transformFSOrder(x) {
	const order = {
		type: 'fs',
		cancelDate: null,
		claimId: null,
		clientLatinName: null,
		clientName: null,
		confirmDate: null,
		country: {},
		dateBeg: null,
		dateEnd: null,
		firstPayDate: null,
		managerName: null,
		operatorName: null,
		paymentDate: null,
		paymentStatus: {},
		requestCancelDate: null,
		reservationDate: null,
		status: null,
		town: {},
	}
	order.cancelDate = x.cancelDate
	order.claimId = x.claimId
	order.clientLatinName = x.clientLatinName
	order.clientName = x.clientName
	order.confirmDate = x.confirmDate
	order.country = x.country
	order.dateBeg = x.dateBeg
	order.dateEnd = x.dateEnd
	order.firstPayDate = x.firstPayDate
	order.managerName = x.managerName
	order.operatorName = x.operatorName
	order.paymentDate = x.paymentDate
	order.paymentStatus = x.paymentStatus
	order.requestCancelDate = x.requestCancelDate
	order.reservationDate = x.reservationDate
	order.status = x.status
	order.town = x.town

	return order
}
function transformTTEOrder(x) {
	return {...x,type: 'tte'}
}

const useClaimsStore = create(
	devtools((set, get) => ({
		claims: null,
		loadingClaimError: [],
		authError: false,
		claim: [],
		actions: {
			getClaims: async () => {
				// const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const tte_token = JSON.parse(window.localStorage.getItem('tte_token'))?.accessToken
				// const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const arr = await Promise.allSettled([
						//Заказы TTE
						axios({
							url: process.env.REACT_APP_GET_CUSTOMER_ORDERS,
							method: 'GET',
							params: {
								Page: 1,
								PageSize: 50,
							},
							headers: {
								Authorization: `Bearer ${tte_token}`
							}
            }),
						// Заказы FUN&SUN // отключены до уточнения реализации совместно с турами
						// axios({
						// 	url: `${BASE_URL}/claims`,
						// 	method: 'GET',
						// 	params: {
						// 		Page: 1,
						// 		PageSize: 100,
						// 		PartpassId: partpass,
						// 		ClaimStatus: -1
						// 	},
						// 	headers: {
						// 		Authorization: `Bearer ${token}`
						// 	}
						// }),
          ])
					let orders = []

					arr.forEach((x,key) => {
						// eslint-disable-next-line default-case
						switch(key) {
							//Заказы TTE
							case 0: {
								if(x.status === 'fulfilled') {
									try {
										const items = x.value.data.orders
										orders = [
											...orders,
											...items.map(transformTTEOrder)
										]
									} catch(e) {
										console.log('fail to load tte orders')
									}
								}
								break
							}
							// Заказы FUN&SUN
							case 1: {
								if(x.status === 'fulfilled') {
									try {
										const items = x.value.data
										orders = [
											...orders,
											...items.map(transformFSOrder)
										]
									} catch(e) {
										console.log('fail to load fs orders')
									}
								}
								break
							}
						}
					})

					// const res = await axios({
					// 	url: `${BASE_URL}/claims`,
					// 	method: 'GET',
					// 	params: {
					// 		Page: 1,
					// 		PageSize: 100,
					// 		PartpassId: partpass,
					// 		ClaimStatus: -1
					// 	},
					// 	headers: {
					// 		Authorization: `Bearer ${token}`
					// 	}
					// })
					set({claims: orders})
				} catch (e) {
					if (e?.response?.status === 401) {
						set({authError: true})
					}
					console.log(e)
				}
			},
			getOrderByNumber: async (id) => {
				const tte_token = JSON.parse(window.localStorage.getItem('tte_token'))?.accessToken
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				let order = null
				try {
					const res = await axios({
						url: process.env.REACT_APP_GET_CUSTOMER_ORDER + '?orderNumber=' + id + '&User.PartPassId=' + partpass,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${tte_token}`
						}
					})
					order = {...res.data}
					return order
				} catch (e) {
					set({loadingClaimError: [...get().loadingClaimError, id]})
					if (e?.response?.status === 401) {
						set({authError: true})
					}
					console.log(e)
				}
			},
			getOrderMessages: async (data) => {
				const token = JSON.parse(localStorage.getItem('tte_token'))?.accessToken
				let messages = []
				try {
					const res = await axios({
						url: process.env.REACT_APP_GET_CUSTOMER_MESSAGES + '?orderNumber=' + data.orderNumber,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					// console.log('res', res)
					return res?.data?.comments[0].Comments.filter(x => x.Type === 19) || messages  // Забираем только "Перенос денег"
				} catch (e) {
					if (e?.response?.status === 401) {
						set({authError: true})
					}
					console.log(e?.response?.data)
					return messages
				}
			},
			getClaim: async (id) => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				set({loadingClaimError: []})
				let claim = get().claim
				if (!claim.find(item => item.id === id)) {
					try {
						const res = await axios({
							url: `${BASE_URL}/claims/${id}`,
							method: 'GET',
							params: {
								PartpassId: partpass,
							},
							headers: {
								Authorization: `Bearer ${token}`
							}
						})
						claim = [...get().claim, {...res.data, id: id}]
						set({claim: claim})
					} catch (e) {
						set({loadingClaimError: [...get().loadingClaimError, id]})
						if (e?.response?.status === 401) {
							set({authError: true})
						}
						console.log(e?.response?.data)
					}
				}
			},
			clearAuthError: () => {
				set({authError: false})
			},
			clearClaims: () => {
				set({claims: null})
			},
		}
	}))
)

export default useClaimsStore
