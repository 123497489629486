import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import formatPrice from '../../../../functions/formatPrice'
import styledComponents, { keyframes } from 'styled-components';
import moment from 'moment';

const shim = keyframes`
  0% {
    background-color: #C8C8C8;
  }

  99% {
    background-color: transparent;
  }

  100% {
    display: none;
  }
`;

const Overlay = styledComponents.div`
  position: absolute;
  animation: ${shim} 0.2s linear forwards;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
`;


const StyledTableContainer = styled(TableContainer)(()=>({
  position: 'relative',
}))

const StyledTableCell = styled(TableCell)(({lastItem}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FAFAFA',
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      height: 78.5,
      padding: '17px',
      verticalAlign: 'top',
      borderBottom: '1px solid #00000008',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({

}));


const StyledPagination = styled(TablePagination)(()=>({
  backgroundColor: '#FAFAFA',
  borderBottom: 'none',
  fontFamily: 'Open Sans',
  fontWeight: 600,
}));


export default function CustomizedTables(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTableContainer>
      <Overlay
        onAnimationEnd={(e) => {
          e.target.style.display = 'none';
        }}
      />
      <Table sx={{ minWidth: 700 }} aria-label="Список истории установки кредитного лимита">
        <TableHead>
          <TableRow>
            <StyledTableCell>Дата установки лимита</StyledTableCell>
            <StyledTableCell>Размер лимита</StyledTableCell>
            <StyledTableCell>Кто установил</StyledTableCell>
            <StyledTableCell>Описание</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? props.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.items).map((x) => (
            <StyledTableRow key={x.id}>
              <StyledTableCell  sx={{width: 204}}>
                {formatDate(x.dateInst, x.timeInst,"DD.MM.YYYY HH:mm")}
              </StyledTableCell>
              <StyledTableCell>{formatPrice(x.limit)}</StyledTableCell>
              <StyledTableCell >{x?.alias || 'Отсутствует'}</StyledTableCell>
              <StyledTableCell >
                <span style={{ fontWeight: "600" }}>Старое значение:</span>{" "}
                {formatPrice(x.oldLimit)} 
                <br />
                <span style={{ fontWeight: "600" }}>Новое значение:</span>{" "}
                {formatPrice(x.limit)} 
              </StyledTableCell>
            </StyledTableRow>
          ))}
           {emptyRows > 0 && (
            <TableRow style={{ height: 78.5 * emptyRows }}>
              <TableCell colSpan={6} sx={{borderBottom: '1px solid #00000008',padding: 0}}/>
            </TableRow>
          )}
        </TableBody>
        {props.items.length > 4 && (<TableFooter>
          <TableRow>
            <StyledPagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
              colSpan={5}
              count={props.items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage='Количество строк'
              labelDisplayedRows={(values)=>{
                return `${values.from}–${values.to} из ${values.count}`
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>)}
      </Table>
    </StyledTableContainer>
  );
}



function formatDate(date,time,format) {
  if(!date) {
    return 'отсутствует';
  }
  return moment(date.replace('00:00:00',time)).format(format);
}