import useSearchTourStore from "../pages/SearchTour/store";
import {convertTo} from "../functions/convertLang";

const useGetFiltersHotels = () => {
	const hotels = useSearchTourStore(({ filters }) => filters.hotels);
	const arrivalCityIds = useSearchTourStore((state) => state.arrivalCityIds);
	const stars = useSearchTourStore((state) => state.stars);
	const hotelTypes = useSearchTourStore((state) => state.hotelTypes);
	const hotelSearch = useSearchTourStore((state) => state.hotelSearch)

	let data = Object.assign([], hotels)
	return data
		/*Название отеля*/
		.filter(hotel => {
			return hotel.name.toLowerCase().includes(convertTo('ru', hotelSearch.toLowerCase())) || hotel.name.toLowerCase().includes(convertTo('eng', hotelSearch.toLowerCase()))
		})
		/*Звездность отеля*/
		.filter(hotel => {
			return stars.length ? !!stars.find((star) => hotel.starGroupList?.includes(star)) : true
		})
		/*Категория отеля*/
		.filter(hotel => {
			return hotelTypes.length ? !!hotelTypes.find((type) => hotel.typeList?.includes(type.toString())) : true
		})
		/*Регион*/
		.filter(hotel => {
			return arrivalCityIds.length ? arrivalCityIds.includes(hotel.townKey?.toString() || hotel.cityId?.toString()) : true
		})
}

export default useGetFiltersHotels
