// eslint-disable react-hooks/exhaustive-deps

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import { TextContaner, TimeText } from './styles';
import formatDuration from '../../../../functions/formatDuration'

import Range from './RCSlider';

const TimeRange = ({
  min,
  max,
  onChange,
  values,
  ...props
}) => {
  const [valuesS, setValues] = useState(values);

  useEffect(() => {
    setValues(values);
  }, [values]);

  const formattedValues = useMemo(() => {
    return valuesS.map((val) => formatDuration(val));
  }, [valuesS]);
  // eslint-disable-next-line
  const setValuesThrottled = useCallback(
    throttle((val) => {
      setValues(val);
    }, 20),
    []
  );

  const handleChange = (vals) => {
    setValuesThrottled(vals);
  };
  return (
    <div>
      <TextContaner>
        <TimeText>от {formattedValues[0]}</TimeText>
        <TimeText>до {formattedValues[1]}</TimeText>
      </TextContaner>
      <Range
        range
        {...props}
        onChange={handleChange}
        value={valuesS}
        min={min}
        max={max}
        allowCross={false}
      />
    </div>
  );
};

export default TimeRange;
