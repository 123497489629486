import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Snackbar, TextField } from "@material-ui/core";
import { Alert } from '@mui/material'
import CustomSelect from "../../components/Select";
import useSearchTourStore from "./store";
import useLoyaltyStore from "../Loyalty/store";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { decodeQueryString } from '../../functions/sequelize'

import 'react-calendar/dist/Calendar.css';
import './style.css'

import DateInput from "../../components/DateInput";
import ListHotels from "../SearchHotels/ListHotels";
import SearchResults from "./components/SearchResults";
import ListTowns from "../SearchHotels/ListTowns";
import { childrenAges } from "../../constants/childrenAges";
import ListStars from "./components/ListStars";
import ListMeals from "./components/ListMeals";
import ListFilters from "./components/ListFilters";
// import Currencies from './components/Сurrencies'
import FromField from "./components/FromField";
import FromFieldCity from './components/FromField/FromFieldCity'
import Symbols from "./components/Symbols";
import SearchMenu from "../../components/SearchMenu";
import useBookingStore from "../Booking/store";
import LinkPage from './components/LinkPage'
import {isMobile} from "react-device-detect";
import { ReactComponent as Calendar } from '../../asset/icons/Visas/calendar.svg';

const MAX_DATE_RANGE = 7;
const MAX_NIGHT_RANGE = 7;

const SearchTour = ({ withFlights = true, fromCrm = false }) => {
	const currentCity = useSearchTourStore(({ currentCity }) => currentCity)
	const currentCountry = useSearchTourStore(({ currentCountry }) => currentCountry)
	const tourType = useSearchTourStore(({ tourType }) => tourType)
	const program = useSearchTourStore(({ searchParams }) => searchParams.program)
	const tour = useSearchTourStore(({ searchParams }) => searchParams.tours)
	const result = useSearchTourStore(({ tours }) => tours)
	const cities = useSearchTourStore(({ departureCities }) => departureCities)
	const arrivalCountries = useSearchTourStore(({ arrivalCountries }) => arrivalCountries)
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const filters = useSearchTourStore(({ filters }) => filters)
	const actions = useSearchTourStore(({ actions }) => actions)
	const checkins = useSearchTourStore(({ checkins }) => checkins)
	const nights = useSearchTourStore(({ nights }) => nights)
	const childNum = useSearchTourStore(({ childNum }) => childNum)
	const groupByHotel = useSearchTourStore(({ groupByHotel }) => groupByHotel)
	const tourSearchNote = useSearchTourStore(({ tourSearchNote }) => tourSearchNote)
	const errorTours = useSearchTourStore(({ errorTours }) => errorTours)
	const actionsLoyalty = useLoyaltyStore(({ actions }) => actions)
	const actionsBooking = useBookingStore(({ actions }) => actions)
	const isAddInf = useSearchTourStore(({ isAddInf }) => isAddInf)

	const { search } = useLocation();

	const {
		departureCityId,
		arrivalCountryId,
		minStartDate,
		maxStartDate,
		adults,
		children,
		tours,
		minNightsCount,
		maxNightsCount,
	} = searchParams

	const [error, setError] = useState(null)
	const [currencies, setCurrencies] = useState([])
	const [currencyBookingId, setCurrencyBookingId] = useState(1)
	const tourRef = useRef(null)

	useEffect(() => {
		actions.getDeparture()
	}, [actions])

	useEffect(() => {
		const departureCityIdFromStorage = localStorage.getItem('departureCityId')
		const currencyIdFromStorage = localStorage.getItem('currencyId')
		if (departureCityIdFromStorage) {
			actions.changeCity(departureCityIdFromStorage)
		}
		if (currencyIdFromStorage) {
			actions.changeCurrency(currencyIdFromStorage.toString())
		}
	}, [actions, actionsLoyalty])

	useEffect(() => {
		actions.getArrival(withFlights)
	}, [currentCity, actions, withFlights])

	useEffect(() => {
		if (currentCountry) {
			actions.getTourSearchNote(withFlights)
		}
	}, [currentCity, actions, currentCountry, withFlights])

	useEffect(() => {
		actions.getFilters(withFlights)
	}, [currentCity, currentCountry, actions, withFlights])

	useEffect(() => {
		if (!isEmpty(filters)) {
			actions.getHotels(withFlights)
		}
		// eslint-disable-next-line
	}, [withFlights, program, tourType, tour, currentCity, currentCountry])

	useEffect(() => {
		if (!isEmpty(filters)) {
			actions.getPrograms(withFlights)
		}
		// eslint-disable-next-line
	}, [withFlights, tourType, tour, currentCity, currentCountry])

	useEffect(() => {
		const currencyId = localStorage.getItem('currencyId')
		let curr = []
		filters?.currencies?.map(item => curr.push({ id: item.id, name: item.currencyIso || item.name }))
		setCurrencies(curr)
		if (!curr.find(item => item.id === Number(currencyId))) {
			actions.changeCurrency('1')
		} else {
			actions.changeCurrency(currencyId)
		}
		const dollar = curr.find(item => item.id === 2)
		const euro = curr.find(item => item.id === 3)
		const currencyBookingId = dollar?.id || euro?.id || 1
		localStorage.setItem('currencyBookingId', currencyBookingId)
		setCurrencyBookingId(currencyBookingId)
	}, [filters, actions, actionsBooking])

	useEffect(() => {
		actions.getCheckins(withFlights)
	}, [currentCity, currentCountry, actions, tourType, tour, withFlights])

	useEffect(() => {
		actions.getNights(withFlights)
	}, [arrivalCountryId, actions, departureCityId, withFlights, minStartDate, maxStartDate, tours, adults, children])

	useEffect(() => {
		if (search) {
			const newSearchParams = decodeQueryString(search)

			if (newSearchParams.arrivalCountryId) {
				actions.changeCountry(Number(newSearchParams.arrivalCountryId))
			}
			if (newSearchParams.departureCityId) {
				actions.changeCity(newSearchParams.departureCityId)
			}
			if (newSearchParams.children && newSearchParams.children.length) {
				actions.changeChildren(newSearchParams.children.length)
			}
			if (newSearchParams.hotelIds) {
				newSearchParams.hotelIds.forEach(hotel => {
					actions.addHotels(hotel, true)
				})
			}

			actions.setAllFilters(newSearchParams)
			actions.getTours(withFlights)
		}
	}, [actions, search, withFlights])


	if (isEmpty(filters) || !nights || !cities || !arrivalCountries.length) {
		return null
	}
	function isDateRange(startDate, endDate) {
		const rangeInDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
		return rangeInDays <= MAX_DATE_RANGE;
	}

	const getTours = () => {
		return isEmpty(filters?.tours?.filter(item => item.typeKey === Number(searchParams.tourType)))
			? filters?.tours.filter(item => !item.name.includes('ж/д'))
			: filters?.tours?.filter(item => item.typeKey === Number(searchParams.tourType) && !item.name.includes('ж/д'))
	}

	const handleSearch = () => {
		if (error) {
			setError(null)
		}

		if (!isDateRange(minStartDate, maxStartDate)) {
			setError(`Уменьшите интервал дат до ${MAX_DATE_RANGE}`)
			return
		}

		if (Math.ceil(maxNightsCount - minNightsCount) > MAX_NIGHT_RANGE) {
			setError(`Уменьшите интервал ночей до ${MAX_NIGHT_RANGE}`)
			return
		}

		actions.getTours(withFlights)
	}
	const handleChangeDepartureCity = (id) => {
		actions.changeCity(id)
	}
	const handleChangeTourType = (id) => {
		if (id === null || id === '') {
			actions.changeTourType(0)
			return
		}
		actions.changeTourType(id)
	}

	const changeCurrency = (id) => {
		localStorage.setItem('currencyId', id)
		actions.changeCurrency(id)
	}

	function handleInputCost(event) {
		event.target.value = event.target.value.replace(/[^\d]/g, "")
	}

	return (
		<>
			{/* <Currencies /> */}
			<SearchMenu />
			<LinkPage />
			<div className={!isMobile ? 'container' : ''} style={{ marginTop: !isMobile ? 'unset' : '-1px'}}>
				<div className="search-form-wrap form">
					<div className="main-form">
						<div style={{ width: !isMobile ? '50%' : '100%', padding: !isMobile ? 20 : '20px 20px 0 20px' }}>
							{withFlights && <Box style={{ marginBottom: 20 }}>
								<FromFieldCity
									value={currentCity}
									lists={cities}
									onChange={handleChangeDepartureCity}
									title="Город отправления"
								/>
							</Box>}
							<Box mb={2}>
								<FromField
									value={currentCountry}
									lists={arrivalCountries}
									title="Страна"
									onChange={actions.changeCountry}
									id={"departure"}
								/>
							</Box>
							<Box>
								<FromField
									value={searchParams.tourType}
									lists={filters?.tourTypes.filter(item => item.id !== 31)}
									title="Тип тура"
									onChange={handleChangeTourType}
									empty
								/>
							</Box>
						</div>
						<div style={{ width: !isMobile ? '50%' : '100%', padding: !isMobile ? 20 : '20px 20px 0 20px' }} ref={tourRef}>
							<Box style={{ marginBottom: 20 }}>
								<FromField
									value={searchParams.tours}
									lists={getTours()}
									title="Тур"
									onChange={actions.changeTours}
									empty
								/>
							</Box>
							<Box mb={2}>
								<FromField
									value={searchParams.program}
									lists={filters?.programs}
									title="Программа"
									onChange={actions.changeProgram}
									empty
								/>
							</Box>
						</div>
						{!!tourSearchNote && <div className={'search-form-note'} dangerouslySetInnerHTML={{ __html: tourSearchNote }} />}
					</div>
					<div style={{ display: 'flex', flexWrap: !isMobile ? 'nowrap' : 'wrap' }}>
						<div style={{ width: !isMobile ? '50%' : '100%', marginRight: !isMobile ? '10' : '0' }}>
							<div className="main-form">
								<div style={{ width: !isMobile ? '70%' : '65%', padding: 20 }}>
									<Box mb={2}>
										<DateInput
											onChange={actions.changeDateTo}
											checkins={checkins}
											label="Вылет от"
											value={searchParams.minStartDate}
											withFlight={withFlights}
										/>
									</Box>
									<Box>
										<DateInput
											onChange={actions.changeDateFrom}
											checkins={checkins}
											label="Вылет до"
											value={searchParams.maxStartDate}
											withFlight={withFlights}
										/>
									</Box>
								</div>
								<div style={{ width: !isMobile ? '30%' : '35%', padding: 20 }}>
									<Box mb={2}>
										<CustomSelect
											value={searchParams.minNightsCount}
											enabledValue={nights?.places}
											options={nights?.nights}
											title="Ночей от"
											onChange={actions.changeNightsTo}
										/>
									</Box>
									<Box>
										<CustomSelect
											value={searchParams.maxNightsCount}
											enabledValue={nights?.places}
											options={nights?.nights}
											title="Ночей до"
											onChange={actions.changeNightsFrom}
										/>
									</Box>
								</div>
							</div>
						</div>
						<div style={{ width: !isMobile ? '50%' : '100%' }}>
							{!isMobile ? <div className="main-form" style={{display: 'block', padding: 20}}>
								<div style={{display: 'flex'}}>
									<div style={{width: '25%', marginRight: 20}}>
										<Box mb={2}>
											<CustomSelect
												value={searchParams.adults}
												options={[1, 2, 3, 4, 5, 6]}
												title="Взрослых"
												onChange={actions.changeAdults}
											/>
										</Box>
									</div>
									<div style={{width: '42%', marginRight: 20}}>
										<Box mb={2}>
											<CustomSelect
												value={searchParams.currencyId}
												options={currencies}
												title="Валюта"
												onChange={changeCurrency}
											/>
										</Box>
									</div>
									<div style={{width: '33%'}} className="min-price">
										<Box mb={2}>
											<TextField
												id="MinCost"
												label="Цена от"
												variant="outlined"
												type="number"
												inputProps={{
													step: "100",
													min: "0"
												}}
												onInput={handleInputCost}
												onChange={(e) => actions.changeMinCost(e.target.value)}
											/>
										</Box>
									</div>
								</div>
								<div style={{display: 'flex'}}>
									<div style={{width: '25%', marginRight: 20}}>
										<Box>
											<CustomSelect
												value={childNum}
												options={[0, 1, 2, 3]}
												title="Детей/Возраст"
												onChange={actions.changeChildren}
											/>
										</Box>
									</div>
									<div style={{width: '42%', marginRight: 20, display: 'flex'}}>
										{[...Array(childNum)].map((value, index) =>
											<Box>
												<CustomSelect
													value={searchParams.children[index]}
													options={childrenAges}
													onChange={(value) => actions.changeChildAge(value, index)}
												/>
											</Box>
										)}
									</div>
									<div style={{width: '33%'}} className="min-price">
										<Box>
											<TextField
												id="MaxCost"
												label="Цена до"
												variant="outlined"
												type="number"
												inputProps={{
													step: "100",
													min: "0"
												}}
												onInput={handleInputCost}
												onChange={(e) => actions.changeMaxCost(e.target.value)}
											/>
										</Box>
									</div>
								</div>
							</div> : <div className="main-form" style={{ display: 'block', padding: 20 }}>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '50%', marginRight: 20 }}>
										<Box mb={2}>
											<CustomSelect
												value={searchParams.adults}
												options={[1, 2, 3, 4, 5, 6]}
												title="Взрослых"
												onChange={actions.changeAdults}
											/>
										</Box>
									</div>
									<div style={{ width: '50%' }}>
										<Box>
											<CustomSelect
												value={childNum}
												options={[0, 1, 2, 3]}
												title="Детей/Возраст"
												onChange={actions.changeChildren}
											/>
										</Box>
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '100%', display: 'flex' }}>
										{[...Array(childNum)].map((value, index) =>
											<Box className={'children_ages'} mb={2}>
												<CustomSelect
													value={searchParams.children[index]}
													options={childrenAges}
													onChange={(value) => actions.changeChildAge(value, index)}
												/>
											</Box>
										)}
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '33%', marginRight: 20 }}>
										<Box mb={2}>
											<CustomSelect
												value={searchParams.currencyId}
												options={currencies}
												title="Валюта"
												onChange={changeCurrency}
											/>
										</Box>
									</div>
									<div style={{ width: '33%', marginRight: 20 }} className="min-price">
										<Box mb={2}>
											<TextField
												id="MinCost"
												label="Цена от"
												variant="outlined"
												type="number"
												inputProps={{
													step: "100",
													min: "0"
												}}
												onInput={handleInputCost}
												onChange={(e) => actions.changeMinCost(e.target.value)}
											/>
										</Box>
									</div>
									<div style={{ width: '33%' }} className="min-price">
										<Box>
											<TextField
												id="MaxCost"
												label="Цена до"
												variant="outlined"
												type="number"
												inputProps={{
													step: "100",
													min: "0"
												}}
												onInput={handleInputCost}
												onChange={(e) => actions.changeMaxCost(e.target.value)}
											/>
										</Box>
									</div>
								</div>
							</div>}
						</div>
					</div>

					<div className="main-form" style={{ padding: 20, gap: !isMobile ? '0' : '10px' }}>
						<ListTowns />
						<ListStars />
						<ListHotels />
						<ListMeals />
					</div>

					<div style={{ display: 'flex', flexWrap: !isMobile ? 'nowrap' : 'wrap' }}>
						<div style={{ width: !isMobile ? '50%' : '100%', marginRight: !isMobile ? '10' : '0' }}>
							<div className="main-form" style={{ display: 'block', padding: 20 }}>
								<ListFilters tourRef={tourRef} />
							</div>
						</div>
						<div style={{ width: !isMobile ? '50%' : '100%' }}>
							<div className="main-form" style={{ display: 'block', padding: 20 }}>
								<div style={{ width: '100%' }}>
									<div style={{ display: 'flex', justifyContent: "space-between", marginRight: 10, marginBottom: 2 }}>
										<span>
											Акции
										</span>
									</div>
									<div className="hotels__container" style={{ height: 120 }}>

									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: !isMobile ? 'flex' : 'block', justifyContent: 'space-between', alignItems: 'center' }}>
						<a className={'calendar_visas'} href={'/countries/visa#calculator'} target={'_blank'} rel={'noreferrer'} style={{ display: 'flex', alignItems: 'center' }}>
							<Calendar />
							<div style={{marginLeft: 5}}>Проверка срока действия паспорта</div>
						</a>

						<div style={{ display: 'flex', flexWrap: !isMobile ? 'nowrap' : 'wrap' }}>
							<label htmlFor={'groupByHotel'} style={{ display: 'flex', alignItems: "center", marginRight: 20 }}>
								<input
									id={'groupByHotel'}
									type="checkbox"
									checked={groupByHotel}
									onChange={(e) => { actions.setGroupByHotel(e.target.checked) }}
								/>
								группировать по гостинице
							</label>
							<Button
								onClick={handleSearch}
								variant="contained"
								color="primary"
								fullWidth={isMobile}
								style={{ marginTop: !isMobile ? '0' : '10' }}
							>
								Искать
							</Button>
						</div>
						<Snackbar
							open={error ?? errorTours}
							autoHideDuration={6000}
						>
							<Alert onClose={() => { setError(null); actions.setErrorTours(false) }} severity="error" sx={{ width: '100%' }}>
								{error ?? errorTours}
							</Alert>
						</Snackbar>
						<Snackbar
							open={isAddInf}
							autoHideDuration={6000}
						>
							<Alert onClose={() => { actions.changeAddInf(false) }} severity="error" sx={{ width: '100%' }}>
								Указывайте возраст детей на дату выезда
							</Alert>
						</Snackbar>
					</div>
				</div>
				<Symbols />
			</div>
			{result && <SearchResults tours={result} withFlights={withFlights} currency={currencyBookingId} fromCrm={fromCrm} />}
		</>
	);
};

export default SearchTour;
