import * as React from 'react'
import Hotel from '../pages/Hotel'
import LandingPage from '../pages/LandingPage'
import ExcursionTour from '../pages/ExcursionTour'
import Footer from '../components/Footer'
import FUN from '../asset/favicon.ico'
import { Helmet } from 'react-helmet'

const routes = [
	{
		path: '/hotel/:id',
		element: (
			<>
				<Hotel />
				<Footer />
			</>
		)
	},
	{
		path: '/hotel/*',
		element: (
			<>
				<Hotel />
				<Footer />
			</>
		)
	},
	{
		path: '/excursiontour/*',
		element: (
			<>
				<Helmet link={[{ rel: 'icon', type: 'image/png', href: FUN }]} />
				<ExcursionTour />
				<Footer />
			</>
		)
	},
	{
		path: '/obshhie-voprosy-nacala-raboty',
		element: <LandingPage />
	},
	{
		path: '/for-new-agents',
		element: <LandingPage />
	},
	{
		path: '/dlya-deistvuyushhix-agentov',
		element: <LandingPage />
	},
	{
		path: '/agreement',
		element: <LandingPage />
	},
	{
		path: '/funsun-soglasie-na-obrabotku-personalnyx-dannyx',
		element: <LandingPage />
	},
	{
		path: '/user-agreement',
		element: <LandingPage />
	}
]

export default routes
