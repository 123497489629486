import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RadioGroup } from '../ui/form';
import { Counter } from '../ui/form/Counter';
import { useSelector, useDispatch } from 'react-redux';
import { setPassengers, setTicketClass } from '../../store/modules/search-form';
import { useFocus } from './useFormFocus';

const Wrapper = styled.div`
  padding: 2px 0;
  @media (min-width: 768px) and (max-width: 1169px) {
    width: 23.5%;
  }

  @media (max-width: 1023px) {
    padding: 1.5px 0;
  }
`;

const PassengerSelect = styled.div`
  position: absolute;
  background-color: #ffffff;
  z-index: 100;
  width: 300px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;
  padding: 0 16px 24px 12px;
  right: 0;
  box-sizing: border-box;

  @media (max-width: 767px) {
    left: 0;
    right: 0;
    width: 100%;
  }
`;

const PeopleSelector = styled.div`
  border-bottom: 1px solid #f2f2f2;
`;
const PeopleCategory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
`;

const PeopleCategoryText = styled.div`
  display: flex;
  flex-direction: column;
`;
const PeopleCategoryTitle = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;
const PeopleCategoryDescription = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #B9B9B9;
`;

const TicketClass = styled.div`
  padding-top: 16px;
`;

const Button = styled.button.attrs({ type: 'button' })`
  font-family: Open Sans;
  color: #3c3c3c;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  width: 163px;
  outline: none;
  padding: 31px 12px 9px 12px;
  background: #fff;
  border: none;
  display: block;
  border-radius: 0 4px 4px 0;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  margin: 0px;
  transition: 0.2s;
  box-shadow: 0 0 0 2px #D4D9DE;

  &:focus {
    box-shadow: 0 0 0 2px #ff6d00;
    z-index: 2;
  }
  ${({ active }) =>
    active
      ? `box-shadow: 0 0 0 2px #ff6d00;
  z-index: 2;`
      : ''}

  @media (max-width: 1169px) {
    width: 100%;
    border-radius: 4px;
  }
  border-left: 0px solid #D4D9DE;
  & > svg {
    ${({ active }) => (active ? 'transform: rotate(180deg);' : '')}
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 9px;
  transform: scale(0.75);
  color: #b9b9b9;
  pointer-events: none;
`;

const Value = styled.span``;

const Svg = styled.svg.attrs({
  width: 24,
  height: 24,
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
})`
  position: absolute;
  right: 8px;
  bottom: 8px;
  transition: transform 0.2s ease-in-out;
  pointer-events: none;
`;

const PassengerPicker = () => {
  const { passengers, ticketClass } = useSelector(
    (state) => state.searchForm
  );

  const { focus, setFocus } = useFocus();
  const dispatch = useDispatch();

  const value = useMemo(() => {
    const { adults, children, infants } = passengers;
    const quantity = `${adults.count}${children.count ? `-${children.count}` : ''
      }${infants.count
        ? children.count
          ? `-${infants.count}`
          : `-0-${infants.count}`
        : ''
      }`;
    return `${quantity}, ${ticketClass.description}`;
  }, [passengers, ticketClass]);

  const handleCounts = (count, name) => {
    const data = {};
    data[name] = count;
    dispatch(setPassengers(data));
  };

  const handleClick = () => {
    setFocus(focus === 'passengers' ? null : 'passengers');
  };

  const handleTicketClassChange = (value) => {
    let description = '';
    switch (value) {
      case 'economy':
        description = 'эконом';
        break;
      case 'comfort':
        description = 'комфорт';
        break;
      case 'business':
        description = 'бизнес';
        break;
      case 'first':
        description = 'первый класс';
        break;
      default:
        description = 'эконом';
        break;  
    }

    dispatch(
      setTicketClass({
        id: value,
        description,
      })
    );
  };

  return (
    <Wrapper>
      <Button
        active={focus === 'passengers'}
        onClick={handleClick}
        name="passengers"
      >
        <Label>Пассажиры</Label>
        <Value>{value}</Value>
        <Svg>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.4017 8.40686C18.3321 8.33702 18.2493 8.2816 18.1582 8.24379C18.0671 8.20598 17.9694 8.18652 17.8707 8.18652C17.7721 8.18652 17.6744 8.20598 17.5833 8.24379C17.4922 8.2816 17.4094 8.33702 17.3397 8.40686L11.2494 14.4987L5.15909 8.40686C5.01826 8.26603 4.82725 8.18691 4.62809 8.18691C4.42893 8.18691 4.23792 8.26603 4.09709 8.40686C3.95626 8.54769 3.87714 8.7387 3.87714 8.93786C3.87714 9.13703 3.95626 9.32803 4.09709 9.46886L10.7184 16.0902C10.7881 16.16 10.8708 16.2154 10.962 16.2533C11.0531 16.2911 11.1508 16.3105 11.2494 16.3105C11.3481 16.3105 11.4457 16.2911 11.5369 16.2533C11.628 16.2154 11.7107 16.16 11.7804 16.0902L18.4017 9.46886C18.4716 9.3992 18.527 9.31643 18.5648 9.22531C18.6026 9.1342 18.6221 9.03652 18.6221 8.93786C18.6221 8.83921 18.6026 8.74153 18.5648 8.65041C18.527 8.5593 18.4716 8.47653 18.4017 8.40686Z"
            fill="#B9B9B9"
          />
        </Svg>
      </Button>
      {focus === 'passengers' && (
        <PassengerSelect>
          <PeopleSelector>
            <PeopleCategory>
              <PeopleCategoryText>
                <PeopleCategoryTitle>Взрослые</PeopleCategoryTitle>
                <PeopleCategoryDescription>
                  Старше 12 лет
                </PeopleCategoryDescription>
              </PeopleCategoryText>
              <Counter
                postFixDataCy={'Adults'}
                onCustomChange={handleCounts}
                value={passengers.adults.count}
                name="adults"
                max={passengers.adults.max}
                min={passengers.adults.min}
              />
            </PeopleCategory>
            <PeopleCategory>
              <PeopleCategoryText>
                <PeopleCategoryTitle>Дети</PeopleCategoryTitle>
                <PeopleCategoryDescription>
                  От 2 лет до 12 лет
                </PeopleCategoryDescription>
              </PeopleCategoryText>
              <Counter
                postFixDataCy={'Children'}
                onCustomChange={handleCounts}
                value={passengers.children.count}
                max={passengers.children.max}
                min={passengers.children.min}
                name="children"
              />
            </PeopleCategory>
            <PeopleCategory>
              <PeopleCategoryText>
                <PeopleCategoryTitle>Младенцы</PeopleCategoryTitle>
                <PeopleCategoryDescription>
                  До 2 лет без места
                </PeopleCategoryDescription>
              </PeopleCategoryText>
              <Counter
                postFixDataCy={'Infants'}
                onCustomChange={handleCounts}
                value={passengers.infants.count}
                max={passengers.infants.max}
                min={passengers.infants.min}
                name="infants"
              />
            </PeopleCategory>
          </PeopleSelector>
          <TicketClass>
            <RadioGroup
              defaultValue={ticketClass.id}
              name="class"
              onChange={handleTicketClassChange}
              items={[
                { value: 'economy', label: 'Эконом' },
                { value: 'comfort', label: 'Комфорт' },
                { value: 'business', label: 'Бизнес' },
                { value: 'first', label: 'Первый класс' },
              ]}
            />
          </TicketClass>
        </PassengerSelect>
      )}
    </Wrapper>
  );
};

export default PassengerPicker;
