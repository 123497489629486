import React from 'react';
import Service from './Service';
import { useSelector, useDispatch } from 'react-redux';
import Modal from './Modal';
import {
  getBookingAncillaryServicesState,
  getBookingPassengersState,
  switchAncillaryService,
  AncillaryServiceType,
} from '../../../../store/modules/booking';
import { SeatMapNotificationModal } from '../../../modals';

export default function AviaServices() {
  const services = useSelector(getBookingAncillaryServicesState);
  const passengers = useSelector(getBookingPassengersState);
  const dispatch = useDispatch();

  const [index, setIndex] = React.useState(null);
  const [notificationVisibility, setNotification] = React.useState(false);
  const handleClick = (x, type) => {
    setIndex(x);
    // ReactGA.event({
    //   category: 'Booking',
    //   action: `flights_booking_${type}_show`,
    // });
  };
  const handleClose = () => {
    if (services[index].segments.find((x) => !x.fulfilled)) {
      setNotification(true);
    } else {
      setIndex(null);
    }
  };

  return (
    <>
      {services.map((x, key) => (
        <Service
          key={x.type}
          type={x.type}
          minPrice={x.minPrice}
          data={x.addictionalInfo}
          passengers={passengers}
          index={key}
          onClick={handleClick}
        />
      ))}

      {index !== null && !notificationVisibility && (
        <Modal
          onClose={handleClose}
          data={services[index]}
          passengers={passengers}
          onItemClick={({ checked, ...data }) => {
            // ReactGA.event({
            //   category: 'Booking',
            //   action: `flights_booking_${data.type}_${checked ? 'on' : 'off'}`,
            // });
            dispatch(switchAncillaryService(data));
          }}
        />
      )}
      {index !== null && notificationVisibility && (
        <SeatMapNotificationModal
          open
          data={services[index].segments}
          title="Выбрано не по всем маршрутам"
          subtitle={`Не забудьте выбрать дополнительн${
            services[index].type === AncillaryServiceType.Luggage
              ? 'ый багаж'
              : 'ое питание'
          } по маршруту`}
          buttonsText={[
            `Выбрать ${
              services[index].type === AncillaryServiceType.Luggage
                ? 'багаж'
                : 'питание'
            }`,
            `Закончить выбор ${
              services[index].type === AncillaryServiceType.Luggage
                ? 'багажа'
                : 'питания'
            }`,
          ]}
          onConfirm={() => {
            setNotification(false);
          }}
          onClose={() => {
            setNotification(false);
            setIndex(null);
          }}
        />
      )}
    </>
  );
}
