import React from 'react';

const MembersBlockModal = ({ title, onClose }) => {

	return (
		<div className="members-block-popup-bg">
			<div className="members-block-popup-block">
                <div className='members-block-popup-header'>
                    <h2 className="members-block-popup-name">
                        {title} 
                    </h2>
                    <div onClick={() => onClose(false)} className='popup-cross'></div>
                </div>
                <hr />
				<p className="members-block-popup-text">Перед бронированием иностранного гражданина необходимо ознакомиться с правилами въезда <a target="_blank" rel="noreferrer" href="https://newb2b.fstravel.com/countries/visa">по ссылке</a></p>
			</div>
		</div>
	);
};

export default MembersBlockModal;
