import * as React from 'react'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import { styled } from '@material-ui/core'
import { Popper } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'
import { FormInput } from '.'

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		fontFamily: 'Open Sans',
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0
		},
		'&::-webkit-scrollbar': {
			backgroundColor: '#dbe0e5',
			width: '5px'
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 0,
			boxShadow: `inset 0 1px 1px #fff`,
			background: '#4872f2'
		}
	}
})

export default function AutocompleteSelect({
	options,
	value,
	onChange,
	label,
	propId
}) {
	const [inputValue, setInputValue] = React.useState('')

	return (
		<Autocomplete
			fullWidth
			id={`filter-list-autocomplete-${label}`}
			inputValue={inputValue}
			// freeSolo
			// forcePopupIcon={true}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			value={value || ''}
			onChange={(event, newValue) => {
				onChange(propId, newValue)
			}}
			PopperComponent={StyledPopper}
			options={options}
			getOptionLabel={option => option.title || ''}
			popupIcon={<ExpandMore />}
			noOptionsText={'Совпадений не найдено'}
			renderInput={params => <FormInput {...params} label={label} />}
			renderOption={(props, option) => (
				<div component='li' {...props}>
					{option.title}
				</div>
			)}
		/>
	)
}
