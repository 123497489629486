import React, { useState, useEffect, useRef } from 'react'

import './style.css'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import FlightIcon from '@material-ui/icons/Flight'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import useSearchTourStore from '../../store'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import useGetGroupedTours from '../../../../hooks/useGetGroupedTours'
import useBookingStore from '../../../Booking/store'
import Promo, { StandartFare } from '../../../Booking/components/Promo'
import Stats from '../../../../asset/images/stats.png'
import Percent from '../../../../asset/images/percent.png'
import PricesPlacesInfo from '../PricesPlacesInfo'
import { isEmpty, orderBy } from 'lodash'
import ServicesPackageInfo from '../ServicesPackageInfo/ServicesPackageInfo'
import { Box, IconButton } from '@mui/material'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import Pagination from '@mui/material/Pagination';
import SortIcon from '../../../../asset/icons/Sort'
import {isMobile} from "react-device-detect";
import declination from "../../../../functions/declination";
import {useNavigate} from "react-router-dom";

const SearchResults = ({ tours, withFlights, fromCrm }) => {
	const groupByHotel = useSearchTourStore(({ groupByHotel }) => groupByHotel)
	const actions = useSearchTourStore(({ actions }) => actions)
	const offers = useSearchTourStore(({ offers }) => offers)
	const currencyAlias = useSearchTourStore(({ currencyAlias }) => currencyAlias)
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const bookingActions = useBookingStore(({ actions }) => actions)
	const filters = useSearchTourStore(({ filters }) => filters)
	const sortDirection = useSearchTourStore(({ sortDirection }) => sortDirection)
	const [selectedItem, setSelected] = useState(null)
	const scrollRef = useRef(null)

	const navigate = useNavigate()

	const handleGetOffers = (tour, withFlight) => {
		if (groupByHotel) {
			!offers || !offers[tour.hotel.id]
				? actions.getOffers(tour.hotel.id, withFlight)
				: actions.clearOffers(tour.hotel.id)
		} else {
			localStorage.setItem('tour', JSON.stringify(tour))
			bookingActions.setTourData(tour)
			const currentSelectedCurrency = searchParams.currencyId


			if (fromCrm) {
				navigate(
					`/booking
				?priceId=${tour.accommodationPriceOffer[0].priceId}
				&currencyId=1
				&currentSelectedCurrency=${currentSelectedCurrency}
				&adults=${searchParams.adults}
				&children=${searchParams.children.filter(item => Number(item) > 1).length}
				&fromCRM=true
				`)
			} else {
				window.open(
					`/booking
				?priceId=${tour.accommodationPriceOffer[0].priceId}
				&currencyId=1
				&currentSelectedCurrency=${currentSelectedCurrency}
				&adults=${searchParams.adults}
				&children=${searchParams.children.filter(item => Number(item) > 1).length}
				${withFlight ? '&withFlight=true' : ''}
				`,
					'_blank'
				)
			}
			// window.open(
			// 	`https://b2b.fstravel.com/bron?
			// 	CATCLAIM=${tour.accommodationPriceOffer[0].priceId}
			// 	&CURRENCY=1
			// 	`,
			// 	'_blank'
			// )
		}
	}

	const handleBooking = (tour, withFlight) => {
		localStorage.setItem('tour', JSON.stringify(tour))
		bookingActions.setTourData(tour)
		const currentSelectedCurrency = searchParams.currencyId
		if (fromCrm) {
			navigate(
				`/booking?priceId=${tour.tourKey}&currencyId=1&currentSelectedCurrency=${currentSelectedCurrency}&adults=${searchParams.adults}
				&children=${searchParams.children.filter(item => Number(item) > 1).length}${withFlight ? '&withFlight=true' : ''}
				&fromCRM=true
				`)
		} else {
			window.open(`/booking?priceId=${tour.tourKey}&currencyId=1&currentSelectedCurrency=${currentSelectedCurrency}&adults=${searchParams.adults}
				&children=${searchParams.children.filter(item => Number(item) > 1).length}${withFlight ? '&withFlight=true' : ''}${fromCrm ? '&fromCRM=true' : ''}`, '_blank')
		}
		// window.open(
		// 	`https://b2b.fstravel.com/bron?
		// 		CATCLAIM=${tour.tourKey}
		// 		&CURRENCY=1
		// 		`,
		// 	'_blank'
		// )
	}

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [tours])

	const getFlightType = (types) => {
		console.log(types)
		if (types.busines.in === 'Y' || types.busines.out === 'Y') return 'Бизнес'
		if (types.econom.in === 'Y' || types.econom.out === 'Y') return 'Эконом'
		if (types.comfort.in === 'Y' || types.comfort.out === 'Y') return 'Комфорт'
		if (types.premium.in === 'Y' || types.premium.out === 'Y') return 'Премиум'
	}

	const availabilityPlace = value => {
		let result
		switch (value) {
			case 'R':
				result = 'по запросу'
				break
			case 'Y':
				result = 'есть места'
				break
			case 'N':
				result = 'нет мест'
				break
			case 'F':
				result = 'мало мест'
				break
			default:
				result = ''
		}
		return result
	}

	const priceInCurrency = value => {
		const currentCurrencyId = Number(value.currencyId)
		const ratesToCurrencyId = Number(searchParams.currencyId)
		if (currentCurrencyId !== ratesToCurrencyId) {
			const ratesTo = filters?.currencies
				.find(item => item.id === currentCurrencyId)
				?.rates.find(item => item.id === ratesToCurrencyId)?.value
			return {
				oldValue: value.oldValue * ratesTo,
				value: value.value * ratesTo
			}
		} else {
			return { oldValue: value.oldValue, value: value.value }
		}
	}

	const [open, setOpen] = useState(false)
	const [tourInfo, setTourInfo] = useState(null)

	const handleClickOpen = async tour => {
		await bookingActions.getServicesReceipt(tour.priceId, 1)
		setTourInfo(tour)
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
		// setTourInfo(null)
	}

	const groupedTours = useGetGroupedTours(tours, groupByHotel, sortDirection)

	const handleChange = (event, value) => {
		actions.getTours(withFlights, value)
	}

	if (!groupedTours.length) {
		return null
	}
	const handleChangeSort = () => {
		if (sortDirection) {
			actions.changeSort(0)
		} else {
			actions.changeSort(1)
		}
		actions.getTours(withFlights, tours.currentPage)
	}

	return (
		<div className='result-wrapper' ref={scrollRef}>
			{!isEmpty(groupedTours) && <div className='sort-panel'>
				<div>Предложений найдено: <strong>{tours?.totalCount}</strong></div>
				<div className='sort-price' onClick={handleChangeSort}>{!isMobile ? 'Сортировать по цене' : ''} <SortIcon className={`sort-${sortDirection}`} /></div>
			</div>}
			{selectedItem && (
				<PricesPlacesInfo
					// item={selectedItem}
					withFlights={withFlights}
					handleGetOffers={handleGetOffers}
					onClose={() => setSelected(null)}
				/>
			)}
			<ServicesPackageInfo
				open={open}
				handleClose={handleClose}
				tour={tourInfo}
			/>
			{!isMobile && <div className='result header'>
				{groupByHotel && <div style={{width: 34}}></div>}
				<div className='date'>Заезд</div>
				<div className='tour'>Тур</div>
				<div className='nights'>Ночей</div>

				<div className='hotel'>Гостиница</div>
				<div className='place'>Места</div>
				<div className='meal'>Питание</div>
				<div className='room'>Номер / Размещение</div>
				<div style={{width: 23}}></div>
				<div className='price'>Цена</div>
				<div style={{width: 19}}></div>
				<div className='price--type'>Тип цены</div>
				{withFlights && <div className='transport'>Транспорт</div>}
			</div>}
			{groupedTours.map(item => (
				<div key={item.hotel.id + Math.random(groupedTours.length)}>
					<div
						className={`result item ${item.isInstantConfirmation ? 'moment' : ''
							} ${item.accommodationPriceOffer[0].hotelAvailability.includes('N') ? 'stopsale' : ''}`}>
						{groupByHotel && (
							<PlaylistAddIcon
								className={`offers-button ${offers &&
									offers[item.hotel.id]?.offers.find(
										value => value.hotelId === item.hotel.id
									) &&
									'opened'
									}`}
								onClick={() => handleGetOffers(item, withFlights)}
							/>
						)}
						<div className='date'>
							{format(parseISO(item.startDate), 'dd.MM.yyyy, EE', {
								locale: ru
							})
								.slice(0, -1)
								.replace('су', 'сб')}
							<br />
							{item.accommodationPriceOffer[0].departureTimeOptions && (
								<span
									title={`вылеты: ${item.accommodationPriceOffer[0].departureTimeOptions}`}
									className='departure-time'>
									<FlightIcon fontSize='small' />
									{item.accommodationPriceOffer[0].departureTimeOptions}
								</span>
							)}
						</div>
						<div className='tour'>
							{item.accommodationPriceOffer[0].tour}{' '}
							{item.accommodationPriceOffer[0].programTypeId === 114 && (
								<Promo />
							)}
							{item.accommodationPriceOffer[0].programTypeId === 14 && (
								<StandartFare />
							)}
							{item.accommodationPriceOffer[0].programTypeId === 5 && (
								<span>GDS</span>
							)}
						</div>
						<div className='nights'>
							{item.accommodationPriceOffer[0].hotelNightsCount}{isMobile && declination(item.accommodationPriceOffer[0].hotelNightsCount, [' ночь', ' ночи', ' ночей'])}
							{item.accommodationPriceOffer[0].nights !== item.accommodationPriceOffer[0].hotelNightsCount && (
								<span className='additional' title={'Ночей в дороге'}>
									{' '}
									+{Number(item.accommodationPriceOffer[0].nights) - Number(item.accommodationPriceOffer[0].hotelNightsCount)}
								</span>
							)}
						</div>
						<div
							className='hotel'
							style={{
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center'
							}}>
							<IconButton
								title='Показать состав пакета'
								size='small'
								onClick={() => handleClickOpen(item)}>
								<AssignmentOutlinedIcon />
							</IconButton>
							<div>
								<a
									href={`/${item.hotel.urlFriendlyName}${searchParams?.momentConfirm
										? '?IsInstantConfirmation=' + searchParams.momentConfirm
										: ''
										}`}
									// href={`https://agentb2b.fstravel.com/${item.hotel.urlFriendlyName}`}
									target='_blank'
									rel='noreferrer'
									className='hotel-name'>
									{item.hotel.name}{' '}
									{!!item.hotel.stars && item.hotel.stars + '*'}
								</a>{' '}
								({item.hotel.cityName})
							</div>
						</div>
						<div
							className='place'
							onClick={() => {
								actions.setSelectedTour(item)
								setSelected(item)
							}}>
							{item.accommodationPriceOffer[0].hotelAvailability.split('').map((item, index) => (
								<span
									key={index}
									className={`hotel_availability helpalt hotel_availability_${item}`}
									title={availabilityPlace(item)}
								/>
							))}
						</div>
						<div className='meal'>
							{item.accommodationPriceOffer[0].mealTypeName}
						</div>
						<div className='room' dangerouslySetInnerHTML={{
							__html: item.accommodationPriceOffer[0].room +
								' / ' +
								item.accommodationPriceOffer[0].htPlace
						}}/>
						<div
							className={'dynamics_icon'}
							style={{ cursor: 'pointer' }}
							title='Динамика цены, наличие мест'
							onClick={() => {
								actions.setSelectedTour(item)
								setSelected(item)
							}}>
							<img src={Stats} alt='' />
						</div>
						<div className='price'>
							{!(
								item.accommodationPriceOffer[0].hotelAvailability.includes('N') ||
								(item.flightAvailability.econom.in === 'N' && item.flightAvailability.busines.in === 'N') ||
								(item.flightAvailability.econom.out === 'N' && item.flightAvailability.busines.out === 'N')
							) ? (
								<div
									className='button'
									onClick={() => handleGetOffers(item, withFlights)}>
									{groupByHotel ? <PlaylistAddIcon /> : <AddShoppingCartIcon />}
									<div>
										{item.accommodationPriceOffer[0].price.oldValue >
											item.accommodationPriceOffer[0].price.value && (
												<span className='oldPrice'>
													{Math.round(
														priceInCurrency(item.accommodationPriceOffer[0].price)
															?.oldValue
													)}{' '}
													{currencyAlias}
												</span>
											)}
										{Math.round(
											priceInCurrency(item.accommodationPriceOffer[0].price)
												?.value
										)}{' '}
										{currencyAlias}
									</div>
								</div>
							) : (
								<div>
									<div style={{ fontStyle: 'italic' }}>
										{item.accommodationPriceOffer[0].price.oldValue >
											item.accommodationPriceOffer[0].price.value && (
												<span className='oldPrice'>
													{Math.round(
														priceInCurrency(item.accommodationPriceOffer[0].price)
															?.oldValue
													)}{' '}
													{currencyAlias}
												</span>
											)}
										{Math.round(
											priceInCurrency(item.accommodationPriceOffer[0].price)
												?.value
										)}{' '}
										{currencyAlias}
									</div>
								</div>
							)}
						</div>
						<div title='Посмотреть скидки' className={'sale_icon'}>
							<img
								src={Percent}
								alt=''
								onClick={actions.getPartnerCommission}
							/>
						</div>
						<div
							className='price--type'
							dangerouslySetInnerHTML={{
								__html: item.accommodationPriceOffer[0].spo
							}}
						/>

						{withFlights && (
							<>
								{(item.flightAvailability.econom.in === 'Y' || item.flightAvailability.econom.out === 'Y') && <div
									className='transport'
									onClick={() => {
										actions.setSelectedTour(item)
										setSelected(item)
									}}>
									Эконом
									<DoubleArrowIcon
										className={`forward-flight availability_${item.flightAvailability.econom.in}`}
										titleAccess={availabilityPlace(
											item.flightAvailability.econom.in
										)}
									/>
									<DoubleArrowIcon
										className={`return-flight availability_${item.flightAvailability.econom.out}`}
										titleAccess={availabilityPlace(
											item.flightAvailability.econom.in
										)}
									/>
								</div>}
								{(item.flightAvailability.busines.in === 'Y' || item.flightAvailability.busines.out === 'Y') && <div
									className='transport'
									onClick={() => {
										actions.setSelectedTour(item)
										setSelected(item)
									}}>
									Бизнес
									<DoubleArrowIcon
										className={`forward-flight availability_${item.flightAvailability.busines.in}`}
										titleAccess={availabilityPlace(
											item.flightAvailability.busines.in
										)}
									/>
									<DoubleArrowIcon
										className={`return-flight availability_${item.flightAvailability.busines.out}`}
										titleAccess={availabilityPlace(
											item.flightAvailability.busines.in
										)}
									/>
								</div>}
							</>

						)}
					</div>
					{offers &&
						offers[item.hotel.id]?.offers.find(
							value => value.hotelId === item.hotel.id
						) &&
						orderBy(
							offers[item.hotel.id]?.offers.filter(
								item => item.tourType.id !== 31
							),
							'price.value'
						).map(offer => (
							<div
								key={offer.hotelId}
								className={`result item ${offer.isInstantConfirmation ? 'moment' : ''
									}`}>
								<div className='date' style={{ marginLeft: 40 }}>
									{format(parseISO(offer.tourStartDate), 'dd.MM.yyyy, EE', {
										locale: ru
									})
										.slice(0, -1)
										.replace('су', 'сб')}
									<br />
									{item.accommodationPriceOffer[0].departureTimeOptions && (
										<span
											title={`вылеты: ${item.accommodationPriceOffer[0].departureTimeOptions}`}
											className='departure-time'>
											<FlightIcon fontSize='small' />
											{item.accommodationPriceOffer[0].departureTimeOptions}
										</span>
									)}
								</div>
								<div className='tour'>
									{offer.tourName} {offer.programType.key === 114 && <Promo />}
								</div>
								<div className='nights'>{offer.nightsCount}{isMobile && declination(offer.nightsCount, [' ночь', ' ночи', ' ночей'])}</div>

								<div className='hotel'>
									<a
										href={`/${item.hotel.urlFriendlyName}`}
										target='_blank'
										rel='noreferrer'
										className='hotel-name'>
										{item.hotel.name}{' '}
										{!!item.hotel.stars && item.hotel.stars + '*'}
									</a>{' '}
									({item.hotel.cityName})
								</div>
								<div className='place'>
									{item.hotel.hotelAvailability.split('').map((item, index) => (
										<span
											key={index}
											className={`hotel_availability helpalt hotel_availability_${item}`}
										/>
									))}
								</div>
								<div className='meal'>
									{
										offers[item.hotel.id]?.meals.find(
											meal => meal.mealTypeId === offer.mealId
										).mealTypeName
									}
								</div>
								<div className='room'
									 dangerouslySetInnerHTML={{
										 __html: offers[item.hotel.id]?.rooms.find(
											 room => room.id === offer.roomId
										 ).name + ' / ' + offer.accomodation.name


									 }} />
								<div className={'dynamics_icon'}>
									<img src={Stats} alt='' />
								</div>
								<div className='price'>
									<div className='button' onClick={() => handleBooking(offer, withFlights)}>
										<AddShoppingCartIcon />
										<div>
											{offer.price.oldValue > offer.price.value && (
												<span className='oldPrice'>
													{Math.round(priceInCurrency(offer.price)?.oldValue)}{' '}
													{currencyAlias}
												</span>
											)}
											{Math.round(priceInCurrency(offer.price)?.value)}{' '}
											{currencyAlias}
										</div>
									</div>
								</div>
								<div className={'sale_icon'}>
									<img src={Percent} alt='' />
								</div>
								<div className='price--type'
									 dangerouslySetInnerHTML={{
										 __html: offer.spo
									 }}/>
								{withFlights && (
									<>
										{(item.flightAvailability.econom.in === 'Y' || item.flightAvailability.econom.out === 'Y') && <div className='transport'>
											Эконом
											<DoubleArrowIcon
												className={`forward-flight availability_${item.flightAvailability.econom.in}`}
											/>
											<DoubleArrowIcon
												className={`return-flight availability_${item.flightAvailability.econom.out}`}
											/>
										</div>}
										{(item.flightAvailability.busines.in === 'Y' || item.flightAvailability.busines.out === 'Y') && <div className='transport'>
											Бизнес
											<DoubleArrowIcon
												className={`forward-flight availability_${item.flightAvailability.busines.in}`}
											/>
											<DoubleArrowIcon
												className={`return-flight availability_${item.flightAvailability.busines.out}`}
											/>
										</div>}
									</>

								)}
							</div>
						))}
				</div>
			))}
			{tours.totalPages > 1 && (
				<Box mt={2} mb={2}>
					<Pagination
						count={tours.totalPages}
						variant='outlined'
						shape='rounded'
						page={tours.currentPage}
						onChange={handleChange}
					/>
				</Box>
			)}
		</div>
	)
}

export default SearchResults
