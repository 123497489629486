import { useRef } from 'react'
import StarsBlock from '../../../Hotel/HotelDescription/StarsBlock'
import Location from '../../../../asset/icons/lp/SimpleSelectionTours/location'
import Plane from '../../../../asset/icons/lp/SimpleSelectionTours/plane'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual } from 'swiper/modules';
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { encodeQueryString } from '../../../../functions/sequelize'

import './HotelCard.scss'

const BASE_IMAGE_URL = process.env.REACT_APP_FS_IMAGE_URL

function HotelCard({ tours, discount }) {
    const ref = useRef(null)
    const hotel = tours.tours[0].hotel
    const searchParam = tours.searchParam
    const filteredImages = hotel?.images.filter(image => image?.resolutionType === "Source").slice(0, 7)
    const link = window.location.origin + "/search_tour?" + encodeQueryString(searchParam)
    const flightInfo = `${tours?.tours[0].isRegularFlight ? "Чартер" : "Регулярный"} / c ${format(parseISO(tours?.tours[0].startDate), 'dd MMMM', { locale: ru })} / ${hotel?.nightsCount} ночей`
    const price = 'от ' + tours.tours[0].accommodationPriceOffer[0].price.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'

    return <div className='hotel-card'>
        <div className='image-container'>
            <Swiper
                modules={[Pagination, Navigation, Virtual]}
                virtual={{
                    addSlidesAfter: 1,
                    addSlidesBefore: 1,
                    enabled: true,
                }}
                pagination={{ clickable: true }}
                navigation
                ref={ref}
                className='image-container-swiper'
            >
                {filteredImages.map((image, index) => {
                    const imageSrc = `${BASE_IMAGE_URL}geocontent/static/Hotel/${hotel.geoId}/${image.imageType}-${image.resolutionType}-${image.fileName}`
                    return <SwiperSlide key={image.fileName} virtualIndex={index} className='img-bg'>
                        <img
                            className='hotel-img'
                            src={imageSrc}
                            alt={image.alt}
                            onError={(e) => {
                                e.target.style.visibility = 'hidden';
                            }}
                        ></img>
                    </SwiperSlide>
                })}

            </Swiper>
            {discount && <div className='bage'>-{discount} %</div>}
        </div>
        <div className='hotel-card__content'>
            <div className='stars'>
                <StarsBlock count={hotel.stars} />
            </div>
            <div className='title-block'><a className='title' href={hotel.urlFriendlyName}> {hotel.name}</a></div>

            <div className='navigation'>
                <div className='navigation-item location'>
                    <div className='navigation-icon'>
                        <Location />
                    </div>
                    {tours?.country?.name}, {tours?.regions[0].name}, {tours?.cities[0].name}
                </div>
                <div className='navigation-item'>
                    <div className='navigation-icon'>
                        <Plane />
                    </div>
                    {flightInfo}
                </div>
            </div>
            <div className='price'>
                <div className='price-btn'>
                    <a className="btn" href={link}>{price}</a>
                </div>
            </div>
        </div>
    </div>
}

export default HotelCard