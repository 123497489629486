import React from 'react'
import formatPrice from "../../../../../functions/formatPrice";

const DetailsItem = ({data, status, total}) => {

  const getStatusColor = (status) => {
    switch (status) {
      case 'Неуспешная выписка':
      case 'Отменена':
        return 'red'
      case 'Выписана':
      case 'Войдирована':
      case 'Возвращена':
      case 'Обменяна':
      case 'Оплачена в кредит/по счету':
        return 'green'
      case 'Требует обработки':
      case 'Отложенная выписка':
      case 'Создана':
        return 'blue'
      default:
        return ''
    }
  }

  return (
    <>
      {data.priceType !== 16 && <div className="orders-body">
        <div className={`orders ${data.priceType === 17 ? 'bold' : ''}`}>{data.description}</div>
        <div className="price">{formatPrice(data.value)}</div>
        {data.priceType !== 17 && <div className={`status ${getStatusColor(status)}`}>{status}</div>}
      </div>}
      {data.priceType === 16 && <>
        <div className="orders-body">
          <div className="orders bold">Сумма оплаты для клиента</div>
          <div className="price">{formatPrice(total)}</div>
        </div>
        <div className="orders-body">
          <div className="orders total">{data.description}: <span>{formatPrice(data.value)}</span></div>
        </div>
      </>}
    </>
  )

}

export default DetailsItem
