import React from "react"
import styled from "styled-components"
import { getDetailOrder } from "../../../store/modules/orders"
import { useSelector } from "react-redux"
import Item from "./Item"

const Wrapper = styled.div`
  margin-top: 35px;
  & > div {
    margin-bottom: 35px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  & > :first-child {
    margin-bottom: 15px;
  }

  @media (max-width: 1024px) {
    & > div {
      margin-bottom: 30px;
    }

    .order-poisition__mobile-body--closed {
      margin-bottom: 15px;
    }

    .order-position__button-detail {
      margin-bottom: 25px;
    }
  }
`

const OrderPositions = () => {
  const { orderPositions, insurances, id } = useSelector(getDetailOrder)
  return (
    <Wrapper>
      {orderPositions.map((item, key) => {
        const insurance = (insurances || []).find(
          x => x.orderPositionId === item.id
        )
        return (
          <Item
            key={key}
            mobileIndex={key}
            insurances={insurance}
            {...{
              ...item,
              orderId: id,
              disabled:
                item.status?.code === "Canceled" ||
                item.isAnnulatedOrderPosition
            }}
          />
        )
      })}
    </Wrapper>
  )
}

export default OrderPositions
