import React from 'react';
import Desktop from './Desktop';
import { FocusFormProvider } from './useFormFocus';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #CFD6EE;
    padding: 36px;
    border-radius: 16px;
    margin-top: 20px;
`

export const AviaSearchForm = () => {
  return (
    <Wrapper>
        <FocusFormProvider>
            <Desktop />
        </FocusFormProvider>
    </Wrapper>
  );
};