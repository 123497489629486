import React from 'react';
import {SvgIcon} from "@mui/material";

const HotelView = (props) => {
	return (
		<SvgIcon {...props} >
			<path d="M6.49969 11.01V7.25201L3.09961 5.95973V11.01H6.49969ZM6.49969 18.01V13.01H3.09961V18.6901L6.49969 18.01ZM3.20029 3.85841C2.18594 3.47288 1.09961 4.22214 1.09961 5.30729V19.239C1.09961 20.2171 1.99446 20.9507 2.95359 20.7589L7.34385 19.8808C7.42594 19.9004 7.51161 19.9107 7.59969 19.9107H16.3997C16.4872 19.9107 16.5723 19.9005 16.6539 19.8812L21.0457 20.7596C22.0048 20.9514 22.8997 20.2178 22.8997 19.2397V5.30799C22.8997 4.22285 21.8134 3.47358 20.799 3.85911L16.1904 5.61072H7.81074L3.20029 3.85841ZM8.59961 17.9107V7.61072H15.3997V17.9107H8.59961ZM17.4997 11.0104V7.25269L20.8997 5.96043V11.0104H17.4997ZM17.4997 18.0108V13.0104H20.8997V18.6908L17.4997 18.0108Z" fill="#2E2E32"/>
		</SvgIcon>
	);
};

export default HotelView;