const formateDate = (dateString) => {
	const date = new Date(dateString);
	const month = date.getMonth() + 1;
	const formattedMonth = month < 10 ? `0${month}` : month;

	return `${date.getDate()}.${formattedMonth}.${date.getFullYear()}`;
}

export default formateDate


export const formatDateToUTC = (date) => {
	const date1 = new Date(date);
	const utcDate = new Date(date1.getTime() - date1.getTimezoneOffset() * 60000);
	return utcDate
}
