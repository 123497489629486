import React, { useCallback, useEffect } from 'react';
import './style.css'
import { Link } from "react-router-dom";
import useAuthStore from "../../pages/Auth/store";
import useSearchTourStore from "../../pages/SearchTour/store";
import { isEmpty } from 'lodash';
import {isMobile} from "react-device-detect";

const menuList = [
	{ name: 'Туры с перелетом', tab: 'search_tour', url: '/search_tour', target: '_self' },
	{
		name: 'Отели | Круизы Fun&Sun',
		tab: 'search_hotel',
		url: '/search_hotel',
		target: '_self',
		isLink: true,
		children: [
			{
				name: 'Отели + Ж/Д билеты',
				target: '_blank',
				url: 'https://b2b.fstravel.com/search_crosstour',
				id: 'search_crosstour'
			}
		]
	},
	{
		id: 'tickets',
		name: 'Авиабилеты',
		tab: 'search-avia',
		url: 'https://b2b.fstravel.com/tickets',
		target: '_blank',
		children: [
			{
				name: 'Регулярные авиабилеты',
				url: '/avia',
				target: '_self'
			},
			{
				tab: 'search-avia',
				id: 'tickets',
				name: 'Чартерные авиабилеты',
				url: 'https://b2b.fstravel.com/tickets',
				target: '_blank'
			}
		]
	},

	{ id: 'cl_wizard', name: 'Конструктор', tab: 'constructor', url: 'https://b2b.fstravel.com/cl_wizard', target: '_blank' },
	{ name: 'Круизы', tab: 'search-cruises', url: 'https://cruises.fstravel.com/searchcruises', target: '_blank' },
	{
		id: 'modern_search',
		name: 'Доп. услуги',
		tab: 'search-services',
		url: 'https://b2b.fstravel.com/modern_search?samo_action=services',
		target: '_blank',
		isLink: false,
		children: [
			{
				name: 'Все',
				url: 'https://b2b.fstravel.com/modern_search?samo_action=services',
				target: '_blank',
			},
			{
				name: 'Трансферы',
				url: '/searchtransfers',
				target: '_self',
			}
		]
	},

]

const SearchMenu = () => {
	const actionsAuth = useAuthStore(({ actions }) => actions)
	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)
	const actionsSearchTour = useSearchTourStore(({ actions }) => actions)

	// const refresh_link = useCallback((id, url) => {
	// 	actionsAuth.refreshSamoLink(id, url)
	// }, [actionsAuth])

	useEffect(() => {
		const getRefreshedLinks = (arr) => {
			arr?.forEach(item => {
				if (samoLinks[item.id]) {
					return
				}
				if (!isEmpty(item.children)) {
					getRefreshedLinks(item.children)
				}
				if (!item.id) {
					return
				}
				// refresh_link(item.id, item.url)
			})

		}
		getRefreshedLinks(menuList)
	}, [samoLinks])

	return (
		<div className={!isMobile ? 'container' : ''}>
			<div className='search-form-wrap menu'>
				<div className='search-menu-btns__wrap'>
					{menuList.map((item, index) => (
						<>
							{!item.children ? <Link
								to={samoLinks[item.id] || item.url}
								target={item.target}
								key={index}
								onClick={() => {
									item.id && actionsAuth.refreshSamoLink(item.id, item.url)
									window.location.pathname.replace('/', '') !== item.tab && actionsSearchTour.clearTours()
								}}
								className={`search-menu__btn ${window.location.pathname.replace('/', '') === item.tab ? 'activated' : ''}`}
							>
								{item.name}
							</Link> :
								<div className={`search-menu__btn ${window.location.pathname.replace('/', '') === item.tab ? 'activatedWithChild' : ''}`} style={{ position: 'relative', minWidth: item.id === 'tickets' ? '222px' : 'fit-content' }}>
									{item.isLink ? <Link
										to={samoLinks[item.id] || item.url}
										target={item.target}
										key={index}
										onClick={() => {
											item.id && actionsAuth.refreshSamoLink(item.id, item.url)
											window.location.pathname.replace('/', '') !== item.tab && actionsSearchTour.clearTours()
										}}
										className='parentItem'
									>
										{item.name}
									</Link> : <div>{item.name}</div>}
									<div className={'children-menu'}>
										{item.children.map(child =>
											<Link to={samoLinks[child.id] || child.url} target={child.target}
												onClick={() => {
													child.id && actionsAuth.refreshSamoLink(child.id, child.url)
													window.location.pathname.replace('/', '') !== child.tab && actionsSearchTour.clearTours()
												}}>
												{child.name}
											</Link>)
										}
									</div>
								</div>
							}
						</>
					))}
				</div>
			</div>
		</div>
	)
}

export default SearchMenu
