import React,{ useState } from 'react'

import { Alert } from '@mui/material'
import { Box, TextField } from '@material-ui/core'

import { nationality } from '../PaxesBlock/nationalities'
import { emailRegExp, phoneRegExp, cyrillicRegExp } from '../../../constants/reg-exps'
import CustomSelect from '../../Select'

const BUYER_TYPE = [
    {
        name: 'Физическое лицо',
        id: '0'
    },
    {
        name: "Юридическое лицо",
        id: "1",
    },
    {
        name: "Индивидуальный предприниматель",
        id: "2",
    },
]

function BuyerBlock({ store }) {
    const buyer = store?.buyer
    const paxes = store?.paxes
    const actions = store.actions
    const isBookingError = store.isBookingError
    const [errors, setErrors] = useState({
        name: null,
        phone: null,
        email: null,
        pserie: null,
        number: null,
        address: null,
        nationalityKey: null,
        agency_docdate: null,
        agency_docnumber: null,
        agency_docsum: null,
        inn: null,
        boss: null
    })
    const [selectedPaxe, setSelectedPaxe] = useState(null)

    const changeBuyerSelect = props => event => {
        actions.changeBuyer(props, event)
    }

    const changeNameHandler = prop => event => {
        setErrors(prev => ({ ...prev, [prop]: null}))
        switch (prop) {
            case 'name':
            case 'boss':
                if (
                    !cyrillicRegExp.test(event.target.value) &&
                    event.target.value !== ''
                ) {
                    setErrors(prev => ({
                        ...prev,
                        [prop]: `Допускается использование только кириллицы`
                    }))
                } else if (!/^\S+\s+\S+(?:\s+\S+)?$/.test(event.target.value) &&
                    event.target.value !== '') {
                    if (!/^\s*\S+(\s+\S+){0,2}\s*$/.test(event.target.value)) {
                        setErrors(prev => ({
                            ...prev,
                            [prop]: `Максимально допустимо 3 слова через пробел`
                        }))
                    } else {
                        setErrors(prev => ({
                            ...prev,
                            [prop]: `Укажите фамилию, имя и отчество клиента полностью`
                        }))
                    }
                } else {
                    setErrors(prev => ({...prev, [prop]: null }))
                }
                actions.changeBuyer(prop, event.target.value?.toUpperCase())
                break

            case 'firmName':
				if (
					!/^[а-яА-Яa-zA-Z0-9-–.,"Ё\u0020]+$/.test(event.target.value) &&
					event.target.value !== ''
				) {
                    setErrors(prev => ({
                        ...prev,
                        [prop]: `Официальное название должно содержать только кириллицу, латиницу, цифры или спец.символы`
                    }))
				} else {
                    setErrors(prev => ({...prev, [prop]: null }))
                }
				actions.changeBuyer(prop, event.target.value?.toUpperCase())
                break

            default:
                break
        }   
    }


    const changeAddressHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (
            !/[a-zA-Zа-яА-Я0-9\s\-\–\.,\/]+/g.test(event.target.value) &&
            event.target.value !== ''
        ) {
            setErrors({
                ...errors,
                [prop]: `Адрес должнен содержать только латиницу, кириллицу или спец.символы`
            })
        } else {
            setErrors({ ...errors, [prop]: null })
        }
        actions.changeBuyer(prop, event.target.value)
    }

    const changePhoneHandler = prop => event => {
        setErrors(prev => ({ ...prev, [prop]: null }))
        if (!phoneRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors(prev => ({
                ...prev,
                [prop]: `Номер телефона указан некорректно`
            }))
        } else {
            setErrors(prev => ({ ...prev, [prop]: null }))
        }
        actions.changeBuyer(prop, event.target.value)
    }

    const changeEmailHandler = prop => event => {
        setErrors(prev => ({...prev, [prop]: null}))
        if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors(prev => ({ 
                ...prev, 
                [prop]: `Email указан некорректно` 
			}))
        } else {
            setErrors(prev => ({...prev, [prop]: null }))
        }
        actions.changeBuyer(prop, event.target.value)
    }

    const changeNumberHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (
            !/^[0-9a-zA-Z]+$/.test(event.target.value) &&
            event.target.value !== ''
        ) {
            setErrors({
                ...errors,
                [prop]: `Указан некорректно. Допустим ввод цифр и латиницы`
            })
        } else {
            setErrors({ ...errors, [prop]: null })
        }
        actions.changeBuyer(prop, event.target.value)
    }

    const changeInnHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (!/[0-9]/g.test(event.target.value) && event.target.value !== '') {
            setErrors({
                ...errors,
                [prop]: `ИНН указан неверно. Допустим ввод только цифр`
            })
        } else {
            setErrors({ ...errors, [prop]: null })
        }
        actions.changeBuyer(prop, event.target.value)
    }

    const fillFields = (paxe) => {
        changeNameHandler('name')({target: {value:  `${paxe.person?.lastName ?? ''} ${paxe.person?.firstName ?? ''}${paxe.person?.patronymic ? ' ' + paxe.person?.patronymic : ''}`}})
        changePhoneHandler('phone')({target: {value: paxe.contactInformation?.phone}})
        changeEmailHandler('email')({target: {value: paxe.contactInformation?.email}})
    }

    const selectPaxe = value => {
        const existPaxe = paxes?.find(paxe => paxe.id === value)
        if (!existPaxe) {
            return
        }
        setSelectedPaxe(value)
        fillFields(existPaxe)
    }

    React.useEffect(() => {
        if (selectedPaxe) {
            fillFields(paxes?.find(paxe => paxe.id === selectedPaxe))
        }
    }, [selectedPaxe, paxes])


    React.useEffect(() => {
        if (isBookingError) {
            setServerError(isBookingError)
        }
    }, [isBookingError])

    const setServerError = (err) => {
        const errText = err?.split(': ')[1]

        if (errText?.includes(`У покупателя`)) {

            let typeError
            if (errText.includes('некорректно указан e-mail')) {
                typeError = 'email'
            }
            // if (errText.includes('Проверьте срок действия паспорта')) {
            //     typeError = 'passportExpirationDate'
            // }
            switch (typeError) {
                case 'email':
                    setErrors((prev) => ({
                        ...prev,
                        email: 'Email указан некорректно'
                    }));
                    actions.changeIsReadyForBooking(false)

                    document.getElementById(`bayerBlock`).scrollIntoView({ behavior: 'smooth' })
                    break;
                // case 'passportExpirationDate':
                //     setErrors((prev) => ({
                //         ...prev,
                //         passportExpirationDate: 'Проставьте даты +5лет'
                //     }));

                //     document.getElementById(`bayerBlock`).scrollIntoView({ behavior: 'smooth' })
                //     break;
                default:
                    return null
            }
        }
    }

    return (
        <>
            <div className='block-header' id='bayerBlock'>
                <h3 className='title'>
                    <span>Информация о заказчике</span>
                </h3>
            </div>

            <Alert severity='error' sx={{ width: '100%' }}>
                Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
                информацию о заказчике!
            </Alert>
            <div className='members-form'>
                <div className='info'>
                    <Box style={{ marginBottom: 15 }}>
                        <CustomSelect
                            value={buyer?.buyer_type}
                            options={BUYER_TYPE}
                            title='Тип клиента'
                            count={1}
                            onChange={changeBuyerSelect('buyer_type')}
                            required
                        />
                    </Box>
                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={selectedPaxe}
                                options={paxes?.map(paxe => ({
                                    id: paxe?.id,
                                    name: `Турист №${paxe.id}`
                                }))}
                                title='Турист из заявки'
                                count={1}
                                onChange={selectPaxe}
                                required
                            />
                        </Box>
                    )}

                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='ФИО'
                                variant='outlined'
                                fullWidth
                                value={buyer?.name || ''}
                                onChange={changeNameHandler('name')}
                                helperText={errors.name}
                                // onBlur={(event) => onBlur('lname', event)}
                                error={!!errors.name}
                                required
                                title='Допустим ввод латиницы, кириллицы и спец.символов "-", " ", "–"'
                            />
                        </Box>
                    )}

                    {/* <Box style={{ marginBottom: 15 }}>
						<TextField
							label='Адрес'
							variant='outlined'
							fullWidth
							value={buyer?.address || ''}
							onChange={changeAddressHandler('address')}
							helperText={errors?.address}
							// onBlur={(event) => onBlur('lname', event)}
							error={!!errors?.address}
							required
							title=''
						/>
					</Box>
					{buyer?.buyer_type !== '1' && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Серия документа'
								variant='outlined'
								fullWidth
								helperText={errors.pserie}
								error={!!errors.pserie}
								value={buyer?.pserie || ''}
								onChange={changeNumberHandler('pserie')}
								title='Серия паспорта может содержать только цифры и латинские буквы'
								required
							/>
						</Box>
					)}
					{buyer?.buyer_type !== '1' && (
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Номер документа'
								variant='outlined'
								fullWidth
								helperText={errors.number}
								error={!!errors.number}
								value={buyer?.number || ''}
								onChange={changeNumberHandler('number')}
								title='Номер документа должен содержать только цифры и латинские буквы'
								required
							/>
						</Box>
					)} */}
                    {buyer?.buyer_type === '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Официальное название'
                                variant='outlined'
                                fullWidth
                                helperText={errors.firmName}
                                error={!!errors.firmName}
                                value={buyer?.firmName || ''}
                                onChange={changeNameHandler('firmName')}
                                required
                            />
                        </Box>
                    )}

                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label='Телефон'
                            variant='outlined'
                            fullWidth
                            helperText={errors.phone}
                            error={!!errors.phone}
                            value={buyer?.phone || ''}
                            onChange={changePhoneHandler('phone')}
                            required
                        />
                    </Box>
                    {buyer?.buyer_type === '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='ФИО руководителя'
                                variant='outlined'
                                fullWidth
                                value={buyer?.boss || ''}
                                onChange={changeNameHandler('boss')}
                                helperText={errors.boss}
                                // onBlur={(event) => onBlur('lname', event)}
                                error={!!errors.boss}
                                required
                                title='Допустим ввод латиницы, кириллицы и спец.символов "-", " ", "–"'
                            />
                        </Box>
                    )}
                    <Box>
                        <TextField
                            label='E-Mail'
                            variant='outlined'
                            fullWidth
                            helperText={errors.email}
                            error={!!errors.email}
                            value={buyer?.email || ''}
                            onChange={changeEmailHandler('email')}
                            required
                        />
                    </Box>
                </div>
                <div className='docs'>
                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.nationalityKey || '210357'}
                                options={nationality}
                                title='Гражданство'
                                count={1}
                                // onChange={actions.changeNationality}
                                onChange={changeBuyerSelect('nationalityKey')}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type === '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.firm_state || '210357'}
                                options={nationality}
                                title='Страна'
                                count={1}
                                // onChange={actions.changeNationality}
                                onChange={changeBuyerSelect('firm_state')}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.identity_document || 1}
                                options={[
                                    { id: 5, name: 'Иной документ' },
                                    { id: 1, name: 'Паспорт' },
                                    { id: 3, name: 'Свидетельство о рождении' },
                                    { id: 4, name: 'Заграничный паспорт' },
                                    { id: 0, name: 'ID карта' }
                                ]}
                                title='Тип документа'
                                count={1}
                                onChange={changeBuyerSelect('identity_document')}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type === '2' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='ИНН'
                                variant='outlined'
                                fullWidth
                                helperText={errors.inn}
                                error={!!errors.inn}
                                value={buyer?.inn || ''}
                                onChange={changeInnHandler('inn')}
                                required
                            />
                        </Box>
                    )}

                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Серия документа'
                                variant='outlined'
                                fullWidth
                                helperText={errors.pserie}
                                error={!!errors.pserie}
                                value={buyer?.pserie || ''}
                                onChange={changeNumberHandler('pserie')}
                                title='Серия паспорта может содержать только цифры и латинские буквы'
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type !== '1' && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Номер документа'
                                variant='outlined'
                                fullWidth
                                helperText={errors.number}
                                error={!!errors.number}
                                value={buyer?.number || ''}
                                onChange={changeNumberHandler('number')}
                                title='Номер документа должен содержать только цифры и латинские буквы'
                                required
                            />
                        </Box>
                    )}
                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label='Адрес'
                            variant='outlined'
                            fullWidth
                            value={buyer?.address || ''}
                            onChange={changeAddressHandler('address')}
                            helperText={errors?.address}
                            // onBlur={(event) => onBlur('lname', event)}
                            error={!!errors?.address}
                            required
                            title=''
                        />
                    </Box>
                    {/* <Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={changeBuyerSelect('agency_docdate')}
							count={1}
							label='Дата договора с клиентом'
							value={buyer?.agencydocdate || null}
							required
						/>
					</Box>
					{/* <Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер договора с клиентом'
							variant='outlined'
							fullWidth
							helperText={errors.agency_docnumber}
							error={!!errors.agency_docnumber}
							value={buyer?.agency_docnumber || ''}
							onChange={changeDocNumber('agency_docnumber')}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Сумма договора с клиентом'
							variant='outlined'
							fullWidth
							helperText={errors.agency_docsum}
							error={!!errors.agency_docsum}
							value={buyer?.agency_docsum || ''}
							onChange={changeDocSum('agency_docsum')}
							required
						/>
					</Box> */}
                </div>
            </div>
        </>
    )
}

export default BuyerBlock
