import React from "react"
import styled from "styled-components"
import DesktopBody from "./Desktop/Body"

const Wrapper = styled.div`
  // border: 1px solid #DCDCDC;
  // box-sizing: border-box;
  // border-radius: 10px;
  // margin-bottom: 30px;
`

const Description = React.memo(props => {
  return (
    <Wrapper>
        <DesktopBody {...props} />
    </Wrapper>
  )
})

export default Description
