import React from "react"
import Router from "./Router"
import { CreateFormProvider } from "./useProps"
import { useDispatch } from "react-redux"
import { addAppealPurify } from "../../../store/modules/appeals"


const CreateFormPopup = ({
  orderPosition,
  defaultSubject,
  onlyOtherSubject,
  onlyOtherRefundReturnSubjects,
  insurances,
  ...props
}) => {
  const dispatch = useDispatch()

  React.useEffect(
    () => {
      // if(!props.open) {
        dispatch(addAppealPurify());
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.open]
  )

  return (
      <CreateFormProvider
        {...{
          orderPosition,
          defaultSubject,
          onlyOtherSubject,
          onlyOtherRefundReturnSubjects,
          insurances
        }}
      >
          <Router {...props} />
      </CreateFormProvider>
  )
}

export default CreateFormPopup
