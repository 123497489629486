import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  padding: none;
  text-align: center;
  width: 30px;
  margin: 0 1px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

export const ButtonBase = css`
  background: #4872F2;
  width: 24px;
  height: 24px;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  &:disabled {
    background: #B9B9B9;
  }
`;


export const Plus = styled.button`
  ${ButtonBase}
  &:before, &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 1px;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  ${({ outlined }) =>
    outlined
      ? `
      background: #ffffff;
      &:before, &:after {
        background-color: #4872F2;
      }
      border: 1px solid #4872F2;
      &:disabled {
        &:before, &:after {
          background-color: #B9B9B9;
        }
        border: 1px solid #B9B9B9;
        background: #ffffff;
      }
  `
      : ''}
`;

export const Minus = styled.button`
  ${ButtonBase}
  &:before {
    position: absolute;
    content: ' ';
    width: 14px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 1px;
  }
  ${({ outlined }) =>
    outlined
      ? `
      background: #ffffff;
      &:before, &:after {
        background-color: #4872F2;
      }
      border: 1px solid #4872F2;
      &:disabled {
        &:before {
          background-color: #B9B9B9;
        }
        border: 1px solid #B9B9B9;
        background: #ffffff;
      }
  `
      : ''}
`;
