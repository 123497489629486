
import React from 'react';
import { SvgIcon } from "@mui/material";

const Calendar = (props) => {
    return (
        <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" style={{ width: '18px', height: '18px' }}>
            <path xmlns="http://www.w3.org/2000/svg" d="M14.8337 2.33268H13.167V1.49935C13.167 1.27834 13.0792 1.06637 12.9229 0.910093C12.7666 0.753813 12.5547 0.666016 12.3337 0.666016C12.1126 0.666016 11.9007 0.753813 11.7444 0.910093C11.5881 1.06637 11.5003 1.27834 11.5003 1.49935V2.33268H6.50033V1.49935C6.50033 1.27834 6.41253 1.06637 6.25625 0.910093C6.09997 0.753813 5.88801 0.666016 5.66699 0.666016C5.44598 0.666016 5.23402 0.753813 5.07774 0.910093C4.92146 1.06637 4.83366 1.27834 4.83366 1.49935V2.33268H3.16699C2.50395 2.33268 1.86807 2.59607 1.39923 3.06492C0.930384 3.53376 0.666992 4.16964 0.666992 4.83268V14.8327C0.666992 15.4957 0.930384 16.1316 1.39923 16.6005C1.86807 17.0693 2.50395 17.3327 3.16699 17.3327H14.8337C15.4967 17.3327 16.1326 17.0693 16.6014 16.6005C17.0703 16.1316 17.3337 15.4957 17.3337 14.8327V4.83268C17.3337 4.16964 17.0703 3.53376 16.6014 3.06492C16.1326 2.59607 15.4967 2.33268 14.8337 2.33268ZM15.667 14.8327C15.667 15.0537 15.5792 15.2657 15.4229 15.4219C15.2666 15.5782 15.0547 15.666 14.8337 15.666H3.16699C2.94598 15.666 2.73402 15.5782 2.57774 15.4219C2.42146 15.2657 2.33366 15.0537 2.33366 14.8327V8.99935H15.667V14.8327ZM15.667 7.33268H2.33366V4.83268C2.33366 4.61167 2.42146 4.39971 2.57774 4.24343C2.73402 4.08715 2.94598 3.99935 3.16699 3.99935H4.83366V4.83268C4.83366 5.0537 4.92146 5.26566 5.07774 5.42194C5.23402 5.57822 5.44598 5.66602 5.66699 5.66602C5.88801 5.66602 6.09997 5.57822 6.25625 5.42194C6.41253 5.26566 6.50033 5.0537 6.50033 4.83268V3.99935H11.5003V4.83268C11.5003 5.0537 11.5881 5.26566 11.7444 5.42194C11.9007 5.57822 12.1126 5.66602 12.3337 5.66602C12.5547 5.66602 12.7666 5.57822 12.9229 5.42194C13.0792 5.26566 13.167 5.0537 13.167 4.83268V3.99935H14.8337C15.0547 3.99935 15.2666 4.08715 15.4229 4.24343C15.5792 4.39971 15.667 4.61167 15.667 4.83268V7.33268Z" fill="#2E2E32" />
        </SvgIcon>
    );
};

export default Calendar;