import logo7468 from '../../../../asset/images/insurance/biglogo_7468.svg'
import logo7469 from '../../../../asset/images/insurance/biglogo_7469.svg'
import logo7470 from '../../../../asset/images/insurance/biglogo_7470.svg'
import logo7471 from '../../../../asset/images/insurance/biglogo_7471.svg'
import logo7472 from '../../../../asset/images/insurance/biglogo_7472.svg'
import logo7659 from '../../../../asset/images/insurance/biglogo_7659.svg'
import logo7785 from '../../../../asset/images/insurance/biglogo_7785.svg'
import logo7789 from '../../../../asset/images/insurance/biglogo_7789.svg'
import logo7809 from '../../../../asset/images/insurance/biglogo_7809.svg'
import logo7874 from '../../../../asset/images/insurance/biglogo_7874.svg'
import logo7900 from '../../../../asset/images/insurance/biglogo_7900.svg'
import logo7906 from '../../../../asset/images/insurance/biglogo_7906.svg'
import logo7932 from '../../../../asset/images/insurance/biglogo_7932.svg'
import logo7962 from '../../../../asset/images/insurance/biglogo_7962.svg'
import logo8105 from '../../../../asset/images/insurance/biglogo_8105.svg'
import logo77459 from '../../../../asset/images/insurance/biglogo_77459.svg'
import logo99242 from '../../../../asset/images/insurance/biglogo_99242.svg'
import logo126810 from '../../../../asset/images/insurance/biglogo_126810.svg'
import logo147347 from '../../../../asset/images/insurance/biglogo_147347.svg'
import logo147351 from '../../../../asset/images/insurance/biglogo_147351.svg'
import logo152847 from '../../../../asset/images/insurance/biglogo_152847.svg'
import logo152929 from '../../../../asset/images/insurance/biglogo_152929.svg'
import logo165961 from '../../../../asset/images/insurance/biglogo_165961.svg'

export const logos = {
	7468: logo7468,
	7469: logo7469,
	7470: logo7470,
	7471: logo7471,
	7472: logo7472,
	7659: logo7659,
	7785: logo7785,
	7789: logo7789,
	7809: logo7809,
	7874: logo7874,
	7900: logo7900,
	7906: logo7906,
	7932: logo7932,
	7962: logo7962,
	8105: logo8105,
	77459: logo77459,
	99242: logo99242,
	126810: logo126810,
	147347: logo147347,
	147351: logo147351,
	152847: logo152847,
	152929: logo152929,
	165961: logo165961,
}