import React from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@mui/material/Collapse'

import { Button } from '@material-ui/core'
// import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as CloseImg } from '../../../asset/images/cabinet/close.svg'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale';
import NewMessageModal from './NewMessageModal'
import SingleMessageModal from './SingleMessageModal'


const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 24px;
	background-color: #fff;
	width: 866px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-height: 100vh;
	overflow-y: auto;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const PopupTitle = styled.h2`
	color: #2e2e32;
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`

const ButtonFilled = styled(Button)`
	align-self: end;

	background-color: #4872f2;
	padding: 8px 20px;
	border-radius: 8px;
	box-shadow: none;
	min-width: fit-content;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
	&:hover {
		box-shadow: none;
	}
`

const Table = styled.div`
	padding: 0 24px 24px 24px;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 340px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 4px;
		border-radius: 8px;
		background-color: #D4D9DE;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #4872F2;
	}
`

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 202px 410px 72px;
	gap: 40px;
`

const TabelText = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`

const TabelRow = styled(TableGrid)`
	padding: 24px 0;
	border-bottom: 1px solid #dbe0e5;
	&:last-child {
		border-bottom: none;
	}
	&:first-child {
		padding-top: 0;
	}
`

const ExpandedBlock = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	border: 1px solid #dbe0e5;
`

const FlexRow = styled(Row)`
	padding: 24px;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	> span {
		color: #4872f2;
	}
`

export default function MessagesModal({ onClose, messages, unreadMessages }) {
    const [modalVisibleNumber, setModalVisibleNumber] = React.useState(1);
	const [currentMessage, setCurrentMessage] = React.useState(null);

	const [isOpen, setIsOpen] = React.useState(true);

	const sortedMessages = [...messages].sort((a, b) => {
		return new Date(b.createDate) - new Date(a.createDate)
	})

	const sortedUnreadMessages = [...unreadMessages].sort((a, b) => {
		return new Date(b.createDate) - new Date(a.createDate)
	})

	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	const handleClose = () => {
		onClose(false)
	}

	const handleOpenSingleMessageModal = (claimId) => {
		setCurrentMessage(claimId)
		setModalVisibleNumber(3)
	}

	return (
		<>
		{modalVisibleNumber === 1 && (
			<PopupBackground>
			<PopupContent>
				<Row>
					<PopupTitle>Сообщения</PopupTitle>
					<CloseBtn onClick={() => handleClose()} />
				</Row>
				<ExpandedBlock>
					<FlexRow
						onClick={() => setIsOpen(!isOpen)}
						style={{ cursor: 'pointer' }}>
						<Title>Непрочитанные <span>({unreadMessages.length} новое сообщение)</span></Title>
						<ExpandMoreIcon
							style={
								isOpen ? { transform: 'rotate(180deg)' } : null
							}
						/>
					</FlexRow>
					<Collapse in={isOpen}>
						<Table>
							{sortedUnreadMessages.map((message) => (
								<TabelRow key={message.messageId}>
									<TabelText>{message.author.length > 17 ? message.author.slice(0, 17) + '...' : message.author}</TabelText>
									<TabelText onClick={() => handleOpenSingleMessageModal(message)} style={{ cursor: 'pointer' }}>
										<b>{`Заявка ${message.claimId} [${message.type.length > 22 ? message.type.slice(0, 22) + '...' : message.type}]`}</b>
									</TabelText>
									<TabelText>
										{format(new Date(message.createDate),'dd MMM',{ locale: ru })}
									</TabelText>
								</TabelRow>
							))}
						</Table>
					</Collapse>
				</ExpandedBlock>
				<ExpandedBlock>
					<FlexRow
						onClick={() => setIsOpen(!isOpen)}
						style={{ cursor: 'pointer' }}>
						<Title>Все сообщения</Title>
						<ExpandMoreIcon
							style={
								!isOpen ? { transform: 'rotate(180deg)' } : null
							}
						/>
					</FlexRow>
					<Collapse in={!isOpen}>
						<Table>
							{sortedMessages.map((message) => (
								<TabelRow key={message.messageId}>
									<TabelText>{message.author.length > 17 ? message.author.slice(0, 17) + '...' : message.author}</TabelText>
									<TabelText onClick={() => handleOpenSingleMessageModal(message)} style={{ cursor: 'pointer' }}>
									<b>{`Заявка ${message.claimId} [${message.type.length > 22 ? message.type.slice(0, 22) + '...' : message.type}]`}</b>
									</TabelText>
									<TabelText>
										{format(new Date(message.createDate), 'dd MMM',{ locale: ru })}
									</TabelText>
								</TabelRow>
							))}
						</Table>
					</Collapse>
				</ExpandedBlock>
				
					<ButtonFilled
						onClick={() => setModalVisibleNumber(2)}
						fullWidth
						// disabled={loading}
						variant='contained'
						color='primary'>
						Новое сообщение
					</ButtonFilled>
			</PopupContent>
		</PopupBackground>
		)}
		{modalVisibleNumber === 2 && <NewMessageModal onClose={() => setModalVisibleNumber(1)} />}
		{modalVisibleNumber === 3 && <SingleMessageModal message={currentMessage} onClose={() => setModalVisibleNumber(1)} onCloseAllModals={() => onClose(false)}/>}
		</>
		
	)
}
