import React from 'react'

import { Box } from '@mui/material'

import styled from 'styled-components'

import useYachtRentServicesStore from '../../../store'
import ServiceFilter from '../../../../../components/Services/ServiceFilter'
import { isEmpty } from 'lodash'

const Clear = styled.div`
	color: #4872f2;
	cursor: pointer;
	display: inline-block;
	user-select: none;
	text-align: center;

	font-family: Open Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`

export default function ServiceFiltersBlock() {
	const actions = useYachtRentServicesStore(({ actions }) => actions)

	const excursionType = useYachtRentServicesStore(
		({ excursionType }) => excursionType
	)

	const checkedExcursionType = useYachtRentServicesStore(
		({ checkedExcursionType }) => checkedExcursionType
	)

	const actionsType = useYachtRentServicesStore(({ actionsType }) => actionsType)

	const checkedActionsType = useYachtRentServicesStore(
		({ checkedActionsType }) => checkedActionsType
	)

	return (
		<Box>
			<ServiceFilter
				title='Тип яхты'
				list={excursionType}
				checked={checkedExcursionType}
				changeHandler={actions.changeExcursionType}
			/>
			<ServiceFilter
				title='Для кого'
				list={actionsType}
				checked={checkedActionsType}
				changeHandler={actions.changeActionType}
			/>
			{(!isEmpty(checkedExcursionType) || !isEmpty(checkedActionsType)) && (
				<Clear onClick={() => actions.clearFilters()}>
					{' '}
					Сбросить все фильтры{' '}
				</Clear>
			)}
		</Box>
	)
}
