import * as React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Auth from "../pages/Auth";
import useAuthStore from "../pages/Auth/store";
import useBookingStore from "../pages/Booking/store";
import { default as useNewBookingStore } from "../pages/newBooking/store";
import useTransferStore from "../pages/Partner/TransferKiwi/store"
// import useAuthStore from "../../pages/Auth/store";
import useSearchTourStore from "../pages/SearchTour/store";
import { Outlet } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "./style.css"
import Nav from "../components/Nav";
import NewNav from "../components/NewNav";
import Footer from "../components/Footer";
// import {decrypt} from "../functions/decrypt";
import AuthCrm from "../pages/Auth/AuthCrm";
import { YMInitializer } from "react-yandex-metrika";
import AlertLoading from "../components/AlertLoading";
import useInsuranceStore from "../pages/Partner/Insurances/store";
import useHotelServiceStore from "../pages/HotelServices/Booking/store";
import useExcursionBookingStore from "../pages/Excursion/Booking/store";
import useAirportServiceBookingStore from "../pages/AirportServices/Booking/store";
import { default as useYachtRentBookingStore } from '../pages/YachtRentServices/Booking/store'

const OLD_BTN_PAGES = [ //для текущая ветки мастера
    '/search_tour',
    '/search_hotel',
    '/booking',
    '/avia',
    '/cabinet',
    '/bookingnew',
]

export default function MainTemplate({ errorElement = null }) {
    const searchLoading = useSearchTourStore(({ loading }) => loading)
    const bookingLoading = useBookingStore(({ loading }) => loading)
    const newBookingLoading = useNewBookingStore(({ newBookingLoading }) => newBookingLoading)
    const transferBookingLoading = useTransferStore(({ loading }) => loading)
    const loadingUpdateBasketTransfer = useTransferStore(({ loadingUpdateBasket }) => loadingUpdateBasket)
    const insuranceBookingLoading = useInsuranceStore(({loading}) => loading)
    const loadingUpdateBasketInsurance = useInsuranceStore(({loadingUpdateBasket}) => loadingUpdateBasket)
    const airportLoading = useAirportServiceBookingStore(({ loading }) => loading)
    const airportReadyForBooking = useAirportServiceBookingStore(({ isReadyForBooking }) => isReadyForBooking)

    const partnerBookingLoading = transferBookingLoading || loadingUpdateBasketTransfer || insuranceBookingLoading || loadingUpdateBasketInsurance

    const agent = useAuthStore(({ agent }) => agent)
    const isLogOut = useAuthStore(({ isLogOut }) => isLogOut)
    const crmAuthLogOut = useAuthStore(({ crmAuthLogOut }) => crmAuthLogOut)
    const actions = useAuthStore(({ actions }) => actions)
    const TTEToken = useAuthStore(({ tokenTTE }) => tokenTTE)
    const isAvia = window.location.pathname.includes('/avia')
    const isCabinet = window.location.pathname.includes('/cabinet')
    const hotelServiceLoading = useHotelServiceStore(({ loading }) => loading)
    const hotelServiceReadyForBooking = useHotelServiceStore(({ isReadyForBooking }) => isReadyForBooking)
    const excursionLoading = useExcursionBookingStore(({ loading }) => loading)
    const excursionReadyForBooking = useExcursionBookingStore(({ isReadyForBooking }) => isReadyForBooking)
    const yachtLoading = useYachtRentBookingStore(({ loading }) => loading)
    const yachtReadyForBooking = useYachtRentBookingStore(({ isReadyForBooking }) => isReadyForBooking)
    const reserveInsuaranceLoading = useInsuranceStore(({ reserveInsuaranceLoading }) => reserveInsuaranceLoading)
    const token = getCookie('access_token')
    const showOldBtn = OLD_BTN_PAGES.includes(useLocation().pathname)

    const isServicesLoading = hotelServiceLoading || yachtLoading || excursionLoading || airportLoading
    const isServicesReadyForBooking = hotelServiceReadyForBooking || yachtReadyForBooking || excursionReadyForBooking || airportReadyForBooking


    const auth = new URLSearchParams(window.location.search)
    // const location = useLocation()

    // useEffect(() => {
    //     if (auth.get('auth')) {
    //         let authData = decrypt(location.search.replace('?auth=',''))
    //         if (authData) {
    //             authData = JSON.parse(authData)
    //             actions.signin(authData.login, authData.password)
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, []);

    if (token && !agent && !isLogOut) {
        actions.externalSignin(token)
    }

    useEffect(() => {
        actions.refreshToken()
        actions.refreshTokenTTE()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            actions.refreshToken()
            actions.refreshTokenTTE()
        }, 20 * 60 * 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    if (auth.get('auth') && !agent && !isLogOut && !crmAuthLogOut) {
        return <AuthCrm />
    }

    return (
        <div className="App">
            {(searchLoading || bookingLoading || partnerBookingLoading || newBookingLoading || isServicesLoading) &&
                <div style={{ position: 'sticky', top: 0, zIndex: 10, width: '100%' }}>
                    <LinearProgress
                        style={{height: 8, background: 'linear-gradient(90deg, #4872F2 0%, #D5D9FF 51.9%, #6C60FF 100%)'}}  />
                </div>}
            {(reserveInsuaranceLoading) &&
                <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={reserveInsuaranceLoading} title={'Бронирование выполняется'}/>
                </div>}
            {(searchLoading) &&
                <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={searchLoading} title={'Поиск выполняется'}/>
                </div>}
            {(newBookingLoading) &&
                <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={newBookingLoading}/>
                </div>}
            {(transferBookingLoading ) &&
                <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={transferBookingLoading } title={'Бронирование выполняется'}/>
                </div>}
            {(loadingUpdateBasketTransfer) && <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={loadingUpdateBasketTransfer } title={'Выполняется проверка'}/>
                </div>}
            {(loadingUpdateBasketInsurance) && <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                <AlertLoading isOpen={loadingUpdateBasketInsurance } title={'Выполняется проверка'}/>
            </div>}
            {isServicesLoading &&
                <div style={{ position: 'sticky', top: 135, zIndex: 10, width: '100%' }}>
                    <AlertLoading isOpen={isServicesLoading} title={isServicesReadyForBooking && "Бронирование выполняется"} />
                </div>
            }


            {showOldBtn && <Nav showBtn={showOldBtn} />}
            {!showOldBtn && <NewNav showBtn={showOldBtn} />}
            {agent ? <div className="content"><Outlet /></div> : <Auth />}
            {agent && !isAvia && !isCabinet && <Footer />}
            {isAvia && TTEToken && <Footer />}
            {isCabinet && TTEToken && <Footer />}
            <YMInitializer accounts={[94869244]} />
        </div>
    )
}
