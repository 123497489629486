import React from 'react';
import styled from 'styled-components';
import { Text as Typography } from "../../ui"
import moment from 'moment';



const SegmentBasePart = styled.div`
  display: flex;
  align-items: flex-start;

  & > :first-child {
    margin-right: 11px;
  }

  @media (max-width: 767px) {
    & > :first-child {
      margin-right: 5px;
    }
  }
`

const Wrapper = styled.div`
  padding: 15px 0;
`

const Head = styled.div`
  width: 100%;

  & > :first-child {
    display: block;
    margin-bottom: 8px;
  }
`

const MobileSegmentDescription = styled.div`
  margin-top: 15px;
  & > * {
    display: block;
    margin-bottom: 5px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const GridBlock = styled.div`
  display: grid;
  grid-template-columns: 253px 1fr 85px;

  @media (max-width: 767px) {
    display: block;
  }
`

const BigText = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`

const Img = styled.img`
  width: 34px;
  height: 34px;

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`



export default function Segment(props) {
    return (
        <Wrapper>
            <SegmentBasePart>
                <Img src={props.icon} />
                <Head>
                    <BigText>
                        {props.departureCity?.name} - {props.arrivalCity?.name}{" "}
                        <DesktopText> ({props.departureCity?.code} - {props.arrivalCity?.code}) </DesktopText>
                    </BigText>
                    <GridBlock>
                        <Text> {moment(props.departureDate).format('DD MMM HH:mm')} - {moment(props.arrivalDate).format('DD MMM HH:mm')} </Text>
                        <Text> {props.company} </Text>
                        <Text> {props.flightNumber} </Text>
                    </GridBlock>
                </Head>
            </SegmentBasePart>
            <MobileSegmentDescription>
                <Text> <strong>Авиакомпания:</strong> {props.company} </Text>
                <Text> <strong>Номер рейса:</strong> {props.flightNumber} </Text>
            </MobileSegmentDescription>
      </Wrapper>
    );
}