import React from "react"
import styled from "styled-components"
import greenOk from "../../../asset/images/avia/agents/greenOk.svg"
import yellowOk from "../../../asset/images/avia/agents/yellowOk.svg"
import redCancel from "../../../asset/images/avia/agents/redCancel.svg"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px 40px;
`

const Title = styled.div`
  font-family: "Open Sans", serif;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px !important;
`

const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-bottom: 24px;
`

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 60%;
    /* height: 550px; */
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`

const GreenOk = styled.img.attrs({ src: greenOk })`
  margin-right: 7px;
`

const YellowOk = styled.img.attrs({ src: yellowOk })`
  margin-right: 7px;
`

const RedCancel = styled.img.attrs({ src: redCancel })`
  margin-right: 7px;
`

const BlueZone = styled.div`
  display: flex;
  background: #eef2ff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const SubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const AgentUsersPopUp = ({ agentUsersPopUpOpen, setAgentUsersPopUpOpen }) => {
  return (
    <StyledPopup
      onClose={() => setAgentUsersPopUpOpen(false)}
      open={agentUsersPopUpOpen}
    >
      <CloseButton
        aria-label="Закрыть окно"
        onClick={() => setAgentUsersPopUpOpen(false)}
      >
        <Icon/>
      </CloseButton>
      <Container>
        <Title>Какую роль назначить пользователю? </Title>
        <Text>
          Добавляйте пользователей к агенту, заполнив email и назначив роль. Мы
          выполним поиск по совпадению в Самотуре и добавим нового пользователя
          автоматически, либо предложим завести самостоятельно.
        </Text>
        <BlueZone>
          <Column>
            <SubTitle>Бухгалтер</SubTitle>
            <FlexRow>
              <GreenOk /> <SubText>Полный раздел финансов и отчетов</SubText>
            </FlexRow>
            <FlexRow>
              <GreenOk /> <SubText>Просмотр всех заявок</SubText>
            </FlexRow>
            <FlexRow>
              <RedCancel />{" "}
              <SubText>Настройка сбора и добавление пользователей</SubText>
            </FlexRow>
          </Column>
          <Column>
            <SubTitle>Менеджер</SubTitle>
            <FlexRow>
              <YellowOk />{" "}
              <SubText>Неполный раздел финансов и отчетов </SubText>
            </FlexRow>
            <FlexRow>
              <YellowOk /> <SubText>Просмотр только своих заявок</SubText>
            </FlexRow>
            <FlexRow>
              <RedCancel />{" "}
              <SubText>Настройка сбора и добавление пользователей</SubText>
            </FlexRow>
          </Column>
          <Column>
            <SubTitle>Cупервайзер</SubTitle>
            <FlexRow>
              <GreenOk /> <SubText>Полный раздел финансов и отчетов</SubText>
            </FlexRow>
            <FlexRow>
              <GreenOk /> <SubText>Просмотр всех заявок</SubText>
            </FlexRow>
            <FlexRow>
              <GreenOk />{" "}
              <SubText>Настройка сбора и добавление пользователей</SubText>
            </FlexRow>
          </Column>
          <Column>
            <SubTitle>Администратор</SubTitle>
            <FlexRow>
              <GreenOk /> <SubText>Полный раздел финансов и отчетов</SubText>
            </FlexRow>
            <FlexRow>
              <GreenOk /> <SubText>Просмотр всех заявок</SubText>
            </FlexRow>
            <FlexRow>
              <GreenOk />{" "}
              <SubText>Настройка сбора и добавление пользователей</SubText>
            </FlexRow>
          </Column>
        </BlueZone>
      </Container>
    </StyledPopup>
  )
}

export default AgentUsersPopUp
