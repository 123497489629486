import { put, call } from "redux-saga/effects"
import { showNotification } from '../../../notification';
import {
  setInitializeState,
  setStatus,
  updateAncillaryServices,
  updatePassengers,
  updateFares,
  selectFlightSuccess,
  setSeatMap
} from "../../duck"
import * as Manager from "../../manager"
import { createSeatMapState } from "../../utils"

import { initPriceDetailWorker } from "./priceDetailWorkers"
// import { NavigationService } from '@services/index';
import { clearsearchForm } from "../../../search-form"

function navigate(url) {
  window.location.href = url
}

export function* checkAvailabilityRequestWorker(args) {
  yield put(setInitializeState(false))

  try {
    yield put(setStatus(true))
    const response = yield call(
      Manager.checkAvailablity,
      args.id,
      args.group,
      args.flights
    )
    if ((response?.errors?.find(err => err.code === "RESEARCH_EXCEPTION") && response?.searchRequest?.salesChannel)
      ||
      (response?.errors?.find((err) => err.code === 'ERROR_EXCEPTION') && response?.searchRequest?.salesChannel)
      ||
      (response?.errors?.find(err => err.code === 'NO_FLIGHTS_AT_RESEARCH_EXCEPTION') && response?.searchRequest?.salesChannel))
    {
      yield put(
        showNotification({
          code: 499,
          message: 'Не удалось забронировать заявку. Пожалуйста, повторите поиск и создайте бронирование повторно',
          searchRequest: response.searchRequest,
        })
      );
      yield put(setStatus(false))
    } else {
      yield put(updateAncillaryServices(response.ancillaryServices))
      yield put(updatePassengers(response.passengers))
      yield put(
        updateFares({
          selected: response.fares[0],
          items: response.fares,
          notificationVisibility: false
        })
      )
      yield put(
        setSeatMap(createSeatMapState(response.fares[0], response.passengers))
      )
      yield put(selectFlightSuccess(response))
      yield call(initPriceDetailWorker)
      yield put(setStatus(false))
      yield put(setInitializeState(true))

      return response.responseId
    }
  } catch (e) {
    // console.log(e?.response?.data);
    const search = window?.location?.search
    const searchParse = search.match(/search=(.*)/)
    const searchResult = Array.isArray(searchParse) ? searchParse[1] : null
    yield put(setStatus(false))

    if (searchResult) {
      yield put(clearsearchForm())
      yield call(navigate, `/avia/search/${searchResult}`)
    } else {
      // yield put(
      //   showNotification({
      //     code: e.name,
      //     message: e.message,
      //   })
      // );
    }
    return null
  }
}
