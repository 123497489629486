import React from 'react';

import './style.css'

const TextBlock = ({ content, id }) => {
	return (
		<div className="container-text-block" id={id}>
			<div className="text-title" style={{textAlign: content.titleAlign}}>
				{content.title}
			</div>
			<div id="wrapperTable" className="text" dangerouslySetInnerHTML={{__html: content.text}} />
			<div id="parentTable" className="parent-table"></div>
		</div>
	);
};

export default TextBlock;