import React from 'react'
import Bg from '../../../../asset/images/bg.svg'

const GridThreeColIcons = ({ content }) => {
	return (
		<div className={'additionalServices'}>
			<span
				style={{ background: `url(${Bg}) no-repeat 50%/contain` }}
				className='bg_rectangle'
			/>
			<div className={'additionalServices__inner'}>
				{content.icons.map(item => (
					<a
						className={'additionalServices__item'}
						href={item.link}
						target={'_blank'}
						rel={'noreferrer'}>
						<div
							className={'additionalServices__item-icon'}
							style={{ backgroundColor: item.color }}>
							<span
								style={{
									background: `url(${item.img}) center center / contain no-repeat`
								}}></span>
						</div>
						<h4 className={'additionalServices__item-text'}>
							{item.title} {item.subTitle}
						</h4>
					</a>
				))}
			</div>
		</div>
	)
}

export default GridThreeColIcons
