import React from "react"
import { TableUi } from "../TableUi"
import moment from "moment"

const AgentLogItem = ({ agent }) => {
  return (
    <>
      <TableUi.BaseTr key={agent.id} style={{ cursor: "default" }}>
        <TableUi.BasicTd>
          {agent.dateTime
            ? moment
                .utc(agent.dateTime)
                .local()
                .format("DD.MM.YYYY HH:mm:ss")
            : ""}
        </TableUi.BasicTd>
        <TableUi.BasicTd>{agent.description}</TableUi.BasicTd>
        <TableUi.BasicTd>
          {agent.description === "Установка кредитного лимита"
            ? agent.oldValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            : agent.oldValue}
        </TableUi.BasicTd>
        <TableUi.BasicTd>
          {agent.description === "Установка кредитного лимита"
            ? agent.newValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            : agent.newValue}{" "}
        </TableUi.BasicTd>
        <TableUi.BasicTd>
          {agent?.userLogin !== "00000000-0000-0000-0000-000000000000" &&
            agent?.userLogin}
        </TableUi.BasicTd>
        <TableUi.BasicTd>{agent?.fieldType}</TableUi.BasicTd>
      </TableUi.BaseTr>
    </>
  )
}

export default AgentLogItem
