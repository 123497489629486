import Deadlines from "../../../../asset/icons/lp/LandingVisa/Deadlines"
import Price from "../../../../asset/icons/lp/LandingVisa/Price"
import Requirement from './../../../../asset/icons/lp/LandingVisa/Requirement';
import ArrowRight from './../../../../asset/icons/lp/LandingVisa/ArrowRight'

import './style.scss'
import { format } from 'date-fns';

function LandingVisa({ content, title, countryId }) {

    console.log(content.desc)
    const currentDate = new Date()
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)
    const btnLink =
        content.buttonLink ?
            content.buttonLink
            : window.location.origin + `/search_tour?clientId=b2b%3Aru&lang=ru&departureCityId=274286&arrivalCountryId=${countryId}&adults=2&minStartDate=${format(endDate, 'yyyy-MM-dd')}&maxStartDate=${format(endDate, 'yyyy-MM-dd')}&minNightsCount=7&maxNightsCount=7&currencyId=1&noStopOfSales=true&withoutPromo=false&hideToursWithoutFreights=true&momentConfirm=false&includePriorityFilter=false&searchScope=b2b`

    return <div className="visa-wrapper">
        <div className="visa-desktop-img"></div>
        <div className="visa-content">
            <div className="visa-title">
                <h3>{title}</h3>
                {content.linkMore && <div className="visa-link-more"><a href={content.linkMore}>Читать подробнее <ArrowRight /></a></div>}
            </div>
            <div className="visa-desc">{content.desc}</div>
            <div className="icon-block">
                {content.price && <div className="icon-item"><Price />{content.price}</div>}
                {content.deadlines && <div className="icon-item"><Deadlines />{content.deadlines}</div>}
                {content.requirement && <div className="icon-item"><Requirement />{content.requirement}</div>}
            </div>
            <div className="visa-btn"><a className="btn" href={btnLink}>{content.buttonTitle ? content.buttonTitle : 'Найти тур'}</a> </div>
        </div>
    </div>
}
export default LandingVisa