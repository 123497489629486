import React from "react"
import { Text } from "../../components/ui"
import { useMediaQuery } from "react-responsive"
import styled from "styled-components"
import { useSelector } from "react-redux"
import mobilechatIcon from '../../asset/images/avia/order-detail/mobilechatIcon.svg'
import { Link } from "react-router-dom"
import { getDetailOrder } from "../../store/modules/orders"
import moment from "moment"

// const Date = styled(Text)`
//   font-size: 14px;
//   line-height: 18px;
//   color: #737373;
//   display: flex;
//   align-items: center;
// `;

const Content = styled.div`
  margin-top: 33px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

const ItemWrapper = styled(Link)`
  position: relative;
  border: 1px solid #DCDCDC;
  color: #737373;

  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    color: #4872F2;
    box-shadow: rgb(0 0 0 / 13%) 0px 5px 10px;
  }

  @media (max-width: 761px) {
    padding: 14px;
    min-height: 147px;
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 761px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Description = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-right: 3px;
`

const Block = styled.div``

// const NewMessage = styled.div`
//   margin-top: 20px;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   background: #edf1fe;
//   box-sizing: border-box;
//   padding: 8px;
//   border-radius: 2px;
//   @media (max-width: 324px) {
//     ${Text} {
//       font-size: 13px;
//     }
//   }
// `;

const MobileChatIcon = styled.img.attrs({ src: mobilechatIcon })``

const MessageIconContainer = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 40px;
  height: 40px;
`

const MessageIconWrapper = styled.div`
  position: relative;
`

// const MessageCount = styled.div`
//   color: #4872f2;
//   position: absolute;
//   border: 1px solid currentColor;
//   top: -6px;
//   right: -5px;
//   border-radius: 50%;
//   background: #fff;
//   width: 16px;
//   height: 16px;
//   font-family: Open Sans;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 20px;
//   & > span {
//     position: absolute;
//     top: -1px;
//     left: 5px;
//   }
// `;

const PropsBlock = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 657px;
  row-gap: 10px;

  @media (max-width: 761px) {
    margin-top: 5px;
    display: block;
    & > div {
      margin-top: 5px;
    }

    & > :first-child {
      margin-top: 0;
    }
  }
`

const ItemList = styled.div`
  & > a {
    margin-bottom: 35px;
  }
  & > :last-child {
    margin-bottom: unset;
  }
`

const AppealList = () => {
  const { supportTickets, orderNumber } = useSelector(getDetailOrder)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const themeHeaderText = isMobile ? "Тема:" : "Тема обращения:"
  const statusHeaderText = isMobile ? "Статус:" : "Статус обращения:"

  return (
    <Content>
      {supportTickets ? (
        <ItemList>
          {supportTickets.map(x => {
            const formatedDate = moment(x.createDate).format("DD.MM.YYYY")
            const mainText = isMobile
              ? ` Обращение № ${x.number}`
              : `Обращение № ${x.number} от ${formatedDate}`
            return (
              <ItemWrapper
                style={{ background: "white" }}
                to={`/cabinet/tickets/${x.number}`}
              >
                <TitleRow>
                  <Title>{mainText}</Title>
                  <MessageIconContainer>
                    <MessageIconWrapper>
                      <MobileChatIcon />
                      {/* {props.unreadMessagesNumber !== 0 && (
                        <MessageCount>
                          <span>{props.unreadMessagesNumber}</span>
                        </MessageCount>
                      )} */}
                    </MessageIconWrapper>
                  </MessageIconContainer>
                </TitleRow>
                <PropsBlock>
                  <Block>
                    <Description>№ заявки:</Description>
                    <Text fontWeight="600">{orderNumber}</Text>
                  </Block>

                  {isMobile && (
                    <Block>
                      <Description>Дата обращения: </Description>
                      <Text fontWeight="600">{formatedDate}</Text>
                    </Block>
                  )}
                  <Block>
                    <Description>{themeHeaderText}</Description>
                    <Text fontWeight="600">{x.subject.description}</Text>
                  </Block>
                  {!isMobile && (
                    <Block>
                      <Description>Тип рейса:</Description>
                      <Text fontWeight="600">Регулярный рейс</Text>
                    </Block>
                  )}
                  <Block>
                    <Description>{statusHeaderText}</Description>
                    <Text color="blue" fontWeight="600">
                      {x.status.description}
                    </Text>
                  </Block>
                </PropsBlock>
                {/* {props.hasUnreadCalculation && (
                    <NewMessage>
                      <Text>{newCalculationMessage}</Text>
                    </NewMessage>
                  )} */}
              </ItemWrapper>
            )
          })}
        </ItemList>
      ) : (
        <Text>Обращения отсутствуют</Text>
      )}
    </Content>
  )
}

export default AppealList
