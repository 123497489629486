import React from 'react';
import styled from 'styled-components';
import Text from './Typography';
import Tippy from '@tippyjs/react';
import quantityCaseFormat from '../../functions/quantityCaseFormat';
import formatDuration from '../../functions/formatDuration';

const Container = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e3eaff;

  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
    border: 3px solid #4872f2;
    border-radius: 50%;
    background: #edf1fe;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
  }
`;

const InnerRound = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #4872f2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    display: none;
  }
`;

const UpperText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4872f2;
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const MobileText = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;

  position: absolute;
  top: -20px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);

  & > strong {
    color: #4872f2;
  }
`;

const TippyWrapper = styled.div`
  padding: 14px;
  padding-bottom: 13px;
  background: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 11px 0px;
  width: 202px;
`;

const TippyText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    color: #4872f2;
  }
`;

const Point = ({
  upperText,
  textSize,
  mobileProps,
  ...props
}) => (
  <Container {...props}>
    <div>
      {upperText && <UpperText>{upperText}</UpperText>}
      {mobileProps && (
        <MobileText>
          {formatDuration(mobileProps.duration)},{' '}
          <Tippy
            render={(attrs) => (
              <TippyWrapper {...attrs}>
                <TippyText>
                  Пересадка {formatDuration(mobileProps.stopDuration)}, аэропорт <strong>{mobileProps.airport.name}, {mobileProps.city.name}</strong>
                </TippyText>
              </TippyWrapper>
            )}
          >
            <strong>
              {mobileProps.stopCount}{' '}
              {quantityCaseFormat(
                mobileProps.stopCount,
                'пересадка',
                'пересадки',
                'пересадок'
              )}
            </strong>
          </Tippy>
        </MobileText>
      )}

      <InnerRound />
    </div>
  </Container>
);

export default Point;
