import React from "react"
import styled from "styled-components"
import { Text } from "../../../ui"
import {CharterTariffsModal, FareConditionModal} from '../../../modals'
import CheckedIconSrc from "../../../../asset/images/avia/tariff-features/check.svg"
import CrossIconSrc from "../../../../asset/images/avia/tariff-features/cross.svg"
import RubleIconSrc from "../../../../asset/images/avia/tariff-features/ruble.svg"
import { ReactComponent as InfoIcon } from "../../../../asset/images/avia/shared/info-icon.svg"
import { useOrderPosition } from "./useItemProps"
import { getRoutes } from "../../../../store/modules/orders"
import formatPrice from "../../../../functions/formatPrice"


const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
`

const BlueTitle = styled(Title)`
  color: #4872F2;
`

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  & > span {
    display: block;
    margin-left: 5px;
  }
`

const FeaturesWrapper = styled.div`
  display: block;
  & > div {
    margin-bottom: 15px;
  }
`

const DetailButton = styled(Text)`
  color: #4872F2;
  cursor: pointer;
  padding-top: 15px;
  display: flex;
  align-items: center;
  & > svg {
    width: 15px;
    height: 15px;
    margin-right: 6.5px;
  }
`

const RowText = styled(Text)`
  white-space: pre-line;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const CheckIcon = () => <img src={CheckedIconSrc} alt="" />

const CrossIcon = () => <img src={CrossIconSrc} alt="" />

const RubleIcon = () => <img src={RubleIconSrc} alt="" />

function getStatusIcon(x) {
  switch (x) {
    case "Included":
      return <CheckIcon />
    case "NotOffered":
      return <CrossIcon />
    case "AtCharge":
      return <RubleIcon />
    default:
      return <RubleIcon />  
  }
}

const Tariff = props => {
  const fareFamily = React.useMemo(() => {
    return props.fareFamilies ? props.fareFamilies[0] : undefined
  }, [props])

  const price = formatPrice(props.adultPrice + props.childPrice)

  const [isPopup, setPopup] = React.useState(false)

  const orderPosition = useOrderPosition()
  const isCharter = props.gds === 'SAMO'

  return (
    <div>
      <Head>
        <Title>
          Тариф перелета: {fareFamily?.name} <BlueTitle>{price}</BlueTitle>
        </Title>
      </Head>
      <FeaturesWrapper>
        {fareFamily?.features?.map(item => (
          <Feature>
            {getStatusIcon(item.applicability)}
            <RowText>
              {getTitle(item.type)}
              {item.descriptionRus}
            </RowText>
          </Feature>
        ))}
      </FeaturesWrapper>
      <DetailButton onClick={() => setPopup(true)}>
        <InfoIcon />
        Посмотреть правила тарифа
      </DetailButton>

      {isCharter ? (
        <CharterTariffsModal open={isPopup} onClose={() => setPopup(false)} />
      ) : (
        <FareConditionModal
          open={isPopup}
          onClose={() => setPopup(false)}
          fare={fareFamily?.name || ""}
          routes={getRoutes(orderPosition)}
        />
      )}


    </div>
  )
}

function getTitle(type) {
  switch (type) {
    case "Baggage":
      return "Багаж: "
    case "CarryOn":
      return "Ручная кладь: "
    case "Exchange: ":
      return "Обмен: "
    case "Refund":
      return "Возврат: "
    default:
      return ""
  }
}

export default Tariff
