import React from 'react';

const Baggage = ({baggage}) => {
	if (!baggage) return null
	return (
		<>
			{baggage.note
				? baggage.note.split('\n').map(m => <div>{m}</div>)
				: baggage.baggage.value === 1 ? 'Багаж: ' +  baggage.baggage.value + 'PC' : 'Багаж: ' + baggage.baggage.value + ' кг'
			}
		</>
	);
};

export default Baggage;