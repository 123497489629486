import React from 'react';
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button';

import styledComponents, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styledComponents.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin: auto;

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 2px solid lightgray;
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  @media(max-width: 1124px) {
      width: 16px;
      height: 18px;
      margin: auto;

      border-top: 1px solid grey;
      border-right: 1px solid grey;
      border-bottom: 1px solid grey;
      border-left: 1px solid lightgray;
    }
`;

const SpinnerContainer = styledComponents.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled(Button)((props) => ({
  fontFamily: "Open Sans",
  textTransform: 'none',
  borderRadius: '4px',
  lineHeight: '24px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  padding: '8px 30.5px',
  letterSpacing: 'normal',
  boxShadow: 'none !important',
  color: props.loading ? 'transparent': undefined,
}));

export default function PrimaryButton({children,...props}){
  return (
    <StyledButton {...props} variant='contained'>
        {children}
        {props.loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
    </StyledButton>
  );
}