export const FareFeatureStatus ={
    // включено в тариф
    Included : 'Included',
    // недоступно
    NotOffered : 'NotOffered',
    // доступно за доп. плату
    AtCharge : 'AtCharge',
  }

  export const PassengerAgeCategory = {
    Adult : 'ADULT',
    Child : 'CHILD',
    Infant : 'INFANT',
  }

  export const AncillaryServiceType = {
    //багаж
    Luggage : 'Luggage',
    //еда
    Meal : 'Meal',
  }

  export const AddictionalServiceTypes = {
    //смс оповещение
    SMS_Service : 'SMS_Service',
    Return_Guarantee : 'Return_Guarantee',
    SeatMap : 'Seat_Map',
    Payment_Receipt : 'Payment_Receipt',
  }

  export const InsuranceLoadStatus = {
    Success: 'Success',
    Failure: 'Failure',
    Loading: 'Loading',
    Default: 'Default',
  }
