import New404 from '../../asset/icons/404/New_404'
import './style.scss'
import {Helmet} from "react-helmet";
import React from "react";

function NotFound() {

    const toSearch = () => {
        window.location.pathname = '/search_tour'
    }

    return <div className="v-404">
        <Helmet title={'Страница не найдена'} />
        <div className="v-404_container">
            <New404 className="v-404_img" />
            <div className='v-404_text-block'>
                <h3 className="v-404_title">Ошибка 404</h3>
                <p className="v-404_desc">Страница не найдена: возможно, она была удалена или адрес введен неверно. Вы можете перейти на главную страницу или воспользоваться поиском.</p>
            </div>
            <div className="v-404_btn" onClick={toSearch}>Начать поиск</div>
        </div>
    </div>
}

export default NotFound