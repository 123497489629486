 const REPLACERS = {
    eng: {
        "й": "q", "ц": "w", "у": "e", "к": "r", "е": "t", "н": "y", "г": "u",
        "ш": "i", "щ": "o", "з": "p", "х": "[", "ъ": "]", "ф": "a", "ы": "s",
        "в": "d", "а": "f", "п": "g", "р": "h", "о": "j", "л": "k", "д": "l",
        "ж": ";", "э": "'", "я": "z", "ч": "x", "с": "c", "м": "v", "и": "b",
        "т": "n", "ь": "m", "б": ",", "ю": ".", ".": "/"
        },
    ru: {
        "q":"й", "w":"ц"  , "e":"у" , "r":"к" , "t":"е", "y":"н", "u":"г", 
        "i":"ш", "o":"щ", "p":"з" , "[":"х" , "]":"ъ", "a":"ф", "s":"ы", 
        "d":"в" , "f":"а"  , "g":"п" , "h":"р" , "j":"о", "k":"л", "l":"д", 
        ";":"ж" , "'":"э"  , "z":"я", "x":"ч", "c":"с", "v":"м", "b":"и", 
        "n":"т" , "m":"ь"  , ",":"б" , ".":"ю" , "/":"."
    },
    translit:  {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'i', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
        'ш': 'sh', 'щ': 'shsc', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'iu', 'я': 'ia'
    }
 }

 export function convertTo(lang, str){
    let replacer = REPLACERS[lang]

    for(let i=0; i < str.length; i++){                        
        if( replacer[ str[i].toLowerCase() ] !== undefined){
            let replace            
            
            if(str[i] === str[i].toLowerCase()){
                replace = replacer[ str[i].toLowerCase() ];    
            } else if(str[i] === str[i].toUpperCase()){
                replace = replacer[ str[i].toLowerCase() ].toUpperCase();
            } 
            
            str = str.replace(str[i], replace);
        }
    }
    
   return str;
 }

 export function transliterateToUppercase(str) {
    return str.replace(/зг/gi, 'ZGH').split('').map((char) => {
        const lowerChar = char.toLowerCase();
        return REPLACERS.translit[lowerChar]?.toUpperCase() ?? char;
    }).join('');
}

export function transliterateLatinToCirillic(str) {
	return str
		.split('')
		.map(char => {
			const lowerChar = char.toLowerCase()
			return REPLACERS.ru[lowerChar]?.toUpperCase() ?? char.toUpperCase()
		})
		.join('')
}