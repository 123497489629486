import React, {useState} from 'react';
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {logos} from "../../constants/logo";
import {Box} from "@mui/material";
import {Button} from "@material-ui/core";
import check from "../../../../../asset/icons/check.svg";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useInsuranceStore from "../../store";

const InsuranceCard = ({ insurance }) => {

	const actions = useInsuranceStore(({ actions }) => actions)
	const [showMore, setShowMore] = useState(false)
	const {dateTo} = useInsuranceStore(({ data }) => data.journeyInfo)

	return (
		<Box key={insurance.id} className={'insurance-result__item'}>
			<div style={{display: "flex"}}>
				<div style={{flexGrow: 1}}>
					<div className={'insurance-result__title'}>{insurance.productName}</div>
					<div className={'insurance-result__date'}>Действие полиса: {format(new Date(insurance.policyStartDate), 'dd MMMM yyyy', {locale: ru})} - {format(new Date(dateTo), 'dd MMMM yyyy', {locale: ru})}</div>
					<div className={'insurance-result__price'}>
						<div className={'insurance-result__price-col'}><img src={logos[insurance.insuranceCompanyId]} alt="" className="insurance__img" /></div>
						<div className={'insurance-result__price-col'}>
							<Box mb={1}>{insurance.lifeInsuranceAmount.toLocaleString('ru', {})} {insurance.currencyIsoCode}</Box>
							<Box className={'insurances__item-options'}>+ {insurance.options.length} опций</Box>
						</div>
						<div className={'insurance-result__price-col'}>{insurance.price.toLocaleString('ru', {})} ₽</div>
					</div>
				</div>
				<div className={'insurance-result__booking'}>
					<Button className={'search-button'} variant={'contained'} onClick={() => actions.addToBasket(insurance)}>Забронировать</Button>
					<div className={'insurance__more-btn'} onClick={() => setShowMore(!showMore)}>
						{showMore ? 'Скрыть условия' : 'Подробнее'}
						<svg data-v-70e87559="" viewBox="0 0 8.1 4.8" style={showMore ? {transform: 'rotate(180deg)'} : {}}>
							<path data-v-70e87559="" d="M7.8.4L4.1 4.1.4.4" />
						</svg>
					</div>
				</div>
			</div>
			{showMore && <Box className={'insurance__more'}>
				<Box mb={2} sx={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
					{!!insurance.assistanceNames.length && <Box mr={4}><span className={'options-title'}>Ассистанс</span> - {insurance.assistanceNames}</Box>}
					{insurance.rulesLink && <Box mr={4}><a href={insurance.rulesLink} target={'_blank'} rel='noreferrer noopener nofollow'>Правила страхования</a></Box>}
					</Box>
				<Box mb={2} className={'options-title'}>Опции:</Box>
				{insurance.options.map(option =>
					<Box mb={1} key={option.optionType} sx={{display: 'flex'}}>
						<div><span className={'insurance__more__icon'}
								   style={{WebkitMask: `url(${check})  no-repeat`}}/> {option.name} - <b>{option.limit.toLocaleString('ru', {})} {insurance.currencyIsoCode}</b></div>
						{option.tooltip &&
							<HelpOutlineIcon
								fontSize={'small'}
								titleAccess={option.tooltip}
								style={{
									cursor: 'pointer',
									marginLeft: 5,
									fill: '#7e8389'
								}}
							/>
						}
					</Box>
				)}
			</Box>}
		</Box>
	);
};

export default InsuranceCard;