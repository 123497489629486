import React from 'react';
import TilesIcon from "./TilesIcon";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/pagination';
import {Autoplay, FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import { isMobile } from "react-device-detect";
import {ReactComponent as Arrow} from "../../../../asset/icons/slider-next.svg";


const SliderIcons = ({ content }) => {

	return (
		<div className='SliderIcons_swiper-container'>
			{!isMobile && (
				<div className='nav-btns'>
					<Arrow className='SliderIcons_swiper-button-prev slide-btn-bg static-banner' />
					<Arrow className='SliderIcons_swiper-button-next slide-btn-bg static-banner' />
				</div>
			)}
			<Swiper
				spaceBetween={30}
				slidesPerView={'auto'}
				mousewheel={false}
				watchSlidesProgress={true}
				autoplay={false}
				loop={false}
				modules={[Autoplay, FreeMode, Navigation, Mousewheel, Pagination]}
				navigation={{
					nextEl: '.SliderIcons_swiper-button-next.static-banner',
					prevEl: '.SliderIcons_swiper-button-prev.static-banner',
				}}
				className='SliderIcons_swiper_slider'
				watchOverflow={true}>
				{content.icons.map((item) => (
					<SwiperSlide key={item.img}>
						<TilesIcon  item={item} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default SliderIcons;