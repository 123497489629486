import { useState, useEffect, useRef } from 'react'
import Calendar from 'react-calendar'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'
import { format, isValid } from 'date-fns'

const NumberToDateRegex = /^(\d{2})(\d{2})(\d{4})$/
const DateRegex =
	/\b(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.((19|20)\d{2})\b/g
const ISODateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/

const CHAR_CODES = {
	DELETE: 46,
	DOT: 190,
	ZERO: 48,
	NINE: 57,
	MIN_CONTROL_CHAR_CODE: 31,
	DELETE_NAME: 'Delete',
	BACKSPACE: 8
}
const MAX_DAY = 31
const MAX_MONTH = 12

function DateInput({
	value,
	onChange,
	label,
	required = false,
	error,
	isExpiration,
	minStartDate = null
}) {
	const [selectedDate, handleDateChange] = useState(value ?? '')
	const [showCalendar, setShowCalendar] = useState(false)
	const [selectedInputDate, handleInputDateChange] = useState(value ?? null)
	const calendarRef = useRef(null)
	const buttonRef = useRef(null)
	const [dateError, setDateError] = useState(false)

	const handleChange = date => {
		handleInputDateChange(date)
		handleDateChange(date)
		onChange(date)
		setShowCalendar(!showCalendar)
		setDateError(false)
	}

	const handleInputChange = event => {
		let value = event.target.value

		if (value.length > 10) {
			return
		}

		if (value.length < 10) {
			setDateError(true)
		}

		if (NumberToDateRegex.test(value) || DateRegex.test(value)) {
			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			let date = new Date(changeDate[2], changeDate[1] - 1, changeDate[0])

			if (isExpiration && date < new Date()) {
				date = new Date()
			}
			setDateError(false)
			handleDateChange(date)
			handleInputDateChange(date)
			onChange(date)
			return
		}

		if (value.length === 2 && value.slice(0, 2) > MAX_DAY) {
			handleInputDateChange(`${MAX_DAY}.`)
			return
		}
		if (value.length === 5 && value.slice(3, 5) > MAX_MONTH) {
			handleInputDateChange(`${value.slice(0, 3)}${MAX_MONTH}.`)
			return
		}

		handleInputDateChange(value)
	}

	const handleShowCalendar = () => {
		setShowCalendar(!showCalendar)
	}

	useEffect(() => {
		handleDateChange(value)
		handleInputDateChange(value)
	}, [value])

	useEffect(() => {
		function handleClick(event) {
			if (calendarRef.current && !calendarRef.current.contains(event.target) && !buttonRef?.current?.contains(event.target)) {
				setShowCalendar(false)
			}
		}
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [calendarRef, setShowCalendar])

	const handleKeyPress = event => {
		const charCode = event.which ? event.which : event.keyCode
		if (
			charCode !== CHAR_CODES.DELETE &&
			charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE &&
			(charCode < CHAR_CODES.ZERO || charCode > CHAR_CODES.NINE)
		) {
			event.preventDefault()
		}
	}

	const handleKeyDown = event => {
		const value = event.target.value
		const charCode = event.which ? event.which : event.keyCode

		if (
			charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE &&
			charCode === CHAR_CODES.DOT
		) {
			return
		}
		if (charCode === CHAR_CODES.BACKSPACE || charCode === CHAR_CODES.DELETE) {
			return
		}
		if (value.length === 2 || value.length === 5) {
			event.target.value = value + '.'
			return
		}
	}

	const formateInput = date => {
		if (ISODateRegex.test(date)) {
			const formatDate = new Date(date)
			return format(formatDate, 'dd.MM.yyyy')
		}
		if (isValid(date)) {
			return format(date, 'dd.MM.yyyy')
		}
		return date
	}

	return (
		<div className='visa-input'>
			<InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
			<FormControl
				style={{ width: '100%' }}
				required={required}
				error={error || dateError}>
				<OutlinedInput
					id='outlined-adornment-password'
					value={formateInput(selectedInputDate)}
					className='mui-date-input'
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onKeyPress={handleKeyPress}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton edge='end' onClick={handleShowCalendar} ref={buttonRef}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 1024 1024'
									width='16'
									height='16'>
									<path d='M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z'></path>
								</svg>
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
			{showCalendar && (
				<div className='date-input' ref={calendarRef}>
					<Calendar
						value={isValid(selectedDate) ? selectedDate : new Date()}
						date={selectedDate}
						showFixedNumberOfWeeks={true}
						onChange={date => handleChange(date)}
						tileDisabled={({ date }) => {
							if (minStartDate) {
								return date < new Date(minStartDate).setDate(new Date(minStartDate).getDate())
							}
							if (isExpiration) {
								return date < new Date().setDate(new Date().getDate() - 1)
							} else {
								return !date
							}
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default DateInput
