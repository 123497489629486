import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import { formatDateToUTC } from '../../../functions/formateDate'
import { isEmpty } from 'lodash'

import { geoApiInstance } from '../../../services/api'
const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useHotelServiceStore = create(
    devtools((set, get) => ({
        draft: [],
        excursion: null,
        loading: false,
        isReadyForBooking: false,
        additional: null,
        userId: '',
        bookingDate: null,
        selectedHotel: null,
        bookingLoadingError: null,
        buyer: {
            buyer_type: "0",
            nationalityKey: "210357",
            send_gis: "1",
            firmState: '210357'
        },
        isBookingError: null,
        paxes: [],
        hotels: [],
        actions: {
            reserve: async () => {
                const partpass = JSON.parse(localStorage.getItem('partPass'))
                const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
                const excursion = get().excursion
                const buyer = get().buyer
                const draft = get().draft

                const splitName = buyer?.name?.split(" ") || []
                const hotel = get().hotels.find(i => get().selectedHotel === i.id)
                const data = {
                    partpassId: Number(partpass),
                    serviceKey: excursion.id,
                    userId: agentInfo?.agents.id,
                    draftId: draft.id,
                    bookingDate: excursion.date,
                    service: {
                        id: excursion?.number,
                        type: 0,
                        client: {
                            person: {
                                firstName: splitName[1],
                                lastName: splitName[0],
                                patronymic: splitName.length === 3 ? splitName[2] : 'ОТСУТСТВУЕТ',
                                nationalityId: buyer.nationalityKey,
                                identityDocument: 0
                            },
                            contactInformation: {
                                phone: buyer.phone,
                                email: buyer.email
                            },
                            passport: {
                                series: buyer.pserie,
                                number: buyer.number,
                                address: buyer.address,
                                issueDate: '1901-01-01T00:00:00Z',
                            },
                            // buyerType: buyer.buyer_type,
                            // sendGis: buyer.send_gis,
                            // agencyDocDate: buyer.agencydocdate,
                            // agencyDocNumber: buyer.agency_docsum,
                            // agencyDocSum: buyer.agency_docnumber,

                        },
                        paxes: get().paxes,
                        // "promoCode": "string",
                        "claimGroup": "newb2b",
                        pickUpAddress: hotel.name
                    }
                }
                set({ isReadyForBooking: true })
                set({ loading: true })
                try {
                    const res = await axios({
                        url: `${BASE_URL}/Services/Reserve`,
                        method: 'POST',
                        data: data,
                        headers: { "X-TUI-ClientId": 'b2b:ru' }
                    })

                    set({ orderId: res?.data?.orderId })
                } catch (error) {
                    set({ isBookingError: error.response?.data?.detail?.split('     ')[0] })
                    console.error("Error in reserve ", error)
                } finally {
                    set({ loading: false })
                    set({ isReadyForBooking: false })
                }
            },
            getService: async () => {
                const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
                set({ loading: true })
                try {
                    const res = await axios({
                        url: `${BASE_URL}/Draft`,
                        method: 'GET',
                        params: {
                            userId: agentInfo.agents.id
                        },
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`
                        }
                    })
                    set({ draft: res.data })

                    if (!isEmpty(res.data?.draftBody?.services[0])) {
                        set({ excursion: res.data?.draftBody?.services[0] })
                    }

                    get().actions.setPaxes()
                    get().actions.getAdditionalService()
                    get().actions.getHotels()
                } catch (error) {
                    console.log(error)
                    set({ bookingLoadingError: error?.response?.data })
                }
                finally {
                    set({ loading: false })
                }
            },
            getHotels: async () => {
                const excursion = get().excursion
                try {
                    const res = await axios({
                        url: `${BASE_URL}/Filters/GetFilters`,
                        method: "GET",
                        params: {
                            DepartureCityId: 274286,
                            ArrivalCountryId: excursion.country.id
                        },
                        headers: {
                            "X-TUI-ClientId": "b2b:ru"
                        }
                    })
                    set({
                        hotels: res.data.hotels
                    })

                } catch (error) {
                    console.log(error)
                }
            },
            setPaxes: () => {
                const adults = get().excursion?.adults
                const children = get().excursion?.children
                const travellers = []
                for (let i = 0; i < adults + children; i++) {
                    travellers.push({
                        id: i + 1,
                        person: {
                            gender: 1,
                        },
                        document: {
                            type: 1
                        },
                        contactInformation: {},
                        nationalityId: 210357
                    })
                }

                set({ paxes: travellers })
            },
            getAdditionalService: async () => {
                const data = { array_ids: [get()?.excursion?.number] }
                try {
                    const res = await geoApiInstance().post(
                        '/additional-service/slice',
                        data
                    )
                    if (!isEmpty(res?.data)) {
                        set({ additional: res?.data[0] })
                    }

                } catch (error) {
                    console.log(error)
                }
            },
            changeFirstNameLatin: (name, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.firstName = name
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeLastNameLatin: (lastName, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.lastName = lastName
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeFirstName: (name, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.firstName = name
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeLastName: (lastName, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.lastName = lastName
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changePatronymic: (patronymic, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.patronymic = patronymic
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeGender: (gender, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.gender = gender
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeBirthDate: (date, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.birthDate = formatDateToUTC(date)
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changePhone: (phone, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.contactInformation.phone = phone
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeEmail: (email, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.contactInformation.email = email
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeNationality: (nation, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.person.nationality = nation
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeTypeDocument: (type, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.document.type = type
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeSeries: (series, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.document.series = series
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeNumber: (pNumber, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.document.number = pNumber
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeExpirationDate: (date, number) => {
                set({
                    paxes: get().paxes.map(item => {
                        if (item.id === number) {
                            item.document.expirationDate = formatDateToUTC(date)
                            return item
                        }
                        return item
                    })
                })
                set({ isReadyForBooking: false })
            },
            changeBuyer: (key, value) => {
                set({ buyer: { ...get().buyer, [key]: value } })
                set({ isReadyForBooking: false })
            },
            changeHotel: (value) => {
                set({ selectedHotel: value })
                set({ isReadyForBooking: false })
            },
            changeIsReadyForBooking: value => {
                set({ isReadyForBooking: value })
            },
            clearError: () => {
                set({ isBookingError: null })
                set({ bookingLoadingError: null })
            }
        }
    }))
)

export default useHotelServiceStore
