export const CalculationType = {
    Refund : 'Refund',
  
    /// <summary>
    /// Доплата
    /// </summary>
    ExtraPayment : 'ExtraPayment',
  
    /// <summary>
    /// Согласие с изменениями
    /// </summary>
    AgreementWithChanges : 'AgreementWithChanges',
}