export const getAgentSiginState = state => state.agents.agentSigninInfo

export const getIsAgent = state => !!getAgentSiginState(state)

export const getAgentInfo = state => state.agents.agent

export const getAgentBalance = state => state.agents.balance

export const getOverPaidsOrders = state => state.agents.overPaidOrders

export const getNotPaidOrders = state => state.agents.notPaidOrders

export const getAgentCreditHistory = state =>
  state.agents.agentsCreditLimitHistory

export const getAgentPaymentHistory = state => state.agents.agentPaymentHistory

export const getAgentLoadings = state => state.agents.loadings
