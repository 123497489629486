import * as React from 'react'
import { styled } from '@mui/material/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import '../style.css'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import useAuthStore from '../../../Auth/store'
import { format } from 'date-fns'

const Accordion = styled(props => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `none`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ExpandMore style={{ color: '#4872F2' }} />}
		{...props}
	/>
))(({ theme }) => ({
	margin: '8px 0 8px 0',
	paddingLeft: theme.spacing(3),
	backgroundColor: '#FFF',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(0),
	borderTop: 'none'
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		whiteSpace: 'nowrap',
		height: '58px',
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		color: '#2E2E32',
		borderColor: '#DBE0E5',
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '26px',
		backgroundColor: '#EDF1FE',
		padding: '8px 28px'
	},
	[`&.${tableCellClasses.body}`]: {
		height: '56px',
		whiteSpace: 'nowrap',
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		fontSize: 18,
		borderColor: '#DBE0E5',
		fontWeight: 400,
		color: '#2E2E32',
		padding: '7px 28px',
		lineHeight: '24px'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderRadius: '8px',
	'&:nth-of-type(even)': {
		backgroundColor: '#F6F8FA'
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

export default function Details() {
	const [expanded, setExpanded] = React.useState('')
	const [title, setTitle] = React.useState('Показать детали')
	const bonusesDetails = useAuthStore(({ bonusesDetails }) => bonusesDetails)

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false)
		setTitle(newExpanded ? 'Скрыть детали' : 'Показать детали')
	}
	React.useEffect(() => {
		setTitle('Показать детали')
		setExpanded('')
	}, [bonusesDetails])
	// console.log(bonusesDetails, 'DETAILS')
	return (
		<div>
			<Accordion
				expanded={expanded === 'panel1'}
				onChange={handleChange('panel1')}>
				<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
					<span className='details-title'>{title}</span>
				</AccordionSummary>
				<AccordionDetails>
					<TableContainer
						component={Paper}
						sx={{
							borderRadius: '16px',
							overflowY: 'hidden',
							maxWidth: 860,
							'&::-webkit-scrollbar': {
								height: 4
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: '#D4D9DE',
								borderRadius: 2
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#4872F2',
								borderRadius: 2
							}
						}}>
						<Table aria-label='customized table'>
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ paddingLeft: '16px' }}>
										Дата
									</StyledTableCell>
									<StyledTableCell align='left'>Тип</StyledTableCell>
									<StyledTableCell align='left'>Заявка</StyledTableCell>
									<StyledTableCell align='left'>Бонус</StyledTableCell>
									<StyledTableCell align='left'>Статус</StyledTableCell>
									<StyledTableCell align='left'>
										Действителен до
									</StyledTableCell>
									<StyledTableCell align='right'>Дата отмены</StyledTableCell>
									<StyledTableCell
										align='right'
										style={{ paddingRight: '16px' }}>
										Примечание
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bonusesDetails?.map((row, i) => (
									<StyledTableRow key={i}>
										<StyledTableCell
											component='th'
											scope='row'
											style={{ paddingLeft: '16px' }}>
											{format(new Date(row.issueDate), "dd.MM.yyyy' 'HH:mm")}
										</StyledTableCell>
										<StyledTableCell align='left'>Приход</StyledTableCell>
										<StyledTableCell align='left'>
											{row.claimId}
										</StyledTableCell>
										<StyledTableCell align='left'>
											{row.bonuses} {row.currencyName}
										</StyledTableCell>
										<StyledTableCell align='left'>
											{row.isCanceled ? (
												<span style={{ color: '#E73C3E' }}>Удален</span>
											) : (
												<span style={{ color: '#28BF68' }}>Доступен</span>
											)}
										</StyledTableCell>
										<StyledTableCell align='left'></StyledTableCell>
										<StyledTableCell align='left'></StyledTableCell>
										<StyledTableCell
											align='left'
											style={{ paddingRight: '16px' }}></StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}
