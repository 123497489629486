import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { ReactComponent as InfoIcon } from "../../../asset/images/avia/shared/info-icon.svg"
import StepList from "./StepList"
import { Text, Button as BaseButton, Checkbox } from "../../ui"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import formatPrice from "../../../functions/formatPrice"
import OutlinedButtonBase from "../../ui/OutlinedButton"
import PrimaryButtonBase from "../../ui/PrimaryButton"
import {styled as styledMUI} from '@mui/material/styles'

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 616px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    max-height: auto;

    @media (max-width: 767px) {
      padding: 34px 20px;
      width: 100%;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      border-radius: 0px;
    }
  }
`

const Title = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 15px;

  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
    white-space: wrap;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const ActionsList = styled.div`
  & > * {
    margin-bottom: 30px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const ActionBlockTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 14px;
  }
`

const ActionBlockDescription = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`

const Button = styled(BaseButton)`
  min-width: 209px;
  padding: 8px 19.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 767px) {
    min-width: auto;
    width: 48%;
    padding: 7px 10px;
    font-size: 14px;
    line-height: 18px;
  }
`

const PrimaryButton = styledMUI(PrimaryButtonBase)(()=>({
  minWidth: '209px',
  padding: '8px 19.5px',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
}));

const OutlinedButton = styledMUI(OutlinedButtonBase)(()=>({
  minWidth: '209px',
  padding: '7px 19.5px',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
}));

const ActionBlockButtons = styled.div`
  display: flex;
  & > button {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    display: none;
  }
`

const ActionButtonMobile = styled.div`
  display: none;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: flex;
    & > button {
      min-width: auto;
    }
  }

  @media (max-width: 425px) {
    display: block;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`

const ErrorDescription = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #e73c3e;
`

const ActionBlockContent = styled.div`
  padding-top: 2px;

  ${ActionBlockTitle} {
    display: block;
    margin-bottom: 12px;
  }

  ${ErrorDescription} {
    display: block;
    margin-bottom: 20px;
  }

  ${ActionBlockDescription} {
    display: block;
    margin-bottom: 10px;
  }

  ${StepList} {
    margin: 20px 0;
  }

  ${ActionBlockButtons} {
    margin-top: 20px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    ${ActionBlockDescription} {
      display: block;
      margin-bottom: 15px;
    }
    ${ErrorDescription} {
      display: block;
      margin-bottom: 15px;
    }
  }
`

const Flex = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
`

const ActionBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-color: #c4c4c4;
  background-color: transparent;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 18px 10px 10px 14px;

  ${ErrorDescription} {
    display: none;
  }

  ${({ selected }) =>
    selected
      ? `
  border-color: #4872F2;
  `
      : ""}

  ${({ disabled }) =>
    disabled
      ? `
      background-color: #F9F9F9;
      border-color: #DCDCDC;

      button {
        background: #DEDEDE !important;
        color: #737373 !important;
      }

      & > :last-child {
        display: none;
      }

      ${ActionBlockContent} {

        & > * {
          display: none;
        }

        ${ActionBlockTitle} {
          display: block;
        }
  
        ${ErrorDescription} {
          display: block;
          margin-bottom: 0;
        }
      }
  `
      : ""}
`

const RowSB = styled.div`
  display: flex;
  justify-content: space-between;
`

const BlueContainer = styled.div`
  background: #edf1fe;
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 20px;
`

const Modal = ({
  creditPaid,
  creditEnough,
  onPay,
  onExtract,
  onCreateInvoice,
  onPayByCredit,
  setAgentModal,
  ...props
}) => {
  const [state, setState] = React.useState({
    a: { type: 1, selected: false, disabled: false, disabledButton: true },
    b: { type: 2, selected: true, disabled: false, disabledButton: false }
  })
  const aquiringInfo = useSelector(state => state.agents.aquiringInfo)
  const order = useSelector(state => state.orders.orderDetail.data)
  const acquiringCommission = order.acquiringCommissions[0] || null

  const handleChange = prop => {
    if ((prop === "c" && creditEnough) || prop === "a" || prop === "b") {
      setState(s => {
        if (!s[prop]) {
          return { ...s }
        }
        const next = {
          ...s[prop],
          selected: !s[prop].selected,
          disabledButton: s[prop].selected
        }

        Object.keys(s).forEach(key => {
          s[key].selected = false
          s[key].disabledButton = !s[key].selected
        })

        return {
          ...s,
          [prop]: next
        }
      })
    }
  }

  React.useEffect(() => {
    if (props.open) {
      const s = {
        a: { type: 1, selected: false, disabled: false, disabledButton: true },
        b: { type: 2, selected: true, disabled: false, disabledButton: false }
      }
      if (creditPaid) {
        s.c = {
          type: 3,
          selected: false,
          disabled: !creditEnough,
          disabledButton: true
        }
      }
      setState(s)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="close"
        onClick={() => setAgentModal(false)}
      >
          <Icon/>
      </CloseButton>
      <RowSB>
        <Title>Выберите способ оплаты</Title>
      </RowSB>
      {/* <Description>
        Выберите один из доступных способов оплаты заявки и выписки билетов.
      </Description> */}
      <ActionsList>
        {state.a && (
          <ActionBlock selected={state.a.selected} disabled={state.a.disabled}>
            <Flex>
              <Checkbox
                type="roundWithArrow"
                checked={state.a.selected}
                blueBorder
                onChange={e => {
                  handleChange("a")
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>Оплатить банковской картой</ActionBlockTitle>
                <ActionBlockDescription>
                  Оплата картой онлайн и моментальная выписка заявки.{" "}
                </ActionBlockDescription>

                {aquiringInfo && (
                  <>
                    <BlueContainer>
                      <Flex>
                        <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                        <ActionBlockDescription style={{ marginBottom: 0 }}>
                          При оплате банковской картой взимается комиссия в
                          размере {aquiringInfo}%.
                        </ActionBlockDescription>
                      </Flex>
                    </BlueContainer>
                    {acquiringCommission && <ActionBlockDescription>
                      Комиссия по заявке составит:{" "}
                      <span style={{ color: "#4872F2", fontWeight: 600 }}>
                        {formatPrice(acquiringCommission?.acquiringAmount)}
                      </span>
                    </ActionBlockDescription>}
                    {acquiringCommission && <ActionBlockDescription>
                      Итого к оплате c учетом комиссии:{" "}
                      <span style={{ color: "#4872F2", fontWeight: 600 }}>
                      {formatPrice(acquiringCommission?.amount)}
                      </span>
                    </ActionBlockDescription>}
                  </>
                )}

                <ActionBlockButtons>
                  {state.a.selected && (
                    <PrimaryButton
                      disabled={state.a.disabledButton}
                      onClick={onPay}
                    >
                      Оплатить заявку
                    </PrimaryButton>
                  )}
                  {!state.a.selected && (
                    <OutlinedButton
                      disabled={state.a.disabledButton}
                      onClick={onPay}
                    >
                      Оплатить заявку
                    </OutlinedButton>
                  )}
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                type={state.a.selected ? "primary" : "outlined"}
                template={state.a.selected ? undefined : "gray"}
                disabled={state.a.disabled}
                onClick={onPay}
              >
                Оплатить заявку
              </Button>
            </ActionButtonMobile>
          </ActionBlock>
        )}
        {state.b && (
          <ActionBlock
            selected={state.b.selected && !state.b.disabled}
            disabled={state.b.disabled}
          >
            <Flex>
              <Checkbox
                type="roundWithArrow"
                checked={state.b.selected}
                blueBorder
                onChange={e => {
                  handleChange("b")
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>
                  Оплатить по счету или депозиту{" "}
                </ActionBlockTitle>
                <ActionBlockDescription>
                  1. Нажмите на кнопку{" "}
                  <span style={{ fontWeight: "bold" }}>
                    “Сформировать счет”
                  </span>{" "}
                  и оплатите счёт (при положительном депозите оплата не
                  требуется).
                </ActionBlockDescription>
                <ActionBlockDescription>
                  2. Оформите заявку нажатием на кнопку{" "}
                  <span style={{ fontWeight: "bold" }}>“Выписать заявку”.</span>{" "}
                  <br />
                  (кнопка доступна в шапке заявки после обработки платежа)
                </ActionBlockDescription>
                <BlueContainer style={{ marginTop: 20 }}>
                  <Flex>
                    <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                    <ActionBlockDescription style={{ marginBottom: 0 }}>
                      Время обработки составляет от 1-го банковского дня. <br />
                      Моментальная выписка при оплате в счет депозита.
                    </ActionBlockDescription>
                  </Flex>
                </BlueContainer>
                <ActionBlockButtons>
                  {state.b.selected && (
                    <PrimaryButton
                      disabled={state.b.disabledButton}
                      onClick={onCreateInvoice}
                    >
                      Сформировать счет
                    </PrimaryButton>
                  )}
                  {!state.b.selected && (
                    <OutlinedButton
                      disabled={state.b.disabledButton}
                      onClick={onCreateInvoice}
                    >
                      Сформировать счет
                    </OutlinedButton>
                  )}
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                disabled={state.b.disabledButton}
                onClick={onCreateInvoice}
                type={state.b.selected ? "primary" : "outlined"}
                template={state.b.selected ? undefined : "gray"}
              >
                Сформировать счет
              </Button>
            </ActionButtonMobile>
          </ActionBlock>
        )}
        {state.c && (
          <ActionBlock selected={state.c.selected} disabled={state.c.disabled}>
            <Flex>
              <Checkbox
                type="roundWithArrow"
                checked={state.c.selected}
                blueBorder
                onChange={e => {
                  handleChange("c")
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>Оплатить в кредит</ActionBlockTitle>

                <ErrorDescription>
                  Данный способ не доступен пока остаток по кредитному лимиту
                  меньше суммы заявки. Необходимо погасить долг.
                </ErrorDescription>
                <ActionBlockDescription>
                  1. Нажмите на кнопку{" "}
                  <span style={{ fontWeight: "bold" }}>
                    “Оплатить в кредит”
                  </span>{" "}
                  и оплатите счет в течение{" "}
                  <span style={{ fontWeight: "bold" }}>3-х рабочих дней.</span>
                </ActionBlockDescription>
                <ActionBlockDescription>
                  2. Оформите заявку нажатием на кнопку{" "}
                  <span style={{ fontWeight: "bold" }}>“Выписать заявку”.</span>{" "}
                  <br />
                  (кнопка доступна в шапке заявки после обработки платежа)
                </ActionBlockDescription>
                <BlueContainer style={{ marginTop: 20 }}>
                  <Flex>
                    <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                    <ActionBlockDescription style={{ marginBottom: 0 }}>
                      Время обработки составляет до 10 минут.
                    </ActionBlockDescription>
                  </Flex>
                </BlueContainer>
                <ActionBlockButtons>
                  {state.c.selected && (
                    <PrimaryButton
                      disabled={state.c.disabledButton}
                      onClick={onPayByCredit}
                    >
                      Оплатить в кредит
                    </PrimaryButton>
                  )}
                  {!state.c.selected && (
                    <OutlinedButton
                      disabled={state.c.disabledButton}
                      onClick={onPayByCredit}
                    >
                      Оплатить в кредит
                    </OutlinedButton>
                  )}
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                disabled={state.c.disabledButton}
                onClick={onPayByCredit}
                type={state.c.selected ? "primary" : "outlined"}
                template={state.c.selected ? undefined : "gray"}
              >
                Оплатить в кредит
              </Button>
            </ActionButtonMobile>
          </ActionBlock>
        )}
      </ActionsList>
    </StyledPopup>
  )
}

export default Modal
