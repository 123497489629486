import React, { useState } from 'react'
import { ReactComponent as Print } from '../../../../asset/icons/Print.svg'
import './style.scss'
import { Margin, usePDF } from 'react-to-pdf'
import { Portal } from '@mui/base/Portal'
import ComponentToPrint from './ComponentToPrint'
import useHotelDescriptionStore from '../../store'
import { useMediaQuery } from 'react-responsive'

export default function PdfLink() {
	const [isHover, onHover] = useState(false)
	const isMobile = useMediaQuery({maxWidth: 576})
	const standardizedName = useHotelDescriptionStore(
		({ standardizedName }) => standardizedName
	)

	const { toPDF, targetRef } = usePDF({
		filename: standardizedName,
		method: 'save',
		page: { margin: Margin.MEDIUM },
		resolution: 1,
	})
	
	const saveToPdf = () => {
		targetRef.current.style.display = 'block'
		toPDF()
		targetRef.current.style.display = 'none'
	}
	return (
		<div
			style={{ cursor: 'pointer' }}
			onMouseEnter={() => onHover(true)}
			onMouseLeave={() => onHover(false)}
			onClick={saveToPdf}>
			<Print style={{ fill: '#B3B9C0' }} className='print-icon' />
			{!isMobile && <div className={isHover ? 'tooltip-share active' : 'tooltip-share'}>
				{'Распечатать карточку'}
			</div>}
			<Component targetRef={targetRef} />
		</div>
	)
}

const Component = ({ targetRef }) => {
	return (
		<Portal>
			<div
				ref={targetRef}
				style={{
					width: '1170px',
					margin: '0 auto',
					display: 'none'
				}}>
				<ComponentToPrint />
			</div>
		</Portal>
	)
}
