import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {  setDestination, getCityListByStr } from '../../../store/modules/search-form';
import Field from './Field';
import styled from 'styled-components';
import Arrow from './Arrow';
import { Suggestion } from './suggestion';
import { debounce } from 'lodash';
import {  useFocus } from '../useFormFocus';

const Wrapper = styled.div`
  display: flex;

  position: relative;

  padding: 2px 0;

  & > :first-child > :last-child {
    right: 22px;
  }

  & > :first-child > input {
    border-radius: 4px 0 0 4px;
  }

  @media (max-width: 1169px) {
    & > * {
      width: 49%;
      & > input {
        border-radius: 4px;
      }
    }

    & > :first-child > input {
      border-radius: 4px;
    }
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    & > * {
      width: 100%;
    }
    & > :first-child {
      margin-bottom: 10px;
    }

    width: 100%;
    flex-direction: column;
  }
`;


const CityFields = ({
  onChange,
  onBlur,
  children,
  errors,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isInsideSuggestion, setInsideSuggestion] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());
  const [suggestionList, setSuggestionList] = React.useState([]);
  const { focus, setFocus } = useFocus();
  const [suggestionIndex, setSuggestionIndex] = useState(null);

  const [{ origin, destination }, setValues] = useState({
    origin: { name: '', code: '' },
    destination: { name: '', code: '' },
  });

  const { ...state } = useSelector(
    ({
      searchForm: { from, to, dates },
    }) => {
      return { from, to, dates };
    }
  );

  const { from, to, dates } = state;
  /* eslint-disable */
  const loadSuggestions = useCallback(
    debounce(
      (value, signal) =>
        getCityListByStr(value, signal)
          .then((data) => {
            setSuggestionList(data.data || []);
          })
          .catch(() => {}),
      200
    ),
    []
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const MAX_INDEX =
        suggestionList.length > 6 ? 5 : suggestionList.length - 1;
      const key = e.key;
      setSuggestionIndex((state) => {
        let result = null;
        if (key === 'ArrowDown') {
          result = state !== null ? (state > MAX_INDEX ? 0 : ++state) : 0;
        } else {
          result =
            state !== null ? (state === 0 ? MAX_INDEX : --state) : MAX_INDEX;
        }
        return result;
      });
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const index = suggestionIndex !== null ? suggestionIndex : 0;
      if (focus === 'destination' && suggestionList.length > 0) {
        dispatch(setDestination({ to: suggestionList[index] }));

        setValues((state) => {
          return {
            ...state,
            destination: {
              name: suggestionList[index].nameRus,
              code: suggestionList[index].iataCode,
            },
          };
        });
        if (dates.from === null && window.innerWidth > 1023) {
          setFocus('forward');
        }
        inputRef.current.blur();
      } else if (suggestionList.length > 0) {
        dispatch(setDestination({ from: suggestionList[index] }));

        setValues((state) => {
          return {
            ...state,
            origin: {
              name: suggestionList[index].nameRus,
              code: suggestionList[index].iataCode,
            },
          };
        });
        if (destination.code === '' && window.innerWidth > 1023) {
          setFocus('destination');
          inputRef.current.focus();
        } else {
          setFocus(null);
          inputRef.current.blur();
        }
      }
    }
  };

  const handleChange = useCallback(
    (e) => {
      const target = e.target;
      const name = e.target.dataset.value;
      const { value } = target;
      setValues((state) => {
        return name === 'origin'
          ? { ...state, origin: { name: value, code: '' } }
          : { ...state, destination: { name: value, code: '' } };
      });
      setFocus(name);
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      loadSuggestions(value, abortControllerRef.current);
    },
      // eslint-disable-next-line
    []
  );

  const swap = useCallback(() => {
    // setFocus(null);
    dispatch(setDestination({ from: to, to: from }));
    setValues({ destination: { ...origin }, origin: { ...destination } });
    // eslint-disable-next-line
  }, [from, to, dispatch]);

  const canSwap = useMemo(() => {
    return from !== '' && to !== '';
  }, [from, to]);

  const handleArrowClick = useCallback(() => {
    if (canSwap) {
      swap();
    }
  }, [swap, canSwap]);

  const handleOnBlur = (e) => {
    if (!isInsideSuggestion) {
      const name = e.target.dataset.value;
      const obj = focus === 'origin' ? from : to;
      const index = suggestionIndex !== null ? suggestionIndex : 0;
      if (typeof obj === 'string') {
        // search
        if (suggestionList.length > 0) {
          if (name === 'origin') {
            dispatch(
              setDestination({
                from: suggestionList[index],
              })
            );
          }
          if (name === 'destination') {
            dispatch(setDestination({ to: suggestionList[index] }));
          }
        }
      }
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      setSuggestionList([]);
    }
  };

  const handleFocus = (e) => {
    const target = e.target;
    const name = target.dataset.value;
    if (target.value !== '') {
      target.select();
    }
    setFocus(name);
  };

  useEffect(() => {
    setSuggestionList([]);
  }, [focus]);

  useEffect(() => {
    if (typeof from === 'object' && origin.name !== from.nameRus) {
      setValues((state) => ({
        ...state,
        origin: { name: from.nameRus, code: from.iataCode },
      }));
    }
    if (typeof to === 'object' && destination.name !== to.nameRus) {
      setValues((state) => ({
        ...state,
        destination: { name: to.nameRus, code: to.iataCode },
      }));
    }
	  // eslint-disable-next-line
  }, [from, to]);

  const handleSelect = React.useCallback(
    (x, focus) => {
      if (focus === 'origin') {
        //eslint-disable-next-line
        if (window.innerWidth > 1023 && destination.code === null) {
          inputRef.current.focus();
          setFocus('destination');
        } else {
          setFocus(null);
        }

        dispatch(setDestination({ from: x }));
        setValues((state) => ({
          ...state,
          origin: { name: x.nameRus, code: x.iataCode },
        }));
      } else {
        if (window.innerWidth > 1023 && dates.from === null) {
          setFocus('forward');
        } else {
          setFocus(null);
        }
        inputRef.current.blur();

        setInsideSuggestion(false);
        dispatch(setDestination({ to: x }));
        setValues((state) => ({
          ...state,
          destination: { name: x.nameRus, code: x.iataCode },
        }));
      }
    },
      // eslint-disable-next-line
    [dates.from]
  );

  return (
    <Wrapper ref={ref}>
      <Field
        data-cy={'whereFromSearchField'}
        active={focus === 'origin'}
        label="Город вылета"
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        value={origin.name}
        error={errors.from}
        code={origin.code}
        type="text"
        data-value="origin"
        autoComplete="none"
        style={{ paddingRight: 22 }}
        {...props}
      />
      <Arrow className={canSwap ? 'active' : ''} onClick={handleArrowClick} />
      <Field
        data-cy={'whereToSearchField'}
        label="Город прилёта"
        active={focus === 'destination'}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        value={destination.name}
        onFocus={handleFocus}
        data-value="destination"
        error={errors.to}
        code={destination.code}
        inputRef={inputRef}
        autoComplete="none"
        {...props}
      />
      {suggestionList.length > 0 && (
        <Suggestion
          onMouseEnter={() => {
            setInsideSuggestion(true);
          }}
          onMouseLeave={() => {
            setInsideSuggestion(false);
          }}
          currentFocus={focus}
          onSelect={handleSelect}
          items={suggestionList}
          suggestionIndex={suggestionIndex}
          setSuggestionIndex={setSuggestionIndex}
        />
      )}
    </Wrapper>
  );
};

export default CityFields;
