import React from 'react';
import styled from 'styled-components';
import {
  getSeatMapState,
  setActiveSegment,
  setActivePassenger,
  setActivePassengerType,
  selectSeat,
} from '../../../../store/modules/booking';
import SeatMap from './SeatMap';
import { Text } from '../../../ui';
import { useSelector, useDispatch } from 'react-redux';
import PassengerSelectButton from './PassengerSelectButton';
import { ReactComponent as ExclamatoryIcon } from '../../../../asset/images/avia/shared/exclamatory.svg';
// import { SeatMapLoaderWithWrapper } from '../../../ui/Loader';
import formatPrice from '../../../../functions/formatPrice';
import quantityCaseFormat from '../../../../functions/quantityCaseFormat'
import Tippy from '@tippyjs/react/headless';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../../asset/images/avia/ui/close.svg'
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../../../ui/PrimaryButton';

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0;
    border-radius: 8px;
    max-width: initial
    // height: calc(var(--vh, 1vh) * 95);

    // @media (min-height: 878px) {
    //   height: auto;
    // }

    // @media (max-width: 1023px) {
    //   display: block;
    //   overflow: scroll;
    //   height: calc(var(--vh, 1vh) * 100);
    //   margin-top: 0 !important;
    //   margin-bottom: 0 !important;
    //   width: 100%;
    // }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: auto;
  overflow: hidden;
  height: fit-content;
`;

const LeftSide = styled.div`
  padding: 30px;
  width: 420px;
  border-right: 3px solid #e3eaff;
  @media (max-width: 1023px) {
    padding: 32px 20px;
    padding-bottom: 0;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 767px) {
    padding: 32px 20px;
    padding-bottom: 0;
    padding-top: 57px;
    width: 100%;
    box-sizing: border-box;
  }
`;
const RightSide = styled.div`
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-right: 100px;
  margin-right: -117px;
  // position: relative;
  @media (max-width: 1023px) {
    padding: 32px 20px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 0;
    margin-right: 0;
    padding-top: 25px;
    padding-left: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 30px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const FromTo = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  & > strong {
    color: #4872f2;
  }

  display: block;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ButtonBlock = styled.div`
  & > button {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  margin-bottom: 35px;

  @media (max-width: 1023px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

const SelectButton = styled.button`
  ${({ selected }) => {
    const mainColor = '#3C3C3C';
    const background = selected ? '#EDF1FE' : 'transparent';

    return `
          min-width: 100px;
          font-family: "Open Sans";
          border-radius: 4px;
          background: ${selected ? background : 'transparent'};
          color: ${selected ? '#4872F2' : '#3C3C3C'};
          border: 1px solid ${selected ? '#4872F2' : '#C4C4C4'};
          outline: none;
          font-style: normal;
          padding: 10px 11px;
          line-height: 20px;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
          max-width: 183px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);

          &:disabled {
              cursor: not-allowed;
              background: transparent;
              color: #B9B9B9;
              border: 1px solid #B9B9B9;
          }
          &:active {
              color: ${mainColor};
              border: 1px solid ${mainColor};
              background-color: ${background};
              box-shadow: 0px 0px 6px rgba(72, 114, 242, 0.64);
          }
          &:hover{
            box-shadow: 0 0 9px -1px #4872F2;
          }
          @media (max-width: 1023px) {
            padding: 8px;
            min-width: 77px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            max-width: 156px;
          }
      `;
  }}
`;

const Obozncheniya = styled.div`
  margin-bottom: 45px;
  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`;

const ObozncheniyaTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  display: block;
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;

const ObobznacheniyaList = styled.div`
  margin-bottom: 45px;

  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

const ObotnacheniyaItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ObotnacheniyaName = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
`;
const ObotnacheniyaPrice = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ObobznacheniyaText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    color: #4872f2;
    font-weight: 600;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const CloseButton = MUIstyled(PrimaryButton)(()=>({
  width: '100%',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 30.5px',
  display: 'block',
  marginTop: '30px',
}))

const PassengerBlockWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
    overflow: hidden;
  }
`;

const PassengersBlock = styled.div`
  & > button {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    overflow: scroll;
    margin-bottom: -10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    & > button {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const Squar = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: ${({ background }) => background};

  border: 1.5px solid
    ${({ background }) => {
      switch (background) {
        case '#CFCBCB':
          return '#848484';
        case '#9484F6':
          return '#6E5CE0';
        case '#96DBDB':
          return '#50A9A9';
        default:
          return '#9C5EB0';
      }
    }};

  @media (max-width: 1023px) {
    width: 20px;
    height: 20px;
  }
`;

const TotalPrice = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;

  & > strong {
    color: #4872f2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileCloseButton = styled.button`
  position: sticky;
  width: 100%;
  bottom: 0px;
  z-index: 2;
  background: #4872f2;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const CloseCrossButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  z-index: 1000;
  
  & > svg {
    fill: #B3B9C0;
  }
`;

const Notification = styled.div`
  padding: 14px 24px;
  padding-left: 12px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > svg {
    margin-right: 7px;
  }

  & > span {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const TippyWrapper = styled.div`
  padding: 10px 15px;
  width: ${({ width }) => width} 130px;
  text-align: center;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 11px 0px;
`;

const TippyText = styled(Text)`
  font-size: 12px;
  line-height: 16px;

  & > strong {
    color: #4872f2;
    font-weight: normal;
  }
`;

const DesktopTop = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MobileTop = styled.div`
  position: sticky;
  top: 59px;
  z-index: 9999;
  background: white;
  width: 100%;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Modal = ({ fare, ...props }) => {
  const dispatch = useDispatch();
  const state = useSelector(getSeatMapState);

  const selectedSeats = state.list[state.selectedSegmentNumber]
    .filter((x) => x.seat !== null)
    .map((x) => x.seat?.number);

  const segment = React.useMemo(
    () => fare.seatMap.find((x) => x.flightNo === state.selectedSegmentNumber),
    [state.selectedSegmentNumber, fare]
  );

  const isIncludesOnlyForAdult = segment?.decks?.some((x) =>
    x?.rows?.some((y) =>
      y.seatGroups.some((z) => z.seats.some((h) => h.onlyForAdult === true))
    )
  );

  return (
    <StyledPopup {...props}>
      <Wrapper>
      <CloseCrossButton
        aria-label="close"
        onClick={(e)=>{
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      >
        <Icon/>
      </CloseCrossButton>
      <MobileTop>
        {' '}
        <FromTo>
          Маршрут:{' '}
          <strong>
            {segment?.from.city} - {segment?.to.city}
          </strong>
        </FromTo>
        <ButtonBlock>
          {fare.seatMap.map((x, key) => {
            const s = state.segmentsAccessibility.find(
              (y) => y.id === x.flightNo
            );
            return (
              <Tippy
                key={key}
                touch={false}
                render={(attrs) => (
                  <TippyWrapper
                    {...attrs}
                    width={s?.haveEmptySelectedPlaces ? 150 : 130}
                  >
                    <TippyText>
                      {s?.haveEmptySelectedPlaces
                        ? 'Места не выбраны'
                        : 'Места выбраны'}
                    </TippyText>
                  </TippyWrapper>
                )}
                offset={[0, 16.5]}
              >
                <SelectButton
                  selected={x.flightNo === state.selectedSegmentNumber}
                  onClick={() => dispatch(setActiveSegment(x.flightNo))}
                >
                  {x.from.city} - {x.to.city}
                </SelectButton>
              </Tippy>
            );
          })}
        </ButtonBlock>
        <PassengerBlockWrapper>
          <PassengersBlock>
            {state.list[state.selectedSegmentNumber].map((x, key) => (
              <PassengerSelectButton
                onClick={() => {
                  dispatch(setActivePassenger(x.uid))
                  dispatch(setActivePassengerType(x.ageCategory));
                }}
                {...x}
                key={key}
                number={key + 1}
                selected={x.uid === state.selectedPassengerId}
              />
            ))}
          </PassengersBlock>
        </PassengerBlockWrapper>
      </MobileTop>
      <LeftSide>
        <DesktopTop>
          <Title>Проведите полет в комфорте</Title>

          <FromTo>
            Маршрут:{' '}
            <strong>
              {segment?.from.city} - {segment?.to.city}
            </strong>
          </FromTo>
          <ButtonBlock>
            {fare.seatMap.map((x, key) => {
              const s = state.segmentsAccessibility.find(
                (y) => y.id === x.flightNo
              );
              return (
                <Tippy
                  key={key}
                  touch={false}
                  render={(attrs) => (
                    <TippyWrapper
                      {...attrs}
                      width={s?.haveEmptySelectedPlaces ? 150 : 130}
                    >
                      <TippyText>
                        {s?.haveEmptySelectedPlaces
                          ? 'Места не выбраны'
                          : 'Места выбраны'}
                      </TippyText>
                    </TippyWrapper>
                  )}
                  offset={[0, 16.5]}
                >
                  <SelectButton
                    selected={x.flightNo === state.selectedSegmentNumber}
                    onClick={() => dispatch(setActiveSegment(x.flightNo))}
                  >
                    {x.from.city} - {x.to.city}
                  </SelectButton>
                </Tippy>
              );
            })}
          </ButtonBlock>
          <PassengerBlockWrapper>
            <PassengersBlock>
              {state.list[state.selectedSegmentNumber].map((x, key) => (
                <PassengerSelectButton
                  onClick={() => {
                    dispatch(setActivePassenger(x.uid))
                    dispatch(setActivePassengerType(x.ageCategory));
                  }}
                  {...x}
                  key={key}
                  number={key + 1}
                  selected={x.uid === state.selectedPassengerId}
                />
              ))}
            </PassengersBlock>
          </PassengerBlockWrapper>
        </DesktopTop>
        <Obozncheniya>
          <ObozncheniyaTitle>Условные обозначения:</ObozncheniyaTitle>
          <ObobznacheniyaList>
            {state.priceArrForColors[2] && (
              <ObotnacheniyaItem>
                <ObotnacheniyaName>
                  <Squar background="#CA96DB"></Squar>
                  <ObobznacheniyaText>
                    Для того, кому важно удобство
                  </ObobznacheniyaText>
                </ObotnacheniyaName>
                <ObotnacheniyaPrice>
                  от{' '}
                  <strong>
                    {formatPrice(state.priceArrForColors[2])}
                  </strong>
                </ObotnacheniyaPrice>
              </ObotnacheniyaItem>
            )}
            {state.priceArrForColors[1] && (
              <ObotnacheniyaItem>
                <ObotnacheniyaName>
                  <Squar background="#96DBDB"></Squar>
                  <ObobznacheniyaText>
                    Для того, кто хочет отдохнуть
                  </ObobznacheniyaText>
                </ObotnacheniyaName>
                <ObotnacheniyaPrice>
                  от{' '}
                  <strong>
                    {formatPrice(state.priceArrForColors[1])}
                  </strong>
                </ObotnacheniyaPrice>
              </ObotnacheniyaItem>
            )}
            <ObotnacheniyaItem>
              <ObotnacheniyaName>
                <Squar background="#9484F6"></Squar>
                <ObobznacheniyaText>
                  Для того, кому важна цена
                </ObobznacheniyaText>
              </ObotnacheniyaName>
              <ObotnacheniyaPrice>
                от{' '}
                <strong>
                  {formatPrice(state.priceArrForColors[0])}
                </strong>
              </ObotnacheniyaPrice>
            </ObotnacheniyaItem>
            <ObotnacheniyaItem>
              <ObotnacheniyaName>
                <Squar background="#CFCBCB"></Squar>
                <ObobznacheniyaText>Кресло уже занято</ObobznacheniyaText>
              </ObotnacheniyaName>
            </ObotnacheniyaItem>
          </ObobznacheniyaList>
        </Obozncheniya>
        <TotalPrice>
          Стоимость всех мест:{' '}
          <strong>{formatPrice(state.totalPrice)}</strong>
        </TotalPrice>
        {state.selectedSeatCount > 0 && state.availableSeatCount > 0 && (
          <Notification>
            <ExclamatoryIcon />
            <Text>Внимание, вы выбрали места не для всех перелетов.</Text>
          </Notification>
        )}
        <CloseButton onClick={props.onClose}>
          Закончить выбор
        </CloseButton>
      </LeftSide>
      <RightSide>
        {/* {state.loading && <SeatMapLoaderWithWrapper />} */}
        <SeatMap
          onPlaceSelect={(x) => {
            dispatch(selectSeat(x));
          }}
          selectedSeats={selectedSeats}
          decks={segment?.decks || []}
          airplaneName={segment?.airplane || ''}
          pricesArr={state.priceArrForColors}
          selectedPassengerType={state.selectedPassengerType}
        />
      </RightSide>
      {!!state.selectedSeatCount && (
        <MobileCloseButton onClick={props.onClose}>
          Добавить {state.selectedSeatCount}{' '}
          {quantityCaseFormat(state.selectedSeatCount, 'место', 'места', 'мест')}{' '}
          за: {formatPrice(state.totalPrice)}
        </MobileCloseButton>
      )}
      </Wrapper>
    </StyledPopup>
  );
};

export default Modal;
