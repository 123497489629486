import React from 'react';
import StarRatings from "react-star-ratings";
import {Box} from "@mui/material";
import styled from "styled-components";

import './style.scss'
import {Button} from "@material-ui/core";
import useClaimsStore from "../../store";

const Header = styled.div`
	color: #2E2E32;
	font-weight: 600;
	line-height: 26px;
  	font-size: 18px;
  	margin-bottom: 16px;
`

const Block = styled.div`
	background-color: #FFFFFF;
  	border: 1px solid #DBE0E5;
  	border-radius: 8px;
  	padding: 8px 16px;
  	display: flex;
  	align-items: center;
  	height: 100px;
  	justify-content: center;
  	flex-wrap: wrap;
`

const Comment = styled.div`
	width: 100%;
	line-height: 18px;
	font-size: 12px;
  	color: #7E8389;
`

const TextArea = styled.textarea`
	width: 100%;
	height: 60px;
	border: none;
	resize: none;
	line-height: 22px;
	font-size: 16px;
	color: #2E2E32;
  	font-family: 'Open Sans', sans-serif;
  	&:focus {
    	border: none;
      	outline: none !important;
	}
`
const ButtonRate = styled(Button)(({ theme }) => ({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '16px 32px 16px 32px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		boxShadow: 'none',
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
}))

const RatingTo = ({ id }) => {

	const [rate, setRate] = React.useState(0)
	const [comment, setComment] = React.useState('')
	const actions = useClaimsStore(({ actions }) => actions)
	const feedbackExist = useClaimsStore(({ feedbackExist }) => feedbackExist.find(item => item.id === id)?.isExist)

	const changeRating = changeRating => {
		if (rate === changeRating) {
			setRate(0)
		} else {
			setRate(changeRating)
		}
	}

	return (
		<Box mt={2} mb={3}>
			<Header>Оцените работу туроператора по заявке</Header>
			{!feedbackExist && <div style={{display: "flex", gap: 24, marginBottom: 16}}>
				<Block style={{minWidth: 256}}>
					<StarRatings
						rating={rate}
						changeRating={changeRating}
						starDimension='20px'
						starRatedColor='#FFCD00'
						starHoverColor='#FFCD00'
						starEmptyColor='#2E2E32'
						starSpacing='1px'
						svgIconViewBox='0 0 22 23'
						svgIconPath='M6.06706 21.8335C5.55595 22.2335 5.03373 22.2446 4.5004 21.8668C3.96706 21.489 3.78929 20.989 3.96706 20.3668L5.86706 14.2001L1.03373 10.7668C0.500397 10.389 0.339508 9.88902 0.551063 9.2668C0.76173 8.64457 1.18929 8.33346 1.83373 8.33346H7.8004L9.73373 1.93346C9.84484 1.62235 10.0173 1.38324 10.2511 1.21613C10.484 1.04991 10.7337 0.966797 11.0004 0.966797C11.2671 0.966797 11.5168 1.04991 11.7497 1.21613C11.9835 1.38324 12.156 1.62235 12.2671 1.93346L14.2004 8.33346H20.1671C20.8115 8.33346 21.2395 8.64457 21.4511 9.2668C21.6617 9.88902 21.5004 10.389 20.9671 10.7668L16.1337 14.2001L18.0337 20.3668C18.2115 20.989 18.0337 21.489 17.5004 21.8668C16.9671 22.2446 16.4448 22.2335 15.9337 21.8335L11.0004 18.0668L6.06706 21.8335Z'
					/>
				</Block>
				<Block style={{width: '100%'}}>
					<Comment>Комментарий</Comment>
					<TextArea onChange={(e) => setComment(e.target.value)} />
				</Block>
			</div>}
			<ButtonRate
				onClick={() => actions.sendRatingTO(id, rate, comment)}
				disabled={feedbackExist || !rate}
				variant='contained'
				fullWidth
			>
				{feedbackExist ? 'Оценка выполнена' : 'Оценить'}
			</ButtonRate>
		</Box>
	);
};

export default RatingTo;