import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const useYandexMapStore = create(
	devtools((set, get) => ({
		openBtn: true,
		activeCountry: null,
		params: {
			center: [20, 20],
			zoom: 2,
			controls: []
		},
		actions: {
			toggleBtns: () => {
				set({ openBtn: !get().openBtn })
			},
			setCountry: value => {
				set({ activeCountry: value })
			},
			setZoom: value => {
				set({
					params: {
						zoom: 6,
						center: [
							Number(value?.latitude?.split(',').join('.')),
							Number(value?.longitude?.split(',').join('.'))
						],
						controls: []
					}
				})
			}
		}
	}))
)

export default useYandexMapStore
