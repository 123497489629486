import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from "@material-ui/core";
import useLoyaltyStore from "./store";
import RegistrationForm from "./components/RegistrationForm";
import LoyaltyForm from "./components/LoyaltyForm";
import HistoryPoints from "./components/HistoryPoints";

const Loyalty = () => {

	const [value, setValue] = useState({
		mobilePhone: '',
	})

	const actions = useLoyaltyStore(({ actions }) => actions)
	const isMember = useLoyaltyStore(({ isMember }) => isMember)
	const client = useLoyaltyStore(({ client }) => client)
	const phoneFound = useLoyaltyStore(({ phoneFound }) => phoneFound)
	const history = useLoyaltyStore(({ history }) => history)

	useEffect(() => {
		if (value.mobilePhone) {
			actions.getPointsHistory()
		}
	}, [client, actions])

	return (
		<>
			<div className={'container'} style={{ width: 600 }}>
				<h3>Получить информацию об участнике программы лояльности</h3>
				<p>Введите номер телефона участника программы лояльности</p>
				<Box style={{ marginBottom: 15 }}>
					<TextField
						label='Телефон'
						variant='outlined'
						fullWidth
						// helperText={errors.phone}
						// error={!!errors.phone}
						value={value.mobilePhone}
						onChange={(event) => setValue({ mobilePhone: event.target.value })}
						required
					/>

				</Box>
				<Box mb={2}>
					<Button variant={'outlined'} color={'primary'} onClick={() => actions.checkLoyalty(value.mobilePhone, value.email)}>Получить</Button>
				</Box>
			</div>
			<div className={'container'}>
				{isMember && phoneFound === 'NotFound' &&
					<RegistrationForm
						phone={value.mobilePhone}
						email={value.email} count={0} />}
				{client && phoneFound !== 'NotFound' &&
					<LoyaltyForm client={client} />}
				{history && phoneFound !== 'NotFound' &&
					<HistoryPoints history={history} />}
			</div>
		</>
	);
};

export default Loyalty;