import React, { useEffect, useState } from 'react'
import {
	Alert,
	tooltipClasses,
	Checkbox,
	FormControlLabel,
	Stack,
	Box,
	TextField,
	Snackbar
} from '@mui/material'
import Switch from '@material-ui/core/Switch'

import './style.css'
import CustomSelect from '../../../../../../components/Select'
import useBookingStore from '../../../../store'
import {
	emailRegExp,
	latinNameRegExp,
	phoneRegExp,
	interPassportNumberRegExp,
	cyrillicRegExp,
	seriesBirthCertificatesRegExp,
	rfPassportSeriesRegExp,
	interPassportSeriesRegExp,
	rfPassportNumberRegExp,
	birthCertNumberRegExp,
	latinUpperAndNumberRegExp,
	latinAndNumberRegExp
} from '../../../../../../constants/reg-exps'
import { useErrorStore } from './store'
import { nationality } from './nationalities'
import useLoyaltyStore from '../../../../../Loyalty/store'
import RegistrationForm from '../../../../../Loyalty/components/RegistrationForm'
import LoyaltyForm from '../../../../../Loyalty/components/LoyaltyForm'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { transliterateToUppercase } from '../../../../../../functions/convertLang'
import MembersBlockModal from './MembersBlockModal'
import { isEmpty } from 'lodash'
import VisaSelect from './VisaSelect'
import { format } from 'date-fns'
import BirthDateInput from './components/BirthDateInput'

const LightTooltip = styled(({ className, color, children, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }}>
		{children}
	</Tooltip>
))(({ theme, color }) => ({
	[`& .${tooltipClasses.tooltipArrow}`]: {
		backgroundColor: color ? 'red' : 'white',
		color: color ? 'white' : 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[7],
		fontSize: 14,
		padding: 10,
		maxWidth: 500
	},
	'& .MuiTooltip-arrow': {
		transform: 'translate(20px, 0px)!important',
		color: color ? 'red' : 'white'
	},
	color
}))
const MembersBlock = ({ peopleKey, title, membersCount }) => {
	const people = useBookingStore(state =>
		state?.peoples?.find(item => item.key === peopleKey)
	)
	const claim = useBookingStore(({ claimNew }) => claimNew)
	const [typeDoc, setTypeDoc] = React.useState(people.identityDocument || 5)
	const [nation, setNation] = useState(false)
	const [error, setError] = useState([])
	const [phoneErrorGds, setPhoneErrorGds] = useState(false)
	const [openTooltip, setOpenTooltip] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const visaServices = useBookingStore(({ visaServices }) => visaServices)
	const visaGroups = useBookingStore(({ visaGroups }) => visaGroups)
	const selectedVisas = useBookingStore(({ selectedServices }) =>
		selectedServices.filter(service =>
			visaGroups.some(visa => visa.id === service?.groupId)
		)
	)
	const visas = useBookingStore(({ visas }) => visas)
	const valid = useErrorStore(({ valid }) => valid)
	const errorForm = useErrorStore(state => state.error)
	const isReadyToReserve = useBookingStore(({ isReadyToReserve }) => isReadyToReserve)

	const [visa, setVisa] = React.useState(
		selectedVisas.find(el =>
			el.clients?.some(client => client?.peopleKey === peopleKey)
		)?.key ?? selectedVisas[0]?.key
	)
	//lname state
	const splitLName = people?.lname?.split(' ')
	const [firstNameLatin, setFirstNameLatin] = useState(
		isEmpty(splitLName) ? '' : splitLName[1]
	)
	const [lastNameLatin, setLastNameLatin] = useState(
		isEmpty(splitLName) ? '' : splitLName[0]
	)

	//name state
	const splitName = people?.name?.split(' ')
	const [firstName, setFirstName] = useState(
		isEmpty(splitName) ? '' : splitName[1]
	)
	const [lastName, setLastName] = useState(
		isEmpty(splitName) ? '' : splitName[0]
	)
	const [patronymic, setPatronymic] = useState(
		isEmpty(splitName) ? '' : splitName[2]
	)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	// const visaOptions = response?.tour?.visaOptions.map(item => {
	// 	return {
	// 		id: item.id,
	// 		name: `${item.type.name.split(':')[1]} ${visas?.find(visa => visa.visaId === item.id) ? ' (подача документов до ' + format(new Date(visas?.find(visa => visa.visaId === item.id).deadlineDate), 'dd.MM.yyyy') + ')' : ''}`
	// 	}
	// })

	const isRussianPassportEnabled = [233909, 325801, 210357, 266539].includes(
		claim?.claimDocument?.stateKey
	)

	const actions = useBookingStore(({ actions }) => actions)

	const applyContactsForAll = useBookingStore(
		({ applyContactsForAll }) => applyContactsForAll
	)

	const handleChangeApplyContactsForAll = event => {
		if (event.target.checked) {
			actions.changeApplyContactsForAll(peopleKey)
		} else {
			actions.changeApplyContactsForAll(false)
		}
	}

	const setErrorForm = useErrorStore(state => state.setErrorForm)
	const [errors, setErrors] = useState({
		lastName: null,
		firstName: null,
		lastNameLatin: null,
		firstNameLatin: null,
		patronymic: null,
		phone: null,
		email: null,
		series: null,
		number: null,
		birthDate: null,
		expirationDate: null
	})

	// useEffect(() => {
	// 	if (!errorForm && Object.values(errors).some(item => item)) {
	// 		setErrorForm(true)
	// 	}
	// }, [errorForm])

	const changeFirstNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		const upperCaseValue = transliterateToUppercase(
			event.target.value.toUpperCase()
		)

		if (!latinNameRegExp.test(upperCaseValue) && upperCaseValue !== '') {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		setFirstNameLatin(upperCaseValue)
		actions.changeLname(lastNameLatin + ' ' + upperCaseValue, peopleKey)
	}

	const changeLastNameLatinHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		const upperCaseValue = transliterateToUppercase(
			event.target.value.toUpperCase()
		)
		if (!latinNameRegExp.test(upperCaseValue) && upperCaseValue !== '') {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только латиницу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		setLastNameLatin(upperCaseValue, people)
		actions.changeLname(upperCaseValue + ' ' + firstNameLatin, peopleKey)
	}

	const changeFirstNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		const upperCaseValue = event.target.value.toUpperCase()
		if (latinNameRegExp.test(upperCaseValue)) return

		if (!cyrillicRegExp.test(upperCaseValue) && upperCaseValue !== '') {
			setErrors({
				...errors,
				[prop]: `Имя должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		setFirstName(upperCaseValue)
		actions.changeName(
			lastName + ' ' + upperCaseValue + ' ' + patronymic,
			peopleKey
		)
	}

	const changeLastNameHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		const upperCaseValue = event.target.value.toUpperCase()
		if (latinNameRegExp.test(upperCaseValue)) return
		if (!cyrillicRegExp.test(upperCaseValue) && upperCaseValue !== '') {
			setErrors({
				...errors,
				[prop]: `Фамилия должна содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		setLastName(upperCaseValue)
		actions.changeName(
			upperCaseValue + ' ' + firstName + ' ' + patronymic,
			peopleKey
		)
	}

	const changePatronymicHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		const upperCaseValue = event.target.value.toUpperCase()
		if (latinNameRegExp.test(upperCaseValue)) return

		if (!cyrillicRegExp.test(upperCaseValue) && upperCaseValue !== '') {
			setErrors({
				...errors,
				[prop]: `Отчество должно содержать только кириллицу`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		setPatronymic(upperCaseValue)
		actions.changeName(
			lastName + ' ' + firstName + ' ' + upperCaseValue,
			peopleKey
		)
	}

	const changePhoneHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		setPhoneErrorGds(false)
		if (peopleKey === memberId) {
			actionsPL.setMemberId(null)
		}
		actions.setPhoneError(false)
		if (!/[0-9]/g.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Номер телефона может содержать только цифры	`
			})
			return
		}
		if (event.target.value.includes('+')) {
			setPhoneErrorGds(true)
			actions.setPhoneError(true)
			actions.changePhone(event.target.value, peopleKey)
			return
		}
		if (!phoneRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Номер телефона указан некорректно`
			})
			setErrorForm(true)
			actions.setPhoneError(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
			actions.setPhoneError(false)
		}
		actions.changePhone(event.target.value, peopleKey)
	}
	const changeEmailHandler = prop => event => {
		setErrors({ ...errors, [prop]: null })
		// actionsPL.setMemberId(null)
		if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
			setErrors({
				...errors,
				[prop]: `Email указан некорректно`
			})
			setErrorForm(true)
		} else {
			setErrors({ ...errors, [prop]: null })
			setErrorForm(false)
		}
		actions.changeEmail(event.target.value, peopleKey)
	}

	const changeNationHandler = (e, n) => {
		if (e !== 210357) {
			setModalVisible(true)
		}
		setNation(e)
		actions.changeNationality(e, n)
	}
	const changeGenderHandler = (e, n) => {
		actions.changeGender(Number(e), n)
	}
	const changeTypeDocHandler = (e, n) => {
		setTypeDoc(e)
		actions.changeTypeDocument(Number(e), n)
	}

	// const changeVisaHandler = (e, n) => {
	// 	setVisa(e)
	// 	actions.changeVisa(e, n)
	// }

	const changeSeriesHandler = prop => event => {
		const value = event.target.value.toUpperCase()
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 1:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 2:
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 4 цифры`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 3:
				if (!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать римское число и две кириллические буквы`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			// case 4:
			// 	if (value.length <= 5) {
			// 		setErrors({
			// 			...errors,
			// 			[prop]: `Серия не менее 5 знаков`
			// 		})
			// 		setErrorForm(true)
			// 	}
			// 	break
			// case 5:
			//   if (!interPassportSeriesRegExp.test(value) && value !== "") {
			//     setErrors({
			//       ...errors,
			//       [prop]: `Серия не более 2 цифры`,
			//     });
			//     setErrorForm(true);
			//   }
			//   break;
			case 6:
				if (!interPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 2 цифры`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 7:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Серия не менее 5 знаков`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}
		actions.changeSeries(value, peopleKey)
	}

	const changeNumberHandler = prop => event => {
		const value = event.target.value
		setErrors({ ...errors, [prop]: null })
		switch (typeDoc) {
			case 1:
				if (!interPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 7 цифр`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 2:
				if (!rfPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 3:
				if (!birthCertNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 6 цифр`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			// case 4:
			// 	if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
			// 		setErrors({
			// 			...errors,
			// 			[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
			// 		})
			// 		setErrorForm(true)
			// 	}
			// 	break
			case 4:
				if (value.length <= 5) {
					setErrors({
						...errors,
						[prop]: `Номер документа не менее 5 знаков`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			//   case 5:
			//     if (!latinAndNumberRegExp.test(value) && value !== "") {
			//       setErrors({
			//         ...errors,
			//         [prop]: `Номер должен содержать только цифры и прописные латинские буквы`,
			//       });
			//       setErrorForm(true);
			//     }
			//     break;
			case 6:
				if (!latinAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			case 7:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
					setErrorForm(true)
				} else {
					setErrors({ ...errors, [prop]: null })
					setErrorForm(false)
				}
				break
			default:
				setErrorForm(false)
				setErrors({ ...errors, [prop]: null })
				break
		}

		actions.changeNumber(value, peopleKey)
	}

	const memberId = useLoyaltyStore(({ memberId }) => memberId)
	const isMember = useLoyaltyStore(({ isMember }) => isMember)
	const client = useLoyaltyStore(({ client }) => client)
	const actionsPL = useLoyaltyStore(({ actions }) => actions)

	const handleChangePL = event => {
		actionsPL.applyPromoCode('')
		if (event.target.checked) {
			actionsPL.setMemberId(Number(event.target.value))
			if (!people?.phone) {
				setError(['Необходимо указать номер телефона туриста'])
				actionsPL.setMemberId(null)
				return
			}
			if (errors.phone) {
				setError([errors.phone])
				actionsPL.setMemberId(null)
				return
			}
			actionsPL.checkLoyalty(people?.phone)
		} else {
			actionsPL.setMemberId(null)
		}
	}

	useEffect(() => {
		// const element = document.getElementById(`info-${peopleKey}`)
		const checkValidationError = paxe => {
			if (!isRussianPassportEnabled) {
				if (!lastNameLatin) {
					setErrors(errors => ({
						...errors,
						lastNameLatin: `Фамилия не может быть пустой`
					}))
					// setError(prev => [
					// 	...new Set([...prev, `Фамилия не может быть пустой`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
					setErrorForm(true)
				} else if (!latinNameRegExp.test(lastNameLatin)) {
					setErrors(errors => ({
						...errors,
						lastNameLatin: `Фамилия должна содержать только латиницу`
					}))
					// setError(prev => [
					// 	...new Set([...prev, `Фамилия должна содержать только латиницу`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
					setErrorForm(true)
				}

				if (!firstNameLatin) {
					setErrors(errors => ({
						...errors,
						firstNameLatin: `Имя не может быть пустым`
					}))
					setErrorForm(true)
					// setError(prev => [...new Set([...prev, `Имя не может быть пустым`])])
					// element.scrollIntoView({ behavior: 'smooth' })
				} else if (!latinNameRegExp.test(firstNameLatin)) {
					setErrors(errors => ({
						...errors,
						firstNameLatin: `Имя должно содержать только латиницу`
					}))
					// setError(prev => [
					// 	...new Set([...prev, `Имя должно содержать только латиницу`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
					setErrorForm(true)
				}
			} else {
				if (!lastName) {
					setErrors(errors => ({
						...errors,
						lastName: `Фамилия не может быть пустой`
					}))
					setErrorForm(true)
					// setError(prev => [
					// 	...new Set([...prev, `Фамилия не может быть пустой`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
				} else if (!cyrillicRegExp.test(lastName)) {
					setErrors(errors => ({
						...errors,
						lastName: `Фамилия должна содержать только кириллицу`
					}))
					setErrorForm(true)
					// setError(prev => [
					// 	...new Set([...prev, `Фамилия должна содержать только кириллицу`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
				}

				if (!firstName) {
					setErrors(errors => ({
						...errors,
						firstName: `Имя не может быть пустым`
					}))
					setErrorForm(true)
					// setError(prev => [...new Set([...prev, `Имя не может быть пустым`])])
					// element.scrollIntoView({ behavior: 'smooth' })
				} else if (!cyrillicRegExp.test(firstName)) {
					setErrors(errors => ({
						...errors,
						firstName: `Имя должно содержать только кириллицу`
					}))
					setErrorForm(true)
					// setError(prev => [
					// 	...new Set([...prev, `Имя должно содержать только кириллицу`])
					// ])
					// element.scrollIntoView({ behavior: 'smooth' })
				}
			}

			if (!paxe?.born) {
				setErrors(errors => ({
					...errors,
					birthDate: `Дата рождения не может быть пустой`
				}))
				setErrorForm(true)
				// setError(prev => [
				// 	...new Set([...prev, `Дата рождения не может быть пустой`])
				// ])
				// element.scrollIntoView({ behavior: 'smooth' })
				// } else if (errors?.birthDate) {
				// 	setError(prev => [...new Set([...prev, `${errors?.birthDate}`])])
				// 	element.scrollIntoView({ behavior: 'smooth' })
			} else setErrors(errors => ({ ...errors, birthDate: null }))

			if (!paxe?.pexpire) {
				setErrors(errors => ({
					...errors,
					expirationDate: `Дата окончания срока действия не может быть пустой`
				}))
				setErrorForm(true)
				// setError(prev => [
				// 	...new Set([
				// 		...prev,
				// 		`Дата окончания срока действия не может быть пустой`
				// 	])
				// ])
				// element.scrollIntoView({ behavior: 'smooth' })
				// } else if (errors?.expirationDate) {
				// 	setError(prev => [...new Set([...prev, `${errors?.expirationDate}`])])
				// 	element.scrollIntoView({ behavior: 'smooth' })
			} else setErrors(errors => ({ ...errors, expirationDate: null }))

			if (!paxe?.phone) {
				setErrors(errors => ({
					...errors,
					phone: `Номер телефона не может быть пустым`
				}))
				setErrorForm(true)
				// setError(prev => [
				// 	...new Set([...prev, `Номер телефона не может быть пустым`])
				// ])
				// element.scrollIntoView({ behavior: 'smooth' })
			} else if (!phoneRegExp.test(paxe?.phone)) {
				setErrors(errors => ({
					...errors,
					phone: `Номер телефона может содержать только цифры`
				}))
				setErrorForm(true)
				// setError(prev => [
				// 	...new Set([...prev, `Номер телефона может содержать только цифры`])
				// ])
				// element.scrollIntoView({ behavior: 'smooth' })
			} else {
				setErrors(errors => ({
					...errors,
					phone: null
				}))
				setErrorForm(false)
			}

			if (!paxe?.email) {
				setErrors(errors => ({
					...errors,
					email: `Email не может быть пустым`
				}))
				setErrorForm(true)
				// setError(prev => [...new Set([...prev, `Email не может быть пустым`])])
				// element.scrollIntoView({ behavior: 'smooth' })
			} else if (!emailRegExp.test(paxe?.email) && !!paxe?.email) {
				setErrors(errors => ({
					...errors,
					email: `Email указан некорректно`
				}))
				setErrorForm(true)
				// setError(prev => [...new Set([...prev, `Email указан некорректно`])])
				// element.scrollIntoView({ behavior: 'smooth' })
			} else {
				setErrors(errors => ({
					...errors,
					email: null
				}))
				setErrorForm(false)
			}

			switch (typeDoc) {
				case 1:
					if (!paxe?.pserie) {
						setErrors(errors => ({
							...errors,
							series: `Серия документа не может быть пустой`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия документа не может быть пустой`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (!interPassportSeriesRegExp.test(paxe?.pserie)) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия может содержать только цифры`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 2:
					if (!paxe?.pserie) {
						setErrors(errors => ({
							...errors,
							series: `Серия документа не может быть пустой`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия документа не может быть пустой`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					if (!rfPassportSeriesRegExp.test(paxe?.pserie)) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 4 цифры`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия может содержать только цифры`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 3:
					if (!paxe?.pserie) {
						setErrors(errors => ({
							...errors,
							series: `Серия документа не может быть пустой`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия документа не может быть пустой`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(paxe?.pserie)) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать римское число и две кириллические буквы`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([
						// 		...prev,
						// 		`Серия документа может содержать римское число, кириллические буквы`
						// 	])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				// case 4:
				// 	if (paxe?.pserie?.length <= 5) {
				// 		setErrors(errors => ({
				// 			...errors,
				// 			series: `Серия не менее 5 знаков`
				// 		}))
				// 		setErrorForm(true)
				// 	}
				// 	break
				case 5:
					if (!paxe?.pserie) {
						setErrors(errors => ({
							...errors,
							series: `Серия документа не может быть пустой`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Серия документа не может быть пустой`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					// else if (!/^[0-9]+[0-9]*$/.test(paxe?.pserie)) {
					// 	setErrors(errors => ({
					// 		...errors,
					// 		series: `Серия может содержать только цифры`
					// 	}))
					// 	setError(prev => [
					// 		...new Set([...prev, `Серия может содержать только цифры`])
					// 	])
					// 	setErrorForm(true)
					// 	element.scrollIntoView({ behavior: 'smooth' })
					// }
					break
				case 6:
					if (!interPassportSeriesRegExp.test(paxe?.pserie) && !!paxe?.pserie) {
						setErrors(errors => ({
							...errors,
							series: `Серия должна содержать 2 цифры`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (paxe?.pserie?.length <= 5) {
						setErrors(errors => ({
							...errors,
							series: `Серия не менее 5 знаков`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, series: null }))
					break
			}

			switch (typeDoc) {
				case 1:
					if (!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 7 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер документа не может быть пустым`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (!interPassportNumberRegExp.test(paxe?.pnumber)) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 7 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([
						// 		...prev,
						// 		`Номер документа может содержать только цифры`
						// 	])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 2:
					if (!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер документа не может быть пустым`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (!rfPassportNumberRegExp.test(paxe?.pnumber)) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([
						// 		...prev,
						// 		`Номер документа может содержать только цифры`
						// 	])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 3:
					if (!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер документа не может быть пустым`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (!birthCertNumberRegExp.test(paxe?.pnumber)) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен быть 6 цифр`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([
						// 		...prev,
						// 		`Номер документа может содержать только цифры`
						// 	])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 4:
					if (!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер документа не может быть пустым`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер документа не может быть пустым`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					} else if (paxe?.pnumber?.length <= 5) {
						setErrors(errors => ({
							...errors,
							number: `Номер документа должен содержать не менее 5 знаков`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер должен содержать не менее 5 знаков`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					break
				case 5:
					if (!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер документа не может быть пустым	`
						}))
						setErrorForm(true)
						// setError(prev => [
						// 	...new Set([...prev, `Номер документа не может быть пустым	`])
						// ])
						// element.scrollIntoView({ behavior: 'smooth' })
					}
					// else if (!/^[0-9]+[0-9]*$/.test(paxe?.pnumber)) {
					// 	setErrors(errors => ({
					// 		...errors,
					// 		number: `Серия может содержать только цифры`
					// 	}))
					// 	setError(prev => [
					// 		...new Set([...prev, `Серия может содержать только цифры`])
					// 	])
					// 	setErrorForm(true)
					// 	element.scrollIntoView({ behavior: 'smooth' })
					// }
					break
				case 6:
					if (!latinAndNumberRegExp.test(paxe?.pnumber) && !!paxe?.pnumber) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				case 7:
					if (
						!latinUpperAndNumberRegExp.test(paxe?.pnumber) &&
						!!paxe?.pnumber
					) {
						setErrors(errors => ({
							...errors,
							number: `Номер должен содержать только цифры и заглавные латинские буквы`
						}))
						setErrorForm(true)
					}
					break
				default:
					setErrors(errors => ({ ...errors, number: null }))
					break
			}
		}
		valid && checkValidationError(people, peopleKey)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valid])

	useEffect(() => {
		if (applyContactsForAll && applyContactsForAll !== peopleKey) {
			setErrors(errors => ({
				...errors,
				email: null,
				phone: null
			}))
			actions.setPhoneError(false)
			setErrorForm(false)
		}
		// eslint-disable-next-line 
	}, [applyContactsForAll])

	const onBlur = (prop, event) => {
		const upperCaseValue = event.target.value.toUpperCase()
		switch (prop) {
			case 'firstNameLatin':
				changeFirstNameLatinHandler('firstNameLatin')({
					...event,
					target: {
						...event.target,
						value: transliterateToUppercase(upperCaseValue)
					}
				})
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Имя не может быть пустым`
					}))
				}
				break
			case 'lastNameLatin':
				changeLastNameLatinHandler('lastNameLatin')({
					...event,
					target: {
						...event.target,
						value: transliterateToUppercase(upperCaseValue)
					}
				})
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Фамилия не может быть пустой`
					}))
				}
				break
			case 'firstName':
				changeFirstNameHandler('firstName')({
					...event,
					target: { ...event.target, value: upperCaseValue }
				})
				changeFirstNameLatinHandler('firstNameLatin')({
					...event,
					target: {
						...event.target,
						value: transliterateToUppercase(upperCaseValue)
					}
				})
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Имя не может быть пустым`
					}))
				}
				break
			case 'lastName':
				changeLastNameHandler('lastName')({
					...event,
					target: { ...event.target, value: upperCaseValue }
				})
				changeLastNameLatinHandler('lastNameLatin')({
					...event,
					target: {
						...event.target,
						value: transliterateToUppercase(upperCaseValue)
					}
				})
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Фамилия не может быть пустой`
					}))
				}
				break
			case 'phone':
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Номер телефона не может быть пустым`
					}))
				}
				break
			case 'email':
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Email не может быть пустым`
					}))
				}
				break
			case 'series':
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Серия документа не может быть пустой`
					}))
				}
				break
			case 'number':
				if (isEmpty(event.target.value)) {
					setErrors(errors => ({
						...errors,
						[prop]: `Номер документа не может быть пустым`
					}))
				}
				break
			case 'patronymic':
				changePatronymicHandler('patronymic')({
					...event,
					target: { ...event.target, value: upperCaseValue }
				})
				break
			default:
				break
		}
	}
	const visaOptions = [...visaServices, ...selectedVisas].map(item => {
		return {
			...item,
			name: `${item.name.split(':')[1]} ${
				visas?.find(visa => visa.visaId === item.id)
					? ' (подача документов до ' +
					  format(
							new Date(
								visas?.find(visa => visa.visaId === item.id).deadlineDate
							),
							'dd.MM.yyyy'
					  ) +
					  ')'
					: ''
			}`
		}
	})

	const changeVisaHandler = (e, n) => {
		setVisa(e)
		actions.changeVisa(e, n)
	}

	return (
		<>
			<Snackbar
				open={!isEmpty(error)}
				autoHideDuration={6000}
				onClose={() => setError([])}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<Stack flexDirection='column' gap={1}>
					{error.map(item => {
						return (
							<Alert
								onClose={() => setError([])}
								severity={'error'}
								sx={{ width: '300px' }}>
								{`${peopleKey} турист: ${item}`}
							</Alert>
						)
					})}
				</Stack>
			</Snackbar>
			<div className='block-header' data-errorsValidation={Object.values(errors).some(el => el) ? 'true' : 'false'} id={`info-${peopleKey}`}>
				<h3 className='title'>
					<span>{title ? title : `Информация о туристе ${peopleKey}`}</span>
					{agentInfo && agentInfo.agents?.name.includes('F&S') && people.age === 1 && (
						<span className={'switcher-pl'}>
							Участник программы лояльности{' '}
							<Switch
								checked={memberId === peopleKey}
								value={peopleKey}
								onChange={handleChangePL}
								color='primary'
							/>
						</span>
					)}
				</h3>
			</div>
			<Alert severity='error' sx={{ width: '100%' }}>
				Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
				информации о туристе!
			</Alert>
			<div className='members-form'>
				{isRussianPassportEnabled ? (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={people?.sex || '1'}
								options={[
									{ id: 1, name: 'Муж.' },
									{ id: 2, name: 'Жен.' }
								]}
								title='Пол'
								count={peopleKey}
								// onChange={actions.changeGender}
								onChange={changeGenderHandler}
								required
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-русски'
								variant='outlined'
								fullWidth
								value={lastName || ''}
								onChange={changeLastNameHandler('lastName')}
								helperText={errors.lastName}
								error={!!errors.lastName}
								onBlur={event => !errors.lastName && onBlur('lastName', event)}
								required
								// title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-русски'
								variant='outlined'
								fullWidth
								value={firstName || ''}
								onChange={changeFirstNameHandler('firstName')}
								helperText={errors.firstName}
								error={!!errors.firstName}
								onBlur={event =>
									!errors.firstName && onBlur('firstName', event)
								}
								required
								// title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Отчество по-русски'
								variant='outlined'
								fullWidth
								value={patronymic || ''}
								onChange={changePatronymicHandler('patronymic')}
								helperText={errors.patronymic}
								error={!!errors.patronymic}
								onBlur={event =>
									!errors.patronymic && onBlur('patronymic', event)
								}
								// required
								// title='В о допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInput
								onChange={actions.changeBirthDate}
								count={peopleKey}
								label='Дата рождения'
								value={people?.born || null}
								birthDateErorr={errors?.birthDate}
								setErrors={setErrors}
								note='Дата рождения не может быть пустой'
								required
							/>
						</Box>
						{(!applyContactsForAll || applyContactsForAll === peopleKey) && (
							<>
								<Box style={{ marginBottom: 15 }}>
									<LightTooltip
										open={phoneErrorGds || openTooltip}
										color={phoneErrorGds}
										onFocus={() => setOpenTooltip(true)}
										onBlur={event => {
											setOpenTooltip(false)
											onBlur('phone', event)
										}}
										title={
											<>
												{phoneErrorGds && (
													<div style={{ whiteSpace: 'nowrap' }}>
														Телефон в формате код страны + код оператора/города
														+ номер
													</div>
												)}
												<div>"+" не указывать, пример 79031111111</div>
											</>
										}
										arrow
										disableHoverListener
										placement='top-start'>
										<TextField
											label='Телефон'
											variant='outlined'
											fullWidth
											helperText={errors.phone}
											error={!!errors.phone}
											value={people?.phone || ''}
											onChange={changePhoneHandler('phone')}
											required
										/>
									</LightTooltip>
								</Box>
								<Box>
									<TextField
										label='E-Mail'
										variant='outlined'
										fullWidth
										helperText={errors.email}
										error={!!errors.email}
										value={people?.email || ''}
										onChange={changeEmailHandler('email')}
										onBlur={event => onBlur('email', event)}
										required
									/>
								</Box>
							</>
						)}
					</div>
				) : (
					<div className='info'>
						<Box style={{ marginBottom: 15 }}>
							<CustomSelect
								value={people?.sex || '1'}
								options={[
									{ id: '1', name: 'Муж.' },
									{ id: '2', name: 'Жен.' }
								]}
								title='Пол'
								count={peopleKey}
								// onChange={actions.changeGender}
								onChange={changeGenderHandler}
								required
								isSex={true}
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Фамилия по-латински'
								variant='outlined'
								fullWidth
								value={lastNameLatin || ''}
								onChange={changeLastNameLatinHandler('lastNameLatin')}
								helperText={errors.lastNameLatin}
								onBlur={event => onBlur('lastNameLatin', event)}
								error={!!errors.lastNameLatin}
								required
								title='В фамилии допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<TextField
								label='Имя по-латински'
								variant='outlined'
								fullWidth
								value={firstNameLatin || ''}
								onChange={changeFirstNameLatinHandler('firstNameLatin')}
								helperText={errors.firstNameLatin}
								error={!!errors.firstNameLatin}
								onBlur={event => onBlur('firstNameLatin', event)}
								required
								title='В имени допустимы только прописные латинские буквы'
							/>
						</Box>
						<Box style={{ marginBottom: 15 }}>
							<BirthDateInput
								onChange={actions.changeBirthDate}
								count={peopleKey}
								label='Дата рождения'
								value={people?.born || null}
								birthDateErorr={errors?.birthDate}
								setErrors={setErrors}
								note='Дата рождения не может быть пустой'
								required
							/>
						</Box>
						{(!applyContactsForAll || applyContactsForAll === peopleKey) && (
							<>
								<Box style={{ marginBottom: 15 }}>
									<LightTooltip
										open={phoneErrorGds || openTooltip}
										color={phoneErrorGds}
										onFocus={() => setOpenTooltip(true)}
										onBlur={event => {
											setOpenTooltip(false)
											onBlur('phone', event)
										}}
										title={
											<>
												{phoneErrorGds && (
													<div style={{ whiteSpace: 'nowrap' }}>
														Телефон в формате код страны + код оператора/города
														+ номер
													</div>
												)}
												<div>"+" не указывать, пример 79031111111</div>
											</>
										}
										arrow
										disableHoverListener
										placement='top-start'>
										<TextField
											label='Телефон'
											variant='outlined'
											fullWidth
											helperText={errors.phone}
											error={!!errors.phone}
											value={people?.phone || ''}
											onChange={changePhoneHandler('phone')}
											required
										/>
									</LightTooltip>
								</Box>
								<Box>
									<TextField
										label='E-Mail'
										variant='outlined'
										fullWidth
										helperText={errors.email}
										error={!!errors.email}
										value={people?.email || ''}
										onBlur={event => onBlur('email', event)}
										onChange={changeEmailHandler('email')}
										required
									/>
								</Box>
							</>
						)}
					</div>
				)}
				<div className='docs'>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={nation || people?.nationality || '210357'}
							options={nationality}
							title='Гражданство'
							count={peopleKey}
							// onChange={actions.changeNationality}
							onChange={changeNationHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<CustomSelect
							value={people?.identityDocument || 5}
							options={[
								{ id: 5, name: 'Иной документ' },
								{ id: 2, name: 'Паспорт' },
								{ id: 3, name: 'Свидетельство о рождении' },
								{ id: 1, name: 'Заграничный паспорт' },
								{ id: 4, name: 'ID карта' }
								// { id: 6, name: 'Дипломатический паспорт' },
								// { id: 7, name: 'Служебный паспорт' },
							]}
							title='Тип документа'
							count={peopleKey}
							// onChange={actions.changeTypeDocument}
							onChange={changeTypeDocHandler}
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Серия документа'
							variant='outlined'
							fullWidth
							helperText={errors.series}
							error={!!errors.series}
							value={people?.pserie || ''}
							onChange={changeSeriesHandler('series')}
							onBlur={event => onBlur('series', event)}
							title='Серия паспорта может содержать только цифры и прописные латинские буквы'
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<TextField
							label='Номер документа'
							variant='outlined'
							fullWidth
							helperText={errors.number}
							error={!!errors.number}
							value={people?.pnumber || ''}
							onChange={changeNumberHandler('number')}
							onBlur={event => onBlur('number', event)}
							title='Номер паспорта должен содержать только цифры и заглавные латинские буквы'
							required
						/>
					</Box>
					<Box style={{ marginBottom: 15 }}>
						<BirthDateInput
							onChange={actions.changeExpirationDate}
							count={peopleKey}
							label='Действителен до'
							value={people?.pexpire || null}
							birthDateErorr={errors?.expirationDate}
							setErrors={setErrors}
							isExpiration={true}
							note='Дата окончания срока действия не может быть пустой'
							required
						/>
					</Box>
					{!isEmpty(visaOptions) && (
						<Box style={{ marginBottom: 15 }}>
							<VisaSelect
								value={visa}
								options={visaOptions}
								title='Виза'
								count={peopleKey}
								onChange={changeVisaHandler}
								required
							/>
						</Box>
					)}
					{(!applyContactsForAll || applyContactsForAll === peopleKey) &&
						membersCount !== 1 && (
							<Box>
								<FormControlLabel
									control={
										<Checkbox
											checked={applyContactsForAll === peopleKey}
											onChange={handleChangeApplyContactsForAll}
										/>
									}
									label='Использовать контакты для всех туристов'
								/>
							</Box>
						)}
				</div>
			</div>
			{memberId === peopleKey && isMember === 'NotFound' && (
				<RegistrationForm count={peopleKey} />
			)}
			{client && memberId === peopleKey && <LoyaltyForm client={client} />}
			{modalVisible && (
				<MembersBlockModal title='Сообщение' onClose={setModalVisible} />
			)}
		</>
	)
}

export default MembersBlock
