
import { create } from 'zustand'
import axios from 'axios'
import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE + '/geocontent'

const useExcursionTourStore = create(
    devtools((set, get) => ({
        excursionTour: {},
        excursionProgram: {},
        actions: {
            getExcursionTour: async (url) => {
                try {
                    const res = await axios({
                        url: `${BASE_URL}/hotel/getbyurlname`,
                        method: 'POST',
                        headers: {
                            'Content-Type': `application/json`,
                            'X-TUI-Storefront-Id': `b2c:ru`
                        },
                        data: decodeURI(url)
                    })
                    set({ excursionTour: res.data })
                    get().actions.getExcursionProgram(res.data.id)
                } catch (e) {
                    console.log(e)
                }
            },
            getExcursionProgram: async (id) => {
                try {
                    const res = await axios({
                        url: `${BASE_URL}/excursion/tour`,
                        method: 'GET',
                        headers: {
                            'Content-Type': `application/json`,
                            'X-TUI-Storefront-Id': `b2c:ru`
                        },
                        params: {
                            id: id,
                        }

                    })
                    set({ excursionProgram: res.data.excursionProgram })
                }
                catch (e) {
                    console.log(e)
                }
            },
        }
    }))
)

export default useExcursionTourStore