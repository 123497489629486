import Like from '../../asset/images/like.png'
import Exclusive from '../../asset/images/exclusive.png'
import Hits from '../../asset/images/hits.png'
import Family from '../../asset/images/familyHotel.png'
import FUN from '../../asset/images/FUN.png'
import ROBINSON from '../../asset/images/ROBINSON.png'
import SUNEO from '../../asset/images/SUNEO.png'
import BONUS from '../../asset/images/BONUS.png'
import StarIcon from '@material-ui/icons/Star'
import Degree from '../../asset/images/360degrees.png'
import RIXOS from '../../asset/images/RIXOS.jpg'
import Sunrise from '../../asset/images/Sunrise.png'
import Albatros from '../../asset/images/albatros.png'
import Grecotel from '../../asset/images/Grecotel.png'
import SPLASHWORLD from '../../asset/images/splashWorld.png'
import TUIBLUE from '../../asset/images/TUIBLUE.png'
import Cuba from '../../asset/images/Cuba.png'
import Weddings from '../../asset/images/weddings.jpg'
import Best from '../../asset/images/Best.png'
import VIP from '../../asset/images/VIP.jpg'
import Ayurveda from '../../asset/images/Ayurveda.jpg'

const HotelsCard = ({
	name,
	id,
	isChecked,
	changeStatus,
	prefix = '',
	icon
}) => {
	const getHotelIcon = (icon, name) => {
		if (
			name === 'Молодежный' ||
			name === 'отели, размещающие одиноких мужчин' ||
			name === 'Смежные номера' ||
			name === 'FUN&SUN FAMILY' ||
			name === 'Premium'
		) {
			return (
				<>
					{icon ? (
						icon
					) : (
						<StarIcon
							style={{ marginRight: 3, marginLeft: 3, fill: '#FFE874' }}
							fontSize='small'
						/>
					)}
				</>
			)
		}
		let result
		switch (name) {
			case 'Рекомендуемые':
				result = Like
				break
			case 'Эксклюзивный':
				result = Exclusive
				break
			case 'Хиты продаж':
				result = Hits
				break
			case 'Семейный отель':
				result = Family
				break
			case 'FUN!Тастические туры':
				result = FUN
				break
			case 'ROBINSON':
				result = ROBINSON
				break
			case 'SuneoClub':
				result = SUNEO
				break
			case 'Бонусы':
				result = BONUS
				break
			case '360 градусов':
				result = Degree
				break
			case 'RIXOS HOTELS':
				result = RIXOS
				break
			case '.Sunrise Hotels':
				result = Sunrise
				break
			case 'Albatros Hotels':
				result = Albatros
				break
			case 'Grecotel':
				result = Grecotel
				break
			case 'SPLASHWORLD':
				result = SPLASHWORLD
				break
			case 'TUI BLUE':
				result = TUIBLUE
				break
			case 'Iberotel':
				result = Cuba
				break
			case 'Свадебные предложения':
				result = Weddings
				break
			case 'Лучший пляж':
				result = Best
				break
			case 'VIP':
				result = VIP
				break
			case 'Ayurveda':
				result = Ayurveda
				break
			default:
				result = ''
		}
		return (
			<>
				{icon ? (
					icon
				) : result ? (
					<img
						src={result}
						alt=''
						style={{ padding: '3px', paddingLeft: '1px' }}
					/>
				) : (
					<div style={{ padding: '13px' }}></div>
				)}
			</>
		)
	}

	return (
		<div>
			<label
				htmlFor={prefix + id}
				style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
				title={name}>
				<input
					id={prefix + id}
					type='checkbox'
					checked={isChecked}
					onChange={event => changeStatus(id, event)}
				/>
				{getHotelIcon(icon, name)}
				{name}
			</label>
		</div>
	)
}

export default HotelsCard
