import React from "react"
import styled from "styled-components"
import { Text,Point,FlightTypeIcon,LowcosterPopup } from "../../../../../ui"
import formatDuration from '../../../../../../functions/formatDuration';
import quantityCaseFormat from '../../../../../../functions/quantityCaseFormat'
import getAirlineLogo from "../../../../../../functions/getAirlineLogo";
import moment from "moment"
import baggageSrc from "../../../../../../asset/images/avia/shared/baggage.svg"
import { useOrderPosition } from "../../useItemProps"

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 9px;
`

const ItemContainer = styled.div`
  position: relative;
  font-family: "Open Sans";
`

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;

  span {
    margin-bottom: 7px;
  }
`

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;

  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }
`

const PlaceTimeWrapper = styled.div`
  display: flex;
`

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }
`

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`

const PlaceContainer = styled.div``

const Description = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
`

const HighlitedDesctiption = styled.span`
  display: inline;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #4872F2;
`

const FlightName = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  display: block;
  margin-bottom: 15px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 60px 1.25fr;
  row-gap: 20px;
`

const Container = styled.div`
  & > span {
    display: block;
  }

  max-width: 204px;
  overflow: hidden;

  & > div {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`

const DetailDescription = styled.div`
  display: flex;
  justify-content: space-between;
`

const DetailDescritionRow = styled.div`
  & > span {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const DetailDescriptionText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;

  & > strong {
    color: #3c3c3c;
  }
`

const AirlineContainer = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 7px;
  }
`

const Airline = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
`

const AirlineName = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`

const StyledType = styled(FlightTypeIcon)`
  margin-left: 0;
  width: fit-content;
`

const IconFlex = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;

  & > div {
    margin-right: 10px;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const Flight = props => {
  const stopString = `${props.stopOvers.length} ${quantityCaseFormat(
    props.stopOvers.length,
    "пересадка",
    "пересадки",
    "пересадок"
  )}`
  const travelTime = formatDuration(props.duration)
  const orderPosition = useOrderPosition()
  const baggage = orderPosition.tariff.fareFamilies
    ? (orderPosition.tariff.fareFamilies[0].features || []).find(
        el => el.type === "Baggage"
      )
    : null

  const baggageFormatted = baggage ? baggage.descriptionRus : "неизвестно"

  const flightNameStartString = props.index === 0 ? "Туда: " : "Обратно: "

  return (
    <ItemContainer>
      {props.flightsCount > 1 && (
        <FlightName>
          {flightNameStartString}
          {props.departureCity.name} — {props.arrivalCity.name}
        </FlightName>
      )}
      <IconFlex>
        <StyledType flightType={props.flight_Type} />
        {!!props.segments.find(x => x.isLowcoster) && <LowcosterPopup />}
      </IconFlex>
      <Grid>
        <div>
          <PlaceTimeWrapper>
            <Time color="#3C3C3C">
              {moment(props.departureDateTime).format("HH:mm")}
            </Time>
            <ProgressInfo>
              <ProgressLine>
                <PointsContainer>
                  <Point />
                  {props.stopOvers.map((x, key) => (
                    <Point key={key} upperText={x.city} />
                  ))}
                  <Point />
                </PointsContainer>
              </ProgressLine>
            </ProgressInfo>
            <Time color="#3C3C3C">
              {moment(props.arrivalDateTime).format("HH:mm")}
            </Time>
          </PlaceTimeWrapper>
          <PlaceDateWrapper>
            <PlaceContainer>
              <Description>
                <HighlitedDesctiption>
                  {props.departureAirport.code}
                </HighlitedDesctiption>{" "}
                {props.departureCity.name}
              </Description>
              <Description>
                <strong>{moment(props.departureDateTime).format("DD MMMM YYYY, dd")}</strong>
              </Description>
            </PlaceContainer>
            <PlaceContainer style={{ textAlign: "center" }}>
              <Description>В пути: {travelTime}</Description>
              {props.stopOvers.length > 0 && (
                <Description>
                  <HighlitedDesctiption>{stopString}</HighlitedDesctiption>
                </Description>
              )}
            </PlaceContainer>
            <PlaceContainer>
              <Description>
                <HighlitedDesctiption>
                  {props.arrivalAirport.code}
                </HighlitedDesctiption>{" "}
                {props.arrivalCity.name}
              </Description>
              <Description>
                <strong>{moment(props.arrivalDateTime).format("DD MMMM YYYY, dd")}</strong>
              </Description>
            </PlaceContainer>
          </PlaceDateWrapper>
        </div>
        <div />
        <Container>
          <AirlineContainer>
            <Airline
              src={getAirlineLogo(
                props.segments[0]?.marketingAirline.code,
                {width: 50, height: 50}
              )}
              alt={props.segments[0]?.marketingAirline.code}
            />
            <AirlineName>
              {props.segments[0]?.marketingAirline?.name}
            </AirlineName>
          </AirlineContainer>

          <Row>
            <>
              <img src={baggageSrc} alt="" />
              <AirlineName
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {baggageFormatted}
              </AirlineName>
            </>
          </Row>
        </Container>
        {props.flightsCount === 1 && (
          <DetailDescription>
            <DetailDescritionRow>
              <DetailDescriptionText>
                Рейс:{" "}
                <strong>
                  {props.segments[0]?.marketingAirline?.code}{" "}
                  {props.segments[0]?.flightNumber} •{" "}
                  {props.segments[0]?.airplane?.name}
                </strong>
              </DetailDescriptionText>
              <DetailDescriptionText>
                Терминалы:{" "}
                <strong>
                  вылет-{props.departureAirport.terminal}, прилет-
                  {props.arrivalAirport.terminal}
                </strong>
              </DetailDescriptionText>
            </DetailDescritionRow>
            <DetailDescritionRow>
              <DetailDescriptionText>
                Авиакомпания:{" "}
                <strong>{props.segments[0]?.marketingAirline.code}</strong>
              </DetailDescriptionText>
              <DetailDescriptionText>
                Рейс выполняет:{" "}
                <strong>{props.segments[0]?.operatingAirline.code}</strong>
              </DetailDescriptionText>
            </DetailDescritionRow>
          </DetailDescription>
        )}
      </Grid>
    </ItemContainer>
  )
}

export default Flight
