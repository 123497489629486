import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {getBookingFaresState,} from '../../../store/modules/booking';
import Text from '../../ui/Typography';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import {Checkbox, DatePicker, Field, RadioGroup, Select,} from '../../ui';
// import AncilllaryServices from './AncillaryServices';
import translateToLatin from '../../../functions/translateToLatin';
import AdultPath from '../../../asset/images/avia/passenger-type/adult.svg';
import ChildPath from '../../../asset/images/avia/passenger-type/child.svg';
import InfantPath from '../../../asset/images/avia/passenger-type/infant.svg';
import {useSelector} from 'react-redux';
import {PassengerAutoSelectModal} from './../AutoSelectModal';
import _ from 'lodash';
import moment from 'moment';
import NumberTooltip from '../../ui/form/NumberTooltip';
import {useMediaQuery} from 'react-responsive'
import {getCountriesList, getCountriesTotalList} from "../../../functions/helpers"
import ReactCountryFlag from "react-country-flag";
import Api from '../../../functions/fetchApi'


async function getPassengers (SearchText) {
  try {
    return Api.get(process.env.REACT_APP_GET_USER_PASSANGERS)
      .query({
        PageSize: 1000,
        SearchText
      })
      .setContentType('application/json')
      .addAuthGuard()
      .send()
  } catch (e) {
    console.log(e)
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

const Header = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    ${Text} {
      font-weight: bold;
    }
    @media (max-width: 780px) {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      ${Text} {
        font-weight: 600;
      }
    }
  `,
};

const FormBlockNew = styled.div`
  display: grid;
  grid-template-areas:
    'field-1 field-2 field-3'
    '. . field-4'
    'field-11 field-11 field-11'
    'field-5 field-6 field-6 '
    'field-7 field-7 field-8 '
    'field-9 field-10 field-12 ';
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 20px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    grid-template-areas:
      'field-6'
      'field-1'
      'field-2'
      'field-3'
      'field-4'
      'field-11'
      'field-5'
      'field-7'
      'field-8'
      'field-9'
      'field-10'
      'field-12';
  }

  & .field-1 {
    grid-area: field-1;
  }
  & .field-2 {
    grid-area: field-2;
  }
  & .field-3 {
    grid-area: field-3;
  }
  & .field-4 {
    grid-area: field-4;
  }
  & .field-5 {
    grid-area: field-5;
  }
  & .field-6 {
    grid-area: field-6;
    @media (max-width: 767px) {
      height: 56px;
    }
  }
  & .field-7 {
    grid-area: field-7;
  }
  & .field-8 {
    grid-area: field-8;
  }
  & .field-9 {
    grid-area: field-9;
  }
  & .field-10 {
    grid-area: field-10;
  }
  & .field-11 {
    grid-area: field-11;
  }
  & .field-12 {
    grid-area: field-12;
  }
  & .field-13 {
    grid-area: field-13;
  }
  /* & .field-14 {
    grid-area: field-14;
  } */
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const AddPassangers = styled(Text)`
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }

    @media(max-width: 780px){
      margin-left:0px;
      margin-top: 15px;
    }
`;

const LoyalityBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 30px;
  grid-template-areas: 
    ${({ checked }) =>
      checked
        ? `'field-1 field-1 field-1'
    'field-2 field-3 .';`
        : `'field-1 field-1 field-1';`}
    
  margin-top: 30px;
  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    margin-top: 15px;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    display: block;
    & > div {
      margin-bottom: 30px;
    }
    & > :last-child {
      margin-bottom: 0;
    }

  }
  & .field-1 {
    grid-area: field-1;
  }
  & .field-2 {
    grid-area: field-2;
  }
  & .field-3 {
    grid-area: field-3;
  }
`;

const PassengerIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const FioBLock = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

const BlueContainer = styled.div`
  background: #edf1fe;
  // border-radius: 4px;
  padding: 12px 0;
  position: relative;
  &:before {
    background: #edf1fe;
    left: -14px;
    content: '';
    display: block;
    width: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 4px 0 0 4px;
  }

  &:after {
    background: #edf1fe;
    right: -14px;
    content: '';
    display: block;
    width: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 0 4px 4px 0;
  }
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
`;

const DesktopDescription = styled(Text)`
  display: block;
  margin-bottom: 15px;
`;

const LoyalityCheckboxWrapper = styled.div``;

const AbolutePassengersList = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;

  box-shadow: rgba(117, 122, 136, 0.19) 0 4px -12px 5px;
  width: 100%;
`;

const AbsoluteItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 58px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: #f1f4ff;
  }
`;

const AbsoluteSubText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #737373;
  margin-bottom: 10px;
`;

const CONVERTED_DOCS = {
  RussianFederationPassport: { value: 'PASSPORT', label: 'Паспорт РФ' },
  InternationalPassport: {
    value: 'INTERNATIONAL_PASSPORT1',
    label: 'Заграничный паспорт',
  },
  NationalPassport: {
    value: 'NATIONALPASSPORT',
    label: 'Национальный паспорт',
  },
  BirthCertificate: {
    value: 'BIRTH_CERTIFICATE',
    label: 'Свидетельство о рождении',
  },
  Other: {
    value: 'OTHER',
    label: 'Другой документ',
  },
};

const COUNTRIES_WITHOUT_FLAGS = ['AB', 'OS', 'DN', 'LN'];

export const CountryNameWithFlag = ({nameRus, isoCode}) => {
  return (
    <>
      {!COUNTRIES_WITHOUT_FLAGS.includes(isoCode) ? <ReactCountryFlag
        countryCode={isoCode}
        svg
        style={{marginRight:'5px',width:'24px',height:'18px',borderRadius:'30%'}}
      /> : ''}
      {nameRus}
    </>
  )
}

const PassengerItem = ({
  index,
  onChange,
  frequentFlyerAirlines,
  ...props
}) => {
  const isMobile = useMediaQuery({maxWidth: 767})
  const [autoSelectInProgress, setProgress] = useState(false);
  const [passengersList, setPassengersList] = React.useState([]);
  const [citizenAbsolute, setCitizenAbsolute] = React.useState({value: '', label: '',});
  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [totalCountriesList, setTotalCountriesList] = React.useState([]);

  const isAuthorized = useSelector(state => state.user.status.isAuthorized)
  const booking = useSelector(state => state.booking);
  const ticket = booking.ticket;
  const isInternationalFlight = ticket.isInternationalFlight;
  const isDomesticPassportFlight = ticket.isDomesticPassportFlight;

  // const dispatch = useDispatch();
  const fares = useSelector(getBookingFaresState);

  const formik = useFormikContext();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getCountriesTotalList();
      if (response?.data.length) {
        const result = response.data.map((country) => ({
          label: CountryNameWithFlag(country),
          value: country.isoCode
        }))
        result.sort((a,b) => ['KZ','UZ','AZ','GE'].includes(a.value) ? -1 : 1)
        result.sort((a,b) => a.value === 'BY' ? -1 : 1)
        result.sort((a,b) => a.value === 'RU' ? -1 : 1)
        setTotalCountriesList(result);
      }
    }

    fetchData()
      .catch(console.error)
  }, [])

  const notSamo = fares.items.some((x) => x.gds !== 'SAMO');

  const airlines = React.useMemo(
    () =>
      (frequentFlyerAirlines || []).map((x) => ({
        label: x.nameRus || x.nameEng || x.code,
        value: x.code,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [frequentFlyerAirlines.length]
  );

  const handleTicketClassChange = (value) => {
    formik.setFieldValue(`passengers.${index}.sex`, value);
  };

  const [isLoyalVisibile, setLoyalVisibility] = React.useState(false);

  const errors = formik.errors.passengers  || [];
  const error = errors[index]
    ? errors[index]
    : {};
  const { touched, setFieldTouched } = formik;
  const isTouched =
    touched.passengers && touched.passengers[index]
      ? touched.passengers[index] : {};

  const DocumentType = React.useMemo(() => {
    switch (props.ageCategory) {
      case 'ADULT': {
        const arr = [
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
        ];
        if (!isInternationalFlight || isDomesticPassportFlight) {
          arr.unshift({ value: 'PASSPORT', label: 'Паспорт РФ' })
        }
        if (props.citizenship !== 'RU') {
          arr.length = 0
          arr.push({ value: 'NATIONALPASSPORT', label: 'Нац. паспорт' })
          arr.push({ value: 'OTHER', label: 'Другой документ' })
        }
        return arr;
      }
      default: {
        const arr = [
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
        ];
        if (props.citizenship !== 'RU') {
          arr.length = 0
          arr.push({ value: 'NATIONALPASSPORT', label: 'Нац. паспорт' });
          arr.push({ value: 'OTHER', label: 'Другой документ' });
        }
        return arr;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ageCategory, props.citizenship]);

  const [{ documentType, citizenship }, setSelectVal] = useState({
    documentType: DocumentType[0],
    citizenship: { value: 'RU', label: CountryNameWithFlag({nameRus:'Россия',isoCode:'RU'}) },
  });

  useEffect(() => {
    formik.setFieldValue(`passengers.${index}.documentType`, DocumentType[0]?.value);
    formik.setFieldValue(`passengers.${index}.citizenship`, citizenship?.value);

    setSelectVal(() => ({ citizenship, documentType: DocumentType[0] }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DocumentType]);

  const citizenshipDefault = { value: 'RU', label: CountryNameWithFlag({nameRus:'Россия',isoCode:'RU'}) };

  const handleBirthDateChange = (val) => {
    formik.setFieldValue(`passengers.${index}.birthDate`, val);
  };

  const setPassengerNameDebounced = React.useMemo(
    () =>
      _.debounce((args) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.nameEng`,
          translateToLatin(args.value).toLocaleUpperCase()
        );
        // formik.setFieldTouched(`passengers.${args.passengerIndex}.nameEng`,true)
        // dispatch(setPassengerName(args));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setPassengerSurnameDebounced = React.useMemo(
    () =>
      _.debounce((args) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.surnameEng`,
          translateToLatin(args.value).toLocaleUpperCase()
        );
        // formik.setFieldTouched(`passengers.${args.passengerIndex}.surnameEng`,true)
        // dispatch(setPassengerSurname(args));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setPassengerSecondNameDebounced = React.useMemo(
    () =>
      _.debounce((args) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.secondNameEng`,
          translateToLatin(args.value).toLocaleUpperCase()
        );
        // formik.setFieldTouched(`passengers.${args.passengerIndex}.secondNameEng`,true)
        // dispatch(setPassengerSecondName(args));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNameChange = (e) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerNameDebounced({ passengerIndex: index, value: trimValue });
    formik.setFieldValue(`passengers.${index}.name`, trimValue);
  };

  const handleNameEngChange = (e) => {
    formik.setFieldValue(`passengers.${index}.nameEng`, e.target.value);
  };

  const pasList = async str => {
    const response = await getPassengers(str);
    setPassengersList(response
        ? response.map(pas => ({
          value: pas,
          label: pas.lastName + ' ' + pas.firstName + ' ' + pas.patronymic,
        }))
        : []
    );
  };

  const handleSurnameChange = (e) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    if (trimValue !== '') {
      pasList(trimValue);
    }
    if (trimValue === '') {
      setPassengersList([]);
    }
    setPassengerSurnameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    formik.setFieldValue(`passengers.${index}.surname`, trimValue);
  };

  const handleSurnameEngChange = (e) => {
    formik.setFieldValue(`passengers.${index}.surnameEng`, e.target.value);
  };

  const handleSecondNameChange = (e) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerSecondNameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    formik.setFieldValue(`passengers.${index}.secondName`, trimValue);
  };

  const handleSecondNameEngChange = (e) => {
    formik.setFieldValue(`passengers.${index}.secondNameEng`, e.target.value);
  };

  const handleKeyDown = (event) => {
    if (/[a-zA-Z]/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  const handledateOfIssue = (val) => {
    formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);
  };

  const handleBlur = (e) => {
    const target = e.target;
    setFieldTouched(`passengers.${index}.${target.name}`, true, true);
  };

  const countriesList = async (str) => {
    const response = await getCountriesList(str);
    return response
      ? response.data.map((country) => ({
        label: CountryNameWithFlag(country),
        value: country.isoCode
      }))
      : [];
  };

  const handleDocumentTypeChange = (val) => {
    setSelectVal((select) => ({ ...select, documentType: val }));
    formik.setFieldValue(`passengers.${index}.documentType`, val.value);
    if (!notSamo) {
      formik.setFieldValue(
        `passengers.${index}.documentSeries`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
            modalData?.citizen?.value === 'RU'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
          : val.value === 'BIRTH_CERTIFICATE' &&
            modalData?.citizen?.value === 'RU'
          ? modalData?.number
              .replace(/[^A-Za-z0-9]/g, '')
              .substring(0, props.number.length - 6)
          : ''
      );

      formik.setFieldValue(
        `passengers.${index}.documentInfo`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
            modalData?.citizen?.value === 'RU'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(2)
          : val.value === 'BIRTH_CERTIFICATE' &&
            modalData?.citizen?.value === 'RU'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').slice(-6)
          : modalData?.number
      );
    }
    return;
  };

  const handleAviacompanyChange = (val) => {
    formik.setFieldValue(`passengers.${index}.loyality.code`, val.value);
    return;
  };

  const handleCitizenshipChange = (val) => {
    setSelectVal((select) => ({ ...select, citizenship: val }));
    formik.setFieldValue(`passengers.${index}.citizenship`, val.value);
  };

  const handleCheckboxChange = (e) => {
    formik.setFieldValue(
      `passengers.${index}.secondNameRequired`,
      !e.target.checked
    );
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.secondName`, '');
      formik.setFieldValue(`passengers.${index}.secondNameEng`, '');
    }
  };

  const handleChangeLoyalInput = (e) => {
    const value = e.target.value;
    formik.setFieldValue(`passengers.${index}.loyality.value`, value);
  };

  const documentPassengersList = passengersList
    .map(x => x.value.documents.map(doc => ({ value: x.value, doc: doc })))
    .flat();

  const transformData = (passengerId, docType) => {
    setProgress(true);
    const passenger = passengers.find(x => x.customerPassengerId === passengerId)
    const document = passenger?.documents.find(x => x?.id === docType)

    const getCountry = async () => {
      const countries = await countriesList(document?.citizenship || 'Россия');
      if (countries.length) {
        const [a] = countries;
        setCitizenAbsolute(a);
      }
    };

    getCountry();

    const documentType = CONVERTED_DOCS[document?.type];

    const lastName = passenger.lastName;
    const name = passenger.firstName;
    const secondName = passenger.patronymic;
    const number = document?.number;
    const dateOfIssue = document?.issueDate
      ? new Date(document?.issueDate)
      : null;
    const birhDate = new Date(passenger.birthDate);
    const sex = passenger.gender === 'MALE' ? 'm' : 'f';
    const secondNameRequired = !!passenger?.patronymic;
    const customerPassengerId = passenger?.customerPassengerId;

    setModalData({
      documentType,
      lastName,
      name,
      secondName,
      number,
      dateOfIssue,
      birhDate,
      sex,
      secondNameRequired,
      citizenAbsolute,
      customerPassengerId,
    });
  };

  const documentMask = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '9999-999999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '99 9999999' : '';

      // case 'BIRTH_CERTIFICATE':
      //   return props.citizenship === 'RU' ? 'aaa-aa 999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const numberPlaceholder = React.useMemo(() => {
    if (
      documentType?.value === 'BIRTH_CERTIFICATE' &&
      citizenship?.value === 'RU'
    ) {
      return 'XII-AA 000000';
    } else if (documentType?.value === 'PASSPORT') {
      return '9999-999999';
    } else if (documentType?.value === 'INTERNATIONAL_PASSPORT1') {
      return '99 9999999';
    } else {
      return isMobile ? undefined : 'Номер';
    }
  }, [isMobile, documentType?.value, citizenship]);

  const openAutoSelect = () => {
    setisModalOpen(true);
    setModalData(null);
  };
  const closeAutoSelect = () => {
    setisModalOpen(false);
  };
  // eslint-disable-next-line no-unused-vars

  const passengers = useSelector(state => state.user.aviaPassengers)

  const filteredPassengers = React.useMemo(() => {
    if (passengers.length) {
      const createDif = (date) => {
        const given = moment(date);
        const current = moment().startOf('day');
        return moment.duration(current.diff(given)).asYears();
      };

      if (props.ageCategory === 'ADULT') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff >= 12 ? true : false;
        });
      } else if (props.ageCategory === 'CHILD') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 12 && diff > 2 ? true : false;
        });
      } else {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 2 ? true : false;
        });
      }
    }

    return [];
  }, [passengers, props.ageCategory]);

  React.useEffect(() => {
    if (modalData) {
      const {
        documentType,
        lastName,
        name,
        secondName,
        number,
        dateOfIssue,
        // dateOfIssueRequired,
        birhDate,
        sex,
        secondNameRequired,
        // citizen,
        customerPassengerId,
      } = modalData;
      setSelectVal((select) => ({
        ...select,
        citizenship: citizenAbsolute,
        documentType,
      }));
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        documentType?.value
      );
      formik.setFieldValue(
        `passengers.${index}.citizenship`,
        citizenAbsolute ? citizenAbsolute?.value : 'RU'
      );
      formik.setFieldValue(`passengers.${index}.birthDate`, birhDate);
      formik.setFieldValue(`passengers.${index}.name`, name);
      formik.setFieldValue(`passengers.${index}.surname`, lastName);
      formik.setFieldValue(`passengers.${index}.number`, number);
      formik.setFieldValue(`passengers.${index}.secondName`, secondName);
      formik.setFieldValue(`passengers.${index}.nameEng`, name);
      formik.setFieldValue(`passengers.${index}.surnameEng`, lastName);
      formik.setFieldValue(`passengers.${index}.secondNameEng`, secondName);
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, dateOfIssue);
      formik.setFieldValue(`passengers.${index}.sex`, sex);
      formik.setFieldValue(
        `passengers.${index}.customerPassengerId`,
        customerPassengerId
      );
      if (!notSamo && documentType !== undefined) {
        formik.setFieldValue(
          `passengers.${index}.documentSeries`,
          documentType.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
            : documentType.value === 'INTERNATIONAL_PASSPORT1' &&
              citizenAbsolute?.value === 'RU'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
            : documentType.value === 'BIRTH_CERTIFICATE' &&
              citizenAbsolute?.value === 'RU'
            ? number
                .replace(/[^A-Za-z0-9]/g, '')
                .substring(0, props.number.length - 6)
            : ''
        );

        formik.setFieldValue(
          `passengers.${index}.documentInfo`,
          documentType.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(4)
            : documentType.value === 'INTERNATIONAL_PASSPORT1' &&
              citizenAbsolute?.value === 'RU'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(2)
            : documentType.value === 'BIRTH_CERTIFICATE' &&
              citizenAbsolute?.value === 'RU'
            ? number.replace(/[^A-Za-z0-9]/g, '').slice(-6)
            : number
        );
      }

      formik.setFieldValue(
        `passengers.${index}.secondNameRequired`,
        secondNameRequired
      );
      // formik.setFieldValue(
      //   `passengers.${index}.dateOfIssueRequired`,
      //   dateOfIssueRequired
      // );

      closeAutoSelect();
      setProgress(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, citizenAbsolute]);

  // const translateName = (value: string, name: string | undefined) => {
  //   formik.setFieldValue(`passengers.${index}.engnName`, value);
  // };

  // const translateSurname = (value: string, name: string | undefined) => {
  //   formik.setFieldValue(`passengers.${index}.engSurname`, value);
  // };

  // const translateSecondName = (value: string, name: string | undefined) => {
  //   formik.setFieldValue(`passengers.${index}.engSecondName`, value);
  // };

  const handleBlurName = () => {
    formik.setFieldTouched(`passengers.${index}.name`, true);
    formik.setFieldTouched(`passengers.${index}.nameEng`, true);
  };

  const handleBlurSurname = () => {
    formik.setFieldTouched(`passengers.${index}.surname`, true);
    formik.setFieldTouched(`passengers.${index}.surnameEng`, true);
  };

  const handleBlurSecondName = () => {
    formik.setFieldTouched(`passengers.${index}.secondName`, true);
    formik.setFieldTouched(`passengers.${index}.secondNameEng`, true);
  };

  const onClickOutsideListener = () => {
    setPassengersList([]);
    document.removeEventListener('click', onClickOutsideListener);
  };

  return (
    <Wrapper>
      <Header.Wrapper>
        <Text size="normal" color="black">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PassengerIcon
              src={getPassengerIconPath(props.ageCategory)}
              alt=""
            />
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              Пассажир{' '}
              {`${index + 1}, ${props.ageCategoryDescription.toLowerCase()}`}
            </span>
          </div>
        </Text>
        {isAuthorized && filteredPassengers.length > 0 && (
          <AddPassangers onClick={() => openAutoSelect()}>
            + Добавить из моих пассажиров
          </AddPassangers>
        )}
      </Header.Wrapper>
      <FormBlockNew>
        <FioBLock className="field-1">
          <StyledField
            data-cy="lastName"
            wrapperClassName="custom-input"
            error={
              error.surname && isTouched.surname ? error.surname : undefined
            }
            onChange={handleSurnameChange}
            onBlur={handleBlurSurname}
            label="Фамилия"
            autoComplete="anyrandomstring1"
            value={props.surname}
            onMouseLeave={() => {
              document.addEventListener('click', onClickOutsideListener);
            }}
          />
          {documentPassengersList && (
            <AbolutePassengersList>
              {documentPassengersList.map((item) => (
                <AbsoluteItem
                  onClick={() => {
                    transformData(item.value.customerPassengerId, item.doc.id);
                    setPassengersList([]);
                  }}
                >
                  <div style={{ marginBottom: 10 }}>
                    {item.value.firstName +
                      ' ' +
                      item.value.lastName +
                      ' ' +
                      item.value.patronymic}
                  </div>
                  <AbsoluteSubText>
                    {item.doc.type + ' ' + item.doc.number}
                  </AbsoluteSubText>
                </AbsoluteItem>
              ))}
            </AbolutePassengersList>
          )}
        </FioBLock>
        <FioBLock className="field-2">
          <StyledField
            data-cy="name"
            wrapperClassName="custom-input"
            error={error.name && isTouched.name ? error.name : undefined}
            onChange={handleNameChange}
            onBlur={handleBlurName}
            label="Имя"
            autoComplete="anyrandomstring2"
            value={props.name}
          />
        </FioBLock>

        {!formik.values.isCharter && (
          <>
            <FioBLock className="field-3">
              <StyledField
                data-cy="secondName"
                wrapperClassName="custom-input"
                error={
                  error.secondName &&
                  isTouched.secondName &&
                  props.secondNameRequired
                    ? error.secondName
                    : undefined
                }
                disabled={!props.secondNameRequired}
                onChange={handleSecondNameChange}
                onBlur={handleBlurSecondName}
                label="Отчество"
                autoComplete="anyrandomstring3"
                value={props.secondName}
              />
            </FioBLock>
            <div className="field-4">
              <Checkbox
                onChange={handleCheckboxChange}
                type="squar"
                label="Без отчества"
                checked={!props.secondNameRequired}
              />
            </div>
          </>
        )}

        {!isInternationalFlight && (
          <BlueContainer className="field-11">
            <DesktopDescription color="black" size="normal">
              Мы автоматически транслитерируем ваше ФИО на латиницу так, как это
              будет выглядеть в билете. Если необходимо вы можете внести
              изменения.
            </DesktopDescription>
            <Flex>
              <FioBLock>
                <StyledField
                  wrapperClassName="custom-input"
                  error={
                    error.surname && isTouched.surnameEng
                      ? error.surnameEng
                      : undefined
                  }
                  onChange={handleSurnameEngChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlurSurname}
                  label="Фамилия (на латинице)"
                  autoComplete="anyrandomstring293"
                  value={props.surnameEng}
                  tabIndex={-1}
                />
              </FioBLock>

              <FioBLock className="margin">
                <StyledField
                  wrapperClassName="custom-input"
                  error={
                    error.nameEng && isTouched.nameEng ? error.nameEng : undefined
                  }
                  onKeyDown={handleKeyDown}
                  onChange={handleNameEngChange}
                  onBlur={handleBlurName}
                  label="Имя (на латинице)"
                  autoComplete="anyrandomstring292041"
                  value={props.nameEng}
                  tabIndex={-1}
                />
              </FioBLock>

              <FioBLock className="margin">
                <StyledField
                  wrapperClassName="custom-input"
                  error={
                    error.secondNameEng &&
                    isTouched.secondNameEng &&
                    props.secondNameRequired
                      ? error.secondNameEng
                      : undefined
                  }
                  disabled={!props.secondNameRequired}
                  onKeyDown={handleKeyDown}
                  onChange={handleSecondNameEngChange}
                  onBlur={handleBlurSecondName}
                  label="Отчество (на латинице)"
                  autoComplete="anyrandomstring342341"
                  value={props.secondNameEng}
                  tabIndex={-1}
                />
              </FioBLock>
            </Flex>
          </BlueContainer>
        )}

        <DatePicker
          dataCy="birthDateInput"
          className="field-5"
          value={props.birthDate}
          onChange={handleBirthDateChange}
          label="Дата рождения"
          error={
            error.birthDate && isTouched.birthDate ? error.birthDate : undefined
          }
          isBirthDate={true}
          selectClose={true}
        />

        <div
          className="field-6"
          style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
        >
          <Label>Пол</Label>
          <div style={{ marginLeft: 7 }}>
            <RadioGroup
              dataCy="gender"
              name={`passenger-${index}`}
              flex
              defaultValue={'m'}
              onChange={handleTicketClassChange}
              items={[
                { value: 'm', label: 'Мужской' },
                { value: 'f', label: 'Женский' },
              ]}
              withAutoSelect={!!modalData}
              autoSelectData={modalData ? modalData.sex : ''}
            />
          </div>
        </div>

        <div className="field-7">
          <Label>Гражданство (выберите из списка или введите)</Label>

          <AsyncSelect
            onChange={handleCitizenshipChange}
            styles={{
              indicatorSeparator: (provided, state) => ({
                ...provided,
                visibility: 'hidden',
              }),
              input: (provided, state) => ({
                ...provided,
                fontFamily: 'Open Sans',
                color: '#3C3C3C',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: 0,
                paddingLeft: '12px',
              }),
              container: (provided, state) => ({
                ...provided,
                padding: 0,
                marginTop: 4,
              }),
              control: (provided, { isFocused, ...state }) => {
                const border = isFocused
                  ? '1px solid #3C3C3C !important'
                  : '1px solid #DCDCDC';

                const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
                const boxShadow = 'none';
                return {
                  ...provided,
                  minHeight: 42,
                  border,
                  borderColor,
                  boxShadow,
                };
              },
              menu: (provided, state) => ({
                ...provided,
                zIndex: 100,
              }),
              menuList: (provided, state) => ({
                ...provided,
                maxHeight: 225,
              }),
            }}
            noOptionsMessage={() => 'Ничего не найдено'}
            loadOptions={async (inputValue, callback) => {
              callback(await countriesList(inputValue));
            }}
            defaultOptions={totalCountriesList}
            defaultValue={citizenshipDefault}
            cacheOptions
            value={citizenship}
          />
        </div>
        <div className="field-8">
          <Select
            label="Выберите документ"
            onChange={handleDocumentTypeChange}
            isSearchable={false}
            value={documentType}
            // defaultValue={DocumentType[0]}
            options={DocumentType}
          />
        </div>
        {notSamo ? (
          <div className="field-9">
            <NumberTooltip
              handleChange={(event) => {
                formik.setFieldValue(
                  `passengers.${index}.number`,
                  event.target.value
                );
              }}
              handleBlur={handleBlur}
              mask={documentMask}
              value={props.number}
            >
              <StyledField
                data-cy="documentNumber"
                wrapperClassName="custom-input"
                error={
                  error.number && isTouched.number ? error.number : undefined
                }
                label="Серия и номер"
                placeholder={numberPlaceholder}
                name="number"
                // isInfo={
                //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                // }
              />
            </NumberTooltip>
            {documentType?.value === 'BIRTH_CERTIFICATE' && (
              <Label style={{ fontSize: 12, color: '#737373' }}>
                Латинские буквы (Х, V, I), 2 буквы кириллицей и 6 цифр{' '}
              </Label>
            )}
          </div>
        ) : (
          <>
            <div className="field-9">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentSeries`,
                    event.target.value
                  );
                }}
                handleBlur={handleBlur}
                value={props.documentSeries}
              >
                <StyledField
                  wrapperClassName="custom-input"
                  label="Серия документа"
                  name="documentSeries"
                  // isInfo={
                  //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                  // }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <Label style={{ fontSize: 12, color: '#737373' }}>
                  Латинские буквы (Х, V, I) и 2 буквы кириллицей
                </Label>
              )}
            </div>
            <div className="field-10">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentInfo`,
                    event.target.value
                  );
                }}
                handleBlur={handleBlur}
                value={props.documentInfo}
              >
                <StyledField
                  wrapperClassName="custom-input"
                  label="Номер документа"
                  name="documentInfo"
                  // isInfo={
                  //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                  // }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <Label style={{ fontSize: 12, color: '#737373' }}>
                  6 цифр{' '}
                </Label>
              )}
            </div>
          </>
        )}

        {(documentType?.value === 'INTERNATIONAL_PASSPORT1' ||
          documentType?.value === 'INTERNATIONAL_PASSPORT' ||
          documentType?.value === 'NATIONALPASSPORT') && (
          <>
            <DatePicker
              className="field-12"
              value={props.dateOfIssue}
              onChange={handledateOfIssue}
              label="Срок действия"
              placeholder="ДД.ММ.ГГГГ"
              error={
                isTouched.dateOfIssue && error.dateOfIssue
                  ? error.dateOfIssue
                  : undefined
              }
              isInfo
            />
          </>
        )}
      </FormBlockNew>
      <LoyalityBlock checked={isLoyalVisibile}>
        {airlines.length > 0 && (
          <LoyalityCheckboxWrapper className="field-1">
            <Checkbox
              onChange={() => setLoyalVisibility((x) => !x)}
              type="squar"
              label="У меня есть карта лояльности"
            />
          </LoyalityCheckboxWrapper>
        )}
        {isLoyalVisibile && (
          <>
            <div className="field-2">
              <Select
                label="Авиакомпания"
                onChange={handleAviacompanyChange}
                isSearchable={false}
                placeholder=""
                // value={documentType}
                // defaultValue={DocumentType[0]}
                options={airlines}
              />
            </div>
            <div className="field-3">
              <StyledField
                wrapperClassName="custom-input"
                onChange={handleChangeLoyalInput}
                label="№ карты лояльности"
                name="loyalityValue"
                value={props.loyality.value}
              />
            </div>
          </>
        )}
      </LoyalityBlock>
      {/* <AncilllaryServices ids={props.ids} /> */}
      {isAuthorized && filteredPassengers.length > 0 && (
        <PassengerAutoSelectModal
          passengers={filteredPassengers}
          isOpen={isModalOpen}
          selectPassanger={setModalData}
          onClose={closeAutoSelect}
          setProgress={setProgress}
          autoSelectInProgress={autoSelectInProgress}
          setCitizenAbsolute={setCitizenAbsolute}
        />
      )}
    </Wrapper>
  );
};

function getPassengerIconPath(type) {
  switch (type) {
    case 'ADULT':
      return AdultPath;
    case 'CHILD':
      return ChildPath;
    case 'INFANT':
      return InfantPath;
    default:
      return '';
  }
}

export default PassengerItem;
