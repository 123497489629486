import { createSelector } from 'reselect';

export const getSearchResult = (state) => state.searchResult;

export const getFiltersCounter = createSelector(
  getSearchResult,
  (state) =>
    Object.entries(state.flightSearch.filter.isDefault).filter(([_, val]) => {
      return !val;
    }).length
);
