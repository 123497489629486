import React from 'react'
import './style.css'
import CountryInput from './CountryInput'
import useGeoStore from '../../store'
import { useMediaQuery } from '@material-ui/core'
import HideOnScrollBar from './HideOnScrollBar'

export const landingType = ['country', 'resort', 'city']

export default function CountryMainBanner() {
	const banner = useGeoStore(({ content }) =>
		JSON.parse(content?.main_banner?.content)
	)
	const isMobile = useMediaQuery('(min-width:480px)')

	const handleClickScroll = id => {
		const element = document.getElementById(id)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' })
		}
	}
	return (
		<>
			{banner?.mainBanner?.landingBanner?.sections && (
				<HideOnScrollBar sections={banner?.mainBanner?.landingBanner?.sections} handleClickScroll={handleClickScroll}/>
			)}
			<div
				className='v-banner-img'
				style={{
					background: `linear-gradient(103.18deg, rgba(25, 17, 54, 0.2) 0%, rgba(28, 22, 51, 0) 75.26%), url(${
						isMobile ? banner?.mainBanner?.image : banner?.mainBanner?.mobileImage
					}) rgb(237, 241, 254)`
				}}>
				{banner?.mainBanner?.landingBanner?.sections && (
					<div className='banner-sticky__container'>
						<div className='v-banner-menu'>
							{banner.mainBanner.landingBanner.sections.map(item => (
								<span
									key={item.blockId}
									onClick={() => handleClickScroll(item.blockId)}>
									{item.name}
								</span>
							))}
						</div>
					</div>
				)}
				<div className='v-banner-container'>
					<div
						className='v-banner-text'
						style={{ color: banner.mainBanner.content.textColor }}>
						{banner?.mainBanner.content.text}
					</div>
					<div
						className='v-banner-head'
						style={{ color: banner.mainBanner.content.textBoldColor }}>
						{banner.mainBanner.content.textBold}
					</div>
					<div className='v-banner-where-field-block'>
						<div className='v-banner__city-text'>Город вылета</div>
						<CountryInput />
					</div>
				</div>
			</div>
		</>
	)
}
