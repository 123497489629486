import { styled, Tooltip, tooltipClasses } from '@mui/material'

export const PaidTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		padding: '8px 16px 8px 16px',
		color: '#2E2E32',
		boxShadow: theme.shadows[1],
		textAlign: 'center',
		fontSize: 12,
		fontFamily: 'Open Sans',
		lineHeight: '18px'
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.white,
		'&:before': {
			boxShadow: theme.shadows[1]
		}
	}
}))
