import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../../../asset/images/avia/addictional-services/info.svg';
import { ReactComponent as MealIcon } from '../../../../asset/images/avia/addictional-services/meal.svg';
import { ReactComponent as LuggageIcon } from '../../../../asset/images/avia/addictional-services/luggage.svg';
import { AncillaryServiceType} from '../../../../store/modules/booking';
import PassengerSelectButton from './PassengerSelectButton';
import { Checkbox,Text, Button } from '../../../ui';
import formatPrice from '../../../../functions/formatPrice';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../../asset/images/avia/ui/close.svg'
import {styled as MUIstyled} from '@mui/material/styles'
import BasePrimaryButton from '../../../ui/PrimaryButton';
import BaseOutlinedButton from '../../../ui/OutlinedButton';

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    width: 100%;
    max-width: 630px;
    box-sizing: border-box;
    overflow: hidden;
    height: fit-content;

    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0;
      padding-top: 44px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }
  ${Text} {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;

    ${Text} {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const Desctiption = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
  }
`;

const ButtonBlock = styled.div`
  & > button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 8px;

  &::-webkit-scrollbar {
      width: 6px;
      background-color: #C4C4C4;
  }
  &::-webkit-scrollbar-track {
      background: #C4C4C4;
  }
  &::-webkit-scrollbar-thumb {
      background: #4872f2;
      border-radius: 8px;
  }
`;

const MobileCloseButton = styled.button`
  position: sticky;
  width: 100%;
  bottom: 0px;
  z-index: 2;
  background: #4872f2;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SelectButton = styled.button`
  ${({ selected }) => {
    const mainColor = '#3C3C3C';
    const background = selected ? '#EDF1FE' : 'transparent';

    return `
          min-width: 100px;
          font-family: "Open Sans";
          border-radius: 4px;
          background: ${selected ? background : 'transparent'};
          color: ${selected ? '#4872F2' : '#3C3C3C'};
          border: 1px solid ${selected ? '#4872F2' : '#C4C4C4'};
          outline: none;
          font-style: normal;
          padding: 10px 11px;
          line-height: 20px;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
          max-width: 183px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);

          &:disabled {
              cursor: not-allowed;
              background: transparent;
              color: #B9B9B9;
              border: 1px solid #B9B9B9;
          }
          &:active {
              color: ${mainColor};
              border: 1px solid ${mainColor};
              background-color: ${background};
              box-shadow: 0px 0px 6px rgba(72, 114, 242, 0.64);
          }
          &:hover{
            box-shadow: 0 0 9px -1px #4872F2;
          }
          @media (max-width: 1023px) {
            padding: 8px;
            min-width: 77px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            max-width: 156px;
          }
      `;
  }}
`;

const PassengerBlockWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
    overflow: hidden;
  }
`;

const PassengersBlock = styled.div`
  & > button {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    padding: 0 20px;
    width: 100%;
    overflow: scroll;
    margin-bottom: -10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    & > button {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const CheckboxContainerDescription = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 11px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const CheckboxContainer = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  margin-bottom: 40px;

  @media (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalPrice = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  & > strong {
    color: #4872f2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const PrimaryButton = MUIstyled(BasePrimaryButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20.5px',
  display: 'block',
}))

const OutlinedButton = MUIstyled(BaseOutlinedButton)(()=>({
  fontSize: '16px',
  // lineHeight: '24px',
  padding: '8px 20.5px',
  display: 'block',
}))


const InfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  & > svg {
    flex-shrink: 0;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const ActionButtonsBlock = styled.div`
  display: flex;

  & > button {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }
  margin-top: 30px;
`;

const DesktopInfo = styled(InfoContainer)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileInfo = styled(InfoContainer)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const InfoText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  padding-left: 8px;

  @media (max-width: 767px) {
    font-weight: 400;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;


const RouteName = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
  }
`;

export default function Modal({
  data,
  passengers,
  onItemClick,
  ...props
}) {
  const [segmentId, setSegment] = React.useState(data?.segments[0]?.id);

  const [availablePassengers, setAvailablePassengers] = React.useState(() => {
    const ids = data?.offers[segmentId] && Object.keys(data?.offers[segmentId]);
    return passengers?.filter((x) => ids?.includes(x?.uid));
  });

  const [passengerId, setPassengerId] = React.useState(
    availablePassengers[0]?.uid
  );

  const handleChangeSegment = (id) => {
    setSegment(id);
    const ids = Object.keys(data.offers[id]);
    setAvailablePassengers(() => {
      return passengers.filter((x) => ids.includes(x.uid));
    });
    setPassengerId(ids[0]);
  };

  const getNextSegment = () => {
    const currentIndex = data.segments.findIndex((x) => x.id === segmentId);
    if (data.segments[currentIndex + 1]) {
      handleChangeSegment(data.segments[currentIndex + 1].id);
    }
  };

  const infantExists = passengers.find((x) => x.ageCategory === 'INFANT');

  const totalPrice = React.useMemo(
    () =>
      _(data.offers).reduce(
        (acc, x) =>
          acc +
          _(x).reduce(
            (acc, x) =>
              acc +
              _(x)
                .filter((x) => x.checked)
                .reduce((acc, x) => acc + x.cost, 0),
            0
          ),
        0
      ),
    [data]
  );

  const {
    TITLE,
    DESCRIPTION,
    CHECKBOX_CONTAINER_DESCRIPTION,
    TOTAL_PRICE,
    ICON,
    RUS_TYPE,
    NOT_SELECTED,
    SELECTED,
  } = React.useMemo(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    switch (data.type) {
      case AncillaryServiceType.Luggage:
        return {
          TITLE: 'Позаботьтесь о вашем багаже',
          DESCRIPTION: `Оформите багаж заранее, в аэропорту эта услуга займет время и может стоить дороже, чем на сайте.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите багаж:',
          TOTAL_PRICE: 'Стоимость всего багажа:',
          RUS_TYPE: 'багаж',
          NOT_SELECTED: 'не выбран',
          SELECTED: `${width > 767 ? 'багаж ' : ''}выбран`,
          ICON: <LuggageIcon />,
        };
      case AncillaryServiceType.Meal:
        return {
          TITLE: 'Насладитесь вашим полетом',
          DESCRIPTION: `Выберите блюдо, подходящее Вашим вкусовым предпочтениям, и наслаждайтесь полетом в комфортных условиях.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите питание:',
          TOTAL_PRICE: 'Стоимость всех блюд:',
          RUS_TYPE: 'питание',
          NOT_SELECTED: 'не выбрано',
          SELECTED: `${width > 767 ? 'питание ' : ''}выбрано`,
          ICON: <MealIcon />,
        };
      default:
        return {
          TITLE: 'TITLE',
          DESCRIPTION: `DESCRIPTION`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'CHECKBOX_CONTAINER_DESCRIPTION:',
          TOTAL_PRICE: 'TOTAL_PRICE:',
          RUS_TYPE: 'RUS_TYPE',
          NOT_SELECTED: 'NOT_SELECTED',
          SELECTED: 'SELECTED',
          ICON: null,
        };
    }
  }, [data.type]);

  return (
    <StyledPopup {...props} open={true}>
      <CloseButton
        aria-label="close"
        onClick={(e)=>{
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      >
        <Icon/>
      </CloseButton>
      <Head>
        {ICON}
        <Text>{TITLE}</Text>
      </Head>
      <Body>
        <Desctiption>{DESCRIPTION}</Desctiption>
        <RouteName>
          Маршрут: <strong>{data?.groupedSegments[segmentId]?.route}</strong>
        </RouteName>
        <ButtonBlock>
          {data.segments.map((x) => {
            return (
              <SelectButton
                key={x.id}
                selected={x.id === segmentId}
                onClick={() => {
                  handleChangeSegment(x.id);
                }}
              >
                {x.route}
              </SelectButton>
            );
          })}
        </ButtonBlock>
        <PassengerBlockWrapper>
          <PassengersBlock>
            {availablePassengers.map((x, key) => {
              const title = _(data.offers[segmentId][x.uid])
                .filter((x) => x.checked)
                .map((x) => x.title)
                .join(',');
              return (
                <PassengerSelectButton
                  key={`${key}-${title}`}
                  {...x}
                  number={key + 1}
                  selected={x.uid === passengerId}
                  title={title !== '' ? SELECTED : NOT_SELECTED}
                  onClick={() => {
                    setPassengerId(x.uid);
                  }}
                />
              );
            })}
          </PassengersBlock>
        </PassengerBlockWrapper>
        {infantExists && (
          <DesktopInfo>
            <InfoIcon />
            <InfoText>
              Обращаем Ваше внимание - {RUS_TYPE} для младенца необходимо
              добавить ко взрослому пассажиру.
            </InfoText>
          </DesktopInfo>
        )}
        <CheckboxContainerDescription>
          {CHECKBOX_CONTAINER_DESCRIPTION}
        </CheckboxContainerDescription>
        <CheckboxContainer>
          {data?.offers[segmentId] &&
            data?.offers[segmentId][passengerId] &&
            data?.offers[segmentId][passengerId]?.map((item) => (
              <Row key={item.key}>
                <div>
                  <Checkbox
                    label={item.title}
                    checked={item.checked}
                    onChange={() => {
                      onItemClick({
                        type: data.type,
                        segmentId,
                        passengerId,
                        key: item.key,
                        checked: item.checked,
                      });
                    }}
                    type="squar"
                  />
                  <Text size="big" color="blue">
                    {' '}
                    {formatPrice(item.cost)}
                  </Text>
                </div>
              </Row>
            ))}
        </CheckboxContainer>
        {infantExists && (
          <MobileInfo>
            <InfoIcon />
            <InfoText>
              Обращаем Ваше внимание - {RUS_TYPE} для младенца необходимо
              добавить ко взрослому пассажиру.
            </InfoText>
          </MobileInfo>
        )}
        <TotalPrice>
          {TOTAL_PRICE} <strong>{formatPrice(totalPrice)}</strong>
        </TotalPrice>
        <ActionButtonsBlock>
          {data.segments.length > 1 && (
            <PrimaryButton onClick={getNextSegment}>
              Перейти к следующему маршруту
            </PrimaryButton>
          )}
          <OutlinedButton onClick={props.onClose}>
            Закончить выбор
          </OutlinedButton>
        </ActionButtonsBlock>
      </Body>
      <MobileCloseButton onClick={props.onClose}>
        Добавить {RUS_TYPE} за: {formatPrice(totalPrice)}
      </MobileCloseButton>
    </StyledPopup>
  );
}
