import { useState } from 'react'
import styled from "styled-components"
import Collapse from '@mui/material/Collapse'
import useFranchisingStore from '../store'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LineWithCircle from './LineWithCircle'
import { isArray, isEmpty } from 'lodash'

const Title = styled.div`
    font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

const Text = styled.div`
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
`

const Container = styled.div`
    display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const ContentContainer = styled(FlexRow)`
    gap: 8px;
    margin: 24px 0;
    align-items: start;
    flex-direction:column;
`

const LineContainer = styled.div`
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
`

const ContentItem = styled.div`
        display:flex;
        justify-content: center;
        align-items: center;
    ${({ width = 170 }) => {
        return `width: ${width}px;`
    }}
`

function SalesRatings() {
    const franchising = useFranchisingStore(({ franchising }) => franchising)
    const rating = franchising?.find(obj => obj.block === 'rating')
    const contents = isArray(rating.content) ? rating?.content : Object.values(rating?.content)
    const [isOpen, setIsOpen] = useState(true)

    if (!rating || isEmpty(contents) || !contents.some(el => el.places.some(item => item.office))) {
        return null
    }

    return <Container>
        <Row
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: 'pointer' }}>
            <FlexRow>
                <Title>{rating?.title}</Title>
            </FlexRow>
            <ExpandMoreIcon
                onClick={() => setIsOpen(!isOpen)}
                style={isOpen ? { transform: 'rotate(180deg)' } : null}
                isActive={isOpen}
            />
        </Row>
        <Collapse in={isOpen}>
            {contents
                ?.sort((a, b) => a.order - b.order)
                ?.map(content => {
                    if (!content?.places?.some(i => i.office)) {
                        return null
                    }
                    return <ContentContainer key={content.order}>
                        <div style={{ width: "100%" }}>
                            {content?.name && <Text style={{ fontSize: '16px', lineHeight: "22px", marginBottom: "8px" }}>{content?.name}</Text>}
                            <LineContainer>
                                {content.places
                                    ?.sort((a, b) => a.order - b.order)
                                    ?.map((place, index, array) => {
                                        if (!place?.office) {
                                            return null
                                        }
                                        return <FlexRow style={{ gap: '16px' }} key={place?.order}>
                                            <ContentItem>
                                                <Text>{place?.order + 1} место</Text>
                                            </ContentItem>
                                            <div><LineWithCircle start={index === 0} end={index === array.length - 1} /></div>
                                            <ContentItem width={476} style={{ justifyContent: 'start' }}>
                                                <Text style={{ padding: '22px 16px' }}>{place?.office}</Text>
                                            </ContentItem>
                                        </FlexRow>
                                    })}
                            </LineContainer>
                        </div>
                    </ContentContainer>
                }
                )}
        </Collapse>
    </Container>
}

export default SalesRatings