import { createSelector } from 'reselect';

export const getUserState = (state) => state.user;
export const getUserStatusState = (state) => state.user.status;
export const getUserDataState = (state) => state.user.data;
export const aviaPassengersSelector = (state) => state.user.aviaPassengers;
export const getCommissionStatus = (state) => state.user.userCommissionStatus;

export const getAuthStatus = createSelector(getUserStatusState,({ isAuthorized }) => isAuthorized);

export const getUserError = createSelector(getUserStatusState,(state) => state.error);
