import React from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const getPageCount = (claimsCount,  limit = 10) => {
	return Math.ceil(claimsCount / limit);
  };

  const getPagesArray = (length, page) => {
	const indent = 1 //отступ от активной страницы
	
	let left = Math.max(page - indent, 1);
	let right = Math.min(left + indent * 2, length);
	// Корректировка когда страница в конце
	left = Math.max(right - indent * 2, 1);
  
	// Массив номеров, чтобы удобней рендерить
	let items = [];
	// Первая страница всегда нужна
	if (left > 1) items.push(1);
	// Пропуск
	if (left > 2) items.push(null);
	// Последовательность страниц
	for (let page = left; page <= right; page++) items.push(page);
	// Пропуск
	if (right < length - 1) items.push(null);
	// Последняя страница
	if (right < length) items.push(length);
  
  return items
  };

export default function Pagination({claimsCount, page, setPage, limit}) { 

	const handleNext = () => {
		if (page < getPageCount(claimsCount, limit)) {
			setPage(page + 1);
		}
	}

	const handlePrev = () => {
		if (page > 1) {
			setPage(page - 1);
		}
	}

	const pagesArray  = getPagesArray(getPageCount(claimsCount, limit),  page)

	return (
		<div className="Pagination">
			<div style={ page === 1 ? {pointerEvents: 'none'} : null } onClick={handlePrev}  className="Pagination-button"><ChevronLeftIcon/></div>
			{pagesArray.map((el) =>
				el ? (
					<div
						onClick={() => setPage(el)}
						className={page === el ? 'Pagination-button active' : 'Pagination-button'}
						key={el}>
						{el}
					</div>
				) : (
					<div className={'Pagination-button-empty'}>...</div>
				)
			)}
			<div style={ page === getPageCount(claimsCount, limit) ? {pointerEvents: 'none'} : null } onClick={handleNext} className="Pagination-button"><ChevronRightIcon/></div>
	  </div>
	);
}