import styled from 'styled-components';
import { Text } from '../../ui';
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as FileIcon } from '../../../../../../asset/images/cabinet/document.svg'
import { ReactComponent as IconClose } from '../../../../../../asset/images/avia/ui/close.svg'
import { useEffect } from 'react';
import useClaimsStore from '../../../store'

const FileWrapper = styled.div`
    height: 78px;
    width: 314px;
    border-radius: 8px;
    padding: 16px;
    background: rgba(246, 248, 250, 1);
`
const Row = styled.div`
    display: flex;
    gap: 8px;

`

const ListFileWrapper = styled(FileWrapper)`
    width: 211px;
    height: unset;
`

const FileNameText = styled(Text)`
width: 226px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`

const FileListWrapper = styled.div`
display:flex;
gap: 8px;
`

const MAX_FILE_COUNT = 2

export function FileList({ messageId }) {
    const actions = useClaimsStore(({ actions }) => actions)
    const files = useClaimsStore(({ files }) => files?.filter(file => file.messageId === messageId))

    useEffect(() => {
        actions.getMessageFile(messageId)
    }, [actions, messageId])

    const handleClickDownload = async (messageId, fileId, fileName) => {
        try {
            const response = await actions.getFile(fileId, messageId)
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error)
        }
    }

    return <FileListWrapper>
        {files?.slice(0, MAX_FILE_COUNT)?.map((file) => {
            return <ListFileWrapper style={{ cursor: 'pointer' }} onClick={() => handleClickDownload(messageId, file.fileId, file.fileName)}>
                <Row>
                    <FileIcon />
                    <div style={{ width: '150px' }}>
                        <FileNameText style={{ width: "100%" }} size={16} height={22}>{file.fileName}</FileNameText>
                        {/* <Text color='rgba(126, 131, 137, 1)'>{getFileSize(fileSize)}</Text> */}
                    </div>
                    {/* <div style={{ cursor: 'pointer' }} onClick={onRemove}>
                        <IconClose fill='rgba(126, 131, 137, 1)' style={{ width: '20px', height: '20px' }} />
                    </div> */}
                </Row>
            </ListFileWrapper>
        })}
    </FileListWrapper>
}

function File({ fileName, fileSize, loading, onRemove }) {
    const getFileSize = (size) => {
        let sizeFile = size
        if ((fileSize / (1024 * 1024)) > 1) {
            sizeFile = (fileSize / (1024 * 1024)).toFixed() + " Мб"
        } else {
            sizeFile = (fileSize / 1024).toFixed() + ' Кб'
        }
        return sizeFile
    }
    return <FileWrapper>
        <Row>
            {loading ? <CircularProgress size={20} /> : <FileIcon />}
            <div style={{ width: '226px' }}>
                <FileNameText size={16} height={22}>{fileName}</FileNameText>
                <Text color='rgba(126, 131, 137, 1)'>{getFileSize(fileSize)}</Text>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={onRemove}>
                <IconClose fill='rgba(126, 131, 137, 1)' style={{ width: '20px', height: '20px' }} />
            </div>
        </Row>
    </FileWrapper>
}

export default File