import React from "react"
import styled from "styled-components"
import Tippy from "@tippyjs/react/headless"
import formatPrice from "../../../../../functions/formatPrice"
import { Text } from "../../../../ui"
import { getSeatColor } from "./utils"
import { ReactComponent as PassengerIcon } from "./assets/media/passenger.svg"
import { useSeatsMap } from "./context"

const Squar = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ background }) => background};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1.5px solid ${({ border }) => border};

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`

const TippyWrapper = styled.div`
  padding: 15px;
  background: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 11px 0px;
`

const TippyText = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  & > strong {
    color: #4872f2;
    font-weight: normal;
  }
`

const Seat = props => {
  const {
    handlePlaceClick,
    selectedSeats,
    onPlaceSelect,
    pricesArr
  } = useSeatsMap()
  const colorProps = getSeatColor(
    props.price.amount,
    props.isAvailable,
    selectedSeats.includes(props.number),
    pricesArr,
    props.onlyForAdult,
    props.selectedPassengerType,
  )

  // console.log(props.rfisc, selectedSeats, selectedSeats.includes(props.rfisc));

  return (
    <Tippy
      trigger="mouseenter focusin"
      onShow={instance => {
        setTimeout(() => {
          instance.hide()
        }, 1000)
      }}
      render={attrs => (
        <TippyWrapper {...attrs}>
          <TippyText>
            {props.isAvailable ? (
              <>
                {props.number},{' '}
                {props.onlyForAdult
                  ? 'Кресло доступно только для взрослых'
                  : 'Кресло доступно'}{' '}
                , <strong>{formatPrice(props.price.amount)}</strong>
              </>
            ) : (
              "Кресло недоступно"
            )}
          </TippyText>
        </TippyWrapper>
      )}
      offset={[0, 16.5]}
    >
      <Squar
        onClick={() => {
          if (props.selectedPassengerType !== 'ADULT' && props.onlyForAdult) {
              return null;
          } else if (props.isAvailable) {
              handlePlaceClick(props.number);
              onPlaceSelect(props);
          }
        }}
        {...colorProps}
      >
        {selectedSeats.includes(props.number) && <PassengerIcon />}
      </Squar>
    </Tippy>
  )
}

export default Seat
