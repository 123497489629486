export function encodeQueryString(params) {
    let queryString = '';
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
        if (Array.isArray(params[key])) {
          for (let i = 0; i < params[key].length; i++) {
            queryString += `${key}%5B${i}%5D=${encodeURIComponent(params[key][i])}&`;
          }
        } else {
          queryString += `${key}=${encodeURIComponent(params[key])}&`;
        }
      }
    }
  
    if (queryString.endsWith('&')) {
      queryString = queryString.slice(0, -1);
    }
    
    return queryString;
  }
  
 export function decodeQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    const obj = {};
    for (const [key, value] of params.entries()) {
      if (value === 'true') {
        obj[key] = true;
      } else if (value === 'false') {
        obj[key] = false;
      } else if (/\[\d+\]$/.test(key)) {
        const matches = key.match(/^(.+)\[(\d+)\]$/);
        const name = matches[1];
        const index = parseInt(matches[2], 10);
        obj[name] = obj[name] || [];
        obj[name][index] = value;
      } else {
        obj[key] = value;
      }
    }
  
    return obj;
  }