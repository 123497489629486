import MainBanner from "../MainBanner"
import MainBannerSlider from "../MainBannerSlider"
import CountryMainBanner, { landingType } from "../CountryMainBanner"
import MainBannerLanding from "../MainBannerLanding"

const WithBanner = ({content, bannerLandingType}) => {

	if (landingType.includes(bannerLandingType) && content.mainBanner.bannerType !== 'landing') {
		return <div className={'landing landing-container'}>
					<CountryMainBanner/>
				</div>
	}

	switch (content.mainBanner.bannerType) {
		case 'banner':
			return <MainBanner content={content}/>
		case undefined:
			return <MainBanner content={content}/>
		case 'slider':
			return <div className={'landing landing-container'}>
						<MainBannerSlider content={content}/>
					</div>
		case 'landing':
			return  <div className={'landing landing-container'}>
						<MainBannerLanding/>
					</div>
		default:
			return null 
	}	
}

export default WithBanner