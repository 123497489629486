import { useState } from "react";
import useBookingStore from "../../store";
import { Button } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import ServiceModalContent from "./ServiceModalContent";
import "./style.css";
import { isEmpty } from "lodash";

function ServiceModal({ services, onClose, title, grouped = false }) {
  const peoples = useBookingStore(({ peoples }) => peoples);
  const actions = useBookingStore(({ actions }) => actions);
  const selectedServices = useBookingStore(
    ({ selectedServices }) => selectedServices
  );

  const [selected, setSelected] = useState(selectedServices);

  const handleChange = (uid) => {
    let newState = [...selected];
    const existService = services.find((item) => item.uid === uid);

    if (!existService) {
      return newState;
    }

    if (newState.some((el) => el.uid === existService.uid)) {
      newState = newState.filter((el) => el.uid !== existService.uid);
    } else {
      existService.clients = peoples.map((people) => ({
        peopleKey: people.key,
        common: 0,
      }));
      newState.push(existService);
    }
    setSelected(newState);
    return newState;
  };

  const personalAdd = (uid, peopleKey) => {
    let newState = [...selected];
    const existService = services.find((service) => service.uid === uid);
    if (!existService) {
      return newState;
    }

    //remove people or service
    if (newState.some((el) => el.uid === existService.uid)) {
      if (
        existService.clients.some((people) => people.peopleKey === peopleKey)
      ) {
        existService.clients = existService.clients.filter(
          (people) => people.peopleKey !== peopleKey
        );
        newState = newState.filter((item) => item.uid !== uid);
        if (!isEmpty(existService.clients)) {
          newState.push(existService);
        }
      } else {
        existService.clients.push({ peopleKey: peopleKey, common: 0 });
        newState = newState.filter((i) => i.uid !== existService.uid);
        newState.push(existService);
      }
    } else {
      //add new service
      existService.clients = [{ peopleKey: peopleKey, common: 0 }];
      newState.push(existService);
    }

    setSelected(newState);
    return newState;
  };

  const onSave = () => {
    actions.updateServices(selected);
    onClose();
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <div className="modal-overlay" />
      <div className="service-modal block-body">
        <div className="modal-title">
          <div
            style={{
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
              fontSize: "1.17em",
              fontWeight: "bold",
            }}
          >
            {title}
          </div>
          <div
            style={{ flex: 0.1, textAlign: "end", cursor: "pointer" }}
            onClick={closeModal}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="grid-service table-header">
          <div>Название</div>
          <div>Даты</div>
          <div></div>
        </div>
        <ServiceModalContent
          services={[...services]}
          selectedServices={selectedServices}
          isGrouped={grouped}
          onChange={handleChange}
          onChangePersonal={personalAdd}
        />
        <div className="modal-button">
          <Button onClick={onSave} variant="contained" color="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
}

export default ServiceModal;
