import React from 'react';
import ExpansionPanelWithoutPadding from '../../components/ExpansionPanel/ExpansionPanelWithoutPadding';
import styled from 'styled-components';
import CustomizedTabs from '../../components/tab/CustomizedTabs';
import AgentCurators from './fromMoscowFlights/AgentCurators';
import GoldClubCurators from './fromMoscowFlights/GoldClubCurators';
import NetworkAgencyCurators from './fromMoscowFlights/NetworkAgencyCurators';
import SilverClubCurators from './fromMoscowFlights/SilverClubCurators'
import IndependentAgencyCurators from './fromMoscowFlights/IndependentAgencyCurators';
import RegionsFlightsCurators from './fromRegionsFlights/RegionsFlightsCurators'


const Title = styled.div`
    font-family: 'Open Sans', serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
    color: #2e2e32;
    @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
`;


export default function CharterPopularContacts() {
    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <ExpansionPanelWithoutPadding
            title={
                <Title>
                    Обращения по пакетам на чартерных или блочных перелетах, по
                    наземному обслуживанию, массовые направления
                </Title>
            }
            body={
                <>
                    <CustomizedTabs value={tab} handleChange={handleChange} />
                    {tab === 0 ? (
                        <>
                         <AgentCurators />
                         <GoldClubCurators />
                         <NetworkAgencyCurators/>
                         <SilverClubCurators/>
                         <IndependentAgencyCurators/>
                        </>
                    
                        ) : (
                            <>
                             <RegionsFlightsCurators />
                            </>
                           
                    )}

           
                </>
            }
        />
    );
}
