import React, { useEffect, useState } from 'react'
import useClaimsStore from '../../store'
import CircularProgress from '@mui/material/CircularProgress'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Button } from '../../../../../components/ui';
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CollapseContainer, Text } from '../ui'
import { ReactComponent as Hotel } from '../../../../../asset/images/cabinet/Hotel.svg'
import { ReactComponent as Room } from '../../../../../asset/images/cabinet/Room.svg'
import { ReactComponent as Meal } from '../../../../../asset/images/cabinet/Meal.svg'
import { ReactComponent as Flight } from '../../../../../asset/images/cabinet/Flight.svg'
import { ReactComponent as Checkmark } from '../../../../../asset/images/cabinet/Checkmark.svg'
import { ReactComponent as Insurance } from '../../../../../asset/images/cabinet/Insurance.svg'
import { ReactComponent as VisaIcon } from '../../../../../asset/icons/Visas/visa_icon.svg'
// import { ReactComponent as Remove } from '../../../../../asset/images/cabinet/Remove.svg'
import { ReactComponent as Arrow } from '../../../../../asset/images/cabinet/Arrow.svg'
import { isEmpty } from 'lodash'
import Boarding from './components/Boarding'

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const OrdersHeader = styled.div`
	display: grid;
	grid-template-columns: 6fr 0fr;
	gap: 16px;
	:nth-child(2) {
		text-align: end;
	}
	margin-bottom: 16px;
`
const OrdersBody = styled(OrdersHeader)`
	margin: 0;
	:nth-child(2) {
		text-align: start;
	}
`
const HotelLink = styled.a`
	color: #4872f2;
	&:hover {
		color: #c73d42;
	}
`

const Count = styled(Row)`
	align-items: end;
`

const LineContainer = styled(Text)`
	text-align: start;
	display: inline-flex;
    align-items: center;
`
const Line = styled.div`
	border-bottom: 1px solid #dcdcdc;
	grid-column: 1 / -1;
`

const TextBaggage = styled(Text)`
	&:nth-child(n) {
		::before {
			content: ' • ';
		}
	}
	span {
		&:first-child {
			&:before {
				content: '';
			}
		}
	}
`

const LineSpan = styled.span`
display: inline-flex;
align-items: center;
gap: 8px;
`

const MButton = (props) => <Button style={{ padding: '8px 20px', fontSize: '14px', borderRadius: '8px', whiteSpace: 'nowrap' }} {...props} />

// const STATUS_FROM_ID = {
// 	1: 'Оплачено',
// 	2: 'Не оплачено',
// 	3: 'Отменено ',
// 	6: 'Частичная оплата',
// 	5: 'Оплачен штраф'
// }

const Orders = ({ id }) => {
	const claim = useClaimsStore(({ claim }) =>
		claim.find(item => item.id === id)
	)
	const actions = useClaimsStore(({ actions }) => actions)
	const freights = useClaimsStore(({ freights }) => freights[id])
	const [showBoarding, setShowBoarding] = useState(false)
	const [boardingId, setBoardingId] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const servicesCount =
		claim?.services?.length +
		claim?.hotels?.length +
		claim?.flights?.length +
		claim?.insurances?.length +
		claim?.visas?.length

	const handleShowBoarding = (e, freights) => {
		const orderInc = freights?.find(item => item.freightInc === +e?.currentTarget.id)?.orderInc
		setShowBoarding(!showBoarding)
		setBoardingId(e?.currentTarget.id)
		if (!showBoarding) {
		actions.getBoarding(claim?.id, e.currentTarget.id, claim?.claims[0].tourId).then(() => {
				claim?.people.forEach(item => {
					actions.getPoPeople(orderInc, item.id)
				})
		})
		}
	}


	useEffect(() => {
		if (isOpen) {
			actions.getFreights(claim?.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<CollapseContainer>
			<Row>
				<div>
					<Text bold size={18} height={26}>
						Состав заявки{' '}
						{claim ? (
							<>
								<span style={{ color: 'rgba(72, 114, 242, 1)' }}>
									({servicesCount})
								</span>
							</>
						) : (
							<CircularProgress size={10} />
						)}
					</Text>
				</div>
				<div
					className={`claim-icon ${!isOpen ? 'open' : ''}`}
					onClick={() => setIsOpen(!isOpen)}>
					<ExpandMoreIcon
						style={
							isOpen
								? { transform: 'rotate(180deg)', cursor: 'pointer' }
								: { cursor: 'pointer' }
						}
						isActive={isOpen}
					/>
				</div>
			</Row>

			{
				<Collapse in={isOpen}>
					<div>
						{/* <div style={{ margin: "24px 0" }}><MButton>Добавить услуги</MButton></div> */}
						<OrdersHeader style={{ margin: '24px 0' }}>
							<Text bold>Наименование</Text>
							<Text bold>Количество</Text>
						</OrdersHeader>
						<OrdersBody>
							{claim &&
								claim.hotels.map(hotel => {
									return (
										<>
											<div className={'orders'}>
												<LineContainer>
													{hotel.name && (
														<HotelLink
															href={`/hotel/${hotel?.hotelID}`}
															target='_blank'
															rel='noreferrer'>
															<Hotel style={{ marginRight: '8px' }} />
															{hotel.name} {hotel?.starHotel},
														</HotelLink>
													)}
													<span>
														{' '}
														{format(
															new Date(hotel.dateBeg),
															'dd.MM.yyyy'
														)} - {format(new Date(hotel.dateEnd), 'dd.MM.yyyy')}
													</span>
													{hotel?.typeRoom && (
														<LineSpan>
															,<Room />
															{hotel.typeRoom}
															{' / '}
															{hotel.placement}
														</LineSpan>
													)}
													{hotel?.typeMeal && (
														<LineSpan>
															,<Meal />
															{hotel.typeMeal}
														</LineSpan>
													)}
												</LineContainer>
											</div>
											<Count>
												<Text>x{hotel.count}</Text>
												{/* <Remove style={{ fill: 'rgba(179, 185, 192, 1)' }} /> */}
											</Count>
											<Line />
										</>
									)
								})}

							{claim &&
								claim.flights.map(flight => {
									return (
										<>
											<div>
												<Flight
													style={{ display: 'block', marginBottom: '8px' }}
												/>
												<LineContainer>
													{flight?.name} ({flight?.transportModel?.title}),{' '}
													{format(new Date(flight.dateBeg), 'dd.MM.yyyy')}{' '}
													{flight?.fromTownName}
													{' '}
													{(flight.fromPortIATA || flight.fromTime) &&
														`(${flight.fromPortIATA} ${flight.fromTime})`}{' '}
													{/* <span> В пути: {Math.floor(flight.durationMin / 60).toString().padStart(2, '0')}:{(flight.durationMin % 60).toString().padStart(2, '0')}.</span> */}
													<Arrow
														style={{
															display: 'inline-flex',
															margin: '0 8px',
															justifyContent: 'center',
															alignItems: 'center'
														}}
													/>
													{ }{' '}
													{flight.toTownName}
													{' '}
													{(flight.toPortIATA || flight.toTime) &&
														`(${flight.toPortIATA} ${flight.toTime})`}{' '}
													&#x2022; {flight.className}
												</LineContainer>
												<TextBaggage>
													{flight.baggage && (
														<span>Багаж: {flight.baggage} КГ</span>
													)}
													{flight?.handBaggage && (
														<span>Ручная кладь: {flight.handBaggage} КГ</span>
													)}
													{flight?.infBaggage && (
														<span>
															Багаж для младенца без места: {flight.infBaggage}
															КГ
														</span>
													)}
												</TextBaggage>
												<div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
													{/* <MButton>Изменить рейс</MButton> */}
													{freights &&
														!isEmpty(freights) &&
														(freights.find(freight => freight.freightInc === flight?.flightID)?.frPlacementAvailable === 1) &&
														<MButton
															id={flight?.flightID}
															type="outlined"
															onClick={(e) => handleShowBoarding(e, freights)}
														>
															Выбор места
														</MButton>}
													{/* <MButton type="outlined">Багаж</MButton> */}
													{/* <MButton type="outlined">Питание</MButton> */}
												</div>
											</div>
											<Count>
												<Text>x{flight.count}</Text>
												{/* <Remove style={{ fill: 'rgba(179, 185, 192, 1)' }} /> */}
											</Count>
											<Line />
										</>
									)
								})}
							{claim &&
								claim.services.map((service) => {
									return (
										<>
											<div className={'orders'}>
												<LineContainer>
													<Checkmark style={{ marginRight: '8px' }} />
													{service.serviceName},

													{' '}
													{service?.serviceCategory === "Трансфер" &&
														<>
															(
															{service?.fromTownName}
															{'—>'}
															{service?.toTownName}
															)
														</>
													}
													{' '}
													{format(new Date(service.dateBeg), 'dd.MM.yyyy')}{' '}
													{service.dateEnd &&
														' — ' +
														format(new Date(service.dateEnd), 'dd.MM.yyyy')}

												</LineContainer>
											</div>
											<Count>
												<Text>x{service.count}</Text>
												{/* <Remove style={{ fill: 'rgba(179, 185, 192, 1)' }} /> */}
											</Count>
											<Line />
										</>
									)
								})}
							{!isEmpty(claim?.insurances) &&
								<React.Fragment>
									<Text bold>Страхование</Text>
									<div />
									{claim.insurances.map(insurance => {
										return (
											<>
												<div className={'orders'}>
													<Insurance style={{ marginRight: '8px' }} />
													{insurance.isMedical ? 'Медицинская' : 'Страховка'}
													<span>
														{' '}
														{insurance?.insureName}
														{' '}
														({insurance.cost} {insurance.costCurrency})
													</span>
													<span>
														{' '}
														{format(
															new Date(insurance.dateBeg),
															'dd.MM.yyyy'
														)} —{' '}
														{format(new Date(insurance.dateEnd), 'dd.MM.yyyy')}
													</span>
												</div>
												<Count>
													<Text>x{insurance.count}</Text>
													{/* <Remove style={{ fill: 'rgba(179, 185, 192, 1)' }} /> */}
												</Count>
												{claim?.inscusts?.filter(i => i?.insureID === insurance?.insureID)?.map(subOption =>
													<React.Fragment>
														<Line />
														<LineContainer style={{ marginLeft: '16px' }}>{subOption.name}</LineContainer>
														<Count>
															<Text>x{subOption.count}</Text>
														</Count>

													</React.Fragment>
												)}
												<Line />
											</>
										)
									})}
								</React.Fragment>}
							{!isEmpty(claim?.visas) &&
								<React.Fragment>
									<Text bold>Визы</Text>
									<div />
									{claim.visas.map(visa => {
										return (
											<>
												<div className={'orders'}>
													<VisaIcon style={{ width: 16, height: 16, marginRight: '8px' }} />
													{visa.visaName}
													<span>
														{' '}
														{format(
															new Date(visa.dateBeg),
															'dd.MM.yyyy'
														)} —{' '}
														{format(new Date(visa.dateEnd), 'dd.MM.yyyy')}
													</span>
													{' '}
													{visa.visaDeadlineDate && <span style={{fontWeight: 600}}>Крайний срок подачи документов: {format(
														new Date(visa.visaDeadlineDate),
														'dd.MM.yyyy HH:mm'
													)}</span>}
												</div>
												<Count>
													<Text>x{visa.count}</Text>
													{/* <Remove style={{ fill: 'rgba(179, 185, 192, 1)' }} /> */}
												</Count>
												<Line />
											</>
										)
									})}
								</React.Fragment>}
							{showBoarding && <Boarding open={showBoarding} onClose={handleShowBoarding} claim={claim} id={boardingId} />}
						</OrdersBody>
					</div>
				</Collapse>
			}
		</CollapseContainer>
	)
}

export default Orders
