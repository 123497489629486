import React, { useEffect, useRef, useState } from 'react';
import { TextField } from "@material-ui/core";
import Minus from '../../../../../asset/icons/minus-circle.svg'
import Plus from '../../../../../asset/icons/plus-circle.svg'
import './style.scss'
import useInsuranceStore from "../../store";

const TouristsField = ({ adults, title, onChange, ...props }) => {
	const [showBody, setShowBody] = useState(false)
	const field = useRef(null)

	const actions = useInsuranceStore(({ actions }) => actions)
	const ages = useInsuranceStore(({ ages }) => ages)

	useEffect(() => {
		function handleClick(event) {
			if (field.current && !field.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [field]);

	const toggle = () => {
		setShowBody(!showBody)
	}

	const handleChangeAge = (value, id) => {
		if (value > 99) {
			actions.changeAge(99, id)
			onChange(99, id)
		}
		if (!value) {
			actions.changeAge(value, id)
			onChange(value, id)
		}
		if (value < 100 && value > 0) {
			actions.changeAge(Number(value), id)
			onChange(Number(value), id)
		}
	}

	const handleAgeBlur = (value, id) => {
		if (!value || value === 0) {
			actions.changeAge(1, id)
			onChange(1, id)
		}
	}

	useEffect(() => {
		if (!showBody) {
			ages?.forEach((element, index) => {
				if (!element) {
					actions.changeAge(1, index)
					onChange(1, index)
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showBody])

	return (
		<div className="v-departure-base" ref={field}>
			<div className="v-departure">
				<div onClick={toggle}>
					<div>
						<TextField
							id={'tourist'}
							label={title}
							variant="outlined"
							fullWidth
							autoComplete={'off'}
							value={`${adults}`}
							{...props}
						/>
					</div>
				</div>
				{showBody && <div className="v-departure__body-container tourists_insurance">
					<div className="tourists__body">
						<div className={'adults'}>
							<div>Количество</div>
							<div className={'button-block'}>
								<span
									className={`icon-button ${adults > 1 && 'active'}`}
									style={{ WebkitMask: `url(${Minus}) no-repeat` }}
									onClick={adults > 1 ? actions.adultDec : () => { }}
								/>
								<div>{adults}</div>
								<span
									className={`icon-button ${(adults) < 5 && 'active'}`}
									style={{ WebkitMask: `url(${Plus}) no-repeat` }}
									onClick={(adults) < 5 ? actions.adultInc : () => { }}
								/>
							</div>
						</div>
						{ages.map((item, index) =>
							<div key={index} className={'adults'}>
								<div>
									<div>Возраст</div>
									<div className={'count-tourist'}>{index + 1} туриста</div>
								</div>

								<div className={'button-block'}>
									<span
										className={`icon-button ${item > 1 && 'active'}`}
										style={{ WebkitMask: `url(${Minus}) no-repeat` }}
										onClick={item > 1 ? () => actions.ageDec(index) : () => { }}
									/>
									<div className={'age-input'}>
										<input type="text" value={item} onChange={(e) => handleChangeAge(e.target.value, index)} onBlur={(e) => handleAgeBlur(e.target.value, index)} />
									</div>
									<span
										className={`icon-button ${(item) < 99 && 'active'}`}
										style={{ WebkitMask: `url(${Plus}) no-repeat` }}
										onClick={(item) < 99 ? () => actions.ageInc(index) : () => { }}
									/>
								</div>
							</div>
						)}
						<div className={'adults'}>* Пожалуйста, убедитесь, что возраст указан корректно — при оформлении страховки графа не редактируется.</div>
					</div>
				</div>}
			</div>
		</div>
	);
};

export default TouristsField;