import React from 'react';
import Select from 'react-select';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css'

const CustomSelect = ({label, required, options, value, onChange, className, defaultValue, ...props }) => {

  const [currentValue, setCurrentValue] = React.useState(value)
  const getValue = () => {
    return currentValue ?  options?.find(item => item.value === currentValue) : []
  }

  const handleChange = (newValue) => {
    setCurrentValue(newValue.value);
    if (onChange) {
      onChange(newValue.value);
    }
  };
    
      return (
        <div className='CustomSelect-wrapper'>   
         <label 
            className='CustomSelect-lable' 
            >{label}{required && <span style={{ color: '#E73C3E' }}>*</span>}
        </label>
        <Select 
            className={className}
            classNamePrefix="CustomSelect"            
            placeholder=""
            options={options}
            value={getValue()} 
            onChange={handleChange}
            components={{
                IndicatorSeparator: () => null, // Скрыть разделитель между значком и текстом
                DropdownIndicator: ({ innerProps }) => (
                  <span {...innerProps} className="CustomSelect-dropdown-indicator">
                    <ExpandMoreIcon/> {/* Заменить значок открытия селекта */}
                  </span>
                ),
              }}
            {...props}
            >
        </Select>
        </div>
      )
    }

export default CustomSelect;