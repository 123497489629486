import React from "react"
import styled from "styled-components"
import { Text, Checkbox } from "../../ui"
import OutlinedButton from '../../ui/OutlinedButton'
import PrimaryButton from "../../ui/PrimaryButton"
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import { styled as MUIStyled } from '@mui/material/styles';
import moment from "moment"
import formatPrice from "../../../functions/formatPrice"
import PriceItem from './PriceItem';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'
import DeclineConfirmModal from "./DeclineConfirmModal"
import {useDispatch,useSelector} from 'react-redux'
import {
    getCalculationModalsState,
    showCalculationModal,
    showOnlyOneCalculationModal,
    MODALS,
    hideCalculationModal
} from '../../../store/modules/appeals';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {styled as MUIstyled} from '@mui/material/styles'


const Container = styled.div`
  width: 640px;
  max-height: 90vh;
  overflow: hidden;
  background-color: white;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 10px;
  }

  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 24px 9px;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
    border-radius: 0px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const Wrapper = styled.div`
  max-height: calc(90vh - 48px);
  overflow: auto;
  padding: 0 15px;

  &::-webkit-scrollbar {
    width: 2.786px;
  }
   
  &::-webkit-scrollbar-thumb {
    background: #4872f2;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #E9EAEC;
    width: 2.786px;
    border-radius: 2px;
  }
`;

const Title = styled(Text)`
  display: block;
  margin-bottom: 24px;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`

const InfoBlock = styled.div`
  & > * {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const InfoBlockTitle = styled(Text)`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px
`

const InfoBlockRow = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7E8389;

  .blue {
    color: #4872F2;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px
  }

  .strong {
    color: #231F20;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;

const PassengersList = styled.div`
`

const ButtonBlock = styled.div`
  display: flex;
  margin-top: 40px;

  & > button {
    padding: 8px 20px;
    font-size: 16px;
    line-height: 24px;
  }

  & > :first-child {
    margin-right: 20px;
  }
  @media (max-width: 767px) {
      flex-direction: column-reverse; 
      & > button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`

const ConfirmRules = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? "color: red;" : "")}
  }
  @media (max-width: 767px) {
    align-items: flex-start;
  }
`

const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

const BottomText = styled(Text)`
  color: #3C3C3C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  & > .blue {
    color: #4872F2;
  }
`

const StyledPrimaryButton = MUIstyled(PrimaryButton)(() => ({
  fontWeight: '600',
}))

const LightTooltip = MUIStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => {
  return {
      [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        "&::before": {
            backgroundColor: 'white',
            border: "1px solid #999"
          }
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 15px 20px 0px rgba(16, 16, 16, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
        fontSize: '12px',
        lineHeight: '16px',
        fontFamily: 'Open Sans',
        padding: 14,
        width: props.width,
      },
  }
});

const Head = styled.div``;
const Content = styled.div``;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const CalculationModal = ({ onApplyCalculation,onDeclineCalculation,data,interaction, ...props }) => {

  const {visibility} = useSelector(getCalculationModalsState)
  const dispatch = useDispatch();
  const isInteractable = typeof interaction === 'undefined' ? true :  interaction;

  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: !isInteractable,
    acceptError: undefined
  })

  if(data === null) return null;

  const isExtraPayment = data.type === 'payment'
  const stillUtc = moment.utc(data.timelimit).toDate()
  const dateTo = moment(stillUtc)
    .local()
    .format("DD MMMM HH:mm")

 
  return (
    <Modal 
      {...props} 
      nested
      aria-labelledby="calculation-modal-title"
      aria-describedby="calculation-modal-description"
    >
      <Container>
        <CloseButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              color: (theme) => theme.palette.grey[500],
            }}
        >
            <Icon/>
        </CloseButton>
        <Wrapper>
          <Head>
            <Title>
              Расчёт на {isExtraPayment ? 'доплату' : 'возврат'} №{data.number}
            </Title>
            <InfoBlock>
              <InfoBlockTitle>Информация:</InfoBlockTitle>
              {!!data.timelimit && (<InfoBlockRow>Время на подтверждение расчета: <span className="blue">{dateTo}</span></InfoBlockRow>)}
              {!!data.comment && data.comment !== "" && (<InfoBlockRow>Комментарий к расчету: <span className="strong">{data.comment}</span></InfoBlockRow>)}
            </InfoBlock>
          </Head>
          <Content>
            <PassengersList>
              {data.items.map((x, key) => (
                <PriceItem {...x}  showFinalPrice={data.items.length > 1} key={key}/>
              ))}
            </PassengersList>
            <BottomBlock>
              <BottomText>
                Итоговая сумма {isExtraPayment ? 'доплаты' : 'возврата'}:{" "}
                <span className="blue">{formatPrice(data.total)}</span>
              </BottomText>
            </BottomBlock>
            <ConfirmRules error={acceptError}>
              <Checkbox
                type="squar"
                error={acceptError}
                checked={isRulesAccepted}
                disabled={!isInteractable}
                onChange={e => {
                  setRulesState(state => ({
                    acceptError: undefined,
                    isRulesAccepted: !state.isRulesAccepted
                  }))
                }}
              />

              <Text>
                Я ознакомлен и согласен с условием на {isExtraPayment ? 'доплату' : 'возврат'},
                договором{" "}
                <a
                  style={{ color: "#4872F2", textDecoration: "none" }}
                  href="/docs/oferta.pdf"
                  target="_blank"
                >
                  публичной оферты
                </a>{" "}
                и{" "}

                  <LightTooltip title={`Подтверждаю ${isExtraPayment ? 'доплату' : 'возврат'} по заявке №${
                    data.orderNumber
                  }. С ${isExtraPayment ? 'доплатой' : 'возвратом'} в размере ${formatPrice(
                    data.total || 0
                  )} согласен.`} arrow placement="top" >
                    <Text color="blue" style={{ cursor: "pointer" }}>
                      текстом заявления{" "}
                    </Text>
                  </LightTooltip>{" "}
              </Text>
            </ConfirmRules>

            {isInteractable && (<ButtonBlock>
              <StyledPrimaryButton
                type="button"
                onClick={() => {
                  if (isRulesAccepted) {
                    onApplyCalculation(data.id);
                  } else {
                    setRulesState(state => ({
                      ...state,
                      acceptError: "ошибочка"
                    }))
                  }
              }}
              >
                Я подтверждаю расчет
              </StyledPrimaryButton>
              <OutlinedButton
                type='button'
                onClick={() => {
                  dispatch(showCalculationModal(MODALS.DECLINE_CONFIRM))
                }}
              >
                Я отклоняю расчет
              </OutlinedButton>
            </ButtonBlock>)}
          </Content>
        
        </Wrapper>
        <DeclineConfirmModal
          open={visibility[MODALS.DECLINE_CONFIRM]}
          onConfirm={() => {
            onDeclineCalculation(data.id);
          }}
          onGoBack={() => dispatch(showOnlyOneCalculationModal(MODALS.CALCULATION))}
          onClose={() => dispatch(hideCalculationModal(MODALS.DECLINE_CONFIRM))}
        />
      </Container>
    </Modal>
  )
}



export default CalculationModal
