import axios from "axios";

const BASE_URL = process.env.REACT_APP_GEO_APIGATE;

const apiTransformResponse = (data) => {
	if (!data || typeof data !== 'string') {
		return data;
	}

	return JSON.parse(data);
};

export const geoApiInstance = (config = {}) => {
	const token = process.env.REACT_APP_GEO;

	config = {
		baseURL: config.baseURL || BASE_URL,
		headers: {
			'clientId': 'b2c:ru',
			'Content-Type': 'application/json',
		},
		transformResponse: apiTransformResponse,
		...config,
	};

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	const apiInstance = axios.create(config);

	apiInstance.interceptors.response.use((res) => {
		return res;
	}, (err) => {
		if ([401, 403].includes(err?.response?.status)) {
			console.error('401 or 403 error happened');
		}
		return err;
	});

	return apiInstance;
};