import React,{useRef, useEffect} from 'react';
import Chart from "react-apexcharts";
import './style.css'
import {CloseOutlined} from '@material-ui/icons';
import {format, parseISO} from "date-fns";
import {ru} from "date-fns/locale";
import { ReactComponent as ArrowDown } from '../../asset/icons/arrow-down-red.svg'
import { ReactComponent as ArrowUp} from '../../asset/icons/arrow-up-green.svg'

const BASE_OPTIONS = {
  locale: 'ru',
  chart: {
    type: 'line',
    zoom: {enabled: false},
    animations: {enabled: false},
  	toolbar: { show: false}
},
  dataLabels: {enabled: false},
  stroke: {curve: 'straight', width: 2},
	markers: {
		size: 3,
	},
  grid: { row: {colors: ['#f3f3f3', 'transparent'], opacity: 0.5},
}}

const сurrencyDifference = (todayRate, yesterdayRate) =>{
  let currencyDelta = todayRate - yesterdayRate
  if(currencyDelta > 0){
    return `<span class='label_box_green'>  +${currencyDelta.toFixed(1)}</span>`
  } 
  if(currencyDelta < 0){
    return `<span class='label_box_red'> ${currencyDelta.toFixed(1)}  </span>`
  }
  return ''
}

const MAX_ITEM_TABLE = 6

function CurrencyChart({data, name, onClose, targetCurrency = 'RUB'}) {
    const ref = useRef(null);

    const handleClick =() => {
      onClose && onClose(false)
    }
    
    const options = {...BASE_OPTIONS,
      xaxis: {
      categories: data.map((i) =>  i.date),
      type: 'datetime',
      labels:{
        formatter: function(value){
          return format(new Date(value), 'MMM dd', {locale: ru})
        }
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex,w }) {
        return '<div class="label_box">' +
          '<div class="label_box_title">' + format(parseISO(w.config.xaxis.categories[dataPointIndex]), 'dd.MM.yyyy', {locale: ru})  + '</div>' +
          '<div class="label_box_content">' + series[seriesIndex][dataPointIndex] + ' ' + targetCurrency + сurrencyDifference(series[seriesIndex][dataPointIndex],series[seriesIndex][dataPointIndex-1]) + "</div>" +
          '</div>'
      }
    },
  }


	useEffect(() => {
		function handleClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
        onClose && onClose(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [ref,onClose]);
   
    return (
      <>
      <div className='modalOverlay'/>
      <div ref={ref} id='ModalCharts' >
          <div className='head'>
            <div style={{flex: 1 , textAlign: 'center',alignSelf: "center"}}>{`История изменения курса ${name}`}</div>
            <div className='close_btn' style={{ textAlign: 'end',cursor:'pointer'}} onClick={handleClick}>
              <CloseOutlined style={{color: '#7E8389'}}/>
            </div>
          </div>
        <div className={'body'} style={{display: "flex"}}>
            <div className='Chart' style={{flex: 1}}>  
              <Chart options={options} series={[{targetCurrency,data: data.map((i) => i.rate), color:'#ff0000'}]} type="line"  width={"100%"}/>
            </div>
          <div className="chart_container" style={{flex: 0.5}}>
            <table id="chart"  className="chart_table">
             <thead>
					     <tr>
						     <th className="first">Дата</th>
						     <th>Курc</th>
						     <th>Изменения</th>
					     </tr>
				      </thead>
              <tbody>
                {data &&
                   data.slice(0, MAX_ITEM_TABLE).map((item, index, arr) => {
                    let courseChange = index < arr.length - 1 ? (item.rate - arr[index + 1].rate).toFixed(1) : '';
					          courseChange = courseChange > 0 ? '+' + courseChange : courseChange
                     return (
                       <tr key={`${item}-${item.date}`}>
                         <td>{format(new Date(item.date), 'dd.MM.yyyy')}</td>
                         <td>{item.rate} ₽</td>
                         <td>
							              {courseChange !== '0.0' ? courseChange : ''}
							              {courseChange < 0 ? <ArrowDown className="rate-down"></ArrowDown> :
								            courseChange && courseChange !== '0.0' && <ArrowUp  className="rate-up" />}
						            </td>
                       </tr>
                     );
                   })}
			        </tbody>
            </table>   
          </div>
        </div>
      </div>
      </>
      )
}

export default CurrencyChart;