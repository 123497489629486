import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import {format, addYears } from "date-fns";
import {uniqBy} from "lodash";

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const usePlansStore = create(
	devtools((set, get) => ({
		plans: [],
		loading: false,
		actions: {

			getFact: async (start, end) => {
				set({loading: true})
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const agentId = JSON.parse(localStorage.getItem('agentInfo'))?.agents?.id
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/sales/fact`,
						method: 'POST',
						data: {
							agentId: agentId,
							startPeriodDate: start,
							endPeriodDate: end,
						},
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						}
					})
				return res.data
				} catch (e) {
					console.error('error in getFact', e?.response?.data)
				} finally {
					set({loading: false})
				}
			},			

			getPlan: async () => {
				set({loading: true})
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const agentId = JSON.parse(localStorage.getItem('agentInfo'))?.agents?.id
				try {
					const res = await axios({
						url: `${BASE_URL}/agentreport/sales/plan`,
						method: 'POST',
						data: {
							agentId: agentId,
							startPeriodDate: "2023-01-01",
							endPeriodDate: format(addYears(new Date(), 1), 'yyyy-MM-dd')
						},
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						}
					})
					// Получение планов и фактов параллельно
					const result = res.data.result.filter(item =>
						item.reportingPeriod.includes('–') || item.reportingPeriod.includes('-')
					).map(item => {
						if (item.reportingPeriod.includes('–')) {
							return {...item, reportingPeriod: item.reportingPeriod.replaceAll(' ', '').replace('–', ' - ')}
						} else {
							return {...item, reportingPeriod: item.reportingPeriod.replaceAll(' ', '').replace('-', ' - ')}
						}
					}).reverse()
					const plansWithFacts = await Promise.all(
						uniqBy(result.filter(item =>
							item.reportingPeriod.includes('–') || item.reportingPeriod.includes('-')
						), 'reportingPeriod').map(async (plan) => {
						// Получение фактов для текущего плана
						const splitDatePeriod = (datePeriod) => {
							const [start, end] = datePeriod.includes('–') ? datePeriod.split('–') : datePeriod.split('-');
							const convertToDateFormat = (date) => {
								return format(new Date(date.split('.').reverse().join('.').trim()), 'yyyy-MM-dd')
							}
							return [convertToDateFormat(start), convertToDateFormat(end)]
						}
						const facts =  await  get().actions.getFact(splitDatePeriod(plan.reportingPeriod)[0], splitDatePeriod(plan.reportingPeriod)[1]);
						// Добавление фактов к текущему плану
						return { ...plan, fact: facts };
					}));

					set({ plans: plansWithFacts });
				} catch (e) {
					console.error('error in getPlan', e)
				} finally {
					set({loading: false})
				}
			},
		}
	}))
)

export default usePlansStore

