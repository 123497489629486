import React from 'react';

const Steps = React.createContext(null);

export const useTicketProps = () => React.useContext(Steps);

export const TicketProvider = React.memo(
  ({ children, ...props }) => {
    return <Steps.Provider value={props}>{children}</Steps.Provider>;
  },
  (prevProps, nextProps) => prevProps.id === nextProps.id
);
