import React from "react"
import styled from "styled-components"
import { Text, Button,Loader } from "../../ui"
import formatPrice from "../../../functions/formatPrice"
import CalculationModal from "../../modals/calculations/CalculationModal"
import DeclineConfirmModal from "../../modals/calculations/DeclineConfirmModal"
import {
  applyCalculationRequest,
  declineCalculationRequest,
  getCalculationModalsState,
  hideCalculationModal,
  MODALS,
  showCalculationModal,
  setCalculationModalsData
} from '../../../store/modules/appeals'
import { useDispatch,useSelector } from "react-redux"
import moment from "moment"
import {createCalculationParams} from './utils'

const Item = styled.div`
  padding: 20px 14px;
  background: ${({ red }) => (red ? "#FFE2E3" : "#fff3cd")};
  border: 1px solid #ffb800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 238px;
  box-sizing: border-box;
  position: relative;
`

const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const CancelButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #b5b3b3;
  }
`

const ItemTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const BaseText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`

const ItemDescription = styled(BaseText)`
  display: block;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Information = styled.div`
  margin-bottom: 52px;

  & > span {
    margin-bottom: 10px;
    display: block;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 14px;
  right: 14px;

  @media (max-width: 769px) {
    display: block;
    position: unset;

    & > button {
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`

const PrimaryButton = styled(Button).attrs({
  type: "primary",
  htmlType: "button"
})`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 12px;
`
const OutlinedButton = styled(Button).attrs({
  type: "outlined",
  htmlType: "button"
})`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 12px;
`

function getTemplateText(type, subject, status) {
  switch (type) {
    case "ExtraPayment":
      return {
        title: "Расчет на доплату",
        totalText: "Сумма доплаты",
        description:
          status === "CanceledByClient"
            ? `Вы отклонили: расчет на ${subject.toLowerCase()}. Напишите в чат по обращению для создания нового расчета.`
            : status === "ApprovedByClient"
            ? "Необходимо внести доплату."
            : `Вами запрошен: расчёт на ${subject.toLowerCase()}. Откройте расчет для согласования.`
      }
    default:
      return {
        title: "Расчет на возврат",
        totalText: "Сумма возврата",
        description:
          status === "CanceledByClient"
            ? `Вы отклонили: расчет на ${subject.toLowerCase()}. Напишите в чат по обращению для создания нового расчета.`
            : `Вами запрошен: расчёт на ${subject.toLowerCase()}. Откройте расчет для согласования.`
      }
  }
}

export default function Calculations({ onGoInsideAppeal, onPay, ...props }) {
  //   const calculation = useSelector(getCalculationSelector);

  const {visibility} = useSelector(getCalculationModalsState)


  // console.log(view.visibility);
  const [
    isConfirmCancelationModal,
    setConfirmCancelationModal
  ] = React.useState(false)

  const dispatch = useDispatch()
  const { title, totalText } = getTemplateText(
    props.supportTicketCalculation.type.code,
    props.subject.description || "",
    props.supportTicketCalculation.status.code || ""
  )

  const isCancelled =
    props.supportTicketCalculation.status.code === "CanceledByClient"

  const total =
    props.supportTicketCalculation?.type.code === "ExtraPayment"
      ? props.supportTicketCalculation?.total || 0
      : props.supportTicketCalculation?.supportTicketCalculationItems?.reduce(
          (acc, x) => acc + x?.supportTicketCalculationParameter?.refundAmount,
          0
        )
  const stillUtc = moment.utc(props.supportTicketCalculation.timelimit).toDate()
  const dateTo = moment(stillUtc)
    .local()
    .format("DD MMMM HH:mm")

  React.useEffect(() => {
    dispatch(setCalculationModalsData(createCalculationParams(props)))
  },[])  

  return (
    <Item red={isCancelled}>
      {visibility[MODALS.LOADING] && <Loader opacity="0.8" zIndex={999}/>}
      <ItemHead>
        <ItemTitle>{title}</ItemTitle>
        <CancelButton
          type="button"
          onClick={() => {
            setConfirmCancelationModal(true)
          }}
        >
          Отменить
        </CancelButton>
      </ItemHead>
      <ItemDescription>
        Расчет на {props.subject.description} по обращению №
        {props.number}.<br />
        {props.supportTicketCalculation.status.code === "WaitingForApproval" &&
          "Откройте расчет для согласования."}
      </ItemDescription>
      <Information>
        <BaseText>
          {totalText}: <strong>{formatPrice(total || 0)}</strong>{" "}
        </BaseText>
        {props.supportTicketCalculation.timelimit && (
          <BaseText>
            Время для согласования до: <strong>{dateTo}</strong>
          </BaseText>
        )}
      </Information>
      {props.supportTicketCalculation.status.code === "WaitingForApproval" && (
        <ButtonBlock>
          <PrimaryButton
            onClick={() => {
              dispatch(showCalculationModal(MODALS.CALCULATION))
            }}
          >
            Открыть расчет
          </PrimaryButton>
          <OutlinedButton onClick={() => onGoInsideAppeal(props)}>
            Перейти в обращение
          </OutlinedButton>
        </ButtonBlock>
      )}

      {isCancelled && (
        <ButtonBlock>
          <OutlinedButton onClick={() => onGoInsideAppeal(props)}>
            Перейти в обращение
          </OutlinedButton>
        </ButtonBlock>
      )}
      <CalculationModal
        open={visibility[MODALS.CALCULATION]}
        onApplyCalculation={(id) => dispatch(applyCalculationRequest({id, page: 'order'}))}
        onDeclineCalculation={(id) => dispatch(declineCalculationRequest({id, page: 'order'}))}
        onClose={() => {
          dispatch(hideCalculationModal(MODALS.CALCULATION))
        }}
        data={createCalculationParams(props)}
      />
      <DeclineConfirmModal 
        open={isConfirmCancelationModal} 
        onGoBack={() => {
          setConfirmCancelationModal(false);
          dispatch(showCalculationModal(MODALS.CALCULATION))
        }}
        onConfirm={() => {
          dispatch(declineCalculationRequest({id: props?.supportTicketCalculation?.id, page: 'order'}))
        }}
        onClose={() => {setConfirmCancelationModal(false);}}
      />
    </Item>
  )
}
