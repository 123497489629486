import { createAction, createReducer } from "redux-act"
import { AGENT_USERS_LOADING_STATE } from "../agent-finance/constants"

export const getAgentUserRolesRequest = createAction(
  "order/getAgentUserRolesRequest"
)
export const getAgentUserRolesSuccess = createAction(
  "order/getAgentUserRolesSuccess"
)

export const getAgentUsersRequest = createAction("@@agents/getAgentUsers")
export const getAgentUsersSuccess = createAction(
  "@@agents/getAgentUsersSuccess"
)

export const addAgentUsersRequest = createAction("@@agents/addAgentUsers")
export const addAgentUsersSuccess = createAction(
  "@@agents/addAgentUsersSuccess"
)

export const setRoleToUserRequest = createAction("@@agents/setRoleToUser")
export const setRoleToUserSuccess = createAction(
  "@@agents/setRoleToUserSuccess"
)

export const setAgentUsersRequest = createAction("@@agents/setAgentUsers")
export const setAgentUsersSuccess = createAction(
  "@@agents/setAgentUsersSuccess"
)

export const createUserInSamoRequest = createAction("@@agents/createUserInSamo")
export const createUserInSamoSuccess = createAction(
  "@@agents/createUserInSamoSuccess"
)

export const getAgentLogRequest = createAction("@@agents/getAgentLogRequest")
export const getAgentLogSuccess = createAction("@@agents/getAgentLogSuccess")

export const getUsefulInfoRequest = createAction(
  "@@agents/getUsefulInfoRequest"
)

const roles = createReducer({}, [])
roles.on(getAgentUserRolesSuccess, (_, payload) => payload?.userRoles)

const agentUsers = createReducer({}, [])
agentUsers.on(getAgentUsersSuccess, (_, payload) => payload.users)

const addAgentUserStatus = createReducer({}, null)
addAgentUserStatus.on(addAgentUsersSuccess, (_, payload) => payload)

const agentLog = createReducer({}, null)
agentLog.on(getAgentLogSuccess, (_, payload) => payload?.event)

const loadingsUser = createReducer({}, AGENT_USERS_LOADING_STATE)
loadingsUser.on(addAgentUsersRequest, state => ({
  ...state,
  createUserInSamo: true
}))
loadingsUser.on(addAgentUsersSuccess, state => ({
  ...state,
  createUserInSamo: false
}))

const userAgentsReducers = {
  roles,
  agentUsers,
  addAgentUserStatus,
  agentLog,
  loadingsUser
}

export default userAgentsReducers
