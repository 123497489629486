import React from 'react'
import styled from 'styled-components'
import { Button, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from "@mui/material/CircularProgress";
import BirthDateInput from './components/BirthDate'
import { FormInput } from './components/FormInput'
import useReportsStore from './store'

const Container = styled.div`
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`
const Title = styled.div`
	font-family: Open Sans;
	font-weight: 600;
	font-size: 28px;
	font-style: normal;
	line-height: 38px;
	color: #2e2e32;

	margin-bottom: 24px;
`
const SelectBlock = styled.div`
	width: 100%;
	padding: 16px 16px 18px 16px;
	border: 1px solid #d4d9de;
	border-radius: 8px;

	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #2e2e32;
`
const Separator = styled.div`
	height: 1px;
	width: 100%;
	background: #dbe0e5;
	margin-top: 32px;
`

const Label = styled(Title)`
	font-size: 18px;
	line-height: 26px;
	margin: 32px 0 16px 0;
`
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
`
const ButtonReport = styled(Button)`
	padding: 16px 32px;
	background-color: #4872f2;
	border-radius: 8px;
	box-shadow: none;
	align-self: center;

	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
`

const Reports = () => {
	const actions = useReportsStore(({ actions }) => actions)
	const loading = useReportsStore(({ loading }) => loading)
	const startDate = useReportsStore(({ startDate }) => startDate)
	const endDate = useReportsStore(({ endDate }) => endDate)
	const claimNumber = useReportsStore(({ claimNumber }) => claimNumber)
	const formatFile = useReportsStore(({ formatFile }) => formatFile)

	return (
		<Container>
			<Title>Отчеты</Title>
			<div>
				<SelectBlock>Отчеты агента</SelectBlock>
				<Separator />
				<Label>Диапазон дат</Label>
				<Row>
					<BirthDateInput
						fullWidth
						onChange={(value) => actions.changeReportParams('startDate', value)}
						label='Дата начала тура'
						value={startDate}
						required
					/>
					<BirthDateInput
						fullWidth
						onChange={(value) => actions.changeReportParams('endDate', value)}
						label='Дата окончания тура'
						value={endDate}
						required
					/>
				</Row>
				<Label>Список заявок</Label>
				<FormInput
					value={claimNumber}
					onChange={(e) => actions.changeReportParams('claimNumber', e.target.value.replace(/[^0-9]/g, ''))}
					fullWidth
					variant='outlined'
					label='Укажите номер заявки'
				/>

				<Label>Формат файла</Label>
				<FormInput
					SelectProps={{ IconComponent: ExpandMoreIcon }}
					select
					fullWidth
					value={formatFile}
					onChange={(value) => actions.changeReportParams('formatFile', value)}
					variant='outlined'>
					<MenuItem value={'PDF'}>PDF</MenuItem>
				</FormInput>
			</div>
			<ButtonReport
				disabled={!startDate || !endDate || loading}
				variant='contained'
				color='primary'
				onClick={() => actions.agentReportGenerate()}>
				{loading && <CircularProgress size={15} />}
				Сформировать отчет
			</ButtonReport>
		</Container>
	)
}

export default Reports
