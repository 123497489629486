import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '../../../../../asset/images/checklk.svg';
import RectangleIcon from '../../../../../asset/images/rectangleIcon.svg';

import { Icon } from '@material-ui/core';

export const Logo = () => (
  <Icon style={{ marginLeft: '0px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #4872F2',
        borderRadius: '6px',
        width: '24px',
        height: '24px',
        backgroundColor: '#4872F2'
      }}
    >
      <img src={CheckIcon} alt='' />
    </div>
  </Icon>
);

export const IntermediateIcon = () => (
  <Icon style={{ marginLeft: '0px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #4872F2',
        borderRadius: '6px',
        width: '24px',
        height: '24px',
        backgroundColor: '#4872F2'
      }}
    >
      <img src={RectangleIcon} alt='' />
    </div>
  </Icon>
);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 24,
  height: 24,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    boxShadow: 'inset 0 0 0 1px #4872F2, inset 0 -1px 0 #4872F2'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)'
  }
}));

export default function BpCheckbox(props) {
  return (
        <Checkbox
          sx={{
            '&:hover': { bgcolor: 'transparent' }
          }}
          disableRipple
          color='default'
          indeterminateIcon={<IntermediateIcon />}
          checkedIcon={<Logo />}
          icon={<BpIcon />}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
          {...props}
        />
  );
}
