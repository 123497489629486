export const DetailInitialState = {
  id: "",
  customerId: "",
  amountExtraPayment: 0,
  orderStatus: { code: "", description: "" },
  paymentStatus: { code: "", description: "" },
  orderNumber: 0,
  createdDate: "",
  departureDate: "",
  arrivalDate: "",
  cancellationTime: "",
  backOfficeId: 0,
  amount: 0,
  amountTickets: 0,
  annulatedAviaOrderPositions: [],
  totalDetalization: {
    total: 0,
    agentTotal: 0,
    agentFee: 0,
    positionList: []
  },
  orderAmount: {
    description: "",
    detalization: [],
    priceType: 1,
    quantity: null,
    value: 0
  },
  documents: [],
  orderPositions: [],
  customer: {
    email: "tuitest@tui.ru",
    externalId: null,
    firstName: "Яковлев",
    id: "691142ae-1491-4898-aa22-e520119fd497",
    lastName: "Иван",
    phone: "+7 (123) 412-34-16",
    agreementToMailing: false
  },
  contactsInfo: {
    contactName: null,
    contactEmail: "kenscl12@inbox.ru",
    contactPhone: "+7 (999) 999-99-99"
  },
  insurances: [],
  availableActions: [],
  invoice: {
    items: [],
    total: 0
  },
  notifications: [],
  additionalServices: [],
  supportTickets: [],
  salesChannelType: "",
  availableCreditPaymentMethods: null,
  acquiringCommissions: [{ acquiringAmount: 0, acquiringPercent: 0, amount: 0, paymentType: '' }],
  isLowcoster: false,
  salesChannel: "",
  availableShipmentAllowed: null
}

export const OrdersListIntialState = {
  customerId: "",
  pageCount: 0,
  orders: [],
  currPage: 1,
  isLoading: false
}


export const PassengerTypes = {
  Adult : 'Adult',
  Child : 'Child',
  Infant : 'Infant',
}

export const AvailableActions = {
  //Печать маршрутной квитанции
  PRINT : 'PRINT',

  //Создание обращения с любой темой
  CREATE_APPEAL_WITH_ANY_THEME : 'CREATE_APPEAL_WITH_ANY_THEME',

  // Создание обращения только с темой "прочее"
  CREATE_APPEAL_WITH_ONLY_OTHER_THEME : 'CREATE_APPEAL_WITH_ONLY_OTHER_THEME',

  // Отмена
  CANCEL : 'CANCEL',

  // Оплата
  PAYMENT : 'PAYMENT',

  // Печать возврата
  REFUND_BLANK : 'REFUND_BLANK',
}

export const STATUS = {
  loading : 'loading',
  failure : 'failure',
  success : 'success',
}

export const AddictionalServiceTypes = {
  SMS_Service : 'SMS_Service',
  Return_Guarantee : 'Return_Guarantee',
  Payment_Receipt : 'Payment_Receipt',
}

export const FieldsTypes = {
  datetime : 'datetime',
  string : 'string',
}

export const EventSegmentsTypes = {
  Deleted : 'Deleted',
  Changed : 'Changed',
  Added : 'Added',
  Mixed : 'Mixed',
  Undefined : 'Undefined',
}

