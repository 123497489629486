import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import React, {useState} from 'react'
import useBookingStore from '../../../store'
import Baggage from '../Baggage'
import EventSeatOutlinedIcon from "@material-ui/icons/EventSeatOutlined";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import Boarding from "../../Boarding";

export default function TransferFlight({ flight }) {
	const peoples = useBookingStore(({ peoples }) => peoples)
	const actions = useBookingStore(({ actions }) => actions)
	const tour = useBookingStore(({ claimNew }) => claimNew.claimDocument)
	const [openBoarding, setOpenBoarding] = useState(false)
	const [boardingData, setBoardingData] = useState({ freight: null, dateFreight: null })

	const closeBoarding = () => {
		setOpenBoarding(false)
		actions.clearBoarding()
	}

	const getBoarding = (id, date) => {
		setBoardingData({ freight: id, dateFreight: date })
		setOpenBoarding(true)
	}

	const fullBoardingForward = () => {
		return flight?.find(i => !i?.direction)?.clients.filter(client => client.seatKey).length === tour.peopleCount
	}

	const fullBoardingBackward = () => {
		return flight?.find(i => i?.direction)?.clients.filter(client => client.seatKey).length  === tour.peopleCount
	}

	const isFullBoardingBackward = fullBoardingBackward()
	const isFullBoardingForward = fullBoardingForward()

	return (
		<div className='block-body'>
			<div className='grid-avia header'>
				<div>Рейс</div>
				<div>Дата</div>
				<div>Кол-во</div>
				<div></div>
			</div>
			{flight?.filter(i => !i?.direction)?.map(item => {
				return (
					<>
						<div className='grid-avia body'>
							<div className='select-flight'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-outlined-label'>
										{'Туда'}
									</InputLabel>
									<Select
										labelId='demo-simple-select-outlined-label'
										value={item.key}
										label={'Туда'}
										className='custom-select'>
										<MenuItem key={item.key} value={item.key}>
											[Эконом] (Места: есть) {item.name} (
											{item.departure.town}{' '}
											{item.departure.portAlias}{' '}
											{item.departure.time} -{' '}
											{item.arrival.town} {item.arrival.portAlias}{' '}
											{item.arrival.time})
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='flight-date'>
								{format(new Date(item.datebeg), 'dd.MM.yyyy')}
							</div>
							<div className='flight-paxes'>{peoples.length}</div>
							<div data-label="Выбор места" style={{ display: 'flex', alignItems: "center" }}>
								{item.boardingAvailable &&
									(!isFullBoardingForward ? <EventSeatOutlinedIcon
										style={{ cursor: "pointer" }}
										onClick={() => getBoarding(item.key, tour.dateEnd)}
										titleAccess={'Нажмине чтобы выбрать места'}
									/> : <EventSeatIcon
										style={{ cursor: "pointer" }}
										onClick={() => getBoarding(item.key, tour.dateEnd)}
										titleAccess={'Нажмине чтобы выбрать места'}
									/>)}
							</div>
						</div>
						<Baggage baggages={item?.tariffs} />
					</>
				)
			})}
			{flight?.filter(i => i?.direction)?.map(item => {
				return (
					<>
						<div className='grid-avia body'>
							<div className='select-flight'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-outlined-label'>
										{'Обратно'}
									</InputLabel>
									<Select
										labelId='demo-simple-select-outlined-label'
										value={item.key}
										label={'Обратно'}
										className='custom-select'>
										<MenuItem key={item.key} value={item.key}>
											[Эконом] (Места: есть) {item.name} (
											{item.departure.town}{' '}
											{item.departure.portAlias}{' '}
											{item.departure.time} -{' '}
											{item.arrival.town} {item.arrival.portAlias}{' '}
											{item.arrival.time})
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='flight-date'>
								{format(new Date(item.datebeg), 'dd.MM.yyyy')}
							</div>
							<div className='flight-paxes'>{peoples.length}</div>
							<div data-label="Выбор места" style={{ display: 'flex', alignItems: "center" }}>
								{item.boardingAvailable &&
									(!isFullBoardingBackward ? <EventSeatOutlinedIcon
										style={{ cursor: "pointer" }}
										onClick={() => getBoarding(item.key, tour.dateEnd)}
										titleAccess={'Нажмине чтобы выбрать места'}
									/> : <EventSeatIcon
										style={{ cursor: "pointer" }}
										onClick={() => getBoarding(item.key, tour.dateEnd)}
										titleAccess={'Нажмине чтобы выбрать места'}
									/>)}
							</div>
						</div>
						<Baggage baggages={item?.tariffs} />
					</>
				)
			})}
			{openBoarding && <Boarding dateFreight={boardingData.dateFreight} freight={Number(boardingData.freight)} onClose={closeBoarding} />}
		</div>
	)
}
