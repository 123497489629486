import React, {useEffect} from 'react';
import InfoBlock from '../InfoBlock';
import Text, { Header } from '../../ui/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {useFormikContext} from "formik";
import PassengerList from './List';
import styled from 'styled-components';
import {getPassanagersRequest} from "../../../store/modules/user";

const Body = styled.div`
  margin-top: 15px;
  @media(max-width: 767px) {
        margin-top: 10px;
      }
`;

const DesktopDescription = styled(Text)`
  margin-bottom: 32px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  & > p {
    margin: 10px 0;
  }
  margin-bottom: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const WarningText = styled.div`
  margin-top: 8px;
  font-weight: 600;
`

const Wrapper = styled(InfoBlock)`
  @media (max-width: 1023px) {
    padding: 20px 20px;
  }
  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Passengers = () => {
  const frequentFlyerAirlines = useSelector((state) => state.booking.ticket.frequentFlyerAirlines);
  const dispatch = useDispatch()
  const {values} = useFormikContext()

  useEffect(() => {
    dispatch(getPassanagersRequest());
  }, [])

  return (
    <Wrapper>
      <Header size="h4">Пассажиры</Header>
      <Body id="passengers">
        <DesktopDescription color="black" size="normal">
          Внимательно внесите данные пассажиров. После оплаты внос изменений
          платный. Авиакомпания может отказать в посадке, если данные не
          совпадут с документами.
        </DesktopDescription>
        <MobileDescription>
          <p>
            Заполните данные пассажиров. После оплаты внесение изменений
            платное.
          </p>
          <p>
            Авиакомпания может отказать в посадке, если данные не совпадут с
            документами.
          </p>
        </MobileDescription>
        {values.isCharter && <WarningText>
          <span style={{color:'red'}}>Внимание!</span> При бронировании чартерного перелета поле "Отчество" заполнять не нужно.
        </WarningText>}
        <PassengerList
          frequentFlyerAirlines={frequentFlyerAirlines}
          onChange={(data, index) => {}}
        />
      </Body>
    </Wrapper>
  );
};

export default Passengers;
