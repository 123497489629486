import React from 'react';
import styled from 'styled-components';
import {TextField, MenuItem, Box} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import { ReactComponent as CloseImg } from '../../../../../asset/images/cabinet/close.svg';
import { ReactComponent as InfoImg } from '../../../../../asset/images/cabinet/alert-circle.svg';
import useClaimsStore from '../../store';
import TouristsTableCancel from '../../Tourists/components/TouristsTableCancel';
import {format} from "date-fns";
import {isEmpty} from "lodash";

const PopupBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(67, 67, 67, 0.7);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const PopupContent = styled.div`
    position: relative;
    border-radius: 16px;
    padding: 32px;
    background-color: #fff;
    min-width: 828px;
    width: 828px;
    animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
`;

const PopupContentSmall = styled(PopupContent)`
    padding: 24px;
    min-width: 570px;
    width: 570px;
`;

const PopupTitle = styled.h2`
    font-family: Open Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
`;

const PopupSubtitle = styled(PopupTitle)`
    font-size: 24px;
    line-height: 32px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const RowWithMargin = styled(Row)`
    margin-bottom: 40px;
`;

const ButtonBlock = styled(Row)`
    gap: 16px;
`;

const ClaimInfo = styled(Row)`
    margin-top: 16px;
    justify-content: flex-start;
`;

const CloseBtn = styled(CloseImg)`
    width: 32px;
    height: 32px;
    fill: #2e2e32;
    cursor: pointer;
    &:hover {
        fill: #4872f2;
    }
`;
const ClaimNumber = styled.div`
    color: #2e2e32;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
`;

const GreenText = styled(ClaimNumber)`
    color: #28bf68;
    font-weight: 400;
    line-height: 26px;
`;

const Status = styled(ClaimNumber)`
    color: #2e2e32;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
`;

const Text = styled.div`
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`;
const P = styled(Status)`
    font-weight: 600;
    text-align: left;
`;

const Circle = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #2e2e32;
    margin: 0 8px;
`;
const AboutTourists = styled.div`
    padding: 24px 0;
    margin: 32px 0 0 0;
    border-top: 1px solid #dbe0e5;
    max-width: 764px;
`;

const FprBlock = styled.div`
    padding: 16px;
    margin: 0 0 32px 0;
    background-color: #FDECEC;
    border-radius: 8px;
    display: flex;
    align-items: center;
`;

const FprText = styled.div`
    padding-left: 8px;
`;

const MyButton = styled(Button)`
    background-color: #4872f2;
    padding: 16px 32px;
    border-radius: 8px;
    box-shadow: none;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
`;

const MyButtonOutlined = styled(MyButton)`
    background-color: #fff;
    color: #4872f2;
    border-color: #4872f2;
`;
const FormInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '8px',
        borderColor: '#D4D9DE',
    },
    '& .MuiInputBase-input': {
        background: 'white',
        textAlign: 'left',
        fontFamily: ['Open Sans', 'sans-serif', '"Segoe UI Symbol"'].join(','),
    },
}));

const CancelClaimModal = ({ onClose, claimId, preorder, setIsClaimCanceled }) => {
    const cancelReasons = useClaimsStore(({ cancelReasons }) => cancelReasons);
    const actions = useClaimsStore(({ actions }) => actions);
    const claim = useClaimsStore(({ claim }) => claim.find(item => item.id === claimId))

    const [reasonCancel, setReasonCancel] = React.useState('');
    const handleChangeCancelReason = (event) => {
        setReasonCancel(event.target.value);
    };

    const [modalVisibleNumber, setModalVisibleNumber] = React.useState(1);

    const handleConfirmeCancellation = () => {
        actions.cancelClaim(claimId, reasonCancel);
        setModalVisibleNumber(3);
        setIsClaimCanceled(true);
    };

    return (
        <>
            {modalVisibleNumber === 1 && (
                <PopupBackground>
                    <PopupContent>
                        <Row>
                            <PopupTitle>Запрос на отмену заявки</PopupTitle>
                            <CloseBtn onClick={() => onClose(false)} />
                        </Row>
                        <ClaimInfo>
                            <ClaimNumber>№{claimId}</ClaimNumber>
                            <Circle />
                            <GreenText>Доступна для оплаты</GreenText>
                            <Circle />
                            <Status>Не оплачена</Status>
                        </ClaimInfo>
                        <AboutTourists>
                            <P>Туристы</P>
                            <TouristsTableCancel id={claimId} />
                        </AboutTourists>
                        {!isEmpty(claim?.fprs) && <FprBlock>
                            <InfoImg/>
                            <FprText>
                                <Box mb={1}>Размер компенсации затрат туроператора при аннуляции для заявок с чартерной
                                    перевозкой:</Box>
                                {claim?.fprs.map(fpr =>
                                    <div>с {format(new Date(fpr.penaltyDay), 'dd.MM.yy')} составляет {fpr.penaltySum} {fpr.perc_or_Cur}</div>
                                )}
                            </FprText>
                        </FprBlock>}
                        <ButtonBlock>
                            <FormInput
                                SelectProps={{ IconComponent: ExpandMoreIcon }}
                                select
                                onChange={(event) =>
                                    handleChangeCancelReason(event)
                                }
                                fullWidth
                                value={reasonCancel}
                                variant="outlined"
                                label="Причина отмены"
                            >
                                {cancelReasons?.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </FormInput>
                            <MyButton
                                onClick={() => setModalVisibleNumber(2)}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!reasonCancel}
                            >
                                Отменить
                            </MyButton>
                        </ButtonBlock>
                    </PopupContent>
                </PopupBackground>
            )}

            {modalVisibleNumber === 2 && (
                <PopupBackground>
                    <PopupContentSmall>
                        <RowWithMargin>
                            <PopupSubtitle>
                                Запрос на отмену заявки
                            </PopupSubtitle>
                        </RowWithMargin>
                        <RowWithMargin>
                            <Text>Вы уверены что хотите отменить заявку?</Text>
                        </RowWithMargin>
                        <ButtonBlock>
                            <MyButtonOutlined
                                onClick={() => setModalVisibleNumber(1)}
                                fullWidth
                                variant="outlined"
                                color="primary"
                            >
                                Нет
                            </MyButtonOutlined>
                            <MyButton
                                onClick={() => handleConfirmeCancellation()}
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Да
                            </MyButton>
                        </ButtonBlock>
                    </PopupContentSmall>
                </PopupBackground>
            )}

            {modalVisibleNumber === 3 && (
                <PopupBackground onClick={() => onClose(false)}>
                    <PopupContentSmall>
                        <RowWithMargin>
                            <PopupSubtitle>
                                Запрос на отмену заявки
                            </PopupSubtitle>
                            <CloseBtn onClick={() => onClose(false)} />
                        </RowWithMargin>
                        <Row>
                            <Text>
                                {preorder
                                    ? `Предварительная заявка №${claimId} отменена`
                                    : `Ваш запрос на отмену заявки №${claimId} отправлен туроператору`}
                            </Text>
                        </Row>
                    </PopupContentSmall>
                </PopupBackground>
            )}
        </>
    );
};

export default CancelClaimModal;
