import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useGeoStore from './store'
import NotFound from '../NotFound'

import './style.css'
import { Breadcrumbs} from '@mui/material'
import Typography from '@mui/material/Typography'
import StaticBlocks from './StaticBlocks'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import FUN from "../../asset/favicon.ico"
import WithBanner from '../LandingPage/components/MainBanner/withBanner'
import ScrollTop from '../../components/ScrollTop'

const LandingPage = props => {
	const location = useLocation()
	const link = location.pathname.substring(1)
	const actions = useGeoStore(({ actions }) => actions)
	const content = useGeoStore(({ content }) => content)
	const isNotFound = useGeoStore(({ isNotFound }) => isNotFound)

	useEffect(() => {
		actions.getPage(link)
	}, [link, actions])

	useEffect(() => {
		return () => {
			actions.clearContent()
		}
		// eslint-disable-next-line
	}, [])

	if (isNotFound) {
		return <NotFound />
	}

	if (!content) return null

	return (
		<>
			<div className={'landing landing-container'}>
				<Helmet
					title={content?.meta_title || content?.title}
					link={[{ rel: 'icon', type: 'image/png', href: FUN }]}
				/>
				<Breadcrumbs
					aria-label='breadcrumb'
					className={'breadcrumb'}
					id='back-to-top-anchor'>
					<Link color='inherit' to={'/'}>
						Главная
					</Link>
					{content.breadcrumbs.map((item, index) => (
						<Typography key={index} style={{ color: '#4872f2' }}>
							{item.title}
						</Typography>
					))}
				</Breadcrumbs>
			</div>
			{(content.type !== 'static' && content.main_banner) && (
				<WithBanner
					content={JSON.parse(content.main_banner.content)}
					bannerLandingType={content.landingType}
				/>
			)}
			<div className={'landing landing-container'}>
				<StaticBlocks
					blocks={content.blocks.filter(block =>
						isMobile ? block.show_mobile : block.show_desktop
					)}
					countryId={content?.countryId}
				/>
			</div>
			<ScrollTop />
		</>
	)
}

export default LandingPage
