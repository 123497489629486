import React from 'react'
import './style.css'

export default function TextShadow({ content, title, id }) {
	return (
		<div id={id}>
			{title && <h3 className='v-center'>{title}</h3>}
			<div
				className='v-shadow-box'
				dangerouslySetInnerHTML={{
					__html: content?.text
				}}></div>
		</div>
	)
}
