import { useState } from 'react'

import './style.scss'
import Calendar from '../../../../asset/icons/ExcursionTour/Calendar'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import declination from "../../../../functions/declination";
import { isEmpty } from 'lodash';
import ResultCardModal from './ResultCardModal';

function ResultCard({
    otherParams,
    title,
    price,
    startDate,
    country,
    city,
    child,
    adults,
    item
}) {
    const [showModal, setShow] = useState(false)
    const description = otherParams?.descriptions?.at(1)
    // console.log(item)
    return <div className="result-card">
        {!item.image_url ?
            <div className="result-card__image-block">
                {otherParams?.images?.length > 0 &&
                    <div className="first-image img-bg" style={{backgroundImage: `url(${otherParams?.images[0]?.src})`}}/>}
                {otherParams?.images?.length > 1 &&
                    <div className="second-image img-bg" style={{backgroundImage: `url(${otherParams?.images[1]?.src})`}}/>}
                {otherParams?.images?.length > 2 &&
                    <div className="third-image img-bg" style={{backgroundImage: `url(${otherParams?.images[2]?.src})`}}/>}
                {otherParams?.images?.length && <div className="image-count">{otherParams?.images?.length} фото</div>}
            </div> :
            <div className="result-card__one-image-block">
                {item.image_url &&
                    <div className="first-image img-bg" style={{backgroundImage: `url(${item.image_url})`}}/>}
            </div>
        }
        <div className="result-card__info-block">
            <div>
                <h4 className='heading-4'>{title}</h4>
                <p className='text-l'>
                    {country?.name}, {city?.name}
                </p>
            </div>
            <div>
                <div className='result-card__filters'>
                    {otherParams?.activity?.map(i => <p key={i.name} className='text-l result-card__filters-item'>{i.name}</p>)}
                    {otherParams?.excursionType?.map(i => <p key={i.name} className='text-l result-card__filters-item'>{i.name}</p>)}
                    {otherParams?.forWhom?.map(i => <p key={i.name} className='text-l result-card__filters-item'>{i.name}</p>)}
                </div>
            </div>

            <div>
                <div style={{ display: 'flex', gap: '8px', marginBottom: '8px', alignItems: "center" }}><Calendar />
                    <p className='text-l' style={{ color: '#2E2E32' }}>
                        {format(new Date(startDate), 'dd MMMM yyyy', { locale: ru })}
                    </p>
                </div>
                {!item.description_tui
                    ? <p className='text-l text-overflow' dangerouslySetInnerHTML={{__html: description?.text}}></p>
                    : <p className='text-l text-overflow' dangerouslySetInnerHTML={{__html: item.description_tui}}></p>
                }
            </div>

            <div className='result-card__price'>
                <div>
                    <h4 className='heading-4'>от {Math.ceil(price?.value)} ₽</h4>
                    <p className='text-l'>За {adults} {declination(adults, ['взрослого', 'взрослых'])} {!isEmpty(child) && "・ " + child.length + " " + declination(Number(child.length), ['ребенка', 'детей'])}</p>
                </div>

                <div className="result-card__btn" onClick={() => setShow(!showModal)}>Подробнее</div>
            </div>
        </div>
        {showModal &&
            <ResultCardModal
                otherParams={otherParams}
                title={title}
                price={price}
                startDate={startDate}
                item={item}
                adults={adults}
                open={showModal}
                onClose={() => setShow(!showModal)}
            />}
    </div>
}

export default ResultCard