import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/Close'
import './style.css'
import { Grid, MenuItem } from '@material-ui/core'
import PhoneAddInput from './PhoneAddInput'
import useAuthStore from '../../../../Auth/store'
import { Box, FormControlLabel, TextField } from '@mui/material'
import BirthDateInput from '../../components/BirthDate'
import { Close, ExpandMore } from '@material-ui/icons'
import BpCheckbox from '../../components/Checkbox/CustomizedCheckbox'
import { emailRegExp } from '../../../../../constants/reg-exps'

export const FormInput = styled(TextField)(() => ({
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& fieldset': {
		borderRadius: '8px'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	}
}))

const SaveButton = styled(Button)({
	borderRadius: '8px',
	background: '#4872f2',
	color: '#ffffff',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: '8px 20px 8px 20px',
	border: '1px solid',
	height: '56px',
	lineHeight: 1.5,
	backgroundColor: '#4872f2',
	borderColor: '#4872f2',
	fontFamily: [
		'Open Sans',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
	'&:hover': {
		backgroundColor: '#4872f2',
		borderColor: '#4872f2'
	},
	'&:disabled': {
		backgroundColor: '#E7E7E7',
		borderColor: '#E7E7E7',
		color: '#B9B9B9'
	}
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '1068px'
		}
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(4)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(6),
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 0
	}
}))

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props

	return (
		<DialogTitle sx={{ m: 0, p: 4 }} {...other}>
			<Grid container>
				<Grid item align='end' xs={12}>
					<IconButton aria-label='close' onClick={onClose} size='small'>
						<CloseOutlinedIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item align='center' xs={12}>
					{children}
				</Grid>
			</Grid>
		</DialogTitle>
	)
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
}

export default function AddAgentPopup({ handleClose, openPopup }) {
	const [error, setError] = React.useState({
		phone: false,
		login: false,
		email: false
	})

	const [user, setUser] = React.useState({
		name: '',
		lastName: '',
		patronymic: '',
		townName: '',
		email: '',
		job: ''
	})

	const actions = useAuthStore(({ actions }) => actions)

	const [birthday, setBirthday] = React.useState('')

	const hadleChangeUser = event => {
		if (event.target.name === 'email') {
			if (!emailRegExp.test(event.target.value) && event.target.value) {
				setError(prev => ({ ...prev, email: 'Неверный формат почты' }))
				setUser(prev => ({ ...prev, [event.target.name]: event.target.value }))
			} else {
				setError(prev => ({ ...prev, email: false }))
				setUser(prev => ({ ...prev, [event.target.name]: event.target.value }))
			}
		}
		setUser(prev => ({ ...prev, [event.target.name]: event.target.value }))
	}

	const [access, setAccess] = React.useState('')
	const handleChange = event => {
		setAccess(event.target.value)
	}

	const [selectPhone, setSelectPhone] = React.useState('+7')
	const handleChangeSelectPhone = event => {
		setSelectPhone(event.target.value)
	}

	const [phone, setPhone] = React.useState('')
	const handleChangePhone = event => {
		if (/^[0-9]+[0-9]*$/.test(event.target.value) || !event.target.value) {
			setPhone(event.target.value)
			setError(prev => ({ ...prev, phone: false }))
		} else {
			setPhone(event.target.value)
			setError(prev => ({ ...prev, phone: 'Неверный формат телефона' }))
		}
	}
	const [gender, setGender] = React.useState('')

	const handleChangeGender = event => {
		setGender(event.target.value)
	}

	const [login, setLogin] = React.useState('')
	const hadleChangeLogin = event => {
		if (/^[^а-яё\s]*$/.test(event.target.value)) {
			setLogin(event.target.value)
			setError(prev => ({ ...prev, login: false }))
		} else {
			setLogin(event.target.value)
			setError(prev => ({ ...prev, login: 'Неверный формат логина' }))
		}
	}

	const [onlineAccess, setOnlineAccess] = React.useState(false)
	const handleChangeAccess = event => {
		setOnlineAccess(event.target.checked)
	}

	const saveHandler = () => {
		actions.addAgentUser({
			selectPhone,
			phone,
			user,
			gender,
			login,
			access,
			birthday,
			onlineAccess
		})
		handleClose()
	}
	return (
		<div>
			<BootstrapDialog
				PaperProps={{
					style: { borderRadius: 16 }
				}}
				fullWidth
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={openPopup}>
				<DialogTitle
					sx={{ m: 0, p: 4, pb: 0 }}
					className='add-agent-info-container'
					// style={{
					// 	display: 'flex',
					// 	justifyContent: 'space-between',
					// 	alignItems: 'center'
					// }}
					id='customized-dialog-title'>
					<div
						className='add-agent-info'
						// style={{
						// 	marginRight: '5px',
						// 	fontFamily: 'Open Sans',
						// 	fontWeight: 600,
						// 	fontSize: '24px'
						// }}
					>
						Добавление данных агента
					</div>
					<IconButton aria-label='close' onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>

				<Box>
					<DialogContent className='container_agents'>
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<FormInput
									value={user.lastName}
									onChange={hadleChangeUser}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Фамилия'
									name='lastName'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									onChange={hadleChangeUser}
									value={user.name}
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Имя'
									name='name'
								/>
								{/* </Box> */}
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									onChange={hadleChangeUser}
									value={user.patronymic}
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Отчество'
									name='patronymic'
								/>
							</Grid>

							<Grid item xs={4}>
								<BirthDateInput
									fullWidth
									onChange={setBirthday}
									label='Дата рождения'
									value={birthday || null}
									required
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									SelectProps={{ IconComponent: ExpandMore }}
									select
									onChange={handleChangeGender}
									fullWidth
									value={gender}
									variant='outlined'
									label='Пол'>
									<MenuItem value={'Мужской'}>Мужской</MenuItem>
									<MenuItem value={'Женский'}>Женский</MenuItem>
								</FormInput>
							</Grid>

							<Grid item xs={4}></Grid>

							<Grid item xs={4}>
								<FormInput
									onChange={hadleChangeUser}
									value={user.job}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Должность'
									name='job'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									onChange={hadleChangeUser}
									value={user.email}
									required
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Email'
									name='email'
									error={!!error.email}
									helperText={error.email}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									onChange={hadleChangeUser}
									value={user.townName}
									fullWidth
									variant='outlined'
									id='outlined-required'
									label='Город'
									name='townName'
								/>
							</Grid>
							<Grid item xs={4}>
								<PhoneAddInput
									selectPhone={selectPhone}
									handleChangeSelectPhone={handleChangeSelectPhone}
									phone={phone}
									handleChangePhone={handleChangePhone}
									error={error}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									required
									SelectProps={{ IconComponent: ExpandMore }}
									select
									onChange={handleChange}
									fullWidth
									value={access}
									variant='outlined'
									id='outlined-required'
									label='Доступ'>
									<MenuItem value={'Полный'}>Полный</MenuItem>
									<MenuItem value={'Частичный'}>Частичный</MenuItem>
								</FormInput>
								<FormControlLabel
									control={
										<BpCheckbox
											checked={onlineAccess}
											onChange={handleChangeAccess}
										/>
									}
									label='Разрешить доступ к личному кабинету'
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									onChange={hadleChangeLogin}
									required
									fullWidth
									value={login}
									variant='outlined'
									label='Логин'
									title='В логине допустим ввод только латинских букв, цифр, сиволов'
									error={!!error.login}
									helperText={error.login}
								/>
							</Grid>
						</Grid>
					</DialogContent>
				</Box>

				<DialogActions>
					<Grid container>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<SaveButton
								fullWidth
								onClick={saveHandler}
								disabled={
									!gender ||
									!access ||
									!phone ||
									!user.job ||
									!user.email ||
									!user.patronymic ||
									!user.name ||
									!user.lastName ||
									!login ||
									error.login ||
									error.phone
								}>
								Добавить
							</SaveButton>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>
				</DialogActions>
			</BootstrapDialog>
		</div>
	)
}
