export  const availabilityPlace = (value) => {
    let result
    switch (value) {
      case 'R':
        result = 'по запросу'
        break;
      case 'Y':
        result = 'есть '
        break;
      case 'N':
        result = 'нет'
        break;
      case 'F':
        result = 'мало'
        break; 
      default:
        result = ''
    }
    return result
  }
export  const availabilityPlaceColor = (value) => {
    let result
    switch (value) {
      case 'R':
        result = '#aaaaaa'
        break;
      case 'Y':
        result = '#b4ddac'
        break;
      case 'N':
        result = '#F1AFAF'
        break;
      case 'F':
        result = '#ffe874'
        break;
      default:
        result = ''
    }
    return result
  }

export  const chartOptions = {
    chart: {
      type: 'bar',
      zoom: {enabled: false},
      animations: {enabled: false},
  	  toolbar: { show: false}
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions:{
      bar: {
        columnWidth: '50%',
      }
    },
  }

export function formatPrice(current, first, currencyAlias = "RUB"){
  const val = (current - first).toFixed(0)

  if(val === "0"){
    return ''
  }

  return val > 0 ? '<span class="arrowUp">' +  val + " " +currencyAlias  + '  &#8593 </span>' : '<span class="arrowDown">' + val.replace('-','') + currencyAlias  +  ' &#8595 </span>'
}