import React from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { setAgentUsersRequest } from "../../../store/modules/agents/agent-users/duck"
import { TableUi } from "../TableUi"
import AgentUserRoleChange from "./AgentUserRoleChange"

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  width: 132px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #3c3c3c;
  cursor: pointer;
`

const AgentUsersItem = ({ agent }) => {
  const dispatch = useDispatch()

  const [changeUserRoleModalOpen, setIsChangeUserRoleOpen] = React.useState(
    false
  )
  const [chosenAgent, setChosenAgent] = React.useState("")

  return (
    <>
      {changeUserRoleModalOpen && (
        <AgentUserRoleChange
          changeUserRoleModalOpen={changeUserRoleModalOpen}
          setIsChangeUserRoleOpen={setIsChangeUserRoleOpen}
          chosenAgent={chosenAgent}
        />
      )}
      <TableUi.BaseTr key={agent.userId} style={{ cursor: "default" }}>
        <TableUi.BasicTd>{agent.name}</TableUi.BasicTd>
        <TableUi.BlueTd>{agent.email}</TableUi.BlueTd>
        <TableUi.BasicTd>{agent.phone}</TableUi.BasicTd>
        <TableUi.BasicTd
          onClick={() => {
            setIsChangeUserRoleOpen(true)
            setChosenAgent(agent?.userId)
          }}
        >
          {agent.role}{" "}
          <span style={{ color: "#4872F2", cursor: "pointer" }}>
            {" "}
            Изменить{" "}
          </span>
        </TableUi.BasicTd>
        <TableUi.BasicTd>{agent.login}</TableUi.BasicTd>
        <TableUi.BasicTd>{agent.partpassId}</TableUi.BasicTd>
        <TableUi.BasicTd>
          <Button
            onClick={() =>
              dispatch(
                setAgentUsersRequest({
                  userId: agent.samoId,
                  isActive: agent?.block
                })
              )
            }
          >
            {agent?.block === true ? "Разблокировать" : "Заблокировать"}
          </Button>
        </TableUi.BasicTd>
      </TableUi.BaseTr>
    </>
  )
}

export default AgentUsersItem
