import './style.scss'

export default function TextBackgroundImage({ content, title }) {
    const styleBg = {
        border: content?.borderColor ? `1px solid ${content?.borderColor}` : '',
        background: content?.background?.gradient
    }
    return <div className="textBackgroundImage">
        <h2>
            {title}
        </h2>
        <div className="textBackgroundImage__text" style={styleBg} >
            {content?.iconPosition?.includes('left') &&
                <div className={`textBackgroundImage__text__iconWrap ${content?.iconPosition}`}>{content?.image && <div style={{ backgroundImage: `url(${content?.image})` }}
                    className='textBackgroundImage__text__img' />}
                </div>}
            <div style={{ width: "99%" }} dangerouslySetInnerHTML={{ __html: content?.text }} />
            {content?.iconPosition?.includes('right') &&
                <div className={`textBackgroundImage__text__iconWrap ${content?.iconPosition}`}>{content?.image &&
                    <div style={{ backgroundImage: `url(${content?.image})` }} className='textBackgroundImage__text__img' />}
                </div>}
        </div>
    </div>
}