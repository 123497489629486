import React from "react"
import styled from "styled-components"
import { Text, Button, Checkbox } from "../../../ui"
import { refreshOrder } from "../../../../store/modules/orders"
import moment from "moment"
import { NavLink } from "react-router-dom"
import womanPasIcon from "./assets/media/womanPasIcon.svg"
import manPasIcon from "./assets/media/manPasIcon.svg"
import Segment from '../Segment'
import { Manager } from "../../../../store/modules/appeals"
import { useDispatch } from "react-redux"
import ConfirmModal from "./ConfirmCancelation"

const Wrapper = styled.div`
  padding: 24px 24px 20px 24px;
  background: #ffe2e3;
  border: 1px solid #dcdcdc;
  color: #DCDCDC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 14px;
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 12px;
  @media (min-width: 768px) {
    display: none;
  }
`

const SubTitle = styled(Text)`
  display: block;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  & > br {
    display: none;
  }

  & > a {
    font-weight: 600;
    color: #4872f2;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;

    & > br {
      display: initial;
    }
  }
`

const SegmentBasePart = styled.div`
  display: flex;
  align-items: flex-start;

  & > :first-child {
    margin-right: 11px;
  }

  @media (max-width: 767px) {
    & > :first-child {
      margin-right: 5px;
    }
  }
`



const SegmentHead = styled.div`
  width: 100%;

  & > :first-child {
    display: block;
    margin-bottom: 8px;
  }
`

const SegmentBigText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`

const PositionName = styled(SegmentBigText)`
  display: block;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`

const SegmentText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`

const Actions = styled.div`
  margin-top: 25px;
  & > button {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    & > button {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`

const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 20px;
`

const OldSegmentsWrapper = styled.div`
  background: #f2f2f2;
  border: 1px solid #dcdcdc;
  border-color: #dcdcdc;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;

  & > div {
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    border-bottom: none;
  }

  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    padding: 15px;

    & > :first-child {
      margin-bottom: 0;
    }

    & > :last-child {
      padding-bottom: 0;
    }
  }
`

const NewSegmentsWrapper = styled(OldSegmentsWrapper)`
  background: #edf1fe;
  border-color: #4872f2;

  & > :first-child {
    color: #4872f2;
  }
`

const SegmentsWrapperList = styled.div`
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    & > div {
      margin-bottom: 15px;
    }
  }
`

const ConfirmRules = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? "color: red;" : "")}
  }
  @media (max-width: 767px) {
    align-items: flex-start;

    & > ${Text} {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
`

const NewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;

  @media (max-width: 789px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 790px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const WomanPasIcon = styled.img.attrs({ src: womanPasIcon })`
  width: 34px;
  height: 34px;
`

const ManPasIcon = styled.img.attrs({ src: manPasIcon })`
  width: 34px;
  height: 34px;
`

export default function Notification(props) {

  const dispatch = useDispatch()
  const [isConfirming, setConfirming] = React.useState(false)
  const [isCanceling, setCanceling] = React.useState(false)
  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: false,
    acceptError: undefined
  })
  const [isModal, setModal] = React.useState(false)

  const toTime = date => moment(date).format("DD.MM.YYYY")
  const isChangeSegment = props.type === 'ChangeSegmentDates';

  return (
    <>
      <Wrapper>
        <Title>
          {isChangeSegment ? 'Внимание! Изменение полетных данных' : 'Внимание! Изменения данных пассажиров'}
        </Title>
        <MobileTitle>
          {isChangeSegment ? 'Внимание, изменение рейсов!' : 'Внимание, изменение пассажиров'}
        </MobileTitle>
        <SubTitle>
          Уважаемый клиент, с вашими{isChangeSegment ? " рейсами " : " пассажирами "}
          произошли изменения, требующие вашего согласования.Для дополнительной информации обратитесь в чат к
          операционисту по{" "}
          <NavLink  to={props.no} >
            обращению № {props.supportTicketNumber}
          </NavLink>
        </SubTitle>
        {props.type !== "ChangesPassengers" && props.prevSegments.length > 0 &&(
          <PositionName>
            Позиция заявки: {props.route}
          </PositionName>
        )}

        <SegmentsWrapperList>
          {props.type !== "ChangesPassengers" && props.prevSegments.length > 0 && (
            <OldSegmentsWrapper>
              <SegmentBigText>Старые данные:</SegmentBigText>
              {(props.prevSegments).map((x,key) => (<Segment {...x} key={key}/>) )}
            </OldSegmentsWrapper>
          )}

          {props.type === "ChangesPassengers" && (
            <OldSegmentsWrapper>
              <SegmentBigText>Старые данные:</SegmentBigText>
              {(props.passengersEvents || []).map(
                (x, key) => {
                  return (
                    <Segment key={key}>
                      <SegmentBasePart>
                        {x.fields.find(y => y.description === "Пол")
                          .oldValue === "Male" ? (
                          <ManPasIcon />
                        ) : (
                          <WomanPasIcon />
                        )}
                        <SegmentHead>
                          <SegmentBigText>
                            {x.passengerDataFields.map(pasDataField =>
                              pasDataField.description === "Номер билета"
                                ? "билет " + pasDataField.oldValue + " "
                                : pasDataField.oldValue + " "
                            )}
                          </SegmentBigText>
                          <NewGrid>
                            {x.fields.map((field, i) => (
                              <>
                                <SegmentText>
                                  <span
                                    style={{
                                      color: "#737373"
                                    }}
                                  >
                                    {field.description}:&nbsp;
                                  </span>
                                  {field.type === "datetime"
                                    ? toTime(field.oldValue)
                                    : field.oldValue}
                                  &nbsp;
                                </SegmentText>
                              </>
                            ))}
                          </NewGrid>
                        </SegmentHead>
                      </SegmentBasePart>
                    </Segment>
                  )
                }
              )}
            </OldSegmentsWrapper>
          )}

          {props.type !== "ChangesPassengers" && (
            <NewSegmentsWrapper>
              <SegmentBigText>Новые данные:</SegmentBigText>
              {(props.newSegments).map((x,key) => (<Segment {...x} key={key}/>) )}
            </NewSegmentsWrapper>
          )}
          {props.type === "ChangesPassengers" && (
            <NewSegmentsWrapper>
              <SegmentBigText>Новые данные:</SegmentBigText>
              {(props.passengersEvents || []).map(
                (x, key) => {
                  return (
                    <Segment key={key}>
                      <SegmentBasePart>
                        {x.fields.find(y => y.description === "Пол")
                          .oldValue === "Male" ? (
                          <ManPasIcon />
                        ) : (
                          <WomanPasIcon />
                        )}
                        <SegmentHead>
                          <SegmentBigText>
                            {x.passengerDataFields.map(pasDataField =>
                              pasDataField.description === "Номер билета"
                                ? "билет " + pasDataField.newValue + " "
                                : pasDataField.newValue + " "
                            )}
                          </SegmentBigText>
                          <NewGrid>
                            {x.fields.map((field, i) => (
                              <>
                                <SegmentText>
                                  <span
                                    style={{
                                      color: "#737373"
                                    }}
                                  >
                                    {field.description}:&nbsp;
                                  </span>
                                  {field.type === "datetime"
                                    ? toTime(field.newValue)
                                    : field.newValue}
                                  &nbsp;
                                </SegmentText>
                              </>
                            ))}
                          </NewGrid>
                        </SegmentHead>
                      </SegmentBasePart>
                    </Segment>
                  )
                }
              )}
            </NewSegmentsWrapper>
          )}
        </SegmentsWrapperList>

        {props.type !== "ChangesPassengers" && (
          <ConfirmRules error={acceptError}>
            <Checkbox
              type="squar"
              error={acceptError}
              checked={isRulesAccepted}
              onChange={e => {
                setRulesState(state => ({
                  acceptError: undefined,
                  isRulesAccepted: !state.isRulesAccepted
                }))
              }}
            />
            <Text>
              Я ознакомлен и согласен с условиями{" "}
              <a
                style={{ color: "#4872F2", textDecoration: "none" }}
                href="/docs/oferta.pdf"
                target="_blank"
              >
                Обмена и возврата билета
              </a>
            </Text>
          </ConfirmRules>
        )}
        {props.type !== "ChangesPassengers" && (
          <Actions>
            <StyledButton
              isLoading={isConfirming}
              onClick={() => {
                if (isRulesAccepted) {
                  setConfirming(true)
                  Manager.applySupportTicketCalculation(
                    props.calculationId
                  )
                    .then(() => {
                      setConfirming(false)
                      dispatch(refreshOrder())
                    })
                    .catch(() => {
                      setConfirming(false)
                      dispatch(refreshOrder())
                    })
                } else {
                  setRulesState(state => ({
                    ...state,
                    acceptError: "ошибочка"
                  }))
                }
              }}
            >
              Подтвердить изменения
            </StyledButton>
            <StyledButton
              isLoading={isCanceling}
              onClick={() => {
                setModal(true)
              }}
            >
              Отклонить изменения
            </StyledButton>
          </Actions>
        )}

        <ConfirmModal
          open={isModal}
          onClose={() => setModal(false)}
          onConfirm={() => {
            setCanceling(true)
            setModal(false)
            Manager.declineSupportTicketCalculation(
              props.calculationId
            )
              .then(() => {
                setCanceling(false)
                dispatch(refreshOrder())
              })
              .catch(() => {
                setCanceling(false)
                dispatch(refreshOrder())
              })
          }}
        />
      </Wrapper>
    </>
  )
}

function transformData(data, valueType) {
  const departureDate = data.fields.find(
    x => x.description === "Дата отправления"
  )
  const arrivalDate = data.fields.find(x => x.description === "Дата прибытия")
  const company = data.fields.find(
    x => x.description === "Маркетинговый перевозчик"
  )
  const flightNumber = data.fields.find(x => x.description === "Номер рейса")
  return {
    departureDate: departureDate
      ? moment(departureDate[valueType]).format("DD MMM HH:mm")
      : null,
    arrivalDate: arrivalDate
      ? moment(arrivalDate[valueType]).format("DD MMM HH:mm")
      : null,
    company: company ? company[valueType] : null,
    flightNumber: flightNumber ? flightNumber[valueType] : null
  }
}
