import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FAFAFA',
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#3C3C3C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      padding: '17px',
      verticalAlign: 'top',
    },
}));

const ActionTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      paddingLeft: '8px',
      paddingTop: '9px',
      verticalAlign: 'top',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({

}));



export default function CustomizedTables(props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Действия</StyledTableCell>
            <StyledTableCell>Дата создания</StyledTableCell>
            <StyledTableCell>Сбор</StyledTableCell>
            <StyledTableCell>Ответственный</StyledTableCell>
            <StyledTableCell>Параметры правила</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((x,key) => (
            <StyledTableRow key={key}>
              <ActionTableCell component="th" sx={{width: 100}}>
                <IconButton onClick={() => {props.onDelete(x)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                      <path d="M1 1L16 16" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 1L1 16" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </IconButton>
                <IconButton onClick={() => {props.onEdit(x)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                      <path d="M13.0202 0.646447C12.9265 0.552678 12.7993 0.5 12.6667 0.5C12.5341 0.5 12.4069 0.552678 12.3131 0.646447L0.646447 12.3131C0.552678 12.4069 0.5 12.5341 0.5 12.6667V16C0.5 16.2761 0.723858 16.5 1 16.5H4.33333C4.46594 16.5 4.59312 16.4473 4.68689 16.3536L16.3536 4.68689C16.5488 4.49162 16.5488 4.17504 16.3536 3.97978L13.0202 0.646447Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.834 5.1665L4.33398 12.6665" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.166 3.56299L13.4993 6.83349" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </IconButton>
              </ActionTableCell>
              <StyledTableCell sx={{width: 144}}>{formatDate(x?.createdDate,"DD.MM.YYYY HH:mm")}</StyledTableCell>
              <StyledTableCell sx={{width: 154}}>{formatPriceRule(!!x?.passengerTypesString ? x?.passengerTypesString : '')}</StyledTableCell>
              <StyledTableCell sx={{width: 194}}>{x?.userLogin || "отсутствует"}</StyledTableCell>
              <StyledTableCell>
              {x?.amount && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Размер сбора:</span>{" "}
                    {x?.amount} <br />
                  </>
                )}
                {x?.calculateType && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Тип сбора:</span>{" "}
                    {x?.calculateType === "rub" ? "рубли" : "проценты"} <br />
                  </>
                )}
                {x?.flightType && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Тип перелета:</span>{" "}
                    {x?.flightType === "all"
                      ? "Любой"
                      : x?.flightType === "inner"
                      ? "Внутренний"
                      : "Международный"}{" "}
                    <br />
                  </>
                )}
                {x?.routeType && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Тип маршрута:</span>{" "}
                    {x?.routeType} <br />
                  </>
                )}
                {x?.saleStart && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Начало продажи:</span>{" "}
                    {formatDate(x?.saleStart,"DD.MM YYYY")} <br />
                  </>
                )}
                {x?.saleEnd && (
                  <>
                    <span style={{ fontWeight: "bold" }}> Конец продажи:</span>{" "}
                    {formatDate(x?.saleEnd,"DD.MM YYYY")} <br />
                  </>
                )}
                {(x?.departure || x?.arrival) && (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      Города вылета/прилета:
                    </span>{" "}<br/>
                    {(x?.departure || 'Не задано') + "/" + (x?.arrival || 'Не задано')} <br />
                  </>
                )}
                {x?.flightClass && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Класс полета:</span>{" "}
                    {x?.flightClass === "e"
                      ? "Эконом"
                      : x?.flightClass === "b"
                      ? "Бизнес"
                      : x?.flightClass === "f"
                      ? "Первый"
                      : "Любой"}{" "}
                    <br />
                  </>
                )}
                {x?.applyTo && (
                  <>
                    <span style={{ fontWeight: "bold" }}>Применить к:</span>{" "}
                    {x?.applyTo === "order"
                      ? "заявке"
                      : x?.applyTo === "passanger"
                      ? "пассажиру"
                      : x?.applyTo === "segment"
                      ? "сегменту"
                      : "пассажирам и сегментам"}{" "}
                    <br />
                  </>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function formatPriceRule(str) {
  return str.replaceAll(/\*|\+|\-/g,(value)=>{
    return ` ${value} `;
  })
}

function formatDate(value,format) {
  if(!value) {
    return 'отсутствует';
  }
  return moment(value).format(format);
}