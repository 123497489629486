export function userNotify(body) {
  if (document.hidden) {
    if (window.Notification) {
      Notification.requestPermission().then(result => {
        if (result === "granted") {
          new Notification("Новое сообщение", {
            body
          })
        }
      })
    } else {
      const sound = new Audio("/sounds/message.mp3")
      sound.play()
    }
  } else {
    const sound = new Audio("/sounds/message.mp3")
    sound.play()
  }
}
