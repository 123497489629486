import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { TextField } from '@mui/material';
import { ReactComponent as CloseImg } from '../../../../asset/images/cabinet/close.svg';
import { ReactComponent as Ruble } from '../../../../asset/images/cabinet/Ruble.svg';
import useClaimsStore from '../store'

const PopupBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(67, 67, 67, 0.7);
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const FormInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '8px',
        borderColor: 'rgba(212, 217, 222, 1)',
    },
    '& .MuiInputBase-input': {
        background: 'white',
        fontFamily: "'Open Sans', 'sans-serif'",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)'
    },
    '& label': {
        fontFamily: "'Open Sans', 'sans-serif'",
        color: '#7E8389'
    },
}));

const PopupContent = styled.div`
    position: relative;
    border-radius: 16px;
    padding: 32px;
    background-color: #fff;
    width: 530px;
    animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;
    max-height: 100vh;
    overflow-y: auto;
`;

const PopupTitle = styled.h2`
	color: #2E2E32;
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`;

const CloseBtn = styled(CloseImg)`
    width: 32px;
    height: 32px;
    fill: #2e2e32;
    cursor: pointer;
    &:hover {
        fill: #4872f2;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const InputRow = styled(Row)`
    gap: 16px;
    align-items: center;
`

const Text = styled.p`
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
`

const PriceText = styled(Text)`
    font-weight: 600;
`

const ReceiptTitle = styled(Text)`
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
`
const CenterText = styled(ReceiptTitle)`
    text-align: center;
`

const ContentRow = styled.div`
    display: flex;
	flex-direction: column;
    gap: 24px;
`

const ButtonFilled = styled(Button)`
    background-color: #4872f2;
    padding: 16px 32px;
    border-radius: 8px;
    box-shadow: none;
	min-width: fit-content;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: none;
`;


function PaymentProcessingModal({ onClose, claimId, onClosePayments}) {
    const payment = useClaimsStore(({ payments }) => payments).find(item => item.claimId === claimId)
    const actions = useClaimsStore(({ actions }) => actions)

    const priceWithCommission = (payment?.invoiceDebt + payment?.invoiceDebt * 0.011).toFixed(2)
    const paid = payment?.invoiceAmount - payment?.invoiceDebt
    const minAmounth = paid === 0 ? Number(((priceWithCommission)/100)?.toFixed()) : 1

    const [amount, setAmount] = useState(payment?.invoiceDebt ? priceWithCommission : 0 )

    const handleChangeInput = (event) => {
        const inputValue = event.target.value.replace(/[^0-9.,]/g, '');
        setAmount(inputValue)
    }

    const handleBlurInput = () => {
        setAmount(amount.replace(/,/g, ".").replace(/\b0+(?!\b)/, ""))
    }

    const handlePay = () => {
        actions.paymentHold(claimId, amount).then(() => {
            onClose(false)
            onClosePayments(false)
        })
    }

    return <PopupBackground>
        <PopupContent>
            <Row>
                <PopupTitle>Оформление платежа</PopupTitle>
                <CloseBtn onClick={() => onClose(false)} />
            </Row>    
            <ContentRow>
                <ReceiptTitle>
                    Информация о платеже
                </ReceiptTitle>
                <InputRow>
                    <ReceiptTitle>Оплачено:</ReceiptTitle>
                    <PriceText>{paid}{' '}{payment?.invoiceCurrencyAlias}</PriceText>
                </InputRow>
                <InputRow>
                    <ReceiptTitle>Сумма для оплаты:</ReceiptTitle>
                    <PriceText>{`${priceWithCommission} ${payment?.invoiceCurrencyAlias}`}</PriceText>
                </InputRow>
                <InputRow> 
                    <FormInput
                        fullWidth
                        value={amount}
                        label='Оплатить'
                        onChange={handleChangeInput}
                        onBlur={handleBlurInput}
                        // required
                        InputProps={{
                            endAdornment: <Ruble />
                        }}
                    />
                </InputRow>
                <CenterText>В том числе комиссия 1%</CenterText>
                <CenterText>{`Минимальная сумма платежа ${minAmounth} ${payment?.invoiceCurrencyAlias}`}</CenterText>
                <InputRow style={{ justifyContent: "center" }}>
                    <ButtonFilled
                        onClick={handlePay}
                        variant="contained"
                        color="primary"
                        disabled={Number(amount) < minAmounth || Number(amount) > priceWithCommission}
                    >Оплатить</ButtonFilled>
                </InputRow>
            </ContentRow>
        </PopupContent>
    </PopupBackground>
}

export default PaymentProcessingModal