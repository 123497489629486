import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoSvg } from '../../asset/images/avia/shared/exclamatory.svg';
import Text from './Typography';
import Tippy from '@tippyjs/react';


const PopupWrapper = styled.div`
  background: ${({ background }) => background};
  border-radius: 8px;
  padding: 6px 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
  color: #000000;

  & > svg {
    margin-right: 3px;
    height: 16px;
    width: 16px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    padding: 5px 6px;
  }
`;

const PopupTextContainer = styled.div`
  & > * {
    margin-bottom: 7px;
    display: block;
  }

  & > :first-child {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const NotyContainer = styled.div`
  padding: 14px 24px;
  background: #edfaf9;
  border: 1px solid #8ac5c0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const NotyTextBlock = styled.div`
  margin-top: 15px;

  &:first-child {
    margin-top: initial;
  }
`;

export const LowcosterPopup = () => {
  return (
    <Tippy
      maxWidth={181}
      theme="light"
      content={
        'Перевозку совершает авиакомпания-лоукостер. Выписка билетов может занять до 6 часов.'
      }
    >
      <PopupWrapper background={'#EDFAF9'}>
        <InfoSvg />
        <PopupTextContainer>
          <Text size={'small'} fontWeight={'600'}>
            Лоукостер
          </Text>
        </PopupTextContainer>
      </PopupWrapper>
    </Tippy>
  );
};

export const LowcosterShortNotification = () => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Обращаем Ваше внимание, рейс совершает авиакомпания-лоукостер. Выписка
          билетов может занять до 6 часов.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};

export const LowcosterNotification = () => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Обращаем ваше внимание, рейс лоукостер!<br/><br/>
          После оплаты в течение 6 часов вы получите маршрутную квитанцию, в которой номер заявки является и PNR, и номером билета.<br/>
          Если в течение 6 часов, по каким-либо причинам, авиакомпания всё ещё не подтвердит бронирование, мы Вас об этом проинформируем.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};
