import { Box, styled, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'

export const MenuTabs = styled(Tabs)({
	borderRight: '1px solid #DBE0E5',
	minWidth: '270px',
	'& .MuiTabs-indicator': {
		backgroundColor: '#3C3C3C',
		display: 'none'
	},
	'& .MuiTab-wrapper': {
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'center',
		marginLeft: '16px',
		color: '#B3B9C0'
	},
	'& .MuiTab-labelIcon': {
		minHeight: 56
	},
	'.MuiTab-wrapper:first-child': {}
})

export const MenuTabCustom = styled(props => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		minWidth: '270px',
		// [theme.breakpoints.up('sm')]: {
		// 	minWidth: 0
		// },
		fontWeight: 600,
		fontSize: 16,
		lineHeight: '24px',
		padding: '0px',
		height: '24px',
		color: '#2E2E32',
		fontFamily: ['Open Sans', 'sans-serif'].join(','),
		'&:hover': {
			color: '#2E2E32',
			backgroundColor: '#EDF1FE',
			opacity: 1
		},
		'&.Mui-selected': {
			color: '#2E2E32',
			backgroundColor: '#EDF1FE',
			fontWeight: 600
		},
		'&.Mui-selected .MuiTab-wrapper': {
			color: '#2E2E32',
		},
		'&.Mui-focusVisible': {
			backgroundColor: '#4872F2'
		}
	})
)

export function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			style={{ width: '100%' }}
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ width: '100%' }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

export function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	}
}
