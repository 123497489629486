import React from 'react';

import './style.scss'
import {Button} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import ErrorIcon from '../../../asset/icons/error.svg'

const ErrorModal = ({title, onClose, errorAccess}) => {

	return (
		<div className="popup-bg">
			<div className="popup-block">
				<div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}>
					<CloseOutlined onClick={onClose} />
				</div>
				{!errorAccess && <div className="popup-image">
					<img src={ErrorIcon} alt=""/>
				</div>}
				<h2 className="popup-name">
					{title}
				</h2>
				<p className={`popup-text`}>
					{errorAccess ? 'Обратитесь к Вашему аккаунт-менеджеру' : 'Повторите попытку позднее'}
				</p>
				<Button variant={'contained'} fullWidth className="popup-btn close" onClick={onClose}>Понятно</Button>
			</div>
		</div>
	);
};

export default ErrorModal;