import React from 'react'

import { isEmpty } from 'lodash'
import useHotelDescriptionStore from '../../../store'
import RoomCardCopy from './RoomCard'

const RoomsCopy = () => {
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const amenities = useHotelDescriptionStore(({ amenities }) => amenities)

	if (isEmpty(hotel)) return

	return (
		<div>
			{hotel?.roomTypes
				.filter(item => item.status === 'Actual')
				.slice(0, 2)
				.map(room => (
					<RoomCardCopy
						hotelId={hotel.id}
						objectType={hotel.objectType}
						key={room.samoId}
						room={room}
						amenities={amenities}
						images={hotel.images.filter(item =>
							room.images.find(image => image.id === item.id)
						)}
					/>
				))}
		</div>
	)
}

export default RoomsCopy
