import React from 'react';
import styled from 'styled-components';
import { Text } from '../ui';
import OutlinedButton from '../ui/OutlinedButton'
import Dialog from '@mui/material/Dialog';

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
`;

const ActionsBlock = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }
`;

const Modal = ({ onConfirm, ...props }) => {
  return (
    <StyledPopup {...props}>
      <Title>Отмена обращения</Title>
      <Question>
        Вы уверены, что хотите отменить создание обращения по данной заявке?
      </Question>
      <ActionsBlock>
        <OutlinedButton onClick={onConfirm}>
          Да, отменить
        </OutlinedButton>
        <OutlinedButton onClick={props.onClose}>
          Нет, вернуться к обращению
        </OutlinedButton>
      </ActionsBlock>
    </StyledPopup>
  );
};

export default Modal;
