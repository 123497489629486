import React from "react"
import styled from "styled-components"
import { Text,Button} from "../../ui"
import {ReactComponent as Icon} from "../../../asset/images/avia/appeal-forms/check.svg"
import { useSelector } from "react-redux"
import {getCalculationModalsState} from '../../../store/modules/appeals';
import Dialog from '@mui/material/Dialog';
import OutlinedButton from '../../ui/OutlinedButton'
import PrimaryButton from "../../ui/PrimaryButton"
import {styled as MUIstyled} from '@mui/material/styles'


const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    z-index: 1001;
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`

const Title = styled(Text)`
  color:  #3C3C3C;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;

  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
`

const Question = styled(Text)`
  color: #3C3C3C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;

  .blue {
    color: #4872F2
  }
`

const ButtonBlock = styled.div`
  display: flex;
  margin-top: 20px;

  & > :first-child {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

const CloseButton = MUIstyled(OutlinedButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20px',
}))

const PayButton = MUIstyled(PrimaryButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20px',
}))


export default function ApplySuccessModal({ onConfirm, ...props }) {
    const {data} = useSelector(getCalculationModalsState)
    const isExtraPayment = (data?.type || props.type) === 'payment';


    return (
    <StyledPopup {...props}>
        <Title><span>Расчет одобрен</span> <Icon/> </Title>
        <Question>
            <span>Вами одобрен расчет на  {isExtraPayment ? 'доплату' : 'возврат'} денежных средств.</span>{' '}   
            {isExtraPayment && (<span>Нажмите кнопку <span className="blue">“Оплатить счет”</span> для завершения оплаты.</span>)} 
            {!isExtraPayment && <span>Денежные средства поступят на ваш счет в течении 3х рабочих дней.</span>} 
        </Question>
        <ButtonBlock>
          {isExtraPayment && (
            <PayButton type="button"  onClick={() => props.onPay()}>
                Оплатить счет
            </PayButton>
          )}
          <CloseButton
            type="button"
            onClick={() => props.onClose()}
          >
            {props.closeButtonText}
          </CloseButton>
        </ButtonBlock>
    </StyledPopup>
    )
}
