import { createSelector } from "reselect"
import { STATUS } from "./constants"
import _ from "lodash"
export const isOrdersLoading = state =>
  state.orders.status === STATUS.loading ||
  state.orders.status === null ||
  state.orders.status === STATUS.failure
export const getDetailOrder = state => state.orders.orderDetail.data

const getCalculationData = state => state.orders.orderDetail.calculation

const getCalculationStatus = state =>
  state.orders.orderDetail.calculationLoadStatus

export const getAddictionalServicesState = state =>
  state.orders.orderDetail.addictionalServices

export const getAddictionalServicesStateNew = state =>
  state.orders.orderDetail.data.additionalServices

export const orderListStateSelector = state => state.orders.orderList

export const orderListSelector = state => state.orders.orderList.orders

export const getPrintedOrder = state => state.orders.orderDetail.printedOrder

export const getAgentCommissionVisibility = state => state.orders.orderDetail.agentCommissionVisibility

export const getCalculationSelector = createSelector(
  [getCalculationData, getCalculationStatus],
  (value, status) => ({ value, status })
)

export const getDataForAddTicketBody = createSelector(
  getDetailOrder,
  ({ id, customerId, orderPositions }) => ({
    id,
    customerId,
    passengers: orderPositions[0].passengers
  })
)

export const getAddictionalServices = createSelector(
  [getDetailOrder, getAddictionalServicesState],
  (detailOrder, addictionalServices) => ({
    canShowExtraServices: detailOrder.availableActions.includes(
      "DISPLAY_ADDITIONAL_SERVICES"
    ),
    items: addictionalServices
  })
)

export const getAddictionalServicesNew = createSelector(
  [getDetailOrder, getAddictionalServicesStateNew],
  (detailOrder, addictionalServicesNew) => ({
    canShowExtraServices: detailOrder.availableActions.includes(
      "DISPLAY_ADDITIONAL_SERVICES"
    ),
    items: addictionalServicesNew
  })
)

export const getPosition = (state, index) => {
  return state.orderPositions[index]
}

export const getMainPosition = createSelector(
  getDetailOrder,
  ({ orderPositions, ...state }) => {
    return { ...state, ...orderPositions[0] }
  }
)

export const getSegmentGroups = createSelector(
  getMainPosition,
  ({ segmentGroups }) => {
    return segmentGroups
  }
)

export const getOrderStatus = createSelector(
  getDetailOrder,
  ({ orderStatus }) => orderStatus
)

export const getPaymentStatus = createSelector(
  getDetailOrder,
  state => state.paymentStatus
)

export const getOrderId = createSelector(getDetailOrder, ({ id }) => id)

export const getFlightsInfo = (state, mutateTransferCount) => ({
  ...state,
  transferCount: (state.segmentGroups || []).reduce(
    (acc, x) =>
      acc + x.segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
    0
  ),
  luggages: (state.tariff?.fareFamilies || []).reduce(
    (acc, x) => ({
      ...acc,
      [`${x.departureAirportCode}-${x.arrivalAirportCode}`]: "" //x.featuresDescriptionRus
    }),
    {}
  ),
  segmentGroups: (state.segmentGroups || []).map(
    ({ segments, ...flight }, key) => {
      const stopOvers = []
      segments.forEach(x => {
        x.stopOvers.forEach(y => {
          stopOvers.push({ duration: y.duration, city: x.arrivalCity.code })
        })
      })
      return {
        ...flight,
        flight_Type: state.flight_Type,
        segments,
        transferCount: segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
        stopOvers,
        flightsCount: state.segmentGroups.length,
        index: key
      }
    }
  ),
  airlines: getAirlinesList2(state.segmentGroups)
})

function getAirlinesList2(segmentGroupList) {
  const airlines = (segmentGroupList || []).reduce(
    (acc, x) => [
      ...acc,
      ...x.segments.map(segment => ({
        ...segment.operatingAirline,
        logoUrl: segment.logoUrl
      }))
    ],
    []
  )
  return _.unionBy(airlines, "code")
}

export const getAirlinesList = createSelector(
  getSegmentGroups,
  segmentGroups => {
    const airlines = (segmentGroups || []).reduce(
      (acc, x) => [
        ...acc,
        ...x.segments.map(segment => ({
          ...segment.operatingAirline,
          logoUrl: segment.logoUrl
        }))
      ],
      []
    )
    return _.unionBy(airlines, "code")
  }
)

export const getInfoBlockData = createSelector(
  [getDetailOrder, getSegmentGroups, getAirlinesList, getMainPosition],
  (state, segmentGroups, airlines, mainPosition) => ({
    orderId: state.id,
    orderNumber: state.orderNumber,
    createdDate: state.createdDate,
    orderStatus: state.orderStatus,
    amount: state.amount,
    amountExtraPayment: state.amountExtraPayment,
    luggages: (mainPosition.tariff?.fareFamilies || []).reduce(
      (acc, x) => ({
        ...acc,
        [`${x.departureAirportCode}-${x.arrivalAirportCode}`]: "" //x.featuresDescriptionRus
      }),
      {}
    ),
    bookingNumber: state.orderPositions[0]
      ? state.orderPositions[0].bookingCode
      : "",
    paymentStatus: state.paymentStatus,
    transferCount: (segmentGroups || []).reduce(
      (acc, x) =>
        acc + x.segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
      0
    ),
    segmentGroups: (segmentGroups || []).map(({ segments, ...flight }) => {
      return {
        ...flight,
        segments,
        transferCount: (segments || []).reduce(
          (acc, x) => acc + x.stopOvers.length,
          0
        )
      }
    }),
    airlines: airlines,
    expiredTime: state.cancellationTime,
    availableActions: state.availableActions,
    notifications: state.notifications,
    invoice: state.invoice.total,
    availableShipmentAllowed: state.availableShipmentAllowed
  })
)

export const getTariffInfo = createSelector(
  getDetailOrder,
  state =>
    state.orderPositions[0]?.tariff.fareFamilies &&
    state.orderPositions[0].tariff.fareFamilies[0]
)

export const getDetailOrderContactInfo = createSelector(
  getDetailOrder,
  state => state.contactsInfo
)

export const getRoutes = state => {
  return state.segmentGroups.map(value => ({
    name: `${value.departureCity?.code} - ${value.arrivalCity?.code}`,
    description: state.tariff.description
  }))
}

export const getTotalTransferCount = state => {
  return state.segmentGroups.reduce((acc, { segments }) => {
    return acc + segments.reduce((acc, x) => acc + x.stopOvers.length, 0)
  }, 0)
}

export const ancillaryServicesSelector = createSelector(
  [getDetailOrder],
  state => {
    const meal = state.orderPositions
      .map(x => {
        const passengers = x.passengers
          .map(x => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? " " + x.patronymic : ""
            }`
            const items = x.meals.items
            return { name, items }
          })
          .filter(x => x.items.length > 0)
        const positionName = createFromToString(x.segmentGroups)
        return { positionName, passengers }
      })
      .filter(x => !!x?.passengers?.length)
    const luggage = state.orderPositions
      .map(x => {
        const passengers = x.passengers
          .map(x => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? " " + x.patronymic : ""
            }`
            const items = x?.luggage?.items
            return { name, items }
          })
          .filter(x => x.items.length > 0)
        const positionName = createFromToString(x.segmentGroups)
        return { positionName, passengers }
      })
      .filter(x => !!x?.passengers?.length)

    const seats = _(state.orderPositions)
      .map(x => {
        const passengers = x.passengers
          .map(x => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? " " + x.patronymic : ""
            }`
            const items = x.seatsList?.items
            return { name, items }
          })
          .filter(x => x.items && x.items.length > 0)

        return passengers
      })
      .filter(x => !!x?.length)
      .flatten()
      // .groupBy('name')
      // .map((x) => x)
      .value()

    const others = state.orderPositions
      .map(x => {
        const passengers = x.passengers
          .map(x => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? " " + x.patronymic : ""
            }`
            const items = x?.otherAncillaryServices?.items
            return { name, items }
          })
          .filter(x => x.items.length > 0)
        const positionName = createFromToString(x.segmentGroups)
        return { positionName, passengers }
      })
      .filter(x => !!x?.passengers?.length)

    const SEATS_TOTAL = seats.reduce(
      (acc, x) =>
        acc +
        (x.items || [])
          .filter(x => x.status.code !== "Refunded")
          .reduce((acc, x) => acc + x.price, 0),
      0
    )

    const MEAL_TOTAL = meal.reduce(
      (acc, x) =>
        acc +
        x.passengers.reduce(
          (acc, x) =>
            acc +
            x.items
              .filter(x => x.status.code !== "Refunded")
              .reduce((acc, x) => acc + x.price, 0),
          0
        ),
      0
    )

    const LUGGAGE_TOTAL = luggage.reduce(
      (acc, x) =>
        acc +
        x.passengers.reduce(
          (acc, x) =>
            acc +
            x.items
              .filter(x => x.status.code !== "Refunded")
              .reduce((acc, x) => acc + x.price, 0),
          0
        ),
      0
    )

    const OTHERS_TOTAL = others.reduce(
      (acc, x) =>
        acc +
        x.passengers.reduce(
          (acc, x) =>
            acc +
            x.items
              .filter(x => x.status.code !== "Refunded")
              .reduce((acc, x) => acc + x.price, 0),
          0
        ),
      0
    )

    return {
      meal,
      luggage,
      others,
      seats,
      total: MEAL_TOTAL + LUGGAGE_TOTAL + OTHERS_TOTAL + SEATS_TOTAL
    }
  }
)

function createFromToString(segmentGroups) {
  return (segmentGroups || [])
    .map(value => {
      if (value.segments.length === 1) {
        return [...value.segments, ...value.segments].map(extractCity)
      } else {
        return value.segments.map(extractCity)
      }
    })
    .map(_.flatten)
    .map((value, index, arr) => removeDuplicates(value, arr[index + 1]))
    .map(value => value?.join(" - "))
    .join(" - ")
}

function extractCity(value, index, arr) {
  if (index !== 0 && index !== arr.length - 1)
    return [value.departureCity?.name, value.arrivalCity?.name]
  return index === 0 ? value.departureCity?.name : value.arrivalCity?.name
}

function removeDuplicates(x1, x2) {
  if (!x2) {
    return x1
  }
  if (x1[x1.length - 1] === x2[0]) {
    const x1Clone = [...x1]
    x1Clone.length = x1.length - 1
    return x1Clone
  }
}
