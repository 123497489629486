import { all, takeLatest, put } from 'redux-saga/effects';
import { showNotification, setNotification } from './duck';

export function* showNotificationWorker({payload}) {
  const { code, message } = payload;
  switch (code) {
    case 0: {
      yield put(
        setNotification({
          label: 'Ошибка',
          body: 'Отсутствует подключение к интернету, попробуйте еще раз',
          code,
        })
      );
      break;
    }
    case 410: {
      yield put(
        setNotification({
          label: 'Обращаем внимание!',
          data: payload?.data,
          booking: payload?.booking,
          code,
        })
      )
      break;
    }
    case 404:
    case 499: {
      yield put(
        setNotification({
          label: 'Не удалось забронировать',
          body: payload?.data?.message || message || 'Не удалось забронировать заявку. Пожалуйста, повторите поиск и создайте бронирование повторно',
          data: payload?.data,
          code,
        })
      );
      break
    }

    default: {
      yield put(
        setNotification({
          label: 'Что-то пошло не так',
          body: 'Произошла техническая ошибка, пожалуйста, попробуйте позже',
          code,
          searchRequest: payload.searchRequest,
          data: payload?.data,
          booking: payload?.booking,
        })
      );
      break;
    }
  }
}

export default function* notificationFlow() {
  yield all([takeLatest(showNotification.getType(), showNotificationWorker)]);
}
