import React from 'react';
import './style.css'
import {format, parseISO} from "date-fns";
import HotelSelect from "./HotelSelect";
import useBookingStore from "../../store";
import InfoIcon from '@material-ui/icons/Info';

const HotelBlock = ({hotel, hotels}) => {

	const cityHotel = hotels.filter(item => item.id === hotel.id)
	const beachHotels = hotels.filter(item => item.id !== hotel.id)

	const selectedHotels = useBookingStore(({ selectedHotels }) => selectedHotels)
	const actions = useBookingStore(({ actions }) => actions)

	return (
		<>
			<div className="block-header">
				<h3 className="title">Проживание</h3>
			</div>
			<div className="block-body">
				<div className="grid-hotel header">
					<div>Гостиница</div>
					<div>Город</div>
					<div>Номер</div>
					<div>Размещение</div>
					<div>Питание</div>
					<div>Период проживания</div>
				</div>
				{cityHotel.length === 1 && <div className="grid-hotel body">
					<div><a target={'_blank'} rel={'noreferrer'} style={{textDecoration: 'none', color: 'inherit'}}
							href={`/hotel/${cityHotel[0].id}`}>{cityHotel[0].name} {cityHotel[0].categoryStarsCount}*</a>
					</div>
					<div>{cityHotel[0].city.name}</div>
					<div>{cityHotel[0].room.name}</div>
					<div>{cityHotel[0].place.name}</div>
					<div>{cityHotel[0].mealType.name}</div>
					<div>
						{format(parseISO(cityHotel[0].checkinDate), 'dd.MM.yyyy')} - {format(parseISO(cityHotel[0].checkoutDate), 'dd.MM.yyyy')}
					</div>
				</div>}
				{!!cityHotel && cityHotel.length > 1 && <div style={{display: 'flex', padding: '10px 0px', alignItems: 'center', justifyContent: "space-between"}}>
					<div style={{width: 900, display: 'flex', alignItems: 'center'}}>
						<HotelSelect
							value={JSON.stringify(selectedHotels[0])}
							options={cityHotel}
							onChange={actions.changeSelectedCityHotels}
						/>
						<a target={'_blank'} rel={'noreferrer'} style={{textDecoration: 'none', color: 'inherit'}} href={`https://agentb2b.fstravel.com/hotel/${cityHotel[0].id}`}><InfoIcon style={{marginLeft: 10}} fontSize={'small'} /></a>
					</div>
					<div style={{marginRight: 35}}>{format(parseISO(cityHotel[0].checkinDate), 'dd.MM.yyyy')} - {format(parseISO(cityHotel[0].checkoutDate), 'dd.MM.yyyy')}</div>
				</div>}
				{!!beachHotels && !!beachHotels.length && <div style={{display: 'flex', padding: '10px 0px', alignItems: 'center', justifyContent: "space-between"}}>
					<div style={{width: 900, display: 'flex', alignItems: 'center'}}>
						<HotelSelect
							value={JSON.stringify(selectedHotels[1])}
							options={beachHotels}
							onChange={actions.changeSelectedBeachHotels}
						/>
						<a target={'_blank'} rel={'noreferrer'} style={{textDecoration: 'none', color: 'inherit'}} href={`https://agentb2b.fstravel.com/hotel/${beachHotels[0].id}`}><InfoIcon style={{marginLeft: 10}} fontSize={'small'} /></a>
					</div>
					<div style={{marginRight: 35}}>{format(parseISO(beachHotels[0].checkinDate), 'dd.MM.yyyy')} - {format(parseISO(beachHotels[0].checkoutDate), 'dd.MM.yyyy')}</div>
				</div>}
			</div>
		</>
	);
};

export default HotelBlock;