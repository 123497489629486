import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Pagination } from "swiper/modules";
import { useState, useRef } from "react";
import { isEmpty } from "lodash";

import "./style.scss";
import "swiper/css";
import ViewModal from "./ViewModal";

const Svg = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 13.7526L8.2487 15L12 11.495L15.7513 15L17 13.7526L12 9L7 13.7526Z"
            fill="#4872F2"
        />
    </svg>
);

function CardSlider({ slides }) {
    const [navSlider, setNavSlider] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const ref = useRef()

    if (isEmpty(slides)) return null;

    return (
        <div className="result-card-slider">
            <div className="result-card-slider__nav">
                <div className="result-card-slider__prev">
                    <Svg />
                </div>
                <Swiper
                    onSwiper={setNavSlider}
                    direction="vertical"
                    spaceBetween={24}
                    slidesPerView={5}
                    ref={ref}
                    navigation={{
                        nextEl: ".result-card-slider__next",
                        prevEl: ".result-card-slider__prev",
                    }}
                    className="result-card__slider-vertical"
                    breakpoints={{
                        0: {
                            direction: "horizontal",
                        },
                        768: {
                            direction: "vertical",
                        },
                    }}
                    modules={[Navigation, Thumbs]}
                >
                    {slides
                        ?.filter((i) => i?.src)
                        ?.map((item) => (
                            <SwiperSlide key={item.id}>
                                <div
                                    style={{
                                        background: `url(${item.src}) lightgray 50% / cover no-repeat`,
                                        width: "52px",
                                        height: "40px",
                                        borderRadius: "8px",
                                    }}
                                ></div>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className="result-card-slider__next">
                    <Svg />
                </div>
            </div>
            <div className="result-card__slider-wrap">
                <Swiper
                    thumbs={{ swiper: navSlider }}
                    direction="horizontal"
                    slidesPerView={1}
                    spaceBetween={24}
                    navigation={{
                        nextEl: ".result-card-slider__next",
                        prevEl: ".result-card-slider__prev",
                    }}
                    style={{
                        "--swiper-pagination-color": "rgba(255, 255, 255, 1)",
                        "--swiper-pagination-bullet-inactive-color": "rgba(255, 255, 255, 1)",
                        "--swiper-pagination-bullet-inactive-opacity": ".6",
                        "--swiper-pagination-bullet-horizontal-gap": "8px"
                    }}
                    pagination
                    breakpoints={{
                        0: {
                            direction: "horizontal",
                            pagination: { enabled: true, clickable: true, }

                        },
                        576: {
                            direction: "horizontal",
                            pagination: { enabled: false }
                        },
                    }}
                    className="result-card__slider-horizontal"
                    modules={[Navigation, Thumbs, Pagination]}
                >
                    {slides
                        ?.filter((i) => i?.src)
                        ?.map((item) => (
                            <SwiperSlide key={item.id}>
                                <div
                                    onClick={() => setShowImage(!showImage)}
                                    className="result-card__slider-horizontal__slide"
                                    style={{
                                        background: `url(${item.src}) lightgray 50% / cover no-repeat`,
                                    }}
                                ></div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            {showImage && (
                <ViewModal
                    lists={slides?.filter((i) => i?.src)}
                    onClose={setShowImage}
                    initialSlide={navSlider?.activeIndex}
                />
            )}
        </div>
    );
}

export default CardSlider;
