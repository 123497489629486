import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, Virtual } from 'swiper/modules';
import { useRef } from 'react'
import { isMobile } from "react-device-detect";

import './style.scss'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const SLIDES_VIEW_COUNT = 5

function PhotoTilesCardSlider({ slider }) {
    const ref = useRef(null)
    const autoplay = slider?.boolAutoList ? { delay: 500 } : false

    const handleLink = (link) => {
        if (link) {
            window.location.href = link
        }
    }

    return <div className='slider-card-container'>
        <Swiper
            ref={ref}
            slidesPerView={2}
            spaceBetween={20}
            loop={true}
            modules={[Pagination, Navigation, Autoplay, Virtual]}
            autoplay={autoplay}
            speed={slider.speed ?? 1500}
            virtual
            pagination={{
                el: '.slider-pagination.mobile-pagination',
                type: 'bullets',
                clickable: true,
                enabled: true,
            }}
            breakpoints={{
                780: {
                    slidesPerView: SLIDES_VIEW_COUNT,
                    pagination: false,
                    // loopedSlides: slider.photos.length,
                    virtual: {
                        addSlidesAfter: SLIDES_VIEW_COUNT,
                        addSlidesBefore: SLIDES_VIEW_COUNT,
                        enabled: true,
                    }
                },
            }}

            navigation={{
                nextEl: ".swiper-button-next.card-nav ",
                prevEl: ".swiper-button-prev.card-nav ",
            }}
            className="photo-tiles_card"
            watchOverflow={true}
        >
            {slider.photos.map((item, index) => <SwiperSlide key={item.uid} virtualIndex={index}>
                <div onClick={() => handleLink(item.link)} className='slide-item-container slide-item'>
                    <div className='slide-item-photo' style={{ background: `url(${item.preview}) center center / 100% 100% no-repeat` }}>
                        <div className='slide-title'>{item.title}</div>
                        <div className='slide-text'>{item.text}</div>
                    </div>
                </div>
            </SwiperSlide>)}
        </Swiper>
        <div className='slider-pagination mobile-pagination'></div>
        <div className='slider-card_nav'>
            {!isMobile && <>
                <div className='swiper-button-next card-nav slide-btn-bg' />
                <div className='swiper-button-prev card-nav slide-btn-bg' />
            </>}
        </div>
    </div>
}

export default PhotoTilesCardSlider