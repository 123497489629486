import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { v4 as uuid } from 'uuid';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './style.scss'
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import ArrowRight from "../../../../asset/icons/arrow-right.svg";
import React from "react";

function TilesPhotoTextBadge({ content, title }) {
    const swiperUid = uuid()
    return <div className='title-photo-text-badge_wrapper'>
        <div className='slider-header'>
            <h2>
                {title}
            </h2>
            {content.link_text && content.link_href && content.tiles.length < 5 &&
                <div className={'wrapper-link'}>
                    <Link to={content.link_href}>{content.link_text}</Link>
                    <span className={'arrow__right'} style={{ WebkitMask: `url(${ArrowRight}) no-repeat center/cover` }} />
                </div>
            }
            {(content.tiles.length > 4 && !isMobile) &&
                <div className='nav'>
                    <div className={`swiper-button-prev id-${swiperUid} nav-button`} ><div className='prev_chevron' /></div>
                    <div className={`swiper-button-next id-${swiperUid} nav-button`} ><div className='next_chevron' /></div>
                </div>
            }
        </div>

        <Swiper
            spaceBetween={30}
            slidesPerView={isMobile ? 'auto' : 4}
            modules={[FreeMode, Navigation]}
            navigation={{
                nextEl: `.swiper-button-next.id-${swiperUid}`,
                prevEl: `.swiper-button-prev.id-${swiperUid}`,
            }}

            className="photo-tiles_slider"
            watchOverflow={true}
        >
            {content?.tiles?.map(item => {
                if (!item.image && !item.text && !item.badge && !item.title && !item.text) return null

                const link = item.link ? item.link : window.location.pathname

                return <SwiperSlide key={item.image}>
                    <div className='slide-image-container'>
                        {item.image &&
                            <Link to={link}>
                                <img key={item.image} className='slide-img'
                                    src={item.image} alt='' />
                            </Link>
                        }
                        {item?.badge && <Link to={item.link}><div className='badge'>{item?.badge}</div></Link>}
                    </div>
                    <Link to={link} className='slide-title'>
                        {item?.title}
                    </Link>
                    <p className='slide-text'>
                        {item?.text}
                    </p>
                </SwiperSlide>
            })}
        </Swiper>

    </div>
}

export default TilesPhotoTextBadge