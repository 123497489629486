import React from 'react';
import { isMobile } from 'react-device-detect';

import './style.css'
import { Link } from "react-router-dom";
import ArrowRight from '../../../../asset/icons/arrow-right.svg'

const TilesPhotoText = ({ content, title, id }) => {

	return (
		<div className={'tiles-photo-text'} id={id}>
			<div className={'tiles-photo-text-title-wrapper'}>
				<h2>{title}</h2>
				{!isMobile && content.link_href && <div className={'wrapper-link'}>
					<Link to={content.link_href}>{content.link_text}</Link>
					<span className={'arrow__right'} style={{ WebkitMask: `url(${ArrowRight}) no-repeat center/cover` }} />
				</div>}
			</div>
			<div className={'tiles-photo-text-block-wrapper'}>
				{content.tiles.map(tile =>
					<div key={tile.order} className={'tiles-photo-text__tile'}>
						<div className={'tiles-photo-text__tile-card'}>
							{tile.link ? <Link className={'tiles-photo-text__tile-card__link'} to={tile.link}
								style={{ backgroundColor: tile.color }}>
								<img src={isMobile ? tile.img_mobile : tile.img_desktop} alt="" />
							</Link> : <div className={'tiles-photo-text__tile-card__link'}><img src={isMobile ? tile.img_mobile : tile.img_desktop} alt="" /></div>}
						</div>
						<h4 className={'tile-card-title'}>{tile.title}</h4>
						{tile.text && <div className={'tile-card-text'}>{tile.text}</div>}
						{tile.link && <Link className={'tile-card-link'} to={tile.link}>{tile.link_text}</Link>}
					</div>
				)}
			</div>
			{isMobile && content.link_href && content.link_text && <Link className={'mobile-button'} to={content.link_href}>{content.link_text}</Link>}
		</div>
	);
};

export default TilesPhotoText;