import React from "react"
import { TableUi } from "../TableUi"
import AgentLogItem from "./AgentLogItem"
import styled from 'styled-components';

const Title = styled.p`
  margin: 0;
  margin-bottom: 25px;
  font-family: "Open Sans",serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
`;

const AgentLogTable = (props) => {
  return (
    <TableUi.Container>
      <Title>История действий агента</Title>
      {!props.logs?.length ? (
        <TableUi.SubTitle>Лог отсутствует</TableUi.SubTitle>
      ) : (
        <TableUi.Table style={{ overflowX: "auto", display: "block" }}>
          <TableUi.Tr>
            <TableUi.TitleTh>Дата</TableUi.TitleTh>
            <TableUi.TitleTh>Действие</TableUi.TitleTh>
            <TableUi.TitleTh>Старое значение</TableUi.TitleTh>
            <TableUi.TitleTh>Новое значение</TableUi.TitleTh>
            <TableUi.TitleTh>Логин пользователя</TableUi.TitleTh>
            <TableUi.TitleTh>Тип поля</TableUi.TitleTh>
            <TableUi.TitleTh></TableUi.TitleTh>
          </TableUi.Tr>
          {props.logs?.map((log, i) => (
            <AgentLogItem agent={log} key={log.id} />
          ))}
        </TableUi.Table>
      )}
    </TableUi.Container>
  )
}

export default AgentLogTable
