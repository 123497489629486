import { createAction, createReducer } from "redux-act"
import { combineReducers } from "redux"
import {parseValidJson, UserStatusInitialState} from "./constants";


export const getUserDataRequest = createAction(
  "@user/getUserDataRequest"
)
export const getUserDataSuccess = createAction(
  "@user/getUserDataSuccess"
)

export const getUserDataFailure = createAction(
  "@user/getUserDataFailure"
)

export const changeUserData = createAction("@user/changeUserData")
export const userLogout = createAction("@user/userLogout")
export const changeUserDataSuccess = createAction("@user/changeUserDataSuccess")

export const checkAuthStatus = createAction("@user/checkAuthStatus")
export const setFetchUserStatus = createAction("@user/setFetchUserStatus")
export const updateUserStatusToken = createAction("@user/updateUserStatusToken")

export const getPassanagersRequest = createAction("@user/getAviaPassangersRequest")
export const getPassangersSuccess = createAction("@user/getAviaPassangersSuccess")

export const updatePassangersRequest = createAction("@user/updateAviaPassangersRequest")

export const updatePassangersSuccess = createAction("@user/updateAviaPassangersSuccess")

export const createPassangersRequest = createAction(
  "@user/createAviaPassangersRequest"
)

export const createPassangersSuccess = createAction(
  "@user/createAviaPassangersSuccess"
)

export const setUserError = createAction("@user/setUserError")

//remove passanger
export const removePassangerRequest = createAction(
  "@user/removeAviaPassangersRequest"
)
export const removePassangerSuccess = createAction(
  "@user/removeAviaPassangersSuccess"
)
export const removePassangerFailure = createAction(
  "@user/removeAviaPassangersFailure"
)

export const setCommissionStatus = createAction(
  '@personal-area/setCommissionStatus'
);

export const purifyError = createAction("@user/purifyError")

const aviaPassengers = createReducer({}, [])
aviaPassengers.on(getPassanagersRequest, () => [])
aviaPassengers.on(getPassangersSuccess, (_, payload) => payload)
aviaPassengers.on(userLogout, () => [])

const error = createReducer({}, "")
error.on(setUserError, (_, payload) => payload)

const removeLoading = createReducer({}, false)
removeLoading.on(removePassangerRequest, () => true)
removeLoading.on(removePassangerSuccess, () => false)
removeLoading.on(removePassangerFailure, () => false)

const passangerLoading = createReducer({}, false)
passangerLoading.on(updatePassangersRequest, () => true)
passangerLoading.on(updatePassangersSuccess, () => false)
passangerLoading.on(createPassangersRequest, () => true)
passangerLoading.on(createPassangersSuccess, () => false)
passangerLoading.on(setUserError, () => false)

const status = createReducer({}, UserStatusInitialState)

status.on(setFetchUserStatus,(state,payload) => ({...state,isFetching: payload}));
status.on(purifyError, state => ({ ...state, error: null }))
status.on(userLogout, () => (UserStatusInitialState))

status.on(updateUserStatusToken, state => ({
  ...state,
  token: parseValidJson(window.localStorage.getItem('tte_token'))?.accessToken || '',
  isFetching: false
}))

status.on(changeUserData, state => ({
  ...state,
}))
status.on(changeUserDataSuccess, state => ({
  ...state,
  isFetching: false
}))

const userData = createReducer({}, null)
userData.on(getUserDataRequest, () => null)
userData.on(userLogout, () => null)
userData.on(getUserDataSuccess, (s, payload) => ({
  ...payload,
  isHideCommission: payload.isHideCommission,
}))

userData.on(getUserDataFailure, () => null)

const userCommissionStatus = createReducer({}, false);
userCommissionStatus.on(setCommissionStatus, (_, payload) => payload);
userCommissionStatus.on(userLogout, () => false);

export default combineReducers({
  status,
  data: userData,
  aviaPassengers,
  userCommissionStatus
})
