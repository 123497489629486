import React from 'react'
import styled from 'styled-components'
import useCommissionStore from '../store'

import ExpandedBlock from './ExpandedBlock'

const Table = styled.div`
	display: flex;
	flex-direction: column;
`

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 100px 452px 164px;
	gap: 48px;
`

const TabelHead = styled(TableGrid)`
	background: #edf1fe;
	border-radius: 16px 16px 0px 0px;
	padding: 16px 24px;

	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

export default function TableComission() {
	const commissions = useCommissionStore((state) => state.commission)

	return (
		<Table>
			<TabelHead>
				<div>Страна</div>
				<div>Тур</div>
				<div>Комиссия</div>
			</TabelHead>
			{commissions?.map((item) => (
				<ExpandedBlock key={item.id} item={item} />
			))}
		</Table>
	)
}
