import React from 'react';
import moment from "moment";

const MessagesItem = ({message}) => {

  return (
    <div className={`message-block ${message.incoming ? 'incoming' : ''}`}>
        <p className={'author'}>{message.Author}</p>
        <p className={'text'}>{message.Text}</p>
        <p className={'time'}>{moment(message.DateTime).format('DD MMM, HH:mm')}</p>
    </div>
  );
};

export default MessagesItem;
