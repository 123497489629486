import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './style.css'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

// import required modules
import { Navigation } from 'swiper/modules'

export default function CruiseSwiper({ photos }) {
	return (
		<div className='slider-cruise'>
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				loop={true}
				navigation={true}
				modules={[Navigation]}>
				{photos.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={item.img} alt={item.title} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}
