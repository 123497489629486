import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import useAuthStore from '../../../Auth/store';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomInput from '../components/CustomInput/CustomInput';
import '../style.css';
import './style.scss';

export default function Payment() {
    const [showBlock, toggle] = useState(false);
    const banks = useAuthStore((state) => state.banks);
    const actions = useAuthStore(({ actions }) => actions);
    const agents = useAuthStore(({ agent }) => agent?.agents);
    const [bicValue, setBicValue] = useState('');

    useEffect(() => {
        actions.getBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (banks) {
            setBicValue(banks.find((item) => item.id === agents?.bank?.id)?.bik);
        }
    }, [banks, agents]);

    const onChangeRs = (event) => {
        if (event.target.value.length > 20) return;
        actions.changeAgentRs(event.target.value);
    };

    const [targetBankName, setTargetBankName] = useState('');

    const onChangeBIC = (event) => {
        const value = event.target.value;
        const targetBank = banks?.find((item) => item.bik === value);

        if (event.target.value.length > 9) return;
        if (event.target.value.length < 9) {
            setTargetBankName('');
        }
        setBicValue(event.target.value);
        actions.changeAgentBank(targetBank);
    };


    const onBlurBIC = (event) => {
        const value = event.target.value;
        const targetBank = banks?.find((item) => item.bik === value);

        actions.clearError();
        if (!value) {
            setTargetBankName('');
            return;
        }
        if (!targetBank) {
            setTargetBankName('');
            actions.setError('Банк не найден. Обратитесь к туроператору.');
            return;
        }
        setTargetBankName(
            targetBank.title.length > 24
                ? targetBank.title.slice(0, 24) + '...'
                : targetBank.title
        );
    };

    if (!agents) return null;


    return (
        <div
            style={{
                backgroundColor: '#F6F8FA',
                padding: 24,
                borderRadius: 16,
                marginBottom: 24 
            }}
            className="cabinet-info-block"
        >
            <div className="v-dropdown">
                <div className="about-agency">Банковские переводы</div>
                <div>
                    {showBlock ? (
                        <ExpandLessIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => toggle(!showBlock)}
                            type="button"
                            className="newagency-close-btn"
                        />
                    ) : (
                        <ExpandMoreIcon
                            style={{ fontSize: 24, color: '#2E2E32' }}
                            onClick={() => toggle(!showBlock)}
                            type="button"
                            className="newagency-open-btn"
                        />
                    )}
                </div>
            </div>

            {showBlock && (
                <div style={{ marginTop: '24px' }}>
                    <div className="payment-grid">
                        <Box className="text-input">
                            <CustomInput
                                label="Рассчётный счет"
                                required
								className={!agents.rs ? 'empty-input' : ''}
                                value={agents.rs}
                                onChange={onChangeRs}
                            />
                        </Box>
                        <Box className="text-input">
                            <CustomInput
                                label="БИК Банка"
                                required
								className={!bicValue ? 'empty-input' : ''}
                                value={bicValue}
                                onChange={onChangeBIC}
                                onBlur={onBlurBIC}
                                bankName={targetBankName}
                            />
                        </Box>
                    </div>
                </div>
            )}
        </div>
    );
}
