import React from "react"
import CreateFormFailureModal from "./CreateFormFailure"
import CreateFormSuccessModal from "./CreateFormSuccess"
import CreateFormModal from "./CreateFormDefault"
import { APPEAL_STEP, getAddAppealState } from "../../../store/modules/appeals"
import { useSelector } from "react-redux"
import { Loader } from "../../ui";

const Router = (props) => {
  const { STEP } = useSelector(getAddAppealState)
  
  return (<>
    {STEP === APPEAL_STEP.REQUEST && <Loader opacity='0.8' zIndex={999}/>}
    <CreateFormModal {...props} open={STEP === APPEAL_STEP.DEFAULT && props.open}/>
    <CreateFormSuccessModal {...props} open={STEP === APPEAL_STEP.SUCCESS && props.open}/>
    <CreateFormFailureModal {...props} open={STEP === APPEAL_STEP.FAILURE && props.open}/>
  </>);
}

export default Router
