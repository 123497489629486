import { useState } from 'react'
import MembersBlock from '../MembersBlock'
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Alert, Stack } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import useBookingStore from '../../../../store'

function BabyBlock({ babyId }) {
    const paxes = useBookingStore((state) => state.paxes);
    const [baby, setBaby] = useState(paxes.find((paxe) => paxe.id === babyId))
    const [open, setOpen] = useState(false)
    const [notifications, setNotification] = useState([])

    const actions = useBookingStore(({ actions }) => actions)
    const insurances = useBookingStore(state => state.insurances)
    const excursions = useBookingStore(state => state.excursions)
    const services = useBookingStore(state => state.services)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const getNotification = (paxe) => {
        const notification = []
        paxe.selectedInsurances.forEach(item => {
            const include = insurances.find(insurance => insurance.id === item.id)

            if (!include) {
                return null
            }
            const notify = {
                targetType: "insurance",
                targetStatus: "Added",
                targetId: include.id,
                targetName: include.name,
                description: `Младенец прекреплен к услуге ${include.name}`
            }
            notification.push(notify)
        })
        paxe.selectedServiceIds.forEach(item => {
            const includeService = services.find(service => service.id === item)
            const includeExcursion = excursions.find(excursion => excursion === item)
            if (includeService) {
                const notify = {
                    targetType: "service",
                    targetStatus: "Added",
                    targetId: includeService.id,
                    targetName: includeService.name,
                    description: `Младенец прекреплен к услуге ${includeService.name}`
                }
                notification.push(notify)
                return null
            }
            if (includeExcursion) {
                const notify = {
                    targetType: "service",
                    targetStatus: "Added",
                    targetId: includeExcursion.id,
                    targetName: includeExcursion.name,
                    description: `Младенец прекреплен к ${includeExcursion.name}`
                }
                notification.push(notify)
                return null
            }
            return null
        })

        return notification
    }

    const removeNotification = (paxe) => {
        const notification = []
        paxe.selectedInsurances.forEach(item => {
            const include = insurances.find(insurance => insurance.id === item.id)

            if (!include) {
                return null
            }
            const notify = {
                targetType: "insurance",
                targetStatus: "Removed",
                targetId: include.id,
                targetName: include.name,
                description: `Младенец откреплен от страховки ${include.name}`
            }
            notification.push(notify)
        })
        paxe.selectedServiceIds.forEach(item => {
            const includeService = services.find(service => service.id === item)
            const includeExcursion = excursions.find(excursion => excursion === item)
            if (includeService) {
                const notify = {
                    targetType: "service",
                    targetStatus: "Removed",
                    targetId: includeService.id,
                    targetName: includeService.name,
                    description: `Младенец откреплен от услуги ${includeService.name}`
                }
                notification.push(notify)
                return null
            }
            if (includeExcursion) {
                const notify = {
                    targetType: "service",
                    targetStatus: "Removed",
                    targetId: includeExcursion.id,
                    targetName: includeExcursion.name,
                    description: `Младенец откреплен от услуги ${includeExcursion.name}`
                }
                notification.push(notify)
                return null
            }
            return null
        })

        return notification
    }


    const showModal = () => {
        if (!baby) {
            const newPax = {
                
                id: paxes.length + 1,
                person: {
                    gender: 0,
                    ageGroup: 3
                },
                nationalityId: paxes[0].nationalityId,
                document: {
                    type: 0
                },
                selectedServiceIds: paxes[0].selectedServiceIds,
                selectedInsurances: paxes[0].selectedInsurances,
                visaOptions: paxes[0].visaOptions,
                selectedFlightsSeats: paxes[0].selectedFlightsSeats,
            }
            actions.addPaxe(newPax)
            setNotification(getNotification(newPax))
            setBaby(newPax)
            setOpen(true)

            return
        }
        setNotification(removeNotification(paxes[paxes.length - 1]))
        actions.removePaxe(baby?.id)
        setBaby(null)
        setOpen(true)
    }

    return <>
        <div>
            <FormControlLabel
                label={'Дополнительно: младенец'}
                control={
                    <Checkbox
                        checked={!!baby}
                        onChange={showModal}
                    />
                }
            />
        </div>
        {baby && <div>
            <MembersBlock title={'Младенец'} count={baby?.id} />
        </div>}
        <Snackbar
            open={open}
            autoHideDuration={16000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Stack flexDirection='column' gap={1}>
                {notifications.map(item => {
                    return (
                        <Alert
                            onClose={handleClose}
                            severity={item.targetStatus === 'Removed' ? 'error' : 'success'}
                            sx={{ width: '300px' }}>
                            {item.description}
                        </Alert>
                    )
                })}
            </Stack>
        </Snackbar>
    </>
}

export default BabyBlock