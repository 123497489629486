import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function CustomSelect({ value = '', label, onChange, options, textType = 'name', valueType = 'id', id, disabled }) {

    return <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
            labelId={id}
            value={value}
            onChange={onChange}
            label={label}
            IconComponent={ExpandMoreIcon}
            style={{ background: "#fff", borderRadius: '8px' }}
            disabled={disabled}
        >
            {!isEmpty(options) && options.map(item => {
                return <MenuItem key={item[valueType]} value={item[valueType]}>
                    {item[textType]}
                </MenuItem>
            })}
        </Select>
    </FormControl>
}

export default CustomSelect