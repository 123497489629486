import React from 'react'

import { Box } from '@mui/material'

import styled from 'styled-components'

import useHotelServicesStore from '../../../store'
import ServiceFilter from '../../../../../components/Services/ServiceFilter'
import { isEmpty } from 'lodash'

const Clear = styled.div`
  color: #4872f2;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  text-align: center;
  
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

`

export default function ServiceFiltersBlock() {

    const actions = useHotelServicesStore(
        ({ actions }) => actions
    )

    const excursionType = useHotelServicesStore(
        ({ excursionType }) => excursionType
    )

    const checkedExcursionType = useHotelServicesStore(
        ({ checkedExcursionType }) => checkedExcursionType
    )

    const actionsType = useHotelServicesStore(
        ({ actionsType }) => actionsType
    )

    const checkedActionsType = useHotelServicesStore(
        ({ checkedActionsType }) => checkedActionsType
    )

    return (
        <Box>
            <ServiceFilter
                title='Тип услуг'
                list={excursionType}
                checked={checkedExcursionType}
                changeHandler={actions.changeExcursionType}
            />
            <ServiceFilter
                title='Событие'
                list={actionsType}
                checked={checkedActionsType}
                changeHandler={actions.changeActionType}
            />
            {(!isEmpty(checkedExcursionType) ||
                !isEmpty(checkedActionsType)) &&
                <Clear onClick={() => actions.clearFilters()}> Сбросить все фильтры </Clear>
            }
        </Box>
    )
}
