import React, {useEffect} from 'react';
import styled from 'styled-components';
import TimeLimit from '../../components/AviaBooking/TimeLimit';
import ContactInformation from '../../components/AviaBooking/ContactInformation';
import FlightFares from '../../components/AviaBooking/FlightFares';
import Passengers from '../../components/AviaBooking/PassengersBlock';
import Insurances from '../../components/AviaBooking/Insurances';
import Payment from '../../components/AviaBooking/PaymentBlock'
import PriceDetail from '../../components/AviaBooking/PriceDetail';
import MirPaymentNotify from '../../components/AviaBooking/MirPaymentNotify';
import ExtraServices, {ExtraServicesProvider, SeatMapOfferBlock} from '../../components/AviaBooking/ExtraServices';
import RightSide from '../../components/AviaBooking/RightSide';
import InsuranceSuggestionModal from '../../components/modals/InsuranceSuggestionModal';
import { useDispatch,useSelector } from 'react-redux';
import {
  bookFlightRequest,
  selectFlightRequest,
  PassengerAgeCategory,
  bookingPurify,
  getBookingTicketState,
  hideInsuranceNotification,
  getBookingFaresState,
  setSeatMap
} from '../../store/modules/booking'
import {createSeatMapState} from "../../store/modules/booking/utils";
import Loader from '../../components/ui/Loader';
import FlighDetail from '../../components/AviaBooking/FlightDetail';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import AgentTaxesBlock from "../../components/AviaBooking/AgentTaxesBlock";
import {LowcosterNotification, CharterNotification} from "../../components/ui";
import WarningNotification from "../../components/AviaBooking/WarningNotification";
import ErrorNotification from "../../components/AviaBooking/ErrorNotification";
import {getNotification} from "../../store/modules/notification";


const Form = styled.form``;
const Header = styled.div`
  display: block;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
  padding-top: 35px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
`;

const LeftRow = styled.div`
  width: 730px;

  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const RightRow = styled.div`
  width: 350px;
  position: relative;

  & > div {
      margin-bottom: 20px;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 20px;

  & > div {
    margin-bottom: 20px;
  }
`;


export default function Booking(props) {
  const [isShowWarning, setIsShowWarning] = React.useState(false);
  const {
    booking: {
      passengers,
      ticket: { loading, initialized, flights },
      insurance,
    },
    user: {
      data: { role },
    }
  } = useSelector((state) => state);

  const { insuranceNotification, warnings, searchRequest } = useSelector(getBookingTicketState);
  const notification = useSelector(getNotification);
  const fares = useSelector(getBookingFaresState);
  const selectedFare = fares?.selected;
  const canBook = role !== 'ViewManager';

  const dispatch = useDispatch();
  useEffect(() => {
         /** query params for request */
         const params = new URLSearchParams(decodeURIComponent(window.location.search));
         const flights = params.getAll('flights');
         const group = params.get('group');
         const id = params.get('id');

         dispatch(
           selectFlightRequest({
             flights: flights,
             group: group,
             id: id,
           })
         );

         return () => {
           dispatch(bookingPurify());
         };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (selectedFare && passengers) {
      dispatch(setSeatMap(createSeatMapState(selectedFare, passengers)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFare]);

  useEffect(() => {
    if (warnings?.length > 0) {
      setIsShowWarning(true);
    }
  }, [warnings]);

  const FormikValidationSchema = React.useMemo(() => {
    const lastSegmentDate = flights
      ? new Date(
          flights[flights.length - 1].segments[
            flights[flights.length - 1].segments.length - 1
          ].toDate
        )
      : new Date();

    const IsLowcosterValid = flights?.some((flight) =>
      flight?.segments?.some((segment) =>
      ['5W', 'W6', 'FR', 'VY', 'V7', 'KC'].includes(segment.airlineCode)
      )
    );

    const MIN_DATE_OF_BIRTH = new Date();
    const MAX_DATE_OF_BIRTH = new Date();
    MIN_DATE_OF_BIRTH.setFullYear(new Date().getFullYear() - 100);

    return yup.object().shape({
      passengers: yup.array().of(
        yup
          .object()
          .shape({
            name: yup
              .string()
              .trim()
              .matches(/^[а-яА-ЯёЁa-zA-Z]+$/, 'Только кириллица или латиница')
              .required('Заполните'),
            nameEng: yup
              .string()
              .trim()
              .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
              .required('Заполните'),
            surname: yup
              .string()
              .trim()
              .matches(/^[а-яА-ЯёЁa-zA-Z]+$/, 'Только кириллица или латиница')
              .required('Заполните'),
            surnameEng: yup
              .string()
              .trim()
              .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
              .required('Заполните'),
            secondName: yup
              .string()
              .when('secondNameRequired', {
                is: (value) => !!value,
                then: yup.string().trim().matches(/^[а-яА-ЯёЁa-zA-Z]+$/, 'Только кириллица или латиница').required('Заполните'),
                otherwise: yup.string(),
              })
              .nullable(),
            secondNameEng: yup
              .string()
              .when('secondNameRequired', {
                is: (value) => !!value,
                then: yup
                  .string()
                  .trim()
                  .matches(/^[a-zA-Z]+$/, 'Только латинские буквы')
                  .required('Заполните'),
                otherwise: yup.string(),
              })
              .nullable(),
            birthDate: yup
              .date()
              .min(MIN_DATE_OF_BIRTH, 'Неправильная дата рождения')
              .max(MAX_DATE_OF_BIRTH, 'Неправильная дата рождения')
              .when('ageCategory', {
                is: (value) => !!(value === PassengerAgeCategory.Infant),
                then: yup
                  .date()
                  .test(
                    'infant',
                    'Младенцу на момент перелета должно быть до 2 лет',
                    function (value) {
                      if (value === null || value === undefined) {
                        return true;
                      }
                      const years = moment
                        .duration(moment(lastSegmentDate).diff(moment(value)))
                        .years();

                      return years <= 2;
                    }
                  )
                  .required('Заполните')
                  .nullable(),
                otherwise: yup
                  .date()
                  .when('ageCategory', {
                    is: (value) => !!(value === PassengerAgeCategory.Child),
                    then: yup
                      .date()
                      .test(
                        'infant',
                        'Ребенку на момент перелета должно быть от 2 до 12 лет',
                        function (value) {
                          if (value === null || value === undefined) {
                            return true;
                          }
                          const years = moment
                            .duration(
                              moment(lastSegmentDate).diff(moment(value))
                            )
                            .years();

                          if (IsLowcosterValid) {
                            return true
                          } else {
                            return years >= 2 && years <= 12;
                          }
                        }
                      )
                      .required('Заполните')
                      .nullable(),
                    otherwise: yup.date().required('Заполните').nullable(),
                  })

                  .required('Заполните')
                  .nullable(),
              })
              .when(['documentType', 'ageCategory', 'citizenship'], {
                is: (value, value2, value3) =>
                  value === 'BIRTH_CERTIFICATE' &&
                  value2 === 'ADULT' &&
                  value3 === 'RU',
                then: yup
                  .date()
                  // .test(
                  //   'adult',
                  //   'На момент полета по свидетельству взрослому должно быть от 12 до 14 лет',
                  //   function (value) {
                  //     if (value === null || value === undefined) {
                  //       return true;
                  //     }
                  //     const years = moment
                  //       .duration(moment(lastSegmentDate).diff(moment(value)))
                  //       .years();
                  //     return years >= 12 && years <= 14;
                  //   }
                  // ),
              })
              .when('ageCategory', {
                is: (value) => value === PassengerAgeCategory.Adult,
                then: yup
                  .date()
                  .test(
                    'adult',
                    'На момент полета взрослому должно быть больше 12 лет',
                    function (value) {
                      if (value === null || value === undefined) {
                        return true;
                      }
                      const years = moment
                        .duration(moment(lastSegmentDate).diff(moment(value)))
                        .years();
                      if (IsLowcosterValid) {
                        return true
                      } else {
                        return years >= 12
                      }
                    }
                  )
                  .required('Заполните')
                  .nullable(),
              })
              .test('not alive', 'Некорректная дата рождения', (v) => {
                return v?.getFullYear() > 1900;
              })
              .nullable(),

            number: yup
              .string()
              .when('citizenship', {
                is: (value) => !!(value === 'RU'),
                then: yup.string().when('documentType', {
                  is: (value) => !!(value === 'PASSPORT'),
                  then: yup
                    .string()
                    .trim()
                    .matches(/\d{4}-?\d{6}/, 'Невалидный номер документа')
                    .required('Заполните'),
                  otherwise: yup.string().when('documentType', {
                    is: (value) => !!(value === 'INTERNATIONAL_PASSPORT1'),
                    then: yup.string().trim().required('Заполните'),
                    otherwise: yup.string().when('documentType', {
                      is: (value) => !!(value === 'BIRTH_CERTIFICATE'),
                      then: yup
                        .string()
                        .trim()
                        // .matches(
                        //   /^[IXV]{1,5}-?[А-ЯЁA-Z]{2,5}\s?№?\s?\d{6}$/,
                        //   'Невалидный номер документа'
                        // )
                        .required('необходимо заполнить'),
                      otherwise: yup.string(),
                    }),
                  }),
                }),
                otherwise: yup.string(),
              })
              .test('not email', 'Спецсимволы запрещены', (v) => {
                return (
                  !v?.includes('@') && !v?.includes('.') && !v?.includes('!')
                );
              }),
            dateOfIssue: yup.date().when('documentType', {
              is: (value) => !!value,
              then: yup
                .date()
                .when('dateOfIssue', {
                  is: (value) => !!value,
                  then: yup
                    .date()
                    .test(
                      'dateOfIssueError',
                      'Некорректный срок действия',
                      function (value) {
                        if (value === null || value === undefined) {
                          return true;
                        }
                        const years = moment
                          .duration(moment(value).diff(moment(new Date())))
                          .years();
                        return years < 50;
                      }
                    ),
                })
                .nullable()

                .when('documentType', {
                  is: (value) =>
                    !!(
                      value === 'INTERNATIONAL_PASSPORT1' ||
                      value === 'INTERNATIONAL_PASSPORT'
                    ),
                  then: yup
                    .date()
                    .required('Заполните')
                    .min(
                      lastSegmentDate,
                      'Паспорт истекает раньше последнего перелёта'
                    )
                    .nullable(),
                }),
              otherwise: yup.date().nullable().notRequired(),
            }),
          })
          .test({
            name: 'equal',
            test: function (value) {
              const isEqual =
                value.secondName === value.surname;

              if (isEqual) {
                return this.createError({
                  path: `passengers[${value.key}].secondName`,
                  message: 'Отчество и фамилия одинаковы',
                });
              }
              return true;
            },
          })
      ),
      phone: yup
        .string()
        .matches(
          /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}/,
          'Некорректный номер телефона'
        )
        .required('Заполните'),
      offerta: yup.boolean().when('isInsurancesChecked', {
        is: true,
        then: yup.boolean(),
        otherwise: yup.boolean().oneOf([true], 'Field must be checked'),
      }),
      isCharter: yup.boolean(),
      charterTariffs: yup.boolean().when('isCharter', {
        is: true,
        then: yup.boolean().oneOf([true], 'Field must be checked'),
        otherwise: yup.boolean(),
      }),

      isInsurancesChecked: yup.boolean(),
      offertaWithInsurances: yup.boolean().when('isInsurancesChecked', {
        is: true,
        then: yup.boolean().oneOf([true], 'Field must be checked'),
        otherwise: yup.boolean(),
      }),
      email: yup.string().email('Неправильный формат').required('Заполните'),
      name: yup.string().required('Заполните'),
      citizenship: yup.string(),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights, insurance.status]);

  const initialPassengers = React.useMemo(() => {
    return passengers.map((x, key) => ({
      ...x,
      number: '',
      dateOfIssue: null,
      secondName: '',
      series: '',
      sex: 'm',
      secondNameRequired: true,
      // dateOfIssueRequired: true,
      loyality: { code: '', value: '' },
      citizenship: 'RU',
      key: key,
      birthDate: null,
      customerPassengerId: '',
      documentSeries: '',
      documentInfo: '',
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengers.length, flights]);

  const initialValues = {
    passengers: initialPassengers,
    phone: '',
    email: '',
    name: '',
    sameContactInfo: false,
    phoneTwo: '',
    nameTwo: '',
    insurance: true,
    return: true,
    policy: true,
    offerta: false,
    isInsurancesChecked: !!insurance.list.offers.find(x => x.checked),
    offertaWithInsurances: false,
    isCharter: false,
    charterTariffs: false,
    emailOfferta: false,
    baggage: [],
  };

  if(loading) {
    return <Loader/>;
  }
  if(initialized) {
    const hasLowcosterSegments = flights?.some((flight) =>
      flight?.segments?.some((segment) =>
        segment.flight_Type === 'Lowcoster'
      )
    );
    const hasCharterSegments = flights?.some((flight) =>
      flight?.segments?.some((segment) =>
        segment.flight_Type === 'Charter'
      )
    );

    return (
      <>
      {isShowWarning && (
        <WarningNotification
          isShowWarning={isShowWarning}
          setIsShowWarning={setIsShowWarning}
          warnings={warnings}
          searchRequest={searchRequest}
        />
      )}
      {notification?.label && <ErrorNotification />}
      <Formik
          initialValues={initialValues}
          validationSchema={FormikValidationSchema}
          onSubmit={(values) => {
            try {
              dispatch(
                bookFlightRequest({
                  ...values,
                  passengers: values.passengers.map((val) => ({
                    ...val,
                    number: val.number.replaceAll(/-|№|\s/g, ''),
                    // birthDate: ,
                    documentType:
                      val.documentType === 'INTERNATIONAL_PASSPORT1'
                        ? 'INTERNATIONAL_PASSPORT'
                        : val.documentType,
                  })),
                })
              );
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {(s) => (
            <>
              <Form  autoComplete='off' onSubmit={(e) => {
                    e.preventDefault();
                    s.handleSubmit();
              }}>
                <Header>Бронирование</Header>
                <ExtraServicesProvider>
                  <Content>
                    <LeftRow>
                        {hasLowcosterSegments && <LowcosterNotification/>}
                        {hasCharterSegments && <CharterNotification/>}
                        <FlighDetail/>
                        <FlightFares/>
                        <Insurances/>
                        <ExtraServices/>
                        <Passengers/>
                        <ContactInformation/>
                        <PriceDetail/>
                        {canBook && (
                          <>
                            <MirPaymentNotify/>
                            <Payment/>
                          </>
                        )}

                    </LeftRow>
                    <RightRow>
                      <Sticky>
                        <TimeLimit duration={selectedFare.timeLimitDuration}/>
                        <RightSide/>
                        <AgentTaxesBlock/>
                        <SeatMapOfferBlock/>
                      </Sticky>
                    </RightRow>
                  </Content>
                </ExtraServicesProvider>
            </Form>
            <InsuranceSuggestionModal
                open={!!insuranceNotification}
                onConfirm={() => {
                  dispatch(hideInsuranceNotification());
                  dispatch(
                    bookFlightRequest({
                      ...s.values,
                      passengers: s.values.passengers.map((val) => ({
                        ...val,
                        number: val.number.replaceAll(/-|№|\s/g, ''),
                        documentType:
                          val.documentType === 'INTERNATIONAL_PASSPORT1'
                            ? 'INTERNATIONAL_PASSPORT'
                            : val.documentType,
                      })),
                    })
                  );
                }}
                onClose={() => {
                  dispatch(hideInsuranceNotification());
                  const scrollElement =
                    document.getElementById('insurances_block');
                  if (scrollElement)
                    scrollElement.scrollIntoView({ behavior: 'smooth' });
                }}
              />
          </>
        )}
      </Formik>
      </>
    );
  }
  return null;
}
