import React from 'react';
import styled from 'styled-components';
import ContactsList from './ContractsList/ContractsList'
import ContractsInfo from './ContractsInfo/index';
import FranchContractsList from './FranchContractsList/FranchContractsList';
import useContractsStore from './store';
import UploadDocuments from './UploadContracts';

const Title = styled.div`
    font-family: Open Sans;
    font-weight: 600;
    font-size: 28px;
	font-style: normal;
    line-height: 38px;
    color: #2e2e32;

    margin-bottom: 24px;
`;

const Container = styled.div`
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
`;
export default function Contracts() {

    const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
    const actions = useContractsStore(({ actions }) => actions);
    const isFranchContractsExist = useContractsStore((state) => state.offices).length > 0;
    React.useEffect(() => {
        if (agentInfo?.agents?.name.includes('F&S')) {
          actions.getOfficeByAgency();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <Container>
            <Title>Договоры</Title>
            <ContractsInfo />
            <UploadDocuments/>
            <ContactsList />
            {(agentInfo?.agents?.name.includes('F&S') && isFranchContractsExist) && <FranchContractsList />}
        </Container>
    );
}

