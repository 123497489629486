
import React from 'react';
import { SvgIcon } from "@mui/material";

const Stars = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16" style={{ width: 16, height: 16 }}>
            <path d="M14.6673 6.73459C14.734 6.40126 14.4673 6.00126 14.134 6.00126L10.334 5.46793L8.60065 2.00126C8.53398 1.86793 8.46732 1.80126 8.33398 1.73459C8.00065 1.53459 7.60065 1.66793 7.40065 2.00126L5.73398 5.46793L1.93398 6.00126C1.73398 6.00126 1.60065 6.06793 1.53398 6.20126C1.26732 6.46793 1.26732 6.86793 1.53398 7.13459L4.26732 9.80126L3.60065 13.6013C3.60065 13.7346 3.60065 13.8679 3.66732 14.0013C3.86732 14.3346 4.26732 14.4679 4.60065 14.2679L8.00065 12.4679L11.4007 14.2679C11.4673 14.3346 11.6007 14.3346 11.734 14.3346C11.8007 14.3346 11.8007 14.3346 11.8673 14.3346C12.2007 14.2679 12.4673 13.9346 12.4007 13.5346L11.734 9.73459L14.4673 7.06793C14.6007 7.00126 14.6673 6.86793 14.6673 6.73459Z" fill="#FFE100" />
        </SvgIcon>
    )
}

export default Stars