import * as React from 'react'
import styled from 'styled-components';
import { Box } from '@material-ui/core'
// import PersonIcon from '../asset/images/Person.svg'
import PencilSquareIcon from '../asset/images/PencilSquare.svg'
// import CreditCardIcon from '../asset/images/CreditCard.svg'
// import TagIcon from '../asset/images/Tag.svg'
// import BriefcaseIcon from '../asset/images/Briefcase.svg'
// import FileEarmarkIcon from '../asset/images/FileEarmark.svg'
// import ChartIcon from '../asset/images/Chart.svg'
// import InboxIcon from '../asset/images/Inbox.svg'
import GearIcon from '../asset/images/gear.svg'
import AirplaneIcon from '../asset/images/airplane.svg'
import PassengerIcon from '../asset/images/passenger.svg'
import {Outlet,useLocation} from 'react-router-dom'
import {
	a11yProps,
	MenuTab,
	MenuTabs,
	TabLink,
	AviaFinancialBalanceBlock
} from '../components/Cabinet'
import {
	checkAuthStatus,
	getPassanagersRequest,
	getUserStatusState,
	updateUserStatusToken,
} from '../store/modules/user'
import {getAgentsBalance} from '../store/modules/agents/agent-finance/duck'
import {useDispatch,useSelector} from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useAuthStore from "../pages/Auth/store";
import Auth from "../pages/Auth";


const theme = createTheme({
	palette: {
		primary: {
			main: '#4872f2',
			light: '#deebff',
			dark: '#0052cc'
		},
		secondary: {
			main: '#ffe100',
			dark: '#ffcd00',
		},
		gray: {
			light: '#D9D9D9',
			main: '#3c3c3c',
			// dark: '#ffcd00',
		},
		lightBlue: {
			main: '#EDF1FE',
			contrastText: '#4872F2',
			// light: '#deebff',
			dark: '#d7dffa'
		}
	}
})

const Content = styled.div`
	flex-grow: 1;
	padding: 24px;
	overflow: auto;
`;

export default function Cabinet() {
	const {pathname} = useLocation();
  const dispatch = useDispatch();
	const TTEToken = useAuthStore(({ tokenTTE }) => tokenTTE)
	const tteStatuses = useSelector(getUserStatusState);
	const value = pathname.split('/')[2];
	const isAgentRequestPath = window.location.pathname === '/cabinet/avia-sale-request'

	const oldPage = React.useRef(pathname)

	React.useEffect(() => {
		if (!!TTEToken) {
			checkAuthStatus()
			dispatch(updateUserStatusToken())
			dispatch(getAgentsBalance())
			dispatch(getPassanagersRequest())
		}
		// eslint-disable-next-line
	}, [TTEToken])

	React.useEffect(() => {
		if (pathname !== oldPage.current) {
			try {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			} catch (error) {
				// for older browser
				window.scrollTo(0, 0)
			}
			oldPage.current = pathname
		}
	}, [pathname])

	if(tteStatuses.isFetching) {
		// return null;
	}
	return (
		<>
			{TTEToken || isAgentRequestPath
				?
				<ThemeProvider theme={theme}>
				<Box
					sx={{
						flexGrow: 1,
						bgcolor: 'background.paper',
						display: 'flex',
						justifyContent: 'start',
						width: '1170px',
						margin: 'auto',
						marginBottom: '30px',
						border: '1px solid #DCDCDC',
						boxShadow: '0px 5px 10px rgb(0 0 0 / 5%)',
						borderRadius: 8,
						overflow: 'hidden',
					}}>
					<MenuTabs
						orientation='vertical'
						value={value}
						aria-label='Vertical tabs example'
						sx={{borderRight: 1, borderColor: 'divider', width: 270}}>
						{/* <MenuTab
						icon={PersonIcon}
						label='Мои данные'
						value="profile"
						to="/cabinet/profile"
						{...a11yProps("/cabinet/profile")}
						component={TabLink}
					/> */}
						{tteStatuses.token !== '' && <MenuTab
							icon={PencilSquareIcon}
							label='Заявки'
							value="orders"
							to="/cabinet/orders"
							{...a11yProps("/cabinet/orders")}
							component={TabLink}
						/>}
						{/* <MenuTab
						icon={CreditCardIcon}
						label='Оплата'
						value="/cabinet/payment"
						to="/cabinet/payment"
						{...a11yProps("/cabinet/payment")}
						component={TabLink}
					/>
					<MenuTab
						icon={TagIcon}
						label='Бонусы'
						value="/cabinet/bonuses"
						to="/cabinet/bonuses"
						{...a11yProps("/cabinet/bonuses")}
						component={TabLink}
					/>
					<MenuTab
						icon={BriefcaseIcon}
						label='Договоры'
						value="/cabinet/something"
						to="/cabinet/something"
						{...a11yProps("/cabinet/something")}
						component={TabLink}
					/>
					<MenuTab
						icon={FileEarmarkIcon}
						label='Акт об оплате'
						value="/cabinet/act"
						to="/cabinet/act"
						{...a11yProps("/cabinet/act")}
						component={TabLink}
					/>
					<MenuTab
						icon={ChartIcon}
						label='Отчеты'
						value="/cabinet/otcheti"
						to="/cabinet/otcheti"
						{...a11yProps("/cabinet/otcheti")}
						component={TabLink}
					/>
					<MenuTab
						icon={InboxIcon}
						label='Комиссия'
						value="/cabinet/comission"
						to="/cabinet/comission"
						{...a11yProps("/cabinet/comission")}
						component={TabLink}
					/> */}
						{tteStatuses.token !== '' && <MenuTab
							icon={GearIcon}
							label='Финансы и настройки'
							value="avia-agent-info"
							to="/cabinet/avia-agent-info"
							{...a11yProps("/cabinet/avia-agent-info/finances")}
							component={TabLink}
						/>}
						<MenuTab
							icon={AirplaneIcon}
							label='Запрос на продажу <br/> авиабилетов'
							value="avia-sale-request"
							to="/cabinet/avia-sale-request"
							{...a11yProps("/cabinet/avia-sale-request")}
							component={TabLink}
						/>
						{tteStatuses.token !== '' && <MenuTab
							icon={PassengerIcon}
							label='Мои пассажиры'
							value="my-passengers"
							to="/cabinet/my-passengers"
							{...a11yProps("/cabinet/my-passengers")}
							component={TabLink}
						/>}
						{tteStatuses.token !== '' && <AviaFinancialBalanceBlock/>}
					</MenuTabs>
					<Content>
						<Outlet/>
					</Content>
				</Box>
				</ThemeProvider>
				:
				<Auth />
			}
		</>
	)
}
