import { Box, ClickAwayListener, Fade, Popper } from '@material-ui/core'
import * as React from 'react'
import useSearchTourStore from '../../../pages/SearchTour/store'
import DepartureCity from './DepartureCity'

export default function DepartureCityPopup({
	open,
	anchorEl,
	setOpen
}) {
	const canBeOpen = open && Boolean(anchorEl)
	const id = canBeOpen ? 'transition-popper' : undefined
	const onClose = () => {
		setOpen(false)
	}

	const cities = useSearchTourStore(({ departureCities }) => departureCities)
	const actions = useSearchTourStore(({ actions }) => actions)

	React.useEffect(() => {
		actions.getDeparture()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const handleChangeDepartureCity = id => {
		actions.changeCity(id)
	}
	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)

	return (
		<div>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				transition
				className='popper-search'>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={onClose}>
						<Fade {...TransitionProps} timeout={0}>
							<Box
								sx={{
									border: 1,
									bgcolor: 'background.paper'
								}}>
								<DepartureCity
									value={searchParams.departureCityId}
									lists={cities}
									onChange={handleChangeDepartureCity}
									onClose={onClose}
								/>
							</Box>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</div>
	)
}
