import React from 'react';
import {ClickAwayListener, Grow, MenuItem, MenuList, Popper} from "@mui/material";
import {Button, Paper} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import useAuthStore from "../../pages/Auth/store";
import {Link} from "react-router-dom";
import ym from "react-yandex-metrika";
import {useDispatch} from "react-redux";
import {userLogout} from "../../store/modules/user";
// import { useNavigate } from 'react-router';


const AccountAuth = ({ openCabinet }) => {

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	// const navigate = useNavigate()
	const actions = useAuthStore(({actions}) => actions)
	const agent = localStorage.getItem('agentInfo')
	const agents = JSON.parse(agent)?.agents
	const agentsUsers = JSON.parse(agent)?.agentsUsers
	const samoLinks = useAuthStore(({ samoLinks }) => samoLinks)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	const dispatch = useDispatch()

	const franch = agentInfo?.agents?.name.includes('F&S') || agentInfo?.partPass === '91554' || agentInfo?.partPass === '121374'

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const Logout = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		actions.logout();
		document.cookie = 'access_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		dispatch(userLogout())
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<div style={{whiteSpace: 'nowrap'}}>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				style={{textTransform: 'unset'}}
			>
				{agents?.officialName}<ArrowDropDownIcon />
			</Button>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
									<MenuItem>
										{franch ? <a
											rel={'noreferrer'}
											href={'/cabinet/my-details'}
											style={{display: 'flex', textAlign: 'left', padding: 0}}
											onClick={() => {
												setOpen(false);
												ym('reachGoal', 'click_LK_newb2b')
											}}

											>
											<PersonIcon fontSize={'small'} style={{marginRight: 5}}/>
											<div>
												{agentsUsers?.find(item => item.partpassId === Number(JSON.parse(agent).partPass))?.fullName || agents?.officialName}
												<div style={{fontSize: 10}}>Турагент</div>
											</div>
										</a> :
										<a
											rel={'noreferrer'}
											href={samoLinks['cl_refer'] || 'https://b2b.fstravel.com/cl_refer'}
											target={'_blank'}
											style={{display: 'flex', textAlign: 'left', padding: 0}}
											onClick={() => {
												openCabinet();
												ym('reachGoal', 'click_LK_newb2b')
											}}

										>
											<PersonIcon fontSize={'small'} style={{marginRight: 5}}/>
											<div>
												{agentsUsers?.find(item => item.partpassId === Number(JSON.parse(agent).partPass))?.fullName || agents?.officialName}
												<div style={{fontSize: 10}}>Турагент</div>
											</div>
										</a>}
									</MenuItem>
									<MenuItem onClick={Logout}>
										<div style={{display: 'flex', alignItems: 'center'}}>
											<ClearIcon fontSize={'small'} style={{marginRight: 5}} />Покинуть систему
										</div>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

export default AccountAuth;
