
import MembersBlock from './components/MembersBlock'
import BabyBlock from './components/BabyBlock'


function PaxesBlock({ paxes, freeinfant }) {
    const baby = paxes.findLast((paxe) => paxe.person.ageGroup === 3);
    return (
        <>
            {paxes && paxes.map((member, i) => {
                if (member.id === baby?.id) return null
                return (
                    <MembersBlock key={member + i} count={i + 1} membersCount={paxes.length} />
                )
            })}
            {freeinfant &&
                <BabyBlock
                    babyId={baby?.id}
                />
            }
        </>
    )
}

export default PaxesBlock