export const UserStatusInitialState = {
  isAuthorized: true,
  isFetching: true,
  token: parseValidJson(window.localStorage.getItem('tte_token'))?.accessToken || "",
  error: null,
}

export function parseValidJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}
