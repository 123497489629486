import { v4 as uuid } from "uuid";
import './style.scss'

const filterCard = (item) => item?.text || item?.title || item?.description

function ButtonsWithTitleTemplate({ content }) {
    return <div className='buttons-with-title-template'>
        {<Card
            key={uuid()}
            link={content?.all_link}
            title={content?.placeholder}
            description={content?.description}
            textColor={content?.textColor}
            text={content?.all_text}
            color={content?.color}
        />}
        {content?.buttons?.filter(filterCard)?.map(item =>
            <Card key={uuid()}
                link={item?.link}
                title={item?.title}
                description={item?.description}
                textColor={item?.textColor}
                text={item?.text}
                color={item?.color}
            />
        )}
    </div>
}

const Card = ({ link, description, title, text, color, textColor }) => {
    return <div className='btn-block-wrapper'>
        <div className='btn-block-wrapper__text'>
            <h5 className='btn-block-wrapper__text__title'>{title}</h5>
            <p className='btn-block-wrapper__text__desc'>
                {description}
            </p>
        </div>
        {link &&
            <a
                href={link}
                style={{ backgroundColor: color, color: textColor }}
                className='btn-block-wrapper__button'>
                {text}
            </a>}
    </div>
}

export default ButtonsWithTitleTemplate