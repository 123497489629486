import { Breadcrumbs, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import useActionsStore from './store'
import BlockWithActions from './BlockWithActions'
import { isEmpty } from 'lodash'
import CountrySelect from './SelectFields/CountrySelect'
import RegionSelect from './SelectFields/RegionSelect'
import {Helmet} from "react-helmet";

export default function Actions() {
	const actions = useActionsStore(({ actions }) => actions)
	const action = useActionsStore(({ action }) => action)
	const [country, setCountry] = React.useState('Все')
	const handleChange = event => {
		setCountry(event.target.value)
		setRegion('Все')
	}
	const [region, setRegion] = React.useState('Все')
	const handleChangeRegion = event => {
		setRegion(event.target.value)
	}
	const filteredActions = useMemo(() => {
		return country !== 'Все'
			? action?.filter(
				item =>
					!isEmpty(
						item.country.filter(item => item.localizedName.includes(country))
					)
			)
			: action
	}, [country, action])

	useEffect(() => {
		actions.getCountries()
	}, [actions])
	// console.log('filteredActions', filteredActions)

	return (
		<div className='container'>
			<Helmet title={'Акции'} />
			<Breadcrumbs aria-label='breadcrumb' className={'breadcrumbs-maps'}>
				<Link color='inherit' to={'/'}>
					Главная
				</Link>
				<Typography color='#4872f2'>Акции</Typography>
			</Breadcrumbs>
			<h1 align={'center'}>Акции</h1>
			<div className='select-block-actions'>
				<CountrySelect
					actionsList={action}
					country={country}
					handleChange={handleChange}
				/>
				<RegionSelect
					actionsList={filteredActions}
					region={region}
					handleChangeRegion={handleChangeRegion}
					country={country}
				/>
			</div>
			<BlockWithActions
				filteredActions={
					region !== 'Все'
						? filteredActions?.filter(
							item =>
								!isEmpty(
									item.regions.filter(item =>
										item.localizedName.includes(region)
									)
								)
						)
						: filteredActions
				}
			/>
		</div>
	)
}
