import React, { useEffect, useState } from 'react';
import useAccentStore from "./store";
import SelectFields from "./components/SelectFields";

import './style.css'
import SortIcon from "../../asset/icons/Sort";
import Offer from "./components/Offer";
import { isEmpty, orderBy, uniqBy } from "lodash";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { utils, writeFile } from 'xlsx';

const SalesAccents = () => {

	const actions = useAccentStore(({ actions }) => actions)

	const geo = useAccentStore(({ geo }) => geo)
	const countries = useAccentStore(({ countries }) => countries)
	const countryId = useAccentStore(({ countryId }) => countryId)
	const regions = useAccentStore(({ regions }) => regions)
	const regionId = useAccentStore(({ regionId }) => regionId)
	const accents = useAccentStore(({ accents }) => accents)
	const offers = useAccentStore(({ offers }) => offers)
	const isAllCountry = useAccentStore(({ isAllCountry }) => isAllCountry)

	const [sort, setSort] = useState({ name: 'price.value', order: 'asc' })
	const [filter, setFilter] = useState('all')

	const downloadxls = (e, data) => {
		const fromFile = data.map(item => {
			return {
				"Страна": item.country?.name,
				"Регион": item.region?.name,
				"Дата": item.formattedDate,
				"Длительность": item.accent.days,
				"Название отеля": item.hotelName,
				"Цена": item.priceStr,
				"Ссылка на бронирование": item.bookingLink
			}
		})
		e.preventDefault();
		const ws = utils.json_to_sheet(fromFile);

		ws["!cols"] = [{ width: 30 }, { width: 30 }, { width: 12 }, { width: 14 }, { width: 40 }, { width: 20 }, { width: 80 }]
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "SheetJS");
		/* generate XLSX file and send to client */
		writeFile(wb, "accent-sales.xlsx");
	};

	const handleChangeSort = (field) => {
		if (sort.name === field) {
			setSort({ name: field, order: sort.order === 'asc' ? 'desc' : 'asc' })
		} else {
			setSort({ name: field, order: 'asc' })
		}
	}

	const handleChangeFilter = (field) => {
		setFilter(field)
	}

	const filteredOffers = () => {
		switch (filter) {
			case 'all':
				return offers
			case 'winter':
				return offers.filter(item => item.accent?.category_type === 'winter')
			case 'summer':
				return offers.filter(item => item.accent?.category_type === 'summer')
			case 'thematic':
				return offers.filter(item => item.accent?.category_type === 'thematic')
			default:
				return offers
		}
	}

	const filtered = filteredOffers()

	useEffect(() => {
		actions.getSalesGeo()
	}, [actions])

	useEffect(() => {
		if (geo) {
			actions.getCountries()
		}
	}, [actions, geo])

	useEffect(() => {
		if (countryId) {
			actions.getRegions(countryId)
		}
	}, [actions, countryId])

	useEffect(() => {
		if (countryId === 0) {
			actions.getSalesAccentByAllCountry()
			return
		}
		if (countryId && regionId) {
			actions.getSalesAccent()
		}
	}, [countryId, regionId, actions])

	useEffect(() => {
		if (accents) {
			accents.forEach(accent => actions.getOffers(accent))
		}
	}, [accents, actions])

	if (!countries || !regions) return null

	return (
		<div className={'container sales-accent'}>
			<Helmet>
				<title>Акценты продаж</title>
			</Helmet>
			<h1 align={'center'}>Акценты продаж</h1>
			<div className='select-block'>
				<SelectFields lists={countries} title={'Выберите страну'} value={countryId} isCountry onChange={actions.changeCountry} />
				<SelectFields lists={regions} title={'Выберите регион'} value={regionId} onChange={actions.changeRegion} disabled={isAllCountry} />
			</div>
			<div className={'static-link'}>
				<a href={'https://agentb2b.fstravel.com/metodiceskie-posobiya'} target={'_blank'} rel={'noreferrer'}>Информация о направлении</a>
				<a href={'https://agentb2b.fstravel.com/actions'} target={'_blank'} rel={'noreferrer'}>Акции</a>
			</div>
			<div className={'filter-panel'}>
				<div className={`item ${filter === 'all' ? 'selected' : ''}`} onClick={() => handleChangeFilter('all')}>Все</div>
				<div className={`item ${filter === 'winter' ? 'selected' : ''}`} onClick={() => handleChangeFilter('winter')}>Зимний сезон</div>
				<div className={`item ${filter === 'summer' ? 'selected' : ''}`} onClick={() => handleChangeFilter('summer')}>Летний сезон</div>
				<div className={`item ${filter === 'thematic' ? 'selected' : ''}`} onClick={() => handleChangeFilter('thematic')}>Тематические туры</div>
			</div>
			<div className={'sorting-panel'}>
				<div className={`item`}>Сортировать по:</div>
				<div className={'item'} onClick={() => handleChangeSort('price.value')}>
					Цена
					{sort.name === 'price.value' && <SortIcon className={sort.order} />}
				</div>
				<div className={'item'} onClick={() => handleChangeSort('daysCount')}>
					Ночи
					{sort.name === 'daysCount' && <SortIcon className={sort.order} />}
				</div>
				<div className={'item'} onClick={() => handleChangeSort('hotelName')}>
					Отель
					{sort.name === 'hotelName' && <SortIcon className={sort.order} />}
				</div>
				<div className={'people'}>Цена тура за 1 человека, от</div>
			</div>
			{offers && orderBy(uniqBy(filtered, 'accent.id'), sort.name, sort.order).map(offer => <Offer offer={offer} />)}
			{!isEmpty(offers) && !isEmpty(filtered) &&
				<div className={'download-accent'}>
					<Button variant={'contained'} color={'primary'} fullWidth onClick={(e) => downloadxls(e, orderBy(uniqBy(filtered, 'accent.id'), sort.name, sort.order))}>
						Скачать
					</Button>
				</div>
			}
		</div>
	);
};



export default SalesAccents;