
import './style.scss'
function WorkTime({ content }) {
    return <div className='work-time'>
        {content?.header && <div className='top' dangerouslySetInnerHTML={{ __html: content.header }} />}
        {content?.left && <div className='left' dangerouslySetInnerHTML={{ __html: content.left }} />}
        {content?.right && <div className='right' dangerouslySetInnerHTML={{ __html: content.right }} />}
        {content?.footer && <div className='footer' dangerouslySetInnerHTML={{ __html: content.footer }} />}
    </div>
}

export default WorkTime