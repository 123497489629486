import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { CloseOutlined } from "@material-ui/icons";
import WideIcon from "../../../../asset/icons/ExcursionTour/WideIcon";
import { isEmpty } from "lodash";

import "./style.scss";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const BASE_IMAGE_URL =
    "https://apigate-tui.fstravel.com/api/geocontent/static/ExcursionTour/";

function ExcursionSlider({ slides, id }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [isOpen, setOpen] = useState(false)
    const refModal = useRef(null)
    const refMain = useRef(null)

    useEffect(() => {
        function handleClick(event) {
            if (refModal.current && !refModal.current.contains(event.target)) {
                setOpen(!isOpen)
            }
        }
        document.addEventListener("mousedown", handleClick)
        return () => {
            document.removeEventListener("mousedown", handleClick)
        };
    }, [refModal, isOpen])

    if (isEmpty(slides)) return null

    const sortedSlides = slides
        .filter((i) => i.resolutionType === "Source" || i.resolutionType === "OtherHotels")
        .sort((a, b) => (b.imageType === "MainPhoto") - (a.imageType === "MainPhoto"))

    return (
        <div className="slider-wrapper">
            <div className="main-slider">
                <Swiper
                    ref={refMain}
                    modules={[Thumbs, Navigation]}
                    navigation
                    thumbs={{ swiper: thumbsSwiper }}
                >
                    {sortedSlides.map((item) => (
                        <SwiperSlide key={item.fileName}>
                            <div
                                className="main-img"
                                style={{
                                    backgroundImage: `url(${BASE_IMAGE_URL}${id}/${item.imageType}-${item.resolutionType}-${item.fileName})`,
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="open-block" onClick={() => setOpen(!isOpen)}>
                    <WideIcon />
                </div>
            </div>

            <div className="thumb-slider">
                <Swiper
                    modules={[Thumbs, Navigation]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    navigation
                    slidesPerView={2}
                    breakpoints={{
                        780: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    {sortedSlides.map((item) => (
                        <SwiperSlide key={item.fileName}>
                            <div
                                className="thumbs-img"
                                style={{
                                    backgroundImage: `url(${BASE_IMAGE_URL}${id}/${item.imageType}-${item.resolutionType}-${item.fileName})`,
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {isOpen && (
                <div className="main-slider_modal">
                    <div className="modal-body" ref={refModal}>
                        <div className="close-btn" onClick={() => setOpen(!isOpen)}>
                            <CloseOutlined />
                        </div>
                        <Swiper
                            modules={[Navigation]}
                            navigation
                            initialSlide={refMain?.current?.swiper?.activeIndex ?? 0}
                        >
                            {sortedSlides.map((item) => (
                                <SwiperSlide key={item.fileName}>
                                    <div
                                        className="main-img"
                                        style={{
                                            backgroundImage: `url(${BASE_IMAGE_URL}${id}/${item.imageType}-${item.resolutionType}-${item.fileName})`,
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExcursionSlider;
