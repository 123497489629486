import React from 'react';
import FilterList from './FilterList';
import styled from 'styled-components';
import ResetButton from './ResetButton';

const StyledFilterList = styled(FilterList)`
  width: 255px;
  margin-right: 26px;
`;

const Template = () => {
  return (
    <StyledFilterList>
      <ResetButton />
    </StyledFilterList>
  );
};

export default Template;
