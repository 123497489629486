import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { CloseOutlined } from "@material-ui/icons";
import { useState } from "react";
import NavIcon from "../../../../../asset/icons/ExcursionTour/NavIcon";

function ViewModal({ lists, onClose, initialSlide = 0 }) {
    const [navSlider, setNavSlider] = useState(null);

    const [currentSlide, setCurrentSlide] = useState(initialSlide)

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.activeIndex)
    }

    return (
        <div className="view-modal">
            <div className="view-modal__nav view-modal-slider__prev">
                <NavIcon style />
            </div>
            <div className="view-modal__content">
                <div className="view-modal__head">
                    {currentSlide + 1} / {lists?.length}
                    <CloseOutlined onClick={() => onClose(false)} />
                </div>
                <div>
                    <div>
                        <Swiper
                            thumbs={{ swiper: navSlider }}
                            initialSlide={currentSlide}
                            direction="horizontal"
                            slidesPerView={1}
                            spaceBetween={24}
                            navigation={{
                                nextEl: ".view-modal-slider__next",
                                prevEl: ".view-modal-slider__prev",
                            }}
                            breakpoints={{
                                0: {
                                    direction: "horizontal",
                                },
                                768: {
                                    direction: "horizontal",
                                },
                            }}
                            onSlideChange={handleSlideChange}
                            className="view-modal__slider"
                            modules={[Navigation, Thumbs]}
                        >
                            {lists.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <div
                                        className="view-modal__slide"
                                        style={{ backgroundImage: `url(${item.src})` }}
                                    ></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="view-modal__swiper-2">
                        <Swiper
                            onSwiper={setNavSlider}
                            direction="horizontal"
                            spaceBetween={10}
                            slidesPerView={5}
                            watchSlidesProgress={true}
                            navigation={{
                                nextEl: ".view-modal-slider__next",
                                prevEl: ".view-modal-slider__prev",
                            }}
                            className="view-modal__slider-thumb"
                            breakpoints={{
                                0: {
                                    direction: "horizontal",
                                },
                                768: {
                                    direction: "horizontal",
                                },
                            }}
                            modules={[Navigation, Thumbs]}
                        >
                            {lists.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <div
                                        className="view-modal__slide-thumb"
                                        style={{ backgroundImage: `url(${item.src})` }}
                                    ></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="view-modal__nav view-modal-slider__next">
                <NavIcon />
            </div>
        </div>
    );
}

export default ViewModal;
