import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
    display: block;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const TabelContacts = styled.div`
    width: 100%;
    background: #f6f8fa;
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    padding: 24px;
`;
const TabelContactsHead = styled.div`
    border-bottom: 1px solid #dbe0e5;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 16px;
`;

const TabelContactsTitle = styled.div`
    width: 164px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`;

const TabelContactsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TabelContactsText = styled.div`
    width: 164px;
    font-family: Open Sans;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 16px;
`;

const contacts = [
    {
        name: 'Буравихина Наталья',
        region: 'Санкт-Петербург',
        phone: '+7 (812) 604 00 81',
        additionalNumber: 'доб. 7134',
        mail: 'spb@fstravel.com',
    },
    {
        name: 'Тайра Лилия',
        region: 'Казань и  Набережные Челны',
        phone: '+7 (843) 212 02 50',
        additionalNumber: 'доб. 7204',
        mail: 'kazan@fstravel.com',
    },
    {
        name: 'Романова Софья',
        region: 'Казань и  Набережные Челны',
        phone: '+7 (843) 212 02 50',
        additionalNumber: 'доб. 7202',
        mail: 'kazan@fstravel.com',
    },
    {
        name: 'Карташова Анна',
        region: 'Самара',
        phone: '+7 (846) 212 09 59',
        additionalNumber: 'доб. 7222',
        mail: 'samara@fstravel.com',
    },
    {
        name: 'Диордица Наталия',
        region: 'Челябинск',
        phone: '+7 (351) 225 32 13',
        mail: 'chel@fstravel.com',
    },
    {
        name: 'Барт Татьяна',
        region: 'Уфа, Магнитогорск',
        phone: '+7 (347) 291 27 75',
        additionalNumber: 'доб. 7271',
        phone1: '+7 (916) 411 95 30',
        mail: 'ufa@fstravel.com',
    },
    {
        name: 'Мануйлова Анастасия',
        region: 'Краснодар, Сочи',
        phone: '+7 (861) 298-21-76',
        additionalNumber: 'доб. 7253',
        mail: 'krasnodar@fstravel.com',
    },
    {
        name: 'Мироненко Артем',
        region: 'Новосибирск',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7294',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Степанченко Юлия',
        region: 'Пятигорск',
        phone: '+7 (8793) 40 10 70',
        additionalNumber: 'доб. 7212',
        mail: 'kmv@fstravel.com',
    },
    {
        name: 'Кошель Юлия',
        region: 'Ростов-на-Дону',
        phone: '+7 (863) 236 20 62',
        additionalNumber: 'доб. 7281',
        mail: 'rostov@fstravel.com',
    },
    {
        name: 'Мелихова Татьяна',
        region: 'Воронеж',
        phone: '+7 (473) 202 06 05',
        phone1: '+7 (919) 773 18 11',
        mail: 'voronezh@fstravel.com',
        mail1: 'belgorod@fstravel.com',
    },
    {
        name: 'Кошель Юлия',
        region: 'Брянск',
        phone: '+7 (863) 236 20 62',
        additionalNumber: 'доб. 7281',
        mail: 'bryansk@fstravel.com',
        mail1: 'rostov@fstravel.com',
    },
    {
        name: 'Мелихова Татьяна',
        region: 'Белгород',
        phone: '+7 (473) 202 06 05',
        phone1: '+7 (919) 773 18 11',
        mail: 'voronezh@fstravel.com',
        mail1: 'belgorod@fstravel.com',
    },
    {
        name: 'Севрук Юлия',
        region: 'Нижний Новгород',
        phone: '+7 (831)  202 43 24',
        mail: 'n.novgorod@fstravel.com',
    },
    {
        name: 'Найданова Анна',
        region: 'Пермь',
        phone: '+7 (342) 269 02 41',
        mail: 'perm@fstravel.com',
    },

    {
        name: 'Ладейщикова Юлия',
        region: 'Нижневартовск',
        phone: '+7 (343) 287 87 24',
        additionalNumber: 'доб. 7242',
        mail: 'ekb@fstravel.com',
        mail1: 'n-vartovsk@fstravel.com',

    },
    {
        name: 'Журавская Анна',
        region: 'Тюмень',
        phone: '+7 (345) 249 40 72',
        mail: 'tmn@fstravel.com',
    },
    {
        name: 'Чулисова Алёна',
        region: 'Красноярск',
        phone: '+7 (391) 254 08 02',
        mail: 'krasnoyarsk@fstravel.com',
    },
    {
        name: 'Гартмансен Юлия',
        region: 'Хабаровск',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7293',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Мироненко Артем',
        region: 'Владивосток',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7294',
        mail: '@fstravel.com',
    },
    {
        name: 'Буравихина Наталья',
        region: 'Калининград',
        phone: '+7 (812) 604 00 81',
        additionalNumber: 'доб. 7134',
        mail: 'kaliningrad@fstravel.com',
        mail1: 'spb@fstravel.com',

    },
    {
        name: 'Мироненко Артем',
        region: 'Иркутск',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7294',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Ладейщикова Юлия',
        region: 'Сургут',
        phone: '+7 (343) 287 87 24',
        additionalNumber: 'доб. 7242',
        mail: 'surgut@fstravel.com',
        mail1: 'ekb@fstravel.com',

    },
    {
        name: 'Хамицаева Зарина',
        region: 'Владикавказ, Махачкала, Грозный',
        phone: '+7 (495) 926 45 42',
        additionalNumber: 'доб. 7671',
        mail: 'vladikavkaz@fstravel.com',
    },
    {
        name: 'Пономарёва Александра',
        region: 'Волгоград',
        phone: '+7 (8442) 60 22 04',
        mail: 'volgograd@fstravel.com',
    },
    {
        name: 'Буравихина Наталья',
        region: 'Архангельск',
        phone: '+7 (812) 604 00 81',
        additionalNumber: 'доб. 7134',
        mail: 'arkhangelsk@fstravel.com',
        mail1: 'spb@fstravel.com',
    },
    {
        name: 'Пономарёва Александра',
        region: 'Астрахань',
        phone: '+7 (8442) 60 22 04',
        mail: 'astrakhan@fstravel.com',
        mail1: 'volgograd@fstravel.com',
    },
    {
        name: 'Гартмансен Юлия',
        region: 'Кемерово',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7293',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Гартмансен Юлия',
        region: 'Омск',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7293',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Карташова Анна',
        region: 'Оренбург',
        phone: '+7 (846) 212 09 59',
        additionalNumber: 'доб. 7222',
        mail: 'orenburg@fstravel.com',
        mail1: 'samara@fstravel.com',
    },
    {
        name: 'Карташова Анна',
        region: 'Саратов',
        phone: '+7 (846) 212 09 59',
        additionalNumber: 'доб. 7222',
        mail: 'saratov@fstravel.com',
        mail1: 'samara@fstravel.com',
    },
    {
        name: 'Буравихина Наталья',
        region: 'Сыктывкар',
        phone: '+7 (812) 604 00 81',
        additionalNumber: 'доб. 7134',
        mail: 'syktyvkar@fstravel.com',
        mail1: 'spb@fstravel.com',
    },
    {
        name: 'Мироненко Артем',
        region: 'Томск',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7294',
        mail: 'tomsk@fstravel.com',
        mail1: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Карташова Анна',
        region: 'Ульяновск',
        phone: '+7 (846) 212 09 59',
        additionalNumber: 'доб. 7222',
        mail: '@fstravel.com',
    },
    {
        name: 'Гартмансен Юлия',
        region: 'Барнаул',
        phone: '+7 (383) 367 07 76',
        additionalNumber: 'доб. 7293',
        mail: 'novosibirsk@fstravel.com',
    },
    {
        name: 'Ясинецкая Марина',
        region: 'Екатеринбург',
        phone: '+7 (343) 287 87 24',
        additionalNumber: 'доб. 7241',
        mail: 'ekb@fstravel.com',
    },
    {
        name: 'Кошель Юлия',
        region: 'Калуга',
        phone: '+7 (863) 236 20 62',
        additionalNumber: 'доб. 7287',
        mail: 'kaluga@fstravel.com',
    },
];
const ReginFlightsCurators = () => {

    return (
        <>
            <Row
                style={{ cursor: 'pointer' }}
            >
                <FlexRow>
                    <TabelContacts>
                        <TabelContactsHead>
                            <TabelContactsTitle>
                                ФИО кураторов
                            </TabelContactsTitle>
                            <TabelContactsTitle>Регион</TabelContactsTitle>
                            <TabelContactsTitle>Телефон</TabelContactsTitle>
                            <TabelContactsTitle>Почта</TabelContactsTitle>
                        </TabelContactsHead>
                        {contacts?.map((contact) => (
                            <TabelContactsRow>
                                <TabelContactsText>
                                    {contact.name}
                                </TabelContactsText>
                                <TabelContactsText>
                                    {contact.region}
                                </TabelContactsText>
                                <TabelContactsText>
                                    {contact.phone}
                                    {contact.additionalNumber && (
                                        <>
                                            <br />
                                            {contact.additionalNumber}
                                        </>
                                    )}
                                    {contact.phone1 && (
                                        <>
                                            <br />
                                            {contact.phone1}
                                        </>
                                    )}
                                </TabelContactsText>
                                <TabelContactsText>
                                    {contact.mail}
                                    {contact.mail1 && (
                                        <>
                                            <br />
                                            {contact.mail1}
                                        </>
                                    )}
                                </TabelContactsText>
                            </TabelContactsRow>
                        ))}
                    </TabelContacts>
                </FlexRow>
            </Row>
        </>
    );
};

export default ReginFlightsCurators;
