import { Box, Button, IconButton, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CloseOutlinedIcon from '@material-ui/icons/Close'
import useAuthStore from './store'

export default function ForgotPassword({ setOpen }) {
	const { forgotError } = useAuthStore()
	const [changePassword, setChangePassword] = useState(false)
	const [email, setEmail] = useState('')
	const actions = useAuthStore(({ actions }) => actions)
	const handleClose = () => {
		setOpen(false)
	}
	const submitHandler = async email => {
		await actions.forgotPassword(email)
		if (!forgotError) {
			setChangePassword(true)
		}
	}

	useEffect(() => {
		return () => {
			actions.setForgotError()
		}
	}, [actions])

	return (
		<>
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
				<h3>Воccтановить пароль</h3>
				<IconButton onClick={handleClose}>
					<CloseOutlinedIcon />
				</IconButton>
			</Box>
			{!changePassword || !!forgotError ? (
				<>
					<Box mb={2}>
						<TextField
							required
							autoFocus
							margin='dense'
							id='name'
							label='Введите E-mail или логин'
							type='email'
							fullWidth
							variant='standard'
							value={email}
							onChange={e => setEmail(e.target.value)}
							error={!!forgotError}
							helperText={forgotError}
						/>
					</Box>
					<Button
						variant={'contained'}
						color={'primary'}
						fullWidth
						onClick={() => submitHandler(email)}
						disabled={!email}
					>
						Отправить
					</Button>
				</>
			) : (
				<p style={{ color: 'green' }}>
					На указанный e-mail отправлена ссылка для подтверждения.
				</p>
			)}
		</>
	)
}
