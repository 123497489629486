import styled from 'styled-components';
import Tooltip from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import {useState} from "react";
import useClaimsStore from "../../../../store";

// const LightTooltip = styled(({ className, children, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }}>
//         {children}
//     </Tooltip>
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[2],
//         fontSize: 14
//     }
// }))

const LightTooltip = MUIStyled(({ className, color, children, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {children}
    </Tooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 12,
        lineHeight: '22px',
        padding: 0,
        borderRadius: 4
    }
}))

const Place = styled.div`
${({ color = '#fff' }) => { return `background: ${color};` }}
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
`

const TooltipItem = styled.div`
    background-color: #fff;
    text-align: center;
    color: rgba(46, 46, 50, 1);
    padding: 5px 8px;
    gap: 10px;
    border-radius: 4px;
`

const Seat = ({ item, letters, isEmptyCol, colName, color, isLastCol }) => {

    const actions = useClaimsStore(({ actions }) => actions)

    const handleSetPlace = (place) => {
        if (place.frPlacementTypeInc === 1 && place.onlineEnabled) {
            actions.setNewSeat(place)
        }
    }

    if (letters) {
        return <div style={{ marginTop: '8px' }}>
            <Place>{letters}</Place>
        </div>
    }

    if (!item) return null

    return <div style={{ marginTop: '8px' }}>
        <LightTooltip enterDelay={500} leaveDelay={2} title={item?.cellName
            && <TooltipItem >
                <div>+ {item?.price}</div>
                {item?.cellName}
            </TooltipItem >
        }>
            <Place
                color={color}
                style={isEmptyCol ? {margin: "0 8px"} : null}
                style={item.available && item.frPlacementTypeInc === 1 ? {cursor: "pointer"} : null}
                onClick={() => handleSetPlace(item)}
            >
                {(isEmptyCol && colName && isLastCol) && colName?.match(/\d+/)[0]}
            </Place>
        </LightTooltip>
    </div>
}

export default Seat