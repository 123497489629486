import React from 'react'

import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import useFranchisingStore from '../store'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: #f6f8fa;
	border-radius: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

const ComissionBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 24px;
	margin-top: 24px;
`

const Text = styled.div`
	display: flex;
	align-items: center;

	font-family: Open Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	color: #2e2e32;
`

export default function Comission() {
	const [isOpen, setIsOpen] = React.useState(true)
	const comissionData = useFranchisingStore((state) => state.comission)
	const actions = useFranchisingStore((state) => state.actions)
	const commission = comissionData?.find(obj => obj.id === 271)?.title
	const loading = useFranchisingStore((state) => state.loading)
	const franchisingData = useFranchisingStore((state) => state.franchising)
	const comissionTitle = franchisingData?.find(obj  => obj.block === 'commissions')?.title

	React.useEffect(() => {
		actions.getComission()
		// eslint-disable-next-line
	},[])

	if (loading) {
		return <CircularProgress size={20} />
	}

	return (
		<Container>
			<Row
				onClick={() => setIsOpen(!isOpen)}
				style={{ cursor: 'pointer' }}>
				<FlexRow>
					<Title>{comissionTitle}</Title>
				</FlexRow>
				<ExpandMoreIcon
					onClick={() => setIsOpen(!isOpen)}
					style={isOpen ? { transform: 'rotate(180deg)' } : null}
					isActive={isOpen}
				/>
			</Row>
			<Collapse in={isOpen}>
				<ComissionBlock>
					<Text>Ваша комиссия - {commission}</Text>
				</ComissionBlock>
			</Collapse>
		</Container>
	)
}
