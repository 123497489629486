/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import useExcursionTourStore from './store'
import ExcursionSlider from './components/ExcursionSlider'

import './style.scss'
import ExcursionYandexMap from './components/ExcursionYandexMap'

const ExcursionTour = () => {
	const actions = useExcursionTourStore(({ actions }) => actions)
	const excursionTour = useExcursionTourStore(
		({ excursionTour }) => excursionTour
	)
	const excursionProgram = useExcursionTourStore(
		({ excursionProgram }) => excursionProgram
	)

	const [tab, activeTab] = useState(0)
	const scrollRef = useRef(null)

	useEffect(() => {
		const url = window.location.pathname
		actions.getExcursionTour(url.replace('/', ''))
	}, [])

	if (!excursionTour || !excursionProgram) return null

	const handleScroll = () => {
		activeTab(0)
		scrollRef.current &&
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<div className="container excursion-tour">
			<h2 className="title">{excursionTour.localizedName}</h2>
			<div className="excursion-info">
				<ExcursionSlider
					slides={excursionTour?.images}
					id={excursionTour?.id}
				/>
				<div className='info'>
					<div
						dangerouslySetInnerHTML={{
							__html: excursionProgram.shortText !== '' ? excursionProgram.shortText : excursionProgram.shortDescription
						}}
					/>
					<div className='more-btn' onClick={handleScroll}>
						Подробнее
					</div>
				</div>
			</div>
			<div ref={scrollRef} className='tab-head'>
				<div
					onClick={() => activeTab(0)}
					className={tab === 0 ? 'tab-item active' : 'tab-item'}>
					Описание
				</div>
				<div
					onClick={() => activeTab(1)}
					className={tab === 1 ? 'tab-item active' : 'tab-item'}>
					На карте
				</div>
			</div>
			<div>
				{tab === 0 ? (
					<div className='program-content'>
						<h4>Программа тура</h4>
						<div
							dangerouslySetInnerHTML={{ __html: excursionProgram?.program }}
						/>
					</div>
				) : (
					<ExcursionYandexMap />
				)}
			</div>
		</div>
	)
}

export default ExcursionTour
