import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Button } from "@material-ui/core";

const TouristsChangeModal = ({ children, onClose, actions }) => {
	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => (document.body.style.overflow = '')
	})

	return (
		<div className='TouristsChangeModal-bg'>
			<div className='TouristsChangeModal-content'>
				<div
					className='TouristsChangeModal-header'
					onClick={() => onClose(false)} >
					<ArrowBackIosIcon />
					<div className='TouristsChangeModal-title'>Туристы</div>
				</div>
				{children}
				<div className='TouristsChangeModal-buttonBlock'>
					<Button
						fullWidth
						onClick={() => onClose(false)}
						variant={'contained'}
						className={'search-button'}
					>
						Готово
					</Button>
				</div>
			</div>
		</div>
	)
}

export default TouristsChangeModal
