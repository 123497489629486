import { create } from 'zustand'
import axios from 'axios'
import {
	addDays,
	format,
	isBefore,
	parseISO,
	differenceInDays,
	subDays
} from 'date-fns'
import { devtools } from 'zustand/middleware'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const PART_PASS = localStorage.getItem('agentInfo')

const useSearchTourStore = create(
	devtools((set, get) => ({
		currencys: {},
		arrivalRegionIds: [],
		arrivalCityIds: [],
		hotelIds: [],
		stars: [],
		hotelTypes: [],
		meals: [],
		hotelSearch: '',
		citySearch: '',
		departureCities: [],
		arrivalCountries: [],
		filters: {},
		checkins: [],
		program: null,
		tour: null,
		tourType: 0,
		currentCity: 274286,
		currentCountry: null,
		currentFilters: {},
		loading: false,
		errorTours: false,
		childNum: 0,
		isAddInf: false,
		selectedTour: null,
		currencyAlias: 'RUB',
		searchParams: {
			clientId: 'b2b:ru',
			lang: 'ru',
			departureCityId: localStorage.getItem('departureCityId') || '274286',
			arrivalCountryId: '18498',
			adults: '2',
			children: [],
			minStartDate: '2023-06-26T00:00:00',
			maxStartDate: '2023-06-26T00:00:00',
			minNightsCount: '7',
			maxNightsCount: '7',
			currencyId: '1',
			minCost: null,
			maxCost: null,
			hotelIds: [],
			tourType: null,
			tours: [],
			program: null,
			noStopOfSales: true,
			withoutPromo: false,
			hideToursWithoutFreights: true,
			momentConfirm: false,
			includePriorityFilter: false,
			searchScope: 'b2b',
			includeNotAvailableForBooking: true,
			includeExtraOffers: true,
			freightType: 0
		},
		tours: null,
		nights: null,
		offers: null,
		groupByHotel: false,
		groupedResult: false,
		partnerCommission: null,
		tourSearchNote: '',
		cities: [],
		tourName: null,
		sortDirection: 1,
		actions: {
			getTourSearchNote: async withFlights => {
				try {
					const res = await axios({
						url: `${BASE_URL}/TourDetails/GetTourSearchNote`,
						method: 'GET',
						params: {
							ClientId: 'b2c:ru',
							DepartureCityId: withFlights
								? get().searchParams.departureCityId
								: 1,
							ArrivalCountryId: get().currentCountry
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ tourSearchNote: res.data.note })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			getPartnerCommission: async () => {
				const data = {
					partpassId: Number(JSON.parse(PART_PASS)?.partPass),
					dateBeg: get().searchParams.minStartDate,
					tourId: get().tours?.id,
					adultCount: get().searchParams.adult,
					childCount: get().searchParams.children.length,
					infantCount: get().searchParams.children.length,
					nightCount: get().searchParams.adult
				}
				try {
					const res = await axios({
						url: `${BASE_URL}/TourDetails/GetPartnerCommission`,
						method: 'POST',
						data: data,
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ partnerCommission: res.data.partnerCommission })
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			clearFilterHotel: () => {
				set(() => ({ hotelIds: [] }))
				const newSearchParams = {
					...get().searchParams,
					hotelIds: get().hotelIds
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			clearFilterHotelName: () => {
				set(() => ({ hotelSearch: '' }))
			},
			clearFilterCityName: () => {
				set(() => ({ citySearch: '' }))
			},
			clearFilterStars: () => {
				set(() => ({ stars: [] }))
				set(() => ({ hotelTypes: [] }))
				const newSearchParams = {
					...get().searchParams,
					stars: get().stars,
					hotelTypes: get().hotelTypes
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			clearFilterMeals: () => {
				set(() => ({ meals: [] }))
				const newSearchParams = { ...get().searchParams, meals: get().meals }
				set(() => ({ searchParams: newSearchParams }))
			},
			clearFilterTowns: () => {
				set(() => ({ arrivalCityIds: [], arrivalRegionIds: [] }))
				const newSearchParams = {
					...get().searchParams,
					arrivalRegionIds: get().arrivalRegionIds,
					arrivalCityIds: get().arrivalCityIds
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			clearFreights: () => {
				const newSearchParams = {
					...get().searchParams,
					freightType: 0
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			clearFilters: () => {
				// const newFilters = { ...get().filters, program: null, tours: null, tourType: null }
				// set(() => ({ filters: newFilters }))
				const newSearchParams = {
					...get().searchParams,
					program: null,
					tours: null,
					tourType: null
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			filterRegions: id => {
				set(state => ({
					arrivalRegionIds: state.arrivalRegionIds.filter(
						region => region !== id
					)
				}))
			},
			filterHotel: valueHotelSearch => {
				set(() => ({ hotelSearch: valueHotelSearch }))
			},
			filterCity: valueCitySearch => {
				set(() => ({ citySearch: valueCitySearch }))
			},
			addRegion: (id, checkboxStatus, towns) => {
				if (checkboxStatus) {
					set(state => {
						return {
							arrivalRegionIds: [...state.arrivalRegionIds, id.toString()],
							arrivalCityIds: [...state.arrivalCityIds, ...towns]
						}
					})
					const newSearchParams = {
						...get().searchParams,
						arrivalRegionIds: get().arrivalRegionIds
					}
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return {
							arrivalRegionIds: state.arrivalRegionIds.filter(
								regionId => regionId !== id.toString()
							),
							arrivalCityIds: state.arrivalCityIds.filter(
								town => !towns.includes(town)
							)
						}
					})
					const newSearchParams = {
						...get().searchParams,
						arrivalRegionIds: get().arrivalRegionIds,
						arrivalCityIds: get().arrivalCityIds
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			addTown: (id, checkboxStatus) => {
				if (checkboxStatus) {
					set(state => {
						return { arrivalCityIds: [...state.arrivalCityIds, id.toString()] }
					})
					const newSearchParams = {
						...get().searchParams,
						arrivalCityIds: get().arrivalCityIds
					}
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return {
							arrivalCityIds: state.arrivalCityIds.filter(
								townId => townId !== id
							)
						}
					})
					const newSearchParams = {
						...get().searchParams,
						arrivalCityIds: get().arrivalCityIds
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			resetTown: (id, towns) => {
				set(state => {
					return {
						arrivalCityIds: state.arrivalCityIds.filter(
							townId =>
								!towns.find(item => item.cityId.toString() === townId) ||
								id === townId
						)
					}
				})
				const newSearchParams = {
					...get().searchParams,
					arrivalCityIds: get().arrivalCityIds
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			addHotels: id => {
				if (!get().hotelIds.includes(id)) {
					set(state => {
						return { hotelIds: [...state.hotelIds, id] }
					})
					const newSearchParams = {
						...get().searchParams,
						hotelIds: get().hotelIds
					}
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return {
							hotelIds: state.hotelIds.filter(hotelId => hotelId !== id)
						}
					})
					const newSearchParams = {
						...get().searchParams,
						hotelIds: get().hotelIds
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			addStars: (id, checkboxStatus) => {
				if (checkboxStatus) {
					set(state => {
						return { stars: [...state.stars, id] }
					})
					const newSearchParams = { ...get().searchParams, stars: get().stars }
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return { stars: state.stars.filter(hotelId => hotelId !== id.toString()) }
					})
					const newSearchParams = { ...get().searchParams, stars: get().stars }
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			addHotelTypes: (id, checkboxStatus) => {
				if (checkboxStatus) {
					set(state => {
						return { hotelTypes: [...state.hotelTypes, id] }
					})
					const newSearchParams = {
						...get().searchParams,
						hotelTypes: get().hotelTypes
					}
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return {
							hotelTypes: state.hotelTypes.filter(hotelId => hotelId !== id)
						}
					})
					const newSearchParams = {
						...get().searchParams,
						hotelTypes: get().hotelTypes
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			addMeals: (id, checkboxStatus) => {
				console.log(id)
				if (checkboxStatus) {
					set(state => {
						return { meals: [...state.meals, id] }
					})
					const newSearchParams = { ...get().searchParams, meals: get().meals }
					set(() => ({ searchParams: newSearchParams }))
				} else {
					set(state => {
						return { meals: state.meals.filter(hotelId => hotelId !== id) }
					})
					const newSearchParams = { ...get().searchParams, meals: get().meals }
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			changeCity: id => {
				localStorage.setItem('departureCityId', id)
				get().actions.clearFilterTowns()
				const newSearchParams = {
					...get().searchParams,
					departureCityId: id.toString()
				}
				set(() => ({ currentCity: id }))
				set(() => ({ searchParams: newSearchParams }))
			},
			clearCurrentCountry: () => {
				localStorage.removeItem('arrivalCountryId')
				set(() => ({ currentCountry: null }))
			},
			getCurrencies: async () => {
				try {
					const currentDate = new Date()
					const startDate = new Date().setMonth(currentDate.getMonth() - 1)

					const dollar = await axios({
						url: `${BASE_URL}/currency/rates`,
						method: 'POST',
						data: {
							clientId: 'b2c:ru',
							currencyFrom: 1,
							currencyTo: 2,
							dateFrom: new Date(startDate),
							dateTo: currentDate
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					const euro = await axios({
						url: `${BASE_URL}/currency/rates`,
						method: 'POST',
						data: {
							clientId: 'b2c:ru',
							currencyFrom: 1,
							currencyTo: 3,
							dateFrom: new Date(startDate),
							dateTo: currentDate
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set(() => ({
						currencys: {
							dollar: dollar.data.currencyRates.reverse(),
							euro: euro.data.currencyRates.reverse()
						}
					}))
				} catch (error) {
					console.log(error)
				}
			},
			getDeparture: async () => {
				set({ loading: true })
				try {
					const ruResult = await axios({
						url: `${BASE_URL}/Filters/GetDepartureCities`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					const kzResult = await axios({
						url: `${BASE_URL}/Filters/GetDepartureCities`,
						headers: {
							'X-Tui-Clientid': 'b2c:kz',
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						},
						method: 'GET'
					})
					const kzDeparture = kzResult?.data.filter(
						city => city?.stateFromName === 'Казахстан'
					)
					set({ departureCities: [...ruResult.data, ...kzDeparture] })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getArrival: async withFlights => {
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/GetArrivalCountries?DepartureCityId=${withFlights ? get().currentCity : 1
							}&ClientId=b2c:ru`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					let arrivalCountryIdFromStorage =
						localStorage.getItem('arrivalCountryId')
					if (
						arrivalCountryIdFromStorage &&
						!res.data.find(
							item =>
								item.id.toString() === arrivalCountryIdFromStorage.toString()
						)
					) {
						get().actions.clearCurrentCountry()
						arrivalCountryIdFromStorage = null
					}
					set({
						currentCountry: arrivalCountryIdFromStorage ?? res.data?.[0].id
					})
					set({ arrivalCountries: res.data.find(el => el.id === 266539) 
						? [res.data.find(el => el.id === 266539), ...res.data] 
						: res.data })
					const newSearchParams = {
						...get().searchParams,
						arrivalCountryId:
							arrivalCountryIdFromStorage ?? res.data?.[0].id.toString()
					}
					set(() => ({ searchParams: newSearchParams }))
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			changeCountry: id => {
				localStorage.setItem('arrivalCountryId', id)
				get().actions.clearFilterTowns()
				get().actions.clearFilters()
				const newSearchParams = {
					...get().searchParams,
					arrivalCountryId: id.toString()
				}
				set(() => ({ currentCountry: id }))
				set(() => ({ searchParams: newSearchParams }))
			},
			getFilters: async withFlights => {
				if (get().currentCountry) {
					set({ loading: true })
					if (withFlights) {
						try {
							const res = await axios({
								url: `${BASE_URL}/Filters/GetFilters
						?DepartureCityId=${get().searchParams.departureCityId}
						&ArrivalCountryId=${get().currentCountry}`,
								method: 'GET',
								headers: {
									'X-User-Agent': process.env.REACT_APP_USER_AGENT
								}
							})
							set({ filters: res.data })
							set({ program: get().filters?.programs })
							set({
								tourType:
									res.data.tourTypes.length === 1 ? res.data.tourTypes[0].id : '0'
							})
						} catch (e) {
							console.log(e?.response?.data)
						} finally {
							set({ loading: false })
						}
					} else {
						try {
							const res = await axios({
								url: `${BASE_URL}/Filters/GetFilters
						?DepartureCityId=1
						&ArrivalCountryId=${get().currentCountry}`,
								method: 'GET',
								headers: {
									'X-User-Agent': process.env.REACT_APP_USER_AGENT
								}
							})
							set({ filters: res.data })
							set({ program: get().filters?.programs })
							set({
								tourType:
									res.data.tourTypes.length === 1 ? res.data.tourTypes[0].id : '0'
							})
							await get().actions.getMealsByHotels()
							await get().actions.getStarsByHotels()
							await get().actions.getHotelTypeByHotels()
						} catch (e) {
							console.log(e?.response?.data)
						} finally {
							set({ loading: false })
						}
					}

				}
			},
			getMealsByHotels: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/FiltersByHotel/meals
						?departureCountryId=210357
						&arrivalCountryId=${get().currentCountry}`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ filters: {...get().filters, meals: res.data} })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getStarsByHotels: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/FiltersByHotel/stars
						?departureCountryId=210357
						&arrivalCountryId=${get().currentCountry}`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ filters: {...get().filters, stars: res.data} })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getHotelTypeByHotels: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/FiltersByHotel/hotelTypesByTour
						?departureCountryId=210357
						&arrivalCountryId=${get().currentCountry}`,
						method: 'GET',
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ filters: {...get().filters, hotelTypes: res.data} })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getCheckins: async withFlights => {
				if (get().currentCountry) {
					try {
						const res = await axios({
							url: `${BASE_URL}/Filters/AvailableCheckins
						?DepartureCityId=${withFlights ? get().searchParams.departureCityId : 1}
						&ArrivalCountryId=${get().currentCountry}
						&tourType=${get().tourType}
						&AdultsCount=${get().searchParams.adults}
						&Tours=${get().searchParams.tours || ''}`,
							method: 'GET',
							headers: {
								'X-User-Agent': process.env.REACT_APP_USER_AGENT
							}
						})
						let data = {
							iso: null,
							valid: null
						}
						const checkins = []
						let idx = 0
						for (let char of res.data.valid) {
							data = {
								iso: addDays(new Date(res.data.start), idx),
								valid: char
							}
							idx += 1
							checkins.push(data)
						}
						let existMinStartDate
						let existMaxStartDate

						const minStartDate = get().searchParams.minStartDate
						const maxStartDate = get().searchParams.maxStartDate

						checkins.forEach((item, index, arr) => {
							if (format(item.iso, 'yyyy-MM-dd') === minStartDate) {
								if (item.valid !== '0') {
									existMinStartDate = item
								} else {
									existMinStartDate = arr
										.slice(index)
										.find(item => item.valid !== '0')
								}
							}

							if (format(item.iso, 'yyyy-MM-dd') === maxStartDate) {
								if (item.valid !== '0') {
									existMaxStartDate = item
								} else {
									existMaxStartDate = arr
										.slice(index)
										.find(item => item.valid !== '0')
								}
							}
						})

						// const existMinStartDate = checkins.find(
						// 	item =>
						// 		format(item.iso, 'yyyy-MM-dd') ===
						// 		get().searchParams.minStartDate
						// )
						//const existMaxStartDate =	 checkins.find(
						// 	item =>
						// 		format(item.iso, 'yyyy-MM-dd') ===
						// 		get().searchParams.maxStartDate && item.valid !== 0
						// )

						if (existMinStartDate && existMaxStartDate) {
							const newSearchParams = {
								...get().searchParams,
								minStartDate: format(existMinStartDate.iso, 'yyyy-MM-dd'),
								maxStartDate: format(existMaxStartDate.iso, 'yyyy-MM-dd')
							}
							set(() => ({ searchParams: newSearchParams }))
							set({ checkins: checkins })
							return
						}

						const newSearchParams = {
							...get().searchParams,
							minStartDate: format(
								checkins.find(item => item.valid !== '0').iso,
								'yyyy-MM-dd'
							),
							maxStartDate: format(
								checkins.find(item => item.valid !== '0').iso,
								'yyyy-MM-dd'
							)
						}

						set(() => ({ searchParams: newSearchParams }))
						set({ checkins: checkins })
					} catch (e) {
						console.log(e?.response?.data)
					}
				}
			},
			changeTourType: id => {
				const newSearchParams = {
					...get().searchParams,
					tourType: id.toString()
				}
				set(() => ({ tourType: id }))
				set(() => ({ searchParams: newSearchParams }))
			},
			changeTours: id => {
				const currentTour = get().filters.tours.find(tour => tour.id === id)
				const newSearchParams = {
					...get().searchParams,
					tours: id ? [id.toString()] : null,
					freightType:
						currentTour?.gdsTransport || currentTour?.gdsHotel ? 2 : 0
				}
				set(() => ({ searchParams: newSearchParams }))
				set(() => ({ tourName: currentTour?.name }))
			},
			changeProgram: id => {
				const newSearchParams = {
					...get().searchParams,
					program: id ? id.toString() : null
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			changeMinCost: value => {
				const newSearchParams = {
					...get().searchParams,
					minCost: value.toString() || null
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			changeMaxCost: value => {
				const newSearchParams = {
					...get().searchParams,
					maxCost: value.toString() || null
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			changeDateTo: date => {
				const newSearchParams = {
					...get().searchParams,
					minStartDate: format(date, 'yyyy-MM-dd'),
					maxStartDate: format(date, 'yyyy-MM-dd')
				}
				set(() => ({ minStartDate: date }))
				set(() => ({ searchParams: newSearchParams }))
			},
			changeDateFrom: date => {
				const newSearchParams = {
					...get().searchParams,
					maxStartDate: format(date, 'yyyy-MM-dd')
				}

				set(() => ({ maxStartDate: date }))
				set(() => ({ searchParams: newSearchParams }))

				if (
					isBefore(
						parseISO(format(date, 'yyyy-MM-dd')),
						parseISO(get().searchParams.minStartDate)
					)
				) {
					const newSearchParams = {
						...get().searchParams,
						minStartDate: format(date, 'yyyy-MM-dd')
					}
					set(() => ({ searchParams: newSearchParams }))
				}
				if (
					differenceInDays(date, parseISO(get().searchParams.minStartDate)) > 7
				) {
					const minStartDate = subDays(date, 7)
					const newSearchParams = {
						...get().searchParams,
						minStartDate: format(minStartDate, 'yyyy-MM-dd')
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			clearTours: () => {
				set({ tours: null })
			},
			getTours: async (withFlights = true, page) => {
				set({ loading: true })
				set({ errorTours: false })
				set({ offers: null })
				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetToursByCountryPaginated`,
						method: 'POST',
						data: withFlights
							? {
								...get().searchParams,
								pagination: {
									page: page || 1,
									pageSize: 50
								},
								sortOrderOptions: {
									sortDirection: get().sortDirection,
									sortField: 1
								}
							}
							: {
								...get().searchParams,
								departureCityId: '1',
								pagination: {
									page: page || 1,
									pageSize: 50
								},
								sortOrderOptions: {
									sortDirection: get().sortDirection,
									sortField: 1
								}
							},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ tours: res.data })
					if (!res.data.data.length) {
						set({ errorTours: 'Нет данных. Уточните параметры поиска.' })
					}
				} catch (e) {
					console.log(e?.response?.data)
					set({ errorTours: 'Нет данных. Уточните параметры поиска.' })
				} finally {
					set({ loading: false })
				}
			},
			setStartDate: value => {
				const newSearchParams = {
					...get().searchParams,
					minStartDate: value.split('T')[0],
					maxStartDate: value.split('T')[0]
				}
				set(() => ({ minStartDate: value }))
				set({ searchParams: newSearchParams })
			},
			setSelectedTour: tour => {
				set({ selectedTour: tour })
			},
			getSelectedTour: async (startDate, hotelId, withFlights = true) => {
				set({ loading: true })
				get().actions.setStartDate(startDate)
				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetByCountry`,
						method: 'POST',
						data: withFlights
							? { ...get().searchParams, hotelIds: [`${hotelId}`] }
							: { ...get().searchParams, departureCityId: '1' },
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ selectedTour: res.data.tours[0] })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getNights: async (withFlights = true) => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/nights`,
						method: 'POST',
						data: {
							departureCityId: withFlights
								? get().searchParams.departureCityId
								: '1',
							arrivalCountryId: get().searchParams.arrivalCountryId,
							checkinBeg: get().searchParams.minStartDate,
							checkinEnd: get().searchParams.maxStartDate,
							adult: get().searchParams.adults,
							child: get().searchParams.children.length,
							tours: get().searchParams.tours?.join() || '0'
						},
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					set({ nights: res.data })

					const nights = get().nights

					if (nights?.nights) {
						const minNightsCount = get().searchParams.minNightsCount
						const maxNightsCount = get().searchParams.maxNightsCount

						if (!nights.nights.includes(minNightsCount)) {
							const searchParams = get().searchParams
							const newMinNightsCount = nights.nights.reduce((prev, curr) =>
								Math.abs(curr - minNightsCount) <
									Math.abs(prev - minNightsCount)
									? curr
									: prev
							)
							set({
								searchParams: {
									...searchParams,
									minNightsCount: newMinNightsCount.toString()
								}
							})
						}

						if (!nights.nights.includes(maxNightsCount)) {
							const searchParams = get().searchParams
							const newMaxNightsCount = nights.nights.reduce((prev, curr) =>
								Math.abs(curr - maxNightsCount) <
									Math.abs(prev - maxNightsCount)
									? curr
									: prev
							)
							set({
								searchParams: {
									...searchParams,
									maxNightsCount: newMaxNightsCount.toString()
								}
							})
						}
					}
				} catch (e) {
					console.log(e?.response?.data)
				}
			},
			changeNightsTo: id => {
				const newSearchParams = {
					...get().searchParams,
					minNightsCount: id.toString()
				}
				set(() => ({ searchParams: newSearchParams }))
				if (Number(get().searchParams.maxNightsCount) < id) {
					const newSearchParams = {
						...get().searchParams,
						maxNightsCount: id.toString()
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			changeNightsFrom: id => {
				const newSearchParams = {
					...get().searchParams,
					maxNightsCount: id.toString()
				}
				set(() => ({ searchParams: newSearchParams }))
				if (Number(get().searchParams.minNightsCount) > id) {
					const newSearchParams = {
						...get().searchParams,
						minNightsCount: id.toString()
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			changeAdults: id => {
				const newSearchParams = { ...get().searchParams, adults: id.toString() }
				set(() => ({ searchParams: newSearchParams }))
			},
			changeCurrency: id => {
				const newSearchParams = {
					...get().searchParams,
					currencyId: id.toString()
				}
				set(() => ({ searchParams: newSearchParams }))
				set(() => ({
					currencyAlias:
						get().filters?.currencies?.find(item => item.id === Number(id))
							?.currencyIso || 'RUB'
				}))
			},
			changeChildren: id => {
				set(() => ({ childNum: id }))
				if (id) {
					let ages = []
						;[...Array(id)].map((value, index) => {
							return (ages[index] =
								get().searchParams.children[index] || (index + 1) * 4)
						})
					const newSearchParams = {
						...get().searchParams,
						children: ages.map(item => item.toString())
					}
					set({ isAddInf: true })
					set(() => ({ searchParams: newSearchParams }))
				} else {
					const newSearchParams = { ...get().searchParams, children: [] }
					set({ isAddInf: false })
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			changeAddInf: (value) => {
				set({ isAddInf: value })
			},
			changeChildAge: (value, index) => {
				let ages = get().searchParams.children
				ages[index] = value
				const newSearchParams = {
					...get().searchParams,
					children: ages.map(item => item.toString())
				}
				set(() => ({ searchParams: newSearchParams }))
			},
			getOffers: async (hotelId, withFlights = true) => {
				const payload = {
					hotelIds: [hotelId],
					clientId: 'b2c:ru',
					lang: 'ru',
					departureCityId: withFlights
						? get().searchParams.departureCityId
						: '1',
					arrivalCountryId: get().searchParams.arrivalCountryId,
					minStartDate: get().searchParams.minStartDate,
					maxStartDate: get().searchParams.maxStartDate,
					nightsFrom: get().searchParams.minNightsCount,
					nightsTo: get().searchParams.maxNightsCount,
					adults: get().searchParams.adults,
					children: get().searchParams.children,
					currency: get().searchParams.currencyId,
					tourIds: get().searchParams.tours
				}
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/Tours/GetByHotels`,
						method: 'POST',
						data: payload,
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})
					const filtredByMeal = data => {
						const baseMealValues = get().meals
						if (baseMealValues.length === 0) {
							return data
						}

						const mealTypeIds = baseMealValues.flatMap(value => {
							return data.meals
								.filter(obj => obj.mealTypeGroupId === Number(value))
								.map(obj => obj.mealTypeId)
						})

						const filteredOffers = data.offers.filter(obj =>
							mealTypeIds.includes(obj.mealId)
						)

						return { ...data, offers: filteredOffers }
					}

					const hotelOffers = get().offers
					set({
						offers: { ...hotelOffers, [hotelId]: filtredByMeal(res.data) }
					})
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			clearOffers: hotelId => {
				const hotelOffers = { ...get().offers }
				delete hotelOffers[hotelId]
				set(() => ({ offers: hotelOffers }))
			},
			setFilters: (checkboxStatus, name) => {
				if (name === 'freightType') {
					const newSearchParams = {
						...get().searchParams,
						[name]: checkboxStatus ? 1 : 0
					}
					set(() => ({ searchParams: newSearchParams }))
				} else {
					const newSearchParams = {
						...get().searchParams,
						[name]: checkboxStatus
					}
					set(() => ({ searchParams: newSearchParams }))
				}
			},
			setAllFilters: filters => {
				const newSearchParams = { ...get().searchParams, ...filters }
				set(() => ({ searchParams: newSearchParams }))
			},
			setGroupByHotel: checkboxStatus => {
				if (!checkboxStatus) {
					set({ offers: null })
				}
				set(() => ({ groupByHotel: checkboxStatus }))
			},
			setGroupedResult: checked => {
				set(() => ({ groupedResult: checked }))
			},
			setErrorTours: error => {
				set({ errorTours: error })
			},
			getHotels: async withFlights => {
				const payload = {
					departureCityId: withFlights
						? get().searchParams.departureCityId
						: '1',
					arrivalCountryId: get().searchParams.arrivalCountryId,
					tourIds: get().searchParams.tours || [],
					tourType: get().searchParams.tourType,
					programId: get().searchParams.program
				}
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/Hotels`,
						method: 'POST',
						data: payload,
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})

					set({
						filters: {
							...get().filters,
							hotels: res.data.length ? res.data : get().filters.hotels
						}
					})
					set({ cities: res.data })
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			getPrograms: async withFlights => {
				const payload = {
					departureCityId: withFlights
						? get().searchParams.departureCityId
						: '1',
					arrivalCountryId: get().searchParams.arrivalCountryId,
					tours: get().searchParams.tours
						? Number(get().searchParams.tours.join(''))
						: 0,
					tourType: Number(get().searchParams.tourType) || 0
				}
				set({ loading: true })
				try {
					const res = await axios({
						url: `${BASE_URL}/Filters/Programs`,
						method: 'POST',
						data: payload,
						headers: {
							'X-User-Agent': process.env.REACT_APP_USER_AGENT
						}
					})

					set({
						filters: {
							...get().filters,
							programs: res.data.length ? res.data : get().filters.programs
						}
					})
				} catch (e) {
					console.log(e?.response?.data)
				} finally {
					set({ loading: false })
				}
			},
			changeSort: value => set({ sortDirection: value })
		}
	}))
)

export default useSearchTourStore
