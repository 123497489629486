import React from 'react';
import useErskStore from "../store";
import {Box} from "@mui/material";
import {Button} from "@material-ui/core";
import FailIcon from "../../../asset/icons/Fail-icon";

const UploadFail = () => {

	const actions = useErskStore(({ actions }) => actions)
	const uploadFailError = useErskStore(({ uploadFailError }) => uploadFailError)

	const handleClick = () => {
		if (uploadFailError) {
			actions.updateForm()
		} else {
			actions.uploadContract()
		}
	}

	return (
		<div className={'upload-documents__form'}>
			<Box sx={{margin: '0 auto', width: '30px'}}>
				<FailIcon />
			</Box>
			<Box sx={{margin: '15px auto 30px', width: '100%', textAlign: 'center', fontSize: 16, lineHeight: '22px'}}>
				Произошла ошибка.<br/>
				Не удалось добавить документы.<br/>
				{uploadFailError}
			</Box>
			<Box>
				<Button
					className={'button-upload'}
					variant={'contained'}
					fullWidth
					onClick={handleClick}
				>
					Попробовать еще раз
				</Button>
			</Box>
		</div>
	);
};

export default UploadFail;