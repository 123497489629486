import { useState, Fragment } from "react";
import { format, parseISO } from "date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import useBookingStore from "../../store";

import "./style.css";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";

function ServiceModalContent({
    services,
    selectedServices,
    isGrouped,
    onChange,
    onChangePersonal,
}) {
    const peoples = useBookingStore(({ peoples }) => peoples);
    const servicesGroups = useBookingStore(
        ({ servicesGroups }) => servicesGroups
    );
    const [selected, setSelected] = useState(selectedServices);

    const handleChange = (uid) => {
        const newState = onChange(uid);
        setSelected(newState);
    };
    const personalAdd = (uid, peopleKey) => {
        const newState = onChangePersonal(uid, peopleKey);
        setSelected(newState);
    };

    useEffect(() => {
        setSelected(selectedServices);
    }, [selectedServices]);


    const PersonalAdd = ({ item }) => {
        return peoples.map((people) => (
            <div
                key={item.uid + people.key}
                className="grid-service insurance-body"
            >
                <div key={people.key}>
                    <Checkbox
                        checked={
                            selected
                                .find((i) => i.uid === item.uid)
                                ?.clients.some(
                                    (clients) => clients.peopleKey === people.key
                                ) || false
                        }
                        onChange={() => personalAdd(item.uid, people.key)}
                    />
                    Турист {people.key}
                </div>
                <div></div>
                <div>
                    {" "}
                    {`+${item.fsPricePerPerson ?? item.price} ${item.currencyAlias
                        }`}
                </div>
            </div>
        ))
    }

    if (isGrouped) {
        const groupedServices = Object.values(
            services.reduce((acc, item) => {
                const typeId = item.groupId;
                const typeName = servicesGroups.find(
                    (i) => i.id === item.groupId
                )?.description;
                if (!acc[typeId]) {
                    acc[typeId] = {
                        id: typeId,
                        name: typeName,
                        items: [],
                    };
                }
                acc[typeId].items.push(item);
                return acc;
            }, {})
        );

        return (
            <div className="modal_body">
                {groupedServices.map((item) => {
                    return (
                        <div key={item.uid}>
                            <div className="grouped title" style={{ margin: 0 }}>{item.name}</div>
                            <div>
                                {item.items.map((service) => {
                                    const include = selected
                                        .find((i) => i.uid === service.uid)
                                        ?.clients.some((clients) => clients.peopleKey);

                                    const price = !service.required &&
                                        `+${service.price} ${service.currencyAlias}`
                                    return (
                                        <Fragment>
                                            <div key={service.uid} className="grid-service body">
                                                <div>
                                                    {!service.personal ? (
                                                        <FormControlLabel
                                                            label={service.name}
                                                            control={
                                                                <Checkbox
                                                                    checked={include || service.required}
                                                                    onChange={() => handleChange(service.uid)}
                                                                    disabled={!service.remove && service.required}
                                                                />
                                                            }
                                                        />
                                                    ) : (
                                                        service.name
                                                    )}
                                                </div>
                                                <div>
                                                    {format(parseISO(service.datebeg), "dd.MM.yyyy")} -{" "}
                                                    {format(parseISO(service.dateend), "dd.MM.yyyy")}
                                                </div>
                                                <div>
                                                    {price}
                                                </div>
                                            </div>
                                            {/*Нет данных */}
                                            {service.note && (
                                                <div
                                                    key={service.uid + uuid()}
                                                    className="baggage-info"
                                                >
                                                    {service.note.split("\n").map((service) => (
                                                        <div>{service}</div>
                                                    ))}
                                                </div>
                                            )}
                                            {service.personal &&
                                                <PersonalAdd item={service} />}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="modal_body">
            {services.map((item) => {
                const price = item?.price ? `+${item.price} ${item.currencyAlias}` : ""

                return (
                    <Fragment key={item.uid}>
                        <div className="grid-service insurance-body">
                            {!item.personal ? (
                                <div>
                                    <FormControlLabel
                                        label={item.name}
                                        checked={
                                            selected.some((select) => select.uid === item.uid) ||
                                            item.required
                                        }
                                        disabled={!item.remove && item.required}
                                        onChange={() => handleChange(item.uid)}
                                        control={<Checkbox />}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                    style={{
                                        minHeight: "42px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {item.name}
                                </div>
                            )}

                            <div>
                                {format(parseISO(item.datebeg), "dd.MM.yyyy")} -{" "}
                                {format(parseISO(item.dateend), "dd.MM.yyyy")}
                            </div>
                            <div>
                                {price}
                            </div>
                        </div>
                        {item.note && (
                            <div key={item.uid + uuid()} className="baggage-info">
                                {item.note.split("\n").map((item) => (
                                    <div>{item}</div>
                                ))}
                            </div>
                        )}
                        {item.personal &&
                            <PersonalAdd item={item} />
                        }
                    </Fragment>
                );
            })}
        </div>
    );
}

export default ServiceModalContent;
