import React from 'react';
import useHotelDescriptionStore from "../store";

import './style.css'
import RoomCard from "./RoomCard";
import {isEmpty} from "lodash";

const Rooms = () => {

	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const amenities = useHotelDescriptionStore(({ amenities }) => amenities)

	if (isEmpty(hotel)) return

	return (
		<div className={'container'}>
			{hotel?.roomTypes.filter(item => item.status === 'Actual').map(room =>
				<RoomCard
					hotelId={hotel.id}
					objectType={hotel.objectType}
					key={room.samoId}
					room={room}
					amenities={amenities}
					images={hotel.images.filter(item => room.images.find(image => image.id === item.id))}
				/>)}
		</div>
	);
};

export default Rooms;