import { isEmpty } from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'

import './simpleGallery.style.scss'
import { Navigation, Virtual } from 'swiper/modules';

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {isMobile} from "react-device-detect";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

function SimpleGallery({ gallery }) {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        centerPadding: "235px",
        speed: 200,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />
    };

    if (isEmpty(gallery)) {
        return null
    }

    if (gallery.length === 1) {
        const image = gallery[0]
        return <div className='simpleGallery-oneSlide'>
            <div className='simpleGallery-oneSlide__wrapper'>
                <img className='simpleGallery-oneSlide__wrapper__img' src={image.image} decoding='async' loading='lazy' alt={image.text} />
            </div>
                <p className='simpleGallery-oneSlide__wrapper__text'>{image.text}</p>
        </div>
    }

    return <>
        {!isMobile ? <Slider {...settings}>
            {gallery.map(({image, text}, index) =>
                <div className='simpleGallery-oneSlide'>
                    <div className='simpleGallery-oneSlide__wrapper'>
                        <img className='simpleGallery__slide__wrapper__imgnew' src={image} alt={text}/>
                    </div>
                    <p className='simpleGallery__slide__text'>
                        {text}
                    </p>
                </div>
            )}
        </Slider> :
            <div className='simpleGallery-wrap-slider'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={100}
                    loop={true}
                    modules={[Virtual, Navigation]}
                    navigation
                    virtual
                    watchOverflow={true}
                    breakpoints={{
                        580: {
                            slidesPerView: 1,
                        }
                    }}
                    className='simpleGallery'
                >
                    {gallery.map(({image,text}, index) =>
                        <SwiperSlide  key={index} virtualIndex={index} className='wrapper-slide'>
                            <div className='simpleGallery__slide'>
                                <div className='simpleGallery__slide__wrapper'>
                                    <img className='simpleGallery__slide__wrapper__img'  src={image} alt={text} />
                                </div>
                                <p className='simpleGallery__slide__text'>
                                    {text}
                                </p>
                            </div>
                        </SwiperSlide>)}
                </Swiper>
            </div>}

    </>

}

export default SimpleGallery