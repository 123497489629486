import React from 'react';

import styled from 'styled-components';
import { Text } from '../../../ui';
import { ReactComponent as LuggageIcon } from '../../../../asset/images/avia/addictional-services/luggage.svg';
import { ReactComponent as MealIcon } from '../../../../asset/images/avia/addictional-services/meal.svg';
import { ReactComponent as ForGourmanIcon } from '../../../../asset/images/avia/addictional-services/for-gourman.svg';
import { ReactComponent as DontMissIcon } from '../../../../asset/images/avia/addictional-services/best-choise.svg';
import { ReactComponent as TriangleIcon } from '../assets/media/triangle.svg';
import { AncillaryServiceType } from '../../../../store/modules/booking';
import formatPrice from '../../../../functions/formatPrice';
import {styled as MUIstyled} from '@mui/material/styles'
import BasePrimaryButton from '../../../ui/PrimaryButton';
import _ from 'lodash';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  position: relative;
`;

const Top = styled.div`
  display: flex;

  & > svg {
    width: 35px;
    height: 35px;
  }
  @media (max-width: 767px) {
    & > svg {
      display: none;
    }
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
    padding-top: 0;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHeadLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872f2;
`;

const MobileHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Name} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  ${Price} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Description = styled(Text)`
  display: block;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const PrimaryButton = MUIstyled(BasePrimaryButton)(()=>({
  minWidth: 153,
  fontSize: '14px',
  lineHeight: '18px',
  padding: '7px 16px',
  display: 'block',
}))



const MealLabel = styled(ForGourmanIcon)`
  position: absolute;
  right: -125px;
  top: -3px;
`;

const MealDesktopLabel = styled(MealLabel)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MealMobileLabel = styled(MealLabel)`
  top: -12px;
  right: -4px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const LuggageLabel = styled(DontMissIcon)`
  position: absolute;
  right: -138px;
  top: -3px;
`;

const LuggageDesktopLabel = styled(LuggageLabel)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const LuggageMobileLabel = styled(LuggageLabel)`
  top: -12px;
  right: -4px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const List = styled.div`
  margin: 20px 0 15px 0;
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const SegmentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const SegmentHeadRight = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 5px;
  }
`;

const SegmentPrice = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: right;

  color: #4872f2;
`;

const Segment = styled.div`
  ${({ open }) => {
    if (!open) {
      return `
        ${SegmentHeadRight} {
           svg {
            transform: rotate(180deg);
          }
        }
        ${PassengerList} {
          display: none;
        }
      `;
    }
  }}
`;

const SegmentName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const PassengerList = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Passenger = styled.div``;

const PassengerHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PassengerText = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const OffersList = styled.div`
  margin-top: 8px;
  & > div {
    margin-bottom: 5px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Offer = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  display: block;
`;

export default function Service(props) {
  const [openSegmentIndexes, setOpen] = React.useState({});

  const passengersById = React.useMemo(() => {
    const result = {};

    _(props.passengers).forEach((x, key) => {
      let name = `Пассажир ${
        key + 1
      }, ${x.ageCategoryDescription.toLocaleLowerCase()}`;
      if (!!x.name || !!x.secondName || !!x.surname) {
        name = `${x.surname}${x.surname ? ' ' : ''}${x.name}${
          x.name ? ' ' : ''
        }${x.secondName}`;
      }
      result[x.uid] = name;
    });

    return result;
  }, [props.passengers]);

  const { TITLE, DESCRIPTION, BUTTON, ICON, DESKTOP_LABEL, MOBILE_LABEL } =
    React.useMemo(() => {
      switch (props.type) {
        case AncillaryServiceType.Luggage:
          return {
            TITLE: 'Дополнительный багаж',
            DESCRIPTION: `Оформите багаж заранее, в аэропорту эта услуга займет время и может стоить дороже, чем на сайте.`,
            BUTTON: `Выбрать багаж`,
            ICON: <LuggageIcon />,
            DESKTOP_LABEL: <LuggageDesktopLabel />,
            MOBILE_LABEL: <LuggageMobileLabel />,
          };
        case AncillaryServiceType.Meal:
          return {
            TITLE: 'Питание в самолете',
            DESCRIPTION: `Выберите блюдо, подходящее Вашим вкусовым предпочтениям, и наслаждайтесь полетом.`,
            BUTTON: `Выбрать питание`,
            ICON: <MealIcon />,
            DESKTOP_LABEL: <MealDesktopLabel />,
            MOBILE_LABEL: <MealMobileLabel />,
          };
        default:
          return {
            TITLE: 'TITLE',
            DESCRIPTION: `DESCRIPTION`,
            BUTTON: `BUTTON`,
            ICON: null,
            DESKTOP_LABEL: null,
            MOBILE_LABEL: null,
          };
      }
    }, [props.type]);

  return (
    <Wrapper>
      {MOBILE_LABEL}
      <Top>
        {ICON}
        <Content>
          <Head>
            <Name>
              {TITLE} {DESKTOP_LABEL}
            </Name>
            <Price>от {formatPrice(props.minPrice)}</Price>
          </Head>
          <MobileHead>
            <MobileHeadLeftSide>
              {ICON} <Name>{TITLE}</Name>
            </MobileHeadLeftSide>
            <Price>от {formatPrice(props.minPrice)}</Price>
          </MobileHead>
          <Description>{DESCRIPTION}</Description>

          {props.data.length > 0 && (
            <List>
              {props.data.map((x, key) => (
                <Segment key={x.key} open={openSegmentIndexes[x.key]}>
                  <SegmentHead
                    onClick={() => {
                      setOpen((s) => ({
                        ...s,
                        [x.key]: s[x.key] ? !s[x.key] : true,
                      }));
                    }}
                  >
                    <SegmentHeadRight>
                      <SegmentName>{x.segmentName}</SegmentName>
                      <TriangleIcon />
                    </SegmentHeadRight>
                    <SegmentPrice>{formatPrice(x.price)}</SegmentPrice>
                  </SegmentHead>
                  <PassengerList>
                    {x.passengers.map((y) => (
                      <Passenger key={y.key}>
                        <PassengerHead>
                          <PassengerText>{passengersById[y.key]}</PassengerText>
                          <PassengerText>
                            {formatPrice(y.price)}
                          </PassengerText>
                        </PassengerHead>
                        <OffersList>
                          {y.offers.map((z) => (
                            <Offer key={z.key}>{z.name}</Offer>
                          ))}
                        </OffersList>
                      </Passenger>
                    ))}
                  </PassengerList>
                </Segment>
              ))}
            </List>
          )}
          <PrimaryButton
            color={props.data.length > 0 ? 'lightBlue' : 'primary'}
            onClick={() => {
              props.onClick(props.index, props.type);
            }}
          >
            {props.data.length > 0 ? 'Изменить выбор' : BUTTON}
          </PrimaryButton>
        </Content>
      </Top>
    </Wrapper>
  );
}

// function getIcon(type: PassengerAgeCategory) {
//   switch (type) {
//     case PassengerAgeCategory.Adult:
//       return <img width={30} src={AdultPath} alt="" />;
//     case PassengerAgeCategory.Child:
//       return <img width={30} src={ChildPath} alt="" />;
//     case PassengerAgeCategory.Infant:
//       return <img width={30} src={InfantPath} alt="" />;
//   }
// }
