import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../ui';
import { ReactComponent as SeatMapIcon } from '../../../../asset/images/avia/addictional-services/seat-map.svg';
import {
  // useAddictionalServicesModalState,
  getSeatMapState,
  AddictionalServiceTypes,
} from '../../../../store/modules/booking';
import {useModalState} from '../context'
import { useSelector } from 'react-redux';
import {styled as MUIstyled} from '@mui/material/styles'
import PrimaryButton from '../../../ui/PrimaryButton';

const Container = styled.div`
  background: #edf1ff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 14px 29px 14px 24px;
  & > div {
    margin-bottom: 14px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
`;

const StyledButton = MUIstyled(PrimaryButton)(()=>({
  fontSize: '14px',
  lineHeight: '18px',
  padding: '7px 16px',
}))



export default function OfferBlock() {
  const { showModal } = useModalState();
  const state = useSelector(getSeatMapState);

  if (!state.available || state.availableSeatCount === 0) {
    return null;
  }

  return (
    <Container>
      <Flex>
        <SeatMapIcon />
        <Text>
          Не забудьте выбрать место в самолете, чтобы провести полет в комфорте!
        </Text>
      </Flex>
      <StyledButton onClick={() => showModal(AddictionalServiceTypes.SeatMap)}>
        Выбрать
      </StyledButton>
    </Container>
  );
}
