import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { isEmpty } from 'lodash'
import { styled, useMediaQuery } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'

export const CustomSelect = styled(Select)(() => ({
	'& fieldset': {
		borderRadius: '8px'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	}
}))

export default function CountrySelect({ actionsList, country, handleChange }) {
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	const countries = React.useMemo(() => {
		const list = actionsList?.map(item =>
			item?.country.map(item => item.localizedName)
		)
		const countriesList = []
		for (let i = 0; i < list?.length; i++) {
			if (!isEmpty(list[i])) {
				for (let j = 0; j < list[i].length; j++) {
					countriesList.push(list[i][j])
				}
			}
		}
		return [...new Set(countriesList)]
	}, [actionsList])
	// console.log(countries, 'countries')

	return (
		<Box
			sx={{
				minWidth: 290,
				pr: isSmallScreen ? 1 : 0,
				mb: isSmallScreen ? 0 : 2
			}}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Выберите страну</InputLabel>
				<CustomSelect
					MenuProps={{
						PaperProps: {
							className: 'myCustomList-actions',
							sx: {
								'& .MuiMenuItem-root': {
									fontFamily: 'Open Sans'
								}
							}
						},
						style: {
							maxHeight: isSmallScreen ? '292px' : '346px',
							maxWidth: 290,
							fontFamily: 'Open Sans'
						}
					}}
					IconComponent={ExpandMore}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={country}
					label='Выберите страну'
					onChange={handleChange}>
					<MenuItem value={'Все'}>
						<em>Все</em>
					</MenuItem>
					{countries?.map((item, i) => (
						<MenuItem value={item} key={i}>
							{item}
						</MenuItem>
					))}
				</CustomSelect>
			</FormControl>
		</Box>
	)
}
