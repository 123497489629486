import React from 'react';

import './style.css'

const TopHotelReviews = ({ isShow }) => {

	return (
		<div style={isShow ? {display: 'block'}: {display: 'none'}}>
			<div id="th_rates_ver_8_b6f8ab20102b45f8676dffade4ab255f" />
		</div>
	);
};

export default TopHotelReviews;