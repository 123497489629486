import Api from "./fetchApi";

export function wordEndingFilter(value, words) {
	//Фильтр для склонения числительных (1 турист, 2 туриста, 5 туристов)
	//value - число
	//words - массив слов по возрастанию ['турист','туриста','туристов']

	let result

	if (
		(value % 100 > 9 && value % 100 < 20) ||
		(value % 10 > 4 && value % 10 < 10) ||
		value % 10 === 0
	) {
		result = words[2]
	} else if (value % 10 === 1) {
		result = words[0]
	} else {
		result = words[1]
	}

	return result
}

/**
 * Функция приведения первой буквы строки в заглавную, остальные буквы строчные
 * @param str
 * @returns {string}
 */
export function firstCharUp(str) {
	let string = ''
	if (!!str) {
		string = str.slice(0,1).toUpperCase() + str.slice(1).toLowerCase()
	}
	return string
}

/**
 * Transform word endings depending on quantity
 *
 * @param {number} number For wich number resolve word ending
 * @param {string} singular Example : банан
 * @param {string} imperative Example : банана
 * @param {string} subordinative Example : бананов
 * @return {string} word with correct ending
 */
export function pluralWord(
	number,
	singular,
	imperative,
	subordinative
) {
	const condition = number % 100;
	const condition2 = condition % 10;
	if (condition > 10 && condition < 20) return subordinative;
	if (condition2 > 1 && condition2 < 5) return imperative;
	if (condition2 === 1) return singular;
	return subordinative;
}

export async function getCountriesTotalList() {
	try {
		return await Api
			.get(process.env.REACT_APP_COUNTRIES_LIST + '?Page=1&PageSize=250')
			.setContentType('application/json')
			.send();
	} catch (e) {
		// console.log(e);
	}
}

export async function getCountriesList(value) {
	try {
		return await Api
			.get(process.env.REACT_APP_COUNTRIES_SEARCH)
			.setContentType('application/json')
			.query({value})
			.send();
	} catch (e) {
		// console.log(e);
	}
}
