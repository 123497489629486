import React from "react"
import styled from "styled-components"
import { Text as Typography, Separator, Chat } from "../../components/ui"
import {
  getDetail,
  getMessagesSelector,
  sendMessage,
  getAppealDetail,
  getRefundInfo,
  cancelAppealRequest,
  Manager,
} from "../../store/modules/appeals"
import Calculation from '../../components/AviaAppeal/Calculation'
import { useDispatch, useSelector } from "react-redux"
import { useParams, NavLink, useNavigate } from "react-router-dom"
import moment from "moment"
import { CancelAppealModal } from "../../components/modals"
import {Button} from "@material-ui/core";

const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 0 0 auto;
  padding: 24px;  
  position: relative;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
`

const OrderButton = styled(Button)`
    margin-bottom: 20px;
    text-transform: unset;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 20px;
    border: 1px solid #4872F2;
    color: #4872F2;
`

const Head = styled.div`
  position: relative;
  & > .header {
    display: block;
    margin-bottom: 15px;
  }
  & > .description {
    display: block;
    margin-bottom: 35px;
    white-space: pre-line;
  }
  & > ${Separator} {
    margin: 35px auto;
  }
`

const StyledChat = styled(Chat)`
  margin-top: 33px;
  @media (max-width: 767px) {
    flex-grow: 1;
    margin-top: 10px;
  }
`

const HeaderText = styled(Typography)`
  margin-bottom: 15px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 7px;
  column-gap: 20px;
`

const PropertyRow = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: block;
  color: #737373;

  & > strong {
    color: #3c3c3c;
  }

  & > a {
    color: #4872f2;
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-weight: normal;
  }
`

const CancelButton = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  border: none;
  margin: unset;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const TopPropertiesList = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;

  & > span {
    display: block;
    margin-bottom: 7px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`

const Info = styled.div`
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 30px;
  background-color: #F6F8FA;
`

const Files = styled.strong`
  & > a {
    padding-right: 5px;
  }

  & > :last-child {
    padding-right: 0;
  }
`

const List = () => {
  const { ticketNumber } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const messagesState = useSelector(getMessagesSelector)
  const {
    number,
    createdDate,
    orderNumber,
    status,
    subject,
    ...rest
  } = useSelector(getAppealDetail)

  const refund = useSelector(getRefundInfo)
  const formatedDate = moment(createdDate).format("DD.MM.YYYY")
  const [popup, setPopup] = React.useState(false)

  const handleSubmit = (value, files) => {
    dispatch(sendMessage({ message: value, files }))
    return new Promise(() => {})
  }

  const redirectToDetails = () => {
    navigate(`/cabinet/orders/${orderNumber}`);
  }

  return orderNumber === 0 ? null : (
    <>
      <OrderButton variant={'outlined'} color={'primary'} onClick={redirectToDetails}>Перейти к заявке</OrderButton>
      <Wrapper>
        <Head>
            <Info>
                <HeaderText
                className="header"
                size={16}
                color="#3C3C3C"
                fontWeight="600"
                >
                {`Обращение №${number} от ${formatedDate}`}
                </HeaderText>
                {status.code !== "Closed" &&
                subject.code !== "ConsentToChangeFlightData" && (
                    <CancelButton
                    onClick={() => {
                        setPopup(true)
                    }}
                    >
                    Отменить обращение
                    </CancelButton>
                )}
                <TopPropertiesList>
                {rest.text && (
                    <PropertyRow>
                    Текст обращения:&nbsp;
                    <strong>{rest.text}</strong>
                    </PropertyRow>
                )}
                {rest.fileInfos.length > 0 && (
                    <PropertyRow>
                    Вложенные файлы:&nbsp;
                    <Files>
                        {rest.fileInfos.map((x, key, arr) => (
                        <a
                            target="_blank"
                            href={x.name}
                            onClick={e => {
                            e.preventDefault()
                            Manager.getFile(x.fieldId, x.name)
                            }}
                            style={{
                            color: "#4872f2",
                            cursor: "pointer",
                            textDecoration: "none"
                            }} rel="noreferrer"
                        >{`${x.name}${
                            key === arr.length - 1 ? "" : ","
                        }`}</a>
                        ))}
                    </Files>
                    </PropertyRow>
                )}
                </TopPropertiesList>
                <HeaderText>Детали обращения:</HeaderText>
                <Grid>
                <PropertyRow>
                    Тема обращения:&nbsp;
                    <strong>{subject.description}</strong>
                </PropertyRow>
                <PropertyRow>
                    Номер заявки:&nbsp;
                    <NavLink to={`/cabinet/orders/${orderNumber}`}>
                    {orderNumber}
                    </NavLink>
                </PropertyRow>
                <PropertyRow>
                    Статус обращения:&nbsp;
                    <strong>{status.description}</strong>
                </PropertyRow>
                {rest.flight_Type && (
                    <PropertyRow>
                    Тип рейса:&nbsp;
                    <strong>
                        {rest.flight_Type === "Regular" ||
                        !rest.flight_Type
                        ? "Регулярный рейс"
                        : "Чартерный рейс"}
                    </strong>
                    </PropertyRow>
                )}

                {(rest.selectedTickets || []).length > 0 && (
                    <PropertyRow>
                    Пассажиры:&nbsp;
                    <strong>
                        {rest.selectedTickets.map(
                        (x, i, arr) =>
                            x.lastName +
                            " " +
                            x.firstName.slice(0, 1) +
                            "." +
                            (x?.patronymic
                            ? x?.patronymic.slice(0, 1)
                            : "") +
                            `${i < arr.length - 1 ? ", " : ""}`
                        )}
                    </strong>
                    </PropertyRow>
                )}
                {(rest.aviaSegments || []).length > 0 && (
                    <PropertyRow>
                    Сегменты:&nbsp;
                    <strong>
                        {rest.aviaSegments.map(
                        (x, i, arr) =>
                            `${x.departureCityCode} - ${
                            x.arrivalCityCode
                            }${i < arr.length - 1 ? ", " : ""}`
                        )}
                    </strong>
                    </PropertyRow>
                )}
                </Grid>
            </Info>
        {refund && (
            <Calculation
            subject={subject.description}
            subjectCode={subject?.code}
            orderNumber={orderNumber}
            {...refund}
            onCancel={() => {
                dispatch(getDetail(ticketNumber))
            }}
            onConfirm={() => {
                dispatch(getDetail(ticketNumber))
            }}
            segmentEvents={rest.segmentEvents}
            selectedTickets={rest.selectedTickets}
            passengerPriceTotal={rest.passengerPriceTotal}
            selectedAncillaryServices={
                rest?.selectedAncillaryServices
            }
            selectedInsurances={rest?.selectedInsurances}
            />
        )}
        <Separator />
        </Head>

        <StyledChat
            items={messagesState.items}
            onSubmit={handleSubmit}
            disabled={status.code === "Closed"}
            />
            <CancelAppealModal
            open={popup}
            onConfirm={() => {
                dispatch(cancelAppealRequest(rest.id))
                setPopup(false)
            }}
            onClose={() => setPopup(false)}
        />
      </Wrapper>
    </>
  )
}

export default List
