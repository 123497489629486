// eslint-disable react-hooks/exhaustive-deps

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import { TextContaner, TimeText } from './styles';
import formatPrice from '../../../../functions/formatPrice'
import Range from './RCSlider';

const PriceRange = ({
  min,
  max,
  onChange,
  values,
  ...props
}) => {
  const [valuesS, setValues] = useState(values);

  useEffect(() => {
    setValues(values);
  }, [values]);

  const formattedValues = useMemo(() => {
    return valuesS.map((val) => formatPrice(val));
  }, [valuesS]);
  
  // eslint-disable-next-line
  const setValuesThrottled = useCallback(
    throttle((val) => {
      setValues(val);
    }, 20),
    []
  );

  const handleChange = (vals) => {
    setValuesThrottled(vals);
  };
  return (
    <div>
      <TextContaner>
        <TimeText>от {formattedValues[0]}</TimeText>
        <TimeText>до {formattedValues[1]}</TimeText>
      </TextContaner>
      <Range
        {...props}
        onAfterChange={onChange}
        onChange={handleChange}
        range
        value={valuesS}
        min={min}
        max={max}
        allowCross={false}
        draggableTrack
      />
    </div>
  );
};

export default PriceRange;
