import React, {useEffect, useRef, useState} from 'react';
import './style.scss'
import {InputAdornment, TextField} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {isEmpty} from "lodash";

const SelectFields = ({lists, onChange, title, isCountry = false, value, disabled}) => {
	const [showBody, setShowBody] = useState(false)
	const [selectValue, setSelectValue] = useState(value !== 0 ? lists.find(item => item?.id?.toString() === value?.toString())?.name || lists[0]?.name : "Все страны")
	const ref = useRef(null);

	useEffect(() => {
		function handleClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [ref]);

	useEffect(() => {
		if(disabled){
			setSelectValue(" ")
		}
	},[disabled])

	useEffect(() => {
		if(value === 0){
			setSelectValue("Все страны")
			return
		}
		if (!isEmpty(lists) && !isCountry) {
			if (lists.find(item => item?.id?.toString() === value?.toString())) {
				setSelectValue(lists.find(item => item?.id?.toString() === value?.toString()).name)
			} else {
				setSelectValue(lists[0].name)
			}
		}
		if (!value) {
			setSelectValue(' ')
		}
	}, [lists, value, isCountry])

	if (!lists) return null

	const toggle = () => {
		setShowBody(!showBody)
	}

	const handleChange = (id) => {
		const item = lists.find(item => item.id === id)
		if(id === 0){
			setShowBody(false)
			setSelectValue("Все страны")
			onChange(id)
			return
		}
		setShowBody(false)
		setSelectValue(item?.name)
		onChange(item?.id ?? item)
	}


	return (
		<div className="v-departure-base select-field" ref={ref}>
			<div className="v-departure">
				<div onClick={toggle}>
					<div>
						<TextField
							id={'departure'}
							label={title}
							variant="outlined"
							fullWidth
							autoComplete={'off'}
							value={selectValue ?? ''}
							// disabled={selectValue === ' '}
							InputProps={{
								endAdornment: <InputAdornment position="end"><ArrowDropDownIcon /></InputAdornment>,
							}}
						/>
					</div>
				</div>
				{showBody && !disabled && <div className="v-departure__body-container">
					<div className="v-departure__body">
						{isCountry && <div
							key={"empty"}
							className="v-departure__elem v-text-16"
							onClick={() => handleChange(0)}
						>
							Все страны
						</div>}
						{lists.map((item) => {
							return (
								<div
									key={item.id || item}
									className={`v-departure__elem v-text-16`}
									onClick={() => handleChange(item.id || item)}
								>
									{item.name || item}
								</div>
							)
						})}
					</div>
				</div>}
			</div>
		</div>
	);
};

export default SelectFields;