import React from "react"
import styled from "styled-components"
import { InfoBlock,Text } from "../../ui"
import { ServiceRulesModal as Modal, SmsServiceModal } from "../../modals"
import SmsService from "./SmsService"
import RefundService from "./RefundService"
import AncillaryServices from "./AncillaryServices"
import PrintService from "./PrintService"
import { useModalState, Provider } from "./context"
import formatPrice from "../../../functions/formatPrice"
import { useSelector } from "react-redux"
import {
  ancillaryServicesSelector,
  getDetailOrderContactInfo,
  AddictionalServiceTypes
} from "../../../store/modules/orders"
import AnimatedNumber from "animated-number-react"
import _ from "lodash"

const Wrapper = styled(InfoBlock)`
  background: #edf1fe;

  & > div {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }
`

const Sum = styled(Text)`
  margin-top: 25px;
  display: block;
  font-size: 16px;
  line-height: 20px;
  & > strong {
    font-weight: 600;
    color: #4872F2;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
  }
`

const List = styled.div`
  margin-top: 20px;
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

// eslint-disable-next-line import/no-anonymous-default-export
export default props => (
  <Provider>
    <AddictionalServices {...props} />
  </Provider>
)

function AddictionalServices({ items, showExtraServices }) {
  const {
    visibility,
    hideModal,
    smsModalVisibility,
    hideSmsModal,
    addSmsService
  } = useModalState()
  const ancillaryServices = useSelector(ancillaryServicesSelector)
  const user = useSelector(getDetailOrderContactInfo)

  const TOTAL_PRICE = React.useMemo(
    () =>
      _(items)
        .filter(x => x.isPaid || x.checked)
        .reduce((acc, x) => acc + x.amount, 0) + ancillaryServices.total,
    [items, ancillaryServices]
  )

  const showPaidItems =
    items.filter(x => x.isPaid).length > 0 ||
    ancillaryServices.luggage.length > 0 ||
    ancillaryServices.meal.length > 0 ||
    ancillaryServices.others.length > 0 ||
    ancillaryServices.seats.length > 0

  const showNotPaidItems =
    showExtraServices && items.filter(x => !x.isPaid).length > 0

  if (!showPaidItems && !showNotPaidItems) return null

  return (
    <Wrapper>
      <div>
        <Title>Дополнительные услуги</Title>
        <List>
          {showPaidItems && (
            <>
              {items
                .filter(x => x.isPaid)
                .map(x => (
                  <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
                ))}
              <AncillaryServices />
            </>
          )}
          {showNotPaidItems &&
            items
              .filter(x => !x.isPaid)
              .map(x => {
                return (
                  <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
                )
              })}
        </List>
        <Sum>
          Сумма выбранных услуг:{" "}
          <strong>
            <AnimatedNumber
              duration={300}
              value={TOTAL_PRICE}
              formatValue={value => formatPrice(value)}
            />
          </strong>
        </Sum>
      </div>

      <Modal open={visibility !== null} onClose={hideModal} type={visibility} />
      <SmsServiceModal
        onSubmit={data => {
          addSmsService(data)
        }}
        open={smsModalVisibility}
        onClose={hideSmsModal}
        user={user}
      />
    </Wrapper>
  )
}

function getTemplate(x) {
  switch (x.type) {
    case AddictionalServiceTypes.SMS_Service:
      return <SmsService {...x} />
    case AddictionalServiceTypes.Return_Guarantee:
      return <RefundService {...x} />
    case AddictionalServiceTypes.Payment_Receipt:
      return <PrintService {...x} />
    default:
      return null
  }
}
