import { Grid, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import useHotelServicesStore from '../../store'
import { isEmpty } from 'lodash'
import NotFindService from '../../../../components/Services/NotFindService'
import ServiceFiltersBlock from './components/ServiceFiltersBlock'
import SearchHotelServicesResults from '../SearchHotelServicesResults'

export default function SearchResultsWithFilters({ result }) {
    const isSmallScreen = useMediaQuery('(min-width:700px)')

    const actions = useHotelServicesStore((state) => state.actions)

    const excursionType = useHotelServicesStore(
        ({ excursionType }) => excursionType
    )
    const checkedExcursionType = useHotelServicesStore(
        ({ checkedExcursionType }) => checkedExcursionType
    )

    const actionsType = useHotelServicesStore(
        ({ actionsType }) => actionsType
    )

    const checkedActionsType = useHotelServicesStore(
        ({ checkedActionsType }) => checkedActionsType
    )

    const filteredExcursions = useMemo(() => {
        if (
            isEmpty(checkedExcursionType) &&
            isEmpty(checkedActionsType)
        ) {
            return result
        } else {
            return result?.filter(service => {
                const serviceType = service?.other_params?.serviceType?.map(
                    item => item?.name
                )
                const actionsType = service?.other_params?.actionType?.map(
                    item => item?.name
                )

                return (
                    checkedExcursionType.every(item => serviceType?.includes(item)) &&
                    checkedActionsType.every(item => actionsType?.includes(item))
                )
            })
        }
    }, [checkedActionsType, checkedExcursionType, result])

    return (
        <Grid
            direction={isSmallScreen ? 'row' : 'column'}
            container
            sx={{ mt: 2, mb: 2 }}
            columnSpacing={'20px'}
            columns={26}>
            {(!isEmpty(excursionType) || !isEmpty(actionsType)) && <Grid item xs={5}>
                {isSmallScreen ? <ServiceFiltersBlock /> : null}
            </Grid>}
            <Grid item xs={!isEmpty(excursionType) || !isEmpty(actionsType) ? 21 : 26}>
                {isEmpty(filteredExcursions) ? (
                    <NotFindService clearFilters={actions.clearFilters} />
                ) : (
                    <SearchHotelServicesResults lists={filteredExcursions} />
                )}
            </Grid>
        </Grid>
    )
}
