import React from 'react';

import './style.css'
import UploadForm from "./UploadForm";
import UploadSuccess from "./UploadSuccess";
import useErskStore from "../store";
import UploadFail from "./UploadFail";
import UserNotFound from "./UserNotFound";

const UploadDocuments = () => {

	const uploadSuccess = useErskStore(({ uploadSuccess }) => uploadSuccess)
	const uploadFail = useErskStore(({ uploadFail }) => uploadFail)
	const userNotFound = useErskStore(({ userNotFound }) => userNotFound)

	return (
		<div className={'upload-documents'}>
			<h2>Загрузка документов в ЛК клиента</h2>
			{!uploadSuccess && !uploadFail && !userNotFound && <UploadForm/>}
			{uploadSuccess && <UploadSuccess />}
			{uploadFail && <UploadFail />}
			{userNotFound && <UserNotFound />}
		</div>
	);
};

export default UploadDocuments;