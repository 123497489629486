import React, { useEffect, useState } from "react";

import useGeoStore from "../../LandingPage/store";
import MainBannerSlider from "../../LandingPage/components/MainBannerSlider";
import MainBanner from "../../LandingPage/components/MainBanner";
import InsuranceSearchForm from "./components/SearchForm";
import useInsuranceStore from "./store";
import SearchResults from "./components/SearchResults";
import ContentBlock from "./components/ContentBlock";
import { Helmet } from "react-helmet";
import StaticBlocks from "../../LandingPage/StaticBlocks";
import { isMobile } from "react-device-detect";

const Insurances = () => {
	const actions = useGeoStore(({ actions }) => actions);
	const content = useGeoStore(({ content }) => content);
	const insurances = useInsuranceStore(({ insurances }) => insurances);
	const currency = useInsuranceStore(({ currency }) => currency);

	const [isUpdated, setIsUpdated] = useState(false);

	useEffect(() => {
		actions.getPage("/searchinsurances").then(setIsUpdated(true));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			actions.clearContent();
		};
		// eslint-disable-next-line
	}, []);

	if (!content || !isUpdated) return null;

	return (
		<div className={"landing"}>
			<Helmet title={content?.meta_title || content?.title} />
			{ content.search_frame && <div className={"container"}>
				<InsuranceSearchForm />
			</div> }
			{content.main_banner && (
				JSON.parse(content.main_banner.content).mainBanner.sliderMainBanner &&
				JSON.parse(content.main_banner.content).mainBanner.boolSliderMainBanner ? (
					<>
						{ content.search_frame && <div className={"container"} style={{ marginBottom: "43px" }}></div>}
						<MainBannerSlider
							content={JSON.parse(content.main_banner.content)}
						/>
					</>
				) : (
					<MainBanner
						content={JSON.parse(content.main_banner.content)}
						showBanner={!insurances}
					/>
				)
			)}
			{insurances && (
				<SearchResults
					items={insurances.items}
					isCompleted={insurances.isCompleted}
					currency={currency}
				/>
			)}
			<div className={"container"}>
				<StaticBlocks
					blocks={content?.blocks.filter((block) =>
						isMobile ? block.show_mobile : block.show_desktop
					)}
				/>
			</div>

			{/* {<ContentBlock />} */}
		</div>
	);
};

export default Insurances;
