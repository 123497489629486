import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

export default function ListLinks({ content, title }) {
	// console.log('LINKS', content)
	return (
		<div
			className='blockWithListLinks'
			style={{ background: content.background.gradient }}>
			{content.image && (
				<div
					className={'image__block'}
					style={{ backgroundImage: `url(${content.image})` }}
				/>
			)}
			{title && <h4 className='title'>{title}</h4>}
			<ul>
				{content?.links?.map(link => (
					<li key={link.order}>
						<Link to={`${link.link}`}>{link.link_text}</Link>
					</li>
				))}
			</ul>
		</div>
	)
}
