import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { selectAgentFees } from "../../../store/modules/agents/agent-fees/selectors"
import { TableUi } from "../TableUi"
import { useNavigate  } from "react-router-dom"
import { deleteFeeRequest } from "../../../store/modules/agents/agent-fees/duck"
import warningIcon from "../../../asset/images/avia/agents/warningIcon.svg"
import {styled as MUIstyled} from '@mui/material/styles'
import Table from './MUITable'
import PrimaryButton from "../../ui/PrimaryButton"

const Container = styled.div`
  margin: 30px 0;
`

const StyledButton = MUIstyled(PrimaryButton)(() => ({
  marginTop: '38px',
}))


const Text = styled.div`
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
`

const RedBlock = styled.div`
  background: #ffe2e3;
  border: 1px solid #f25f61;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
`

const WarningIcon = styled.img.attrs({ src: warningIcon })`
  margin-right: 7px;
`

const BlueBlockText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 12px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 33px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f25f61;
`

const Title = styled.p`
  color:  #3C3C3C;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
  margin-bottom: 20px;
`;

const FeesTable = () => {
  const data = useSelector(selectAgentFees)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <Container>
      <Title >Настройки правил сборов по перелетам</Title>
      <Text>
        В данном блоке доступна таблица с созданными правилами сборов.
        Нажмите кнопку “Создать новую запись” для создания нового правила.
      </Text>
      <RedBlock>
        <Row>
          <WarningIcon />
          Для авиаперелета применяется только одно правило!
        </Row>
        <Column>
          <BlueBlockText>
            При создании правила учтите все необходимые параметры в настройке
            сборов: класс обслуживания, тип перелета, количество пассажиров и
            сегменты.
          </BlueBlockText>
          <BlueBlockText>
            Если создано два правила, отвечающих одному перелету, то применится
            последнее по времени создания.
          </BlueBlockText>
        </Column>
      </RedBlock>
      {!!data?.length && (
        <Table 
          items={data}
          onEdit={(rule) => {navigate(`/cabinet/avia-agent-info/rules/${rule.id}`)}}
          onDelete={(rule) => {dispatch(deleteFeeRequest(rule?.id ?? ""))}}
        />
      )}
      {!data?.length && <TableUi.SubTitle>Правила не найдены</TableUi.SubTitle>} 
      <StyledButton onClick={() => navigate("/cabinet/avia-agent-info/rules")}>
        Создать новую запись
      </StyledButton>
    </Container>
  )
}

export default FeesTable
