import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import '../style.css'
import { isEmpty } from 'lodash'
import useBookingStore from '../../../store'
import AviaFilter from './AviaFilter'
import AviaFlightsTable from './AviaFlightsTable'

const AviaRegBlock = ({ tour }) => {
	const actions = useBookingStore(({ actions }) => actions)
	const flights = useBookingStore(state => state?.tour?.flightOptions)
	const withoutFlights = tour?.tourType.id === 7 || tour?.name?.includes('(hotel only)') || tour?.tourType.id === 30

	useEffect(() => {
		if (!withoutFlights && tour?.gdsId && isEmpty(flights)) {
			actions.getRegularFlights()
		}
	}, [tour, actions, flights, withoutFlights])

	if (withoutFlights) {
		return <>
			<div className='block-header'>
				<h3 className='title'>Транспорт</h3>
			</div>
			<div className='no-flights'>Нет доступных перелетов</div>
		</>
	}

	return (
		<>
			<div className='block-header'>
				<h3 className='title'>Транспорт</h3>
			</div>
			{!isEmpty(flights) && <AviaFilter flights={flights} />}
			{isEmpty(flights) ? (
				<div className='no-flights'>
					<CircularProgress />
				</div>
			) : (
				<AviaFlightsTable />
			)}
		</>
	)
}

export default AviaRegBlock
