import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as CloseImg } from '../../../../asset/images/cabinet/close.svg'
import { MuiTextField } from '../../AgentInfo/components/MuiTextField/MuiTextField'
import useFitClaimStore from '../store'

const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 24px;
	background-color: #fff;
	width: 828px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;

	max-height: 457px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 4px;
		border-radius: 8px;
		background-color: #D4D9DE;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #4872F2;
	}



`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`
const CommentBlock = styled(Row)`
	display: flex;
	flex-direction: row;
	gap: 24px;

	> :first-child {
   	   width: 551px;
		::-webkit-scrollbar {
			width: 4px;
			border-radius: 8px;
			background-color: #D4D9DE;
		}
		::-webkit-scrollbar-thumb {
			border-radius: 8px;
			background-color: #4872F2;
		}
  	}

	> :last-child {
		flex: 1;
	}
`

const PopupTitle = styled.h2`
	color: #2e2e32;
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`

const ButtonFilled = styled(Button)`
	align-self: end;

	background-color: #4872f2;
	padding: 16px 32px;
	border-radius: 8px;
	box-shadow: none;
	min-width: fit-content;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
	&:hover {
		box-shadow: none;
	}
`

const Table = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-height: 219px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 4px;
		border-radius: 8px;
		background-color: #D4D9DE;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #4872F2;
	}
`

const TableHead = styled.div`
	display: grid;
	grid-template-columns: 540px 220px;
	gap: 16px;
` 

const TabelTitle = styled.div`
	padding: 0px;

	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
`
const TabelText = styled(TabelTitle)`
	font-family: Open Sans;
	font-weight: 400;
`

const TabelRow = styled(TableHead)`
	padding: 16px 0;
	border-bottom: 1px solid #DBE0E5;
	&:last-child {
		border-bottom: none;
	}
`

export default function CommentModal({ claimId, comments, onClose }) {
	const actions = useFitClaimStore(({ actions }) => actions)
	const newCommentValue = useFitClaimStore(({ newCommentValue }) => newCommentValue)
	const loading = useFitClaimStore(({ loading }) => loading)

	React.useEffect(() => {
		document.body.style.overflow = 'hidden' 
		return () => {
			document.body.style.overflow = '';
		};
	  }, []);

	const handleClose = () => {
		actions.setNewCommentValue('')
		onClose()
	}
	
	return (
		<PopupBackground>
			<PopupContent>
				<Row>
					<PopupTitle>Заявка {claimId}</PopupTitle>
					<CloseBtn onClick={handleClose} />
				</Row>		 
				<Table>
					<TableHead>
						<TabelTitle>Комментарий</TabelTitle>
						<TabelTitle>Автор</TabelTitle>
					</TableHead>
					{comments?.map(comment => (
                        <TabelRow key={comment.updated}>
                            <TabelText>{comment.body}</TabelText>
                            <TabelText>{comment.author.displayName}</TabelText>
                        </TabelRow>
                    ))}
					{loading && <TabelRow><TabelText><CircularProgress size={15}  /></TabelText><TabelText><CircularProgress size={15}   /></TabelText></TabelRow>}

				</Table>
				<CommentBlock>
					<MuiTextField
						value={newCommentValue}
						onChange={(event) => actions.setNewCommentValue(event.target.value)}
						fullWidth
						variant='filled'
						multiline
						rows={3}
						label='Новый комментарий'
					/>
					<ButtonFilled
						onClick={() => actions.postComment(claimId)}
						fullWidth
						disabled={!newCommentValue}
						variant='contained'
						color='primary'>
					Комментировать
					</ButtonFilled>
				</CommentBlock>	
			</PopupContent>
		</PopupBackground>
	)
}
