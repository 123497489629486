export const APPEAL_STEP = {
  DEFAULT: 'default',
  REQUEST: 'request',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const STATUS = {
  loading: 'loading',
  failure: 'failure',
  success: 'success',
}

export const addAppealInitialState = {
  STEP: APPEAL_STEP.DEFAULT,
  response: null
}

export const AppealListInitialState = {
  pageCount: 0,
  tickets: [],
  currPage: 0,
  isLoading: false
}

export const AppealDetailInitialState = {
  id: "",
  number: 3,
  orderNumber: 0,
  flight_Type: "Regular",
  orderId: "",
  segmentId: null,
  passengers: null,
  fileInfos: [],
  calculations: [],
  passengerPriceTotal: 0,
  subject: {
    code: "",
    description: ""
  },
  status: {
    code: "",
    description: ""
  },
  text: "",
  userId: "",
  createdDate: "",
  calculation: null,
  aviaSegments: [
    {
      departureCityCode: "",
      arrivalCityCode: ""
    }
  ],

  selectedTickets: [
    {
      id: "",
      firstName: "",
      lastName: "",
      eTicketNumber: "",
      patronymic: "",
      userId: "",
      passengerType: "",
      price: {
        basePrice: 0,
        taxes: 0,
        subAgentExtraCharge: 0,
        agentExtraCharge: 0,
        discount: 0
      }
    }
  ],
  supportTicketCalculationItems: [],
  selectedAncillaryServices: [],
  selectedInsurances: []
}

export const MODALS  ={
  CALCULATION: 'CALCULATION',
  CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
  DECLINE_CONFIRM: 'DECLINE_CONFIRM',
  DECLINE_SUCCESS: 'DECLINE_SUCCESS',
  FAILURE: 'FAILURE',
  LOADING: 'LOADING',
}


export const DEFAULT_VISIBILITY = {
  [MODALS.CALCULATION]: false,
  [MODALS.CONFIRM_SUCCESS]: false,
  [MODALS.DECLINE_CONFIRM]: false,
  [MODALS.DECLINE_SUCCESS]: false,
  [MODALS.FAILURE]: false,
  [MODALS.LOADING]: false,
}

export const CALCULATION_MODALS_INITIAL_STATE = {
  visibility: DEFAULT_VISIBILITY,
  data: null,
}