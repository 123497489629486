import { useMediaQuery } from '@material-ui/core'
import React from 'react'

const EmptyLine = ({ content }) => {
	const isMobile = useMediaQuery('(min-width:480px)')
	const style = {
		height: `${isMobile ? content.desktop : content?.mobile}px`,
		minHeight: `${isMobile ? content.desktop : content?.mobile}px`,
		maxHeight: `${isMobile ? content.desktop : content?.mobile}px`
	}

	return <div className={'container'} style={style} />
}

export default EmptyLine
