import React from 'react';
import RCSlider from 'rc-slider';
import "rc-slider/assets/index.css";
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledRange = styled(RCSlider)`
  & .rc-slider-handle {
    border: 1px solid ;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    color: #B9B9B9;
    opacity: 1;
  }
  & .rc-slider-handle-dragging {
    border-color: #4872F2 !important;
    box-shadow: none !important;
  }
  & .rc-slider-track {
    background-color: #4872F2;
    height: 1px;
  }
  & .rc-slider-rail {
    height: 1px;
  }

  &.rc-slider {
    width: 93%;
  }
`;

const Range = (props) => {
  return (
    <Wrapper>
      <StyledRange {...props} />
    </Wrapper>
  );
};

export default Range;
