import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import formatPrice from "../../../functions/formatPrice"
import { ReactComponent as Meal } from "../../../asset/images/avia/addictional-services/meal.svg"
import { ReactComponent as Luggage } from "../../../asset/images/avia/addictional-services/luggage.svg"
import { ReactComponent as Others } from "./assets/media/others.svg"
import { ReactComponent as Added } from "./assets/media/payment-awaiting.svg"
import { ReactComponent as SeatMap } from "../../../asset/images/avia/addictional-services/seat-map.svg"
import { ReactComponent as TriangleIcon } from './assets/media/triangle.svg';
import { useSelector } from "react-redux"
import {getDetailOrder, ancillaryServicesSelector} from "../../../store/modules/orders"

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    padding: 10px 14px;
  }
`

const PositionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const PositionHeadRight = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 5px;
  }
`

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 5px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    position: initial;
  }
`

const Price = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const AddedIcon = styled(Added)`
  position: absolute;
  right: -120px;
  @media (max-width: 767px) {
    position: absolute;
    right: -4px;
    top: -12px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const PositionsList = styled.div`
  margin-left: 35px;

  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`

const PassengersList = styled.div`
  margin-top: 15px;

  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`

const Position = styled.div`
  ${({ open }) => {
    if (!open) {
      return `
        ${PositionHeadRight} {
          svg {
            transform: rotate(180deg);
          }
        }
        ${PassengersList} {
          display: none;
        }
      `
    }
  }}
`

const PositionName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const Passenger = styled.div``

const PassengerName = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding-left: 20px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    padding-left: 9px;
  }
`

const AncillaryList = styled.div`
  margin-top: 3px;
`
const Ancillary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 5px 0;
  padding-left: 20px;
  @media (max-width: 767px) {
    padding-left: 9px;
  }

  ${({ disabled }) =>
    disabled ? "background: #F2F2F2; padding-right: 5px;" : ""}
`

const AncillaryName = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
  color: #3c3c3c;
`
const AncillaryPrice = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

// const PositionTitleMobile = styled(Text)`
//   font-size: 12px;
//   line-height: 16px;
//   @media (min-width: 768px) {
//     display: none;
//   }
// `

const RedText = styled.span`
  font-weight: 600;
  color: #d40e14;
`

const PlaceList = styled.div`
  & > div {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  margin-left: 20px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`

const Place = styled.div``

const PlaceTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`

const GrayText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
`

export default function AncillaryServices() {
  const items = useSelector(ancillaryServicesSelector)
  const orderData = useSelector(getDetailOrder)

  const [openSegmentIndexes, setOpen] = React.useState({})

  return (
    <>
      {items.meal.length > 0 && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <Meal style={{ width: 30, height: 30 }} />
                <Name>
                  Питание в самолете {orderData?.paymentStatus?.code === 'NotPaid' && <AddedIcon />}
                </Name>
              </Row>
            </Head>
            <PositionsList>
              {items.meal.map((x, key) => (
                <Position
                  key={key}
                  open={openSegmentIndexes[`food-${x.positionName}-${key}`]}
                >
                  <PositionHead
                    onClick={() => {
                      const k = `food-${x.positionName}-${key}`
                      setOpen(s => ({
                        ...s,
                        [k]: s[k] ? !s[k] : true
                      }))
                    }}
                  >
                    <PositionHeadRight>
                      <PositionName>{x.positionName}</PositionName>
                       <TriangleIcon />
                    </PositionHeadRight>
                    <Price>
                      {formatPrice(
                        x.passengers.reduce(
                          (acc, x) =>
                            acc +
                            x.items
                              .filter(x => x.status.code !== "Refunded")
                              .reduce((acc, x) => acc + x.price, 0),
                          0
                        )
                      )}
                    </Price>
                  </PositionHead>
                  <PassengersList>
                    {x.passengers.map((x, key) => (
                      <Passenger key={key}>
                        <PassengerName>{x.name}</PassengerName>
                        <AncillaryList>
                          {x.items.map((x, key) => (
                            <Ancillary disabled={x.status.code === "Refunded"}>
                              <AncillaryName>
                                {x.name}{" "}
                                {x.emDcode && `(№ услуги: ${x.emDcode})`}{" "}
                                {x.status.code === "Refunded" && (
                                  <RedText>аннулирована</RedText>
                                )}
                              </AncillaryName>
                              <AncillaryPrice>
                                {formatPrice(x.price)}
                              </AncillaryPrice>
                            </Ancillary>
                          ))}
                        </AncillaryList>
                      </Passenger>
                    ))}
                  </PassengersList>
                </Position>
              ))}
            </PositionsList>
          </Content>
        </Wrapper>
      )}
      {items.luggage.length > 0 && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <Luggage style={{ width: 30, height: 30 }} />
                <Name>
                  Дополнительный багаж {orderData?.paymentStatus?.code === 'NotPaid' && <AddedIcon />}
                </Name>
              </Row>
            </Head>
            <PositionsList>
              {items.luggage.map((x, key) => (
                <Position
                  key={key}
                  open={openSegmentIndexes[`luggage-${x.positionName}-${key}`]}
                >
                  <PositionHead
                    onClick={() => {
                      const k = `luggage-${x.positionName}-${key}`
                      setOpen(s => ({
                        ...s,
                        [k]: s[k] ? !s[k] : true
                      }))
                    }}
                  >
                    <PositionHeadRight>
                      <PositionName>{x.positionName}</PositionName>
                       <TriangleIcon />
                    </PositionHeadRight>
                    <Price>
                      {formatPrice(
                        x.passengers.reduce(
                          (acc, x) =>
                            acc +
                            x.items
                              .filter(x => x.status.code !== "Refunded")
                              .reduce((acc, x) => acc + x.price, 0),
                          0
                        )
                      )}
                    </Price>
                  </PositionHead>
                  <PassengersList>
                    {x.passengers.map((x, key) => (
                      <Passenger key={key}>
                        <PassengerName>{x.name}</PassengerName>
                        <AncillaryList>
                          {x.items.map((x, key) => (
                            <Ancillary disabled={x.status.code === "Refunded"}>
                              <AncillaryName>
                                Багаж {x.value} кг{" "}
                                {x.emDcode && `(№ услуги: ${x.emDcode})`}{" "}
                                {x.status.code === "Refunded" && (
                                  <RedText>аннулирована</RedText>
                                )}
                              </AncillaryName>
                              <AncillaryPrice>
                                {formatPrice(x.price)}
                              </AncillaryPrice>
                            </Ancillary>
                          ))}
                        </AncillaryList>
                      </Passenger>
                    ))}
                  </PassengersList>
                </Position>
              ))}
            </PositionsList>
          </Content>
        </Wrapper>
      )}

      {items.seats && items.seats.length > 0 && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <SeatMap style={{ width: 30, height: 30 }} />
                <Name>
                  Место в самолете {orderData?.paymentStatus?.code === 'NotPaid' && <AddedIcon />}
                </Name>
              </Row>
            </Head>
            <PositionsList>
              {items.seats.map((x, key) => {
                return (
                  <Position
                    key={key}
                    open={openSegmentIndexes[`seats-${x.name}-${key}`]}
                  >
                    <PositionHead
                      onClick={() => {
                        const k = `seats-${x.name}-${key}`
                        setOpen(s => ({
                          ...s,
                          [k]: s[k] ? !s[k] : true
                        }))
                      }}
                    >
                      <PositionHeadRight>
                        <PositionName>{x.name}</PositionName>
                         <TriangleIcon />
                      </PositionHeadRight>
                      <Price>
                        {formatPrice(
                          (x.items || [])
                            .filter(x => x.status.code !== "Refunded")
                            .reduce((acc, x) => acc + x.price, 0)
                        )}
                      </Price>
                    </PositionHead>
                    <PassengersList>
                      <PlaceList>
                        {(x.items || []).map((x, key) => (
                          <Place key={key}>
                            <PlaceTop>
                              <AncillaryName>{x.segmentFromTo}</AncillaryName>
                              <AncillaryPrice>
                                {formatPrice(x.price)}
                              </AncillaryPrice>
                            </PlaceTop>
                            <GrayText>
                              Место {x.seatNumber}
                              {x.emDcode ? ` ( № услуги: ${x.emDcode} )` : ""}
                            </GrayText>
                          </Place>
                        ))}
                      </PlaceList>
                    </PassengersList>
                  </Position>
                )
              })}
            </PositionsList>
          </Content>
        </Wrapper>
      )}

      {items.others.length > 0 && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <Others style={{ width: 30, height: 30 }} />
                <Name>
                  Другие услуги {orderData?.paymentStatus?.code === 'NotPaid' && <AddedIcon />}
                </Name>
              </Row>
            </Head>
            <PositionsList>
              {items.others.map((x, key) => (
                <Position
                  key={key}
                  open={openSegmentIndexes[`seats-${x.positionName}-${key}`]}
                >
                  <PositionHead
                    onClick={() => {
                      const k = `food-${x.positionName}-${key}`
                      setOpen(s => ({
                        ...s,
                        [k]: s[k] ? !s[k] : true
                      }))
                    }}
                  >
                    <PositionHeadRight>
                      <PositionName>{x.positionName}</PositionName>
                       <TriangleIcon />
                    </PositionHeadRight>
                    <Price>
                      {formatPrice(
                        x.passengers.reduce(
                          (acc, x) =>
                            acc +
                            x.items
                              .filter(x => x.status.code !== "Refunded")
                              .reduce((acc, x) => acc + x.price, 0),
                          0
                        )
                      )}
                    </Price>
                  </PositionHead>
                  <PassengersList>
                    {x.passengers.map((x, key) => (
                      <Passenger key={key}>
                        <PassengerName>{x.name}</PassengerName>
                        <AncillaryList>
                          {x.items.map((x, key) => (
                            <Ancillary disabled={x.status.code === "Refunded"}>
                              <AncillaryName>
                                {x.name}{" "}
                                {x.emDcode && `(№ услуги: ${x.emDcode})`}{" "}
                                {x.status.code === "Refunded" && (
                                  <RedText>аннулирована</RedText>
                                )}
                              </AncillaryName>
                              <AncillaryPrice>
                                {formatPrice(x.price)}
                              </AncillaryPrice>
                            </Ancillary>
                          ))}
                        </AncillaryList>
                      </Passenger>
                    ))}
                  </PassengersList>
                </Position>
              ))}
            </PositionsList>
          </Content>
        </Wrapper>
      )}
    </>
  )
}
