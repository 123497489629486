import React from 'react';
import { SvgIcon } from "@mui/material";

function ArrowRight(props) {
    return <SvgIcon {...props} viewBox="0 0 18 18" fill="none" style={{ width: "18px", height: "18px" }} >
        <path d="M11.4363 5.2489L11.4364 5.24885C11.4858 5.19937 11.5444 5.16012 11.6089 5.13334C11.6735 5.10656 11.7427 5.09277 11.8125 5.09277C11.8824 5.09277 11.9516 5.10656 12.0161 5.13334C12.0807 5.16012 12.1393 5.19937 12.1887 5.24885L12.1887 5.24888L15.5637 8.62388L15.5637 8.6239C15.6132 8.67325 15.6524 8.73188 15.6792 8.79642C15.706 8.86096 15.7198 8.93015 15.7198 9.00003C15.7198 9.06991 15.706 9.1391 15.6792 9.20364C15.6524 9.26818 15.6132 9.32681 15.5637 9.37615L15.5637 9.37618L12.1887 12.7512C12.0889 12.8509 11.9536 12.907 11.8125 12.907C11.6714 12.907 11.5361 12.8509 11.4364 12.7512C11.3366 12.6514 11.2806 12.5161 11.2806 12.375C11.2806 12.2339 11.3366 12.0986 11.4364 11.9989C11.4364 11.9989 11.4364 11.9989 11.4364 11.9989L14.4142 9.02213L14.4364 9.00003L14.4142 8.97793L11.4364 6.00118L11.4363 6.00115C11.3869 5.95181 11.3476 5.89318 11.3208 5.82864C11.2941 5.7641 11.2803 5.69491 11.2803 5.62503C11.2803 5.55515 11.2941 5.48596 11.3208 5.42142C11.3476 5.35688 11.3869 5.29825 11.4363 5.2489Z" fill="#737373" stroke="#737373" stroke-width="0.0625" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 9C2.25 8.85082 2.30926 8.70774 2.41475 8.60225C2.52024 8.49676 2.66332 8.4375 2.8125 8.4375H14.625C14.7742 8.4375 14.9173 8.49676 15.0227 8.60225C15.1282 8.70774 15.1875 8.85082 15.1875 9C15.1875 9.14918 15.1282 9.29226 15.0227 9.39775C14.9173 9.50324 14.7742 9.5625 14.625 9.5625H2.8125C2.66332 9.5625 2.52024 9.50324 2.41475 9.39775C2.30926 9.29226 2.25 9.14918 2.25 9Z" fill="#737373" />
    </SvgIcon>
}

export default ArrowRight