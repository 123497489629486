import { createAction, createReducer } from 'redux-act';
import {
  searchFormInitialState,
} from './constants';


export const initializeSearchForm = createAction('@search-form/initialize');


////////////////////////

/** Set quantity of passengers fro ticket search form */

export const setPassengers = createAction('@searchForm/setPassengers');

/** Set ticket class for search form */

export const setTicketClass = createAction(
  '@searchForm/setTicketClass'
);

/** Set date "from" and "to" for search from */

export const setDate = createAction('@searchForm/setDate');

/** Set return state */

export const setReturn = createAction('@searchForm/setReturn');

/** Set destination where from and where to*/

export const setDestination = createAction(
  '@searchForm/setDestination'
);

export const clearsearchForm = createAction('@searchForm/clearSearch');

export const updateSearchFormState = createAction(
  '@searchForm/updateState'
);

export const setPromotions = createAction('@searchForm/setPromotions');

//Reducer

export const searchFormReducer = createReducer({}, searchFormInitialState);

searchFormReducer.on(clearsearchForm, () => searchFormInitialState);

searchFormReducer.on(setPassengers, (state, payload) => {
  const MAX = 9;
  const adults = payload.adults || state.passengers.adults.count;

  const children =
    payload.children !== undefined
      ? payload.children
      : state.passengers.children.count;

  const infants =
    payload.infants !== undefined
      ? payload.infants
      : state.passengers.infants.count;

  const childrenMin = 0;
  const infantsMin = 0;
  const adultsMax = MAX - (children + infants);
  const adultsMin = Math.max(Math.ceil(children / 2), infants || 1);

  const childrenMax =
    MAX - (adults + infants) > adults * 2
      ? adults * 2
      : MAX - (adults + infants);

  const infantsMax =
    MAX - (adults + children) > adults ? adults : MAX - (adults + children);

  return {
    ...state,
    passengers: {
      adults: {
        count: adults,
        max: adultsMax,
        min: adultsMin,
      },
      children: {
        count: children,
        max: childrenMax,
        min: childrenMin,
      },
      infants: {
        count: infants,
        max: infantsMax,
        min: infantsMin,
      },
    },
  };
});

searchFormReducer.on(setTicketClass, (state, payload) => {
  return {
    ...state,
    ticketClass: payload,
  };
});

searchFormReducer.on(setDate, (state, dates) => {
  return {
    ...state,
    dates,
  };
});

searchFormReducer.on(setReturn, (state, returnTicket) => {
  return { ...state, returnTicket };
});

searchFormReducer.on(setDestination, (state, payload) => {
  return { ...state, ...payload };
});

searchFormReducer.on(setPromotions, (state, promotions) => {
  return {
    ...state,
    promotions,
  };
});
