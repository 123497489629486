import React from "react"
import AddictionalServiceTemplate from "./AddictionalServicesTemplate"
import CalculationTemplate from "./CalculationTemplate"

export default function Calculations({ onGoInsideAppeal, onPay, ...props }) {
  if (
    props.supportTicketCalculation &&
    props.supportTicketCalculation.supportTicketCalculation.status.code !==
      "ApprovedByClient"
  ) {
    return (
        <CalculationTemplate
          {...props.supportTicketCalculation}
          onPay={onPay}
          onGoInsideAppeal={onGoInsideAppeal}
        />
    )
  }
  if (
    props.additionalServivesInfo ||
    props.insuranceInfo ||
    (props.supportTicketCalculation &&
      //@ts-ignore
      props.supportTicketCalculation.supportTicketCalculation.status.code ===
        "ApprovedByClient")
  ) {
    return (
      <AddictionalServiceTemplate
        additionalServivesInfo={props.additionalServivesInfo}
        insuranceInfo={props.insuranceInfo}
        supportTicketCalculation={props.supportTicketCalculation}
        onPay={onPay}
      />
    )
  }
  return null
}
