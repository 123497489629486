import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'

const CustomSelect = ({options, title, onChange, value, enabledValue, count, isSex = false}) => {

	return (
		<FormControl variant="outlined" style={{width: '100%'}}>
			<InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={value}
				onChange={(e) => onChange(e.target.value, count)}
				label={title}
				className="custom-select"
			>
				{!value && <MenuItem key={0} value={0}>--------</MenuItem>}
				{options.map((item) =>
					<MenuItem
						className={`${enabledValue && enabledValue.includes(item) ? 'place-enabled' : ''}`}
						key={item.id || item}
						value={item.id || item}
					>
						{item.name || item}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default CustomSelect;
