import React, {useEffect, useRef, useState} from 'react';
import {isEmpty} from "lodash";
import {TextField} from "@material-ui/core";
import iconSearch from "../../../../../asset/images/chosen-sprite.png";
import CountryChangeModal from './CountryChangeModal'
import { isIOS } from 'react-device-detect';

import './style.scss'
const CountryField = ({lists, onChange, title, value, withSearch = true, ...props}) => {

	const [showBody, setShowBody] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [selectValue, setSelectValue] = useState(value)
	const [useFilter, setUseFilter] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const ref = useRef(null);
	const textInput = useRef(null);

	useEffect(() => {
		function handleClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [ref]);

	useEffect(() => {
		if (textInput.current) {
			textInput.current.focus();
		}
	}, [textInput, showBody])

	useEffect(() => {
		if (textInput.current && !isIOS) {
			textInput.current.focus();
		}
	}, [modalVisible])

	useEffect(() => {
		if (!isEmpty(lists)) {
			if (lists.find(item => item?.value?.toString() === value?.toString())) {
				setSelectValue(lists.find(item => item?.value?.toString() === value?.toString()).name)
			} else {
				setSelectValue(lists[0].name)
			}
		}
		setUseFilter(false)
	}, [lists, value])

	if (!lists) return null

	const toggle = () => {
		if (window.innerWidth > 766) {
			setUseFilter(false)
			setInputValue('')
			setShowBody(!showBody)
		} else {
			setUseFilter(false)
			setInputValue('')
			setModalVisible(true)
		}
	}

	const handleChange = (id) => {
		const item = lists.find(item => item.value === id)

		setShowBody(false)
		setModalVisible(false)
		setSelectValue(item?.name ?? '----' )
		setUseFilter(false)
		onChange(item?.value ?? item)
	}


	const changeInput = (e) => {
		setInputValue(e.target.value.toLowerCase())
		setUseFilter(true)
	}

	const getFiltered = () => {
		let result
		if(!inputValue || !useFilter){
			result = lists
		} else {
			result = lists.filter(el =>
				el.name.toLowerCase().includes(inputValue) ||
				el.alias?.includes(inputValue) ||
				el.puntoName?.toLowerCase().includes(inputValue)
			)
		}
		return result
	}


	const filtered = getFiltered()

	return (
		<div className="v-departure-base-CountryField" ref={ref}>
			<div className="v-departure">
				<div onClick={toggle}>
					<div>
						<TextField
							id={'country'}
							label={title}
							variant="outlined"
							fullWidth
							autoComplete={'off'}
							value={selectValue}
							{...props}
						/>
					</div>
				</div>
				{showBody && <div className="v-departure__body-container">
					{withSearch && <div className={'chosen-search'}>
						<input
							type="text"
							onChange={changeInput}
							value={inputValue}
							autoComplete={'off'}
							className={'chosen-search__field'}
							ref={textInput}
							style={{backgroundImage: `url(${iconSearch})`}}
						/>
					</div>}
					<div className="v-departure__body">
						{useFilter && isEmpty(filtered) && <div className={'empty-search'}>Нет совпадений</div>}
						{filtered.map((item) => {
							return (
								<div
									key={item.value || item}
									className={`v-departure__elem v-text-16`}
									onClick={() => handleChange(item.value || item)}
								>
									{item.name || item}
								</div>
							)
						})}
					</div>
				</div>}
			</div>
			{modalVisible && (
				<CountryChangeModal 
					title={title}
					changeInput={changeInput}
					inputValue={inputValue}
					textInput={textInput}
					useFilter={useFilter}
					filtered={filtered}
					handleChange={handleChange}
					onClose={setModalVisible}>
				</CountryChangeModal>
			)}
		</div>
	);
};

export default CountryField;