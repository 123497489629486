import React, { useEffect } from 'react';
import CityFields from '../CityFields';
import { Button } from '../../ui';
import DatePicker from '../DatePicker';
import PassengerPicker from '../PassengersPicker';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import createSearchParams from '../../../functions/createSearchParams'
import { useFormik } from 'formik';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useFocus } from '../useFormFocus';


const Wrapper = styled.div`
  font-family: "Open Sans";
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > button {
    margin-left: 12px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const SearchButton = styled(Button)`
  width: 146px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 30.5px;
  margin-left: 10px;
  white-space: nowrap;
  box-sizing: border-box;

  @media (max-width: 1122px) and (min-width: 1025px) {
    width: 161px;
  }
`;

const Form = styled.form``;

export const DesktopSearchForm = (props) => {
  const data = useSelector((state) => state.searchForm);
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const { setFocus, focus } = useFocus();

  const isFocusActive = React.useMemo(() => !!focus, [focus]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) {
          setFocus(null);
        }
      }
    };

    if (!isFocusActive) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isFocusActive]);

  const formik = useFormik({
    initialValues: {
      from: typeof data.from === 'object' ? data.from.nameRus : data.from,
      to: typeof data.to === 'object' ? data.to.nameRus : data.to,
    },
    validate: ({ from, to }) => {
      const errors = {};

      if (from === '') {
        errors.from = 'Укажите город';
      }
      if (to === '') {
        errors.to = 'Укажите город';
      }
      return errors;
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      // ReactGA.event({
      //   category: 'Search',
      //   action: 'flights_search_form_submit',
      // });
      navigate(
        `/avia/search/${createSearchParams({
          fromCode: data.from.iataCode,
          ToCode: data.to.iataCode,
          forwardDate: data.dates.from || moment(),
          backwardDate: data.dates.to,
          adults: data.passengers.adults.count,
          children: data.passengers.children.count,
          infants: data.passengers.infants.count,
          flightClass: data.ticketClass.id,
        })}`
      );
    },
  });

  useEffect(() => {
    if (data.from !== null) {
      formik.setFieldValue('from', data.from);
      delete formik.errors['from'];
      formik.setErrors({ ...formik.errors });
    }
    if (data.to !== null) {
      formik.setFieldValue('to', data.to);
      delete formik.errors['to'];
      formik.setErrors({ ...formik.errors });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Form
      className={props.className}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Wrapper>
        <InputWrapper ref={ref}>
          <CityFields onBlur={formik.handleBlur} errors={formik.errors} />

          <DatePicker />
          <PassengerPicker />
        </InputWrapper>
        <SearchButton type="primary" htmlType="submit" color="yellow">
          Найти
        </SearchButton>
      </Wrapper>
    </Form>
  );
};
