import React from 'react';
import {Box} from "@mui/material";
import styled from "styled-components";

const PopupContent = styled.div`
	padding: 24px;
  	border-radius: 16px;
`

const Text = styled.p`
  	margin-bottom: 40px;
  	margin-top: 0;
`

const H2 = styled.h2`
  	margin-bottom: 40px;
`

const ButtonClose = styled.button`
  	border-radius: 8px;
  	width: 100%;
  	background-color: #FFFFFF;
  	color: #4872F2;
  	border: 1px solid #4872F2;
`

const ButtonSuccess = styled.button`
  	color: #FFFFFF;
  	border-radius: 8px;
  	background-color: #4872F2;
  	width: 100%;
`

const PaymentBonusModal = ({title, text, successButtonTitle, closeButtonTitle, onClose, onSuccess, className}) => {

	return (
		<div className="popup-bg">
			<PopupContent className="popup-block">
				<H2 className="popup-name">
					{title}
				</H2>
				{text && <Text className={`popup-text ${className}`}>{text}</Text>}
				<Box sx={{display: 'flex', gap: '16px'}}>
					{closeButtonTitle && <ButtonClose className="popup-btn" onClick={() => onClose(false)}>{closeButtonTitle}</ButtonClose>}
					{successButtonTitle && <ButtonSuccess className="popup-btn" onClick={onSuccess}>{successButtonTitle}</ButtonSuccess>}
				</Box>
			</PopupContent>
		</div>
	);
};

export default PaymentBonusModal;