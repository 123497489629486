import React from 'react'
import { Container, Divider } from '@material-ui/core'
import HotelPopup from './HotelPopup/HotelPopup'
import useHotelDescriptionStore from '../store'
import HotelLocationPopup from '../HotelLocation/HotelLocationPopup'
import HotelLocation from '../HotelLocation/HotelLocation'
import HotelSlider from './HotelSlider'
import StarsBlock from './StarsBlock'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CopyLink from './CopyLink'
import HotelIncludes from './HotelIncludes'
import BreadcrumbsBlock from './BreadcrumbsBlock'
import HotelTags from './HotelTags'

import './style.scss'
import './HotelPopup/style.css'
import PdfLink from './PdfLink'
import { useMediaQuery } from '@mui/material'
import MobileAboutHotelPopup from './HotelPopup/MobileVersion'
import MobileVersionLocation from '../HotelLocation/MobileVersion'

export default function HotelInfo() {
	const [open, setOpen] = React.useState(false)
	const BASE_SITE_URL = window.location.origin
	const handleClose = () => {
		setOpen(false)
	}

	const handleClickOpen = () => {
		setOpen(true)
	}
	const isSmallScreen = useMediaQuery('(min-width:700px)')

	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const standardizedName = useHotelDescriptionStore(
		({ standardizedName }) => standardizedName
	)
	const hotelDescText = useHotelDescriptionStore(
		({ hotelDescText }) => hotelDescText?.text
	)

	function createText() {
		return {
			__html:
				hotelDescText?.length > 196
					? hotelDescText?.slice(0, 193) + '...'
					: hotelDescText
		}
	}

	const [openMap, setOpenMap] = React.useState(false)
	const mapRef = React.useRef(null)

	const handleClickOpenMap = () => {
		if (!isSmallScreen) {
			mapRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
			return
		}
		setOpenMap(true)
	}
	const handleCloseMap = () => {
		setOpenMap(false)
	}

	if (!hotel) return null

	return (
		<div className='container'>
			<BreadcrumbsBlock
				locations={hotel?.urlName}
				hotelName={standardizedName}
				hotelId={hotel.id}
			/>
			<div className='hotel_info-block'>
				<div className='hotel_slider-wrapper'>
					{hotel?.images && (
						<HotelSlider images={hotel?.images} id={hotel?.id} />
					)}
				</div>
				<div className='hotel_info-right'>
					<div className='hotel_stars-share'>
						<div>
							{hotel?.hotelCategory?.name && (
								<StarsBlock
									count={Number(hotel?.hotelCategory?.name.replace('*', ''))}
								/>
							)}
						</div>{' '}
						<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
							<CopyLink link={BASE_SITE_URL + '/' + hotel?.urlName} />{' '}
							<PdfLink />{' '}
						</div>
					</div>
					<div>
						<h2 className='hotel_name'>{standardizedName}</h2>
					</div>
					{hotel?.address && (
						<div className='hotel_location' onClick={handleClickOpenMap}>
							<LocationOnIcon htmlColor={'#4872f2'} /> {hotel?.address}
						</div>
					)}
					{/* <Divider style={{ margin: '24px 0px' }} />
					<div className='hotel_price'></div>
					<Divider style={{ margin: '24px 0px' }} /> */}
					<HotelTags />
					<div className='hotel_includes'>
						<HotelIncludes />
					</div>
				</div>
			</div>

			{isSmallScreen ? (
				<HotelPopup handleClose={handleClose} open={open} />
			) : (
				<MobileAboutHotelPopup handleClose={handleClose} open={open} />
			)}
			<HotelLocationPopup open={openMap} handleClose={handleCloseMap} />
			<Divider style={{ margin: '24px 0px' }} />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'space-between',
					flexDirection: isSmallScreen ? 'row' : 'column'
				}}>
				<Container maxWidth='sm' style={{ paddingLeft: '0' }}>
					<h4 style={{ margin: '0px' }}>Описание отеля</h4>
					<p
						style={{ color: '#2e2e32' }}
						dangerouslySetInnerHTML={createText()}></p>
					<div className='description' onClick={handleClickOpen}>
						Подробнее об отеле
					</div>
					{!isSmallScreen && <Divider style={{ margin: '24px 0px' }} />}
				</Container>
				<Container ref={mapRef} maxWidth='sm' style={{ padding: 0 }}>
					{isSmallScreen ? (
						<HotelLocation handleClickOpenMap={handleClickOpenMap} />
					) : (
						<MobileVersionLocation />
					)}
				</Container>
			</div>
			<Divider style={{ margin: '24px 0px' }} />
		</div>
	)
}
