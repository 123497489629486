import React from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import {
  getAgentBillBySamoNumberRequest,
  getAgentBillByTteNumberRequest
} from "../../../store/modules/agents/agent-finance/duck"
import infoIcon from "../../../asset/images/avia/shared/info-icon.svg"
import agentInfoImage from "../../../asset/images/avia/agents/agentInfoImage.svg"
import AgentFinancePopUp from "./AgentFinancePopUp"
import agentBillByOrderImage from "../../../asset/images/avia/agents/agentBillByOrderImage.svg"
import ArrowButton from "./ArrowButton"
import Collapse from '@mui/material/Collapse';
import PrimaryButton from "../../ui/PrimaryButton"
import TextField from '../../ui/form/NewTextField'
import {styled as MUIStyled} from '@mui/material/styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  //width: 750px;
`

const Margin = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 20px;
  }
`

const StyledField = MUIStyled(TextField)(()=>({
  width: 256,
  marginRight: '20px',
}))

const StyledButton = MUIStyled(PrimaryButton)(()=>({
  
}))

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  cursor: pointer;
`

const BlueText = styled.div`
  font-family: "Open Sans", serif;
  color: #4872f2;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 5px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-left: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const AgentBillByOrderImage = styled.img.attrs({
  src: agentBillByOrderImage
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const SubText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 350;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const ColumnRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 25px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${({ isActive }) => (isActive ? "#4872F2" : "#dcdcdc")};
`

const GetBillByOrderId = ({
  orderNumber,
  setOrderNumber,
  tteNumber,
  setTteNumber
}) => {
  const [agentPopUpOpen, setAgentPopUp] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [hover, setHover] = React.useState(false)

  const isLoading = useSelector(
    state => state.agents.loadings.getBillByOrderOrTTE
  )
  const disabled = !tteNumber && !orderNumber

  const changeTteNumber = value => {
    if (orderNumber) {
      setOrderNumber("")
    }

    setTteNumber(value)
  }

  const changeOrderNumber = value => {
    if (tteNumber) {
      setTteNumber("")
    }

    setOrderNumber(value)
  }

  const dispatch = useDispatch()

  const submit = () => {
    if (tteNumber) {
      dispatch(getAgentBillByTteNumberRequest(tteNumber))
    } else {
      dispatch(getAgentBillBySamoNumberRequest(orderNumber))
    }
  }

  return (
    <Container>
      {agentPopUpOpen && (
        <AgentFinancePopUp
          agentPopUpOpen={agentPopUpOpen}
          setAgentPopUp={setAgentPopUp}
          image={agentInfoImage}
        />
      )}
      <Row
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <FlexRow>
          <AgentBillByOrderImage />{" "}
          <Title>Получить счет по номеру заявки </Title>
        </FlexRow>
        <ArrowButton onClick={() => setIsOpen(!isOpen)} isActive={isOpen}/>
      </Row>
      <SubTitle style={{ visibility: isOpen ? "hidden" : undefined }}>
        Формируйте счет на оплату по номеру заявки или номеру для оплаты.{" "}
      </SubTitle>
      <Collapse in={isOpen}>
        <div>
          <SubText style={{ marginBottom: 0}}>
            В данном блоке вам доступно получение счета на оплату. Для получения
            введите номер для оплаты или номер заявки и получите счет.
          </SubText>
          <ColumnRow>
            <Column>
              <StyledField
                value={orderNumber}
                autoComplete="off"
                onChange={event => {
                  changeOrderNumber(event.target.value)
                }}
                label="Номер для оплаты"
                name='numberForPayment'
                fullWidth
                infoIcon={{title: 'Номер для оплаты - это шестизначное число, начинающееся минимум с цифры 6.', width: 295}}
              />
            </Column>
            <Margin />
            <Column>
              <StyledField
                value={tteNumber}
                autoComplete="off"
                onChange={event => {
                  changeTteNumber(event.target.value)
                }}
                label="Номер заявки"
                name='numberOfOrder'
                fullWidth
                infoIcon={{title: 'Номер заявки в ТТЕ - это пятизначное число.', width: 168}}
              />
            </Column>
            <StyledButton
              disabled={disabled}
              onClick={() => {
                submit()
              }}
              isLoading={isLoading}
            >
              Получить счет
            </StyledButton>
          </ColumnRow>
          <FlexRow style={{ marginTop: 15 }}>
            <InfoIcon />
            <BlueText onClick={() => setAgentPopUp(true)}>
              Где находится номер заявки и номер для оплаты?{" "}
            </BlueText>
          </FlexRow>
        </div>
      </Collapse>
      <Separator isActive={hover} />
    </Container>
  )
}

export default GetBillByOrderId
