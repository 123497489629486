import { Box,  Fab, Fade, useScrollTrigger } from '@mui/material'
import { KeyboardArrowUp } from '@material-ui/icons'


export default function ScrollTop(props) {
	const { window } = props

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100
	})

	const handleClick = event => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			'#back-to-top-anchor'
		)
		if (anchor) {
			anchor.scrollIntoView({
				block: 'center',
				behavior: 'smooth' 
			})
		}
	}

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role='presentation'
				sx={{ position: 'fixed', bottom: 16, right: 16 }}>
				<Fab size='small' aria-label='scroll back to top'>
					<KeyboardArrowUp />
				</Fab>
				{/* {props.children} */}
			</Box>
		</Fade>
	)
}
