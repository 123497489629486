import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import OutlinedButton from '../../ui/OutlinedButton'
import IconButton from "@mui/material/IconButton"
import {ReactComponent as Icon} from "../../../asset/images/avia/appeal-forms/round-cross.svg"
import Dialog from '@mui/material/Dialog';
import {styled as MUIstyled} from '@mui/material/styles'

const StyledPopup = styled(Dialog)`

  & .MuiPaper-root {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`

const Title = styled(Text)`
  color:  #3C3C3C;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  margin-bottom: 17px;

  display: flex;
  align-items: center;

`

const Question = styled(Text)`
  color: #3C3C3C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
`

const ButtonBlock = styled.div`
  display: flex;
  margin-top: 20px;

  & > :first-child {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

const Button = MUIstyled(OutlinedButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20px',
}))

export default function CalculationFailureModal({ onConfirm, ...props }) {

    return (
        <StyledPopup {...props}>
            <Title><span>Произошла ошибка</span> <IconButton onClick={() => props.onClose()} ><Icon/></IconButton> </Title>
            <Question>
            При подтверждение расчёта произошла ошибка. Пожалуйста, попробуйте еще раз
            </Question>
            <ButtonBlock>
              <Button
                type="button"
                onClick={() => props.onClose()}
              >
                {props.closeButtonText}
              </Button>
            </ButtonBlock>
        </StyledPopup>
    )
}
