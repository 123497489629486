import React from "react"
import styled from "styled-components"
import { ReactComponent as DownloadIcon } from "../../asset/images/avia/shared/download.svg"
import Select, { components } from "react-select"
import { Text } from "../ui"
import { useSelector, useDispatch } from "react-redux"
import { getDetailOrder,getAgentOrderBillRequest } from "../../store/modules/orders"
import Api from '../../functions/fetchApi'

// http://order.tvm-tte-k8s.fstravel.com/api/order
const selectStylesPrimary = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0
  }),
  input: (provided, state) => ({
    ...provided,
    fontFamily: "Open Sans",
    color: "#3C3C3C",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    justifyContent: "center"
    // paddingLeft: '12px',
  }),
  container: (provided, state) => ({
    ...provided,
    padding: 0,
    cursor: "pointer"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    padding: 0,
    color: "black"
  }),
  control: (provided, { isFocused, ...state }) => {
    const boxShadow = "none"
    return {
      ...provided,
      height: 40,
      minHeight: 40,
      border: "none",
      boxShadow,
      background: "#4872f2",
      borderRadius: 4,
      cursor: "pointer",
      maxWidth: 210
    }
  },
  menu: (provided, state) => ({ ...provided, zIndex: 100 })
}

const selectStylesOutlined = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0
  }),
  input: (provided, state) => ({
    ...provided,
    fontFamily: "Open Sans",
    color: "#3C3C3C",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    justifyContent: "center"
    // paddingLeft: '12px',
  }),
  container: (provided, state) => ({
    ...provided,
    padding: 0,
    cursor: "pointer"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    padding: 0,
    color: "black"
  }),
  control: (provided, { isFocused, ...state }) => {
    return {
      ...provided,
      height: 40,
      minHeight: 40,
      borderColor: isFocused ? "#3399FF" : "#4872f2",
      boxShadow : isFocused ? "0 0 0 3px #B6DAFF" : "none",
      borderRadius: 4,
      cursor: "pointer",
      ':hover': {borderColor: "#4872f2"},
    }
  },
  menu: (provided, state) => ({ ...provided, zIndex: 100 })
}

const StyledSelect = styled(Select)`
  font-size: 14px;
  line-height: 18px;
  max-width: 288px;

  color: #3c3c3c;
`

const Flex = styled.div`
  padding: 8px 14px;
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 10px;
      & > path {
          stroke: ${({ variant }) => (variant === 'outlined' ? '#4872F2' : null)};
      }
  }
  & > span {
    color: ${({ variant }) => (variant === 'outlined' ? '#4872F2' : null)};
  }
`

// const DropdownIndicator = (props: any) => (
//   <components.DropdownIndicator {...props}>
//     <ThreeDots />
//   </components.DropdownIndicator>
// );

export default function DocumentSelector({variant}) {
  const state = useSelector(getDetailOrder)
  const dispatch = useDispatch()

  if (state.documents.length === 0) {
    return null
  }

  const options = state.documents.map((x, key) => ({
    action: x.type,
    value: key,
    label: x.title
  }))

  const Placeholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <Flex variant={variant}>
          <DownloadIcon />
          <Text color="#FFFFFF">Документы по заявке</Text>
        </Flex>
      </components.Placeholder>
    )
  }

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <Flex variant={variant}>
          <DownloadIcon />
          <Text color="#FFFFFF">Документы по заявке</Text>
        </Flex>
      </components.SingleValue>
    )
  }

  const selectStyles = (variant) => {
    switch (variant) {
      case 'primary':
        return selectStylesPrimary;
      case 'outlined':
        return selectStylesOutlined;
      default:
        return selectStylesPrimary;
    }
  };

  const handleChange = data => {
    const printLink = Api.get(process.env.REACT_APP_PRINT_ORDER).query({ orderId: state.id, orderPositionId: state.documents[data.value].orderPositionId, format: "pdf" }).toString()
    const printCheckLink = Api.get(process.env.REACT_APP_PRINT_CHECK).query({ orderId: state.id, format: "1" }).toString()
    if (state.documents[data.value].url !== null) {
      window.open(state.documents[data.value].url, "_blank")
    } else if (data.action === "AviaTickets") {
      window.open(printLink, "_blank")
    } else if (data.action === "PaymentReceipt") {
      window.open(
        printCheckLink,
        "_blank"
      )
    } else if (data.action === "AgentBill") {
      dispatch(getAgentOrderBillRequest({ orderId: state.id }))
    } else if (data.action === "All") {
      state.documents.forEach((x, i) => {
        let path = ""

        if (x.url) {
          path = x.url
        } else if (x.type === "AviaTickets") {
          path = printLink;
        } else if (x.type === "PaymentReceipt") {
          path = printCheckLink
        }

        const temporaryDownloadLink = document.createElement("a")
        temporaryDownloadLink.style.display = "none"
        document.body.appendChild(temporaryDownloadLink)
        temporaryDownloadLink.setAttribute("href", path)
        temporaryDownloadLink.setAttribute("download", x.title)
        setTimeout(() => {
          temporaryDownloadLink.click()
        }, 400 * i)
        // window.open(path, '_blank' + i);
      })
    }
  }
  return (
    <StyledSelect
      components={{ DropdownIndicator: null, Placeholder, SingleValue }}
      styles={selectStyles(variant)}
      onChange={handleChange}
      options={[
        ...options
        // { action: 'All', value: 0, label: 'Скачать все документы' },
      ]}
      placeholder="Документы по заявке"
      isSearchable={false}
    />
  )
}
