import React from 'react';
import useBookingStore from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import useLoyaltyStore from "../../../Loyalty/store";

const BookingSuccessPopup = ({ claim }) => {

	const actions = useBookingStore(({ actions }) => actions)
	const response = useBookingStore(({ response }) => response)
	const preorder = useLoyaltyStore(({ preorder }) => preorder)
	const currencys = useBookingStore(({ currencys }) => currencys)

	const navigate = useNavigate()

	const backToSearch = () => {
		actions.backToSearch()
		actions.clearError()
		navigate('/search_tour')
	}

	const showOrder = () => {
		const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
		const franch = agentInfo?.agents?.name.includes('F&S') || agentInfo?.partPass === '91554' || agentInfo?.partPass === '121374'
		if (franch) {
			// navigate(`/cabinet/claims?claimId=${claim}`)
			window.location.href = window.location.origin + `/cabinet/claims?claimId=${claim}`
		} else {
			window.location.href = `https://b2b.fstravel.com/cl_refer?CLAIM=${claim}`
		}
	}

	const { search } = useLocation();

	const getRates = () => {
		const query = new URLSearchParams(search)
		let currency = {}
		Object.values(currencys).forEach(item => {
			currency = item.find(value => value.currencyKey === Number(query.get('currencyId')))
		})
		if (currency) {
			return currency.rate
		}
	}
	const bonusPointsSpentForCurrentOrder = Math.round(preorder?.order?.totalBonusPointsInfo?.spentAmountForCurrentOrder) || 0

	const price = preorder?.order?.totalPrice ? preorder?.order?.totalPrice : response?.price?.amount ?? 0

	return (
		<div className="popup-bg">
			<div className="popup-block">
				<h2 className="popup-name">
					Ваша заявка забронирована
				</h2>
				<p className="popup-text">Номер вашей заявки: <span style={{ fontWeight: 'bold' }}>{claim}</span></p>
				<p className="popup-text">Цена составляет: <span style={{ fontWeight: 'bold' }}>{price} {response.price.currencyAlias}</span></p>
				<div style={{ display: 'flex' }}>
					<button className="popup-btn" onClick={showOrder}>Посмотреть заявку</button>
					<button className="popup-btn close" onClick={backToSearch}>Еще одна заявка</button>
				</div>
			</div>
		</div>
	);
};

export default BookingSuccessPopup;