import { useState } from "react";
import ArrowDownSmall from "../../../../asset/icons/ArrowDownSmall";

import "./style.scss";

function MenuTab({ content, changeContent }) {
    const [activeTab, setTab] = useState(null);

    const handleChangeTab = (value, index) => {
        if (activeTab === index) {
            setTab(null);
            changeContent(null);
            return;
        } else {
            setTab(index);
            changeContent(value);
        }
    };

    return (
        <div className="menu">
            {content.map((item, index) => (
                <MenuItem
                    item={item}
                    index={index + 1}
                    active={activeTab === index + 1}
                    onChange={handleChangeTab}
                />
            ))}
        </div>
    );
}

const MenuItem = ({ item, active, index, onChange }) => {
    const [open, setOpen] = useState(false);

    return (
        <div key={item.name} className="menu-element">
            <div
                className={active ? " item active" : "item"}
                onClick={() => {
                    onChange(item.content, index);
                    setOpen(!open);
                }}
            >
                <p className="item-name">{item.name}</p>
                <div className={active ? "rotate-0 rotate" : "rotate"}>
                    <ArrowDownSmall />
                </div>
            </div>
            {open && (
                <div className="mobile-view">
                    <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                </div>
            )}
        </div>
    );
};

export default MenuTab;
