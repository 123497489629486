import React, {useRef} from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    PriceBlock,
    OrderPositions,
    Contacts,
    Head,
    Insurances,
    Notification,
    AddictionalServices,
    Calculations,
    DocumentSelector,
    PromoCode,
    PromoCodeApplied,
} from "../../components/AviaOrder"
import {
    getDetailOrder,
    getAddictionalServices,
    AddictionalServiceTypes,
    issueTicketsRequest,
    payOrderRequest,
    getAgentOrderBillRequest
} from "../../store/modules/orders"

import {
    Text,
    Checkbox,
    LowcosterNotification,
    CharterNotification,
    RegularNotification,
    OnlyRusCardNoty,
} from "../../components/ui"
import {CharterTariffsModal} from '../../components/modals'
import clock from "../../asset/images/avia/order-detail/clock.svg"
import okIcon from "../../asset/images/avia/order-detail/okIcon.svg"
import DeclineSuccessModal from "../../components/modals/calculations/DeclineSuccessModal"
import ApplySuccessModal from "../../components/modals/calculations/ApplySuccessModal"
import CalculationFailureModal from "../../components/modals/calculations/CalculationFailureModal"
import {getCalculationModalsState,hideCalculationModal,MODALS} from '../../store/modules/appeals'
import { AgentPayModal } from "../../components/modals"
import PaymentBlockAdvanced from "../../components/AviaOrder/PaymentBlockAdvanced";

const TextRow = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px !important;

  @media (max-width: 768px) {
    margin-top: 14px;
  }
`

const Agreement = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-left: 8px;
`

const AgreementBlue = styled.a`
  color: rgba(72, 114, 242, 1);
  text-decoration: none;
  cursor: pointer;
`

const IssueBlock = styled.div`
  background: ${({ isActive }) => (isActive ? "#EBFFE4" : "#f7f7f7")};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
`

const BlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
`

const ClockImage = styled.img.attrs({ src: clock })`
  margin-right: 5px;
`

const OkImage = styled.img.attrs({ src: okIcon })`
  margin-right: 5px;
  width: 20px;
  color: #21a038;
`

const FlexRowSB = styled.div`
  display: flex;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  justify-content: space-between;
`;

const OrderDetail = () => {
  const resultRef = useRef(null);
  const [popup, setPopup] = React.useState(false)
  const dispatch = useDispatch()
  const {orderNumber} = useParams();
  const {
    contactsInfo,
    availableActions,
    insurances,
    notifications,
    amount,
    customer,
    additionalServices,
    isLowcoster,
    availableShipmentAllowed,
    orderPositions,
    id,
    orderAmount,
    salesChannel,
  } = useSelector(getDetailOrder)
  const {visibility} = useSelector(getCalculationModalsState)
  const availableInsuransesState = useSelector(x => x.orders.orderDetail.availableInsurances)
  const agentDoPaymentProps = useSelector(x => x.orders.orderDetail.data.availableCreditPaymentMethods)
  const user = useSelector(x => x.user)
  const [agentModal, setAgentModal] = React.useState(false)

  let isCharter = false
  if (orderPositions.length && orderPositions[0].flight_Type === "Charter") {
    isCharter = true
  }

  const smsService = additionalServices.find(x => x.type === AddictionalServiceTypes.SMS_Service)

  const isPromocodeApplied = orderAmount.detalization.some(x => x.priceType === 20);

  const extraPayment = useSelector(x => x.orders.orderDetail.activeCalulations.totalAmount) > 0
  const isOwnRetail = (availableActions || []).includes("PAYMENT") && !extraPayment && salesChannel === "Собственная розница";

  const addictionalServices = useSelector(getAddictionalServices)
  const positionsLength = useSelector(state => state.orders.orderDetail.data.orderPositions.length)

  // eslint-disable-next-line no-unused-vars
  const notification = (notifications || []).filter(x => x.type !== "ApprovedCalculation" && x.type !== "NotApprovedCalculation")

  const handlePay = e => {
    setAgentModal(true)
  }

  return (<>
        {notification.length > 0 &&
            notification.map((x, key) => (
                <Notification
                    key={key}
                    notification={x}
                    orderNumber={parseInt(orderNumber)}
                />
        ))}

        {/*Промокод и загрузка документов*/}
          <FlexRowSB>
            {availableActions.includes('PAYMENT') && <PromoCode />}
            <DocumentSelector />
          </FlexRowSB>

        {availableShipmentAllowed?.availableIssue && (
            <IssueBlock isActive={availableShipmentAllowed?.ticketIssue === true}>
                {availableShipmentAllowed?.ticketIssue === false ? (
                    <>
                    {" "}
                    <ClockImage />{" "}
                    <BlockText>
                        Дождитесь завершения обработки заявки для выписки.
                    </BlockText>
                    </>
                ) : (
                    <>
                    <OkImage />{" "}
                    <BlockText>
                        Обработка заявки завершена. Выписка заявки доступна.
                    </BlockText>
                    </>
                )}
            </IssueBlock>
        )}

        {positionsLength > 0 && <Head resultRef={resultRef}/>}
        {/* Notifications*/}
        {(isCharter && isLowcoster) && <CharterNotification/>}
        {(isLowcoster && !isCharter) && <LowcosterNotification/>}
        {(!isCharter && !isLowcoster) && <RegularNotification/>}

        <Calculations />
        <OrderPositions />
        {(insurances.length > 0 ||
            (availableInsuransesState &&
            availableInsuransesState.items.length > 0)) && (
            <Insurances items={insurances} />
        )}
        <AddictionalServices
            items={addictionalServices.items}
            showExtraServices={addictionalServices.canShowExtraServices}
        />
        <Contacts
            {...contactsInfo}
            sms={smsService ? smsService.additionalContactInfo : null}
        />
        {positionsLength > 0 && <PriceBlock amount={amount} />}

        {!isOwnRetail && (availableActions.includes("PAYMENT") || availableActions.includes("EXTRAPAYMENT")) && (
          <PaymentBlockAdvanced ref={resultRef}/>
        )}

        {isPromocodeApplied && <PromoCodeApplied />}

        <OnlyRusCardNoty />

        <TextRow>
            <Checkbox disabled type="squar" checked />
            <Agreement>
            На этапе бронирования заявки Вы ознакомились и согласились с{" "}
            <AgreementBlue href="/docs/oferta.pdf" target="_blank">
                договором публичной оферты, условиями применения тарифов и
                политикой в отношении обработки персональных данных.
            </AgreementBlue>{" "}
            </Agreement>
        </TextRow>
        {isCharter && (
            <TextRow>
            <Checkbox disabled type="squar" checked />
            <Agreement>
                Я согласен(а) с{" "}
                <AgreementBlue
                onClick={() => {
                    setPopup(true)
                }}
                >
                условиями применения тарифов перевозки
                </AgreementBlue>
                {"."} Я предупреждён, что в исключительных случаях
              возможна замена перевозчика, типа воздушного судна на
              аналогичные услуги без взимания какой-либо доплаты.
            </Agreement>
            </TextRow>
        )}

        <TextRow>
            <Checkbox
            type="squar"
            checked={customer?.agreementToMailing || false}
            />
            <Agreement>
            Я согласен(а) на получение email-рассылки.
            </Agreement>
        </TextRow>

        <CharterTariffsModal
            open={popup}
            onClose={() => setPopup(false)}
        />
        <DeclineSuccessModal
          open={visibility[MODALS.DECLINE_SUCCESS]}
          onClose={() => dispatch(hideCalculationModal(MODALS.DECLINE_SUCCESS))}
          closeButtonText='Вернуться к заявке'
        />
        <ApplySuccessModal
          open={visibility[MODALS.CONFIRM_SUCCESS]}
          onClose={() => dispatch(hideCalculationModal(MODALS.CONFIRM_SUCCESS))}
          onPay={()=> {
            handlePay();
            dispatch(hideCalculationModal(MODALS.CONFIRM_SUCCESS))
          }}
          closeButtonText='Вернуться к заявке'
        />
        <CalculationFailureModal
          open={visibility[MODALS.FAILURE]}
          onClose={() => dispatch(hideCalculationModal(MODALS.FAILURE))}
          closeButtonText='Вернуться к заявке'
        />
        <AgentPayModal
          open={agentModal}
          {...agentDoPaymentProps}
          onClose={() => setAgentModal(false)}
          setAgentModal={setAgentModal}
          onPay={() => {
            setAgentModal(false)
            dispatch(payOrderRequest({OrderNumber: Number(orderNumber),}))
          }}
          onExtract={byCredit => {
            dispatch(
              issueTicketsRequest({
                orderId: id,
                typePaid: byCredit ? "credit" : "bill",
                userId: user?.data?.agentId
              })
            )
            setAgentModal(false)
          }}
          onCreateInvoice={() => {
            setAgentModal(false)
            dispatch(getAgentOrderBillRequest({ orderId: id }))
          }}
          onPayByCredit={() => {
            dispatch(
              issueTicketsRequest({
                orderId: id,
                typePaid: "credit",
                userId: user?.data?.agentId,
              })
            )
            setAgentModal(false)
          }}
        />
  </>)
}

export default OrderDetail
