import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import React from 'react'
import useBookingStore from '../../../store'
import Baggage from '../Baggage'
import { getClassName } from '../helper'

export default function TransferFlight({ flight }) {
	const paxes = useBookingStore(({ paxes }) => paxes)

	return (
		<div className='block-body'>
			<div className='grid-avia header'>
				<div>Рейс</div>
				<div>Дата</div>
				<div>Кол-во</div>
			</div>
			{flight.forwardFlight.segments.map(item => {
				return (
					<>
						<div className='grid-avia body'>
							<div className='select-flight'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-outlined-label'>
										{'Туда'}
									</InputLabel>
									<Select
										labelId='demo-simple-select-outlined-label'
										value={item.flightNumber}
										label={'Туда'}
										className='custom-select'>
										<MenuItem key={item} value={item.flightNumber.toString()}>
											[{getClassName(item?.class?.id)}] (Места: есть) {item.flightNumber} (
											{item.startWayPoint.city.name}{' '}
											{item.startWayPoint.port.alias}{' '}
											{item.startTime.slice(0, -3)} -{' '}
											{item.endWayPoint.city.name} {item.endWayPoint.port.alias}{' '}
											{item.endTime.slice(0, -3)})
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='flight-date'>
								{format(parseISO(item.startDateTime), 'dd.MM.yyyy')}
							</div>
							<div className='flight-paxes'>{paxes.length}</div>
						</div>
						<div className='baggage-info'>
							<Baggage baggage={item} />
						</div>
					</>
				)
			})}
			{flight.backwardFlight.segments.map(item => {
				return (
					<>
						<div className='grid-avia body'>
							<div className='select-flight'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-outlined-label'>
										{'Обратно'}
									</InputLabel>
									<Select
										labelId='demo-simple-select-outlined-label'
										value={item.flightNumber}
										label={'Обратно'}
										className='custom-select'>
										<MenuItem key={item} value={item.flightNumber.toString()}>
											[{getClassName(item?.class?.id)}] (Места: есть) {item.flightNumber} (
											{item.startWayPoint.city.name}{' '}
											{item.startWayPoint.port.alias}{' '}
											{item.startTime.slice(0, -3)} -{' '}
											{item.endWayPoint.city.name} {item.endWayPoint.port.alias}{' '}
											{item.endTime.slice(0, -3)})
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='flight-date'>
								{format(parseISO(item.startDateTime), 'dd.MM.yyyy')}
							</div>
							<div className='flight-paxes'>{paxes.length}</div>
						</div>
						<div className='baggage-info'>
							<Baggage baggage={item} />
						</div>
					</>
				)
			})}
		</div>
	)
}
