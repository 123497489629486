import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Autoplay } from 'swiper/modules';
import PhotoTilesCardSlider from './PhotoTilesCardSlider';
import { isMobile } from "react-device-detect";
import { useMediaQuery } from 'react-responsive';
import PhotoTilesCardNewSlider from './PhotoTilesCardNewSlider'
import "./style.scss";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

function PhotoTilesSlider({ slider, title }) {
    const isMobileScreen = useMediaQuery({ maxWidth: 768 });
    const autoplay = slider?.boolAutoList ? { delay: 0 } : false
    const handleClick = (link) => {
        window.open(link, "_blank");
    };

    if (slider.typeCards) {
        return <PhotoTilesCardSlider slider={slider} />
    }

    if (slider.typeSliderTiles) {
        return <PhotoTilesCardNewSlider slider={slider} />
    }

    return (
        <div className='photo-tiles_slider-container'>
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                mousewheel={true}
                speed={slider.speed ?? 1500}
                watchSlidesProgress={true}
                autoplay={autoplay}
                loop={true}
                modules={[Autoplay, FreeMode, Navigation]}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                className="photo-tiles_slider"
                watchOverflow={true}
            >
                {slider.photos.map(item => <SwiperSlide key={item.uid} >
                    <img key={item.preview} onClick={() => handleClick(item.link)} className='photo-tiles_slide' src={isMobileScreen ? item.mobile : item.preview} alt='' />
                </SwiperSlide>)}
            </Swiper>
            {!isMobile && <>
                <div className='swiper-button-prev slide-btn-bg' />
                <div className='swiper-button-next slide-btn-bg' />
            </>}
        </div>
    )
}

export default PhotoTilesSlider