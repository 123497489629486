import React from "react"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useNavigate, useHref} from 'react-router-dom'
import { styled } from '@mui/material/styles';
import {NavLink} from 'react-router-dom'


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#4872F2',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    padding: 0,
    marginRight: 30,
    color: '#737373',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    '&.Mui-selected': {
      color: '#4872F2',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);


function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  const navigate = useNavigate();
  return (
    <StyledTab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        navigate(props.href);
      }}
      {...props}
    />
  );
}


const Navigation = ({ items, ...props }) => {
  const href = useHref();
  const [value, setValue] = React.useState(href);

  const subroutes = React.useMemo(() => {
    const result = new Map();
    items.forEach(x => {
      if(x.subroutes) {
        x.subroutes.forEach(y => {
          result.set(y,x.path);
        })
      }
    })
    return result;
  },[items]);

  React.useEffect(() => {
    let value = href;
    if(subroutes.size > 0) {
      for (const [key,x] of subroutes) {
        if(href.startsWith(key)) {
          value = x;
          break;
        }
      }
    }
    setValue(value);
  },[href,subroutes])


  return (
    <Box sx={{ width: '100%',borderBottom: 1, borderColor: 'divider'}} className={props.className}>
      <StyledTabs
        value={value}
        aria-label="navigation"
      >
        {items.map((item, key) => (<LinkTab label={item.name} value={item.path} href={item.path} key={key}/>))}
      </StyledTabs>
  </Box>
  )
}

export default Navigation
