import React from 'react';
import {SvgIcon} from "@mui/material";

const ArrowThink = (props) => {
	return (
		<SvgIcon {...props}>
			<path d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V19.5C12.75 19.6989 12.671 19.8897 12.5303 20.0303C12.3897 20.171 12.1989 20.25 12 20.25C11.8011 20.25 11.6103 20.171 11.4697 20.0303C11.329 19.8897 11.25 19.6989 11.25 19.5V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"/>
			<path d="M11.469 3.969C11.5386 3.89915 11.6214 3.84374 11.7125 3.80593C11.8036 3.76812 11.9013 3.74866 12 3.74866C12.0986 3.74866 12.1963 3.76812 12.2874 3.80593C12.3785 3.84374 12.4613 3.89915 12.531 3.969L17.031 8.469C17.1718 8.60983 17.2509 8.80083 17.2509 9C17.2509 9.19916 17.1718 9.39017 17.031 9.531C16.8901 9.67183 16.6991 9.75095 16.5 9.75095C16.3008 9.75095 16.1098 9.67183 15.969 9.531L12 5.5605L8.03097 9.531C7.89014 9.67183 7.69913 9.75095 7.49997 9.75095C7.30081 9.75095 7.1098 9.67183 6.96897 9.531C6.82814 9.39017 6.74902 9.19916 6.74902 9C6.74902 8.80083 6.82814 8.60983 6.96897 8.469L11.469 3.969Z" />
		</SvgIcon>
	);
};

export default ArrowThink;