import React, { useState, useEffect } from "react";
import useBookingStore from "../../store";
import { Button } from "@material-ui/core";
import "./style.css";
import ServiceModal from "./ServiceModal";
import ServiceLine from "./ServiceLine";
import { isEmpty } from "lodash";
import { v4 as uuid } from 'uuid';

const ServicesBlock = ({ elements, title, isExcursions = false }) => {

	const peoples = useBookingStore(({ peoples }) => peoples)
	const servicesGroups = useBookingStore(({ servicesGroups }) => servicesGroups)
	const selectedServices = useBookingStore(({ selectedServices }) => selectedServices)
	const actions = useBookingStore(({ actions }) => actions);

	const [modalVisible, setModalVisible] = useState(false);
	const [includeBaby, setIncludeBaby] = useState(peoples.some(people => people.age === 3))

	const services = isExcursions ?
		elements.filter(service => service?.serviceCategoryName?.includes('Экскурсия'))
		: elements.filter(service => !service?.serviceCategoryName?.includes('Экскурсия') && servicesGroups.some(group => group.id === service.groupId))
	const selectedExcursion = selectedServices.filter(service => service?.serviceCategoryName?.includes('Экскурсия'))
	const showModal = () => {
		setModalVisible(!modalVisible);
	};


	useEffect(() => {
		setIncludeBaby(peoples.some(people => people.age === 3))
	}, [peoples])

	if (isEmpty(services)) { return null }

	const renderButton = (id) => {
		const isIncluded = selectedServices.some(item => item.uid === id)
		if (isIncluded) {
			return (
				<Button
					onClick={() => actions.removeServices(id)}
					variant="contained"
					color="secondary"
					fullWidth
				>
					Удалить
				</Button>
			);
		} else {
			return (
				<Button
					onClick={() => actions.addServices(id)}
					variant="contained"
					color="primary"
					fullWidth
				>
					Добавить
				</Button>
			);
		}
	};

	const renderContent = (content) => {
		const isSelected = content.some(i => selectedExcursion.some(el => el.uid === i.uid))
		if (isSelected) {
			return content.map(item => {
				const isIncluded = item?.clients?.length !== 0 || item.required;
				if (!isIncluded) {
					return null
				}

				const paxesLength = item.required ? peoples.length : item.clients.reduce((accumulator, people) => {
					if (includeBaby && people.age === 3) {
						return accumulator
					}
					return accumulator + 1;
				}, 0);

				return <ServiceLine key={uuid()} item={item} paxesCount={paxesLength} babyCount={includeBaby} selected={isSelected} button={renderButton} />
			})
		} else {
			const firstItem = content.sort(
				(a, b) => new Date(a.datebeg) - new Date(b.datebeg)
			)[0];
			if (includeBaby) {
				return <ServiceLine item={firstItem} paxesCount={peoples.length - 1} babyCount={includeBaby} button={renderButton} />
			}

			return <ServiceLine item={firstItem} paxesCount={peoples.length} button={renderButton} />
		}

	};

	const servicesSort = (a) => {
		if (a.type.id === 21 || a.type.id === 12) {
			return 1
		}
		return 0
	}


	return (
		<>
			<div className="block-header">
				<h3 className="title">{title}</h3>
			</div>
			<div className="block-body">
				<div className="grid-insurances header">
					<div>Название</div>
					<div>Даты</div>
					<div></div>
					<div className="paxes">Количество</div>
					<div></div>
				</div>
				{isExcursions
					? renderContent(services, includeBaby)
					: services
						.sort(servicesSort)
						.map((item) => {
							if (!selectedServices.find(service => service.uid === item.uid) && !item.name.includes('Индивидуальный трансфер:')) {
								return null
							}
							let peoplesLength = item.personal ? item.clients.length : peoples.length
							if (includeBaby) {
								peoplesLength = peoplesLength - 1
							}

							return <ServiceLine key={uuid()} item={item} paxesCount={peoplesLength} babyCount={includeBaby} selected={item.required || selectedServices.find(service => service.uid === item.uid)} button={renderButton} />
						})
				}
				<div className="button-change">
					{" "}
					<Button onClick={showModal} variant="contained" color="primary">
						Изменить услуги
					</Button>
				</div>
			</div>
			{modalVisible && (
				<ServiceModal
					grouped={isExcursions}
					services={[...services]}
					onClose={showModal}
					title={title}
				/>
			)}
		</>
	);
};

export default ServicesBlock;
