import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #3c3c3c;
  height: 60px;
  box-sizing: border-box;
  width: 256px;
  position: relative;
  font-size: 16px;
  line-height: 20px;

  ${({ active }) => (active ? `z-index: 2;` : '')}
`;

const StyledInput = styled.input`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  border: none;
  outline: 0;
  padding: 31px 15px 9px 12px;
  box-sizing: border-box;
  text-transform: capitalize;
  outline: none;
  width: 100%;
  box-shadow: 0 0 0 2px #D4D9DE;
  transition: 0.2s;

  &:focus {
    box-shadow: 0 0 0 2px #ff6d00;
    z-index: 2;
  }
`;

const Label = styled.label`
  position: absolute;
  transform: translate(12px, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  color: #b9b9b9;
  ${({ active }) =>
    active ? 'transform: translate(12px, 9px) scale(.75);' : ''}
`;

const Code = styled.span`
  color: #b9b9b9;
  text-transform: uppercase;
  position: absolute;
  top: 31px;
  right: 12px;
  pointer-events: none;
`;

const Field = (props) => {
  return (
    <Container className={props.className} active={props.active}>
      <Label active={props.active || Boolean(props.value)}>{props.label}</Label>
      <StyledInput {...props} ref={props.inputRef} />
      {props.code && <Code>{props.code}</Code>}
    </Container>
  );
};

export default Field;
