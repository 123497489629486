import { TOP_COUNTRIES } from './data'
import CountryItem from './CountryItem'
import useCountryStore from '../../store'

import './style.scss'

function AllCountry({ country }) {
    const countriesList = useCountryStore(({ countriesList }) => countriesList)
    console.log(countriesList)

    return <div className='other-country'>
        <h2>Другие страны</h2>
        <div className='country-grid'>
            {country.filter(i => !TOP_COUNTRIES.includes(i.name)).sort((a, b) => a.name.localeCompare(b.name, 'ru')).map(item => {
                const existCountry = countriesList.find(i => i.ebgNodeId === item.id)
                if (!existCountry) return null

                return <CountryItem key={existCountry.id} content={existCountry} />
            })}
        </div>
    </div>
}
export default AllCountry