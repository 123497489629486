import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import './style.css'
import {ListSubheader} from "@mui/material";

const VisaSelect = ({options, title, onChange, value, enabledValue, count}) => {

	return (
		<FormControl variant="outlined" style={{width: '100%'}}>
			<InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
			<Select
				labelId="demo-simple-select-outlined-label"
				value={value}
				onChange={(e) => onChange(e.target.value, count)}
				label={title}
				className="custom-select"
			>
				<MenuItem key={-1} value={-1}>Своя виза</MenuItem>
				{options.filter(filter => filter.id !== -1).length &&
					<ListSubheader sx={{fontSize: 16, color: '#000', fontWeight: 600, lineHeight: '32px'}}>
						Город подачи: Москва
					</ListSubheader>
				}
				{options.filter(filter => filter.id !== -1).map((item) =>
					<MenuItem
						className={`${enabledValue && enabledValue.includes(item) ? 'place-enabled' : ''}`}
						key={item.id || item}
						value={item.id || item}
						style={{paddingLeft: 30}}
					>
						{item.name || item}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};


export default VisaSelect;
