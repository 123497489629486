import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const useCommissionStore = create(
	devtools((set, get) => ({
		commission: null,
		activeSort: null,
		actions: {	
			getCommission: async () => {
				const token = JSON.parse(localStorage.getItem('token'))?.access_token
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				try {
					const res = await axios({
						url: `${BASE_URL}/payments/commission`,
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-TUI-ClientId': 'b2c:ru'
						},
						params: {
							PartpassId: partpass,
						}
					})
					set({ commission: res.data })
				} catch (e) {
					console.error('error in getCommission', e?.response?.data)
				}
			},

			setActiveSort: (value) => {
				if (value !== get().activeSort) {
					set({activeSort: value})
					switch (value) {
						case "сity":
							set({
								commission: [...get().commission].sort((a, b) => (a?.toState?.title.localeCompare(b?.toState?.title)))
							})
						  return  
						case "commmission":
							set({
								commission: [...get().commission].sort((a, b) => (a?.commission - b?.commission))
							})
							return
						case "сity-decrease":
							set({
								commission: [...get().commission].sort((a, b) => (b?.toState?.title.localeCompare(a?.toState?.title)))
							})
							return  
						case "commmission-decrease":
							set({
								commission: [...get().commission].sort((a, b) => (b?.commission - a?.commission))
							})
							return
						default:
						  return  
					  }	
				}
			},
			// получениес id стран
			// getCountriesSamo: async () => {
			// 	try {
			// 		const res = await axios({
			// 			url: `${BASE_URL}/Filters/GetArrivalCountries`,
			// 			method: 'GET',
			// 			params: {
			// 				DepartureCityId: 1,
			// 				LoadCities: true
			// 			}
			// 		})
			// 		set({ countriesSamo: res.data })
		
			// 	} catch (e) { console.error('error in getCountriesSamo', e) }
			// },
			
	 
		}
	}))
)

export default useCommissionStore
