import React from "react"
import styled from "styled-components"
import { DatePicker } from "../../ui"

const Row = styled.div`
  display: flex;
  margin-top: 39px;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: -10px;
  }
`

const StyledPicker = styled(DatePicker)`
  width: 256px;
  height: 40px;
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin-right: 0;
    width: 100%;
    margin-bottom: 40px;
  }
`

const SalesDateRow = props => {
  const { setFieldValue, values } = props

  return (
    <Row>
      <StyledPicker
        value={values.saleStart}
        onChange={date => {
          setFieldValue("saleStart", date)
        }}
        label="Начало продажи"
      />
      <StyledPicker
        value={values.saleEnd}
        onChange={date => {
          setFieldValue("saleEnd", date)
        }}
        label="Конец продажи"
      />
    </Row>
  )
}

export default SalesDateRow
