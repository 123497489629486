import './style.scss';

function GenerationListsTables({ content, title }) {
    const { markerList, generatingTable, tableHeader } = content;
    const display = markerList?.options?.display;
    const items = markerList?.items;
    const isVertically = display === 'vertically'

    return (
        <div className={`generation-lists-tables_container${isVertically ? ' vertically' : '' }`}>
            {title && <h3 className='title'>{title}</h3>}
            {generatingTable && (
                <table className="generation_table">
                    <thead
                        style={tableHeader ? { background: '#EDF1FE' } : null}
                    >
                        <tr>
                            {generatingTable[0].map((header, index) => (
                                <th
                                    key={header + index}
                                    dangerouslySetInnerHTML={{ __html: header }}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {generatingTable.slice(1).map((row, rowIndex) => (
                            <tr key={row + rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        dangerouslySetInnerHTML={{
                                            __html: cell,
                                        }}
                                    />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {items?.map((item, index) => (
                <div
                    key={item?.title + index}
                    className={`generation-lists-tables_card${!isVertically ? ' horizontally' : '' }`}
                >
                    <div className="card-img">
                        <img src={item?.img} alt="" />
                    </div>
                    <div className="card-body">
                        <div className="card-title">{item?.title}</div>
                        {item?.text && (
                            <div
                                className="card-text"
                                dangerouslySetInnerHTML={{ __html: item?.text }}
                            ></div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default GenerationListsTables