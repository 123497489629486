import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import { ReactComponent as ClockIcon } from "./assets/media/clock.svg"
import formatDuration from "../../../functions/formatDuration"
import dateToLocal from "../../../functions/dateToLocal"
import moment from "moment"

const DesktopText = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 17px;
  margin-bottom: 15px;
  & > svg {
    margin-right: 7px;
  }
  & > strong {
    margin-left: 7px;
    font-weight: 600;
    color: #4872f2;
  }
`

export default function ExpirationTime(props) {
  const duration = formatDuration(
    dateToLocal(props.value).diff(moment(), "minutes")
  )

  return (
    <StyledText>
      <ClockIcon />{" "}
      <span>
        Время для оплаты <DesktopText> истечет через</DesktopText>
      </span>{" "}
      <strong>{duration}</strong>.
    </StyledText>
  )
}
