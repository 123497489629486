import React from 'react'
import './style.css'
import CountryInput from './CountryInput'
import useGeoStore from '../../store'
import { useMediaQuery } from '@material-ui/core'
import HideOnScrollBar from './HideOnScrollBar'
import {
	Tabs ,
	Tab,
	Box,
} from '@mui/material'

export default function MainBannerLanding(props) {
	const banner = useGeoStore(
		({ content }) =>
			JSON.parse(content?.main_banner?.content).mainBanner.landingBanner
	)
	const { tour_search_link } = useGeoStore(({ content }) => content)
	const isMobile = useMediaQuery('(max-width:480px)')
	const isLessThan768 = useMediaQuery('(max-width:768px)')

	const handleClickScroll = id => {
		const element = document.getElementById(id)
		if (element) {
			const scrollPosition = element.getBoundingClientRect().top - 84; // Расстояние от верха страницы до элемента минус нав.панель
			window.scrollBy({
				top: scrollPosition,
				behavior: 'smooth'
			});
		}
	}

	return (
		<>
			<HideOnScrollBar sections={banner?.sections} handleClickScroll={handleClickScroll}/>
			<div
				className='MainBannerLanding-banner-img'
				style={{
					background: `linear-gradient(103.18deg, rgba(25, 17, 54, 0.2) 0%, rgba(28, 22, 51, 0) 75.26%), url(${
						isMobile ? banner?.image.mobile : banner?.image.desktop
					}) rgb(237, 241, 254)`
				}}>
				{banner?.sections && !isLessThan768  && (
					<div className='MainBannerLanding-banner-sticky__container'>
						<div className='MainBannerLanding-banner-menu'>
							{banner.sections.map(item => (
								<span
									key={item.blockId}
									onClick={() => handleClickScroll(item.blockId)}>
									{item.name}
								</span>
							))}
						</div>
					</div>
				)}
				{banner?.sections && isLessThan768 && (
					<Box
						style={{ padding: !isMobile ? '0 64px' : '0 0px', borderRadius: !isMobile ? '0 0 16px 16px' : '0 0 8px 8px',  }}
						>
						<Tabs
							className='app-bar__tabs white'
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
							value={false}
						>
							{banner.sections?.map(item => (
								<Tab 
									key={item.blockId}
									label={item.name}
									onClick={() => handleClickScroll(item.blockId)}
									className='app-bar__item white'
									>
								</Tab>
							))}
						</Tabs>
					</Box>
				)}
				<div className='MainBannerLanding-banner-container'>
					<div
						className='MainBannerLanding-banner-text'
						style={{ color: banner.textColor }}>
						{banner?.text}
					</div>
					<div
						className='MainBannerLanding-banner-head'
						style={{ color: banner.titleColor }}>
						{banner.title}
					</div>
					{tour_search_link && (
						<div className='MainBannerLanding-banner-where-field-block'>
							<div className='MainBannerLanding-banner__city-text'>
								Город вылета
							</div>
							<CountryInput />
						</div>
					)}
				</div>
			</div>
		</>
	)
}
