import React, {useEffect, useRef, useState} from 'react';
import {TextField} from "@material-ui/core";
import Minus from '../../../../../../asset/icons/minus-circle.svg'
import Plus from '../../../../../../asset/icons/plus-circle.svg'
import './style.scss'
import useTransferStore from "../../../store";

const TouristsField = ({adults, children, title, ...props}) => {
	const [showBody, setShowBody] = useState(false)
	const field = useRef(null)

	const actions = useTransferStore(({ actions }) => actions)

	useEffect(() => {
		function handleClick(event) {
			if (field.current && !field.current.contains(event.target)) {
				setShowBody(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [field]);

	const toggle = () => {
		setShowBody(!showBody)
	}

	return (
		<div className="v-departure-base" ref={field}>
			<div className="v-departure">
				<div onClick={toggle}>
					<div>
						<TextField
							id={'tourist'}
							label={title}
							variant="outlined"
							fullWidth
							autoComplete={'off'}
							value={`${adults} - ${children}`}
							{...props}
						/>
					</div>
				</div>
				{showBody && <div className="v-departure__body-container tourists">
					<div className="tourists__body">
						<div className={'adults'}>
							<div>Взрослые</div>
							<div className={'button-block'}>
								<span
									className={`icon-button ${adults > 1 && 'active'}`}
									style={{WebkitMask: `url(${Minus}) no-repeat`}}
									onClick={adults > 1 ? actions.adultDec : () => {} }
								/>
								<div>{adults}</div>
								<span
									className={`icon-button ${(adults + children) < 19 && 'active'}`}
									style={{WebkitMask: `url(${Plus}) no-repeat`}}
									onClick={(adults + children) < 19 ? actions.adultInc : () => {} }
								/>
							</div>
						</div>
						<div className={'children'}>
							<div>Дети</div>
							<div className={'button-block'}>
								<span
									className={`icon-button ${children > 0 && 'active'}`}
									style={{WebkitMask: `url(${Minus}) no-repeat`}}
									onClick={children > 0 ? actions.childDec : () => {} }
								/>
								<div>{children}</div>
								<span
									className={`icon-button ${(adults + children) < 19 && 'active'}`}
									style={{WebkitMask: `url(${Plus}) no-repeat`}}
									onClick={(adults + children) < 19 ? actions.childInc : () => {} }
								/>
							</div>
						</div>
					</div>
				</div>}
			</div>
		</div>
	);
};

export default TouristsField;