import React from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box, Collapse, Divider, FormControlLabel } from '@mui/material'
import FilterCheckbox from '../FilterCheckbox'
import { isEmpty } from 'lodash'

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
`

const Title = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
`
const Label = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
`
const MoreButton = styled.div`
	cursor: pointer;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #4872f2;
`

export default function ServiceFilter({
    list,
    title,
    checked = [],
    changeHandler,
    showDivider = true
}) {
    const [isOpen, setIsOpen] = React.useState(true)
    const [titleButton, setTitleButton] = React.useState('Еще')

    const handleChange = () => {
        setTitleButton(prev => (prev === 'Еще' ? 'Скрыть' : 'Еще'))
    }

    if (isEmpty(list)) return null

    return (
        <Box>
            <Row onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
                <Title>{title}</Title>
                <ExpandMoreIcon
                    onClick={() => setIsOpen(!isOpen)}
                    style={isOpen ? { transform: 'rotate(180deg)' } : null}
                />
            </Row>
            <Collapse in={isOpen}>
                <Box sx={{ ml: '1px' }}>
                    {list?.slice(0, 5).map(item => (
                        <Box key={item} sx={{ mb: '10px' }}>
                            <FormControlLabel
                                style={{
                                    gap: '4px',
                                    marginRight: '5px',
                                    display: 'flex',
                                    alignItems: 'flex-start'
                                }}
                                control={
                                    <FilterCheckbox
                                        checked={checked.includes(item)}
                                        onChange={() => changeHandler(item)}
                                    />
                                }
                                label={<Label>{item}</Label>}
                            />
                        </Box>
                    ))}
                </Box>
                {titleButton === 'Скрыть' && (
                    <Box sx={{ ml: '1px' }}>
                        {list?.slice(5).map(item => (
                            <Box key={item} sx={{ mb: '10px' }}>
                                <FormControlLabel
                                    style={{
                                        gap: '4px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        alignItems: 'flex-start'
                                    }}
                                    control={
                                        <FilterCheckbox
                                            checked={checked.includes(item)}
                                            onChange={() => changeHandler(item)}
                                        />
                                    }
                                    label={<Label>{item}</Label>}
                                />
                            </Box>
                        ))}
                    </Box>
                )}
                {list.length > 5 && (
                    <MoreButton onClick={handleChange}>{titleButton}</MoreButton>
                )}

                {showDivider && <Divider sx={{ mt: 2, mb: 2, color: '#D4D9DE' }} />}
            </Collapse>
        </Box>
    )
}
