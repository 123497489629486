import React, { useMemo } from 'react'

import './style.scss'
import { Button } from '@material-ui/core'
import useTransferStore from '../../../store'
import { Alert } from '@mui/material'

export const childServices = ['childAutoCradle', 'childBooster', 'childChair']

const AdditionalServices = ({ draft, transfer, valid }) => {
	const actions = useTransferStore(({ actions }) => actions)
	const transferServiceInfos = useTransferStore(
		({ TransferServiceInfos }) => TransferServiceInfos
	)
	const isConsistChildService = useMemo(() => {
		if (!draft.transfers[0].passengers.children) {
			return true
		} else {
			if (valid) {
				return transferServiceInfos.some(item =>
					childServices.includes(item.latinName)
				)
			}
			return true
		}
	}, [transferServiceInfos, draft, valid])
	return (
		<div className={'transfer-kiwi__services'}>
			<div className='block-header'>
				<h3 className='title'>Дополнительные услуги</h3>
			</div>
			<div
				className='block-body'
				style={{ marginBottom: isConsistChildService ? '20px' : 0 }}>
				<div className='grid-tour header'>
					<div>Услуга</div>
					<div>Цена</div>
					<div></div>
				</div>
				{transfer.optionalServicesPrice.drinkingWater && (
					<div className='grid-tour body'>
						<div>Питьевая вода</div>
						<div>
							{Math.round(
								1.25 * transfer.optionalServicesPrice.drinkingWater.rub.cost
							).toLocaleString('ru', {})}{' '}
							{draft.transfers[0].currency.currencyAlias}
						</div>
						<div>
							<Button
								variant='contained'
								color={
									!transferServiceInfos.find(
										item => item.latinName === 'drinkingWater'
									)
										? 'primary'
										: 'secondary'
								}
								fullWidth
								onClick={() =>
									actions.addService({
										...transfer.optionalServicesPrice.drinkingWater,
										latinName: 'drinkingWater',
										name: 'Питьевая вода'
									})
								}>
								{!transferServiceInfos.find(
									item => item.latinName === 'drinkingWater'
								)
									? 'Добавить'
									: 'Удалить'}
							</Button>
						</div>
					</div>
				)}
				{transfer.optionalServicesPrice.childAutoCradle && (
					<div
						className='grid-tour body'
						style={{ borderColor: isConsistChildService ? '#888888' : 'red' }}>
						<div>Детская люлька</div>
						<div>
							{Math.round(
								1.25 * transfer.optionalServicesPrice.childAutoCradle.rub.cost
							).toLocaleString('ru', {})}{' '}
							{draft.transfers[0].currency.currencyAlias}
						</div>
						<div>
							<Button
								variant='contained'
								color={
									!transferServiceInfos.find(
										item => item.latinName === 'childAutoCradle'
									)
										? 'primary'
										: 'secondary'
								}
								fullWidth
								onClick={() =>
									actions.addService({
										...transfer.optionalServicesPrice.childAutoCradle,
										latinName: 'childAutoCradle',
										name: 'Детская люлька'
									})
								}>
								{!transferServiceInfos.find(
									item => item.latinName === 'childAutoCradle'
								)
									? 'Добавить'
									: 'Удалить'}
							</Button>
						</div>
					</div>
				)}
				{transfer.optionalServicesPrice.childBooster && (
					<div
						className='grid-tour body'
						style={{ borderColor: isConsistChildService ? '#888888' : 'red' }}>
						<div>Бустер</div>
						<div>
							{Math.round(
								1.25 * transfer.optionalServicesPrice.childBooster.rub.cost
							).toLocaleString('ru', {})}{' '}
							{draft.transfers[0].currency.currencyAlias}
						</div>
						<div>
							<Button
								variant='contained'
								color={
									!transferServiceInfos.find(
										item => item.latinName === 'childBooster'
									)
										? 'primary'
										: 'secondary'
								}
								fullWidth
								onClick={() =>
									actions.addService({
										...transfer.optionalServicesPrice.childBooster,
										latinName: 'childBooster',
										name: 'Бустер'
									})
								}>
								{!transferServiceInfos.find(
									item => item.latinName === 'childBooster'
								)
									? 'Добавить'
									: 'Удалить'}
							</Button>
						</div>
					</div>
				)}
				{transfer.optionalServicesPrice.childChair && (
					<div
						className='grid-tour body'
						style={{ borderColor: isConsistChildService ? '#888888' : 'red' }}>
						<div>Детское сидение</div>
						<div>
							{Math.round(
								1.25 * transfer.optionalServicesPrice.childChair.rub.cost
							).toLocaleString('ru', {})}{' '}
							{draft.transfers[0].currency.currencyAlias}
						</div>
						<div>
							<Button
								variant='contained'
								color={
									!transferServiceInfos.find(
										item => item.latinName === 'childChair'
									)
										? 'primary'
										: 'secondary'
								}
								fullWidth
								onClick={() =>
									actions.addService({
										...transfer.optionalServicesPrice.childChair,
										latinName: 'childChair',
										name: 'Детское кресло'
									})
								}>
								{!transferServiceInfos.find(
									item => item.latinName === 'childChair'
								)
									? 'Добавить'
									: 'Удалить'}
							</Button>
						</div>
					</div>
				)}
			</div>
			{!isConsistChildService && (
				<Alert severity='error' style={{ marginBottom: '20px' }}>
					Отсутствует детское удерживающие устройство
				</Alert>
			)}
		</div>
	)
}

export default AdditionalServices
