import React from 'react';
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import infoIconSrc from "../../../asset/images/avia/shared/info-icon.svg"
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))((props) => {
    return {
        [`& .${tooltipClasses.arrow}`]: {
          color: 'white',
          "&::before": {
              backgroundColor: 'white',
              border: "1px solid #999"
            }
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'white',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '0px 15px 20px 0px rgba(16, 16, 16, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
          fontSize: '12px',
          lineHeight: '16px',
          fontFamily: 'Open Sans',
          padding: 14,
          width: props.width,
        },
    }
  });

function InfoIcon({className,...props}) {
    return (
        <LightTooltip {...props} arrow placement="top" >
            <img className={className} src={infoIconSrc} alt={''}/>
        </LightTooltip>
    );
}

InfoIcon.propTypes = {
    title: PropTypes.string
}

export default InfoIcon;