import { useMediaQuery } from '@material-ui/core'
import React from 'react'

import './style.css'

const BannerFullWidth = ({ content }) => {
	const isMobile = useMediaQuery('(min-width:480px)')
	const style = {
		backgroundImage: `url(${
			isMobile ? content.style.desktop.bg : content.style.mobile.bg
		})`
	}

	return (
		<div
			className={isMobile ? 'banner-full-width' : 'banner-full-width-mobile'}
			style={style}>
			<div
				className={'banner_title'}
				style={{
					fontSize: isMobile
						? content.style.desktop.title_font + 'em'
						: content.style.mobile.title_font + 'em',
					textAlign: isMobile
						? content.style.desktop.title_align
						: content.style.mobile.title_align,
					color: isMobile
						? content.style.desktop.title_color
						: content.style.mobile.title_color
				}}>
				<span>{content.title}</span>
			</div>
			<div
				className={'banner_text'}
				style={{
					fontSize: isMobile
						? content.style.desktop.text_font + 'em'
						: content.style.mobile.text_font + 'em',
					textAlign: isMobile
						? content.style.desktop.text_align
						: content.style.mobile.text_align,
					color: isMobile
						? content.style.desktop.text_color
						: content.style.mobile.text_color
				}}>
				<p className={'text'}>{content.text}</p>
			</div>
			<div
				className={'banner_link'}
				style={{
					fontSize: isMobile
						? content.style.desktop.link_font + 'em'
						: content.style.mobile.link_font + 'em',
					textAlign: isMobile
						? content.style.desktop.link_align
						: content.style.mobile.link_align,
					color: isMobile
						? content.style.desktop.link_color
						: content.style.mobile.link_color
				}}>
				<span>
					<a href={content.linkUrl}>{content.linkName}</a>
				</span>
			</div>
		</div>
	)
}

export default BannerFullWidth
