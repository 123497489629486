import React from 'react';
import './RightSide.scss';
import YourFlight from './YourFlight';
import Prices from './Prices'



export default function RightSide() {
  return (
    <div className='fs-booking-right-side'>
      <YourFlight/>
      <div className='fs-booking-right-side__divide'/>
      <Prices/>
    </div>
  );
}