export const nationality = [
	{id: 233909, name: "Абхазия"},
	{id: 15907, name: "Австралия"},
	{id: 18754, name: "Австрия"},
	{id: 433579, name: "Азербайджан"},
	{id: 341911, name: "Албания"},
	{id: 1000985, name: "Алжир"},
	{id: 1000986, name: "Ангола"},
	{id: 18757, name: "Андорра"},
	{id: 1000987, name: "Антигуа и Барбуда"},
	{id: 823046, name: "Аргентина"},
	{id: 235572, name: "Армения"},
	{id: 1000988, name: "Афганистан"},
	{id: 1000989, name: "Багамские Острова"},
	{id: 1000990, name: "Бангладеш"},
	{id: 15997, name: "Барбадос"},
	{id: 689311, name: "Бахрейн"},
	{id: 325801, name: "Беларусь"},
	{id: 15998, name: "Белиз"},
	{id: 369082, name: "Бельгия"},
	{id: 1000991, name: "Бенин"},
	{id: 18764, name: "Болгария"},
	{id: 20462, name: "Боливия"},
	{id: 1000992, name: "Босния"},
	{id: 1001104, name: "Босния и Герцеговина"},
	{id: 1000993, name: "Ботсвана"},
	{id: 376559, name: "Бразилия"},
	{id: 1000994, name: "Бруней"},
	{id: 1000995, name: "Буркина-Фасо"},
	{id: 1000996, name: "Бурунди"},
	{id: 1000997, name: "Бутан"},
	{id: 15912, name: "Вануату"},
	{id: 1000998, name: "Ватикан"},
	{id: 18767, name: "Великобритания"},
	{id: 188015, name: "Венгрия"},
	{id: 20465, name: "Венесуэла"},
	{id: 1000999, name: "Восточный Тимор"},
	{id: 293645, name: "Вьетнам"},
	{id: 1001000, name: "Габон"},
	{id: 16000, name: "Гаити"},
	{id: 1001001, name: "Гайана"},
	{id: 1001002, name: "Гамбия"},
	{id: 1001003, name: "Гана"},
	{id: 16002, name: "Гватемала"},
	{id: 1001005, name: "Гвинея-Бисау"},
	{id: 1001004, name: "Гвинея"},
	{id: 351392, name: "Германия"},
	{id: 16003, name: "Гондурас"},
	{id: 696010, name: "Гонконг"},
	{id: 1001006, name: "Гренада"},
	{id: 18741, name: "Греция"},
	{id: 223991, name: "Грузия"},
	{id: 367722, name: "Дания"},
	{id: 1001008, name: "Демократическая Республика Конго"},
	{id: 1001009, name: "Джибути"},
	{id: 16004, name: "Доминиканская Республика"},
	{id: 18498, name: "Египет"},
	{id: 1001011, name: "Замбия"},
	{id: 1001012, name: "Зимбабве"},
	{id: 17762, name: "Израиль"},
	{id: 18028, name: "Индия"},
	{id: 250722, name: "Индонезия"},
	{id: 230174, name: "Иордания"},
	{id: 1001013, name: "Ирак"},
	{id: 833464, name: "Иран"},
	{id: 436511, name: "Ирландия"},
	{id: 384450, name: "Исландия"},
	{id: 18747, name: "Испания"},
	{id: 18770, name: "Италия"},
	{id: 1001017, name: "Йемен"},
	{id: 1000976, name: "Кабо-Верде"},
	{id: 367404, name: "Казахстан"},
	{id: 1001097, name: "Казахстан."},
	{id: 376031, name: "Камбоджа"},
	{id: 1001018, name: "Камерун"},
	{id: 21062, name: "Канада"},
	{id: 759622, name: "Катар"},
	{id: 815810, name: "Кения"},
	{id: 18772, name: "Кипр"},
	{id: 764932, name: "Киргизия"},
	{id: 1001019, name: "Кирибати"},
	{id: 230826, name: "Китай"},
	{id: 1001022, name: "КНДР"},
	{id: 20468, name: "Колумбия"},
	{id: 1001020, name: "Коморы"},
	{id: 1001021, name: "Конго"},
	{id: 1001023, name: "Косово"},
	{id: 295842, name: "Коста-Рика"},
	{id: 1001024, name: "Кот-д’Ивуар"},
	{id: 266539, name: "Круизы"},
	{id: 1001085, name: "Круизы"},
	{id: 16006, name: "Куба"},
	{id: 1001025, name: "Кувейт"},
	{id: 1001026, name: "Лаос"},
	{id: 352187, name: "Латвия"},
	{id: 1001027, name: "Лесото"},
	{id: 1001028, name: "Либерия"},
	{id: 1000956, name: "Ливан"},
	{id: 1001029, name: "Ливия"},
	{id: 384548, name: "Литва"},
	{id: 1001030, name: "Лихтенштейн"},
	{id: 1001031, name: "Люксембург"},
	{id: 293483, name: "Маврикий"},
	{id: 1001032, name: "Мавритания"},
	{id: 1001033, name: "Мадагаскар"},
	{id: 1001034, name: "Малави"},
	{id: 435746, name: "Малайзия"},
	{id: 1001036, name: "Мали"},
	{id: 275410, name: "Мальдивы"},
	{id: 341454, name: "Мальта"},
	{id: 745206, name: "Марокко"},
	{id: 1001037, name: "Маршалловы Острова"},
	{id: 16007, name: "Мексика"},
	{id: 1001038, name: "Микронезия"},
	{id: 1001039, name: "Мозамбик"},
	{id: 1000982, name: "Молдавия"},
	{id: 1001040, name: "Монако"},
	{id: 1001041, name: "Монголия"},
	{id: 1001103, name: "Монголия"},
	{id: 376019, name: "Мьянма"},
	{id: 1001043, name: "Намибия"},
	{id: 1001044, name: "Науру"},
	{id: 1001045, name: "Непал"},
	{id: 1001046, name: "Нигер"},
	{id: 1001047, name: "Нигерия"},
	{id: 363196, name: "Нидерланды"},
	{id: 1001048, name: "Никарагуа"},
	{id: 15910, name: "Новая Зеландия"},
	{id: 380522, name: "Норвегия"},
	{id: 20613, name: "ОАЭ"},
	{id: 277953, name: "Оман"},
	{id: 1001091, name: "Острова Кайман"},
	{id: 1001049, name: "Пакистан"},
	{id: 1001050, name: "Палау"},
	{id: 1001051, name: "Палестина"},
	{id: 16010, name: "Панама"},
	{id: 1001052, name: "Папуа - Новая Гвинея"},
	{id: 20469, name: "Парагвай"},
	{id: 1001053, name: "Перу"},
	{id: 287299, name: "Польша"},
	{id: 18792, name: "Португалия"},
	{id: 16012, name: "Пуэрто-Рико"},
	{id: 210357, name: "Россия"},
	{id: 1001054, name: "Руанда"},
	{id: 1000959, name: "Румыния"},
	{id: 1001055, name: "Сальвадор"},
	{id: 1001056, name: "Самоа"},
	{id: 1001057, name: "Сан-Марино"},
	{id: 1001058, name: "Сан-Томе и Принсипи"},
	{id: 841186, name: "Саудовская Аравия"},
	{id: 1001059, name: "Свазиленд"},
	{id: 276798, name: "Северная Македония"},
	{id: 15913, name: "Северные Марианские Острова"},
	{id: 1001060, name: "Северный Кипр"},
	{id: 284350, name: "Сейшелы"},
	{id: 1001061, name: "Сенегал"},
	{id: 1001062, name: "Сент-Винсент и Гренадины"},
	{id: 1001063, name: "Сент-Китс и Невис"},
	{id: 1001064, name: "Сент-Люсия"},
	{id: 209834, name: "Сербия"},
	{id: 251543, name: "Сингапур"},
	{id: 1000963, name: "Сирия"},
	{id: 216683, name: "Словакия"},
	{id: 288271, name: "Словения"},
	{id: 15911, name: "Соломоновы Острова"},
	{id: 1001065, name: "Сомали"},
	{id: 1001066, name: "Судан"},
	{id: 20474, name: "Суринам"},
	{id: 660560, name: "США"},
	{id: 1001067, name: "Сьерра-Леоне"},
	{id: 708665, name: "Таджикистан"},
	{id: 20625, name: "Таиланд"},
	{id: 1001096, name: "Тайвань"},
	{id: 439254, name: "Танзания"},
	{id: 1001070, name: "Того"},
	{id: 1001071, name: "Тонга"},
	{id: 1001072, name: "Тринидад и Тобаго"},
	{id: 1001073, name: "Тувалу"},
	{id: 18464, name: "Тунис"},
	{id: 1001074, name: "Туркменистан"},
	{id: 18803, name: "Турция"},
	{id: 1001075, name: "Уганда"},
	{id: 708675, name: "Узбекистан"},
	{id: 196637, name: "Украина"},
	{id: 20471, name: "Уругвай"},
	{id: 15908, name: "Фиджи"},
	{id: 253604, name: "Филиппины"},
	{id: 204426, name: "Финляндия"},
	{id: 18808, name: "Франция"},
	{id: 18810, name: "Хорватия"},
	{id: 1001077, name: "Чад"},
	{id: 18812, name: "Черногория"},
	{id: 18813, name: "Чехия"},
	{id: 1001078, name: "Чили"},
	{id: 18750, name: "Швейцария"},
	{id: 388181, name: "Швеция"},
	{id: 20629, name: "Шри-Ланка"},
	{id: 1001079, name: "Эквадор"},
	{id: 1001080, name: "Экваториальная Гвинея"},
	{id: 1001081, name: "Эритрея"},
	{id: 385060, name: "Эстония"},
	{id: 1001082, name: "Эфиопия"},
	{id: 277390, name: "Южная Корея"},
	{id: 1001083, name: "Южно-Африканская Республика"},
	{id: 1001084, name: "Южный Судан"},
	{id: 1000965, name: "Ямайка"},
	{id: 337852, name: "Япония"},
]