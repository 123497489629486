import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

const Title = styled.div`
  margin-bottom: 25px;
  margin-top: 30px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`

const SubTitle = styled(Title)`
  margin: 0px;
  font-weight: 400;
  font-size: 15px;

  @media (max-width: 1024px) {
    margin-top: 15px;
  }
`

const Table = styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width: calc(120%); //

    margin-top: 20px;
    //margin-left: -10px;
  }
`

const ScrollTable = styled.table`
  table-layout: auto;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: calc(898px - 48px);
  // width: calc(120%); 

  @media (max-width: 768px) {
    margin-top: 20px;
    //margin-left: -10px;
  }
`

const Tr = styled.tr`
  background: #fafafa;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
`

const TitleTh = styled.th`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  height: 56px;
  margin: 0;
  text-align: left;

  padding: 17px 0px;
  padding-left: 17px;
  color: #3c3c3c;
`

const BasicTd = styled.td`
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 17px 0px;
  padding-left: 17px;
  padding-right: 20px;
  text-align: left;
  box-sizing: border-box;
  color: #3c3c3c;

  //border: 1px solid #3c3c3c;
`

const BlueTd = styled(BasicTd)`
  color: #4872f2;
  text-decoration: underline;
`

const BaseTr = styled.tr`
  border-bottom: 0.5px solid #dcdcdc;
  cursor: pointer;
`

export const TableUi = {
  Container,
  Title,
  SubTitle,
  Table,
  Tr,
  TitleTh,
  BlueTd,
  BaseTr,
  BasicTd,
  ScrollTable
}
