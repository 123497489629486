import React, { useEffect, useRef, useState } from "react"
import MessageList from "./MessageList"
import Input from "./Input"
import { userNotify } from "./utils"
import styled from 'styled-components';

const Header = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px
`
const Chat = ({ items, children, ...props }) => {
  const scrollBar = useRef(null)
  const [length, setLength] = useState(items.length)

  useEffect(() => {
    if (window.Notification) {
      Notification.requestPermission()
    }
  }, [])

  useEffect(() => {
    const newMessageReceived = items.length !== length

    if (scrollBar.current && newMessageReceived) {
      const notInitialState = length !== 0 && items.length !== 0
      const lastMessageNotOwnedByUser = !items[items.length - 1].owner
      if (notInitialState && lastMessageNotOwnedByUser) {
        userNotify(items[items.length - 1].text)
      }
      setLength(items.length)
      scrollBar.current.scrollToBottom()
    }
  }, [scrollBar, items])

  return (
    <>
      <Header>Чат службы поддержки</Header>
      <MessageList items={items} scrollbar={scrollBar} {...props} />
      <Input {...props} />
    </>
  )
}

export default Chat
