import ButtonsWithTitleTemplate from "./template/buttonsWithTitle";
import BubbleButtons from "../blockWithButtons/template/bubbleButtons";

import "./style.scss"

function BlockWithButtons({ content, title }) {
    const renderTemplate = (template) => {
        switch (template) {
            case 'buttonsWithTitle':
                return <ButtonsWithTitleTemplate content={content} />
			case 'bubbleButtons':
				return <BubbleButtons content={content} />
            default: return null
        }
    }

    return <div className="buttonsWithTitle">
        <div className="buttonsWithTitle__head">
            <h2 className="buttonsWithTitle__head__title">
                {title}
            </h2>
            {content?.all_link && <a
                className="buttonsWithTitle__head__link"
                href={content?.all_link}
                target="_blank"
                rel="noreferrer"
            >
                {content?.all_text}
            </a>}
        </div>
        <div className="buttonsWithTitle__content">
            {renderTemplate(content?.template)}
        </div>
    </div>
}

export default BlockWithButtons