import React from "react"
import styled from "styled-components"
import { Text } from "../../ui"
import moment from "moment"
import formatPrice from "../../../functions/formatPrice";
import Collapse from "@mui/material/Collapse";
import {styled as MUIStyled} from '@mui/material'

const Cell = styled(Text)`
  display: block;
  padding-left: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3c3c3c;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  white-space: nowrap;
`

const Empty = styled(Text)`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  margin-top: 14px;
  width: 100%;
  display: block;
`;

const DescriptionCell = styled(Cell)`
  background: #fafafa;
  font-weight: 600;
`

const Wrapper = styled.div`
  // background: white;
  // padding: 20px 24px;
  width: calc(100%);

  @media (max-width: 1140px) {
    padding: 20px 14px;
    margin-left: -14px;
    overflow-x: scroll;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.1fr;

  ${DescriptionCell} {
    &:after {
      position: absolute;
      content: " ";
      height: 22px;
      width: 1px;
      top: 50%;
      background: rgba(0, 0, 0, 0.03);
      transform: translate(0, -50%);
      right: 0;
    }
  }

  & > :nth-child(4) {
    &:after {
      display: none;
    }
  }
`
const StyledCollapse = MUIStyled(Collapse)(()=>({
  '& .MuiCollapse-wrapper': {
    background: 'white',
    padding: '20px 24px',
  },
}));


export default function AgentPayment(props) {
  return (
    <StyledCollapse in={props.open}>
      <Wrapper>
        <Container>
          <DescriptionCell>Дата платежа</DescriptionCell>
          <DescriptionCell>Сумма платежа</DescriptionCell>
          <DescriptionCell>Тип платежа</DescriptionCell>
          <DescriptionCell>Информация о платеже</DescriptionCell>
          {props.items?.map((x, key) => (
            <React.Fragment key={key}>
              <Cell>{moment(x.paymentDate).format("DD.MM.YYYY HH:mm")}</Cell>
              <Cell>{formatPrice(x.paymentSum)}</Cell>
              <Cell>{x.paymentType}</Cell>
              <Cell>{x.paymentInfo === "" ? "отсутствует" : x.paymentInfo}</Cell>
            </React.Fragment>
          ))}
        </Container>
        {props.items?.length === 0 && <Empty>Платежи отсутствуют</Empty>}
      </Wrapper>
    </StyledCollapse>
  )
}
