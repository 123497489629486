import React from "react"
import styled, {keyframes} from "styled-components"
import { Text, OnlyRusCardNotyBlue } from "../../ui"
import { AgentPayModal,IssueModal } from "../../modals"
import formatPrice from "../../../functions/formatPrice";
import formatDuration from "../../../functions/formatDuration";
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import {
  issueTicketsRequest,
  issueTicketsPurify,
  showModalError,
  issueOrderPositionRequest,
  payOrderRequest,
  getAgentOrderBillRequest,
  getDetailOrder,
} from "../../../store/modules/orders"
import AgentPayments from "./AgentPayments"
import arrowSrc from "../../../asset/images/avia/order-detail/arrow1.svg"
import {ReactComponent as ClockIcon} from "../../../asset/images/avia/order-detail/clock.svg"
import {ReactComponent as OkIcon} from "../../../asset/images/avia/order-detail/okIcon.svg"
import Collapse from "@mui/material/Collapse";
import PrimaryButton from "../../ui/PrimaryButton";
import OutlinedButton from '../../ui/OutlinedButton';
import {styled as styledMUI} from '@mui/material/styles'
import dateToLocal from "../../../functions/dateToLocal";
import {getCommissionStatus, setCommissionStatus} from "../../../store/modules/user";


const Wrapper = styled.div`
  padding-top: 24px;
  background: #EDF1FE;
  color: #DCDCDC;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 14px;
  }

  & > :last-child {
    padding-bottom: 24px;
  }
  
  .MuiCollapse-root {
    padding-bottom: 0;
  }
`

const PaddingWrapper = styled.div`
  padding: 0 24px;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div`
  text-align: left;
  & > span {
    display: block;
    margin-bottom: 10px;
  }

  @media (max-width: 1140px) {
    ${Text} {
      font-size: 14px;
      font-weight: normal;
    }
    & > :first-child {
      font-weight: 600;
    }
    margin-bottom: 5px;
  }
`

const DescriptionBlock = styled.div`
  text-align: left;
  & > span {
    display: block;
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1140px) {
    ${Text} {
      font-size: 14px;
      font-weight: normal;
    }
  }

  ${({ grid }) =>
    grid
      ? `
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 802px;

    .user {
      order: 4;
    }

    .email {
      order: 6;
    }

    .price {
      order: 1;
    }

    .total-price {
      order: 7;
    }

    .debt {
      order: 5;
    }

    .status,
    .status2 {
      order: 3;
    }

    .backoffice {
      order: 2;
    }

    .agent-price {
      order: 9;
      border-left: 1px solid #4872F2;
      border-radius: 8px 0 0 8px;
      padding: 10px;
      border-top: 1px solid #4872F2;
      border-bottom: 1px solid #4872F2;
      margin-left: -10px;
      background-color: #fff;
      box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.5);
    }

    .agent-fee {
      order: 10;
      border-right: 1px solid #4872F2;
      border-radius: 0 8px 8px 0;
      padding: 10px 0;
      border-top: 1px solid #4872F2;
      border-bottom: 1px solid #4872F2;
      margin-right: -10px;
      background-color: #fff;
      box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.5);
    }
  `
      : ""}
`



const RightSide = styled.div`
  text-align: right;
  & > span {
    display: block;
  }
  @media (max-width: 1140px) {
    text-align: left;
    & > span {
      font-weight: normal;
      display: initial;
    }
    & > :last-child {
      color: #3c3c3c;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const Row = styled(Text).attrs({ fontWeight: 600, color: "#737373" })`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
  font-weight: 600;
`

const MobileText = styled(Text)`
  display: none;
  @media (max-width: 1140px) {
    display: inline;
  }
`
const PaymentButton = styledMUI(PrimaryButton)(()=>({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 25px',
  fontStyle: 'normal',
  fontWeight: '600',
  display: 'block',
  minWidth: '183px',
  alignSelf: 'flex-start',
  flexShrink: '0',
}));

const AgentNewButton = styledMUI(OutlinedButton)(()=>({
  padding: '10px 24px',
  lineHeight: '20px',
}));


const IssueButton = styledMUI(PrimaryButton)(()=>({
  border: '1px solid #c4c4c4',
  fontSize: '14px',
  lineHeight: '20px',
  padding: '10px 20px',
  marginLeft: '20px',

}));

const ExtraPaymentBlock = styled.div`
  border-top: 1px solid #dbe0e5;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PaymentNewBlock = styled.div`
  border-top: 1px solid #dbe0e5;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
`

const ExpirationText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }
`

const ShowAgentPaymentButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  display: block;

  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  position: relative;

  & > img {
    transition: transform 0.5s;
    ${(props) => props.open ? `transform: rotateX(180deg);`: ''}
  }
`

const Arrow = styled.img.attrs({ src: arrowSrc, width: 10, height: 5 })`
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
`

const show = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const hide = keyframes`
  to {
    stroke-dashoffset: 23;
  }
`;

const ActionButton = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: none;
  background: transparent;
  margin-top: 6px;
  padding: 0;
  display: flex;
  align-items: center;
  color: #4872F2;

  & > svg {
    margin-right: 5px;
  }

  .tte-svg-eye {
    .tte-svg-eye-line {
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      &.hide {
        stroke-dashoffset: 0;
        animation: ${hide} 0.3s linear forwards;
      }
      &.show {
        animation: ${show} 0.3s linear forwards;
      }
    }
  }
`;

const Head = ({props, resultRef}) => {
  const { totalDetalization } = useSelector(getDetailOrder)
  const dispatch = useDispatch()

  const modalErrorVisible = useSelector(
    state => state.orders.orderDetail.modalErrorVisible
  )
  const localCancellationTime = dateToLocal(props.expiredTime)
  const duration = localCancellationTime.diff(moment(), "minutes")

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [agentPaymentsOpen, setAgentPaymentsOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [showErrorModal, setShowErrorModal] = React.useState(false)
  const order = useSelector(x => x.orders.orderDetail)
  const issueStatus = useSelector(state => state.orders.orderDetail.issueStatus)
  const totalAmount = useSelector(x => x.orders.orderDetail.activeCalulations.totalAmount)
  const totalAmountWithSubAgentExtraCharge = useSelector(x => x.orders.orderDetail.activeCalulations.totalAmountWithSubAgentExtraCharge)
  const paymentMethodSelected = useSelector(x => x.orders.orderDetail.paymentMethod)
  const user = useSelector(x => x.user)
  const isShowCommission = useSelector(getCommissionStatus);
  const extraPayment = totalAmount > 0

  const scrollToPayOrder = () => {
    resultRef.resultRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const yellow = props.amountExtraPayment > 0

  React.useEffect(() => {
    if (issueStatus !== null) {
      setIsModalOpen(true)
    }
  }, [issueStatus])

  React.useEffect(() => {
    if (modalErrorVisible) {
      setShowErrorModal(true)
      dispatch(showModalError(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalErrorVisible])

  const isIssueAvailable = props?.availableShipmentAllowed?.availableIssue
  const firstPayButton = (props.availableActions || []).includes("PAYMENT") && !extraPayment && order?.data?.salesChannel !== "Собственная розница" && !isIssueAvailable;
  const secondPayButton = (props.availableActions || []).includes("PAYMENT") && !extraPayment && order?.data?.salesChannel !== "Собственная розница" && isIssueAvailable && user?.data?.agentId;
  const thirdPayButton = (props.availableActions || []).includes("PAYMENT") && !extraPayment && order?.data?.salesChannel === "Собственная розница";
  const isAgentPaymentTable = !!order?.getOrderPayments && order.getOrderPayments.payments !== null;

  const handleActionButtonClick = () => {
    dispatch(setCommissionStatus(!isShowCommission))
  }

  return (
    <Wrapper yellow={yellow}>
        <PaddingWrapper>
          <Flex>
            <LeftSide>
              <Text size={16} color="#3C3C3C" fontWeight="600">
                Заявка № {props.orderNumber} от{" "}
                {dateToLocal(props.createdDate).format("DD.MM.YYYY, HH:mm")}
              </Text>
              <DescriptionBlock grid={order?.data?.salesChannelType === "b2b"}>
                {order?.data?.salesChannelType === "b2b" && (
                  <>
                    <Row className="price" fontWeight="600" color="#737373">
                      Стоимость заявки:{" "}
                      <strong style={{color:'#4872F2'}}>
                        {formatPrice(
                          props.amount - totalAmountWithSubAgentExtraCharge
                        )}
                      </strong>
                    </Row>
                    <Row className="debt" fontWeight="600" color="#737373">
                      <span> Долг по заявке</span>:{" "}
                      <strong>
                        {formatPrice(
                          order?.getOrderPayments?.orderDebt || 0
                        )}
                      </strong>
                    </Row>
                    <Row className="user" fontWeight="600" color="#737373">
                      Заявку создал(а):{" "}
                      <strong>
                        {order?.data?.customer.firstName
                          ? order?.data?.customer.firstName +
                            " " +
                            order?.data?.customer.lastName
                          : order?.data?.customer.email}
                      </strong>
                    </Row>
                  </>
                )}

                {!extraPayment && (
                  <>
                    <Row className="status2" fontWeight="600" color="#737373">
                      Статус оплаты:{" "}
                      <strong style={{color:'#4872F2'}}>{props.paymentStatus?.description}</strong>
                    </Row>
                    {isShowCommission && <>
                    <Row className="agent-price" fontWeight="600" color="#737373">
                      <span> Стоимость для агента</span>:{" "}
                      <strong style={{color:'#4872F2'}}>{formatPrice(totalDetalization.agentTotal || 0)}</strong>
                    </Row>
                    <Row className="agent-fee" fontWeight="600" color="#737373">
                      <span> Сбор агента</span>:{" "}
                      <strong style={{color:'#4872F2'}}>{formatPrice(totalDetalization.agentFee || 0)}</strong>
                    </Row>
                    </>}
                  </>
                )}

                {extraPayment && (
                  <>
                    <Row className="status" fontWeight="600" color="#737373">
                      Статус оплаты:{" "}
                      <strong>{props.paymentStatus?.description}</strong>
                    </Row>
                    {order?.data?.salesChannelType !== "b2b" && (
                      <Row fontWeight="600" color="#737373">
                        Стоимость заявки:{" "}
                        <strong>
                          {formatPrice(props.amount - totalAmount)}
                        </strong>
                      </Row>
                    )}
                    <Row className="total-price" fontWeight="600" color="#737373">
                      Итоговая стоимость заявки после доплаты:{" "}
                      <strong>{formatPrice(props.amount)}</strong>
                    </Row>
                  </>
                )}

                {order?.data?.salesChannelType === "b2b" && order?.data?.customer && (
                  <>
                    <Row className="backoffice" fontWeight="600" color="#737373">
                      Номер для оплаты: <strong>{order?.data?.backOfficeId}</strong>
                    </Row>
                    <Row className="email" fontWeight="600" color="#737373">
                      Email: <strong>{order?.data?.customer.email}</strong>
                    </Row>
                  </>
                )}
              </DescriptionBlock>

              <ActionButton onClick={handleActionButtonClick}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="tte-svg-eye">
                  <g id="Frame">
                    <path id="Vector" d="M15.9424 8.2215C16.2979 8.6865 16.2979 9.31425 15.9424 9.7785C14.8226 11.2403 12.1361 14.25 8.99964 14.25C5.86314 14.25 3.17664 11.2403 2.05689 9.7785C1.88393 9.55585 1.79004 9.28194 1.79004 9C1.79004 8.71806 1.88393 8.44415 2.05689 8.2215C3.17664 6.75975 5.86314 3.75 8.99964 3.75C12.1361 3.75 14.8226 6.75975 15.9424 8.2215Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path stroke="#4872F2" d="M3 15L15 3"  stroke-linecap="round" stroke-linejoin="round" className={`tte-svg-eye-line ${isShowCommission ? 'show': 'hide'}`}/>
                    <path id="Vector_2" d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#4872F2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>
                <span>{isShowCommission ? 'Скрыть' : 'Открыть'} детали для агента</span>
              </ActionButton>

            </LeftSide>{" "}

            {!extraPayment && order?.data?.salesChannelType !== "b2b" && (
              <RightSide>
                <Text fontWeight="600" color="#737373">
                  Стоимость<MobileText> заявки</MobileText>:{" "}
                </Text>
                <Text size={18} fontWeight="600" color="blue">
                  {formatPrice(order?.data?.amount - totalAmount)}
                </Text>
              </RightSide>
            )}
          </Flex>
        </PaddingWrapper>
        {isAgentPaymentTable && (
          <PaddingWrapper>
            <ShowAgentPaymentButton open={agentPaymentsOpen} onClick={() => setAgentPaymentsOpen(x => !x)}>
              {agentPaymentsOpen ? "Свернуть" : "Посмотреть"} список платежей
              <Arrow />
            </ShowAgentPaymentButton>
            <Collapse in={agentPaymentsOpen || (!agentPaymentsOpen && !(firstPayButton || secondPayButton || thirdPayButton || extraPayment))}>
              <div style={{height: 15}}></div>
            </Collapse>
            <Collapse in={!agentPaymentsOpen && !(firstPayButton || secondPayButton || thirdPayButton || extraPayment)}>
              <div style={{height: 15}}></div>
            </Collapse>
          </PaddingWrapper>
        )}
        {isAgentPaymentTable && (
          <>
            <AgentPayments
              open={agentPaymentsOpen}
              items={order.getOrderPayments.payments}
            />
          </>
        )}

        {firstPayButton && (
            <PaddingWrapper>
              <ExtraPaymentBlock style={{display: 'block'}}>
                <OnlyRusCardNotyBlue />
                <Flex style={{alignItems: 'center',marginTop: '18px'}}>
                {props.availableActions.includes("PAYMENT") && duration > 0 && (
                  <Row className="expiration" fontWeight="600" color="#737373">
                    Необходимо оплатить заявку в течении:{" "}
                    <strong style={{color:'#4872F2'}}>{formatDuration(duration)}</strong>
                  </Row>
                )}
                <PaymentButton color="secondary" onClick={scrollToPayOrder}>
                  Перейти к оплате
                </PaymentButton>
                </Flex>
              </ExtraPaymentBlock>
            </PaddingWrapper>
          )}

        {secondPayButton && (
            <PaddingWrapper>
              <PaymentNewBlock>
                <AgentNewButton onClick={scrollToPayOrder}>
                  Перейти к оплате
                </AgentNewButton>
                {isIssueAvailable && ['Credit', 'Bill'].includes(paymentMethodSelected) && <IssueButton
                  color="secondary"
                  startIcon={props?.availableShipmentAllowed?.ticketIssue ? <OkIcon/> : <ClockIcon width={20} height={20}/>}
                  onClick={() => {
                    dispatch(
                      issueTicketsRequest({
                        orderId: props.orderId,
                        typePaid: "bill",
                        userId: user?.data?.agentId
                      })
                    )
                  }}
                  disabled={!props?.availableShipmentAllowed?.ticketIssue}
                >
                  Выписать заявку
                </IssueButton>}
              </PaymentNewBlock>
            </PaddingWrapper>
          )}
        {thirdPayButton && (
            <PaddingWrapper>
              <ExtraPaymentBlock>
                <PaymentButton
                  color="secondary"
                  onClick={() =>
                    dispatch(
                      issueOrderPositionRequest({
                        orderNumber: order?.data.orderNumber,
                        orderPositionId: order?.data?.orderPositions[0].id
                      })
                    )
                  }
                >
                  Выписать заявку
                </PaymentButton>
              </ExtraPaymentBlock>
            </PaddingWrapper>
          )}
        {extraPayment && (
          <PaddingWrapper>
            <ExtraPaymentBlock>
              {!!duration && (
                <ExpirationText>
                  Необходимо оплатить заявку в течении:{" "}
                  <strong>
                    {duration ? formatDuration(duration) : "-"}
                  </strong>
                </ExpirationText>
              )}
              <OnlyRusCardNotyBlue />
              <PaymentButton color="secondary" onClick={scrollToPayOrder}>
                Доплатить {formatPrice(totalAmount)}
              </PaymentButton>
            </ExtraPaymentBlock>
          </PaddingWrapper>
        )}

        {isModalOpen && (
          <IssueModal
            open={isModalOpen}
            issueStatus={issueStatus}
            onClose={() => {
              setIsModalOpen(false)
              dispatch(issueTicketsPurify())
            }}
          />
        )}
    </Wrapper>
  )
}

export default Head
