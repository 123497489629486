import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import { addDays, format, differenceInDays } from 'date-fns'
import { geoApiInstance } from '../../services/api'
import { isEmpty } from 'lodash'

const BASE_URL = process.env.REACT_APP_FS_APIGATE

const SERVICE_ID = 48

const useYachtRentServicesStore = create(
	devtools((set, get) => ({
		authError: false,
		loading: false,
		loadingSearch: false,
		countries: null,
		currentCountry: null,
		adults: 2,
		children: [],
		date: {
			dateFrom: format(addDays(new Date(), 4), 'yyyy-MM-dd'),
			dateTo: format(addDays(new Date(), 8), 'yyyy-MM-dd'),
			daysCount: 4
		},
		cities: null,
		searchResultCountry: null,
		currentCity: null,
		excursions: null,
		samoExcursions: null,
		categories: null,
		checkedActionsType: [],
		actionType: [],
		excursionType: [],
		searchComplete: false,
		actions: {
			postCountries: async () => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/${SERVICE_ID}/Countries`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: {}
					})
					set({ countries: res.data })
					set({
						currentCountry: get().currentCountry || get().countries[1]?.id
					})
				} catch (e) {
					console.error('error in postCountries', e)
				}
			},
			postCities: async id => {
				try {
					const res = await axios({
						url: `${BASE_URL}/Services/${SERVICE_ID}/Cities`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: {
							country: {
								id: id
							}
						}
					})

					set({ cities: res.data })
					set({ currentCity: get().cities[1]?.id })
				} catch (e) {
					console.error('error in postCities', e)
				}
			},
			postSearch: async () => {
				set({ loadingSearch: true })
				set({ actionType: [] })
				set({ checkedActionsType: [] })
				set({ excursionType: [] })
				set({ checkedExcursionType: [] })
				set({ searchComplete: false })

				set({
					searchResultCountry: get().countries?.find(
						country => country.id === get().currentCountry
					)
				})
				const data = {
					country: {
						id: get().currentCountry
					},
					departureCityId: get().currentCity,
					adults: get().adults,
					childrens: get().children,
					startDate: get().date.dateFrom + "T00:00:00Z",
					endDate: get().date.dateTo + "T00:00:00Z",
					currencyId: 1,
					transferSourceId: 0,
					transferTargetId: 0,
					attributes: [],
					isIndividual: false
				}

				try {
					const res = await axios({
						url: `${BASE_URL}/Services/${SERVICE_ID}/Search`,
						headers: {
							'X-Tui-Clientid': 'b2c:ru'
						},
						method: 'POST',
						data: data
					})
					set({ samoExcursions: res.data })
					const excursions = [...res.data]
					const ids = []

					excursions.forEach(element => {
						if (!ids.includes(element.id)) {
							ids.push(element.id)
						}
					})

					get().actions.getAdditionalAirportService(ids)
					set({ searchComplete: true })
				} catch (e) {
					console.error('error in postSearch', e)
					set({ loadingSearch: false })
				}
			},
			getAdditionalAirportService: async ids => {
				const data = { array_ids: ids }
				try {
					const res = await geoApiInstance().post(
						'/additional-service/slice',
						data
					)
					set({ additionalAirportService: res?.data })
					const newExcursions = get().samoExcursions.map(item => {
						const existGeoContent = res?.data.find(
							el => el.service_id === item.id
						)
						let newItem = { ...item, other_params: null, image_url: null }

						if (existGeoContent) {
							newItem.other_params = existGeoContent.other_params
							newItem.image_url = existGeoContent.other_params.image_url
						}

						return newItem
					})

					const serviceFilters = res?.data?.map(item => item?.other_params)

					const filters = serviceFilters?.reduce(
						(acc, cur) => {
							if (isEmpty(cur)) {
								return acc
							}
							return {
								...acc,
								yachtType: [
									...new Set(
										acc.yachtType?.concat(
											cur?.yachtType?.map(item => item?.name)
										)
									)
								],
								forWhomYacht: [
									...new Set(
										acc.forWhomYacht?.concat(
											cur?.forWhomYacht?.map(item => item?.name)
										)
									)
								]
							}
						},
						{
							yachtType: [],
							forWhomYacht: []
						}
					)

					set({ excursionType: filters.yachtType?.filter(i => i) })
					set({ actionsType: filters.forWhomYacht?.filter(i => i) })
					set({ excursions: newExcursions?.filter(i => i?.other_params) })
				} catch (error) {
					console.error(error)
				} finally {
					set({ loadingSearch: false })
				}
			},
			setDate: value => {
				const dateFrom = format(value[0], 'yyyy-MM-dd')
				const dateTo = format(value[1], 'yyyy-MM-dd')
				const daysCount = differenceInDays(value[1], value[0]) + 1
				set({
					date: {
						dateFrom: dateFrom,
						dateTo: dateTo,
						daysCount: daysCount
					}
				})
			},
			setCountry: id => {
				set({ currentCountry: id })
			},
			setCity: id => {
				set({ currentCity: id })
			},
			setAdult: id => {
				set({ adults: id })
			},
			adultInc: () => {
				set({ adults: get().adults + 1 })
			},
			adultDec: () => {
				set({ adults: get().adults - 1 })
			},
			setChild: child => {
				set({ children: child })
			},
			childInc: age => {
				set({ children: [...get().children, age] })
			},
			childDec: ind => {
				set({ children: get().children.filter((_, index) => index !== ind) })
			},
			clearFilters: () => {
				set({ checkedExcursionType: [] })
				set({ checkedActionsType: [] })
			},
			changeActionType: type => {
				if (!get().checkedActionsType.some(name => name === type)) {
					set({
						checkedActionsType: [...get().checkedActionsType, type]
					})
				} else {
					set({
						checkedActionsType: [
							...get().checkedActionsType.filter(name => name !== type)
						]
					})
				}
			},
			changeExcursionType: type => {
				if (!get().checkedExcursionType.some(name => name === type)) {
					set({
						checkedExcursionType: [...get().checkedExcursionType, type]
					})
				} else {
					set({
						checkedExcursionType: [
							...get().checkedExcursionType.filter(name => name !== type)
						]
					})
				}
			},
			addToBasket: async payload => {
				const partpass = JSON.parse(localStorage.getItem('partPass'))
				const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

				let service = {
					id: null,
					travellers: null,
					serviceCategory: null,
					imgUrl: null,
					name: null,
					startDate: null,
					endDate: null,
					price: null,
					adults: null,
					children: null,
					contentFilters: null,
					number: null,

					currency: {
						currencyId: null,
						currencyAlias: null
					},
					city: {
						id: null,
						name: null
					},
					country: {
						id: null,
						name: null
					}
				}
				service.id = payload.serviceKey
				service.travellers = []
				service.serviceCategory = payload?.category?.id
				service.serviceCategoryName = payload?.category?.code
				service.imgUrl = payload.imgUrl
				service.name = payload.name
				service.date = payload.startDate
				service.startDate = payload.startDate
				service.endDate = payload.endDate
				service.price = payload.price.value
				service.currency.currencyId = payload.price.currencyId
				service.currency.currencyAlias = payload.price.currencyAlias
				service.adults = get().adults
				service.children = get().children?.length
				service.city.id = get().currentCity
				service.city.name = payload.city.name
				service.country.id = get().currentCountry
				service.country.name = payload.country.name
				service.contentFilters = payload?.contentFilters
				service.number = payload.id

				try {
					await axios({
						url: `${BASE_URL}/Draft/createOrUpdate`,
						method: 'POST',
						data: {
							draftType: 'FunSun',
							partPassId: Number(partpass),
							userId: agentInfo?.agents.id,
							partnerId: agentInfo?.agents.id,
							customerInfo: null,
							services: [service],
							excursions: [],
							transfers: [],
							insurance: []
						},
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token
								}`
						}
					})
					//ios fix
					setTimeout(() => {
						window.open('/booking/yachtrent', '_blank');
					})
				} catch (e) {
					console.log(e)
				}
			}
		}
	}))
)

export default useYachtRentServicesStore
