import { useState } from 'react'
import ArrowDownSmall from '../../../../asset/icons/ArrowDownSmall'

import './style.scss'

function Spoiler({ content }) {
    const [open, setOpen] = useState(content.isOpen)

    const toggle = () => {
        setOpen(!open)
    }

    return <div className="spoiler-container">
        <div className='spoiler-head' onClick={toggle}>
            <div className='spoiler-title'>
                {content?.name}
                <div className={`spoiler-arrow ${open ? 'rotate' : ''}`}>
                    <ArrowDownSmall />
                </div>
            </div>
        </div>
        {open && <div className='spoiler-content'>
            {content?.content.first && <div dangerouslySetInnerHTML={{ __html: content?.content.first }} />}
            {content?.content.second && <div dangerouslySetInnerHTML={{ __html: content?.content.second }} />}
        </div>}
    </div>
}

export default Spoiler