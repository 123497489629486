import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { ExpandMore } from '@material-ui/icons'
import './style.css'
import { Box } from '@material-ui/core'
import useAuthStore from '../../Auth/store'
import { MenuItem } from '@mui/material'
import StyledSelect from './components/styledSelct'
import Details from './components/Details'
import { isEmpty } from 'lodash'
import { FormInput } from '../AgentInfo/AllAgents/components/AddAgentPopup'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		whiteSpace: 'nowrap',
		height: '58px',
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		color: '#2E2E32',
		borderColor: '#DBE0E5',
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '26px',
		backgroundColor: '#EDF1FE',
		padding: '8px 24px'
	},
	[`&.${tableCellClasses.body}`]: {
		height: '56px',
		whiteSpace: 'nowrap',
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		fontSize: 18,
		borderColor: '#DBE0E5',
		fontWeight: 400,
		color: '#2E2E32',
		padding: '7px 24px',
		lineHeight: '24px'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderRadius: '8px',
	'&:nth-of-type(even)': {
		backgroundColor: '#F6F8FA'
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

export default function Bonuses() {
	// const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	const bonusesItog = useAuthStore(({ bonusesItog }) => bonusesItog)
	const agents = useAuthStore(({ agentsUsers }) =>
		!isEmpty(agentsUsers.filter(agent => agent.isAdministrator))
			? agentsUsers.filter(agent => agent.isAdministrator)
			: agentsUsers
	)
	const bonuses = useAuthStore(({ bonuses }) => bonuses)
	const [agentName, setAgentName] = React.useState(agents[0]?.fullName || '')

	const changeHandler = event => {
		setAgentName(event.target.value)
	}
	const actions = useAuthStore(({ actions }) => actions)

	React.useEffect(() => {
		if (agentName) {
			const id = agents?.find(agent => agent.fullName === agentName).partpassId
			actions.getAgentBonuses(id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agentName])

	// console.log('details', details)
	// console.log('bonuses', bonuses)

	return (
		<Box sx={{ p: '16px 20px 32px 20px' }}>
			<div className='bonuses-title'>Бонусы</div>
			<Box sx={{ pt: 3, pb: 3 }}>
				<FormInput
					SelectProps={{
						IconComponent: ExpandMore,
						MenuProps: {
							PaperProps: {
								className: 'myCustomList-actions',
								sx: {
									'& .MuiMenuItem-root': {
										fontFamily: 'Open Sans'
									}
								}
							},
							style: {
								maxHeight: '288px',
								top: 1
							}
						}
					}}
					select
					onChange={changeHandler}
					fullWidth
					value={agentName}
					variant='outlined'>
					{agents?.map(item => (
						<MenuItem value={item.fullName} key={item.partpassId}>
							{item.fullName}
						</MenuItem>
					))}
				</FormInput>
			</Box>
			<TableContainer
				component={Paper}
				sx={{
					borderRadius: '16px',
					overflowY: 'hidden',
					maxWidth: 860,
					'&::-webkit-scrollbar': {
						height: 4
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#D4D9DE',
						borderRadius: 2
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#4872F2',
						borderRadius: 2
					}
				}}>
				<Table aria-label='customized table'>
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ paddingLeft: '16px' }}>
								Валюта
							</StyledTableCell>
							<StyledTableCell align='right'>Начислено</StyledTableCell>
							<StyledTableCell align='right'>Израсходовано</StyledTableCell>
							<StyledTableCell align='right'>Сгорело</StyledTableCell>
							<StyledTableCell align='right'>Осталось</StyledTableCell>
							<StyledTableCell align='right'>Доступно</StyledTableCell>
							<StyledTableCell align='right' style={{ paddingRight: '16px' }}>
								Ожидают начисления
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{bonuses?.map((row, i) => (
							<StyledTableRow key={i}>
								<StyledTableCell
									component='th'
									scope='row'
									style={{ paddingLeft: '16px' }}>
									{row.currencyName}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{row.accruedBonuses}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{row.usedBonuses}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{row.burnedBonuses}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{row.totalBonuses}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{row.availableBonuses}
								</StyledTableCell>
								<StyledTableCell align='left' style={{ paddingRight: '16px' }}>
									{row.notAvailableBonuses}
								</StyledTableCell>
							</StyledTableRow>
						))}
						<StyledTableRow>
							<StyledTableCell
								component='th'
								scope='row'
								style={{ paddingLeft: '16px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Box sx={{ pr: 1 }}>Итого в валюте</Box>
									<Box sx={{ pl: 1 }}>
										<StyledSelect />
									</Box>
								</div>
							</StyledTableCell>
							<StyledTableCell align='left'>
								{bonusesItog?.accruedBonuses}
							</StyledTableCell>
							<StyledTableCell align='left'>
								{bonusesItog?.usedBonuses}
							</StyledTableCell>
							<StyledTableCell align='left'>
								{bonusesItog?.burnedBonuses}
							</StyledTableCell>
							<StyledTableCell align='left'>
								{bonusesItog?.totalBonuses}
							</StyledTableCell>
							<StyledTableCell align='left'>
								{bonusesItog?.availableBonuses}
							</StyledTableCell>
							<StyledTableCell align='left' style={{ paddingRight: '16px' }}>
								{bonusesItog?.notAvailableBonuses}
							</StyledTableCell>
						</StyledTableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Details />
		</Box>
	)
}
