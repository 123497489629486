import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {isEmpty} from "lodash";

const CountryChangeModal = ({ onClose, withSearch = true, title, changeInput, inputValue, textInput, useFilter, filtered, handleChange }) => {
	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => (document.body.style.overflow = '')
	})

	return (
		<div className='CountryChangeModal-bg'>
			<div className='CountryChangeModal-content'>
				<div className="v-departure__body-container">
					{withSearch && <div className={'chosen-search'}>
						<ArrowBackIosIcon className='btn-back' onClick={() => onClose(false)} />
						<input
							placeholder={title}
							type="text"
							onChange={changeInput}
							value={inputValue}
							autoComplete={'off'}
							className={'chosen-search__field'}
							ref={textInput}
						/>
					</div>}
					<div className="v-departure__body">
						{useFilter && isEmpty(filtered) && <div className={'empty-search'}>Нет совпадений</div>}
						{filtered.map((item) => {
							return (
								<div
									key={item.id || item}
									className={`v-departure__elem v-text-16`}
									onClick={() => handleChange(item.id || item)}
								>
									{item.name || item}
								</div>
							)
						})}
					</div>
				</div>		 					
			</div>
		</div>
	)
}

export default CountryChangeModal
