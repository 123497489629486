import { isEmpty } from 'lodash'
import CountryItem from './CountryItem'
import { TOP_COUNTRIES } from './data'

import './style.scss'


function TopCountry({ country }) {
    if (isEmpty(country)) return null

    let countriesView = country.filter(item => TOP_COUNTRIES.includes(item.name))
    // .sort(function (a, b) {
    //     var indexA = TOP_COUNTRIES.indexOf(a.name);
    //     var indexB = TOP_COUNTRIES.indexOf(b.name);
    //     return indexA - indexB;
    // }
    // )

    return <div>
        <h2>ТОП направлений</h2>
        <div className='country-grid'>
            {countriesView.map(item =>
                <CountryItem key={item.id} content={item} />
            )}
        </div>
    </div>
}

export default TopCountry