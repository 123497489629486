import React from 'react';
import {SvgIcon} from "@mui/material";

const Square = (props) => {
	return (
		<SvgIcon {...props} >
			<path d="M4 21C3.71667 21 3.47933 20.904 3.288 20.712C3.096 20.5207 3 20.2833 3 20V14C3 13.7167 3.096 13.479 3.288 13.287C3.47933 13.0957 3.71667 13 4 13C4.28333 13 4.521 13.0957 4.713 13.287C4.90433 13.479 5 13.7167 5 14V17.6L17.6 5H14C13.7167 5 13.4793 4.904 13.288 4.712C13.096 4.52067 13 4.28333 13 4C13 3.71667 13.096 3.479 13.288 3.287C13.4793 3.09567 13.7167 3 14 3H20C20.2833 3 20.5207 3.09567 20.712 3.287C20.904 3.479 21 3.71667 21 4V10C21 10.2833 20.904 10.5207 20.712 10.712C20.5207 10.904 20.2833 11 20 11C19.7167 11 19.4793 10.904 19.288 10.712C19.096 10.5207 19 10.2833 19 10V6.4L6.4 19H10C10.2833 19 10.521 19.096 10.713 19.288C10.9043 19.4793 11 19.7167 11 20C11 20.2833 10.9043 20.5207 10.713 20.712C10.521 20.904 10.2833 21 10 21H4Z" fill="#2E2E32"/>
		</SvgIcon>
	);
};

export default Square;