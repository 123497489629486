import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"

const StyledNavLink = styled(NavLink)`
  font-size: 14px;
  line-height: 16px;
  margin: 0 10px;
  text-decoration: none;
  color: #b9b9b9;
  &.active {
    color: #737373;
  }
`

const Nav = styled.nav`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #b9b9b9;
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const Breadcrumbs = React.memo(({ items, ...props }) => (
  <Nav {...props}>
    {items.map(({ name, path }, key) => {
      const link = (
        <StyledNavLink key={key} exact={key !== items.length - 1} to={path}>
          {name}
        </StyledNavLink>
      )
      return key === items.length - 1 ? (
        link
      ) : (
        <React.Fragment key={`${key}_1`}>{link}/</React.Fragment>
      )
    })}
  </Nav>
))

export default Breadcrumbs
