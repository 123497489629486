import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { Close } from '@material-ui/icons'
import { Box, DialogContent, DialogTitle } from '@mui/material'
import styled from 'styled-components'
import MobileTabsBlock from './MobileTabsBlock'
import { BootstrapDialog } from '../HotelPopup'

const Title = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 24px;
	line-height: 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export default function MobileAboutHotelPopup({ open, handleClose }) {
	return (
		<React.Fragment>
			<BootstrapDialog
				fullScreen
				open={open}
				onClose={handleClose}
				scroll='paper'
				TransitionComponent={Transition}>
				<DialogTitle
					id='scroll-dialog-title'
					sx={{ pl: 2, pr: 2, pb: 1, pt: 1 }}>
					<Title>
						<Box>Об отеле</Box>
						<IconButton aria-label='close' size='small' onClick={handleClose}>
							<Close />
						</IconButton>
					</Title>
				</DialogTitle>
				<DialogContent sx={{ p: 0 }}>
					<MobileTabsBlock close={handleClose} />
				</DialogContent>
			</BootstrapDialog>
		</React.Fragment>
	)
}
