import moment from 'moment';
import greyPlane from "./Queue/assets/media/old-segment-plane.svg"
import bluePlane from "./Queue/assets/media/new-segment-plane.svg"

export function isNotificationValid(args) {
    const x = args.notification;
    return (x.type === "ChangeSegmentDates" && x?.segmentEvents?.length !== 0) || (x.type === "ChangesPassengers" && x?.passengersEvents?.length !== 0);
} 

export function transformProps(args) {
    const notification = args.notification;
    const type = notification.type;
    const isChangeSegment = type === 'ChangeSegmentDates';

    const {segmentEvents,...rest} = notification;
    return {
        ...rest,
        type,
        appealLink: `/cabinet/tickets/${notification.supportTicketNumber}`,
        newSegments: (segmentEvents || []).map((x) => ({
            ...extractFields(x,'newValue'),
            icon: bluePlane,
        })),
        prevSegments: (segmentEvents || []).map((x) => ({
            ...extractFields(x,'oldValue'),
            icon: greyPlane,
        })),
        // newPassengers: 
    }
}

function extractFields(data, valueType) {


    const departureDate = data.fields.find(
        (x) => x.description === 'Дата отправления'
    )
    const arrivalDate = data.fields.find(
        (x) => x.description === 'Дата прибытия'
    )
    const company = data.fields.find(
        (x) => x.description === 'Маркетинговый перевозчик'
    )
    const departureCityRes = data.fields.find(
        (x) => x.description === 'Город отправления'
    )

    const arrivalCityRes = data.fields.find(
        (x) => x.description === 'Город назначения'
    )

    // const departureCityCodeRes = data.fields.find(
    //     (x) => x.description === 'Город отправления'
    // )
    // const arrivalCityCodeRes = data.fields.find(
    //     (x) => x.description === 'Город назначения'
    // )
    const flightNumber = data.fields.find(x => x.description === "Номер рейса")

    const departureCityStr = departureCityRes ? departureCityRes[valueType] : data.departureCity.name
    const arrivalCityStr = arrivalCityRes ? arrivalCityRes[valueType] : data.arrivalCity.name
    const departureCityCode = departureCityStr.match(/\((.*?)\)/)[1];
    const arrivalCityCode = arrivalCityStr.match(/\((.*?)\)/)[1];
    const departureCityName = departureCityStr.match(/(.*?)\(/)[1];
    const arrivalCityName = arrivalCityStr.match(/(.*?)\(/)[1];


    return {
        departureDate: departureDate
            ? departureDate[valueType]
            : null,
        arrivalDate: arrivalDate
            ? arrivalDate[valueType]
            : null,
        departureCity : {
            name: departureCityName,
            code: departureCityCode,
        },
        arrivalCity: {
            name: arrivalCityName,
            code: arrivalCityCode,
        },
        company: company ? company[valueType] : null,
        flightNumber: flightNumber ? flightNumber[valueType] : null,
    }
}


function extractPassengerFields(data, valueType) {
    const sex = data.fields.find(y => y.description === "Пол")
    const birthday = data.fields.find(y => y.description === "Дата рождения")
    const documentNumber = data.fields.find(y => y.description === "№ документа")
    const documentType = data.fields.find(y => y.description === "Тип документа")
    const citizen = data.fields.find(y => y.description === "Гражданство")
    const ticketStatus = data.fields.find(y => y.description === "Статус билета")
    const ticketNumber = data.fields.find(y => y.description === "Номер билета")
    const lastName = data.fields.find(y => y.description === "Фамилия")
    const name = data.fields.find(y => y.description === "Имя")
    const patranomic = data.fields.find(y => y.description === "Статус билета")



    return {
        sex: extractProp(sex,valueType),
        birthdayDate: extractProp(birthday,valueType),
        citizen: extractProp(citizen,valueType),
        lastName: extractProp(lastName,valueType),
        name: extractProp(name,valueType),
        patranomic: extractProp(patranomic,valueType),
        document: {
            number: extractProp(documentNumber,valueType),
            type: extractProp(documentType,valueType),
        },
        ticket: {
            status: extractProp(ticketStatus,valueType),
            number: extractProp(ticketNumber,valueType),
        }
    }
}

function extractProp(prop, valueType) {
    if(!prop || prop[valueType] === '') {
        return null;
    }
    return prop[valueType];
}