import { createAction, createReducer } from "redux-act"

export const getAgentFeesInfoRequest = createAction(
  "agents/getAgentFeesInfoRequest"
)
export const getAgentFeesInfoSuccess = createAction(
  "agents/getAgentFeesInfoSuccess"
)
export const getAgentTaxesSuccess = createAction("agents/getAgentTaxesSuccess")
export const getAgentFeesInfoFailure = createAction(
  "agents/getAgentFeesInfoFailure"
)

export const updateTaxesRequest = createAction("agents/updateTaxesRequest")
export const updateTaxesSuccess = createAction("agents/updateTaxesSuccess")
export const updateTaxesFailure = createAction("agents/updateTaxesFailure")

export const createFeeRequest = createAction("agents/createFeeRequest")
export const createFeeSuccess = createAction("agents/createFeeSuccess")
export const createFeeFailure = createAction("agents/createFeeFailure")

export const editFeesRequest = createAction("agents/editFeesRequest")
export const editFeesSuccess = createAction("agents/createFeeSuccess")

export const deleteFeeRequest = createAction("agents/deleteFeeRequest")

const taxes = createReducer({}, null)
taxes.on(getAgentTaxesSuccess, (_, payload) => payload)

const fees = createReducer({}, null)
fees.on(getAgentFeesInfoSuccess, (_, payload) => payload)

const taxesLoading = createReducer({}, false)
taxesLoading.on(updateTaxesRequest, () => true)
taxesLoading.on(updateTaxesSuccess, () => false)
taxesLoading.on(updateTaxesFailure, () => false)

const feesLoading = createReducer({}, false)
feesLoading.on(editFeesRequest, () => true)
feesLoading.on(createFeeRequest, () => true)
feesLoading.on(createFeeSuccess, () => false)
feesLoading.on(editFeesSuccess, () => false)

const feesAgentsReducer = {
  taxes,
  fees,
  taxesLoading,
  feesLoading
}

export default feesAgentsReducer
