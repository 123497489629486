import React from 'react';
import { InfoBlock } from '../ui';
import Text, { Header } from '../ui/Typography';
import InputMask from 'react-input-mask';
import { useFormikContext } from 'formik';
import { Field, Checkbox } from '../ui/form';
import {
  addictionalServicesSelector,
  AddictionalServiceTypes,
} from '../../store/modules/booking';
import { getUserDataState } from '../../store/modules/user';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Body = styled.div`
  margin-top: 15px;
`;

const Description = styled(Text)`
  margin-bottom: 32px;
`;

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  @media (max-width: 1024px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
`;

const Wrapper = styled(InfoBlock)`
  padding: 24px;
`;

const NotificationWrapper = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 19px;
  margin-top: 20px;
  margin-bottom: 20px;

  & > span {
    display: block;
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const NotificationText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`;

const NewField = styled(Field)`
  &:disabled {
    background: #f1f1f2;
  }
`;

const Contacts = () => {
  const { handleChange, handleBlur, errors, values, touched,setFieldValue} = useFormikContext();
  const userData = useSelector(getUserDataState);

  React.useEffect(() => {
    setFieldValue('phone', userData?.phone ? userData?.phone : '');
    setFieldValue('email', userData?.email ? userData?.email : '');
    setFieldValue('name', userData?.name ? userData?.name : '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const items = useSelector(addictionalServicesSelector);

  const isSmsService = items.find(
    (x) => x.type === AddictionalServiceTypes.SMS_Service && x.checked
  );

  // const [popup, setPopup] = React.useState(false);

  return (
    <Wrapper id="contacts">
      <Header size="h4">Контактная информация</Header>
      <Body>
        <Description color="black" size="normal">
          Мы отправим билет на почту, вышлем уведомления об изменениях в рейсе
          или в случае других экстренных ситуаций
        </Description>
        <Content>
          <Field
            name="name"
            style={{ width: '100%' }}
            label="Имя"
            error={errors.name && touched.name ? errors.name : undefined}
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
          />
          <InputMask
            mask="+7 (999) 999-99-99"
            onChange={handleChange}
            autoComplete="anyrandomstring4"
            value={values.phone}
            onBlur={handleBlur}
            maskChar={null}
          >
            <Field
              label="Телефон"
              name="phone"
              error={errors.phone && touched.phone ? errors.phone : undefined}
              style={{ width: '100%' }}
            />
          </InputMask>
          <Field
            label="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : undefined}
            style={{ width: '100%' }}
          />
        </Content>
      </Body>
      {isSmsService && (
        <>
          <NotificationWrapper>
            <NotificationText>
              Вы добавили услугу <strong>SMS-информирование</strong>.{' '}
              Уведомления по заявке автоматически будут присылаться на указанный
              номер телефона.
            </NotificationText>
            <NotificationText>
              Если вам необходимо получать уведомления на другой номер, укажите
              его в поле ниже.
            </NotificationText>
          </NotificationWrapper>
          <Content>
            <NewField
              name="nameTwo"
              style={{ width: '100%' }}
              label="Имя"
              error={
                errors.nameTwo && touched.nameTwo ? errors.nameTwo : undefined
              }
              onChange={handleChange}
              disabled={values.sameContactInfo}
              value={!values.sameContactInfo ? values.nameTwo : values.name}
              onBlur={handleBlur}
            />
            <InputMask
              mask="+7 (999) 999-99-99"
              onChange={handleChange}
              autoComplete="anyrandomstring9639"
              value={!values.sameContactInfo ? values.phoneTwo : values.phone}
              disabled={values.sameContactInfo}
              onBlur={handleBlur}
              maskChar={null}
            >
              <NewField
                label="Телефон"
                name="phoneTwo"
                error={
                  errors.phoneTwo && touched.phoneTwo
                    ? errors.phoneTwo
                    : undefined
                }
                style={{ width: '100%' }}
              />
            </InputMask>
          </Content>
          <div>
            <Checkbox
              type="squar"
              label="Использовать те же контактные данные"
              name="sameContactInfo"
              checked={values.sameContactInfo}
              onChange={(e) => {
                setFieldValue('sameContactInfo',e.target.checked);
              }}
            />
          </div>
        </>
      )}
      {/* <SmsNotificationCoditions
        open={popup}
        onClose={() => {
          setPopup(false);
        }}
      /> */}
    </Wrapper>
  );
};

export default Contacts;
