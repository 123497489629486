import React from 'react';
import {isMobile} from 'react-device-detect';

import './style.scss'

const Youtube = ({ content, title, id }) => {

	const videoId = content.video.split('v=')[1]
	console.log(content)

	return (
		<>
			<h2 className={'video-youtube__header'} id={id}>{title}</h2>
			<div className={'video-youtube'} style={{backgroundImage: `url(${isMobile ? content.img_mobile : content.img_desktop})`}}>
				<div className={'video-youtube__title'}><h2>{content.title}</h2></div>
				{content.video && <iframe
					src={`https://www.youtube.com/embed/${videoId}`}
					title={'Youtube'}
					className={'video'}
				/>}
				<div className={'video-youtube__description'}><div>{content.description}</div></div>
			</div>
		</>

	);
};

export default Youtube;