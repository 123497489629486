import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import useBookingStore from '../../../../store'
import './style.css'
import { Grid } from '@mui/material'

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

export default function FaresTabs({
	setRequestId,
	setFareId,
	setPrice,
	setBaggage
}) {
	const fares = useBookingStore(({ fares }) => fares)
	const [value, setValue] = React.useState(
		fares?.findIndex(fare => fare.checked) || 0
	)

	const handleChange = (event, newValue) => {
		setValue(newValue)
		setRequestId(fares[newValue].requestId)
		setFareId(fares[newValue].id)
		setBaggage(fares[newValue].baggage.split(' '))
		if (isNaN(Number(fares[newValue].price))) {
			setPrice(0)
		} else {
			setPrice(fares[newValue].price)
		}
	}

	React.useEffect(() => {
		return () => {
			setPrice(0)
			setBaggage([])
		}
	}, [setPrice, setBaggage])
	// console.log(fares, 'fares')

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					className='tabs'
					value={value}
					onChange={handleChange}
					variant='scrollable'
					scrollButtons
					allowScrollButtonsMobile
					aria-label='scrollable force tabs example'>
					{fares?.map((fare, index) => (
						<Tab
							style={{ padding: '10px' }}
							label={fare.fareName}
							{...a11yProps(fare.id)}
							key={fare.id}
						/>
					))}
				</Tabs>
			</Box>
			{fares?.map((fare, i) => (
				<TabPanel value={value} index={i} key={fare.id}>
					<Grid container spacing={0.5}>
						<Grid item xs={4} style={{ fontWeight: 'bold' }}>
							Доплата
						</Grid>
						<Grid item xs={8}>
							{fare.price <= 0 && !fares?.findIndex(fare => fare.checked)
								? 'Без доплат'
								: fare.price + ' ' + fare.currency}
							{/* {fare.price + ' ' + fare.currency} */}
						</Grid>
						<Grid item xs={4} style={{ fontWeight: 'bold' }}>
							Возврат
						</Grid>
						<Grid item xs={8}>
							{fare.refund ? fare.refund : 'Нет информации'}
						</Grid>
						<Grid item xs={4} style={{ fontWeight: 'bold' }}>
							Места
						</Grid>
						<Grid item xs={8}>
							{fare.seats}
						</Grid>
						<Grid item xs={4} style={{ fontWeight: 'bold' }}>
							Багаж
						</Grid>
						<Grid item xs={8}>
							{fare.baggage.split('\r').join('\n')}
						</Grid>
						<Grid item xs={4} style={{ fontWeight: 'bold' }}>
							Сервис
						</Grid>
						<Grid item xs={8}>
							{fare.service ? fare.service : 'Нет информации'}
						</Grid>
					</Grid>
				</TabPanel>
			))}
		</Box>
	)
}
