import React from 'react';

import './style.scss'
import MobileFiltersBlock from "../SearchResultsWithFilters/components/MobileFiltersBlock";
import useYachtRentServicesStore from "../../store";
import { format, getMonth } from "date-fns";
import { ru } from "date-fns/locale";
import { uniqBy } from "lodash";
import MobileSearchFormPopup from "./MobileSearchFormPopup";

const MobileSearchForm = () => {

    const date = useYachtRentServicesStore(({ date }) => date)
    const excursions = useYachtRentServicesStore(({ excursions }) => excursions)
    const countries = useYachtRentServicesStore(({ countries }) => countries)
    const currentCountry = useYachtRentServicesStore(({ currentCountry }) => currentCountry)
    const cities = useYachtRentServicesStore(({ cities }) => cities)
    const currentCity = useYachtRentServicesStore(({ currentCity }) => currentCity)

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const dateFormatted = () => {
        if (date?.dateFrom === date?.dateTo) {
            return format(new Date(date?.dateFrom), 'dd MMM', { locale: ru })
        }
        if (getMonth(new Date(date?.dateFrom)) === getMonth(new Date(date?.dateTo))) {
            return `${format(new Date(date?.dateFrom), 'dd', { locale: ru })} - ${format(new Date(date?.dateTo), 'dd MMM', { locale: ru })}`
        }
        return `${format(new Date(date?.dateFrom), 'dd MMM', { locale: ru })} - ${format(new Date(date?.dateTo), 'dd MMM', { locale: ru })}`
    }

    const destinations = () => {
        // const country = uniqBy(excursions, 'city.id')[0]?.country.name
        // const city = uniqBy(excursions, 'city.id').length === 1 ? uniqBy(excursions, 'city.id')[0]?.city.name : ''
        const country = countries.find(item => item.id === currentCountry)?.name
        const city = cities.find(item => item.id === currentCity)?.name || ''
        if (country) {
            return `${country}${city ? ', ' + city : ''}`
        }
    }

    if (!destinations()) return

    return (
        <div className={'hotel-service-port hotel-service-search-form-wrapper mobile-search'}>
            <div className={'mobile-search__form'}>
                <div className={'mobile-search__form-country'} onClick={handleClickOpen}>
                    {destinations()}
                </div>
                <div className={'mobile-search__form-date'}>
                    {dateFormatted()}
                </div>
            </div>
            <MobileFiltersBlock />
            <MobileSearchFormPopup open={open} setOpen={setOpen} />
        </div>
    );
};

export default MobileSearchForm;
