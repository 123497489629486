import React from "react"
import styled from "styled-components"
import Text from '../../../ui/Typography'
import {Button} from '../../../ui/Button'
import moment from "moment"
import formatPrice from "../../../../functions/formatPrice"
import {NavLink} from 'react-router-dom'
import PrimaryButton from "../../../ui/PrimaryButton"
import {styled as MUIstyled} from '@mui/material/styles'


const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 22px;
  margin-left: 5px;
  width: 20px;
  vertical-align: bottom;

  & > svg {
    position: absolute;
  }
`

const NotificationContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`

const RightSide = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 8px;
  }
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

const DescriptionsList = styled.div`
  margin-top: 15px;
  & > * {
    display: block;
    margin-bottom: 7px;
  }
  &>: last-child {
    margin-bottom: 0;
  }
`

const DescriptionItem = styled(Text)`
  color: #3c3c3c;
  & > strong {
    font-weight: 600;
  }
`

const DesktopButton = MUIstyled(PrimaryButton)(()=>({
  padding: '8px 20px',
}))

// const DesktopButton = styled(Button).attrs({
//   type: "primary",
//   htmlType: "button"
// })`
//   @media (max-width: 767px) {
//     display: none;
//   }
// `

const MobileButton = styled(Button).attrs({
  type: "primary",
  htmlType: "button"
})`
  display: none;
  width: 100%;
  margin-top: 25px;
  @media (max-width: 767px) {
    display: block;
  }
`

const LinkButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  outline: none;
  background: transparent;
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  text-decoration: underline;
  margin-top: 15px;
  &:hover,
  &:focus {
    color: #7597fe;
  }
`

const WellIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#21A038" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1 7.0304L9.17298 17.8232L2.5005 12.0004L4.28326 10.0298L9.14876 14.2758L18.2916 5.24524L20.1 7.0304Z"
      fill="#ECFFE8"
    />
  </svg>
)

const BadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM17.781 7.281C17.9218 7.14017 18.0009 6.94916 18.0009 6.75C18.0009 6.55084 17.9218 6.35983 17.781 6.219C17.6402 6.07817 17.4492 5.99905 17.25 5.99905C17.0508 5.99905 16.8598 6.07817 16.719 6.219L12 10.9395L7.281 6.219C7.21127 6.14927 7.12848 6.09395 7.03738 6.05621C6.94627 6.01848 6.84862 5.99905 6.75 5.99905C6.65138 5.99905 6.55373 6.01848 6.46262 6.05621C6.37152 6.09395 6.28873 6.14927 6.219 6.219C6.14927 6.28873 6.09395 6.37152 6.05621 6.46262C6.01848 6.55373 5.99905 6.65138 5.99905 6.75C5.99905 6.84862 6.01848 6.94627 6.05621 7.03738C6.09395 7.12848 6.14927 7.21127 6.219 7.281L10.9395 12L6.219 16.719C6.07817 16.8598 5.99905 17.0508 5.99905 17.25C5.99905 17.4492 6.07817 17.6402 6.219 17.781C6.35983 17.9218 6.55084 18.0009 6.75 18.0009C6.94916 18.0009 7.14017 17.9218 7.281 17.781L12 13.0605L16.719 17.781C16.7887 17.8507 16.8715 17.906 16.9626 17.9438C17.0537 17.9815 17.1514 18.0009 17.25 18.0009C17.3486 18.0009 17.4463 17.9815 17.5374 17.9438C17.6285 17.906 17.7113 17.8507 17.781 17.781C17.8507 17.7113 17.906 17.6285 17.9438 17.5374C17.9815 17.4463 18.0009 17.3486 18.0009 17.25C18.0009 17.1514 17.9815 17.0537 17.9438 16.9626C17.906 16.8715 17.8507 16.7887 17.781 16.719L13.0605 12L17.781 7.281Z"
      fill="#3C3C3C"
    />
  </svg>
)
const Suggestion = styled(Text)`
a  {
  color: #4872F2;
  text-decoration: underline;
}
`

const SecondRow = styled(Text)`
  strong {
    font-weight: 600;
  }
`;

const TitleBlock = styled.div`
  ${SecondRow} {
    margin-top: 12px;
    display: block;
  }
`;

const RefundRouter = (props) => {

  const onPay = () => {
    // OrdersManager.payOrder({
    //   OrderNumber: data.orderNumber,
    //   SupportTicketCalculationId: data.id
    // })
    //   .then(data => {
    //     if (data.paymentURL) {
    //       window.history.pushState({}, "Обращение № 1", window.location.href)
    //       window.location.replace(data.paymentURL)
    //     }
    //   })
    //   .catch(() => {
    //     setStep(STEPS.FAILURE)
    //   })
  }
  const stillUtc = moment.utc(props.timelimit).toDate()
  const dateTo = moment(stillUtc)
    .local()
    .format("DD MMMM HH:mm")

  const colors = getColors(props.status.code || "")
  const isButton = props.status.code === "WaitingForApproval"
  const isAddictionalInfo =
  props.status.code === "WaitingForApproval"
  const isDetailButton = props.status.code === "ApprovedByClient"
  const total = props.supportTicketCalculationItems?.reduce(
    (acc, x) => acc + x?.supportTicketCalculationParameter?.refundAmount,
    0
  )

  return (
    <>
      <NotificationContainer
        backgroundColor={colors.background}
        borderColor={colors.border}
      >
        <Head>
          <RightSide>
            <TitleBlock>
              <TitleWrapper>
                <Title>
                  {getTitle(props.status.code, props.subject?.toLowerCase(),props)}
                </Title>
                {getIcon(props.status.code)}
              </TitleWrapper>
              {props.status.code === 'ApprovedByClient' && <SecondRow>
                Сумма возврата: <strong>{formatPrice(total)}</strong>
              </SecondRow>}
            </TitleBlock>
            {isAddictionalInfo && (
              <DescriptionsList>
                <DescriptionItem>
                  Сумма возврата:{" "}
                  <strong>{formatPrice(total)}</strong>
                </DescriptionItem>
                {props.timelimit && (
                  <DescriptionItem>
                    Согласовать до: <strong>{dateTo}</strong>
                  </DescriptionItem>
                )}
              </DescriptionsList>
            )}
          </RightSide>
          {isButton && (
            <DesktopButton
                onClick={() => {
                    if(props.status.code === "CanceledByClient" || props.status.code === "Paid") {
                        return null;
                    } else if (props.status.code === "WaitingForApproval") {
                        props.onCalculationShow();
                    } else {
                        props.onPay();
                    }
                }}
                type="button"
            >
              {getButtonText(props.status.code)}
            </DesktopButton>
          )}
        </Head>
        <Suggestion>
          {getSuggestionText(props.status.code, props.subject?.toLowerCase(),props)}
        </Suggestion>
        {isDetailButton && (
          <LinkButton
            onClick={props.onCalculationShow}
          >
            Открыть детали расчета
          </LinkButton>
        )}
      </NotificationContainer>
      {isButton && (
        <MobileButton
        //   onClick={
        //     props.status.code === "CanceledByClient" ||
        //     props.status.code === "Paid"
        //       ? undefined
        //       : props.status.code === "WaitingForApproval"
        //       ? () => setStep(STEPS.CALCULATE)
        //       : onPay
        //   }
        >
          {getButtonText(props.status.code)}
        </MobileButton>
      )}
    </>
  )
}

function getSuggestionText(status, subject,data) {
  switch (status) {
    case "WaitingForApproval":
      return (
        <>
          Вам направлен расчет на {subject}, откройте детали расчета для
          согласования.
        </>
      )
    case "ApprovedByClient":
      return <>По заявке №<NavLink to={`/cabinet/orders/${data.orderNumber}`}>{data.orderNumber}</NavLink> произведен возврат. Денежные средства поступят на ваш счет в течении 3х рабочих дней. Перейдите в детали заявки, чтобы ознакомиться с информацией.</>
    case "Refunded":
      return (
        <>
          По данной заявке произведен {subject} . Перейдите в детали заявки,
          чтобы ознакомиться с изменениями.
        </>
      )
    default:
      return (
        <>
          Вы отклонили расчёт на возврат по заявке №<NavLink to={`/cabinet/orders/${data.orderNumber}`}>{data.orderNumber}</NavLink>.
          Пожалуйста, обратитесь в чат к операционисту для создания нового расчёта.
        </>
      )
  }
}

function getColors(status) {
  switch (status) {
    case "WaitingForApproval":
      return { background: "#EDF1FE", border: "#4872F2" }
    case "Paid":
    case "ApprovedByClient":
    case "Refunded":
      return { background: "#ECFFE8", border: "#21A038" }
    default:
      return { background: "#FFE2E3", border: "#F64C50" }
  }
}

function getTitle(status, subject,data) {
  switch (status) {
    case "WaitingForApproval":
      return `Расчет на ${subject} №${data.number} от ${moment(data.createdDate).format('DD.MM.YYYY')}`
    case "ApprovedByClient":
      return `Одобрен расчет на ${subject} №${data.number} от ${moment(data.createdDate).format('DD.MM.YYYY')}`
    case "Refunded":
      return `Расчет на ${subject} завершён`
    default:
      return `Отклонен расчет на ${subject} №${data.number} от ${moment(data.createdDate).format('DD.MM.YYYY')}`
  }
}

function getIcon(status) {
  switch (status) {
    case "WaitingForApproval":
      return null
    case "Refunded":
    case "ApprovedByClient":
    case "Paid":
      return (
        <IconContainer>
          <WellIcon />
        </IconContainer>
      )
    default:
      return (
        <IconContainer>
          <BadIcon />
        </IconContainer>
      )
  }
}

function getButtonText(status) {
  switch (status) {
    case "WaitingForApproval":
      return "Детали расчета"
    case "ApprovedByClient":
      return "Оплатить счет"
    default:
      return ""
  }
}

export default RefundRouter
