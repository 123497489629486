// import { useState } from 'react'
import BtnArrow from '../../../../../../asset/icons/lp/excursionsFilters/BtnArrow'
import "./style.scss"
import { format, addDays } from 'date-fns';

// const MAX_CARD_COUNT = 6

function FiltersNewViewHotelServices({ filters, button }) {
    // const [showMore, setShow] = useState(false)

    const todayDate = new Date()
    const tomorrowDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate() + 1
    )
    // const startDate = new Date(
    //     todayDate.getFullYear(),
    //     todayDate.getMonth(),
    //     todayDate.getDate() + 1
    // )
    // const endDate = new Date(
    //     startDate.getFullYear(),
    //     startDate.getMonth(),
    //     startDate.getDate() + 7
    // )
    //TODO: уточнить насчет ограничения на количество фильтров, сейчас закоментил обрезку до MAX_CARD_COUNT

    const lists = filters?.filter(item => item.city && item.country && item.excursionType)

    return <div className='filters-new-view-hotel-services'>
            {button?.text && <div
                className='filters-new-view-hotel-services__show-more-btn'
                // onClick={() => setShow(!showMore)}
                style={{ color: button?.textColor, borderColor: button?.borderColor }}
            >
                {button?.text}
            </div>}
        <div className='filters-new-view-hotel-services__container'>

            {lists
                // ?.slice(0, showMore ? lists.length : MAX_CARD_COUNT)
                ?.map((item, index) => {
                    const startDay = format(addDays(new Date(tomorrowDate), item?.daysTomorrow ? Number(item?.daysTomorrow) : 0), 'yyyy-MM-dd')
                    const endDay = format(addDays(new Date(startDay), item?.daysAfterTomorrow ? Number(item?.daysAfterTomorrow) : 0), 'yyyy-MM-dd')

                    const link = `/searchhotelservices?countryId=${item?.country?.ebgNodeId}&departureCityId=${item?.city?.ebgNodeId}&startDate=${startDay}&endDate=${endDay}&adults=1&currencyId=1&excursionType=${item?.excursionType?.id}`
                    return <FilterCard
                        key={filters.excursionType}
                        img={item.imgUrl}
                        text={item.textPlate}
                        name={item.changedFilterName || item?.excursionType?.name}
                        link={link}
                    />
                })}
        </div>
        {/* {lists?.length > MAX_CARD_COUNT &&
            <div
                className='filters-new-view-hotel-services__show-more-btn'
                onClick={() => setShow(!showMore)}
                style={{ color: button?.textColor, borderColor: button?.borderColor }}
            >
                {button?.text ? button?.text : showMore ? "Свернуть" : "Смотреть все"}
            </div>} */}
    </div>
}

function FilterCard({ img, text, name, link }) {

    return <div className='filters-new-view-hotel-services__card'>
        <a href={link} target="_blank" rel="noreferrer"><div className='filters-new-view-hotel-services__card__img' style={{ backgroundImage: `url(${img})` }}></div></a>
        <div className='filters-new-view-hotel-services__card__bottom'>
            <div>
                <div className='filters-new-view-hotel-services__card__name'>{name}</div>
                <div className='filters-new-view-hotel-services__card__text'>{text}</div>
            </div>
            <a href={link} target="_blank" rel="noreferrer"><div className='btn-arrow'>
                <BtnArrow />
            </div></a>
        </div>
    </div>
}

export default FiltersNewViewHotelServices