import React from "react";
import {Input as BaseInput} from "@mui/base/Input/Input";
import {styled} from "@mui/system";

const SearchInput = React.forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

const InputElement = styled('input')(
  ({ theme }) => `
  width: 370px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 20px;
  border-radius: 4px;
  color: #1C2025;
  background: '#FFFFFF';
  border: 1px solid #DAE2ED;
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.05);

  &:hover {
    border-color: #4872F2;
  }

  &:focus {
    border-color: #3399FF;
    box-shadow: 0 0 0 3px #B6DAFF;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }`,
);

export default SearchInput
