import * as React from 'react'
import useHotelDescriptionStore from '../../store'
import HotelSliderCopy from './HotelSlider'
import StarsBlockCopy from './StarsBlock'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import HotelTags from '../HotelTags'
import HotelIncludesCopy from './HotelIncludes'
import { Box } from '@material-ui/core'
import RoomsCopy from './Rooms'
import VerticalTabsCopy from './HotelPopup/VerticalTabs'

export default function ComponentToPrint() {
	const hotel = useHotelDescriptionStore(({ hotel }) => hotel)
	const standardizedName = useHotelDescriptionStore(
		({ standardizedName }) => standardizedName
	)
	if (!hotel) return null
	return (
		<Box sx={{ p: 1, m: 1 }}>
			<div style={{display: 'flex', gap: '20px', marginBottom: '20px'}} className='hotel_info-block'>
				<div className='hotel_slider-wrapper'>
					{hotel?.images && (
						<HotelSliderCopy images={hotel?.images} id={hotel?.id} />
					)}
				</div>
				<div className='hotel_info-right'>
					<div className='hotel_stars-share'>
						<div>
							{hotel?.hotelCategory?.name && (
								<StarsBlockCopy
									count={Number(hotel?.hotelCategory?.name.replace('*', ''))}
								/>
							)}
						</div>{' '}
					</div>
					<div>
						<h2>{standardizedName}</h2>
					</div>
					{hotel?.address && (
						<div className='hotel_location'>
							<LocationOnIcon htmlColor={'#4872f2'} /> {hotel?.address}
						</div>
					)}
					<HotelTags />
					<div className='hotel_includes'>
						<HotelIncludesCopy />
					</div>
				</div>
			</div>
			<RoomsCopy />
			<VerticalTabsCopy />
		</Box>
	)
}
