import { isEmpty } from 'lodash'
import useGeoStore from '../../store'
import { encodeQueryString } from '../../../../functions/sequelize'
import { formatISO } from 'date-fns';
import StarsBlock from './../../../Hotel/HotelDescription/StarsBlock/index';

function HotelCard({ hotel }) {
    const arrivalCountries = useGeoStore(({ arrivalCountries }) => arrivalCountries)

    if (!hotel || !hotel.urlName) {
        return null
    }

    const handleClick = () => {
        if (isEmpty(arrivalCountries)) return null
        const country = arrivalCountries.find((item) => item.name === hotel.country)
        const currentDate = new Date()
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)

        const searchParam = {
            departureCityId: '274286',
            arrivalCountryId: country?.id,
            adults: '2',
            minStartDate: formatISO(date, { representation: 'date' }),
            maxStartDate: formatISO(date, { representation: 'date' }),
            minNightsCount: '7',
            maxNightsCount: '7',
            hotelIds: [hotel?.ebgNodeId],
        }

        const link = `/search_tour?` + encodeQueryString(searchParam)
        window.location.href = window.origin + link
    }

    return <div className="hotel__card" onClick={handleClick}>
        <div className="hotel__img-wrapper">
            <img className="hotel__img" src={hotel?.photo} alt=''
                onError={(e) => {
                    e.target.style.visibility = 'hidden';
                }}
            />
            {hotel.badge && <div className="img-bage">{hotel.badge}</div>}
        </div>
        <div className="hotel__content">
            <div className="hotel__content-country">{hotel?.breadcrumbs}</div>
            <div className="hotel__content-name">{hotel?.localizedName}</div>
            <StarsBlock count={Number(hotel?.hotelCategory?.name?.replace('*', ''))} />
        </div>

    </div>
}

export default HotelCard