import { useState } from 'react'
import ShareIcon from '../../../../asset/icons/Share-icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ShareLink from '../../../../asset/icons/Share-link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './style.scss'

function CopyLink({ link }) {
    const [copy, setCopy] = useState(false);
    const [isHover, onHover] = useState(false)


    const handleClick = () => {
        setTimeout(() => {
            setCopy(false);
            onHover(false);
        }, 5000);

    }

    return <div
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        style={{ cursor: 'pointer' }}  
    >
        <ShareIcon style={{ fill: '#B3B9C0' }} />
        <CopyToClipboard text={link} onCopy={() => setCopy(true)}>
            <div onClick={handleClick}  className={isHover ? "tooltip-share active" : 'tooltip-share'}>{copy ? <><CheckCircleIcon style={{ fill: '#008000' }} /> Ссылка скопирована </> : <><ShareLink />   Скопировать ссылку</>}</div>    
        </CopyToClipboard>    
    </div>
}
export default CopyLink