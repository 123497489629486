import { useState, useEffect, useRef } from 'react';
import useClaimsStore from '../../../store'
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import { Text, Row } from '../../ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { ReactComponent as SendIcon } from '../../../../../../asset/images/cabinet/send.svg'
import { ReactComponent as PaperClip } from '../../../../../../asset/images/cabinet/paperclip.svg'
import { isEmpty, sortBy } from 'lodash';
import { FileList } from './File';

const Icon = styled.div`
${({ open }) => {
        return `
    ${open ? `transform: unset;` : `transform: rotate(180deg); `}
    transition: 200ms;
    `
    }}	
`

const MessageContainer = styled.div`
	border: 1px solid rgba(219, 224, 229, 1);
	background: rgba(255, 255, 255, 1);
	border-radius: 16px;
	padding: 24px;
`

const ChatHeader = styled.div`
    margin-top: 24px;
    border-bottom: 1px solid rgba(219, 224, 229, 1);
    padding-bottom: 18px;
    margin-bottom: 24px;
`

const ChatMessage = styled.div`
${({ isUser }) => {
        return `
    ${isUser ? `background: rgba(237, 241, 254, 1);` : `background: rgba(239, 242, 246, 1);`}
    padding: 16px;
    margin-bottom: 8px; 
    border-radius: 8px;
    `
    }}	
`

const SendButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 56px;
    height: 56px;
    
    fill: rgba(72, 114, 242, 1);

    border: 1px solid rgba(72, 114, 242, 1);
    border-radius: 8px;
    
    cursor: pointer;
`

const IconFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const StyledInput = styled.input`
  font-family: Open Sans;
  color: #3c3c3c;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  padding: 17px 0px;
  text-align: left;
  border: none;
  width: 100%;
  &::placeholder {
    color: #B9B9B9;
  }
`;

const StyledInputWrap = styled.div`
  border: 1px solid rgba(212, 217, 222, 1);
  border-radius: 8px;
  display:flex;
  gap: 10px;
  width: 695px;
`

const ChatMessageFlex = styled.div`
  display:flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  margin-bottom: 24px;
  max-height: 378px;
  padding-right: 8px;
  margin-right: -8px;
`

const MessageWrapper = styled.div`
${({ isUser }) => {
        return `${isUser ? `align-self: flex-end;` : `align-self: flex-start;`}
    max-width: 90%;
    min-width: 40%;
`
    }}	
`

const FileCount = styled.div`
    position: absolute;
    top: 2;
    right: -6;
    background-color: #668aff;
    padding: 0 4px;
    color: white;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    &:before {
        content: attr(text);
    }

    &:hover{
        background-color: #ff1e1e;
    }

    &:hover::before {
        content: attr(hover-text);
    }
`

const MessageHeader = ({ children, isOpen, onOpen }) => {
    const handleClick = () => {
        onOpen(!isOpen)
    }
    return <Row>
        <div>
            {children}
        </div>
        <Icon onClick={handleClick} open={isOpen}>
            <ExpandMoreIcon isActive={isOpen} style={{ transform: 'rotate(180deg)' }} />
        </Icon>
    </Row>
}


function MessagesBlock({ message, type, claimId }) {
    const [isOpen, setOpen] = useState(false)
    const [isRead, setIsRead] = useState(message.filter(item => !item.isRead && item.isIncoming))
    const [messageText, setMessageText] = useState('')
    const actions = useClaimsStore(({ actions }) => actions)
    const [files, setFiles] = useState([])
    const ref = useRef(null)
    const refEndItem = useRef(null)
    const handleSendMessage = async () => {
        if (!(messageText && messageText.trim().length > 0) && isEmpty(files)) {
            return
        }

        const data = {
            ClaimId: claimId,
            Body: `${messageText}`,
            TypeId: message[0]?.typeId,
        }

        if (message[0]?.parentMessageId) {
            data.ParentMessageId = message[0]?.parentMessageId
        }

        const formData = Object.entries(data).reduce((fd, n) => (fd.append(...n), fd), new FormData())
        if (!isEmpty(files)) {
            files.forEach((file) => {
                formData.append('Attachments', file);
            });
        }

        await actions.sendMessage(formData)
        actions.getMessages(claimId)
        setMessageText('')
        setFiles([])
    }

    useEffect(() => {
        if (!isEmpty(isRead) && isOpen) {
            actions.markRead(isRead.map(item => item.messageId))
            actions.getMessages(claimId)
            setIsRead([])
        }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen])

    useEffect(() => {
        if (isOpen && refEndItem.current) {
            refEndItem.current.scrollTop = refEndItem.current?.scrollHeight;
        }

    }, [message, isOpen])

    const handleFileButton = () => {
        if (files.length === 2) {
            return
        }
        if (ref.current) {
            ref.current.click()
        }
    }

    const addAttachments = (e) => {
        const files = e.target.files || []
        if (files.length > 0) {
            setFiles(s => [...s, ...files])
        }
    }

    const removeFile = () => {
        setFiles([])
    }

    const sortedMessage = sortBy(message, 'createDate')

    return <MessageContainer>
        <MessageHeader isOpen={isOpen} onOpen={(open) => setOpen(open)} style={{ transform: 'rotate(0deg)' }}>
            <Text bold size={18} height={26}>{type} <span style={{ color: 'rgb(72, 114, 242)' }}>{!isEmpty(isRead) && "(" + isRead.length + ')'}</span></Text>
        </MessageHeader>
        <Collapse in={isOpen}>
            <ChatHeader>
                <Text>{message[0]?.author}</Text>
            </ChatHeader>
            <ChatMessageFlex ref={refEndItem}>
                {sortedMessage
                    .map((item, index) => {
                        if (!item.body && !item.filesCount) {
                            return null
                        }
                        return <MessageWrapper isUser={!item.isIncoming} key={item.messageId}>
                            {item.body && <ChatMessage isUser={!item.isIncoming}>
                                <Text>{item.body}</Text>
                            </ChatMessage>}
                            {item.filesCount !== 0 && <FileList messageId={item.messageId} />}
                            <Text style={!item.isIncoming ? { textAlign: 'end' } : { textAlign: 'start' }} color={"rgba(126, 131, 137, 1)"}>{item?.createDate && format(new Date(item?.createDate), "dd MMM  HH:mm", { locale: ru })}</Text>
                        </MessageWrapper>
                    }
                    )}
            </ChatMessageFlex>
            {type !== 'Информация' && <div style={{ display: "flex", gap: '8px' }}>
                <StyledInputWrap>
                    <IconFlex style={{ marginLeft: "10px", position: 'relative' }}>
                        <input
                            ref={ref}
                            type="file"
                            style={{ display: "none" }}
                            onChange={addAttachments}
                        />
                        {!isEmpty(files) && <FileCount onClick={removeFile} text={files.length} hover-text="X" />}
                        <PaperClip onClick={handleFileButton} />
                    </IconFlex>
                    <StyledInput value={messageText} placeholder='Напишите ответ' onChange={(e) => setMessageText(e.target.value)} />
                </StyledInputWrap>
                <SendButton onClick={handleSendMessage}>
                    <SendIcon />
                </SendButton>
            </div>}
        </Collapse>
    </MessageContainer>
}

export default MessagesBlock