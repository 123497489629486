import React, {useState} from 'react';
import ArrowThink from "../../../../../asset/icons/Arrow-think";
import PassengersItem from "./PassengersItem";
import Collapse from '@mui/material/Collapse';

const Passengers = ({details}) => {

	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<div className={'avia-order-block__item'}>
				<div className={'header'}>
					<div>Пассажиры ({details?.orderPositions[0]?.passengers?.length})</div>
				</div>
				<div className={`avia-order-icon ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}><ArrowThink /></div>
			</div>
			<Collapse in={isOpen}>
				<div style={{padding: '0 15px 15px'}}>
					<div className="orders-header">
						<div className="full-name">ФИО</div>
						<div className="gender">Пол</div>
						<div className="b-date">Дата рождения</div>
						<div className="document">Документ</div>
					</div>
					{details?.orderPositions[0]?.passengers?.map((el, idx) =>
						<PassengersItem key={idx} data={el}/>
					)}
				</div>
			</Collapse>
		</>
	);
};

export default Passengers;
