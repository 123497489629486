import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import useAirportStore from '../../store'
import CountryField from "../../../../components/Services/CountryField";
import DateInputRange from "../../../../components/Services/DateInputRange";
import TouristsField from "../TouristsField";
import { useEffect } from "react";

import './style.scss'

const Title = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 24px;
	line-height: 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const SaveButton = styled(Button)(({ theme }) => ({
    width: '138px',
    boxShadow: 'none',
    background: '#FFE100',
    borderRadius: '8px',
    color: '#2e2e32',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    height: '46px',
    lineHeight: '22px',
    padding: '12px 24px',
    textAlign: 'center',
    textDecoration: 'none',
    fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(','),
    '&:hover': {
        background: '#FFE733',
        boxShadow: 'none'
    },
    '&:focus': {
        boxShadow: 'none'
    },
    '&:active': {
        outline: '0px'
    }
}))

export const CancelButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    width: '138px',
    borderColor: '#4872f2',
    borderRadius: '8px',
    color: '#4872f2',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    height: '46px',
    lineHeight: '22px',
    padding: '12px 24px',
    textAlign: 'center',
    textDecoration: 'none',
    fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(',')
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const MobileSearchFormPopup = ({ open, setOpen }) => {
    const actions = useAirportStore(({ actions }) => actions)
    const countries = useAirportStore(({ countries }) => countries)
    const currentCountry = useAirportStore(({ currentCountry }) => currentCountry)
    const cities = useAirportStore(({ cities }) => cities)
    const currentCity = useAirportStore(({ currentCity }) => currentCity)
    const adults = useAirportStore(({ adults }) => adults)
    const children = useAirportStore(({ children }) => children)
    const date = useAirportStore(({ date }) => date)

    useEffect(() => {
        actions.postCountries()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (currentCountry) {
            actions.postCities(currentCountry)
        }
        // eslint-disable-next-line
    }, [currentCountry])

    const handleChangeCountry = (id) => {
        actions.setCountry(id)
    }

    const handleChangeDate = (value) => {
        actions.setDate(value)
    }

    const handleChangeTourists = (value, id) => {
        console.log(value, 'handleChangeTourists value')
        console.log(id, 'handleChangeDate id')
    }

    const handleSearch = () => {
        actions.postSearch()
        handleClose()
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Dialog
                className='MobileSearchFormPopup airport-page'
                fullScreen
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                TransitionComponent={Transition}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle
                    id='scroll-dialog-title'
                    sx={{ pl: 2, pr: 2, pb: 2, pt: 1 }}>
                    <Title>
                        <Box>Поиск</Box>
                        <IconButton aria-label='close' size='small' onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Title>
                </DialogTitle>
                <DialogContent sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <Box className={`airport-search-form mobile-popup`}>
                        <CountryField
                            className={'airport-countries'}
                            lists={countries}
                            value={currentCountry}
                            title={'Страна поездки'}
                            onChange={handleChangeCountry}
                        />
                        <CountryField
                            className={'airport-city'}
                            lists={cities}
                            value={currentCity}
                            title={'Откуда'}
                            onChange={(id) => actions.setCity(id)}
                        />
                        <DateInputRange
                            label="Даты поездки"
                            value={[new Date(date.dateFrom), new Date(date.dateTo)]}
                            onChange={handleChangeDate}
                        />
                        <TouristsField
                            className={'tourists'}
                            onChange={handleChangeTourists}
                            adults={adults}
                            children={children}
                            title={'Туристы'}
                        />
                    </Box>
                </DialogContent>
                <DialogContent sx={{ p: 0 }}>
                    <Box className={'airport-search-buttonBlock'}>
                        <Button
                            onClick={handleSearch}
                            variant={'contained'}
                            className={'search-button'}
                        // disabled={loading}
                        >
                            Найти
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default MobileSearchFormPopup;
