import React from "react"
import Text, { Header } from "../../ui/Typography"
import styled from "styled-components"
import {Button} from '../../ui'
import { useDispatch } from "react-redux"
import { addAppealPurify } from "../../../store/modules/appeals"
import { refreshOrder } from "../../../store/modules/orders"
import checkIconSrc from "../../../asset/images/avia/appeal-forms/round-cross.svg"
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../../asset/images/avia/ui/close.svg'

const Container = styled.div`
  width: 509px;
  @media (max-width: 767px) {
    display:flex;
    flex-direction:column;
  }
`

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Check = styled.img`
  margin-left: 8px;
  width: 32px;
  height: 32px;
`


const Description = styled(Text)`
  color: #3C3C3C;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 24px 

  a > strong {
    color: #4872F2;
    font-weight: 600;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  & > :first-child {
    margin-right: 20px;
  }
`;

const StyledPopup = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 8px;
    padding: 40px;
    padding-bottom: 30px;
    width: 569px;
    box-sizing: border-box;
    position: relative;
    display: block;
  
    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
      padding: 25px 21px;
      height: auto;
      min-height: 100%;
      display: flex;
      // align-items: center;
    }
  }
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  & > svg {
    fill: #B3B9C0;
  }
`;

const CreateFormSuccess = (props) => {
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(addAppealPurify());
  }

  React.useEffect(() => {
    dispatch(refreshOrder());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <StyledPopup {...props}>
      <CloseButton
        aria-label="close"
        onClick={props.handleClose}
      >
          <Icon/>
      </CloseButton>
      <Container>
        <Head>
          <Header size="h3">Обращение не создано</Header>
          <Check src={checkIconSrc} alt="" />
        </Head>
        <Description>
          При создании обращения произошла ошибка. Пожалуйста, попробуйте еще раз
        </Description>
        <Buttons>
          <Button onClick={handleReset} htmlType='button'>Попробовать еще раз</Button>
          <Button onClick={props.onClose} type='outlined' htmlType='button' >Вернуться к заявке</Button>
        </Buttons>
      </Container>
    </StyledPopup>  
 
  )
}

export default CreateFormSuccess
