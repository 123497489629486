import React from "react"
import Item from "./Item"

const List = React.memo(({ items, itemWrapper, separator }) => (
  <>
    {items.map((props, key) =>
      React.cloneElement(itemWrapper, {
        children: (
          <Item
            separator={separator && key !== items.length - 1}
            passengerNo={key + 1}
            {...props}
          />
        )
      })
    )}
  </>
))

export default List
