import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { isEmpty } from 'lodash'
import useActionsStore from '../store'
import { CircularProgress, styled, useMediaQuery } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'
import '../style.css'

export const CustomSelect = styled(Select)(() => ({
	'& fieldset': {
		borderRadius: '8px'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	}
}))

export default function RegionSelect({
	actionsList,
	region,
	handleChangeRegion,
	country
}) {
	const actions = useActionsStore(({ actions }) => actions)
	const allRegions = useActionsStore(({ allRegions }) => allRegions)
	const loading = useActionsStore(({ loading }) => loading)
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	React.useEffect(() => {
		if (country !== 'Все') actions.getRegions(country)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country])
	const regions = React.useMemo(() => {
		const list =
			country !== 'Все'
				? actionsList
						?.filter(
							item =>
								!isEmpty(
									item.country.filter(item =>
										item.localizedName.includes(country)
									)
								)
						)
						.map(item => item?.regions.map(item => item.localizedName))
				: actionsList?.map(item =>
						item?.regions.map(item => item.localizedName)
				  )
		const regionsList = []
		for (let i = 0; i < list?.length; i++) {
			if (!isEmpty(list[i])) {
				for (let j = 0; j < list[i].length; j++) {
					regionsList.push(list[i][j])
				}
			}
		}
		return country !== 'Все'
			? [...new Set(regionsList)].filter(item => allRegions?.includes(item))
			: [...new Set(regionsList)]
	}, [actionsList, country, allRegions])
	// console.log(regions, 'regionsList')

	return (
		<Box sx={{ minWidth: 290, pl: isSmallScreen ? 1 : 0 }}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Выберите регион</InputLabel>
				<CustomSelect
					defaultValue='Open Sans'
					MenuProps={{
						PaperProps: {
							className: 'myCustomList-actions',
							sx: {
								'& .MuiMenuItem-root': {
									fontFamily: 'Open Sans'
								}
							}
						},
						style: {
							maxHeight: isSmallScreen ? '292px' : '346px',
							maxWidth: 290,
							fontFamily: 'Open Sans'
						}
					}}
					IconComponent={ExpandMore}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={region}
					label='Выберите страну'
					onChange={handleChangeRegion}>
					<MenuItem value={'Все'}>
						<em>Все</em>
					</MenuItem>
					{!loading ? (
						regions?.map((item, i) => (
							<MenuItem value={item} key={i}>
								{item}
							</MenuItem>
						))
					) : (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
				</CustomSelect>
			</FormControl>
		</Box>
	)
}
