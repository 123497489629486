import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
	createTheme,
	IconButton,
	Radio,
	Tooltip,
	withStyles
} from '@material-ui/core'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined'
import '../style.css'
import useBookingStore from '../../../store'
import {
	useAviaCompanyStore,
	useBackwardFlightStore,
	useForwardFlightStore
} from './store'
import { isEmpty } from 'lodash'
import { Box, Grid, TableSortLabel, ThemeProvider } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { DoubleArrowOutlined } from '@material-ui/icons'
import FaresPopup from './FaresPopup/FaresPopup'

const StyledTooltip = withStyles({
	tooltipPlacementTop: {
		margin: '3px 0'
	}
})(Tooltip)

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		backgroundColor: '#4872F2',
		color: '#ECF5FB',
		padding: 6,
		fontSize: 16,
		fontWeight: 'bold'
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: [
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		fontSize: 14,
		padding: 4,
		color: '#3C3C3C'
	}
}))

const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(4n+3),&:nth-of-type(4n+4)': {
		backgroundColor: '#FBF9EE'
	},
	// hide last border
	'&:last-child td, &:last-child th, nth-last-child(-n+2) td': {
		border: 0
	}
}))

const theme = createTheme({
	components: {
		MuiTableSortLabel: {
			styleOverrides: {
				root: {
					color: '#ECF5FB',
					'&:hover': {
						color: '#e6d196'
					},
					'&.Mui-active': {
						'&&': {
							color: '#e6d196',

							'& * ': {
								color: '#e6d196'
							}
						}
					}
				},
				icon: {
					color: '#e6d196'
				}
			}
		}
	}
})

const DEFAULT_ORDER = 'asc'
const DEFAULT_ORDER_BY = 'surcharge'

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

// function descendingComparator(a, b, orderBy) {
// 	if (b[orderBy] < a[orderBy]) {
// 		return -1
// 	}
// 	if (b[orderBy] > a[orderBy]) {
// 		return 1
// 	}
// 	return 0
// }

function descendingComparator(a, b, orderBy) {
	if (orderBy === 'surcharge') {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		if (a[orderBy] === b[orderBy]) {
			if (a.duration > b.duration) {
				return -1
			}
			if (b.duration > a.duration) {
				return 1
			}
		}
		return 0
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

export default function AviaFlightsTable() {
	const setSelectedRegularFlightId = useBookingStore(
		state => state.actions.setSelectedRegularFlightId
	)
	const selectedFlightOptionId = useBookingStore(
		state => state.selectedFlightOptionId
	)
	const flights = useBookingStore(state => state.tour.flightOptions)
	const sortFlights = React.useMemo(() => {
		return flights.map(flight => ({
			...flight,
			duration: flight.forwardFlight.segments[0].duration
		}))
	}, [flights])

	const actions = useBookingStore(({ actions }) => actions)
	const [openFaresPopup, setOpenFaresPopup] = React.useState(false)

	const handleOpenFaresPopup = () => {
		setOpenFaresPopup(true)
	}
	const handleCloseFaresPopup = () => {
		actions.setFaresError(false)
		setOpenFaresPopup(false)
	}

	const handleChange = event => {
		setSelectedRegularFlightId(event.target.value)
	}
	const handleChangeFlight = event => {
		setSelectedRegularFlightId(event.target.id)
	}
	const clickHandler = async value => {
		setSelectedRegularFlightId(value)
		await actions.tourRecount()
		await actions.getFares()
		handleOpenFaresPopup()
	}
	const backwardFlightEnd = useBackwardFlightStore(
		state => state.backwardFlightEnd
	)
	const backwardFlightStart = useBackwardFlightStore(
		state => state.backwardFlightStart
	)
	const forwardFlightEnd = useForwardFlightStore(
		state => state.forwardFlightEnd
	)
	const forwardFlightStart = useForwardFlightStore(
		state => state.forwardFlightStart
	)
	const currentAviaCompany = useAviaCompanyStore(
		state => state.currentAviaCompany
	)
	const filterOptions = React.useMemo(() => {
		if (currentAviaCompany) {
			return sortFlights.filter(
				item =>
					item.backwardFlight.segments[0].startTime.slice(0, -3) >=
						backwardFlightStart &&
					item.backwardFlight.segments[0].startTime.slice(0, -3) <=
						backwardFlightEnd &&
					item.forwardFlight.segments[0].startTime.slice(0, -3) >=
						forwardFlightStart &&
					item.forwardFlight.segments[0].startTime.slice(0, -3) <=
						forwardFlightEnd &&
					item.forwardFlight.segments[0].aviacompany.id === currentAviaCompany
			)
		}
		return sortFlights.filter(
			item =>
				item.backwardFlight.segments[0].startTime.slice(0, -3) >=
					backwardFlightStart &&
				item.backwardFlight.segments[0].startTime.slice(0, -3) <=
					backwardFlightEnd &&
				item.forwardFlight.segments[0].startTime.slice(0, -3) >=
					forwardFlightStart &&
				item.forwardFlight.segments[0].startTime.slice(0, -3) <=
					forwardFlightEnd
		)
	}, [
		backwardFlightEnd,
		backwardFlightStart,
		sortFlights,
		forwardFlightEnd,
		forwardFlightStart,
		currentAviaCompany
	])
	const [order, setOrder] = React.useState(DEFAULT_ORDER)
	const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY)

	const [visibleRows, setVisibleRows] = React.useState(null)

	// console.log('render')

	React.useEffect(() => {
		const rowsOnMount = stableSort(
			filterOptions,
			getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
		)

		setVisibleRows(rowsOnMount)
	}, [filterOptions])

	const handleRequestSort = React.useCallback(
		(event, newOrderBy) => {
			const isAsc = orderBy === newOrderBy && order === 'asc'
			const toggledOrder = isAsc ? 'desc' : 'asc'
			setOrder(toggledOrder)
			setOrderBy(newOrderBy)

			const sortedRows = stableSort(
				filterOptions,
				getComparator(toggledOrder, newOrderBy)
			)

			setVisibleRows(sortedRows)
		},
		[order, orderBy, filterOptions]
	)
	const createSortHandler = newOrderBy => event => {
		handleRequestSort(event, newOrderBy)
	}

	const getYear = date => {
		return date.split('T')[0].split('-').reverse().join('.')
	}

	const startTimeItem = (item, index, arr, row) => {
		const year = getYear(item.startDateTime)
		if (index !== arr.length - 1) {
			return (
				<StyledTooltip
					key={index}
					title={`${item.startTime.slice(0, 5)} ${year} `}
					placement='top'>
					<span
						id={row.key}
						onClick={handleChangeFlight}>{`${item.startTime.slice(
						0,
						5
					)}\n`}</span>
				</StyledTooltip>
			)
		} else
			return (
				<StyledTooltip
					key={index}
					title={`${item.startTime.slice(0, 5)} ${year}`}
					placement='top'>
					<span id={row.key} onClick={handleChangeFlight}>
						{item.startTime.slice(0, 5)}
					</span>
				</StyledTooltip>
			)
	}
	const endTimeItem = (item, index, arr, row) => {
		const year = getYear(item.endDateTime)
		if (index !== arr.length - 1) {
			return (
				<StyledTooltip
					key={index}
					title={
						item.startDateTime.split('T')[0] === item.endDateTime.split('T')[0]
							? `${item.endTime.slice(0, 5)} ${year}`
							: `${item.endTime.slice(0, 5)}+1 ${year}`
					}
					placement='top'>
					<span id={row.key} onClick={handleChangeFlight}>
						{item.startDateTime.split('T')[0] === item.endDateTime.split('T')[0]
							? `${item.endTime.slice(0, 5)}\n`
							: `${item.endTime.slice(0, 5)}+1\n`}
					</span>
				</StyledTooltip>
			)
		} else
			return (
				<StyledTooltip
					key={index}
					title={
						item.startDateTime.split('T')[0] === item.endDateTime.split('T')[0]
							? `${item.endTime.slice(0, 5)} ${year}`
							: `${item.endTime.slice(0, 5)}+1 ${year}`
					}
					placement='top'>
					<span id={row.key} onClick={handleChangeFlight}>
						{item.startDateTime.split('T')[0] === item.endDateTime.split('T')[0]
							? item.endTime.slice(0, 5)
							: `${item.endTime.slice(0, 5)}+1`}
					</span>
				</StyledTooltip>
			)
	}
	const flightNumber = (item, index, arr, row) => {
		if (index !== arr.length - 1) {
			return (
				<StyledTooltip
					key={index}
					title={item?.aviacompany?.name}
					placement='top'>
					<span
						id={row.key}
						onClick={handleChangeFlight}>{`${item.flightNumber}\n`}</span>
				</StyledTooltip>
			)
		} else
			return (
				<StyledTooltip
					key={index}
					title={item?.aviacompany?.name}
					placement='top'>
					<span id={row.key} onClick={handleChangeFlight}>
						{item.flightNumber}
					</span>
				</StyledTooltip>
			)
	}

	return (
		<>
			{isEmpty(filterOptions) ? (
				<div className='no-flights no-filter'>Нет доступных перелетов</div>
			) : (
				<TableContainer
					component={Paper}
					style={{ maxHeight: 486, marginBottom: '20px' }}>
					<Table sx={{ minWidth: 700 }} stickyHeader aria-label='sticky table'>
						<ThemeProvider theme={theme}>
							<TableHead>
								<TableRow>
									<StyledTableCell
										colSpan={3}
										rowSpan={2}
										align='right'
										style={{ paddingRight: '10px' }}>
										Рейсы
									</StyledTableCell>
									<StyledTableCell
										rowSpan={2}
										align='center'
										sortDirection={orderBy === 'duration' ? order : false}>
										<TableSortLabel
											active={orderBy === 'duration'}
											direction={orderBy === 'duration' ? order : 'asc'}
											onClick={createSortHandler('duration')}>
											Время пути
											{orderBy === 'duration' ? (
												<Box component='span' sx={visuallyHidden}>
													{order === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</Box>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell
										colSpan={2}
										rowSpan={2}
										align='center'
										style={{ paddingRight: '0px' }}>
										<Grid container spacing={1}>
											<Grid
												item
												xs={12}
												style={{ borderBottom: '1px solid black' }}>
												Время
											</Grid>
											<Grid item xs={6} style={{ fontSize: '14px' }}>
												Вылета
											</Grid>
											<Grid item xs={6} style={{ fontSize: '14px' }}>
												Прибытия
											</Grid>
										</Grid>
									</StyledTableCell>
									<StyledTableCell
										colSpan={2}
										rowSpan={2}
										align='center'
										style={{ paddingLeft: '0px' }}>
										<Grid container spacing={1}>
											<Grid
												item
												xs={12}
												style={{ borderBottom: '1px solid black' }}>
												Аэропорт
											</Grid>
											<Grid item xs={6} style={{ fontSize: '14px' }}>
												Вылета
											</Grid>
											<Grid item xs={6} style={{ fontSize: '14px' }}>
												Прибытия
											</Grid>
										</Grid>
									</StyledTableCell>
									<StyledTableCell rowSpan={2} align='center'>
										Класс
									</StyledTableCell>
									<StyledTableCell
										rowSpan={2}
										align='center'
										sortDirection={orderBy === 'surcharge' ? order : false}>
										<TableSortLabel
											active={orderBy === 'surcharge'}
											direction={orderBy === 'surcharge' ? order : 'asc'}
											onClick={createSortHandler('surcharge')}>
											Доплата
											{orderBy === 'surcharge' ? (
												<Box component='span' sx={visuallyHidden}>
													{order === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</Box>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell rowSpan={2} align='center'></StyledTableCell>
									<StyledTableCell rowSpan={2} align='center'>
										Багаж
									</StyledTableCell>
								</TableRow>
							</TableHead>
						</ThemeProvider>
						<TableBody>
							{visibleRows
								? visibleRows?.map(row => (
										<React.Fragment key={row.key}>
											<StyledTableRow
												role='checkbox'
												style={{
													whiteSpace: 'pre-wrap'
												}}>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													rowSpan={2}
													align='center'
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}>
													<Radio
														color='primary'
														checked={selectedFlightOptionId === row.key}
														onChange={handleChange}
														value={row.key}
														name='radio-buttons'
													/>
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													style={{ paddingBottom: 0 }}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													<DoubleArrowOutlined
														className='no-rotate'
														id={row.key}
														onClick={handleChangeFlight}
													/>
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													style={{ padding: '2px' }}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.forwardFlight.segments[0].flightNumber} */}
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															return flightNumber(item, index, arr, row)
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{row.forwardFlight.segments[0].duration.slice(
														0,
														2
													) === '1.'
														? row.forwardFlight.segments[0].duration
																.slice(2, -3)
																.split(':')
																.map((item, i) => {
																	if (i === 0) {
																		return +item + 24
																	} else return item
																})
																.join(':')
														: row.forwardFlight.segments[0].duration.slice(
																0,
																-3
														  )}
													{/* {row.forwardFlight.segments[0].duration} */}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															return startTimeItem(item, index, arr, row)
														}
													)}
													{/* {row.forwardFlight.segments[0].startTime} */}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.forwardFlight.segments[0].endTime} */}
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															return endTimeItem(item, index, arr, row)
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {
														row.forwardFlight.segments[0].startWayPoint.port
															.name
													} */}
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.startWayPoint.port.name}\n`
															} else return item.startWayPoint.port.name
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.forwardFlight.segments[0].endWayPoint.port.name} */}
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.endWayPoint.port.name}\n`
															} else return item.endWayPoint.port.name
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.forwardFlight.segments[0].class.name} */}
													{row?.forwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.class.name}\n`
															} else return item.class.name
														}
													)}
												</StyledTableCell>
												{row.surcharge > 0 ? (
													<StyledTableCell
														sx={{ borderColor: '#888888' }}
														className={
															selectedFlightOptionId === row.key &&
															'checked-color'
														}
														align='center'
														rowSpan={2}
														id={row.key}
														onClick={handleChangeFlight}>
														<b>+{row.surcharge} ₽</b>
													</StyledTableCell>
												) : (
													<StyledTableCell
														sx={{ borderColor: '#888888' }}
														className={
															selectedFlightOptionId === row.key &&
															'checked-color'
														}
														align='center'
														rowSpan={2}
														id={row.key}
														onClick={handleChangeFlight}>
														<b>Без доплат</b>
													</StyledTableCell>
												)}
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													rowSpan={2}>
													<IconButton
														style={{ cursor: 'pointer' }}
														onClick={() => clickHandler(row.key)}
														id={row.key}
														value={row.key}
														aria-label='add to business center outlined'>
														<BusinessCenterOutlinedIcon />
													</IconButton>
												</StyledTableCell>
												<StyledTableCell
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{row.forwardFlight.segments[0].baggage.value === 1 ||
													row.forwardFlight.segments[0].baggage.value === 2 ? (
														row.forwardFlight.segments[0].baggage.value + 'PC'
													) : row.forwardFlight.segments[0].baggage.value >
													  2 ? (
														row.forwardFlight.segments[0].baggage.value + 'Kg'
													) : (
														<div id={row.key} onClick={handleChangeFlight}>
															нет
														</div>
													)}
												</StyledTableCell>
											</StyledTableRow>
											<StyledTableRow
												style={{
													whiteSpace: 'pre-wrap'
												}}>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													style={{ paddingBottom: 0 }}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													<DoubleArrowOutlined
														className='rotate'
														id={row.key}
														onClick={handleChangeFlight}
													/>
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													style={{ padding: '2px' }}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.backwardFlight.segments[0].flightNumber} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															return flightNumber(item, index, arr, row)
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{row.backwardFlight.segments[0].duration.slice(
														0,
														2
													) === '1.'
														? row.backwardFlight.segments[0].duration
																.slice(2, -3)
																.split(':')
																.map((item, i) => {
																	if (i === 0) {
																		return +item + 24
																	} else return item
																})
																.join(':')
														: row.backwardFlight.segments[0].duration.slice(
																0,
																-3
														  )}
													{/* {row.backwardFlight.segments[0].duration} */}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.backwardFlight.segments[0].startTime} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															return startTimeItem(item, index, arr, row)
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.backwardFlight.segments[0].endTime} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															return endTimeItem(item, index, arr, row)
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {
														row.backwardFlight.segments[0].startWayPoint.port
															.name
													} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.startWayPoint.port.name}\n`
															} else return item.startWayPoint.port.name
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.backwardFlight.segments[0].endWayPoint.port.name} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.endWayPoint.port.name}\n`
															} else return item.endWayPoint.port.name
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{/* {row.backwardFlight.segments[0].class.name} */}
													{row?.backwardFlight?.segments?.map(
														(item, index, arr) => {
															if (index !== arr.length - 1) {
																return `${item.class.name}\n`
															} else return item.class.name
														}
													)}
												</StyledTableCell>
												<StyledTableCell
													sx={{ borderColor: '#888888' }}
													className={
														selectedFlightOptionId === row.key &&
														'checked-color'
													}
													align='center'
													id={row.key}
													onClick={handleChangeFlight}>
													{row.backwardFlight.segments[0].baggage.value === 1 ||
													row.backwardFlight.segments[0].baggage.value === 2 ? (
														row.backwardFlight.segments[0].baggage.value + 'PC'
													) : row.backwardFlight.segments[0].baggage.value >
													  2 ? (
														row.backwardFlight.segments[0].baggage.value + 'Kg'
													) : (
														<div id={row.key} onClick={handleChangeFlight}>
															нет
														</div>
													)}
												</StyledTableCell>
											</StyledTableRow>
										</React.Fragment>
								  ))
								: null}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<FaresPopup open={openFaresPopup} handleClose={handleCloseFaresPopup} />
		</>
	)
}
