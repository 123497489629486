import React, { useState } from 'react';
import './style.scss';

function LandingDoing({ content, title }) {
    const { headText: subtitle, text, images } = content;

    // eslint-disable-next-line no-useless-escape
    const shouldTruncateText = text.replace(/(\<(\/?[^>]+)>)/g, '').replace(/&[^;]+;/g, '-').length > 650;

    const [isExpanded, setIsExpanded] = useState(false);
    const handleExpand = () => {
        setIsExpanded(true);
    };
    const truncatedText =
        shouldTruncateText && !isExpanded ? text.slice(0, 650) + '...' : text;

    return (
        <div id="description" className="doing_container">
            <div className="doing_article">
                <div className="images_wrapper">
                    {images.slice(0, 3).map((image, index) => (
                        <div key={index} className={`foto${index + 1}`}>
                            {image && <img src={image} alt="" />}
                        </div>
                    ))}
                </div>
                <div className='content_wrapper'>
                    {subtitle && <h4 className="doing_subtitle">{subtitle}</h4>}
                    <h2 className="doing_title">{title}</h2>
                    <div className="doing_text" dangerouslySetInnerHTML={{ __html: truncatedText }}></div>
                    {shouldTruncateText && !isExpanded && (
                        <button className="doing_button" onClick={handleExpand}>
                            Читать далее
                        </button>
                )}
                </div>
            </div>
        </div>
    );
}

export default LandingDoing;
