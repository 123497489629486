import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { geoApiInstance } from '../../services/api'

const useGeoMenuStore = create(
	devtools(set => ({
		content: null,
		contentMenu: null,
		footerMenu: null,
		mainMenu: null,
		actions: {
			getMenuContent: async () => {
				try {
					const data = {
						isMainPage: false,
						link: '/all-menu',
						defaultCityEbgId: 274286,
						isAgentContent: true,
						isClientContent: false
					}
					const res = await geoApiInstance().post('/all-menu', data)
					set({
						content: res.data
					})
					set({
						contentMenu: res.data.burgerNav.menu_items.filter(
							item =>
								item?.user_roles?.includes('agent') || !item.user_roles
						)
					})
					set({
						footerMenu: res.data.footerNav.menu_items.filter(
							item =>
								item?.user_roles?.includes('agent') || !item.user_roles
						)
					})
					set({
						mainMenu: res.data.mainNav.menu_items.filter(
							item =>
								item?.user_roles?.includes('agent') || !item.user_roles
						)
					})
				} catch (e) {
					console.log(e?.response?.data)
				}
			}
		}
	}))
)

export default useGeoMenuStore
