import { useState } from 'react'
import BtnArrow from '../../../../../../asset/icons/lp/excursionsFilters/BtnArrow'
import "./style.scss"
import { format } from 'date-fns';

const MAX_CARD_COUNT = 6

function FiltersNewView({ filters, button }) {
    const [showMore, setShow] = useState(false)

    const todayDate = new Date()
    const startDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate() + 14
    )
    const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7
    )

    const lists = filters?.filter(item => item.city && item.country && item.excursionType)

    return <div className='filters-new-view'>
        <div className='filters-new-view__container'>
            {lists
                ?.slice(0, showMore ? lists.length : MAX_CARD_COUNT)
                ?.map((item, index) => {
                    const link = `/searchexcursions?countryId=${item?.country?.ebgNodeId}&departureCityId=${item?.city?.ebgNodeId}&startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}&adults=1&currencyId=1&excursionType=${item?.excursionType?.id}`
                    return <FilterCard
                        key={filters.excursionType}
                        img={item.imgUrl}
                        text={item.textPlate}
                        name={item.changedFilterName || item?.excursionType?.name}
                        link={link}
                    />
                })}
        </div>
        {lists?.length > MAX_CARD_COUNT &&
            <div
                className='filters-new-view__show-more-btn'
                onClick={() => setShow(!showMore)}
                style={{ color: button?.textColor, borderColor: button?.borderColor }}
            >
                {button?.text ? button?.text : showMore ? "Свернуть" : "Смотреть все"}
            </div>}
    </div>
}

function FilterCard({ img, text, name, link }) {

    return <div className='filters-new-view__card'>
        <a href={link} target="_blank" rel="noreferrer"><div className='filters-new-view__card__img' style={{ backgroundImage: `url(${img})` }}></div></a>
        <div className='filters-new-view__card__bottom'>
            <div>
                <div className='filters-new-view__card__name'>{name}</div>
                <div className='filters-new-view__card__text'>{text}</div>
            </div>
            <a href={link} target="_blank" rel="noreferrer"><div className='btn-arrow'>
                <BtnArrow />
            </div></a>
        </div>
    </div>
}

export default FiltersNewView