import { ThemeProvider } from '@material-ui/core'
import './style.css'
import React from 'react'
import CustomizedTabs from './components/Tabs/CustomizedTabs'
import useAuthStore from '../../Auth/store'
import { Box, Snackbar, Alert } from '@mui/material'
import { theme } from './AgencyInfo/AboutAgency'
import AboutAgency2 from './AgencyInfo/AboutAgency2'
import Payment from './Payment-new'
import ButtonSave from './ButtonSave/ButtonSave'

import Contacts from './Contacts-new'
import AllAgents from './AllAgents/AllAgents'

export default function Agency() {
	const [tab, setTab] = React.useState(0)
	const actions = useAuthStore(({ actions }) => actions)
	const officialName = useAuthStore(({ officialName }) => officialName)
	const error = useAuthStore(({ error }) => error)
	const handleChange = (event, newValue) => {
		setTab(newValue)
	}

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ padding: '16px 20px' }}>
				<div>
					<div>
						<p className='welcome-title'>Добро пожаловать,</p>
						<p className='agent-name'>{officialName}</p>
					</div>
					<CustomizedTabs
						value={tab}
						handleChange={handleChange}
						label1='Данные агентства'
						label2='Все агенты'
					/>

					{tab === 0 ? (
						<>
							<AboutAgency2 />
							<Contacts />
							<Payment />
							<ButtonSave />
						</>
					) : (
						<AllAgents />
					)}
				</div>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={!!error}
				autoHideDuration={6}>
				<Alert
					onClose={() => {
						actions.clearError()
					}}
					severity='error'
					sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
		</ThemeProvider>
	)
}
