
import React from 'react';
import { SvgIcon } from "@mui/material";

const NavIcon = (props) => {
    return (
        <SvgIcon {...props} width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" style={{ width: '24px', height: '24px' }}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 13.7526L8.2487 15L12 11.495L15.7513 15L17 13.7526L12 9L7 13.7526Z"
                fill="#4872F2"
            />
        </SvgIcon>
    );
};

export default NavIcon;