const Card = ({
	name,
	id,
	isChecked,
	changeStatus,
	prefix = '',
	icon,
	star
}) => {
	return (
		<div>
			<label
				htmlFor={prefix + id}
				style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
				title={star ? name + ' ' + star : name}>
				<input
					id={prefix + id}
					type='checkbox'
					checked={isChecked}
					onChange={event => changeStatus(id, event)}
				/>
				{icon ? icon : <span style={{ marginRight: 5 }}></span>}
				{name} {star}
			</label>
		</div>
	)
}

export default Card
