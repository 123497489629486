import React, {useEffect, useRef, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {IconButton, InputAdornment, OutlinedInput} from "@material-ui/core";
import {format, isValid} from "date-fns";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import Calendar from "react-calendar";
import {isEmpty} from "lodash";
import IconPlace from '../../asset/images/icon-place.png'

import './style.css'
import useSearchTourStore from "../../pages/SearchTour/store";

const NumberToDateRegex = /^(\d{2})(\d{2})(\d{4})$/;
const DateRegex = /\b(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.(0{4}|[1-9]\d{3})\b/g;
const ISODateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/

const CHAR_CODES = {
	DELETE: 46,
	DOT: 190,
	ZERO: 48,
	NINE: 57,
	MIN_CONTROL_CHAR_CODE: 31,
	DELETE_NAME: 'Delete',
	BACKSPACE: 8
}
const MAX_DAY = 31;
const MAX_MONTH = 12;

const DateInput = ({checkins, label, onChange, value, withFlight}) => {

	const [selectedDate, setSelectedDate] = useState(new Date(value));
	const [showCalendar, setShowCalendar] = useState(false)
	const [selectedInputDate, setSelectedInputDate] = useState(new Date(value))

	const searchParams = useSearchTourStore(({ searchParams }) => searchParams)
	const filters = useSearchTourStore(({ filters }) => filters)

	const calendarRef = useRef(null)

	const handleChange = (date) => {
		let newDate = new Date(date)
		if (new Date(date) < new Date()) {
			newDate = new Date()
		}
		setSelectedInputDate(newDate)
		setSelectedDate(newDate)
		onChange(newDate)
	}

	useEffect(() => {
		setShowCalendar(false)
	}, [selectedDate])

	useEffect(() => {
		setSelectedInputDate(new Date(value))
		setSelectedDate(new Date(value))
		// eslint-disable-next-line
	}, [value])

	useEffect(() => {
		function handleClick(event) {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setShowCalendar(false)
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [calendarRef,setShowCalendar]);

	const handleInputChange = event => {

		let value = event.target.value

		if(value.length > 10){
			return
		}
		if(NumberToDateRegex.test(value) || DateRegex.test(value)){
			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			const date = new Date(changeDate[2], changeDate[1]-1, changeDate[0])
			handleChange(date)
			return
		}  

		if(value.length === 2 && value.slice(0,2) > MAX_DAY){
			setSelectedInputDate(`${MAX_DAY}.`)
			return
		}
		if(value.length === 5 && value.slice(3,5) > MAX_MONTH){
			setSelectedInputDate(`${value.slice(0,3)}${MAX_MONTH}.`)
			return
		}
		setSelectedInputDate(value)

		if(NumberToDateRegex.test(value) || DateRegex.test(value)){
			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			const date = new Date(changeDate[2], changeDate[1]-1, changeDate[0])
			handleChange(date)
			return
		}  
	}

	const formateInput = (date) => {
		if(ISODateRegex.test(date)){
			const formatDate = new Date(date)
			return format(formatDate, 'dd.MM.yyyy')
		}
		if(isValid(date)) {
			return format(date, 'dd.MM.yyyy')
		}
			return date
	}

	const setCheckinData = (date) => {
		if (!isEmpty(checkins)) {
			let checkin = checkins.find(el => format(el.iso, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'))
			let checkinClass = ''
			if (checkin) {
				switch(checkin.valid){
					//1 - есть предложения, статус мест на прямой рейс неизвестен
					//2 - есть предложения и места на прямой чартерный рейс (для указанного числа пассажиров)
					//3 - мало мест на прямой чартерный рейс (но достаточно для указанного числа пассажиров)
					case '1':
						checkinClass = 'only-hotel'
						break
					case '2':
						checkinClass = 'charter'
						break
					case '3':
						checkinClass = 'few'
						break
					//5 - есть места на регулярных рейсах (GDS)
					case '5':
						checkinClass = 'gds'
						break
					case '6':
						checkinClass = 'charter'
						break
					default:
						checkinClass = ''
				}
				if (searchParams.tours) {
					const tourId = Number(searchParams.tours[0])
					const isGds = filters.tours.find(item => item.id === tourId)?.withRegularFreight
					if (isGds) {
						switch(checkin.valid){
							case '0':
								checkinClass = ''
								break
							default:
								checkinClass = 'gds'
						}
					}
				}
				return checkinClass
			}
		}
	}

	const handleShowCalendar = () => {
		setShowCalendar(!showCalendar)
	}

	const handleKeyPress = (event) => {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode !== CHAR_CODES.DELETE && charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE && (charCode < CHAR_CODES.ZERO || charCode > CHAR_CODES.NINE)) {
		event.preventDefault();
		}
	};

	const handleKeyDown = (event) => {
		const value = event.target.value
		const charCode = event.which ? event.which : event.keyCode

		if(charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE && charCode === CHAR_CODES.DOT){
			return
		}
		if (charCode === CHAR_CODES.BACKSPACE || charCode === CHAR_CODES.DELETE) {
			return
		}
		if (value.length === 2 || value.length === 5) {
			event.target.value = value + '.'
			return
		}
	}

	const handleBlur = () => {
		if (selectedInputDate.length < 10 ) {
			handleChange(new Date())
			return
		}
	}

	return (
		<div className='DateInput'>
			<FormControl variant="outlined" style={{width: '100%'}}>
				<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
				<OutlinedInput
					label={label}
					id="outlined-adornment-password"
					value={formateInput(selectedInputDate)}
					className="mui-date-input"
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onKeyPress={handleKeyPress}
					onBlur={handleBlur}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								edge="end"
								onClick={handleShowCalendar}
							>
								<EventAvailableOutlinedIcon />
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
			{showCalendar && <div className="date-input" ref={calendarRef}>
				<Calendar
					value={selectedDate}
					date={selectedDate}
					onChange={(date) => handleChange(date)}
					tileDisabled={({date}) => (date < new Date()) || !setCheckinData(date)}
					tileClassName={({date}) => setCheckinData(date)}
				/>
				{withFlight && <div>
					<div className="calendar-legend">
						<span className="icon-place charter" style={{backgroundImage: `url(${IconPlace})`}}/> - есть
						места
					</div>
					<div className="calendar-legend">
						<span className="icon-place few" style={{backgroundImage: `url(${IconPlace})`}}/> - мало мест
					</div>
					<div className="calendar-legend">
						<span className="icon-place regular" style={{backgroundImage: `url(${IconPlace})`}}/> -
						регулярные рейсы
					</div>
				</div>}
			</div>}
		</div>
	);
};

export default DateInput;