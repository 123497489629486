import React from 'react';
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {tooltipClasses} from "@mui/material";

const LightTooltip = styled(({ className, children, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }}>
		{children}
	</Tooltip>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor:'#dbe0e5',
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[2],
		fontSize: 14,
		padding: 10
	},
	"& .MuiTooltip-arrow": {
		color: '#dbe0e5',
	}
}))

export default LightTooltip;