import * as React from 'react'
import OnBoardServices from "../pages/OnBoardServices";

const routes = [
	{
		path: '/flydubai',
		element: <OnBoardServices path={'/flydubai'} />
	},
	{
		path: '/airarabia',
		element: <OnBoardServices path={'/airarabia'} />
	},
	{
		path: '/uralskie-avialinii',
		element: <OnBoardServices path={'/uralskie-avialinii'} />
	},
	{
		path: '/azur-air',
		element: <OnBoardServices path={'/azur-air'} />
	},
	{
		path: '/red-wings',
		element: <OnBoardServices path={'/red-wings'} />
	},
	{
		path: '/corendon-airlines',
		element: <OnBoardServices path={'/corendon-airlines'} />
	},
]

export default routes
