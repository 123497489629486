import {createAction,createReducer} from 'redux-act';

export const showNotification = createAction('@notification/show')
// const hideNotification = createAction('@notification/hide')

export const setNotification = createAction('@notification/set');
export const closeNotification = createAction('@notification/close');

const notification = createReducer({}, null);

notification.on(setNotification, (_, payload) => ({ ...payload }));
notification.on(closeNotification, (_, payload) => payload?.searchRequest || null);

export default notification;
