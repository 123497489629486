import Agency from "../pages/NewCabinet/AgentInfo/Agency";
import Claims from "../pages/NewCabinet/Claims";
import * as React from "react";
import Bonuses from "../pages/NewCabinet/Bonuses";
import Contracts from "../pages/NewCabinet/Contracts";
import Franchising from "../pages/NewCabinet/Franchising";
import Reports from "../pages/NewCabinet/Reports";
import Comission from "../pages/NewCabinet/Comission";
import CuratorContacts from "../pages/NewCabinet/CuratorContacts";
import FitClaims from "../pages/NewCabinet/FitClaims";
import Plans from "../pages/NewCabinet/Plans";
import {redirect} from "react-router";
import {
	AgentInfoTemplate,
	AviaAppealTemplate,
	AviaOrderTemplate,
	PassengersTemplate
} from "../templates";
import OrderDetail from "../pages/Avia/OrderDetail";
import OrderHistory from "../pages/Avia/OrderHistory";
import OrderAppeals from "../pages/Avia/OrderAppeals";
import AppealDetail from "../pages/Avia/AppealDetail";
import AppealHistory from "../pages/Avia/AppealHistory";
import AgentFinance from "../pages/Avia/AgentFinance";
import AgentHistory from "../pages/Avia/AgentHistory";
import AgentUsers from "../pages/Avia/AgentUsers";
import AgentFees from "../pages/Avia/AgentFees";
import AgentRules from "../pages/Avia/AgentRules";
import AviaSaleRequest from "../pages/Cabinet/AviaSaleRequest";
import MyPassengers from "../pages/Cabinet/MyPassengers";
import CreatePassenger from "../pages/Cabinet/MyPassengers/components/CreatePassenger";
import EditPassenger from "../pages/Cabinet/MyPassengers/components/EditPassenger";
import Orders from "../pages/Cabinet/Claims";
import Education from "../pages/NewCabinet/Education";

const routes = [
	{
		index: true,
		loader: async () => redirect("/cabinet/my-details")
	},
	{
		path: "my-details",
		element: <Agency/>,
	},
	{
		path: "claims",
		element: <Claims />,
	},
	{
		path: "bonus",
		element: <Bonuses />,
	},
	{
		path: "agreement",
		element: <Contracts />,
	},
	{
		path: "franchising",
		element: <Franchising />,
	},
	{
		path: "reports",
		element: <Reports />,
	},
	{
		path: "commission",
		element: <Comission />,
	},
	{
		path: "curator-contacts",
		element: <CuratorContacts />,
	},
	{
		path: "fit",
		element: <FitClaims />,
	},
	{
		path: "my-plans",
		element: <Plans />,
	},
	{
		path: "education",
		element: <Education />,
	},

	// new routes for TTE
	{
		path: "orders",
		element: <Orders/>,
	},
	{
		path: "orders/:orderNumber",
		element: <AviaOrderTemplate/>,
		children: [
			{
				path: "",
				element: <OrderDetail />,
			},
			{
				path: "history",
				element: <OrderHistory />,
			},
			{
				path: "tickets",
				element: <OrderAppeals />,
			},
		]
	},
	{
		path: "tickets/:ticketNumber",
		element: <AviaAppealTemplate/>,
		children: [
			{
				path: "",
				element: <AppealDetail />,
			},
			{
				path: "history",
				element: <AppealHistory />,
			},
		]
	},
	{
		path: "avia-agent-info",
		element: <AgentInfoTemplate/>,
		children: [
			{
				path: "",
				element: <AgentFinance />,
			},
			{
				path: "history",
				element: <AgentHistory />,
			},
			{
				path: "users",
				element: <AgentUsers />,
			},
			{
				path: "fees",
				element: <AgentFees />,
			},
			{
				path: "rules",
				element: <AgentRules />,
			},
			{
				path: "rules/:id",
				element: <AgentRules />,
			},
		]
	},
	{
		path: "avia-sale-request",
		element: <AviaSaleRequest/>,
	},
	{
		path: "my-passengers",
		element: <PassengersTemplate />,
		children: [
			{
				path: "",
				element: <MyPassengers />,
			},
			{
				path: "create",
				element: <CreatePassenger />,
			},
			{
				path: "edit",
				element: <EditPassenger />,
			}
		],
	},
]

export default routes
