import React, { useEffect } from 'react';
import CountryField from "../../../../components/Services/CountryField";
import TouristsField from "../TouristsField";
import { Button } from "@material-ui/core";
import useAirportStore from "../../store";
import DateInputRange from "../../../../components/Services/DateInputRange";
// import declination from "../../../../functions/declination";
import { Box } from "@mui/material";
import LinearProgress from "@material-ui/core/LinearProgress";
import './style.scss'
import { isEmpty } from 'lodash';
import {useNavigate} from "react-router-dom";

const AirportSearchForm = () => {
    const actions = useAirportStore(({ actions }) => actions)
    const countries = useAirportStore(({ countries }) => countries)
    const currentCountry = useAirportStore(({ currentCountry }) => currentCountry)
    const cities = useAirportStore(({ cities }) => cities)
    const currentCity = useAirportStore(({ currentCity }) => currentCity)
    const adults = useAirportStore(({ adults }) => adults)
    const children = useAirportStore(({ children }) => children)
    const excursions = useAirportStore(({ excursions }) => excursions)
    const loadingSearch = useAirportStore(({ loadingSearch }) => loadingSearch)
    const date = useAirportStore(({ date }) => date)

    const navigate = useNavigate()

    useEffect(() => {
        actions.postCountries()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (currentCountry) {
            actions.postCities(currentCountry)
        }
        // eslint-disable-next-line
    }, [currentCountry])

    const handleChangeCountry = (id) => {
        actions.setCountry(id)
    }

    const handleChangeDate = (value) => {
        actions.setDate(value)
    }

    const handleSearch = () => {
        navigate(`?countryId=${currentCountry}&startDate=${date.dateFrom}&endDate=${date.dateTo}&adults=${adults}&childrens=${children}&departureCityId=${currentCity}`)
        actions.postSearch()
    }

    if (!countries || !cities) return

    return (
        <div className={'airport-page'}>
            <div className={`airport-search-form-wrapper ${!excursions ? 'background-color' : ''}`}>
                <div
                    className={`airport-search-form ${!!excursions ? 'container' : ''}`}
                >
                    <CountryField
                        className={'airports-countries'}
                        lists={countries}
                        value={currentCountry}
                        title={'Страна поездки'}
                        onChange={handleChangeCountry}
                    />
                    <CountryField
                        className={'airport-city'}
                        lists={cities}
                        value={currentCity}
                        title={'Куда'}
                        onChange={(id) => actions.setCity(id)}
                    />
                    <DateInputRange
                        label="Даты поездки"
                        value={[new Date(date.dateFrom), new Date(date.dateTo)]}
                        onChange={handleChangeDate}
                    />
                    <TouristsField
                        className={'tourists'}
                        actions={actions}
                        adults={adults}
                        children={children}
                        title={'Туристы'}
                    />
                    <Button
                        onClick={handleSearch}
                        variant={'contained'}
                        className={'search-button'}
                    // disabled={loading}
                    >
                        Найти
                    </Button>
                </div>
            </div>
            <Box className={'container'} mt={2}>
                {((!excursions && loadingSearch) || (isEmpty(excursions) && loadingSearch)) &&
                    <div style={{ color: '#49c8c0', fontSize: 16 }}>
                        Идет поиск.
                    </div>
                }
                {loadingSearch &&
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={excursions?.length * 6.25} />
                    </Box>
                }
            </Box>
        </div>
    )
};

export default AirportSearchForm;