import './style.css'
import React from 'react'
import useAuthStore from '../../../Auth/store'

function ButtonSave() {
	const actions = useAuthStore(({ actions }) => actions)
	const validateErrors = useAuthStore(({ validateErrors }) => validateErrors)
	const agents = useAuthStore(({ agent }) => agent?.agents)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))

	const [isValueChangeted, setIsValueChanged] = React.useState(false)

	const initialRender = React.useRef(true);
	React.useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}
			setIsValueChanged(true);
	}, 	[
			agents?.officialName,
			agents?.pAddress,
			agents?.registrationDate,
			agents?.taxation?.title,
			agents?.boss,
			agents?.directorPosition?.title,
			agents?.ownership?.title,
			agents?.worker,
			agents?.activity?.title,
			agents?.rs,
			agents?.bank?.id,
			agents?.fAddress,
			agents?.addressCode,
			agents?.phone,
			agents?.email
		]);

	const btnDisabled =
		agentInfo?.agents?.name.includes('F&S') || !isValueChangeted

	const checkRequiredFields = () => {
		return (
			agents?.officialName &&
			agents?.pAddress &&
			agents?.registrationDate &&
			agents?.taxation.title &&
			agents?.boss &&
			agents?.directorPosition.title &&
			agents?.ownership.title &&
			agents?.worker &&
			agents?.activity.title &&
			agents?.rs &&
			agents?.bank?.id &&
			agents?.fAddress &&
			agents?.addressCode &&
			agents?.phone &&
			agents?.email
		)
	}

	const checkValidateErrors = () => {
		return Object.values(validateErrors).some(el => !!el)
	}

	const saveHandler = () => {

		if (!checkRequiredFields()) {
			actions.setError('Необходимо заполнить все обязательные поля.')
			return
		}

		if (checkValidateErrors()) {
			actions.setError('Проверьте правильность заполнения полей.')
			return
		}
		actions.changeAgents().then(() => {
			setIsValueChanged(false);
		})
	}

	return (
		<button
			type='button'
			className={`agency-save-btn ${btnDisabled ? 'disabled' : ''}`}
			onClick={saveHandler}
			disabled={btnDisabled}
			>
			Сохранить
		</button>
	)
}

export default React.memo(ButtonSave)
