import React from "react"
import styled from "styled-components"
import { Link, useParams } from "react-router-dom"
import { Outlet } from "react-router-dom";
import {Loader, Navigation} from "../components/ui"
import { useDispatch, useSelector } from "react-redux";
import { detailPurify, getAppealDetail, getDetail } from "../store/modules/appeals"
import { Breadcrumbs, Typography } from "@mui/material";

const Wrapper = styled.div`

`

const Content = styled.div`
  position: relative;
  min-height: 447px;

  & > div {
    margin-bottom: 30px;
  }

  & > :nth-child(2) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    & > :nth-child(2) {
      margin-bottom: 15px;
    }
  }
`

const StyledNavigation = styled(Navigation)`
  @media (max-width: 767px) {
    margin-top: 25px;
  }
  margin-bottom: 35px;
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiBreadcrumbs-root,
  .MuiTypography-root {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #B9B9B9;
  }
  .MuiBreadcrumbs-ol {
    margin-bottom: 16px;
  }
  .MuiBreadcrumbs-li a {
    color: #B9B9B9;
  }
`

const OrderDetail = () => {
  const {ticketNumber} = useParams();
  const dispatch = useDispatch()
  const { orderNumber, orderId } = useSelector(getAppealDetail)

  React.useEffect(() => {
    dispatch(getDetail(ticketNumber))

    return () => {
      dispatch(detailPurify())
    }
  }, [])



  const navigation = React.useMemo(
    () => [
      {
        name:  "Чат службы поддержки",
        path: `/cabinet/tickets/${ticketNumber}`
      },
      {
        name: "История обращения",
        path: `/cabinet/tickets/${ticketNumber}/history`
      },
    ],
    [ticketNumber]
  )


  return  (
    <Wrapper>
      {orderId === '' && <Loader opacity='0.8' zIndex={999}/>}
      <StyledBreadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' to={'/cabinet/orders'}>Список заявок</Link>
        <Link color='inherit' to={`/cabinet/orders/${orderNumber}`}>Детали заявки №{orderNumber}</Link>
        <Link color='inherit' to={`/cabinet/orders/${orderNumber}/tickets`}>Список обращений</Link>
        <Typography style={{ color: '#737373' }}>Обращение №{ticketNumber}</Typography>
      </StyledBreadcrumbs>
      <StyledNavigation items={navigation} />
      <Content>
        <Outlet/>
      </Content>
    </Wrapper>
  )
}

export default OrderDetail
