import React from 'react'
import styled from 'styled-components'
import alertCircle from '../../../asset/images/cabinet/alertCircle.svg'
import {ReactComponent as ArrowForward} from '../../../asset/icons/slider-next.svg'
import usePlansStore from './store'
import CircularProgress from '@mui/material/CircularProgress';

const NextBtn = styled(ArrowForward)`
	cursor: pointer;
	pointer-events: ${({disabled}) => disabled ? 'none'  : ''};;
	& > path {
		fill: ${({disabled}) => disabled ? '#b3b9c0'  : '#2e2e32'};
	}
	& > circle {
		stroke:  ${({disabled}) => disabled ? '#b3b9c0'  : '#7e8389'};
	}
	&:hover {
		& > path {
			fill: #4872F2;
		}
		& > circle {
			stroke: #4872F2;
		}
	}
`

const PrevBtn = styled(NextBtn)`
	transform: rotate(180deg);
	margin-right: 8px;
`
 

const Title = styled.div`
	font-family: Open Sans;
	font-weight: 600;
	font-size: 28px;
	font-style: normal;
	line-height: 38px;
	color: #2e2e32;
	& span {
		color: #28BF68
	}
`

const Container = styled.div`
	width: 900px;
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`
const GrayBlock = styled.div`
	width: 100%;
	background: #f6f8fa;
	border-radius: 16px;
	padding: 24px 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
`
const YellowBlock = styled(GrayBlock)`
	background: #FFFCE6;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`

const Text = styled.div`
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	color: #2e2e32;
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: break-spaces;
`

const Text2  = styled(Text)`
	font-size: 16px;
	line-height: 22px;
`
const Block = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`
const Circle = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: #2E2E32;
	margin: 0 8px;
`
const AlertImage = styled.img.attrs({
	src: alertCircle
  })`
	width: 24px;
	height: 24px;
  `
const Link = styled.a`
font-size: 16px;
line-height: 22px;
font-weight: 400;
color: rgba(72, 114, 242, 1);
`

 
export default function Plans() {

	const [reports, setReports] = React.useState([])
	const [sliceValue, setSliceValue] = React.useState(0)

	const actions = usePlansStore(({actions}) => actions)
	const loading = usePlansStore(({loading}) => loading)
	// const comission = JSON.parse(localStorage.getItem('agentInfo')).agents.groups.find(el => el.id === 271)?.title.slice(-3)
	const agentInfo = JSON.parse(localStorage.getItem('agentInfo'))
	
	const getStartDate = (datePeriod) => {
		return  new Date(datePeriod.split(' – ')[0].split('.').reverse().join('.')) 
	}

	const sortedPlans = usePlansStore(({plans}) => plans).toSorted((a, b) => {
		return getStartDate(b.reportingPeriod) - getStartDate(a.reportingPeriod)
	})

	const currentPlan = sortedPlans[0]

	React.useEffect(() => {
		actions.getPlan()
		// eslint-disable-next-line
	}, [])
	
	React.useEffect(() => {
		if (sortedPlans.length > 4) {
			setReports(sortedPlans.slice(sliceValue, sliceValue + 4))
		} else {
			setReports(sortedPlans)
		}
		// eslint-disable-next-line
	},[sliceValue, sortedPlans.length])
		const handleNext = () => {
			setSliceValue(prev => prev + 4)
		}
		const handlePrev = () => {
			setSliceValue(prev => prev - 4)
		}

	return (
		<Container>
			<Title>Мои планы</Title>
			{/* <GrayBlock><Title>Ваша комиссия - <span>{comission}</span></Title></GrayBlock> */}
			{loading ? (
				<div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress size={20} /></div> 
				) : (
				<>			
					<Block>
						<Text><b>Отчетный период</b><Circle/>{currentPlan?.reportingPeriod}</Text>
						<Block style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '24px'}}>
							<GrayBlock>
								<Text>План PAX — <b> {currentPlan?.planPax}</b></Text> 
								<Text>План У.Е. — <b> {currentPlan?.planYe}</b></Text>
							</GrayBlock>
							<GrayBlock>
								<Text>Факт PAX — <b> {currentPlan?.fact?.paxFact}</b> выполнено на {Math.round(currentPlan?.fact?.paxFact / currentPlan?.planPax * 100)}%</Text>
								<Text>Факт У.Е. — <b> {currentPlan?.fact?.yeFact}</b> выполнено на {Math.round(currentPlan?.fact?.yeFact / currentPlan?.planYe * 100)}%</Text>
							</GrayBlock>
						</Block>
					</Block>
					<Block style={{marginTop: 16}}>
						<Text><b>Ваши планы на период</b></Text>
						<Text2>Даты бронирования: {currentPlan?.bookingDates}</Text2>
						<Text2>Даты заездов: {currentPlan?.checkInDates}</Text2>
						<Text2>Участвуют: {currentPlan?.participate}</Text2>
						<YellowBlock>
							<AlertImage/>
							<Text style={{ fontSize: '14px', lineHeight: '20px' }}>{currentPlan?.conditions}</Text>
						</YellowBlock>
					</Block>
					{!agentInfo?.agents?.name.includes('F&S') && <Block style={{marginTop: 16}}>
						<Text><b>Загрузка дополнительного соглашения</b></Text>
						<Text2>Загрузить бланк дополнительного соглашения можно  <Link href={window.location.origin + currentPlan?.blank} target='blank'>по этой ссылке</Link> </Text2>
						<Text2>Заполненное соглашение необходимо прислать по адресу <Link href="mailto:partnership@fstravel.com<">partnership@fstravel.com</Link></Text2>
					</Block>}
					<Block style={{marginTop: 16}}>
						<Text><b>История отчетных периодов</b></Text>
						{reports?.map((el, index) => (
							<Block style={{marginTop: 24}} key={index}>
								<Text style={{ fontSize: '14px', lineHeight: '24px' }}><b>Отчетный период </b> <Circle/> {el.reportingPeriod}</Text>
								<Block style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '24px'}}>
									<GrayBlock>
										<Text>План PAX — <b> {el.planPax}</b></Text> 
										<Text>План У.Е. — <b> {el.planYe}</b></Text>
									</GrayBlock>
									<GrayBlock>
										<Text>Факт PAX — <b> {el?.fact?.paxFact}</b> выполнено на {Math.round(el?.fact?.paxFact / el.planPax * 100)}%</Text> 
										<Text>Факт У.Е. — <b> {el?.fact?.yeFact}</b> выполнено на {Math.round(el?.fact?.yeFact / el.planYe * 100)}%</Text>
									</GrayBlock>
								</Block>
							</Block>
							
						))}
						{sortedPlans.length > 4 && (
							<div>
								<PrevBtn onClick={handlePrev} disabled={sliceValue <= 0}/>
								<NextBtn onClick={handleNext} disabled={sliceValue + 4 >= sortedPlans.length}/>
							</div>
						)}
					</Block>		
				</>
				)
			}
		</Container>
	)
}
