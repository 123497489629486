import { all, takeLatest, select, call, put,take,delay } from "redux-saga/effects"
import {
  getAgentBillByOrderIdRequest,
  getAgentBillByOrderIdSuccess,
  showSnackbar,
  getAgentBillBySamoNumberRequest,
  getAgentBillByTteNumberRequest,
  getAgentBillSuccess,
  getAgentCreditHistoryRequest,
  getAgentCreditHistorySuccess,
  getAgentCreditHistoryFailure,
  getAgentMainInformation,
  getAgentPaymentHistoryFailure,
  getAgentPaymentHistoryRequest,
  getAgentPaymentHistorySuccess,
  getAgentReport,
  getAgentReportResponse,
  setAgentBalance,
  setAgentMainInfo,
  setAgentNotPaidOrders,
  setAgentOverPaidOrders,
  getAgentOverPaidOrders,
  getAgentNotPaidOrders,
  getAgentReportFailure,
  getAquiringCommission,
  getAquiringCommissionSuccess,
  addAgentRequest,
  addAgentSuccess,
  addAgentFailure,
  getAgentDeposit,
  getAgentDepositSuccess,
  getAgentDepositList,
  getAgentDepositListSuccess,
  getAgentInfo,
  getAgentInfoSuccess,
  getAgentsBalance,
  resetData,
  purifyAgentCreditHistory,
  purifyAgentDepositList,
  purifyAgentPaymentHistory,
} from "./duck"
import * as FeeManager from '../agent-fees/manager';
import {getAgentFeesInfoSuccess,getAgentTaxesSuccess} from '../agent-fees/duck'
import * as AgentUserManager from '../agent-users/manager'
import {getAgentLogSuccess} from '../agent-users/duck'
import {setAgentInfoLoadingState} from '../duck'



import * as AgentsManager from "./manager"
import { getAgentSiginState } from "./selectors"
import * as OrderManager from '../../orders/manager'

function* getAgentMainInformationRunner({payload}) {
  // дожидаемся что подгрузились данные агента
  yield call(waitFor, state => state.agents.agentSigninInfo !== null);

  const agent = yield select(getAgentSiginState)

  try {
    yield put(setAgentInfoLoadingState(true));
    if (agent) {
      const agentInfo = yield call(AgentsManager.getAgentInfo, agent.agentId);

      if(payload.includes("fees") || payload.includes("rules")) {
        const [fee,tax] =  yield all([
          call(FeeManager.getAgentFee, agent.agentId),
          call(FeeManager.getAgentTax, agent.agentId)
        ]);
        yield put(getAgentFeesInfoSuccess(fee))
        yield put(getAgentTaxesSuccess(tax))
        yield delay(500);
      }else if(payload.includes("history")) {
        const response = yield call(
          AgentUserManager.getAgentLog,
          agent.agentId
        )
        yield delay(500);
        yield put(getAgentLogSuccess(response))
      } else {
        const [balance,overpaid, notPaid] = yield all([
          call(AgentsManager.getAgentsBalance, agent.agentSamoCode),
          call(AgentsManager.getAgentOverPaidOrders, agent.agentSamoCode),
          call(AgentsManager.getAgentNotPaidOrders, agent.agentSamoCode),
        ])
        yield put(setAgentBalance(balance))
        yield put(setAgentOverPaidOrders(overpaid || null))
        yield put(setAgentNotPaidOrders(notPaid || []))
      }

      yield put(setAgentMainInfo(agentInfo))
      yield put(setAgentInfoLoadingState(false));
      
    }
  } catch (err) {
    yield put(setAgentInfoLoadingState(false));
    console.log(err);
  }
}

function* getAgentCreditHistory({ payload }) {
  try {
    const data = yield call(
      AgentsManager.getAgentCreditHistory,
      payload.agentId,
      payload.date,
      payload?.sortField,
      payload?.sortAsc
    )

    yield put(getAgentCreditHistorySuccess(data))
  } catch (err) {
    yield put(getAgentCreditHistoryFailure());
    yield put(showSnackbar())
  }
}

function* getAgentBillByOrderIdSaga(action) {
  try {
    yield call(
      OrderManager.getAgentOrderBill,
      action.payload
    )

    yield put(getAgentBillByOrderIdSuccess())
  } catch (err) {
    yield put(showSnackbar())
  }
}

function* getAgentReportSaga(action) {
  try {
    yield call(
      AgentsManager.getAgentReport,
      action.payload.agentId,
      action.payload.date,
    )
    yield put(getAgentReportResponse())
  } catch (err) {
    console.log(err);
    yield put(getAgentReportFailure(true))
  }
}

function* getAgentPaymentHistorySaga(action) {
  try {
    const data = yield call(
      AgentsManager.getAgentPaymentHistory,
      action.payload
    )

    yield put(getAgentPaymentHistorySuccess(data))
  } catch (err) {
    yield put(getAgentPaymentHistoryFailure())
    yield put(showSnackbar())
  }
}

function* getAgentOverPaidOrdersSaga(action) {
  const { payload } = action

  try {
    const data = yield call(
      AgentsManager.getAgentOverPaidOrders,
      payload?.partnerId,
      payload?.sortField,
      payload?.sortAsc
    )
    
    yield put(setAgentOverPaidOrders(data || null))
  } catch (err) {
    //
  }
}

function* getAgentNotPaidOrdersSaga(action) {
  const { payload } = action

  try {
    const data = yield call(
      AgentsManager.getAgentNotPaidOrders,
      payload?.partnerId,
      payload?.sortField,
      payload?.sortAsc
    )

    yield put(setAgentNotPaidOrders(data))
  } catch (err) {
    //
  }
}

function* getAgentBillByNumberSaga(action) {
  try {
    yield call(
      OrderManager.getAgentBillBySamoId,
      action.payload,
      
    )
    yield put(getAgentBillSuccess())
  } catch (err) {
    yield put(showSnackbar())
  }
}

function* getAgentBillByTteNumberSaga(action) {
  try {
    yield call(
      OrderManager.getAgentBillByTteNumber,
      action.payload,
      
    )
    yield put(getAgentBillSuccess())
  } catch (err) {
    yield put(showSnackbar())
  }
}

function* getAquiringCommissionSaga(action) {
  const { payload } = action

  try {
    const data = yield call(
      AgentsManager.getAquiringCommission,
      payload?.AgentId
    )
      
    yield put(getAquiringCommissionSuccess(data))
  } catch (err) {
    console.log(err);
    //
  }
}

function* addAgentRequestSaga(action) {
  const { payload } = action

  try {
    yield call(AgentsManager.addAgentRequest, payload)
    yield put(addAgentSuccess(true))
  } catch (err) {
    yield put(addAgentFailure(true))
  }
}

function* getAgentDepositSaga(action) {
  const { payload } = action
  try {
    yield call(
      AgentsManager.getAgentDeposit,
      payload?.AgentId,
      payload?.Summ,
      
    )
    yield put(getAgentDepositSuccess())
  } catch (err) {
    yield put(showSnackbar())
  }
}

function* getAgentDepositListSaga(action) {
  const { payload } = action
  try {
    const result = yield call(
      AgentsManager.getDepositList,
      payload?.AgentId,
      payload?.From,
      payload?.To
    )
    yield put(getAgentDepositListSuccess(result))
  } catch (err) {
    yield put(showSnackbar())
  }
}

function* getAgentInfoSaga(action) {
  const { payload } = action
  try {
    const result = yield call(
      AgentsManager.getAgentInfoRequests,
      payload?.PartnerId
    )
    yield put(getAgentInfoSuccess(result))
  } catch (err) {
    console.log(err)
  }
}

function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}

export function* getAgentBalanceSaga(action) {
  try {
    yield call(waitFor, state => state.user.data !== null);

    const agent = yield select(state => state.user.data)
    const data = yield call(AgentsManager.getAgentsBalance, agent.agentSamoCode);
    yield put(setAgentBalance(data));
  } catch(e) {
    console.log(e);
  }
}


function* resetDataSaga() {
  try {
    yield put(purifyAgentCreditHistory());
    yield put(purifyAgentDepositList())
    yield put(purifyAgentPaymentHistory())
  } catch(e) {
    console.log(e);
  }
}




export default function* rootSaga() {
  yield all([
    takeLatest(
      getAgentMainInformation.getType(),
      getAgentMainInformationRunner
    ),
    takeLatest(getAgentCreditHistoryRequest.getType(), getAgentCreditHistory),
    takeLatest(
      getAgentBillByOrderIdRequest.getType(),
      getAgentBillByOrderIdSaga
    ),
    takeLatest(getAgentReport.getType(), getAgentReportSaga),
    takeLatest(getAgentsBalance.getType(),getAgentBalanceSaga),
    takeLatest(
      getAgentPaymentHistoryRequest.getType(),
      getAgentPaymentHistorySaga
    ),
    takeLatest(getAgentOverPaidOrders.getType(), getAgentOverPaidOrdersSaga),
    takeLatest(getAgentNotPaidOrders.getType(), getAgentNotPaidOrdersSaga),

    takeLatest(
      getAgentBillBySamoNumberRequest.getType(),
      getAgentBillByNumberSaga
    ),
    takeLatest(
      getAgentBillByTteNumberRequest.getType(),
      getAgentBillByTteNumberSaga
    ),
    takeLatest(getAquiringCommission.getType(), getAquiringCommissionSaga),
    takeLatest(addAgentRequest.getType(), addAgentRequestSaga),
    takeLatest(getAgentDeposit.getType(), getAgentDepositSaga),
    takeLatest(getAgentDepositList.getType(), getAgentDepositListSaga),
    takeLatest(getAgentInfo.getType(), getAgentInfoSaga),
    takeLatest(resetData.getType(), resetDataSaga),
  ])
}
