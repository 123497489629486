import {
	Button,
	Divider,
	Grid,
	styled,
	TextField,
	useMediaQuery
} from '@material-ui/core'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { subscribeAgent } from './mindbox'
import SuccessPopup, { SuccessDrawer } from './SuccessPopup'
import { emailRegExp } from '../../../constants/reg-exps'

const ColorButton = styled(Button)(({ theme }) => ({
	'@media (max-width: 600px)': {
		fontSize: '14px',
		height: '40px',
		lineHeight: '22px',
		marginBottom: '16px',
		marginTop: '16px'
	},
	width: '100%',
	boxShadow: 'none',
	background: '#ffe100',
	borderRadius: '8px',
	color: '#2e2e32',
	textTransform: 'none',
	fontSize: '16px',
	fontWeight: 600,
	height: '56px',
	lineHeight: '24px',
	padding: '16px 39px',
	textAlign: 'center',
	textDecoration: 'none',
	fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(','),
	'&:hover': {
		background: '#ffcd00',
		boxShadow: 'none'
	},
	'&:focus': {
		boxShadow: 'none'
	},
	'&:active': {
		outline: '0px'
	}
}))

export const SubscribeButton = ({ onClick, children }) => {
	return (
		<ColorButton variant='contained' disableRipple onClick={onClick}>
			{children}
		</ColorButton>
	)
}

const SubscribeInput = styled(TextField)(() => ({
	'& fieldset': {
		borderRadius: '8px'
	},
	'& .MuiInputBase-input': {
		background: 'white'
	}
}))

export default function SubscribeBlock() {
	const { pathname } = useLocation()
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)

	const changeEmailHandler = e => {
		setEmailError(false)
		setEmail(e.target.value)
	}

	const subscribeHandler = () => {
		if (!emailRegExp.test(email)) {
			setEmailError('Неверный формат почты')
		} else {
			setEmailError(false)
			subscribeAgent(email, pathname)
			handleClickOpen()
			// if (isSmallScreen) {
			// } else toggleDrawer('bottom', true)()
		}
	}

	const [state, setState] = React.useState({
		bottom: false
	})

	const toggleDrawer = (anchor, open) => event => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setState({ ...state, [anchor]: open })
	}

	const isSmallScreen = useMediaQuery('(min-width:600px)')
	return (
		<>
			<div className='v-subscription-new-inner'>
				<div className='v-subscription-new-appeal'>
					<h3 className='v-subscription-new-title'>Будь в курсе</h3>
					<p className='v-subscription-new-text'>
						Подпишитесь и получайте выгодные туры на свою почту
					</p>
				</div>
				<div className='v-subscription-new-form'>
					{!open ? (
						<Grid
							container
							columns={12}
							spacing={isSmallScreen ? 2 : 0}
							direction='row'
							justifyContent='space-between'
							alignItems='flex-start'>
							<Grid item xs={12} sm={8} lg={9}>
								<SubscribeInput
									fullWidth
									variant='outlined'
									label={'Email'}
									size={isSmallScreen ? 'medium' : 'small'}
									value={email}
									helperText={emailError}
									error={!!emailError}
									onChange={changeEmailHandler}
								/>
							</Grid>
							<Grid item xs={12} sm={4} lg={3}>
								<SubscribeButton onClick={subscribeHandler}>
									Подписаться
								</SubscribeButton>
							</Grid>
							<Grid item xs={12} sm={8} style={{ paddingTop: '0px' }}>
								<p className='v-text-special__link'>
									Нажимая «Подписаться» вы даёте согласие на{' '}
									<Link
										className='v-text-special__link'
										to={'/funsun-soglasie-na-obrabotku-personalnyx-dannyx'}>
										обработку персональных данных.
									</Link>
								</p>
							</Grid>
						</Grid>
					) : (
						<p
							style={{
								fontSize: isSmallScreen ? '20px' : '14px',
								color: '#2E2E32'
							}}>
							Спасибо, вы успешно подписались на рассылку!
						</p>
					)}
				</div>
			</div>
			<Divider style={{ margin: '32px auto' }} />
			{/* <SuccessPopup handleClose={handleClose} open={open} /> */}
			{/* <SuccessDrawer state={state} toggleDrawer={toggleDrawer} /> */}
		</>
	)
}
