import { useState } from "react";

import { Alert } from "@mui/material";
import { Box, TextField } from "@material-ui/core";
import useBookingStore from "../../store";
import CustomSelect from "../../../../components/Select";
import BirthDateInput from "../../../../components/BirthDateInput";
import { nationality } from "../PaxesBlock/components/MembersBlock/nationalities";
import {
	emailRegExp,
	phoneRegExp,
	birthCertNumberRegExp,
	interPassportNumberRegExp,
	interPassportSeriesRegExp,
	rfPassportSeriesRegExp,
	rfPassportNumberRegExp,
	latinUpperAndNumberRegExp,
} from '../../../../constants/reg-exps'

const BUYER_TYPE = [
    {
        name: "Физическое лицо",
        id: "0",
    },
    // {
    //     name: "Юридическое лицо",
    //     id: "1",
    // },
    // {
    //     name: "Индивидуальный предприниматель",
    //     id: "2",
    // },
];

function BuyerBlock() {
    const buyer = useBookingStore(({ buyer }) => buyer);
    const paxes = useBookingStore(({ paxes }) => paxes);
    const actions = useBookingStore(({ actions }) => actions);
    const buyerErrors = useBookingStore(({ buyerErrors }) => buyerErrors);
    const [errors, setErrors] = useState({
        name: null,
        phone: null,
        email: null,
        pserie: null,
        number: null,
        address: null,
        nationalityKey: null,
        agency_docdate: null,
        agency_docnumber: null,
        agency_docsum: null,
        inn: null,
        boss: null,
    });

    const [selectedPaxe, setSelectedPaxe] = useState(null);

    const changeBuyerSelect = (props) => (event) => {
        actions.changeBuyer(props, event);
    };

    const changeNameHandler = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (
            !/[a-zA-Zа-яА-Я\s\-\–']/g.test(event.target.value) &&
            event.target.value !== ""
        ) {
            setErrors({
                ...errors,
                [prop]: `Имя должно содержать только латиницу, кириллицу или спец.символы`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changeAddressHandler = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (
            !/[a-zA-Zа-яА-Я0-9\s\-\–\.,\/]+/g.test(event.target.value) &&
            event.target.value !== ""
        ) {
            setErrors({
                ...errors,
                [prop]: `Адрес должнен содержать только латиницу, кириллицу или спец.символы`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changePhoneHandler = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (!phoneRegExp.test(event.target.value) && event.target.value !== "") {
            setErrors({
                ...errors,
                [prop]: `Номер телефона указан некорректно`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changeEmailHandler = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (!emailRegExp.test(event.target.value) && event.target.value !== "") {
            setErrors({
                ...errors,
                [prop]: `Email указан некорректно`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changeSeriesHandler = prop => event => {
        setErrors({ ...errors, [prop]: null });
        actions.changeBuyerErrors(prop, null);
        const value = event.target.value
        switch (buyer?.identity_document) {
            case 0:
                if (value.length <= 5) {
                    setErrors({
                        ...errors,
                        [prop]: `Серия не менее 5 знаков`
                    })
                    actions.changeBuyerErrors(prop, `Серия не менее 5 знаков`);
                }
                break
			case 1:
				if (!rfPassportSeriesRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Серия должна содержать 4 цифры`
					})
                    actions.changeBuyerErrors(prop, `Серия должна содержать 4 цифры`);
				}
				break
            case 3:
                if (!/^([IVXLCDM]{1,6})([А-ЯЁ]{2})$/.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать римское число и две кириллические буквы`
                    })
                    actions.changeBuyerErrors(prop, `Серия должна содержать римское число и две кириллические буквы`);               
                }
                break
            case 4:
                if (!interPassportSeriesRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать 2 цифры`
                    })
                    actions.changeBuyerErrors(prop, `Серия должна содержать 2 цифры`);
                }
                break
            default:
                setErrors({ ...errors, [prop]: null })
                actions.changeBuyerErrors(prop, null);
                break
        }
        actions.changeBuyer(prop, value);
	}
    const changeNumberHandler = (prop) => (event) => {
        const value = event.target.value
        setErrors({ ...errors, [prop]: null });
        actions.changeBuyerErrors(prop, null);
        switch (buyer?.identity_document) {
            case 0:
				if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
					})
                    actions.changeBuyerErrors(prop, `Номер должен содержать только цифры и заглавные латинские буквы`);
				}
				break
            case 1:
                if (!rfPassportNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен быть 6 цифр`
                    })
                    actions.changeBuyerErrors(prop, `Номер должен быть 6 цифр`);
                }
                break
            case 3:
                if (!birthCertNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен быть 6 цифр`
                    })
                    actions.changeBuyerErrors(prop, `Номер должен быть 6 цифр`);
                }
                break
			case 4:
				if (!interPassportNumberRegExp.test(value) && value !== '') {
					setErrors({
						...errors,
						[prop]: `Номер должен быть 7 цифр`
					})
                    actions.changeBuyerErrors(prop, `Номер должен быть 7 цифр`);
				}
				break

			default:
				setErrors({ ...errors, [prop]: null })
                actions.changeBuyerErrors(prop, null);
				break
		}   
        actions.changeBuyer(prop, value);
    };

    const changeInnHandler = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (!/[0-9]/g.test(event.target.value) && event.target.value !== "") {
            setErrors({
                ...errors,
                [prop]: `ИНН указан неверно. Допустим ввод только цифр`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changeDocSum = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (
            !/^[0-9\s\-\–\.,\/]+$/.test(event.target.value) &&
            event.target.value !== ""
        ) {
            setErrors({
                ...errors,
                [prop]: `Сумма договора с клиентом указана неверно. Допустим ввод латиницы, кириллицы, цифр и спец.символов`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const changeDocNumber = (prop) => (event) => {
        setErrors({ ...errors, [prop]: null });
        if (
            !/[a-zA-Zа-яА-Я0-9\s\-\–\.,\/]+/g.test(event.target.value) &&
            event.target.value !== ""
        ) {
            setErrors({
                ...errors,
                [prop]: `Номер договора с клиентом указана неверно. Допустим ввод цифр и спец.символов`,
            });
        } else {
            setErrors({ ...errors, [prop]: null });
        }
        actions.changeBuyer(prop, event.target.value);
    };

    const selectPaxe = (value) => {
        const existPaxe = paxes?.find((paxe) => paxe.id === value);
        if (!existPaxe) {
            return;
        }
        setSelectedPaxe(value);
    };

    return (
        <>
            <div className="block-header">
                <h3 className="title">
                    <span>Информация о заказчике</span>
                </h3>
            </div>

            <Alert severity="error" sx={{ width: "100%" }}>
                Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
                информацию о заказчике!
            </Alert>
            <div className="members-form">
                <div className="info">
                    <Box style={{ marginBottom: 15 }}>
                        <CustomSelect
                            value={buyer?.buyer_type}
                            options={BUYER_TYPE}
                            title="Тип клиента"
                            count={1}
                            onChange={changeBuyerSelect("buyer_type")}
                            required
                        />
                    </Box>
                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={selectedPaxe}
                                options={paxes.map((paxe) => ({
                                    id: paxe.id,
                                    name: `Турист №${paxe.id}`,
                                }))}
                                title="Турист из заявки"
                                count={1}
                                onChange={selectPaxe}
                                required
                            />
                        </Box>
                    )}

                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="ФИО"
                                variant="outlined"
                                fullWidth
                                value={buyer?.name || ""}
                                onChange={changeNameHandler("name")}
                                helperText={errors.name}
                                // onBlur={(event) => onBlur('lname', event)}
                                error={!!errors.name}
                                required
                                title='Допустим ввод латиницы, кириллицы и спец.символов "-", " ", "–"'
                            />
                        </Box>
                    )}

                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label="Адрес"
                            variant="outlined"
                            fullWidth
                            value={buyer?.address || ""}
                            onChange={changeAddressHandler("address")}
                            helperText={errors?.address}
                            // onBlur={(event) => onBlur('lname', event)}
                            error={!!errors?.address}
                            required
                            title=""
                        />
                    </Box>
                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="Серия документа"
                                variant="outlined"
                                fullWidth
                                helperText={errors.pserie}
                                error={!!errors.pserie}
                                value={buyer?.pserie || ""}
                                onChange={changeSeriesHandler("pserie")}
                                title="Серия паспорта может содержать только цифры и латинские буквы"
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="Номер документа"
                                variant="outlined"
                                fullWidth
                                helperText={errors.number}
                                error={!!errors.number}
                                value={buyer?.number || ""}
                                onChange={changeNumberHandler("number")}
                                title="Номер документа должен содержать только цифры и латинские буквы"
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type === "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="Официальное название"
                                variant="outlined"
                                fullWidth
                                helperText={errors.firmName}
                                error={!!errors.firmName}
                                value={buyer?.firmName || ""}
                                onChange={changeNameHandler("firmName")}
                                required
                            />
                        </Box>
                    )}

                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label="Телефон"
                            variant="outlined"
                            fullWidth
                            helperText={errors.phone}
                            error={!!errors.phone}
                            value={buyer?.phone || ""}
                            onChange={changePhoneHandler("phone")}
                            required
                        />
                    </Box>
                    {buyer?.buyer_type === "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="ФИО руководителя"
                                variant="outlined"
                                fullWidth
                                value={buyer?.boss || ""}
                                onChange={changeNameHandler("boss")}
                                helperText={errors.boss}
                                // onBlur={(event) => onBlur('lname', event)}
                                error={!!errors.boss}
                                required
                                title='Допустим ввод латиницы, кириллицы и спец.символов "-", " ", "–"'
                            />
                        </Box>
                    )}
                    <Box>
                        <TextField
                            label="E-Mail"
                            variant="outlined"
                            fullWidth
                            helperText={errors.email}
                            error={!!errors.email}
                            value={buyer?.email || ""}
                            onChange={changeEmailHandler("email")}
                            required
                        />
                    </Box>
                </div>
                <div className="docs">
                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.nationalityKey || "210357"}
                                options={nationality}
                                title="Гражданство"
                                count={1}
                                // onChange={actions.changeNationality}
                                onChange={changeBuyerSelect("nationalityKey")}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type === "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.firm_state || "210357"}
                                options={nationality}
                                title="Страна"
                                count={1}
                                // onChange={actions.changeNationality}
                                onChange={changeBuyerSelect("firm_state")}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type !== "1" && (
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={buyer?.identity_document}
                                options={[
                                    { id: 5, name: "Иной документ" },
                                    { id: 1, name: "Паспорт" },
                                    { id: 3, name: "Свидетельство о рождении" },
                                    { id: 4, name: "Заграничный паспорт" },
                                    { id: 0, name: "ID карта" },
                                ]}
                                title="Тип документа"
                                count={1}
                                onChange={changeBuyerSelect("identity_document")}
                                required
                            />
                        </Box>
                    )}
                    {buyer?.buyer_type === "2" && (
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label="ИНН"
                                variant="outlined"
                                fullWidth
                                helperText={errors.inn}
                                error={!!errors.inn}
                                value={buyer?.inn || ""}
                                onChange={changeInnHandler("inn")}
                                required
                            />
                        </Box>
                    )}
                    <Box style={{ marginBottom: 15 }}>
                        <BirthDateInput
                            onChange={changeBuyerSelect("agency_docdate")}
                            count={1}
                            label="Дата договора с клиентом"
                            value={buyer?.agencydocdate || null}
                            required
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label="Номер договора с клиентом"
                            variant="outlined"
                            fullWidth
                            helperText={errors.agency_docnumber}
                            error={!!errors.agency_docnumber}
                            value={buyer?.agency_docnumber || ""}
                            onChange={changeDocNumber("agency_docnumber")}
                            required
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label="Сумма договора с клиентом"
                            variant="outlined"
                            fullWidth
                            helperText={errors.agency_docsum}
                            error={!!errors.agency_docsum}
                            value={buyer?.agency_docsum || ""}
                            onChange={changeDocSum("agency_docsum")}
                            required
                        />
                    </Box>
                </div>
            </div>
        </>
    );
}

export default BuyerBlock;
