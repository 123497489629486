import React from "react"
import styled from "styled-components"
import {Text} from "../../../../../ui"
import FlightList from "./FlightList"

import { useOrderPosition } from "../../useItemProps"

const DetailButtonWrapper = styled.div`
  display: grid;
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // left: 0;
  grid-template-columns: 4fr 60px 1.25fr;
`

const DetailButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${Text} {
    color: #4872F2;
  }
`

const Arrow = styled.button`
  position: relative;
  outline: none;
  border: none;
  height: 19px;
  width: 18px;
  padding: 0;
  background: transparent;
  margin-left: 5px;
  pointer-events: none;
  transition: transform 0.5s;
  ${({ open }) => (open ? "transform: rotate(180deg);" : "")}
  &:before,
  &:after {
    content: "";
    background: #4872f2;
    width: 10px;
    height: 1px;
    position: absolute;
    top: 50%;
  }
  &:before {
    transform: rotate(45deg) translateY(-50%);
    left: 0;
  }
  &:after {
    transform: rotate(-45deg) translateY(-50%);
    right: 0;
  }
`

const Body = React.memo(({ onClick, ...props }) => {
  const { isOpen } = useOrderPosition()

  return (
    <>
      <FlightList
        items={props.segmentGroups.map(item => ({
          ...item,
          transferCount: props.transferCount
        }))}
      />
      <DetailButtonWrapper>
        <div />
        <div />
        <DetailButton onClick={onClick}>
          <Text>Открыть детали</Text> <Arrow open={isOpen} />
        </DetailButton>
      </DetailButtonWrapper>
    </>
  )
})

export default Body
