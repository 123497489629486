import flow from "lodash/flow"
import flatten from "lodash/flatten"
import formatPrice from "../../../functions/formatPrice"

export const translatePassengerType = type => {
  switch (type) {
    case "Adult":
      return "взрослый"
    case "Child":
      return "ребенок"
    default:
      return "младенец"
  }
}

export const translateItemType = type => {
  switch (type) {
      case "SeatAssignment":
      return "место в самолете"
      case "Meal":
      return "питание"
      default:
      return "багаж"
  }
}

export function createDescriptionRows(args) {
  console.log(args)
  const arr = [];
  // console.log(args);
  const patronymic = args.patronymic ? args.patronymic : ""
  const name = args.lastName + " " + args.firstName + " " + patronymic
  arr.push({title: `Пассажир ${args.index + 1}, ${translatePassengerType(args.passengerType)}`, value: name});

  const fullPrice = args.price
  ? Object.entries(args.price).reduce((acc, x) => {
      const [key, value] = x
      return key !== "discount" ? acc + value : acc
      }, 0)
  : 0

  // arr.push({title: '',value: ''});


  // eslint-disable-next-line default-case
  switch(args.itemType) {
      case 'passenger': {
      if(!!args.eTicketNumber) {
          arr.push({title: 'Номер билета',value: args.eTicketNumber});
      }
      arr.push({title: 'Стоимость билета',value: formatPrice(fullPrice)});
      break;
      }

      case 'service': {
        arr.push({title: 'Название доп. услуги',value: args.name});
        arr.push({title: 'Стоимость доп. услуги',value: formatPrice(args.basePrice + args.agentExtraCharge)});
        arr.push({title: 'Тип доп. услуги:',value: translateItemType(args.type)});
        if(!!args.emd) {
          arr.push({title: 'Номер EMD:',value: args.emd});
        }
        break;
      }

      case 'insurance': {
        arr.push({title: 'Название страховки',value: args.name});
        arr.push({title: 'Стоимость страховки',value: formatPrice(args.basePrice + args.agentExtraCharge)});
        break;
      }
      
  }

  if(args.calculationType === 'refund') {
      arr.push({title: 'Сумма удержаний за возврат',value: formatPrice(args.retention)});
  }

  return arr;
}

export const formatTableRows = flow(
  item => {
    return [createParamsRows(item), createTaxRows(item)]
  },
  flatten,
  data => data.filter(x => !!x.cost)
)

function createParamsRows({ type, supportTicketCalculationParameter }) {
  return [
    {
      label:
        type === 'payment' ? "Сбор за обмен" : "Сбор за возврат",
      cost: supportTicketCalculationParameter?.refundTax
    },
    {
      label: "Сбор субагента",
      cost: supportTicketCalculationParameter?.subAgencyTax
    },
    {
      label: "Добор по тарифу",
      cost: supportTicketCalculationParameter?.tariff
    },
    {
      label:
        type === 'payment'
          ? "Сбор авиакомпании за обмен"
          : "Сбор авиакомпании за возврат",
      cost: supportTicketCalculationParameter?.aviaCompanyRefundTax
    },
    {
      label: "Сбор за аннулирование",
      cost: supportTicketCalculationParameter?.aviaCompanyCancellationTax
    },
    {
      label: "Сбор агенства",
      cost: supportTicketCalculationParameter?.agencyTax
    },
    {
      label: "Удержание за тариф",
      cost: supportTicketCalculationParameter?.usedRouteTax
    },
    {
      label: "Сбор платежной системы",
      cost: supportTicketCalculationParameter?.paySystemTax
    },
    {
      label: "Возвращено клиенту",
      cost: supportTicketCalculationParameter?.customerRefund
    }
  ]
}

function createTaxRows({ supportTicketCalculationTax }) {
  const hashMap = supportTicketCalculationTax
    ? Object.entries(supportTicketCalculationTax).reduce(
        (acc, [key, value]) => {
          const isCost = key.includes("tax")
          const index = (key.match(/\d/) || [])[0] || "undefined"
          const defaultObject = { ...acc[index] } || {
            label: "",
            cost: 0
          }
          if (isCost) {
            defaultObject.cost = value
          } else {
            defaultObject.label = value
          }

          return {
            ...acc,
            [index]: defaultObject
          }
        },
        {}
      )
    : {}

  return Object.entries(hashMap).map(([key, value]) => value)
}


export function createCalculationParams(data) {
  const calculationType = data.type.code === "ExtraPayment" ? 'payment' : 'refund'

      
  const passengers = (data.selectedTickets || []).map((x,key) => {
    const calculation = data.supportTicketCalculationItems
      ? data.supportTicketCalculationItems.find(
          y => y.aviaPassengerId === x.id
        )
      : null
    const ticketPrice = x.price
      ? Object.entries(x.price).reduce((acc, x) => {
          const [key, value] = x
          return key !== "discount" ? acc + value : acc
        }, 0)
      : 0

    const taxRows = calculation
      ? formatTableRows({
          type: calculationType,
          ...calculation
        })
      : []

    
    const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0)  
    const total =  retention || 0
    const finalPrice =  calculationType === 'payment' ?  total : calculation?.supportTicketCalculationParameter.refundAmount || 0;
    const rows = createDescriptionRows({...x,calculationType,itemType: 'passenger',index: key,retention});
    return {
      ...x,
      ticketPrice,
      taxRows,
      rows,
      retention,
      total,
      finalPrice: {title: `Сумма ${calculationType === 'payment' ? 'доплаты' : 'возврата'} по билету`, value: formatPrice(finalPrice)},
    }
  })

  
  const hashMap = {};
  passengers.forEach((x,key) => {
  hashMap[`${x.lastName}-${x.firstName}-${x.passengerType}`] = key;
  })

  // console.log(passengersIndexMap)

  const ancillaryServices = (data.selectedAncillaryServices || [])
    .map(x => {
      const calculation = data.supportTicketCalculationItems
        ? data.supportTicketCalculationItems.find(
            y => y.ancillaryServiceId === x.ancillaryServicetId
          )
        : null

      const taxRows = calculation
        ? formatTableRows({
            type: calculationType,
            ...calculation
          })
        : []
      
      const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0)
      const total =
      data.supportTicketCalculation?.type.code === "ExtraPayment"
        ? data.supportTicketCalculation?.total || 0
        : retention || 0
      const rows = createDescriptionRows({
        ...x,
        calculationType,
        itemType: 'service',
        index: hashMap[`${x.passenger.lastName}-${x.passenger.firstName}-${x.passenger.passengerType}`],
        retention,
        total,
        ...x.passenger
      });
      const finalPrice =  calculationType === 'payment' ?  data.supportTicketCalculation?.total || 0 : calculation?.supportTicketCalculationParameter.refundAmount || 0;

      return {
        ...x,
        taxRows,
        rows,
        retention,
        total,
        index: hashMap[`${x.passenger.lastName}-${x.passenger.firstName}-${x.passenger.passengerType}`],
        finalPrice: {title: `Сумма ${calculationType === 'payment' ? 'доплаты' : 'возврата'} по услуге`, value: formatPrice(finalPrice)},
      }
    })
    .filter(x => x.taxRows.length > 0)

  const insurances = (data.selectedInsurances || [])
    .map(x => {
      const calculation = data.supportTicketCalculationItems
        ? data.supportTicketCalculationItems.find(
            y => y?.insurancePolicyId === x.id
          )
        : null
      
      const total =
        data.type.code === "ExtraPayment"
          ? data.total || 0
          : calculation?.supportTicketCalculationParameter.refundAmount || 0

      const taxRows = calculation
        ? formatTableRows({
          type: calculationType,
            ...calculation
          })
        : []
      const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0)  
      const rows = createDescriptionRows({
        ...x,calculationType,
        itemType: 'insurance',
        index: hashMap[`${x.passenger.lastName}-${x.passenger.firstName}-${x.passenger.passengerType}`],
        retention,
        ...x.passenger
      });
      const finalPrice =  calculationType === 'payment' ?  data.supportTicketCalculation?.total || 0 : calculation?.supportTicketCalculationParameter.refundAmount || 0;

      return {
        ...x,
        taxRows,
        rows,
        retention,
        finalPrice: {title: `Сумма ${calculationType === 'payment' ? 'доплаты' : 'возврата'} по страховке`, value: formatPrice(finalPrice)},
        total
      }
    })
    .filter(x => x.taxRows.length > 0)

  const total =
    data.type.code === "ExtraPayment"
      ? data.total
      : data.supportTicketCalculationItems?.reduce(
          (acc, x) => acc + x?.supportTicketCalculationParameter?.refundAmount,
          0
        )

  const {supportTicketCalculation,...rest} = data;
  
  return {
      items: [...passengers,...ancillaryServices,...insurances],
      ...rest,
      ...supportTicketCalculation,
      type: calculationType,
      total, 
  }
}