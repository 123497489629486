import React, { useEffect, useState } from 'react'
import { Alert, tooltipClasses } from '@mui/material'

import './style.css'
import CustomSelect from '../../Select'
import { Box, Snackbar, TextField } from '@material-ui/core'
import BirthDateInput from './components/BirthDateInput'
import {
    emailRegExp,
    latinNameRegExp,
    // phoneRegExp,
    interPassportNumberRegExp,
    cyrillicRegExp,
    seriesBirthCertificatesRegExp,
    rfPassportSeriesRegExp,
    interPassportSeriesRegExp,
    rfPassportNumberRegExp,
    birthCertNumberRegExp,
    latinUpperAndNumberRegExp,
    latinAndNumberRegExp
} from '../../../constants/reg-exps'
import { useErrorStore } from './store'
import { nationality } from './nationalities'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, color, children, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {children}
    </Tooltip>
))(({ theme, color }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
        backgroundColor: color ? 'red' : 'white',
        color: color ? 'white' : 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[7],
        fontSize: 14,
        padding: 10,
        maxWidth: 500
    },
    '& .MuiTooltip-arrow': {
        transform: 'translate(20px, 0px)!important',
        color: color ? 'red' : 'white'
    },
    color
}))

const PaxesBlock = ({ count, title, store }) => {
    const paxe =  store?.paxes.find(item => item.id === count)
    
    const phoneRegExp =
    /^7?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

    const [gender, setGender] = React.useState('0')
    const [nation, setNation] = React.useState(false)
    const [typeDoc, setTypeDoc] = React.useState(paxe.document.type || 1)

    const isRussianPassportEnabled = false
    const actions =  store.actions

    const setErrorForm = useErrorStore(state => state.setErrorForm)
    const valid = useErrorStore(state => state.valid)

    const [errors, setErrors] = useState({
        lastName: null,
        firstName: null,
        patronymic: null,
        phone: null,
        email: null,
        series: null,
        number: null,
        expirationDate: null,
        birthDate: null,
    })

    useEffect(() => {
		if (Object.values(errors).some(item => item)) {
			setErrorForm(true)
		} else {
			setErrorForm(false)
		}
		// eslint-disable-next-line
	}, [errors])


    const errorForm = useErrorStore(state => state.error)

    const [phoneErrorGds, setPhoneErrorGds] = useState(false)
    const [openTooltip, setOpenTooltip] = useState(false)
    const isBookingError = store.isBookingError

    React.useEffect(() => {
        if (!errorForm && Object.values(errors).some(value => value)) {
            setErrorForm(true)
        }
    // eslint-disable-next-line
    }, [errorForm])

    React.useEffect(() => {
        if (isBookingError) {
            setServerError(isBookingError)
        }
    // eslint-disable-next-line
    }, [isBookingError])

    const setServerError = (err) => {
        const errText = err?.split(': ')[1]

        if (errText?.includes(`${paxe?.person?.lastName} ${paxe?.person?.firstName} ${paxe?.person?.patronymic}`)) {

            let typeError
            if (errText.includes('некорректно указан e-mail')) {
                typeError = 'email'
            }
            if (errText.includes('Проверьте срок действия паспорта')) {
                typeError = 'passportExpirationDate'
            }
            switch (typeError) {
                case 'email':
                    setErrors((prev) => ({
                        ...prev,
                        email: 'Email указан некорректно'
                    }));
                    setErrorForm(true)
                    document.getElementById(`info-${count}`).scrollIntoView({ behavior: 'smooth' })
                    break;
                case 'passportExpirationDate':
                    setErrors((prev) => ({
                        ...prev,
                        expirationDate: 'Проставьте даты +5лет'
                    }));
                    // setErrorForm(true)
                    document.getElementById(`info-${count}`).scrollIntoView({ behavior: 'smooth' })
                    break;
                default:
                    return null
            }
        }
    }



    const changeFirstNameLatinHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (
            !latinNameRegExp.test(event.target.value) &&
            event.target.value !== ''
        ) {
            setErrors({
                ...errors,
                [prop]: `Имя должно содержать только латиницу`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changeFirstNameLatin(event.target.value, count)
    }
    const changeLastNameLatinHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (
            !latinNameRegExp.test(event.target.value) &&
            event.target.value !== ''
        ) {
            setErrors({
                ...errors,
                [prop]: `Фамилия должна содержать только латиницу`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changeLastNameLatin(event.target.value, count)
    }

    const changeFirstNameHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors({
                ...errors,
                [prop]: `Имя должно содержать только кириллицу`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changeFirstName(event.target.value, count)
    }
    const changeLastNameHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors({
                ...errors,
                [prop]: `Фамилия должна содержать только кириллицу`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changeLastName(event.target.value, count)
    }
    const changePatronymicHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (!cyrillicRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors({
                ...errors,
                [prop]: `Отчество должно содержать только кириллицу`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changePatronymic(event.target.value, count)
    }

    const changePhoneHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if ((!phoneRegExp.test(event.target.value) && event.target.value !== '') || event.target.value.length !== 11) {
            setErrors({
                ...errors,
                [prop]: `Допустимая длина номера 11 символов, формата  79876543210`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changePhone(event.target.value, count)
    }
    const changeEmailHandler = prop => event => {
        setErrors({ ...errors, [prop]: null })
        if (!emailRegExp.test(event.target.value) && event.target.value !== '') {
            setErrors({
                ...errors,
                [prop]: `Email указан некорректно`
            })
            setErrorForm(true)
        } else {
            setErrors({ ...errors, [prop]: null })
            setErrorForm(false)
        }
        actions.changeEmail(event.target.value, count)
    }

    const changeNationHandler = (e, n) => {
        setNation(e)
        actions.changeNationality(e, n)
    }
    const changeGenderHandler = (e, n) => {
        setGender(e)
        actions.changeGender(e, n)
    }
    const changeTypeDocHandler = (e, n) => {
        setTypeDoc(e)
        actions.changeTypeDocument(e, n)
    }

    const changeSeriesHandler = prop => event => {
        const value = event.target.value
        setErrorForm(false)
        setErrors({ ...errors, [prop]: null })
        switch (typeDoc) {
            case 3:
                if (!interPassportSeriesRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать 2 цифры`
                    })
                    setErrorForm(true)
                }
                break
            case 1:
                if (!rfPassportSeriesRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать 4 цифры`
                    })
                    setErrorForm(true)
                }
                break
            case 2:
                if (!seriesBirthCertificatesRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать римское число и две кириллические буквы, через дефис`
                    })
                    setErrorForm(true)
                }
                break
            case 4:
                if (value.length <= 5) {
                    setErrors({
                        ...errors,
                        [prop]: `Серия не менее 5 знаков`
                    })
                    setErrorForm(true)
                }
                break
            case 6:
                if (!interPassportSeriesRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Серия должна содержать 2 цифры`
                    })
                    setErrorForm(true)
                }
                break
            case 7:
                if (value.length <= 5) {
                    setErrors({
                        ...errors,
                        [prop]: `Серия не менее 5 знаков`
                    })
                    setErrorForm(true)
                }
                break
            default:
                setErrorForm(false)
                setErrors({ ...errors, [prop]: null })
                break
        }

        actions.changeSeries(value, count)
    }

    const changeNumberHandler = prop => event => {
        const value = event.target.value
        setErrors({ ...errors, [prop]: null })
        setErrorForm(false)
        switch (typeDoc) {
            case 3:
                if (!interPassportNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен быть 7 цифр`
                    })
                    setErrorForm(true)
                }
                break
            case 1:
                if (!rfPassportNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен быть 6 цифр`
                    })
                    setErrorForm(true)
                }
                break
            case 2:
                if (!birthCertNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен быть 6 цифр`
                    })
                    setErrorForm(true)
                }
                break
            case 4:
                if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
                    })
                    setErrorForm(true)
                }
                break
            case 6:
                if (!latinAndNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
                    })
                    setErrorForm(true)
                }
                break
            case 7:
                if (!latinUpperAndNumberRegExp.test(value) && value !== '') {
                    setErrors({
                        ...errors,
                        [prop]: `Номер должен содержать только цифры и заглавные латинские буквы`
                    })
                    setErrorForm(true)
                }
                break
            default:
                setErrorForm(false)
                setErrors({ ...errors, [prop]: null })
                break
        }

        actions.changeNumber(value, count)
    }

    const [error, setError] = useState(null)

    const checkValidationError = paxe => {
        if (
            !cyrillicRegExp.test(paxe.person.lastName) ||
            !paxe.person.lastName
        ) {
            setErrors(errors => ({
                ...errors,
                lastName: `Фамилия должна содержать только кириллицу`
            }))
            setErrorForm(true)
        }
        if (
            !cyrillicRegExp.test(paxe.person.firstName) ||
            !paxe.person.firstName
        ) {
            setErrors(errors => ({
                ...errors,
                firstName: `Имя должно содержать только кириллицу`
            }))
            setErrorForm(true)
        }
        if (!phoneRegExp.test(paxe?.contactInformation?.phone) || !paxe?.contactInformation?.phone) {
            setErrors(errors => ({
                ...errors,
                phone: `Допустимая длина номера 11 символов, формата  79876543210`
            }))
            setErrorForm(true)
        }
        if (!emailRegExp.test(paxe?.contactInformation?.email) || !paxe?.contactInformation?.email) {
            setErrors(errors => ({
                ...errors,
                email: `Email указан некорректно`
            }))
            setErrorForm(true)
        }
        if (
            !cyrillicRegExp.test(paxe.person.patronymic) &&
            !!paxe.person.patronymic
        ) {
            setErrors(errors => ({
                ...errors,
                patronymic: `Отчество должно содержать только кириллицу`
            }))
            setErrorForm(true)
        }
        if (!paxe?.document?.expirationDate) {
            setErrors(errors => ({
                ...errors,
                expirationDate: `Дата не может быть пустой`
            }))
            setErrorForm(true)
        }
        if (!paxe?.person?.birthDate) {
            setErrors(errors => ({
                ...errors,
                birthDate: `Дата не может быть пустой`
            }))
            setErrorForm(true)
        }

        switch (typeDoc) {
            case 3:
                if (
                    !interPassportSeriesRegExp.test(paxe?.document?.series) ||
                    !paxe?.document?.series
                ) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия должна содержать 2 цифры`
                    }))
                    setErrorForm(true)
                }
                break
            case 1:
                if (
                    !rfPassportSeriesRegExp.test(paxe?.document?.series) ||
                    !paxe?.document?.series
                ) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия должна содержать 4 цифры`
                    }))
                    setErrorForm(true)
                }
                break
            case 2:
                if (
                    !seriesBirthCertificatesRegExp.test(paxe?.document?.series) ||
                    !paxe?.document?.series
                ) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия должна содержать римское число и две кириллические буквы, через дефис`
                    }))
                    setErrorForm(true)
                }
                break
            case 4:
                if (paxe?.document?.series.length <= 5) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия не менее 5 знаков`
                    }))
                    setErrorForm(true)
                }
                break
            case 6:
                if (
                    !interPassportSeriesRegExp.test(paxe?.document?.series) ||
                    !paxe?.document?.series
                ) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия должна содержать 2 цифры`
                    }))
                    setErrorForm(true)
                }
                break
            case 7:
                if (paxe?.document?.series.length <= 5) {
                    setErrors(errors => ({
                        ...errors,
                        series: `Серия не менее 5 знаков`
                    }))
                    setErrorForm(true)
                }
                break
            default:
                setErrors(errors => ({ ...errors, series: null }))
                break
        }

        switch (typeDoc) {
            case 3:
                if (
                    !interPassportNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен быть 7 цифр`
                    }))
                    setErrorForm(true)
                }
                break
            case 1:
                if (
                    !rfPassportNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен быть 6 цифр`
                    }))
                    setErrorForm(true)
                }
                break
            case 2:
                if (
                    !birthCertNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен быть 6 цифр`
                    }))
                    setErrorForm(true)
                }
                break
            case 4:
                if (
                    !latinUpperAndNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен содержать только цифры и заглавные латинские буквы`
                    }))
                    setErrorForm(true)
                }
                break
            case 6:
                if (
                    !latinAndNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен содержать только цифры только цифры и прописные латинские буквы`
                    }))
                    setErrorForm(true)
                }
                break
            case 7:
                if (
                    !latinUpperAndNumberRegExp.test(paxe?.document?.number) ||
                    !paxe?.document?.number
                ) {
                    setErrors(errors => ({
                        ...errors,
                        number: `Номер должен содержать только цифры и заглавные латинские буквы`
                    }))
                    setErrorForm(true)
                }
                break
            default:
                setErrors(errors => ({ ...errors, number: null }))
                break
        }
    }

    useEffect(() => {
        valid && checkValidationError(paxe)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid])

    const onBlur = (prop, event) => {
        const upperCaseValue = event.target.value.toUpperCase()
        switch (prop) {
            case 'firstName':
                changeFirstNameHandler('firstName')({
                    ...event,
                    target: {
                        ...event.target,
                        value: upperCaseValue
                    }
                })
                break
            case 'lastName':
                changeLastNameHandler('lastName')({
                    ...event,
                    target: {
                        ...event.target,
                        value: upperCaseValue
                    }
                })
                break
            case 'patronymic':
                changePatronymicHandler('patronymic')({
                    ...event,
                    target: {
                        ...event.target,
                        value: upperCaseValue
                    }
                })
                break
            default:
                return
        }
    }

    return (
        <>
            <Snackbar
                open={error}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                    onClose={() => setError(null)}
                    severity='error'
                    sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <div className='block-header' id={`info-${count}`}>
                <h3 className='title'>
                    <span>{title ? title : `Информация о туристе ${count}`}</span>
                    {/* {agentInfo && agentInfo.agents?.name.includes('F&S') && <span className={'switcher-pl'}>
                        Участник программы лояльности{' '}
                        <Switch
                            checked={memberId === count}
                            value={count}
                            onChange={handleChangePL}
                            color='primary'
                        />
                    </span>} */}
                </h3>
            </div>
            <Alert severity='error' sx={{ width: '100%' }}>
                Убедительно просим Вас при бронировании корректно внести ВСЕ ПОЛЯ в
                информации о туристе!
            </Alert>
            <div className='members-form'>
                {isRussianPassportEnabled ? (
                    <div className='info'>
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={paxe.person.gender || '0'}
                                options={[
                                    { id: '0', name: 'Муж.' },
                                    { id: '1', name: 'Жен.' }
                                ]}
                                title='Пол'
                                count={count}
                                onChange={changeGenderHandler}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Фамилия по-латински'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.lastName || ''}
                                onChange={changeLastNameLatinHandler('lastName')}
                                helperText={errors.lastName}
                                error={!!errors.lastName}
                                onBlur={event => onBlur('lastName', event)}
                                required
                                title='В фамилии допустимы только прописные латинские буквы'
                            />
                        </Box>
                        {/* </FormControl> */}
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Имя по-латински'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.firstName || ''}
                                onChange={changeFirstNameLatinHandler('firstName')}
                                helperText={errors.firstName}
                                error={!!errors.firstName}
                                onBlur={event => onBlur('firstName', event)}
                                required
                                title='В имени допустимы только прописные латинские буквы'
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Фамилия по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.lastName || ''}
                                onChange={changeLastNameHandler('lastName')}
                                helperText={errors.lastName}
                                error={!!errors.lastName}
                                onBlur={event => !errors.lastName && onBlur('lastName', event)}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Имя по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.firstName || ''}
                                onChange={changeFirstNameHandler('firstName')}
                                helperText={errors.firstName}
                                error={!!errors.firstName}
                                onBlur={event =>
                                    !errors.firstName && onBlur('firstName', event)
                                }
                                required

                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Отчество по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.patronymic || ''}
                                onChange={changePatronymicHandler('patronymic')}
                                helperText={errors.patronymic}
                                error={!!errors.patronymic}
                                onBlur={event =>
                                    !errors.patronymic && onBlur('patronymic', event)
                                }
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <BirthDateInput
                                onChange={actions.changeBirthDate}
                                count={count}
                                label='Дата рождения'
                                value={paxe.person.birthDate || null}
                                setErrors={setErrors}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <LightTooltip
                                open={phoneErrorGds || openTooltip}
                                color={phoneErrorGds}
                                onFocus={() => setOpenTooltip(true)}
                                onBlur={() => setOpenTooltip(false)}
                                title={
                                    <>
                                        {phoneErrorGds && (
                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                Телефон в формате код страны + код оператора/города +
                                                номер
                                            </div>
                                        )}
                                        <div>"+" не указывать, пример 79031111111</div>
                                    </>
                                }
                                arrow
                                disableHoverListener
                                placement='top-start'>
                                <TextField
                                    label='Телефон туриста для связи'
                                    variant='outlined'
                                    fullWidth
                                    helperText={errors.phone}
                                    error={!!errors.phone}
                                    value={paxe.contactInformation.phone || ''}
                                    onChange={changePhoneHandler('phone')}
                                    required
                                />
                            </LightTooltip>
                        </Box>
                        <Box>
                            <TextField
                                label='E-Mail'
                                variant='outlined'
                                fullWidth
                                helperText={errors.email}
                                error={!!errors.email}
                                value={paxe.contactInformation.email || ''}
                                onChange={changeEmailHandler('email')}
                                required
                            />
                        </Box>
                    </div>
                ) : (
                    <div className='info'>
                        <Box style={{ marginBottom: 15 }}>
                            <CustomSelect
                                value={gender || paxe.person.gender || '0'}
                                options={[
                                    { id: '0', name: 'Муж.' },
                                    { id: '1', name: 'Жен.' }
                                ]}
                                title='Пол'
                                count={count}
                                onChange={changeGenderHandler}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Фамилия по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.lastName || ''}
                                onChange={changeLastNameHandler('lastName')}
                                helperText={errors.lastName}
                                error={!!errors.lastName}
                                onBlur={event => !errors.lastName && onBlur('lastName', event)}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Имя по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.firstName || ''}
                                onChange={changeFirstNameHandler('firstName')}
                                helperText={errors.firstName}
                                error={!!errors.firstName}
                                onBlur={event =>
                                    !errors.firstName && onBlur('firstName', event)
                                }
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <TextField
                                label='Отчество по-русски'
                                variant='outlined'
                                fullWidth
                                value={paxe.person.patronymic || ''}
                                onChange={changePatronymicHandler('patronymic')}
                                helperText={errors.patronymic}
                                error={!!errors.patronymic}
                                onBlur={event =>
                                    !errors.patronymic && onBlur('patronymic', event)
                                }
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <BirthDateInput
                                onChange={actions.changeBirthDate}
                                count={count}
                                label='Дата рождения'
                                value={paxe.person.birthDate || null}
                                birthDateErorr={errors?.birthDate}
                                setErrors={setErrors}
                                required
                            />
                        </Box>
                        <Box style={{ marginBottom: 15 }}>
                            <LightTooltip
                                open={phoneErrorGds || openTooltip}
                                color={phoneErrorGds}
                                onFocus={() => setOpenTooltip(true)}
                                onBlur={() => setOpenTooltip(false)}
                                title={
                                    <>
                                        {phoneErrorGds && (
                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                Телефон в формате код страны + код оператора/города +
                                                номер
                                            </div>
                                        )}
                                        <div>"+" не указывать, пример 79031111111</div>
                                    </>
                                }
                                arrow
                                disableHoverListener
                                placement='top-start'>
                                <TextField
                                    label='Телефон'
                                    variant='outlined'
                                    fullWidth
                                    helperText={errors.phone}
                                    error={!!errors.phone}
                                    value={paxe?.contactInformation?.phone || ''}
                                    onChange={changePhoneHandler('phone')}
                                    required
                                />
                            </LightTooltip>
                        </Box>
                        <Box>
                            <TextField
                                label='E-Mail'
                                variant='outlined'
                                fullWidth
                                helperText={errors.email}
                                error={!!errors.email}
                                value={paxe?.contactInformation?.email || ''}
                                onChange={changeEmailHandler('email')}
                                required
                            />
                        </Box>
                    </div>
                )}
                <div className='docs'>
                    <Box style={{ marginBottom: 15 }}>
                        <CustomSelect
                            value={nation || paxe.person.nationality || '210357'}
                            options={nationality}
                            title='Гражданство'
                            count={count}
                            onChange={changeNationHandler}
                            required
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <CustomSelect
                            value={typeDoc || paxe.document.type || 1}
                            options={[
                                { id: 0, name: 'Иной документ' },
                                { id: 1, name: 'Паспорт' },
                                { id: 2, name: 'Свидетельство о рождении' },
                                { id: 3, name: 'Заграничный паспорт' },
                                { id: 4, name: 'ID карта' }
                            ]}
                            title='Тип документа'
                            count={count}
                            onChange={changeTypeDocHandler}
                            required
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label='Серия документа'
                            variant='outlined'
                            fullWidth
                            helperText={errors.series}
                            error={!!errors.series}
                            value={paxe.document.series || ''}
                            onChange={changeSeriesHandler('series')}
                            required
                            title='Серия паспорта может содержать только цифры и прописные латинские буквы'
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <TextField
                            label='Номер документа'
                            variant='outlined'
                            fullWidth
                            helperText={errors.number}
                            error={!!errors.number}
                            value={paxe.document.number || ''}
                            onChange={changeNumberHandler('number')}
                            required
                            title='Номер паспорта должен содержать только цифры и заглавные латинские буквы'
                        />
                    </Box>
                    <Box style={{ marginBottom: 15 }}>
                        <BirthDateInput
                            onChange={actions.changeExpirationDate}
                            count={count}
                            label='Действителен до'
                            value={paxe.document.expirationDate || null}
                            isExpiration={true}
                            birthDateErorr={errors?.expirationDate}
                            setErrors={setErrors}
                            required
                        />
                    </Box>
                </div>
            </div>
            {/* {modalVisible && <MembersBlockModal
                title='Сообщение'
                onClose={setModalVisible}
            />} */}
        </>
    )
}

export default PaxesBlock
