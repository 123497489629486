import { useEffect, useState } from 'react'

import './style.scss'
import ContentWithPagination from './ContentWithPagination'
import { useMediaQuery } from '@mui/material'
import useActionsStore from '../store'
import '../style.css'

function BlockWithActions({ filteredActions }) {
	const actions = useActionsStore(({ actions }) => actions)
	const isSmallScreen = useMediaQuery('(min-width:700px)')
	const [relevance, setRelevance] = useState('actual')
	const [displayOption, setDisplayOption] = useState('grid')

	const [filter, setFilter] = useState('Все')
	const handleChangeFilter = field => {
		setFilter(field)
	}
	const { actual, past } = getFilteredAction(filteredActions)

	function getFilteredAction(initialActions) {
		if (!initialActions) {
			return { actual: [], past: [] }
		}

		let filteredActions =
			filter !== 'Все'
				? initialActions.filter(item => {
						return item.categories[0]?.title.includes(filter)
				  })
				: initialActions

		return {
			actual: filteredActions.filter(el => !el.archive),
			past: filteredActions.filter(el => el.archive)
		}
	}

	const handleRelevance = view => {
		setRelevance(view)
	}

	useEffect(() => {
		actions.getActions()
	}, [actions])

	if (!filteredActions) {
		return null
	}
	// console.log('action', filteredActions)

	return (
		<>
			<div className={'filter-panel'}>
				<div
					className={`item ${filter === 'Все' ? 'selected' : ''}`}
					onClick={() => handleChangeFilter('Все')}>
					Все
				</div>
				{[
					...new Set(
						relevance === 'actual'
							? filteredActions
									.filter(item => !item.archive)
									.map(item => {
										return item.categories[0]?.title
									})
							: filteredActions
									.filter(item => item.archive)
									.map(item => {
										return item.categories[0]?.title
									})
					)
				]
					.filter(item => item)
					.map(item => (
						<div
							className={`item ${filter === item ? 'selected' : ''}`}
							onClick={() => handleChangeFilter(item)}>
							{item}
						</div>
					))}
			</div>

			<div
				className={`${isSmallScreen ? ' ' : 'hidden-desktop'} ${
					!isSmallScreen ? ' ' : 'hidden-mobile'
				}`}>
				<div className='actions-switch'>
					<div className='switch-left'>
						<div
							onClick={() => handleRelevance('actual')}
							className={`switch-item ${
								relevance === 'actual' ? 'active' : ''
							}`}>
							Актуальные {actual.length}
						</div>
						<div
							onClick={() => handleRelevance('past')}
							className={`switch-item ${relevance === 'past' ? 'active' : ''}`}>
							Прошедшие {past.length}
						</div>
					</div>
					<div className='switch-right'>
						<div
							onClick={() => setDisplayOption('grid')}
							className={`variant-item ${
								displayOption === 'grid' ? 'active' : ''
							}`}>
							<svg
								data-v-7fb23a56=''
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									data-v-7fb23a56=''
									fillRule='evenodd'
									clipRule='evenodd'
									d='M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0L21.75 0C22.3467 0 22.919 0.237053 23.341 0.65901C23.7629 1.08097 24 1.65326 24 2.25V21.75C24 22.3467 23.7629 22.919 23.341 23.341C22.919 23.7629 22.3467 24 21.75 24H2.25C1.65326 24 1.08097 23.7629 0.65901 23.341C0.237053 22.919 0 22.3467 0 21.75L0 2.25ZM2.25 1.5C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V7.5H7.5V1.5H2.25ZM7.5 9H1.5V15H7.5V9ZM9 15V9H15V15H9ZM7.5 16.5H1.5V21.75C1.5 21.9489 1.57902 22.1397 1.71967 22.2803C1.86032 22.421 2.05109 22.5 2.25 22.5H7.5V16.5ZM9 16.5H15V22.5H9V16.5ZM16.5 16.5V22.5H21.75C21.9489 22.5 22.1397 22.421 22.2803 22.2803C22.421 22.1397 22.5 21.9489 22.5 21.75V16.5H16.5ZM16.5 15H22.5V9H16.5V15ZM16.5 7.5H22.5V2.25C22.5 2.05109 22.421 1.86032 22.2803 1.71967C22.1397 1.57902 21.9489 1.5 21.75 1.5H16.5V7.5ZM15 7.5H9V1.5H15V7.5Z'></path>
							</svg>
						</div>
						<div
							onClick={() => setDisplayOption('table')}
							className={`variant-item ${
								displayOption === 'table' ? 'active' : ''
							}`}>
							<svg
								data-v-7fb23a56=''
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									data-v-7fb23a56=''
									fillRule='evenodd'
									clipRule='evenodd'
									d='M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0L21.75 0C22.3467 0 22.919 0.237053 23.341 0.65901C23.7629 1.08097 24 1.65326 24 2.25V21.75C24 22.3467 23.7629 22.919 23.341 23.341C22.919 23.7629 22.3467 24 21.75 24H2.25C1.65326 24 1.08097 23.7629 0.65901 23.341C0.237053 22.919 0 22.3467 0 21.75L0 2.25ZM2.25 1.5C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V7.5H7.5H9H15H16.5H22.5V2.25C22.5 2.05109 22.421 1.86032 22.2803 1.71967C22.1397 1.57902 21.9489 1.5 21.75 1.5H16.5H15H9H7.5H2.25ZM7.5 9H1.5V15H7.5H9H15H16.5H22.5V9H16.5H15H9H7.5ZM7.5 16.5H1.5V21.75C1.5 21.9489 1.57902 22.1397 1.71967 22.2803C1.86032 22.421 2.05109 22.5 2.25 22.5H7.5H9H15H16.5H21.75C21.9489 22.5 22.1397 22.421 22.2803 22.2803C22.421 22.1397 22.5 21.9489 22.5 21.75V16.5H16.5H15H9H7.5Z'></path>
							</svg>
						</div>
					</div>
				</div>
				<ContentWithPagination
					actionsList={relevance === 'actual' ? actual : past}
					displayOption={displayOption}
				/>
			</div>
		</>
	)
}

export default BlockWithActions
