import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import useExcursionStore from '../../../store'
import ExcursionFilter from './ExcursionFilter'

const Title = styled.div`
	font-family: Open Sans;
	color: #2e2e32;
	font-weight: 600;
	font-size: 24px;
	line-height: 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const SaveButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	background: '#FFE100',
	borderRadius: '8px',
	color: '#2e2e32',
	textTransform: 'none',
	fontSize: '14px',
	fontWeight: 600,
	height: '46px',
	lineHeight: '22px',
	padding: '12px 24px',
	textAlign: 'center',
	textDecoration: 'none',
	fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(','),
	'&:hover': {
		background: '#FFE733',
		boxShadow: 'none'
	},
	'&:focus': {
		boxShadow: 'none'
	},
	'&:active': {
		outline: '0px'
	}
}))

export const CancelButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	borderColor: '#4872f2',
	borderRadius: '8px',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: '14px',
	fontWeight: 600,
	height: '46px',
	lineHeight: '22px',
	padding: '12px 24px',
	textAlign: 'center',
	textDecoration: 'none',
	fontFamily: ['Open Sans', '"Segoe UI"', 'Roboto'].join(',')
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export default function MobileFilterPopup({ open, setOpen }) {
	const actions = useExcursionStore(({ actions }) => actions)

	const excursionType = useExcursionStore(({ excursionType }) => excursionType)
	const checkedExcursionType = useExcursionStore(
		({ checkedExcursionType }) => checkedExcursionType
	)

	const forWhom = useExcursionStore(({ forWhom }) => forWhom)
	const checkedForWhom = useExcursionStore(
		({ checkedForWhom }) => checkedForWhom
	)

	const activity = useExcursionStore(({ activity }) => activity)
	const checkedActivity = useExcursionStore(
		({ checkedActivity }) => checkedActivity
	)
	const handleClose = () => {
		setOpen(false)
	}

	const handleCancel = () => {
		actions.clearFilters()
		handleClose()
	}

	return (
		<React.Fragment>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				TransitionComponent={Transition}
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				<DialogTitle
					id='scroll-dialog-title'
					sx={{ pl: 2, pr: 2, pb: 2, pt: 1 }}>
					<Title>
						<Box>Фильтры</Box>
						<IconButton aria-label='close' size='small' onClick={handleClose}>
							<Close />
						</IconButton>
					</Title>
				</DialogTitle>
				<DialogContent sx={{ pl: 2, pr: 2, pb: 2 }}>
					<Box>
						<ExcursionFilter
							title='Тип экскурсий'
							list={excursionType}
							checked={checkedExcursionType}
							changeHandler={actions.changeExcursionType}
						/>
						<ExcursionFilter
							title='Для кого'
							list={forWhom}
							checked={checkedForWhom}
							changeHandler={actions.changeForWhom}
						/>
						<ExcursionFilter
							title='Активности'
							list={activity}
							checked={checkedActivity}
							changeHandler={actions.changeActivity}
							showDivider={false}
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						pl: 2,
						pt: '12px',
						pr: 2,
						pb: '12px',
						display: 'flex',
						justifyContent: 'center'
					}}
					style={{
						boxShadow:
							'0 0 10px rgba(0, 0, 0, 0.1), 0 15px 20px hsla(0, 0%, 6%, 0.15)'
					}}>
					<CancelButton fullWidth variant='outlined' onClick={handleCancel}>
						Сбросить
					</CancelButton>
					<SaveButton
						fullWidth
						onClick={handleClose}
						style={{ marginLeft: '12px' }}>
						Применить
					</SaveButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
