import React, { useEffect, useRef, useState } from 'react'
import { format, isValid } from 'date-fns'
import Calendar from 'react-calendar'
import { ReactComponent as CalendarImage } from '../../../../../asset/images/cabinet/calendar.svg'

import './style.css'
import { IconButton, InputAdornment } from '@mui/material'
import { FormInput } from '../FormInput'
// import { EventNoteOutlined } from '@material-ui/icons'

const NumberToDateRegex = /^(\d{2})(\d{2})(\d{4})$/
const DateRegex =
	/\b(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.((19|20)\d{2})\b/g
const ISODateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/

const CHAR_CODES = {
	DELETE: 46,
	DOT: 190,
	ZERO: 48,
	NINE: 57,
	MIN_CONTROL_CHAR_CODE: 31,
	DELETE_NAME: 'Delete',
	BACKSPACE: 8
}
const MAX_DAY = 31
const MAX_MONTH = 12

const BirthDateInput = ({ label, onChange, value, isExpiration, disable, ...props }) => {
	const [selectedDate, handleDateChange] = useState(value ?? '')
	const [selectedInputDate, handleInputDateChange] = useState(
		value ?? ''
	)
	const [showCalendar, setShowCalendar] = useState(false)

	const calendarRef = useRef(null)
	useEffect(() => {
		setShowCalendar(false)
	}, [selectedDate])

	useEffect(() => {
		handleDateChange(value ? new Date(value) : null)
		handleInputDateChange(formateInput(value))
	}, [value])

	const handleInputChange = event => {
		let value = event.target.value

		if (value.length > 10) {
			return
		}

		if (NumberToDateRegex.test(value) || DateRegex.test(value)) {
			const changeDate = value.replace(NumberToDateRegex, '$1.$2.$3').split('.')
			const date = new Date(changeDate[2], changeDate[1] - 1, changeDate[0])
			handleDateChange(date)
			handleInputDateChange(date)
			onChange(date)
			return
		}

		if (value.length === 2 && value.slice(0, 2) > MAX_DAY) {
			handleInputDateChange(`${MAX_DAY}.`)
			return
		}
		if (value.length === 5 && value.slice(3, 5) > MAX_MONTH) {
			handleInputDateChange(`${value.slice(0, 3)}${MAX_MONTH}.`)
			return
		}

		handleInputDateChange(value)
	}

	const formateInput = date => {
		if (ISODateRegex.test(date)) {
			const formatDate = new Date(date)
			return format(formatDate, 'dd.MM.yyyy')
		}
		if (isValid(date)) {
			return format(date, 'dd.MM.yyyy')
		}
		return date
	}
	const handleChange = date => {
		handleInputDateChange(date)
		handleDateChange(date)
		onChange(date)
	}

	const handleShowCalendar = () => {
		setShowCalendar(!showCalendar)
	}

	const onFocus = event => {
		if (event.target.value === 'ДД.ММ.ГГГГ') {
			handleInputDateChange('')
		}
	}
	const onBlur = event => {
		const { value } = event.target
		if (!value) {
			handleInputDateChange('ДД.ММ.ГГГГ')
		}
	}

	const handleKeyPress = event => {
		const charCode = event.which ? event.which : event.keyCode
		if (
			charCode !== CHAR_CODES.DELETE &&
			charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE &&
			(charCode < CHAR_CODES.ZERO || charCode > CHAR_CODES.NINE)
		) {
			event.preventDefault()
		}
	}

	const handleKeyDown = event => {
		const value = event.target.value
		const charCode = event.which ? event.which : event.keyCode

		if (
			charCode > CHAR_CODES.MIN_CONTROL_CHAR_CODE &&
			charCode === CHAR_CODES.DOT
		) {
			return
		}
		if (charCode === CHAR_CODES.BACKSPACE || charCode === CHAR_CODES.DELETE) {
			return
		}
		if (value.length === 2 || value.length === 5) {
			event.target.value = value + '.'
			return
		}
	}

	return (
		<div
			className='CalendarWrapper'
			style={{
				width: '100%',
				alignItems: 'center',
				display: 'flex',
				height: '100%'
			}}
			{...props}
		>
			<FormInput
				disabled={!!disable}
				label={label}
				fullWidth
				value={formateInput(selectedInputDate)}
				onChange={handleInputChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={handleKeyDown}
				onKeyPress={handleKeyPress}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								edge='end'
								onClick={handleShowCalendar}
								disabled={!!disable}>
								<CalendarImage className='calendar-svg' />
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
			{showCalendar && (
				<div className='date-input' ref={calendarRef}>
					<Calendar
						value={isValid(selectedDate) ? selectedDate : new Date()}
						date={selectedDate}
						onChange={date => handleChange(date)}
						// tileDisabled={({ date }) => {
						// 	if (isExpiration) {
						// 		return date < new Date()
						// 	} else {
						// 		return date > new Date()
						// 	}
						// }}
					/>
				</div>
			)}
		</div>
	)
}

export default BirthDateInput