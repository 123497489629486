import React from 'react'
import { alpha } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import Box from "@mui/material/Box";
import {  styled as styledMUI } from '@mui/material/styles'
import { uniqBy } from 'lodash'
import { ReactComponent as Document } from '../../../asset/images/cabinet/document.svg'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from "@mui/material/TextField"
import { MenuItem, Button, Autocomplete } from '@mui/material'
import { ReactComponent as CloseImg } from '../../../asset/images/cabinet/close.svg'
import paperclip from '../../../asset/images/cabinet/paperclip.svg'
import  useClaimsStore from '../Claims/store'
import useMessagesStore from './store'

const UploadButton = styledMUI(Button)({
	borderRadius: '8px',
	color: '#4872f2',
	textTransform: 'none',
	fontSize: 16,
	fontWeight: 600,
	padding: 0,
	minWidth: 'fit-content',
	border: 'none',
	fontFamily: ['Open Sans', '-apple-system', 'Roboto'].join(','),
	'&:hover': {
		backgroundColor: '#fff',
		border: 'none',
	},
})

const VisuallyHiddenInput = styledMUI('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

const Attachment = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #EFF2F6;
	border-radius: 8px;
	padding: 16px 56px 16px 16px;
	gap: 8px;
	align-items: flex-start;
	width: 350px;

	color: #2E2E32;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-wrap: wrap;
	word-break: break-all;
`

const AttachmentsBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	flex-wrap: wrap;
	height: 78px;
	overflow: overlay; 
	
	padding-right: 1px;
	&::-webkit-scrollbar {
	width: 4px;
	border-radius: 8px;
	background-color: #D4D9DE;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #4872F2;
	}
`

const MuiTextField = styledMUI(props => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& label': {
		fontFamily: "'Open Sans', 'sans-serif'",
		color: '#7E8389',
		left:5
	},
	'& .MuiInputLabel-filled.MuiInputLabel-shrink': {
		transform: 'translate(12px, 8px) scale(0.75)'
	},
	'& .MuiFormLabel-asterisk': {
		color: 'red'
	},
	'& .MuiInputBase-input': {
		fontFamily: "'Open Sans', 'sans-serif'",
		position: 'relative',
	},
	'& .MuiFilledInput-root': {
		overflow: 'hidden',
		borderRadius: 8,
		backgroundColor: '#FFF',
		border: '1px solid',
		borderColor: '#E9EAEC',
		paddingLeft: 16,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow'
		]),
		'&:hover': {
			borderColor: '#282828',
			backgroundColor: '#FFF'
		},
		'&.Mui-focused': {
			boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 1px`,
			borderColor: theme.palette.primary.main,
			backgroundColor: '#FFF'
		},
		'&.Mui-disabled': {
			borderColor: '#B5B8BC',
			backgroundColor: '#FFF'
		}, 
		'& .MuiFilledInput-input': {	
			paddingLeft: 0,
		},
		'& :focus': {
			backgroundColor: '#FFF'
		},
		'& .MuiSvgIcon-root': {
			right: 16
		},
		'& .MuiAutocomplete-popupIndicator': {
			marginRight: 4
		}
	}
}))


const PaperclipImage = styled.img.attrs({
	src: paperclip
  })`
	width: 24px;
	height: 24px;
	margin-top: 8px;
	&:hover {
		cursor: pointer;
	}
  `

const MuiTextFieldAria = styledMUI(MuiTextField)`
	& .MuiFilledInput-root {
		border: none;
		padding-left: 0;
		&.Mui-focused {
			box-shadow: none;
			border: none;
		}
	}
	& label {
		fontFamily: "'Open Sans', 'sans-serif'";
		color: '#7E8389';
		left: -12;
	},
	& .MuiInputLabel-filled.MuiInputLabel-shrink {
		transform: translate(12px, 8px) scale(1);
		font-size: 12px;
	}
`
const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 32px;
	background-color: #fff;
	width: 780px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;
	max-height: 100vh;
	overflow-y: auto;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const PopupTitle = styled.h2`
	color: #2e2e32;
	font-family: Open Sans;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 38px;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`
const CloseBtnSmall = styled(CloseBtn)`
	width: 20px;
	height: 20px;
`

const ButtonSend = styledMUI(Button)`
	background-color: #ffe100;
	padding: 16px 32px;
	border-radius: 8px;
	box-shadow: none;
	min-width: fit-content;
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
	color: #2e2e32;
	&:hover {
		box-shadow: none;
		background-color: #ffcd00;
	}
`
 
function humanFileSize(bytes, si = true, dp = 1) {
	const thresh = si ? 1000 : 1024

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + ' ' + units[u]
}

export default function NewMessageModal({ onClose}) {
	const claimsActions = useClaimsStore(({ actions }) => actions)
	const actions = useMessagesStore(({ actions }) => actions)
	const claims = useClaimsStore(({ claims }) => claims)
	const departments = useMessagesStore(({ departments }) => departments)
	const newMessage = useMessagesStore(({ newMessage }) => newMessage)
	const typesByDepartments = useMessagesStore(({ typesByDepartments }) => typesByDepartments)

	const btnDisabled =  Object.values(newMessage).some(el => !el)
	 
	React.useEffect(() => {
		claimsActions.getClaims()	
		// eslint-disable-next-line
	}, [])

	React.useEffect(()=> {
		actions.getDepartments(newMessage.claimId)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[newMessage.claimId])

	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	const handleSendMessage = () => {
		actions.postMessage(attachments)
		actions.clearMessage()
		onClose()
	}

	const selectedClaims = React.useMemo(() => {
		if (!claims) return []
		const uniqueClaims = [...new Set(claims?.map((claim) => claim.claimId))];
		return uniqueClaims.map((claimId) => claimId.toString());
	}, [claims] )

	const [attachments, setAttachments] = React.useState([])

	const handleChange = (event) => {
		const files = [...event.target.files]
		if (files.some((file) => file.size > 30000000)) {
			const newFiles = files.filter((file) => file.size < 30000000)
			setAttachments((prev) => uniqBy([...prev, ...newFiles], 'name'))
		} else {
			setAttachments((prev) => uniqBy([...prev, ...files], 'name'))
		}
	}

	const handleRemoveAttachment = (name) => {
		setAttachments(prev => prev.filter(attachment => attachment.name !== name))
	}

	return (
	
			<PopupBackground>
				<PopupContent>
					<Row>
						<PopupTitle>Новое сообщение</PopupTitle>
						<CloseBtn onClick={() => onClose()} />
					</Row>
					<Autocomplete
						popupIcon={<ExpandMoreIcon />}
						disableClearable={!newMessage.claimId}
						options={selectedClaims}
						value={newMessage.claimId}
						onChange={(event, newValue) => actions.setNewMessageParams(('claimId'), newValue)}
						renderInput={(params) => (
						<MuiTextField
							{...params}
							label="Номер заявки"
							variant="filled"
							InputProps={{
							...params.InputProps,
							disableUnderline: true,
							}}
						/>
						)}
					/>

					<MuiTextField
						SelectProps={{ IconComponent: ExpandMoreIcon }}
						select
						fullWidth
						label='Выберите отдел'
						value={newMessage.department}
						onChange={(event) => actions.setNewMessageParams(('department'), event.target.value)}
						variant='filled'>
						{departments?.map((department) => (
							<MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
						))}
					</MuiTextField>
					<MuiTextField
						SelectProps={{ IconComponent: ExpandMoreIcon }}
						select
						fullWidth
						label='Выберите тип сообщения'
						value={newMessage.type}
						onChange={(event) => actions.setNewMessageParams(('type'), event.target.value)}
						variant='filled'>
						{newMessage.department && typesByDepartments[newMessage.department]?.map((type) => (
							<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
						))}
					</MuiTextField>
					
					<Box
						sx={{
						display: "flex",
						alignItems: "flex-start",
						borderRadius: 2,
						backgroundColor: "#FFF",
						border: "1px solid",
						borderColor: "#E9EAEC",
						padding: "0 0 0 16px",
						"&:hover": {
							borderColor: "#282828",
							backgroundColor: "#FFF",
						},
						}}
					>
						<UploadButton
							component='label'
							variant='outlined'
							startIcon={<PaperclipImage/>}
						> 
							<VisuallyHiddenInput
								type='file'
								accept='.png,.jpg,.jpeg,.pdf,.docx'
								onChange={handleChange}
								>
							</VisuallyHiddenInput>
						</UploadButton>
						<MuiTextFieldAria		
							value={newMessage.text}
							onChange={(event) => actions.setNewMessageParams(('text'), event.target.value)}
							fullWidth
							variant='filled'
							multiline
							maxRows={5}
							label='Текст сообщения'
						/>
					</Box>
					{attachments.length > 0 && (
						<AttachmentsBox>
							{attachments.map((attachment) => (
								<Attachment key={attachment.name}>
									<Document/>
									<div>
										{attachment.name}
										<br />
										<span style={{color: '#7e8389'}}>{humanFileSize(attachment.size)}</span>
									</div>
									<IconButton onClick={() => handleRemoveAttachment(attachment.name)}>
										<CloseBtnSmall/>
									</IconButton>
								</Attachment>
							))}
						</AttachmentsBox>
					)}
						
					<ButtonSend
						onClick={handleSendMessage}
						disabled={btnDisabled}
					>Отправить</ButtonSend>	
				</PopupContent>
			</PopupBackground>
		)
}
