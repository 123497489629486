import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { TextField } from '@mui/material'
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as CloseImg } from '../../../../asset/images/cabinet/close.svg'
import CustomizedCheckbox from '../components/Checkbox/CustomizedCheckbox'
import useClaimsStore from '../store'
import ReceiptModal from './ReceiptModal';
import PaymentProcessingModal from './PaymentProcessingModal';
import PaymentBonusModal from "./PaymentBonusModal";

const FormInput = styled(TextField)(() => ({
	'& fieldset': {
		borderRadius: '8px',
		borderColor: '#D4D9DE'
	},
	'& .MuiInputBase-input': {
		background: 'white',
		fontFamily: "'Open Sans', 'sans-serif'"
	},
	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, -9px) scale(0.75)'
	},
	'& label': {
		fontFamily: "'Open Sans', 'sans-serif'",
		color: '#7E8389'
	}
}))

const PopupBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(67, 67, 67, 0.7);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const PopupContent = styled.div`
	position: relative;
	border-radius: 16px;
	padding: 32px;
	background-color: #fff;
	width: 770px;
	animation: opacity 200ms cubic-bezier(0.41, 0.56, 1, 0.7);
	display: flex;
	flex-direction: column;
	gap: 32px;
	max-height: 100vh;
	overflow-y: auto;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`
const ButtonsBlock = styled(Row)`
	justify-content: center;
	gap: 32px;
	align-self: center;
`
const RowStart = styled(Row)`
	justify-content: flex-start;
	cursor: pointer;
`

const PopupTitle = styled.h2`
	color: #2e2e32;
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`

const CloseBtn = styled(CloseImg)`
	width: 32px;
	height: 32px;
	fill: #2e2e32;
	cursor: pointer;
	&:hover {
		fill: #4872f2;
	}
`

const PaymentMethod = styled.div`
	padding: 24px;
	border: ${({ isActive }) =>
		isActive ? '1px solid #4872F2' : '1px solid #DBE0E5'};
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const BonusBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Text = styled.p`
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
`

const RedText = styled(Text)`
	color: #e73c3e;
`

const PaymentMethodTitle = styled(Text)`
	color: #3c3c3c;
`

const ButtonFilled = styled(Button)`
	background-color: #4872f2;
	padding: 16px 32px;
	border-radius: 8px;
	box-shadow: none;
	min-width: fit-content;

	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-transform: none;
`

const RadioButton = styled.div`
	margin-right: 12px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: ${({ isActive }) =>
		isActive ? '7px solid#4872F2' : '1px solid #D4D9DE'};
	cursor: pointer;
	background-color: #fff;
	transition: all 0.2s ease-in-out;
	&:hover {
		background-color: #d4d9de;
	}
`
const PAYMENT_VARIANT = {
	CARD: 10011,
	RECEIPT: 10,
	BONUS: 2,
	INVOICE: 5
}

const PaymentMethodModal = ({ claimId, onClose }) => {

	const actions = useClaimsStore(({ actions }) => actions)
	const loading = useClaimsStore(({ loading }) => loading)
	const bonus = useClaimsStore(({ bonus }) => bonus)
	const paymentBonusStatus = useClaimsStore(({ paymentBonusStatus }) => paymentBonusStatus)
	const [activeBlock, setActiveBlock] = React.useState(PAYMENT_VARIANT.CARD)
	const [openReceipt, setOpenReceipt] = React.useState(false)
	const [openBonus, setOpenBonus] = React.useState(false)
	const [amount, setAmount] = React.useState('')
	const [error, setError] = React.useState('')
	// const [openBonus, setOpenBonus] = React.useState(false)
	const [openPaymentProcessing, setOpenPaymentProcessing] = React.useState(false)

	const payVariant = useClaimsStore(({ payVariants }) => payVariants)?.find(i => i?.claimId === claimId)?.payVariant
	const visibleBlocks = {
		cardVisible: !!payVariant?.find(i => i?.id === PAYMENT_VARIANT.CARD),
		receiptVisible: !!payVariant?.find(i => i?.id === PAYMENT_VARIANT.RECEIPT),
		bonusVisible: !!payVariant?.find(i => i?.id === PAYMENT_VARIANT.BONUS),
		invoiceVisible: !!payVariant?.find(i => i?.id === PAYMENT_VARIANT.INVOICE)
	}

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	useEffect(() => {
		actions.getBonus(claimId)
	}, [actions, claimId])

	useEffect(() => {
		actions.getCheckPayVariant(claimId)
	}, [actions, claimId])

	// useEffect(() => {
	// 	if (!bonus || !bonus?.at(0)?.claimAmount) {
	// 		setActiveBlock(PAYMENT_VARIANT.CARD)
	// 	}
	// }, [bonus, activeBlock])

	const handleClose = () => {
		onClose(false)
	}

	const handleStartPaying = () => {
		if (activeBlock === PAYMENT_VARIANT.INVOICE) {
			actions.getPaymentInvoice(claimId)
		} else if (activeBlock === PAYMENT_VARIANT.RECEIPT) {
			setOpenReceipt(true)
		} else if (activeBlock === PAYMENT_VARIANT.BONUS) {
			if (bonus.at(0).claimAmount >= Number(amount)) {
				setOpenBonus(true)
			} else {
				setError('Введенное число бонусов превышает доступное')
			}
		}
		else {
			setOpenPaymentProcessing(true)
			// actions.paymentHold(claimId)
		}
	}

	const getButtonText = () => {
		switch (activeBlock) {
			case PAYMENT_VARIANT.CARD:
				return 'Перейти к оплате'
			case PAYMENT_VARIANT.INVOICE:
				return 'Скачать'
			case PAYMENT_VARIANT.RECEIPT:
				return 'Выписать квитанцию'
			case PAYMENT_VARIANT.BONUS:
				return 'Использовать бонусы'
			default:
				return 'Перейти к оплате'
		}
	}

	const handleChangeBonus = (e) => {
		setError('')
		if (e.target.checked) {
			setActiveBlock(PAYMENT_VARIANT.BONUS)
		} else {
			setAmount('')
			setActiveBlock(PAYMENT_VARIANT.CARD)
		}
	}

	const payBonus = () => {
		setOpenBonus(false)
		actions.payBonus(claimId, Number(amount), bonus.at(0).claimCurrency)
	}

	return (
		<PopupBackground>
			<PopupContent>
				<Row>
					<PopupTitle>Способы оплаты заявки №{claimId}</PopupTitle>
					<CloseBtn onClick={() => handleClose()} />
				</Row>
				{visibleBlocks.cardVisible && <PaymentMethod isActive={activeBlock === PAYMENT_VARIANT.CARD}>
					<RowStart onClick={() => setActiveBlock(PAYMENT_VARIANT.CARD)}>
						<RadioButton isActive={activeBlock === PAYMENT_VARIANT.CARD} />
						<PaymentMethodTitle>Оплатить картой</PaymentMethodTitle>
					</RowStart>
					<Text>
						Данный способ позволяет проовести оплату с помощью банковской карты
					</Text>
				</PaymentMethod>}
				{visibleBlocks.invoiceVisible && <PaymentMethod isActive={activeBlock === PAYMENT_VARIANT.INVOICE}>
					<RowStart onClick={() => setActiveBlock(PAYMENT_VARIANT.INVOICE)}>
						<RadioButton isActive={activeBlock === PAYMENT_VARIANT.INVOICE} />
						<PaymentMethodTitle>Получить счёт</PaymentMethodTitle>
					</RowStart>
					<Text>
						Данный способ оплаты позволяет распечатать бухгалтерский счет на
						оплату и на его основе произвести оплату заявки.
					</Text>
				</PaymentMethod>}
				{visibleBlocks.receiptVisible &&
					<PaymentMethod isActive={activeBlock === PAYMENT_VARIANT.RECEIPT}>
						<RowStart onClick={() => setActiveBlock(PAYMENT_VARIANT.RECEIPT)}>
							<RadioButton isActive={activeBlock === PAYMENT_VARIANT.RECEIPT} />
							<PaymentMethodTitle>
								Оплата по квитанции через банк
							</PaymentMethodTitle>
						</RowStart>
						<RedText>
							С 30.07.2018 при оплате через Сбербанк (терминал, личный кабинет
							онлайн) удерживается комиссия 0,2% с плательщика.
						</RedText>
						<Text>
							При оплате через ВТБ (кассу, терминал, личный кабинет он-лайн)
							комиссия не взимается.
						</Text>
						<Text>
							Вы распечатываете квитанцию на оплату (пример) и на ее основе
							оплачиваете заявку в отделениях ВТБ банка. Необходимо помнить
							следующее:
							<ul>
								<li>
									Квитанция должна быть обязательно выписана на плательщика. Если
									Вы выписали квитанцию на Сидорова И.И., а производить оплату
									будет Петров В.В., то банк оплату не примет, т.к. должен быть
									обязательно Сидоров И.И.
								</li>
								<li>
									Квитанция действительна на день распечатки и должна быть
									оплачена в тот же день, т.к. на следующий день курс может
									изменится.
								</li>
							</ul>
							Если Вы выписываете квитанцию на плательщика, на которого уже
							выписывалась квитанция, Вам необходимо выбрать его из списка ФИО.
						</Text>
					</PaymentMethod>}
				{visibleBlocks.bonusVisible && <BonusBlock>
					<CustomizedCheckbox
						onChange={handleChangeBonus}
						disabled={!bonus?.at(0)?.claimAmount}
						label='Списать баллы агента'
					/>
					<Text>Накопленные баллы: <strong>{bonus?.at(0)?.bonusSum}</strong></Text>
					<Text>Доступно к оплате: <strong>{bonus?.at(0)?.claimAmount}</strong></Text>
					<FormInput
						value={amount}
						onChange={(event) => {
							setError('')
							setAmount(event.target.value.replace(',', '.'))
						}}
						fullWidth
						variant='outlined'
						label='Баллы для списания'
						error={!!error}
						helperText={error}
					/>
				</BonusBlock>}
				<ButtonsBlock>
					<ButtonFilled
						onClick={() => handleStartPaying()}
						fullWidth
						disabled={loading}
						variant='contained'
						color='primary'>
						{loading && <CircularProgress size={10} />}
						{getButtonText()}
					</ButtonFilled>
				</ButtonsBlock>
				{openReceipt &&
					<ReceiptModal
						claimId={claimId}
						onClose={() => setOpenReceipt(false)}
						onClosePayments={() => onClose(false)}
					/>}
				{openPaymentProcessing &&
					<PaymentProcessingModal
						claimId={claimId}
						onClose={() => setOpenPaymentProcessing(false)}
						onClosePayments={() => onClose(false)}
					/>}
				{openBonus &&
					<PaymentBonusModal
						className={'pay_bonus'}
						closeButtonTitle={'Нет'}
						successButtonTitle={'Да'}
						title={'Использовать бонусы'}
						text={'Вы действительно хотите использовать бонусные баллы в счёт оплаты заявки?'}
						onClose={() => setOpenBonus(false)}
						onSuccess={payBonus}
					/>}
				{paymentBonusStatus && paymentBonusStatus === 'success' &&
					<PaymentBonusModal
						successButtonTitle={'Закрыть'}
						title={'Оплата прошла успешно'}
						onSuccess={() => actions.cancelPaymentStatus()}
					/>}
				{paymentBonusStatus && paymentBonusStatus === 'fail' &&
					<PaymentBonusModal
						successButtonTitle={'Закрыть'}
						title={'Ошибка, попробуйте ещё раз'}
						onSuccess={() => actions.cancelPaymentStatus()}
					/>}
			</PopupContent>
		</PopupBackground>
	)
}

export default PaymentMethodModal
