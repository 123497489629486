import React, { useEffect, useState, useRef } from 'react';
import useGeoStore from "../../store";
import { isEmpty } from "lodash";
import HotelCard from "./HotelCard"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Virtual } from 'swiper/modules';
import { isMobile } from "react-device-detect";
import { v4 as uuid } from 'uuid';

import './styles.scss'
import LoadingCard from './LoadingCard';

const MIN_CARD_COUNT = 4

const getSettings = (arr) => arr.reduce((acc, setting) => {
	const { elements } = setting;
	elements.forEach(element => {
		const { alias, value } = element;
		acc[alias] = value;
	});
	return acc;
}, {});

const getDiscount = (discounts) => {
	if (discounts?.useDiscounts) {
		const max = Number(discounts.endDiscount)
		const min = Number(discounts.startDiscount)
		return Math.floor(Math.random() * (max - min + 1)) + min
	} else {
		return false
	}
}


const SimpleSelectionOfTours = ({ content, id }) => {
	const [discount] = useState(getDiscount(content.discounts))
	const [loading, setLoading] = useState(true)
	const actions = useGeoStore(({ actions }) => actions)
	const [slides, setSlides] = useState()
	const swiperRef = useRef(null)
	const uid = uuid()
	const isShowMobile = !isMobile && content.hotelLinks.length > MIN_CARD_COUNT

	const getSlides = () => {
		setLoading(true)
		const request = content.hotelLinks?.map(item => {
			const settings = getSettings(item.additionalSettings)
			return actions.getSimpleSelectionOfTours(item, settings)
		})
		Promise.all(request).then((value) => {
			setSlides(value.filter(value => value))
			setLoading(false)
		})
	}

	useEffect(() => {
		setSlides(getSlides())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='simple-selection-tours' id={id}>
			<div className='title'><h2>{content.heading}</h2>
				{isShowMobile && <div className='nav'>
					<div className={`swiper-button-disabled swiper-button-prev id-${uid} nav-button`} ><div className='prev_chevron' /></div>
					<div className={`swiper-button-next id-${uid} nav-button`} ><div className='next_chevron' /></div>
				</div>}
			</div>
			<div>
				<Swiper
					ref={swiperRef}
					modules={[Virtual, Navigation]}
					slidesPerView={1.3}
					virtual={{
						addSlidesAfter: 5,
						addSlidesBefore: 5,
						enabled: true,
					}}
					breakpoints={{
						780: {
							slidesPerView: 4,
							spaceBetween: 20,
							navigation:
							{
								nextEl: `.swiper-button-next.id-${uid}`,
								prevEl: `.swiper-button-prev.id-${uid}`,
							}
						},
					}}
				>
					{
						loading ?
							content.hotelLinks.map((i, index) =>
								<SwiperSlide className='simple-selection-tours__slide' key={index} virtualIndex={index}><LoadingCard /></SwiperSlide>
							)
							:
							isEmpty(slides) ?
								<div> Поиск не дал результатов.</div>
								: slides.map((item, index) => {
									if (item.noContent) {
										return <div>{item.noContent}</div>
									}

									return <SwiperSlide className='simple-selection-tours__slide' key={item.tours[0].hotel.id} virtualIndex={index}>
										<HotelCard tours={item} discount={discount} />
									</SwiperSlide>
								}
								)
					}
				</Swiper>
			</div>
		</div>
	);
};

export default SimpleSelectionOfTours;